import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment from "moment";
import React from "react";
import { useMutation, useQuery } from "react-query";
import workingOrderMService, { ProductPOM, PurchasingOrderM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { FORMAT_DATE_TIME } from "utils/constant";

export type Props = ModalProps & {
    onProductAdd?: (products: ProductPOM[], pom?: PurchasingOrderM) => void;
};

const AddPurchasingOrderMModal = ({ children, onProductAdd, ...props }: Props) => {
    const closeRef = React.useRef<HTMLButtonElement | null>(null);
    const [page, setPage] = React.useState<number>(1);
    const [step, setStep] = React.useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const getPom = useQuery([workingOrderMService.getPom, page], async () => {
        return (await workingOrderMService.GetPom({ page })).data.data;
    });

    const getProductPom = useMutation(async (id: number) => {
        return (await workingOrderMService.GetProductPom({ id })).data.data;
    });

    const onClickProductAdd = () => {
        const products = getProductPom.data?.filter((p) => selectedRowKeys.includes(p.product_id));
        if (!onProductAdd) return;
        onProductAdd(
            products!,
            getPom.data?.list?.find((el) => el.id === getProductPom?.variables)
        );
        closeRef.current?.click();
    };

    const onClickChoose = (pom: PurchasingOrderM) => {
        setStep(1);
        getProductPom.mutate(pom.id);
        setSelectedRowKeys([]);
    };

    const columnsPom: ColumnsType<PurchasingOrderM> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((getPom.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "PO No",
            dataIndex: "po_no",
        },
        {
            title: "Total SKU",
            dataIndex: "total_product",
            width: "120px",
        },
        {
            title: "Qty",
            dataIndex: "total_qty",
            width: "80px",
        },
        {
            title: "Create At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            title: "Action",
            width: "80px",
            render: (val, rec) => (
                <Button onClick={() => onClickChoose(rec)} type="primary">
                    Choose
                </Button>
            ),
        },
    ];

    const columnsProductPom: ColumnsType<ProductPOM> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render(value, record, index) {
                return index + 1;
            },
        },
        {
            title: "Product Code",
            dataIndex: "product_code1",
        },
        // {
        //     title: "Qty",
        //     dataIndex: "qty",
        // },
        {
            title: "Remaining Qty",
            dataIndex: "qty_sisa",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "-",
            render(value, record, index) {
                return `${record?.qty_wo} / ${record?.qty}`;
            },
        },
    ];

    const rowSelection: TableRowSelection<ProductPOM> = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: ProductPOM) => ({
            ...record,
            disabled: (() => {
                if (record.qty_wo === record.qty) return true;
                return false;
            })(),
        }),
    };

    return (
        <ModalTemplate
            {...props}
            width={800}
            title={step === 0 ? "Add Purchasing Order M" : "Add Product WIP"}
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    {step === 0 && (
                        <Table
                            loading={getPom.isLoading}
                            columns={columnsPom}
                            size="small"
                            rowKey={(el) => el.id}
                            dataSource={getPom?.data?.list || []}
                        />
                    )}
                    {step === 1 && (
                        <Table
                            rowSelection={rowSelection}
                            loading={getProductPom.isLoading}
                            columns={columnsProductPom}
                            size="small"
                            rowKey={(el) => el.product_id}
                            dataSource={getProductPom?.data || []}
                        />
                    )}
                    {step === 1 && (
                        <div className="w-full flex justify-between">
                            <Button onClick={() => setStep(0)} type="text">
                                Back
                            </Button>
                            <Button onClick={onClickProductAdd} disabled={selectedRowKeys.length === 0} type="primary">
                                Add Product
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </ModalTemplate>
    );
};

export default AddPurchasingOrderMModal;

import { Button, Dropdown, MenuProps } from "antd";
import { UserContext } from "context/user";
import React, { useContext } from "react";
import { VscSignOut } from "react-icons/vsc";
import { RiAccountCircleFill } from "react-icons/ri";
import Utils from "utils";
import { FaCubes } from "react-icons/fa";
import { IoLockClosed, IoLockClosedOutline, IoLockOpenSharp, IoSettingsSharp } from "react-icons/io5";
import { APPS_PATH, CHANGE_PASSWORD_PATH, SETTING_PATH } from "utils/routes";
import { Link, useNavigate } from "react-router-dom";
import { MainMenu } from "models";
import { StateContext } from "context/state";

const AccountHeader = () => {
    const navigate = useNavigate();
    const { state, setState: setStateUser } = useContext(UserContext);
    const { setState: setStateState, navigateAppHandler } = useContext(StateContext);

    const signOutHandler = () => {
        Utils.SignOut();
        if (setStateUser) setStateUser({});
        if (setStateState) setStateState({});
        window.location.reload();
    };

    const appClickHandler = (app: MainMenu) => {
        return () => {
            if (!navigateAppHandler) return;
            navigate(navigateAppHandler(app));
        };
    };

    const items: MenuProps["items"] = [
        {
            label: <div className="w-[200px] py-3">{state.user?.email}</div>,
            key: "0",
        },
        {
            type: "divider",
        },
        ...(state.user?.apps?.map((app, i) => ({ key: i + 1, label: app.apps_name, onClick: appClickHandler(app) })) || []), // apps
        {
            type: "divider",
        },
        {
            label: (
                <Link to={APPS_PATH}>
                    <div className="flex items-center">
                        <FaCubes />
                        <p className="capitalize m-0 ml-3">apps</p>
                    </div>
                </Link>
            ),
            key: "200",
        },
        {
            label: (
                <Link to={SETTING_PATH}>
                    <div className="flex items-center">
                        <IoSettingsSharp />
                        <p className="capitalize m-0 ml-3">setting</p>
                    </div>
                </Link>
            ),
            key: "300",
        },
        {
            label: (
                <Link to={CHANGE_PASSWORD_PATH}>
                    <div className="flex items-center">
                        <IoLockClosed />
                        <p className="capitalize m-0 ml-3">change password</p>
                    </div>
                </Link>
            ),
            key: "400",
        },
        {
            type: "divider",
        },
    ];

    if (!items.find((el) => el?.key === "100")) {
        items.push({
            label: (
                <div className="flex items-center">
                    <VscSignOut />
                    <p className="capitalize m-0 ml-3">sign out</p>
                </div>
            ),
            key: "100",
            danger: true,
            onClick: signOutHandler,
        });
    }

    return (
        <Dropdown menu={{ items }} trigger={["click"]}>
            <Button title="Profile" size="large" shape="circle" className="!flex !items-center !justify-center !bg-transparent !border-none">
                <RiAccountCircleFill className="text-3xl" />
            </Button>
        </Dropdown>
    );
};

export default AccountHeader;

import React from "react";
import { Button, Space, Table } from "antd";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { DetailLocator, DetailProduct } from "../models";

type Props<T> = {
    list: T[];
};

const LocatorDetailTable = <T extends DetailProduct>({ list }: Props<T>) => {
    const columns = [
        {
            width: "50px",
            title: "No",
            render: (id: any, record: any, index: number) => <p className="capitalize m-0">{index + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text: any) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Locator From",
            children: [
                {
                    title: "Locator Name",
                    dataIndex: "list_locator_from",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].locator_name || "-"}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "list_locator_from",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_before || "0"}</p>,
                },
                {
                    title: "Qty Transfer",
                    dataIndex: "list_locator_from",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_transfer || "0"}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "list_locator_from",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_after || "0"}</p>,
                },
            ],
        },
        {
            title: "Locator To",
            children: [
                {
                    title: "Locator Name",
                    dataIndex: "list_locator_to",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].locator_name || "-"}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "list_locator_to",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_before || "0"}</p>,
                },
                {
                    title: "Qty Transfer",
                    dataIndex: "list_locator_to",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_transfer || "0"}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "list_locator_to",
                    render: (record: DetailLocator[]) => <p className="capitalize m-0">{record[0].qty_after || "0"}</p>,
                },
            ],
        },
    ];

    return <Table size="small" columns={columns} dataSource={list || []} className="w-full" bordered />;
};

export default LocatorDetailTable;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface CreateRawMaterial {
    id?: any;
    code1: string;
    name: string;
    description?: string;
    uom_id: number;
    is_active: number;
    // dimensi_id: number;
    // workflow_type_id: number[];
    images?: any[];
}

export interface ListRawMaterial {
    id?: any;
    code1: string;
    text: string;
    description?: string;
    uom_id: number;
    uom_name: string;
    is_active: number;
    images?: any[];
}

export interface Dimension {
    id: number;
    code: string;
    name: string;
    description: any;
}

export interface WFType {
    wf_id: number;
    wf_code: any;
    wf_name: string;
}

export interface GetAllRawMaterialParams {
    page?: any;
    param_search?: any;
}

class ManufactureRawMaterialService extends BaseService {
    create = "/manufacture/create-raw-material";

    getWorkflowType = "/master-product/get-workflow-process";

    getDimension = "/master-product/get-dimensi";

    getAll = "/manufacture/list-raw-material";

    detail = "/manufacture/get-material-detail";

    delete = "";

    constructor() {
        super();
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Detail<T extends ListRawMaterial>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends ListRawMaterial>(params: GetAllRawMaterialParams) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    GetWorkflowType<T extends WFType[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getWorkflowType,
            });
            return req;
        });
    }

    GetDimension<T extends Dimension[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDimension,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateRawMaterial, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const manufactureRawMaterialService = new ManufactureRawMaterialService();
export default manufactureRawMaterialService;

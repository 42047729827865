import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterStyle from "modules/master-data/style/index/filter";
import { TDataStyle } from "modules/master-data/style/models";
import StyleTable from "modules/master-data/style/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import styleService from "services/api-endpoints/dashboard/master-data/style";

const filter = ["style_name", "style_code", "is_active"];

const Style = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([styleService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await styleService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await styleService.Filter(filterValue as any)).data.data;
        return (await styleService.GetAll({ page })).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataStyle) => (await styleService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataStyle>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterStyle filter={filter}>
                {(dt) => <Toolbar placeholder="Style Name / Style Code" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterStyle>
            <StyleTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Style;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import suppliesService, { FilterPurchasing, SuppliesList } from "services/api-endpoints/purchasing/supplies";

const filter = ["start_date", "end_date"];

const Assembling = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([suppliesService.getAll, page, filterValue, isSearch], async () => {
        return (
            await suppliesService.GetAll({
                ...({ ...filterValue, param_search: searchValue.query || "" } as unknown as FilterPurchasing),
            })
        ).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Assembling Package ..." filter={false} add={false} />
            {/* <SuppliesTable fetcher={getList} onClickDelete={showConfirm} /> */}
        </div>
    );
};

export default Assembling;

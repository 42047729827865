/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { AllModel, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import { FCreateDataProduct } from "../models";

type SelectOptionCustom = SelectOption & AllModel;

export interface Props extends ControlledInputSelectProps<FCreateDataProduct> {
    onSelectedData?: (id: number, data: SelectOptionCustom[]) => void;
}

export function withModel<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        // const brand = props?.control?._getWatch("brand");
        const query = useQuery([modelService.getAllModel], async () => {
            const req = await modelService.GetAllModel({ brand_id: 0 });
            return req.data.data?.map((el) => {
                return {
                    ...el,
                    label: `${el?.model_name} ${el.model_year?.join(", ") || ""} ${el.model_type_name || "ALL TYPE"}` || "Any",
                    value: el.id || "",
                } as SelectOptionCustom;
            });
        });

        const onSelectChange = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(id, query.data || []);
            }
        };

        return (
            <Component
                {...props}
                // disabled={!brand}
                onSelect={onSelectChange}
                onDeselect={onSelectChange}
                optionFilterProp="children"
                loading={query.isLoading}
                options={query.data || []}
            />
        );
    };
}

const ModelSelect = withModel(ControlledSelectInput);

export default ModelSelect;

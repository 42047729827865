import { Button, Card, Form, message, Select } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { SelectOption } from "models";
import { getDocumentRoute } from "utils/routes";
import { AUTOGENERATED } from "utils/constant";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { CreateDeliveryOrder } from "modules/dashboard-jsl/delivery-order/models";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ProductTable from "modules/dashboard-jsl/delivery-order/add/product-table";
import ModalBPDOTable from "modules/dashboard-jsl/bp-picking-list/modal-bp-do-table";
import bpDeliveryOrderService, { CreateDO, DetailPL, DetailProduct } from "services/api-endpoints/dashboard/master-data/bp-delivery-order";
import salesOrderService from "services/api-endpoints/dashboard-jsl/sales-order";
import bpOrderService from "services/api-endpoints/dashboard/master-data/bp-order";

const schema: yup.SchemaOf<Partial<CreateDeliveryOrder>> = yup.object().shape({
    bp_id: yup.number().required("Request From required"),
    driver_name: yup.string().required("Driver Name required"),
    plate_number: yup.string().required("Plate Number required"),
    note: yup.string(),
});

const BPDeliveryOrderAdd = () => {
    const navigate = useNavigate();
    const [productsSO, setProductsSO] = useState<DetailPL[]>([]);
    const [productsSOReal, setProductsSOReal] = useState<DetailPL[]>([]);
    const [selectedSO, setSelectedSO] = useState<React.Key[]>([]);
    const [topId, setTopId] = useState<number | null>(null);
    const [form] = Form.useForm();
    const { handleSubmit, watch, control } = useForm<CreateDeliveryOrder>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const warehouse = watch("bp_id");

    const getHeaderPOQuery = useQuery([salesOrderService.getHeaderSO], async () => {
        return (await salesOrderService.GetHeaderSO()).data.data;
    });

    const requestFromQuery = useQuery([bpOrderService.getBusinessPartner], async () => {
        return (await bpOrderService.GetBusinessPartner({ status: 3 })).data.data?.map(
            (el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption)
        );
    });

    const createDOMutation = useMutation(
        [bpDeliveryOrderService.createDO],
        async (data: CreateDO) => {
            return (await bpDeliveryOrderService.CreateDO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Delivery order created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = () => {
        form.submit();
    };

    const onRemoveProduct = (product: DetailPL) => {
        setProductsSO((prev) => [...prev].filter((el) => JSON.stringify(el) !== JSON.stringify(product)));
    };

    const onProductSO = (data: DetailPL[], dataReal: DetailPL[]) => {
        // setProductsSO((prev) => {
        //     if (prev.length === 0) return data;
        //     const setNewData = data.filter((product) => !prev.find((prevProduct) => JSON.stringify(prevProduct) === JSON.stringify(product)));
        //     return [...prev, ...setNewData];
        // });
        setProductsSO(data);
        setProductsSOReal(dataReal);
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: CreateDO = {
            ...data,
            payment_term_id: topId,
            child_document: [...new Set(productsSOReal.map((el) => el.pl_id!))],
            detail_product: [...productsSOReal].map(
                (el) => ({ price: el.product_price, product_id: el.product_id, doc_id: el.pl_id, qty: el.qty, area_id: el.area_id } as DetailProduct)
            ),
        };
        createDOMutation.mutateAsync(parseData);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new BP delivery order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDOMutation.isLoading}
                            disabled={productsSO.length === 0}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save And Send
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-3 gap-x-4">
                        <p className="capitalize m-0">
                            delivery order no <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            className="!w-[300px]"
                            showSearch
                            name="bp_id"
                            label="Request From"
                            placeholder="Request From"
                            optionFilterProp="children"
                            control={control}
                            loading={requestFromQuery.isLoading}
                            options={requestFromQuery.data || []}
                        />
                        <p className="capitalize m-0">
                            request to <br />
                            <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                        </p>
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="plate_number"
                            label="Plate Number"
                            placeholder="Plate Number"
                        />
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="driver_name"
                            label="Driver Name"
                            placeholder="Driver Name"
                        />
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="note"
                            label="Note"
                            placeholder="Note"
                        />
                    </div>
                </Form>
            </Card>
            <ModalBPDOTable
                onProductSO={onProductSO}
                warehouse={warehouse || null}
                selectedSO={selectedSO}
                setSelectedSO={setSelectedSO}
                setTopId={setTopId}
            >
                {(dt) => (
                    <Button disabled={warehouse === undefined} className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Data BP Packing List
                    </Button>
                )}
            </ModalBPDOTable>
            <ProductTable list={productsSO} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default BPDeliveryOrderAdd;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { UserContext } from "context/user";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { FDataCreateExtSO } from "modules/master-data/bp-order/models";
import { mapDiscountProductExt } from "modules/master-data/consignment/delivery-order/utils";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VoucherModal from "modules/master-data/consignment/modal/voucher-modal";
import VersionHistory from "modules/master-data/consignment/version-history";
import ModalEditSO from "modules/master-data/external-order/ext-so/edit-so-modal";
import TableEdit from "modules/master-data/external-order/ext-so/table-edit";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineUndo } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import extSalesOrderService, {
    CreateSalesOrder,
    DetailProduct,
    EditCSalesOrder,
    EditProduct,
    ReadyParam,
    SummaryPriceParam,
} from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { ACCOUNT_ID_GNM, C_STATUS_NEW, C_STATUS_ORDER } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<FDataCreateExtSO>> = yup.object().shape({
    bp_id: yup.number().required("Business partner required"),
    payment_term_id: yup.number().required("Term Of Payment required"),
    note: yup.string(),
});

const ExtSalesOrderEdit = () => {
    const navigate = useNavigate();
    const { state } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [products, setProducts] = useState<DetailProduct[]>([]);
    const [allProducts, setAllProducts] = useState<DetailProduct[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<FDataCreateExtSO>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchBpId = watch("bp_id");

    const detailQuery = useQuery(
        [extSalesOrderService.detail, id],
        async () => {
            return (await extSalesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setValue("bp_id", data.bp_id || 0);
                setValue("payment_term_id", data.payment_term_id || 0);
                setValue("note", data.note || "");
                const p = (data.list_detail || [])
                    .map((val) => ({
                        ...val,
                        qty_max: val.qty,
                        hideEditAction: val.is_last_stock === 1,
                        hideRemoveAction: val.is_last_stock === 1,
                    }))
                    .filter((val) => val.is_free === 0);
                setProducts(p);
                if (data.voucher_id !== 0) {
                    setVouchers([{ voucher_id: data.voucher_id, voucher_code: data.voucher_code } as Voucher]);
                }
            },
        }
    );

    const getSummaryPrice = useMutation(
        [extSalesOrderService.summaryPrice],
        async (data: SummaryPriceParam) => {
            return (await extSalesOrderService.SummaryPrice(data)).data.data;
        },
        {
            onSuccess: (data) => {
                const filterFree = data.detail_product?.filter((val) => val.is_free === 1);
                const prodFree = (filterFree || []).map((el) => {
                    return {
                        ...el,
                        product_price: el.price,
                        product_code1: el.product_code1,
                        hideEditAction: true,
                        hideRemoveAction: true,
                    } as DetailProduct;
                });
                const filterDisc = data.detail_product?.filter((val) => val.is_free !== 1 && val.voucher_id !== null);
                const disc = (filterDisc || []).map((el) => {
                    return {
                        ...el,
                        product_price: el.price,
                        product_code1: el.product_code1,
                    } as DetailProduct;
                });
                const prodDisc = mapDiscountProductExt(products, disc || []);
                setAllProducts([...prodDisc, ...prodFree]);
                if (prodFree.length !== 0) {
                    message.success(`Get Free ${prodFree.length} Product`);
                }
            },
        }
    );

    const readyToPickingMutation = useMutation(
        [extSalesOrderService.readyToPicking],
        async (data: ReadyParam) => {
            return (await extSalesOrderService.ReadyToPicking(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("SO Success Ready To Picking");
                navigate(-1);
            },
        }
    );

    const onReadyToPickingHandler = async () => {
        try {
            const parseData: ReadyParam = {
                id,
            };
            readyToPickingMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const editMutation = useMutation(
        [extSalesOrderService.edit],
        async (data: EditCSalesOrder) => {
            return (await extSalesOrderService.Edit(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales Order Edited");
                navigate(-1);
            },
        }
    );

    const onParseData = () => {
        const productFilter = products.filter((p) => p.status !== "delete");
        const parseData: SummaryPriceParam = {
            account_id_to: watchBpId,
            detail_product: [
                ...(productFilter?.map(
                    (p) => ({ price: p.product_price || p.price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)
                ) || []),
            ],
            voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
        };
        return parseData;
    };

    const onSaveHandler = async (type: number) => {
        try {
            const productFilter = allProducts.filter((p) => p.status !== "delete");
            const parseData: EditCSalesOrder = {
                id,
                type_edit: type,
                voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
                discount_price: getSummaryPrice.data?.total_discount || 0,
                detail_product: productFilter.map((el) => {
                    return {
                        product_id: el.product_id,
                        price: el.product_price,
                        qty: el.qty,
                        discount_price: el.discount_price,
                        voucher_id: el.voucher_id,
                        note: el.note,
                        is_free: el.is_free,
                        is_last_stock: el.is_last_stock ? 1 : 0,
                    } as EditProduct;
                }),
            };
            editMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onRemoveProduct = (product: DetailProduct) => {
        const productIsAlreadyThere = products?.find((p) => p.product_id === product.product_id);
        if (productIsAlreadyThere) {
            message.warning("Product is given DELETED flag");
            setAllProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            setProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            return;
        }
        message.warning("Product removed");
        setAllProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
        setProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
    };

    const onUndoRemoveProduct = (product: DetailProduct) => {
        return () => {
            const prevProduct = products?.find((p) => p.product_id === product.product_id);

            const isEdited = prevProduct?.qty !== product.qty;
            setAllProducts((prev) =>
                prev?.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
            setProducts((prev) =>
                prev?.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
        };
    };

    const onEditProduct = (productList: DetailProduct[], prevEditProduct?: DetailProduct | null) => {
        if (!prevEditProduct) return;

        const productNotFree = (detailQuery.data?.list_detail || [])
            .map((val) => {
                return {
                    ...val,
                    qty_max: val.qty,
                    is_check: productList.find((el) => el.product_id === val.product_id)?.is_check,
                };
            })
            .filter((val) => val.is_free === 0);
        const prevProduct = productNotFree?.find((p) => p.product_id === prevEditProduct?.product_id);
        const currEditProduct = productList?.find((p) => p.product_id === prevEditProduct.product_id);
        if (!currEditProduct) return;
        const isEdited = prevProduct?.qty !== currEditProduct.qty;
        if (prevProduct) {
            setAllProducts(() =>
                productList?.map((p) =>
                    p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined, qty: currEditProduct.qty } : p
                )
            );
            setProducts(() =>
                productList?.map((p) =>
                    p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined, qty: currEditProduct.qty } : p
                )
            );
            return;
        }
        setAllProducts(productList);
        setProducts(productNotFree);
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    const isSameProduct = () => {
        const filteredQty = products.filter((p) => p.status === "update" || p.status === "delete");
        if (filteredQty.length !== 0) {
            return false;
        }
        return true;
    };

    const isNotDeleteProduct = () => {
        const filteredQty = products.filter((p) => p.status !== "delete");
        if (filteredQty.length !== 0) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        getSummaryPrice.mutate(onParseData());
    }, [products, vouchers]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit Ext Sales Order"
                rightAddition={() => (
                    <Space>
                        {detailQuery.data?.order_status === C_STATUS_NEW && state.user?.account_id === ACCOUNT_ID_GNM && (
                            <Button
                                loading={readyToPickingMutation.isLoading}
                                onClick={onReadyToPickingHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                Ready To Picking
                            </Button>
                        )}
                        <ModalEditSO title="Konfirmasi" onSubmit={onSaveHandler} isSameProduct={isSameProduct()}>
                            {(modal) => (
                                <Button
                                    loading={editMutation.isLoading}
                                    onClick={modal.openModal}
                                    type="default"
                                    className="!flex !items-center"
                                    disabled={isSameProduct() || isNotDeleteProduct()}
                                >
                                    <BiSave className="m-0 mr-2" />
                                    Save Edit
                                </Button>
                            )}
                        </ModalEditSO>
                    </Space>
                )}
            />

            <Form form={form} layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(stateDetail) => (
                            <>
                                <State.Data state={stateDetail}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 mb-4">
                                                        sales order no <br />
                                                        <span className=" m-0 font-semibold text-gray-700">{detailQuery.data?.doc_no}</span>
                                                    </p>
                                                    <p className="capitalize m-0">
                                                        Referensi No <br />
                                                        {detailQuery.data?.ref_id ? (
                                                            <RefModal
                                                                id={detailQuery.data?.ref_id}
                                                                docNo={detailQuery.data?.ref_no}
                                                                type={detailQuery.data.ref_type}
                                                            >
                                                                {(dt) => (
                                                                    <span
                                                                        onClick={dt.openModal}
                                                                        className="cursor-pointer !text-primary underline m-0"
                                                                    >
                                                                        {detailQuery.data?.ref_no || "-"}
                                                                    </span>
                                                                )}
                                                            </RefModal>
                                                        ) : (
                                                            <span className="underline m-0">-</span>
                                                        )}
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        total price <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Partner Name <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.partner_name || "-"}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 mt-4">Voucher</p>
                                                    <VoucherModal vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                                        {(dt) => (
                                                            <div className="w-full flex flex-col gap-1">
                                                                <Button
                                                                    onClick={dt.openModal}
                                                                    disabled={vouchers.length >= 1}
                                                                    className="!w-full !flex !items-center !justify-between"
                                                                >
                                                                    Voucher <MdAdd />
                                                                </Button>
                                                                <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                                            </div>
                                                        )}
                                                    </VoucherModal>
                                                    <p className="capitalize m-0 text-gray-700 mt-4">
                                                        total discount <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        BP Name <br />
                                                        <span className="font-semibold m-0 text-gray-700">{detailQuery.data?.bp_name || "-"}</span>
                                                    </p>
                                                    <p className="capitalize m-0 mt-4">
                                                        note <br />
                                                        <span className="font-semibold m-0 text-gray-700">{detailQuery.data?.note || "-"}</span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        grand total <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Term Of Payment <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.payment_term_code || "-"}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 mt-4">
                                                        status <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {C_STATUS_ORDER.find((st) => st.value === detailQuery.data?.order_status)?.label}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </State.Data>
                                <State.Loading state={stateDetail}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
            </Form>

            {getSummaryPrice.isError ? <Alert type="error" message={(getSummaryPrice.error as any)?.message} /> : null}
            {/* <AdvanceSerachExt selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal} disabled={!watchBpId}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSerachExt> */}
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: (
                                <TableEdit
                                    list={allProducts}
                                    setList={setAllProducts}
                                    setProduct={setProducts}
                                    loading={getSummaryPrice.isLoading}
                                    onSetList={onEditProduct}
                                    removeItemList={onRemoveProduct}
                                    // action={detailQuery.data?.account_id === state.user?.account_id}
                                    actionAddition={(record: DetailProduct) =>
                                        record.status === "delete" ? (
                                            <Button
                                                onClick={onUndoRemoveProduct(record)}
                                                icon={<AiOutlineUndo className="mr-1" />}
                                                size="small"
                                                type="primary"
                                            >
                                                Undo
                                            </Button>
                                        ) : (
                                            ""
                                        )
                                    }
                                />
                            ),
                        },
                        {
                            label: `Version History`,
                            key: "2",
                            children: <VersionHistory id={id} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default ExtSalesOrderEdit;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */

import { Icon } from "@mui/material";
import { Button, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import roleMenuService, { ListMenu } from "services/api-endpoints/dashboard/master-data/role-menu";

export type ModalAddRoleSubMenuProps = ModalProps & {
    onSubmit?: (menu: ListMenu | undefined) => void;
    keyMenu?: any;
};

const columns: ColumnsType<ListMenu> = [
    {
        title: "Menu name",
        dataIndex: "name",
    },
    {
        title: "Menu key",
        dataIndex: "key",
    },
    {
        title: "Icon",
        dataIndex: "icon",
        render: (val) => (
            <div className="flex items-center gap-2">
                {val && <Icon fontSize="medium">{val}</Icon>}
                {val}
            </div>
        ),
    },
];

const ModalAddRoleSubMenu = ({ children, onSubmit, keyMenu }: ModalAddRoleSubMenuProps) => {
    const [picked, setPicked] = useState<ListMenu | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const getMenuQuery = useQuery(
        [roleMenuService.getSubMenu, keyMenu],
        async () => {
            return (await roleMenuService.GetSubMenu({ key: keyMenu })).data.data;
        },
        {
            enabled: open,
        }
    );

    const options = getMenuQuery.data?.map((el) => ({ value: el.key, label: el.name })) || [];

    const onSelect = (key: string) => {
        setPicked(getMenuQuery.data?.find((el) => el.key === key));
    };

    const onClickSubmit = () => {
        if (onSubmit) {
            onSubmit(picked);
            setPicked(undefined);
            closeRef.current?.click();
        }
    };

    const isModalOpenFn = (val: boolean) => {
        setOpen(val);
    };

    return (
        <ModalTemplate isModalOpenFn={isModalOpenFn} title="Choose sub menu" width={700} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <div className="flex flex-col gap-2">
                        <p>Choose menu</p>
                        <Select
                            loading={getMenuQuery.isLoading}
                            value={picked?.key}
                            onSelect={onSelect}
                            id="pick"
                            options={options}
                            placeholder="Choose menu"
                            showSearch
                        />
                        <Table size="small" columns={columns as any} dataSource={picked ? [picked] : []} className="w-full" pagination={false} />
                        <div className="w-full flex justify-end">
                            <Button onClick={onClickSubmit} type="primary">
                                Save
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddRoleSubMenu;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterManufacturingOrder from "modules/manufacturing-order/index/filter";
import TablePOMIndex from "modules/purchasing-order-m/table-pom-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingOrderMService from "services/api-endpoints/dashboard/master-data/purchasing-order-m";

const filter = ["start_date", "end_date", "doc_no"];

const PurchasingOrderM = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([purchasingOrderMService.list, page, searchValue, isSearch, filterValue, isFilter], async () => {
        return (
            await purchasingOrderMService.List({
                doc_no: filterValue?.doc_no || searchValue?.query,
                start_date: filterValue?.start_date,
                end_date: filterValue?.end_date,
                page,
            })
        ).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterManufacturingOrder filter={filter}>
                {(dt) => <Toolbar placeholder="Doc No..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterManufacturingOrder>
            <TablePOMIndex fetcher={getList} />
        </div>
    );
};

export default PurchasingOrderM;

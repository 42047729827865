import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { DetailExtReturnOrder } from "services/api-endpoints/dashboard/master-data/ext-return-order";
import { STOCK_ACTION } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const TableView = <T extends DetailExtReturnOrder>({ fetcher }: Props<T>) => {
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product owner",
            dataIndex: "product_owner",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty Request",
            dataIndex: "qty_return",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Locator",
            dataIndex: "area_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Stock action",
            dataIndex: "stock_action",
            render: (text) => <p className="capitalize m-0">{STOCK_ACTION.find((act) => act.value === text)?.label || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={(fetcher.data?.list_detail as any[]) || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default TableView;

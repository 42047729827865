import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import moment from "moment";
import React from "react";
import { FORMAT_DATE_1, RETURN_ORDER_STATUS, STATUS_SUBMIT } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterReturnOrder = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Filter Return Order"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Return No"
                            value={dt.values.return_no}
                            name="return_no"
                            onChange={(e) => dt.setOneValue("return_no", e.target.value)}
                        />
                        <Input
                            placeholder="Order Reference no"
                            value={dt.values.order_reference_no}
                            name="order_reference_no"
                            onChange={(e) => dt.setOneValue("order_reference_no", e.target.value)}
                        />
                        <Select
                            allowClear
                            value={dt.values.status !== null && dt.values.status !== undefined ? Number(dt.values.status) : dt.values.status}
                            placeholder="Status"
                            options={RETURN_ORDER_STATUS}
                            onChange={(value) => dt.setOneValue("status", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterReturnOrder;

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoryDutyTrip } from "services/api-endpoints/hadir-app/hadir-app-service";
import { EDIT_PATH, STATUS_ACTIVE_DUTY_TRIP } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const MasterCategoryDutyTripTable = <T extends CategoryDutyTrip[]>({ fetcher }: Props<T>) => {
    const navigate = useNavigate();
    const location = useLocation();

    // const editHandler = (data: CategoryDutyTrip) => {
    //     return () => {
    //         navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    //     };
    // };

    const columns: ColumnsType<CategoryDutyTrip> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (val: number) => STATUS_ACTIVE_DUTY_TRIP.find((el) => el.value === val)?.label,
        },
        {
            title: "Type",
            dataIndex: "type_name",
        },
        // {
        //     width: "100px",
        //     title: "Action",
        //     key: "action",
        //     fixed: "right",
        //     render: (_, record) => <ButtonAccessEdit onClick={editHandler(record)} type="text" />,
        // },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default MasterCategoryDutyTripTable;

import { Button, ButtonProps } from "antd";
import { StateContext } from "context/state";
import React, { useContext } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";

type Props = ButtonProps;

export default function ButtonAccessDelete({ children, ...props }: Props) {
    const { deleteAccess } = useContext(StateContext);

    if (!deleteAccess) {
        return null;
    }

    return (
        <Button {...props}>
            <MdOutlineDeleteOutline className="text-lg text-red-400" />
        </Button>
    );
}

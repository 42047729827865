import { Alert, Skeleton } from "antd";
import State from "components/common/state";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { MostProductValue as MostProductValueType } from "services/api-endpoints/dashboard-giias/report";
import { TYPE_POS_OFFLINE } from "utils/constant";
import ModalInvoiceMostValue from "./modal-invoice-most-value";

const ProductItem = ({ item, ...props }: React.HTMLAttributes<HTMLButtonElement> & { item: MostProductValueType }) => {
    return (
        <button
            id={`most-product-value-${item.product_id}`}
            type="button"
            className="m-0 capitalize pt-4 text-gray-700 text-xs font-bold w-full bg-transparent border-none text-start cursor-pointer hover:bg-gray-100"
            {...props}
        >
            <div className="flex w-full items-start justify-between">
                <span className="w-[80%]">{item.product_name}</span>
                <span>{item.product_qty} Item</span>
            </div>
            <span className="capitalize font-medium text-gray-400">
                @{item.product_price?.ToIndCurrency("Rp")} | {item.product_value?.ToIndCurrency("Rp")}
            </span>
            <div className="w-full bg-gray-200 mt-4" style={{ height: 1 }} />
        </button>
    );
};

const MostProductValue = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const watchParams = [startDate, endDate, typeSales];

    const topTenQuery = useQuery([reportService.getMostProductValue, ...watchParams], async () => {
        return (await reportService.GetMostProductValue({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
    });

    return (
        <State data={topTenQuery.data} isLoading={topTenQuery.isLoading} isError={topTenQuery.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        <div className="w-full h-[350px] 2xl:h-[430px] overflow-auto pr-3">
                            {topTenQuery.data?.map((item) => (
                                <ModalInvoiceMostValue item={item} key={item.product_id}>
                                    {(ctrl) => <ProductItem item={item} onClick={ctrl.openModal} />}
                                </ModalInvoiceMostValue>
                            ))}
                        </div>
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton paragraph={{ rows: 3 }} active />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(topTenQuery.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default MostProductValue;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import BPDeliveryOrderTable from "modules/dashboard-jsl/bp-delovery-order/bp-do-table";
import FilterBPDeliveryOrder from "modules/dashboard-jsl/bp-delovery-order/filter";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpDeliveryOrderService from "services/api-endpoints/dashboard/master-data/bp-delivery-order";

const filter = ["do_no", "inv", "bp_id"];

const BPDeliveryOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bpDeliveryOrderService.getAll, page, searchValue, isSearch, isFilter, filterValue], async () => {
        // if (isSearch) return (await deliveryOrderService.Search({ page, query: searchValue.query })).data.data;
        // if (isFilter) return (await deliveryOrderService.Filter(filterValue as any)).data.data;
        return (await bpDeliveryOrderService.GetAll({ ...filterValue, page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBPDeliveryOrder filter={filter}>
                {(dt) => <Toolbar placeholder="BP Delivery Order NO" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBPDeliveryOrder>
            <BPDeliveryOrderTable fetcher={getList} />
        </div>
    );
};

export default BPDeliveryOrder;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface Product {
    id: number;
    item: string;
    product_code?: string;
}

export interface ProductWithMinQty extends Product {
    min_qty_order: number | null | undefined;
}

export interface ProductWithQty extends Product {
    qty?: number | null | undefined;
}

export interface ProductList {
    list_product: Product[];
}

export interface ArrayProduct {
    id: number;
    item: string;
    product_code?: string;
    min_qty_order: number | null | undefined;
}

export interface ArrayRegion {
    province_id: number;
    province_name: string;
    city_id: number;
    city_name: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
}

export interface CreateVoucher {
    voucher_code: string;
    priority: number;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    customer_type: number;
    array_user: User[];
    array_region: ArrayRegion[];
    product_type: number;
    max_coupon: string;
    logic: number;
    usage_coupon_per_user: number;
    array_product: ArrayProduct[];
    impact_name: number;
    impact_category: number;
    min_trx: number | string;
    max_discount: number | string;
    amount: number | string;
    is_multiple: number;
    free_product: ProductWithQty[];
    image_path: string;
}

export interface DetailVoucher extends CreateVoucher {
    id: number;
}

export interface Province {
    province_id: number;
    province_name: string;
}
export interface ProvinceList {
    list: Province[];
}

export interface UserList {
    list: User[];
}

export interface City {
    province_id: number;
    province_name: string;
    city_id: number;
    city_name: string;
}

export interface ProducType {
    id: number;
    name: string;
}

class VoucherService extends BaseService {
    getAll = "/voucher/all-voucher";

    search = "/voucher/search-voucher";

    create = "/voucher/add-voucher";

    getDataCustomer = "/voucher/data-customer";

    getCity = "/voucher/get-city";

    getProductType = "/voucher/get-product-type";

    getProduct = "/voucher/get-product";

    detail = "/voucher/detail-voucher";

    constructor() {
        super();
    }

    Detail<T extends DetailVoucher>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    GetProduct<T extends ProductList>(params: { product_type: number; id: number; search: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
                config: { params },
            });
            return req;
        });
    }

    GetProductType<T extends ProducType[]>(params: { product_type: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductType,
                config: { params },
            });
            return req;
        });
    }

    GetCity<T extends City[]>(params: { province_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCity,
                config: { params },
            });
            return req;
        });
    }

    GetUser<T extends UserList>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDataCustomer,
                config: {
                    params: {
                        customer_type: 1,
                    },
                },
            });
            return req;
        });
    }

    GetProvince<T extends ProvinceList>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDataCustomer,
                config: {
                    params: {
                        customer_type: 2,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateVoucher, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataStyle>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataStyle>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const voucherService = new VoucherService();
export default voucherService;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { AnimatePresence, motion } from "framer-motion";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes, backgroundColorManufacture } from "modules/manufacture/utils/constant";
import { Entity } from "modules/manufacture/utils/models";
import React from "react";
import { useDrop } from "react-dnd";
import { CiTrash } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import ListMaterial from "./list-material";
import ListModel from "./list-model";
import ListOperator from "./list-operator";
import ListProcess from "./list-process";
import ListVariable from "./list-variable";
import { clsx } from "clsx";

const ListOption = () => {
    const { menu, isMenuOpen, entities, deleteEntity, setIsMenuOpen } = React.useContext(ManufactureContext) as any;
    const activeMenu = Object.keys(MenuTypes)
        .map((key) => MenuTypes[key as keyof typeof MenuTypes])
        .find((item) => item.id === menu);

    const closeOptionMenu = () => {
        setIsMenuOpen(false);
    };

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: [MenuTypes.subProcess.type, MenuTypes.multipleOperator.type, MenuTypes.process.type],
            collect: (monitor) => ({
                isOver: !!monitor.isOver() && !!(monitor.getItem() as Entity)?.idEntity,
            }),
            drop(item: any) {
                if (!item?.idEntity) return;
                deleteEntity(item);
            },
        }),
        [menu, entities]
    );

    return (
        <AnimatePresence>
            {isMenuOpen ? (
                <motion.div
                    ref={drop}
                    initial={{ y: "200px" }}
                    animate={{ y: "0" }}
                    exit={{ y: "200px" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className={clsx("w-full  flex items-center relative z-10 pointer-events-auto", backgroundColorManufacture.secondary)}
                >
                    <div
                        className={clsx(
                            "flex items-center justify-between w-fit p-3 absolute top-[-48px] left-4 rounded-tr-lg rounded-tl-lg",
                            backgroundColorManufacture.secondary
                        )}
                    >
                        <button
                            type="button"
                            title="close"
                            className="text-xl border-none cursor-pointer flex items-center bg-transparent justify-center"
                            onClick={closeOptionMenu}
                        >
                            <IoMdClose />
                        </button>
                        <h1 className="font-medium text-base m-0">{activeMenu?.text}</h1>
                    </div>
                    <div className="flex p-3 relative overflow-x-hidden w-full">
                        <ListProcess />
                        <ListMaterial />
                        <ListOperator />
                        <ListVariable />
                        <ListModel />
                    </div>
                    <AnimatePresence>
                        {isOver ? (
                            <motion.div className="w-full text-red-400 absolute h-full bg-white top-0 left-0 rounded p-3 flex items-center justify-center">
                                <CiTrash className="text-2xl mr-2" />
                                <p className="text-xl text-center m-0">Drop to delete entity</p>
                            </motion.div>
                        ) : null}
                    </AnimatePresence>
                </motion.div>
            ) : null}
        </AnimatePresence>
    );
};

export default ListOption;

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import React from "react";
import { STATUS_ACTIVE } from "utils/constant";
import { CreateDataModelType } from "../models";

export function withIsActive<T extends ControlledInputSelectProps<CreateDataModelType>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        return <Component {...props} optionFilterProp="children" options={STATUS_ACTIVE} />;
    };
}

const IsActiveSelect = withIsActive(ControlledSelectInput);

export default IsActiveSelect;

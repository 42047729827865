import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import OrganizationSelect from "modules/accounting/bank-account/lib/organization-select";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import bankAccountService, { CreateBankAccount, EditBankAccount } from "services/api-endpoints/accounting/bank-account";
import { STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<CreateBankAccount>> = yup.object().shape({
    name: yup.string().required("Name required"),
    description: yup.string(),
    account_no: yup.string().required("Account required"),
    organization_id: yup.number().required("Organization required"),
    is_active: yup.number().required("Status required"),
});

const BankAccountEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<CreateBankAccount>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [bankAccountService.detail, id],
        async () => {
            return (await bankAccountService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("name", data.name);
                setValue("description", data.description);
                setValue("account_no", data.account_no);
                setValue("organization_id", data.organization_id);
                setValue("is_active", data.is_active);
            },
        }
    );

    const editMutation = useMutation(
        [bankAccountService.edit],
        async (data: EditBankAccount) => {
            return (await bankAccountService.Edit(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Bank Account Edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        editMutation.mutate({
            id,
            ...data,
        });
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new bank account"
                rightAddition={() => (
                    <Space>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <div className="w-[50%]">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Card>
                                    <Form form={form} disabled={editMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">COA information</h1>
                                        <ControlledInputText allowClear control={control} name="name" placeholder="Name" />
                                        <ControlledInputText allowClear control={control} name="description" placeholder="Description" />
                                        <ControlledInputText allowClear control={control} name="account_no" placeholder="Account No" />
                                        <OrganizationSelect allowClear control={control} name="organization_id" placeholder="Organization" />
                                        <ControlledSelectInput
                                            allowClear
                                            control={control}
                                            name="is_active"
                                            placeholder="Is Active"
                                            optionFilterProp="children"
                                            options={STATUS_ACTIVE}
                                        />
                                    </Form>
                                </Card>
                            </State.Data>

                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
        </div>
    );
};

export default BankAccountEdit;

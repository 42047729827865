import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Form, Select, Space, message } from "antd";
import Card from "antd/lib/card/Card";
import { SelectOption } from "components/form/inputs/input-select";
import BukuBesarAkunTable from "modules/accounting/buku-besar/index/table";
import { bukuBesarTableToExcel } from "modules/accounting/buku-besar/utils";
import HistoryBankTable from "modules/accounting/history-bank/index/table";
import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDownload } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bukuBesarService, { BukuBesarList, FilterBukuBesar } from "services/api-endpoints/accounting/buku-besar";
import historyBankService, { FilterHistoryBank } from "services/api-endpoints/accounting/history-bank";
import paymentService from "services/api-endpoints/dashboard/payment";

import { FORMAT_DATE_1 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<FilterBukuBesar, "page">> = yup.object().shape({
    type_id: yup.number(),
    id: yup.number(),
    account_id: yup.number(),
    coa_id: yup.number().required("Coa Name Required"),
    bp_id: yup.number(),
    start_date: yup.string().required("Start Date Required"),
    end_date: yup.string().required("End Date Required"),
});

const HistoryBank = () => {
    const [form] = Form.useForm();
    const { setValue, watch } = useForm<FilterHistoryBank>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchCoaId = watch("coa_id");
    const watchStartDate = watch("start_date");
    const watchEndDate = watch("end_date");

    const getHistoryBank = useMutation(
        [historyBankService.historyBank],
        async () => {
            return (
                await historyBankService.HistoryBank({ coa_id: watchCoaId, start_date: watchStartDate, end_date: watchEndDate } as FilterHistoryBank)
            ).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Search Success");
            },
        }
    );

    const saldoAwal = () => {
        if ((getHistoryBank.data?.saldo_awal?.saldo || 0) === 0)
            return `${getHistoryBank.data?.saldo_awal?.note || ""} : ${(getHistoryBank.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")}`;
        if ((getHistoryBank.data?.saldo_awal?.saldo || 0) < 0)
            return `${getHistoryBank.data?.saldo_awal?.note || ""} : ${((getHistoryBank.data?.saldo_awal?.saldo || 0) * -1).ToIndCurrency("Rp")} (K)`;
        return `${getHistoryBank.data?.saldo_awal?.note || ""} : ${(getHistoryBank.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const total = () => {
        if ((getHistoryBank.data?.total?.saldo || 0) < 0)
            return `${getHistoryBank.data?.total?.note || ""} : ${((getHistoryBank.data?.total?.saldo || 0) * -1).ToIndCurrency("Rp")} (K)`;
        return `${getHistoryBank.data?.total?.note || ""} : ${(getHistoryBank.data?.total?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const getCoaList = useQuery([bukuBesarService.coaList], async () => {
        return (await bukuBesarService.CoaList()).data.data?.map(
            (el) => ({ label: `${el.coa_name} (${el.coa_code})`, value: el.coa_id } as SelectOption)
        );
    });

    const bankAccountQuery = useQuery([paymentService.getBA], async () => {
        return (await paymentService.GetBA()).data.data?.map((el) => ({ label: el.bank_account_no, value: el.coa_id } as SelectOption));
    });

    const onSearch = async () => {
        getHistoryBank.mutate();
    };

    const onDownload = () => {
        const BankAccount = bankAccountQuery.data?.find((el) => el.value === watchCoaId)?.label;
        const date = `${watchStartDate?.split("-").join("")}_${watchEndDate?.split("-").join("")}`;

        bukuBesarTableToExcel({
            title: `History Bank.${BankAccount}.${date}`,
            list: getHistoryBank.data?.list || [],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Card>
                <Form className="flex flex-col gap-5" form={form} layout="vertical">
                    <div className="grid grid-cols-3 gap-x-4 align-bottom mb-4">
                        <div>
                            <p className="mb-2">Bank Account</p>
                            <Select
                                className="w-full"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                                placeholder="Bank account"
                                loading={bankAccountQuery.isLoading}
                                options={bankAccountQuery.data}
                                onChange={(val) => {
                                    setValue("coa_id", val);
                                }}
                            />
                        </div>
                        <div>
                            <p className="mb-2">Date Range</p>
                            <DatePicker.RangePicker
                                className="w-full"
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    setValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    setValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div>

                        <div className="m-0 mt-7 flex items-center gap-4">
                            <Button
                                onClick={onSearch}
                                type="primary"
                                className="!flex !items-center"
                                disabled={!watchCoaId || !watchStartDate || !watchEndDate}
                                loading={getHistoryBank.isLoading}
                            >
                                <BiSearch className="m-0 mr-2" />
                                Search
                            </Button>
                            <Button onClick={onDownload} disabled={(getHistoryBank.data?.list || []).length === 0} className="w-fit">
                                <AiOutlineDownload className="m-0 mr-2" />
                                Download
                            </Button>
                        </div>
                    </div>
                </Form>
                {getHistoryBank.data?.saldo_awal ? (
                    <div className="grid grid-cols-5">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{saldoAwal()}</p>
                    </div>
                ) : null}
                <HistoryBankTable listData={getHistoryBank.data?.list || []} />
                {getHistoryBank.data?.total ? (
                    <div className="grid grid-cols-5 mt-4">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{total()}</p>
                    </div>
                ) : null}
            </Card>
        </div>
    );
};

export default HistoryBank;

/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React from "react";
import { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { TableBoxType } from "./table-box";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    boxes: TableBoxType[];
};

export function addTable<T extends ProductPOM>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, boxes, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<ProductPOM | null>(null);

        const columns: ColumnType<ProductPOM>[] = [
            {
                title: "No",
                dataIndex: "no",
                render: (t, r, i) => i + 1,
            },
            {
                title: "Product Code",
                dataIndex: "product_code1",
            },
            {
                title: "Quantity",
                dataIndex: "qty_sisa",
                ...{ editable: true },
                onCell: (record: ProductPOM) => {
                    const alreadyUseQty = boxes.reduce((totalUse: number, box) => {
                        const product = (box.detail as ProductPOM[]).find((a) => a.product_id === record.product_id);
                        return totalUse + (product?.qty_sisa || 0);
                    }, 0);

                    return {
                        className: (record.qty_sisa || 0) > (record?.qty || 0) - alreadyUseQty ? "text-red-400 qty-overflow" : "",
                    };
                },
            },
            {
                title: "Status",
                dataIndex: "status",
                render: (val, record: ProductPOM) => {
                    const alreadyUseQty = boxes.reduce((totalUse: number, box) => {
                        const product = (box.detail as ProductPOM[]).find((a) => a.product_id === record.product_id);
                        return totalUse + (product?.qty_sisa || 0);
                    }, 0);

                    return <p className={alreadyUseQty === record.qty ? "text-red-400 m-0" : "m-0"}>{`${alreadyUseQty}/${record.qty}`}</p>;
                },
            },
        ];

        const isEditing = (record: ProductPOM, edited: ProductPOM | null) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const findIndexSave = (record: ProductPOM, edited: ProductPOM) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const rowKey = (record: ProductPOM) => `${record.product_id}_${record?.product_id}`;

        const editInputType: EditTableProps<ProductPOM>["editInputType"] = { qty_sisa: "number" };

        const maxQty = boxes?.reduce((totalUse: number, box) => {
            const product = (box.detail as ProductPOM[]).find((a) => a.product_id === editRow?.product_id);
            return totalUse + (product?.qty_sisa || 0);
        }, 0);

        return (
            <Component
                {...props}
                bordered
                cellProps={{
                    minNumber: 1,
                    maxNumber: (editRow?.qty || 0) - maxQty,
                }}
                list={list as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const TableProductsPOM = addTable(EditTable);
export default TableProductsPOM;

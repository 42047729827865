/* eslint-disable react/no-children-prop */
import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ListStock, MaterialMovement } from "services/api-endpoints/dashboard/master-data/material-movement";
import { EDIT_PATH, FORMAT_DATE_TIME, MATERIAL_MOVEMENT_STATUS, VIEW_PATH } from "utils/constant";
import ButtonAccessView from "components/button/button-access-view";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};
const colorTag = ["grey", "blue", "green", "purple"];
const RoutineStockTable = <T extends ListStock>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: ListStock) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "WH From",
            dataIndex: "warehouse_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "WH To",
            dataIndex: "warehouse_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Move",
            dataIndex: "total_move",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => {
                const type = MATERIAL_MOVEMENT_STATUS.find((t) => t.value === Number(text));
                if (!type) return "";
                if (Number(text) === -1) return <Tag color="red">{type?.label}</Tag>;
                return <Tag color={colorTag[type?.value || 0]}>{type?.label}</Tag>;
            },
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{moment(text).format(FORMAT_DATE_TIME)}</p>,
        },
        {
            width: "80px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default RoutineStockTable;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Coa {
    id: number;
    parent_id: number | null;
    name_in: string;
    name_en: string;
    currency_name: string;
    type_name: string;
    is_active: number;
    account: string;
    children?: Coa[];
}

export interface Parent {
    parent_id: number;
    parent_name: string;
}

export interface Currency {
    currency_id: number;
    currency_name: string;
}

export interface AccType {
    type_id: number;
    type_name: string;
}

export interface CreateCoa {
    code: any;
    name_in: any;
    name_en: any;
    parent_id?: any | null;
    type_id: any;
    currency_id: any;
    is_active: any;
    current_balance?: string | null;
}

export interface EditCoa extends CreateCoa {
    id: number;
}

export interface DetailCoa {
    id: number;
    account: string;
    parent_id: number;
    name_in: string;
    name_en: string;
    currency_id: number;
    currency_name: string;
    type_id: number;
    type_name: string;
    is_active: number;
    current_balance?: number;
}

class MasterCoaService extends BaseService {
    getAll = "/accounting/coa/get-all-coa";

    getParent = "/accounting/get-parent";

    getCurrency = "/accounting/get-currency";

    getAccType = "/accounting/get-acc-type";

    createCoa = "/accounting/coa/create-coa";

    detailCoa = "/accounting/coa/get-detail-coa";

    editCoa = "/accounting/coa/edit-coa";

    constructor() {
        super();
    }

    EditCoa<T extends any>(data: EditCoa) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editCoa,
                data,
            });
            return req;
        });
    }

    DetailCoa<T extends DetailCoa>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailCoa}/${params.id}`,
            });
            return req;
        });
    }

    GetParent<T extends Parent[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getParent,
            });
            return req;
        });
    }

    CreateCoa<T extends any>(data: CreateCoa) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createCoa,
                data,
            });
            return req;
        });
    }

    GetAccType<T extends AccType[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAccType,
            });
            return req;
        });
    }

    GetCurrency<T extends Currency[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCurrency,
            });
            return req;
        });
    }

    GetAll<T extends Coa>() {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
            });
            return req;
        });
    }
}

const masterCoaService = new MasterCoaService();
export default masterCoaService;

import Toolbar from "components/toolbar";
import TableIndex from "modules/master-data/external-order/ext-pl/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extPackingListService from "services/api-endpoints/dashboard/master-data/ext-packing-list";

const ExtPackingList = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const getList = useQuery([extPackingListService.listAll, page, size], async () => {
        return (await extPackingListService.ListAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default ExtPackingList;

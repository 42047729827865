/* eslint-disable no-shadow */
import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterReceipt from "modules/master-data/receipt/index/filter";
import ReceiptTable from "modules/master-data/receipt/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import receiptService, { ReceiptList } from "services/api-endpoints/dashboard/receipt";

const filter = ["doc_no", "start_date", "end_date", "account_id"];

const Receipt = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([receiptService.getListReceipt, page, filterValue], async () => {
        return (await receiptService.GetListReceipt(filterValue as any)).data.data;
    });

    const deleteMutation = useMutation(async (data: ReceiptList) => (await receiptService.DeleteReceipt({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<ReceiptList>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterReceipt filter={filter}>
                {(dt) => <Toolbar placeholder="Search Receipt Voucher" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterReceipt>
            <ReceiptTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default Receipt;

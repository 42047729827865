/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ChargeData {
    charge_id?: number;
    // charge_code?: string;
    charge_name?: string;
}

export interface BaseReceiptResponse<T = any> {
    list: T[];
    total_data: number;
    total_page: number;
    current_page: number;
}

export interface BankAccount {
    bank_id: number;
    bank_no: string;
}

export interface ExpansesData extends ChargeData {
    note?: any;
    amount?: any;
    bp_id?: any;
    bp_name?: any;
}

export interface ChildData {
    doc_id?: any;
    doc_no?: any;
    inv_ref?: any;
    grand_total?: any;
    description?: any;
    date_invoice?: any;
}

export interface CreateTransferBank {
    bank_coa_from?: any;
    bank_coa_to?: any;
    transaction_date?: any;
    expenses: ExpansesData[];
    bank_images: any[];
    amount?: any;
    total_amount?: any;
    note?: any;
}

export interface DraftTransferBank extends CreateTransferBank {
    id?: any;
}

export interface DetailTransferBank extends CreateTransferBank {
    id?: any;
    doc_no?: string;
    is_active?: number;
}

export interface TransferBankList {
    id?: number;
    doc_no?: string;
    bank_coa_from?: number;
    bank_from?: string;
    bank_coa_to?: number;
    bank_to?: string;
    transaction_date?: number;
    created_at?: number;
    total_amount?: number;
    is_active?: number;
}

export interface FilterTransferBank {
    doc_no: string;
    start_date: string;
    end_date: string;
    page: number | string;
}

class TransferBankService extends BaseService {
    getCharge = "/receipt-voucher/get-charge";

    bankAccount = "/bank-transfer/get-bank-account";

    getListTransferBank = "/bank-transfer/get-bank-transfer";

    createTransferBank = "/bank-transfer/create";

    draftTransferBank = "/bank-transfer/save-draft";

    detailTransferBank = "/bank-transfer/detail";

    deleteReceipt = "/receipt-voucher/delete-receipt-voucher";

    constructor() {
        super();
    }

    DeleteReceipt<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteReceipt}/${id}`,
            });
            return req;
        });
    }

    CreateTransferBank<T extends any>(data: CreateTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createTransferBank,
                data,
                config,
            });
            return req;
        });
    }

    DraftTransferBank<T extends any>(data: CreateTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftTransferBank,
                data,
                config,
            });
            return req;
        });
    }

    SaveCreateTransferBank<T extends any>(data: DraftTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createTransferBank,
                data,
                config,
            });
            return req;
        });
    }

    SaveDraftTransferBank<T extends any>(data: DraftTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftTransferBank,
                data,
                config,
            });
            return req;
        });
    }

    DetailTransferBank<T extends DetailTransferBank>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailTransferBank,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetListTransferBank<T extends TransferBankList>(param: FilterTransferBank) {
        return this.ProxyRequest<BaseReceiptResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseReceiptResponse<T>>({
                url: this.getListTransferBank,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetCharge<T extends ChargeData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCharge,
            });
            return req;
        });
    }

    GetBankAccount<T extends BankAccount[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.bankAccount,
            });
            return req;
        });
    }
}

const transferBankService = new TransferBankService();
export default transferBankService;

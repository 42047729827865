import { Image, Skeleton } from "antd";
import React, { useState } from "react";
import { ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { IMAGE_FALLBACK } from "utils/constant";

import Placeholder from "assets/images/placeholder.png";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    data: ProductSub;
};

const Loading = () => {
    return (
        <div className="rounded-lg bg-white border-solid border border-gray-300 overflow-hidden h-full">
            <div className="w-full h-[150px] bg-gray-200" />
            <Skeleton active paragraph={{ rows: 3 }} className="mt-4 mx-4" />
        </div>
    );
};

const ProductCard = ({ data, ...props }: Props) => {
    const [img, setImg] = useState(data?.product_images ? data.product_images[0] : Placeholder);

    const onErrorImage = () => {
        setImg(Placeholder);
    };

    return (
        <button {...props} type="button" className="p-0 border-none text-start cursor-pointer bg-white">
            <div className="rounded-lg bg-white border-solid border border-gray-300 overflow-hidden h-full">
                <Image
                    preview={false}
                    height={200}
                    width="100%"
                    src={img}
                    onError={onErrorImage}
                    className="object-cover w-full"
                    fallback={IMAGE_FALLBACK}
                    alt={data.product_name}
                />
                <div className="p-2 flex flex-col justify-between">
                    <div className="">
                        <p className="uppercase text-primary m-0 leading-4 text-xs">{data.product_code}</p>
                        <p className="m-0 text-gray-500 capitalize font-medium leading-4">{data.product_name}</p>
                    </div>
                    <div className="mt-4">
                        <p className="m-0 capitalize text-gray-400 leading-4 text-xs">harga eceran tertinggi</p>
                        <p className="m-0 font-semibold text-gray-600 leading-4">{data?.product_price?.ToIndCurrency("Rp")}</p>
                    </div>
                </div>
            </div>
        </button>
    );
};

ProductCard.Loading = Loading;

export default ProductCard;

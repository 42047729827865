/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import { Button, Select, Skeleton, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import BomModalAdd from "modules/bom/modal-add";
import AddBomTable from "modules/bom/table";
import React from "react";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import bomService, { BomExample, CreateBOM } from "services/api-endpoints/dashboard/manufacture/bom";

const BomEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [selectedCode, setSelectedCode] = React.useState<number>();
    const [list, setList] = React.useState<BomExample[]>([]);

    const detailQuery = useQuery(
        [bomService.detail, id],
        async () => {
            return (await bomService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setSelectedCode(data.id);
                const temp = data.bom.map((val) =>
                    !val.child?.length ? { ...val } : { ...val, id: new Date().getTime(), logic: { logic_name: "OR Logic" }, hideEditAction: true }
                );
                setList(temp);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const getProductCode = useQuery([bomService.getProductCode], async () => {
        return (await bomService.GetProductCode({ type: "3" })).data.data;
    });

    const codeOptions = getProductCode.data?.map((el) => ({ label: el.product_code, value: el.id } as SelectOption));

    const createMutation = useMutation([bomService.create], async (data: CreateBOM) => {
        return (await bomService.Create(data)).data.data;
    });

    const onSubmitList = (ls: BomExample[], asChild: boolean) => {
        setList((prev) => {
            if (asChild) {
                return [
                    ...prev,
                    {
                        id: new Date().getTime(),
                        logic: { logic_name: "OR Logic" },
                        hideEditAction: true,
                        child: ls,
                    },
                ];
            }
            const duplicateProducts = ls.filter((el) => prev.find((prevEl) => prevEl.id === el.id));
            const merge = [...prev, ...ls.filter((el) => !duplicateProducts.find((dupEl) => dupEl.id === el.id))];
            return merge.map((el) => {
                const dupProduct = duplicateProducts.find((dupEl) => dupEl.id === el.id);
                if (!dupProduct) return el;
                return {
                    ...el,
                    qty: el.qty + dupProduct.qty,
                };
            });
        });
    };

    const onRemoveList = (row: BomExample) => {
        setList((prev) => prev.filter((el) => el.id !== row.id));
    };

    const onRemoveListChild = (record: BomExample) => {
        return (row: BomExample) => {
            if (record.child?.length === 1 && row.id === record.child[0].id) {
                setList((prev) => prev.filter((el) => el.id !== record.id));
                return;
            }
            setList((prev) =>
                prev.map((el) => {
                    if (el.id !== record.id) return el;
                    return {
                        ...el,
                        child: el.child?.filter((cld) => cld.id !== row.id),
                    };
                })
            );
        };
    };

    const onSetListChild = (record: BomExample) => {
        return (ls: BomExample[]) => {
            setList((prev) =>
                prev.map((el) => {
                    if (el.id !== record.id) return el;
                    return {
                        ...el,
                        child: ls,
                    };
                })
            );
        };
    };

    const onCreate = () => {
        const parse: CreateBOM = {
            id: selectedCode,
            bom: list.map((el) => {
                if (el.logic) {
                    return {
                        child: el.child?.map((cl) => {
                            return {
                                id: cl.id,
                                qty: cl.qty,
                            };
                        }),
                    };
                }
                return {
                    id: el.id,
                    qty: el.qty,
                };
            }) as any,
        };
        if (!parse.id) {
            message.error("Code required!");
            return;
        }
        createMutation.mutateAsync(parse).then(() => {
            message.success("Create Assembling Success");
            navigate(-1);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit Bom"
                rightAddition={() => (
                    <>
                        <Button onClick={onCreate} disabled={!list.length} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <ToolbarAction
                title="Code :"
                leftAddition={() => (
                    <Select
                        loading={getProductCode.isLoading}
                        className="w-[300px]"
                        size="middle"
                        placeholder="Code"
                        optionFilterProp="label"
                        allowClear
                        options={codeOptions || []}
                        showSearch
                        value={selectedCode}
                        onChange={(val) => setSelectedCode(val)}
                        status={!selectedCode ? "error" : undefined}
                    />
                )}
                rightAddition={() => (
                    <BomModalAdd canAsChild onSubmitList={onSubmitList}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} type="primary" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Add
                            </Button>
                        )}
                    </BomModalAdd>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <AddBomTable
                                removeItemList={onRemoveList}
                                expandable={{
                                    expandedRowRender: (rec: BomExample) => {
                                        return (
                                            <div className="flex flex-row pl-8 items-stretch opacity-80">
                                                <div className="h-auto w-[5px] bg-blue-400 rounded" />
                                                <div className="flex gap-1 flex-col flex-1">
                                                    <AddBomTable
                                                        removeItemList={onRemoveListChild(rec)}
                                                        footer={() => (
                                                            <p className="text-red-400">In {rec.logic?.logic_name} you can only choose one item *</p>
                                                        )}
                                                        list={rec?.child || []}
                                                        setList={onSetListChild(rec) as any}
                                                        pagination={false}
                                                        className="flex-1"
                                                    />
                                                </div>
                                            </div>
                                        );
                                    },
                                    rowExpandable: (rec: BomExample) => !!rec.logic,
                                }}
                                list={list}
                                setList={setList}
                            />
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

export default BomEdit;

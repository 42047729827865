import { Button, ButtonProps } from "antd";
import { StateContext } from "context/state";
import React, { useContext } from "react";
import { AiOutlinePlus } from "react-icons/ai";

type Props = ButtonProps;

export default function ButtonAccessAdd({ children, ...props }: Props) {
    const { createAccess } = useContext(StateContext);

    if (!createAccess) {
        return null;
    }

    return (
        <Button {...props}>
            <AiOutlinePlus className="mr-3" />
            {children || "Add"}
        </Button>
    );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { message, Select } from "antd";
import { Product, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import extSalesOrderService from "services/api-endpoints/dashboard/master-data/ext-sales-order";

type SelectOptionCustom = SelectOption & Product;

type Props = {
    onSelectProduct?: (data: Product) => void;
    onDeSelectProduct?: (data: Product) => void;
    selected: Product[];
};

const createKey = (data: Product) => `${data.product_name}~${data?.product_code1 || (data as any)?.product_code}~${data.product_id}`;
const getProductId = (key: string) => key.split("~")[key.split("~").length - 1];

const GetAllProduct = ({ onSelectProduct, onDeSelectProduct, selected }: Props) => {
    const getAllProduct = useQuery(
        [extSalesOrderService.productPrice],
        async () => {
            return (await extSalesOrderService.ProductPrice()).data.data
                ?.filter((v, i, a) => a.findIndex((v2) => v2.product_id === v.product_id) === i)
                ?.map((el) => ({ ...el, label: el.product_name, value: el.product_id } as SelectOptionCustom));
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        }
    );

    const getProduct = (key: string) => {
        const product = [...(getAllProduct.data || [])].find((p) => p.product_id === Number(getProductId(key))) as Product;
        return product;
    };

    const onSelect = (values: string) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Product not found");
            return;
        }
        if (onSelectProduct) onSelectProduct(product);
    };

    const onDeSelect = (values: string) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Cannot find product id");
            return;
        }
        if (onDeSelectProduct) onDeSelectProduct(product);
    };

    const OptionsMemoize = React.useCallback(
        () =>
            getAllProduct.data?.map((option) => {
                return (
                    <Select.Option value={createKey(option)} key={option.value}>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                            {option.label}
                            <br />
                            <span style={{ margin: 0, fontSize: "9px", fontWeight: "bold" }}>
                                {option.product_code1} | {option.product_price?.ToIndCurrency("Rp")}
                            </span>
                        </p>
                    </Select.Option>
                );
            }),
        [getAllProduct.data]
    );

    const SelectMemoize = React.useMemo(
        () => (
            <Select
                value={selected.map((p) => createKey(p))}
                bordered
                disabled={getAllProduct.isLoading}
                mode="multiple"
                className="!w-full"
                showSearch
                loading={getAllProduct.isLoading}
                placeholder="Search Product"
                onSelect={onSelect}
                onDeselect={onDeSelect}
            >
                {OptionsMemoize()}
            </Select>
        ),
        [getAllProduct.data, selected]
    );

    return <React.Suspense fallback={<p className="m-0">Loading...</p>}>{SelectMemoize}</React.Suspense>;
};

export default React.memo(GetAllProduct);

import React from "react";
import { Button, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { DetailPL } from "services/api-endpoints/dashboard-jsl/delivery-order";

type Props<T> = {
    list: T[];
    removeItemList: (index: T) => void;
};

const ProductTable = <T extends DetailPL>({ list, removeItemList }: Props<T>) => {
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "SO NO",
            dataIndex: "so_no",
            render: (text) => <p className="capitalize m-0">{text || "FREE"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Price",
            dataIndex: "product_price",
            render: (text) => <p className="capitalize m-0">{Number(text).ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        // {
        //     width: "70px",
        //     title: "Action",
        //     key: "action",
        //     fixed: "right",
        //     render: (_, record) => (
        //         <Space size="middle" direction="horizontal">
        //             <Button onClick={() => removeItemList(record)} type="text" danger>
        //                 <MdOutlineDeleteOutline className="text-lg" />
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={{
                // pageSize: 10000000, // [WARNING] page dibuat panjang, karena tidak ada yg bisa dijadikan unik id
                pageSize: 10,
                total: list.length,
                showSizeChanger: false,
            }}
        />
    );
};

export default ProductTable;

import React from "react";
import { Button, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { HiPlus } from "react-icons/hi2";
import { TDataProduct } from "modules/master-data/product/models";
import { AiOutlineMinus } from "react-icons/ai";
import { TDataProductPrint } from "./models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    productsToPrint: T[];
    removeProductToPrint: (dt: T) => void;
    addProductToPrint: (dt: TDataProductPrint) => void;
    className?: string;
};

const BarcodeProductTable = React.memo(
    <T extends TDataProduct>({ fetcher, className, productsToPrint, removeProductToPrint, addProductToPrint }: Props<T>) => {
        const [searchParams, setSearchParams] = useSearchParams();

        const handleTableChange = (pagination: TablePaginationConfig) => {
            searchParams.set("page", pagination.current?.toString() || "1");
            setSearchParams(searchParams);
        };

        const addHandler = (dt: T) => {
            addProductToPrint({ ...dt, qty: 1 });
        };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Code",
                dataIndex: "code1",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Molding Code",
                dataIndex: "molding_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Model Name",
                dataIndex: "model_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                width: "100px",
                title: "Add Print",
                key: "action",
                fixed: "right",
                render: (_, record) => (
                    <div className="w-full flex items-center justify-center">
                        {productsToPrint.find((p) => p.id === record.id) ? (
                            <Button onClick={() => removeProductToPrint(record)} type="text" danger>
                                <AiOutlineMinus className="text-lg" />
                            </Button>
                        ) : (
                            <Button onClick={() => addHandler(record)} type="text">
                                <HiPlus className="text-lg" />
                            </Button>
                        )}
                    </div>
                ),
            },
        ];

        return (
            <Table
                rowKey={(record) => record.id!}
                scroll={{ x: 1000 }}
                size="small"
                loading={fetcher.isLoading}
                columns={columns}
                dataSource={fetcher.data?.list || []}
                className={className}
                pagination={{
                    current: fetcher.data?.current_page || 1,
                    pageSize: 10,
                    total: fetcher.data?.total_data || 0,
                    showSizeChanger: false,
                }}
                onChange={handleTableChange}
            />
        );
    }
);

export default BarcodeProductTable;

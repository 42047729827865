import TableTransfer from "components/table/transfer-doc-edit-table";
import { SelectOptionCustom } from "modules/master-data/price/lib/warehouse-select";
import React from "react";
import { UseQueryResult } from "react-query";
import { ProductTransfer, ProductTransferWithEdit } from "services/api-endpoints/dashboard/master-data/material-movement";
import TableTransferEdit from "./table-direct-edit";
import { message } from "antd";

export interface Props<T> {
    locatorFetcher: UseQueryResult<SelectOptionCustom[], unknown>;
    products?: ProductTransferWithEdit[];
    setProducts?: React.Dispatch<React.SetStateAction<ProductTransferWithEdit[]>>;
    setTargetKeys: React.Dispatch<React.SetStateAction<any[]>>;
    titles?: string[];
    loading?: boolean;
    targetKeys: string[];
}

const TableTransferProduct = <T extends ProductTransfer>({
    products,
    loading,
    setProducts,
    targetKeys,
    setTargetKeys,
    locatorFetcher,
    titles,
}: Props<T>) => {
    const onChange = (nextTargetKeys: string[]) => {
        const productSelected = products?.filter((p) => nextTargetKeys.includes(p.id as any));
        const productFilter = productSelected?.filter((p1, index) => productSelected.findIndex((p2) => p2.product_id === p1.product_id) === index);
        if (productSelected?.length !== productFilter?.length) {
            message.error("Tidak Boleh.! Terdapat Product Yang Sama");
            return;
        }
        setTargetKeys(nextTargetKeys);
    };

    const onEditChange = (record: T) => {
        if (setProducts) {
            setProducts((prev) => prev?.map((el) => (el?.id === record?.id ? record : el)));
        }
    };

    const getLocatorTo = (item: any) => {
        return locatorFetcher?.data?.find((loc) => loc?.value === item?.locator_to || loc?.label === item?.locator_to)?.label || "";
    };

    return (
        <TableTransfer
            titles={titles}
            rowKey={(record) => record?.id as any}
            dataSource={products || []}
            targetKeys={targetKeys}
            showSearch
            loading={loading}
            onChange={onChange}
            filterOption={(inputValue, item) =>
                item?.product_name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1 ||
                item?.product_code?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
            }
        >
            {({ filteredItems, rowSelection }) => {
                const tFiltered = filteredItems?.map((el: any) => ({
                    ...el,
                    locator_to: getLocatorTo(el),
                }));
                return (
                    <TableTransferEdit
                        locatorFetcher={locatorFetcher}
                        onEditChange={onEditChange}
                        dataSource={tFiltered}
                        rowSelection={rowSelection}
                        loading={loading}
                    />
                );
            }}
        </TableTransfer>
    );
};

export default TableTransferProduct;

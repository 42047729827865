/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { Entity, MaterialEntity, VariableEntity } from "../utils/models";
import { MIN_TOTAL_ITEM } from "../utils/constant";
import { motion, useAnimate } from "framer-motion";

export type TotalItemInputProps = {
    entity?: Entity<MaterialEntity & VariableEntity>;
    onChange?: (total: number) => void;
};

const TotalItemInput = ({ entity, onChange }: TotalItemInputProps) => {
    const [value, setValue] = useState<any>(entity?.data?.total || MIN_TOTAL_ITEM);
    const [scope, animate] = useAnimate();

    useEffect(() => {
        setValue(entity?.data?.total || MIN_TOTAL_ITEM);
        if (entity?.data?.temp_total) {
            animate(scope.current, { width: "200%" }, { delay: 0.3 }).then(() => {
                if (!scope.current) return;
                animate(scope.current, { width: "100%" }, { delay: 1 }).then(() => {
                    if (!entity.data?.temp_total) return;
                    if (onChange) {
                        onChange((entity.data!.total || MIN_TOTAL_ITEM) + entity.data!.temp_total!);
                    }
                });
            });
        }
    }, [entity]);

    const onKeyDown = (event: any) => {
        if (isNaN(event.key) && event.key !== "Backspace" && event.key !== "Tab" && event.key !== "Shift" && event.key !== "Enter") {
            event.preventDefault();
        }
    };

    const onChangeInput = (event: any) => {
        setValue(Number(event.target.value));
    };

    const onBlur = (event: any) => {
        if (!event.target.value.trim() || event.target.value === "0") {
            setValue(MIN_TOTAL_ITEM);
            if (onChange) onChange(MIN_TOTAL_ITEM);
            return;
        }
        if (onChange) onChange(Number(event.target.value));
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { value } = event.currentTarget.elements[0] as HTMLInputElement;
        if (!value.trim() || value === "0") {
            setValue(MIN_TOTAL_ITEM);
            if (onChange) onChange(MIN_TOTAL_ITEM);
            return;
        }
        if (onChange) onChange(Number(value));
    };

    return (
        <form className="relative" onSubmit={onSubmit}>
            <motion.input
                name="input"
                size={1}
                maxLength={3}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={onKeyDown}
                onChange={onChangeInput}
                onBlur={onBlur}
                value={value}
                className="bg-white border-none text-xs rounded-full text-center text-gray-700 focus:outline-none active:outline-none relative z-10"
            />
            <motion.div
                ref={scope}
                className="h-[95%] w-full text-2xs bg-gray-300 overflow-hidden whitespace-pre text-black absolute top-[5%] left-0 rounded-full flex justify-end items-center px-2"
            >
                +{entity?.data?.temp_total || ""}
            </motion.div>
        </form>
    );
};

export default TotalItemInput;

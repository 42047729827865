import { ColProps, Form, Input, InputProps, InputRef } from "antd";
import React, { forwardRef, Ref } from "react";

type TextInputProps = Omit<InputProps, "onChange" | "onBlur"> & {
    error: string | undefined;
    label: string;
    labelCol?: ColProps;
    rows?: number;
    cols?: number;
    initialValue?: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    classNameForm?: string;
};

const { TextArea } = Input;

const InputTextArea: React.FC<TextInputProps> = forwardRef(
    (
        {
            type = "text",
            placeholder,
            error,
            label,
            name,
            value,
            labelCol,
            initialValue,
            onChange,
            onBlur,
            allowClear,
            rows,
            cols,
            className,
            classNameForm,
            ...rest
        }: TextInputProps,
        ref: Ref<InputRef>
    ) => {
        return (
            <Form.Item
                label={label}
                name={name}
                labelCol={labelCol}
                validateStatus={error ? "error" : ""}
                help={error && error}
                initialValue={initialValue}
                className={`!w-full ${classNameForm}`}
            >
                <TextArea
                    rows={rows || undefined}
                    cols={cols || undefined}
                    placeholder={placeholder}
                    value={value || ""}
                    onChange={onChange}
                    name={name}
                    onBlur={onBlur}
                    ref={ref}
                    className={`!w-full ${className}`}
                />
            </Form.Item>
        );
    }
);

InputTextArea.displayName = "InputTextArea";

export default InputTextArea;

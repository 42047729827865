import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Form, Select, message } from "antd";
import Card from "antd/lib/card/Card";
import { SelectOption } from "components/form/inputs/input-select";
import BukuBesarAkunTable from "modules/accounting/buku-besar/index/table";
import { bukuBesarTableToExcel } from "modules/accounting/buku-besar/utils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDownload } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bukuBesarService, { FilterBukuBesar } from "services/api-endpoints/accounting/buku-besar";
import jurnalUmumService from "services/api-endpoints/accounting/jurnal-umum";

import { FORMAT_DATE_1 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<FilterBukuBesar, "page">> = yup.object().shape({
    type_id: yup.number(),
    id: yup.number(),
    account_id: yup.number().required("Account Cabang Required"),
    coa_id: yup.number().required("Coa Name Required"),
    bp_id: yup.number(),
    start_date: yup.string().required("Start Date Required"),
    end_date: yup.string().required("End Date Required"),
});

const ALL_COA = { label: "Semua Akun", value: -1 };

const BukuBesarAkun = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const [form] = Form.useForm();
    const { setValue, watch } = useForm<FilterBukuBesar>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchAccountId = watch("account_id");
    const watchCoaId = watch("coa_id");
    const watchStartDate = watch("start_date");
    const watchEndDate = watch("end_date");

    const getBukuBesarList = useMutation(
        [bukuBesarService.bukuBesarList],
        async (pg: any) => {
            return (
                await bukuBesarService.BukuBesarList({
                    account_id: watchAccountId,
                    coa_id: watchCoaId,
                    start_date: watchStartDate,
                    end_date: watchEndDate,
                    page: pg,
                } as FilterBukuBesar)
            ).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Search Success");
            },
        }
    );

    const getAccountQuery = useQuery([jurnalUmumService.getAccount], async () => {
        return (await jurnalUmumService.GetAccount()).data.data.map(
            (el) => ({ ...el, label: `${el.account_code} - ${el.account_name}`, value: el.account_id } as SelectOption)
        );
    });

    const saldoAwal = () => {
        if ((getBukuBesarList.data?.saldo_awal?.saldo || 0) === 0)
            return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${(getBukuBesarList.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")}`;
        if ((getBukuBesarList.data?.saldo_awal?.saldo || 0) < 0)
            return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${((getBukuBesarList.data?.saldo_awal?.saldo || 0) * -1).ToIndCurrency(
                "Rp"
            )} (K)`;
        return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${(getBukuBesarList.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const total = () => {
        if ((getBukuBesarList.data?.total?.saldo || 0) < 0)
            return `${getBukuBesarList.data?.total?.note || ""} : ${((getBukuBesarList.data?.total?.saldo || 0) * -1).ToIndCurrency("Rp")} (K)`;
        return `${getBukuBesarList.data?.total?.note || ""} : ${(getBukuBesarList.data?.total?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const getCoaList = useQuery([bukuBesarService.coaList], async () => {
        const coaList = (await bukuBesarService.CoaList()).data.data?.map(
            (el) => ({ label: `${el.coa_name} (${el.coa_code})`, value: el.coa_id } as SelectOption)
        );
        return [ALL_COA, ...(coaList || [])];
    });

    const onSearch = async () => {
        searchParams.set("page", "1");
        setSearchParams(searchParams);
        getBukuBesarList.mutate(1);
    };

    useEffect(() => {
        if (page !== 1) {
            getBukuBesarList.mutate(page);
        }
    }, [page]);

    const onDownload = () => {
        const COAName = getCoaList.data?.find((el) => el.value === watchCoaId)?.label;
        const date = `${watchStartDate?.split("-").join("")}_${watchEndDate?.split("-").join("")}`;

        bukuBesarTableToExcel({
            title: `Buku Besar.${COAName}.${date}`,
            list: getBukuBesarList.data?.list || [],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Card>
                <Form className="flex flex-col gap-5" form={form} layout="vertical">
                    <div className="grid grid-cols-3 gap-x-4 align-bottom mb-4">
                        <div>
                            <p className="mb-2">Account Cabang</p>
                            <Select
                                className="w-full"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                                placeholder="Account Cabang"
                                loading={getAccountQuery.isLoading}
                                options={getAccountQuery.data}
                                onChange={(val) => {
                                    setValue("account_id", val);
                                }}
                            />
                        </div>
                        <div>
                            <p className="mb-2">Coa Name</p>
                            <Select
                                className="w-full"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                                placeholder="Coa Name"
                                loading={getCoaList.isLoading}
                                options={getCoaList.data}
                                onChange={(val) => {
                                    setValue("coa_id", val);
                                }}
                            />
                        </div>
                        <div>
                            <p className="mb-2">Date Range</p>
                            <DatePicker.RangePicker
                                className="w-full"
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    setValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    setValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div>

                        <div className="m-0 mt-7 flex items-center gap-4">
                            <Button
                                onClick={onSearch}
                                type="primary"
                                className="!flex !items-center"
                                disabled={!watchCoaId || !watchStartDate || !watchEndDate}
                                loading={getBukuBesarList.isLoading}
                            >
                                <BiSearch className="m-0 mr-2" />
                                Search
                            </Button>
                            <Button onClick={onDownload} disabled={(getBukuBesarList.data?.list || []).length === 0} className="w-fit">
                                <AiOutlineDownload className="m-0 mr-2" />
                                Download
                            </Button>
                        </div>
                    </div>
                </Form>
                {getBukuBesarList.data?.saldo_awal ? (
                    <div className="grid grid-cols-5">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{saldoAwal()}</p>
                    </div>
                ) : null}
                <BukuBesarAkunTable fetcher={getBukuBesarList} />
                {getBukuBesarList.data?.total ? (
                    <div className="grid grid-cols-5 mt-4">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{total()}</p>
                    </div>
                ) : null}
            </Card>
        </div>
    );
};

export default BukuBesarAkun;

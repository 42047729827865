/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, message } from "antd";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import businessPartnerService, { CreateBusinessPartner, EditBusinessPartner } from "services/api-endpoints/dashboard-jsl/business-partner";
import purchasingInventoryService from "services/api-endpoints/purchasing/inventory";
import { BP_CREDIT_STATUS, BP_MEMBER_STATUS, BP_TYPE, BP_TYPE_VENDOR, TYPE_TAX, TYPE_TAX_INCLUDED, TYPE_TAX_TAXABLE } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<EditBusinessPartner>> = yup.object().shape({
    id: yup.number().required("id Required"),
    name: yup.string().required("Name Required"),
    address: yup.string().required("Address Required"),
    code: yup.string().required("Code Required"),
    phone_number: yup.string().required("Phone Number Required"),
    type: yup.number().required("Type Required"),
    credit_status: yup.number().required("Creadit Status Required"),
    credit_limit: yup.string().required("Creadit Limit Required"),
    open_balance: yup.string().nullable(),
    tax_id: yup.number().nullable(),
    tax_type: yup.number().nullable(),
    payment_term_id: yup.number().nullable(),
});

const BusinessPartnerEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue, formState } = useForm<EditBusinessPartner>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchType = watch("type");
    const watchTaxType = watch("tax_type");

    const createMutation = useMutation(
        [businessPartnerService.create],
        async (data: CreateBusinessPartner) => {
            return (await businessPartnerService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Business Partner Created");
                navigate(-1);
            },
        }
    );

    const detailQuery = useQuery(
        [businessPartnerService.detail, id],
        async () => {
            return (await businessPartnerService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("id", data.id);
                setValue("name", data.name);
                setValue("address", data.address);
                setValue("type", data.type);
                setValue("code", data.code);
                setValue("credit_status", data.credit_status);
                setValue("credit_limit", data.credit_limit);
                setValue("phone_number", data.phone_number);
                if (data.type === BP_TYPE_VENDOR) {
                    setValue("open_balance", data.open_balance);
                    setValue("tax_id", data.tax_id);
                    setValue("tax_type", data.tax_type);
                    setValue("payment_term_id", data.payment_term_id);
                }
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const termOfPayment = useQuery([purchasingInventoryService.getPayment], async () => {
        return (await purchasingInventoryService.GetPayment()).data.data?.map(
            (el) => ({ label: el.description, value: el.payment_term_id } as SelectOption)
        );
    });

    const tax = useQuery([purchasingInventoryService.getTax], async () => {
        return (await purchasingInventoryService.GetTax()).data.data?.map((el) => ({ label: el.tax_name, value: el.tax_id } as SelectOption));
    });

    const onSubmitHandler = handleSubmit(async (data) => {
        const tempData: CreateBusinessPartner = {
            ...data,
            credit_limit: (data.credit_limit || "0").split(".").join(""),
            open_balance: (data.open_balance || "0").split(".").join(""),
        };
        if (watchType === BP_TYPE_VENDOR) {
            if (!watch("open_balance")) {
                message.error("Open Balance Required");
                return;
            }
            if (!watch("tax_type")) {
                message.error("Tax Type Required");
                return;
            }
            if (!watch("tax_id") && !(watchTaxType !== TYPE_TAX_TAXABLE && watchTaxType !== TYPE_TAX_INCLUDED)) {
                message.error("Tax Required");
                return;
            }
            if (!watch("payment_term_id")) {
                message.error("Term Of Payment Required");
                return;
            }
        }
        createMutation.mutate(tempData);
    });

    const onSaveCreateHandler = async () => {
        await form.validateFields();
        console.log(formState.errors);
        onSubmitHandler();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit business partner"
                rightAddition={() => (
                    <>
                        <Button loading={createMutation.isLoading} onClick={onSaveCreateHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                                        <ControlledInputText control={control} name="address" placeholder="Address" label="Address" />
                                        <ControlledInputText control={control} name="code" placeholder="Code" label="Code" />
                                        <ControlledInputText control={control} name="phone_number" placeholder="Phone Number" label="Phone Number" />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            options={BP_CREDIT_STATUS}
                                            name="credit_status"
                                            placeholder="Credit Status"
                                            label="Credit Status"
                                        />
                                        <ControlledInputText
                                            control={control}
                                            name="credit_limit"
                                            type="number"
                                            placeholder="Credit Limit"
                                            label="Credit Limit"
                                        />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            name="type"
                                            options={BP_TYPE}
                                            placeholder="Type"
                                            label="Type"
                                        />
                                        {watchType === BP_TYPE_VENDOR ? (
                                            <>
                                                <ControlledInputText
                                                    control={control}
                                                    name="open_balance"
                                                    type="number"
                                                    placeholder="Open Balance"
                                                    label="Open Balance"
                                                />
                                                <ControlledSelectInput
                                                    classNameForm="col-span-1"
                                                    control={control}
                                                    options={TYPE_TAX}
                                                    name="tax_type"
                                                    placeholder="Tax Type"
                                                    label="Tax Type"
                                                />
                                                <ControlledSelectInput
                                                    classNameForm="col-span-1"
                                                    control={control}
                                                    options={tax.data}
                                                    loading={tax.isLoading}
                                                    name="tax_id"
                                                    placeholder="Tax"
                                                    label="Tax"
                                                    disabled={watchTaxType !== TYPE_TAX_TAXABLE && watchTaxType !== TYPE_TAX_INCLUDED}
                                                />
                                                <ControlledSelectInput
                                                    classNameForm="col-span-1"
                                                    control={control}
                                                    options={termOfPayment.data}
                                                    loading={termOfPayment.isLoading}
                                                    name="payment_term_id"
                                                    placeholder="Term Of Payment"
                                                    label="Term Of Payment"
                                                />
                                            </>
                                        ) : null}
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

export default BusinessPartnerEdit;

import { Input, Form, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (type: number, note: string) => void;
    isSameProduct: boolean;
};

const ModalNewPo = ({ onSubmit, isSameProduct, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();
    const [value, setValue] = useState("");

    const onSameHandler = () => {
        onSubmit(1, value);
        closeRef.current?.click();
    };
    const onWithNewPoHandler = () => {
        onSubmit(2, value);
        closeRef.current?.click();
    };
    const onWithOutPoHandler = () => {
        onSubmit(3, value);
        closeRef.current?.click();
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col gap-1 items-end">
                            {!isSameProduct && (
                                <h4 className="m-0 !w-full font-semibold text-red-400 mb-4">
                                    Dikarenakan ada selisih jumlah item dari Internal PO, Apakah anda ingin buat Internal PO baru.?
                                </h4>
                            )}
                            <Input.TextArea rows={5} placeholder="Note Material Movement" value={value} onChange={(e) => setValue(e.target.value)} />
                            {isSameProduct ? (
                                <Button type="primary" className="items-center mt-4" onClick={onSameHandler} disabled={!value}>
                                    Kirim
                                </Button>
                            ) : (
                                <div className="grid grid-cols-2 gap-4 mt-8">
                                    <Button type="default" className="items-center" onClick={onWithOutPoHandler} disabled={!value}>
                                        Tanpa PO Baru
                                    </Button>
                                    <Button type="primary" className="items-center" onClick={onWithNewPoHandler} disabled={!value}>
                                        Dengan PO Baru
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalNewPo;

/* eslint-disable no-shadow */
import React from "react";
import { useDrop } from "react-dnd";
import { RiDragDropLine } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import { ManufactureContext, changeIds } from "../context/context";
import { MenuTypes, backgroundColorManufacture } from "../utils/constant";
import RenderEntities from "./render-entities";
import { clsx } from "clsx";

const Draw = () => {
    const { setEntities, entities, models } = React.useContext(ManufactureContext) as any;

    const dropEntity = (data: any) => {
        if (!Object.keys(data || {}).length) return;
        setEntities((entities: any[]) => {
            if (!data?.idEntity) return [...entities, { idEntity: uuidv4(), ...data }];

            let tempEntities = [...entities];
            let tempIdEntity = data?.idEntity;

            if (data?.asType === MenuTypes.model.type) {
                // drop entity as model
                const modelEntities = models?.find((m: any) => m.parent?.idEntity === data?.idEntity)?.entities;
                const newEntityIds = changeIds(modelEntities);
                newEntityIds.forEach((ent: any) => {
                    if (!ent?.idEntityParent) {
                        tempIdEntity = ent?.idEntity;
                    }
                });
                tempEntities = [...tempEntities, ...newEntityIds];
            }

            return tempEntities.map((entity) => {
                if (entity?.idEntity !== tempIdEntity) return entity;
                return {
                    ...entity,
                    left: data.left,
                    top: data.top,
                };
            });
        });
    };

    const dropEntityToDraw = (data: any) => {
        if (!Object.keys(data || {}).length) return;
        setEntities((entities: any[]) => {
            const removeParentIdFromEntities = entities?.map((entity: any) => {
                if (data?.idEntity !== entity?.idEntity) return entity;
                return {
                    ...data,
                    idEntityParent: null,
                };
            });
            const removeChildFromParentEntities = removeParentIdFromEntities?.map((entity: any) => {
                if (entity?.idEntity !== data?.idEntityParent) return entity;
                return {
                    ...entity,
                    data: {
                        ...entity.data,
                        children: entity?.data?.children?.filter((id: string) => id !== data?.idEntity),
                        child1: entity?.data?.child1 === data?.idEntity ? null : entity?.data?.child1,
                        child2: entity?.data?.child2 === data?.idEntity ? null : entity?.data?.child2,
                    },
                };
            });
            return removeChildFromParentEntities;
        });
    };

    const [, drop] = useDrop(
        () => ({
            accept: [
                MenuTypes.process.type,
                MenuTypes.multipleOperator.type,
                MenuTypes.subProcess.type,
                MenuTypes.operator.type,
                MenuTypes.model.type,
            ],
            drop(item: any, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset();
                const deltaInital = monitor.getInitialSourceClientOffset();
                if (!delta || !deltaInital) return undefined;
                const data = {
                    ...item,
                    left: Math.round(item.left + delta.x + deltaInital.x),
                    top: Math.round(item.top + delta.y + deltaInital.y),
                };

                if (item?.idEntityParent) {
                    dropEntityToDraw(data);
                    return undefined;
                }
                dropEntity(data);
                return undefined;
            },
        }),
        [dropEntity, dropEntityToDraw]
    );

    return (
        <div ref={drop} id="container-draw" className={clsx("w-screen relative h-screen overflow-hidden", backgroundColorManufacture.primary)}>
            {!entities?.length ? (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 pointer-events-none -translate-y-1/2 flex flex-col items-center">
                    <RiDragDropLine className="text-9xl text-gray-400" />
                    <p className="text-gray-400 text-3xl">Drag and drop entity here</p>
                </div>
            ) : null}
            <RenderEntities entities={entities} />
        </div>
    );
};

export default Draw;

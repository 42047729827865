/* eslint-disable import/prefer-default-export */
import { List } from "antd";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ProductSalesOrder } from "services/api-endpoints/dashboard/master-data/c-purchase-order";
import InvoiceModal from "../modal/invoice-modal";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function viewTable<T extends ProductSalesOrder>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
            // setSize(pagination.pageSize || 10);
        };
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{(text || 0).ToIndCurrency("Rp")}</p>,
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                width: "75px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "References Invoice",
                dataIndex: "references",
                render: (text, record) => (
                    <>
                        {record.references ? (
                            record.references?.map((val) => (
                                <InvoiceModal data={val}>
                                    {(dt) => (
                                        <List.Item onClick={dt.openModal} className="text-xs cursor-pointer !text-primary">
                                            {val.inv || "-"}
                                        </List.Item>
                                    )}
                                </InvoiceModal>
                            ))
                        ) : (
                            <p className="capitalize m-0">-</p>
                        )}
                    </>
                ),
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const TableView = viewTable(EditTable);
export default TableView;

/* eslint-disable no-shadow */
import { Alert, Card, Descriptions, Image, message, Select, Skeleton } from "antd";
import StateRender from "components/common/state-render";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import hadirAppService, { SetApproval } from "services/api-endpoints/hadir-app/hadir-app-service";
import { APPROVAL_STATUS_CUTI_DUTYTRIP_LEMBUR } from "utils/constant";

const PerjalananDinasView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") as any;

    const detailQuery = useQuery(
        [hadirAppService.detailDutyTrip, id],
        async () => {
            return (await hadirAppService.DetailDutyTrip({ id })).data.data;
        },
        { enabled: !!id }
    );

    const setApproval = useMutation([hadirAppService.setApprovalDutyTrip], async (data: SetApproval) => {
        return (await hadirAppService.SetApprovalDutyTrip(data)).data.data;
    });

    const onChangeStatus = (opt: number) => {
        setApproval.mutateAsync({ id, status: opt }).then(() => {
            message.success("Success set status");
            detailQuery.refetch();
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card>
                        <Descriptions title="Detail Duty Trip">
                            <Descriptions.Item label="Category Name">{detailQuery.data?.category_name}</Descriptions.Item>
                            <Descriptions.Item label="Description">{detailQuery.data?.description}</Descriptions.Item>
                            <Descriptions.Item label="Facilities">{detailQuery.data?.facilities}</Descriptions.Item>
                            <Descriptions.Item label="Start Date">{detailQuery.data?.start_date}</Descriptions.Item>
                            <Descriptions.Item label="End Date">{detailQuery.data?.end_date}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{detailQuery.data?.created_at}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <Select
                                    className="!w-[200px]"
                                    status={(detailQuery.data?.status as any) === -1 ? "error" : ""}
                                    loading={setApproval.isLoading}
                                    onChange={onChangeStatus}
                                    defaultValue={Number(detailQuery.data?.status)}
                                    options={APPROVAL_STATUS_CUTI_DUTYTRIP_LEMBUR}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="User Name">{detailQuery.data?.user_name}</Descriptions.Item>
                            <Descriptions.Item label="City Name">{detailQuery.data?.city_name}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <hr className="my-2" />
                    <p className="font-medium text-xl m-0">List History</p>
                    {detailQuery.data?.list_history?.map((history) => (
                        <Card className="mb-5">
                            <div className="w-full ">
                                <div className="flex items-start gap-4 w-full justify-between">
                                    <p className="m-0 font-medium capitalize max-w-[400px]">{history.description}</p>
                                    <span className="font-light text-gray-400">{history.created_at}</span>
                                </div>
                                <div className="flex items-start gap-5 mt-4">
                                    <iframe
                                        height="170"
                                        frameBorder="0"
                                        title="test"
                                        src="https://maps.google.com/maps?q=-6.148056,106.73421&hl=id;z=14&amp;output=embed"
                                    />
                                    <div className="grid grid-cols-4 gap-5">
                                        {history.images?.map((img) => (
                                            <Image src={img} alt={img} className="w-full h-[170px] object-cover" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </StateRender.Data>
                <StateRender.Loading>
                    <Card>
                        <Skeleton />
                    </Card>
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default PerjalananDinasView;

import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation, useQuery } from "react-query";
import masterBpService, { CreateBP, EditBP } from "services/api-endpoints/accounting/master-bp";
import ControlledInputNumber from "components/form/controlled-inputs/controlled-input-number";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { PARTNER_AGENT, PARTNER_TYPE } from "utils/constant";
import BpSelect from "modules/accounting/master-bp/lib/bp-select";
import PaymentTermSelect from "modules/accounting/master-bp/lib/payment-term-select";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<CreateBP>> = yup.object().shape({
    name: yup.string().required("Name required"),
    partner_code: yup.string().required("Partner code required"),
    partner_type: yup.number().required("Partner type required"),
    credit_limit: yup.number().required("Creadit limit required"),
    tax_id: yup.string().required("Tax ID required"),
    payment_term_id: yup.number().required("Payment Term required"),
    business_partner_id: yup.number().nullable(),
});

const MasterBPEdit = () => {
    const [params] = useSearchParams();
    const id = params.get("id");

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue, setError } = useForm<CreateBP>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const partnerTypeWatch = watch("partner_type");

    const detailQuery = useQuery(
        [masterBpService.detail, id],
        async () => {
            return (await masterBpService.Detail({ id })).data.data;
        },
        {
            onSuccess(data) {
                form.setFieldsValue(data);
                setValue("business_partner_id", data.business_partner_id);
                setValue("credit_limit", data.credit_limit);
                setValue("name", data.name);
                setValue("partner_code", data.partner_code);
                setValue("partner_type", data.partner_type);
                setValue("payment_term_id", data.payment_term_id);
                setValue("tax_id", data.tax_id);
            },
            enabled: !!id,
        }
    );

    const editMutation = useMutation(
        [masterBpService.edit],
        async (data: EditBP) => {
            return (await masterBpService.Edit(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Business Partner Edited");
                navigate(-1);
            },
        }
    );

    const onPartnerTypeSelect = () => {
        form.setFieldValue("business_partner_id", null);
        setValue("business_partner_id", null);
    };

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        if (data.partner_type === PARTNER_AGENT && !data.business_partner_id) {
            setError("business_partner_id", {
                message: "Business Partner Required",
                type: "required",
            });
            return;
        }
        editMutation.mutate({
            ...data,
            id,
        });
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit Business Partner"
                rightAddition={() => (
                    <Space>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <div className="w-[50%]">
                <Card>
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Form form={form} disabled={editMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">Business Partner information</h1>
                                        <ControlledInputText allowClear control={control} name="name" placeholder="Name" />
                                        <ControlledInputText allowClear control={control} name="partner_code" placeholder="Partner Code" />
                                        <ControlledInputNumber control={control} name="credit_limit" placeholder="Credit Limit" />
                                        <ControlledInputText allowClear control={control} name="tax_id" placeholder="Tax ID" />

                                        <ControlledSelectInput
                                            onSelect={onPartnerTypeSelect}
                                            control={control}
                                            name="partner_type"
                                            options={PARTNER_TYPE}
                                            placeholder="Partner Type"
                                        />
                                        <BpSelect
                                            disabled={partnerTypeWatch !== PARTNER_AGENT}
                                            control={control}
                                            name="business_partner_id"
                                            placeholder="Business Partner"
                                        />
                                        <PaymentTermSelect control={control} name="payment_term_id" placeholder="Payment Term" />
                                    </Form>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 4 }} />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(detailQuery.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </Card>
            </div>
        </div>
    );
};

export default MasterBPEdit;

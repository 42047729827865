import { ColProps, Form, InputNumber as InputNumberAntd, InputNumberProps } from "antd";
import React, { forwardRef, Ref } from "react";
import Utils from "utils";
import { currency } from "utils/constant";

type TextInputProps = InputNumberProps & {
    error: string | undefined;
    label?: string;
    labelCol?: ColProps;
    initialValue?: string;
    classNameForm?: string;
};

const InputNumberDecimal: React.FC<TextInputProps> = forwardRef(
    (
        { placeholder, error, label, name, labelCol, initialValue, classNameForm, onChange, value, onBlur, ...rest }: TextInputProps,
        ref: Ref<HTMLInputElement>
    ) => {
        const currencyOptions = currency.map((c) => ({
            label: c.CcyNm,
            value: `${c.CtryNm}::${c.Ccy}`,
        }));

        return (
            <Form.Item
                label={label}
                name={name}
                labelCol={labelCol}
                validateStatus={error ? "error" : ""}
                help={error && error}
                initialValue={initialValue}
                className={`!w-full ${classNameForm}`}
            >
                <InputNumberAntd
                    {...rest}
                    value={value || ""}
                    style={{ width: "100%" }}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    name={name}
                    placeholder={placeholder}
                    step="0.01"
                />
            </Form.Item>
        );
    }
);

InputNumberDecimal.displayName = "InputNumber";

export default InputNumberDecimal;

import { Button, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import KoliPrint from "modules/master-data/consignment/packing-list/koli-print";
import MultipleKoliPrint from "modules/master-data/consignment/packing-list/multiple-koli-print";
import { useRef } from "react";
import { useMutation } from "react-query";
import { useReactToPrint } from "react-to-print";
import docContainerService, { DetailProduct } from "services/api-endpoints/dashboard/doc-container";

type Props = {
    list?: DetailProduct[];
    docId?: string;
};

const DocContainerDetailTable = ({ list, docId }: Props) => {
    const printOneRef = useRef<HTMLDivElement | null>(null);
    const printAllRef = useRef<HTMLDivElement | null>(null);

    const handleOnePrint = useReactToPrint({
        content: () => printOneRef.current,
        documentTitle: `BARCODE`,
        pageStyle: "@page { size: auto }",
    });

    const handleAllPrint = useReactToPrint({
        content: () => printAllRef.current,
        documentTitle: `BARCODE`,
        pageStyle: "@page { size: auto }",
    });

    const detailListKoliMutate = useMutation([], async (param: { doc_id: any }) => {
        return (await docContainerService.DetailListKoli(param)).data.data;
    });

    const detailKoliMutate = useMutation([], async (param: { doc_id: any; no_koli: any }) => {
        return (await docContainerService.DetailKoli(param)).data.data;
    });

    const clickPrint = (row: DetailProduct) => {
        return () => {
            detailKoliMutate.mutateAsync({ doc_id: docId, no_koli: row.no_koli }).then(() => {
                setTimeout(() => {
                    handleOnePrint();
                }, 500);
            });
        };
    };

    const clickPrintAll = () => {
        detailListKoliMutate.mutateAsync({ doc_id: docId }).then((res) => {
            setTimeout(() => {
                handleAllPrint();
            }, 500);
        });
    };

    const columns: ColumnsType<DetailProduct> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Note",
            dataIndex: "note",
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
        },
        {
            title: "Product Code",
            dataIndex: "product_code1",
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
        },
        {
            title: "Scanned Koli",
            dataIndex: "is_mm",
            render: (text, record) => <Tag color={record.is_mm === 1 ? "green" : "red"}>{record.is_mm === 1 ? "Already" : "Not Yet"}</Tag>,
        },
        {
            title: "Area Code",
            dataIndex: "area_code",
        },
        {
            title: "Qty",
            dataIndex: "qty",
        },
        {
            title: "No Koli",
            dataIndex: "no_koli",
            render: (text, record) => {
                if (detailKoliMutate.isLoading && record.no_koli === detailKoliMutate.variables?.no_koli) {
                    return "Load...";
                }
                return (
                    <Button title="Print barcode" type="link" onClick={clickPrint(record)}>
                        {text}
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <div className="w-full flex justify-end mb-3">
                <Button onClick={clickPrintAll}>Print All Koli</Button>
            </div>
            <MultipleKoliPrint ref={printAllRef} detailListKoli={detailListKoliMutate.data} />
            <KoliPrint
                ref={printOneRef}
                noKoli={detailKoliMutate.data?.no_koli}
                products={detailKoliMutate.data?.list_product as any}
                qrString={`${detailKoliMutate.data?.do_no}_${detailKoliMutate.data?.no_koli}` || ""}
            />
            <Table rowKey={(record) => record.id!} size="small" columns={columns} dataSource={list || []} className="w-full" pagination={false} />
        </>
    );
};

export default DocContainerDetailTable;

import { Input, Select } from "antd";
import React from "react";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import { useQuery } from "react-query";
import bankAccountService from "services/api-endpoints/accounting/bank-account";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBankAccount = ({ filter = [], ...props }: Props) => {
    const orgQuery = useQuery([bankAccountService.getOrg], async () => {
        const req = await bankAccountService.GetOrg();
        return req.data.data?.map((el) => ({ label: el.organization_name, value: el.organization_id } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Bank Account"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input placeholder="Name" value={dt.values.name} name="name" onChange={(e) => dt.setOneValue("name", e.target.value)} />
                        <Input
                            placeholder="Description"
                            value={dt.values.description}
                            name="description"
                            onChange={(e) => dt.setOneValue("description", e.target.value)}
                        />
                        <Input
                            placeholder="Account_no"
                            value={dt.values.account_no}
                            name="account_no"
                            onChange={(e) => dt.setOneValue("account_no", e.target.value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.organization_id}
                            loading={orgQuery.isLoading}
                            placeholder="Organization"
                            options={orgQuery.data || []}
                            onChange={(value) => dt.setOneValue("organization_id", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBankAccount;

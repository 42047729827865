/* eslint-disable no-shadow */
import { Button, Card, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import BPHistoryTable from "modules/dashboard-jsl/bp-delovery-order/history-table";
import Print from "modules/dashboard-jsl/bp-goods-receipt/print";
import ProductTableBPView from "modules/dashboard-jsl/bp-goods-receipt/product-table-bp-view";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import bpGoodsReceiptService from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";
import { DetailList } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import { STATUS_BP_ORDER } from "utils/constant";

const BPGoodsReceiptView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const [products, setProducts] = useState<DetailList[]>([]);

    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `BP Goods Receipt - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const detailQuery = useQuery(
        [bpGoodsReceiptService.detailGR, id],
        async () => {
            return (await bpGoodsReceiptService.DetailGR({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [bpGoodsReceiptService.history, page],
        async () => {
            return (await bpGoodsReceiptService.History({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="BP goods receipt detail"
                rightAddition={() => (
                    <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                        <AiFillPrinter className="m-0 mr-2" />
                        Print
                    </Button>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Goods Receipt No <br />
                                        <span className="underline italic m-0">{detailQuery.data?.gr_no || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Invoice no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.inv_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                </div>
                                <div className="flex items-start justify-between mt-6">
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-600" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_BP_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTableBPView loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: <BPHistoryTable fetcher={history} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default BPGoodsReceiptView;

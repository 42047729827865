/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ChildData, DetailPOData } from "services/api-endpoints/dashboard/master-data/penerimaan-barang";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends ChildData>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "PO NO",
                dataIndex: "po_no",
                render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Type Name",
                dataIndex: "type_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "UOM",
                dataIndex: "uom_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Qty Order",
                dataIndex: "qty",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Qty Receipt",
                dataIndex: "qty_receive",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="m-0 text-center ">{text === 0 ? "0" : text || "-"}</p>,
            },
        ];

        const isEditing = (record: ChildData, edited: ChildData | null) => record.po_id === edited?.po_id && record.id === edited?.id;
        const findIndexSave = (record: ChildData, edited: ChildData) => record.po_id === edited?.po_id && record.id === edited?.id;
        const rowKey = (record: ChildData) => (record?.po_id && record.po_id) as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty_receive: "number" };

        const reqSend = editRow?.qty || 1;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 0,
                    maxNumber: reqSend,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const PenerimaanTableAdd = tableEdit(EditTable);
export default PenerimaanTableAdd;

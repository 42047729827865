import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import TableIndex from "modules/master-data/consignment/invoice/table-index";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpSalesService, { BpSales } from "services/api-endpoints/dashboard-all/bp-sales";
import cInvoiceService from "services/api-endpoints/dashboard/master-data/c-invoice";

const filter = ["status"];

const CInvoice = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([cInvoiceService.listAll, page, size, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await cInvoiceService.ListAll()).data.data;
    });

    const deleteMutation = useMutation(async (data: BpSales) => (await bpSalesService.DeleteDraft({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<BpSales>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} add={false} />
            <TableIndex onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default CInvoice;

import { Select } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { SelectOption } from "models";
import React, { useEffect, useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (orderId: number[] | null) => void;
    options: SelectOption[] | undefined;
};

const ModalDoc = ({ onSubmit, options, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [doc, setDoc] = useState<number[]>([]);

    const onSelect = (data: number) => {
        setDoc((old) => [...old, data]);
    };

    const onDeSelect = (data: number) => {
        setDoc((prev) => prev?.filter((ord) => ord !== data));
    };

    const onSubmitHandler = () => {
        onSubmit(doc);
        closeRef.current?.click();
        setDoc([]);
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} onOk={onSubmitHandler} okButtonProps={{ disabled: doc.length === 0 }}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Select
                        placeholder="Doc Name"
                        mode="multiple"
                        value={doc}
                        options={options}
                        onSelect={onSelect}
                        onDeselect={onDeSelect}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        style={{ width: "100%" }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalDoc;

import { Alert, Card, Skeleton } from "antd";
import React from "react";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import loyaltyService from "services/api-endpoints/dashboard/master-data/loyalty";
import { BOOLEAN_TYPE, LOYALTY_TYPE } from "utils/constant";

const LoyaltyView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const detailQuery = useQuery(
        [loyaltyService.detail, id],
        async () => {
            return (await loyaltyService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail loyalty" />
            <Card className="">
                <div className="grid grid-cols-4 gap-4">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <p className="TEXT-1 mt-4">
                                        name <br />
                                        <span className="TEXT-2">{detailQuery.data?.name}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        type <br />
                                        <span className="TEXT-2">
                                            {LOYALTY_TYPE.find((el) => el.value === Number(detailQuery.data?.type))?.label}
                                        </span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        starting price <br />
                                        <span className="TEXT-2">{detailQuery.data?.starting_price?.ToIndCurrency("Rp")}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        end price <br />
                                        <span className="TEXT-2">{detailQuery.data?.end_price?.ToIndCurrency("Rp")}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        is multiple <br />
                                        <span className="TEXT-2">
                                            {BOOLEAN_TYPE.find((el) => el.value === Number(detailQuery.data?.is_multiple))?.label}
                                        </span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        max user per day <br />
                                        <span className="TEXT-2">{detailQuery.data?.max_user_per_day}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        point <br />
                                        <span className="TEXT-2">{detailQuery.data?.point}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        max point <br />
                                        <span className="TEXT-2">{detailQuery.data?.max_point}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        start date <br />
                                        <span className="TEXT-2">{detailQuery.data?.start_date}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        end date <br />
                                        <span className="TEXT-2">{detailQuery.data?.end_date}</span>
                                    </p>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(detailQuery.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                </div>
            </Card>
        </div>
    );
};

export default LoyaltyView;

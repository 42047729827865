import { Button } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { rekapBpSalesToExcel } from "modules/master-data/rekap/download/bpsales-utils";
import FilterBpSales from "modules/master-data/rekap/filter/filter-bp-sales";
import TableIndexBPSales from "modules/master-data/rekap/table-index-bpsales";
import { FaDownload } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import rekapService from "services/api-endpoints/dashboard/master-data/rekap";

const filter = ["start_date", "end_date", "order_status", "bp_id"];

const RekapBpSales = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [rekapService.getBpSales, searchValue, filterValue, isFilter, isSearch],
        async () => {
            // if (isSearch) return (await orderService.Search({ page, param_search: searchValue.query })).data.data;
            // if (isFilter) return (await orderService.Filter(filterValue as any)).data.data;
            return (await rekapService.GetBPSales(filterValue as any)).data.data;
        },
        {
            enabled: !!filterValue.start_date || !!filterValue.end_date,
        }
    );

    const onDownloadRekap = () => {
        const dateFilter = `${filterValue.start_date?.split("-").join("")}_${filterValue.end_date?.split("-").join("")}`;
        rekapBpSalesToExcel({
            title: `Rekap Penjualan - BP Sales (${dateFilter})`,
            list: getList.data || [],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBpSales filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Invoice No"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <Button
                                loading={getList.isLoading}
                                disabled={!getList.data}
                                type="primary"
                                className="!flex !items-center w-fit"
                                onClick={onDownloadRekap}
                            >
                                <FaDownload className="mr-2" />
                                Download
                            </Button>
                        )}
                    />
                )}
            </FilterBpSales>
            <TableIndexBPSales fetcher={getList} />
        </div>
    );
};

export default RekapBpSales;

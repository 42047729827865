/* eslint-disable no-unreachable */
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, DatePicker, Form, message, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation } from "react-query";
import loyaltyService, { CreateLoyalty } from "services/api-endpoints/dashboard/master-data/loyalty";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { BOOLEAN_TYPE, FORMAT_DATE_1, LOYALTY_TYPE } from "utils/constant";
import moment from "moment";
import Utils from "utils";

const schema: yup.SchemaOf<Partial<CreateLoyalty>> = yup.object().shape({
    name: yup.string().required("Name required"),
    type: yup.number().required("Type required"),
    starting_price: yup.string().required("Starting price required"),
    end_price: yup.string().required("End price required"),
    start_date: yup.string().required("Valid date required"),
    end_date: yup.string().required("Valid date required"),
    max_user_per_day: yup.string().required("Max user per day required"),
    is_multiple: yup.number().required("Is Multiple required"),
    point: yup.string().required("Point required"),
    max_point: yup.string().required("Max point required"),
});

const LoyaltyAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        setError,
        formState: { errors },
    } = useForm<CreateLoyalty>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const startDateWatch = watch("start_date") ? moment(watch("start_date"), FORMAT_DATE_1) : null;
    const endDateWatch = watch("end_date") ? moment(watch("end_date"), FORMAT_DATE_1) : null;

    const createMutation = useMutation(
        [loyaltyService.create],
        async (data: CreateLoyalty) => {
            return (await loyaltyService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Loyalty Created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        if (!data.start_date || !data.end_date) {
            setError("start_date", {
                message: "Effective date required",
                type: "required",
            });
            return;
        }
        const parseData: CreateLoyalty = {
            ...data,
            starting_price: Utils.convertToIntFormat(data.starting_price)?.toString(),
            end_price: Utils.convertToIntFormat(data.end_price)?.toString(),
            max_user_per_day: Utils.convertToIntFormat(data.max_user_per_day)?.toString(),
            point: Utils.convertToIntFormat(data.point)?.toString(),
            max_point: Utils.convertToIntFormat(data.max_point)?.toString(),
        };
        createMutation.mutate(parseData);
    });

    const onChangeDatePricker = (key: keyof CreateLoyalty, value: string) => {
        setValue(key, value);
        form.setFieldValue(key, value);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new loyalty"
                rightAddition={() => (
                    <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card>
                <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-2 gap-x-4">
                        <ControlledInputText allowClear control={control} name="name" placeholder="Name" />
                        <ControlledSelectInput allowClear control={control} name="type" options={LOYALTY_TYPE} placeholder="Loyalty type" />
                        <ControlledInputText allowClear control={control} type="number" name="starting_price" placeholder="Starting price" />
                        <ControlledSelectInput allowClear control={control} name="is_multiple" options={BOOLEAN_TYPE} placeholder="Multiple" />
                        <ControlledInputText allowClear control={control} type="number" name="end_price" placeholder="End price" />
                        <ControlledInputText allowClear control={control} type="number" name="max_user_per_day" placeholder="Max user per day" />
                        <ControlledInputText allowClear control={control} type="number" name="point" placeholder="Point" />
                        <ControlledInputText allowClear control={control} type="number" name="max_point" placeholder="Max point" />
                        <div className="!w-full">
                            <DatePicker.RangePicker
                                status={errors.start_date ? "error" : undefined}
                                className="w-full"
                                value={[startDateWatch, endDateWatch]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    onChangeDatePricker("start_date", val?.[0]?.format(FORMAT_DATE_1) || "");
                                    onChangeDatePricker("end_date", val?.[1]?.format(FORMAT_DATE_1) || "");
                                }}
                            />
                            {errors.start_date ? <p className="text-red-400">{errors.start_date?.message}</p> : null}
                        </div>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default LoyaltyAdd;

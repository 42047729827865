import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ForbiddenPage = () => {
    return (
        <div className="w-full flex items-center justify-center">
            <Result status="403" title="Forbidden" subTitle="You do not have access to this page" />
        </div>
    );
};

export default ForbiddenPage;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListInvoice {
    id: number;
    doc_no: string;
    partner_name: string;
    bp_name: string;
    order_status: number;
    created_at: string;
}

export interface ProductInvoice {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    pl_id: number;
    is_free: number;
    note?: string;
    discount_price: number;
    voucher_id: number;
    code2: string;
}

export interface DetailInvoice {
    id: number;
    doc_no: string;
    ref_no: string;
    ref_id: number;
    ref_type: number;
    name: string;
    address: string;
    partner_name: string;
    partner_address?: string;
    bp_name?: string;
    bp_address?: string;
    order_status: number;
    created_at: string;
    total_price?: number;
    discount_price?: number;
    grand_total?: number;
    ppn?: number;
    dpp?: number;
    list_detail: ProductInvoice[];
    payment_status: string;
}

class ExtInvoiceService extends BaseService {
    listAll = "/ext-order/list-invoice";

    detail = "/ext-order/detail-invoice";

    constructor() {
        super();
    }

    Detail<T extends DetailInvoice>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    // ListAll<T extends ListInvoice[]>() {
    //     return this.ProxyRequest<T>(async () => {
    //         const req = await ApiMethod.get<T>({
    //             url: this.listAll,
    //         });
    //         return req;
    //     });
    // }
    ListAll<T extends ListInvoice>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const extInvoiceService = new ExtInvoiceService();
export default extInvoiceService;

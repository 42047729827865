/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Space, Tabs } from "antd";
import State from "components/common/state";
import ModalDelete from "components/modal/delete_modal";
import ToolbarAction from "components/toolbar/action";
import BPHistoryTable from "modules/dashboard-jsl/bp-delovery-order/history-table";
import ProductTableBPView from "modules/dashboard-jsl/bp-picking-list/product-table-bp-view";
import Print from "modules/dashboard-jsl/sales-order/view/print";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import bpPickingListService, { DeleteData, DetailList } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import { BP_ORDER_PROCESS, STATUS_BP_ORDER } from "utils/constant";

const BPPickingListView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const [products, setProducts] = useState<DetailList[]>([]);

    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `BP Picking List - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const detailQuery = useQuery(
        [bpPickingListService.detailPL, id],
        async () => {
            return (await bpPickingListService.DetailPL({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [bpPickingListService.history, page],
        async () => {
            return (await bpPickingListService.History({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const deletePLMutation = useMutation(
        [bpPickingListService.deletePL],
        async (data: DeleteData) => {
            return (await bpPickingListService.DeletePL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Picking List deleted");
                navigate(-1);
            },
        }
    );

    const onDeletePLHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePLMutation.mutate(dataDelete);
    };

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="Picking list detail"
                rightAddition={() => (
                    <Space>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                        <ModalDelete title="Delete PL" onSubmit={onDeletePLHandler}>
                            {(dt) => (
                                <Button
                                    loading={deletePLMutation.isLoading}
                                    disabled={!((detailQuery.data?.order_status || 0) <= BP_ORDER_PROCESS)}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        picking list no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.pl_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-600" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_BP_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTableBPView loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: <BPHistoryTable fetcher={history} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default BPPickingListView;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterSubCategory from "modules/master-data/sub-category/index/filter";
import { TDataSubCategory } from "modules/master-data/sub-category/models";
import SubCategoryTable from "modules/master-data/sub-category/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";

const filter = ["category_name", "subcategory_name", "subcategory_code", "is_popular", "is_active"];

const SubCategory = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([subCategoryService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await subCategoryService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await subCategoryService.Filter(filterValue as any)).data.data;
        return (await subCategoryService.GetAll({ page, category_id: 0 })).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataSubCategory) => (await subCategoryService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataSubCategory>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterSubCategory filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Category Name / Subcategory Name / Subcategory Code"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterSubCategory>
            <SubCategoryTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default SubCategory;

/* eslint-disable no-shadow */

import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@mui/material";
import { Alert, Button, Card, Form, message, Skeleton } from "antd";
import StateRender from "components/common/state-render";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import ModalAddMenu from "modules/master-data/menu/add/modal";
import SubMenuAddTable, { ItemRowSubMenu } from "modules/master-data/menu/add/table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import menuService, { MenuRequest } from "services/api-endpoints/dashboard/master-data/menu";
import { toLowerSnakeCase } from "utils";
import { MENU_ACTIVE } from "utils/constant";
import * as yup from "yup";
import { v4 as uuid } from "uuid";

export type FormMenu = Pick<MenuRequest, "name" | "key" | "icon" | "is_active">;

export type SubMenuStruct = ItemRowSubMenu & {
    id?: any;
    collapse?: SubMenuStruct[];
};

const schema: yup.SchemaOf<FormMenu> = yup.object().shape({
    name: yup.string().required("Name required"),
    key: yup.string().required("Key required"),
    icon: yup.string(),
    is_active: yup.number().required("Is Active required"),
});

const MenuEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [subMenu, setSubMenu] = useState<SubMenuStruct[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<FormMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchIcon = watch("icon");

    const detailQuery = useQuery(
        [menuService.detailMenu, id],
        async () => {
            return (await menuService.DetailMenu({ id: id as any })).data.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            onSuccess(data) {
                form.setFieldValue("is_active", data.is_active);
                form.setFieldValue("name", data.menu.name);
                form.setFieldValue("key", data.menu.key);
                form.setFieldValue("icon", data.menu?.icon || "");
                setValue("is_active", data.is_active);
                setValue("name", data.menu.name);
                setValue("key", data.menu.key);
                setValue("icon", data.menu?.icon || "");
                setSubMenu(
                    data.menu.collapse?.map((parent) => ({
                        id: uuid(),
                        name: parent.name,
                        icon: parent?.icon,
                        key: parent.key,
                        collapse: parent?.collapse?.map((child) => ({
                            id: uuid(),
                            name: child.name,
                            key: child.key,
                            icon: child?.icon,
                        })),
                    })) as any[]
                );
            },
        }
    );

    const editMutation = useMutation(
        async (data: MenuRequest) => {
            return (await menuService.CreateMenu({ ...data, id: id as any })).data.data;
        },
        {
            onSuccess() {
                navigate(-1);
                message.success("Menu edited");
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            name: data.name,
            key: data.key,
            is_active: data.is_active,
            icon: data?.icon ? toLowerSnakeCase(data.icon) : null,
            collapse: subMenu,
        } as MenuRequest;

        editMutation.mutate(parseData);
    });

    const onCreateHandler = async () => {
        if (detailQuery.isLoading) return;
        await form.validateFields();
        form.submit();
    };

    const onDelete = (key: string) => {
        setSubMenu((prev) => {
            return prev
                .map((parent) => {
                    const collapse = parent?.collapse?.filter((child) => child?.key !== key);
                    return { ...parent, collapse };
                })
                .filter((parent) => parent.key !== key);
        });
    };

    const checkSameKey = (key: string) => {
        let isSame = false;
        subMenu.forEach((parent) => {
            if (isSame) return;
            if (parent.key === key) {
                isSame = true;
            }
            parent?.collapse?.forEach((child) => {
                if (child.key === key) {
                    isSame = true;
                }
            });
        });
        return isSame;
    };

    const onSubmitParent = (data: ItemRowSubMenu) => {
        if (checkSameKey(data.key!)) {
            message.error(`Key "${data.key}" already exist`);
            return;
        }
        setSubMenu((prev) => [...prev, { ...data, collapse: [] }]);
    };

    const onSubmitChild = (data: ItemRowSubMenu, parentKey: string) => {
        if (parentKey) {
            if (checkSameKey(data.key!)) {
                message.error(`Key "${data.key}" already exist`);
                return;
            }
            // add
            setSubMenu((prev) => {
                return prev?.map((el) => {
                    if (el.key !== parentKey) return el;
                    return {
                        ...el,
                        collapse: [...(el?.collapse || []), data],
                    };
                });
            });
            return;
        }
        // edit
        setSubMenu((prev) => {
            return prev?.map((parent) => {
                if (parent.id === data.id) {
                    return {
                        ...parent,
                        ...data,
                        collapse: parent?.collapse,
                    };
                }
                return {
                    ...parent,
                    collapse: (parent?.collapse || [])?.map((child) => {
                        if (child.id === data.id) {
                            return {
                                ...child,
                                ...data,
                            };
                        }
                        return child;
                    }),
                };
            });
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit menu"
                rightAddition={() => (
                    <Button loading={editMutation.isLoading} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card className="">
                        <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                            <div className="grid grid-cols-3 gap-x-4">
                                <ControlledInputText control={control} name="name" placeholder="Menu Name" label="Men Name" />
                                <ControlledInputText control={control} name="key" placeholder="Menu Key" label="Menu Key" />
                                <div className="flex items-start gap-3">
                                    {watchIcon && <Icon fontSize="medium">{toLowerSnakeCase(watchIcon)}</Icon>}
                                    <ControlledInputText control={control} name="icon" placeholder="Icon" label="Icon" />
                                    <a href="https://mui.com/material-ui/material-icons/" target="_blank" className="" rel="noreferrer">
                                        icons
                                    </a>
                                </div>
                                <ControlledSelectInput
                                    options={MENU_ACTIVE}
                                    control={control}
                                    label="Is Active"
                                    name="is_active"
                                    placeholder="Is Active"
                                />
                            </div>
                        </Form>
                        <div className="w-full flex justify-end">
                            <ModalAddMenu onSubmit={onSubmitParent}>
                                {({ openModal }) => (
                                    <Button onClick={openModal} type="primary" className="!flex !items-center">
                                        <BiPlus className="m-0 mr-2" />
                                        Sub Menu
                                    </Button>
                                )}
                            </ModalAddMenu>
                        </div>
                    </Card>
                    <SubMenuAddTable onDelete={onDelete} onSubmit={onSubmitChild} list={subMenu} asParent />
                </StateRender.Data>
                <StateRender.Loading>
                    <div className="w-full flex flex-col">
                        <Skeleton className="w-[90%] h-11" />
                        <Skeleton className="w-[80%] h-11 my-2" />
                    </div>
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert type="error" message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default MenuEdit;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Skeleton, Space, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonDelete from "components/button/button-delete";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import FilterDocumentPayment, { columnsCCAndPaymentMethod, columnsFilterDocPayment } from "modules/master-data/payment/add/filter-document";
import FilterPiutangReceipt from "modules/master-data/payment/add/filter-putang";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalAddExpenses from "modules/master-data/payment/add/modal-add-expenses";
import BankAccountSelect from "modules/master-data/payment/lib/bank-account-select";
import BusinessPartnerSelect from "modules/master-data/payment/lib/business-partner-select";
import ModalJournalDetail from "modules/master-data/purchasing/modal-journal-detail";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";
import ModalRefDoc from "modules/master-data/receipt/modal-ref-doc";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService, { CreatePayment, FilterDocPayment, InvoiceDocPayment } from "services/api-endpoints/dashboard/payment";
import receiptService, { DraftReceipt, ExpansesData } from "services/api-endpoints/dashboard/receipt";
import Utils from "utils";
import {
    AUTOGENERATED,
    FORMAT_DATE_1,
    ORDER_PAYMENT_AUTOLOKA,
    ORDER_PAYMENT_BP_SALES,
    ORDER_PAYMENT_EMPLOYESS,
    ORDER_PAYMENT_OFFLINE,
    ORDER_PAYMENT_ONLINE,
    RECEIPT_OFFLINE_TRANSFER,
    RECEIPT_OFFLINE_TYPE,
    TYPE_ORDER_PAYMENT,
    TYPE_PAYMENT_BP_SALES,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<DraftReceipt>> = yup.object().shape({
    id: yup.number(),
    account_id: yup.number().required("Cabang Account Required"),
    bank_account_id: yup.number().required("Bank Account Required"),
    transaction_date: yup.string().required("Transaction Date Required"),
    order_type_id: yup.number().required("Sales Type Required"),
    note: yup.string().required("Note Voucher Required"),
    partner_id: yup.number().notRequired(),
    child: yup.array(),
    expenses: yup.array(),
    receipt_images: yup.array(),
    receipt_amount: yup.number(),
    total_receipt_amount: yup.number(),
    payment_id: yup.number(),
});

const ReceiptEdit = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const id = searchParams.get("id");

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [invoiceList, setInvoiceList] = React.useState<InvoiceDocPayment[]>([]);
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);
    const [titlePartner, setTitlePartner] = React.useState<string>();
    const [dataSourceFilter, setDataSourceFilter] = React.useState<InvoiceDocPayment[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreatePayment>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const watchOrderType = watch("order_type_id");
    const watchPartnerId = watch("partner_id");
    const watchAccountId = watch("account_id");

    const detailQuery = useQuery(
        [receiptService.detailReceipt, id],
        async () => {
            return (await receiptService.DetailReceipt({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    transaction_date: moment(data.transaction_date),
                    note: data.note,
                });
                setValue("transaction_date", moment(data.transaction_date));
                setValue("bank_account_id", data.bank_account_id);
                setValue("order_type_id", data.order_type_id);
                setValue("note", data.note);
                setValue("partner_id", data.partner_id || 0);
                setValue("payment_id", data?.payment_id || 0);
                setValue("account_id", data?.account_id);
                const temp =
                    data.child?.map((itm) => ({
                        inv_id: itm.doc_id,
                        inv_no: itm.doc_no,
                        inv_ref: itm.inv_ref,
                        inv_ref_id: itm.inv_ref_id,
                        ref_id: itm.ref_id,
                        grand_total: itm.grand_total,
                        description: itm.description,
                        created_at: itm.date_invoice,
                        cc_charge: itm.cc_charge,
                    })) || [];
                setInvoiceList(temp);
                setDataSourceFilter(temp);
                setExpanses(data.expenses);
                setUrlImgList((data.receipt_images || [])?.filter((url) => url));
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const saveCreateMutate = useMutation(
        [receiptService.createReceipt],
        async (data: DraftReceipt) => {
            return (await receiptService.SaveCreateReceipt(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Receipt Voucher Success Post Jurnal");
                navigate(-1);
            },
        }
    );

    const saveDraftMutate = useMutation(
        [receiptService.draftReceipt],
        async (data: DraftReceipt) => {
            return (await receiptService.SaveDraftReceipt(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Receipt Voucher Save Draft");
                navigate(-1);
            },
        }
    );

    const filterDocMutate = useMutation(
        [paymentService.filterDoc],
        async (data: FilterDocPayment) => {
            return (await paymentService.FilterDoc(data)).data.data;
        },
        {
            onSuccess: (data) => {
                setInvoiceList(data);
            },
        }
    );

    const getAccountQuery = useQuery([paymentService.getAccount], async () => {
        return (await paymentService.GetAccount()).data.data?.map((el) => ({ label: el.account_name, value: el.account_id } as SelectOption));
    });

    const employeeQuery = useQuery([paymentService.getEmployee, watchAccountId], async () => {
        return (await paymentService.GetEmployee({ account_id: watchAccountId })).data.data?.map(
            (el) => ({ label: el.name, value: el.id } as SelectOption)
        );
    });

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    // const dataSourceFilter = invoiceList.filter((el) => selectedDocument.includes(el.inv_id));
    const total = dataSourceFilter?.reduce((t, curr) => Number(curr?.grand_total || 0) + t, 0);
    const grandTotal = (total === undefined ? 0 : total) - expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const onSaveCreateSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: DraftReceipt = {
            ...data,
            account_id: data.account_id,
            id: Number(id),
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            note: data.note,
            child: dataSourceFilter?.map((itm) => ({
                doc_id: itm.inv_id,
                grand_total: itm.grand_total,
                cc_charge: itm.cc_charge,
                ref_id: itm.ref_id,
            })),
            expenses: expanses,
            receipt_images: [...(filesBase64 || []), ...urlImgList],
            receipt_amount: total,
            total_receipt_amount: grandTotal,
        };

        saveCreateMutate.mutate(transformData);
    });

    const onSaveDraftSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: DraftReceipt = {
            ...data,
            account_id: data.account_id,
            id: Number(id),
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            note: data.note,
            child: dataSourceFilter?.map((itm) => ({
                doc_id: itm.inv_id,
                grand_total: itm.grand_total,
                cc_charge: itm.cc_charge,
                ref_id: itm.ref_id,
            })),
            expenses: expanses,
            receipt_images: [...(filesBase64 || []), ...urlImgList],
            receipt_amount: total,
            total_receipt_amount: grandTotal,
        };

        saveDraftMutate.mutate(transformData);
    });

    const onSaveCreateHandler = async () => {
        await form.validateFields();
        onSaveCreateSubmitHandler();
    };

    const onSaveDraftHandler = async () => {
        await form.validateFields();
        onSaveDraftSubmitHandler();
    };

    const removeItemFilter = (item: InvoiceDocPayment) => {
        return () => {
            setDataSourceFilter((prev) => prev?.filter((el) => el.inv_id !== item.inv_id));
        };
    };

    const columnsFilter: ColumnsType<InvoiceDocPayment> = [
        {
            title: "Action",
            render: (_, record) => <IoCloseSharp onClick={removeItemFilter(record)} className="text-red-400 text-lg cursor-pointer" />,
        },
        {
            title: "Doc No",
            dataIndex: "inv_no",
            render: (text, record) => {
                if (watchOrderType === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalJournalDetail title="Detail Jurnal" id={record.inv_id}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalJournalDetail>
                    );
                return (
                    <ModalRefVoucher title="Invoice" id={record.inv_id} type={watchOrderType}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalRefVoucher>
                );
            },
        },
        {
            title: "Inv Ref",
            dataIndex: "inv_ref",
            render: (text, record) => {
                if (watchOrderType === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalRefDoc title="Invoice" id={record.inv_ref_id} type={watchOrderType}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalRefDoc>
                    );
                return <span className="text-sm">{text}</span>;
            },
        },
        ...columnsFilterDocPayment,
        ...(detailQuery.data?.order_type_id === ORDER_PAYMENT_OFFLINE ? columnsCCAndPaymentMethod : []),
    ];

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    const isAddInvoice = () => {
        if (watchOrderType === ORDER_PAYMENT_ONLINE) {
            return !watchPartnerId;
        }
        return !watchOrderType;
    };

    const onChangeSalesType = (mId: any) => {
        setValue("order_type_id", mId);
        if (mId === ORDER_PAYMENT_AUTOLOKA) {
            setTitlePartner("Autoloka");
        }
        if (mId === ORDER_PAYMENT_BP_SALES) {
            setValue("payment_id", undefined);
        } else {
            setValue("payment_id", 0);
        }
        setDataSourceFilter([]);
        setExpanses([]);
    };

    const onChangePartnerType = (mId: any) => {
        setValue("partner_id", mId);
        setTitlePartner(marketplaceQuery?.data?.find((item) => item.value === mId)?.label);
        setDataSourceFilter([]);
        setExpanses([]);
    };

    const onChangeOfflinePartner = (mId: any) => {
        setValue("partner_id", mId);
        setTitlePartner(`Offline ${RECEIPT_OFFLINE_TYPE.find((item) => item.value === mId)?.label}`);
        setDataSourceFilter([]);
        setExpanses([]);
    };

    const onChangeCabang = (mId: any) => {
        setValue("account_id", mId);
        setDataSourceFilter([]);
        setExpanses([]);
    };

    const onDeleteImageUrl = (image: any) => {
        return (e: any) => {
            e.stopPropagation();
            setUrlImgList((prev) => prev?.filter((url) => url !== image));
        };
    };

    const onAddInvoice = (data: InvoiceDocPayment[]) => {
        const removeDuplicateInv = [...dataSourceFilter, ...data]?.reduce((prev: InvoiceDocPayment[], curr) => {
            if (prev.find((inv) => inv.inv_id === curr.inv_id)) return prev;
            return [...prev, curr];
        }, []);
        setDataSourceFilter(removeDuplicateInv);
    };

    const handlePreviewExistImage = (url: string) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit receipt voucher"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <>
                            <Button
                                disabled={!dataSourceFilter.length}
                                loading={saveDraftMutate.isLoading}
                                onClick={onSaveDraftHandler}
                                type="default"
                                className="!flex !items-center"
                            >
                                <RiDraftLine className="m-0 mr-2" />
                                Save Draft
                            </Button>
                            <Button
                                disabled={!dataSourceFilter.length}
                                loading={saveCreateMutate.isLoading}
                                onClick={onSaveCreateHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                <BiSave className="m-0 mr-2" />
                                Post Jurnal
                            </Button>
                        </>
                    </Space>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                                <Card className="">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                                        </p>
                                        <ControlledSelectInput
                                            control={control}
                                            name="account_id"
                                            placeholder="Cabang Account"
                                            label="Cabang Account"
                                            showSearch
                                            options={getAccountQuery.data}
                                            loading={getAccountQuery.isLoading}
                                            onChange={onChangeCabang}
                                        />
                                        <BankAccountSelect control={control} name="bank_account_id" placeholder="Bank Account" label="Bank Account" />
                                        <ControlledInputDate
                                            control={control}
                                            name="transaction_date"
                                            placeholder="Transaction Date"
                                            label="Transaction Date"
                                        />
                                        <>
                                            {watchOrderType === ORDER_PAYMENT_BP_SALES ? (
                                                <ControlledSelectInput
                                                    control={control}
                                                    name="payment_id"
                                                    placeholder="Payment BP Sales"
                                                    label="Payment BP Sales"
                                                    options={TYPE_PAYMENT_BP_SALES}
                                                />
                                            ) : null}
                                        </>
                                        <ControlledInputTextArea
                                            control={control}
                                            name="note"
                                            placeholder="Note Voucher"
                                            label="Note Voucher"
                                            rows={2}
                                        />
                                    </div>
                                </Card>
                                <Card className="">
                                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <ControlledSelectInput
                                            control={control}
                                            name="order_type_id"
                                            placeholder="Sales Type"
                                            label="Sales Type"
                                            options={TYPE_ORDER_PAYMENT}
                                            onChange={onChangeSalesType}
                                        />
                                        <>
                                            {watchOrderType === ORDER_PAYMENT_ONLINE ? (
                                                <ControlledSelectInput
                                                    showSearch
                                                    name="partner_id"
                                                    label="Market Place"
                                                    placeholder="Market Place"
                                                    optionFilterProp="children"
                                                    control={control}
                                                    loading={marketplaceQuery.isLoading}
                                                    options={marketplaceQuery.data || []}
                                                    onChange={onChangePartnerType}
                                                />
                                            ) : (
                                                <>
                                                    {watchOrderType === ORDER_PAYMENT_EMPLOYESS ? (
                                                        <ControlledSelectInput
                                                            showSearch
                                                            name="partner_id"
                                                            label="Employee Name"
                                                            placeholder="Employee Name"
                                                            optionFilterProp="children"
                                                            control={control}
                                                            loading={employeeQuery.isLoading}
                                                            options={employeeQuery.data || []}
                                                        />
                                                    ) : (
                                                        <>
                                                            {watchOrderType === ORDER_PAYMENT_BP_SALES ? (
                                                                <BusinessPartnerSelect
                                                                    control={control}
                                                                    name="partner_id"
                                                                    placeholder="Partner Name"
                                                                    label="Partner Name"
                                                                />
                                                            ) : (
                                                                <div />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>

                                        <p className="capitalize m-0">
                                            Invoice Total
                                            <br />
                                            <span className="font-semibold m-0">{total?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                            <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                                        </p>
                                        <div className="col-span-2 flex flex-col gap-5 pr-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                                            <>
                                                {watchOrderType === ORDER_PAYMENT_EMPLOYESS ? (
                                                    <FilterPiutangReceipt
                                                        accountID={watchAccountId}
                                                        title={titlePartner}
                                                        type={watchOrderType}
                                                        partnerId={watchPartnerId}
                                                        total={total}
                                                        onAddInvoice={onAddInvoice}
                                                    >
                                                        {(ctrl) => (
                                                            <Button
                                                                onClick={ctrl.openModal}
                                                                type="primary"
                                                                className="!self-start"
                                                                disabled={!watchPartnerId}
                                                            >
                                                                Add Piutang
                                                            </Button>
                                                        )}
                                                    </FilterPiutangReceipt>
                                                ) : (
                                                    <FilterDocumentPayment
                                                        accountID={watchAccountId}
                                                        title={titlePartner}
                                                        type={watchOrderType}
                                                        partnerId={watchPartnerId}
                                                        total={total}
                                                        onAddInvoice={onAddInvoice}
                                                    >
                                                        {(ctrl) => (
                                                            <Button
                                                                onClick={ctrl.openModal}
                                                                type="primary"
                                                                className="!self-start"
                                                                disabled={isAddInvoice()}
                                                            >
                                                                Add Invoice
                                                            </Button>
                                                        )}
                                                    </FilterDocumentPayment>
                                                )}
                                            </>
                                            <Table
                                                scroll={{ x: 800 }}
                                                rowKey={(rec) => rec.inv_id}
                                                columns={columnsFilter}
                                                dataSource={dataSourceFilter}
                                                pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                                style={{ borderBottom: "1px solid #d9d9d9" }}
                                                className="pb-2"
                                            />
                                            <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                <div>
                                                    <BiImageAdd className="text-2xl" />
                                                    <div style={{ marginTop: 8 }}>Upload</div>
                                                </div>
                                            </Upload>
                                            <div className="grid grid-cols-6 gap-4">
                                                {urlImgList?.map((url) => (
                                                    <button
                                                        onClick={() => handlePreviewExistImage(url)}
                                                        type="button"
                                                        className="relative border-none bg-transparent"
                                                    >
                                                        <ButtonDelete onClick={onDeleteImageUrl(url)} />
                                                        <ImageCard image={url} title="document" />
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <>
                                                {expanses.length !== 0 ? (
                                                    <div>
                                                        <p className="capitalize m-0">Expenses</p>
                                                        <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </>
                                            <ModalAddExpenses onCreate={onAddExpenses} paymentType={0}>
                                                {(dt) => (
                                                    <div className="w-full flex flex-col gap-1">
                                                        <Button
                                                            onClick={dt.openModal}
                                                            type="primary"
                                                            className="!self-start"
                                                            disabled={
                                                                (dataSourceFilter === undefined ? 0 : dataSourceFilter.length) === 0 ||
                                                                (watchOrderType === ORDER_PAYMENT_OFFLINE &&
                                                                    watchPartnerId === RECEIPT_OFFLINE_TRANSFER)
                                                            }
                                                        >
                                                            Add Expenses
                                                        </Button>
                                                    </div>
                                                )}
                                            </ModalAddExpenses>
                                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                                            <p className="capitalize m-0">
                                                Receipt Total
                                                <br />
                                                <span className="font-semibold m-0">{grandTotal?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default ReceiptEdit;

import React from "react";
import { MdDeleteOutline } from "react-icons/md";

type Props = React.HTMLAttributes<HTMLButtonElement>;

const ButtonDelete = ({ className, ...props }: Props) => {
    return (
        <button
            title="Delete"
            type="button"
            className={`cursor-pointer w-10 h-10 rounded-md border-none bg-transparent absolute top-2 right-2 z-10 !bg-red-400 opacity-70 hover:opacity-100 flex items-center justify-center ${className}`}
            {...props}
        >
            <MdDeleteOutline className="text-white text-2xl" />
        </button>
    );
};

export default ButtonDelete;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";
import { CreateDataDocInventory, DetailDataDocInventory, LocatorData, ProductDocInv } from "modules/master-data/doc-inventory/models";

class DocInventoryService extends BaseService {
    getAll = "/doc-inv/all-doc-inventory";

    search = "/doc-inv/search-doc-inventory";

    create = "doc-inv/add-doc-inventory";

    detail = "doc-inv/detail-doc-inventory";

    product = "doc-inv/get-product";

    locator = "doc-inv/get-locator";

    constructor() {
        super();
    }

    GetAll<T extends Models.MasterDataDocInventory>(param: { page: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataDocInventory>(param: { page: any; param_search: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Detail<T extends DetailDataDocInventory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataDocInventory, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    Product<T extends ProductDocInv[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.product,
            });
            return req;
        });
    }

    Locator<T extends LocatorData[]>(param: { product_id: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.locator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const docInventoryService = new DocInventoryService();
export default docInventoryService;

import { Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { STATUS_ACTIVE } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterItemsProducts = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Filter Items Product"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Package Name"
                            value={dt.values.product_name}
                            name="product_name"
                            onChange={(e) => dt.setOneValue("product_name", e.target.value)}
                        />
                        <Input
                            placeholder="Package Code"
                            value={dt.values.product_code}
                            name="product_code"
                            onChange={(e) => dt.setOneValue("product_code", e.target.value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_active !== null && dt.values.is_active !== undefined ? Number(dt.values.is_active) : dt.values.is_active
                            }
                            placeholder="Status"
                            options={STATUS_ACTIVE}
                            onChange={(value) => dt.setOneValue("is_active", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterItemsProducts;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataProduct, FEditDataProduct, FilterDataProduct } from "modules/master-data/product/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface UOM {
    uom_id: number;
    uom_code: string;
    uom_name: string;
}

export interface Model {
    id?: number;
    model_name?: string;
    model_year?: number[];
    model_code?: string;
    model_type_id?: any;
    model_type_name?: any;
}

export interface Product {
    id?: number;
    type?: number;
    model?: Model[];
    model_name?: string;
    model_year?: number[];
    model_type_id?: any;
    model_type_name?: any;
    subcategory_id?: number;
    subcategory_name?: string;
    style_id?: number;
    style_name?: string;
    attribute_id?: number;
    attribute_name?: string;
    subattribute_id?: number;
    subattribute_name?: string;
    subattribute_code?: string;
    code1?: string;
    code2?: string;
    molding_code?: string;
    name?: string;
    description?: string;
    is_active?: number;
    images?: string[];
    uom_id?: any;
    sku?: any;
    brand_code?: string;
    category_id?: number;
    category_name?: string;
    category_type?: string;
}

class ProductService extends BaseService {
    getAll = "/master-product/all-products";

    delete = "/master-product/delete-product";

    search = "/master-product/search-master-product";

    filter = "/master-product/filter-product";

    create = "/master-product/create-product";

    getUom = "/master-product/get-uom";

    detail = "/master-product/get-detail-product";

    edit = "/master-product/edit-product";

    constructor() {
        super();
    }

    Edit<T extends any>(data: FEditDataProduct, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends Product>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${params.id}`,
            });
            return req;
        });
    }

    GetUom<T extends UOM[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getUom,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataProduct, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataProduct>(param: {
        model_type_id: any;
        subcategory_id: any;
        style_id: any;
        attribute_id: any;
        subattribute_id: any;
        page: any;
    }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataProduct>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataProduct>(param: FilterDataProduct) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const productService = new ProductService();
export default productService;

/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ColumnType } from "antd/lib/table";
import { DetailProduct } from "services/api-endpoints/dashboard/master-data/bp-order";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function addProductTable<T extends DetailProduct>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text, record) => (
                    <p className={!record.order_detail_id ? "text-green-500 capitalize m-0" : "capitalize m-0"}>
                        {Number(text)?.ToIndCurrency("Rp")}
                    </p>
                ),
            },
            {
                title: "Estimated Price",
                dataIndex: "-",
                render: (text, record) => (
                    <p className={!record.order_detail_id ? "text-green-500 capitalize m-0" : "capitalize m-0"}>
                        {(Number(record.qty || 1) * Number(record.product_price))?.ToIndCurrency("Rp")}
                    </p>
                ),
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                fixed: "right",
                width: "75px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Note",
                dataIndex: "note",
                align: "center",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) =>
            record.product_id === edited?.product_id && record.order_detail_id === edited?.order_detail_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id && record.order_detail_id === edited?.order_detail_id;
        const rowKey = (record: T) => (record.product_id && record.order_detail_id)! as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
            />
        );
    };
}

const ProductTable = addProductTable(EditTable);
export default ProductTable;

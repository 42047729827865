import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React from "react";
import { useNavigate } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/model/lib/is-active-select";
import InputFile from "components/form/inputs/input-file";
import { IMAGE_TYPES } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import useProgressUpload from "hooks/useProgressUpload";
import { CreateDataModel } from "modules/master-data/model/models";
import BrandNameSelect from "modules/master-data/model/lib/brand-name-select";
import ModelYearSelect from "modules/master-data/model/lib/model-year-select";
import ParentNameSelect from "modules/master-data/model/lib/parent-name-select";
import ModelTypeSelect from "modules/master-data/model/lib/model-type-select";

const schema: yup.SchemaOf<Partial<CreateDataModel>> = yup.object().shape({
    brand_id: yup.number().required("Brand name required"),
    model_type_id: yup.string().nullable().required("Model type required"),
    parent_id: yup.string(),
    model_name: yup.string().required("Brand type required"),
    model_code: yup.string().required("Popular required"),
    model_year: yup.array().nullable(),
    is_active: yup.number().required("Is Active required"),
    model_image: yup.string().required("Header Image required"),
});

const ModelAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [form] = Form.useForm();

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
    } = useForm<CreateDataModel>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const parentID = watch("parent_id");

    const [controlFile] = useControlFile<CreateDataModel>({
        setValue,
        errors: errors as any,
    });

    const createMutation = useMutation(
        [modelService.create],
        async (data: CreateDataModel) => {
            return (await modelService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Model created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    const onChangeParent = (data: string | null) => {
        setValue("parent_id", data);
        if (data === "") {
            setValue("model_year", []);
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new model"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-2 gap-4">
                    <div className="grid grid-cols-2 gap-4">
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="model_image" className="col-span-2">
                            {(dt) => <ImageCard image={dt.file} title="model image" />}
                        </InputFile>
                    </div>
                    <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">brand information</h1>
                        <BrandNameSelect control={control} label="Brand Type" name="brand_id" placeholder="Brand type" />

                        <h1 className="capitalize font-semibold text-gray-500 mb-4">model information</h1>
                        <div className="grid grid-cols-2 gap-x-5">
                            <ParentNameSelect
                                control={control}
                                label="Parent Name"
                                name="parent_id"
                                placeholder="Parent Name"
                                onChange={onChangeParent}
                            />
                            <ControlledInputText control={control} label="Model Name" name="model_name" placeholder="Model Name" />
                            <ModelYearSelect
                                label="Model Year"
                                mode="multiple"
                                control={control}
                                name="model_year"
                                placeholder="Model Year"
                                showSearch
                                disabled={!parentID}
                            />
                            <ModelTypeSelect control={control} label="Model Type" name="model_type_id" placeholder="Model Type" />
                            <ControlledInputText control={control} label="Model Code" name="model_code" placeholder="Model Code" />
                            <IsActiveSelect control={control} label="Is Active" name="is_active" placeholder="Is Active" />
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default ModelAdd;

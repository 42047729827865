import React, { useRef } from "react";

type ReturnType = [progress: React.ReactNode | null, onUploadProgress: (event: any) => void];

const useProgressUpload = () => {
    const ref = useRef<HTMLParagraphElement | null>(null);

    const progress = (
        <p ref={ref} className="capitalize hidden m-0">
            uploading: <span className="m-0">0%</span>
        </p>
    );

    const onUploadProgress = (event: any) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        ref.current?.classList.remove("hidden");

        const span = ref.current?.querySelector("span");
        if (span) {
            span.innerText = `${percentCompleted}%`;
        }
        if (percentCompleted === 100 && span) {
            setTimeout(() => {
                ref.current?.classList.add("hidden");
                span.innerText = "0%";
            }, 400);
        }
    };

    return [progress, onUploadProgress] as ReturnType;
};

export default useProgressUpload;

import { Button, Input } from "antd";
import ButtonAccessAdd from "components/button/button-access-add";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoFilter } from "react-icons/io5";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ADD_PATH } from "utils/constant";

type Props = {
    placeholder?: string;
    filter?: boolean;
    add?: boolean;
    defaultParams?: string[];
    prefixAddition?: () => void;
    postfixAddition?: () => void;
    filterHandler?: () => void;
    addHandler?: () => void;
    onSearchHandler?: (query: string) => void;
    search?: boolean;
};

const Toolbar = ({
    filter = true,
    add = true,
    defaultParams = [],
    addHandler,
    filterHandler,
    placeholder = "Search",
    onSearchHandler,
    prefixAddition,
    postfixAddition,
    search = true,
}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryUrl = searchParams.get("query");
    const [query, setQuery] = useState(queryUrl || "");

    const params = ["query", "page"];

    const { searchValue } = useTypeSearchUrl({
        search: [...params, ...defaultParams],
    });

    useEffect(() => {
        if (!queryUrl) {
            setQuery("");
        }
    }, [queryUrl]);

    const onSearch = (value: string) => {
        if (onSearchHandler) onSearchHandler(value);
        if (!value) {
            params.forEach((param) => {
                searchParams.delete(param);
            });
            setSearchParams(searchParams);
            return;
        }
        setSearchParams({
            ...searchValue,
            query: value,
            page: "1",
        });
    };

    const onClickAddHandler = () => {
        if (addHandler) {
            addHandler();
            return;
        }
        navigate(location.pathname + ADD_PATH);
    };

    return (
        <div className="w-full flex items-center bg-tertiary p-4 rounded-md gap-4 justify-between">
            <>
                <span />
                {prefixAddition && prefixAddition()}
                {search && (
                    <Input.Search value={query} allowClear placeholder={placeholder} onChange={(e) => setQuery(e.target.value)} onSearch={onSearch} />
                )}
                {filter && (
                    <Button onClick={filterHandler} type="primary" className="!flex !items-center !font-semibold">
                        <IoFilter className="mr-3" />
                        Filter
                    </Button>
                )}
                {add && <ButtonAccessAdd onClick={onClickAddHandler} type="primary" className="!flex !items-center !font-semibold" />}
                {postfixAddition && postfixAddition()}
            </>
        </div>
    );
};

export default Toolbar;

/* eslint-disable import/no-extraneous-dependencies */
import { Alert, Button, Layout as LayoutAntd, Skeleton, message } from "antd";
import Header from "components/header";
import { StateContext } from "context/state";
import { UserContext } from "context/user";
import useScrollToTop from "hooks/useScrollToTop";
import { MdGeneratingTokens } from "react-icons/md";
import ForbiddenPage from "pages/403";
import { useContext, useState } from "react";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { ACTION_PATHS, ADD_PATH, EDIT_PATH, VIEW_PATH } from "utils/constant";
import Sidebar from "./sidebar";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./error-page";
import HistoryTab from "./history-tab";

type Props = {
    children: (params: any) => any;
};

const Loading = () => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <LayoutAntd className="w-full flex min-h-screen">
            <LayoutAntd.Sider
                className="!overflow-auto !h-screen !fixed !left-0 !top-0 !bottom-0"
                theme="light"
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                width={230}
            >
                <Sidebar.Loading />
            </LayoutAntd.Sider>
            <LayoutAntd style={{ marginLeft: collapsed ? 80 : 230, transition: "all .4s" }} className="w-screen">
                <LayoutAntd.Content style={{ background: "#fbfbfb" }}>
                    <Header.Loading />
                    <div className="px-4 py-2 min-h-screen">
                        <br />
                        <br />
                        <Skeleton paragraph={{ rows: 3 }} active avatar />
                    </div>
                </LayoutAntd.Content>
            </LayoutAntd>
        </LayoutAntd>
    );
};

const Layout = ({ children }: Props) => {
    const { state } = useContext(UserContext);
    const { state: stateContext } = useContext(StateContext);
    const location = useLocation();
    const isActionPath = ACTION_PATHS.find((path) => location.pathname.includes(path));

    const onCopyToken = () => {
        if (navigator.clipboard.writeText && state.user?.token) {
            navigator.clipboard.writeText(state.user?.token);
            message.success("Token copied to clipboard");
        }
    };

    const onReload = () => {
        window?.location.reload();
    };

    const offlineAlert = () => {
        if (stateContext.online) return null;
        return (
            <Alert
                message="You seem to be offline. Data in this workspace might not be up to date."
                banner
                className="w-full"
                icon={<IoCloudOfflineOutline />}
                action={
                    <Button onClick={onReload} size="small" type="text">
                        Reload
                    </Button>
                }
            />
        );
    };

    return (
        <>
            {state?.user?.token && process.env.NODE_ENV === "development" && (
                <Button onClick={onCopyToken} shape="circle" className="!fixed !bottom-20 !left-4 z-500 shadow-my-shadow-1" type="primary">
                    <MdGeneratingTokens className="text-white text-2xl" />
                </Button>
            )}
            {children({ isActionPath, onReload, offlineAlert })}
        </>
    );
};

const Default = ({ children, backHandlerOverride }: { children: any; backHandlerOverride?: () => void }) => {
    const { readAccess, createAccess, updateAccess, state: stateContext, setState: setStateContext } = useContext(StateContext);

    useScrollToTop();

    const onCollapseSidebar = (value: boolean) => {
        if (setStateContext) {
            setStateContext((prev) => ({
                ...prev,
                menuCollapsed: value,
            }));
        }
    };

    return (
        <Layout>
            {({ isActionPath, onReload, offlineAlert }) => (
                <LayoutAntd className="w-full flex min-h-screen">
                    <LayoutAntd.Sider
                        className="!overflow-auto !h-screen !fixed !left-0 !top-0 !bottom-0"
                        theme="light"
                        collapsible
                        collapsed={stateContext.menuCollapsed}
                        onCollapse={onCollapseSidebar}
                        width={230}
                    >
                        <Sidebar collapse={stateContext.menuCollapsed!} />
                    </LayoutAntd.Sider>
                    <LayoutAntd style={{ marginLeft: stateContext.menuCollapsed ? 80 : 230, transition: "all .4s" }} className="w-screen">
                        <LayoutAntd.Content style={{ background: "#eeeeee" }}>
                            {offlineAlert()}
                            <HistoryTab />
                            <Header isActionPath={!!isActionPath} backHandlerOverride={backHandlerOverride} />
                            <ErrorBoundary FallbackComponent={ErrorPage}>
                                {!isActionPath ? <div className="px-4 py-2 min-h-screen">{readAccess ? children : <ForbiddenPage />}</div> : null}
                                {isActionPath === ADD_PATH ? (
                                    <div className="px-4 py-2 min-h-screen">{createAccess ? children : <ForbiddenPage />}</div>
                                ) : null}
                                {isActionPath === EDIT_PATH ? (
                                    <div className="px-4 py-2 min-h-screen">{updateAccess ? children : <ForbiddenPage />}</div>
                                ) : null}
                                {isActionPath === VIEW_PATH ? (
                                    <div className="px-4 py-2 min-h-screen">{readAccess ? children : <ForbiddenPage />}</div>
                                ) : null}
                            </ErrorBoundary>
                        </LayoutAntd.Content>
                    </LayoutAntd>
                </LayoutAntd>
            )}
        </Layout>
    );
};

Layout.Loading = Loading;
Layout.Default = Default;

export default Layout;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Image, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import JourneyPO from "modules/dashboard-jsl/penerimaan-barang/journey-po";
import Print from "modules/dashboard-jsl/penerimaan-barang/print";
import PenerimaanTableView from "modules/dashboard-jsl/penerimaan-barang/table-view";
import React, { useState } from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import penerimaanBarangService, { DetailPBChild } from "services/api-endpoints/dashboard/master-data/penerimaan-barang";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const PenerimaanBarangView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    const [childPO, setChildPO] = useState<DetailPBChild[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const letterRef = React.useRef<HTMLDivElement | null>(null);

    const detailQuery = useQuery(
        [penerimaanBarangService.detailPB, id],
        async () => {
            return (await penerimaanBarangService.DetailPB({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setChildPO(data.child);
                setUrlImgList((data.pb_images || [])?.filter((url) => url));
            },
        }
    );

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `GR Supplies - ${id}`,
        pageStyle: "@page {  }",
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail GR Supplies"
                rightAddition={() => (
                    <>
                        <Button onClick={() => handlePrintLetter()} type="default" className="!flex !items-center">
                            <AiOutlinePrinter className="m-0 mr-2" />
                            Print Document
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <p className="capitalize m-0">
                                        Document No <br />
                                        <span className="underline italic m-0">{detailQuery.data?.pb_no || "-"}</span>
                                    </p>
                                    <div className="">
                                        <div className="capitalize m-0">
                                            Vendor From <br />
                                            <span className="underline italic m-0">{detailQuery.data?.vendor_name || "-"}</span>
                                        </div>
                                    </div>
                                </div>
                                {urlImgList.length !== 0 ? (
                                    <>
                                        <p className="capitalize m-0 mt-4 mb-2">Bukti Penerimaan</p>
                                        <div className="grid grid-cols-6 gap-2">
                                            {(detailQuery.data?.pb_images || []).map((img) => (
                                                <Image src={img} />
                                            ))}
                                        </div>
                                    </>
                                ) : null}
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    items={[
                        {
                            label: `Items`,
                            key: "items",
                            children: <PenerimaanTableView list={childPO} setList={setChildPO} canRemove={false} action={false} />,
                        },
                        {
                            label: `Journey PO`,
                            key: "journey_po",
                            children: <JourneyPO paramId={detailQuery.data?.doc_id} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default PenerimaanBarangView;

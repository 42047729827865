import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BiPlus } from "react-icons/bi";
import { MenuRequest } from "services/api-endpoints/dashboard/master-data/menu";
import ModalAddMenu from "./modal";
import { SubMenuStruct } from "pages/menu/add";
import { Icon } from "@mui/material";
import { toLowerSnakeCase } from "utils";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { v4 as uuid } from "uuid";

export type ItemRowSubMenu = Pick<MenuRequest, "name" | "key" | "icon"> & { id?: any };

export type SubMenuAddTableProps = {
    list?: SubMenuStruct[];
    asParent?: boolean;
    onSubmit?: (data: ItemRowSubMenu, parentKey: string) => void;
    onDelete: (key: string) => void;
};

const SubMenuAddTable = ({ list, asParent, onSubmit, onDelete }: SubMenuAddTableProps) => {
    const onSubmitChildMenu = (data: ItemRowSubMenu, parentKey: any) => {
        if (onSubmit) onSubmit({ ...data, icon: data?.icon ? toLowerSnakeCase(data.icon) : "" }, parentKey);
    };

    const columns: ColumnsType<SubMenuStruct> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Menu",
            dataIndex: "name",
        },
        {
            title: "Key",
            dataIndex: "key",
        },
        {
            title: "Icon",
            dataIndex: "icon",
            render: (val) => (
                <div className="flex items-center gap-2">
                    {val && <Icon fontSize="medium">{val}</Icon>}
                    {val}
                </div>
            ),
        },
        {
            title: "Action",
            width: "100px",
            render: (_, record) => (
                <Space>
                    {asParent && (
                        <ModalAddMenu onSubmit={(val) => onSubmitChildMenu(val, record.key!)}>
                            {({ openModal }) => (
                                <Button onClick={openModal} type="text" className="!flex !items-center">
                                    <BiPlus className="m-0 mr-2" />
                                    Child Menu
                                </Button>
                            )}
                        </ModalAddMenu>
                    )}
                    <ModalAddMenu defaultValues={record} onSubmit={(val) => onSubmitChildMenu(val, undefined)}>
                        {({ openModal }) => <ButtonAccessEdit onClick={openModal} type="text" />}
                    </ModalAddMenu>
                    <ButtonAccessDelete onClick={() => onDelete(record.key!)} danger type="text" />
                </Space>
            ),
        },
    ];

    const childTable = {
        expandedRowRender: (rec: SubMenuStruct) => {
            if (!rec?.collapse || !rec?.collapse?.length) return null;
            return (
                <div className="w-full my-5 grayscale">
                    <SubMenuAddTable onSubmit={onSubmit} list={rec.collapse} onDelete={onDelete} />
                </div>
            );
        },
        rowExpandable: (rec: SubMenuStruct) => !!rec.key,
        defaultExpandAllRows: true,
    };

    return (
        <Table
            {...(asParent ? childTable : {})}
            rowKey={(record) => record.key!}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default SubMenuAddTable;

/* eslint-disable react/no-array-index-key */
import moment from "moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { FORMAT_DATE_TIME } from "utils/constant";
import { DetailProduct, DetailPurchasingInventory } from "services/api-endpoints/purchasing/inventory";

type Props = {
    data: DetailPurchasingInventory | null;
};

const PrintDO = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const [currentTime, setCurrentTime] = useState();

    const productPerPage = 10;

    const listDetailGroup = React.useMemo(() => {
        if (!data?.list_product) return [];
        const listProductCopy = [...(data!.list_product || [])];
        const temp: DetailProduct[][] = [];
        const totalPage = Math.ceil(listProductCopy.length / productPerPage);
        [...new Array(totalPage)].forEach(() => {
            temp.push(listProductCopy.splice(0, productPerPage));
        });
        return temp;
    }, [data]);

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            {listDetailGroup?.map((products, page) => (
                <div className="w-full bg-white p-5 break-after-page">
                    {page === 0 ? (
                        <>
                            <div className="w-full flex items-end justify-between">
                                <div className="max-w-[55%]">
                                    <h1 className="text-2xl uppercase m-0">PT. SEHATI PRIMA MAKMUR</h1>
                                    <p className="text-xs m-0">
                                        Rukan Paris Golf Lake Residence Blok B No 23-25 Cengkareng, RT.10/RW.14, East Cengkareng, Cengkareng, West
                                        Jakarta City, Jakarta 11730
                                    </p>
                                </div>
                                <h2 className="text-3xl font-medium m-0 capitalize">Delivery Order</h2>
                            </div>
                            <div className="w-full h-[2px] bg-gray-400 mt-2" />
                            <div className="w-full h-[2px] bg-gray-400 mt-1" />
                            <div className="w-full grid grid-cols-3 mt-4">
                                <div className="">
                                    <table>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">No</td>
                                            <td>{`: ${data?.header?.do_no}`}</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Tanggal</td>
                                            <td>: {moment().format("dddd DD MM yyyy")}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="flex justify-center">
                                    <QRCode size={70} value={(data?.header?.do_no as any) || ""} />
                                </div>
                            </div>
                        </>
                    ) : null}
                    <div className="flex flex-col mt-5">
                        <table>
                            <thead className="border border-solid border-gray-500">
                                <th className="border border-solid border-gray-500 px-2">No</th>
                                <th className="border border-solid border-gray-500">Kode</th>
                                <th className="border border-solid border-gray-500">Nama Produk</th>
                                <th className="border border-solid border-gray-500">Type Item</th>
                                <th className="border border-solid border-gray-500">Locator To</th>
                                <th className="border border-solid border-gray-500">Qty</th>
                            </thead>
                            <tbody className="border border-solid border-gray-500">
                                {products?.map((el, i) => (
                                    <tr key={i} className="border border-solid border-gray-500">
                                        <td className="border border-solid border-gray-500 text-center px-1">{i + 1 + productPerPage * page}</td>
                                        <td className="border border-solid border-gray-500 px-1 whitespace-nowrap">-</td>
                                        <td className="border border-solid border-gray-500 px-1">{el.product_name}</td>
                                        <td className="border border-solid border-gray-500 px-1">{el.type_name}</td>
                                        <td className="border border-solid border-gray-500 px-1 whitespace-nowrap">-</td>
                                        <td className="border border-solid border-gray-500 px-1 whitespace-nowrap">{el.qty} SET</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p className="self-end mt-2">{`Halaman ${page + 1} dari ${listDetailGroup.length}`}</p>
                    </div>
                    {page === listDetailGroup.length - 1 ? (
                        <div className="flex items-start justify-between">
                            {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                                <div className="flex flex-col items-center" key={i}>
                                    <p className="capitalize font-medium text-xs">{el}</p>
                                    <p className="text-3xl m-0 mt-10">(...........)</p>
                                </div>
                            ))}
                            <div className="">
                                <table className="text-xs">
                                    <tr>
                                        <td className="min-w-[100px]">Catatan</td>
                                        <td className="max-w-[200px]">: {data.header?.note || ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="min-w-[100px]">Print</td>
                                        <td>
                                            : - <br />
                                            {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
});

export default PrintDO;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import VoucherTable from "modules/master-data/voucher/index/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import voucherService from "services/api-endpoints/dashboard/master-data/voucher";

const Voucher = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([voucherService.getAll, page, searchValue, isSearch], async () => {
        if (isSearch) return (await voucherService.Search({ page, param_search: searchValue.query })).data.data;
        return (await voucherService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Voucher Code" filter={false} />
            <VoucherTable fetcher={getList} />
        </div>
    );
};

export default Voucher;

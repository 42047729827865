/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import hadirAppService, { CreateCategoryDutyTrip } from "services/api-endpoints/hadir-app/hadir-app-service";
import { STATUS_ACTIVE_DUTY_TRIP, TYPE_DUTY_TRIP } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateCategoryDutyTrip>> = yup.object().shape({
    name: yup.string().required("Name Required"),
    is_active: yup.number().required("Status Required"),
    type: yup.number().required("Type Required"),
});

const MasterCategoryDutyTripAdd = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreateCategoryDutyTrip>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation([hadirAppService.createCategoryDutyTrip], async (data: CreateCategoryDutyTrip) => {
        return (await hadirAppService.CreateCategoryDutyTrip(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        createMutate.mutateAsync(data).then(() => {
            navigate(-1);
            message.success("Success Create Category Duty Trip");
        });
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new raw category duty trip"
                rightAddition={() => (
                    <Button onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Create
                    </Button>
                )}
            />
            <Card>
                <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <h1 className="capitalize font-semibold text-gray-500 mb-4">Material</h1>
                    <div className="w-full grid grid-cols-2 gap-x-4">
                        <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                        <ControlledSelectInput
                            classNameForm="col-span-1"
                            control={control}
                            name="is_active"
                            options={STATUS_ACTIVE_DUTY_TRIP}
                            placeholder="Status"
                            label="Status"
                        />
                        <ControlledSelectInput
                            classNameForm="col-span-1"
                            control={control}
                            name="type"
                            options={TYPE_DUTY_TRIP}
                            placeholder="Type"
                            label="Type"
                        />
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default MasterCategoryDutyTripAdd;

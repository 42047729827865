/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import { useNavigate, useSearchParams } from "react-router-dom";
import { STATUS_PURCHASE_ORDER } from "utils/constant";
import ModalDelete from "components/modal/delete_modal";
import { BiTrash } from "react-icons/bi";
import { getDocumentRoute } from "utils/routes";
import pickingListService, { DeleteData, DetailList } from "services/api-endpoints/dashboard-jsl/picking-list";
import ProductTable from "modules/dashboard-jsl/picking-list/view/product-table";

const PickingListView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const [products, setProducts] = useState<DetailList[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [pickingListService.detailPL, id],
        async () => {
            return (await pickingListService.DetailPL({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    // const history = useQuery(
    //     [salesOrderService.historySO, page],
    //     async () => {
    //         return (await salesOrderService.HistorySO({ page: page as any, id })).data.data;
    //     },
    //     {
    //         enabled: !!id,
    //     }
    // );

    const deletePLMutation = useMutation(
        [pickingListService.deletePL],
        async (data: DeleteData) => {
            return (await pickingListService.DeletePL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Picking List deleted");
                navigate(getDocumentRoute({ menuName: "picking-list", page: "index" }), { replace: true });
            },
        }
    );

    const onDeletePLHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePLMutation.mutate(dataDelete);
    };

    const onClickLinkHandler = (id: number) => {
        setDetailHistoryID(id);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Picking list detail"
                rightAddition={() => (
                    <ModalDelete title="Delete PL" onSubmit={onDeletePLHandler}>
                        {(dt) => (
                            <Button
                                loading={deletePLMutation.isLoading}
                                disabled={detailQuery.data?.order_status === 2 || detailQuery.data?.order_status === -1}
                                onClick={dt.openModal}
                                type="default"
                                className="!flex !items-center text-red-600"
                            >
                                <BiTrash className="m-0 mr-2 text-red-600" />
                                Delete
                            </Button>
                        )}
                    </ModalDelete>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        picking list no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.pl_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-600" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        // {
                        //     label: `History`,
                        //     key: "2",
                        //     children: (
                        //         <ModalHistorySO idHistory={detailHistoryID}>
                        //             {(dt) => (
                        //                 <HistoryTable
                        //                     fetcher={history}
                        //                     onClickLink={(id) => {
                        //                         onClickLinkHandler(id);
                        //                         dt.openModal();
                        //                     }}
                        //                 />
                        //             )}
                        //         </ModalHistorySO>
                        //     ),
                        // },
                    ]}
                />
            </Card>
        </div>
    );
};

export default PickingListView;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import PemakaianBarangView from "pages/pemakaian-barang/view";
import PenerimaanBarangView from "pages/penerimaan-barang/view";
import { useSearchParams } from "react-router-dom";
import { History } from "services/api-endpoints/dashboard/master-data/quantity";
import { TYPE_DOC_SUPPLIES_PEMAKAIAN_BARANG, TYPE_DOC_SUPPLIES_PENERIMANAN_BARANG } from "utils/constant";

export type Props = ModalProps & {
    data?: History | null;
};

const DetailModal = ({ data, children, ...props }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const removeDetailParams = () => {
        searchParams.delete("preview_page");
        setSearchParams(searchParams);
    };

    const isModalOpen = (isOpen: boolean) => {
        if (isOpen) {
            searchParams.set("preview_page", "1");
            setSearchParams(searchParams);
        }
    };

    return (
        <ModalTemplate
            isModalOpenFn={isModalOpen}
            afterClose={removeDetailParams}
            width={1300}
            title={<p>{data?.doc_no || "-"}</p>}
            handlerInComponent={children}
            footer={null}
            {...props}
        >
            {(dt) => {
                if (data?.doc_type_id === TYPE_DOC_SUPPLIES_PENERIMANAN_BARANG) {
                    return <PenerimaanBarangView paramId={data?.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_SUPPLIES_PEMAKAIAN_BARANG) {
                    return <PemakaianBarangView paramId={data?.doc_id} />;
                }
                return <p className="text-red-400 text-xl">Doc type not handled yet</p>;
            }}
        </ModalTemplate>
    );
};

export default DetailModal;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import RoleModalAdd from "modules/master-data/role/add/modal-add";
import RoleTable from "modules/master-data/role/index/table";
import { useQuery } from "react-query";
import roleService from "services/api-endpoints/dashboard/master-data/role";

const Role = () => {
    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([roleService.listRole, searchValue, isSearch], async () => {
        return (await roleService.ListRole({ query: searchValue.query })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <RoleModalAdd refresh={getList.refetch}>
                {(ctrl) => <Toolbar addHandler={ctrl.openModal} placeholder="Searching..." filter={false} />}
            </RoleModalAdd>
            <RoleTable fetcher={getList} />
        </div>
    );
};

export default Role;

import { AxiosResponse } from "axios";
import { message, notification } from "antd";
import { BaseResponse } from "models";
import { DEFAULT_ERROR_MESSAGE, INTERNAL_SERVER_ERROR, UNAUTHORIZED, UNAUTHORIZED_MESSAGE } from "utils/constant";

/* eslint-disable class-methods-use-this */
class BaseService {
    async ProxyRequest<T>(request: () => Promise<AxiosResponse<BaseResponse<T>, any>>): Promise<AxiosResponse<BaseResponse<T>, any>> {
        try {
            const req = await request();
            const status = req.data?.status;
            if (status !== 200) {
                const msg = (() => {
                    if (req.data?.message) return req.data?.message;
                    if (status === 500) return INTERNAL_SERVER_ERROR;
                    if (status === 401) return UNAUTHORIZED;
                    return DEFAULT_ERROR_MESSAGE;
                })();
                throw new Error(msg);
            }
            return req;
        } catch (error: any) {
            const msg = error?.message || DEFAULT_ERROR_MESSAGE;
            if (msg === DEFAULT_ERROR_MESSAGE || msg === INTERNAL_SERVER_ERROR) {
                message.error({ key: msg, content: msg });
            } else {
                notification.error({
                    message: msg === UNAUTHORIZED ? UNAUTHORIZED?.CapitalizeFirstLetter() : "Error",
                    description: msg === UNAUTHORIZED ? UNAUTHORIZED_MESSAGE?.CapitalizeFirstLetter() : msg,
                    key: msg,
                });
            }
            throw new Error(msg);
        }
    }

    async ProxyRequestNoAxios<T>(request: () => Promise<T>): Promise<T> {
        try {
            const req = await request();
            return req;
        } catch (error: any) {
            const msg = error?.message || DEFAULT_ERROR_MESSAGE;
            if (msg === DEFAULT_ERROR_MESSAGE || msg === INTERNAL_SERVER_ERROR) {
                message.error({ key: msg, content: msg });
            } else {
                notification.error({
                    message: msg === UNAUTHORIZED ? UNAUTHORIZED?.CapitalizeFirstLetter() : "Error",
                    description: msg === UNAUTHORIZED ? UNAUTHORIZED_MESSAGE?.CapitalizeFirstLetter() : msg,
                    key: msg,
                });
            }
            throw new Error(msg);
        }
    }
}

export default BaseService;

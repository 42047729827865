/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { AllWarehouse, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import { FCreateProductPrice } from "../models";

export type SelectOptionCustom = SelectOption & AllWarehouse;

export interface Props extends ControlledInputSelectProps<FCreateProductPrice> {
    onSelectedData?: (data: AllWarehouse | null | undefined) => void;
}

export function withWarehouse<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([priceService.listAccount], async () => {
            const req = await priceService.ListAccount();
            return req.data.data?.map((el) => ({ ...el, label: el.warehouse_name, value: el.warehouse_id } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.value === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const WarehouseSelect = withWarehouse(ControlledSelectInput);

export default WarehouseSelect;

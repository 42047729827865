/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Space, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import FilterDocInternalOrder, { columnsFilterInternalPayment } from "modules/master-data/payment/add/filter-doc-internal-order";
import FilterDocumentPayment2, { columnsFilterDocPayment } from "modules/master-data/payment/add/filter-document2";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalAddExpenses from "modules/master-data/payment/add/modal-add-expenses";
import ModalDetailInv from "modules/master-data/payment/modal/modal-detail-inv";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import paymentService, {
    CreatePaymentV,
    FilterInternal,
    FilterInternalList,
    FilterPurchasing,
    FilterPurchasingList,
} from "services/api-endpoints/dashboard/payment";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import Utils from "utils";
import {
    AUTOGENERATED,
    DOC_TYPE_PURCHASING,
    FORMAT_DATE_1,
    PAYMENT_INTERNAL_ORDER,
    PAYMENT_LOAN_INTERNAL,
    PAYMENT_OTHER,
    PAYMENT_PURCHASING,
    PAYMENT_TYPE,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePaymentV>> = yup.object().shape({
    account_id: yup.number().required("Cabang Account Required"),
    bank_account_id: yup.number().required("Bank Account Required"),
    transaction_date: yup.string().required("Transaction Date Required"),
    payment_type: yup.number().required("Payment Type Required"),
    note: yup.string().required("Note Voucher Required"),
    purchasing_type: yup.number(),
    child: yup.array(),
    expenses: yup.array(),
    payment_images: yup.array(),
    payment_amount: yup.number(),
    total_payment_amount: yup.number(),
    vendor_id: yup.number(),
});

const PaymentAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [selectedDocument, setSelectedDocument] = React.useState<React.Key[]>([]);
    const [dataFilterInternal, setDataFilterInternal] = React.useState<FilterInternalList[]>([]);

    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);
    const [titlePartner, setTitlePartner] = React.useState<string>();

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreatePaymentV>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchPaymentType = watch("payment_type");
    const watchPurchasing = watch("purchasing_type");
    const watchVendorId = watch("vendor_id");
    const watchAccountId = watch("account_id");

    const createMutate = useMutation(
        [paymentService.createPaymentV],
        async (data: CreatePaymentV) => {
            return (await paymentService.CreatePaymentV(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Payment Voucher Paid");
                navigate(-1);
            },
        }
    );

    const draftMutate = useMutation(
        [paymentService.draftPaymentV],
        async (data: CreatePaymentV) => {
            return (await paymentService.DraftPaymentV(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Payment Voucher Drafted");
                navigate(-1);
            },
        }
    );

    const getAccountQuery = useQuery([paymentService.getAccount], async () => {
        return (await paymentService.GetAccount()).data.data?.map((el) => ({ label: el.account_name, value: el.account_id } as SelectOption));
    });

    const bankAccountQuery = useQuery([paymentService.getBA], async () => {
        return (await paymentService.GetBA()).data.data?.map((el) => ({ label: el.bank_account_no, value: el.bank_account_id } as SelectOption));
    });

    const getVendor = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const filterPurchasingMutate = useMutation([paymentService.filterPurchasing], async (data: FilterPurchasing) => {
        return (await paymentService.FilterPurchasing(data)).data.data;
    });

    const filterInternalMutate = useMutation([paymentService.filterInternalOrder], async (data: FilterInternal) => {
        return (await paymentService.FilterInternalOrder(data)).data.data;
    });

    const dataSourceFilter = filterPurchasingMutate.data?.filter((el) => selectedDocument.includes(el.po_id));

    const total =
        watchPaymentType === PAYMENT_PURCHASING
            ? dataSourceFilter?.reduce((t, curr) => Number(curr?.grand_total || 0) + t, 0)
            : dataFilterInternal?.reduce((t, curr) => Number(curr?.grand_total || 0) + t, 0);

    const grandTotal = (total === undefined ? 0 : total) + expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const onPType = () => {
        if (watchPaymentType === PAYMENT_PURCHASING) {
            return watchPaymentType;
        }
        if (watchPaymentType === PAYMENT_INTERNAL_ORDER) {
            return watchPaymentType;
        }
        if (watchPaymentType === PAYMENT_LOAN_INTERNAL) {
            return PAYMENT_LOAN_INTERNAL;
        }
        return PAYMENT_OTHER;
    };

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: CreatePaymentV = {
            ...data,
            account_id: data.account_id,
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            note: data.note,
            purchasing_type: onPType(),
            child:
                watchPaymentType === PAYMENT_PURCHASING
                    ? dataSourceFilter?.map((itm) => ({
                          doc_id: itm.po_id,
                          grand_total: itm.grand_total,
                          total_tax: itm.total_tax,
                          total_disc: itm.total_disc,
                          total_price: itm.total_price,
                      }))
                    : dataFilterInternal?.map((itm) => ({
                          doc_id: itm.id,
                          grand_total: itm.grand_total,
                      })),
            expenses: expanses,
            payment_images: filesBase64 || null,
            payment_amount: total,
            total_payment_amount: grandTotal,
        };

        createMutate.mutate(transformData);
    });

    const onDraftSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: CreatePaymentV = {
            ...data,
            account_id: data.account_id,
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            note: data.note,
            purchasing_type: onPType(),
            child:
                watchPaymentType === PAYMENT_PURCHASING
                    ? dataSourceFilter?.map((itm) => ({
                          doc_id: itm.po_id,
                          grand_total: itm.grand_total,
                          total_tax: itm.total_tax,
                          total_disc: itm.total_disc,
                          total_price: itm.total_price,
                      }))
                    : dataFilterInternal?.map((itm) => ({
                          doc_id: itm.id,
                          grand_total: itm.grand_total,
                      })),
            expenses: expanses,
            payment_images: filesBase64 || null,
            payment_amount: total,
            total_payment_amount: grandTotal,
        };

        draftMutate.mutate(transformData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        onDraftSubmitHandler();
    };

    const removeItemFilter = (item: FilterPurchasingList) => {
        return () => {
            setSelectedDocument((prev) => prev?.filter((el) => el !== item.po_id));
        };
    };

    const columnsFilter: ColumnsType<FilterPurchasingList> = [
        {
            title: "Action",
            render: (_, record) => <IoCloseSharp onClick={removeItemFilter(record)} className="text-red-400 text-lg cursor-pointer" />,
        },
        {
            title: "PO No",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <ModalDetailInv title="Invoice Detail" id={record.po_id} type={PAYMENT_PURCHASING}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalDetailInv>
                );
            },
        },
        ...columnsFilterDocPayment,
    ];

    const removeItemFilterInternal = (item: FilterInternalList) => {
        return () => {
            setDataFilterInternal((prev) => prev?.filter((el) => el.id !== item.id));
        };
    };

    const columnsFilterInternal: ColumnsType<FilterInternalList> = [
        {
            title: "Action",
            render: (_, record) => <IoCloseSharp onClick={removeItemFilterInternal(record)} className="text-red-400 text-lg cursor-pointer" />,
        },
        {
            title: "Invoice No",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <ModalDetailInv title="Invoice Detail" id={record.id} type={PAYMENT_INTERNAL_ORDER}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalDetailInv>
                );
            },
        },
        ...columnsFilterInternalPayment,
    ];

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    const isAddExpenses = () => {
        if ((dataSourceFilter === undefined ? 0 : dataSourceFilter.length) !== 0) {
            return false;
        }
        if (watchPaymentType === PAYMENT_OTHER) {
            return false;
        }
        if (watchPaymentType === PAYMENT_LOAN_INTERNAL) {
            return false;
        }
        if ((dataFilterInternal === undefined ? 0 : dataFilterInternal.length) !== 0) {
            return false;
        }
        return true;
    };

    const onChangeCategory = (id: any) => {
        setValue("purchasing_type", id);
        setTitlePartner(DOC_TYPE_PURCHASING.find((item) => item.value === id)?.label);
    };

    const onAddInvoice = (data: FilterInternalList[]) => {
        const removeDuplicateInv = [...dataFilterInternal, ...data]?.reduce((prev: FilterInternalList[], curr) => {
            if (prev.find((inv) => inv?.id === curr?.id)) return prev;
            return [...prev, curr];
        }, []);
        setDataFilterInternal(removeDuplicateInv);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new payment voucher"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <>
                            <Button
                                disabled={!selectedDocument.length && !expanses.length && !dataFilterInternal.length}
                                loading={draftMutate.isLoading}
                                onClick={onDraftHandler}
                                type="default"
                                className="!flex !items-center"
                            >
                                <RiDraftLine className="m-0 mr-2" />
                                Draft
                            </Button>
                            <Button
                                disabled={!selectedDocument.length && !expanses.length && !dataFilterInternal.length}
                                loading={createMutate.isLoading}
                                onClick={onCreateHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                <BiSave className="m-0 mr-2" />
                                Paid Voucher
                            </Button>
                        </>
                    </Space>
                )}
            />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <div className="grid grid-cols-4 gap-x-5">
                        <p className="capitalize m-0">
                            Document No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            control={control}
                            name="account_id"
                            placeholder="Cabang Account"
                            label="Cabang Account"
                            showSearch
                            options={getAccountQuery.data}
                            loading={getAccountQuery.isLoading}
                        />
                        <ControlledSelectInput
                            control={control}
                            name="bank_account_id"
                            placeholder="Bank Account"
                            label="Bank Account"
                            showSearch
                            options={bankAccountQuery.data}
                            loading={bankAccountQuery.isLoading}
                        />
                        <ControlledInputDate control={control} name="transaction_date" placeholder="Transaction Date" label="Transaction Date" />
                        <ControlledInputTextArea control={control} name="note" placeholder="Note Voucher" label="Note Voucher" rows={2} />
                    </div>
                </Card>
                <Card className="">
                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                    <div className="grid grid-cols-3 gap-x-5">
                        <ControlledSelectInput
                            control={control}
                            name="payment_type"
                            placeholder="Transaction Type"
                            label="Transaction Type"
                            options={PAYMENT_TYPE}
                            disabled={!watchAccountId}
                        />
                        <>
                            {watchPaymentType === PAYMENT_PURCHASING ? (
                                <ControlledSelectInput
                                    control={control}
                                    name="purchasing_type"
                                    placeholder="Category Purchasing"
                                    label="Category Purchasing"
                                    options={DOC_TYPE_PURCHASING}
                                    onChange={onChangeCategory}
                                />
                            ) : (
                                <div />
                            )}
                        </>
                        <p className="capitalize m-0">
                            Invoice Total
                            <br />
                            <span className="font-semibold m-0">{total?.ToIndCurrency("Rp") || "Rp. -"}</span>
                            <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                        </p>
                        <>
                            {watchPaymentType === PAYMENT_PURCHASING ? (
                                <>
                                    <ControlledSelectInput
                                        control={control}
                                        name="vendor_id"
                                        placeholder="Vendor Name"
                                        label="Vendor Name"
                                        options={getVendor.data}
                                        showSearch
                                    />
                                    <div />
                                    <div />
                                </>
                            ) : null}
                        </>
                        <div className="col-span-2 flex flex-col gap-5 pr-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                            <>
                                {watchPaymentType === PAYMENT_PURCHASING && (
                                    <FilterDocumentPayment2
                                        accountID={watchAccountId}
                                        title={titlePartner}
                                        type={watchPurchasing}
                                        vendorId={watchVendorId}
                                        total={total}
                                        fetcher={filterPurchasingMutate}
                                        selectedDocument={selectedDocument}
                                        setSelectedDocument={setSelectedDocument}
                                    >
                                        {(ctrl) => (
                                            <Button
                                                onClick={ctrl.openModal}
                                                type="primary"
                                                className="!self-start"
                                                disabled={
                                                    !watchPurchasing ||
                                                    watchPaymentType === PAYMENT_OTHER ||
                                                    watchPaymentType === PAYMENT_LOAN_INTERNAL
                                                }
                                            >
                                                Add Invoice
                                            </Button>
                                        )}
                                    </FilterDocumentPayment2>
                                )}
                                {watchPaymentType === PAYMENT_INTERNAL_ORDER && (
                                    <FilterDocInternalOrder
                                        accountID={watchAccountId}
                                        title="Intenal Order"
                                        type={watchPurchasing}
                                        fetcher={filterInternalMutate}
                                        onAddInvoice={onAddInvoice}
                                    >
                                        {(ctrl) => (
                                            <Button onClick={ctrl.openModal} type="primary" className="!self-start">
                                                Add Invoice
                                            </Button>
                                        )}
                                    </FilterDocInternalOrder>
                                )}
                            </>
                            <>
                                {watchPaymentType === PAYMENT_PURCHASING && (
                                    <Table
                                        scroll={{ x: 800 }}
                                        rowKey={(rec) => rec.po_id}
                                        columns={columnsFilter}
                                        dataSource={dataSourceFilter}
                                        pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                        style={{ borderBottom: "1px solid #d9d9d9" }}
                                        className="pb-2"
                                    />
                                )}

                                {watchPaymentType === PAYMENT_INTERNAL_ORDER && (
                                    <Table
                                        scroll={{ x: 800 }}
                                        rowKey={(rec) => rec.id}
                                        columns={columnsFilterInternal}
                                        dataSource={dataFilterInternal}
                                        pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                        style={{ borderBottom: "1px solid #d9d9d9" }}
                                        className="pb-2"
                                    />
                                )}
                            </>

                            <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                <div>
                                    <BiImageAdd className="text-2xl" />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            </Upload>
                        </div>
                        <div className="flex flex-col">
                            <>
                                {expanses.length !== 0 ? (
                                    <div>
                                        <p className="capitalize m-0">Expenses</p>
                                        <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </>
                            <ModalAddExpenses accountID={watchAccountId} onCreate={onAddExpenses} paymentType={watchPaymentType}>
                                {(dt) => (
                                    <div className="w-full flex flex-col gap-1">
                                        <Button onClick={dt.openModal} type="primary" className="!self-start" disabled={isAddExpenses()}>
                                            Add Expenses
                                        </Button>
                                    </div>
                                )}
                            </ModalAddExpenses>
                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                            <p className="capitalize m-0">
                                Payment Total
                                <br />
                                <span className="font-semibold m-0">{grandTotal?.ToIndCurrency("Rp") || "Rp. -"}</span>
                            </p>
                        </div>
                    </div>
                </Card>
            </Form>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default PaymentAdd;

import { Button, message, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import orderService, { Locator, OrderProduct } from "services/api-endpoints/dashboard/master-data/order";
import { STATUS_PRODUCT } from "utils/constant";

type Props = ModalProps & {
    onSubmit: (product: OrderProduct) => void;
};

const LocatorModal = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [product, setProduct] = useState<OrderProduct | null>(null);

    const locatorQuery = useQuery(
        [orderService.getLocator, product?.product_id],
        async () => {
            return (await orderService.GetLocator({ product_id: product?.product_id as any })).data.data;
        },
        {
            enabled: !!product?.product_id,
        }
    );

    const onAddLocator = (locator: Locator) => {
        return () => {
            if (!product) {
                message.error("Product not found");
                return;
            }
            if (!locator.stock_qty) {
                message.error("Stock not available");
                return;
            }
            const qty = locator.stock_qty - locator.qty_threshold;

            if (qty < product.quantity) {
                message.error("Not enough stock");
                return;
            }
            onSubmit({ ...product, area_id: locator.area_id, area_name: locator.area_name });
            closeRef.current?.click();
        };
    };

    const columns: ColumnsType<Locator> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Stock Qty",
            dataIndex: "stock_qty",
            render: (text) => <p className="capitalize m-0 text-xs items-center">{text || "-"}</p>,
        },
        {
            title: "Threshold Qty",
            dataIndex: "qty_threshold",
            render: (text) => <p className="capitalize m-0 text-xs items-center">{text || "-"}</p>,
        },
        {
            title: "Area Name",
            dataIndex: "area_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Status",
            dataIndex: "is_active",
            render: (text: keyof typeof STATUS_PRODUCT) => {
                return <Tag color={STATUS_PRODUCT[text].color}>{STATUS_PRODUCT[text].text}</Tag>;
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return <Button onClick={onAddLocator(record)}>Add Locator</Button>;
            },
        },
    ];

    const handlerDataChange = (prd: OrderProduct) => {
        setProduct(prd);
    };

    return (
        <ModalTemplate width={700} handlerInComponent={children} handlerDataChange={handlerDataChange} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        size="small"
                        loading={locatorQuery.isLoading}
                        columns={columns}
                        dataSource={locatorQuery.data || []}
                        className="w-full"
                        pagination={{
                            pageSize: 10,
                            total: locatorQuery.data?.length || 0,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default LocatorModal;

import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import moment from "moment";
import { DOC_TYPE_PURCHASING, FORMAT_DATE_1 } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterTask = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Filter Task"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        {/* <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            allowClear
                            value={dt.values.docType ? dt.values.docType : null}
                            placeholder="Purchasing Category"
                            options={DOC_TYPE_PURCHASING}
                            onChange={(val) => {
                                dt.setOneValue("docType", val);
                            }}
                        /> */}
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterTask;

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import { CreateDataDocInventory } from "../models";

export function withParentName<T extends ControlledInputSelectProps<CreateDataDocInventory>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const warehouse = useQuery([priceService.warehouse], async () => {
            const req = await priceService.Warehouse();
            return req.data.data?.map((el) => ({ label: el?.warehouse_name || "", value: el.warehouse_id || "" } as SelectOption));
        });
        return <Component {...props} optionFilterProp="children" loading={warehouse.isLoading} options={warehouse.data || []} />;
    };
}

const WarehouseSelect = withParentName(ControlledSelectInput);

export default WarehouseSelect;

/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { UserContext } from "context/user";
import { Product, SelectOption } from "models";
import ModalPickingDetailAdd from "modules/master-data/external-order/ext-pd/modal-add";
import TableAdd from "modules/master-data/external-order/ext-pd/table-add";
import { Key, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import consignmentService from "services/api-endpoints/dashboard/master-data/consignment";
import extPickingDetailService, {
    BPData,
    CreatePickingDetail,
    CreateProduct,
    ProductSO,
} from "services/api-endpoints/dashboard/master-data/ext-picking-detail";
import extSalesOrderService, { BusinessPartner } from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { ACCOUNT_ID_GNM, ACCOUNT_ID_SPM, ACCOUNT_ID_SPM_MKS, AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePickingDetail>> = yup.object().shape({
    account_id_to: yup.number().required("Partner Required"),
    bp_id: yup.number(),
    note: yup.string(),
    child_document: yup.array(),
    so_child: yup.array(),
    detail_product: yup.array(),
});

const ExtPickingDetailAdd = () => {
    const { state } = useContext(UserContext);
    const navigate = useNavigate();
    const [productsSO, setProductsSO] = useState<ProductSO[]>([]);
    const [selectedSO, setSelectedSO] = useState<Key[]>([]);
    const [soChild, setSoChild] = useState<number[]>([]);
    const [bpData, setBpData] = useState<BPData | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreatePickingDetail>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const watchPartner = watch("account_id_to");

    const getBP = useQuery([extSalesOrderService.getBp], async () => {
        const req = await extSalesOrderService.GetBp({ status: 0 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption & BusinessPartner));
    });

    const getPartnerInt = useQuery([consignmentService.getPartnerInt], async () => {
        return (await consignmentService.GetPartnerInt()).data.data?.map((el) => ({ label: el.partner_name, value: el.partner_id } as SelectOption));
    });

    const createMutate = useMutation(
        [extPickingDetailService.create],
        async (data: CreatePickingDetail) => {
            return (await extPickingDetailService.Create(data)).data.data;
        },
        {
            onSuccess(data) {
                message.success("Picking Detail Created");
                navigate(-1);
            },
        }
    );

    const onRemoveProduct = (product: Product) => {
        setProductsSO((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const areaEmpty = productsSO.filter((val) => !val.area_id);
        if (areaEmpty.length !== 0) {
            message.error("Please Select Locator");
            return;
        }
        const parse: CreatePickingDetail = {
            ...data,
            account_id_to: watchPartner,
            bp_id: bpData?.bp_id,
            child_document: [...new Set(productsSO?.map((p) => p.id!) || [])],
            so_child: soChild,
            detail_product: [
                ...(productsSO?.map(
                    (p) =>
                        ({
                            product_id: p.product_id,
                            price: p.product_price,
                            discount_price: p.discount_price || 0,
                            voucher_id: p.voucher_id,
                            note: p.note,
                            qty: p.qty_approve,
                            so_id: p.id,
                            area_id: p.area_id,
                            is_free: p.is_free || 0,
                            is_last_stock: p.is_last_stock,
                        } as CreateProduct)
                ) || []),
            ],
        };
        createMutate.mutate(parse);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const onProductSO = (data: ProductSO[]) => {
        setProductsSO(data);
        // setProductsSO((prev) => {
        //     const newData = data.map((el) => ({ ...el, qty_request: el.qty! }));
        //     if (prev.length === 0) return newData;
        //     const setNewData = newData.filter(
        //         (product) => !prev.find((prevProduct) => prevProduct.product_id === product.product_id && prevProduct.id === product.id)
        //     );
        //     return [...prev, ...setNewData];
        // });
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        setProductsSO((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id !== locator.product_id || prd.id !== docId) return prd;
                return {
                    ...prd,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: Number(locator.qty_locator),
                };
            })
        );
    };

    const partnerName = getBP.data?.find((val) => val.value === watchPartner)?.label;

    const onNote = (text: string) => {
        setValue("note", text);
        form.setFieldsValue({
            note: text,
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new picking detail"
                rightAddition={() => (
                    <Button
                        disabled={!productsSO.length}
                        onClick={onCreateHandler}
                        type="primary"
                        className="!flex !items-center"
                        loading={createMutate.isLoading}
                    >
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <div className="grid grid-cols-4 gap-x-5">
                        <p className="capitalize m-0">
                            Document No <br />
                            <span className="m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            control={control}
                            options={
                                state.user?.account_id === ACCOUNT_ID_SPM || state.user?.account_id === ACCOUNT_ID_SPM_MKS
                                    ? getBP.data
                                    : getPartnerInt.data
                            }
                            loading={
                                state.user?.account_id === ACCOUNT_ID_SPM || state.user?.account_id === ACCOUNT_ID_SPM_MKS
                                    ? getBP.isLoading
                                    : getPartnerInt.isLoading
                            }
                            name="account_id_to"
                            placeholder="Partner Name"
                            label="Partner Name"
                        />
                        <p className="capitalize m-0">
                            BP Name <br />
                            <span className="m-0">{bpData?.bp_name || "-"}</span>
                        </p>
                        <ControlledInputTextArea className="!w-[300px]" control={control} rows={2} name="note" label="Note" placeholder="Note" />
                    </div>
                </Card>
                <ModalPickingDetailAdd
                    partnerID={watchPartner}
                    partnerName={partnerName || ""}
                    onProductSO={onProductSO}
                    selectedSO={selectedSO}
                    setSelectedSO={setSelectedSO}
                    setSoChild={setSoChild}
                    setBpData={setBpData}
                    onNote={onNote}
                >
                    {(dt) => (
                        <Button className="!flex !items-center w-fit mr-3" type="primary" onClick={dt.openModal} disabled={!watchPartner}>
                            <MdAdd className="mr-2" />
                            Add Sales Order
                        </Button>
                    )}
                </ModalPickingDetailAdd>
                <TableAdd
                    list={productsSO}
                    setList={setProductsSO}
                    onSubmitLocator={onSubmitLocator}
                    removeItemList={onRemoveProduct}
                    action={false}
                />
            </Form>
        </div>
    );
};

export default ExtPickingDetailAdd;

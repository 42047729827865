import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    FORMAT_DATE_1,
    OFFLINE_STATUS,
    OFFLINE_STATUS_ALL,
    STATUS_ORDER,
    STATUS_PAYMENT_ORDER,
    TYPE_ORDER_AUTOLOKA,
    TYPE_SALES,
    TYPE_SALES_OFFLINE,
} from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterListCancelOrder = ({ filter = [], ...props }: Props) => {
    const [orderType, setOrderType] = useState<any>();
    const [searchParams] = useSearchParams();
    const orderTypeParam = searchParams.get("order_type");

    useEffect(() => {
        if (orderTypeParam === null || orderTypeParam === undefined) return;
        setOrderType(Number(orderTypeParam));
    }, [orderTypeParam]);

    return (
        <FilterModal
            filter={filter}
            title="Filter Order"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Order Number"
                            value={dt.values.order_number}
                            name="order_number"
                            onChange={(e) => dt.setOneValue("order_number", e.target.value)}
                        />
                        <Input
                            placeholder="Invoice No"
                            value={dt.values.invoice_no}
                            name="invoice_no"
                            onChange={(e) => dt.setOneValue("invoice_no", e.target.value)}
                        />
                        <Input
                            placeholder="Client Name"
                            value={dt.values.client_name}
                            name="client_name"
                            onChange={(e) => dt.setOneValue("client_name", e.target.value)}
                        />
                        <Select
                            allowClear
                            value={orderType ? Number(orderType) : null}
                            placeholder="Order Type"
                            options={TYPE_SALES || []}
                            onChange={(value) => {
                                setOrderType(value);
                                dt.setOneValue("order_type", value);
                                if (value === TYPE_SALES_OFFLINE || value === TYPE_ORDER_AUTOLOKA) {
                                    dt.setOneValue("offline_status", OFFLINE_STATUS_ALL);
                                }
                            }}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterListCancelOrder;

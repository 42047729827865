/* eslint-disable no-shadow */
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Form, message, Tooltip } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { STATUS_ACTIVE } from "utils/constant";
import { useMutation } from "react-query";
import userManagementService, { AppsData } from "services/api-endpoints/dashboard-all/user-management/user";
import { toLowerSnakeCase } from "utils";
import { Icon } from "@mui/material";

export type RoleModalEditProps = {
    children: (data: HandlerProps) => void;
    refresh?: () => void;
    data?: AppsData;
};

const schema: yup.SchemaOf<Omit<AppsData, "id">> = yup.object().shape({
    name: yup.string().required("Name required"),
    description: yup.string().required("Description required"),
    icon: yup.string().required("Icon required"),
    theme_color: yup.string().required("Theme Color required"),
    is_active: yup.number().required("Is active required"),
});

const ModalEdit = ({ children, refresh, data }: RoleModalEditProps) => {
    const closeModalRef = React.useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<Omit<AppsData, "id">>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            is_active: data?.is_active,
            name: data?.name,
        },
    });

    const watchIcon = watch("icon");

    React.useEffect(() => {
        setValue("name", data?.name as any);
        setValue("description", data?.description as any);
        setValue("icon", data?.icon as any);
        setValue("theme_color", data?.theme_color as any);
        setValue("is_active", data?.is_active as any);
    }, [data]);

    const createAppsMutate = useMutation(
        async (reqData: Omit<AppsData, "id">) => {
            return (await userManagementService.EditApps({ ...reqData, id: data?.id as any })).data.data;
        },
        {
            onSuccess() {
                closeModalRef?.current?.click();
                form.resetFields(["name", "description", "icon", "theme_color", "is_active"]);
                setValue("name", "");
                setValue("description", "");
                setValue("icon", "");
                setValue("theme_color", "");
                setValue("is_active", 0);
                message.success("Apps Edit!");
                if (refresh) refresh();
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        createAppsMutate.mutate({
            ...data,
            icon: data?.icon ? toLowerSnakeCase(data.icon) : undefined,
        });
    });

    return (
        <ModalTemplate width="800px" title="Edit Apps" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} disabled={createAppsMutate.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="grid grid-cols-3 gap-x-4">
                            <ControlledInputText control={control} name="name" placeholder="Apps Name" label="Apps Name" />
                            <ControlledInputText control={control} name="description" placeholder="Description" label="Description" />
                            <div className="flex items-start gap-2">
                                {watchIcon && <Icon fontSize="medium">{toLowerSnakeCase(watchIcon)}</Icon>}
                                <Tooltip title="Search Icon: https://mui.com/material-ui/material-icons/">
                                    <ControlledInputText control={control} name="icon" placeholder="Icon" label="Icon" />
                                </Tooltip>
                            </div>
                            <ControlledInputText control={control} name="theme_color" placeholder="Theme Color" label="Theme Color" />
                            <ControlledSelectInput
                                control={control}
                                name="is_active"
                                options={STATUS_ACTIVE}
                                placeholder="Is Active"
                                label="Is Active"
                            />
                        </div>
                        <Button loading={createAppsMutate.isLoading} htmlType="submit" type="primary">
                            Save
                        </Button>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalEdit;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Card, Checkbox, Form, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import TableView from "modules/master-data/consignment/sales-order/table-view";
import VersionHistory from "modules/master-data/consignment/version-history";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/bp-sales";
import cSalesOrderService, { ProductPerformaInv } from "services/api-endpoints/dashboard/master-data/c-sales-order";
import { C_STATUS_ORDER, TYPE_MODAL_C_PO } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const CSalesOrderView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<ProductPerformaInv[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);

    const detailQuery = useQuery(
        [cSalesOrderService.detail, id],
        async () => {
            return (await cSalesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail);
                if (data.voucher_id !== 0) {
                    setVouchers([{ voucher_id: data.voucher_id, voucher_code: data.voucher_code } as Voucher]);
                }
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail sales order" />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Referensi No <br />
                                            {detailQuery.data?.ref_id ? (
                                                <RefModal
                                                    id={detailQuery.data?.ref_id}
                                                    docNo={detailQuery.data?.ref_no}
                                                    type={detailQuery.data.ref_type}
                                                >
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {detailQuery.data?.ref_no || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ) : (
                                                <span className="underline m-0">-</span>
                                            )}
                                        </p>
                                        <p className="capitalize m-0">
                                            Partner Internal <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                        <div className="m-0 mt-4">
                                            <p className="capitalize m-0 mb-1">Voucher</p>
                                            <ListVoucher vouchers={vouchers} bordered showTextEmpty />
                                        </div>
                                        <p className="capitalize m-0 mt-4">
                                            total price <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(detailQuery.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            total discount <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(detailQuery.data?.discount_price || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            grand total <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(detailQuery.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        {detailQuery.data?.is_last_stock === 1 && (
                                            <div className="mt-8">
                                                <Checkbox checked className="font-bold" disabled>
                                                    SO Last Stock
                                                </Checkbox>
                                            </div>
                                        )}
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>

                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: <TableView list={products} setList={setProducts} action={false} loading={detailQuery.isLoading} />,
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default CSalesOrderView;

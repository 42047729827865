import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { ProductWithMinQty } from "services/api-endpoints/dashboard/master-data/voucher";

type Props = {
    loading?: boolean;
    list: ProductWithMinQty[];
    onRemove?: (user: ProductWithMinQty) => () => void;
};

const ProductTable = ({ list, loading, onRemove }: Props) => {
    const columns: ColumnsType<ProductWithMinQty> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Item",
            dataIndex: "item",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Min Qty Order",
            dataIndex: "min_qty_order",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            ...(onRemove
                ? {
                      title: "Action",
                      dataIndex: "action",
                      render: (text, record) => (
                          <Button type="text" danger onClick={onRemove(record)}>
                              <MdOutlineDeleteOutline className="text-lg" />
                          </Button>
                      ),
                  }
                : null),
        },
    ];
    return (
        <Table
            size="small"
            loading={loading}
            columns={columns}
            dataSource={list || []}
            pagination={{
                pageSize: 10,
                total: list.length || 0,
                showSizeChanger: false,
            }}
        />
    );
};

export default ProductTable;

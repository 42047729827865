/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Input, InputNumber, Select, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import InputTextArea from "components/form/inputs/input-textarea";
import { SelectOption } from "models";
import { UseQueryResult } from "react-query";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";

type Props<T> = {
    list?: T[];
    loading?: boolean;
    onEditChange?: (record: T) => void;
    uomFetcher: UseQueryResult<SelectOption[], unknown>;
    removeItem?: (record: T) => void;
};

const TableAddRequest = <T extends ChildRequest>({ list, loading, onEditChange, uomFetcher, removeItem }: Props<T>) => {
    const onChangeQty = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                qty: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };
    const onChangeUOM = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                uom_id: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };
    const onChangeNote = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                note: val.target.value,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const onDeleteClick = (record: T) => {
        return () => {
            if (removeItem) removeItem(record);
        };
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (_, r, i) => i + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => record?.product_name,
        },
        {
            title: "Type",
            render: (_, record) => record?.type_name,
        },
        {
            title: "Quantity",
            width: "150px",
            dataIndex: "qty",
            render: (_, record) => <InputNumber value={record.qty} onChange={onChangeQty(record)} min={1} className="!w-[150px]" />,
        },
        {
            title: "UOM",
            width: "150px",
            dataIndex: "uom_id",
            render: (_, record) => (
                <Select
                    value={record?.uom_id}
                    showSearch
                    placeholder="UOM"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                    className="!w-[150px]"
                    loading={uomFetcher.isLoading}
                    options={uomFetcher.data || []}
                    onChange={onChangeUOM(record)}
                />
            ),
        },
        {
            title: "Referensi",
            width: "250px",
            dataIndex: "note",
            render: (_, record) => (
                <InputTextArea value={record.note} rows={2} onChange={onChangeNote(record)} className="!w-[250px]" error={undefined} label="" />
            ),
        },
        {
            width: "50px",
            title: "Action",
            render: (_, record) => {
                return <ButtonAccessDelete onClick={onDeleteClick(record)} />;
            },
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            size="small"
            columns={columns}
            dataSource={list}
            className="w-full"
            loading={loading}
            pagination={{ pageSize: 100 }}
        />
    );
};

export default TableAddRequest;

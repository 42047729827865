import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import { useQuery } from "react-query";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import { FORMAT_DATE_1, STATUS_ONLINE_REKAP_FILTER, STATUS_ORDER_SALES_FILTER } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterOnline = ({ filter = [], ...props }: Props) => {
    const getMarketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Online"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.mp_id ? Number(dt.values.mp_id) : null}
                            placeholder="Marketplace"
                            options={getMarketplaceQuery.data}
                            loading={getMarketplaceQuery.isLoading}
                            onChange={(value) => dt.setOneValue("mp_id", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.order_status ? Number(dt.values.order_status) : null}
                            placeholder="Status Order"
                            options={STATUS_ONLINE_REKAP_FILTER}
                            onChange={(value) => dt.setOneValue("order_status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterOnline;

import { Alert, Button, Card, message, Select, Skeleton, TablePaginationConfig, Tabs } from "antd";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import ProductTable from "modules/dashboard-all/bp-sales/view/product-table";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import HistoryStatus from "modules/dashboard-all/sales/view/history-status";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpSalesService, { EditBpSalesOrder } from "services/api-endpoints/dashboard-all/bp-sales";
import { ORDER_SALES_CANCEL, STATUS_ORDER_SALES } from "utils/constant";

const BpSalesView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [page, setPage] = useState(1);

    const [orderStatus, setOrderStatus] = useState<number | null>(null);

    const detailQuery = useQuery(
        [bpSalesService.detail, id],
        async () => {
            return (await bpSalesService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setOrderStatus(data?.order_status);
            },
        }
    );

    const editMutation = useMutation(
        [bpSalesService.editBpSales],
        async (data: EditBpSalesOrder) => {
            return (await bpSalesService.EditBpSales(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Status updated!");
            },
        }
    );

    const onSubmitNote = (value: string) => {
        if (!orderStatus) return;
        editMutation.mutateAsync({ id: id as any, note: value, order_status: orderStatus }).finally(detailQuery.refetch);
    };

    const onChangePaging = (pagination: TablePaginationConfig) => {
        setPage(pagination.current as any);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Detail Bp Sales"
                rightAddition={() => (
                    <ModalNote title="Status Notes" onSubmit={onSubmitNote}>
                        {(dt) => (
                            <Button
                                disabled={detailQuery.data?.order_status === orderStatus}
                                loading={editMutation.isLoading}
                                onClick={dt.openModal}
                                className="!flex !items-center"
                            >
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        )}
                    </ModalNote>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-4 gap-4">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="">
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="TEXT-1">
                                                    order no <br />
                                                    <span className="TEXT-2">{detailQuery.data?.order_no}</span>
                                                </p>
                                                <p className="TEXT-1 mt-4">
                                                    Partner <br />
                                                    <span className="TEXT-2">{detailQuery.data?.bp_name || "_"}</span>
                                                </p>
                                            </div>
                                            <div className="h-[100px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                        </div>
                                        <p className="TEXT-1 mt-4">
                                            courier <br />
                                            <span className="TEXT-2">{detailQuery.data?.shipping_name || "_"}</span>
                                        </p>
                                        <p className="TEXT-1 mt-4">
                                            status <br />
                                            <Select
                                                value={orderStatus}
                                                options={STATUS_ORDER_SALES}
                                                status={orderStatus === ORDER_SALES_CANCEL ? "error" : undefined}
                                                onChange={(value) => setOrderStatus(value)}
                                                className="w-[90%]"
                                            />
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex">
                                            <div className="flex flex-col gap-4 w-full">
                                                <div className="">
                                                    <p className="capitalize m-0 font-semibold text-gray-700">Voucher</p>
                                                    <ListVoucher showTextEmpty bordered vouchers={detailQuery.data?.voucher || []} />
                                                </div>
                                                <div className="">
                                                    <p className="capitalize m-0 font-semibold text-gray-700">Custom Voucher</p>
                                                    <ListCustomVoucher bordered showTextEmpty vouchers={detailQuery.data?.voucher_custom || []} />
                                                </div>
                                            </div>
                                            <div className="h-[100px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                        </div>
                                        <p className="TEXT-1">
                                            total discount <br />
                                            <span className="TEXT-2">{detailQuery.data?.total_discount?.ToIndCurrency("Rp") || "Rp.-"}</span>
                                        </p>
                                    </div>
                                    <div className="col-span-2 grid grid-cols-2 ">
                                        <div className="grid grid-cols-2 gap-x-4 col-span-2">
                                            <p className="TEXT-1">
                                                name <br />
                                                <span className="TEXT-2">{detailQuery.data?.customer_name || "_"}</span>
                                            </p>
                                            <div className="row-span-2">
                                                <p className="TEXT-1">
                                                    address <br />
                                                    <span className="TEXT-2">{detailQuery.data?.customer_address || "_"}</span>
                                                </p>
                                            </div>
                                            <p className="TEXT-1">
                                                info <br />
                                                <span className="TEXT-2">{detailQuery.data?.customer_info || "_"}</span>
                                            </p>
                                        </div>
                                        <p className="TEXT-1">
                                            total price <br />
                                            <span className="TEXT-2">{detailQuery.data?.total_price?.ToIndCurrency("Rp") || "Rp.-"}</span>
                                        </p>
                                        <p className="TEXT-1">
                                            grand total <br />
                                            <span className="TEXT-2">{detailQuery.data?.grand_total?.ToIndCurrency("Rp") || "Rp.-"}</span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(detailQuery.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                </div>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: (
                                <ProductTable
                                    onChange={onChangePaging}
                                    pagination={{
                                        pageSize: 10,
                                        current: page,
                                    }}
                                    list={detailQuery.data?.list_detail || []}
                                    loading={detailQuery.isLoading}
                                />
                            ),
                        },
                        {
                            label: `Order History`,
                            key: "2",
                            children: <HistoryStatus />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default BpSalesView;

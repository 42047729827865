import { Alert } from "antd";
import React from "react";

const Transaction = () => {
    return (
        <div className="w-full flex flex-col gap-6">
            <Alert showIcon message="Transaction Page" type="info" />
        </div>
    );
};

export default Transaction;

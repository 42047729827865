/* eslint-disable prettier/prettier */
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterWorkingOrderM from "modules/working-order-m/index/filter";
import WorkingOrderMTable from "modules/working-order-m/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import workingOrderMService from "services/api-endpoints/dashboard/manufacture/working-order-m";

const filter = ["start_date", "end_date"];

const WorkingOrderM = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || 1);

    const { isFilter, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([workingOrderMService.getList, page, isFilter, filterValue], async () => {
        return (await workingOrderMService.GetList({ page, ...filterValue })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterWorkingOrderM filter={filter}>
                {(dt) => (
                    <Toolbar placeholder="Search..." filterHandler={dt.openModal} />
                )}
            </FilterWorkingOrderM>
            <WorkingOrderMTable fetcher={getList} />
        </div>
    );
};

export default WorkingOrderM;

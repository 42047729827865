/* eslint-disable no-plusplus */
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { mapDuplicateProduct2 } from "modules/master-data/consignment/delivery-order/utils";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import extDeliveryOrderService, { ListPickingList, ProductPL } from "services/api-endpoints/dashboard/master-data/ext-delivery-order";
import { BPData } from "services/api-endpoints/dashboard/master-data/ext-picking-detail";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    partnerID: number | null;
    partnerName: string;
    selectedSO: React.Key[];
    setSelectedSO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductSO: (data: ProductPL[], real: ProductPL[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
    setSoChild: React.Dispatch<React.SetStateAction<number[]>>;
    setBpData: React.Dispatch<React.SetStateAction<BPData | null>>;
};

const ModalDeliveryOrderAdd = <T extends ListPickingList>({
    children,
    selectedSO,
    setSelectedSO,
    partnerID,
    onProductSO,
    partnerName,
    setSoChild,
    setBpData,
}: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [bp2Data, setBp2Data] = useState<BPData | null>(null);

    const listPOQuery = useQuery(
        [extDeliveryOrderService.pickingList, partnerID],
        async () => {
            return (await extDeliveryOrderService.PickingList<T[]>({ partner_id: partnerID })).data.data;
        },
        {
            enabled: !!partnerID,
        }
    );

    const getProductPLMutation = useMutation([extDeliveryOrderService.getProductPL], async (ids: string) => {
        return (await extDeliveryOrderService.GetProductPL({ order_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const bpData = listPOQuery.data
            ?.filter((po) => newSelectedRowKeys.includes(po.id as any))
            ?.map((po) => ({ bp_id: po.bp_id, bp_name: po.bp_name } as BPData));
        const soChild = listPOQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.so_child);
        if ([...new Set(bpData)].length !== 1) {
            setCanSubmit(false);
        } else {
            setCanSubmit(true);
        }
        setBp2Data(bpData !== undefined ? bpData[0]! : null);
        setSoChild([...new Set(soChild?.flat())]);
        setSelectedSO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedSO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "PL No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "BP Name",
            dataIndex: "bp_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    const onOkHandler = () => {
        getProductPLMutation
            .mutateAsync(selectedSO.join(","))
            .then((res) => {
                onProductSO(mapDuplicateProduct2(res), res);
                setBpData(bp2Data);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={900}
            title={`Packing List - ${partnerName}`}
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: getProductPLMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Pilih BP Name yang sama</p>
                    <Table
                        rowKey={(i) => i?.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalDeliveryOrderAdd;

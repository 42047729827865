/* eslint-disable no-shadow */
import { Button, Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import BPInvoicePrint from "modules/dashboard-jsl/bp-invoice/bp-invoice-print";
import ProductTableView from "modules/dashboard-jsl/bp-invoice/product-table-view";
import { mapDuplicateProducts } from "modules/master-data/bp-invoice/utils";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import bpInvoiceService from "services/api-endpoints/dashboard/master-data/bp-invoice";
import { DetailList } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import { PAYMENNT_INV_STATUS } from "utils/constant";

interface Props {
    paramId?: any;
}

const BPInvoiceView = ({ paramId }: Props) => {
    const [searchParams] = useSearchParams();
    const id = paramId || searchParams.get("id");
    const [products, setProducts] = useState<DetailList[]>([]);
    const invoiceRef = useRef<HTMLDivElement | null>(null);

    const detailQuery = useQuery(
        [bpInvoiceService.detailInvoice, id],
        async () => {
            return (await bpInvoiceService.DetailInvoice({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(mapDuplicateProducts(data.list_detail || []));
            },
        }
    );

    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: detailQuery.data?.inv_no || "",
        pageStyle: "@page { auto; }",
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <BPInvoicePrint ref={invoiceRef} data={detailQuery.data} />
            <ToolbarAction
                title="BP Invoice detail"
                rightAddition={() => (
                    <>
                        <Button onClick={handlePrintInvoice} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print Invoice
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Invoice no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.inv_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        payment status <br />
                                        <span>{PAYMENNT_INV_STATUS.find((el) => el.value === detailQuery.data?.payment_status)?.label}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <ProductTableView loading={detailQuery.isLoading} list={products} setList={setProducts} />
            </Card>
        </div>
    );
};

export default BPInvoiceView;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface ListData {
    id: number;
    pk_no: string;
    qty: number;
    created_at: string;
}

export interface ChildPK {
    supplies_id: number;
    qty: number;
    uom_id: number;
}

export interface CreatePK {
    note: string;
    child: ChildPK[];
}

export interface SuppliesPK {
    supplies_id: number;
    name: string;
    uom_id: number;
    uom_code: string;
    qty: number;
    qty_max: any;
}

export interface DetailChildPK {
    id: number;
    qty: number;
    supplies_id: number;
    supplies_name: string;
    uom_id: number;
    uom_code: string;
}

export interface DetailPK {
    id: number;
    pk_no: string;
    note: string;
    child: DetailChildPK[];
}

class PemakaianBarangService extends BaseService {
    getAll = "/pemakaian-barang/list-pemakaian-barang";

    create = "/pemakaian-barang/create";

    getSupplies = "/pemakaian-barang/get-supplies";

    detail = "/pemakaian-barang/detail";

    constructor() {
        super();
    }

    Detail<T extends DetailPK>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetSupplies<T extends SuppliesPK[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSupplies,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreatePK) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetAll<T extends ListData>(param: { start_date: any; end_date: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const pemakaianBarangService = new PemakaianBarangService();
export default pemakaianBarangService;

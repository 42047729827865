import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, DatePicker, Form, Popover, Skeleton, Space, Steps, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import ModalDetailInv from "modules/master-data/purchasing/modal-detail-inv";
import moment from "moment";
import { DocReqChild } from "pages/purchasing-inventory/add";
import { Dispatch, Key, SetStateAction, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import purchasingInventoryService, { DetailRequest, FilterRequestList } from "services/api-endpoints/purchasing/inventory";
import { DOC_TYPE_PURCHASING, FORMAT_DATE_1, TYPE_MODAL_PR } from "utils/constant";
import * as yup from "yup";

export interface AddRequest {
    prNo?: any;
    start_date?: any;
    end_date?: any;
    type: any;
}

type Props = {
    children: (data: HandlerProps) => void;
    selected: Key[];
    setSelected: Dispatch<SetStateAction<Key[]>>;
    setDocReqChild: Dispatch<SetStateAction<DocReqChild[]>>;
    onChoose: (type: any, doc: any) => void;
};

const schema: yup.SchemaOf<AddRequest> = yup.object().shape({
    prNo: yup.string().notRequired(),
    start_date: yup.string().notRequired(),
    end_date: yup.string().notRequired(),
    type: yup.number().required("Category Purchasing Required"),
});

const ModalAddRequest = ({ children, selected, setSelected, setDocReqChild, onChoose }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [page, setPage] = useState(1);
    const [pageProd, setPageProd] = useState(1);
    const [step, setStep] = useState(0);
    const [selectedProd, setSelectedProd] = useState<Key[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, reset, watch } = useForm<AddRequest>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const type = watch("type");

    const filterRequest = useMutation([purchasingInventoryService.filterRequest, type], async (data: AddRequest) => {
        return (await purchasingInventoryService.FilterRequest(data)).data.data;
    });

    const detailRequest = useMutation(
        [purchasingInventoryService.detailRequest],
        async () => {
            return (await purchasingInventoryService.DetailRequest({ pr_id: selected.join(","), type })).data.data;
        },
        {
            onSuccess(data) {
                const temp = data.map((val) => ({ ...val, qty_max: val.qty }));
                // setDocReqChild(temp);
            },
        }
    );

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const handleTableChangeProd = (pagination: TablePaginationConfig) => {
        setPageProd(pagination.current || 1);
    };

    const onSubmitHandler = handleSubmit(async (data) => {
        setSelected([]);
        filterRequest.mutate(data);
    });

    const onChooseRequest = () => {
        setStep(1);
        detailRequest.mutate();
    };

    const onChooseProduct = () => {
        const doc = filterRequest.data
            ?.filter((val) => selected.includes(val.pr_id!))
            .map((val) => val.pr_no)
            .join(", ");
        onChoose(type, doc);
        const temp = detailRequest.data
            ?.filter((val) => selectedProd.some((id) => id === val.product_id))
            .map((val) => ({ ...val, qty_max: val.qty }));
        setDocReqChild(temp || []);
        closeRef.current?.click();
    };

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelected(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<FilterRequestList> = {
        selectedRowKeys: selected,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<FilterRequestList> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 100 + (i + 1)}</p>,
        },
        {
            title: "PR No",
            dataIndex: "pr_no",
            width: "200px",
            render: (text, record) => {
                return (
                    <ModalDetailInv title="Document Detail" id={record.pr_id} type={TYPE_MODAL_PR}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalDetailInv>
                );
            },
        },
        {
            title: "PR Note",
            dataIndex: "note",
            render: (text) => text || "-",
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            render: (text) => text || 0,
        },
    ];

    const steps = [
        {
            title: "Select Document",
        },
        {
            title: "Select Product",
        },
    ];

    const onChangeStep = (stp: number) => {
        setStep(stp);
    };

    const onSelectChangeProd = (newSelectedRowKeys: Key[]) => {
        setSelectedProd(newSelectedRowKeys);
    };

    const rowSelectionProd: TableRowSelection<DetailRequest> = {
        selectedRowKeys: selectedProd,
        onChange: onSelectChangeProd,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columnsProd: ColumnsType<DetailRequest> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(pageProd - 1) * 100 + (i + 1)}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Ref",
            render: (_, record) => {
                if (!record.note) return "-";
                const note = record.note || "";
                const title = note.length > 50 ? `${note.substring(0, 50)}...` : note;
                return (
                    <Popover
                        title="Link Referensi"
                        trigger="click"
                        content={
                            <div className="w-full">
                                <a href={note} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </div>
                        }
                    >
                        <Button>Link Ref</Button>
                    </Popover>
                );
            },
        },
        {
            title: "Type Item",
            dataIndex: "type_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <ModalTemplate width={1000} title="Add Document Request" handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button onClick={dt.closeModal} type="button" ref={closeRef} className="hidden">
                        close
                    </button>
                    <Steps current={step} onChange={onChangeStep} items={steps} />
                    {step === 0 && (
                        <>
                            <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                <div className="grid grid-cols-2 gap-x-5 mt-4">
                                    <ControlledInputText control={control} name="prNo" placeholder="PR No" label="PR No" allowClear />
                                    <div>
                                        <p className="capitalize mb-2">Date</p>
                                        <DatePicker.RangePicker
                                            className="w-full"
                                            value={[
                                                watch("start_date") ? moment(watch("start_date"), FORMAT_DATE_1) : null,
                                                watch("end_date") ? moment(watch("end_date"), FORMAT_DATE_1) : null,
                                            ]}
                                            placeholder={["Start Date", "End Date"]}
                                            onChange={(val) => {
                                                setValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                                setValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                            }}
                                            allowClear
                                        />
                                    </div>
                                    <ControlledSelectInput
                                        control={control}
                                        options={DOC_TYPE_PURCHASING}
                                        name="type"
                                        placeholder="Category Purchasing"
                                        label="Category Purchasing"
                                        allowClear
                                    />
                                </div>

                                <Button type="default" htmlType="submit" className="mt-4">
                                    Search
                                </Button>
                            </Form>
                            <State data={filterRequest.data} isLoading={filterRequest.isLoading} isError={filterRequest.isError}>
                                {(state) => (
                                    <>
                                        <State.Data state={state}>
                                            <Table
                                                rowKey={(record) => record.pr_id!}
                                                rowSelection={rowSelection}
                                                size="small"
                                                columns={columns}
                                                dataSource={filterRequest.data}
                                                className="w-full mt-4"
                                                loading={filterRequest.isLoading}
                                                pagination={{ pageSize: 100, showSizeChanger: false }}
                                                onChange={handleTableChange}
                                            />
                                            {filterRequest.data && (
                                                <div className="w-full flex justify-end">
                                                    <Button type="primary" className="mt-4" onClick={onChooseRequest} disabled={!selected.length}>
                                                        Select Product
                                                    </Button>
                                                </div>
                                            )}
                                        </State.Data>
                                        <State.Loading state={state}>
                                            <Skeleton active />
                                        </State.Loading>
                                        <State.Error state={state}>
                                            <Alert type="error" message={(filterRequest.error as any)?.message} />
                                        </State.Error>
                                    </>
                                )}
                            </State>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <Table
                                rowKey={(record) => record.product_id!}
                                rowSelection={rowSelectionProd}
                                size="small"
                                columns={columnsProd}
                                dataSource={detailRequest.data}
                                className="w-full mt-4"
                                loading={detailRequest.isLoading}
                                pagination={{ pageSize: 100, showSizeChanger: false }}
                                onChange={handleTableChangeProd}
                            />
                            {detailRequest.data && (
                                <div className="w-full flex justify-end">
                                    <Button type="primary" className="mt-4" onClick={onChooseProduct} disabled={!selectedProd.length}>
                                        Choose Product
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddRequest;

import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { useState } from "react";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BpSales } from "services/api-endpoints/dashboard-all/bp-sales";
import { ListPerformaInv } from "services/api-endpoints/dashboard/master-data/consignment";
import { C_STATUS_ORDER, FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T[], unknown>;
    onClickDelete: (data: T) => void;
};

const TableIndex = <T extends ListPerformaInv>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const viewHandler = (data: ListPerformaInv) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
        },
        {
            width: "200px",
            title: "Doc No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Partner Internal",
            dataIndex: "partner_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "200px",
            title: "Order status",
            dataIndex: "order_status",
            render: (text) => <p className="capitalize m-0">{C_STATUS_ORDER.find((val) => val.value === text)?.label || "-"}</p>,
        },
        {
            width: "200px",
            title: "Create Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "75px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            scroll={{ x: 1300 }}
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            onChange={handleTableChange}
        />
    );
};

export default TableIndex;

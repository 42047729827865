import { Button, message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterProduct from "modules/master-data/product/index/filter";
import ProductTable from "modules/master-data/product/index/table";
import { TDataProduct } from "modules/master-data/product/models";
import { parseProductsToCsvData } from "modules/master-data/product/utils";
import { FaFileCsv } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import productService from "services/api-endpoints/dashboard/master-data/product";
import Utils from "utils";

const filter = ["product_name", "product_code", "molding_code", "model_id", "model_type_id", "sub_category_id", "is_active"];

const Product = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([productService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await productService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await productService.Filter(filterValue as any)).data.data;
        return (await productService.GetAll({ page, attribute_id: 0, model_type_id: 0, style_id: 0, subattribute_id: 0, subcategory_id: 0 })).data
            .data;
    });

    const getAllList = useMutation(["export-csv"], async () => {
        const req = await (() => {
            if (isSearch) return productService.Search({ page: 0, param_search: searchValue.query });
            if (isFilter) return productService.Filter({ ...(filterValue as any), page: 0 });
            return productService.GetAll({ page: 0, attribute_id: 0, model_type_id: 0, style_id: 0, subattribute_id: 0, subcategory_id: 0 });
        })();
        return parseProductsToCsvData(req.data.data?.list);
    });

    const deleteMutation = useMutation(async (data: TDataProduct) => (await productService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataProduct>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    const onClickExport = async () => {
        getAllList.mutateAsync().then((res) => {
            Utils.jsonToCSV({ json: res as any, title: "Product", showLabel: true });
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterProduct filter={filter} filterValue={filterValue}>
                {(dt) => (
                    <Toolbar
                        placeholder="Product Name / Product Code / Molding Code / Brand / Model"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <Button
                                onClick={onClickExport}
                                loading={getAllList.isLoading}
                                type="primary"
                                className="!flex !items-center !font-semibold"
                            >
                                <FaFileCsv className="mr-3" />
                                Export
                            </Button>
                        )}
                    />
                )}
            </FilterProduct>
            <ProductTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Product;

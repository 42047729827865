import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { CreateDataBrand } from "modules/master-data/brand/models";
import BrandTypeSelect from "modules/master-data/brand/lib/brand-type-select";
import IsPopularSelect from "modules/master-data/brand/lib/is-popular-select";
import IsActiveSelect from "modules/master-data/brand/lib/is-active-select";
import InputFile from "components/form/inputs/input-file";
import { IMAGE_TYPES } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import useProgressUpload from "hooks/useProgressUpload";

const schema: yup.SchemaOf<Partial<CreateDataBrand>> = yup.object().shape({
    brand_name: yup.string().required("Brand name required"),
    brand_code: yup.string().required("Brand code required"),
    brand_type: yup.number().required("Brand type required"),
    is_popular: yup.number().required("Popular required"),
    is_active: yup.number().required("Is Active required"),
    brand_image: yup.string().required("Brand Image required"),
    car_image: yup.string().required("Car Image required"),
    header_image: yup.string().required("Header Image required"),
});

const BrandAdd = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<CreateDataBrand>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [progress, onUploadProgress] = useProgressUpload();

    const [controlFile] = useControlFile<CreateDataBrand>({
        setValue,
        errors: errors as any,
    });

    const createMutation = useMutation(
        [brandService.create],
        async (data: CreateDataBrand) => {
            return (await brandService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Brand created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new brand"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-2 gap-4">
                    <div className="grid grid-cols-2 gap-4">
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="header_image" className="col-span-2">
                            {(dt) => <ImageCard image={dt.file} title="header image" />}
                        </InputFile>
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="car_image">
                            {(dt) => <ImageCard image={dt.file} title="car image" />}
                        </InputFile>
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="brand_image">
                            {(dt) => <ImageCard image={dt.file} title="brand image" />}
                        </InputFile>
                    </div>
                    <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">brand information</h1>
                        <ControlledInputText
                            label="Brand Name"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="brand_name"
                            placeholder="Brand Name"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <ControlledInputText
                            label="Brand Code"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="brand_code"
                            placeholder="Brand Code"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <BrandTypeSelect
                            label="Brand Type"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="brand_type"
                            placeholder="Brand Type"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <IsPopularSelect
                            label="Is Popular"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="is_popular"
                            placeholder="Is Popular"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <IsActiveSelect
                            label="Is Active"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="is_active"
                            placeholder="Is Active"
                            style={{ transform: "translateY(-6px)" }}
                        />
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default BrandAdd;

import { Button, Form } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { Key, useRef } from "react";
import { useQuery } from "react-query";
import extSalesOrderService, { CheckSOReq, SoOutstanding } from "services/api-endpoints/dashboard/master-data/ext-sales-order";

type Props = ModalProps & {
    onSubmit: () => void;
    total: number;
    selectedRowKeys: Key[];
    products: SoOutstanding[];
};

const ModalGenerateSO = ({ onSubmit, total, children, selectedRowKeys, products, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();

    const parseData =
        products
            ?.filter((p) => selectedRowKeys.includes(p.id as any))
            .map(
                (el) =>
                    ({
                        doc_id: el.doc_id,
                        product_id: el.product_id,
                        qty: el.qty,
                    } as CheckSOReq)
            ) || [];

    const checkSOQuery = useQuery(
        [extSalesOrderService.checkSO, selectedRowKeys, products],
        async () => {
            return (await extSalesOrderService.CheckSO(parseData)).data.data;
        },
        {
            enabled: !!selectedRowKeys,
        }
    );

    const onSubmitHandler = () => {
        onSubmit();
        closeRef.current?.click();
    };

    const onSubmitSame = () => {
        onSubmit();
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col items-end">
                            {checkSOQuery.data?.length !== 0 ? (
                                <>
                                    <h4 className="m-0 !w-full font-semibold mb-2">
                                        Ada {checkSOQuery.data?.length} SO yang akan di generate SO baru. Anda yakin generate SO.?
                                    </h4>
                                    {checkSOQuery.data?.map((el) => (
                                        <>
                                            <h4 className="m-0 !w-full font-semibold">Document {el.invoice}</h4>
                                            <>
                                                {el.list_product.map((el2) => (
                                                    <h4 className="m-0 !w-full font-bold ml-2"> - Product {el2.Item}</h4>
                                                ))}
                                            </>
                                        </>
                                    ))}
                                    <Button type="primary" className="items-center mt-4" onClick={onSubmitHandler} loading={checkSOQuery.isLoading}>
                                        Generate SO
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h4 className="m-0 !w-full font-semibold mb-2">
                                        Tidak Ada SO baru yang akan tergenerate, Apakah anda yakin membuat SO ini menjadi Ready to Picking ?
                                    </h4>
                                    <Button type="primary" className="items-center mt-4" onClick={onSubmitSame} loading={checkSOQuery.isLoading}>
                                        Ready to Picking
                                    </Button>
                                </>
                            )}
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalGenerateSO;

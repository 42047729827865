import { InputNumber, Table, message } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TableRowSelection } from "antd/lib/table/interface";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { Dispatch, Key, SetStateAction } from "react";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { SoOutstanding } from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    product: T[];
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
    selectedRowKeys: Key[];
    onEditChange?: (record: T) => void;
};

const TableIndexOut = <T extends SoOutstanding>({ fetcher, product, setSelectedRowKeys, selectedRowKeys, onEditChange }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig, filter: any, sorter: any) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        if (sorter.field === "partner_name") {
            searchParams.set("bp", sorter.order === "descend" ? "desc" : "asc");
        }
        if (sorter.field === "created_at") {
            searchParams.set("date", sorter.order === "descend" ? "desc" : "asc");
        }
        if (sorter.field === "so_no") {
            searchParams.set("doc", sorter.order === "descend" ? "desc" : "asc");
        }
        if (sorter.field === "product_code") {
            searchParams.set("code1", sorter.order === "descend" ? "desc" : "asc");
        }
        if (sorter.field === "code2") {
            searchParams.set("code2", sorter.order === "descend" ? "desc" : "asc");
        }
        setSearchParams(searchParams);
    };

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        const accountIdTo = fetcher.data?.list?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.account_id_to);
        if ([...new Set(accountIdTo)].length > 1) {
            message.error("Partner Name Tidak Boleh Berbeda");
            return;
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: (record.qty_stock || 0) < (record.qty || 0),
        }),
    };

    const onChangeQty = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                qty: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            fixed: "left",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 50 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "so_no",
            sorter: true,
            fixed: "left",
            render: (text) => <p className="capitalize m-0 font-bold">{text || "-"}</p>,
        },
        {
            title: "Partner Name",
            dataIndex: "partner_name",
            sorter: true,
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "BP Name",
            dataIndex: "bp_name",
            sorter: true,
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Doc Date",
            dataIndex: "created_at",
            sorter: true,
            render: (text) => <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            sorter: true,
            width: "200px",
            render: (text) => <p className="capitalize m-0 font-bold">{text || "-"}</p>,
        },
        {
            title: "Code 2",
            dataIndex: "code2",
            sorter: true,
            width: "150px",
            render: (text) => <p className="capitalize m-0 font-bold">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            width: "500px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Owner",
            dataIndex: "product_owner",
            width: "100px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Price",
            dataIndex: "product_price",
            width: "100px",
            render: (text) => <p className="capitalize m-0">{(text || 0).ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            width: "100px",
            render: (text, record) => (
                <InputNumber value={record.qty} onChange={onChangeQty(record)} min={1} max={record.qty_req} className="!w-[70px]" />
            ),
        },
        {
            title: "Stock",
            dataIndex: "qty_stock",
            width: "70px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowSelection={rowSelection}
            rowKey={(record) => record.id as any}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            scroll={{ x: 2000 }}
            dataSource={product}
            className="w-full my-ant-design-table"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 50,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableIndexOut;

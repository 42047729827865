/* eslint-disable no-underscore-dangle */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { SignInEmailData } from "models";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import authService from "services/api-endpoints/dashboard/auth";
import * as yup from "yup";

type Props = {
    onSuccess: () => void;
};

const schema: yup.SchemaOf<SignInEmailData> = yup.object().shape({
    email: yup.string().required("Email Required"),
    password: yup.string().required("Password Required"),
});

const Verification = ({ onSuccess }: Props) => {
    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<SignInEmailData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const verificationMutation = useMutation(
        [orderSalesService.checkAuth],
        async (data: SignInEmailData) => {
            const idToken = await authService._signInToFirebase(data);
            await orderSalesService.CheckAuth({ idToken });
        },
        { onSuccess }
    );

    const onSubmitHandler = handleSubmit(async (data) => {
        verificationMutation.mutate(data);
    });

    return (
        <Form form={form} disabled={verificationMutation.isLoading} onFinish={onSubmitHandler} layout="vertical">
            <Space direction="vertical" className="w-full">
                <ControlledInputText control={control} name="email" placeholder="Email" />
                <ControlledInputText control={control} name="password" placeholder="Password" type="password" />
                <Button type="primary" htmlType="submit" disabled={verificationMutation.isLoading} loading={verificationMutation.isLoading}>
                    Verify
                </Button>
            </Space>
        </Form>
    );
};

export default Verification;

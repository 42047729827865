import { Alert, Button, Card, DatePicker, Form, Skeleton, message } from "antd";
import React from "react";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FORMAT_DATE_1 } from "utils/constant";
import { FCreateSeasonal } from "modules/master-data/seasonal-product/models";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import AdvanceSearch from "modules/advance-search";
import { Product } from "models";
import { MdAdd } from "react-icons/md";
import ProductTable from "modules/master-data/seasonal-product/add/product-table";
import { useMutation, useQuery } from "react-query";
import seasonalProductService, { EditSeasonal } from "services/api-endpoints/dashboard/master-data/seasonal-product";
import moment from "moment";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<FCreateSeasonal>> = yup.object().shape({
    title: yup.string().required("Title required"),
    subtitle: yup.string().required("Sub title required"),
});

const SeasonalProductEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [rangeDate, setRangeDate] = React.useState<{ start: any | null; end: any | null } | null>(null);
    const [products, setProducts] = React.useState<Product[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<FCreateSeasonal>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detail = useQuery(
        [seasonalProductService.detail, id],
        async () => {
            return (await seasonalProductService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                form.setFieldsValue({
                    title: data.title,
                    subtitle: data.subtitle,
                });
                setValue("title", data.title);
                setValue("subtitle", data.subtitle);
                setRangeDate({
                    start: moment(data.start_date),
                    end: moment(data.end_date),
                });
                setProducts(data.seasonal_detail as any);
            },
        }
    );

    const edit = useMutation([seasonalProductService.edit], async (data: EditSeasonal) => {
        return (await seasonalProductService.Edit(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        edit.mutateAsync({
            ...data,
            id: id as any,
            start_date: moment(rangeDate?.start).format(FORMAT_DATE_1),
            end_date: moment(rangeDate?.end).format(FORMAT_DATE_1),
            seasonal_detail: products?.map((p) => ({ product_id: p.product_id })),
        }).then(() => {
            message.success("Seasonal Product Edited");
            navigate(-1);
        });
    });

    const onDatePickerChange = (val: any) => {
        if (!val) setRangeDate(null);
        else
            setRangeDate({
                start: val?.[0],
                end: val?.[1],
            });
    };

    const saveHandler = () => form.submit();

    const removeProduct = (product: Product) => {
        setProducts((prev) => prev?.filter((pdct) => pdct.product_id !== product.product_id));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit seasonal product"
                rightAddition={() => (
                    <Button
                        loading={edit.isLoading}
                        onClick={saveHandler}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={!rangeDate || products.length === 0}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <State data={detail.data} isLoading={detail.isLoading} isError={detail.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form className="w-full" form={form} layout="horizontal" onFinish={onSubmitHandler}>
                                    <div className="w-full grid grid-cols-3 gap-4">
                                        <div className="capitalize m-0">
                                            Title <br />
                                            <ControlledInputText control={control} name="title" placeholder="Title" />
                                        </div>
                                        <div className="capitalize m-0">
                                            Subtitle <br />
                                            <ControlledInputText control={control} name="subtitle" placeholder="Sub title" />
                                        </div>
                                        <div className="capitalize m-0">
                                            Start date - End date <br />
                                            <DatePicker.RangePicker
                                                value={[rangeDate?.start || null, rangeDate?.end || null]}
                                                placeholder={["Start Date", "End Date"]}
                                                onChange={onDatePickerChange}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detail.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={products} removeItemList={removeProduct} />
        </div>
    );
};

export default SeasonalProductEdit;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBPDeliveryOrder from "modules/dashboard-jsl/bp-delovery-order/filter";
import BPInvoiceTable from "modules/dashboard-jsl/bp-invoice/index-table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpGoodsReceiptService from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";
import bpInvoiceService from "services/api-endpoints/dashboard/master-data/bp-invoice";

const filter = ["do_no", "inv", "bp_id"];

const BPInvoice = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bpInvoiceService.getAll, page, searchValue, isSearch, isFilter, filterValue], async () => {
        // if (isSearch) return (await deliveryOrderService.Search({ page, query: searchValue.query })).data.data;
        // if (isFilter) return (await deliveryOrderService.Filter(filterValue as any)).data.data;
        return (await bpInvoiceService.GetAll({ ...filterValue, page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBPDeliveryOrder filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="BP Invoice No"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        add={false}
                        filter={false}
                    />
                )}
            </FilterBPDeliveryOrder>
            <BPInvoiceTable fetcher={getList} />
        </div>
    );
};

export default BPInvoice;

/* eslint-disable no-shadow */
import { Alert, Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import DetailModal from "modules/purchasing/supplies-quantity/modal-detail";
import ModalHistorySuppliesQty from "modules/purchasing/supplies-quantity/modal-history";
import SuppliesHistoryTable from "modules/purchasing/supplies-quantity/table-history";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { History } from "services/api-endpoints/dashboard/master-data/quantity";
import suppliesQuantityService from "services/api-endpoints/purchasing/supplies-quantity";

const SuppliesQuantityView = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const warehouseId = searchParams.get("warehouse_id");
    const page = searchParams.get("page") || 1;
    const [historyShowed, setHistoryShowed] = useState<History | null>(null);

    const detailQuery = useQuery(
        [suppliesQuantityService.getSuppliesInfo, id],
        async () => {
            return (await suppliesQuantityService.GetSuppliesInfo({ suppliesID: id, warehouse_id: warehouseId })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const historyQuery = useQuery(
        [suppliesQuantityService.getHistory, id],
        async () => {
            return (await suppliesQuantityService.GetHistory({ suppliesID: id, page, warehouse_id: warehouseId })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail supplies quantity" />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        Supplies Name <br />
                                        <span className="underline italic m-0">{detailQuery.data?.name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Type Of Supplies <br />
                                        <span className="underline italic m-0">{detailQuery.data?.type_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Type Code <br />
                                        <span className="underline italic m-0">{detailQuery.data?.type_code}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Quantity <br />
                                        <span className="underline italic m-0">{historyQuery.data?.total_qty || 0}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailQuery.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <DetailModal data={historyShowed}>
                {(dt) => (
                    <SuppliesHistoryTable
                        loading={detailQuery.isLoading}
                        onClickLink={(history) => {
                            setHistoryShowed(history);
                            dt.openModal();
                        }}
                    />
                )}
            </DetailModal>
        </div>
    );
};

export default SuppliesQuantityView;

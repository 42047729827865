import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Card, Form, message, Space, Alert } from "antd";
import ToolbarAction from "components/toolbar/action";
import AdvanceSearch from "modules/advance-search";
import ProductTable from "modules/dashboard-all/sales/add/product-table";
import React, { Key, useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { AUTOGENERATED, MARKET_PLACE_VALUES } from "utils/constant";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalVoucher from "modules/dashboard-all/sales/add/modal-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import ModalCustomVoucher from "modules/dashboard-all/sales/add/modal-custom-voucher";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Product, SelectOption } from "models";
import { ProductWithQty } from "modules/advance-search/models";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import bpSalesService, { Voucher } from "services/api-endpoints/dashboard-all/bp-sales";
import { CreateBpSales, CustomVoucher, DetailProduct, FDataCreateBpSales, GetSummaryPrice } from "modules/dashboard-all/bp-sales/models";

const schema: yup.SchemaOf<Partial<FDataCreateBpSales>> = yup.object().shape({
    bp_id: yup.number().required("Business partner required"),
    customer_name: yup.string().required("Customer name required"),
    customer_info: yup.string().required("Customer info required"),
    customer_address: yup.string().required("Customer address required"),
    shipping_id: yup.number().required("Courier required"),
});

const BpSalesAdd = () => {
    const navigate = useNavigate();

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);
    const [cvProdKeys, setcvProdKeys] = useState<Key[]>([]);

    const getBpQuery = useQuery([bpSalesService.getBp], async () => {
        return (await bpSalesService.GetBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const getShippingQuery = useQuery([bpSalesService.getShipping], async () => {
        return (await bpSalesService.GetShipping()).data.data?.map((el) => ({ label: el.shipping_name, value: el.shipping_id } as SelectOption));
    });

    const getSummaryPrice = useMutation([bpSalesService.getSummaryPrice], async (data: GetSummaryPrice) => {
        return (await bpSalesService.GetSummaryPrice(data)).data.data;
    });

    const createMutation = useMutation(
        [bpSalesService.createBpSales],
        async (data: CreateBpSales) => {
            return (await bpSalesService.CreateBpSales(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Bp Sales created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [bpSalesService.createDraftBpSales],
        async (data: CreateBpSales) => {
            return (await bpSalesService.CreateDraftBpSales(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Bp Sales draft created");
                navigate(-1);
            },
        }
    );

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FDataCreateBpSales>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("submit");
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("draft");
    };

    const parseData: GetSummaryPrice = {
        detail_product: [
            ...(products?.map(
                (p) => ({ product_price: p.product_price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)
            ) || []),
        ],
        voucher_id: [...(vouchers?.map((v) => v.voucher_id) || [])],
        voucher: [
            ...(customVouchers?.map((cv) => ({ name: cv.name, price: cv.price || null, percentage: cv.percentage || null } as CustomVoucher)) || []),
        ],
    };

    const onSubmitHandler = handleSubmit((data) => {
        setTimeout(() => {
            const createSales: CreateBpSales = {
                ...data,
                ...parseData,
            };
            if (typeSave === "submit") createMutation.mutate(createSales);
            else createDraftMutation.mutate(createSales);
        }, 300);
    });

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    const onRemoveCustomVoucher = (index: number) => {
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    useEffect(() => {
        if (!products.length) return;
        getSummaryPrice.mutate(parseData);
    }, [products, vouchers, customVouchers]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new bp sales"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDraftMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-3">
                                        sales order no <br />
                                        <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                    </p>
                                    <div className="">
                                        <p className="capitalize m-0 font-semibold text-gray-700">Partner</p>
                                        <ControlledSelectInput
                                            showSearch
                                            name="bp_id"
                                            placeholder="Business Partner"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={getBpQuery.isLoading}
                                            options={getBpQuery.data || []}
                                        />
                                        <p className="capitalize m-0 font-semibold text-gray-700">Courier</p>
                                        <ControlledSelectInput
                                            showSearch
                                            name="shipping_id"
                                            placeholder="Courier"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={getShippingQuery.isLoading}
                                            options={getShippingQuery.data || []}
                                        />
                                    </div>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                            <p className="capitalize m-0 text-gray-700">
                                status <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">New</span>
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex">
                                <div className="flex flex-col gap-6 w-full">
                                    <div className="">
                                        <p className="capitalize m-0 font-semibold text-gray-700">Voucher</p>
                                        <ModalVoucher vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                            {(dt) => (
                                                <div className="w-full flex flex-col gap-1">
                                                    <Button
                                                        onClick={dt.openModal}
                                                        disabled={vouchers.length === 2}
                                                        className="!w-full !flex !items-center !justify-between"
                                                    >
                                                        Voucher <MdAdd />
                                                    </Button>
                                                    <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                                </div>
                                            )}
                                        </ModalVoucher>
                                    </div>
                                    <ModalCustomVoucher
                                        onCreate={onCustomVoucherCreate}
                                        products={products}
                                        selectedRowKeys={cvProdKeys}
                                        setSelectedRowKeys={setcvProdKeys}
                                    >
                                        {(dt) => (
                                            <div className="w-full flex flex-col gap-1">
                                                <Button
                                                    onClick={dt.openModal}
                                                    disabled={customVouchers.length === 2}
                                                    className="!w-full !flex !items-center !justify-between"
                                                >
                                                    Custom Voucher <MdAdd />
                                                </Button>
                                                <ListCustomVoucher vouchers={customVouchers} onRemoveVoucher={onRemoveCustomVoucher} />
                                            </div>
                                        )}
                                    </ModalCustomVoucher>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                            <p className="capitalize m-0 text-gray-700">
                                total discount <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">
                                    {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col w-full">
                            <p className="capitalize m-0 font-semibold text-gray-700">Customer</p>
                            <ControlledInputText control={control} labelCol={{ xs: 12 }} name="customer_name" placeholder="Customer Name" />
                            <ControlledInputText control={control} labelCol={{ xs: 12 }} name="customer_info" placeholder="Customer Info" />
                            <p className="capitalize m-0 text-gray-700">
                                total price <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">
                                    {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 w-full">
                            <div className="mt-5">
                                <ControlledInputTextArea
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="customer_address"
                                    placeholder="Customer Address"
                                    className="h-full"
                                    rows={3}
                                />
                            </div>
                            <p className="capitalize m-0 text-gray-700">
                                grand total <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">
                                    {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                        </div>
                    </div>
                </Form>
            </Card>
            {getSummaryPrice.isError ? <Alert type="error" message={(getSummaryPrice.error as any)?.message} /> : null}
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={products} setList={setProducts} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default BpSalesAdd;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface CreateProduct {
    product_id: number;
    qty: number;
    ref_id: number[];
}

export interface CreateGenerateInvoice {
    account_id_from: number;
    note: string;
    doc_id: number[];
    detail_product: CreateProduct[];
}

export interface CreatePickingList {
    partner_id: number;
    product: any[];
}

export interface FilterInvoiceParams {
    type?: number;
    partner_id?: number;
    start_date?: string;
    end_date?: string;
}

export interface ListInvoice {
    id: number;
    inv_no: string;
    inv_ref: string;
    created_at: string;
}

export interface ListPartnerInt {
    partner_id?: number;
    partner_name?: string;
}

export interface ProductRef {
    id: number;
    inv: string;
}
export interface ProductInvoice {
    product_id: number;
    product_code1: string;
    product_name: string;
    product_owner: string;
    product_owner_id: number;
    qty: number;
    references: ProductRef[];
}

export interface ListPerformaInv {
    id: number;
    inv_no: string;
    partner_name: string;
    order_status: number;
    created_at: string;
}

export interface ProductPerformaInv {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    is_free: number;
    note?: string;
    discount_price: number;
    voucher_id: number;
}

export interface DetailPerformaInv {
    id: number;
    doc_no: string;
    ref_no: string;
    ref_id: number;
    ref_type: number;
    partner_name: string;
    order_status: number;
    created_at: string;
    list_detail: ProductPerformaInv[];
}

export interface HistoryVersion {
    id: number;
    note: string;
    created_by: string;
    created_at: string;
}

class ConsignmentService extends BaseService {
    create = "/consignment/create-generate-invoice";

    getInvoice = "/consignment/get-invoice-sales";

    getPartnerInt = "/consignment/get-partner-internal";

    getProductInvoice = "/consignment/get-product-invoice";

    detail = "/consignment/detail-performa-c-invoice";

    createPickingList = "";

    listPerformaInv = "/consignment/list-performa-c-invoice";

    listSalesOrder = "/consignment/list-c-sales-order";

    history = "/consignment/get-history-internal-order";

    constructor() {
        super();
    }

    HistoryVersion<T extends HistoryVersion>(param: { id: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.history,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListPerformaInv<T extends ListPerformaInv[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listPerformaInv,
            });
            return req;
        });
    }

    Detail<T extends DetailPerformaInv>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    CreatePickingList<T extends any>(data: CreatePickingList, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetProductInvoice<T extends ProductInvoice[]>(data: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.getProductInvoice,
                data,
            });
            return req;
        });
    }

    GetPartnerInt<T extends ListPartnerInt[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPartnerInt,
            });
            return req;
        });
    }

    GetInvoice<T extends ListInvoice[]>(param: FilterInvoiceParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getInvoice,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateGenerateInvoice, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const consignmentService = new ConsignmentService();
export default consignmentService;

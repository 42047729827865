import { Button, Table } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { END_DATE, START_DATE, TYPE_SALE } from "pages/dashboard-pos";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { InvoiceProduct, MostProductValue } from "services/api-endpoints/dashboard-giias/report";
import { ORDER_CANCELED, TYPE_POS_OFFLINE } from "utils/constant";
import InvoiceOrderDetailModal from "./invoice-order-detail-modal";

type Props = ModalProps & {
    item: MostProductValue;
};

const ModalInvoiceMostValue = ({ item, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get(START_DATE);
    const endDate = searchParams.get(END_DATE);
    const typeSales = searchParams.get(TYPE_SALE) || TYPE_POS_OFFLINE;
    const page = searchParams.get("page") || 1;

    const getInvoiceReportQuery = useQuery(
        [reportService.invoicePerProduct, startDate, endDate, typeSales, page, item.product_id],
        async () => {
            return (
                await reportService.InvoicePerProduct({
                    end_date: endDate,
                    start_date: startDate,
                    type: typeSales,
                    id: item.product_id,
                    page,
                })
            ).data.data;
        },
        {
            enabled: modalOpen,
        }
    );

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnType<InvoiceProduct>[] = [
        {
            width: "50px",
            title: "No",
            render: (text, record, i) => <p className="capitalize m-0">{(((page || 1) as number) - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Invoice",
            dataIndex: "inv_no",
            render: (text, record) => (
                <InvoiceOrderDetailModal item={record}>
                    {(ctrl) => (
                        <Button onClick={ctrl.openModal} size="small" danger={record.status === ORDER_CANCELED}>
                            {text}
                        </Button>
                    )}
                </InvoiceOrderDetailModal>
            ),
        },
        {
            title: "Invoice Referensi",
            dataIndex: "inv_ref",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Total Price",
            dataIndex: "total_price",
            render: (text) => <p className="capitalize m-0">{(text as number)?.ToIndCurrency("Rp")}</p>,
        },
    ];

    const onCancelModal = () => {
        closeRef.current?.click();
        searchParams.delete("page");
        setSearchParams(searchParams);
    };

    return (
        <ModalTemplate
            {...props}
            title={item.product_name}
            onCancel={onCancelModal}
            isModalOpenFn={(isOpen) => {
                setModalOpen(isOpen);
            }}
            destroyOnClose
            width={700}
            handlerInComponent={children}
            footer={false}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        rowKey={(val) => val.id!}
                        loading={getInvoiceReportQuery.isLoading}
                        size="small"
                        dataSource={getInvoiceReportQuery.data?.list || []}
                        columns={columns}
                        pagination={{
                            current: getInvoiceReportQuery.data?.current_page,
                            total: getInvoiceReportQuery.data?.total_data,
                            pageSize: 20,
                            showSizeChanger: false,
                        }}
                        onChange={handleTableChange}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalInvoiceMostValue;

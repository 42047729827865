import React, { useState } from "react";
import { Image, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { IMAGE_FALLBACK, VIEW_PATH } from "utils/constant";
import ButtonAccessView from "components/button/button-access-view";
import { TDataQuantity } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const ImagesPreview = ({ images }: { images: string[] }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Image preview={{ visible: false }} width={80} src={images[0]} onClick={() => setVisible(true)} fallback={IMAGE_FALLBACK} />
            <div style={{ display: "none" }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                    {images?.map((src: string) => (
                        <Image src={src} key={src} fallback={IMAGE_FALLBACK} />
                    ))}
                </Image.PreviewGroup>
            </div>
        </>
    );
};

const QuantityTable = <T extends TDataQuantity>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouseId = searchParams.get("warehouse_id");

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: TDataQuantity) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.product_id}&warehouse_id=${warehouseId}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            width: "80px",
            render: (text) => <p className="capitalize m-0">{text || "0"}</p>,
        },
        {
            title: "Image",
            dataIndex: "images",
            width: "100px",
            render: (images) => {
                if (!images) return "";
                return <ImagesPreview images={images || []} />;
            },
        },
        {
            title: "Area",
            dataIndex: "list_area",
            render: (text, record) => <p className="capitalize m-0">{record?.list_area?.length !== 0 ? record?.list_area?.join(", ") : "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_name",
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Brand",
            dataIndex: "brand",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Model",
            dataIndex: "model_detail",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        // {
        //     title: "Model Year",
        //     dataIndex: "model_year",
        //     render: (text, record) => <p className="capitalize m-0">{record?.model_year?.join(", ")}</p>,
        // },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Model Type",
            dataIndex: "model_type_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default QuantityTable;

import { Select } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { SelectOption } from "models";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (orderId: number | null) => void;
    value: number | null;
    subTitle: string;
};

const ModalConfirm = ({ onSubmit, value, subTitle, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const onSubmitHandler = () => {
        onSubmit(value);
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} onOk={onSubmitHandler}>
            {(dt) => (
                <>
                    <h3>{subTitle}</h3>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalConfirm;

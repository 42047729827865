import { Input, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (value: string) => void;
};

const ModalDelete = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [value, setValue] = useState("");

    const onSubmitHandler = () => {
        onSubmit(value);
        closeRef.current?.click();
        setValue("");
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Input.TextArea rows={10} placeholder="Please write note for delete" value={value} onChange={(e) => setValue(e.target.value)} />
                    <div className="w-full relative flex flex-col gap-1 items-end">
                        <Button type="primary" className="items-center mt-4" onClick={onSubmitHandler} disabled={value === ""}>
                            Delete
                        </Button>
                    </div>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalDelete;

import { Button } from "antd";
import ModalDownload from "components/modal/download_modal";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterOrder from "modules/master-data/order/index/filter";
import OrderTable from "modules/master-data/order/index/table";
import { parseOrderToCsvData } from "modules/master-data/product/utils";
import { FaDownload } from "react-icons/fa";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import orderService, { DownloadParams } from "services/api-endpoints/dashboard/master-data/order";
import Utils from "utils";
import FilterListCancelOrder from "modules/master-data/list-cancel-order/filter";

const filter = ["order_number", "invoice_no", "client_name", "start_date", "end_date", "order_type", "offline_status"];

const ListCancelOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([orderService.listCancelOrder, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await orderService.ListCancelOrder(filterValue as any)).data.data;
    });

    const getDownload = useMutation(["export-csv"], async (data: DownloadParams) => {
        const req = await (() => {
            return orderService.DownloadOrder(data);
        })();
        return parseOrderToCsvData(req.data.data?.list, req.data.data?.list_summary || []);
    });

    const onDownload = async (type: number | null, startDate: any, endDate: any) => {
        const parseData: DownloadParams = {
            type,
            start_date: startDate,
            end_date: endDate,
        };
        getDownload.mutateAsync(parseData).then((res) => {
            Utils.jsonToCSV({ json: res as any, title: "List Order", showLabel: true });
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterListCancelOrder filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Order Number / Invoice No / Client Name"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <ModalDownload title="Download" onSubmit={onDownload}>
                                {(dt2) => (
                                    <Button
                                        onClick={dt2.openModal}
                                        loading={getDownload.isLoading}
                                        type="primary"
                                        className="!flex !items-center w-fit"
                                    >
                                        <FaDownload className="mr-2" />
                                        Download
                                    </Button>
                                )}
                            </ModalDownload>
                        )}
                    />
                )}
            </FilterListCancelOrder>
            <OrderTable fetcher={getList} />
        </div>
    );
};

export default ListCancelOrder;

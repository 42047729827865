import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import OrderView from "pages/order/view";
import { ProductRef } from "services/api-endpoints/dashboard/master-data/consignment";

export type Props = ModalProps & {
    data?: ProductRef;
};

const InvoiceModal = ({ data, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => <OrderView asPreview paramId={data?.id} />}
        </ModalTemplate>
    );
};

export default InvoiceModal;

import moment from "moment";
import { FastMovingProduct } from "services/api-endpoints/dashboard/master-data/quantity";
import { FORMAT_DATE_1_TIME } from "utils/constant";
import XLSX from "xlsx-js-style";

const downloadFastMoving = ({ list, title }: { list: FastMovingProduct[]; title: string }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Code", ...headerStyle },
        { v: "Product Name", ...headerStyle },
        { v: "Subcategory Name", ...headerStyle },
        { v: "Attribute Name", ...headerStyle },
        { v: "Total Quantity", ...headerStyle },
        { v: "Unit Price", ...headerStyle },
        { v: "Locator", ...headerStyle },
        { v: "Current Stock", ...headerStyle },
    ];

    const rows: any[] = [];
    list?.forEach((el, listIndex) => {
        const row = [
            { v: listIndex + 1, t: "s" },
            { v: el.code1, t: "s" },
            { v: el.product_name, t: "s" },
            { v: el.subcategory_name, t: "s" },
            { v: el.attribute_name, t: "s" },
            { v: el.total_qty, t: "s" },
            { v: el.unit_price, t: "s" },
            { v: el.locator, t: "s" },
            { v: el.current_stock, t: "s" },
        ];
        rows.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}-${moment().format(FORMAT_DATE_1_TIME)}.xlsx`);
};

export default downloadFastMoving;

/* eslint-disable no-shadow */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { MenuRequest } from "services/api-endpoints/dashboard/master-data/menu";
import * as yup from "yup";
import { ItemRowSubMenu } from "./table";
import { toLowerSnakeCase } from "utils";
import { Icon } from "@mui/material";

export type FormMenu = Pick<MenuRequest, "name" | "key" | "icon">;

const schema: yup.SchemaOf<FormMenu> = yup.object().shape({
    name: yup.string().required("Name required"),
    key: yup.string().required("Key required"),
    icon: yup.string(),
});

export type ModalAddMenuProps = ModalProps & {
    onSubmit: (data: ItemRowSubMenu) => void;
    defaultValues?: FormMenu;
};

const ModalAddMenu = ({ children, onSubmit, defaultValues }: ModalAddMenuProps) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<FormMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const setDefaultValues = () => {
        form.setFieldsValue(defaultValues);
        setValue("name", defaultValues!.name);
        setValue("key", defaultValues!.key);
        setValue("icon", defaultValues?.icon || "");
    };

    useEffect(() => {
        if (defaultValues) {
            setDefaultValues();
        }
    }, [defaultValues]);

    const watchIcon = watch("icon");

    const onSubmitHandler = handleSubmit((data) => {
        onSubmit({ ...data, icon: data?.icon ? toLowerSnakeCase(data.icon) : "" });
        closeRef?.current?.click();
        if (defaultValues) {
            setDefaultValues();
            return;
        }
        form.resetFields();
        setValue("name", "");
        setValue("key", "");
        setValue("icon", "");
    });

    return (
        <ModalTemplate title="Sub menu / Child menu" width={700} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="grid grid-cols-2 gap-x-4">
                            <ControlledInputText control={control} name="name" placeholder="Menu Name" label="Menu Name" />
                            <ControlledInputText control={control} name="key" placeholder="Menu Key" label="Menu Key" />
                            <div className="flex items-start gap-1">
                                {watchIcon && <Icon fontSize="medium">{toLowerSnakeCase(watchIcon)}</Icon>}
                                <ControlledInputText control={control} name="icon" placeholder="Icon" label="Icon" />
                            </div>
                            <div className="flex items-start gap-3">
                                <a href="https://mui.com/material-ui/material-icons/" target="_blank" className="" rel="noreferrer">
                                    icons
                                </a>
                            </div>
                            <div />
                            <Button htmlType="submit" type="primary" className="flex-1">
                                Save
                            </Button>
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddMenu;

import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import deliveryOrderService from "services/api-endpoints/dashboard-jsl/delivery-order";
import { FORMAT_DATE_1, STATUS_SUBMIT } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBPDeliveryOrder = ({ filter = [], ...props }: Props) => {
    const requesterQuery = useQuery([deliveryOrderService.getRequester], async () => {
        return (await deliveryOrderService.GetRequester()).data.data.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });
    return (
        <FilterModal
            filter={filter}
            title="Filter BP Delivery Order"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        {/* <Input
                            placeholder="Purchase Order No"
                            value={dt.values.po_no}
                            name="po_no"
                            onChange={(e) => dt.setOneValue("po_no", e.target.value)}
                        />
                        <Input
                            placeholder="Sales Order No"
                            value={dt.values.so_no}
                            name="so_no"
                            onChange={(e) => dt.setOneValue("so_no", e.target.value)}
                        /> */}
                        <Input
                            allowClear
                            placeholder="Delivery Order No"
                            value={dt.values.do_no}
                            name="do_no"
                            onChange={(e) => dt.setOneValue("do_no", e.target.value)}
                        />
                        <Input
                            allowClear
                            placeholder="Invoice No"
                            value={dt.values.inv}
                            name="inv"
                            onChange={(e) => dt.setOneValue("inv", e.target.value)}
                        />
                        <Select
                            allowClear
                            value={Number(dt.values.bp_id)}
                            placeholder="Request From"
                            loading={requesterQuery.isLoading}
                            options={requesterQuery.data || []}
                            onChange={(value) => dt.setOneValue("bp_id", value)}
                        />
                        {/* <Select
                            allowClear
                            value={dt.values.status !== null && dt.values.status !== undefined ? Number(dt.values.status) : dt.values.status}
                            placeholder="Status"
                            options={STATUS_SUBMIT}
                            onChange={(value) => dt.setOneValue("status", value)}
                        /> */}
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBPDeliveryOrder;

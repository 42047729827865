import { Space } from "antd";
import React from "react";

type Props = {
    title?: string;
    leftAddition?: () => React.ReactNode;
    rightAddition?: () => React.ReactNode;
};

const ToolbarAction = ({ title = "title", leftAddition, rightAddition }: Props) => {
    return (
        <div className="w-full flex items-center justify-between">
            <Space direction="horizontal">
                <h2 className="font-medium text-base text-gray-400 capitalize">{title}</h2>
                {leftAddition ? leftAddition() : null}
            </Space>
            <Space>{rightAddition ? rightAddition() : null}</Space>
        </div>
    );
};

export default ToolbarAction;

/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import masterBpService, { PaymentTerm, CreateBP } from "services/api-endpoints/accounting/master-bp";

type SelectOptionCustom = SelectOption & PaymentTerm;

export interface Props extends ControlledInputSelectProps<CreateBP> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withPaymentTerm<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([masterBpService.getPaymentTerm], async () => {
            const req = await masterBpService.GetPaymentTerm();
            return req.data.data?.map((el) => ({ ...el, label: el.payment_term_code, value: el.payment_term_id } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.payment_term_id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const PaymentTermSelect = withPaymentTerm(ControlledSelectInput);

export default PaymentTermSelect;

import { Button, Card, Collapse, Form, Select, Space, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiPlus, BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { AUTOGENERATED, FORMAT_DATE_1, ORDER_PAYMENT_ONLINE, TYPE_ORDER_PAYMENT } from "utils/constant";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectOption } from "models";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService from "services/api-endpoints/dashboard/payment";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import { RiDraftLine } from "react-icons/ri";
import moment from "moment";
import TableAddJurnalUmum from "modules/accounting/jurnal-umum/table-add";
import ModalAddItemJurnalUmum, { AddItem, AddItemT } from "modules/accounting/jurnal-umum/modal-add-item";
import jurnalUmumService, { BodyChildJurnalUmum, BodyCreateJurnalUmum } from "services/api-endpoints/accounting/jurnal-umum";

const schema: yup.SchemaOf<Partial<BodyCreateJurnalUmum>> = yup.object().shape({
    transaction_date: yup.string().required("Date Required"),
    id: yup.number(),
    child: yup.array(),
});

const JurnalUmumAdd = () => {
    const navigate = useNavigate();
    const [list, setList] = useState<AddItemT[]>([]);
    const [editedItem, setEditedItem] = useState<AddItemT | undefined>(undefined);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<BodyCreateJurnalUmum>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchId = watch("id");
    const watchDate = watch("transaction_date");

    const balanceAmount = list?.reduce((a, b) => (b.type ? a + b.amount : a), 0) === list?.reduce((a, b) => (!b.type ? a + b.amount : a), 0);

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const bpQuery = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const onAddItem = (item: AddItem & { id?: any }) => {
        setList((prev) => {
            if (prev?.find((el) => el.id === item.id)) {
                return prev?.map((el) => (el.id !== item.id ? el : item));
            }
            return [...prev, item];
        });
        setEditedItem(undefined);
    };

    const onDeleteItem = (item: AddItemT) => {
        setList((prev) => prev?.filter((el) => el.id !== item.id));
    };

    const onEditItem = (item: AddItemT) => {
        setEditedItem(item);
    };

    const saveMutate = useMutation(async (data: BodyCreateJurnalUmum) => {
        return (await jurnalUmumService.Create(data)).data;
    });

    const saveDraftMutate = useMutation(async (data: BodyCreateJurnalUmum) => {
        return (await jurnalUmumService.CreateDraft(data)).data;
    });

    const prepareData = () => {
        return [...list]?.map(
            (el) =>
                ({
                    coa_id: el.account_detail?.coa_id,
                    debit: el.type ? null : el.amount,
                    kredit: el.type ? el.amount : null,
                    name: el.account_detail?.coa_name,
                    note: el.note,
                } as BodyChildJurnalUmum)
        );
    };

    const onCreateSubmitHandler = () => {
        saveMutate
            .mutateAsync({
                id: watchId,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Journal");
                navigate(-1);
            });
    };

    const save = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftSubmitHandler = () => {
        saveDraftMutate
            .mutateAsync({
                id: watchId,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Draft Journal");
                navigate(-1);
            });
    };

    const saveDraft = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onDraftSubmitHandler();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add jurnal umum"
                rightAddition={() => (
                    <Space>
                        <Button
                            onClick={saveDraft}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading}
                            loading={saveDraftMutate.isLoading}
                            type="ghost"
                            className="!flex !items-center !bg-white"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            onClick={save}
                            loading={saveMutate.isLoading}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical">
                    <div className="grid grid-cols-4 gap-x-5">
                        <p className="capitalize m-0 col-span-1">
                            Journal No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledInputDate control={control} name="transaction_date" placeholder="Transaction Date" label="Transaction Date" />
                    </div>
                </Form>
            </Card>
            <ModalAddItemJurnalUmum afterClose={() => setEditedItem(undefined)} defaultValues={editedItem} onSubmit={onAddItem}>
                {(ctrl) => (
                    <div className="w-full flex items-end justify-between">
                        <Button
                            disabled={saveMutate.isLoading || saveDraftMutate.isLoading}
                            type="default"
                            className="!flex !items-center w-fit"
                            onClick={ctrl.openModal}
                        >
                            <BiPlus />
                            Add Item
                        </Button>
                        {!balanceAmount ? <p className="m-0 text-red-400">The number of credit and debit amount must be balance</p> : null}
                    </div>
                )}
            </ModalAddItemJurnalUmum>
            <TableAddJurnalUmum onClickEdit={onEditItem} onClickDelete={onDeleteItem} list={list} />
        </div>
    );
};

export default JurnalUmumAdd;

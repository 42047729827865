/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import { DetailList } from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function viewProductTable<T extends DetailList>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);
        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "SO NO",
                dataIndex: "so_no",
                render: (text, record) => <p className="capitalize m-0">{text || "FREE"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            // {
            //     title: "Price",
            //     dataIndex: "product_price",
            //     render: (text) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
            // },
            {
                title: "Quantity Send",
                dataIndex: "qty_send",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Receipt",
                dataIndex: "qty_gr",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id && record.po_id === edited?.po_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited?.product_id && record.po_id === edited?.po_id;
        const rowKey = (record: T) => `${record?.product_id}-${record.po_id}`;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                action={false}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const ProductTableBPView = viewProductTable(EditTable);
export default ProductTableBPView;

import { Icon } from "@mui/material";
import { Alert, Skeleton, StepProps, Steps } from "antd";
import State from "components/common/state";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import consignmentService from "services/api-endpoints/dashboard/master-data/consignment";
import { FORMAT_DATE_TIME } from "utils/constant";

interface Props {
    id?: any;
}

const VersionHistory = ({ id }: Props) => {
    const getHistory = useQuery([consignmentService.history, id], async () => (await consignmentService.HistoryVersion({ id, page: 1 })).data.data);

    const versioning = React.useCallback(
        () =>
            getHistory.data?.list?.map(
                (version) =>
                    ({
                        status: "finish",
                        icon: <Icon fontSize="medium">beenhereicon</Icon>,
                        title: <span className="text-sm">{version.note || "-"}</span>,
                        description: (
                            <div className="">
                                <div className="flex items-center mb-2">
                                    {version.created_by && <p className="m-0">By: {version.created_by}</p>}
                                    <p className="m-0">, at {moment(version.created_at).format(FORMAT_DATE_TIME)}</p>
                                </div>
                            </div>
                        ),
                    } as StepProps)
            ),
        [getHistory.data]
    );

    return (
        <State data={getHistory.data} isLoading={getHistory.isLoading} isError={getHistory.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        <Steps direction="vertical" size="default" items={versioning() || []} />
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(getHistory.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default VersionHistory;

import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/style/lib/is-active-select";
import { useMutation } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import { CreateDataStyle } from "modules/master-data/style/models";
import styleService from "services/api-endpoints/dashboard/master-data/style";

const schema: yup.SchemaOf<Partial<CreateDataStyle>> = yup.object().shape({
    style_name: yup.string().required("Style Name required"),
    style_code: yup.string().required("Style Code required"),
    is_active: yup.number().required("Is Active required"),
});

const StyleAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<CreateDataStyle>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [progress, onUploadProgress] = useProgressUpload();

    const createMutation = useMutation(
        [styleService.Create],
        async (data: CreateDataStyle) => {
            return (await styleService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Style created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new syle"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="max-w-2xl">
                <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                    <h1 className="capitalize font-semibold text-gray-500 mb-4">brand information</h1>
                    <ControlledInputText
                        control={control}
                        label="Style Name"
                        labelCol={{ span: 24 }}
                        name="style_name"
                        placeholder="Style Name"
                        style={{ transform: "translateY(-6px)" }}
                    />
                    <ControlledInputText
                        control={control}
                        label="Style Code"
                        labelCol={{ span: 24 }}
                        name="style_code"
                        placeholder="Style Code"
                        style={{ transform: "translateY(-6px)" }}
                    />
                    <IsActiveSelect
                        control={control}
                        label="Is Active"
                        labelCol={{ span: 24 }}
                        name="is_active"
                        placeholder="Is Active"
                        style={{ transform: "translateY(-6px)" }}
                    />
                </Form>
            </Card>
        </div>
    );
};

export default StyleAdd;

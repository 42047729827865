import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { UseQueryResult } from "react-query";
import { Role } from "services/api-endpoints/dashboard/master-data/role";
import RoleModalEdit from "../edit/modal-edit";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const RoleTable = <T extends Role[]>({ fetcher }: Props<T>) => {
    const columns: ColumnsType<Role> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Role Name",
            dataIndex: "name",
        },
        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (val) => (val ? "Active" : "Non active"),
        },
        {
            width: "60px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <RoleModalEdit refresh={fetcher.refetch} role={record}>
                    {(ctrl) => <ButtonAccessEdit onClick={ctrl.openModal} type="text" />}
                </RoleModalEdit>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default RoleTable;

import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes, backgroundColorManufacture, getIdElementEntity } from "modules/manufacture/utils/constant";
import { Entity, MultipleOperatorData, MultipleOperatorEntity } from "modules/manufacture/utils/models";
import React, { useEffect } from "react";
import { useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";
import PlaceholderOperator from "../operator/placeholder-operator";
import { clsx } from "clsx";
// import PlaceholderOperator from "./placeholder-operator";

type OperatorProps = {
    attach?: Entity;
};

const Operator = ({ attach }: OperatorProps) => {
    const { entities, models, setEntities } = React.useContext(ManufactureContext) as any;

    const dropItem = (data: Entity) => {
        setEntities((ents: Entity[]) => {
            let tempEntities = [...ents];
            const tempIdEntity = data?.idEntity || uuid();

            tempEntities = tempEntities.map((ent) => {
                // put idEntity to parent children
                if (ent?.idEntity !== attach?.idEntity) return ent;
                const thisEntity = ent as MultipleOperatorEntity;
                if (thisEntity.data?.children?.find((id: string) => id === tempIdEntity)) return ent;
                return {
                    ...thisEntity,
                    data: {
                        ...thisEntity?.data,
                        children: [...(thisEntity?.data?.children || []), tempIdEntity],
                    },
                };
            });

            if (data?.idEntity) {
                // put idEntityParent to itemDrop
                tempEntities = tempEntities.map((ent) => {
                    if (ent?.idEntity !== data?.idEntity) return ent;
                    return {
                        ...ent,
                        idEntityParent: attach?.idEntity,
                    };
                });
            } else {
                // put data to entities and set idEntityParent
                tempEntities = [...tempEntities, { ...data, idEntity: tempIdEntity, idEntityParent: attach?.idEntity }];
            }

            if (data?.idEntityParent && data?.idEntityParent !== attach?.idEntity) {
                tempEntities = tempEntities?.map((ent) => {
                    if (ent?.idEntity !== data?.idEntityParent) return ent;
                    return {
                        ...ent,
                        data: {
                            ...ent?.data,
                            children: (ent?.data as MultipleOperatorData<string>)?.children?.filter((id: string) => id !== tempIdEntity),
                        },
                    };
                });
            }

            return tempEntities;
        });
    };

    const [{ isOver, itemDrop }, drop] = useDrop(
        () => ({
            accept: [MenuTypes.subProcess.type, MenuTypes.operator.type, MenuTypes.multipleOperator.type, MenuTypes.model.type],
            drop(item: Entity) {
                dropItem(item);
                return undefined;
            },
            collect(monitor) {
                return {
                    isOver: !!monitor.isOver({ shallow: true }),
                    itemDrop: monitor.getItem() as Entity,
                };
            },
        }),
        [entities, models]
    );

    useEffect(() => {
        const container = document.querySelector(getIdElementEntity(attach?.idEntity));
        if (isOver) {
            container?.classList.add("outline", "outline-gray-500");
        } else {
            container?.classList.remove("outline", "outline-gray-500");
        }
    }, [isOver]);

    return (
        <div ref={drop} className={clsx("min-w-[100px] min-h-[50px] rounded shadow-inner relative", backgroundColorManufacture.primary)}>
            {isOver ? <PlaceholderOperator entity={itemDrop} /> : null}
        </div>
    );
};

export default Operator;

/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ProductWithBOM } from "pages/manufacturing-order/add";
import React from "react";
import { BOM, LocatorManufacturingOrder } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import TableLocatorManufacturing from "./table-locator";

export type BOMWithLocatorAndNote = BOM &
    LocatorManufacturingOrder & {
        note?: any;
    };

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export type TransformListToBOMCentris = BOMWithLocatorAndNote & {
    product: ProductWithBOM;
    numberChild: number;
    qty?: number;
    hideRemoveAction?: boolean;
};

export function addTable<T extends ProductWithBOM>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<TransformListToBOMCentris | null>(null);

        const transformList = list.reduce((arr: TransformListToBOMCentris[], product: ProductWithBOM) => {
            const mapping = product.child?.map((child, i) => ({
                ...child,
                product,
                numberChild: i,
                hideRemoveAction: i !== 0,
            })) as TransformListToBOMCentris[];
            return [...arr, ...mapping];
        }, []);

        const onSubmitLocator = (row: TransformListToBOMCentris) => {
            return (locator: LocatorManufacturingOrder) => {
                if (setList) {
                    setList((prev) => {
                        return prev?.map((product) => {
                            if (product.product_id !== row.product?.product_id) return product;
                            return {
                                ...product,
                                child: product?.child?.map((child) => {
                                    if (child.product_id !== row.product_id) return child;
                                    return {
                                        ...child,
                                        ...locator,
                                    };
                                }),
                            };
                        });
                    });
                }
            };
        };

        const childColumns: ColumnType<TransformListToBOMCentris>[] = [
            {
                title: "Code WIP",
                dataIndex: "code1",
            },
            {
                title: "Quantity",
                dataIndex: "qty_bom",
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => {
                    if (editRow?.product_id === record.product_id && editRow?.product?.product_id === record?.product?.product_id) {
                        return (
                            <TableLocatorManufacturing title="Pick Locator" onSubmit={onSubmitLocator(record)} productId={record.product_id}>
                                {(ctrl) => (
                                    <Button
                                        size="small"
                                        onClick={() => ctrl.openModalWithData(record)}
                                        className="!flex !items-center"
                                        type="default"
                                        {...(!text ? { danger: true } : {})}
                                    >
                                        {text || "Choose Locator"}
                                    </Button>
                                )}
                            </TableLocatorManufacturing>
                        );
                    }
                    return text;
                },
            },
            {
                title: "Note",
                dataIndex: "note",
                ...{ editable: true },
            },
        ];

        const columns: ColumnType<TransformListToBOMCentris>[] = [
            {
                title: "Code 1",
                render: (_, record) => record.product.product_code1,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            {
                title: "Molding Code",
                render: (_, record) => record.product.molding_code,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            {
                title: "Product Name",
                render: (_, record) => record.product.product_name,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                ...{ editable: true },
                render: (_, record) => record.product.qty,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            ...childColumns,
        ];

        const isEditing = (record: TransformListToBOMCentris, edited: TransformListToBOMCentris | null) =>
            record.product_id === edited?.product_id && record.product.product_id === edited?.product?.product_id;
        const findIndexSave = (record: TransformListToBOMCentris, edited: TransformListToBOMCentris) =>
            record.product_id === edited?.product_id && record.product.product_id === edited?.product?.product_id;
        const rowKey = (record: TransformListToBOMCentris) => `${record.product_id}_${record.product?.product_id}`;

        const editInputType: EditTableProps<TransformListToBOMCentris>["editInputType"] = { qty: "number", note: "text" };

        const onSetList = (ls: TransformListToBOMCentris[], prev: TransformListToBOMCentris) => {
            const row = ls.find((el) => el.product_id === prev.product_id && el.product?.product_id === prev.product?.product_id);

            if (setList) {
                setList((prev) => {
                    return prev?.map((product) => {
                        if (product?.product_id !== row?.product?.product_id) return product;
                        const newQty = row?.qty || row?.product?.qty;
                        return {
                            ...product,
                            qty: newQty,
                            child: product?.child?.map((child) => {
                                return {
                                    ...child,
                                    note: child.product_id !== row?.product_id ? child?.note : row?.note,
                                    qty_bom: (newQty || 1) * (child.qty_bom_temp || 1),
                                };
                            }),
                        };
                    });
                });
            }
        };

        const removeRow = (row: TransformListToBOMCentris) => {
            if (setList) {
                setList((prev) => {
                    return prev.filter((product) => product.product_id !== row.product?.product_id);
                });
            }
        };

        return (
            <Component
                {...props}
                removeItemList={removeRow as any}
                bordered
                list={transformList as any}
                setEditRow={setEditRow as any}
                onSetList={onSetList as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const AddManufacturingOrder = addTable(EditTable);
export default AddManufacturingOrder;

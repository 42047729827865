/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { Button, Card, Form, Image, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import PrintGr from "modules/master-data/purchasing/print-gr";
import { transformToChild } from "modules/purchasing/inventory/lib/utils";
import { CustomVoucherList } from "modules/purchasing/inventory/models";
import TenderViewPurchasing from "modules/purchasing/inventory/view/tender-view";
import moment from "moment";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { AiOutlineCar } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import purchasingInventoryService, {
    BodyCreatePurchasingInventory,
    DetailVendor,
    InventoryItem
} from "services/api-endpoints/purchasing/inventory";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";
import {
    FORMAT_DATE_1,
    FORMAT_DATE_2,
    FORMAT_DATE_TIME
} from "utils/constant";
import * as yup from "yup";

export interface CreatePurchasingInventory
    extends Omit<
        BodyCreatePurchasingInventory,
        "id" | "voucher" | "child" | "total_tax" | "total_disc" | "total_price" | "grand_total" | "doc_type"
    > {}

export interface DocReqChild extends ChildRequest {
    vendors?: {
        [columnIndex: number]: {
            vendor_id: any;
            price: any;
        };
    };
}

const schema: yup.SchemaOf<Partial<Omit<CreatePurchasingInventory, "vendor_id">>> = yup.object().shape({
    payment_term_id: yup.number().required("Payment required"),
    delivery_date: yup.string().required("Delivery date required"),
    delivery_address: yup.string().required("Delivery address required"),
    shipping_id: yup.number().required("Shipping required"),
    tax_type: yup.number().required("Tax Type required"),
    transaction_date: yup.string().required("Transaction date required"),
    tax_id: yup.number(),
    doc_id: yup.number(),
    pr_id: yup.array(),
    expenses: yup.array(),
    note: yup.string(),
});

export const PurchasingContext = createContext<any>({});

const GrInventoryViewContext = () => {
    const { paramId, asPreview, setPickedVendor, setListVendor, setDocReqChild, setListVoucher } = useContext(PurchasingContext);
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId || null : searchParams.get("id");
  
    const letterRef = useRef<HTMLDivElement | null>(null);
    const [expanses, setExpanses] = useState<ExpansesData[]>([]);

    const [form] = Form.useForm();

    const shippingQuery = useQuery([purchasingInventoryService.getShipping], async () => {
        return (await purchasingInventoryService.GetShipping()).data.data;
    });

    const detailQuery = useQuery(
        [purchasingInventoryService.getDetail, id],
        async () => {
            return (await purchasingInventoryService.GetDetail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setPickedVendor(data.header?.selected_vendor_id);
                setListVendor(data.list_vendor || []);
                setDocReqChild(transformToChild(data?.list_product_price || []));
                setListVoucher(data.header?.list_voucher);
                setExpanses(data?.header?.expenses?.map((val) => ({ ...val, charge_id: val.id, charge_name: val.name } as ExpansesData)) || []);
            },
        }
    );

    // const imageDeliveryQuery = useQuery([purchasingInventoryService.getImageDelivery, id], async () => {
    //     return (await purchasingInventoryService.GetImageDelivery({ po_history_id: id })).data.data;
    // });

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `GR Inventory - ${id}`,
        pageStyle: "@page {  }",
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintGr ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail GR Inventory"
                rightAddition={() => (
                    <>
                        <Button onClick={() => handlePrintLetter()} type="default" className="!flex !items-center">
                            <AiOutlineCar className="m-0 mr-2" />
                            Print Document Gr
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Purchasing Order Information</h1>
                                </div>
                                <Form form={form} layout="vertical">
                                    <div className="grid grid-cols-5 gap-6">
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Document No <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.gr_no || "-"}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            PO No <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.po_no || "-"}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Payment Method <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.payment_term}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Courier <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {
                                                    shippingQuery.data?.find((val) => val.shipping_id === detailQuery.data?.header?.shipping_id)
                                                        ?.shipping_name
                                                }
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            PO Note <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.note || "-"}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            GR Date <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {moment(detailQuery.data?.header?.gr_date).format(FORMAT_DATE_TIME)}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Transaction Date <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {moment(detailQuery.data?.header?.transaction_date).format(FORMAT_DATE_2)}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Delivery Date <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {moment(detailQuery.data?.header?.delivery_date).format(FORMAT_DATE_2)}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                            Delivery Address <br />
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.delivery_address}
                                            </span>
                                        </p>
                                        <p className="col-span-1 capitalize m-0 text-gray-700">
                                            grand total <br />
                                            <span className="font-bold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.grand_total || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                    </div>
                                </Form>
                            </Card>
                            <Card>
                                    <p className="capitalize m-0 text-gray-700 mb-2">Photo GR Inventory</p>
                                    <div className="grid grid-cols-12 gap-4">
                                        {(detailQuery.data?.header?.do_images || []).map((img) => (
                                            <Image src={img} />
                                        ))}
                                    </div>
                                    <p className="col-span-1 capitalize m-0 text-gray-700 mt-4">
                                        Catatan Penerima <br />
                                        <span className="font-semibold m-0 text-gray-700">{detailQuery.data?.header?.gr_note || "-"}</span>
                                    </p>
                                </Card>
                            <Card>
                                <div className="flex justify-between items-center">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                        PR No :
                                        <span className="m-0 font-normal text-gray-500">
                                            {" "}
                                            {detailQuery.data?.header?.pr_no ||
                                                (detailQuery.data?.header?.references || []).map((val) => val.pr_no).join(", ")}
                                        </span>
                                    </p>
                                </div>
                                <TenderViewPurchasing context={PurchasingContext} />
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const GrInventoryView = ({ paramId, asPreview }: Props) => {
    const [problemChild, setProblemChild] = useState<InventoryItem[]>([]);
    const [docReqChild, setDocReqChild] = useState<DocReqChild[]>([]);
    const [pickedVendor, setPickedVendor] = useState<number | null>(null);
    const [listVendor, setListVendor] = useState<DetailVendor[]>([]);
    const [listVoucher, setListVoucher] = useState<CustomVoucherList[]>([]);

    const getVendor = useQuery([purchasingInventoryService.getBp], async () => {
        return (await purchasingInventoryService.GetBP()).data.data?.map((el, i) => ({
            label: el.bp_name,
            value: el.bp_id,
        }));
    });

    const value = useMemo(
        () => ({
            paramId,
            asPreview,
            docReqChild,
            setDocReqChild,
            pickedVendor,
            setPickedVendor,
            getVendor,
            setProblemChild,
            problemChild,
            listVendor,
            setListVendor,
            listVoucher,
            setListVoucher,
        }),
        [paramId, docReqChild, pickedVendor, getVendor]
    );

    return (
        <PurchasingContext.Provider value={value}>
            <GrInventoryViewContext />
        </PurchasingContext.Provider>
    );
};

export default GrInventoryView;

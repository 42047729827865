import React from "react";

import Autoloka from "assets/images/autoloka-logo.png";
import { Order, OrderProduct } from "services/api-endpoints/dashboard/master-data/order";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = {
    products?: OrderProduct[];
    order?: Order;
};

const InvoicePrint = React.forwardRef<HTMLDivElement, Props>(({ products, order }, ref) => {
    const dpp = Number(((100 / 111) * (order?.grand_total || 1)).toFixed(2))?.ToIndCurrency("");
    const ppn = Number(((11 / 111) * (order?.grand_total || 1)).toFixed(2))?.ToIndCurrency("");

    if (!products || !order) return <div className="" />;
    return (
        <div ref={ref} className="w-full h-full bg-white relative hidden print:block">
            <img src={Autoloka} alt="" className="absolute -z--1 opacity-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70%]" />
            <div className="z-20 relative">
                <div className="w-full flex gap-4 ">
                    <img src={Autoloka} alt="" className="h-[120px]" />
                    <div className="flex flex-col">
                        <p className="m-0 text-gray-400 font-medium">
                            PT.SEHATI PRIMA MAKMUR <br />
                            <span>
                                Rukan Paris Golf Lake Residence Blok B NO 23-25 Cengkareng, RT. 10/RW. 14, East Cengkareng, Cengkareng, West Jakarta
                                City, Jakarta 11730 71.807.824.9-075.000
                            </span>
                        </p>
                        <p className="m-0 text-blue-500 font-bold text-5xl self-end">INVOICE</p>
                    </div>
                </div>
                <div className="w-full h-1 bg-blue-500 my-3" />
                <div className="w-full flex gap-16">
                    <div className="flex flex-col gap-6 flex-2">
                        <p className="text-blue-500 text-xl m-0">
                            BILL TO: <br />
                            <span className="font-semibold">{order?.alias_address}</span>
                        </p>
                        <p className="font-semibold tex-xl m-0">{order?.address}</p>
                    </div>
                    <table className="flex-1.5 mt-5 h-fit bg-white">
                        <thead className="border border-solid border-gray-400 ">
                            <th className="border border-solid border-gray-400 text-left px-2" colSpan={2}>
                                Invoice {order?.invoice_no}
                            </th>
                        </thead>
                        {order?.invoice_reference ? (
                            <thead className="border border-solid border-gray-400 ">
                                <th className="border border-solid border-gray-400 text-left px-2" colSpan={2}>
                                    {order?.invoice_reference}
                                </th>
                            </thead>
                        ) : null}
                        <thead className="border border-solid border-gray-400 ">
                            <th className="border border-solid border-gray-400 text-left px-2">Invoice Date</th>
                            <th className="border border-solid border-gray-400 text-left px-2">
                                {moment(order?.created_at).format(FORMAT_DATE_TIME)}
                            </th>
                        </thead>
                        <thead className="border border-solid border-gray-400 ">
                            <th className="border border-solid border-gray-400 text-left px-2">Due Date</th>
                            <th className="border border-solid border-gray-400 text-left px-2">{moment(order?.due_date).format(FORMAT_DATE_TIME)}</th>
                        </thead>
                    </table>
                </div>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 bg-blue-200">
                        <th className="border border-solid border-blue-500">No</th>
                        <th className="border border-solid border-blue-500">Description</th>
                        <th className="border border-solid border-blue-500">Qty</th>
                        <th className="border border-solid border-blue-500">Unit Price</th>
                        <th className="border border-solid border-blue-500">Total</th>
                    </thead>
                    {products?.map((product, index) => (
                        <tbody key={product.order_detail_id} className="border border-solid border-blue-500">
                            <td className="border border-solid border-blue-500 w-[50px] text-center">{index + 1}</td>
                            <td className="border border-solid border-blue-500 px-2">{product?.product_name}</td>
                            <td className="border border-solid border-blue-500 w-[50px] text-right px-2">{product?.quantity}</td>
                            <td className="border border-solid border-blue-500 w-[150px] text-right px-2">{product?.price?.ToIndCurrency("Rp")}</td>
                            <td className="border border-solid border-blue-500 w-[150px] text-right px-2">
                                {(product.quantity * product.price).ToIndCurrency("Rp")}
                            </td>
                        </tbody>
                    ))}
                </table>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 ">
                        <th className="border border-solid border-blue-500 text-right px-2" colSpan={2}>
                            DPP = {dpp} | PPN = {ppn}
                        </th>
                    </thead>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2">Subtotal</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2">{order?.total_price?.ToIndCurrency("Rp")}</td>
                    </tbody>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2">Discount</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2">{order?.discount?.ToIndCurrency("Rp")}</td>
                    </tbody>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2 font-semibold">Total Due</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2 font-semibold text-white bg-blue-500">
                            {order?.grand_total?.ToIndCurrency("Rp")}
                        </td>
                    </tbody>
                </table>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 ">
                        <th className="border border-solid border-blue-500 text-left px-2">SEND PAYMENT TO</th>
                        <th className="border border-solid border-blue-500 text-left px-2">PAYMENT CONFIRMATION</th>
                    </thead>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 px-2">{HTMLReactParser(order?.transfer_dest || "")}</td>
                    </tbody>
                    <thead className="border border-solid border-blue-500 ">
                        <th className="border border-solid border-blue-500 text-left px-2" colSpan={2}>
                            NOTES
                        </th>
                    </thead>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 px-2" colSpan={2}>
                            This is invoice generated by system, no signature requried
                        </td>
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default InvoicePrint;

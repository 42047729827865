/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Input, Popconfirm, Tree, message } from "antd";
import { clsx } from "clsx";
import AccountHeader from "components/header/account";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { FaRegSave, FaSitemap } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { FiMaximize2 } from "react-icons/fi";
import Utils from "utils";
import { Entities, ManufactureContext, findEntity } from "../context/context";
import {
    MenuTypes,
    cardMaterialItemInfo,
    cardOperatorItemInfo,
    cardProcessItemInfo,
    cardSubProcessItemInfo,
    cardVariableItemInfo,
    convertToStringFormat,
    operatorIcons,
} from "../utils/constant";
import { IoMdCheckmark } from "react-icons/io";
import { Entity, MaterialEntity, OperatorEntity, ProcessEntity, VariableEntity } from "../utils/models";
import { LiaTimesSolid } from "react-icons/lia";

type TotalType = { total: number; idEntity: string };

const IndicatorEntity = ({ className, ...props }: React.HTMLProps<HTMLDivElement>) => (
    <div {...props} className={clsx("w-5 h-5 rounded flex items-center justify-center p-1", className)} />
);

const transformChildrenTree = (entities: any[], totals: TotalType[], entity?: Entities): any => {
    if (!entity) return {};
    const base = {
        key: entity?.idEntity,
        type: entity?.type,
    };
    const total = totals.find((i) => i.idEntity === entity?.idEntity)?.total;
    if (entity.type === MenuTypes.material.type) {
        const materialEntity = entity as Entity<MaterialEntity>;
        return {
            ...base,
            title: (
                <div className={clsx("m-0 w-fit p-1 whitespace-pre rounded flex gap-1 items-center")}>
                    <IndicatorEntity className={cardMaterialItemInfo.style.color} />
                    {entity?.data?.text?.CapitalizeFirstLetter()}{" "}
                    <span className="text-gray-400 text-xs">
                        {(materialEntity.data?.total || 1) <= 1 ? "" : `( x ${materialEntity.data?.total})`}
                    </span>
                </div>
            ),
        };
    }
    if (entity.type === MenuTypes.variable.type) {
        const varEntity = entity as Entity<VariableEntity>;
        return {
            ...base,
            title: (
                <div className={clsx("m-0 w-fit p-1 whitespace-pre rounded flex gap-1 items-center")}>
                    <IndicatorEntity className={cardVariableItemInfo.style.color} />
                    {entity?.data?.text?.CapitalizeFirstLetter()}
                    <span className="text-gray-400 text-xs">
                        ( Rp.{Utils.convertToStringFormat(varEntity.data?.price)}{" "}
                        {(varEntity.data?.total || 1) <= 1 ? "" : `x ${varEntity.data?.total}`})
                    </span>
                </div>
            ),
        };
    }
    if (entity.type === MenuTypes.subProcess.type) {
        return {
            ...base,
            title: (
                <div className={clsx("m-0 w-fit p-1 whitespace-pre rounded flex gap-1 items-center")}>
                    <IndicatorEntity className={cardSubProcessItemInfo.style.color} />
                    {entity?.data?.text?.CapitalizeFirstLetter()}
                    <span className="text-gray-400 text-xs">(Rp. {Utils.convertToStringFormat(total)})</span>
                </div>
            ),
            children: entity?.data?.children
                ?.map((child) => {
                    return transformChildrenTree(entities, totals, child as Entities);
                })
                .filter((i) => Object.keys(i).length),
        };
    }

    if (entity.type === MenuTypes.multipleOperator.type) {
        const operatorEntity = entity as Entity<OperatorEntity>;
        const Icon = operatorIcons.find((icon) => icon.id === operatorEntity?.data?.icon)?.icon as React.ReactNode;
        return {
            ...base,
            title: (
                <div className={clsx("m-0 w-fit p-1 whitespace-pre rounded flex gap-1 items-center")}>
                    <IndicatorEntity className={cardOperatorItemInfo.style.color}>{Icon}</IndicatorEntity>
                    {entity?.data?.text?.CapitalizeFirstLetter()}
                    <span className="text-gray-400 text-xs">(Rp. {Utils.convertToStringFormat(total)})</span>
                </div>
            ),
            children: entity?.data?.children
                ?.map((id) => {
                    const tempId = id as unknown as string;
                    return transformChildrenTree(entities, totals, findEntity(entities, tempId));
                })
                .filter((i) => Object.keys(i).length),
        };
    }
    // below asuming process entity
    return {
        ...base,
        title: (
            <div className={clsx("m-0 w-fit p-1 whitespace-pre rounded flex gap-1 items-center")}>
                <IndicatorEntity className={cardProcessItemInfo.style.color}>
                    <FaSitemap />
                </IndicatorEntity>
                {entity?.data?.text?.CapitalizeFirstLetter()}
                <span className="text-gray-400 text-xs">(Rp. {Utils.convertToStringFormat(total)})</span>
            </div>
        ),
        children: entity?.data?.children
            ?.map((id) => {
                const tempId = id as unknown as string;
                return transformChildrenTree(entities, totals, findEntity(entities, tempId));
            })
            .filter((i) => Object.keys(i).length),
    };
};

const LeftSidebar = () => {
    const {
        modelEdit,
        saveToModel,
        activeEntity,
        entities,
        entitiesWithTotal,
        processEntitiesWithTotal,
        setExpandEntities,
        onChangeNameEntity,
        onDropEditModel,
        deleteEntity,
    } = React.useContext(ManufactureContext) as any;

    const entity = findEntity(entities, activeEntity?.idEntity) as Entity<OperatorEntity & ProcessEntity>;

    const mergeAllEntityTotal = [
        ...entitiesWithTotal.filter((i: TotalType) => !processEntitiesWithTotal.find((el: TotalType) => el.idEntity === i.idEntity)),
        ...processEntitiesWithTotal,
    ];

    const total = mergeAllEntityTotal.find((item: TotalType) => item.idEntity === entity?.idEntity) as TotalType;

    const transformToTree = React.useMemo(
        () => transformChildrenTree(entities, mergeAllEntityTotal, entity as Entities),
        [entities, entity, activeEntity, mergeAllEntityTotal]
    );

    const onChangeName = (e: any) => {
        onChangeNameEntity(entity, e.target.value);
    };

    const deleteEntityHandler = () => {
        deleteEntity(entity);
    };

    const saveToModelHandler = () => {
        saveToModel(entities, entity);
    };

    const onClickExpandAll = () => {
        setExpandEntities((prev: string[]) => {
            if (prev.length) return [];
            return entities.map((ent: Entity) => ent.idEntity);
        });
    };

    const canSaveEditModel = entities.reduce((sum: number, ent: Entity) => (!ent?.idEntityParent ? sum + 1 : sum), 0) === 1;

    const onClickSaveEdit = () => {
        if (!entities.length) {
            message.error("At least drop one entity!");
            return;
        }
        if (!canSaveEditModel) {
            message.error("Draw area must only contain one entity and its children, remove other entity that not used!");
            return;
        }
        saveToModel(
            entities,
            entities.find((ent: Entity) => !ent.idEntityParent)
        );
    };

    return (
        <div className="relative z-50 shadow-xl">
            <motion.div
                onClick={(e) => e.stopPropagation()}
                animate={activeEntity ? { x: "-350px" } : { x: "0px" }}
                transition={{ duration: 0.2 }}
                className="fixed z-100 top-8 right-5 flex items-center gap-2 bg-white rounded-full shadow-lg"
            >
                <AnimatePresence>
                    {modelEdit && (
                        <motion.div
                            animate={{ width: "165px" }}
                            initial={{ width: 0 }}
                            exit={{ width: 0 }}
                            className="flex items-center bg-green-300 gap-3 rounded-full h-full py-2 overflow-hidden"
                        >
                            <motion.p animate={!modelEdit ? { width: "3px" } : {}} className="p-0 m-0 text-white whitespace-pre overflow-hidden ml-3">
                                Edit Mode
                            </motion.p>
                            <div className="flex items-center gap-2">
                                <Popconfirm onConfirm={onClickSaveEdit} title="Save Edit ?" okText="Yes" cancelText="No">
                                    <button
                                        type="button"
                                        title="Save edit"
                                        className="border-none flex items-center hover:bg-green-400 hover:text-white justify-center rounded-full w-7 h-7 cursor-pointer"
                                    >
                                        <IoMdCheckmark />
                                    </button>
                                </Popconfirm>
                                <Popconfirm onConfirm={onDropEditModel} title="Drop Edit ?" okText="Yes" cancelText="No">
                                    <button
                                        type="button"
                                        title="Drop edit"
                                        className="border-none mr-3 flex items-center hover:bg-red-300 hover:text-white justify-center rounded-full w-7 h-7 cursor-pointer text-red-400"
                                    >
                                        <LiaTimesSolid />
                                    </button>
                                </Popconfirm>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <Button
                    onClick={onClickExpandAll}
                    title="Manimize/Maximize"
                    size="small"
                    shape="circle"
                    className="!flex !items-center !justify-center !border-none ml-2"
                >
                    <FiMaximize2 className="text-lg" />
                </Button>
                <AccountHeader />
            </motion.div>
            <AnimatePresence>
                {activeEntity ? (
                    <motion.div
                        onClick={(e) => e.stopPropagation()}
                        initial={{ x: "350px" }}
                        animate={{ x: "0" }}
                        exit={{ x: "350px" }}
                        transition={{ duration: 0.2 }}
                        style={{ borderLeft: "1px solid #c7c9c8" }}
                        className="h-screen p-4 w-[350px] z-[20] bg-white flex flex-col shadow-xl gap-4 overflow-x-hidden overflow-y-auto fixed top-0 right-0"
                    >
                        <Button className="w-fit" type="primary">
                            <FaRegSave className="mr-2" /> Create
                        </Button>
                        <h1 className="font-light">
                            Total: <span className="text-xl font-semibold">Rp. {convertToStringFormat(total?.total)}</span>
                        </h1>
                        <div className="w-full">
                            <Input
                                status={entity?.data?.text?.trim() ? undefined : "error"}
                                value={entity?.data?.text}
                                onChange={onChangeName}
                                size="large"
                                placeholder="Process Name"
                            />
                            {!entity?.data?.text?.trim() && <span className="text-xs text-red-400">Title can not be empty</span>}
                        </div>
                        <div className="flex-1 w-full overflow-x-auto">
                            <Tree switcherIcon={<FaAngleDown />} defaultExpandAll showIcon showLine treeData={[transformToTree]} />
                        </div>
                        <div className="flex gap-2">
                            <Button onClick={deleteEntityHandler} danger type="text">
                                Remove Entity
                            </Button>
                            <Button type="text" disabled={!entity?.data?.text?.trim()} onClick={saveToModelHandler}>
                                Save to Model
                            </Button>
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

export default LeftSidebar;

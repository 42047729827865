import { Alert, Button, Card, message, Skeleton, Space, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import React from "react";
import { useMutation, useQuery } from "react-query";
import inventoryOutService, { AddRemarksData, SetStatusData } from "services/api-endpoints/dashboard-jsl/inventory-out";
import ProductTable from "modules/dashboard-jsl/inventory-out/view/product-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import State from "components/common/state";
import RemarksTable from "modules/dashboard-jsl/inventory-out/view/remarks-table";
import { BiSave } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import ModalNote from "components/modal/note-modal";

const InventoryOutEdit = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const pageRemark = searchParams.get("page-remark") || 1;

    const detailIO = useQuery([inventoryOutService.detailIO, id, page], async () => {
        return (await inventoryOutService.DetailIO({ id })).data.data;
    });

    const detailProductIO = useQuery(
        [inventoryOutService.detailProductIO, id],
        async () => {
            return (await inventoryOutService.DetailProductIO({ id, page })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const remarksQuery = useQuery([inventoryOutService.getRemarks, id, pageRemark], async () => {
        return (await inventoryOutService.GetRemarks({ id, page: pageRemark })).data.data;
    });

    const addRemarks = useMutation([inventoryOutService.addRemarks], async (data: AddRemarksData) => {
        return (await inventoryOutService.AddRemarks(data)).data.data;
    });

    const setStatus = useMutation([inventoryOutService.setStatus], async (data: SetStatusData) => {
        return (await inventoryOutService.SetStatus(data)).data.data;
    });

    const onSaveHandler = (value: string) => {
        setStatus.mutateAsync({ note: value, id: id as any, order_status: 0 }).finally(() => {
            message.success("Inventory Out saved");
            navigate(-1);
        });
    };

    const addRemarksHandler = (value: string) => {
        addRemarks.mutateAsync({ note: value, order_id: id as any }).finally(remarksQuery.refetch);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Edit inventory out"
                rightAddition={() => (
                    <Space>
                        <ModalNote title="Edit Confirmation" onSubmit={addRemarksHandler}>
                            {(dt) => (
                                <Button loading={addRemarks.isLoading} onClick={dt.openModal} className="!flex !items-center">
                                    <CgNotes className="m-0 mr-2" />
                                    Add remarks
                                </Button>
                            )}
                        </ModalNote>
                        <ModalNote title="Save status" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button loading={setStatus.isLoading} onClick={dt.openModal} type="primary" className="!flex !items-center">
                                    <BiSave className="m-0 mr-2" />
                                    Save and send
                                </Button>
                            )}
                        </ModalNote>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailIO.data} isLoading={detailIO.isLoading} isError={detailIO.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="capitalize m-0">
                                        document no <br /> <span className="underline italic m-0">{detailIO.data?.doc_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        requester <br /> <span className="underline italic m-0">{detailIO.data?.requester}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br /> <span className="underline italic m-0">{detailIO.data?.status ? "Open" : "Close"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        notes <br /> <span className="underline italic m-0">{detailIO.data?.note}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailIO.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable fetcher={detailProductIO} />,
                        },
                        {
                            label: `Remarks`,
                            key: "2",
                            children: <RemarksTable fetcher={remarksQuery} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default InventoryOutEdit;

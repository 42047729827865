/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton } from "antd";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ToolbarAction from "components/toolbar/action";
import TableEdit from "modules/user-magement/user-role-menu/table-edit";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import userManagementService, {
    DetailGetRoleMenu,
    EditRoleMenu,
    EditUserRoleMenuData,
    UserRoleMenu,
} from "services/api-endpoints/dashboard-all/user-management/user";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<UserRoleMenu, "id">> = yup.object().shape({
    user_id: yup.number().required("User Required"),
    rm_id: yup.array().required("User Menu Required"),
});

const UserRoleMenuEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [allMenu, setAllMenu] = React.useState<DetailGetRoleMenu[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<UserRoleMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchRoleMenu = watch("rm_id");

    const detailQuery = useQuery(
        [userManagementService.detailUserRoleMenu],
        async () => {
            return (await userManagementService.DetailUserRoleMenu({ user_id: id })).data.data;
        },
        {
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setAllMenu(data.list_role_menu || []);
            },
        }
    );

    const editMutation = useMutation(
        [userManagementService.editUserRoleMenu],
        async (data: EditUserRoleMenuData) => {
            return (await userManagementService.EditUserRoleMenu(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("User Role Menu Edited");
                navigate(-1);
            },
        }
    );

    const getRoleMenu = useQuery([userManagementService.listRoleMenu], async () => {
        return (await userManagementService.ListRoleMenu()).data.data;
    });

    const existMenuID = (detailQuery.data?.list_role_menu || []).map((el) => Number(el.role_menu_id));
    const availMenu = getRoleMenu.data?.map((el) => ({
        label: el.description || "",
        value: el.id || 0,
        disabled: existMenuID.includes(Number(el.id!)),
    }));

    const getRoleMenuDetail = useQuery(
        [userManagementService.detailGetRoleMenu, watchRoleMenu],
        async () => {
            return (await userManagementService.DetailGetRoleMenu({ id: (watchRoleMenu || []).join(",") })).data.data;
        },
        {
            refetchOnWindowFocus: false,
            enabled: !!watchRoleMenu,
            onSuccess(data) {
                const menu = [...(detailQuery.data?.list_role_menu || []), ...data.map((val) => ({ ...val, is_active: 1 }))];
                setAllMenu(menu);
            },
        }
    );

    const onSubmitHandler = () => {
        const oldMenu = allMenu.filter((val) => existMenuID.includes(Number(val.role_menu_id)));
        const changeMenu = oldMenu
            .filter((val) =>
                detailQuery.data?.list_role_menu?.some((val2) => val.role_menu_id === val2.role_menu_id && val.is_active !== val2.is_active)
            )
            .map((val) => ({ id: val.id, role_menu_id: val.role_menu_id, is_active: val.is_active } as EditRoleMenu));

        const newMenu = allMenu
            .filter((val) => !existMenuID.includes(Number(val.role_menu_id)))
            .map((val) => ({ id: val.id, role_menu_id: val.role_menu_id, is_active: val.is_active } as EditRoleMenu));
        const tempData: EditUserRoleMenuData = {
            user_id: id,
            role_menu: [...changeMenu, ...newMenu],
        };
        editMutation.mutate(tempData);
    };

    const onChangeActive = (roleMenuID: number | undefined, isActive: number) => {
        setAllMenu((prev) =>
            prev.map((val) => {
                if (val.role_menu_id === roleMenuID) {
                    return { ...val, is_active: isActive };
                }
                return val;
            })
        );
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Edit user role menu"
                rightAddition={() => (
                    <>
                        <Button loading={editMutation.isLoading} onClick={onSubmitHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />

            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div>
                                            <p>User</p>
                                            <p className="font-semibold">{detailQuery.data?.email}</p>
                                        </div>
                                        <div>
                                            <p>Existing Role Menu</p>
                                            <p className="font-semibold">
                                                {detailQuery.data?.list_role_menu?.map((val) => val.description).join(", ")}
                                            </p>
                                        </div>
                                        <ControlledSelectInput
                                            classNameForm="col-span-2"
                                            control={control}
                                            loading={getRoleMenu.isLoading}
                                            options={availMenu}
                                            name="rm_id"
                                            placeholder="New Role Menu"
                                            label="New Role Menu"
                                            showSearch
                                            mode="multiple"
                                        />
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 4 }} />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert type="error" message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
            <TableEdit data={allMenu} fetcher={getRoleMenuDetail} onChangeActive={onChangeActive} />
        </div>
    );
};

export default UserRoleMenuEdit;

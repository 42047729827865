import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, Image } from "antd";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { RiDraftLine, RiMapFill, RiMapLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import taskService from "services/api-endpoints/dashboard-all/team-force/task";

const TeamForceTaskView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const detailQuery = useQuery(
        [taskService.taskDetail, id],
        async () => {
            return (await taskService.TaskDetail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail task" />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Task Information</h1>
                                </div>
                                <Form layout="vertical">
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Employee Name <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.employee_name}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Dealer Brand <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.dealer_brand_name}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Pic Name <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.pic_name}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Pic Title <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.pic_title}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Package Name <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.off_package_name}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Description <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.description}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Address <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.address}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Created At <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.created_at}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col gap-2">
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                            Location <br />
                                            <a
                                                href={`https://maps.google.com/?q=${detailQuery.data?.lat},${detailQuery.data?.lon}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Button type="default" className="!flex !items-center" htmlType="button">
                                                    <RiMapLine className="m-0 mr-2" />
                                                    Open Maps
                                                </Button>
                                            </a>
                                        </p>
                                    </div>
                                </Form>
                            </Card>
                            {(detailQuery.data?.images || []).length !== 0 ? (
                                <Card>
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-2">Image Task</p>
                                    <div className="grid grid-cols-12 gap-4 items-start self-start">
                                        {(detailQuery.data?.images || []).map((img) => (
                                            <Image src={img} />
                                        ))}
                                    </div>
                                </Card>
                            ) : null}
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

export default TeamForceTaskView;

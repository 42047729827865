import { DatePicker } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import moment from "moment";
import { FORMAT_DATE_1 } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterWorkingOrderM = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Working Order M"
            forms={(dt) => {
                return (
                    <DatePicker.RangePicker
                        value={[
                            dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                            dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                        ]}
                        placeholder={["Start Date", "End Date"]}
                        onChange={(val) => {
                            dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                            dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                        }}
                    />
                );
            }}
            {...props}
        />
    );
};

export default FilterWorkingOrderM;

import { Button, Card, DatePicker, Form, message } from "antd";
import React from "react";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FORMAT_DATE_1 } from "utils/constant";
import { FCreateSeasonal } from "modules/master-data/seasonal-product/models";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import AdvanceSearch from "modules/advance-search";
import { Product } from "models";
import { MdAdd } from "react-icons/md";
import ProductTable from "modules/master-data/seasonal-product/add/product-table";
import { useMutation } from "react-query";
import seasonalProductService, { CreateSeasonal } from "services/api-endpoints/dashboard/master-data/seasonal-product";
import moment from "moment";

const schema: yup.SchemaOf<Partial<FCreateSeasonal>> = yup.object().shape({
    title: yup.string().required("Title required"),
    subtitle: yup.string().required("Sub title required"),
});

const SeasonalProductAdd = () => {
    const navigate = useNavigate();
    const [rangeDate, setRangeDate] = React.useState<{ start: any | null; end: any | null } | null>(null);
    const [products, setProducts] = React.useState<Product[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FCreateSeasonal>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const create = useMutation([seasonalProductService.create], async (data: CreateSeasonal) => {
        return (await seasonalProductService.Create(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        create
            .mutateAsync({
                ...data,
                start_date: moment(rangeDate?.start).format(FORMAT_DATE_1),
                end_date: moment(rangeDate?.end).format(FORMAT_DATE_1),
                seasonal_detail: products?.map((p) => ({ product_id: p.product_id })),
            })
            .then(() => {
                message.success("Seasonal Product Created");
                navigate(-1);
            });
    });

    const onDatePickerChange = (val: any) => {
        if (!val) setRangeDate(null);
        else
            setRangeDate({
                start: val?.[0],
                end: val?.[1],
            });
    };

    const saveHandler = () => form.submit();

    const removeProduct = (product: Product) => {
        setProducts((prev) => prev?.filter((pdct) => pdct.product_id !== product.product_id));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add seasonal product"
                rightAddition={() => (
                    <Button
                        loading={create.isLoading}
                        onClick={saveHandler}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={!rangeDate || products.length === 0}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <Form className="w-full" form={form} layout="horizontal" onFinish={onSubmitHandler}>
                    <div className="w-full grid grid-cols-3 gap-4">
                        <div className="capitalize m-0">
                            Title <br />
                            <ControlledInputText control={control} name="title" placeholder="Title" />
                        </div>
                        <div className="capitalize m-0">
                            Subtitle <br />
                            <ControlledInputText control={control} name="subtitle" placeholder="Sub title" />
                        </div>
                        <div className="capitalize m-0">
                            Start date - End date <br />
                            <DatePicker.RangePicker
                                value={[rangeDate?.start || null, rangeDate?.end || null]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={onDatePickerChange}
                            />
                        </div>
                    </div>
                </Form>
            </Card>
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={products} removeItemList={removeProduct} />
        </div>
    );
};

export default SeasonalProductAdd;

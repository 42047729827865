import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useState } from "react";
import { DetailProductData } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";

type Props<T> = {
    product?: T[];
};

const TablePOMError = <T extends DetailProductData>({ product }: Props<T>) => {
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Code 1",
            dataIndex: "code1",
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
        },
        {
            title: "Product Name",
            dataIndex: "name",
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            size="small"
            columns={columns}
            dataSource={product || []}
            className="w-full"
            pagination={{
                pageSize: 10,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TablePOMError;

/* eslint-disable no-plusplus */
import React, { useRef, useState } from "react";
import { Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import bpPickingListService, { DetailProduct, ListData } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import cPickingListService, { ListCSO, ProductCSO } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import { FORMAT_DATE_TIME } from "utils/constant";
import moment from "moment";

type Props<T> = {
    partnerID: number | null;
    partnerName: string;
    selectedSO: React.Key[];
    setSelectedSO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductSO: (data: ProductCSO[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
};

const PickingListAddModal = <T extends ListCSO>({ children, selectedSO, setSelectedSO, onProductSO, partnerID, partnerName }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const listPOQuery = useQuery(
        [cPickingListService.getCSalesOrder, partnerID],
        async () => {
            return (await cPickingListService.GetCSalesOrder<T[]>({ partner_id: partnerID })).data.data;
        },
        {
            enabled: !!partnerID,
        }
    );

    const detailProductCSOMutation = useMutation([cPickingListService.getProductCSO], async (ids: string) => {
        return (await cPickingListService.GetProductCSO({ order_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // const termsId = listPOQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.payment_term_id);
        // if ([...new Set(termsId)].length !== 1) {
        //     // setCanSubmit(false);
        // } else {
        //     // setCanSubmit(true);
        //     setTopId(termsId !== undefined ? termsId[0]! : null);
        // }
        setCanSubmit((newSelectedRowKeys?.length || 0) === 1);
        setSelectedSO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedSO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    const onOkHandler = () => {
        detailProductCSOMutation
            .mutateAsync(selectedSO.join(""))
            .then((res) => {
                const qtyApprove = res.map((val) => {
                    return {
                        ...val,
                        qty_approve: val.qty,
                    };
                });
                onProductSO(qtyApprove);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title={`List Sales Order - ${partnerName}`}
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: detailProductCSOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Selected 1 Sales Order</p>
                    <Table
                        rowKey={(i) => i.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default PickingListAddModal;

/* eslint-disable no-shadow */
import React, { useContext, useRef, useState } from "react";
import { Button, message, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TbFileInvoice } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import orderService, {
    DetailOrder,
    IsCheckData,
    Order,
    OrderProduct,
    QCData,
    QCDetailOrder,
} from "services/api-endpoints/dashboard/master-data/order";
import { IoClose } from "react-icons/io5";
import { TableRowSelection } from "antd/lib/table/interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import { APPROVE_BY_ADMIN, ORDER_APPROVE } from "utils/constant";
import { useReactToPrint } from "react-to-print";
import { StateContext } from "context/state";
import LocatorModal from "./locator-modal";
import InvoicePrint from "./invoice-print";

type Props<T> = {
    detailQuery: UseQueryResult<Order, unknown>;
    paramId?: any;
    asPreview?: boolean;
};

const DetailProduct = <T extends OrderProduct>({ detailQuery, paramId, asPreview }: Props<T>) => {
    const { updateAccess } = useContext(StateContext);

    const invoiceRef = useRef<HTMLDivElement | null>(null);
    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: detailQuery.data?.invoice_no || "",
        pageStyle: "@page { auto; }",
    });

    const id = paramId;

    const [products, setProducts] = useState<OrderProduct[]>([]);
    const [page, setPage] = useState(1);
    const [isAllCheck, setIsAllCheck] = useState(false);

    const orderProductQuery = useQuery(
        [orderService.detailOrderProduct, id],
        async () => {
            return (await orderService.DetailOrderProduct({ id: id as any })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                setProducts(data?.list_order_product || []);
            },
        }
    );

    const allDetailProducts = useMutation([orderService.allDetailProduct], async (id: any) => {
        return (await orderService.AllDetailProduct({ id })).data.data;
    });

    // const submitQCMutate = useMutation(
    //     [orderService.submitQC, id],
    //     async (orderID: any) => {
    //         return (await orderService.SubmitQC({ order_id: orderID })).data.data;
    //     },
    //     {
    //         onSuccess() {
    //             message.success("QC Submitted");
    //             detailQuery.refetch();
    //         },
    //     }
    // );

    const setQcCheckMutate = useMutation(
        [orderService.setQcCheck],
        async (data: QCData) => {
            return (await orderService.SetQcCheck(data)).data.data;
        },
        {
            onSuccess() {
                message.success("QC Check Success");
                detailQuery.refetch();
                orderProductQuery.refetch();
            },
        }
    );

    const setIsCheckMutate = useMutation(
        [orderService.setIsCheck],
        async (data: IsCheckData) => {
            return (await orderService.SetIsCheck(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Check update");
                detailQuery.refetch();
                orderProductQuery.refetch();
            },
        }
    );

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current as number);
    };

    const setIsCheckHandler = async () => {
        const areaNull = products.filter((val) => !val.area_id);
        if (areaNull.length) {
            message.error("Locator is Null, Please Select Locator");
            return;
        }
        const parseData: IsCheckData = {
            detail_order: products.map((el) => ({ order_detail_id: el.order_detail_id, area_id: el.area_id || null } as DetailOrder)),
        };
        if (detailQuery.data?.payment_status === APPROVE_BY_ADMIN && detailQuery.data.order_status === ORDER_APPROVE) {
            setIsCheckMutate.mutateAsync(parseData).finally(detailQuery.refetch);
            return;
        }
        message.error("Cannot save, payment and order must be in approve status");
    };

    const onSubmitLocator = (product: OrderProduct) => {
        setProducts((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id === product.product_id) {
                    return {
                        ...prd,
                        area_id: product.area_id,
                        area_name: product.area_name,
                    };
                }
                return prd;
            })
        );
    };

    const onRemoveLocator = (product: OrderProduct) => {
        setProducts((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id === product.product_id) {
                    return {
                        ...prd,
                        area_id: null,
                        area_name: null,
                    };
                }
                return prd;
            })
        );
    };

    const columns: ColumnsType<OrderProduct> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (text) => <p className="capitalize m-0 text-xs">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Stock",
            dataIndex: "stock_quantity",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Locator",
            dataIndex: "area_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
    ];

    const locatorColAction: ColumnsType<OrderProduct>[0] = {
        title: "Action",
        key: "locator_action",
        dataIndex: "area_id",
        fixed: "right",
        render: (text, record) => (
            <Space size={10}>
                <LocatorModal title="Locator" onSubmit={onSubmitLocator}>
                    {(dt) => (
                        <Button
                            size="small"
                            onClick={() => dt.openModalWithData(record)}
                            className="!flex !items-center"
                            type="default"
                            {...(!text ? { danger: true } : {})}
                        >
                            {!text ? "Incheck" : "Checked"}
                        </Button>
                    )}
                </LocatorModal>
                {text ? <IoClose className="text-red-400 text-xl mt-1 cursor-pointer" onClick={() => onRemoveLocator(record)} /> : null}
            </Space>
        ),
    };

    const setQcCheckHandler = () => {
        const parseData: QCData = {
            order_id: id,
            detail_order: products.map((el) => ({ order_detail_id: el.order_detail_id, qc_pass: 1 } as QCDetailOrder)),
        };
        setQcCheckMutate.mutate(parseData);
    };

    if (!asPreview && orderProductQuery.data?.mode === "PICK_LOCATOR" && !columns.find((col) => col.key === "locator_action") && updateAccess) {
        columns.push(locatorColAction);
    }

    const rowSelection: TableRowSelection<OrderProduct> = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: OrderProduct[]) => {
            setIsAllCheck(selectedRows?.length === products?.length);
        },
        fixed: "left",
        type: "checkbox",
    };

    const onDownloadInvoiceClick = () => {
        allDetailProducts.mutateAsync(id).then((res) => {
            setTimeout(() => {
                handlePrintInvoice();
            }, 400);
        });
    };

    return (
        <>
            {updateAccess && <InvoicePrint ref={invoiceRef} products={allDetailProducts.data?.list_order_product || []} order={detailQuery.data} />}
            <div className="flex flex-col gap-4 items-start">
                {updateAccess && (
                    <Space size={20}>
                        {!asPreview && (
                            <Space size={20}>
                                {orderProductQuery.data?.mode === "PICK_LOCATOR" && (
                                    <Button
                                        loading={setIsCheckMutate.isLoading}
                                        disabled={setIsCheckMutate.isLoading}
                                        onClick={setIsCheckHandler}
                                        size="small"
                                        type="primary"
                                        className="!flex !items-center"
                                    >
                                        Save
                                    </Button>
                                )}
                                {orderProductQuery.data?.mode === "QC_ITEM" && isAllCheck && (
                                    <Button
                                        onClick={setQcCheckHandler}
                                        loading={setQcCheckMutate.isLoading}
                                        disabled={setQcCheckMutate.isLoading}
                                        size="small"
                                        type="primary"
                                        className="!flex !items-center"
                                    >
                                        QC Pass
                                    </Button>
                                )}
                            </Space>
                        )}
                        <Button onClick={onDownloadInvoiceClick} loading={allDetailProducts.isLoading} size="small" className="!flex !items-center">
                            <TbFileInvoice className="mr-2" />
                            Download invoice
                        </Button>
                    </Space>
                )}
                <Table
                    size="small"
                    scroll={{ x: 1000 }}
                    loading={orderProductQuery.isLoading}
                    columns={columns}
                    dataSource={products?.map((el, i) => ({ ...el, key: i })) || []}
                    className="w-full"
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                    }}
                    {...(orderProductQuery.data?.mode === "QC_ITEM" ? { rowSelection } : {})}
                    onChange={handleTableChange}
                />
            </div>
        </>
    );
};

export default DetailProduct;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ChargeData {
    charge_id?: number;
    // charge_code?: string;
    charge_name?: string;
}

export interface BaseReceiptResponse<T = any> {
    list: T[];
    total_data: number;
    total_page: number;
    current_page: number;
}

export interface BankAccount {
    bank_account_id: number;
    bank_account_no: string;
}

export interface ExpansesData extends ChargeData {
    note?: any;
    amount?: number;
    bp_id?: any;
    bp_name?: any;
}

export interface ChildData {
    doc_id?: any;
    doc_no?: any;
    inv_ref?: any;
    inv_ref_id?: any;
    grand_total?: any;
    description?: any;
    date_invoice?: any;
    cc_charge?: any;
    ref_id?: any;
    payment_channel_name?: string;
}

export interface CreateReceipt {
    account_id?: any;
    bank_account_id?: any;
    transaction_date?: any;
    order_type_id?: any;
    partner_id?: any;
    child?: ChildData[];
    expenses: ExpansesData[];
    receipt_images: any[];
    receipt_amount?: any;
    total_receipt_amount?: any;
    note?: any;
    payment_id?: number;
}

export interface DraftReceipt extends CreateReceipt {
    id?: any;
}

export interface DetailReceipt extends CreateReceipt {
    id?: any;
    doc_no?: string;
    is_active?: number;
    account_name?: any;
}

export interface ReceiptList {
    account_name?: string;
    id?: number;
    doc_no?: string;
    name?: string;
    bank_account_id?: number;
    transaction_date?: number;
    created_by?: number;
    created_at?: number;
    receipt_amount?: number;
    total_receipt_amount?: number;
    order_type_id?: number;
    partner_name?: string;
    is_active?: number;
}

export interface FilterReceipt {
    doc_no: string;
    start_date: string;
    end_date: string;
    page: number | string;
}

export interface EditParams {
    id: any;
    transaction_date: any;
    receipt_images: any[];
}

class ReceiptService extends BaseService {
    getCharge = "/receipt-voucher/get-charge";

    bankAccount = "/receipt-voucher/get-bank-account";

    getListReceipt = "/receipt-voucher/get-receipt-voucher";

    createReceipt = "/receipt-voucher/create";

    draftReceipt = "/receipt-voucher/save-draft";

    detailReceipt = "/receipt-voucher/detail-receipt-voucher";

    deleteReceipt = "/receipt-voucher/delete-receipt-voucher";

    editReceipt = "/receipt-voucher/edit";

    constructor() {
        super();
    }

    EditReceipt<T extends any>(data: EditParams, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.editReceipt,
                data,
                config,
            });
            return req;
        });
    }

    DeleteReceipt<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteReceipt}/${id}`,
            });
            return req;
        });
    }

    CreateReceipt<T extends any>(data: CreateReceipt, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createReceipt,
                data,
                config,
            });
            return req;
        });
    }

    DraftReceipt<T extends any>(data: CreateReceipt, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftReceipt,
                data,
                config,
            });
            return req;
        });
    }

    SaveCreateReceipt<T extends any>(data: DraftReceipt, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createReceipt,
                data,
                config,
            });
            return req;
        });
    }

    SaveDraftReceipt<T extends any>(data: DraftReceipt, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftReceipt,
                data,
                config,
            });
            return req;
        });
    }

    DetailReceipt<T extends DetailReceipt>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailReceipt,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetListReceipt<T extends ReceiptList>(param: FilterReceipt) {
        return this.ProxyRequest<BaseReceiptResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseReceiptResponse<T>>({
                url: this.getListReceipt,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetCharge<T extends ChargeData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCharge,
            });
            return req;
        });
    }

    GetBankAccount<T extends BankAccount[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.bankAccount,
            });
            return req;
        });
    }
}

const receiptService = new ReceiptService();
export default receiptService;

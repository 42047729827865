import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterPurchasingInventory from "modules/purchasing/inventory/index/filter";
import PurchasingInventoryTable from "modules/purchasing/inventory/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingInventoryService, { PurchasingInventory as PIType } from "services/api-endpoints/purchasing/inventory";

const filter = ["page", "start_date", "end_date", "docType", "status", "vendor_id", "created_by"];

const PurchasingInventory = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([purchasingInventoryService.filter, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await purchasingInventoryService.Filter({ ...filterValue, ...searchValue })).data.data;
    });

    const deleteModelType = useMutation(async (data: PIType) => (await purchasingInventoryService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<PIType>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterPurchasingInventory filter={filter}>
                {(dt) => <Toolbar placeholder="Search PO No/Request By ..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterPurchasingInventory>
            <PurchasingInventoryTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default PurchasingInventory;

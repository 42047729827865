/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Input, InputRef, Space, Table, TableColumnType } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { ColumnsType } from "antd/lib/table";
import { FilterDropdownProps } from "antd/lib/table/interface";
import ToolbarAction from "components/toolbar/action";
import downloadStock from "modules/master-data/stock/download";
import ModalDetailQuantityStock from "modules/master-data/stock/modal-detail";
import Print from "modules/master-data/stock/print";
import React from "react";
import Highlighter from "react-highlight-words";
import { AiFillPrinter, AiOutlineDownload } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import quantityService, { ListStockResponse } from "services/api-endpoints/dashboard/master-data/quantity";
import { VIEW_PATH } from "utils/constant";
import { v4 as uuid } from "uuid";
import { sort } from "fast-sort";
import { FaFilter } from "react-icons/fa";

const StockLocatorView = () => {
    const pageSize = 50;
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const warehouse = searchParams.get("warehouse_id");
    const includeLocator = searchParams.get("locators");
    const [page, setPage] = React.useState(1);

    const [searchText, setSearchText] = React.useState("");
    const [searchedColumn, setSearchedColumn] = React.useState("");
    const searchInput = React.useRef<InputRef>(null);
    const letterRef = React.useRef<HTMLDivElement | null>(null);
    const [list, setList] = React.useState<ListStockResponse[]>([]);
    const [sortingLocator, setSortingLocator] = React.useState<{ name: "asc" | "desc"; qty: "asc" | "desc" }>({
        name: "asc",
        qty: "asc",
    });

    const [recordSearch, setRecordSearch] = React.useState<{ [key: string]: any } | undefined>(undefined);

    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps["confirm"] | undefined, dataIndex: any) => {
        if (confirm) confirm({ closeDropdown: false });
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setRecordSearch((prev) => ({
            ...prev,
            [dataIndex]: selectedKeys[0],
        }));
    };

    const getColumnSearchProps = (dataIndex: keyof ListStockResponse): TableColumnType<ListStockResponse> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => {
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        autoFocus
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0] as string}
                        onChange={(e) => {
                            const sKeys = e.target.value ? [e.target.value] : [];
                            setSelectedKeys(sKeys);
                            handleSearch(sKeys as string[], confirm, dataIndex);
                            setRecordSearch((prev) => ({
                                ...prev,
                                [dataIndex]: sKeys,
                            }));
                        }}
                        allowClear
                    />
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => {
            if ((recordSearch || {})[dataIndex] && recordSearch![dataIndex]?.length) {
                return (
                    <Space>
                        <p className="text-white m-0 opacity-80 font-light">{recordSearch![dataIndex][0]}</p>
                        <IoSearch style={{ color: "#ffffff" }} />
                    </Space>
                );
            }
            return <IoSearch style={{ color: "#ffffff" }} />;
        },
        onFilter: (value, record) =>
            (record[dataIndex] || "")
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const listQuery = useQuery(
        [quantityService.listStockLocator, warehouse, includeLocator],
        async () => {
            return (
                await quantityService.ListStockLocator({
                    warehouse_id: warehouse,
                    in_area_id: includeLocator,
                })
            ).data.data.map((el) => ({ ...el, id: uuid() }));
        },
        {
            onSuccess(data) {
                setList(data);
            },
        }
    );

    const onClickSortingLocatorName = () => {
        setList((prev) => {
            if (sortingLocator.name === "asc") {
                return sort(prev).desc([(u) => u.list_stock![0].area_code]);
            }
            return sort(prev).asc([(u) => u.list_stock![0].area_code]);
        });
        setSortingLocator((prev) => ({ ...prev, name: prev.name === "asc" ? "desc" : "asc" }));
    };

    const onClickSortingLocatorQty = () => {
        setList((prev) => {
            if (sortingLocator.qty === "asc") {
                return sort(prev).desc([(u) => u.list_stock![0].qty]);
            }
            return sort(prev).asc([(u) => u.list_stock![0].qty]);
        });
        setSortingLocator((prev) => ({ ...prev, qty: prev.qty === "asc" ? "desc" : "asc" }));
    };

    const columns: ColumnsType<ListStockResponse> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            fixed: "left",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * pageSize + (i + 1)}</p>,
        },
        {
            title: "Product Code",
            fixed: "left",
            dataIndex: "code1",
            width: "200px",
            ...getColumnSearchProps("code1"),
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            width: "200px",
        },
        {
            title: "Product Name",
            dataIndex: "name",
            width: "300px",
            ...getColumnSearchProps("name"),
        },
        {
            title: "UOM",
            dataIndex: "uom_name",
            width: "75px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: () => {
                return (
                    <div className="flex items-center gap-10">
                        <p className="text-white font-semibold m-0">Locator</p>
                        <Space>
                            <button
                                onClick={onClickSortingLocatorName}
                                type="button"
                                className="bg-black bg-opacity-0 hover:bg-opacity-10 rounded p-1 text-xs flex gap-2 cursor-pointer border-none"
                            >
                                Name <FaFilter />
                            </button>
                            <button
                                onClick={onClickSortingLocatorQty}
                                type="button"
                                className="bg-black bg-opacity-0 hover:bg-opacity-10 rounded p-1 text-xs flex gap-2 cursor-pointer border-none"
                            >
                                Quantity <FaFilter />
                            </button>
                        </Space>
                    </div>
                );
            },
            render: (_, record) => {
                return (
                    <div className="flex items-center gap-3">
                        {record.list_stock?.map((stock) => (
                            <ModalDetailQuantityStock
                                title={`Detail ${stock.area_code}`}
                                key={stock.area_id}
                                areaId={stock.area_id}
                                productId={record.product_id}
                                warehouseId={warehouse}
                            >
                                {(ctrl) => (
                                    <button
                                        onClick={ctrl.openModal}
                                        title="click to detail"
                                        type="button"
                                        className="flex whitespace-nowrap border-none cursor-pointer items-center gap-2 bg-blue-400 px-4 py-2 text-xs rounded text-white font-medium"
                                    >
                                        <span>{stock.area_code}</span>:<span>{stock.qty}</span>
                                    </button>
                                )}
                            </ModalDetailQuantityStock>
                        ))}
                    </div>
                );
            },
        },
    ];

    const resetViewClick = () => {
        navigate(location.pathname.replace(VIEW_PATH, ""), { replace: true });
    };

    const downloadClick = () => {
        if (listQuery.isLoading) return;
        downloadStock({ list: listQuery.data!, title: "Warehouse Stock Locator" });
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const pg = pagination.current || 1;
        setPage(pg);
    };

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Warehouse Stock Locator`,
        pageStyle: "@page {  }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="flex flex-col gap-6 w-full">
            <ToolbarAction
                title="View stock locator"
                rightAddition={() => (
                    <Space>
                        <Button onClick={resetViewClick} type="text">
                            Reset View
                        </Button>
                        <Button onClick={downloadClick} type="primary">
                            <AiOutlineDownload className="m-0 mr-2" />
                            Download
                        </Button>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </Space>
                )}
            />
            <Table
                scroll={{ x: 1500 }}
                dataSource={list}
                loading={listQuery.isLoading}
                rowKey={(p) => p.id!}
                size="small"
                columns={columns}
                className="w-full"
                pagination={{
                    current: page,
                    pageSize,
                    total: list?.length,
                }}
                onChange={handleTableChange}
            />
            <Print ref={letterRef} data={list || []} />
        </div>
    );
};

export default StockLocatorView;

import { Icon } from "@mui/material";
import { Alert, Button, Card, Skeleton, Space, StepProps, Steps } from "antd";
import State from "components/common/state";
import ImagesModal from "modules/master-data/order/view/images-modal";
import moment from "moment";
import React from "react";
import { BiSolidCheckCircle } from "react-icons/bi";
import { IoIosCheckmarkCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { IoImagesSharp } from "react-icons/io5";
import { RiDraftLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingInventoryService from "services/api-endpoints/purchasing/inventory";
import { FORMAT_DATE_TIME, STATUS_PURCHASING_APPROVED } from "utils/constant";

interface Props {
    isRefresh?: boolean;
    paramId?: any;
}

const ApprovalPurchasing = ({ isRefresh, paramId }: Props) => {
    const id = paramId;

    const getHistoryApproval = useQuery(
        [purchasingInventoryService.historyApproval, id, isRefresh],
        async () => (await purchasingInventoryService.HistoryApproval(id)).data.data,
        {
            enabled: !!id,
        }
    );

    const approvalHistories = React.useCallback(
        () =>
            getHistoryApproval.data?.Approval?.map(
                (history) =>
                    ({
                        status: "finish",
                        icon: (
                            <>
                                {history.status_approval === STATUS_PURCHASING_APPROVED ? (
                                    <IoIosCheckmarkCircle />
                                ) : (
                                    <IoMdCloseCircleOutline className="text-red-400" />
                                )}
                            </>
                        ),
                        title: (
                            <span
                                className={
                                    history.status_approval === STATUS_PURCHASING_APPROVED ? "font-semibold m-0" : "text-red-400 font-semibold m-0"
                                }
                            >
                                {history.name || "-"}
                            </span>
                        ),
                        description: (
                            <Space>
                                <RiDraftLine className="text-blue-300 text-xl" />
                                <div className="ml-2">
                                    <p className="m-0 text-gray-600 capitalize font-semibold">
                                        {history.created_by ? `${history.created_by} - ` : ""}
                                        {moment(history.created_at).format(FORMAT_DATE_TIME)}
                                    </p>
                                    {history.note && <p className="m-0">{history.note}</p>}
                                </div>
                            </Space>
                        ),
                    } as StepProps)
            ),
        [getHistoryApproval.data]
    );

    return (
        <State data={getHistoryApproval.data} isLoading={getHistoryApproval.isLoading} isError={getHistoryApproval.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        {getHistoryApproval.data?.Approval?.length !== 0 ? (
                            <Steps direction="vertical" size="default" items={approvalHistories() || []} />
                        ) : (
                            <Alert message="Waiting Approval Purchasing" type="warning" showIcon />
                        )}
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(getHistoryApproval.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default ApprovalPurchasing;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface WorkingOrder {
    id?: number;
    wo_no?: string;
    cust_id?: number;
    cust_name?: string;
    phone_number?: string;
    vehicle_no?: string;
    brand_name?: string;
    model_name?: string;
    model_type_name?: string;
    year?: number;
    car_color?: string;
    created_at?: string;
    status?: string;
}

export interface ListProductWo {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    product_price?: number;
    uom_id?: number;
    uom_name?: string;
    qty_use?: number;
}

export interface TeknisiWo {
    teknisi_id?: number;
    teknisi_name?: string;
    account_id?: number;
    from?: string;
}

export interface DetailWorkingOrder {
    id?: number;
    wo_no?: string;
    list_inv?: { ref_id: number; ref_no: string }[];
    teknisi?: TeknisiWo[];
    cust_id?: number;
    cust_name?: string;
    address?: string;
    phone_number?: string;
    status?: number;
    list_product?: ListProductWo[];
    waste_product?: ListProductWo[];
    after?: string[];
    before?: string[];
}

export interface ListHistoryWo {
    order_history_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface HistoryWO {
    icon?: string;
    order_status?: number;
    name?: string;
    list?: ListHistoryWo[];
}

class WorkingOrderService extends BaseService {
    getAll = "/master-order/list-wo";

    detail = "/master-order/detail-product-wo?";

    history = "/master-order/get-history-wo";

    constructor() {
        super();
    }

    History<T extends HistoryWO[]>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.history}/${id}`,
            });
            return req;
        });
    }

    Detail<T extends DetailWorkingOrder>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends WorkingOrder>(params: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }
}

const workingOrderService = new WorkingOrderService();
export default workingOrderService;

/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export type ProductCodeAssembling = {
    id: number;
    product_code: string;
    product_name: string;
};

export type CreateBOM = {
    id?: any;
    bom: (
        | {
            id: number;
            qty: number;
        }
        | {
            child: {
                id: number;
                qty: number;
            }
        }
    )[];
};

class AssemblingService extends BaseService {
    create = "/master-product/create-bom";

    getProductCode = "master-product/get-product-code";

    constructor() {
        super();
    }

    GetProductCode<T extends ProductCodeAssembling[]>(params: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductCode,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateBOM, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const assemblingService = new AssemblingService();
export default assemblingService;

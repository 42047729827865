/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import subCategoryService, { AllSubCategory } from "services/api-endpoints/dashboard/master-data/sub-category";
import { FCreateDataProduct } from "../models";

type SelectOptionCustom = SelectOption & AllSubCategory;

export interface Props extends ControlledInputSelectProps<FCreateDataProduct> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withSubCategory<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const categoryTypeID = props?.control?._getWatch("category_type");

        const query = useQuery([subCategoryService.getAllSubCategory], async () => {
            const req = await subCategoryService.GetAllSubCategory({ category_id: 0 });
            return req.data.data?.map((el) => ({ ...el, label: el.subcategory_name || "Any", value: el.id || "" } as SelectOptionCustom));
        });

        const options = query.data?.filter((el) => el.category_id === categoryTypeID) || [];

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(options.find((el) => el.id === id));
            }
        };

        return (
            <Component
                {...props}
                disabled={!categoryTypeID}
                onSelect={onSelect}
                optionFilterProp="children"
                loading={query.isLoading}
                options={options}
            />
        );
    };
}

const SubCategorySelect = withSubCategory(ControlledSelectInput);

export default SubCategorySelect;

/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import receiptService, { ReceiptList } from "services/api-endpoints/dashboard/receipt";
import useConfirmDelete from "hooks/useConfirmDelete";
import { message } from "antd";
import transferBankService from "services/api-endpoints/dashboard/transfer-bank";
import TransferBankTable from "modules/master-data/transfer-bank/table-index";
import FilterTransferBank from "modules/master-data/transfer-bank/filter";

const filter = ["doc_no", "start_date", "end_date"];

const TransferBank = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([transferBankService.getListTransferBank, page, filterValue], async () => {
        return (await transferBankService.GetListTransferBank(filterValue as any)).data.data;
    });

    const deleteMutation = useMutation(async (data: ReceiptList) => (await receiptService.DeleteReceipt({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<ReceiptList>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterTransferBank filter={filter}>
                {(dt) => <Toolbar placeholder="Search Transfer Bank" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterTransferBank>
            <TransferBankTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default TransferBank;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ModalConfirm from "components/modal/confirm_modal";
import ModalDoc from "components/modal/doc_modal";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ModalNewPo from "modules/master-data/material-movement-doc/add/new-po-modal";
import TableTransferProductByOrder from "modules/master-data/material-movement-doc/add/table-transfer-product-byorder";
import { mapDuplicateProducts } from "modules/master-data/material-movement-doc/utils";
import LocatorSelect, { SelectOptionCustom } from "modules/master-data/material-movement/lib/locator-select";
import WarehouseSelect from "modules/master-data/material-movement/lib/warehouse-select";
import { SearchProduct } from "modules/master-data/material-movement/models";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { v4 as uuid } from "uuid";
import { BsSearch } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import materialMovementService, {
    CreateMM,
    GetProductByOrderParams,
    GetProductParams,
    ProductTransferWithEdit,
} from "services/api-endpoints/dashboard/master-data/material-movement";
import {
    AUTOGENERATED,
    MATERIAL_MOVEMENT_OPTIONS,
    TYPE_MM_INTERNAL_PO,
    TYPE_MM_RETURN_BP,
    TYPE_MM_RETURN_EXTERNAL,
    TYPE_MM_RETURN_INTERNAL,
    TYPE_MM_RETURN_ORDER,
    TYPE_MM_WORKING_ORDER,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<SearchProduct>> = yup.object().shape({
    warehouse_from: yup.string().required("Warehouse from required"),
    warehouse_to: yup.string().required("Warehouse to required"),
    locator_from: yup.string().required("Locator from required"),
    option: yup.string().required("Option required"),
});

const MaterialMovementDocAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [order, setOrder] = useState<number[] | null>(null);
    const {
        control,
        watch,
        formState: { errors },
        setValue,
    } = useForm<SearchProduct>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    // property table-transfer-product-byorder.tsx
    const [products, setProducts] = useState<ProductTransferWithEdit[]>([]);
    const [targetKeyTransfer, setTargetKeyTransfer] = useState<string[]>([]);

    const warehouseFrom = watch("warehouse_from");
    const warehouseTo = watch("warehouse_to");
    const locatorFrom = watch("locator_from");
    const option = watch("option");

    useEffect(() => {
        if (option === TYPE_MM_RETURN_INTERNAL || option === TYPE_MM_RETURN_EXTERNAL || option === TYPE_MM_WORKING_ORDER) {
            setProducts((prev) =>
                prev.map((prd) => {
                    if (prd?.qty_locator)
                        return {
                            ...prd,
                            disabled: false,
                        };
                    return {
                        ...prd,
                        disabled: true,
                    };
                })
            );
        }
    }, [products]);

    const byDoc = useMutation([materialMovementService.byDoc], async (data: GetProductParams) => {
        const req = await materialMovementService.ByDoc(data);
        return req.data.data?.map((el) => ({ label: el?.doc_no || "", value: el.order_id || "" } as SelectOption));
    });

    const productOrderMutation = useMutation(
        [materialMovementService.productByOrderId],
        async (data: GetProductByOrderParams) => {
            return (await materialMovementService.ProductByOrderId(data)).data.data;
        },
        {
            onSuccess(data) {
                const initialProducts =
                    data?.map((el) => ({ ...el, random_id: uuid(), locator_to: 1, qty_to: el?.qty_to || el?.qty_from || 1 })) || [];
                // const mergeDuplicateProducts = mapDuplicateProducts(initialProducts);
                setProducts(initialProducts);
            },
        }
    );

    const whToMutation = useQuery([materialMovementService.getWHTo], async () => {
        const req = await materialMovementService.GetWHTo();
        return req.data.data?.map((el) => ({ label: el?.warehouse_name || "", value: el.warehouse_id_to || "" } as SelectOption));
    });

    const whFromMutation = useQuery([materialMovementService.getWHFrom], async () => {
        const req = await materialMovementService.GetWHFrom();
        return req.data.data?.map((el) => ({ label: el?.warehouse_name || "", value: el.warehouse_id_from || "" } as SelectOption));
    });

    const locatorFromMutate = useMutation([materialMovementService.getLocatorFrom], async (wh: any) => {
        const req = await materialMovementService.GetLocatorFrom({ warehouse_id_from: wh });
        return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_from || "" } as SelectOptionCustom));
    });

    const locatorToQuery = useQuery(
        [materialMovementService.getLocatorTo, warehouseTo],
        async () => {
            const req = await materialMovementService.GetLocatorTo({ warehouse_id_to: warehouseTo });
            return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_to || "" } as SelectOptionCustom));
        },
        {
            enabled: !!warehouseTo,
        }
    );

    const createMutation = useMutation(
        [materialMovementService.create],
        async (data: CreateMM) => {
            return (await materialMovementService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Material Movement Created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [materialMovementService.createDraft],
        async (data: CreateMM) => {
            return (await materialMovementService.CreateDraft(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Material Movement Draft Created");
                navigate(-1);
            },
        }
    );

    const onChangeWarehouseFrom = (key: string) => {
        if (option === TYPE_MM_INTERNAL_PO) {
            form.setFieldValue("locator_from", 1);
            setValue("locator_from", 1);
        } else {
            form.setFieldValue("locator_from", "");
            setValue("locator_from", "");
        }
        locatorFromMutate.mutate(key);
    };

    const onChangeOption = (key: number) => {
        setProducts([]);
        if (key === TYPE_MM_RETURN_ORDER) {
            form.setFieldValue("warehouse_from", 7);
            setValue("warehouse_from", 7);
            form.setFieldValue("warehouse_to", 7);
            setValue("warehouse_to", 7);
            form.setFieldValue("locator_from", 1);
            setValue("locator_from", 1);
        } else if (key === TYPE_MM_INTERNAL_PO) {
            form.setFieldValue("warehouse_from", "");
            setValue("warehouse_from", "");
            form.setFieldValue("warehouse_to", "");
            setValue("warehouse_to", "");
            form.setFieldValue("locator_from", 1);
            setValue("locator_from", 1);
        } else {
            form.setFieldValue("warehouse_from", "");
            setValue("warehouse_from", "");
            form.setFieldValue("warehouse_to", "");
            setValue("warehouse_to", "");
            form.setFieldValue("locator_from", "");
            setValue("locator_from", "");
        }
    };

    const prepareSave = (type: number, note: string) => {
        const filteredProduct = products?.filter((p) => targetKeyTransfer.includes(p.random_id as any));

        if (!filteredProduct.length) {
            throw new Error("Select products first");
        }
        if (Object.keys(errors).length) throw new Error("Some Error occurred");

        const parseData: CreateMM = {
            type,
            type_stock_opname: "MATERIAL MOVEMENT",
            warehouse_id_from: warehouseFrom,
            warehouse_id_to: warehouseTo,
            area_from_id: locatorFrom,
            note,
            option,
            order_id: order,
            product: filteredProduct?.map((product) => ({
                order_id: product?.order_id,
                product_id: product?.product_id,
                locator_from: [{ area_id_from: product?.area_id, qty_from: product?.qty_from }],
                locator_to: [
                    {
                        area_id_to:
                            locatorToQuery.data?.find((loc) => loc.label === product?.locator_to || loc.value === product?.locator_to)?.value || "",
                        qty_to: product?.qty_to,
                    },
                ],
            })),
        };

        const emptyLoc = parseData.product?.reverse()?.filter((product) => {
            const filter = product.locator_to?.filter((loc) => {
                if (!loc.area_id_to) {
                    message.error(`Locator to can't be empty on product ${products?.find((p) => p.product_id === product.product_id)?.product_name}`);
                    return loc;
                }
                return null;
            });
            if (filter?.length) return filter;
            return null;
        });

        if (emptyLoc?.length) throw new Error(`Locator to can't be empty on product`);

        const emptyLocFrom = parseData.product?.reverse()?.filter((product) => {
            const filterFrom = product.locator_from?.filter((loc) => {
                if (!loc.area_id_from && (option === TYPE_MM_RETURN_INTERNAL || option === TYPE_MM_RETURN_EXTERNAL)) {
                    message.error(
                        `Locator From can't be empty on product ${products?.find((p) => p.product_id === product.product_id)?.product_name}`
                    );
                    return loc;
                }
                return null;
            });
            if (filterFrom?.length) return filterFrom;
            return null;
        });

        if (emptyLocFrom?.length) throw new Error(`Locator from can't be empty on product`);

        return parseData;
    };

    const onSaveDraftHandler = (note: string) => {
        try {
            const createData = prepareSave(1, note);
            createDraftMutation.mutate(createData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const isSameProduct = () => {
        if (
            option === TYPE_MM_RETURN_ORDER ||
            option === TYPE_MM_RETURN_INTERNAL ||
            option === TYPE_MM_RETURN_EXTERNAL ||
            option === TYPE_MM_WORKING_ORDER
        ) {
            return true;
        }
        const filteredProduct = products?.filter((p) => targetKeyTransfer.includes(p.random_id as any));
        if (products.length !== filteredProduct.length) {
            return false;
        }
        const filteredQty = products.filter((p) => p.qty_from !== p.qty_to);
        if (filteredQty.length !== 0) {
            return false;
        }
        return true;
    };

    const onSaveHandler = async (type: number, note: string) => {
        try {
            const createData = prepareSave(type, note);
            createMutation.mutate(createData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onByDocHandler = () => {
        const parseData: GetProductParams = {
            area_id: locatorFrom || 0,
            option: option || 1,
            warehouse_id_to: warehouseTo || 0,
        };
        byDoc.mutate(parseData);
    };

    const onSearchHandler = (orderId: number[] | null) => {
        setOrder(orderId);
        const parseData: GetProductByOrderParams = {
            order_id: String(orderId).replace("[", "").replace("]", ""),
            option,
            warehouse_id_to: warehouseTo,
        };
        productOrderMutation.mutate(parseData);
    };

    const isSearch = () => {
        return !(option !== undefined && warehouseFrom !== "" && warehouseTo !== "" && locatorFrom !== "");
    };

    const titles = [
        whFromMutation?.data?.find((wh) => wh.value === warehouseFrom)?.label || "",
        whToMutation.data?.find((wh) => wh.value === warehouseTo)?.label || "",
    ];

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new material movement doc"
                rightAddition={() => (
                    <Space>
                        {/* <ModalNote title="Note Material Movement" onSubmit={onSaveDraftHandler}>
                            {(handler) => (
                                <Button
                                    loading={createDraftMutation.isLoading}
                                    onClick={handler.openModal}
                                    type="default"
                                    className="!flex !items-center"
                                >
                                    <RiDraftLine className="m-0 mr-2" />
                                    Draft
                                </Button>
                            )}
                        </ModalNote> */}
                        <ModalNewPo title="Note dan Konfirmasi" onSubmit={onSaveHandler} isSameProduct={isSameProduct()}>
                            {(modal) => (
                                <Button loading={createMutation.isLoading} onClick={modal.openModal} type="primary" className="!flex !items-center">
                                    <BiSave className="m-0 mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNewPo>
                    </Space>
                )}
            />
            <ModalDoc options={byDoc.data} title="Material Movement By Doc" onSubmit={onSearchHandler}>
                {(handler) => (
                    <Card>
                        <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onByDocHandler}>
                            <div className="grid grid-cols-3 gap-4">
                                <p className="capitalize m-0">
                                    Sales order no <br />
                                    <span className="underline italic m-0">{AUTOGENERATED}</span>
                                </p>
                                <ModalConfirm
                                    title="Konfirmasi Perubahan Data"
                                    subTitle="Anda Yakin Merubah Warehouse From, Data akan hilang.?"
                                    value={0}
                                    onSubmit={() => {
                                        setOrder([]);
                                        setProducts([]);
                                        setValue("warehouse_from", "");
                                    }}
                                >
                                    {(hdl) => (
                                        <WarehouseSelect
                                            allowClear
                                            loading={whFromMutation.isLoading}
                                            options={whFromMutation.data || []}
                                            onSelect={onChangeWarehouseFrom}
                                            control={control}
                                            name="warehouse_from"
                                            placeholder="WH From"
                                            onClick={products.length === 0 ? undefined : hdl.openModal}
                                            disabled={option === TYPE_MM_RETURN_ORDER}
                                        />
                                    )}
                                </ModalConfirm>
                                <ModalConfirm
                                    title="Konfirmasi Perubahan Data"
                                    subTitle="Anda Yakin Merubah Warehouse To, Data akan hilang.?"
                                    value={0}
                                    onSubmit={() => {
                                        setOrder([]);
                                        setProducts([]);
                                        setValue("warehouse_to", "");
                                    }}
                                >
                                    {(hdl) => (
                                        <WarehouseSelect
                                            allowClear
                                            loading={whToMutation.isLoading}
                                            options={whToMutation.data || []}
                                            control={control}
                                            name="warehouse_to"
                                            placeholder="WH To"
                                            onClick={products.length === 0 ? () => undefined : hdl.openModal}
                                            disabled={option === TYPE_MM_RETURN_ORDER}
                                        />
                                    )}
                                </ModalConfirm>
                                <ControlledSelectInput
                                    allowClear
                                    onSelect={onChangeOption}
                                    control={control}
                                    name="option"
                                    options={MATERIAL_MOVEMENT_OPTIONS}
                                    placeholder="Option"
                                />
                                <LocatorSelect
                                    allowClear
                                    options={locatorFromMutate?.data || []}
                                    loading={locatorFromMutate?.isLoading}
                                    showSearch
                                    control={control}
                                    name="locator_from"
                                    placeholder="Locator From"
                                    disabled={option === TYPE_MM_RETURN_ORDER || option === TYPE_MM_INTERNAL_PO}
                                />
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className="!flex !items-center"
                                    disabled={isSearch()}
                                    onClick={handler.openModal}
                                >
                                    <BsSearch className="m-0 mr-2" />
                                    Search
                                </Button>
                            </div>
                        </Form>
                    </Card>
                )}
            </ModalDoc>
            <TableTransferProductByOrder
                titles={titles}
                products={products}
                setProducts={setProducts}
                locatorFetcher={locatorToQuery}
                setTargetKeys={setTargetKeyTransfer}
                targetKeys={targetKeyTransfer}
                option={option}
                loading={productOrderMutation.isLoading || locatorToQuery.isLoading}
            />
        </div>
    );
};

export default MaterialMovementDocAdd;

import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterModel from "modules/master-data/model/index/filter";
import { TDataModel } from "modules/master-data/model/models";
import ModelTable from "modules/master-data/model/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import modelService from "services/api-endpoints/dashboard/master-data/model";

const filter = ["brand_name", "model_name", "model_code", "is_active"];

const Model = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getAllModel = useQuery([modelService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await modelService.Search({ page, param_search: searchValue.query })).data.data; // search
        if (isFilter) return (await modelService.Filter(filterValue as any)).data.data; // filter
        return (await modelService.GetAll({ page, brand_id: 0 })).data.data; // all
    });

    const deleteMutation = useMutation(async (data: TDataModel) => (await modelService.Delete({ id: data?.id })).data.data, {
        onSuccess: () => getAllModel.refetch(),
    });

    const [showConfirm] = useConfirmDelete<TDataModel>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterModel filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Brand Name / Model Name / Model Code"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterModel>
            <ModelTable fetcher={getAllModel} onClickDelete={showConfirm} />
        </div>
    );
};

export default Model;

import { Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import { STATUS_ACTIVE } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterModelType = ({ filter = [], ...props }: Props) => {
    const modelTypeNameQuery = useQuery([modelTypeService.suggestModelTypeName], async () => {
        const req = await modelTypeService.SuggestModelTypeName({ model_type_name: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const modelTypeCodeQuery = useQuery([modelTypeService.suggestModelTypeCode], async () => {
        const req = await modelTypeService.SuggestModelTypeCode({ model_type_code: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Model Type"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.model_type_name}
                            loading={modelTypeNameQuery.isLoading}
                            placeholder="Model Name"
                            options={modelTypeNameQuery.data || []}
                            onChange={(value) => dt.setOneValue("model_type_name", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.model_type_code}
                            loading={modelTypeCodeQuery.isLoading}
                            placeholder="Model Code"
                            options={modelTypeCodeQuery.data || []}
                            onChange={(value) => dt.setOneValue("model_type_code", value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_active !== null && dt.values.is_active !== undefined ? Number(dt.values.is_active) : dt.values.is_active
                            }
                            placeholder="Status"
                            options={STATUS_ACTIVE}
                            onChange={(value) => dt.setOneValue("is_active", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterModelType;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface ProductPL {
    doc_id?: number | null;
    product_id?: number;
    qty?: number;
    price?: number;
    area_id?: number;
}

export interface CreatePLData {
    bp_id?: number;
    doc_type?: number;
    payment_term_id: number | null;
    child_document?: number[];
    detail_product?: ProductPL[];
}

export interface ListData {
    doc_id?: number | null;
    doc_no?: string;
    total_sku?: number;
    total_item?: number;
    date?: number;
    payment_term_code?: string;
    payment_term_id?: number;
}

export interface DetailProduct {
    doc_id?: number | null;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    area_id?: number;
    area_name?: string;
    qty_locator?: number;
    qty_req?: number;
    qty?: number;
    qty_approve?: number;
    so_no?: string;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

export interface DetailList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    qty?: number;
    po_id?: number;
    qty_req?: number;
    area_id?: number;
    area_name?: string;
    so_no?: string;
}

export interface DetailPL {
    id?: number;
    pl_no?: string;
    request_from?: string;
    request_from_id?: number;
    request_to?: string;
    order_status?: number;
    child_document?: number[];
    doc_type?: number;
    payment_term_code?: string;
    payment_term_id: number | null;
    list_detail?: DetailList[];
}

export interface EditPLData extends CreatePLData {
    id: number;
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface List {
    id?: number;
    no?: string;
}

export interface ListHistory {
    id?: number;
    notes?: string;
    created_by?: string;
    created_at?: number;
    list_so?: List[];
    list_pl?: List[];
    list_do?: List[];
    list_history?: List[];
}

class BpPickingListService extends BaseService {
    getAll = "/bp-pl/get-all-picking-list";

    listData = "/bp-pl/list-data";

    detailProduct = "/bp-pl/get-detail-product";

    createPL = "/bp-pl/create-bp-picking-list";

    draftPL = "/bp-pl/draft-bp-picking-list";

    locator = "/bp-pl/get-locator";

    detailPL = "/bp-pl/get-detail-picking-list";

    deletePL = "/bp-pl/delete-picking-list";

    history = "/bp-pl/history-picking-list";

    constructor() {
        super();
    }

    History<T extends ListHistory>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.history,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DeletePL<T extends any>(data: DeleteData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.deletePL,
                data,
            });
            return req;
        });
    }

    DetailPL<T extends DetailPL>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailPL}/${id}`,
            });
            return req;
        });
    }

    ListData<T extends ListData[]>(param: { bp_id: any; option: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listData,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailProduct<T extends DetailProduct[]>(params: { doc_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProduct,
                config: { params },
            });
            return req;
        });
    }

    Locator<T extends LocatorData[]>(param: { product_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.locator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    CreatePL<T extends any>(data: CreatePLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPL,
                data,
            });
            return req;
        });
    }

    CreateEditPL<T extends any>(data: EditPLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPL,
                data,
            });
            return req;
        });
    }

    DraftPL<T extends any>(data: CreatePLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPL,
                data,
            });
            return req;
        });
    }

    DraftEditPL<T extends any>(data: EditPLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPL,
                data,
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslPickingList>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const bpPickingListService = new BpPickingListService();
export default bpPickingListService;

import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/sub-category/lib/is-active-select";
import IsPopularSelect from "modules/master-data/sub-category/lib/is-popular-select";
import InputFile from "components/form/inputs/input-file";
import { AWS_PATH, IMAGE_TYPES } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import useProgressUpload from "hooks/useProgressUpload";
import { CreateDataSubCategory, EditDataSubCategory } from "modules/master-data/sub-category/models";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";
import State from "components/common/state";
import CategorySelect from "modules/master-data/sub-category/lib/category-select";
import CategorySuggestSelect from "modules/master-data/category/lib/categorysuggestselect";
import { SubCategoryData } from "models";
import { InfoDataCategory } from "modules/master-data/category/models";
import categoryService from "services/api-endpoints/dashboard/master-data/category";
import CategoryTypeSelect from "modules/master-data/sub-category/lib/category-type-select";

const schema: yup.SchemaOf<Partial<CreateDataSubCategory>> = yup.object().shape({
    category_id: yup.number().required("Category required"),
    category_name: yup.string(),
    category_code: yup.string(),
    category_status: yup.number(),
    subcategory_name: yup.string().required("Sub Category name required"),
    subcategory_code: yup.string().required("Sub Category code required"),
    subcategory_image: yup.string().required("Sub Category Image required"),
    is_popular: yup.number().required("Is Popular required"),
    is_active: yup.number().required("Is Active required"),
});

const SubCategoryEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [form] = Form.useForm();
    const [info, setInfo] = useState([] as InfoDataCategory[]);
    const [category, setCategory] = useState({} as InfoDataCategory);
    const [nameCategory, setNameCategory] = useState("");
    const [subCategory, setSubCategory] = useState([] as SubCategoryData[]);
    const [sub, setSub] = useState({} as SubCategoryData);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useForm<CreateDataSubCategory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchCategoryName = watch("category_name");

    const [controlFile] = useControlFile<CreateDataSubCategory>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    // useEffect(() => {
    //     form.setFieldValue("category_id", null);
    //     setValue("category_id", null);
    // }, [watchCategoryName]);

    const [progress, onUploadProgress] = useProgressUpload();

    const categorySelected = (data: InfoDataCategory[]) => {
        const name = getValues("category_name");
        const models = data.filter((el) => name === el.category_code);
        setValue("category_code", models[0].category_code);
        setValue("category_id", null);
    };

    const categoryTypeSelected = (data: SubCategoryData[]) => {
        const catId = getValues("category_id");
        const models = data.filter((el) => catId === el.id);
        setValue("category_status", Number(models[0].category_status));
    };

    const detailQuery = useQuery(
        [subCategoryService.detail, id],
        async () => {
            return (await subCategoryService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                    is_active: data.status,
                });
                setValue("category_id", data.category_id);
                setValue("category_name", data.category_name);
                setValue("category_code", data.category_code);
                setValue("subcategory_name", data.subcategory_name);
                setValue("subcategory_code", data.subcategory_code);
                setValue("subcategory_image", data.subcategory_image);
                setValue("is_popular", Number(data.is_popular));
                setValue("is_active", Number(data.status));
            },
        }
    );

    const editMutation = useMutation(
        [subCategoryService.edit],
        async (data: EditDataSubCategory) => {
            return (await subCategoryService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("SubCategory edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
            subcategory_image: data.subcategory_image?.includes(AWS_PATH) ? "default" : data.subcategory_image || "",
        } as EditDataSubCategory;
        editMutation.mutate(parseData);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit sub category"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="subcategory_image" className="col-span-2">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="Subcategory image" size="large" />}
                                        </InputFile>
                                    </div>
                                    <Form form={form} disabled={editMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">sub category information</h1>
                                        <ControlledInputText
                                            control={control}
                                            label="Sub Category Name"
                                            labelCol={{ span: 24 }}
                                            name="subcategory_name"
                                            placeholder="Sub Category Name"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <ControlledInputText
                                            control={control}
                                            label="Sub Category Code"
                                            labelCol={{ span: 24 }}
                                            name="subcategory_code"
                                            placeholder="Sub Category Code"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <IsPopularSelect
                                            control={control}
                                            label="Is Popular"
                                            labelCol={{ span: 24 }}
                                            name="is_popular"
                                            placeholder="Is Popular"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <IsActiveSelect
                                            control={control}
                                            label="Is Active"
                                            labelCol={{ span: 24 }}
                                            name="is_active"
                                            placeholder="Is Active"
                                            style={{ transform: "translateY(-6px)" }}
                                        />

                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">category information</h1>
                                        <CategorySelect
                                            onSelectedData={categorySelected}
                                            classNameForm="col-span-2"
                                            label="Category Name"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="category_name"
                                            placeholder="Category Name"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <ControlledInputText
                                            control={control}
                                            label="Category Code"
                                            labelCol={{ span: 24 }}
                                            name="category_code"
                                            placeholder="Category Code"
                                            disabled
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <CategoryTypeSelect
                                            onSelectedData={categoryTypeSelected}
                                            classNameForm="col-span-2"
                                            label="Category Name"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="category_id"
                                            placeholder="Category Name"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <IsActiveSelect
                                            control={control}
                                            label="Category Is Active"
                                            labelCol={{ span: 24 }}
                                            name="category_status"
                                            placeholder="Category Is Active"
                                            disabled
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                    </Form>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default SubCategoryEdit;

import { Alert, Card, Skeleton } from "antd";
import State from "components/common/state";
import React from "react";
import ToolbarAction from "components/toolbar/action";
import LocatorDetailTable from "modules/master-data/doc-inventory/lib/table-detail";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import docInventoryService from "services/api-endpoints/dashboard/master-data/doc-inventory";

const DocInventoryView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const detailQuery = useQuery(
        [docInventoryService.detail, id],
        async () => {
            return (await docInventoryService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {},
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail inventory transfer" />
            <Card className="max-w-2xl">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-x-4 col-span-2">
                                    <p className="TEXT-1">
                                        Warehouse <br />
                                        <span className="TEXT-2">{detailQuery.data?.warehouse_name || "_"}</span>
                                    </p>
                                    <p className="TEXT-1">
                                        Doc Type <br />
                                        <span className="TEXT-2">{detailQuery.data?.doc_type || "_"}</span>
                                    </p>
                                    <p className="TEXT-1 mt-4">
                                        Note <br />
                                        <span className="TEXT-2">{detailQuery.data?.note || "_"}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 1 }} active />
                                <Skeleton paragraph={{ rows: 1 }} active />
                                <Skeleton paragraph={{ rows: 1 }} active />
                                <Skeleton paragraph={{ rows: 1 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <LocatorDetailTable list={detailQuery.data?.list_product || []} />
        </div>
    );
};

export default DocInventoryView;

import { Button, ButtonProps } from "antd";
import { StateContext } from "context/state";
import React, { useContext } from "react";
import { FiEdit } from "react-icons/fi";

type Props = ButtonProps;

export default function ButtonAccessEdit({ children, ...props }: Props) {
    const { updateAccess } = useContext(StateContext);

    if (!updateAccess) {
        return null;
    }

    return (
        <Button {...props}>
            <FiEdit className="text-lg" />
        </Button>
    );
}

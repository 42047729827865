import { Button, Card, Collapse, Form, Select, Skeleton, Space, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiPlus, BiSave } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AUTOGENERATED, FORMAT_DATE_1, ORDER_PAYMENT_ONLINE, TYPE_ORDER_PAYMENT } from "utils/constant";
import moment from "moment";
import TableAddJurnalUmum from "modules/accounting/jurnal-umum/table-add";
import ModalAddItemJurnalUmum, { AddItem, AddItemT } from "modules/accounting/jurnal-umum/modal-add-item";
import jurnalUmumService, { BodyChildJurnalUmum, BodyCreateJurnalUmum } from "services/api-endpoints/accounting/jurnal-umum";

const schema: yup.SchemaOf<Partial<BodyCreateJurnalUmum>> = yup.object().shape({
    transaction_date: yup.string().required("Date Required"),
    id: yup.number(),
    child: yup.array(),
});

const JurnalUmumEdit = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const id = params.get("id");

    const [list, setList] = useState<AddItemT[]>([]);
    const [editedItem, setEditedItem] = useState<AddItemT | undefined>(undefined);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<BodyCreateJurnalUmum>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchDate = watch("transaction_date");

    const balanceAmount = list?.reduce((a, b) => (b.type ? a + b.amount : a), 0) === list?.reduce((a, b) => (!b.type ? a + b.amount : a), 0);

    const onAddItem = (item: AddItem & { id?: any }) => {
        setList((prev) => {
            if (prev?.find((el) => el.id === item.id)) {
                return prev?.map((el) => (el.id !== item.id ? el : { ...item, canEdit: true }));
            }
            return [...prev, { ...item, canEdit: true }];
        });
        setEditedItem(undefined);
    };

    const onDeleteItem = (item: AddItemT) => {
        setList((prev) => prev?.filter((el) => el.id !== item.id));
    };

    const onEditItem = (item: AddItemT) => {
        setEditedItem(item);
    };

    const detailQuery = useQuery(
        [jurnalUmumService.detail, id],
        async () => {
            return (await jurnalUmumService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                form.setFieldsValue({
                    transaction_date: moment(data.transaction_date),
                });
                const child = data?.child;
                if (child) {
                    setList(
                        child?.map(
                            (el) =>
                                ({
                                    account_detail: {
                                        coa_name: el.name,
                                        coa_id: el.coa_id,
                                    },
                                    amount: el.debit || el.kredit,
                                    type: !!el.kredit,
                                    id: el.coa_id || uuid(),
                                    note: el.note,
                                    canEdit: false,
                                } as AddItemT)
                        )
                    );
                }
                setValue("transaction_date", data.transaction_date);
            },
        }
    );

    const saveMutate = useMutation(async (data: BodyCreateJurnalUmum & { id?: any }) => {
        return (await jurnalUmumService.Create(data)).data;
    });

    const saveDraftMutate = useMutation(async (data: BodyCreateJurnalUmum & { id?: any }) => {
        return (await jurnalUmumService.CreateDraft(data)).data;
    });

    const prepareData = () => {
        return [...list]?.map(
            (el) =>
                ({
                    coa_id: el.account_detail?.coa_id,
                    debit: el.type ? null : el.amount,
                    kredit: el.type ? el.amount : null,
                    name: el.account_detail?.coa_name,
                    note: el.note,
                } as BodyChildJurnalUmum)
        );
    };

    const onCreateSubmitHandler = () => {
        saveMutate
            .mutateAsync({
                id,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Journal");
                navigate(-1);
            });
    };

    const save = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftSubmitHandler = () => {
        saveDraftMutate
            .mutateAsync({
                id,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Draft Journal");
                navigate(-1);
            });
    };

    const saveDraft = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onDraftSubmitHandler();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit jurnal Umum"
                rightAddition={() => (
                    <Space>
                        <Button
                            onClick={saveDraft}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading || detailQuery.isLoading}
                            loading={saveDraftMutate.isLoading}
                            type="ghost"
                            className="!flex !items-center !bg-white"
                        >
                            <BiSave className="m-0" />
                            Draft
                        </Button>
                        <Button
                            onClick={save}
                            loading={saveMutate.isLoading}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading || detailQuery.isLoading}
                            type="primary"
                            className="!flex !items-center"
                        >
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form form={form} layout="vertical">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0 col-span-1">
                                            Journal No <br />
                                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                                        </p>
                                        <ControlledInputDate
                                            control={control}
                                            name="transaction_date"
                                            placeholder="Transaction Date"
                                            label="Transaction Date"
                                        />
                                    </div>
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <p className="capitalize m-0">
                                    Journal No <br />
                                    <Skeleton active paragraph={{ rows: 1 }} />
                                </p>
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <ModalAddItemJurnalUmum afterClose={() => setEditedItem(undefined)} defaultValues={editedItem} onSubmit={onAddItem}>
                {(ctrl) => (
                    <div className="w-full flex items-end justify-between">
                        <Button
                            disabled={saveMutate.isLoading || saveDraftMutate.isLoading || detailQuery.isLoading}
                            type="default"
                            className="!flex !items-center w-fit"
                            onClick={ctrl.openModal}
                        >
                            <BiPlus />
                            Add Item
                        </Button>
                        {!balanceAmount ? <p className="m-0 text-red-400">The number of credit and debit amount must be the same</p> : null}
                    </div>
                )}
            </ModalAddItemJurnalUmum>
            <TableAddJurnalUmum onClickEdit={onEditItem} onClickDelete={onDeleteItem} list={list} loading={detailQuery.isLoading} />
        </div>
    );
};

export default JurnalUmumEdit;

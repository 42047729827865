import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TeknisiWo } from "services/api-endpoints/dashboard/master-data/working-order";

type Props<T> = {
    list: T[];
};

const WorkingOrderTableTeknisi = <T extends TeknisiWo>({ list }: Props<T>) => {
    const columns: ColumnsType<TeknisiWo> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Teknisi Name",
            dataIndex: "teknisi_name",
        },
        {
            title: "From",
            dataIndex: "from",
        },
    ];

    return (
        <Table rowKey={(record) => record.teknisi_id!} size="small" columns={columns} dataSource={list || []} className="w-full" pagination={false} />
    );
};

export default WorkingOrderTableTeknisi;

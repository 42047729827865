import Toolbar from "components/toolbar";
import TableIndex from "modules/master-data/external-order/ext-ro/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extReturnOrderService from "services/api-endpoints/dashboard/master-data/ext-return-order";

const ExtReturnOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const getList = useQuery([extReturnOrderService.getList, page], async () => {
        return (await extReturnOrderService.GetList({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar search={false} filter={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default ExtReturnOrder;

import { Icon } from "@mui/material";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import React from "react";
import { ListMenu } from "services/api-endpoints/dashboard/master-data/role-menu";
import { ItemRowRoleMenu } from "./table-menu";

export type ItemRowRoleChildMenu = Pick<ListMenu, "name" | "key" | "icon"> & { id?: any };

export type RoleChildMenuTableProps = {
    list?: ItemRowRoleChildMenu[];
    setMenus: React.Dispatch<React.SetStateAction<ItemRowRoleMenu[]>>;
};

const RoleChildMenuTable = ({ list, setMenus }: RoleChildMenuTableProps) => {
    const onDelete = (key: string) => {
        setMenus((prev) =>
            prev?.map((menu) => ({
                ...menu,
                collapse: menu?.collapse?.map((submenu) => ({
                    ...submenu,
                    collapse: submenu?.collapse?.filter((child) => child.key !== key),
                })),
            }))
        );
    };

    const columns: ColumnsType<ItemRowRoleChildMenu> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Child menu name",
            dataIndex: "name",
        },
        {
            title: "Child menu key",
            dataIndex: "key",
        },
        {
            title: "Icon",
            dataIndex: "icon",
            render: (val) => (
                <div className="flex items-center gap-2">
                    {val && <Icon fontSize="medium">{val}</Icon>}
                    {val}
                </div>
            ),
        },
        {
            title: "Action",
            width: "100px",
            render: (_, record) => <ButtonAccessDelete onClick={() => onDelete(record.key)} danger type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.key!}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full third-table-branch"
            pagination={false}
        />
    );
};

export default RoleChildMenuTable;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { UserContext } from "context/user";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import PrintCPD from "modules/master-data/consignment/picking-detail/c-pd-print";
import ModalEditPD from "modules/master-data/consignment/picking-detail/edit_pd_modal";
import TableEdit from "modules/master-data/consignment/picking-detail/table-edit";
import TableView from "modules/master-data/consignment/picking-detail/table-view";
import VersionHistory from "modules/master-data/consignment/version-history";
import { useContext, useRef, useState } from "react";
import { AiFillPrinter, AiOutlineUndo } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import cPickingListService, {
    CreateProduct,
    EditPickingDetail,
    LocatorData,
    ProductPickingList,
} from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import { C_STATUS_ORDER, TYPE_MODAL_C_SO } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const CPickingDetailEdit = ({ paramId, asPreview }: Props) => {
    const { state } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductPickingList[]>([]);

    const detailQuery = useQuery(
        [cPickingListService.detail, id],
        async () => {
            return (await cPickingListService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail.map((el) => ({ ...el, qty_approved: el.qty })));
            },
        }
    );

    const editMutation = useMutation(
        [cPickingListService.edit],
        async (data: EditPickingDetail) => {
            return (await cPickingListService.Edit(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Picking Detail Edited");
                navigate(-1);
            },
        }
    );

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Picking Detail - ${detailQuery.data?.doc_no}`,
        pageStyle: "@page { size: A5 landscape; }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        // setProducts((prev) =>
        //     [...prev].map((prd) => {
        //         if (prd.product_id !== locator.product_id || prd.id !== docId) return prd;
        //         return {
        //             ...prd,
        //             area_id: locator.area_id,
        //             area_name: locator.area_name,
        //             qty_locator: Number(locator.qty_locator),
        //         };
        //     })
        // );
    };

    const onEditHandler = async () => {
        try {
            const productFilter = products.filter((p) => p.status !== "delete");
            const parseData: EditPickingDetail = {
                id,
                detail_product: productFilter.map((el) => {
                    return {
                        product_id: el.product_id,
                        price: el.product_price,
                        qty: el.qty_approved,
                        discount_price: el.discount_price,
                        voucher_id: el.voucher_id,
                        note: el.note,
                        is_free: el.is_free,
                        area_id: el.area_id,
                    } as CreateProduct;
                }),
            };
            editMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const isSameProduct = () => {
        const filteredQty = products.filter((p) => p.status === "update" || p.status === "delete");
        if (filteredQty.length !== 0) {
            return false;
        }
        return true;
    };

    const onUndoRemoveProduct = (product: ProductPickingList) => {
        return () => {
            const prevProduct = products?.find((p) => p.product_id === product.product_id);
            const isEdited = prevProduct?.qty !== product.qty_approved;
            setProducts((prev) =>
                prev.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
        };
    };

    const onEditProduct = (productList: ProductPickingList[], prevEditProduct?: ProductPickingList | null) => {
        if (!prevEditProduct) return;

        const prevProduct = detailQuery.data?.list_detail?.find((p) => p.product_id === prevEditProduct?.product_id);
        const currEditProduct = productList?.find((p) => p.product_id === prevEditProduct.product_id);
        if (!currEditProduct) return;

        const isEdited = prevProduct?.qty !== currEditProduct.qty_approved || (prevProduct?.note || null) !== (currEditProduct?.note || null);
        if (prevProduct) {
            setProducts(() =>
                productList?.map((p) => (p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined } : p))
            );
            return;
        }
        setProducts(productList);
    };

    const onRemoveProduct = (product: ProductPickingList) => {
        const productIsAlreadyThere = detailQuery.data?.list_detail?.find((p) => p.product_id === product.product_id);
        if (productIsAlreadyThere) {
            message.warning("Product is given DELETED flag");
            setProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            return;
        }
        message.warning("Product removed");
        setProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintCPD ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="edit picking detail"
                rightAddition={() => (
                    <Space>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print Picking Detail
                        </Button>
                        {detailQuery.data?.account_id === state.user?.account_id && (
                            <ModalEditPD title="Konfirmasi" onSubmit={onEditHandler} isSameProduct={isSameProduct()}>
                                {(modal) => (
                                    <Button
                                        loading={editMutation.isLoading}
                                        onClick={modal.openModal}
                                        type="primary"
                                        className="!flex !items-center"
                                        disabled={isSameProduct()}
                                    >
                                        <BiSave className="m-0 mr-2" />
                                        Save Edit
                                    </Button>
                                )}
                            </ModalEditPD>
                        )}
                    </Space>
                )}
            />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(stateDetail) => (
                            <>
                                <State.Data state={stateDetail}>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Referensi No <br />
                                            {detailQuery.data?.ref_id ? (
                                                <RefModal
                                                    id={detailQuery.data?.ref_id}
                                                    docNo={detailQuery.data?.ref_no}
                                                    type={detailQuery.data.ref_type}
                                                >
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {detailQuery.data?.ref_no || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ) : (
                                                <span className="underline m-0">-</span>
                                            )}
                                        </p>
                                        <p className="capitalize m-0">
                                            Partner Internal <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={stateDetail}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: (
                                    <TableEdit
                                        list={products}
                                        setList={setProducts}
                                        loading={detailQuery.isLoading}
                                        onSubmitLocator={onSubmitLocator}
                                        onSetList={onEditProduct}
                                        removeItemList={onRemoveProduct}
                                        action={detailQuery.data?.account_id === state.user?.account_id}
                                        actionAddition={(record: ProductPickingList) =>
                                            record.status === "delete" ? (
                                                <Button
                                                    onClick={onUndoRemoveProduct(record)}
                                                    icon={<AiOutlineUndo className="mr-1" />}
                                                    size="small"
                                                    type="primary"
                                                >
                                                    Undo
                                                </Button>
                                            ) : (
                                                ""
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default CPickingDetailEdit;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import RoutineStockTable from "modules/master-data/routine-stock/table-index";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import materialMovementService, {
    DeleteMM,
    MaterialMovement as MaterialMovementModel,
} from "services/api-endpoints/dashboard/master-data/material-movement";

const RoutineStock = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getAll = useQuery([materialMovementService.getRoutineStock, page, searchValue, isSearch], async () => {
        return (await materialMovementService.GetRoutineStock({ page, query: searchValue.query || "" })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar filter={false} add={false} placeholder="Search..." />
            <RoutineStockTable fetcher={getAll} />
        </div>
    );
};

export default RoutineStock;

/* eslint-disable jsx-a11y/control-has-associated-label */
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import OrderView from "pages/order/view";

type Props = ModalProps & {
    id: any;
};

const ModalOrder = ({ id, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1000} handlerInComponent={children} footer={null} {...props}>
            {(dt) => <OrderView paramId={id} asPreview />}
        </ModalTemplate>
    );
};

export default ModalOrder;

/* eslint-disable react/no-array-index-key */
import { StateContext } from "context/state";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import MyAppsPage from "./myapps";

const IndexPage = () => {
    const { state: stateContext } = useContext(StateContext);

    if (!stateContext.activeApp) return <MyAppsPage />;

    const defaultFirstRoute = stateContext.activeApp?.apps_menu![0].collapse
        ? stateContext.activeApp.apps_menu![0].collapse[0].route
        : stateContext.activeApp.apps_menu![0].key;

    return <Navigate to={defaultFirstRoute || "/"} />;
};

export default IndexPage;

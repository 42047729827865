import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import ModalAddHoliday from "modules/master-data/holiday/add/modal-add";
import ModalEditHoliday from "modules/master-data/holiday/edit/modal-edit";
import HolidayTable from "modules/master-data/holiday/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import holidayService, { Holiday } from "services/api-endpoints/dashboard/master-data/holiday";

const MasterHoliday = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const getAllHoliday = useQuery([holidayService.getAll, page], async () => {
        return (await holidayService.GetAll({ page })).data.data;
    });

    const deleteModelType = useMutation(async (data: Holiday) => (await holidayService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getAllHoliday.refetch();
            message.success("Data has been removed!");
        },
    });

    const [showConfirm] = useConfirmDelete<Holiday>({
        title: "Delete row?",
        content: `You sure to remove this data?`,
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ModalAddHoliday refetchAll={getAllHoliday.refetch}>
                {(handler) => (
                    <Toolbar
                        placeholder="Master Holiday"
                        filter={false}
                        addHandler={() => handler.openModalWithUrlParam("add", new Date().getTime())}
                    />
                )}
            </ModalAddHoliday>
            <ModalEditHoliday fetcher={getAllHoliday}>
                {(handler) => (
                    <HolidayTable
                        onClickEdit={(row) => handler.openModalWithUrlParam("edit", row.id)}
                        onClickDelete={showConfirm}
                        fetcher={getAllHoliday}
                    />
                )}
            </ModalEditHoliday>
        </div>
    );
};

export default MasterHoliday;

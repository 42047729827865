/* eslint-disable prettier/prettier */
import { Button } from "antd";
import ModalDownload from "components/modal/download_modal";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { parseOrderToCsvData } from "modules/master-data/product/utils";
import WorkingOrderTable from "modules/master-data/working-order/table";
import { FaDownload } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import orderService, { DownloadParams } from "services/api-endpoints/dashboard/master-data/order";
import workingOrderService from "services/api-endpoints/dashboard/master-data/working-order";
import Utils from "utils";

const WorkingOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([workingOrderService.getAll, page, searchValue, isSearch], async () => {
        return (await workingOrderService.GetAll({ page, query: searchValue.query })).data.data;
    });

    // const getDownload = useMutation(["export-csv"], async (data: DownloadParams) => {
    //     const req = await (() => {
    //         return orderService.DownloadOrder(data);
    //     })();
    //     return parseOrderToCsvData(req.data.data?.list, req.data.data?.list_summary || []);
    // });

    // const onDownload = async (type: number | null, startDate: any, endDate: any) => {
    //     const parseData: DownloadParams = {
    //         type,
    //         start_date: startDate,
    //         end_date: endDate,
    //     };
    //     getDownload.mutateAsync(parseData).then((res) => {
    //         Utils.jsonToCSV({ json: res as any, title: "List Order", showLabel: true });
    //     });
    // };

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar
                filter={false}
                add={false}
                placeholder="Order Number / Wo No / Client Name"
            // postfixAddition={() => (
            //     <ModalDownload title="Download" onSubmit={onDownload}>
            //         {(dt2) => (
            //             <Button onClick={dt2.openModal} loading={getDownload.isLoading} type="primary" className="!flex !items-center w-fit">
            //                 <FaDownload className="mr-2" />
            //                 Download
            //             </Button>
            //         )}
            //     </ModalDownload>
            // )}
            />
            <WorkingOrderTable fetcher={getList} />
        </div>
    );
};

export default WorkingOrder;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { CreateBpSales, CustomVoucher, EditBpSales, GetSummaryPrice } from "modules/dashboard-all/bp-sales/models";

export interface Voucher {
    voucher_id: number;
    voucher_image: string | null;
    voucher_code: string;
}

export interface SummaryPrice {
    total_price: number;
    total_discount: number;
    grand_total: number;
}

export interface Product {
    note?: string | null;
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
}

export interface DetailBpSales {
    order_id?: number;
    order_no?: string;
    bp_id?: number;
    bp_name?: number;
    customer_name?: string;
    customer_info?: string;
    customer_address?: string;
    order_status: number;
    total?: string;
    total_price?: number;
    grand_total?: number;
    total_discount?: number;
    list_detail?: Product[];
    voucher?: Voucher[];
    voucher_custom?: CustomVoucher[] | null;
    note?: string;
    shipping_id?: number;
    shipping_name?: string;
}

export interface EditBpSalesOrder {
    id: number;
    order_status: number;
    note: string;
}

export interface ListHistory {
    order_history_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface HistoryOrder {
    icon?: string;
    name?: string;
    order_status?: number;
    list: ListHistory[];
}

export interface ShippingData {
    shipping_id?: number;
    shipping_name?: string;
    shipping_code?: string;
    shipping_images?: string;
}

export interface BpSales {
    id?: number;
    so_no?: string;
    inv_no?: string;
    bp_name?: number;
    customer_name?: string;
    grand_total?: number;
    status?: number;
    date?: string;
}

export interface BpData {
    bp_id?: number;
    bp_name?: string;
    payment_term_id?: number;
}

class BpSalesService extends BaseService {
    getVoucher = "/bp-pos/get-voucher";

    checkAuth = "/bp-pos/check-auth";

    getSummaryPrice = "/bp-pos/get-summary-price";

    createBpSales = "/bp-pos/create-bp-sales";

    createDraftBpSales = "/bp-pos/create-draft";

    detail = "/bp-pos/detail-bp-sales";

    editBpSales = "/bp-pos/edit-bp-sales";

    editDraftBpSales = "/bp-pos/edit-draft";

    historyOrder = "/bp-pos/get-history-order";

    getShipping = "/bp-pos/get-shipping";

    getAll = "/bp-pos/get-bp-sales";

    getBp = "/bp-pos/get-business-partner";

    deleteDraft = "/bp-pos/delete-draft";

    constructor() {
        super();
    }

    DeleteDraft<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteDraft}/${id}`,
            });
            return req;
        });
    }

    GetBp<T extends BpData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBp,
            });
            return req;
        });
    }

    GetAll<T extends BpSales>(param: { page: any; size: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetShipping<T extends ShippingData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getShipping,
            });
            return req;
        });
    }

    HistoryOrder<T extends HistoryOrder[]>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.historyOrder}/${data.id}`,
            });
            return req;
        });
    }

    EditBpSales<T extends any>(data: EditBpSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editBpSales,
                data,
            });
            return req;
        });
    }

    EditDraftBpSales<T extends any>(data: EditBpSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraftBpSales,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailBpSales>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    CreateBpSales<T extends any>(data: CreateBpSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createBpSales,
                data,
            });
            return req;
        });
    }

    CreateDraftBpSales<T extends any>(data: CreateBpSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDraftBpSales,
                data,
            });
            return req;
        });
    }

    GetSummaryPrice<T extends SummaryPrice>(data: GetSummaryPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.getSummaryPrice,
                data,
            });
            return req;
        });
    }

    GetVoucher<T extends Voucher[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getVoucher,
            });
            return req;
        });
    }

    CheckAuth<T extends any>(data: { idToken: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.checkAuth,
                data,
            });
            return req;
        });
    }
}

const bpSalesService = new BpSalesService();
export default bpSalesService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface ProductGR {
    doc_id?: number;
    product_id?: number;
    qty?: number;
    price?: number;
    area_id?: number;
}

export interface CreateGRData {
    bp_id?: number;
    doc_type?: number;
    payment_term_id: number | null;
    child_document?: number[];
    detail_product?: ProductGR[];
}

export interface ListData {
    do_id?: number;
    do_no?: string;
    total_sku?: number;
    total_item?: number;
    date?: number;
    payment_term_code?: string;
    payment_term_id?: number;
}

export interface DetailProduct {
    doc_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    area_id?: number;
    area_name?: string;
    qty?: number;
    qty_req?: number;
    qty_receipt?: number;
    so_no?: string;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

export interface DetailList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    qty?: number;
    po_id?: number;
    qty_gr?: number;
    qty_send?: number;
    area_id?: number;
    area_name?: string;
    so_no?: string;
}

export interface DetailGR {
    id?: number;
    gr_no?: string;
    inv_no?: string;
    request_from?: string;
    request_from_id?: number;
    request_to?: string;
    order_status?: number;
    child_document?: number[];
    doc_type?: number;
    list_detail?: DetailList[];
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface List {
    id?: number;
    no?: string;
}

export interface ListHistory {
    id?: number;
    notes?: string;
    created_by?: string;
    created_at?: number;
    list_so?: List[];
    list_pl?: List[];
    list_do?: List[];
    list_history?: List[];
}

export interface BPGRList {
    id?: number;
    do_no?: string;
    gr_no?: string;
    inv_no?: string;
    reques_from?: string;
    driver_name?: string;
    status_order?: number;
    date?: number;
    pl_no?: any[];
}

class BpGoodsReceiptService extends BaseService {
    getAll = "/bp-gr/get-all-goods-receipt";

    listData = "/bp-gr/list-data";

    detailProduct = "/bp-gr/get-detail-product";

    createGR = "/bp-gr/create-goods-receipt";

    detailGR = "/bp-gr/detail-goods-receipt";

    history = "/bp-gr/history-goods-receipt";

    constructor() {
        super();
    }

    History<T extends ListHistory>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.history,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailGR<T extends DetailGR>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailGR}/${id}`,
            });
            return req;
        });
    }

    ListData<T extends ListData[]>(param: { bp_id: any; option: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listData,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailProduct<T extends DetailProduct[]>(params: { do_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProduct,
                config: { params },
            });
            return req;
        });
    }

    CreateGR<T extends any>(data: CreateGRData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createGR,
                data,
            });
            return req;
        });
    }

    GetAll<T extends BPGRList>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const bpGoodsReceiptService = new BpGoodsReceiptService();
export default bpGoodsReceiptService;

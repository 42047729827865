/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export enum Type {
    Debit = "DEBIT",
    Kredit = "KREDIT",
}

export interface BaseBukuBesarResponse<T = any> {
    data: T[];
    total_data: number;
    total_page: number;
    current_page: number;
}

export interface Child {
    name?: string;
    type?: Type;
    amount?: number;
}

export interface JurnalUmum {
    id?: number;
    doc_id?: string;
    is_posting?: null;
    is_acc_posted?: number;
    is_fn_posted?: number;
    note?: string;
    created_at?: string;
    child?: Child[];
}

export interface FilterJurnalUmum {
    start_date: any;
    end_date: any;
    type: any;
}

export interface BaseJurnalResponse<T> {
    data: any;
    list_unposted: T[];
    list_posted: T[];
}

export interface BaseGitResponse<T> {
    data: any;
    list: T[];
}

export interface FilterBukuBesar {
    type_id?: any;
    id?: any;
    coa_id?: any;
    bp_id?: any;
    account_id?: any;
    start_date?: any;
    end_date?: any;
    page?: any;
}

export interface FilterBukuUtang {
    type_id?: any;
    marketplace_id?: any;
    start_date?: any;
    end_date?: any;
}

export interface CoaList {
    coa_id?: any;
    coa_name?: any;
    coa_code?: any;
}

export interface BukuBesarList {
    id?: number;
    doc_id?: number;
    doc_type?: number;
    account?: string;
    journal_no?: string;
    is_acc_posted?: number;
    created_at?: string;
    coa_id?: number;
    coa_code?: string;
    coa_name?: string;
    name?: string;
    note?: string;
    saldo?: number;
    debit?: number;
    kredit?: number;
}

export interface SaldoData {
    note?: string;
    saldo?: number;
}

export interface BukuBesarData {
    list?: BukuBesarList[];
    saldo_awal?: SaldoData;
    total?: SaldoData;
}

export interface BukuBesarPagination<T> extends Models.BasePaginationResponse<T> {
    saldo_awal?: SaldoData;
    total?: SaldoData;
}

export interface BukuUtangList {
    id?: number;
    journal_no?: string;
    is_acc_posted?: number;
    created_at?: string;
    coa_id?: number;
    name?: string;
    note?: string;
    saldo?: number;
    debit?: number;
    kredit?: number;
}

export interface CoaPiutangList {
    id?: number;
    bp_id?: number;
    name?: string;
}

export interface JournalChild {
    id?: any;
    coa_id?: number;
    name?: string;
    debit?: any;
    kredit?: any;
    type?: any;
}

export interface JournalDetail {
    id?: number;
    doc_type?: number;
    journal_no?: string;
    journal_name?: string;
    child?: JournalChild[];
}

export interface RefVoucherChild {
    inv_id?: number;
    inv_no?: string;
    transaction_date?: string;
    grand_total?: number;
}

export interface RefVoucherDetail {
    journal_id?: number;
    doc_id?: number;
    doc_no?: string;
    doc_type?: number;
    doc_name?: string;
    type_name?: string;
    partner_name?: string;
    total_voucher?: number;
    created_at?: string;
    child?: RefVoucherChild[];
}

export interface RefInvoiceChild {
    product_id?: number;
    product_name?: string;
    price?: number;
    qty?: number;
}

export interface RefInvoiceDetail {
    journal_id?: number;
    inv_id?: number;
    inv_no?: string;
    type?: number;
    type_name?: string;
    grand_total?: number;
    created_at?: string;
    child?: RefInvoiceChild[];
}

class BukuBesarService extends BaseService {
    filterGoodsInTransit = "/report-accounting/filter-goods-transit";

    coaList = "/report-accounting/get-all-coa";

    coaPiutangList = "/report-accounting/get-coa-piutang";

    coaUtangList = "/report-accounting/get-coa-utang";

    bukuBesarList = "/report-accounting/filter-buku-besar-perakun";

    bukuUtangList = "/report-accounting/filter-buku-utang";

    detailJournal = "/report-accounting/ref-general-ledger";

    refVoucher = "/report-accounting/ref-voucher";

    refInvoice = "/report-accounting/ref-invoice";

    RefInvoice<T extends RefInvoiceDetail>(params: { id: any; inv_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.refInvoice,
                config: { params },
            });
            return req;
        });
    }

    RefVoucher<T extends RefVoucherDetail>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.refVoucher,
                config: { params },
            });
            return req;
        });
    }

    DetailJournal<T extends JournalDetail>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailJournal,
                config: { params },
            });
            return req;
        });
    }

    BukuUtangList<T extends BukuUtangList[]>(params: FilterBukuUtang) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.bukuUtangList,
                config: { params },
            });
            return req;
        });
    }

    BukuBesarList<T extends BukuBesarList>(params: FilterBukuBesar) {
        return this.ProxyRequest<BukuBesarPagination<T>>(async () => {
            const req = await ApiMethod.get<BukuBesarPagination<T>>({
                url: this.bukuBesarList,
                config: { params },
            });
            return req;
        });
    }

    CoaList<T extends CoaList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.coaList,
            });
            return req;
        });
    }

    CoaPiutangList<T extends CoaPiutangList[]>(params: { order_type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.coaPiutangList,
                config: { params },
            });
            return req;
        });
    }

    CoaUtangList<T extends CoaPiutangList[]>(params: { order_type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.coaUtangList,
                config: { params },
            });
            return req;
        });
    }

    FilterGoodsInTransit<T extends JurnalUmum>(params: FilterJurnalUmum) {
        return this.ProxyRequest<BaseGitResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseGitResponse<T>>({
                url: this.filterGoodsInTransit,
                config: { params },
            });
            return req;
        });
    }
}

const bukuBesarService = new BukuBesarService();
export default bukuBesarService;

import { Button, Card, Tabs, message } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import JurnalProvider from "modules/accounting/jurnal/context/jurnal";
import FilterJurnal from "modules/accounting/jurnal/index/filter";
import PostedTable from "modules/accounting/jurnal/index/table-posted";
import UnpostedTable from "modules/accounting/jurnal/index/table-unposted";
import LoyaltyTable from "modules/master-data/loyalty/index/table";
import React from "react";
import { IoRefresh } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import jurnalUmumService, { FilterJurnalUmum, UpdateJournalData } from "services/api-endpoints/accounting/jurnal-umum";
import loyaltyService from "services/api-endpoints/dashboard/master-data/loyalty";
import { TYPE_JOURNAL_ACCOUNTING, TYPE_JURNAL_PENUTUP } from "utils/constant";

const filter = ["start_date", "end_date"];

const JurnalPenutup = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isSearch, searchValue, filterValue2 } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([jurnalUmumService.filter, filterValue2, isSearch, searchValue], async () => {
        if (isSearch)
            return (
                await jurnalUmumService.Filter({
                    ...({ query: searchValue.query, start_date: "", end_date: "", type: TYPE_JURNAL_PENUTUP } as FilterJurnalUmum),
                })
            ).data.data;
        return (
            await jurnalUmumService.Filter({
                ...({ ...filterValue2, query: "", type: TYPE_JURNAL_PENUTUP } as FilterJurnalUmum),
            })
        ).data.data;
    });

    const updateStatusJournal = useMutation(
        [jurnalUmumService.updateStatusJournal],
        async (data: UpdateJournalData) => {
            return (await jurnalUmumService.UpdateStatusJournal(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Success Post Journal");
                getList.refetch();
            },
        }
    );

    const onPosJournal = (data: any[]) => {
        const dataFilter = data.filter((el) => el.is_posting);
        const parseData: UpdateJournalData = {
            type: TYPE_JOURNAL_ACCOUNTING,
            doc_id: dataFilter?.map((itm) => itm.id as any),
        };

        updateStatusJournal.mutateAsync(parseData);
    };

    const onRefresh = () => {
        getList.refetch();
        message.success("Refresh Journal");
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterJurnal title="Jurnal Penutup" filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Search Invoice Referensi..."
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterJurnal>
            <Card>
                <Tabs
                    tabBarExtraContent={
                        <Button className="w-fit" onClick={onRefresh}>
                            <IoRefresh className="mr-3" />
                            Refresh
                        </Button>
                    }
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Unposted`,
                            key: "1",
                            children: (
                                <JurnalProvider>
                                    <UnpostedTable fetcher={getList} onPosJournal={onPosJournal} typeJurnal={TYPE_JURNAL_PENUTUP} />
                                </JurnalProvider>
                            ),
                        },
                        {
                            label: `Posted`,
                            key: "2",
                            children: <PostedTable fetcher={getList} typeJurnal={TYPE_JURNAL_PENUTUP} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default JurnalPenutup;

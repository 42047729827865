import React from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Product } from "models";

type Props<T> = {
    list: T[];
    removeItemList: (record: T) => void;
};

const ProductTable = <T extends Product>({ list, removeItemList }: Props<T>) => {
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Button onClick={() => removeItemList(record)} type="text" danger>
                    <MdOutlineDeleteOutline className="text-lg" />
                </Button>
            ),
        },
    ];

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={{
                pageSize: 10,
                total: list.length,
                showSizeChanger: false,
            }}
        />
    );
};

export default ProductTable;

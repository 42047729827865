/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button, Select, Space, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { LocatorPI, UploadProduct } from "services/api-endpoints/dashboard/master-data/physical-inventory";
import LocatorModalPI from "./modal-locator";
import { IoClose } from "react-icons/io5";
import { SelectOption } from "models";

export type ValidasiLocatorTableProps = {
    type?: number | undefined;
    list?: (UploadProduct & LocatorPI)[];
    locatorList?: SelectOption[];
    onSubmit: (locator: UploadProduct & LocatorPI) => void;
    onRemove: (locator: UploadProduct & LocatorPI) => void;
};

const ValidasiLocatorTable = ({ type, list, locatorList, onSubmit, onRemove }: ValidasiLocatorTableProps) => {
    const [page, setPage] = useState<number>(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const onSubmitLocator = (product: UploadProduct & LocatorPI) => {
        onSubmit(product);
    };

    const onChange = (product: UploadProduct, val: any) => {
        const temp = { ...product, area_id: val } as UploadProduct & LocatorPI;
        onSubmit(temp);
    };

    const columns: ColumnsType<UploadProduct & LocatorPI> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Locator",
            dataIndex: "area_name",
            render: (text, record) => {
                if (type === 1)
                    return (
                        <Select
                            placeholder="Select Locator"
                            value={record.area_id}
                            options={locatorList}
                            optionFilterProp="label"
                            showSearch
                            onChange={(val) => onChange(record, val)}
                        />
                    );
                return (
                    <LocatorModalPI title="Locator" product={record} list={list} onSubmit={onSubmitLocator}>
                        {(dt) => (
                            <Space>
                                <Button
                                    size="small"
                                    onClick={() => (record?.area_id ? {} : dt.openModalWithData(record))}
                                    className="!flex !items-center"
                                    type={record?.area_id ? "text" : "default"}
                                    {...(!text ? { danger: true } : {})}
                                >
                                    {text || "Choose Locator"}
                                </Button>
                                {record?.area_id ? (
                                    <button
                                        onClick={() => onRemove(record)}
                                        className="border-none bg-transparent cursor-pointer"
                                        type="button"
                                        title="remove"
                                    >
                                        <IoClose className="text-red-400 text-xl" />
                                    </button>
                                ) : null}
                            </Space>
                        )}
                    </LocatorModalPI>
                );
            },
        },
    ];

    return (
        <Table
            rowKey={(i) => i.id as any}
            size="small"
            columns={columns}
            dataSource={list || []}
            pagination={{
                pageSize: 20,
                total: list?.length,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ValidasiLocatorTable;

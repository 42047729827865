/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ColumnType } from "antd/lib/table";
import { ProductWithQty } from "modules/advance-search/models";
import { STOCK_ACTION } from "utils/constant";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function addProductTable<T extends ProductWithQty>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
            },
            {
                title: "Quantity Return",
                dataIndex: "qty_return",
                fixed: "right",
                width: "75px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Stock Action",
                width: "100px",
                dataIndex: "stock_action",
                ...{ editable: true },
                render: (text) => text || "",
            },
        ];

        const editInputType: EditTableProps<T>["editInputType"] = { qty_return: "number", stock_action: "select" };
        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id! as any;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    selectProps: {
                        options: STOCK_ACTION,
                        className: "!w-[150px]",
                        placeholder: "Action",
                    },
                    ...props?.cellProps,
                }}
                list={props.list?.map((order: any) => ({
                    ...order,
                    stock_action: STOCK_ACTION.find((act) => act.value === order.stock_action || act.label === order.stock_action)?.label,
                }))}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const ProductTableAdd = addProductTable(EditTable);
export default ProductTableAdd;

/* eslint-disable react/no-array-index-key */
import moment from "moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { DetailStock, ProductTransferWithEdit2 } from "services/api-endpoints/dashboard/master-data/material-movement";
import { FORMAT_DATE_TIME } from "utils/constant";
import { tProductTransformWithQty2 } from "../material-movement/utils";

type Props = {
    data: DetailStock | null;
};

const Print = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const [currentTime, setCurrentTime] = useState();

    const limitPattern = [10, 20]; // [first, middle]

    const arr: ProductTransferWithEdit2[][] = [];
    const listProductCopy = [...(tProductTransformWithQty2(data?.list_product || []) || [])];
    while (listProductCopy.length) {
        arr.push(listProductCopy.splice(0, arr.length === 0 ? limitPattern[0] : limitPattern[1]));
    }

    const listProduct = tProductTransformWithQty2(data?.list_product || []);

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            <div className="w-full bg-white p-5">
                <div className="w-full flex items-end justify-between">
                    <div className="max-w-[55%]">
                        <h1 className="text-2xl uppercase m-0">PT. SEHATI PRIMA MAKMUR</h1>
                        <p className="text-xs m-0">
                            Rukan Paris Golf Lake Residence Blok B No 23-25 Cengkareng, RT.10/RW.14, East Cengkareng, Cengkareng, West Jakarta City,
                            Jakarta 11730
                        </p>
                    </div>
                    <h2 className="text-3xl font-medium m-0 capitalize">Routine Stock</h2>
                </div>
                <div className="w-full h-[2px] bg-gray-400 mt-2" />
                <div className="w-full h-[2px] bg-gray-400 mt-1" />
                <div className="w-full grid grid-cols-3">
                    <div className="">
                        <table>
                            <tr className="text-xs">
                                <td className="min-w-[60px]">No</td>
                                <td>{` : ${data?.mm_no}`}</td>
                            </tr>
                            <tr className="text-xs">
                                <td className="min-w-[60px]">Tanggal</td>
                                <td> : {moment().format("dddd DD MM yyyy")}</td>
                            </tr>
                            <tr className="text-xs">
                                <td>.</td>
                            </tr>
                            <tr className="text-xs">
                                <td className="min-w-[60px]">Locator From</td>
                                <td>: {data?.area_from || "-"}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex justify-center">
                        <QRCode size={70} value={(data?.mm_no as any) || ""} />
                    </div>
                </div>
                <div className="flex flex-col mt-3 mb-5 break-inside-auto">
                    <table>
                        <thead className="border border-solid border-gray-500">
                            <th className="border border-solid border-gray-500 px-2 text-xs">No</th>
                            <th className="border border-solid border-gray-500 text-xs">Kode</th>
                            <th className="border border-solid border-gray-500 text-xs">Nama Produk</th>
                            <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Before</th>
                            <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Scan</th>
                        </thead>
                        <tbody className="border border-solid border-gray-500">
                            {listProduct?.map((el, i) => {
                                return (
                                    <tr key={i} className="border border-solid border-gray-500">
                                        <td className="border border-solid border-gray-500 text-xs text-center px-1">{i + 1}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.product_code}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1">{el.product_name}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_before}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_scan}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-start justify-between">
                    {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                        <div className="flex flex-col items-center" key={i}>
                            <p className="capitalize font-medium text-xs">{el}</p>
                            <p className="text-3xl m-0 mt-1">(...........)</p>
                        </div>
                    ))}
                    <div className="">
                        <table className="text-xs">
                            <tr>
                                <td className="min-w-[100px]">Catatan</td>
                                <td className="max-w-[200px]">: </td>
                            </tr>
                            <tr>
                                <td className="min-w-[100px]">Print</td>
                                <td>
                                    : - <br />
                                    {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
});

// const Print = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
//     const [currentTime, setCurrentTime] = useState();

//     const limitPattern = [10, 15]; // [first, middle]

//     const arr: ProductTransferWithEdit2[][] = [];
//     const listProductCopy = [...(tProductTransformWithQty2(data?.list_product || []) || [])];
//     while (listProductCopy.length) {
//         arr.push(listProductCopy.splice(0, arr.length === 0 ? limitPattern[0] : limitPattern[1]));
//     }

//     if (!data) return <div className="" />;
//     return (
//         <div ref={ref} className="hidden print:block">
//             {arr?.map((products, page) => (
//                 <div className="w-full bg-white p-5">
//                     {page === 0 ? (
//                         <>
//                             <div className="w-full flex items-end justify-between">
//                                 <div className="max-w-[55%]">
//                                     <h1 className="text-2xl uppercase m-0">PT. SEHATI PRIMA MAKMUR</h1>
//                                     <p className="text-xs m-0">
//                                         Rukan Paris Golf Lake Residence Blok B No 23-25 Cengkareng, RT.10/RW.14, East Cengkareng, Cengkareng, West
//                                         Jakarta City, Jakarta 11730
//                                     </p>
//                                 </div>
//                                 <h2 className="text-3xl font-medium m-0 capitalize">Routine Stock</h2>
//                             </div>
//                             <div className="w-full h-[2px] bg-gray-400 mt-2" />
//                             <div className="w-full h-[2px] bg-gray-400 mt-1" />
//                             <div className="w-full grid grid-cols-3 mt-4">
//                                 <div className="">
//                                     <table>
//                                         <tr className="text-xs">
//                                             <td className="min-w-[60px]">No</td>
//                                             <td>{` : ${data?.mm_no}`}</td>
//                                         </tr>
//                                         <tr className="text-xs">
//                                             <td className="min-w-[60px]">Tanggal</td>
//                                             <td> : {moment().format("dddd DD MM yyyy")}</td>
//                                         </tr>
//                                         <tr className="text-xs">
//                                             <td>.</td>
//                                         </tr>
//                                         <tr className="text-xs">
//                                             <td className="min-w-[60px]">Locator From</td>
//                                             <td>: {data?.area_from || "-"}</td>
//                                         </tr>
//                                     </table>
//                                 </div>
//                                 <div className="flex justify-center">
//                                     <QRCode size={70} value={(data?.mm_no as any) || ""} />
//                                 </div>
//                             </div>
//                         </>
//                     ) : null}
//                     <div className="flex flex-col mt-5 break-after-auto break-inside-avoid">
//                         <table>
//                             <thead className="border border-solid border-gray-500">
//                                 <th className="border border-solid border-gray-500 px-2 text-xs">No</th>
//                                 <th className="border border-solid border-gray-500 text-xs">Kode</th>
//                                 <th className="border border-solid border-gray-500 text-xs">Nama Produk</th>
//                                 <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Before</th>
//                                 <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Scan</th>
//                             </thead>
//                             <tbody className="border border-solid border-gray-500">
//                                 {products?.map((el, i) => {
//                                     const noItem =
//                                         page === 0
//                                             ? i + 1
//                                             : i + 1 + arr.reduce((value, current, index) => (index < page ? value + current.length : value), 0);
//                                     return (
//                                         <tr key={i} className="border border-solid border-gray-500">
//                                             <td className="border border-solid border-gray-500 text-xs text-center px-1">{noItem}</td>
//                                             <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.product_code}</td>
//                                             <td className="border border-solid border-gray-500 text-xs px-1">{el.product_name}</td>
//                                             <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_before}</td>
//                                             <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_scan}</td>
//                                         </tr>
//                                     );
//                                 })}
//                             </tbody>
//                         </table>
//                         <p className="self-end mt-2">{`Halaman ${page + 1} dari ${arr.length}`}</p>
//                     </div>
//                     {page === arr.length - 1 ? (
//                         <div className="flex items-start justify-between">
//                             {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
//                                 <div className="flex flex-col items-center" key={i}>
//                                     <p className="capitalize font-medium text-xs">{el}</p>
//                                     <p className="text-3xl m-0 mt-1">(...........)</p>
//                                 </div>
//                             ))}
//                             <div className="">
//                                 <table className="text-xs">
//                                     <tr>
//                                         <td className="min-w-[100px]">Catatan</td>
//                                         <td className="max-w-[200px]">: </td>
//                                     </tr>
//                                     <tr>
//                                         <td className="min-w-[100px]">Print</td>
//                                         <td>
//                                             : - <br />
//                                             {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}
//                                         </td>
//                                     </tr>
//                                 </table>
//                             </div>
//                         </div>
//                     ) : null}
//                 </div>
//             ))}
//         </div>
//     );
// });

export default Print;

import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { CreateDataBrand, EditDataBrand } from "modules/master-data/brand/models";
import BrandTypeSelect from "modules/master-data/brand/lib/brand-type-select";
import IsPopularSelect from "modules/master-data/brand/lib/is-popular-select";
import IsActiveSelect from "modules/master-data/brand/lib/is-active-select";
import InputFile from "components/form/inputs/input-file";
import { AWS_PATH, IMAGE_TYPES } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import useProgressUpload from "hooks/useProgressUpload";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<CreateDataBrand>> = yup.object().shape({
    brand_name: yup.string().required("Brand name required"),
    brand_code: yup.string().required("Brand code required"),
    brand_type: yup.number().required("Brand type required"),
    is_popular: yup.number().required("Popular required"),
    is_active: yup.number().required("Is Active required"),
    brand_image: yup.string().nullable(),
    car_image: yup.string().nullable(),
    header_image: yup.string().nullable(),
});

const BrandEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<CreateDataBrand>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [progress, onUploadProgress] = useProgressUpload();

    const [controlFile] = useControlFile<CreateDataBrand>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const detailQuery = useQuery(
        [brandService.detail, id],
        async () => {
            return (await brandService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                    is_active: data.status,
                });
                setValue("brand_code", data.brand_code);
                setValue("brand_name", data.brand_name);
                setValue("brand_type", data.brand_type);
                setValue("is_popular", data.is_popular);
                setValue("is_active", data.status);
                setValue("header_image", data.header_image);
                setValue("car_image", data.car_image);
                setValue("brand_image", data.brand_image);
            },
        }
    );

    const editMutation = useMutation(
        [brandService.edit],
        async (data: EditDataBrand) => {
            return (await brandService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Brand edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
            header_image: data.header_image?.includes(AWS_PATH) ? "default" : data.header_image || "",
            car_image: data.car_image?.includes(AWS_PATH) ? "default" : data.car_image || "",
            brand_image: data.brand_image?.includes(AWS_PATH) ? "default" : data.brand_image || "",
        } as EditDataBrand;
        editMutation.mutate(parseData);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit brand"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="header_image" className="col-span-2">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="header image" />}
                                        </InputFile>
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="car_image">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="car image" />}
                                        </InputFile>
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="brand_image">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="brand image" />}
                                        </InputFile>
                                    </div>
                                    <Form form={form} disabled={editMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">brand information</h1>
                                        <ControlledInputText
                                            label="Brand Name"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="brand_name"
                                            placeholder="Brand Name"
                                        />
                                        <ControlledInputText
                                            label="Brand Code"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="brand_code"
                                            placeholder="Brand Code"
                                        />
                                        <BrandTypeSelect
                                            label="Brand Type"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="brand_type"
                                            placeholder="Brand Type"
                                        />
                                        <IsPopularSelect
                                            label="Is Popular"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="is_popular"
                                            placeholder="Is Popular"
                                        />
                                        <IsActiveSelect
                                            label="Is Active"
                                            labelCol={{ span: 24 }}
                                            control={control}
                                            name="is_active"
                                            placeholder="Is Active"
                                        />
                                    </Form>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default BrandEdit;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity, VariableEntity } from "modules/manufacture/utils/models";
import { ReactNode, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export type WrapperCardEffectProps = {
    attach?: Entity<MaterialEntity | VariableEntity>;
    removeChildren?: (data: Entity) => void;
    index?: number;
    onMoveChild?: (hoverIndex: number, item: Entity<MaterialEntity | VariableEntity>) => void;
    isOverOldItem?: boolean;
    totalChild?: number;
    indexEffectedItem?: number[];
    children: (value: any) => ReactNode;
};

const NORMAL_DENS_CARD = 55;
const LOW_DENS_CARD = 40;
const HIGH_DENS_CARD = 30;

const BREAK_POINT_DENS = {
    "1": {
        height: NORMAL_DENS_CARD,
        hoverHeight: NORMAL_DENS_CARD * 2 - 20,
    },
    "5": {
        height: LOW_DENS_CARD,
        hoverHeight: LOW_DENS_CARD * 2 + 10,
    },
    "10": {
        height: HIGH_DENS_CARD,
        hoverHeight: HIGH_DENS_CARD * 3,
    },
};

const calculateDens = (totalChild: number) => {
    const breakpoints = Object.keys(BREAK_POINT_DENS)
        .map((key) => Number(key))
        .reverse();
    const breakpoint = breakpoints.find((bp) => totalChild > bp);
    return BREAK_POINT_DENS[String(breakpoint || 1) as keyof typeof BREAK_POINT_DENS];
};

const WrapperCardEffect = ({
    removeChildren,
    onMoveChild,
    isOverOldItem,
    totalChild = 0,
    index = 0,
    children,
    indexEffectedItem,
    attach,
}: WrapperCardEffectProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop({
        accept: MenuTypes.material.type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor) {
            if (!ref.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            if (onMoveChild && dragIndex !== undefined) {
                onMoveChild(hoverIndex, item);
            }
            item.index = hoverIndex;
        },
    });

    const [{ isDragging, itemDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.material.type,
            item: { ...MenuTypes.material, ...attach },
            end: (item, monitor) => {
                if (!monitor.didDrop() && removeChildren) {
                    removeChildren(item);
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                itemDragging: monitor.getItem(),
            }),
        }),
        [removeChildren]
    );

    drag(drop(ref));

    const heightCard = calculateDens(totalChild);

    const whileHover = () => {
        if (index === 0 && totalChild === 1) return {};
        return {
            height: isOverOldItem ? NORMAL_DENS_CARD : heightCard.hoverHeight,
        };
    };

    const initialState = () => {
        let state = {
            height: isOverOldItem ? NORMAL_DENS_CARD : heightCard.height,
        };
        const effects = [
            { x: "-30px", scale: 1.2 },
            { x: "-50px", scale: 1.3, marginTop: 20, marginBottom: 20 },
            { x: "-30px", scale: 1.2 },
        ];
        const findIndexEffected = indexEffectedItem?.findIndex((val: any) => val === index) || 0;
        const effect = findIndexEffected < 0 ? { x: 0, scale: 1 } : effects[findIndexEffected];

        if (totalChild > 2) {
            state = {
                ...state,
                ...effect,
            };
        }

        if (!isOverOldItem) {
            state = {
                height: state.height,
            };
        }

        return state;
    };

    const isDragOutsideToRemove = itemDragging && itemDragging?.idEntity === attach?.idEntity && !isOverOldItem;

    const value = {
        isDragOutsideToRemove,
        handlerId,
        isDragging,
        ref,
    };

    return (
        <motion.div
            style={{ zIndex: 1 + index, overflow: "visible" }}
            initial={initialState()}
            animate={initialState()}
            className={clsx("w-full overflow-y-visible relative")}
        >
            <motion.div whileHover={whileHover()} className={clsx("!absolute bottom-0 left-0 w-full")}>
                {children(value)}
            </motion.div>
        </motion.div>
    );
};

export default WrapperCardEffect;

/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React from "react";
import { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { SchemaFormBox } from "../modal/add-box";

export type TableBoxType = SchemaFormBox & {
    detail: ProductPOM[];
    id: any;
    no?: number;
};

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function addTable<T extends TableBoxType>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<TableBoxType | null>(null);

        const columns: ColumnType<TableBoxType>[] = [
            {
                title: "No",
                dataIndex: "no",
                render: (t, r, i) => i + 1,
            },
            {
                title: "Box No",
                dataIndex: "box_no",
            },
            {
                title: "Machine No",
                dataIndex: "machine_no",
            },
            {
                title: "Operator",
                dataIndex: "operator_name",
            },
            {
                title: "Product Code",
                render: (val, record) => (
                    <div className="flex flex-col gap-1">
                        {record?.detail?.map((product) => (
                            <p className="m-0">{product?.product_code1}</p>
                        ))}
                    </div>
                ),
            },
            {
                title: "Qty",
                render: (val, record) => (
                    <div className="flex flex-col gap-1">
                        {record?.detail?.map((product) => (
                            <p className="m-0">{product?.qty_sisa}</p>
                        ))}
                    </div>
                ),
            },
        ];

        const isEditing = (record: TableBoxType, edited: TableBoxType | null) => record.id === edited?.id && record.id === edited?.id;
        const findIndexSave = (record: TableBoxType, edited: TableBoxType) => record.id === edited?.id && record.id === edited.id;
        const rowKey = (record: TableBoxType) => `${record.id}_${record?.id}`;

        const editInputType: EditTableProps<TableBoxType>["editInputType"] = {
            //
        };

        return (
            <Component
                {...props}
                bordered
                list={list as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const TableBox = addTable(EditTable);
export default TableBox;

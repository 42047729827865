import { Button, Card, message, Select, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import purchaseOrderExtService, { CreatePOExt } from "services/api-endpoints/dashboard-jsl/purchase-order-ext";
import State from "components/common/state";
import AdvanceSearch from "modules/advance-search";
import { MdAdd } from "react-icons/md";
import { ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { TDataProductSub } from "modules/dashboard-jsl/purchase-order/models";
import ProductTable from "modules/dashboard-jsl/purchase-order/add/product-table";
import { parseProductsToCreate, parseProductsToCreateExt } from "modules/dashboard-jsl/purchase-order/utils";
import { useNavigate } from "react-router-dom";
import { getDocumentRoute } from "utils/routes";
import { Product, SelectOption } from "models";
import { ProductWithQty } from "modules/advance-search/models";

const PurchaseOrderAddExt = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [bpId, setBpId] = useState<number | null>(null);

    const getHeaderPOQuery = useQuery([purchaseOrderExtService.getHeaderPO], async () => {
        return (await purchaseOrderExtService.GetHeaderPO()).data.data;
    });

    const draftPoMutation = useMutation(
        [purchaseOrderExtService.draftPO],
        async (data: CreatePOExt) => {
            return (await purchaseOrderExtService.DraftPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order draft created");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const createPoMutation = useMutation(
        [purchaseOrderExtService.createPO],
        async (data: CreatePOExt) => {
            return (await purchaseOrderExtService.CreatePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order created");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const requestBpList = useQuery([purchaseOrderExtService.listBp], async () => {
        return (await purchaseOrderExtService.ListBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const onChangeWarehouse = (value: number) => {
        setBpId(value);
    };

    const onSaveHandler = () => {
        createPoMutation.mutate(parseProductsToCreateExt(bpId, products));
    };

    const onDraftHandler = () => {
        draftPoMutation.mutate(parseProductsToCreateExt(bpId, products));
    };

    const onRemoveProduct = (product: ProductSub | TDataProductSub) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new purchase order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={draftPoMutation.isLoading}
                            disabled={products.length === 0 || bpId === null}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createPoMutation.isLoading}
                            disabled={products.length === 0 || bpId === null}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={getHeaderPOQuery.data} isLoading={getHeaderPOQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{getHeaderPOQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <Select
                                                value={bpId}
                                                className="!w-[200px]"
                                                loading={requestBpList.isLoading}
                                                options={requestBpList.data || []}
                                                onChange={onChangeWarehouse}
                                                showSearch
                                                placeholder="Request From"
                                            />
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={products} setList={setProducts} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default PurchaseOrderAddExt;

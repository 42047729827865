/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import bankAccountService, { CreateBankAccount } from "services/api-endpoints/accounting/bank-account";
import { AccType } from "services/api-endpoints/accounting/master-coa";

type SelectOptionCustom = SelectOption & AccType;

export interface Props extends ControlledInputSelectProps<CreateBankAccount> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withOrganization<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([bankAccountService.getOrg], async () => {
            const req = await bankAccountService.GetOrg();
            return req.data.data?.map((el) => ({ label: el?.organization_name, value: el.organization_id } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.type_id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const OrganizationSelect = withOrganization(ControlledSelectInput);

export default OrganizationSelect;

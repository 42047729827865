import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ModalConfirm from "components/modal/confirm_modal";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import Print from "modules/master-data/purchasing/print-request";
import TableViewRequest from "modules/purchasing/request/table-view-item";
import HistoryStatusRequest from "modules/purchasing/request/view/history-status";
import JourneyPORequest from "modules/purchasing/request/view/journey-po";
import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlinePrinter } from "react-icons/ai";
import { BiMessageSquareCheck } from "react-icons/bi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import purchasingRequestService, { ApprovedData, ChildRequest, CreatePurchasingRequest } from "services/api-endpoints/purchasing/purchasing-request";
import {
    DOC_TYPE_PURCHASING,
    STATUS_PURCHASING_APPROVED,
    STATUS_PURCHASING_REJECTED,
    STATUS_PURCHASING_REQUEST,
    STATUS_PURCHASING_REQUEST_APPROVED,
    STATUS_PURCHASING_REQUEST_NEW,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePurchasingRequest>> = yup.object().shape({
    doc_type: yup.number().required("Purchasing Category required"),
    note: yup.string(),
    child: yup.array(),
});

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const PurchasingRequestView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const { approveAccess } = useContext(StateContext);
    const letterRef = useRef<HTMLDivElement | null>(null);

    const [items, setItems] = useState<ChildRequest[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<CreatePurchasingRequest>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Purchasing Request - ${id}`,
        pageStyle: "@page {  }",
    });

    const watchType = watch("doc_type");

    const detailQuery = useQuery(
        [purchasingRequestService.detailRequest, id],
        async () => {
            return (await purchasingRequestService.DetailRequest({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("doc_type", data.doc_type || 0);
                setItems(data.child || []);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const approvedMutation = useMutation(
        [purchasingRequestService.approvedRequest],
        async (data: ApprovedData) => {
            return (await purchasingRequestService.ApprovedRequest(data)).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                message.success("Success Action Request");
            },
        }
    );

    const voidMutation = useMutation(
        [purchasingRequestService.void],
        async () => {
            return (await purchasingRequestService.Void({ id })).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                message.success("Success Void PR");
            },
        }
    );

    const onApprove = (value: string) => {
        const data: ApprovedData = {
            id,
            note: value,
            type_approved: STATUS_PURCHASING_APPROVED,
        };
        approvedMutation.mutate(data);
    };

    const onReject = (value: string) => {
        const data: ApprovedData = {
            id,
            note: value,
            type_approved: STATUS_PURCHASING_REJECTED,
        };
        approvedMutation.mutate(data);
    };

    const onVoid = () => {
        voidMutation.mutate();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail purchasing request"
                rightAddition={() => (
                    <>
                        <Space>
                            <Button onClick={() => handlePrintLetter()} type="default" className="!flex !items-center">
                                <AiOutlinePrinter className="m-0 mr-2" />
                                Print Document
                            </Button>
                            {detailQuery.data?.doc_status === STATUS_PURCHASING_REQUEST_NEW && approveAccess && (
                                <>
                                    <ModalNote title="Approved Purchasing Request" onSubmit={onApprove}>
                                        {(dt) => (
                                            <Button
                                                type="primary"
                                                className="!flex !items-center"
                                                htmlType="button"
                                                onClick={dt.openModal}
                                                loading={approvedMutation.isLoading}
                                            >
                                                <BiMessageSquareCheck className="m-0 mr-2" />
                                                Approve Purchasing Request
                                            </Button>
                                        )}
                                    </ModalNote>
                                    <ModalNote title="Approved Purchasing Request" onSubmit={onReject}>
                                        {(dt) => (
                                            <Button
                                                danger
                                                className="!flex !items-center"
                                                htmlType="button"
                                                onClick={dt.openModal}
                                                loading={approvedMutation.isLoading}
                                            >
                                                <IoMdCloseCircleOutline className="m-0 mr-2" />
                                                Reject Purchasing Request
                                            </Button>
                                        )}
                                    </ModalNote>
                                </>
                            )}
                            {detailQuery.data?.doc_status === STATUS_PURCHASING_REQUEST_APPROVED && (
                                <ModalConfirm title="Void PR" value={id} subTitle="Anda yakin ingin void PR ini" onSubmit={onVoid}>
                                    {(dt) => (
                                        <Button onClick={dt.openModal} danger className="!flex !items-center" loading={voidMutation.isLoading}>
                                            <IoMdCloseCircleOutline className="m-0 mr-2" />
                                            Void PR
                                        </Button>
                                    )}
                                </ModalConfirm>
                            )}
                        </Space>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Inventory Information</h1>
                                </div>
                                <Form form={form} layout="vertical">
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700">
                                                document no <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.pr_no}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Purchasing Category <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {DOC_TYPE_PURCHASING.find((val) => val.value === detailQuery.data?.doc_type)?.label}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Request By <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.request_by}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Note <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{detailQuery.data?.note}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                Status <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {STATUS_PURCHASING_REQUEST.find((val) => val.value === detailQuery.data?.doc_status)?.label}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Card>
                <Tabs
                    items={[
                        {
                            label: `Items`,
                            key: "items",
                            children: <TableViewRequest list={items} loading={detailQuery.isLoading} />,
                        },
                        {
                            label: `History Status`,
                            key: "history_status",
                            children: <HistoryStatusRequest />,
                        },
                        {
                            label: `Journey PO`,
                            key: "journey_po",
                            children: <JourneyPORequest id={id} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default PurchasingRequestView;

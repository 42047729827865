/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Input, Space, Table, Tabs, TabsProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ToolbarAction from "components/toolbar/action";
import ModalAddKoli from "modules/master-data/consignment/packing-list/modal-add-koli";
import React from "react";
import { BiPlus, BiSave } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import cPackingListService, { CreatePackingList, GetPickingDetail, ProductCPD } from "services/api-endpoints/dashboard/master-data/c-packing-list";

export type ProductCPDWithScan = ProductCPD & {
    group_koli: {
        total_scan: number;
        no_koli?: string;
    }[];
};

export type Koli = {
    name: string;
    total_scan: number;
    total_product?: number;
};

export const ADD_KOLI_STR = "tambah.koli";
export const SAVE_KOLI_STR = "save.koli";

const CPackingListAdd = () => {
    const navigate = useNavigate();
    const [productPD, setProductPD] = React.useState<ProductCPDWithScan[]>([]);
    const [packingDetail, setPackingDetail] = React.useState<GetPickingDetail[]>([]);
    const [tab, setTab] = React.useState<"product" | "koli">("product");
    const [query, setQuery] = React.useState<string>("");
    const addKoliButtonRef = React.useRef<HTMLButtonElement>(null);

    const koliList = productPD
        .reduce((arr: Koli[], curr) => {
            const kolis = curr.group_koli;
            return [...arr, ...kolis.map((koli) => ({ name: koli.no_koli, total_scan: koli.total_scan } as Koli))];
        }, [])
        .reduce((arr: Koli[], curr) => {
            if (!arr.find((koli) => koli.name === curr.name)) {
                return [...arr, { ...curr, total_product: 1 }];
            }
            return arr.map((koli) => {
                if (koli.name !== curr.name) return koli;
                return {
                    ...koli,
                    total_product: (koli.total_product || 0) + 1,
                    total_scan: koli.total_scan + curr.total_scan,
                };
            });
        }, []);

    const sumQtyAndScan = productPD.reduce(
        (sum: { totalQty: number; totalScan: number }, curr) => {
            return {
                ...sum,
                totalQty: sum.totalQty + (curr.qty || 1),
                totalScan: sum.totalScan + curr.group_koli.reduce((sumKoli, koli) => koli.total_scan + sumKoli, 0),
            };
        },
        { totalQty: 0, totalScan: 0 }
    );

    const canCreate = sumQtyAndScan.totalQty === sumQtyAndScan.totalScan && !!packingDetail.length && !!productPD.length;

    const productDetailMutate = useMutation(
        [cPackingListService.getProductCPD],
        async (pdId: string) => {
            return (await cPackingListService.GetProductCPD({ pd_id: pdId })).data.data;
        },
        {
            onSuccess(data) {
                setProductPD(data.map((el) => ({ ...el, group_koli: [] })));
            },
        }
    );

    const pickingDetailMutate = useMutation(
        [cPackingListService.getPickingDetail],
        async (pdNo: string) => {
            return (await cPackingListService.GetPickingDetail({ pd_no: pdNo })).data.data;
        },
        {
            onSuccess(data) {
                if (!Object.keys(data).length) {
                    message.error("Document is Empty");
                    return;
                }
                if (packingDetail.find((el) => el.doc_no === data.doc_no)) {
                    message.error("Document already exists");
                    return;
                }
                if (!packingDetail.every((el) => el.account_id_to === data?.account_id_to)) {
                    message.error("Partner cannot be different!");
                    return;
                }
                setPackingDetail((prev) => {
                    const merge = [...prev, data];
                    productDetailMutate.mutate(merge.map((el) => el.id).join(","));
                    return merge;
                });
            },
        }
    );

    const createCPackingListMutate = useMutation(
        [cPackingListService.create],
        async (data: CreatePackingList) => {
            return (await cPackingListService.Create(data)).data.data;
        },
        {
            onSuccess(data) {
                message.success("Create Packing List Success");
                navigate(`/internal-order/packing-list/view?id=${data.id}`);
            },
        }
    );

    const onCreate = () => {
        const detailProducts = productPD.reduce((arr: ProductCPD[], curr) => {
            const list = curr.group_koli.map((koli) => ({
                ...curr,
                group_koli: null,
                no_koli: koli.no_koli,
                qty: koli.total_scan,
                price: curr.product_price,
                discount_price: (koli.total_scan / (curr.qty || 1)) * (curr.discount_price || 0),
            }));
            return [...arr, ...list];
        }, []);

        const createData: CreatePackingList = {
            account_id_to: packingDetail[0].account_id_to as number,
            child_document: packingDetail.map((el) => el.id) as number[],
            detail_product: detailProducts,
        };

        createCPackingListMutate.mutate(createData);
    };

    const onRemove = (row: GetPickingDetail) => {
        return () => {
            setPackingDetail((prev) => {
                const filter = prev.filter((el) => el.id !== row.id);
                if (filter.length) {
                    productDetailMutate.mutate(filter.map((el) => el.id).join(","));
                } else {
                    setProductPD([]);
                }
                return filter;
            });
        };
    };

    const onDeleteProduct = (item: ProductCPDWithScan) => {
        setProductPD((prev) => prev.filter((el) => el.product_code1 !== item.product_code1));
    };

    const onChangeTab = (key: any) => {
        setTab(key);
    };

    const onSaveKoli = (products: ProductCPDWithScan[]) => {
        setProductPD((prev) =>
            prev.map((pd) => {
                const sameProduct = products.find((p) => p.product_code1 === pd.product_code1);
                if (sameProduct) {
                    return { ...pd, ...sameProduct };
                }
                return pd;
            })
        );
    };

    const onResetScan = () => {
        setProductPD((prev) => prev.map((p) => ({ ...p, group_koli: [] })));
    };

    const onSearch = (value: string) => {
        setQuery("");
        if (value.includes(ADD_KOLI_STR)) {
            if (addKoliButtonRef.current) {
                addKoliButtonRef.current.click();
            }
            return;
        }
        if (value.includes(SAVE_KOLI_STR)) {
            const btnSave = document.querySelector("#save-koli-btn") as HTMLButtonElement;
            if (!btnSave) return;
            btnSave.click();
            return;
        }
        pickingDetailMutate.mutate(value.trim());
    };

    const onDeleteKoli = (koli: Koli) => {
        setProductPD((prev) => {
            return prev.map((product) => {
                return {
                    ...product,
                    group_koli: product.group_koli?.filter((kl) => kl.no_koli !== koli.name),
                };
            });
        });
    };

    React.useEffect(() => {
        let tempStr = "";
        let code = "";

        window.addEventListener("keyup", (e: any) => {
            const textInput = e.key || String.fromCharCode(e.keyCode);
            const targetName = e.target.localName;
            let str = "";
            if (textInput && textInput.length === 1 && targetName !== "input") {
                str = tempStr + textInput;
                if (code.split("")[code.length] === str) return;
                code += str;

                if (code.includes(ADD_KOLI_STR)) {
                    if (addKoliButtonRef.current) {
                        addKoliButtonRef.current?.click();
                    }
                    code = "";
                    tempStr = "";
                    str = "";
                    return;
                }

                if (code.includes(SAVE_KOLI_STR)) {
                    const btnSave = document.querySelector("#save-koli-btn") as HTMLButtonElement;
                    if (!btnSave) return;
                    btnSave.click();

                    code = "";
                    tempStr = "";
                    str = "";
                }
            }
        });
    }, []);

    const columnsDetail: ColumnsType<GetPickingDetail> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Partner Internal",
            dataIndex: "partner_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Button onClick={onRemove(record)} danger size="small">
                    Remove
                </Button>
            ),
        },
    ];

    const columnsProduct: ColumnsType<ProductCPDWithScan> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Scan Total",
            render: (_, record) => {
                const totalScan = record.group_koli.reduce((sum, curr) => sum + curr.total_scan, 0);
                const notCompleteScan = record.qty !== totalScan;
                return (
                    <p
                        title={notCompleteScan ? "1 item not scan yet" : ""}
                        className={`capitalize m-0 ${notCompleteScan ? "text-red-500 font-semibold" : ""}`}
                    >
                        {totalScan}
                    </p>
                );
            },
        },
        {
            title: "Koli",
            render: (_, record) => <p className="capitalize m-0">{record.group_koli.map((koli) => koli.no_koli).join(", ")}</p>,
        },
        {
            title: "Action",
            render: (_, record) => (
                <Button onClick={() => onDeleteProduct(record)} title="Delete row" type="text" danger>
                    <MdOutlineDeleteOutline className="text-lg" />
                </Button>
            ),
        },
    ];

    const columnsKoli: ColumnsType<Koli> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Koli",
            dataIndex: "name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Scan",
            dataIndex: "total_scan",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Product",
            dataIndex: "total_product",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Action",
            render: (_, record) => {
                const koliProducts = productPD.filter((el) => el.group_koli.find((koli) => koli.no_koli === record.name));
                return (
                    <Space>
                        <ModalAddKoli
                            title={`Edit ${record.name} koli`}
                            onSaveKoli={onSaveKoli}
                            productPD={productPD}
                            defaultKoliName={record.name}
                            defaultProductScan={koliProducts}
                        >
                            {(control) => (
                                <Button onClick={control.openModal} title="Edit row" type="text">
                                    <FiEdit className="text-lg" />
                                </Button>
                            )}
                        </ModalAddKoli>
                        <Button onClick={() => onDeleteKoli(record)} title="Delete row" type="text" danger>
                            <MdOutlineDeleteOutline className="text-lg" />
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const itemsTab: TabsProps["items"] = [
        {
            key: "product",
            label: "Product",
            children: (
                <Table
                    rowKey={(record) => record.product_code1!}
                    size="small"
                    loading={pickingDetailMutate.isLoading || productDetailMutate.isLoading}
                    columns={columnsProduct}
                    dataSource={productPD}
                    className="w-full"
                    pagination={false}
                />
            ),
        },
        {
            key: "koli",
            label: "Koli",
            children: (
                <Table
                    size="small"
                    rowKey={(record) => record.name}
                    columns={columnsKoli}
                    dataSource={koliList}
                    className="w-full"
                    pagination={false}
                />
            ),
        },
    ];

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new packing list"
                rightAddition={() => (
                    <Button
                        loading={createCPackingListMutate.isLoading}
                        onClick={onCreate}
                        disabled={!canCreate}
                        type="primary"
                        className="!flex !items-center"
                    >
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card>
                <div className="flex gap-3 w-full flex-col">
                    <Input.Search
                        onSearch={onSearch}
                        placeholder="Doc"
                        className="!w-[300px]"
                        value={query}
                        onChange={(val) => setQuery(val.target.value)}
                    />
                    <Table
                        rowKey={(record) => record.id!}
                        size="small"
                        loading={pickingDetailMutate.isLoading || productDetailMutate.isLoading}
                        columns={columnsDetail}
                        dataSource={packingDetail}
                        className="w-full"
                        pagination={false}
                    />
                </div>
            </Card>

            <Card>
                <Tabs
                    onChange={onChangeTab}
                    type="card"
                    items={itemsTab}
                    tabBarExtraContent={{
                        right: (
                            <Space>
                                <Button onClick={onResetScan} disabled={!sumQtyAndScan.totalScan} type="link">
                                    Reset Scan
                                </Button>
                                <ModalAddKoli onSaveKoli={onSaveKoli} productPD={productPD}>
                                    {(control) => (
                                        <Button ref={addKoliButtonRef} onClick={control.openModal} type="primary" className="!flex !items-center">
                                            <BiPlus className="text-lg text-white mr-1" />
                                            Koli
                                        </Button>
                                    )}
                                </ModalAddKoli>
                            </Space>
                        ),
                    }}
                />
            </Card>
        </div>
    );
};

export default CPackingListAdd;

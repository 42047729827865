import { Button, Card, Select, Table } from "antd";
import { SelectOption } from "models";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import voucherService, { City, Province } from "services/api-endpoints/dashboard/master-data/voucher";
import RegionTable from "./region-table";

const createKey = (data: City) => data.city_id;

type Props = {
    selectedCity: City[];
    setSelectedCity: React.Dispatch<React.SetStateAction<City[]>>;
};

const RegionPicker = ({ selectedCity, setSelectedCity }: Props) => {
    const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);

    const getProvince = useQuery([voucherService.getDataCustomer, 2], async () => {
        return (await voucherService.GetProvince()).data.data;
    });

    const getCity = useQuery(
        [voucherService.getCity, selectedProvince?.province_id],
        async () => {
            return (await voucherService.GetCity({ province_id: selectedProvince?.province_id as any })).data.data;
        },
        {
            enabled: !!selectedProvince?.province_id,
        }
    );

    const getAllCity = useMutation([voucherService.getCity, 0], async () => {
        return (await voucherService.GetCity({ province_id: 0 })).data.data;
    });

    const onChangeProvince = (key: number) => {
        const province = getProvince.data?.list.find((el) => el.province_id === key);
        setSelectedProvince(province || null);
        setSelectedCity([]);
    };

    const onChangeCity = (keys: number[]) => {
        const cities = getCity.data?.filter((city) => keys.find((key) => city.city_id === Number(key)));
        setSelectedCity(cities || []);
    };

    const onAllCityInProvince = () => {
        setSelectedCity(getCity.data || []);
    };

    const onAllCityInIndonesia = () => {
        getAllCity.mutateAsync().then((res) => {
            setSelectedProvince(null);
            setSelectedCity(res || []);
        });
    };

    const onDeselectAll = () => {
        setSelectedCity([]);
    };

    const onRemove = (city: City) => {
        return () => {
            setSelectedCity((prev) => [...prev].filter((ct) => ct.city_id !== city.city_id));
        };
    };

    const provinceOptions = getProvince.data?.list?.map((el) => ({ value: el.province_id, label: el.province_name } as SelectOption));
    const cityOptions = getCity.data?.map((el) => ({ value: el.city_id, label: el.city_name } as SelectOption));

    return (
        <Card className="!bg-gray-50">
            <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center gap-4 w-full">
                    <Select
                        value={selectedProvince?.province_id}
                        onChange={onChangeProvince}
                        options={provinceOptions}
                        loading={getProvince.isLoading}
                        showSearch
                        placeholder="Pick province"
                        className="w-[300px]"
                    />
                    <Select
                        disabled={!selectedProvince}
                        value={selectedCity.map((city) => createKey(city))}
                        onChange={onChangeCity}
                        loading={getCity.isLoading}
                        showSearch
                        mode="multiple"
                        placeholder="Pick cities"
                        maxTagCount="responsive"
                        className="w-full"
                        options={cityOptions}
                    />
                    {selectedProvince ? (
                        <Button disabled={getCity.isLoading} onClick={onAllCityInProvince}>
                            All city in province
                        </Button>
                    ) : null}
                    <Button loading={getAllCity.isLoading} onClick={onAllCityInIndonesia}>
                        All city in indonesia
                    </Button>
                    {selectedCity.length !== 0 ? (
                        <Button onClick={onDeselectAll} danger>
                            Deselect All
                        </Button>
                    ) : null}
                </div>
                <RegionTable loading={getAllCity.isLoading} list={selectedCity} onRemove={onRemove} />
            </div>
        </Card>
    );
};

export default RegionPicker;

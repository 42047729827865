import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterInventoryOut from "modules/dashboard-jsl/inventory-out/index/filter";
import InventoryOutTable from "modules/dashboard-jsl/inventory-out/index/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import inventoryOutService from "services/api-endpoints/dashboard-jsl/inventory-out";

const filter = ["doc_no", "requester", "status", "start_date", "end_date"];

const InventoryOut = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getAll = useQuery([inventoryOutService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await inventoryOutService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await inventoryOutService.Filter(filterValue as any)).data.data;
        return (await inventoryOutService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterInventoryOut filter={filter}>
                {(dt) => <Toolbar placeholder="Doc No / Requester" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterInventoryOut>
            <InventoryOutTable fetcher={getAll} />
        </div>
    );
};

export default InventoryOut;

import { Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import { STATUS_POPULAR } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBrand = ({ filter = [], ...props }: Props) => {
    const brandNameQuery = useQuery([brandService.suggestBrandName], async () => {
        const req = await brandService.SuggestBrandName({ brand_name: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const brandCodeQuery = useQuery([brandService.suggestBrandCode], async () => {
        const req = await brandService.SuggestBrandCode({ brand_code: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const brandTypeQuery = useQuery([brandService.suggestBrandType], async () => {
        const req = await brandService.SuggestBrandType();
        return req.data.data?.map((el) => ({ label: el?.type_name || "Any", value: el.brand_type || "" } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Brand"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.brand_name}
                            loading={brandNameQuery.isLoading}
                            placeholder="Brand Name"
                            options={brandNameQuery.data || []}
                            onChange={(value) => dt.setOneValue("brand_name", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.brand_code}
                            loading={brandCodeQuery.isLoading}
                            placeholder="Brand Code"
                            options={brandCodeQuery.data || []}
                            onChange={(value) => dt.setOneValue("brand_code", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.brand_type}
                            loading={brandTypeQuery.isLoading}
                            placeholder="Brand Type"
                            options={brandTypeQuery.data || []}
                            onChange={(value) => dt.setOneValue("brand_type", value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_popular !== null && dt.values.is_popular !== undefined
                                    ? Number(dt.values.is_popular)
                                    : dt.values.is_popular
                            }
                            placeholder="Popular"
                            options={STATUS_POPULAR}
                            onChange={(value) => dt.setOneValue("is_popular", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBrand;

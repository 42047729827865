import { Button, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { END_DATE, START_DATE } from "pages/dashboard-pos";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { DetailTopCustomerList, InvoiceProduct, TopCustomerData } from "services/api-endpoints/dashboard-giias/report";
import InvoiceOrderDetailModal from "./invoice-order-detail-modal";
import { ORDER_CANCELED } from "utils/constant";

type Props = ModalProps & {
    item: TopCustomerData;
};

const ModalTopCustomer = ({ item, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get(START_DATE);
    const endDate = searchParams.get(END_DATE);

    const getInvoiceReportQuery = useQuery(
        [reportService.detailTopCustomer, startDate, endDate, item.customer_id],
        async () => {
            return (await reportService.DetailTopCustomer({ end_date: endDate, start_date: startDate, customer_id: item.customer_id })).data.data;
        },
        {
            enabled: modalOpen,
        }
    );

    const columns: ColumnType<DetailTopCustomerList>[] = [
        {
            width: "50px",
            title: "No",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text, record) => (
                <InvoiceOrderDetailModal item={{ id: record.id, inv_no: record.inv_no } as InvoiceProduct}>
                    {(ctrl) => (
                        <Button onClick={ctrl.openModal} size="small" danger={record.status === ORDER_CANCELED}>
                            {text}
                        </Button>
                    )}
                </InvoiceOrderDetailModal>
            ),
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Total Price",
            dataIndex: "total_price",
            render: (text) => <p className="capitalize m-0">{(text as number)?.ToIndCurrency("Rp")}</p>,
        },
    ];

    const onCancelModal = () => {
        closeRef.current?.click();
        searchParams.delete("page");
        setSearchParams(searchParams);
    };

    return (
        <ModalTemplate
            {...props}
            title={item.customer_name}
            onCancel={onCancelModal}
            isModalOpenFn={(isOpen) => {
                setModalOpen(isOpen);
            }}
            destroyOnClose
            width={700}
            handlerInComponent={children}
            footer={false}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        rowKey={(val) => val.id!}
                        loading={getInvoiceReportQuery.isLoading}
                        size="small"
                        dataSource={getInvoiceReportQuery.data || []}
                        columns={columns}
                        pagination={{
                            total: getInvoiceReportQuery.data?.length,
                            pageSize: 20,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalTopCustomer;

import { Button } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { rekapOnlineAccountingToExcel } from "modules/master-data/rekap/download/online-accounting-utils";
import FilterOnline from "modules/master-data/rekap/filter/filter-online";
import TableIndexOnlineAccounting from "modules/master-data/rekap/table-index-online-accounting";
import { FaDownload } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import rekapService from "services/api-endpoints/dashboard/master-data/rekap";

const filter = ["mp_id", "order_status", "start_date", "end_date"];

const RekapOnlineAccounting = () => {
    const [searchParams] = useSearchParams();

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [rekapService.getOnline, searchValue, filterValue, isFilter, isSearch],
        async () => {
            if (isSearch) return (await rekapService.GetOnline({ query: searchValue.query } as any)).data.data;
            return (await rekapService.GetOnline(filterValue as any)).data.data;
        },
        {
            enabled: !!filterValue.start_date || !!filterValue.end_date || !!searchValue.query,
        }
    );

    const onDownloadRekap = () => {
        const dateFilter = `${filterValue.start_date?.split("-").join("")}_${filterValue.end_date?.split("-").join("")}`;
        rekapOnlineAccountingToExcel({
            title: `Rekap Penjualan - Online (Accounting) (${dateFilter})`,
            list: getList.data || [],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterOnline filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Invoice No"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <Button
                                loading={getList.isLoading}
                                disabled={!getList.data?.length}
                                type="primary"
                                className="!flex !items-center w-fit"
                                onClick={onDownloadRekap}
                            >
                                <FaDownload className="mr-2" />
                                Download
                            </Button>
                        )}
                    />
                )}
            </FilterOnline>
            <TableIndexOnlineAccounting fetcher={getList} />
        </div>
    );
};

export default RekapOnlineAccounting;

/* eslint-disable no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, Skeleton, Space, message } from "antd";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import InputFile from "components/form/inputs/input-file";
import InputText from "components/form/inputs/input-text";
import ToolbarAction from "components/toolbar/action";
import useControlFile from "hooks/useControlFile";
import useProgressUpload from "hooks/useProgressUpload";
import { AllBrand, AllModel } from "models";
import AttributeSelect from "modules/master-data/product/lib/attribute-select";
import BrandSelect from "modules/master-data/product/lib/brand-select";
import CategorySelect from "modules/master-data/product/lib/category-select";
import ModelSelect from "modules/master-data/product/lib/model-select";
import StyleSelect from "modules/master-data/product/lib/style-select";
import SubAttributeSelect from "modules/master-data/product/lib/subattribute-select";
import SubCategorySelect from "modules/master-data/product/lib/subcategory-select";
import UomSelect from "modules/master-data/product/lib/uom-select";
import { FCreateDataProduct, FEditDataProduct, TempData } from "modules/master-data/product/models";
import { generateSKU } from "modules/master-data/product/utils";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AllAttribute } from "services/api-endpoints/dashboard/master-data/attribute";
import { AllCategory } from "services/api-endpoints/dashboard/master-data/category";
import productService from "services/api-endpoints/dashboard/master-data/product";
import { AllStyle } from "services/api-endpoints/dashboard/master-data/style";
import { AllSubAttribute } from "services/api-endpoints/dashboard/master-data/sub-attribute";
import { AllSubCategory } from "services/api-endpoints/dashboard/master-data/sub-category";
import { CATEGORY_PRODUCT, IMAGE_TYPES, STATUS_ACTIVE, TYPE_ITEM_INVENTORY } from "utils/constant";
import * as yup from "yup";

const supporter = ["image1", "image2", "image3", "image4", "image5", "brand", "category", "category_type"] as const;
type Supporter = typeof supporter;

const schema: yup.SchemaOf<Partial<FCreateDataProduct>> = yup.object().shape({
    type: yup.number().required("Product Type Required"),
    model_id: yup.array().required("Model required"),
    subcategory_id: yup.string().required("Sub Category required"),
    style_id: yup.number().required("Style required"),
    attribute_id: yup.number().required("Attribute required"),
    subattribute_id: yup.number().required("Sub Attribute required"),
    code1: yup.string().required("Product Code required"),
    molding_code: yup.string().required("Molding Code required"),
    name: yup.string().required("Product Name required"),
    description: yup.string().nullable(),
    uom_id: yup.number().required("UOM required"),
    is_active: yup.number().required("Status required"),
    image1: yup.string(),
    brand: yup.string().required("Brand required"),
    category: yup.string().required("Category required"),
    category_type: yup.string().required("Category type required"),
    sku: yup.string(),
    image2: yup.string(),
    image3: yup.string(),
    image4: yup.string(),
    image5: yup.string(),
    images: yup.array(),
});

const ProductEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [progress, onUploadProgress] = useProgressUpload();
    const [tempData, setTempData] = React.useState<TempData>({});

    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors },
        watch,
    } = useForm<FCreateDataProduct>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const category = watch("category");
    console.log(category);

    const [controlFile] = useControlFile<FCreateDataProduct>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const editMutation = useMutation(
        [productService.edit, id],
        async (data: FEditDataProduct) => {
            return (await productService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess() {
                message.success("Product Edited");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: FEditDataProduct = {
            id: id as any,
            ...data,
            images: [data.image1, data.image2, data.image3, data.image4, data.image5].filter((el) => el),
        };
        Object.keys(parseData).forEach((key: any) => {
            if (supporter.includes(key)) {
                delete parseData[key as keyof FEditDataProduct];
            }
        });
        editMutation.mutate(parseData);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const brandSelected = (brand: AllBrand | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            brand,
        }));
    };

    const modelSelected = (id: number, model: AllModel[]) => {
        const selectedModels = getValues("model_id");
        const models = model.filter((el) => selectedModels?.includes(el.id as any));
        setTempData((prev) => ({
            ...prev,
            models,
        }));
    };

    const categoryTypeSelected = (category: AllCategory | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            category,
        }));
    };

    const subCategorySelected = (subCategory: AllSubCategory | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            subCategory,
        }));
    };

    const styleSelected = (style: AllStyle | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            style,
        }));
    };

    const attributeSelected = (attribute: AllAttribute | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            attribute,
        }));
    };

    const subAttributeSelected = (subAttribute: AllSubAttribute | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            subAttribute,
        }));
    };

    const categorySelected = (id: any) => {
        form.setFieldsValue({
            category_type: "",
            subcategory_id: "",
        });
        setValue("category_type", "");
        setValue("subcategory_id", "");
        setTempData((prev) => ({
            ...prev,
            subCategory: null,
        }));
    };

    const detailQuery = useQuery(
        [productService.detail, id],
        async () => {
            return (await productService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: async (data) => {
                const { images } = data;
                form.setFieldsValue({
                    ...data,
                    model_id: data?.model?.map((model) => model?.id),
                    category: CATEGORY_PRODUCT.find((cat) => cat.label === data?.category_name)?.value,
                    category_type: data?.category_id,
                    image1: images ? images[0] : "",
                    image2: images ? images[1] : "",
                    image3: images ? images[2] : "",
                    image4: images ? images[3] : "",
                    image5: images ? images[4] : "",
                });
                setValue("type", data.type || 3);
                setValue("brand", Number(data?.brand_code) as any);
                setValue("model_id", data?.model?.map((model) => model?.id) as any);
                setValue("category", CATEGORY_PRODUCT.find((cat) => cat.label === data?.category_name)?.value as any);
                setValue("category_type", data?.category_id as any);
                setValue("subcategory_id", data?.subcategory_id as any);
                setValue("style_id", data?.style_id as any);
                setValue("attribute_id", data?.attribute_id as any);
                setValue("subattribute_id", data?.subattribute_id as any);
                setValue("code1", data?.code1 as any);
                setValue("molding_code", data?.molding_code as any);
                setValue("name", data?.name as any);
                setValue("description", data?.description as any);
                setValue("is_active", data?.is_active as any);
                setValue("uom_id", data?.uom_id as any);
                setValue("sku", data?.sku as any);
                setValue("image1", images ? images[0] : "");
                setValue("image2", images ? images[1] : "");
                setValue("image3", images ? images[2] : "");
                setValue("image4", images ? images[3] : "");
                setValue("image5", images ? images[4] : "");
                setTempData({
                    brand: {
                        brand_code: data?.brand_code,
                    },
                    models: data?.model,
                    subCategory: {
                        subcategory_code: data?.subcategory_id?.toString().padStart(2, "0") as any,
                    },
                    style: {
                        style_code: data?.style_id?.toString().padStart(2, "0"),
                    },
                    attribute: {
                        attribute_code: data?.attribute_id?.toString().padStart(2, ""),
                    },
                    subAttribute: {
                        subattribute_code: data?.subattribute_code as any,
                    },
                });
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        const sku = generateSKU(tempData);
        form.setFieldValue("sku", sku);
        setValue("sku", sku);
    }, [tempData]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit product"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="grid gap-4 h-fit">
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="image1" className="col-span-2">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="header image" />}
                                        </InputFile>
                                        {["image2", "image3", "image4", "image5"].map((img) => (
                                            <InputFile key={img} control={controlFile} types={IMAGE_TYPES} name={img}>
                                                {(dt) => <ImageCard size="small" image={dt.file || dt.urlFile} title="Additional Image" />}
                                            </InputFile>
                                        ))}
                                    </div>
                                    <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">product literation</h1>
                                        <div className="w-full grid grid-cols-3 gap-x-4">
                                            <ControlledSelectInput
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="type"
                                                options={TYPE_ITEM_INVENTORY}
                                                placeholder="Product Type"
                                                label="Product Type"
                                            />
                                            <BrandSelect
                                                onSelectedData={brandSelected}
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="brand"
                                                placeholder="Brand Name"
                                                label="Brand Name"
                                                showSearch
                                            />
                                            <InputText value={tempData?.brand?.brand_code} disabled placeholder="Brand Code" label="Brand Code" />
                                            <ModelSelect
                                                onSelectedData={modelSelected}
                                                mode="multiple"
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="model_id"
                                                placeholder="Model Name"
                                                label="Model Name"
                                                showSearch
                                            />
                                            <InputText
                                                value={tempData?.models?.map((el) => el.model_code).join(", ")}
                                                disabled
                                                placeholder="Model Code"
                                                label="Model Code"
                                            />
                                            <ControlledSelectInput
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="category"
                                                options={CATEGORY_PRODUCT}
                                                placeholder="Category Name"
                                                label="Category Name"
                                                onSelect={categorySelected}
                                            />
                                            <CategorySelect
                                                onSelectedData={categoryTypeSelected}
                                                control={control}
                                                name="category_type"
                                                placeholder="Category Type"
                                                label="Category Type"
                                                showSearch
                                            />
                                            <SubCategorySelect
                                                onSelectedData={subCategorySelected}
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="subcategory_id"
                                                placeholder="Subcategory Name"
                                                label="Subcategory Name"
                                                showSearch
                                            />
                                            <InputText
                                                value={tempData?.subCategory?.subcategory_code}
                                                disabled
                                                placeholder="Subcategory Code"
                                                label="Subcategory Code"
                                            />
                                            <StyleSelect
                                                onSelectedData={styleSelected}
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="style_id"
                                                placeholder="Product Style"
                                                label="Product Style"
                                                showSearch
                                            />
                                            <InputText value={tempData?.style?.style_code} disabled placeholder="Style Code" label="Style Code" />
                                            <AttributeSelect
                                                onSelectedData={attributeSelected}
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="attribute_id"
                                                placeholder="Attribute"
                                                label="Attribute"
                                                showSearch
                                            />
                                            <InputText
                                                value={tempData?.attribute?.attribute_code}
                                                disabled
                                                placeholder="Attribute Code"
                                                label="Attribute Code"
                                            />
                                            <SubAttributeSelect
                                                onSelectedData={subAttributeSelected}
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="subattribute_id"
                                                placeholder="Sub Attribute"
                                                label="Sub Attribute"
                                                showSearch
                                            />
                                            <InputText
                                                value={tempData?.subAttribute?.subattribute_code}
                                                disabled
                                                placeholder="Subattribute Code"
                                                label="Subattribute Code"
                                            />
                                        </div>
                                        <h1 className="capitalize font-semibold text-gray-500 my-4">product information</h1>
                                        <div className="w-full grid grid-cols-3 gap-x-4">
                                            <ControlledInputText
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="name"
                                                placeholder="Product Name"
                                                label="Product Name"
                                            />
                                            <ControlledInputText control={control} name="code1" placeholder="Product Code" label="Product Code" />
                                            <div className="col-span-3 grid grid-cols-3 gap-x-4">
                                                <ControlledInputTextArea
                                                    classNameForm="col-span-2"
                                                    control={control}
                                                    rows={4}
                                                    name="description"
                                                    placeholder="Product Description"
                                                    label="Product Description"
                                                />
                                                <div className="">
                                                    <ControlledInputText
                                                        control={control}
                                                        name="molding_code"
                                                        placeholder="Molding Code"
                                                        label="Molding Code"
                                                    />
                                                    <UomSelect showSearch control={control} name="uom_id" placeholder="UOM" label="UOM" />
                                                </div>
                                            </div>
                                            <ControlledSelectInput
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="is_active"
                                                options={STATUS_ACTIVE}
                                                placeholder="Status"
                                                label="Status"
                                            />
                                            <ControlledInputText control={control} name="sku" placeholder="SKU" label="SKU" />
                                        </div>
                                    </Form>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 8 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default ProductEdit;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBpOrder from "modules/master-data/bp-order/index/filter";
import BpOrderTable from "modules/master-data/bp-order/index/table";
import { TDataBpOrder } from "modules/master-data/bp-order/models";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpOrderService from "services/api-endpoints/dashboard/master-data/bp-order";

const filter = ["order_no", "so_no", "status", "bp_name", "start_date", "end_date", "size"];

const BpOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("size") || 10;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bpOrderService.getAll, page, searchValue, filterValue, isFilter, isSearch, size], async () => {
        if (isSearch) return (await bpOrderService.Search({ page, query: searchValue.query, size })).data.data;
        if (isFilter) return (await bpOrderService.Filter(filterValue as any)).data.data;
        return (await bpOrderService.GetAll({ page, size })).data.data;
    });

    const deleteModelType = useMutation(async (data: TDataBpOrder) => (await bpOrderService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataBpOrder>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBpOrder filter={filter}>
                {(dt) => <Toolbar placeholder="Search..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBpOrder>
            <BpOrderTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default BpOrder;

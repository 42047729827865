import { Button, Card, Select, message } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import InternalModalProduct from "modules/master-data/internal-return-order/modal-product";
import InternalReturnProductTable from "modules/master-data/internal-return-order/table-add";
import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import internalReturnOrderService, {
    CreateInternalReturn,
    InternalReturnProduct,
} from "services/api-endpoints/dashboard/master-data/internal-return-order";
import { AUTOGENERATED, STOCK_ACTION } from "utils/constant";

const CReturnOrderAdd = () => {
    const navigate = useNavigate();

    const [reason, setReason] = useState<number | null>(null);
    const [products, setProducts] = useState<InternalReturnProduct[]>([]);

    const getHeader = useQuery([internalReturnOrderService.header], async () => {
        return (await internalReturnOrderService.Header()).data.data;
    });

    const getReason = useQuery([internalReturnOrderService.getReason], async () => {
        return (await internalReturnOrderService.GetReason()).data.data;
    });

    const getProduct = useQuery([internalReturnOrderService.getProduct], async () => {
        return (await internalReturnOrderService.GetProduct()).data.data?.map((el) => ({ ...el, label: el.product_name, value: el.product_id }));
    });

    const createMutate = useMutation([internalReturnOrderService.create], async (data: CreateInternalReturn) => {
        return (await internalReturnOrderService.Create(data)).data.data;
    });

    const onChangeReason = (value: number) => {
        setReason(value);
    };

    const onSaveHandler = async () => {
        // const noStockAction = products.find((itm) => !itm.stock_action);
        // if (noStockAction) {
        //     message.error(`Stock Action Required on ${noStockAction.product_name}`);
        //     return;
        // }

        const noQty = products.find((itm) => !itm.qty);
        if (noQty) {
            message.error(`Quantity Required on ${noQty.product_name}`);
            return;
        }

        const data: CreateInternalReturn = {
            reason_id: reason as number,
            return_order_detail: products.map((itm) => {
                return {
                    product_id: itm.product_id,
                    qty_return: itm.qty,
                    // stock_action: STOCK_ACTION.find((act) => act.label === itm.stock_action || act.value === itm.stock_action)?.value,
                    area_id: itm.area_id,
                } as any;
            }),
        };

        createMutate.mutateAsync(data).then(() => {
            message.success("Internal Return Order Created");
            navigate(-1);
        });
    };

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onSelectOrder = async (product: any) => {
        const p = { ...product, qty: 1 };
        setProducts((prev) => [...prev, p]);
    };

    const onDeSelectOrder = (product: any) => {
        setProducts((prev) => prev.filter((p) => p.product_id !== product.product_id));
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        setProducts((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id !== locator.product_id) return prd;
                return {
                    ...prd,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: Number(locator.qty_locator),
                };
            })
        );
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new Return Order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createMutate.isLoading}
                            disabled={products.length === 0 || !reason}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-3 gap-x-5">
                    <p className="capitalize m-0">
                        Document No <br />
                        <span className="underline m-0">{AUTOGENERATED}</span>
                    </p>
                    <p className="capitalize m-0">
                        Return To <br />
                        <span className="underline m-0">{getHeader.data?.parent_name || "-"}</span>
                    </p>
                    <div className="capitalize m-0">
                        Reason to return
                        <br />
                        <Select
                            loading={getReason.isLoading}
                            disabled={getReason.isLoading || getReason.isError}
                            value={reason}
                            className="!w-[300px]"
                            options={getReason.data?.map((r) => ({ label: r.reason_name, value: r.reason_id } as SelectOption))}
                            onChange={onChangeReason}
                            showSearch
                            optionFilterProp="label"
                            placeholder="Reason"
                        />
                    </div>
                </div>
            </Card>
            <InternalModalProduct
                getProductLoading={getProduct.isLoading}
                onSelectOrder={onSelectOrder}
                onDeSelectOrder={onDeSelectOrder}
                selected={products}
                products={getProduct.data}
            >
                {(dt) => (
                    <Button onClick={dt.openModal} className="!flex !items-center w-fit" type="primary">
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </InternalModalProduct>
            <InternalReturnProductTable list={products} setList={setProducts} removeItemList={onRemoveProduct} onSubmitLocator={onSubmitLocator} />
        </div>
    );
};

export default CReturnOrderAdd;

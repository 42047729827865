/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Checkbox, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TableRowSelection } from "antd/lib/table/interface";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BaseJurnalResponse, JurnalUmum, Type } from "services/api-endpoints/accounting/jurnal-umum";
import { FORMAT_DATE_TIME, ORDER_CANCELED, TYPE_JURNAL, TYPE_JURNAL_PAYMENT, TYPE_JURNAL_RECEIPT } from "utils/constant";
import JurnalProvider, { JurnalContext, JurnalContextType } from "../context/jurnal";
import { jurnalTableToExcel } from "../utils";
import ModalRefDetail from "modules/master-data/purchasing/model-ref-detail";

type Props<T> = {
    fetcher: UseQueryResult<BaseJurnalResponse<T>, unknown>;
    onPosJournal: (data: any[]) => void;
    typeJurnal: string;
    canEdit?: boolean;
};

const { confirm } = Modal;

const UnpostedTable = <T extends JurnalUmum>({ fetcher, onPosJournal, typeJurnal, canEdit }: Props<T>) => {
    const [searchParams] = useSearchParams();
    const startDateFilter = searchParams.get("start_date");
    const endDateFilter = searchParams.get("end_date");
    const { list, setList } = useContext(JurnalContext) as JurnalContextType<JurnalUmum>;

    const [canPos, setCanPos] = useState<Boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [selectAllJurnal, setSelectAllJurnal] = useState(false);

    useEffect(() => {
        setList(fetcher.data?.list_unposted || []);
        const canpos =
            !!fetcher.data?.list_unposted &&
            fetcher.data?.list_unposted?.length !== 0 &&
            fetcher.data?.list_unposted?.filter((el) => el.is_posting).length === fetcher.data?.list_unposted?.length;
        setCanPos(canpos);
    }, [fetcher.data]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const onClickPos = () => {
        confirm({
            content: "Anda Yakin untuk Post Jurnal?",
            onOk() {
                onPosJournal(list);
            },
        });
    };

    const onDownloadJurnal = () => {
        const dateFilter = `${startDateFilter?.split("-").join("")}_${endDateFilter?.split("-").join("")}`;
        jurnalTableToExcel({
            title: `${TYPE_JURNAL.find((val) => val.value === typeJurnal)?.label || ""}.${dateFilter}`,
            listJurnal: list,
        });
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            className: "h-[1px]",
            render: (text, record, i) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>
                </div>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>
                </div>
            ),
        },
        {
            title: "Account",
            dataIndex: "account",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0 font-semibold">{text || "-"}</p>
                </div>
            ),
        },
        {
            title: "Ref ID",
            dataIndex: "doc_no",
            className: "h-[1px]",
            render: (text, record) => {
                return (
                    <div className="w-full h-full items-start">
                        <div className="w-full mb-2">
                            <ModalRefVoucher title="Detail Document" id={record?.doc_id} type={record.doc_type} type_={record.type}>
                                {(ctrl) => (
                                    <Button onClick={ctrl.openModal} size="small" danger={record?.status === ORDER_CANCELED}>
                                        {text}
                                    </Button>
                                )}
                            </ModalRefVoucher>
                        </div>
                        {record.ref_id && (
                            <div className="w-full">
                                <ModalRefDetail title="Detail Referensi" id={record?.ref_id} type={record.ref_type}>
                                    {(ctrl) => (
                                        <Button onClick={ctrl.openModal} size="small" danger={record?.status === ORDER_CANCELED}>
                                            {record.ref_no}
                                        </Button>
                                    )}
                                </ModalRefDetail>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Account Name",
            className: "h-[1px]",
            render: (_, record) => {
                return (
                    <Space direction="vertical">
                        {record?.child?.map((item, i) => (
                            <JurnalProvider.AccountNameEdit item={item} record={record} key={i} />
                        ))}
                    </Space>
                );
            },
        },
        {
            title: "Debit",
            className: "h-[1px]",
            render: (_, record) => {
                return (
                    <Space direction="vertical">
                        {record?.child?.map((item, i) => (
                            <JurnalProvider.AmmountEdit itemIndex={i} type={Type.Debit} item={item} record={record} key={i} />
                        ))}
                    </Space>
                );
            },
        },
        {
            className: "h-[1px]",
            title: "Kredit",
            render: (_, record) => {
                return (
                    <Space direction="vertical">
                        {record?.child?.map((item, i) => (
                            <JurnalProvider.AmmountEdit itemIndex={i} type={Type.Kredit} item={item} record={record} key={i} />
                        ))}
                    </Space>
                );
            },
        },
        Table.SELECTION_COLUMN,
        {
            width: "90px",
            title: "Status",
            key: "status",
            fixed: "right",
            dataIndex: "is_posting",
            className: "",
            render: (_, record) => {
                if (record.is_acc_posted === 0) return <p className="mt-3">Unposted</p>;
                return (
                    <Checkbox checked disabled>
                        Posted
                    </Checkbox>
                );
            },
        },
    ];

    if (!columns.find((col) => col.key === "action") && canEdit) {
        columns.push({
            width: "90px",
            title: "Action",
            fixed: "right",
            key: "action",
            render: (_, record) => {
                if (String(record.doc_type) === TYPE_JURNAL_RECEIPT || String(record.doc_type) === TYPE_JURNAL_PAYMENT)
                    return <p className="text-red-500">Cant Edit</p>;
                return <JurnalProvider.ActionEdit refetchJournal={fetcher.refetch} record={record} />;
            },
        });
    }

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: (list?.filter((el) => el.is_posting).map((el) => el.id) as any[]) || [],
        onSelectAll(selected, selectedRows, changeRows) {
            setList((prev) => {
                const curr = prev?.map((el) => {
                    const filterRows = selectedRows.filter((val) => val !== undefined);
                    const rowId = (selected ? filterRows : changeRows).find((el2) => el2.id === el.id)?.id;
                    return el.id === rowId ? { ...el, is_posting: selected } : el;
                });
                setCanPos(curr.filter((el) => el.is_posting).length > 0);
                return curr;
            });
        },
        onSelect(record) {
            setList((prev) => {
                const curr = prev?.map((el) => (el.id === record.id ? { ...el, is_posting: !el.is_posting } : el));
                setCanPos(curr.filter((el) => !el.is_posting).length > 0);
                return curr;
            });
        },
    };

    const onChangeSelectAll = (e: any) => {
        const isChecked = e.target.checked;
        setList((prev) => {
            const curr = prev?.map((el) => ({ ...el, is_posting: isChecked }));
            setCanPos(curr.filter((el) => el.is_posting).length > 0);
            return curr;
        });
        setSelectAllJurnal(isChecked);
    };

    React.useEffect(() => {
        setSelectAllJurnal(list.filter((el) => el.is_posting).length === list.length);
    }, [list]);

    return (
        <div className="flex flex-col gap-5">
            <div className="w-full flex items-center justify-between">
                <div>
                    <Button onClick={onClickPos} disabled={!canPos} className="w-fit mr-2">
                        Post Jurnal ({list.filter((el) => el.is_posting).length})
                    </Button>
                    <Checkbox checked={selectAllJurnal} onChange={onChangeSelectAll} disabled={list.length === 0}>
                        All Jurnal
                    </Checkbox>
                </div>
                <Space>
                    <Button onClick={onDownloadJurnal} disabled={list.length === 0} className="w-fit">
                        <AiOutlineDownload className="m-0 mr-2" />
                        Download Jurnal
                    </Button>
                </Space>
            </div>
            <Table
                rowSelection={rowSelection}
                rowKey={(record) => record.id!}
                size="small"
                loading={fetcher.isLoading}
                columns={columns}
                dataSource={(list as T[]) || []}
                className="w-full"
                pagination={{
                    current: page,
                    pageSize: 20,
                    showSizeChanger: false,
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default UnpostedTable;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import { Popconfirm } from "antd";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, ModelEntity } from "modules/manufacture/utils/models";
import React, { memo } from "react";
import { useDrag } from "react-dnd";
import { AiOutlineDelete } from "react-icons/ai";
import { SlPuzzle } from "react-icons/sl";

type CardModelItemProps = React.HTMLProps<HTMLDivElement> & {
    attach?: Entity<ModelEntity>;
    onEdit?: () => void;
    onDelete?: () => void;
};

const CardModelItem = ({ className, onEdit, onDelete, attach, children, ...props }: CardModelItemProps) => {
    const { modelEdit } = React.useContext(ManufactureContext) as any;
    const [isHover, setIsHover] = React.useState(false);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.model.type,
            item: { asType: MenuTypes.model.type, ...attach, left: 0, top: 0 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    const classname = clsx(
        className,
        "hover:opacity-70 w-full items-center cursor-grab gap-3 flex flex-col shadow",
        isDragging && "filter grayscale"
    );

    return (
        <motion.div onHoverStart={() => setIsHover(true)} onHoverEnd={() => setIsHover(false)} className="relative">
            <p className="capitalize font-medium text-gray-500 text-sm m-0 whitespace-pre">{props.title}</p>
            {modelEdit && modelEdit === attach?.idEntity && (
                <div className="bg-white font-light rounded-tr-md absolute bottom-0 left-0 py-1 px-2 flex items-center gap-1">
                    <SlPuzzle />
                    edit
                </div>
            )}
            <motion.div
                animate={isHover && !modelEdit ? { opacity: 1 } : { opacity: 0 }}
                className="bg-white rounded-tr-md p-2 flex items-center gap-3 absolute z-10 bottom-0 left-0"
            >
                <button
                    onClick={() => onEdit && onEdit()}
                    title="Edit Model"
                    type="button"
                    className=" border-none text-gray-400 hover:text-gray-700 p-0 cursor-pointer flex items-center justify-center"
                >
                    <SlPuzzle className="text-xl" />
                </button>
                <Popconfirm onConfirm={() => onDelete && onDelete()} title="Delete this model?" okText="Yes" cancelText="No">
                    <button
                        title="Delete Model"
                        type="button"
                        className=" border-none text-gray-400 hover:text-red-400 p-0 cursor-pointer flex items-center justify-center"
                    >
                        <AiOutlineDelete className="text-xl" />
                    </button>
                </Popconfirm>
            </motion.div>
            <div ref={drag} className={classname} {...props}>
                {children}
            </div>
        </motion.div>
    );
};

export default memo(CardModelItem);

import { Button, Card, DatePicker, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import InputFile from "components/form/inputs/input-file";
import { BANNER_TYPE, FORMAT_DATE_1, IMAGE_TYPES, PRIORITY, STATUS_ACTIVE } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation } from "react-query";
import bannerService, { Banner } from "services/api-endpoints/dashboard/master-data/banner";
import useProgressUpload from "hooks/useProgressUpload";
import ControlledInputRichText from "components/form/controlled-inputs/controlled-input-richtext";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import moment from "moment";

const schema: yup.SchemaOf<Partial<Banner>> = yup.object().shape({
    title: yup.string().required("Banner title required"),
    description: yup.string().required("Banner description required"),
    position: yup.number().required("Banner position required"),
    type: yup.number().required("Banner type required"),
    link: yup.string().required("Banner link required"),
    active: yup.number().required("Banner active required"),
    start_date: yup.string().required("Banner effective date required"),
    end_date: yup.string().required("Banner effective required"),
    image: yup.string().required("Banner image required"),
});

const BannerAdd = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<Banner>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const startDateWatch = watch("start_date") ? moment(watch("start_date"), FORMAT_DATE_1) : null;
    const endDateWatch = watch("end_date") ? moment(watch("end_date"), FORMAT_DATE_1) : null;

    const [progress, onUploadProgress] = useProgressUpload();

    const [controlFile] = useControlFile<Banner>({
        setValue,
        errors: errors as any,
    });

    const createMutation = useMutation(
        [bannerService.create],
        async (data: Banner) => {
            return (await bannerService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Banner created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    const onChangeDatePricker = (key: keyof Banner, value: string) => {
        setValue(key, value);
        form.setFieldValue(key, value);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new banner"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-2 gap-4">
                    <InputFile control={controlFile} types={IMAGE_TYPES} name="image">
                        {(dt) => <ImageCard image={dt.file} title="banner image" />}
                    </InputFile>
                    <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">banner information</h1>
                        <ControlledInputText control={control} name="title" placeholder="Brand title" />
                        <ControlledInputRichText control={control} name="description" placeholder="Brand description" />
                        <ControlledSelectInput control={control} name="type" options={BANNER_TYPE} placeholder="Banner Type" />
                        <ControlledSelectInput control={control} name="position" options={PRIORITY} placeholder="Banner Position" />
                        <ControlledInputText control={control} name="link" placeholder="Brand link" />
                        <ControlledSelectInput control={control} name="active" options={STATUS_ACTIVE} placeholder="Banner Active" />
                        <div className="!w-full">
                            <DatePicker.RangePicker
                                allowClear
                                status={errors.start_date ? "error" : undefined}
                                className="w-full"
                                value={[startDateWatch, endDateWatch]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    onChangeDatePricker("start_date", val?.[0]?.format(FORMAT_DATE_1) || "");
                                    onChangeDatePricker("end_date", val?.[1]?.format(FORMAT_DATE_1) || "");
                                }}
                            />
                            {errors.start_date ? <p className="text-red-400">{errors.start_date?.message}</p> : null}
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default BannerAdd;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListPurchaseOrder {
    id: number;
    po_no: string;
    partner_name: string;
    order_status: number;
    created_at: string;
}

export interface RefInv {
    id: number;
    inv: string;
}

export interface ProductSalesOrder {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    references: RefInv[];
}

export interface DetailSalesOrder {
    id: number;
    doc_no: string;
    ref_no: string;
    ref_id?: number;
    ref_type: number;
    partner_name: string;
    order_status: number;
    created_at: string;
    note: string;
    list_detail: ProductSalesOrder[];
}

class CPurchaseOrderService extends BaseService {
    listAll = "/consignment/list-c-purchase-order";

    detail = "/consignment/detail-c-purchase-order";

    constructor() {
        super();
    }

    Detail<T extends DetailSalesOrder>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    ListAll<T extends ListPurchaseOrder[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAll,
            });
            return req;
        });
    }
}

const cPurchaseOrderService = new CPurchaseOrderService();
export default cPurchaseOrderService;

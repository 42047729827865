import { ManufactureContext, findEntity } from "modules/manufacture/context/context";
import RenderEntities from "modules/manufacture/draw/render-entities";
import { MenuTypes, getIdElementEntity } from "modules/manufacture/utils/constant";
import { Entity, OperatorEntity } from "modules/manufacture/utils/models";
import React, { useEffect } from "react";
import { useDrop } from "react-dnd";
import PlaceholderOperator from "./placeholder-operator";

type FirstOperatorProps = {
    entity?: Entity<OperatorEntity>;
};

const FirstOperator = ({ entity }: FirstOperatorProps) => {
    const { entities, dropOperatorChildren, models } = React.useContext(ManufactureContext) as any;

    const [{ isOver, itemDrop, candDrop }, drop] = useDrop(
        () => ({
            accept: [MenuTypes.subProcess.type, MenuTypes.operator.type, MenuTypes.model.type],
            drop(item: any) {
                dropOperatorChildren(item, "child1", entity, models);
                return undefined;
            },
            canDrop() {
                if (entity?.data?.child1) return false;
                return true;
            },
            collect(monitor) {
                return {
                    isOver: !!monitor.isOver({ shallow: true }),
                    itemDrop: monitor.getItem() as Entity,
                    candDrop: !!monitor.canDrop(),
                };
            },
        }),
        [entities, models]
    );

    useEffect(() => {
        const container = document.querySelector(getIdElementEntity(entity?.idEntity));
        if (isOver) {
            container?.classList.add("outline", "outline-gray-500");
        } else {
            container?.classList.remove("outline", "outline-gray-500");
        }
    }, [isOver]);

    const child = findEntity(entities, entity?.data?.child1);

    return (
        <div ref={drop} className="min-w-[80px] min-h-[50px] rounded bg-gray-300 shadow-inner relative">
            {isOver && candDrop ? <PlaceholderOperator entity={itemDrop} /> : null}
            <RenderEntities asChild entities={child ? [child] : []} />
        </div>
    );
};

export default FirstOperator;

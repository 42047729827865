/* eslint-disable no-useless-constructor */
import { SaveModels } from "modules/manufacture/context/context";
import BaseService from "services/api-endpoints/base";
import * as Models from "models";
import ApiMethod from "services/api-methods";

export interface CreateModelManufacture {
    id?: any;
    name?: string;
    is_active?: number;
    process?: SaveModels;
}

class ManufactureModelService extends BaseService {
    getAll = "/manufacture/list-model";

    create = "/manufacture/create-model";

    delete = "/manufacture/delete-model";

    constructor() {
        super();
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends CreateModelManufacture>(params: { param_search?: string }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateModelManufacture, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data: {
                    ...data,
                    process: JSON.stringify(data.process),
                },
                config,
            });
            return req;
        });
    }
}

const manufactureModelService = new ManufactureModelService();
export default manufactureModelService;

import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponse } from "models";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WorkingOrder } from "services/api-endpoints/dashboard/master-data/working-order";
import { VIEW_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const WorkingOrderTable = <T extends WorkingOrder>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: WorkingOrder) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "WO No",
            dataIndex: "wo_no",
        },
        {
            title: "Customer Name",
            dataIndex: "cust_name",
        },
        {
            title: "Phone",
            dataIndex: "phone_number",
        },
        {
            title: "Vehicle",
            dataIndex: "vehicle_no",
        },
        {
            title: "Brand",
            dataIndex: "brand_name",
        },
        {
            title: "Model",
            dataIndex: "model_name",
        },
        {
            title: "Year",
            dataIndex: "year",
        },
        {
            title: "Color",
            dataIndex: "car_color",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessView onClick={() => viewHandler(record)} type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 20,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default WorkingOrderTable;

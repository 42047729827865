/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Skeleton, Space, Upload, UploadFile, UploadProps, message } from "antd";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import InputFile from "components/form/inputs/input-file";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalAddExpenses from "modules/master-data/payment/add/modal-add-expenses";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InvoiceDocPayment } from "services/api-endpoints/dashboard/payment";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import transferBankService, { CreateTransferBank, DraftTransferBank } from "services/api-endpoints/dashboard/transfer-bank";
import Utils from "utils";
import { AUTOGENERATED, FORMAT_DATE_1 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateTransferBank>> = yup.object().shape({
    bank_coa_from: yup.number().required("Bank Account From Required"),
    bank_coa_to: yup.number().required("Bank Account To Required"),
    transaction_date: yup.string().required("Transaction Date Required"),
    note: yup.string().required("Note Voucher Required"),
    amount: yup.string().required("Amount (Rp) Required"),
    expenses: yup.array(),
    bank_images: yup.array(),
    total_amount: yup.number(),
});

const TransferBankEdit = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreateTransferBank>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchAmount = watch("amount");

    const detailQuery = useQuery(
        [transferBankService.detailTransferBank, id],
        async () => {
            return (await transferBankService.DetailTransferBank({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    transaction_date: moment(data?.transaction_date),
                    note: data?.note,
                });
                setValue("bank_coa_from", data?.bank_coa_from);
                setValue("bank_coa_to", data?.bank_coa_to);
                setValue("transaction_date", moment(data?.transaction_date));
                setValue("note", data?.note);
                setExpanses(data?.expenses);
                setUrlImgList((data?.bank_images || [])?.filter((url) => url));
                setValue("amount", data?.amount);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const saveCreateMutate = useMutation(
        [transferBankService.createTransferBank],
        async (data: DraftTransferBank) => {
            return (await transferBankService.SaveCreateTransferBank(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Transfer Bank Success");
                navigate(-1);
            },
        }
    );

    const saveDraftMutate = useMutation(
        [transferBankService.draftTransferBank],
        async (data: DraftTransferBank) => {
            return (await transferBankService.SaveDraftTransferBank(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Transfer Bank Drafted");
                navigate(-1);
            },
        }
    );

    const bankAccountQuery = useQuery([transferBankService.bankAccount], async () => {
        return (await transferBankService.GetBankAccount()).data.data?.map((el) => ({ label: el.bank_no, value: el.bank_id } as SelectOption));
    });

    const grandTotal = Number(String(watchAmount).replaceAll(".", "") || 0) + expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: DraftTransferBank = {
            ...data,
            id: id!,
            bank_coa_from: data.bank_coa_from,
            bank_coa_to: data.bank_coa_to,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            expenses: expanses,
            bank_images: [...(filesBase64 || []), ...urlImgList],
            amount: Number(String(watchAmount).replaceAll(".", "") || 0),
            total_amount: grandTotal,
        };

        saveCreateMutate.mutate(transformData);
    });

    const onDraftSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: DraftTransferBank = {
            ...data,
            id: id!,
            bank_coa_from: data.bank_coa_from,
            bank_coa_to: data.bank_coa_to,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            expenses: expanses,
            bank_images: [...(filesBase64 || []), ...urlImgList],
            amount: Number(String(watchAmount).replaceAll(".", "") || 0),
            total_amount: grandTotal,
        };

        saveDraftMutate.mutate(transformData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        onDraftSubmitHandler();
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    const onDeleteImageUrl = (image: any) => {
        setUrlImgList((prev) => prev?.filter((url) => url !== image));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new transfer bank"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <>
                            <Button loading={saveDraftMutate.isLoading} onClick={onDraftHandler} type="default" className="!flex !items-center">
                                <RiDraftLine className="m-0 mr-2" />
                                Draft
                            </Button>
                            <Button loading={saveCreateMutate.isLoading} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        </>
                    </Space>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                                <Card className="">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline italic mt-4">{AUTOGENERATED}</span>
                                        </p>
                                        <ControlledSelectInput
                                            showSearch
                                            control={control}
                                            name="bank_coa_from"
                                            placeholder="Bank Account From"
                                            label="Bank Account From"
                                            options={bankAccountQuery.data}
                                        />
                                        <ControlledSelectInput
                                            showSearch
                                            control={control}
                                            name="bank_coa_to"
                                            placeholder="Bank Account To"
                                            label="Bank Account To"
                                            options={bankAccountQuery.data}
                                        />
                                        <ControlledInputDate
                                            control={control}
                                            name="transaction_date"
                                            placeholder="Transaction Date"
                                            label="Transaction Date"
                                        />
                                        <ControlledInputTextArea control={control} name="note" placeholder="Note" label="Note" rows={2} />
                                    </div>
                                </Card>
                                <Card className="">
                                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <ControlledInputText
                                            control={control}
                                            type="number"
                                            name="amount"
                                            placeholder="Amount (Rp)"
                                            label="Amount (Rp)"
                                        />
                                        <div />
                                        <div className="col-span-2 flex flex-col gap-5 pr-2 mt-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                                            <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                <div>
                                                    <BiImageAdd className="text-2xl" />
                                                    <div style={{ marginTop: 8 }}>Upload</div>
                                                </div>
                                            </Upload>
                                            <div className="grid grid-cols-5 gap-4">
                                                {urlImgList?.map((url) => (
                                                    <InputFile imageUrl={url} disabled onDelete={onDeleteImageUrl}>
                                                        {(dt) => <ImageCard image={dt.file || dt.urlFile} title="document" />}
                                                    </InputFile>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="flex flex-col">
                                            <>
                                                {expanses.length !== 0 ? (
                                                    <div>
                                                        <p className="capitalize m-0">Expenses</p>
                                                        <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </>
                                            <ModalAddExpenses onCreate={onAddExpenses} paymentType={0}>
                                                {(dt) => (
                                                    <div className="w-full flex flex-col gap-1">
                                                        <Button onClick={dt.openModal} type="primary" className="!self-start">
                                                            Add Expenses
                                                        </Button>
                                                    </div>
                                                )}
                                            </ModalAddExpenses>
                                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                                            <p className="capitalize m-0">
                                                Total Transfer
                                                <br />
                                                <span className="font-semibold m-0">{(grandTotal || 0).ToIndCurrency("Rp")}</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default TransferBankEdit;

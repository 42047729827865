import { Form, Select, SelectProps, ColProps } from "antd";
import React, { forwardRef } from "react";

export type SelectOption = {
    label: string;
    value: number;
};

type InputSelectProps = SelectProps & {
    error: string | undefined;
    label: string;
    labelCol?: ColProps;
    classNameForm?: string;
};

const InputSelect: React.FC<InputSelectProps> = forwardRef(
    ({ placeholder, error, classNameForm, label, value, onChange, options, labelCol, loading, ...rest }: InputSelectProps) => (
        <Form.Item
            label={label}
            validateStatus={error ? "error" : ""}
            help={error && error}
            labelCol={labelCol}
            className={`!w-full ${classNameForm}`}
        >
            <Select {...rest} value={value} loading={loading} placeholder={placeholder} onChange={onChange} placement="bottomLeft">
                {options?.map((option) => {
                    return <Select.Option {...option}>{option.label}</Select.Option>;
                })}
            </Select>
        </Form.Item>
    )
);

InputSelect.displayName = "InputSelect";

export default InputSelect;

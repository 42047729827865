/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface Locator {
    id: number;
    area_code: string;
    is_active: number;
    warehouse_id: number;
    warehouse_name: string;
}

export interface LocatorRequest {
    id?: number;
    warehouse_id: number;
    code: string;
    is_active: number;
}

class LocatorService extends BaseService {
    getList = "/locator/get-list";

    detail = "/locator/detail";

    create = "/locator/create";

    constructor() {
        super();
    }

    Create<T extends any>(data: LocatorRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    Detail<T extends Locator>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    GetList<T extends Models.BasePaginationResponse<Locator>>(params: { warehouse_id?: any; query?: any; page?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getList,
                config: { params },
            });
            return req;
        });
    }
}

const locatorService = new LocatorService();
export default locatorService;

/* eslint-disable no-useless-concat */
import { Icon } from "@mui/material";
import { Select } from "antd";
import { StateContext } from "context/state";
import { UserContext } from "context/user";
import { AppsMenu } from "models";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

type MenuSelect = Partial<AppsMenu> & {
    id: string;
    menuName?: string;
    appName?: string;
};

const SearchMenu = () => {
    const navigate = useNavigate();
    const { state } = React.useContext(UserContext);
    const { navigateAppHandler } = React.useContext(StateContext);
    const [allMenu, setAllMenu] = React.useState<MenuSelect[]>([]);

    const flatCollapse = (collapse: any, flatten: any): any => {
        if (!collapse?.collapse) return flatten;
        return flatCollapse(collapse.collapse, flatten.concat(collapse.collapse));
    };

    React.useEffect(() => {
        const flatten: any = [];
        state?.user?.apps?.forEach((app) => {
            app.apps_menu?.forEach((menu) => {
                if (!menu?.collapse) {
                    flatten.push({
                        ...menu,
                        id: uuid(),
                        key: `/${menu.key}[]${menu.name}[]${app.apps_name}`,
                        menuName: menu.name,
                        appName: app.apps_name,
                    });
                    return;
                }
                menu?.collapse?.forEach((col1) => {
                    if (!col1?.collapse?.length) {
                        flatten.push({
                            ...col1,
                            id: uuid(),
                            key: `${col1.route}[]${col1.name}[]${app.apps_name}`,
                            menuName: `${menu.name}>${col1.name}`,
                            appName: app.apps_name,
                        });
                        return;
                    }
                    col1?.collapse?.forEach((col2) => {
                        flatten.push({
                            ...col2,
                            id: uuid(),
                            key: `${col2.route}[]${col1.name}/${col2.name}[]${app.apps_name}`,
                            menuName: `${menu.name}>${col1.name}>${col2.name}`,
                            appName: app.apps_name,
                        });
                    });
                });
            });
        });

        setAllMenu(flatten);
    }, [state?.user?.apps]);

    const OptionsMemoize = React.useCallback(
        () =>
            allMenu?.map((menu) => {
                return (
                    <Select.Option value={menu?.key} key={menu.id}>
                        <div className="flex gap-4 w-full">
                            <Icon fontSize="small" className="bg-blue-400 text-white p-[2px] rounded">
                                {menu?.icon || "dashboard"}
                            </Icon>
                            <div className="flex flex-col">
                                <span className="text-[10px] font-semibold leading-none">
                                    {menu.appName}
                                    {">"}
                                    {menu.menuName}
                                </span>
                                <p className="m-0 text-lg">{menu.name}</p>
                            </div>
                        </div>
                    </Select.Option>
                );
            }),
        [allMenu]
    );

    const onSelect = (value: string) => {
        const appname = value.split("[]")[value.split("[]").length - 1];
        const route = value.split("[]")[0];
        const app = state?.user?.apps?.find((ap) => ap.apps_name === appname);

        if (!navigateAppHandler || !app) return;
        navigate(navigateAppHandler(app, route));
    };

    return (
        <div className="w-full">
            <Select autoFocus size="large" showArrow className="!w-full min-w-[400px]" showSearch placeholder="Start typing..." onSelect={onSelect}>
                {OptionsMemoize()}
            </Select>
        </div>
    );
};

export default SearchMenu;

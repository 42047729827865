import { DetailList } from "services/api-endpoints/dashboard/master-data/bp-invoice";
import { ProductInvoice } from "services/api-endpoints/dashboard/master-data/c-invoice";

export function mapDuplicateProducts(arr: DetailList[]) {
    let after: DetailList[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id)) {
            after = after.map((ap) => {
                if (ap.product_id !== el.product_id) return ap;
                return {
                    ...ap,
                    // so_no: `${ap.so_no}, ${el.so_no}`,
                    discount: (ap.discount || 0) + (el.discount || 0),
                    qty: ap.qty! + el.qty!,
                };
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export function mapDuplicateProducts2(arr: ProductInvoice[]) {
    let after: ProductInvoice[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id)) {
            after = after.map((ap) => {
                if (ap.product_id !== el.product_id) return ap;
                return {
                    ...ap,
                    discount_price: (ap.discount_price || 0) + (el.discount_price || 0),
                    qty: ap.qty! + el.qty!,
                };
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export default {};

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import { CreateRawMaterialSchema } from "pages/raw-material/add";
import React from "react";
import { useQuery } from "react-query";
import productService from "services/api-endpoints/dashboard/master-data/product";

export function withUom<T extends ControlledInputSelectProps<CreateRawMaterialSchema>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([productService.getUom], async () => {
            const req = await productService.GetUom();
            return req.data.data?.map((el) => ({ label: el?.uom_name || "Any", value: el.uom_id || "" } as SelectOption));
        });
        return <Component {...props} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const UomSelect = withUom(ControlledSelectInput);

export default UomSelect;

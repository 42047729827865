import { MasterDataProduct } from "models";
import { OrderData, SummaryTrx } from "services/api-endpoints/dashboard/master-data/order";
import { STATUS_ACTIVE } from "../../../utils/constant";
import { TempData } from "./models";

export default {};

export const parseProductsToCsvData = (data: MasterDataProduct[]) => {
    return [...(data || [])].map((p, i) => ({
        No: i + 1,
        "Product Name": p?.name || "",
        "Product Code": p?.code1 || "",
        "Molding Code": p?.molding_code || "",
        "Model Name": p?.model_name || "",
        "Model Year": p?.model_year?.join(", ") || "",
        "Model Type": p?.model_type_name || "",
        "Sub Category": p?.subcategory_name || "",
        "Sub Attribute": p?.subattribute_name || "",
        status: STATUS_ACTIVE.find((el) => el.value === p?.is_active)?.label || "",
    }));
};

export const parseOrderToCsvData = (orderList: OrderData[], summaryTrx?: SummaryTrx[]) => {
    const orderListWithEmptyRow = summaryTrx?.length ? [...orderList, {}, {}] : orderList; // add 2 empty row
    const parseOrderList = orderListWithEmptyRow?.map((p, i) => ({
        No: p?.date ? i + 1 : "",
        "Invoice No": p?.inv_no || "",
        Name: p?.client_name || "",
        "Sales Name": p?.marketplace_name || "",
        Reference: p?.invoice_reference || "-",
        "Product Name": p?.product_name || "",
        "Product Code": p?.product_code || "",
        "Product Owner": p?.product_owner || "",
        Qty: p?.qty || "",
        Date: p?.date || "",
        Status: p?.order_status || "",
    }));

    const parseSummaryTrx = summaryTrx?.length
        ? [...summaryTrx, { marketplace_name: "TOTAL", total_trx: summaryTrx?.reduce((t, c) => t + c.total_trx, 0) }]?.map((s, i) => ({
              "": "",
              " ": "",
              "  ": "",
              "   ": "",
              "    ": "",
              "     ": "",
              "      ": "",
              "       ": s.marketplace_name,
              "        ": s.total_trx,
          }))
        : summaryTrx;

    if (parseSummaryTrx?.length) return [...parseOrderList, ...parseSummaryTrx];
    return parseOrderList;
};

export const generateSKU = (tempData: TempData) => {
    if (!tempData.models) return "";
    const modelCode = tempData.models ? tempData.models[0]?.model_code || "00" : "00";
    const subCategoryCode = tempData?.subCategory?.subcategory_code;
    const styleCode = tempData?.style?.style_code;
    const attributeCode = tempData?.attribute?.attribute_code;
    const subAttributeCode = tempData?.subAttribute?.subattribute_code;

    if (!modelCode || !subCategoryCode || !styleCode || !attributeCode || !subAttributeCode) return "";
    return `${modelCode}-${subCategoryCode}${styleCode}.${attributeCode}${subAttributeCode}`;
};

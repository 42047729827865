/* eslint-disable jsx-a11y/control-has-associated-label */
import { Alert, Button, Image, message, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import orderService, { Locator, OrderProduct } from "services/api-endpoints/dashboard/master-data/order";

type Props = ModalProps & {
    orderHistoryId: any;
};

const ImagesModal = ({ orderHistoryId, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const imageWrapQuery = useQuery([orderService.getImagesWrap, orderHistoryId], async () => {
        return (await orderService.GetImagesWrap({ order_history_id: orderHistoryId })).data.data;
    });

    return (
        <ModalTemplate width={700} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <State data={imageWrapQuery.data} isLoading={imageWrapQuery.isLoading} isError={imageWrapQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-3 gap-5">
                                        {imageWrapQuery.data?.wrap_images?.map((img) => (
                                            <a href={img} target="_blank" rel="noreferrer" key={img}>
                                                <Image src={img} preview={false} />
                                            </a>
                                        ))}
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <div className="grid grid-cols-3 gap-5">
                                        <Skeleton.Image active />
                                        <Skeleton.Image active />
                                    </div>
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(imageWrapQuery.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </>
            )}
        </ModalTemplate>
    );
};

export default ImagesModal;

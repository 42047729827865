import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import bpSalesService from "services/api-endpoints/dashboard-all/bp-sales";
import { FORMAT_DATE_1, MARKET_PLACE_VALUES, STATUS_ORDER_SALES_FILTER, STATUS_SUBMIT } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBpSales = ({ filter = [], ...props }: Props) => {
    const getBpQuery = useQuery([bpSalesService.getBp], async () => {
        return (await bpSalesService.GetBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });
    return (
        <FilterModal
            filter={filter}
            title="Filter Bp Sales"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Sales Order No"
                            value={dt.values.sales_order_no}
                            name="sales_order_no"
                            onChange={(e) => dt.setOneValue("sales_order_no", e.target.value)}
                        />
                        <Input
                            placeholder="Invoice No"
                            value={dt.values.invoice_no}
                            name="invoice_no"
                            onChange={(e) => dt.setOneValue("invoice_no", e.target.value)}
                        />
                        <Input
                            placeholder="Customer Name"
                            value={dt.values.customer_name}
                            name="customer_name"
                            onChange={(e) => dt.setOneValue("customer_name", e.target.value)}
                        />
                        <Select
                            allowClear
                            value={Number(dt.values.marketplace_id)}
                            placeholder="Business Partner"
                            options={getBpQuery.data || []}
                            loading={getBpQuery.isLoading}
                            onChange={(value) => dt.setOneValue("marketplace_id", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            allowClear
                            value={dt.values.status === null || dt.values.status !== undefined ? Number(dt.values.status) : dt.values.status}
                            placeholder="Status"
                            options={STATUS_ORDER_SALES_FILTER}
                            onChange={(value) => dt.setOneValue("status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBpSales;

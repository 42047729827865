import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Space, Switch } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { AnimatePresence, motion } from "framer-motion";
import { Dispatch, Key, SetStateAction, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CustomVoucher } from "../models";
import { DocReqChild } from "pages/purchasing-inventory/add";
import TableCustomVoucher from "./table-custom-voucher";

type Props = {
    children: (data: HandlerProps) => void;
    onCreate: (data: CustomVoucher) => void;
    doc?: DocReqChild[];
    setSelectedRowKeys?: Dispatch<SetStateAction<Key[]>>;
    selectedRowKeys?: Key[];
};

const schema: yup.SchemaOf<CustomVoucher> = yup.object().shape({
    name: yup.string().required("Name Required"),
    percentage: yup.number().nullable(),
    price: yup.string().nullable(),
    product: yup.array(),
});

const ModalCustomVoucher = ({ children, onCreate, selectedRowKeys, setSelectedRowKeys, doc }: Props) => {
    const [type, setType] = useState(true); // true = percentage, false = price
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, reset } = useForm<CustomVoucher>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const resetForm = () => {
        reset();
        form.setFieldsValue({});
        setValue("name", "");
        setValue("percentage", null);
        setValue("price", null);
    };

    const onSubmitHandler = handleSubmit(async (data) => {
        const parseData = {
            ...data,
            price: data?.price?.ToIntegerFromIndCurrency() || null,
        };
        if (type && !parseData.percentage) {
            setError("percentage", {
                message: "Percentage Required",
                type: "required",
            });
            return;
        }
        if (!type && !parseData.price) {
            setError("price", {
                message: "Price Required",
                type: "required",
            });
            return;
        }

        if (selectedRowKeys?.length === 0) {
            message.error("Product doc Harus dipilih terlebih dahulu");
            return;
        }

        onCreate(parseData);
        closeRef.current?.click();
        resetForm();
    });

    const onChangeType = () => {
        setType((prev) => !prev);
        form.setFieldsValue({
            percentage: "",
            price: "",
        });
        setValue("percentage", null);
        setValue("price", null);
    };

    return (
        <ModalTemplate title={<div className="flex items-center gap-4">Custom Discount</div>} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button onClick={dt.closeModal} type="button" ref={closeRef} className="hidden">
                        close
                    </button>
                    <AnimatePresence mode="popLayout">
                        <motion.div
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1, transition: { type: "spring", duration: 0.5, bounce: 0.4 } }}
                        >
                            <Form form={form} onFinish={onSubmitHandler} layout="vertical">
                                <Space direction="vertical" className="w-full">
                                    <ControlledInputText control={control} name="name" placeholder="Discount Name" />
                                    <div className="w-full flex items-start gap-4">
                                        {type ? (
                                            <ControlledInputText
                                                addonBefore="%"
                                                control={control}
                                                name="percentage"
                                                type="number"
                                                placeholder="Percentage"
                                            />
                                        ) : (
                                            <ControlledInputText allowClear type="number" control={control} name="price" placeholder="Price" />
                                        )}
                                        <Switch
                                            className="!mt-1"
                                            checked={type}
                                            onChange={onChangeType}
                                            checkedChildren={<p className="text-white font-semibold">%</p>}
                                            unCheckedChildren={<p className="text-primary font-semibold whitespace-nowrap ">Rp</p>}
                                        />
                                    </div>
                                    <TableCustomVoucher doc={doc} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
                                    <Button type="primary" htmlType="submit">
                                        Create
                                    </Button>
                                </Space>
                            </Form>
                        </motion.div>
                    </AnimatePresence>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalCustomVoucher;

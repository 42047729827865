/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { Product } from "models";
import React, { useState } from "react";
import { PACKAGE_BUNDLING } from "utils/constant";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    subattribute: number;
};

export function tableEdit<T extends Product>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Qty",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className={text === null || text === 0 ? "m-0 text-center text-red-500" : "m-0 text-center "}>{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                fixed: "right",
                width: "150px",
                ...{ editable: true },
                render: (val) => (typeof val === "number" ? val.ToIndCurrency("Rp") : val),
            },
        ];

        const columns2: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Qty",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className={text === null || text === 0 ? "m-0 text-center text-red-500" : "m-0 text-center "}>{text || "-"}</p>,
            },
        ];

        const isEditing = (record: Product, edited: Product | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: Product, edited: Product) => record.product_id === edited?.product_id;
        const rowKey = (record: Product) => record.product_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number", product_price: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 0,
                }}
                columns={props.subattribute === PACKAGE_BUNDLING ? columns : columns2}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
                onChange={handleTableChange}
            />
        );
    };
}

const TableAdd = tableEdit(EditTable);
export default TableAdd;

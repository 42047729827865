import Toolbar from "components/toolbar";
import { UserContext } from "context/user";
import IndexTable from "modules/master-data/external-order/ext-so/table-index";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extSalesOrderService from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { ACCOUNT_ID_SPM, ACCOUNT_ID_SPM_MKS } from "utils/constant";

const ExtSalesOrder = () => {
    const { state } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("size") || 10;

    const getList = useQuery([extSalesOrderService.getAll, page], async () => {
        return (await extSalesOrderService.GetAll({ page, size })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar
                placeholder="Search..."
                filter={false}
                add={state.user?.account_id === ACCOUNT_ID_SPM || state.user?.account_id === ACCOUNT_ID_SPM_MKS}
            />
            <IndexTable fetcher={getList} />
        </div>
    );
};

export default ExtSalesOrder;

import { Button, Card, Select, Space, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import AdvanceSearch from "modules/advance-search";
import ModalOrder from "modules/master-data/external-order/ext-ro/modal-order";
import OrderTable from "modules/master-data/external-order/ext-ro/table-order";
import TableProductExtRO from "modules/master-data/external-order/ext-ro/table-product";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import extReturnOrderService, {
    CreateReturnOrder,
    DetailOrder,
    LocatorData,
    Order,
    ReturnOrderDetail,
} from "services/api-endpoints/dashboard/master-data/ext-return-order";
import extSalesOrderService from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import returnOrderService from "services/api-endpoints/dashboard/master-data/return-order";

import {
    ACTION_DESTROY,
    AUTOGENERATED,
    EXT_RETURN_TUKAR_BARANG,
    EXT_RETURN_TYPE,
    SOURCE_EXTERNAL_ORDER,
    SOURCE_WITH_INVOICE,
    SOURCE_WITHOUT_INVOICE,
    STOCK_ACTION,
} from "utils/constant";

export type ProductWithStock = Product & {
    stock_action?: any;
    qty_return?: number;
    area_id?: number;
    area_name?: string;
    qty_locator?: number;
};

const ExtReturnOrderAdd = () => {
    const navigate = useNavigate();
    const [reason, setReason] = useState<number | null>(null);
    const [source, setSource] = useState<number | null>(null);
    const [bp, setBP] = useState<number | null>(null);
    const [type, setType] = useState<number | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<Order[]>([]);
    const [orderItems, setOrderItems] = useState<DetailOrder[]>([]);
    const [products, setProducts] = useState<ProductWithStock[]>([]);
    const [warehouse, setWarehouse] = useState<number | null>(null);

    const getReason = useQuery([returnOrderService.getReason], async () => {
        return (await returnOrderService.GetReason()).data.data;
    });

    const getBP = useQuery([extSalesOrderService.getBp], async () => {
        const req = await extSalesOrderService.GetBp({ status: 0 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption));
    });

    const getWarehouse = useQuery([priceService.warehouse], async () => {
        return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
    });

    const locatorIN = useMutation([extReturnOrderService.locator], async (whID: number) => {
        const req = await extReturnOrderService.Locator({ type: 1, warehouse_id: whID });
        return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id || "" } as SelectOption & LocatorData));
    });

    const getDetailOrder = useMutation([extReturnOrderService], async (orderId: string) => {
        return (await extReturnOrderService.GetDetailOrder({ order_id: orderId })).data.data;
    });

    const create = useMutation([extReturnOrderService.create], async (data: CreateReturnOrder) => {
        return (await extReturnOrderService.Create(data)).data.data;
    });

    const getDataOrder = useMutation([extReturnOrderService.getDataOrder], async (partnerID: number) => {
        return (await extReturnOrderService.GetDataOrder({ query: "", partner_id: partnerID })).data.data?.map(
            (order) => ({ ...order, label: order.inv_no, value: order.order_id } as SelectOption & Order)
        );
    });

    const onSelectOrder = async (order: Order) => {
        getDetailOrder.mutateAsync(order.order_id as any).then((res) => {
            setOrderItems((prev) => [...prev, ...res.map((itm) => ({ ...itm, qty_return: itm.qty }))]);
            setSelectedOrder((prev) => {
                if (!prev.find((ord) => ord.order_id === order.order_id)) {
                    return [...prev, order];
                }
                return prev;
            });
        });
    };

    const onDeSelectOrder = (order: Order) => {
        setSelectedOrder((prev) => prev?.filter((ord) => ord.order_id !== order.order_id));
        setOrderItems((prev) => prev?.filter((item) => item.id !== order.order_id));
    };

    const onRemoveOrderItem = (item: DetailOrder) => {
        setOrderItems((prev) => {
            return prev
                ?.map((itm) => {
                    if (itm.id === item.id && itm.product_id === item.product_id) return {};
                    return itm;
                })
                .filter((itm) => Object.keys(itm).length);
        });
    };

    const onRemoveProduct = (item: Product) => {
        setProducts((prev) => prev.filter((product) => product.product_id !== item.product_id));
    };

    const createHandler = () => {
        const noStockAction = orderItems.find((itm) => !itm.stock_action);
        if (noStockAction) {
            message.error(`Stock Action Required on ${noStockAction.product_name}`);
            return;
        }

        const withInvDetail = orderItems.map((itm) => {
            return {
                order_id: itm.id,
                product_id: itm.product_id,
                qty_order: itm.qty,
                qty_return: itm.qty_return,
                area_id: itm.area_id,
                stock_action: STOCK_ACTION.find((act) => act.label === itm.stock_action || act.value === itm.stock_action)?.value,
            } as ReturnOrderDetail;
        });

        const withoutInvDetail = products.map((itm) => {
            return {
                product_id: itm.product_id,
                qty_return: itm.qty_return,
                area_id: itm.area_id,
                stock_action: STOCK_ACTION.find((act) => act.label === itm.stock_action || act.value === itm.stock_action)?.value,
            } as ReturnOrderDetail;
        });

        if (source === SOURCE_WITH_INVOICE && withInvDetail.find((item) => item?.area_id === null || item?.area_id === undefined)) {
            message.error("Please select locator");
            return;
        }

        if (source === SOURCE_WITHOUT_INVOICE && withoutInvDetail.find((item) => item?.area_id === null || item?.area_id === undefined)) {
            message.error("Please select locator");
            return;
        }

        if (source === SOURCE_WITH_INVOICE && withInvDetail.find((item) => item?.qty_return === null || item?.qty_return === undefined)) {
            message.error("Please check the quantity");
            return;
        }

        if (source === SOURCE_WITHOUT_INVOICE && withoutInvDetail.find((item) => item?.qty_return === null || item?.qty_return === undefined)) {
            message.error("Please check the quantity");
            return;
        }

        if (source === SOURCE_WITH_INVOICE && withInvDetail.find((item) => item?.stock_action === null || item?.stock_action === undefined)) {
            message.error("Please check the stock action");
            return;
        }

        if (source === SOURCE_WITHOUT_INVOICE && withoutInvDetail.find((item) => item?.stock_action === null || item?.stock_action === undefined)) {
            message.error("Please check the stock action");
            return;
        }

        const data: CreateReturnOrder = {
            inv_type: source!,
            bp_id: bp!,
            reason_id: reason as number,
            order_id: [...new Set(orderItems.map((itm) => itm.id))] as number[],
            impact_type: type!,
            warehouse_id: warehouse!,
            return_order_detail: source === SOURCE_WITH_INVOICE ? withInvDetail : withoutInvDetail,
        };

        create.mutateAsync(data).then(() => {
            message.success("Return Order Created");
            navigate(-1);
        });
    };

    const onChangeReason = (value: number) => {
        setReason(value);
    };

    const onChangeSource = (value: number) => {
        setSource(value);
    };

    const onChangeWarehouse = (value: number) => {
        setWarehouse(value);
        locatorIN.mutate(value);
    };

    const onChangeType = (value: number) => {
        setType(value);
        setProducts((prev) =>
            [...prev].map((p) => {
                return {
                    ...p,
                    stock_action: undefined,
                    area_id: undefined,
                    area_name: undefined,
                    qty_locator: undefined,
                };
            })
        );
        setOrderItems((prev) =>
            [...prev].map((p) => {
                return {
                    ...p,
                    stock_action: undefined,
                    area_id: undefined,
                    area_name: undefined,
                    qty_locator: undefined,
                };
            })
        );
    };

    const onChangeBP = (value: number) => {
        getDataOrder.mutateAsync(value);
        setBP(value);
        setOrderItems([]);
        setSelectedOrder([]);
    };

    React.useEffect(() => {
        setSelectedOrder((prev) => {
            const deletedOrder = prev.filter((ord) => !orderItems.find((itm) => itm.id === ord.order_id));
            if (!deletedOrder.length) return prev;
            return prev.filter((prevOrd) => deletedOrder.find((ord) => prevOrd.order_id !== ord.order_id));
        });
    }, [orderItems]);

    const onSubmitLocator = (locator: LocatorData) => {
        setProducts((prev) =>
            [...prev].map((p) => {
                if (p.product_id !== locator.product_id) return p;
                return {
                    ...p,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: locator.qty_locator,
                };
            })
        );
    };

    const onSubmitLocatorOrder = (locator: LocatorData) => {
        setOrderItems((prev) =>
            [...prev].map((p) => {
                if (p.product_id !== locator.product_id) return p;
                return {
                    ...p,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: locator.qty_locator,
                };
            })
        );
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add return order"
                rightAddition={() => (
                    <Button
                        onClick={createHandler}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={
                            !reason || !type || !warehouse || (source === SOURCE_WITH_INVOICE ? orderItems.length === 0 : products.length === 0)
                        }
                        loading={create.isLoading}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-4 gap-5">
                    <p className="capitalize m-0">
                        Document No <br />
                        <span className="underline m-0">{AUTOGENERATED}</span>
                    </p>
                    <div className="capitalize m-0 w-full">
                        BP Name
                        <br />
                        <Select
                            loading={getBP.isLoading}
                            disabled={getBP.isLoading || getBP.isError}
                            value={bp}
                            options={getBP.data}
                            onChange={onChangeBP}
                            showSearch
                            optionFilterProp="label"
                            className="w-full"
                            placeholder="BP Name"
                        />
                    </div>
                    <div className="capitalize m-0 w-full col-span-1">
                        Source
                        <br />
                        <Select
                            options={SOURCE_EXTERNAL_ORDER}
                            value={source}
                            onChange={onChangeSource}
                            showSearch
                            optionFilterProp="label"
                            className="w-full"
                            placeholder="Source"
                        />
                    </div>
                    <div className="capitalize m-0 w-full col-span-1">
                        Type Return
                        <br />
                        <Select
                            loading={getReason.isLoading}
                            disabled={getReason.isLoading || getReason.isError}
                            value={type}
                            options={EXT_RETURN_TYPE}
                            onChange={onChangeType}
                            showSearch
                            optionFilterProp="label"
                            placeholder="Type Return"
                            className="w-full"
                        />
                    </div>
                    <div className="capitalize m-0 w-full col-span-2">
                        Reason to return
                        <br />
                        <Select
                            loading={getReason.isLoading}
                            disabled={getReason.isLoading || getReason.isError}
                            value={reason}
                            options={getReason.data?.map((r) => ({ label: r.reason_name, value: r.reason_id } as SelectOption))}
                            onChange={onChangeReason}
                            showSearch
                            optionFilterProp="label"
                            className="w-full"
                            placeholder="Reason"
                        />
                    </div>
                    <div className="capitalize m-0 w-full col-span-1">
                        Warehouse Return
                        <br />
                        <Select
                            options={getWarehouse.data}
                            loading={getWarehouse.isLoading}
                            value={warehouse}
                            onChange={onChangeWarehouse}
                            showSearch
                            optionFilterProp="label"
                            className="w-full"
                            placeholder="Warehouse Return"
                        />
                    </div>
                </div>
            </Card>
            <div className="flex gap-4">
                <ModalOrder
                    getDetailOrderLoading={getDetailOrder.isLoading}
                    onSelectOrder={onSelectOrder}
                    onDeSelectOrder={onDeSelectOrder}
                    selected={selectedOrder}
                    orderData={getDataOrder.data}
                    loading={getDataOrder.isLoading}
                >
                    {(dt) => (
                        <Button
                            loading={getDetailOrder.isLoading}
                            className="!flex !items-center w-fit"
                            type="primary"
                            onClick={dt.openModal}
                            disabled={bp === null || source !== SOURCE_WITH_INVOICE}
                        >
                            <MdAdd className="mr-2" />
                            Data Order
                        </Button>
                    )}
                </ModalOrder>
                <AdvanceSearch selected={products} setSelected={setProducts}>
                    {(dt) => (
                        <Button
                            disabled={bp === null || source !== SOURCE_WITHOUT_INVOICE}
                            className="!flex !items-center w-fit"
                            type="primary"
                            onClick={dt.openModal}
                        >
                            <MdAdd className="mr-2" />
                            Add Product
                        </Button>
                    )}
                </AdvanceSearch>
            </div>
            {source === SOURCE_WITH_INVOICE && (
                <OrderTable
                    list={orderItems}
                    setList={setOrderItems}
                    removeItemList={onRemoveOrderItem}
                    type={type}
                    locatorIN={locatorIN}
                    warehouseId={warehouse}
                    onSubmitLocatorOrder={onSubmitLocatorOrder}
                />
            )}
            {source === SOURCE_WITHOUT_INVOICE && (
                <TableProductExtRO
                    list={products}
                    setList={setProducts}
                    removeItemList={onRemoveProduct}
                    type={type}
                    locatorIN={locatorIN}
                    warehouseId={warehouse}
                    onSubmitLocator={onSubmitLocator}
                />
            )}
        </div>
    );
};

export default ExtReturnOrderAdd;

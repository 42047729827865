import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import React, { useState } from "react";
import { UseMutationResult } from "react-query";
import { ProductData } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";

type Props<T> = {
    fetcher?: UseMutationResult<T[], unknown>;
    selectedProduct: React.Key[];
    setSelectedProduct: React.Dispatch<React.SetStateAction<React.Key[]>>;
};

const TableChooseProduct = <T extends ProductData>({ fetcher, selectedProduct, setSelectedProduct }: Props<T>) => {
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedProduct(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedProduct,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Code 1",
            dataIndex: "product_code1",
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
        },
        {
            title: "Qty",
            dataIndex: "qty",
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            size="small"
            rowSelection={rowSelection}
            loading={fetcher?.isLoading}
            columns={columns}
            dataSource={fetcher?.data || []}
            className="w-full"
            pagination={{
                pageSize: 10,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableChooseProduct;

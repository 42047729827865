import { Alert, Button, Card, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import Print from "modules/master-data/material-movement-doc/edit/print";
import TableEditProduct from "modules/master-data/material-movement-doc/edit/table-edit";
import ModalHistoryMM from "modules/master-data/material-movement/edit/modal-history";
import HistoryTable from "modules/master-data/material-movement/edit/table-history";
import { SelectOptionCustom } from "modules/master-data/material-movement/lib/locator-select";
import { EditMM } from "modules/master-data/material-movement/models";
import { tProductTransformWithQty } from "modules/master-data/material-movement/utils";
import React, { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import materialMovementService, { ProductTransfer, ProductTransferWithEdit } from "services/api-endpoints/dashboard/master-data/material-movement";
import { MATERIAL_MOVEMENT_DRAFT, MATERIAL_MOVEMENT_OPTIONS } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const MaterialMovementDocEdit = ({ paramId, asPreview }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const status = searchParams.get("status");

    const letterRef = useRef<HTMLDivElement | null>(null);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Material Movement - ${id}`,
        pageStyle: "@page {  }",
    });

    const [products, setProducts] = React.useState<ProductTransferWithEdit[]>([]);

    const saveMutate = useMutation(
        [materialMovementService.edit],
        async (data: EditMM) => {
            return (await materialMovementService.Edit(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Material Movement Save Edit");
                navigate(-1);
            },
        }
    );

    const saveDraftMutate = useMutation(
        [materialMovementService.editDraft],
        async (data: EditMM) => {
            return (await materialMovementService.EditDraft(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Material Movement Save Edit");
                navigate(-1);
            },
        }
    );

    const detailQuery = useQuery(
        [materialMovementService.detail, id],
        async () => {
            return (await materialMovementService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setProducts(tProductTransformWithQty(data?.list_product) || []);
            },
        }
    );

    const locatorToQuery = useQuery(
        [materialMovementService.getLocatorTo, detailQuery.data?.warehouse_id_to],
        async () => {
            const req = await materialMovementService.GetLocatorTo({ warehouse_id_to: detailQuery.data?.warehouse_id_to });
            return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_to || "" } as SelectOptionCustom));
        },
        {
            enabled: !!detailQuery.data?.warehouse_id_to,
        }
    );

    const historyQuery = useQuery(
        [materialMovementService.historyMM],
        async () => {
            return (await materialMovementService.HistoryMM({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const prepareSave = () => {
        if (!products.length) {
            message.error("Select products first");
            throw new Error("Select products first");
        }

        const parseData: EditMM = {
            id: id as any,
            option: detailQuery.data?.option,
            order_id: detailQuery.data?.order_id || null,
            product: products?.map((product) => ({
                order_id: product.order_id,
                product_id: product?.product_id,
                locator_from: [{ area_id_from: product?.area_id, qty_from: product?.qty_from }],
                locator_to: [
                    {
                        area_id_to:
                            locatorToQuery.data?.find((loc) => loc.label === product?.locator_to || loc.value === product?.locator_to)?.value || "",
                        qty_to: product?.qty_to,
                    },
                ],
            })),
        };

        const emptyLoc = parseData.product?.reverse()?.filter((product) => {
            const filter = product.locator_to?.filter((loc) => {
                if (!loc.area_id_to) {
                    message.error(`Locator to can't be empty on product ${products?.find((p) => p.product_id === product.product_id)?.product_name}`);
                    return loc;
                }
                return null;
            });
            if (filter?.length) return filter;
            return null;
        });

        if (emptyLoc?.length) throw new Error(`Locator to can't be empty on product`);

        return parseData;
    };

    const onSaveHandler = async () => {
        try {
            const editData = prepareSave();
            // if (status && Number(status) === MATERIAL_MOVEMENT_DRAFT) {
            //     saveDraftMutate.mutate(editData);
            //     return;
            // }
            saveMutate.mutate(editData);
        } catch (e: any) {
            console.log(e?.message);
        }
    };

    const onDraftHandler = async () => {
        try {
            const editData = prepareSave();
            saveDraftMutate.mutate(editData);
        } catch (e: any) {
            console.log(e?.message);
        }
    };

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const isEditing = (record: ProductTransfer, edited: ProductTransfer | null) => record.product_id === edited?.product_id;
    const findIndexSave = (record: ProductTransfer, edited: ProductTransfer) => record.product_id === edited.product_id;
    const rowKey = (record: ProductTransfer) => record.product_id! as any;

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="Detail material movement doc"
                rightAddition={() => (
                    <Space>
                        {status && Number(status) === MATERIAL_MOVEMENT_DRAFT && (
                            <Button loading={saveDraftMutate.isLoading} onClick={onDraftHandler} className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Draft
                            </Button>
                        )}
                        {status && Number(status) === MATERIAL_MOVEMENT_DRAFT && (
                            <Button loading={saveMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        )}
                        {status && Number(status) !== MATERIAL_MOVEMENT_DRAFT && (
                            <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                                <AiFillPrinter className="m-0 mr-2" />
                                Print
                            </Button>
                        )}
                    </Space>
                )}
            />
            <Card>
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-3 gap-4">
                                    <p className="capitalize m-0">
                                        Material Movement No <br />
                                        <span className="italic m-0">{detailQuery.data?.mm_no || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse From <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse To <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Options <br />
                                        <span className="underline italic m-0">
                                            {MATERIAL_MOVEMENT_OPTIONS?.find((opt) => opt.value === detailQuery.data?.option)?.label}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Locator From <br />
                                        <span className="italic m-0">{detailQuery.data?.area_from || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 4 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: (
                                <TableEditProduct
                                    isEditing={isEditing}
                                    findIndexSave={findIndexSave}
                                    rowKey={rowKey}
                                    canRemove={false}
                                    cellProps={{
                                        selectProps: {
                                            options: locatorToQuery?.data?.map((loc) =>
                                                products.find((product) => product.area_id === loc.value) ? { ...loc, disabled: true } : loc
                                            ),
                                            loading: locatorToQuery?.isLoading,
                                            className: "!w-[150px]",
                                            showSearch: true,
                                            optionFilterProp: "label",
                                            placeholder: "Locator",
                                        },
                                    }}
                                    loading={detailQuery.isLoading}
                                    list={products?.map((p) => ({
                                        ...p,
                                        locator_to: locatorToQuery.data?.find((loc) => loc.value === p.locator_to || loc.label === p.locator_to)
                                            ?.label,
                                    }))}
                                    onSetList={setProducts}
                                    action={asPreview ? false : Number(status) === MATERIAL_MOVEMENT_DRAFT}
                                />
                            ),
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <ModalHistoryMM idHistory={detailHistoryID} option={detailQuery.data?.option}>
                                    {(dt) => (
                                        <HistoryTable
                                            onClickLink={(mId: number) => {
                                                setDetailHistoryID(mId);
                                                dt.openModal();
                                            }}
                                            fetcher={historyQuery}
                                        />
                                    )}
                                </ModalHistoryMM>
                            ),
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default MaterialMovementDocEdit;

/* eslint-disable no-shadow */
import { Select } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { SelectOption } from "models";
import FilterQuantity from "modules/master-data/quantity/index/filter";
import QuantityTable from "modules/master-data/quantity/index/table";
import SuppliesQuantityTable from "modules/purchasing/supplies-quantity/table-index";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";
import suppliesQuantityService from "services/api-endpoints/purchasing/supplies-quantity";

const filter = ["product_name", "product_code", "molding_code", "brand_id", "model_id", "model_type_id", "model_type_name", "area_id"];

const SuppliesQuantity = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const warehouse = searchParams.get("warehouse_id");

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const changeWarehouse = ({ id, page }: { id: any; page: string }) => {
        filter.forEach((ftr) => searchParams.delete(ftr));
        searchParams.set("warehouse_id", id);
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [suppliesQuantityService.getSuppliesWH],
        async () => {
            return (await suppliesQuantityService.GetSuppliesWH()).data.data?.map(
                (el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption)
            );
        },
        {
            onSuccess: (data) => {
                if (warehouse) return;
                changeWarehouse({ id: data[0].value, page: "1" });
            },
        }
    );

    const getList = useQuery(
        [suppliesQuantityService.getAll, page, searchValue, filterValue, isFilter, isSearch, warehouse],
        async () => {
            return (await suppliesQuantityService.GetAll({ param_search: searchValue.query || "", page, warehouse_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    useEffect(() => {
        (() => {
            if (warehouse) return;
            getWarehouse.refetch();
        })();
    }, [warehouse]);

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterQuantity filter={filter} warehouseId={warehouse}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        filter={false}
                        prefixAddition={() => (
                            <Select
                                className="!w-[400px]"
                                showSearch
                                value={warehouse ? Number(warehouse) : ""}
                                loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                                placeholder="Warehouse"
                                options={getWarehouse.data || []}
                                onChange={(val) => changeWarehouse({ id: val, page: "1" })}
                            />
                        )}
                        placeholder="Supplies Name"
                        defaultParams={["warehouse_id"]}
                    />
                )}
            </FilterQuantity>
            <SuppliesQuantityTable fetcher={getList} />
        </div>
    );
};

export default SuppliesQuantity;

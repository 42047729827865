import { Alert, Input, Pagination } from "antd";
import StateRender from "components/common/state-render";
import { BasePaginationResponse } from "models";
import CardVariableItem from "modules/manufacture/card/variable/item";
import CardVariableLoading from "modules/manufacture/card/variable/loading";
import PriceTag from "modules/manufacture/components/price-tag";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, VariableEntity } from "modules/manufacture/utils/models";
import React from "react";
import { PiPuzzlePieceBold } from "react-icons/pi";
import { useQuery } from "react-query";
import manufactureMasterVariableService from "services/api-endpoints/dashboard/manufacture/master-variable";

const ListVariable = () => {
    const { menu } = React.useContext(ManufactureContext) as any;
    const [params, setParams] = React.useState({
        page: 1,
        paramSearch: "",
        queryValue: "",
    });

    const variableQuery = useQuery(
        [manufactureMasterVariableService.getAll, params.page, params.paramSearch],
        async () => {
            return (await manufactureMasterVariableService.GetAll({ page: params.page, param_search: params.paramSearch })).data
                .data as BasePaginationResponse<VariableEntity>;
        },
        {
            enabled: menu === MenuTypes.variable.id,
        }
    );

    const onChangePage = (page: number) => {
        setParams((prev) => ({
            ...prev,
            page,
        }));
    };

    const onChangeSearch = (e: any) => {
        const query = e.target.value;
        setParams((prev) => ({
            ...prev,
            queryValue: query,
        }));
    };

    const onSearch = (query: string) => {
        setParams((prev) => ({
            page: 1,
            queryValue: query,
            paramSearch: query,
        }));
    };

    if (menu !== MenuTypes.variable.id) return null;
    return (
        <>
            {menu === MenuTypes.variable.id ? (
                <div className="flex flex-col pt-4 pb-2 gap-3 w-full relative">
                    <StateRender data={variableQuery.data} isLoading={variableQuery.isLoading} isError={variableQuery.isError}>
                        <StateRender.Data>
                            {!variableQuery.data?.list?.length ? (
                                <div className="w-full h-full flex items-center flex-col justify-center">
                                    <PiPuzzlePieceBold className="text-7xl text-gray-300" />
                                    <p className="m-0 text-gray-300 text-xl font-medium">No variable listed</p>
                                </div>
                            ) : null}
                            <div className="w-full flex gap-2 py-2 overflow-x-auto">
                                {variableQuery.data?.list.map((item) => (
                                    <CardVariableItem title={item.text} key={item.id} attach={{ data: item } as Entity<VariableEntity>}>
                                        <div className="flex flex-col w-full px-2 min-w-[150px]">
                                            <PriceTag price={item.price} />
                                            <span className="line-clamp-2 text-xs mt-1 break-words">{item.text}</span>
                                        </div>
                                    </CardVariableItem>
                                ))}
                            </div>
                        </StateRender.Data>
                        <StateRender.Loading>
                            <div className="w-full flex gap-2">
                                <CardVariableLoading />
                                <CardVariableLoading />
                                <CardVariableLoading />
                            </div>
                        </StateRender.Loading>
                        <StateRender.Error>
                            <Alert message={(variableQuery.error as any)?.message} type="error" showIcon />
                        </StateRender.Error>
                    </StateRender>
                    <div className="flex justify-between items-center w-full">
                        <div className="w-[300px]">
                            <Input.Search
                                value={params.queryValue}
                                onChange={onChangeSearch}
                                onSearch={onSearch}
                                allowClear
                                placeholder="find variable..."
                                className="flex-1"
                            />
                        </div>
                        <Pagination current={params.page} onChange={onChangePage} total={variableQuery.data?.total_data} size="small" pageSize={20} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ListVariable;

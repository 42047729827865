import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { ORDER_TYPE_LIST_ORDER, STATUS_ORDER, STATUS_PAYMENT_ORDER, VIEW_PATH } from "utils/constant";
import ButtonAccessView from "components/button/button-access-view";
import { TDataOrder } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const colorTag = ["purple", "blue", "magenta", "green"];

const OrderTable = <T extends TDataOrder>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: TDataOrder) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        // {
        //     width: "120px",
        //     title: "Order No",
        //     dataIndex: "order_no",
        //     render: (text) => <p className="capitalize m-">{text || "-"}</p>,
        // },
        {
            title: "Invoice No",
            dataIndex: "invoice_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Order Reference",
            dataIndex: "invoice_reference",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Order Type",
            dataIndex: "type",
            render: (text) => {
                const type = ORDER_TYPE_LIST_ORDER.find((t) => t.value === text);
                if (!type) return "";
                return <Tag color={colorTag[type?.value || 0]}>{type?.label}</Tag>;
            },
        },
        {
            title: "Client Name",
            dataIndex: "client_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "order_status",
            render: (_, record) => <p className="capitalize m-0">{STATUS_ORDER.find((el) => el.value === record.order_status)?.label}</p>,
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: (_, record) => <p className="capitalize m-0">{record.grand_total?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Payment Status",
            dataIndex: "payment_status",
            render: (_, record) => (
                <p className="capitalize m-0">
                    {record.payment_status === null
                        ? STATUS_PAYMENT_ORDER[0].label
                        : STATUS_PAYMENT_ORDER.find((el) => el.value === (record.payment_status as any))?.label}
                </p>
            ),
        },
        {
            width: "200px",
            title: "Last Activity",
            dataIndex: "last_activity",
            fixed: "right",
            render: (text) => {
                if (!text) return "-";
                return (
                    <p className="capitalize m-0">
                        {text?.split(" at ")[0]} <br /> <span className="text-gray-400 text-xs">{text?.split(" at ")[1]}</span>
                    </p>
                );
            },
        },
        {
            width: "100px",
            title: "Create Date",
            dataIndex: "date",
            fixed: "right",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default OrderTable;

import { Button } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterProduct from "modules/master-data/product/index/filter";
import { TDataProduct } from "modules/master-data/product/models";
import { TDataProductPrint } from "modules/print/barcode/models";
import BarcodeProductTable from "modules/print/barcode/table";
import ToPrintProductTable from "modules/print/barcode/to-print-table";
import React, { useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import productService from "services/api-endpoints/dashboard/master-data/product";

const Preview = React.lazy(() => import("modules/print/barcode/preview"));

const filter = ["product_name", "product_code", "molding_code", "brand_name", "model_name", "model_type", "is_active"];

const Barcode = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const [productsToPrint, setProductsToPrint] = useState<TDataProductPrint[]>([]);

    const isAnyProductToPrint = productsToPrint.length;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([productService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await productService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await productService.Filter(filterValue as any)).data.data;
        return (await productService.GetAll({ page, attribute_id: 0, model_type_id: 0, style_id: 0, subattribute_id: 0, subcategory_id: 0 })).data
            .data;
    });

    const addProductToPrint = (product: TDataProductPrint) => {
        setProductsToPrint((prev) => {
            if (prev.find((p) => p.id === product.id)) return prev;
            return [...prev, product];
        });
    };

    const removeProductToPrint = (product: TDataProduct | TDataProductPrint) => {
        setProductsToPrint((prev) => prev.filter((p) => p.id !== product.id));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterProduct filter={filter} filterValue={filterValue}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Product Name / Product Code / Molding Code / Brand / Model"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <>
                                {isAnyProductToPrint ? (
                                    <React.Suspense fallback={<p className="m-0 p-1">Loading...</p>}>
                                        <Preview list={productsToPrint}>
                                            {(preview) => (
                                                <Button onClick={preview.openModal} type="primary" className="!flex !items-center !font-semibold">
                                                    <AiFillPrinter className="mr-3" />
                                                    Print
                                                </Button>
                                            )}
                                        </Preview>
                                    </React.Suspense>
                                ) : null}
                            </>
                        )}
                    />
                )}
            </FilterProduct>
            <div className="w-full flex gap-5">
                <BarcodeProductTable
                    addProductToPrint={addProductToPrint}
                    removeProductToPrint={removeProductToPrint}
                    productsToPrint={productsToPrint}
                    fetcher={getList}
                    className={isAnyProductToPrint ? "w-[50%]" : "w-full"}
                />
                {isAnyProductToPrint ? (
                    <ToPrintProductTable
                        setProductsToPrint={setProductsToPrint}
                        removeProductToPrint={removeProductToPrint}
                        productsToPrint={productsToPrint}
                        className={isAnyProductToPrint ? "w-[48%]" : "w-full"}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default Barcode;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import masterBpService from "services/api-endpoints/accounting/master-bp";
import MasterBPTable from "modules/accounting/master-bp/index/table";
import FilterBP from "modules/accounting/master-bp/index/filter";

const filter = [""];

const MasterBP = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getAll = useQuery([masterBpService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await masterBpService.Search({ page, query: searchValue.query })).data.data; // search
        // if (isFilter) return (await masterBpService.Filter(filterValue as any)).data.data; // filter
        return (await masterBpService.GetAll({ page })).data.data; // all
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBP filter={filter}>
                {(dt) => <Toolbar placeholder="Search..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBP>
            <MasterBPTable fetcher={getAll} />
        </div>
    );
};

export default MasterBP;

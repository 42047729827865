import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import QuantityView from "pages/quantity/view";
import React from "react";

export type Props = ModalProps & {
    warehouseId: any;
    areaId: any;
    productId: any;
};

const ModalDetailQuantityStock = ({ warehouseId, areaId, productId, children, ...props }: Props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <ModalTemplate isModalOpenFn={(val) => setOpen(val)} width={1200} {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    {open && <QuantityView defaultAreaID={areaId} defaultWHID={warehouseId} defaultProductID={productId} />}
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalDetailQuantityStock;

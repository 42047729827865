/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Checkbox, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BaseJurnalResponse, JurnalUmum, Type } from "services/api-endpoints/accounting/jurnal-umum";
import { FORMAT_DATE_TIME, ORDER_CANCELED, TYPE_JURNAL } from "utils/constant";
import { jurnalTableToExcel } from "../utils";

type Props<T> = {
    fetcher: UseQueryResult<BaseJurnalResponse<T>, unknown>;
    typeJurnal: string;
};

const { confirm } = Modal;

const PostedTable = <T extends JurnalUmum>({ fetcher, typeJurnal }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDateFilter = searchParams.get("start_date");
    const endDateFilter = searchParams.get("end_date");

    const [list, setList] = useState<T[]>([]);
    const [page, setPage] = useState<number>(1);
    const [canPos, setCanPos] = useState<Boolean>(false);

    useEffect(() => {
        setList(fetcher.data?.list_posted || []);
        const canpos =
            !!fetcher.data?.list_posted &&
            fetcher.data?.list_posted?.length !== 0 &&
            fetcher.data?.list_posted?.filter((el) => el.is_posting).length === fetcher.data?.list_posted?.length;
        setCanPos(canpos);
    }, [fetcher.data]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const onChangeCheckbox = (record: JurnalUmum) => {
        return () => {
            setList((prev) => {
                const curr = prev?.map((el) => (el.id === record.id ? { ...el, is_posting: !el.is_posting } : el));
                setCanPos(curr.filter((el) => el.is_posting).length > 0);
                return curr;
            });
        };
    };

    const onDownloadJurnal = () => {
        const dateFilter = `${startDateFilter?.split("-").join("")}_${endDateFilter?.split("-").join("")}`;
        jurnalTableToExcel({
            title: `${TYPE_JURNAL.find((val) => val.value === typeJurnal)?.label || ""}.${dateFilter}`,
            listJurnal: list,
        });
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            className: "h-[1px]",
            render: (text, record, i) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>
                </div>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>
                </div>
            ),
        },
        {
            title: "Account",
            dataIndex: "account",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0 font-semibold">{text || "-"}</p>
                </div>
            ),
        },
        {
            title: "Ref ID",
            dataIndex: "doc_no",
            className: "h-[1px]",
            render: (text, record) => {
                return (
                    <div className="w-full h-full flex items-start">
                        <ModalRefVoucher title="Detail Referensi" id={record?.doc_id} type={record.doc_type} type_={record.type}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small" danger={record?.status === ORDER_CANCELED}>
                                    {text}
                                </Button>
                            )}
                        </ModalRefVoucher>
                    </div>
                );
            },
        },
        {
            title: "Account Name",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            // <p className="m-0 mb-2 font-semibold">{item?.name?.toUpperCase()}</p>
                            <p className="m-0 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        )}
                    </div>
                ));
            },
        },
        {
            title: "Debit",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
        {
            className: "h-[1px]",
            title: "Kredit",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Kredit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
        {
            width: "90px",
            title: "Status",
            key: "status",
            fixed: "right",
            dataIndex: "is_posting",
            className: "",
            render: (_, record) => {
                const checked = list?.find((el) => el.id === record.id)?.is_posting;
                return (
                    <>
                        {record.is_acc_posted === 0 ? (
                            <Checkbox checked={!!checked} onChange={onChangeCheckbox(record)}>
                                {checked ? "Posted" : "Unposted"}
                            </Checkbox>
                        ) : (
                            <Checkbox checked disabled>
                                Posted
                            </Checkbox>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className="flex flex-col gap-5">
            <div className="w-full flex items-center justify-between">
                <div />
                <Space>
                    <Button onClick={onDownloadJurnal} disabled={list.length === 0} className="w-fit">
                        <AiOutlineDownload className="m-0 mr-2" />
                        Download Jurnal
                    </Button>
                </Space>
            </div>
            <Table
                rowKey={(record) => record.id!}
                size="small"
                loading={fetcher.isLoading}
                columns={columns}
                dataSource={list || []}
                className="w-full"
                pagination={{
                    current: page,
                    pageSize: 20,
                    showSizeChanger: false,
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default PostedTable;

import { MenuTypes, cardVariableItemInfo } from "modules/manufacture/utils/constant";
import { Entity, VariableEntity } from "modules/manufacture/utils/models";
import React from "react";
import { useDrag } from "react-dnd";
import ShapeVariable from "./shape";

type CardVariableItemProps = React.HTMLProps<HTMLDivElement> & {
    attach?: Entity<VariableEntity>;
};

const CardVariableItem = ({ className, attach, ...props }: CardVariableItemProps) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.variable.type,
            item: { ...MenuTypes.variable, ...attach },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <ShapeVariable
            ref={drag as any}
            backgroundColor={cardVariableItemInfo.style.color}
            className={`cursor-grab !w-[150px] text-white ${isDragging ? "opacity-40" : ""} ${className}`}
            {...props}
        />
    );
};

export default CardVariableItem;

import { Button, Card, message, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductTable from "modules/dashboard-jsl/sales-order/edit/product-table";
import salesOrderService, {
    CreateSOData,
    DeleteData,
    EditDetailProduct,
    EditSOData,
    ProductSO,
} from "services/api-endpoints/dashboard-jsl/sales-order";
import ModalPOTable from "modules/dashboard-jsl/sales-order/add/modal-po-table";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { BiSave, BiTrash } from "react-icons/bi";
import { getDocumentRoute } from "utils/routes";
import { STATUS_PURCHASE_ORDER } from "utils/constant";
import ModalDelete from "components/modal/delete_modal";
import ModalNewPo from "components/modal/new_po_modal";

const SalesOrderEdit = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const id = searchParams.get("id");
    const [productsPO, setProductsPO] = useState<ProductSO[]>([]);
    const [deletedPO, setDeletedPO] = useState<ProductSO[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);

    const detailQuery = useQuery(
        [salesOrderService.detail, id],
        async () => {
            return (await salesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProductsPO(data.list_detail || []);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editDraftSoMutation = useMutation(
        [salesOrderService.draftSO],
        async (data: EditSOData) => {
            return (await salesOrderService.EditDraftSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order draft edited");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const editSoMutation = useMutation(
        [salesOrderService.createSO],
        async (data: EditSOData) => {
            return (await salesOrderService.EditSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order edited");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const editSOData: EditSOData = {
        id: id as any,
        type: 1,
        bp_id: detailQuery.data?.request_from_id,
        payment_term_id: detailQuery.data?.payment_term_id,
        child_document: [...new Set(productsPO?.map((p) => p.po_id!) || [])],
        detail_product: [
            ...([...productsPO]?.map(
                (p) =>
                    ({
                        po_id: p.po_id,
                        product_id: p.product_id,
                        qty: p.qty,
                        price: p.price,
                        order_detail_id: p?.order_detail_id || null,
                    } as EditDetailProduct)
            ) || []),
        ],
    };

    const mergeSOData = {
        ...editSOData,
        detail_product: [
            ...editSOData.detail_product!,
            ...(deletedPO || []).map((el) => ({ order_detail_id: el.order_detail_id, is_active: 0 } as EditDetailProduct)),
        ],
    };

    const onSaveHandler = (typeData: number) => {
        const edit2SOData: EditSOData = {
            id: id as any,
            type: typeData,
            bp_id: detailQuery.data?.request_from_id,
            payment_term_id: detailQuery.data?.payment_term_id,
            child_document: [...new Set(productsPO?.map((p) => p.po_id!) || [])],
            detail_product: [
                ...([...productsPO]?.map(
                    (p) =>
                        ({
                            po_id: p.po_id,
                            product_id: p.product_id,
                            qty: p.qty,
                            price: p.price,
                            order_detail_id: p?.order_detail_id || null,
                        } as EditDetailProduct)
                ) || []),
            ],
        };
        const merge2SOData = {
            ...edit2SOData,
            detail_product: [
                ...editSOData.detail_product!,
                ...(deletedPO || []).map((el) => ({ order_detail_id: el.order_detail_id, is_active: 0 } as EditDetailProduct)),
            ],
        };
        editSoMutation.mutate(merge2SOData);
    };

    const onSave1Handler = () => {
        editSoMutation.mutate(mergeSOData);
    };

    const onDraftHandler = () => {
        editDraftSoMutation.mutate(mergeSOData);
    };

    // const onRemoveProduct = (product: ProductSO) => {
    //     setDeletedPO((prev) => {
    //         if (product.order_detail_id) return [...prev, product];
    //         return prev;
    //     });
    //     setProductsPO((prev) => {
    //         return prev
    //             .map((el) => {
    //                 if (el?.product_id === product?.product_id && el?.order_detail_id === product?.order_detail_id) return {};
    //                 return el;
    //             })
    //             .filter((el) => el.product_id);
    //     });
    // };

    const onRemoveProduct = (product: ProductSO) => {
        setProductsPO((prev) => {
            return prev
                .map((el) => {
                    if (el.product_id === product.product_id && el.order_detail_id === product.order_detail_id)
                        return {
                            order_detail_id: product.order_detail_id,
                            product_id: product.product_id,
                            product_name: product.product_name,
                            product_code: product.product_code,
                            price: product.price,
                            product_price: product.product_price,
                            qty: 0,
                            qty_request: product.qty_request,
                            po_id: product.po_id,
                        } as ProductSO;
                    return el;
                })
                .filter((el) => el.product_id);
        });
    };
    const onProductPO = (data: ProductSO[]) => {
        setProductsPO((prev) => {
            const newData = data.map((el) => ({ ...el, qty_request: el.qty! }));
            if (prev.length === 0) return newData;
            const setNewData = newData.filter(
                (product) =>
                    !prev.find(
                        (prevProduct) => prevProduct.product_id === product.product_id && prevProduct.order_detail_id === product.order_detail_id
                    )
            );
            return [...prev, ...setNewData];
        });
    };

    const deletePOMutation = useMutation(
        [salesOrderService.deleteSO],
        async (data: DeleteData) => {
            return (await salesOrderService.DeleteSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order deleted");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const onDeletePOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePOMutation.mutate(dataDelete);
    };

    const onFilter: ProductSO[] = productsPO.filter((el) => el.qty !== el.qty_request);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Sales order detail"
                rightAddition={() => (
                    <>
                        <Button
                            loading={editDraftSoMutation.isLoading}
                            disabled={productsPO.length === 0}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <ModalDelete title="Delete SO" onSubmit={onDeletePOHandler}>
                            {(dt) => (
                                <Button
                                    loading={deletePOMutation.isLoading}
                                    disabled={detailQuery.data?.order_status === 2}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                        <ModalNewPo title="Konfirmasi" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button
                                    onClick={onFilter.length === 0 ? onSave1Handler : dt.openModal}
                                    type="primary"
                                    className="!flex !items-center w-fit"
                                    disabled={productsPO.length === 0}
                                >
                                    <BiSave className="mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNewPo>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        sales order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.so_number}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span className="underline italic m-0">
                                            {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        term of payment <br />
                                        <span className="underline italic m-0">{detailQuery.data?.payment_term_code}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <ModalPOTable
                onProductPO={onProductPO}
                warehouse={detailQuery.data?.request_from_id || null}
                selectedPO={selectedPO}
                setSelectedPO={setSelectedPO}
            >
                {(dt) => (
                    <Button
                        disabled={detailQuery.data?.request_from_id === undefined}
                        className="!flex !items-center w-fit"
                        type="primary"
                        onClick={dt.openModal}
                    >
                        <MdAdd className="mr-2" />
                        Data PO
                    </Button>
                )}
            </ModalPOTable>
            <ProductTable loading={detailQuery.isLoading} list={productsPO} setList={setProductsPO} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default SalesOrderEdit;

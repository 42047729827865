import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import Utils from "utils";

type Props<T> = {
    setValue: UseFormSetValue<any>;
    watchValue?: UseFormWatch<any>;
    errors?: { [key: string]: { message: string; ref: any; type: string } };
    onChange?: () => void;
};

export type ReturnType = {
    handleChange: (name: string, file: File | null) => void;
    errors?: { [key: string]: { message: string; ref: any; type: string } };
    watchValue?: UseFormWatch<any>;
};

const useControlFile = <T>({ setValue, errors, onChange, watchValue }: Props<T>) => {
    const handleChange = (name: string, file: File | null) => {
        if (!file) {
            setValue(name, "");
            return;
        }
        Utils.getBase64(file, (url) => {
            setValue(name, url);
        });

        if (onChange) onChange();
    };

    const control: ReturnType = {
        handleChange,
        errors,
        watchValue,
    };
    return [control];
};

export default useControlFile;

import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { EDIT_PATH, STATUS_PURCHASE_ORDER, STATUS_PURCHASE_ORDER_INT, VIEW_PATH } from "utils/constant";
import { BasePaginationResponse } from "models";
import ButtonAccessView from "components/button/button-access-view";
import ButtonAccessEdit from "components/button/button-access-edit";
import { RequestIntPOData } from "services/api-endpoints/dashboard-jsl/purchase-order";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: RequestIntPOData) => void;
};

const colorTag = ["purple", "blue", "green", "magenta"];

const RequestIntPOTable = <T extends RequestIntPOData>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: RequestIntPOData) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            width: "250px",
            title: "PO No",
            dataIndex: "purchase_order_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Request To",
            dataIndex: "request_to",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "150px",
            title: "Status Order",
            dataIndex: "status_order",
            render: (text) => {
                const type = STATUS_PURCHASE_ORDER_INT.find((el) => el.value === text);
                if (!type) return "-";
                if (type.value === -1) return <Tag color="red">{type?.label}</Tag>;
                return <Tag color={colorTag[type?.value || 0]}>{type?.label}</Tag>;
            },
        },
        {
            width: "150px",
            title: "Date",
            dataIndex: "date",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "150px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessView onClick={() => viewHandler(record)} type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1300 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default RequestIntPOTable;

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import React from "react";
import { STATUS_POPULAR } from "utils/constant";
import { CreateDataBrand } from "../models";

export function withIsPopular<T extends ControlledInputSelectProps<CreateDataBrand>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        return <Component {...props} optionFilterProp="children" options={STATUS_POPULAR} />;
    };
}

const IsPopularSelect = withIsPopular(ControlledSelectInput);

export default IsPopularSelect;

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Card, Form, message, Space, Alert } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import { CreateDataDocInventory, ProductDocInv, ProductDocInventory } from "modules/master-data/doc-inventory/models";
import docInventoryService from "services/api-endpoints/dashboard/master-data/doc-inventory";
import DocTypeSelect from "modules/master-data/doc-inventory/lib/doc-type-select";
import Transfer from "modules/master-data/doc-inventory/lib/transfer";
import WarehouseSelect from "modules/master-data/doc-inventory/lib/warehouse-select";
import LocatorTable from "modules/master-data/doc-inventory/lib/table";

const schema: yup.SchemaOf<Partial<CreateDataDocInventory>> = yup.object().shape({
    type_stock_opname: yup.string().required("Type Stock Opname required"),
    note: yup.string().required("Note required"),
    warehouse_id: yup.number().required("Warehouse required"),
    product: yup.array(),
});

const DocInventoryAdd = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductDocInventory[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<CreateDataDocInventory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [docInventoryService.create],
        async (data: CreateDataDocInventory) => {
            return (await docInventoryService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Inventory Transfer Success");
                navigate(-1);
            },
        }
    );

    const onRemoveProduct = (product: ProductDocInventory) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onSaveHandler = async () => {
        setValue("product", products);
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add inventory transfer"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={products.length === 0}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="max-w-2xl">
                <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                    <h1 className="capitalize font-semibold text-gray-500 mb-4">Inventory information</h1>
                    <WarehouseSelect classNameForm="col-span-2" control={control} name="warehouse_id" placeholder="Warehouse" />
                    <DocTypeSelect classNameForm="col-span-2" control={control} name="type_stock_opname" placeholder="Doc Type" />
                    <ControlledInputTextArea control={control} labelCol={{ xs: 12 }} name="note" placeholder="Note" className="h-full" rows={4} />
                </Form>
            </Card>
            <Transfer warehouseID={watch("warehouse_id")} selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button
                        className="!flex !items-center w-fit"
                        type="primary"
                        onClick={dt.openModal}
                        disabled={watch("warehouse_id") === undefined}
                    >
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </Transfer>
            <LocatorTable list={products} onRemove={onRemoveProduct} />
        </div>
    );
};

export default DocInventoryAdd;

import { Alert } from "antd";
import React from "react";

const DashboardSales = () => {
    return (
        <div className="w-full flex flex-col gap-6">
            <Alert showIcon message="Dashboard Sales Page" type="info" />
        </div>
    );
};

export default DashboardSales;

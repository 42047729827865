import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import AdvanceSerachExt from "modules/advance-serach-ext";
import ModalVoucher from "modules/dashboard-all/sales/add/modal-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { FDataCreateExtSO } from "modules/master-data/bp-order/models";
import ProductTable from "modules/master-data/external-order/ext-so/table-add";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import extSalesOrderService, {
    BusinessPartner,
    CreateSalesOrder,
    DetailProduct,
    SummaryPriceParam,
} from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<FDataCreateExtSO>> = yup.object().shape({
    bp_id: yup.number().required("Business partner required"),
    payment_term_id: yup.number().required("Term Of Payment required"),
    note: yup.string(),
});

const ExtSalesOrderAdd = () => {
    const navigate = useNavigate();

    const [products, setProducts] = useState<DetailProduct[]>([]);
    const [allProducts, setAllProducts] = useState<DetailProduct[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<FDataCreateExtSO>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchBpId = watch("bp_id");

    const getSummaryPrice = useMutation(
        [extSalesOrderService.summaryPrice],
        async (data: SummaryPriceParam) => {
            return (await extSalesOrderService.SummaryPrice(data)).data.data;
        },
        {
            onSuccess: (data) => {
                // const prodExist = products.filter((el) => !el.is_free);
                // const prodFree = (data?.free_product || []).map(
                //     (el) =>
                //         ({
                //             product_id: el.id,
                //             product_name: el.item,
                //             product_code: el.product_code,
                //             product_price: el.price,
                //             qty: el.qty,
                //             note: "FREE",
                //             hideEditAction: true,
                //             hideRemoveAction: true,
                //             isFree: true,
                //         } as ProductWithQty)
                // );
                setAllProducts(data.detail_product);
            },
        }
    );

    const createMutation = useMutation(
        [extSalesOrderService.create],
        async (data: CreateSalesOrder) => {
            return (await extSalesOrderService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Sales Order Created");
                navigate(-1);
            },
        }
    );

    const topQuery = useQuery([extSalesOrderService.paymentTerm, watchBpId], async () => {
        return (await extSalesOrderService.PaymentTerm()).data.data?.map(
            (el) => ({ label: el.payment_term_code, value: el.payment_term_id } as SelectOption)
        );
    });

    const getBP = useQuery([extSalesOrderService.getBp], async () => {
        const req = await extSalesOrderService.GetBp({ status: 0 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption & BusinessPartner));
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const parseData: SummaryPriceParam = {
        account_id_to: watchBpId,
        detail_product: [
            ...(products?.map(
                (p) => ({ price: p.product_price || p.price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)
            ) || []),
        ],
        voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
    };

    const onSubmitHandler = handleSubmit((data) => {
        const createData: CreateSalesOrder = {
            ...data,
            detail_product: [
                ...(allProducts?.map(
                    (p) =>
                        ({
                            product_id: p.product_id,
                            price: p.price,
                            discount_price: p.discount_price || 0,
                            voucher_id: p.voucher_id || 0,
                            note: p.note || "",
                            qty: p.qty,
                            is_free: p.is_free || 0,
                        } as DetailProduct)
                ) || []),
            ],
            voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
        };
        createMutation.mutate(createData);
    });

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onSetProduct = (list: any[], prevRow: any) => {
        const prodList = list.filter((el) => !el.isFree);
        setProducts([...prodList]);
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    useEffect(() => {
        if (!products.length) return;
        getSummaryPrice.mutate(parseData);
    }, [products, vouchers, watchBpId]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new Ext Sales Order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                        sales order no <br />
                                        <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                    </p>
                                    <p className="capitalize m-0 text-gray-700 mb-2">Voucher</p>
                                    <ModalVoucher vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                        {(dt) => (
                                            <div className="w-full flex flex-col gap-1">
                                                <Button
                                                    onClick={dt.openModal}
                                                    disabled={vouchers.length === 2}
                                                    className="!w-full !flex !items-center !justify-between"
                                                >
                                                    Voucher <MdAdd />
                                                </Button>
                                                <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                            </div>
                                        )}
                                    </ModalVoucher>
                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                        status <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">New</span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <ControlledSelectInput
                                        name="bp_id"
                                        label="BP Name"
                                        placeholder="BP Name"
                                        control={control}
                                        showSearch
                                        loading={getBP.isLoading}
                                        options={getBP.data || []}
                                        onChange={(val) => {
                                            const tempID = getBP.data?.find((val2) => val === val2.bp_id)?.payment_term_id;
                                            setValue("bp_id", val);
                                            setValue("payment_term_id", Number(tempID || 0));
                                        }}
                                    />
                                    <p className="capitalize m-0 text-gray-700">
                                        total price <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <ControlledSelectInput
                                        name="payment_term_id"
                                        label="Term Of Payment"
                                        placeholder="Term Of Payment"
                                        control={control}
                                        loading={topQuery.isLoading}
                                        options={topQuery.data || []}
                                    />
                                    <p className="capitalize m-0 text-gray-700">
                                        total discount <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <ControlledInputTextArea
                                        className="!w-[300px]"
                                        control={control}
                                        rows={2}
                                        name="note"
                                        label="Note"
                                        placeholder="Note"
                                    />
                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                        grand total <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Card>
            {getSummaryPrice.isError ? <Alert type="error" message={(getSummaryPrice.error as any)?.message} /> : null}
            <AdvanceSerachExt selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal} disabled={!watchBpId}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSerachExt>
            <ProductTable list={allProducts} setList={setAllProducts} removeItemList={onRemoveProduct} onSetList={onSetProduct} />
        </div>
    );
};

export default ExtSalesOrderAdd;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ProductTransfer } from "services/api-endpoints/dashboard/master-data/material-movement";

type Props<T> = Omit<EditTableProps<T>, "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends ProductTransfer>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);
        const [size, setSize] = useState(10);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
            setSize(pagination.pageSize || 10);
        };

        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * size + (i + 1)}</p>,
            },
            {
                dataIndex: "product_code",
                title: "Code",
                render: (text) => text,
            },
            {
                dataIndex: "product_name",
                title: "Name",
                render: (text) => text,
            },
            {
                title: "Qty Before",
                width: "100px",
                dataIndex: "qty_before",
                render: (text) => text,
            },
            {
                title: "Qty Scan",
                width: "100px",
                dataIndex: "qty_scan",
                render: (text) => text,
            },
            {
                title: "Qty Approved",
                width: "120px",
                dataIndex: "qty",
                ...{ editable: true },
                render: (text) => text || "-",
            },
        ];

        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 0,
                    ...props?.cellProps,
                }}
                columns={columns}
                onChange={handleTableChange}
            />
        );
    };
}

const TableViewProduct = tableEdit(EditTable);
export default TableViewProduct;

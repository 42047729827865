import React, { useContext } from "react";
import { VOUCHER_ITEM_LOGIC } from "utils/constant";
import { VoucherContext } from "../context/voucher";
import ProductTable from "../lib/product-table";

const DetailCondition = () => {
    const { state } = useContext(VoucherContext);

    return (
        <div className="w-full grid grid-cols-2 gap-5">
            <p className="TEXT-1 mt-4">
                logic <br />
                <span className="TEXT-2">{VOUCHER_ITEM_LOGIC.find((el) => el.value === state?.createData.logic)?.label}</span>
            </p>
            <p className="TEXT-1 mt-4">
                usage coupon per user <br />
                <span className="TEXT-2">{state?.createData?.usage_coupon_per_user}</span>
            </p>
            <div className="col-span-2">
                <ProductTable list={state?.createData.array_product || []} />
            </div>
        </div>
    );
};

export default DetailCondition;

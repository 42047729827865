/* eslint-disable jsx-a11y/label-has-associated-control */
import { Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { AllModelType, SelectOption } from "models";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";
import { STATUS_ACTIVE } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
    filterValue: { [key: string]: any };
};

const FilterProduct = ({ filter = [], filterValue, ...props }: Props) => {
    const [searchParams] = useSearchParams();
    const modelIdParam = searchParams.get("model_id");
    const modelTypeIdParam = searchParams.get("model_type_id");

    const modelNameQuery = useQuery([modelService.getAllModel], async () => {
        const req = await modelService.GetAllModel({ brand_id: 0 });
        return req.data.data?.map((el) => ({ label: el.model_name, value: String(el.id) } as SelectOption));
    });

    const modelTypeQuery = useMutation([modelTypeService.getAllModelType], async (modelId: number) => {
        const req = await modelTypeService.GetAllModelType({ model_id: modelId });
        return req.data.data?.map((el) => ({ ...el, label: el.name, value: String(el.type_id) } as SelectOption & AllModelType));
    });

    const subCategoryQuery = useMutation([subCategoryService.subCategory], async (modelType?: SelectOption & AllModelType) => {
        const req = await subCategoryService.SubCategory({
            model_id: modelType?.model_id,
            is_all_year: modelType?.model_year_id ? 0 : 1,
            type_id: modelType?.type_id,
        });
        return req.data.data?.subcategory_list?.map((el) => ({ label: el.subcategory_name, value: String(el.subcategory_id) } as SelectOption));
    });

    React.useEffect(() => {
        if (modelIdParam === undefined || modelIdParam === null) return;
        modelTypeQuery.mutateAsync(Number(modelIdParam)).then((data) => {
            const model = data?.find((val) => val.value === modelTypeIdParam);
            subCategoryQuery.mutate(model);
        });
    }, [modelIdParam]);

    return (
        <FilterModal
            filter={filter}
            title="Filter Product"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <label htmlFor="product_name">
                            Product Name
                            <Input
                                id="product_name"
                                placeholder="Product Name"
                                value={dt.values.product_name}
                                name="product_name"
                                onChange={(e) => dt.setOneValue("product_name", e.target.value)}
                            />
                        </label>
                        <label htmlFor="product_code">
                            Product Code
                            <Input
                                id="product_code"
                                placeholder="Product Code"
                                value={dt.values.product_code}
                                name="product_code"
                                onChange={(e) => dt.setOneValue("product_code", e.target.value)}
                            />
                        </label>
                        <label htmlFor="molding_code">
                            Molding Code
                            <Input
                                id="molding_code"
                                placeholder="Molding Code"
                                value={dt.values.molding_code}
                                name="molding_code"
                                onChange={(e) => dt.setOneValue("molding_code", e.target.value)}
                            />
                        </label>
                        <label htmlFor="model" className="flex flex-col">
                            Model
                            <Select
                                id="model"
                                showSearch
                                allowClear
                                value={dt.values.model_id}
                                loading={modelNameQuery.isLoading}
                                placeholder="Model"
                                options={modelNameQuery.data || []}
                                onChange={(value) => {
                                    dt.setOneValue("model_id", value);
                                    dt.setOneValue("model_type_id", undefined);
                                    dt.setOneValue("sub_category_id", undefined);
                                    if (value !== undefined || value !== null) modelTypeQuery.mutate(value);
                                }}
                            />
                        </label>
                        <label htmlFor="model_type" className="flex flex-col">
                            Model Type
                            <Select
                                id="model_type"
                                showSearch
                                allowClear
                                value={dt.values.model_type_id ? String(dt.values.model_type_id) : null}
                                loading={modelTypeQuery.isLoading}
                                placeholder="Model Type"
                                options={modelTypeQuery.data || []}
                                onChange={(value) => {
                                    const model = modelTypeQuery.data?.find((val) => val.value === value);
                                    dt.setOneValue("model_type_id", model?.type_id);
                                    dt.setOneValue("sub_category_id", undefined);
                                    if (value !== undefined || value !== null) subCategoryQuery.mutate(model);
                                }}
                            />
                        </label>
                        <label htmlFor="sub_category" className="flex flex-col">
                            Sub Category
                            <Select
                                id="sub_category"
                                showSearch
                                allowClear
                                value={dt.values.sub_category_id ? String(dt.values.sub_category_id) : null}
                                loading={subCategoryQuery.isLoading}
                                placeholder="Sub Category"
                                options={subCategoryQuery.data || []}
                                onChange={(value) => dt.setOneValue("sub_category_id", value)}
                            />
                        </label>
                        <label htmlFor="status" className="flex flex-col">
                            Status
                            <Select
                                id="status"
                                allowClear
                                value={
                                    dt.values.is_active !== null && dt.values.is_active !== undefined
                                        ? Number(dt.values.is_active)
                                        : dt.values.is_active
                                }
                                placeholder="Status"
                                options={STATUS_ACTIVE}
                                onChange={(value) => dt.setOneValue("is_active", value)}
                            />
                        </label>
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterProduct;

import { Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UseMutationResult, UseQueryResult } from "react-query";
import { DetailGetRoleMenu } from "services/api-endpoints/dashboard-all/user-management/user";
import { STATUS_ACTIVE } from "utils/constant";

type Props<T> = {
    data: DetailGetRoleMenu[];
    fetcher: UseQueryResult<T, unknown>;
    onChangeActive: (roleMenuID: number | undefined, is_active: number) => void;
};

const TableEdit = <T extends DetailGetRoleMenu[]>({ data, fetcher, onChangeActive }: Props<T>) => {
    const columns: ColumnsType<DetailGetRoleMenu> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "App Name",
            dataIndex: "apps_name",
            render: (text, record, i) => (
                <p className="capitalize m-0">
                    {text} {!record.id && <Tag color="green">New</Tag>}
                </p>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "List Menu",
            dataIndex: "list_menu",
            render: (text) => {
                const menu = text?.split(",");
                return (
                    <div>
                        {menu?.map((val: any) => (
                            <Tag color="blue">{val}</Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            width: "150px",
            title: "Is Action",
            key: "is_active",
            render: (text, record) => (
                <Select
                    className="w-full"
                    value={record.is_active}
                    options={STATUS_ACTIVE}
                    placeholder="Is Active"
                    onChange={(val) => onChangeActive(record.role_menu_id, val)}
                />
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.role_menu_id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={data}
            className="w-full"
            pagination={false}
        />
    );
};

export default TableEdit;

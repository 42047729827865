import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GrInventory, PurchasingInventory } from "services/api-endpoints/purchasing/inventory";
import { FORMAT_DATE_2, FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const TableIndex = <T extends GrInventory>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: PurchasingInventory) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 15 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "gr_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "PO No",
            dataIndex: "po_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "GR Date",
            dataIndex: "gr_date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            title: "TRX Date",
            dataIndex: "transaction_date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_2) : "-"}</p>,
        },
        {
            title: "Vendor",
            dataIndex: "vendor",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Request By",
            dataIndex: "request_by",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessView onClick={() => viewHandler(record)} type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 15,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableIndex;

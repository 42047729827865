/* eslint-disable no-lonely-if */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Steps, Upload } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import docContainerService, { CreateDocContainer, DetailProduct, UploadData } from "services/api-endpoints/dashboard/doc-container";
import { LocatorPI } from "services/api-endpoints/dashboard/master-data/physical-inventory";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import { v4 as uuid } from "uuid";
import * as yup from "yup";
import ValidasiLocatorTable from "../physical-inventory/index/validasi-locator-table";

export type Props = ModalProps & {
    onSuccess: () => void;
};

const schema: yup.SchemaOf<UploadData> = yup.object().shape({
    file: yup.object(),
    warehouse_id: yup.number().required("Type Required"),
});

const ModalUploadDocContainer = ({ children, onSuccess, ...props }: Props) => {
    const [progress, onUploadProgress] = useProgressUpload();
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [step, setStep] = useState(0);
    const [list, setList] = useState<(DetailProduct & LocatorPI)[]>([]);

    const [form] = Form.useForm();
    const { watch, control } = useForm<UploadData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const warehouseId = watch("warehouse_id");

    const getWarehouse = useQuery([priceService.warehouse], async () => {
        return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
    });

    const uploadMutate = useMutation(
        async (data: FormData) => {
            return (await docContainerService.Upload(data as any, { onUploadProgress })).data.data;
        },
        {
            onSuccess(data) {
                setList(data.detail_product?.map((val) => ({ ...val, id: uuid() })) as any);
                if ((data.detail_product || []).length === 0) {
                    message.success("Upload file success");
                    form.resetFields();
                    onSuccess();
                    closeRef.current?.click();
                } else {
                    setStep(1);
                }
            },
        }
    );

    const createDoc = useMutation(async (data: CreateDocContainer) => {
        return (await docContainerService.Create(data)).data.data;
    });

    const onFinish = (values: any) => {
        if (!warehouseId) {
            message.error("Select warehouse!");
            return;
        }

        const formData = new FormData();
        formData.append("file", values.file[0]?.originFileObj);
        formData.append("warehouse_id", warehouseId.toString());

        uploadMutate.mutate(formData);
        // uploadMutate.mutate(formData).then((data) => {
        //     if ((data.detail_product || []).length === 0) {
        //         message.success("Upload file success");
        //         form.resetFields();
        //         onSuccess();
        //         closeRef.current?.click();
        //     } else {
        //         setStep(1);
        //     }
        // });
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onChangeStep = (stp: number) => {
        if (!list?.length && stp === 1) return;
        setStep(stp);
    };

    const onChooseLocator = (product: DetailProduct & LocatorPI) => {
        setList((prev) => {
            return prev.map((item) => {
                if (item.id !== product.id) return item;
                return {
                    ...item,
                    area_id: product.area_id,
                    area_name: product.area_name,
                };
            });
        });
    };

    const onRemoveLocator = (product: DetailProduct & LocatorPI) => {
        setList((prev) => {
            return prev.map((item) => {
                if (item.id !== product.id) return item;
                return {
                    ...item,
                    area_id: undefined,
                    area_name: undefined,
                };
            });
        });
    };

    const steps = [
        {
            title: "Upload File",
        },
        {
            title: "Validasi Locator",
        },
    ];

    const canSubmitLocator = list.every((el) => !!el.area_id);

    const onSubmit = () => {
        const productList: DetailProduct[] = list.map((el) => ({
            area_id: el.area_id,
            product_id: el.product_id,
            qty: el.qty,
            no_koli: el.no_koli,
            note: el.note,
        }));

        const dataParse: CreateDocContainer = {
            detail_product: productList,
            note: uploadMutate.data?.note,
            warehouse_id: uploadMutate.data?.warehouse_id,
        };

        createDoc.mutateAsync(dataParse).then(() => {
            closeRef.current?.click();
            message.success("Doc Container Created");
            setList([]);
            setStep(0);
            uploadMutate.reset();
        });
    };

    return (
        <>
            <ModalTemplate
                {...props}
                title={steps[step].title}
                width={step === 1 ? 1000 : 600}
                closable={!uploadMutate.isLoading}
                handlerInComponent={children}
                footer={null}
            >
                {(dt) => (
                    <div className="w-full flex flex-col gap-6">
                        <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                            close
                        </button>
                        <Steps current={step} onChange={onChangeStep} items={steps} />
                        <div className="rounded-lg border-solid border border-gray-300 p-4">
                            {step === 0 ? (
                                <Form
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    form={form}
                                    name="control-hooks"
                                    onFinish={onFinish}
                                    style={{ maxWidth: 600 }}
                                >
                                    <ControlledSelectInput
                                        control={control}
                                        name="warehouse_id"
                                        placeholder="Select Warehouse To"
                                        label="WH To"
                                        options={getWarehouse.data || []}
                                    />
                                    <Form.Item name="file" label="File" rules={[{ required: true }]} getValueFromEvent={normFile} className="!w-full">
                                        <Upload
                                            beforeUpload={() => false}
                                            action={undefined}
                                            listType="text"
                                            accept=".xlsx,.xls,.csv"
                                            className="!w-full"
                                        >
                                            <Button htmlType="button">Pick File</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="!flex !items-center !gap-3">
                                            {progress}
                                            Upload
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ) : null}
                            {step === 1 ? (
                                <>
                                    <p className="m-0 mb-2 text-xs text-red-400">Silakan pilih locator berdasarkan product</p>
                                    <ValidasiLocatorTable list={list as any} onSubmit={onChooseLocator as any} onRemove={onRemoveLocator as any} />
                                    <Button onClick={onSubmit} disabled={!canSubmitLocator} type="primary" loading={uploadMutate.isLoading}>
                                        Submit
                                    </Button>
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
            </ModalTemplate>
        </>
    );
};

export default ModalUploadDocContainer;

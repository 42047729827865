/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import subAttributeService, { AllSubAttribute } from "services/api-endpoints/dashboard/master-data/sub-attribute";
import { FCreateDataProduct } from "../models";

type SelectOptionCustom = SelectOption & AllSubAttribute;

export interface Props extends ControlledInputSelectProps<FCreateDataProduct> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withSubAttribute<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([subAttributeService.getAllSubAttribute], async () => {
            const req = await subAttributeService.GetAllSubAttribute();
            return req.data.data?.map((el) => ({ ...el, label: el?.subattribute_name || "Any", value: el.id || "" } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const SubAttributeSelect = withSubAttribute(ControlledSelectInput);

export default SubAttributeSelect;

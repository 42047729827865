import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterItemsProducts from "modules/master-data/package-products/index/filter";
import PackageProductTable from "modules/master-data/package-products/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import packageProductsService from "services/api-endpoints/dashboard-jsl/package-products";

const filter = ["product_name", "product_code", "is_active"];

const PackageProducts = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([packageProductsService.example, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await packageProductsService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await packageProductsService.Filter(filterValue as any)).data.data;
        return (await packageProductsService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterItemsProducts filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Order Number or Invoice No or Client Name"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterItemsProducts>
            <PackageProductTable fetcher={getList} />
        </div>
    );
};

export default PackageProducts;

import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import bpPickingListService, { LocatorData } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import cPickingListService from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import cSalesOrderService, { StockData } from "services/api-endpoints/dashboard/master-data/c-sales-order";

type Props = ModalProps & {
    productId: number;
    productName: string;
};

const StockModal = ({ productId, productName, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const locatorQuery = useQuery(
        [cSalesOrderService.listStock, productId],
        async () => {
            return (await cSalesOrderService.ListStock({ product_id: productId })).data.data;
        },
        {
            enabled: !!productId,
        }
    );

    const columns: ColumnsType<StockData> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Warehouse Name",
            dataIndex: "warehouse_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Area Name",
            dataIndex: "area_code",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
    ];

    return (
        <ModalTemplate title={`Stock Product - ${productName}`} width={700} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        size="small"
                        loading={locatorQuery.isLoading}
                        columns={columns}
                        dataSource={locatorQuery.data || []}
                        className="w-full"
                        pagination={{
                            pageSize: 10,
                            total: locatorQuery.data?.length || 0,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default StockModal;

/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import PerjalananDinasTable from "modules/perjalanan-dinas/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import hadirAppService from "services/api-endpoints/hadir-app/hadir-app-service";

const PerjalananDinas = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const getAll = useQuery([hadirAppService.getListDutyTrip, page], async () => {
        return (await hadirAppService.GetListDutyTrip({ page, status: 1 })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} add={false} />
            <PerjalananDinasTable fetcher={getAll} />
        </div>
    );
};

export default PerjalananDinas;

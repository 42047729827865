import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import bpOrderService from "services/api-endpoints/dashboard/master-data/bp-order";
import { FORMAT_DATE_1, STATUS_ACTIVE, STATUS_BP_ORDER } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBpOrder = ({ filter = [], ...props }: Props) => {
    const bpOrder = useQuery([bpOrderService.getBusinessPartner], async () => {
        const req = await bpOrderService.GetBusinessPartner({ status: 0 });
        return req.data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Bp Order"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Order No"
                            value={dt.values.order_no}
                            name="order_no"
                            onChange={(e) => dt.setOneValue("order_no", e.target.value)}
                        />
                        <Input
                            placeholder="SO NO"
                            value={dt.values.invoice_no}
                            name="so_no"
                            onChange={(e) => dt.setOneValue("so_no", e.target.value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={Number(dt.values.bp_name)}
                            loading={bpOrder.isLoading}
                            placeholder="Bp Name"
                            options={bpOrder.data || []}
                            onChange={(value) => dt.setOneValue("bp_name", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            allowClear
                            value={dt.values.status !== null && dt.values.status !== undefined ? Number(dt.values.status) : dt.values.status}
                            placeholder="Status"
                            options={STATUS_BP_ORDER}
                            onChange={(value) => dt.setOneValue("status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBpOrder;

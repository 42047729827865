/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SelectOption } from "components/form/inputs/input-select";
import { Product } from "models";
import AdvanceSearch from "modules/advance-search";
import React from "react";
import { MdAdd, MdOutlineDeleteOutline } from "react-icons/md";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";
import { VIEW_PATH } from "utils/constant";

const StockProduct = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouse = searchParams.get("warehouse_id");
    const includeLocator = searchParams.get("include_locator");
    const excludeLocators = searchParams.get("exclude_locators");

    const [products, setProducts] = React.useState<Product[]>([]);

    React.useEffect(() => {
        searchParams.delete("products");
        setSearchParams(searchParams);
    }, []);

    React.useEffect(() => {
        searchParams.set("products", products.map((el) => el.product_id).join(","));
        setSearchParams(searchParams);
    }, [products]);

    const changeWarehouse = ({ id }: { id: any }) => {
        searchParams.set("warehouse_id", id);
        setSearchParams(searchParams);
    };

    const changeExludeLocators = (values: number[]) => {
        searchParams.set("exclude_locators", values.join(","));
        setSearchParams(searchParams);
    };

    const changeInludeLocator = (values: number[]) => {
        searchParams.set("include_locator", values.join(","));
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.warehouse, warehouse],
        async () => {
            return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            enabled: warehouse !== undefined,
        }
    );

    const areaQuery = useQuery(
        [quantityService.allArea, warehouse],
        async () => {
            const req = await quantityService.AllArea({ warehouse_id: warehouse });
            return [{ label: "ALL", value: -1 }, ...(req.data.data?.map((el) => ({ label: el.area_code, value: el.area_id } as SelectOption)) || [])];
        },
        {
            enabled: !!warehouse,
        }
    );

    const removeProduct = (product: Product) => {
        return () => {
            setProducts((prev) => prev.filter((p) => p.product_id !== product.product_id));
        };
    };

    const columns: ColumnsType<Product> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Button onClick={removeProduct(record)} title="Delete row" type="text" danger>
                    <MdOutlineDeleteOutline className="text-lg" />
                </Button>
            ),
        },
    ];

    const viewHandler = () => {
        navigate(location.pathname + VIEW_PATH + location.search);
    };

    return (
        <div className="flex flex-col gap-6 items-center justify-center w-full">
            <div className="w-[700px] rounded-2xl bg-white border border-solid border-gray-300 p-7 flex flex-col gap-5">
                <label htmlFor="warehouse" className="flex flex-col gap-2">
                    Warehouse Name
                    <Select
                        id="warehouse"
                        showSearch
                        value={warehouse ? Number(warehouse) : undefined}
                        loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                        placeholder="Warehouse"
                        options={getWarehouse.data || []}
                        onChange={(val) => changeWarehouse({ id: val })}
                    />
                </label>
                <label htmlFor="include-locator" className="flex flex-col gap-2">
                    Include Locator
                    <Select
                        value={includeLocator ? includeLocator.split(",").map(Number) : undefined}
                        showSearch
                        id="include-locator"
                        mode="multiple"
                        allowClear
                        loading={areaQuery.isLoading}
                        placeholder="Include Locator"
                        onChange={changeInludeLocator}
                        optionFilterProp="label"
                        options={areaQuery.data}
                    />
                </label>
                <label htmlFor="exclude-locator" className="flex flex-col gap-2">
                    Exclude Locator
                    <Select
                        value={excludeLocators ? excludeLocators.split(",").map(Number) : undefined}
                        showSearch
                        id="exclude-locator"
                        mode="multiple"
                        allowClear
                        placeholder="Exclude Locator"
                        onChange={changeExludeLocators}
                        optionFilterProp="label"
                        options={areaQuery.data}
                    />
                </label>
                <label className="flex flex-col gap-2">
                    Product
                    <AdvanceSearch selected={products} setSelected={setProducts}>
                        {(dt) => (
                            <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                                <MdAdd className="mr-2" />
                                Add Product
                            </Button>
                        )}
                    </AdvanceSearch>
                </label>
                <Table
                    rowKey={(record) => record.product_id!}
                    size="small"
                    columns={columns}
                    dataSource={products || []}
                    className="w-full"
                    pagination={false}
                />
                <div className="w-full flex justify-end">
                    <Button
                        disabled={!warehouse || !includeLocator || !products.length}
                        onClick={viewHandler}
                        className="!flex !items-center w-fit"
                        type="primary"
                    >
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StockProduct;

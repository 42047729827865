import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult, useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    EDIT_PATH,
    FORMAT_DATE_2,
    FORMAT_DATE_TIME,
    ORDER_PAYMENT_BP_SALES,
    ORDER_PAYMENT_EMPLOYESS,
    ORDER_PAYMENT_OFFLINE,
    ORDER_PAYMENT_OFFLINE_DP,
    ORDER_PAYMENT_ONLINE,
    RECEIPT_OFFLINE_TYPE,
    TYPE_ORDER_PAYMENT,
    VIEW_PATH,
} from "utils/constant";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessView from "components/button/button-access-view";
import moment from "moment";
import { BaseReceiptResponse, ReceiptList } from "services/api-endpoints/dashboard/receipt";
import { SelectOption } from "models";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService from "services/api-endpoints/dashboard/payment";

type Props<T> = {
    fetcher: UseQueryResult<BaseReceiptResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const ReceiptTable = <T extends ReceiptList>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: ReceiptList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: ReceiptList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const bpQuery = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const employeeQuery = useQuery([paymentService.getEmployee], async () => {
        return (await paymentService.GetEmployee()).data.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption));
    });

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Cabang Account",
            dataIndex: "account_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Bank Account",
            dataIndex: "name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            render: (text) => <p className="capitalize m-0">{moment(text)?.format(FORMAT_DATE_2)}</p>,
        },
        {
            title: "Sales Type",
            dataIndex: "order_type_id",
            render: (text) => <p className="capitalize m-0">{TYPE_ORDER_PAYMENT.find((val) => val.value === text)?.label}</p>,
        },
        {
            title: "Partner Name",
            dataIndex: "partner_id",
            render: (text, record) => {
                if (record.order_type_id === ORDER_PAYMENT_OFFLINE || record.order_type_id === ORDER_PAYMENT_OFFLINE_DP)
                    return <p className="capitalize m-0">{RECEIPT_OFFLINE_TYPE.find((val) => val.value === text)?.label}</p>;
                // return <p className="capitalize m-0">Offline</p>;
                if (record.order_type_id === ORDER_PAYMENT_ONLINE)
                    return <p className="capitalize m-0">{marketplaceQuery.data?.find((val) => val.value === text)?.label}</p>;
                if (record.order_type_id === ORDER_PAYMENT_BP_SALES)
                    return <p className="capitalize m-0">{bpQuery.data?.find((val) => val.value === text)?.label}</p>;
                if (record.order_type_id === ORDER_PAYMENT_EMPLOYESS)
                    return <p className="capitalize m-0">{employeeQuery.data?.find((val) => val.value === text)?.label}</p>;
                return <p className="capitalize m-0">{text || "-"}</p>;
            },
        },
        {
            title: "Total Amount",
            dataIndex: "total_receipt_amount",
            render: (text) => <p className="capitalize m-0">{(text || 0).ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{moment(text)?.format(FORMAT_DATE_TIME)}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.is_active ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : (
                        <>
                            <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                            <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 20,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ReceiptTable;

import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface FilterReport {
    periode?: any;
    filter?: number;
}

export interface TrialBalance {
    coa_code?: string;
    coa_name_in?: string;
    coa_name_en?: string;
    debit?: number;
    kredit?: number;
}

class AccountingReportService extends BaseService {
    trialBalance = "/report-accounting/get-trial-balance";

    TrialBanlance<T extends TrialBalance[]>(param: FilterReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.trialBalance,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const accountingReportService = new AccountingReportService();
export default accountingReportService;

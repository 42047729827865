/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface Holiday {
    id: number;
    start_date: string;
    end_date: string;
    full_name: string;
    description: string;
}

export interface HolidayCreate extends Omit<Holiday, "full_name" | "id"> {}
export interface HolidayEdit extends Omit<Holiday, "full_name"> {}

class HolidayService extends BaseService {
    getAll = "/master-holiday/get-list-holiday";

    create = "/master-holiday/create";

    delete = "/master-holiday/delete";

    constructor() {
        super();
    }

    GetAll<T extends Holiday>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: HolidayCreate | HolidayEdit) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    Delete<T extends any>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${data.id}`,
            });
            return req;
        });
    }
}

const holidayService = new HolidayService();
export default holidayService;

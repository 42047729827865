import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import { CreateDataBrand } from "../models";

export function withBrandType<T extends ControlledInputSelectProps<CreateDataBrand>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const brandTypeQuery = useQuery([brandService.suggestBrandType], async () => {
            const req = await brandService.SuggestBrandType();
            return req.data.data?.map((el) => ({ label: el?.type_name || "Any", value: el.brand_type || "" } as SelectOption));
        });
        return <Component {...props} optionFilterProp="children" loading={brandTypeQuery.isLoading} options={brandTypeQuery.data || []} />;
    };
}

const BrandTypeSelect = withBrandType(ControlledSelectInput);

export default BrandTypeSelect;

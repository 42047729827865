/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { Alert, Button, Card, Divider, Image, message, Modal, Popconfirm, Skeleton, Space, Tabs, Upload, UploadFile, UploadProps } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
    APPROVE_BY_ADMIN,
    CHECKING_PAYMENT_STATUS,
    ORDER_APPROVE,
    ORDER_COMPLETED,
    ORDER_PICKED,
    ORDER_SALES_CANCEL,
    REJECT_BY_ADMIN,
    STATUS_ORDER,
    STATUS_PAYMENT_ORDER,
    TYPE_SALES_OFFLINE,
} from "utils/constant";
import orderService, { SetStatusOrder, SetStatusPayment, UploadReceiptData } from "services/api-endpoints/dashboard/master-data/order";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import DetailProduct from "modules/master-data/order/view/detail-product";
import HistoryPaymentTable from "modules/master-data/order/view/history-payment-table";
import HistoryStatus from "modules/master-data/order/view/history-status";
import Utils from "utils";
import { StateContext } from "context/state";
import VersioningOrder from "modules/master-data/order/view/versioning";
import { BiImageAdd } from "react-icons/bi";
import useProgressUpload from "hooks/useProgressUpload";
import useConfirmDelete from "hooks/useConfirmDelete";

export const PRODUCT_PAGE = "product_page";
export const MAX_RECEIPT_UPLOAD = 1;

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ListCancelOrderView = ({ paramId, asPreview }: Props) => {
    const { updateAccess } = useContext(StateContext);

    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    // uploader image preview
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [progress, onUploadProgress] = useProgressUpload();

    const detailQuery = useQuery(
        [orderService.detail, id],
        async () => {
            return (await orderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const statusPaymentMutate = useMutation(
        [orderService.setStatusPayment],
        async (data: SetStatusPayment) => {
            return (await orderService.SetStatusPayment(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Status payment edited");
            },
        }
    );


    const transactionCancelMutate = useMutation(
        [orderService.transactionCancel],
        async (data: { id: any; type: any }) => {
            return (await orderService.TransactionCancel(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Action Success");
            },
        }
    );

    const uploadReceiptMutate = useMutation(
        [orderService.uploadReceipt],
        async () => {
            const base64 = await Utils.toBase64(fileList[0].originFileObj as any);
            return (await orderService.UploadReceipt({ id, receipt_image: base64 }, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                setFileList([]);
                message.success("Upload receipt success");
                detailQuery.refetch();
            },
        }
    );

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const [showConfirmReject] = useConfirmDelete<any>({
        title: "Reject Request Cancellation",
        content: "`You sure to reject request cancellation this transaction?",
        onOk: (data, callback) => {
            transactionCancelMutate.mutateAsync({ id: id as any, type: 0 }).then(callback).catch(callback).finally(detailQuery.refetch);
        },
    });

    const [showConfirmApprove] = useConfirmDelete<any>({
        title: "Approve Request Cancellation",
        content: "`You sure to approve request cancellation this transaction?",
        onOk: (data, callback) => {
            transactionCancelMutate.mutateAsync({ id: id as any, type: 1 }).then(callback).catch(callback).finally(detailQuery.refetch);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail order"
                rightAddition={() => (
                    <Space>
                        <Button
                            onClick={showConfirmReject}
                            type="ghost"
                            loading={transactionCancelMutate.isLoading}
                            disabled={detailQuery.data?.is_cancellation_req !== 1}
                        >
                            Reject Cancel Order
                        </Button>
                        <Button
                            onClick={showConfirmApprove}
                            type="primary"
                            loading={transactionCancelMutate.isLoading}
                            disabled={detailQuery.data?.is_cancellation_req !== 1}
                        >
                            Approve Cancel Order
                        </Button>
                    </Space>
                )}
            />
            <div className="grid grid-cols-8 gap-4">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="col-span-5">
                                    <Card>
                                        <div className="grid grid-cols-2 mb-5">
                                            {/* <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">no trx</p>
                                                <span>: {detailQuery.data?.order_no}</span>
                                            </div> */}
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">invoice no</p>
                                                <span>: {detailQuery.data?.invoice_no}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">client name</p>
                                                <span>: {detailQuery.data?.client_name}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">order reference</p>
                                                <span>: {detailQuery.data?.invoice_reference}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">total price</p>
                                                <span>: {detailQuery.data?.total_price?.ToIndCurrency("Rp")}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">total qty</p>
                                                <span>: {detailQuery.data?.total_quantity}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">status order</p>
                                                <span>: {STATUS_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">Discount Name</p>
                                                <span>: {detailQuery.data?.discount_name}</span>
                                            </div>
                                        </div>
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    label: `Detail Product`,
                                                    key: "1",
                                                    children: <DetailProduct asPreview={asPreview} detailQuery={detailQuery} paramId={id} />,
                                                },
                                                {
                                                    label: `Status History`,
                                                    key: "2",
                                                    children: <HistoryStatus paramId={id} />,
                                                },
                                                {
                                                    label: `Payment History`,
                                                    key: "3",
                                                    children: <HistoryPaymentTable paramId={id} />,
                                                },
                                                {
                                                    label: "Versioning History",
                                                    key: "4",
                                                    children: <VersioningOrder paramId={id} />,
                                                },
                                            ]}
                                        />
                                    </Card>
                                </div>
                                <div className="col-span-3 flex flex-col gap-4">
                                    <Card>
                                        <div className="flex w-full flex-col gap-4">
                                            <p className="m-0 capitalize font-semibold">
                                                payment method <br />
                                                <span className="font-normal text-xs">{detailQuery.data?.payment_channel || "-"}</span> <br />
                                                {detailQuery.data?.payment_channel !== "COD" ? (
                                                    <>
                                                        <span className="font-normal text-xs">
                                                            {Utils.stripHtml(detailQuery.data?.transfer_dest || "-")}
                                                        </span>{" "}
                                                        <br />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <span className="font-normal text-xs">{detailQuery.data?.order_no || "-"}</span> <br />
                                            </p>
                                            <form className="">
                                                {detailQuery.data?.payment_receipt_url ? (
                                                    <div>
                                                        <p className="font-semibold">Payment Receipt</p>
                                                        <Image src={detailQuery.data?.payment_receipt_url} />
                                                    </div>
                                                ) : null}
                                            </form>
                                            <Divider />
                                            <div className="">
                                                <div className="capitalize flex items-center justify-between">
                                                    <p className="m-0">total price</p>
                                                    <p className="m-0">{detailQuery.data?.total_price?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                <div className="capitalize flex items-center justify-between">
                                                    <p className="m-0">shipping</p>
                                                    <p className="m-0">{detailQuery.data?.shipping_cost?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                {detailQuery.data?.discount !== 0 ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">discount</p>
                                                        <p className="m-0">{detailQuery.data?.discount?.ToIndCurrency("- Rp")}</p>
                                                    </div>
                                                ) : null}
                                                {detailQuery.data?.cc_amount && (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">{detailQuery.data.cc_name}</p>
                                                        <p className="m-0">{detailQuery.data?.cc_amount?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                )}
                                                <div className="capitalize flex items-center justify-between font-semibold mb-4">
                                                    <p className="m-0">grand total</p>
                                                    <p className="m-0 ">{detailQuery.data?.grand_total?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                {detailQuery.data?.dp_amount ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">Down Payment (DP)</p>
                                                        <p className="m-0">{detailQuery.data?.dp_amount?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                ) : null}
                                                {detailQuery.data?.sisa_pembayaran ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">Sisa Pembayaran</p>
                                                        <p className="m-0">{detailQuery.data?.sisa_pembayaran?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <div className="m-0 capitalize w-fit text-xs">
                                                <p className="mb-1 font-medium">recipient address</p>
                                                <span className="">{detailQuery.data?.alias_address}</span> <br />
                                                <span className="">{detailQuery.data?.address}</span>
                                            </div>
                                            <div className="m-0 capitalize w-fit text-xs">
                                                <p className="mb-1 font-medium">courier</p>
                                                <span className="">{detailQuery.data?.courier_name}</span>
                                            </div>
                                        </Space>
                                    </Card>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="col-span-5">
                                    <Card>
                                        <Skeleton paragraph={{ rows: 4 }} />
                                    </Card>
                                </div>
                                <div className="col-span-3 flex flex-col gap-4">
                                    <Card>
                                        <Skeleton paragraph={{ rows: 3 }} />
                                    </Card>
                                    <Card>
                                        <Skeleton paragraph={{ rows: 2 }} />
                                    </Card>
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="receipt" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default ListCancelOrderView;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Skeleton, Upload, UploadFile, UploadProps, message } from "antd";
import ButtonDelete from "components/button/button-delete";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import suppliesService, { CreateSupplies, EditSupplies } from "services/api-endpoints/purchasing/supplies";
import Utils from "utils";
import { STATUS_ACTIVE_3 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<EditSupplies>> = yup.object().shape({
    id: yup.number(),
    name: yup.string().required("Name Required"),
    code: yup.string().required("Code Required"),
    type_id: yup.number().required("Type Of Supplies Required"),
    description: yup.string().required("Description Required"),
    is_active: yup.number().required("Is Active Required"),
    uom_id: yup.number().required("UOM Of Supplies Required"),
    images: yup.array(),
});

const SuppliesEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = useState<string[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<EditSupplies>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [suppliesService.detailSupplies, id],
        async () => {
            return (await suppliesService.DetailSupplies({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("id", data.id);
                setValue("type_id", data.type_id);
                setValue("name", data.name);
                setValue("code", data.code);
                setValue("description", data.description);
                setValue("is_active", data.is_active);
                setValue("uom_id", data.uom_id);
                setUrlImgList((data.images || [])?.filter((url) => url));
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const getSuppliesType = useQuery([suppliesService.getSuppliesType], async () => {
        return (await suppliesService.GetSuppliesType()).data.data?.map((el) => ({ label: el.type_name, value: el.type_id } as SelectOption));
    });

    const getUOM = useQuery([suppliesService.getUOM], async () => {
        return (await suppliesService.GetUOM()).data.data?.map((el) => ({ label: el.uom_name, value: el.uom_id } as SelectOption));
    });

    const createMutation = useMutation(
        [suppliesService.createSupplies],
        async (data: CreateSupplies) => {
            return (await suppliesService.CreateSupplies(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Item Supplies Created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));
        const tempData: CreateSupplies = {
            ...data,
            images: [...(filesBase64 || []), ...urlImgList],
        };
        createMutation.mutate(tempData);
    });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const handlePreviewExistImage = (url: string) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };

    const onDeleteImageUrl = (image: any) => {
        return (e: any) => {
            e.stopPropagation();
            setUrlImgList((prev) => prev?.filter((url) => url !== image));
        };
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit item supplies"
                rightAddition={() => (
                    <>
                        <Button loading={createMutation.isLoading} onClick={onSubmitHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                                        <ControlledInputText control={control} name="code" placeholder="Code" label="Code" />
                                        <ControlledInputText control={control} name="description" placeholder="Description" label="Description" />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            options={getSuppliesType.data}
                                            loading={getSuppliesType.isLoading}
                                            name="type_id"
                                            placeholder="Type Of Supplies"
                                            label="Type Of Supplies"
                                        />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            options={getUOM.data}
                                            loading={getUOM.isLoading}
                                            name="uom_id"
                                            placeholder="UOM Of Supplies"
                                            label="UOM Of Supplies"
                                        />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            name="is_active"
                                            options={STATUS_ACTIVE_3}
                                            placeholder="Status"
                                            label="Status"
                                        />
                                        <div className="col-span-4">
                                            <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                <div>
                                                    <BiImageAdd className="text-2xl" />
                                                    <div style={{ marginTop: 8 }}>Upload Image</div>
                                                </div>
                                            </Upload>
                                            <div className="col-span-4">
                                                {urlImgList?.map((url) => (
                                                    <button
                                                        onClick={() => handlePreviewExistImage(url)}
                                                        type="button"
                                                        className="relative border-none bg-transparent"
                                                    >
                                                        <ButtonDelete onClick={onDeleteImageUrl(url)} />
                                                        <ImageCard image={url} title="document" />
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default SuppliesEdit;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { DetailOrder } from "services/api-endpoints/dashboard/master-data/ext-return-order";
import { STOCK_ACTION } from "utils/constant";

type Props<T> = Omit<EditTableProps<T>, "editInputType" | "columns" | "editRow" | "setEditRow" | "findIndexSave" | "isEditing" | "rowKey">;

export function tableEdit<T extends DetailOrder>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                dataIndex: "inv_no",
                title: "Invoice No",
                render: (text) => text,
            },
            {
                dataIndex: "product_name",
                title: "Product",
                render: (text) => text,
            },
            {
                dataIndex: "product_code1",
                title: "Code",
                render: (text) => text,
            },
            {
                dataIndex: "qty",
                title: "Qty Order",
                render: (text) => text,
            },
            {
                title: "Qty Return",
                width: "100px",
                dataIndex: "qty_return",
                ...{ editable: true },
                render: (text) => text || "",
            },
            {
                title: "Stock Action",
                width: "100px",
                dataIndex: "stock_action",
                ...{ editable: true },
                render: (text) => text || "",
            },
        ];

        const editInputType: EditTableProps<T>["editInputType"] = { qty_return: "number", stock_action: "select" };
        const isEditing = (record: DetailOrder, edited: DetailOrder | null) => record.id === edited?.id && record.product_id === edited?.product_id;
        const findIndexSave = (record: DetailOrder, edited: DetailOrder) => record.id === edited.id && record.product_id === edited?.product_id;
        const rowKey = (record: DetailOrder) => `${record.id}${record.product_id}`;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: editRow?.qty,
                    selectProps: {
                        options: STOCK_ACTION,
                        className: "!w-[150px]",
                        placeholder: "Action",
                    },
                    ...props?.cellProps,
                }}
                list={props.list?.map((order: any) => ({
                    ...order,
                    stock_action: STOCK_ACTION.find((act) => act.value === order.stock_action || act.label === order.stock_action)?.label,
                }))}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const OrderTable = tableEdit(EditTable);
export default OrderTable;

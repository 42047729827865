/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { CustomVoucher, CustomVoucherList } from "modules/purchasing/inventory/models";
import { AddRequest } from "modules/purchasing/inventory/add/modal-add-request";
import axios from "axios";
import Cookies from "js-cookie";
import { TOKEN_USER } from "utils/constant";

export interface ChargeList {
    charge_id?: number;
    charge_code?: string;
    charge_name?: string;
}

export interface ExpansesList extends ChargeList {
    note?: any;
    amount?: number;
    id?: any;
    name?: any;
}

export interface PurchasingInventory {
    id?: number;
    po_no?: string;
    vendor?: string;
    payment_term?: string;
    grand_price?: number;
    qty?: number;
    request_by?: string;
    doc_status?: number;
    doc_type?: number;
    doc_name?: string;
    transaction_date?: string;
    gr_date?: string;
}

export interface GrInventory extends PurchasingInventory {
    gr_note?: string;
    gr_no?: string;
}

export interface TaxInventory {
    tax_id: number;
    tax_name: string;
    tax_value: number;
}

export interface ShippingInventory {
    shipping_id: number;
    shipping_name: string;
}

export interface BPInventory {
    bp_id: number;
    bp_name: string;
}

export interface PaymentTermInventory {
    payment_term_id: number;
    description: string;
}

export interface ProductInventory {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    product_price?: number;
}

export interface FilterPurchasingInventory {
    page?: any;
    docType?: any;
    start_date?: any;
    end_date?: any;
}

export interface FilterGrInventory {
    page?: any;
    docType?: any;
    start_date?: any;
    end_date?: any;
}

export interface InventoryItem {
    product_id: any;
    doc_id?: any;
    qty?: any;
    price?: any;
    vendor_id?: any;
    type_of_supplies_id?: any;
    note?: any;
}

export interface CustomVoucherWithProduct extends CustomVoucher {
    product?: {
        id: any;
        price: any;
        qty: any;
    }[];
}

export interface GetSummary {
    child: InventoryItem[];
    voucher: CustomVoucherWithProduct[];
    tax_type: any;
    tax_value: any;
    total_expenses: number[];
}

export interface Summary {
    grand_total: number;
    total_discount: number;
    total_pajak: number;
    total_price: number;
    total_expenses?: number;
    list_discount: {
        product_id: number;
        discount_name: string;
        discount_price: number;
        qty: number;
    }[];
}

export interface Ref {
    pr_id?: number;
    pr_no?: string;
}

export interface DetailHeader {
    id?: number;
    doc_id?: number;
    po_no?: string;
    pr_no?: string;
    gr_no?: string;
    references?: Ref[];
    do_no?: string;
    payment_term_id?: number;
    payment_term?: string;
    voucher?: CustomVoucher[];
    list_voucher?: CustomVoucherList[];
    delivery_date?: number;
    shipping_id?: number;
    kurir?: string;
    delivery_address?: string;
    transaction_date?: string;
    gr_date?: string;
    note?: string;
    gr_note?: string;
    tax_type: number;
    tax_id?: number;
    tax?: string;
    total_tax?: number;
    total_disc?: number;
    total_price?: number;
    total_expenses?: number;
    grand_total?: number;
    created_by?: number;
    request_by?: string;
    doc_status?: number;
    doc_type?: number;
    created_at?: string;
    selected_vendor_id?: number;
    selected_vendor_name?: string;
    inv_vendor?: string[] | null;
    expenses?: ExpansesList[];
    account_name?: string;
    account_address?: string;
    do_images?: string[];
}

export interface DetailVendor {
    vendor_id?: number;
    vendor_name?: string;
}

export interface DetailProduct {
    vendor_id?: number;
    product_id?: number;
    id?: number;
    po_id?: number;
    type_id?: number;
    product_name?: string;
    type_name?: string;
    qty?: number;
    price?: number;
    uom_code?: string;
    uom_id?: number;
}

export interface DetailPurchasingInventory {
    header?: DetailHeader;
    list_vendor?: DetailVendor[];
    list_product?: DetailProduct[];
    list_product_price?: DetailProduct[];
}

export interface FilterProduct {
    prd_id?: number;
    qty?: number;
    product_id?: number;
    type_id?: number;
    product_name?: string;
    type_name?: string;
    uom_code?: string;
    uom_id?: number;
}

export interface FilterRequestList {
    pr_id?: number;
    pr_no?: string;
    created_at?: string;
    note?: string;
    list_product?: FilterProduct[];
}

export interface ApprovedData {
    id?: any;
    note?: any;
    type_approved?: number;
}

export interface ListHistoryApproval {
    purchasing_order_id?: string;
    status_approval?: number;
    name?: string;
    note?: string;
    created_by?: string;
    created_at?: string;
    created_id?: number;
}

export interface HistoryDetail {
    IsAlreadyApproval?: boolean;
    Approval?: ListHistoryApproval[];
}

export interface ImageDO {
    do_images: string[];
    user_note: string;
}

export interface UploadInvoice {
    id?: number;
    inv_vendor?: any[];
}

export interface BodyCreatePurchasingInventory {
    id?: any;
    payment_term_id?: any;
    voucher?: CustomVoucherWithProduct[];
    delivery_date?: any;
    vendor_id?: any;
    shipping_id?: any;
    tax_type?: any;
    tax_id?: any;
    delivery_address?: string;
    note?: string;
    doc_type?: any;
    doc_id?: any;
    pr_id?: any[];
    child?: InventoryItem[];
    total_tax?: any;
    total_disc?: any;
    total_price?: any;
    grand_total?: any;
    expenses?: ExpansesList[];
    transaction_date?: any;
}

export interface JourneyPOData {
    doc_id?: number;
    doc_no?: string;
    vendor_id?: number;
    vendor_name?: string;
    type?: number;
    child?: JourneyPOData[];
}

export interface POPrice {
    bp_id: number;
    child: { product_id: number; price: number }[];
}

export interface DetailRequest {
    prd_id: number;
    qty: number;
    product_id: number;
    uom_id: number;
    uom_code: string;
    type_id: number;
    note: string;
    product_name: string;
    type_name: string;
}

export interface CreatedByData {
    created_by: number;
    full_name: string;
    email: string;
}

class PurchasingInventoryService extends BaseService {
    filter = "/purchasing/get-all-purchasing-order";

    grList = "/purchasing/list-gr-inventory";

    getTax = "/purchasing/get-all-tax";

    getShipping = "/purchasing/get-all-shipping";

    getBp = "/purchasing/get-business_partner";

    getPayment = "/purchasing/get-payment-term";

    getInventoryPrice = "/purchasing/get-inventory-price";

    draft = "/purchasing/save-draft";

    save = "/purchasing/create";

    getSummary = "/purchasing/get-summary-price";

    getDetail = "/purchasing/detail-purchasing-order";

    delete = "/purchasing/delete-purchasing-order";

    filterRequest = "/purchasing/filter-get-request";

    completeRequest = "/purchasing/set-completed-tender";

    setStatusApproval = "/purchasing/set-status-approval";

    historyApproval = "/purchasing/get-history-approval/";

    setGenerateDO = "/purchasing/set-generate-do";

    getImageDelivery = "/purchasing/get-images-delivery/";

    uploadInvoiceVendor = "/purchasing/upload-invoice-vendor";

    charge = "/purchasing/get-charge";

    journeyPO = "/purchasing/get-journey-po";

    poPrice = "/purchasing/po-price";

    detailRequest = "/purchasing/get-detail-request";

    voidPO = "/purchasing/void-po";

    createdBy = "/purchasing/get-request-po";

    constructor() {
        super();
    }

    CreatedBy<T extends CreatedByData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.createdBy,
            });
            return req;
        });
    }

    VoidPO<T extends any>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.voidPO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GrList<T extends GrInventory>(params: FilterGrInventory) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.grList,
                config: { params },
            });
            return req;
        });
    }

    DetailRequest<T extends DetailRequest[]>(param: { pr_id: any; type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailRequest,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    POPrice<T extends POPrice>(data: POPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.poPrice,
                data,
            });
            return req;
        });
    }

    JourneyPO<T extends JourneyPOData[]>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.journeyPO}/${id}`,
            });
            return req;
        });
    }

    Charge<T extends ChargeList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.charge,
            });
            return req;
        });
    }

    UploadInvoiceVendor<T extends any>(data: UploadInvoice, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.uploadInvoiceVendor,
                data,
                config,
            });
            return req;
        });
    }

    GetImageDelivery<T extends ImageDO>(param: { po_history_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getImageDelivery,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SetGenerateDO<T extends any>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.setGenerateDO}/${id}`,
            });
            return req;
        });
    }

    HistoryApproval<T extends HistoryDetail>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.historyApproval}/${id}`,
            });
            return req;
        });
    }

    SetStatusApproved<T extends any>(data: ApprovedData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setStatusApproval,
                data,
            });
            return req;
        });
    }

    FilterRequest<T extends FilterRequestList[]>(params: AddRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filterRequest,
                config: { params },
            });
            return req;
        });
    }

    Delete<T extends any>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${params.id}`,
            });
            return req;
        });
    }

    GetDetail<T extends DetailPurchasingInventory>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDetail,
                config: { params },
            });
            return req;
        });
    }

    GetSummary<T extends Summary>(data: GetSummary) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.getSummary,
                data,
            });
            return req;
        });
    }

    Draft<T extends any>(data: BodyCreatePurchasingInventory) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draft,
                data,
            });
            return req;
        });
    }

    Save<T extends any>(data: BodyCreatePurchasingInventory) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.save,
                data,
            });
            return req;
        });
    }

    Filter<T extends PurchasingInventory>(params: FilterPurchasingInventory) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: { params },
            });
            return req;
        });
    }

    GetInventoryPrice<T extends ProductInventory[]>(params: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getInventoryPrice,
                config: { params },
            });
            return req;
        });
    }

    GetPayment<T extends PaymentTermInventory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPayment,
            });
            return req;
        });
    }

    GetBP<T extends BPInventory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBp,
            });
            return req;
        });
    }

    GetShipping<T extends ShippingInventory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getShipping,
            });
            return req;
        });
    }

    GetTax<T extends TaxInventory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getTax,
            });
            return req;
        });
    }
}

const purchasingInventoryService = new PurchasingInventoryService();
export default purchasingInventoryService;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import SuppliesTable from "modules/purchasing/supplies/table-index";
import SuppliesTypeTable from "modules/purchasing/supplies/table-index-type";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import suppliesService, { FilterPurchasing, SuppliesList, SuppliesTypeList } from "services/api-endpoints/purchasing/supplies";
import { DOC_TYPE_SUPPLIES } from "utils/constant";

const filter = ["start_date", "end_date"];

const Supplies = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([suppliesService.getAll, page, filterValue, isSearch], async () => {
        return (
            await suppliesService.GetAll({
                ...({ ...filterValue, param_search: searchValue.query || "" } as unknown as FilterPurchasing),
            })
        ).data.data;
    });

    const deleteMutation = useMutation(async (data: SuppliesList) => (await suppliesService.DeleteSupplies({ id: data.type_id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Supplies Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<SuppliesList>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Item Supplies ..." filter={false} />
            <SuppliesTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default Supplies;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { CreatePurchasingInventory } from "pages/purchasing-inventory/add";
import React from "react";
import { useQuery } from "react-query";
import purchasingInventoryService, { ShippingInventory } from "services/api-endpoints/purchasing/inventory";

export interface Props extends ControlledInputSelectProps<CreatePurchasingInventory> {
    onSelectedData?: (id: number, data: ShippingInventory[]) => void;
}

export function withShipping<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const shippingQuery = useQuery([purchasingInventoryService.getShipping], async () => {
            return (await purchasingInventoryService.GetShipping()).data.data;
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(id, shippingQuery.data || []);
            }
        };

        const options = shippingQuery?.data?.map((el) => ({ value: el.shipping_id, label: el.shipping_name }));

        return <Component {...props} onSelect={onSelect} loading={shippingQuery.isLoading} onDeselect={onSelect} options={options} />;
    };
}

const ShippingSelect = withShipping(ControlledSelectInput);

export default ShippingSelect;

import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/category/lib/is-active-select";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import { DetailDataCategory, CreateDataCategory, EditDataCategory, InfoDataCategory } from "modules/master-data/category/models";
import categoryService from "services/api-endpoints/dashboard/master-data/category";
import CategoryNameSelect from "modules/master-data/category/lib/category-name-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import State from "components/common/state";
import CategorySelect from "modules/master-data/category/lib/categoryselect";

const schema: yup.SchemaOf<Partial<CreateDataCategory>> = yup.object().shape({
    category_name: yup.string().required("Category name required"),
    category_code: yup.string().required("Category code required"),
    category_type: yup.string().required("Category type required"),
    is_active: yup.number().required("Is Active required"),
    category_image: yup.string().required("Category Image required"),
});

function CategoryEdit() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [banner, setBanner] = useState("");
    const [info, setInfo] = useState([] as InfoDataCategory[]);

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<CreateDataCategory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [progress, onUploadProgress] = useProgressUpload();

    const detailQuery = useQuery(
        [categoryService.detail, id],
        async () => {
            return (await categoryService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("category_name", data.category_name);
                setValue("category_code", data.category_code);
                setValue("category_type", data.category_type);
                setValue("category_image", data.category_image);
                setValue("is_active", data.is_active);
                setBanner(data.banner_image || "");
            },
        }
    );

    const infoCategory = useQuery(
        [categoryService.info],
        async () => {
            return (await categoryService.Info()).data.data;
        },
        {
            onSuccess: (data) => {
                setInfo(data as []);
            },
        }
    );

    const editMutation = useMutation(
        [categoryService.edit],
        async (data: EditDataCategory) => {
            return (await categoryService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Category edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
            banner_image: banner,
        } as EditDataCategory;
        editMutation.mutate(parseData);
    });
    const handleChange = (value: string) => {
        setValue("category_name", value);

        const cat = info.filter((item) => item.category_name === value);
        setValue("category_image", cat[0].category_image);
        setValue("category_code", cat[0].category_code);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit category"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-2 gap-4 center">
                                        <div className="col-span-2">
                                            <ImageCard title="category image" image={watch("category_image")} />
                                        </div>
                                    </div>
                                    <Form form={form} disabled={editMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">category information</h1>
                                        <CategorySelect handleChange={handleChange} info={info} value={watch("category_name")} />
                                        <div className={watch("category_name") === undefined ? "hidden" : ""}>
                                            <ControlledInputText
                                                control={control}
                                                label="Category Code"
                                                labelCol={{ span: 24 }}
                                                name="category_code"
                                                placeholder="Category Code"
                                                disabled
                                                style={{ transform: "translateY(-6px)" }}
                                            />
                                        </div>
                                        <ControlledInputText
                                            control={control}
                                            label="Category Type"
                                            labelCol={{ span: 24 }}
                                            name="category_type"
                                            placeholder="Category Type"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                        <IsActiveSelect
                                            control={control}
                                            label="Is Active"
                                            labelCol={{ span: 24 }}
                                            name="is_active"
                                            placeholder="Is Active"
                                            style={{ transform: "translateY(-6px)" }}
                                        />
                                    </Form>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>

                <State data={infoCategory.data} isLoading={infoCategory.isLoading} isError={infoCategory.isError}>
                    {(state) => <></>}
                </State>
            </Card>
        </div>
    );
}

export default CategoryEdit;

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useQuery } from "react-query";
import categoryService from "services/api-endpoints/dashboard/master-data/category";

const CategorySelect = (props) => {
    const { handleChange, info, value } = props;

    return (
        <Select value={value} placeholder="Category Name" style={{ width: "100%", marginBottom: 24 }} onChange={handleChange}>
            {info.map((content) => {
                return (
                    <Select.Option value={content.category_name} key={content.category_name}>
                        {content.category_name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

export default CategorySelect;

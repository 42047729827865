import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Row, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { UserContext } from "context/user";
import * as Models from "models";
import { SignInEmailData } from "models";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import Background from "assets/images/bg-login.jpeg";
import ImgJsl from "assets/images/jsl.png";
import { useMutation } from "react-query";
import authService from "services/api-endpoints/dashboard/auth";
import Utils from "utils";

const schema: yup.SchemaOf<SignInEmailData> = yup.object().shape({
    email: yup.string().required("Email wajib diisi"),
    password: yup.string().required("Password wajib diisi"),
});

const AuthPage = () => {
    const { saveUser } = useContext(UserContext);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<SignInEmailData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const signinMutation = useMutation(
        [authService.signIn],
        async (data: Models.SignInEmailData) => {
            Utils.SignOut();
            const req = await authService.SignIn(data);
            return req.data.data;
        },
        {
            onSuccess: (data) => {
                if (saveUser) saveUser(data);
            },
        }
    );

    const onSubmitHandler = handleSubmit(async (data) => {
        signinMutation.mutate(data);
    });

    return (
        <div className=" w-full !h-screen overflow-y-hidden flex items-center justify-center flex-col">
            <img src={Background} alt="" className="absolute w-full h-full object-cover" />
            <div className="flex flex-col items-center z-10">
                <div className="bg-white rounded-2xl w-40vw 2xl:w-[400px] p-10 shadow-xl z-10 relative">
                    <div className="bg-primary rounded-md px-20 py-5 absolute flex items-center justify-center left-1/2 -top-[70px] transform -translate-x-1/2 ">
                        <img src={ImgJsl} className="" alt="" />
                    </div>
                    <Form
                        form={form}
                        labelCol={{ span: 3 }}
                        labelAlign="left"
                        disabled={signinMutation.isLoading}
                        colon={false}
                        style={{ width: "100%" }}
                        onFinish={onSubmitHandler}
                        layout="vertical"
                    >
                        <Space direction="vertical" className="w-full">
                            <ControlledInputText control={control} labelCol={{ xs: 12 }} name="email" label="Email" placeholder="Email" />
                            <ControlledInputText
                                control={control}
                                labelCol={{ xs: 12 }}
                                name="password"
                                label="Password"
                                placeholder="Password"
                                type="password"
                            />

                            <Row justify="start">
                                <Space>
                                    <Button type="primary" htmlType="submit" disabled={signinMutation.isLoading} loading={signinMutation.isLoading}>
                                        Sign In
                                    </Button>
                                </Space>
                            </Row>
                        </Space>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;

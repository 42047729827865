/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Space, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import useConfirmDelete from "hooks/useConfirmDelete";
import HistoryTable from "modules/dashboard-jsl/purchase-order/view/history-table";
import ModalHistoryPO from "modules/dashboard-jsl/purchase-order/view/modal-history";
import ProductTable from "modules/dashboard-jsl/purchase-order/view/product-table";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import purchaseOrderService, { ListDetail } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { STATUS_PURCHASE_ORDER_INT } from "utils/constant";

const RequestIntPOView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;

    const [products, setProducts] = useState<ListDetail[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [purchaseOrderService.detail, id],
        async () => {
            return (await purchaseOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [purchaseOrderService.historyPO, page],
        async () => {
            return (await purchaseOrderService.HistoryPO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const approveRequestMutate = useMutation(
        [purchaseOrderService.approveRequest],
        async (data: { id: any; type_approve: any }) => {
            return (await purchaseOrderService.ApproveRequest(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Action Success");
            },
        }
    );

    const onClickLinkHandler = (id: number) => {
        setDetailHistoryID(id);
    };

    const [showConfirmReject] = useConfirmDelete<any>({
        title: "Reject Request Internal PO",
        content: "`You sure to reject Request Internal PO?",
        onOk: (data, callback) => {
            approveRequestMutate
                .mutateAsync({ id: id as any, type_approve: 2 })
                .then(callback)
                .catch(callback)
                .finally(detailQuery.refetch);
        },
    });

    const [showConfirmApprove] = useConfirmDelete<any>({
        title: "Approve Request Internal PO",
        content: "`You sure to approve Request Internal PO?",
        onOk: (data, callback) => {
            approveRequestMutate
                .mutateAsync({ id: id as any, type_approve: 1 })
                .then(callback)
                .catch(callback)
                .finally(detailQuery.refetch);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Request Internal PO"
                rightAddition={() => (
                    <>
                        {detailQuery.data?.approver && (
                            <Space>
                                <Button
                                    onClick={showConfirmReject}
                                    type="ghost"
                                    loading={approveRequestMutate.isLoading}
                                    disabled={detailQuery.data?.order_status !== 2}
                                >
                                    Reject Request Internal PO
                                </Button>
                                <Button
                                    onClick={showConfirmApprove}
                                    type="primary"
                                    loading={approveRequestMutate.isLoading}
                                    disabled={detailQuery.data?.order_status !== 2}
                                >
                                    Approve Request Internal PO
                                </Button>
                            </Space>
                        )}
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="w-full grid grid-cols-4 gap-x-4">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{detailQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            note <br />
                                            <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            status <br />
                                            <span
                                                className={
                                                    detailQuery.data?.order_status === -1
                                                        ? "underline italic m-0 text-red-600"
                                                        : "underline italic m-0"
                                                }
                                            >
                                                {STATUS_PURCHASE_ORDER_INT.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <ModalHistoryPO idHistory={detailHistoryID}>
                                    {(dt) => (
                                        <HistoryTable
                                            fetcher={history}
                                            onClickLink={(id) => {
                                                onClickLinkHandler(id);
                                                dt.openModal();
                                            }}
                                        />
                                    )}
                                </ModalHistoryPO>
                            ),
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default RequestIntPOView;

/* eslint-disable no-useless-constructor */
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { BasePaginationResponse } from "models";
import { TYPE_POS_ONLINE } from "utils/constant";

export interface DailyCust {
    daily_customer: number;
    diff_cust_percentage: number;
}

export interface TodaySale {
    total_sales: number;
    diff_sales_percentage: number;
}

export interface Report {
    // today_sales: TodaySale[];
    // daily_cust: DailyCust[];
    total_customer: string;
    total_revenue: string;
    total_product: string;
    average: string;
}

export interface TopTen {
    product_id: number;
    product_name: string;
    total_product: number;
    product_price: number;
    total_price: number;
}

export interface ListOverview {
    x_axis_day: string;
    y_axis_total: number;
}

export interface SalesOverview {
    list_overview: ListOverview[];
}

export interface SalesPerformance {
    sales_name: string;
    total_price: number;
}

export interface MostProductValue {
    product_id: number;
    product_name: string;
    product_qty: number;
    product_price: number;
    product_value: number;
}

export interface ParamReport {
    start_date: any;
    end_date: any;
    type?: number; // 1 online, 2 offline
}

export interface DetailTopCustomer {
    customer_id: number;
    start_date: any;
    end_date: any;
}

export interface PaymentChannel {
    payment_channel_id: number;
    grand_total: number;
}

export interface TopTransaction {
    sales_id: any;
    sales_name: string;
    total_transaction: number;
    total_price: number;
}

export interface ProductCategoryData {
    actual_invoice: number;
    actual_price: number;
    percentage_invoice: number;
    percentage_price: number;
    target_invoice: number;
    target_price: number;
    target_sales_id: number;
    target_sales_name: string;
    max_percent: number;
}

export interface InvoicePerProductParams {
    start_date: any;
    end_date: any;
    type: any;
    id: any;
    page: any;
}

export interface InvoiceProduct {
    id: number;
    inv_no: string;
    inv_ref: string;
    total_price: number;
    status?: number;
}

export interface TopCustomerData {
    customer_id: any;
    customer_name: string;
    total_transaction: number;
    total_price: number;
}

export interface DownloadData {
    inv_no?: string;
    code1?: string;
    name?: string;
    subcategory_name?: string;
    created_at?: string;
    is_active?: number;
    note?: string;
    payment_channel?: string;
    qty?: string;
    price?: string;
    diskon?: string;
}

export interface DownloadDataOnline {
    order_no?: string;
    sub_brand?: string;
    customer_name?: string;
    market_place?: string;
    customer_invoice?: string;
    code1?: string;
    product_name?: string;
    subcategory_name?: string;
    attribute_name?: string;
    created_at?: Date;
    is_active?: number;
    qty?: string;
    price?: string;
    diskon?: string;
}

export interface DetailTopCustomerList {
    id: number;
    created_at: string;
    inv_no: string;
    total_price: number;
    status?: number;
}

export interface SalesName {
    id: number;
    name: string;
}

export interface TechnicianName {
    teknisi_id: number;
    teknisi_name: string;
    account_id: number;
}

export interface SummarySalesPerformanceParam {
    start_date: any;
    end_date: any;
    sales_id?: any;
}
export interface SummaryTechnicianPerformanceParam {
    start_date: any;
    end_date: any;
    technician_id?: any;
}

export interface SummarySalesPerformance {
    [type: string]: {
        sales_name: string;
        total_price: number;
    }[];
}
export interface SummaryTechnicianPerformance {
    [type: string]: {
        technician_name: string;
        total_price: number;
    }[];
}

class ReportService extends BaseService {
    getAllReport = "/giias/report/get-all";

    getTopTen = "/giias/report/top-ten-product";

    chartSalesOverview = "/giias/report/chart-sales-overview";

    getSalesPerformance = "/giias/report/sales-performance";

    getMostProductValue = "/giias/report/most-product-value";

    topPaymentChannel = "/giias/report/top-payment-channel";

    topTransaction = "/giias/report/total-transaction";

    productCategory = "/giias/report/get-product-category";

    invoicePerProduct = "/giias/report/invoice-per-product";

    invoicePerSales = "/giias/report/invoice-per-sales";

    topCustomer = "/giias/report/top-customer";

    downloadOffline = "/giias/report/sales-offline";

    downloadOnline = "/giias/report/sales-online";

    detailTopCustomer = "/giias/report/detail-top-customer";

    salesName = "/giias/report/get-sales-name";

    technicianName = "/giias/report/get-technician-name";

    summarySalesPerformance = "/giias/report/sales-performance/summary";

    summaryTechnicianPerformance = "/giias/report/technician-performance/summary";

    constructor() {
        super();
    }

    SummaryTechnicianPerformance<T extends SummaryTechnicianPerformance[]>(params: SummaryTechnicianPerformanceParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.summaryTechnicianPerformance,
                config: { params },
            });
            return req;
        });
    }

    SummarySalesPerformance<T extends SummarySalesPerformance[]>(params: SummarySalesPerformanceParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.summarySalesPerformance,
                config: { params },
            });
            return req;
        });
    }

    TechnicianName<T extends TechnicianName[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.technicianName,
            });
            return req;
        });
    }

    SalesName<T extends SalesName[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.salesName,
            });
            return req;
        });
    }

    DetailTopCustomer<T extends DetailTopCustomerList[]>(params: DetailTopCustomer) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailTopCustomer,
                config: { params },
            });
            return req;
        });
    }

    Download<T extends DownloadData[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: params.type === TYPE_POS_ONLINE ? this.downloadOnline : this.downloadOffline,
                config: { params },
            });
            return req;
        });
    }

    TopCustomer<T extends TopCustomerData[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.topCustomer,
                config: { params },
            });
            return req;
        });
    }

    InvoicePerSales<T extends InvoiceProduct>(params: InvoicePerProductParams) {
        return this.ProxyRequest<BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponse<T>>({
                url: this.invoicePerSales,
                config: { params },
            });
            return req;
        });
    }

    InvoicePerProduct<T extends InvoiceProduct>(params: InvoicePerProductParams) {
        return this.ProxyRequest<BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponse<T>>({
                url: this.invoicePerProduct,
                config: { params },
            });
            return req;
        });
    }

    ProductCategory<T extends ProductCategoryData[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.productCategory,
                config: { params },
            });
            return req;
        });
    }

    TopTransaction<T extends TopTransaction[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.topTransaction,
                config: { params },
            });
            return req;
        });
    }

    TopPaymentChannel<T extends PaymentChannel[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.topPaymentChannel,
                config: { params },
            });
            return req;
        });
    }

    GetAllReport<T extends Report>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllReport,
                config: { params },
            });
            return req;
        });
    }

    GetTopTen<T extends TopTen[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getTopTen,
                config: { params },
            });
            return req;
        });
    }

    ChartSalesOverview<T extends SalesOverview>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.chartSalesOverview,
                config: { params },
            });
            return req;
        });
    }

    GetSalesPerformance<T extends SalesPerformance[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSalesPerformance,
                config: { params },
            });
            return req;
        });
    }

    GetMostProductValue<T extends MostProductValue[]>(params: ParamReport) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getMostProductValue,
                config: { params },
            });
            return req;
        });
    }
}

const reportService = new ReportService();
export default reportService;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import docInventoryService from "services/api-endpoints/dashboard/master-data/doc-inventory";
import { ProductDocInventory, ProductDocInv } from "../models";

type SelectOptionCustom = SelectOption & ProductDocInv;

export interface Props extends ControlledInputSelectProps<ProductDocInventory> {
    onSelectedData?: (id: number, data: SelectOptionCustom[]) => void;
}

export function withProduct<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const product = useQuery([docInventoryService.product], async () => {
            const req = await docInventoryService.Product();
            return req.data.data?.map((el) => ({ ...el, label: el?.product_name || "", value: el.product_id || "" } as SelectOptionCustom));
        });
        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(id, product.data || []);
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={product.isLoading} options={product.data || []} />;
    };
}

const ProductSelect = withProduct(ControlledSelectInput);

export default ProductSelect;

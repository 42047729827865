import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import TableTransferProduct from "modules/master-data/material-movement/add/table-transfer-product";
import LocatorSelect, { SelectOptionCustom } from "modules/master-data/material-movement/lib/locator-select";
import WarehouseSelect from "modules/master-data/material-movement/lib/warehouse-select";
import { SearchProduct } from "modules/master-data/material-movement/models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import materialMovementService, {
    CreateMM,
    GetProduct2Params,
    ProductTransferWithEdit,
} from "services/api-endpoints/dashboard/master-data/material-movement";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<SearchProduct>> = yup.object().shape({
    warehouse_from: yup.string().required("Warehouse from required"),
    warehouse_to: yup.string().required("Warehouse to required"),
    locator_from: yup.string().required("Locator from required"),
    option: yup.string(),
});

const MaterialMovementAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [option, setOption] = useState<number>(2);
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
        setValue,
    } = useForm<SearchProduct>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    // property table-transfer-product.tsx
    const [products, setProducts] = useState<ProductTransferWithEdit[]>([]);
    const [targetKeyTransfer, setTargetKeyTransfer] = useState<string[]>([]);
    // //

    const warehouseFrom = watch("warehouse_from");
    const warehouseTo = watch("warehouse_to");
    const locatorFrom = watch("locator_from");

    const productMutation = useMutation(
        [materialMovementService.getProduct, warehouseFrom, locatorFrom, option],
        async (data: GetProduct2Params) => {
            return (await materialMovementService.GetProduct2(data)).data.data;
        },
        {
            onSuccess(data) {
                setProducts(data?.map((el) => ({ ...el, locator_to: 1, qty_to: el?.qty_to || el?.qty_from || 1 })) || []);
            },
        }
    );

    const warehouseToQ = useQuery([materialMovementService.getWHTo], async () => {
        const req = await materialMovementService.GetWHTo();
        return req.data.data?.map((el) => ({ label: el?.warehouse_name || "", value: el.warehouse_id_to || "" } as SelectOption));
    });

    const warehouseFromQ = useQuery([materialMovementService.getWHFrom], async () => {
        const req = await materialMovementService.GetWHFrom();
        return req.data.data?.map((el) => ({ label: el?.warehouse_name || "", value: el.warehouse_id_from || "" } as SelectOption));
    });

    const locatorFromMutate = useMutation([materialMovementService.getLocatorFrom], async (wh: any) => {
        const req = await materialMovementService.GetLocatorFrom({ warehouse_id_from: wh });
        return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_from || "" } as SelectOptionCustom));
    });

    const locatorToQuery = useQuery(
        [materialMovementService.getLocatorTo, warehouseTo],
        async () => {
            const req = await materialMovementService.GetLocatorTo({ warehouse_id_to: warehouseTo });
            return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_to || "" } as SelectOptionCustom));
        },
        {
            enabled: !!warehouseTo,
        }
    );

    const createMutation = useMutation(
        [materialMovementService.create],
        async (data: CreateMM) => {
            return (await materialMovementService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Material Movement Created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [materialMovementService.createDraft],
        async (data: CreateMM) => {
            return (await materialMovementService.CreateDraft(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Material Movement Draft Created");
                navigate(-1);
            },
        }
    );

    const onChangeWarehouseFrom = (key: string) => {
        form.setFieldValue("locator_from", "");
        setValue("locator_from", "");
        locatorFromMutate.mutate(key);
    };

    // const onChangeOption = (key: number) => {
    //     if (key === 4) {
    //         form.setFieldValue("warehouse_from", 7);
    //         setValue("warehouse_from", 7);
    //         form.setFieldValue("warehouse_to", 7);
    //         setValue("warehouse_to", 7);
    //         form.setFieldValue("locator_from", 1);
    //         setValue("locator_from", 1);
    //     } else {
    //         form.setFieldValue("warehouse_from", "");
    //         setValue("warehouse_from", "");
    //         form.setFieldValue("warehouse_to", "");
    //         setValue("warehouse_to", "");
    //         form.setFieldValue("locator_from", "");
    //         setValue("locator_from", "");
    //     }
    // };

    const prepareSave = (note: string) => {
        const filteredProduct = products?.filter((p) => targetKeyTransfer.includes(p.id as any));

        if (!filteredProduct.length) {
            throw new Error("Select products first");
        }

        if (Object.keys(errors).length) throw new Error("Errors");

        const parseData: CreateMM = {
            type_stock_opname: "MATERIAL MOVEMENT",
            warehouse_id_from: warehouseFrom,
            warehouse_id_to: warehouseTo,
            area_from_id: locatorFrom,
            note,
            option,
            order_id: null,
            product: filteredProduct?.map((product) => ({
                order_id: 0,
                product_id: product?.product_id,
                locator_from: [{ area_id_from: product?.area_id, qty_from: product?.qty_from }],
                locator_to: [
                    {
                        area_id_to:
                            locatorToQuery.data?.find((loc) => loc.label === product?.locator_to || loc.value === product?.locator_to)?.value || "",
                        qty_to: product?.qty_to,
                    },
                ],
            })),
        };

        const emptyLoc = parseData.product?.reverse()?.filter((product) => {
            const filter = product.locator_to?.filter((loc) => {
                if (!loc.area_id_to) {
                    message.error(`Locator to can't be empty on product ${products?.find((p) => p.product_id === product.product_id)?.product_name}`);
                    return loc;
                }
                return null;
            });
            if (filter?.length) return filter;
            return null;
        });

        if (emptyLoc?.length) throw new Error(`Locator to can't be empty on product`);

        return parseData;
    };

    const onSaveDraftHandler = (note: string) => {
        try {
            const createData = prepareSave(note);
            createDraftMutation.mutate(createData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onSaveHandler = async (note: string) => {
        try {
            const createData = prepareSave(note);
            createMutation.mutate(createData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onSearchHandler = handleSubmit((data) => {
        const parseData: GetProduct2Params = {
            area_id_from: data.locator_from,
            warehouse_id_from: data.warehouse_from,
            warehouse_id_to: data.warehouse_to,
            option,
        };
        productMutation.mutate(parseData);
    });

    const titles = [
        warehouseFromQ?.data?.find((wh) => wh.value === warehouseFrom)?.label || "",
        warehouseToQ?.data?.find((wh) => wh.value === warehouseTo)?.label || "",
    ];

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new material movement"
                rightAddition={() => (
                    <Space>
                        <ModalNote title="Note Material Movement" onSubmit={onSaveDraftHandler}>
                            {(handler) => (
                                <Button
                                    loading={createDraftMutation.isLoading}
                                    onClick={handler.openModal}
                                    type="default"
                                    className="!flex !items-center"
                                >
                                    <RiDraftLine className="m-0 mr-2" />
                                    Draft
                                </Button>
                            )}
                        </ModalNote>
                        <ModalNote title="Note Material Movement" onSubmit={onSaveHandler}>
                            {(handler) => (
                                <Button loading={createMutation.isLoading} onClick={handler.openModal} type="primary" className="!flex !items-center">
                                    <BiSave className="m-0 mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNote>
                    </Space>
                )}
            />
            <Card>
                <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSearchHandler}>
                    <div className="grid grid-cols-3 gap-4">
                        <p className="capitalize m-0">
                            Sales order no <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <WarehouseSelect
                            allowClear
                            loading={warehouseFromQ.isLoading}
                            options={warehouseFromQ.data || []}
                            onSelect={onChangeWarehouseFrom}
                            control={control}
                            name="warehouse_from"
                            placeholder="WH From"
                            disabled={option === 4}
                        />
                        <WarehouseSelect
                            allowClear
                            loading={warehouseToQ.isLoading}
                            options={warehouseToQ.data || []}
                            control={control}
                            name="warehouse_to"
                            placeholder="WH To"
                            disabled={option === 4}
                        />
                        <p className="capitalize m-0">
                            Options
                            <br />
                            <span className="underline italic m-0">Warehouse Stock</span>
                        </p>
                        <LocatorSelect
                            allowClear
                            options={locatorFromMutate?.data || []}
                            loading={locatorFromMutate?.isLoading}
                            showSearch
                            control={control}
                            name="locator_from"
                            placeholder="Locator From"
                            disabled={option === 4}
                        />
                        <Button htmlType="submit" type="primary" className="!flex !items-center">
                            <BsSearch className="m-0 mr-2" />
                            Search
                        </Button>
                    </div>
                </Form>
            </Card>
            <TableTransferProduct
                titles={titles}
                products={products}
                setProducts={setProducts}
                locatorFetcher={locatorToQuery}
                setTargetKeys={setTargetKeyTransfer}
                targetKeys={targetKeyTransfer}
                loading={productMutation.isLoading || locatorToQuery.isLoading}
            />
        </div>
    );
};

export default MaterialMovementAdd;

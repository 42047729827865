import CardProcessItem from "modules/manufacture/card/process/item";
import CardSubProcessItem from "modules/manufacture/card/sub-process/item";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { ProcessEntity } from "modules/manufacture/utils/models";
import React from "react";

const ListProcess = () => {
    const { menu } = React.useContext(ManufactureContext) as any;

    const subProcess: ProcessEntity[] = [
        {
            id: 1,
            text: "Sub Process",
        },
    ];

    const process: ProcessEntity[] = [
        {
            id: 1,
            text: "Process",
        },
    ];

    if (menu !== MenuTypes.subProcess.id && menu !== MenuTypes.process.id) return null;
    return (
        <>
            {menu === MenuTypes.subProcess.id || menu === MenuTypes.process.id ? (
                <div className="flex gap-5">
                    {process.map((item) => (
                        <CardProcessItem key={item.id} attach={{ data: item }}>
                            {item.text}
                        </CardProcessItem>
                    ))}
                    {subProcess.map((item) => (
                        <CardSubProcessItem key={item.id} attach={{ data: item }}>
                            {item.text}
                        </CardSubProcessItem>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default ListProcess;

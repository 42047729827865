import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import CInvoiceView from "pages/c-invoice/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import { PAYMENT_INTERNAL_ORDER, PAYMENT_PURCHASING } from "utils/constant";

export type Props = ModalProps & {
    id?: any;
    type?: number;
};

const ModalDetailInv = ({ id, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => {
                if (type === PAYMENT_PURCHASING) {
                    return <PurchasingInventoryView paramId={id} asPreview />;
                }
                if (type === PAYMENT_INTERNAL_ORDER) {
                    return <CInvoiceView paramId={id} asPreview />;
                }
                return <p className="text-red-400 text-xl">Doc type not handled yet</p>;
            }}
        </ModalTemplate>
    );
};

export default ModalDetailInv;

import React from "react";
import { List, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import quantityService, { History } from "services/api-endpoints/dashboard/master-data/quantity";
import suppliesQuantityService from "services/api-endpoints/purchasing/supplies-quantity";

type Props = {
    loading?: boolean;
    onClickLink: (history: History | null) => void;
};

const SuppliesHistoryTable = ({ loading, onClickLink }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const warehouseId = searchParams.get("warehouse_id");

    const historyQuery = useQuery(
        [suppliesQuantityService.getHistory, id],
        async () => {
            return (await suppliesQuantityService.GetHistory({ suppliesID: id, page, warehouse_id: warehouseId })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<History> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((historyQuery.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <List.Item onClick={() => onClickLink(record)} className="text-xs cursor-pointer !text-primary">
                        {text}
                    </List.Item>
                );
            },
        },
        {
            title: "Doc Type",
            dataIndex: "doc_type",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Quantity",
            children: [
                {
                    title: "Qty",
                    dataIndex: "qty",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "qty_before",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "qty_after",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
            ],
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            bordered
            loading={historyQuery.isLoading || loading}
            columns={columns}
            dataSource={(historyQuery.data?.list_history as any[]) || []}
            className="w-full"
            pagination={{
                current: historyQuery.data?.current_page || 1,
                pageSize: 10,
                total: historyQuery.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default SuppliesHistoryTable;

import { List, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { History } from "services/api-endpoints/dashboard/master-data/quantity";
import { BasePaginationResponseHistory } from "../models";
import DetailModal from "./detail-modal";

type Props = {
    loading?: boolean;
    fetcher: UseQueryResult<BasePaginationResponseHistory<History>, unknown>;
    handleTableChangeOverride?: (page: string) => void;
};

const HistoryTable = ({ loading, fetcher, handleTableChangeOverride }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const page = pagination.current?.toString() || "1";
        if (handleTableChangeOverride) {
            handleTableChangeOverride(page);
            return;
        }
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<History> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <DetailModal data={record}>
                        {(dt) => (
                            <List.Item onClick={dt.openModal} className="text-xs cursor-pointer !text-primary">
                                {text || "-"}
                            </List.Item>
                        )}
                    </DetailModal>
                );
            },
        },
        {
            title: "Doc Type",
            dataIndex: "doc_type",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Quantity",
            children: [
                {
                    title: "Qty",
                    dataIndex: "qty",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "qty_before",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "qty_after",
                    render: (text) => <p className="capitalize m-0">{text}</p>,
                },
            ],
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            bordered
            loading={fetcher.isLoading || loading}
            columns={columns}
            dataSource={fetcher.data?.list_history || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default HistoryTable;

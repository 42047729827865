import React, { useState } from "react";
import { Cascader } from "antd";
import { useMutation, useQuery } from "react-query";
import specificSearchService, { ModelType } from "services/api-endpoints/giias/cashier-giias/specific-search";

export interface Option {
    value: string | number;
    label: string;
    id: string | number;
    model_id?: string | number;
    type_id?: string | number;
    children?: Option[];
    isLeaf?: boolean;
    loading?: boolean;
}

type Props = {
    onFinalSelect: (data: Option | null) => void;
};

const Filter: React.FC<Props> = ({ onFinalSelect }) => {
    const [options, setOptions] = useState<Option[]>([]);

    const getBrand = useQuery(
        [specificSearchService.getBrand],
        async () => {
            return (await specificSearchService.GetBrand()).data.data;
        },
        {
            onSuccess: (data) => {
                if (options.length) return;
                setOptions(
                    data.map((el) => ({ value: el.brand_id + el.brand_name, id: el.brand_id, label: el.brand_name, isLeaf: false } as Option))
                );
            },
        }
    );

    const getModelByBrand = useMutation([specificSearchService.getModelByBrand], async (id: any) => {
        return (await specificSearchService.GetModelByBrand({ brand_id: id })).data.data;
    });

    const getModelType = useMutation([specificSearchService.GetModelType], async (id: any) => {
        return (await specificSearchService.GetModelType({ model_id: id })).data.data;
    });

    const loadData = (selectedOptions: Option[]) => {
        const index = selectedOptions.length;
        const targetOption = selectedOptions[index - 1];
        targetOption.loading = true;

        if (index === 1) {
            getModelByBrand.mutateAsync(selectedOptions[0].id).then((res) => {
                targetOption.loading = false;
                targetOption.children =
                    res.model_list?.map(
                        (el) => ({ value: el.model_id + el.model_name, id: el.model_id, label: el.model_name, isLeaf: false } as Option)
                    ) || [];
                setOptions([...options]);
            });
            return;
        }

        if (index === 2) {
            getModelType.mutateAsync(selectedOptions[1].id).then((res) => {
                targetOption.loading = false;
                targetOption.children =
                    res?.map(
                        (el) =>
                            ({
                                value: el.type_id + el.name,
                                id: el.type_id + el.name,
                                type_id: el.type_id,
                                model_id: el.model_id,
                                label: el.name,
                                isLeaf: true,
                            } as Option)
                    ) || [];
                setOptions([...options]);
            });
        }
    };

    const onChange = (value: string[], selectedOptions: Option[]) => {
        onFinalSelect(value?.length === 3 ? selectedOptions[2] : null);
    };

    return (
        <Cascader
            loading={getBrand.isLoading || getModelByBrand.isLoading || getModelType.isLoading}
            placeholder="Filter"
            className="!w-[400px]"
            options={options}
            loadData={loadData as any}
            onChange={onChange as any}
            changeOnSelect
        />
    );
};

export default Filter;

import Layout from "components/common/layout";

import { AnimatePresence, motion } from "framer-motion";
import { Alert, Button, Layout as LayoutAntd } from "antd";
import SidebarApp from "components/common/sidebar";
import { StateContext } from "context/state";
import { ManufactureContext, ManufactureProvider } from "modules/manufacture/context/context";
import Draw from "modules/manufacture/draw";
import ListOption from "modules/manufacture/draw/list-option";
import LeftSidebar from "modules/manufacture/left-sidebar";
import Sidebar from "modules/manufacture/sidebar";
import { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SlPuzzle } from "react-icons/sl";

const ManufactureBuilderProvide = () => {
    const { state: stateContext, setState: setStateContext } = useContext(StateContext);
    const { modelEdit } = useContext(ManufactureContext) as any;
    const [alertEdit, setAlertEdit] = useState({
        show: false,
        noEverShow: false,
    });

    const onCollapseSidebar = (value: boolean) => {
        if (setStateContext) {
            setStateContext((prev) => ({
                ...prev,
                menuCollapsed: value,
            }));
        }
    };

    const closeAlertEdit = () => {
        setAlertEdit((prev) => ({
            ...prev,
            noEverShow: false,
        }));
    };

    useEffect(() => {
        if (modelEdit && !alertEdit.show) {
            setAlertEdit({
                show: true,
                noEverShow: true,
            });
        } else if (!modelEdit && alertEdit.show) {
            closeAlertEdit();
        }
    }, [modelEdit]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Layout>
                {({ isActionPath, offlineAlert }) => (
                    <LayoutAntd className="w-full flex min-h-screen">
                        <LayoutAntd.Sider
                            className="!overflow-auto !h-screen !fixed !left-0 !top-0 !bottom-0 z-100"
                            theme="light"
                            collapsible
                            collapsed={stateContext.menuCollapsed}
                            onCollapse={onCollapseSidebar}
                            width={230}
                            collapsedWidth={80}
                        >
                            <SidebarApp collapse={stateContext.menuCollapsed!} />
                        </LayoutAntd.Sider>
                        <LayoutAntd.Content>
                            <motion.div
                                animate={{ paddingLeft: stateContext.menuCollapsed ? 80 : 230 }}
                                className="fixed !w-full top-0 left-0 z-20 flex flex-col"
                            >
                                {offlineAlert()}
                                <AnimatePresence>
                                    {alertEdit.show && alertEdit.noEverShow && (
                                        <motion.div exit={{ y: -100 }} animate={{ y: 0, transition: { delay: 1 } }} initial={{ y: -100 }}>
                                            <Alert
                                                type="info"
                                                icon={<SlPuzzle />}
                                                banner
                                                description={
                                                    <div className="flex items-center gap-4">
                                                        Dont worry, your previous work will restore after exiting edit mode 👍
                                                        <Button onClick={closeAlertEdit} size="small" type="text">
                                                            Close
                                                        </Button>
                                                    </div>
                                                }
                                            />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                            <motion.div
                                animate={{ paddingLeft: stateContext.menuCollapsed ? 80 : 230 }}
                                transition={{ duration: 0.2 }}
                                className="fixed bottom-0 pointer-events-none left-0 w-full z-50"
                            >
                                <ListOption />
                                <Sidebar />
                            </motion.div>
                            <LeftSidebar />
                        </LayoutAntd.Content>
                    </LayoutAntd>
                )}
            </Layout>
            <Draw />
        </DndProvider>
    );
};

const ManufactureBuilder = () => {
    return (
        <ManufactureProvider>
            <ManufactureBuilderProvide />
        </ManufactureProvider>
    );
};

export default ManufactureBuilder;

/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-constructor */
import * as Models from "models";
import configFirebase from "config/firebase";
import { FirebaseApp } from "firebase/app";
import { Auth, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface IdTokenEmail {
    idToken: string;
    email: string;
}

export interface AppsLink {
    id: number;
    name: string;
    version: string;
    url: string;
}
export interface ChangePassData {
    email: string;
    idToken: string;
    password: string;
    new_password: string;
    confirm_password: string;
}

class AuthService extends BaseService {
    signIn = "/user/sign-in/dashboard";

    changePass = "/user/change-password";

    getListAppsDistribution = "/autoloka/get-list-apps";

    auth: Auth;

    config: FirebaseApp;

    constructor(config: FirebaseApp) {
        super();
        this.config = config;
        this.auth = getAuth(this.config);
    }

    ChangePassToServer<T extends Models.AuthResponse>(data: ChangePassData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.changePass,
                data,
            });
            return req;
        });
    }

    _signInToFirebase(data: Models.SignInEmailData) {
        return this.ProxyRequestNoAxios<string>(async () => {
            const user = await signInWithEmailAndPassword(this.auth, data.email, data.password);
            if (!user) throw new Error("User not defined");

            const idToken = await this.auth.currentUser?.getIdToken();
            if (!idToken) throw new Error("Cannot get id token");
            return idToken;
        });
    }

    _signInToServer<T extends Models.AuthResponse>(data: IdTokenEmail) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.signIn,
                data,
            });
            return req;
        });
    }

    ChangePass<T extends Models.AuthResponse>(data: ChangePassData) {
        return this.ProxyRequest<T>(async () => {
            const idToken = await this._signInToFirebase(data);
            const parseData = { ...data, idToken };
            const authData = await this.ChangePassToServer<T>(parseData);
            return authData;
        });
    }

    SignIn<T extends Models.AuthResponse>(data: Models.SignInEmailData) {
        return this.ProxyRequest<T>(async () => {
            const idToken = await this._signInToFirebase(data);
            const authData = await this._signInToServer<T>({ email: data.email, idToken });
            return authData;
        });
    }

    GetListAppDistribution<T extends AppsLink[]>(data: Models.SignInEmailData) {
        return this.ProxyRequest<T>(async () => {
            const idToken = await this._signInToFirebase(data);
            const req = await ApiMethod.post<T>({
                url: this.getListAppsDistribution,
                data: {
                    email: data.email,
                    idToken,
                },
                config: {
                    headers: {},
                },
            });
            return req;
        });
    }
}

const authService = new AuthService(configFirebase.app);
export default authService;

import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation, useQuery } from "react-query";
import masterCoaService, { CreateCoa, EditCoa } from "services/api-endpoints/accounting/master-coa";
import AccTypeSelect from "modules/accounting/master-coa/lib/acc-type-select";
import State from "components/common/state";
import { STATUS_ACTIVE } from "utils/constant";

const schema: yup.SchemaOf<Partial<CreateCoa>> = yup.object().shape({
    code: yup.string().required("Code required"),
    name_in: yup.string().required("Name IN required"),
    name_en: yup.string().required("Name EN required"),
    parent_id: yup.string().nullable(),
    type_id: yup.number().required("Type required"),
    currency_id: yup.number().required("Currency required"),
    is_active: yup.number().required("Status required"),
    current_balance: yup.string().required("Beginning Balance required"),
});

const MasterCoaEdit = ({ id, onSaveSuccess, onClose }: { id: any; onSaveSuccess: () => void; onClose: () => void }) => {
    const [form] = Form.useForm();
    const [balance, setBalance] = React.useState<string>();
    const { handleSubmit, control, setValue } = useForm<CreateCoa>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const editMutation = useMutation(
        [masterCoaService.createCoa],
        async (data: EditCoa) => {
            return (await masterCoaService.EditCoa(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("COA Edited");
                onSaveSuccess();
            },
        }
    );

    const detailQuery = useQuery(
        [masterCoaService.detailCoa, id],
        async () => {
            return (await masterCoaService.DetailCoa({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({ ...data });
                setValue("name_in", data.name_in);
                setValue("name_en", data.name_en);
                setValue("parent_id", data.parent_id);
                setValue("type_id", data.type_id);
                setValue("currency_id", data.currency_id);
                setValue("is_active", data.is_active);
                setValue("code", data.account);
                setValue("current_balance", String(data.current_balance || 0));
                setBalance(String(data.current_balance || 0));
            },
        }
    );

    const getParent = useQuery([masterCoaService.getParent], async () => {
        return (await masterCoaService.GetParent()).data.data;
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const temp: EditCoa = {
            ...data,
            id: id as any,
            current_balance: balance === data.current_balance ? null : (data.current_balance || "0").replaceAll(".", ""),
        };
        editMutation.mutate(temp);
    });

    return (
        <div className="w-full flex flex-col gap-6 ">
            <ToolbarAction
                title="edit COA"
                rightAddition={() => (
                    <Space>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                        <Button danger onClick={onClose}>
                            Close
                        </Button>
                    </Space>
                )}
            />
            <div className="w-full">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form
                                    form={form}
                                    disabled={editMutation.isLoading}
                                    layout="vertical"
                                    onFinish={onSubmitHandler}
                                    className="grid grid-cols-2 gap-4"
                                >
                                    <p className="capitalize m-0">
                                        Code <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.account}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Name IN <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.name_in}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Name EN <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.name_en}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Parent <br />
                                        <span className="font-semibold underline m-0">
                                            {getParent.data?.find((item) => item.parent_id === detailQuery.data?.parent_id)?.parent_name || "-"}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Currency <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.currency_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Is Active <br />
                                        <span className="font-semibold underline m-0">
                                            {STATUS_ACTIVE.find((item) => item.value === detailQuery.data?.is_active)?.label}
                                        </span>
                                    </p>
                                    <AccTypeSelect allowClear control={control} name="type_id" placeholder="Acc Type" label="Acc Type" />
                                    <ControlledInputText
                                        allowClear
                                        control={control}
                                        name="current_balance"
                                        placeholder="Beginning Balance"
                                        label="Beginning Balance"
                                        type="number"
                                    />
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 4 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
        </div>
    );
};

export default MasterCoaEdit;

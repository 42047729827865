import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import { useQuery } from "react-query";
import purchasingInventoryService from "services/api-endpoints/purchasing/inventory";
import { DOC_TYPE_PURCHASING, FORMAT_DATE_1, STATUS_PURCHASING } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterGrInventory = ({ filter = [], ...props }: Props) => {
    const vendorQuery = useQuery([purchasingInventoryService.getBp], async () => {
        return (await purchasingInventoryService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const createdByQuery = useQuery([purchasingInventoryService.createdBy], async () => {
        return (await purchasingInventoryService.CreatedBy()).data.data?.map(
            (el) => ({ label: `${el.full_name} - ${el.email}`, value: el.created_by } as SelectOption)
        );
    });
    return (
        <FilterModal
            filter={filter}
            title="Filter GR Inventory"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <div className="w-full">
                            <p>Date Range</p>
                            <DatePicker.RangePicker
                                className="w-full"
                                value={[
                                    dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                    dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                                ]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <p>Vendor</p>
                            <Select
                                className="w-full"
                                allowClear
                                showSearch
                                optionFilterProp="label"
                                value={dt.values.vendor_id ? dt.values.vendor_id : null}
                                placeholder="Vendor"
                                options={vendorQuery.data}
                                onChange={(val) => {
                                    dt.setOneValue("vendor_id", val);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <p>Request By</p>
                            <Select
                                className="w-full"
                                showSearch
                                allowClear
                                value={dt.values.created_by ? dt.values.created_by : null}
                                placeholder="Request By"
                                options={createdByQuery.data}
                                loading={createdByQuery.isLoading}
                                optionFilterProp="label"
                                filterOption={(input, option) => {
                                    return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                }}
                                onChange={(val) => {
                                    dt.setOneValue("created_by", val);
                                }}
                            />
                        </div>
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterGrInventory;

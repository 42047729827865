/* eslint-disable no-shadow */
import { Alert, Card, Skeleton, StepProps, Steps } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { STEP_CREATE_VOUCHER, RULE_INFORMATION_STEP, DATA_CUSTOMER_STEP, CONDITION_STEP, DETAIL_CONDITION_STEP, ACTION_STEP } from "utils/constant";
import RuleInformation from "modules/master-data/voucher/view/rule-information";
import DataCustomer from "modules/master-data/voucher/view/data-customer";
import Condition from "modules/master-data/voucher/view/condition";
import DetailCondition from "modules/master-data/voucher/view/detail-condition";
import Action from "modules/master-data/voucher/view/action";
import { ImInfo } from "react-icons/im";
import { VoucherContext, VoucherProvider } from "modules/master-data/voucher/context/voucher";
import voucherService from "services/api-endpoints/dashboard/master-data/voucher";
import State from "components/common/state";
import { FaUsers } from "react-icons/fa";
import { MdAssignment, MdAssignmentTurnedIn } from "react-icons/md";
import { VscSymbolProperty } from "react-icons/vsc";

const VIEW_STEP_VOUCHER = [
    { key: RULE_INFORMATION_STEP, icon: <ImInfo /> },
    { key: DATA_CUSTOMER_STEP, icon: <FaUsers /> },
    { key: CONDITION_STEP, icon: <MdAssignmentTurnedIn /> },
    { key: DETAIL_CONDITION_STEP, icon: <MdAssignment /> },
    { key: ACTION_STEP, icon: <VscSymbolProperty /> },
];

const Main = () => {
    const [params] = useSearchParams();
    const id = params.get("id");

    const { currentStep, state, setState, setCurrentStep } = useContext(VoucherContext);

    const detailQuery = useQuery(
        [voucherService.detail, id],
        async () => {
            return (await voucherService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                if (setState) {
                    setState((prev) => ({
                        ...prev,
                        createData: data,
                    }));
                }
            },
        }
    );

    const component = useMemo(() => {
        const components = [<RuleInformation />, <DataCustomer />, <Condition />, <DetailCondition />, <Action />];
        return components[currentStep!];
    }, [currentStep, state?.createData]);

    const onChangeNavigation = (value: number) => {
        if (setCurrentStep) {
            setCurrentStep(value);
        }
    };

    const viewStepVoucher = VIEW_STEP_VOUCHER.map((view) => {
        const create = STEP_CREATE_VOUCHER.find((crt) => crt.key === view.key);
        return { ...create, ...view, status: "process" as StepProps["status"] };
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail voucher" />
            <Card>
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="w-full flex flex-col gap-10">
                                    <Steps type="navigation" onChange={onChangeNavigation} current={currentStep} items={viewStepVoucher} />
                                    {component}
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 5 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

const VoucherView = () => (
    <VoucherProvider>
        <Main />
    </VoucherProvider>
);

export default VoucherView;

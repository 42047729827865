import moment from "moment";
import { JurnalUmum } from "services/api-endpoints/accounting/jurnal-umum";
import { FORMAT_DATE_2, ORDER_CANCELED } from "utils/constant";
import XLSX from "xlsx-js-style";

export default {};
export const jurnalTableToExcel = ({ title, listJurnal }: { title: string; listJurnal: JurnalUmum[] }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Date", ...headerStyle },
        { v: "Account", ...headerStyle },
        { v: "Ref ID", ...headerStyle },
        { v: "Account Name", ...headerStyle },
        { v: "Debit", ...headerStyle },
        { v: "Kredit", ...headerStyle },
        { v: "Status", ...headerStyle },
    ];

    const errorStyle = { font: { bold: true, color: { rgb: "de122d" } } };

    const rows: any[] = [];
    listJurnal?.forEach((el, listIndex) => {
        if (!el.child?.length) {
            rows.push([
                { v: listIndex + 1, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: moment(el.created_at)?.format(FORMAT_DATE_2), t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: el.account, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: el.doc_no, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                null,
                null,
                null,
                { v: el.is_acc_posted ? "Posted" : "Unposted", t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
            ]);
            return;
        }
        el.child?.forEach((child, childIndex) => {
            const row = [
                { v: listIndex + 1, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: moment(el.created_at)?.format(FORMAT_DATE_2), t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: el.account, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: el.doc_no, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: child.name, t: "s", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: child.type === "DEBIT" ? child.amount : "-", t: "n", s: el.status === ORDER_CANCELED ? errorStyle : {} },
                { v: child.type === "KREDIT" ? child.amount : "-", t: "n", s: el.status === ORDER_CANCELED ? errorStyle : {} },
            ];

            if (childIndex === 0) {
                row.push({ v: el.is_acc_posted ? "Posted" : "Unposted", t: "s" } as any);
            }

            rows.push(row);
        });
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
};

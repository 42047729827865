/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import React from "react";
import { useQuery } from "react-query";
import jurnalPenyesuaianService, { AccountItem } from "services/api-endpoints/accounting/jurnal-penyesuaian";
import { AddItem } from "../add/modal-add-item";

export interface Props extends ControlledInputSelectProps<AddItem> {
    onSelectedData?: (data?: AccountItem) => void;
}

export function withAccountCOA<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const locator = useQuery([jurnalPenyesuaianService.account], async () => {
            const req = await jurnalPenyesuaianService.GetAccount();
            return req.data.data;
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(locator?.data?.find((el) => el.coa_id === id));
            }
        };

        return (
            <Component
                {...props}
                allowClear
                onSelect={onSelect}
                onDeselect={onSelect}
                showSearch
                loading={locator.isLoading}
                options={locator.data?.map((el) => ({ label: el?.coa_name || "", value: el.coa_id || "" })) || []}
            />
        );
    };
}

const AccountSelect = withAccountCOA(ControlledSelectInput);

export default AccountSelect;

/* eslint-disable no-shadow */
import { Select } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { SelectOption } from "models";
import DocInventoryTable from "modules/master-data/doc-inventory/index/table";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import docInventoryService from "services/api-endpoints/dashboard/master-data/doc-inventory";
import priceService from "services/api-endpoints/dashboard/master-data/price";

const DocInventory = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const warehouse = searchParams.get("warehouse_id");

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const changeWarehouse = ({ id, page }: { id: any; page: string }) => {
        searchParams.set("warehouse_id", id);
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.warehouse],
        async () => {
            return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            onSuccess: (data) => {
                if (warehouse) return;
                changeWarehouse({ id: data[0].value, page: "1" });
            },
        }
    );

    const getList = useQuery(
        [docInventoryService.getAll, page, searchValue, isSearch, warehouse],
        async () => {
            if (isSearch) return (await docInventoryService.Search({ page, param_search: searchValue.query, warehouse_id: warehouse })).data.data;
            return (await docInventoryService.GetAll({ page, warehouse_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    useEffect(() => {
        (() => {
            if (warehouse) return;
            getWarehouse.refetch();
        })();
    }, [warehouse]);

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar
                filter={false}
                prefixAddition={() => (
                    <Select
                        className="!w-[400px]"
                        showSearch
                        value={warehouse ? Number(warehouse) : ""}
                        loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                        placeholder="Warehouse"
                        options={getWarehouse.data || []}
                        onChange={(val) => changeWarehouse({ id: val, page: "1" })}
                    />
                )}
                placeholder="Doc ID / Note"
                defaultParams={["warehouse_id"]}
            />
            <DocInventoryTable fetcher={getList} />
        </div>
    );
};

export default DocInventory;

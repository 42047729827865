import { ProductCPL, ProductDeliveryOrder } from "services/api-endpoints/dashboard/master-data/c-delivery-order";
import { ProductPerformaInv } from "services/api-endpoints/dashboard/master-data/c-sales-order";
import { DetailProduct } from "services/api-endpoints/dashboard/master-data/ext-sales-order";

export function mapDuplicateProducts(arr: ProductDeliveryOrder[]) {
    let after: ProductDeliveryOrder[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id && p.voucher_id === el.voucher_id)) {
            after = after.map((ap) => {
                if (ap.product_id === el.product_id && ap.voucher_id === el.voucher_id) {
                    return {
                        ...ap,
                        so_no: `${ap.pl_id || "-"}, ${el.pl_id || "-"}`,
                        qty: ap.qty! + el.qty!,
                        discount_price: ap.discount_price! + el.discount_price!,
                        no_koli: ap.no_koli !== el.no_koli ? `${ap.no_koli || "-"}, ${el.no_koli || "-"}` : ap.no_koli,
                    };
                }
                return ap;
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export function mapDuplicateProduct2(arr: ProductCPL[]) {
    let after: ProductCPL[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id && p.voucher_id === el.voucher_id)) {
            after = after.map((ap) => {
                if (ap.product_id === el.product_id && ap.voucher_id === el.voucher_id) {
                    return {
                        ...ap,
                        so_no: `${ap.id || "-"}, ${el.id || "-"}`,
                        qty: ap.qty! + el.qty!,
                        discount_price: ap.discount_price! + el.discount_price!,
                        no_koli: ap.no_koli !== el.no_koli ? `${ap.no_koli || "-"}, ${el.no_koli || "-"}` : ap.no_koli,
                    };
                }
                return ap;
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export function mapDuplicateProductDetail(arr: ProductDeliveryOrder[]) {
    let after: ProductDeliveryOrder[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id && p.voucher_id === el.voucher_id)) {
            after = after.map((ap) => {
                if (ap.product_id === el.product_id && ap.voucher_id === el.voucher_id) {
                    return {
                        ...ap,
                        qty: ap.qty! + el.qty!,
                        discount_price: ap.discount_price! + el.discount_price!,
                        no_koli: ap.no_koli !== el.no_koli ? `${ap.no_koli || "-"}, ${el.no_koli || "-"}` : ap.no_koli,
                    };
                }
                return ap;
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export function mapDiscountProduct(arr: ProductPerformaInv[], discount: ProductPerformaInv[]) {
    return arr?.map((product) => {
        const matchingItem = discount.find((disc) => disc.product_id === product.product_id);
        if (matchingItem) {
            return {
                ...product,
                voucher_id: matchingItem.voucher_id,
                discount_price: matchingItem.discount_price,
                note: matchingItem.note,
            } as ProductPerformaInv;
        }
        return {
            ...product,
            voucher_id: 0,
            discount_price: 0,
            note: undefined,
        } as ProductPerformaInv;
    });
}

export function mapDiscountProductExt(arr: DetailProduct[], discount: DetailProduct[]) {
    return arr?.map((product) => {
        const matchingItem = discount.find((disc) => disc.product_id === product.product_id);
        if (matchingItem) {
            return {
                ...product,
                voucher_id: matchingItem.voucher_id,
                discount_price: matchingItem.discount_price,
                note: matchingItem.note,
            } as DetailProduct;
        }
        return {
            ...product,
            voucher_id: 0,
            discount_price: 0,
            note: undefined,
        } as DetailProduct;
    });
}

export default {};

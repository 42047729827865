import { Alert, Card, Skeleton } from "antd";
import State from "components/common/state";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService from "services/api-endpoints/dashboard-giias/report";
import { PAYMENT_CHANNEL, TYPE_POS_OFFLINE } from "utils/constant";

const TopPaymentChannel = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const watchParams = [startDate, endDate, typeSales];

    const topTenQuery = useQuery([reportService.topPaymentChannel, ...watchParams], async () => {
        return (await reportService.TopPaymentChannel({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
    });

    return (
        <Card className="h-full">
            <State data={topTenQuery.data} isLoading={topTenQuery.isLoading} isError={topTenQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <p className="m-4 text-center font-light text-xl text-gray-500 capitalize">top payment</p>
                            <div className="w-full h-[250px] 2xl:h-[430px] overflow-auto pr-3">
                                {topTenQuery.data?.map((item) => (
                                    <p className="m-0 capitalize text-gray-700 text-xs font-bold" key={item.payment_channel_id}>
                                        {PAYMENT_CHANNEL.find((pc) => pc.value === item.payment_channel_id)?.label} <br />
                                        <span className="capitalize font-medium text-gray-400">{item.grand_total?.ToIndCurrency("Rp")}</span>
                                        <div className="w-full bg-gray-200 my-4" style={{ height: 1 }} />
                                    </p>
                                ))}
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 3 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(topTenQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </Card>
    );
};

export default TopPaymentChannel;

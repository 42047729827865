import { Modal } from "antd";
import React from "react";

type Props<T> = {
    title?: string;
    content?: any;
    onOk: (data: T, callback: () => void) => void;
    onCancel?: () => void;
};

const useConfirmDelete = <T>({ title, content, onOk, onCancel }: Props<T>) => {
    const showConfirm = (data: T) => {
        Modal.confirm({
            title: title || "Delete",
            content: content || `You sure to delete this row?`,
            onOk() {
                return new Promise((res, rej) => {
                    onOk(data, () => {
                        res(true);
                    });
                });
            },
            onCancel() {
                if (onCancel) onCancel();
            },
        });
    };

    return [showConfirm];
};

export default useConfirmDelete;

/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import LemburTable from "modules/lembur/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import hadirAppService from "services/api-endpoints/hadir-app/hadir-app-service";

const Lembur = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const getAll = useQuery([hadirAppService.getListLembur, page], async () => {
        return (await hadirAppService.GetListLembur({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} add={false} />
            <LemburTable fetcher={getAll} />
        </div>
    );
};

export default Lembur;

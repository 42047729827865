import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import PemakaianIndexTable from "modules/dashboard-jsl/pamakaian-barang/table-index";
import PenerimaanIndexTable from "modules/dashboard-jsl/penerimaan-barang/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import pemakaianBarangService from "services/api-endpoints/dashboard/master-data/pemakaian-barang";

const filter = ["do_no", "inv", "bp_id"];

const PemakaianBarang = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([pemakaianBarangService.getAll, page, searchValue, isSearch, isFilter, filterValue], async () => {
        return (await pemakaianBarangService.GetAll({ start_date: "", end_date: "", page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} />
            <PemakaianIndexTable fetcher={getList} />
        </div>
    );
};

export default PemakaianBarang;

import Cookies from "js-cookie";
import { AuthResponse, MainMenu } from "models";
import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import appsMenuService from "services/api-endpoints/dashboard/apps-menu";
import { EMAIL_USER, ID_USER, TOKEN_USER } from "utils/constant";

type Props = {
    children: any;
};

type StateType = {
    user?: Partial<AuthResponse> | null;
};

type ValueContextType = {
    state: StateType;
    setState?: Dispatch<SetStateAction<StateType>>;
    saveUser?: (user: AuthResponse) => void;
    allApps?: UseQueryResult<MainMenu[], unknown>;
};

const UserContext = createContext<ValueContextType>({
    state: {
        user: {
            token: Cookies.get(TOKEN_USER),
            id: Cookies.get(ID_USER),
            email: Cookies.get(EMAIL_USER),
        },
    },
});

function UserProvider({ children }: Props) {
    const [state, setState] = useState<StateType | null>({
        user: {
            token: Cookies.get(TOKEN_USER),
            id: Cookies.get(ID_USER),
            email: Cookies.get(EMAIL_USER),
        },
    });

    const saveUser = (user: AuthResponse) => {
        Cookies.set(TOKEN_USER, user.token);
        Cookies.set(ID_USER, user.id);
        Cookies.set(EMAIL_USER, user.email);
        setState((prev) => ({
            ...prev,
            user: {
                ...prev?.user,
                ...user,
            },
        }));
    };

    const allApps = useQuery(
        [appsMenuService.getMainMenu],
        async () => {
            const req = await appsMenuService.GetMainMenu();
            return req.data.data;
        },
        {
            onSuccess: (data) => {
                setState((prev) => ({
                    ...prev,
                    user: {
                        ...prev?.user,
                        apps: data,
                    },
                }));
            },
            enabled: !!state?.user?.token && !state.user.apps,
        }
    );

    const value = useMemo(
        () => ({
            state,
            setState,
            saveUser,
            allApps,
        }),
        [state]
    );
    return <UserContext.Provider value={value as any}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };

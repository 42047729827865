import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import seasonalProductService from "services/api-endpoints/dashboard/master-data/seasonal-product";
import SeasonalProductTable from "modules/master-data/seasonal-product/index/table";
import FilterSeasonalProduct from "modules/master-data/seasonal-product/index/filter";

const filter = ["title", "subtitle", "start_date", "end_date"];

const SeasonalProduct = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([seasonalProductService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await seasonalProductService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await seasonalProductService.Filter(filterValue as any)).data.data;
        return (await seasonalProductService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterSeasonalProduct filter={filter}>
                {(dt) => <Toolbar placeholder="Title or Subtitle" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterSeasonalProduct>
            <SeasonalProductTable fetcher={getList} />
        </div>
    );
};

export default SeasonalProduct;

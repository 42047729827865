import { DetailPL, ListDetail } from "services/api-endpoints/dashboard-jsl/delivery-order";

export function mapDuplicateProducts(arr: DetailPL[]) {
    let after: DetailPL[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id)) {
            after = after.map((ap) => {
                if (ap.product_id !== el.product_id) return ap;
                return {
                    ...ap,
                    so_no: `${ap.so_no || "FREE"}, ${el.so_no || "FREE"}`,
                    qty: ap.qty! + el.qty!,
                };
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export function mapDuplicateProducts2(arr: ListDetail[]) {
    let after: ListDetail[] = [];
    arr?.forEach((el) => {
        if (after.find((p) => p.product_id === el.product_id)) {
            after = after.map((ap) => {
                if (ap.product_id !== el.product_id) return ap;
                return {
                    ...ap,
                    so_no: `${ap.so_no || "FREE"}, ${el.so_no || "FREE"}`,
                    qty: ap.qty! + el.qty!,
                };
            });
            return;
        }
        after.push(el);
    });
    return after;
}

export default {};

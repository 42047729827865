/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface GetReason {
    reason_id: number;
    reason_name: string;
}

export interface DataOrder {
    order_id: number;
    inv_no: string;
    total_sku: number;
    total_item: number;
    created_at: number;
    bp_id: number;
}

export interface ListDetail {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    code2?: null;
    qty_order?: number;
    qty_return?: number;
    stock_action?: number;
    area_code?: string;
}

export interface Reference {
    ref_id?: number;
    ref_no?: string;
}

export interface DetailReturnOrder {
    id?: number;
    doc_no?: string;
    ref_no?: string;
    ref_id?: number;
    name?: string;
    address?: string;
    references?: Reference[];
    reason_id?: number;
    reason?: string;
    order_status?: number;
    list_detail?: ListDetail[];
}

export interface InternalReturnProduct {
    product_id: number;
    product_name: string;
    product_code1: string;
    product_code2: string;
    product_owner: string;
    type: number;
    stock_action?: any;
    qty?: any;
    qty_locator?: any;
    area_id?: any;
    area_name?: any;
}

export interface CreateInternalReturn {
    reason_id: number;
    return_order_detail: {
        product_id: number;
        qty_return: number;
        // stock_action: number;
        area_id: number;
    }[];
}

export interface InternalReturn {
    id: number;
    return_no: string;
    status: number;
    created_by: string;
    created_at: number;
}

export interface HeaderData {
    id: number;
    parent_name: string;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

class InternalReturnOrderService extends BaseService {
    getReason = "/consignment/get-reason";

    getProduct = "/consignment/get-product";

    create = "/consignment/create-return-order";

    getList = "/consignment/list-return-order";

    getDetailReturnOrder = "/consignment/detail-return-order";

    header = "/consignment/get-header-return";

    getLocator = "/consignment/get-locator-return";

    constructor() {
        super();
    }

    GetLocator<T extends LocatorData[]>(param: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Header<T extends HeaderData>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.header,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateInternalReturn) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetProduct<T extends InternalReturnProduct[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
            });
            return req;
        });
    }

    GetDetailReturnOrder<T extends DetailReturnOrder>(id?: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getDetailReturnOrder}/${id}`,
            });
            return req;
        });
    }

    GetReason<T extends GetReason[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getReason,
            });
            return req;
        });
    }

    GetList<T extends InternalReturn>(params: { page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getList,
                config: { params },
            });
            return req;
        });
    }
}

const internalReturnOrderService = new InternalReturnOrderService();
export default internalReturnOrderService;

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { CategoryCuti } from "services/api-endpoints/hadir-app/hadir-app-service";
import { STATUS_ACTIVE_CUTI } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const MasterCategoryCutiTable = <T extends CategoryCuti[]>({ fetcher }: Props<T>) => {
    const columns: ColumnsType<CategoryCuti> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (val: number) => STATUS_ACTIVE_CUTI.find((el) => el.value === val)?.label,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default MasterCategoryCutiTable;

/* eslint-disable no-shadow */
import { Alert, Card, DatePicker, Select, Skeleton, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useState } from "react";
import { useQuery } from "react-query";
import State from "components/common/state";
import { useSearchParams } from "react-router-dom";
import quantityService, { History } from "services/api-endpoints/dashboard/master-data/quantity";
import { SelectOption } from "models";
import HistoryTable from "modules/master-data/quantity/view/history-table";
import ModalHistoryQty from "modules/master-data/quantity/view/history-modal-qty";
import KartuStokTable from "modules/master-data/kartu-stok/table-view";
import kartuStokService from "services/api-endpoints/dashboard/master-data/kartu-stock";
import { FORMAT_DATE_1 } from "utils/constant";

const KartuStokView = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const areaId = searchParams.get("area_id");
    const warehouseId = searchParams.get("warehouse_id");
    const [historyShowed, setHistoryShowed] = useState<History | null>(null);
    const [totalQty, setTotalQty] = useState<number>(0);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);

    const detailQuery = useQuery(
        [kartuStokService.detailStock, id, startDate, endDate],
        async () => {
            return (await kartuStokService.DetailStock({ product_id: id, warehouse_id: warehouseId, start_date: startDate, end_date: endDate })).data
                .data;
        },
        {
            enabled: !!id,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail kartu stok"
                rightAddition={() => (
                    <DatePicker.RangePicker
                        className="w-full"
                        placeholder={["Start Date", "End Date"]}
                        onChange={(val) => {
                            setStartDate(val?.[0]?.format(FORMAT_DATE_1));
                            setEndDate(val?.[1]?.format(FORMAT_DATE_1));
                        }}
                    />
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        product name <br />
                                        <span className="underline italic m-0">{detailQuery.data?.product_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        product code <br />
                                        <span className="underline italic m-0">{detailQuery.data?.product_code}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Total Qty <br />
                                        <span className="underline italic m-0">{detailQuery.data?.qty}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailQuery.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <KartuStokTable loading={detailQuery.isLoading} data={detailQuery.data?.list_stock} />
        </div>
    );
};

export default KartuStokView;

import React, { useContext } from "react";
import { CUSTOMER_TYPE, PRODUCT_TYPE } from "utils/constant";
import { VoucherContext } from "../context/voucher";

const Condition = () => {
    const { state } = useContext(VoucherContext);

    return (
        <div className="w-full grid grid-cols-2 gap-5">
            <p className="TEXT-1 mt-4">
                product type <br />
                <span className="TEXT-2">{PRODUCT_TYPE.find((el) => el.value === state?.createData.product_type)?.label}</span>
            </p>
            <p className="TEXT-1 mt-4">
                max coupon <br />
                <span className="TEXT-2">{state?.createData?.max_coupon}</span>
            </p>
        </div>
    );
};

export default Condition;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface WorkingOrderM {
    id: number;
    wo_no: string;
    pom_id: number;
    po_no: string;
    resource_id: number;
    created_at: number;
    status: number;
}

export interface PurchasingOrderM {
    id: number;
    po_no: string;
    total_qty: number;
    total_product: string;
    created_at: number;
}

export interface Machine {
    id: number;
    machine_no: string;
    machine_tonnage: number;
}

export interface Operator {
    operator_id: number;
    operator_name: string;
}

export interface ProductPOM {
    product_id: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    molding_code?: string;
    qty?: number;
    qty_wo?: number;
    qty_sisa?: number;
}

export interface ChildWOM {
    product_id: number;
    qty: number;
}

export interface ChildBoxWOM {
    operator_id: number;
    machine_id: number;
    box_no: string;
    detail: {
        product_id: number;
        qty: number;
    };
}

export interface CreateWOM {
    note?: string | null;
    pom_id: number;
    resource_id?: number;
    child: ChildWOM[];
}

export interface CreateBox {
    note?: string | null;
    id: number;
    child: ChildBoxWOM[];
}

export interface ListProduct {
    product_id: number;
    product_code1: string;
    product_name: string;
    qty: number;
    detail_id?: number;
}

export interface ListBox {
    box_no: string;
    operator_id: number;
    operator_name: string;
    machine_id: number;
    machine_no: string;
    is_regis?: number;
    detail: ListProduct[];
}

export interface DetailWOM {
    id: number;
    wo_no: string;
    pom_id: number;
    po_no: string;
    resource_id: number;
    created_at: number;
    note: string;
    status: number;
    list_product: ListProduct[];
    list_box: ListBox[];
}

class WorkingOrderMService extends BaseService {
    getList = "/mf-wo/get-list";

    getMachine = "/mf-wo/get-machine";

    getOperator = "/mf-wo/get-operator";

    getPom = "/mf-wo/get-pom";

    getProductPom = "/mf-wo/get-product-pom";

    create = "/mf-wo/create";

    detail = "/mf-wo/get-detail";

    addBox = "/mf-wo/add-box";

    constructor() {
        super();
    }

    AddBox<T extends ProductPOM>(data: CreateBox) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.addBox,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailWOM>(params: { id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends ProductPOM>(data: CreateWOM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetProductPom<T extends ProductPOM[]>(params: { id?: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductPom,
                config: { params },
            });
            return req;
        });
    }

    GetPom<T extends PurchasingOrderM>(params: { page?: number }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getPom,
                config: { params },
            });
            return req;
        });
    }

    GetOperator<T extends Operator[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getOperator,
            });
            return req;
        });
    }

    GetMachine<T extends Machine[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getMachine,
            });
            return req;
        });
    }

    GetList<T extends WorkingOrderM>(params: { page?: number; start_date?: string; end_date?: string }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getList,
                config: { params },
            });
            return req;
        });
    }
}

const workingOrderMService = new WorkingOrderMService();
export default workingOrderMService;

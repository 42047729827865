/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelService, { ModelType } from "services/api-endpoints/dashboard/master-data/model";
import { CreateDataModel } from "../models";

type SelectOptionCustom = SelectOption & ModelType;

export interface Props extends ControlledInputSelectProps<CreateDataModel> {
    onSelectedData?: (data: ModelType[]) => void;
}

export function withModelType<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([modelService.listModelType], async () => {
            const req = await modelService.ListModelType();
            return req.data.data?.map(
                (el) => ({ ...el, label: `${el?.model_type_name}` || "", value: el.model_type_id || "" } as SelectOptionCustom)
            );
        });

        const onSelectChange = () => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data || []);
            }
        };

        return (
            <Component
                {...props}
                showSearch
                filterOption={(input, option) => {
                    return (option?.children ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                }}
                onSelect={onSelectChange}
                onDeselect={onSelectChange}
                optionFilterProp="children"
                loading={query.isLoading}
                options={query.data || []}
            />
        );
    };
}

const ModelTypeSelect = withModelType(ControlledSelectInput);

export default ModelTypeSelect;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import BPInvoiceView from "pages/bp-invoice/view";
import OrderView from "pages/order/view";
import PaymentView from "pages/payment/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import TransferBankView from "pages/transfer-bank/view";
import {
    ORDER_PAYMENT_EMPLOYESS,
    TYPE_JURNAL_BP_SALES_NO,
    TYPE_JURNAL_PEMBELIAN_NO,
    TYPE_JURNAL_PENYESUAIAN_NO,
    TYPE_JURNAL_UMUM_BANK_TRANSFER,
    TYPE_SALES_AUTOLOKA,
    TYPE_SALES_OFFLINE,
    TYPE_SALES_ONLINE,
} from "utils/constant";

export type Props = ModalProps & {
    id?: any;
    type?: any;
};

const ModalRefDoc = ({ id, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    {type === ORDER_PAYMENT_EMPLOYESS ? <PaymentView paramId={id} /> : null}
                    {type === TYPE_SALES_OFFLINE ||
                    type === TYPE_SALES_ONLINE ||
                    type === TYPE_SALES_AUTOLOKA ||
                    type === TYPE_JURNAL_PENYESUAIAN_NO ? (
                        <OrderView asPreview paramId={id} />
                    ) : null}
                    {type === TYPE_JURNAL_PEMBELIAN_NO ? <PurchasingInventoryView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_BP_SALES_NO ? <BPInvoiceView paramId={id} /> : null}
                    {type === TYPE_JURNAL_UMUM_BANK_TRANSFER ? <TransferBankView paramId={id} /> : null}
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalRefDoc;

import { Select, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UseMutationResult, UseQueryResult } from "react-query";
import { DetailGetRoleMenu } from "services/api-endpoints/dashboard-all/user-management/user";
import { STATUS_ACTIVE } from "utils/constant";

type Props<T> = {
    fetcher: UseMutationResult<T, unknown, any[], unknown>;
};

const TableAdd = <T extends DetailGetRoleMenu[]>({ fetcher }: Props<T>) => {
    const columns: ColumnsType<DetailGetRoleMenu> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "App Name",
            dataIndex: "apps_name",
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "List Menu",
            dataIndex: "list_menu",
            render: (text) => {
                const menu = text?.split(",");
                return (
                    <div>
                        {menu?.map((val: any) => (
                            <Tag color="blue">{val}</Tag>
                        ))}
                    </div>
                );
            },
        },
        // {
        //     width: "150px",
        //     title: "Is Action",
        //     key: "is_active",
        //     render: (_, record) => <Select options={STATUS_ACTIVE} placeholder="Is Active" />,
        // },
    ];

    return (
        <Table
            rowKey={(record) => record.role_menu_id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default TableAdd;

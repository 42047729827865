import React, { useContext, useState } from "react";
import { Button, Card, message, Select, Space } from "antd";
import { CUSTOMER_TYPE, CUSTOMER_SPECIFIC_USER, CUSTOMER_BY_REGION, CUSTOMER_ALL_USER } from "utils/constant";
import { City, User } from "services/api-endpoints/dashboard/master-data/voucher";
import UserPicker from "../lib/user-picker";
import RegionPicker from "../lib/region-picker";
import { VoucherContext } from "../context/voucher";

const DataCustomer = () => {
    const { nextStep, prevStep, setState, state } = useContext(VoucherContext);

    const [customerType, setCustomerType] = useState(state?.createData?.customer_type || CUSTOMER_ALL_USER);

    const [selectedCity, setSelectedCity] = useState<City[]>(state?.createData?.array_region || []);
    const [selectedUser, setSelectedUser] = useState<User[]>(
        state?.createData?.array_user?.map((el) => ({ ...el, full_name: el.name } as User)) || []
    );

    const onNextHandler = () => {
        if (!setState) return;
        if (customerType === CUSTOMER_SPECIFIC_USER) {
            if (!selectedUser.length) {
                message.error("List of user cannot be empty");
                return;
            }
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    customer_type: customerType,
                    array_user: selectedUser,
                    array_region: [],
                },
            }));
        }
        if (customerType === CUSTOMER_BY_REGION) {
            if (!selectedCity.length) {
                message.error("List of region cannot be empty");
                return;
            }
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    customer_type: customerType,
                    array_region: selectedCity,
                    array_user: [],
                },
            }));
        }
        if (customerType === CUSTOMER_ALL_USER) {
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    customer_type: customerType,
                    array_region: [],
                    array_user: [],
                },
            }));
        }

        if (nextStep) nextStep();
    };

    const onPrevHandler = () => {
        if (prevStep) prevStep();
    };

    const onChangeCustomerType = (key: number) => setCustomerType(key);

    return (
        <>
            <div className="flex flex-col gap-6">
                <Select value={customerType} onChange={onChangeCustomerType} options={CUSTOMER_TYPE} placeholder="Customer type" className="w-1/2" />
                {customerType === CUSTOMER_SPECIFIC_USER ? <UserPicker selectedUser={selectedUser} setSelectedUser={setSelectedUser} /> : null}
                {customerType === CUSTOMER_BY_REGION ? <RegionPicker selectedCity={selectedCity} setSelectedCity={setSelectedCity} /> : null}
            </div>
            <Space>
                <Button onClick={onPrevHandler}>Previous</Button>
                <Button type="primary" onClick={onNextHandler} className="!w-fit">
                    Next
                </Button>
            </Space>
        </>
    );
};

export default DataCustomer;

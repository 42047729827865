/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import ModalProductManufacturingOrder from "modules/manufacturing-order/modal-product";
import { BOMWithLocatorAndNote } from "modules/manufacturing-order/table";
import ModalProductByDoc from "modules/purchasing-order-m/modal-product-by-doc";
import TablePOMAdd from "modules/purchasing-order-m/table-pom-add";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdSearch } from "react-icons/md";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { MultipleChildBOM } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import purchasingOrderMService, { CreatePOM, ProductChild } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreatePOM, "notes">> = yup.object().shape({
    notes: yup.string().required("Note Required"),
});

export type ProductWithBOM = Product & {
    child?: BOMWithLocatorAndNote[];
    no?: number;
};

const PurchasingOrderMAdd = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductWithBOM[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreatePOM>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation([purchasingOrderMService.create], async (data: CreatePOM) => {
        return (await purchasingOrderMService.Create(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        const notSelectedWarn = document.querySelectorAll(".warning-not-selected");

        if (notSelectedWarn.length) {
            message.error(`Please select child on ${notSelectedWarn.length} row items`);
            notSelectedWarn.forEach((element) => element.parentElement?.classList.add("bg-red-300"));
            return;
        }

        const parse = {
            note: data.notes,
            detail: products.map((p) => {
                return {
                    note: p.product_code1,
                    child: p.child?.map((c) => {
                        if ("child" in c && (c as MultipleChildBOM).child.length) {
                            const selected = (c as MultipleChildBOM).child.find((m) => m.selected);
                            return {
                                product_id: selected?.product_id,
                                qty: selected?.qty_bom,
                            };
                        }
                        return {
                            product_id: c.product_id,
                            qty: c.qty_bom,
                        } as ProductChild;
                    }),
                };
            }),
        } as CreatePOM;

        createMutate.mutateAsync(parse).then(() => {
            navigate(-1);
            message.success("Success Create Data!");
        });
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new purchasing order m"
                rightAddition={() => (
                    <Button disabled={!products.length} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Create
                    </Button>
                )}
            />
            <Card>
                <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="w-full grid grid-cols-3 gap-x-4">
                        <p className="capitalize m-0">
                            Doc No <br />
                            <span className="m-0">{AUTOGENERATED}</span>
                        </p>
                        <div className="w-full">
                            Products <br />
                            <Space>
                                <ModalProductManufacturingOrder selected={products} setSelected={setProducts}>
                                    {(dt) => (
                                        <Button className="!flex !items-center w-full mt-2" type="primary" onClick={dt.openModal}>
                                            <MdSearch className="mr-2" />
                                            Search Product
                                        </Button>
                                    )}
                                </ModalProductManufacturingOrder>
                                <ModalProductByDoc setSelected={setProducts}>
                                    {(dt) => (
                                        <Button className="!flex !items-center w-full mt-2" type="primary" onClick={dt.openModal}>
                                            <MdSearch className="mr-2" />
                                            Search Product By Doc
                                        </Button>
                                    )}
                                </ModalProductByDoc>
                            </Space>
                        </div>
                        <ControlledInputText classNameForm="col-span-1" control={control} name="notes" placeholder="Notes" label="Notes" />
                    </div>
                </Form>
            </Card>
            <TablePOMAdd list={products} setProducts={setProducts} />
        </div>
    );
};

export default PurchasingOrderMAdd;

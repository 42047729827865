/* eslint-disable react/no-array-index-key */
import { mapDuplicateProducts } from "modules/master-data/consignment/delivery-order/utils";
import moment from "moment";
import React, { useContext } from "react";
import QRCode from "react-qr-code";
import { DetailDeliveryOrder, ProductDeliveryOrder } from "services/api-endpoints/dashboard/master-data/ext-delivery-order";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = {
    data: DetailDeliveryOrder | null;
};

const Print = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const limitPattern = [10, 20]; // [first, middle]

    const arr: ProductDeliveryOrder[][] = [];
    const listProductCopy = [...(mapDuplicateProducts(data?.list_detail || []) || [])];
    while (listProductCopy.length) {
        arr.push(listProductCopy.splice(0, arr.length === 0 ? limitPattern[0] : limitPattern[1]));
    }

    const listProduct = mapDuplicateProducts(data?.list_detail || []);

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            <div className="w-full bg-white p-4 h-full">
                <div className="w-full flex items-end justify-between">
                    <div className="max-w-[60%]">
                        <h1 className="text-2xl uppercase m-0">{data?.partner_name}</h1>
                        <p className="text-xs m-0">{data?.partner_address || ""}</p>
                    </div>
                    <h2 className="text-4xl font-medium m-0 capitalize">surat jalan</h2>
                </div>
                <div className="w-full h-[2px] bg-gray-400 mt-2" />
                <div className="w-full h-[2px] bg-gray-400 mt-1" />
                <div className="w-full grid grid-cols-3 mt-4">
                    <div className="">
                        <h2 className="m-0 capitalize text-2xl font-medium">kepada yth.</h2>
                        <p className="m-0 uppercase text-xs">{data?.bp_name}</p>
                        <p className="m-0 capitalize text-xs">{data?.bp_address || "-"}</p>
                    </div>
                    <div className="flex justify-center">
                        <QRCode size={70} value={data?.doc_no || ""} />
                    </div>
                    <div className="">
                        <table>
                            <tr className="text-xs">
                                <td className="min-w-[80px]">No</td>
                                <td>: </td>
                                <td>{`${data?.doc_no}`}</td>
                            </tr>
                            <tr className="text-xs">
                                <td className="min-w-[80px]">Tanggal</td>
                                <td>: </td>
                                <td>{`${moment().format("dddd DD MM yyyy")}`}</td>
                            </tr>
                            <tr className="text-xs">
                                <td className="min-w-[80px]">Plat Kendaraan</td>
                                <td>: </td>
                                <td>{`${data?.plate_number}`}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="flex flex-col mt-5 break-inside-auto">
                    <table>
                        <thead className="border border-solid border-gray-500">
                            <th className="border border-solid border-gray-500 px-1">No</th>
                            <th className="border border-solid border-gray-500">Kode</th>
                            <th className="border border-solid border-gray-500">Kode 2</th>
                            <th className="border border-solid border-gray-500">Nama Produk</th>
                            <th className="border border-solid border-gray-500 px-1">Qty</th>
                            <th className="border border-solid border-gray-500 px-1">No Koli</th>
                        </thead>
                        <tbody className="border border-solid border-gray-500">
                            {listProduct?.map((el, i) => (
                                <tr key={i} className="border border-solid border-gray-500">
                                    <td className="border border-solid border-gray-500 px-1">{i + 1}</td>
                                    <td className="border border-solid border-gray-500 px-1">{el.product_code}</td>
                                    <td className="border border-solid border-gray-500 px-1">{el.code2 || "-"}</td>
                                    <td className="border border-solid border-gray-500 px-1">{`${el.product_name}`}</td>
                                    <td className="border border-solid border-gray-500 px-1">{el.qty}</td>
                                    <td className="border border-solid border-gray-500 px-1">{el.no_koli}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-start justify-between mt-10">
                    {["Penerima", "Pengemudi (Driver)", "Petugas Gudang"].map((el, i) => (
                        <div className="flex flex-col items-center" key={i}>
                            <p className="capitalize font-medium text-xs">{el}</p>
                            <p className="font-bold text-xs m-0 mt-10">
                                {el === "Pengemudi (Driver)" ? `( ${data.driver_name} )` : "( ................ )"}
                            </p>
                        </div>
                    ))}
                    <div className="">
                        <table className="text-xs">
                            <tr>
                                <td className="min-w-[100px]">Catatan</td>
                                <td className="max-w-[200px]">: {data?.note}</td>
                            </tr>
                            <tr>
                                <td className="min-w-[100px]">Print</td>
                                <td>
                                    : - <br />
                                    {moment(moment.now()).format(FORMAT_DATE_TIME)}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Print;

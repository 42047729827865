/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Space, Table } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { ColumnsType } from "antd/lib/table";
import ToolbarAction from "components/toolbar/action";
import downloadStock from "modules/master-data/stock/download";
import ModalDetailQuantityStock from "modules/master-data/stock/modal-detail";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import quantityService, { ListStockResponse } from "services/api-endpoints/dashboard/master-data/quantity";
import { VIEW_PATH } from "utils/constant";
import { v4 as uuid } from "uuid";

const StockProductView = () => {
    const pageSize = 50;
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const warehouse = searchParams.get("warehouse_id");
    const includeLocator = searchParams.get("include_locator");
    const excludeLocators = searchParams.get("exclude_locators");
    const products = searchParams.get("products");
    const [page, setPage] = React.useState(1);

    const listQuery = useQuery([quantityService.listStockProduct, warehouse, includeLocator, excludeLocators, products], async () => {
        return (
            await quantityService.ListStockProduct({
                warehouse_id: warehouse,
                in_area_id: includeLocator
                    ?.split(",")
                    .map((id) => (id !== "-1" ? id : 1))
                    .join(","),
                ex_area_id: excludeLocators
                    ?.split(",")
                    .map((id) => (id !== "-1" ? id : 1))
                    .join(","),
                product_id: products,
            })
        ).data.data.map((el) => ({ ...el, id: uuid() }));
    });

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const pg = pagination.current || 1;
        setPage(pg);
    };

    const columns: ColumnsType<ListStockResponse> = [
        {
            width: "50px",
            title: "No",
            fixed: "left",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * pageSize + (i + 1)}</p>,
        },
        {
            title: "Product Code",
            fixed: "left",
            width: "200px",
            dataIndex: "code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            width: "200px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "name",
            width: "300px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_name",
            width: "75px",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Locator",
            render: (_, record) => {
                return (
                    <div className="flex items-center gap-3">
                        {record.list_stock?.map((stock) => (
                            <ModalDetailQuantityStock
                                title={`Detail ${stock.area_code}`}
                                areaId={stock.area_id}
                                key={stock.area_id}
                                productId={record.product_id}
                                warehouseId={warehouse}
                            >
                                {(ctrl) => (
                                    <button
                                        onClick={ctrl.openModal}
                                        title="click to detail"
                                        type="button"
                                        className="flex whitespace-nowrap border-none cursor-pointer items-center gap-2 bg-blue-400 px-4 py-2 text-xs rounded text-white font-medium"
                                    >
                                        <span>{stock.area_code}</span>:<span>{stock.qty}</span>
                                    </button>
                                )}
                            </ModalDetailQuantityStock>
                        ))}
                    </div>
                );
            },
        },
    ];

    const resetViewClick = () => {
        navigate(location.pathname.replace(VIEW_PATH, ""), { replace: true });
    };

    const downloadClick = () => {
        if (listQuery.isLoading) return;
        downloadStock({ list: listQuery.data!, title: "Warehouse stock product" });
    };

    return (
        <div className="flex flex-col gap-6 w-full">
            <ToolbarAction
                title="View stock product"
                rightAddition={() => (
                    <Space>
                        <Button onClick={resetViewClick} type="text">
                            Reset View
                        </Button>
                        <Button onClick={downloadClick} type="primary">
                            <AiOutlineDownload className="m-0 mr-2" />
                            Download
                        </Button>
                    </Space>
                )}
            />
            <Table
                scroll={{ x: 1500 }}
                dataSource={listQuery.data}
                loading={listQuery.isLoading}
                rowKey={(p) => p.id!}
                size="small"
                columns={columns}
                className="w-full"
                pagination={{
                    current: page,
                    pageSize,
                    total: listQuery.data?.length,
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default StockProductView;

/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, message, Modal, Select, Upload, UploadFile, UploadProps } from "antd";
import ToolbarAction from "components/toolbar/action";
import useConfirmDelete from "hooks/useConfirmDelete";
import { SelectOption } from "models";
import ModalPurchasingTable from "modules/dashboard-jsl/penerimaan-barang/modal-puchasing-table";
import PenerimaanTableAdd from "modules/dashboard-jsl/penerimaan-barang/table-add";
import React, { useState } from "react";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import penerimaanBarangService, {
    ChildData,
    CreateChild,
    CreatePenerimaan,
    DetailPOData,
} from "services/api-endpoints/dashboard/master-data/penerimaan-barang";
import Utils from "utils";
import { AUTOGENERATED } from "utils/constant";

const PenerimaanBarangAdd = () => {
    const navigate = useNavigate();
    const [dataPO, setDataPO] = useState<DetailPOData | null>(null);
    const [childPO, setChildPO] = useState<ChildData[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);
    const [vendor, setVendor] = useState<number | null>(null);

    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);

    const bpQuery = useQuery([penerimaanBarangService.getBp], async () => {
        return (await penerimaanBarangService.GetBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const createMutation = useMutation(
        [penerimaanBarangService.create],
        async (data: CreatePenerimaan) => {
            return (await penerimaanBarangService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                navigate(-1);
                message.success("GR Supplies Created");
            },
        }
    );

    const prepareData = async () => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));
        const createData: CreatePenerimaan = {
            doc_id: dataPO?.id!,
            vendor_id: vendor!,
            child: [
                ...(childPO?.map(
                    (val) =>
                        ({
                            supplies_id: val.product_id,
                            qty_order: val.qty,
                            qty_receipt: val.qty_receive,
                        } as CreateChild)
                ) || []),
            ],
            do_image: filesBase64 || [],
        };
        return createData;
    };

    const onChangeVendor = (value: number) => {
        setDataPO(null);
        setChildPO([]);
        setVendor(value);
    };

    const onProductPO = (data: DetailPOData) => {
        setDataPO(data);
        setChildPO(data.child.map((el) => ({ ...el, po_no: data.po_no, qty_receive: el.qty } as ChildData)));
    };

    const [showConfirm] = useConfirmDelete<any>({
        title: "GR Supplies",
        content: "`Anda yakin membuat GR Supplies?",
        onOk: async (data, callback) => {
            if (fileList.length === 0) {
                message.error("Upload Bukti Penerimaan Wajib.!");
                return;
            }
            const createPLData = prepareData();
            createMutation
                .mutateAsync(await createPLData)
                .then(callback)
                .catch(callback)
                .finally(message.success("Waiting On Proccess...."));
        },
    });

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const handleCancel = () => setPreviewOpen(false);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new GR Supplies"
                rightAddition={() => (
                    <Button
                        onClick={showConfirm}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={!dataPO}
                        loading={createMutation.isLoading}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-2 gap-4">
                    <p className="capitalize m-0">
                        Doc No <br />
                        <span className="underline italic m-0">{AUTOGENERATED}</span>
                    </p>
                    <div className="">
                        <div className="capitalize m-0">
                            Vendor From <br />
                            <Select
                                value={vendor}
                                className="!w-[300px] mt-3"
                                loading={bpQuery.isLoading}
                                options={bpQuery.data || []}
                                onChange={onChangeVendor}
                                showSearch
                                placeholder="Vendor From"
                                optionFilterProp="label"
                            />
                        </div>
                    </div>
                </div>
                <p className="capitalize mt-4">
                    Upload Bukti Penerimaan <br />
                    <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5 mt-3">
                        <div>
                            <BiImageAdd className="text-2xl" />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    </Upload>
                </p>
            </Card>
            <div className="flex items-start">
                <ModalPurchasingTable onProductPO={onProductPO} warehouse={vendor} selectedPO={selectedPO} setSelectedPO={setSelectedPO}>
                    {(dt) => (
                        <Button className="!flex !items-center w-fit mr-3" type="primary" onClick={dt.openModal} disabled={!(vendor !== null)}>
                            <MdAdd className="mr-2" />
                            Data Purchasing Order
                        </Button>
                    )}
                </ModalPurchasingTable>
            </div>
            <PenerimaanTableAdd list={childPO} setList={setChildPO} canRemove={false} />
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default PenerimaanBarangAdd;

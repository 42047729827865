/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React from "react";
import { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function addTable<T extends ProductPOM>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<ProductPOM | null>(null);

        const columns: ColumnType<ProductPOM>[] = [
            {
                title: "No",
                dataIndex: "no",
                render: (t, r, i) => i + 1,
            },
            {
                title: "Product Code",
                dataIndex: "product_code1",
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                ...{ editable: true },
            },
        ];

        const isEditing = (record: ProductPOM, edited: ProductPOM | null) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const findIndexSave = (record: ProductPOM, edited: ProductPOM) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const rowKey = (record: ProductPOM) => `${record.product_id}_${record?.product_id}`;

        const editInputType: EditTableProps<ProductPOM>["editInputType"] = { qty_sisa: "number" };

        return (
            <Component
                {...props}
                bordered
                cellProps={{
                    minNumber: 1,
                    maxNumber: editRow?.qty_sisa,
                }}
                list={list as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const TableProductsView = addTable(EditTable);
export default TableProductsView;

import { Input, Button, DatePicker, Select, message } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment from "moment";
import React, { useRef, useState } from "react";
import { FORMAT_DATE_1, TYPE_SALES } from "utils/constant";

type Props = ModalProps & {
    onSubmit: (type: number | null, startDate: string, endDate: string) => void;
};

const ModalDownload = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [type, setType] = useState<number | null>(null);
    const [startDate, setStartDate] = useState<any | null>(null);
    const [endDate, setEndDate] = useState<any | null>(null);

    const onSubmitHandler = () => {
        if (type === null) {
            message.error("Please Choose Order Type");
            return;
        }
        if (startDate === null || endDate === null) {
            message.error("Please Choose Date");
            return;
        }
        onSubmit(type, startDate, endDate);
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <div className="flex gap-5 items-center w-full">
                        <Select value={type} placeholder="Order Type" options={TYPE_SALES} onChange={(value) => setType(value)} />
                        <DatePicker.RangePicker
                            value={[startDate ? moment(startDate, FORMAT_DATE_1) : null, endDate ? moment(endDate, FORMAT_DATE_1) : null]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                setStartDate(val?.[0]?.format(FORMAT_DATE_1));
                                setEndDate(val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Button type="primary" className="items-center" onClick={onSubmitHandler}>
                            Download
                        </Button>
                    </div>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalDownload;

import { Table, TableProps, InputNumber, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import { SelectOptionCustom } from "modules/master-data/price/lib/warehouse-select";
import React from "react";
import { UseQueryResult } from "react-query";
import { ProductTransfer } from "services/api-endpoints/dashboard/master-data/material-movement";

type Props<T> = TableProps<T> & {
    onEditChange?: (record: T) => void;
    locatorFetcher: UseQueryResult<SelectOptionCustom[], unknown>;
};

const TableTransferEdit = <T extends ProductTransfer>({ onEditChange, locatorFetcher, ...props }: Props<T>) => {
    const onChangeQty = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                qty_to: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const onChangeLocator = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                locator_to: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const columns: ColumnType<T>[] = [
        {
            dataIndex: "product_code",
            title: "Code",
            render: (text) => text,
        },
        {
            dataIndex: "product_name",
            title: "Name",
            render: (text) => text,
        },
        {
            title: "Locator From",
            dataIndex: "area_name",
            render: (text) => text,
        },
        {
            title: "Qty Transfer",
            width: "20px",
            dataIndex: "qty_to",
            render: (qtyTo, record) => (
                <InputNumber value={qtyTo} onChange={onChangeQty(record)} min={1} max={record?.qty_from || 1} className="!w-[80px]" />
            ),
        },
        {
            dataIndex: "suggestion_locator",
            title: "Suggestion Locator",
            render: (text) => text || "-",
        },
        {
            title: "Locator To",
            width: "120px",
            dataIndex: "locator_to",
            render: (_, record) => (
                <Select
                    value={record?.locator_to}
                    showSearch
                    placeholder="Locator To"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                    className="!w-[150px]"
                    loading={locatorFetcher.isLoading}
                    options={locatorFetcher.data || []}
                    onChange={onChangeLocator(record)}
                />
            ),
        },
    ];

    return <Table size="small" columns={columns} {...props} />;
};

export default TableTransferEdit;

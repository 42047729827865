/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ProductPO } from "services/api-endpoints/dashboard-jsl/sales-order";
import { ColumnType } from "antd/lib/table";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function viewProductTable<T extends ProductPO>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
            },
            {
                title: "Quantity Request",
                dataIndex: "qty_req",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Approved",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id && record.po_no === edited?.po_no;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited?.product_id && record.po_no === edited?.po_no;
        const rowKey = (record: T) => `${record?.product_id}-${record.po_no}`;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                action={false}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
            />
        );
    };
}

const ProductTable = viewProductTable(EditTable);
export default ProductTable;

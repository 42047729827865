/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export const STATUS_PERJALANAN_DINAS = [
    {
        value: 1,
        label: "New",
    },
    {
        value: 2,
        label: "Approved",
    },
    {
        value: 3,
        label: "On Going",
    },
    {
        value: 4,
        label: "Done",
    },
    {
        value: -1,
        label: "Rejected",
    },
];

export interface PerjalananDinasList {
    id?: number;
    category_id?: number;
    category_name?: string;
    description?: string;
    facilities?: string;
    start_date?: string;
    end_date?: string;
    created_at?: string;
    status?: string;
    user_name?: string;
    city_id?: number;
    city_name?: string;
}

export interface LemburList {
    id?: number;
    overtime_date?: string;
    start_time?: string;
    end_time?: string;
    status?: string;
    description?: string;
    user_name?: string;
}

export interface CutiList {
    id?: number;
    category_id?: number;
    category_name?: string;
    description?: string;
    created_at?: string;
    paid_date?: string[];
    user_name?: string;
    status?: string;
}

export interface CreateHolidayData {
    start_date: string;
    end_date: string;
    libur_wajib: string[];
    libur_nasional: string[];
}

export interface HolidayList extends CreateHolidayData {
    id: number;
}

export interface CategoryDutyTrip {
    id: number;
    name: string;
    is_active: number;
    type: number;
    type_name: string;
}

export interface DutyTripHistory {
    id?: number;
    description?: string;
    created_at?: string;
    images?: string[];
    lat?: number;
    lon?: number;
}

export interface DetailDutyTrip {
    id?: number;
    category_id?: number;
    category_name?: string;
    description?: string;
    facilities?: string;
    start_date?: string;
    end_date?: string;
    created_at?: string;
    status?: string;
    user_name?: string;
    city_id?: number;
    city_name?: string;
    list_history?: DutyTripHistory[];
}

export interface CreateCategoryDutyTrip {
    name: string;
    is_active: number;
    type: number;
}

export interface SetApprovalDutyTrip {
    id: any;
    status: number;
}

export interface CategoryCuti {
    id: number;
    name: string;
    is_active: number;
}

export interface DetailCuti {
    id: number;
    category_id: number;
    category_name: string;
    description: string;
    address: string;
    created_at: string;
    status: string;
    user_name: string;
    paid_date: string[];
}

export interface CreateCategoryCuti {
    name: string;
    is_active: number;
}

export interface DetailLembur {
    id: number;
    overtime_date: string;
    start_time: string;
    end_time: string;
    status: string;
    description: string;
}

export interface SetApproval {
    id: number;
    status: number;
}

class HadirAppService extends BaseService {
    getListCuti = "/hrm/list-cuti";

    getListLembur = "/hrm/list-lembur";

    detailLembur = "/hrm/detail-lembur";

    createHoliday = "/hrm/create-holiday";

    getListHoliday = "/hrm/list-holiday";

    detailHoliday = "/hrm/detail-holiday";

    editHoliday = "/hrm/edit-holiday";

    getListDutyTrip = "/hrm/list-perjalanan-dinas";

    getListCategoryDutyTrip = "/hrm/list-category-perjalanan-dinas";

    detailDutyTrip = "/hrm/detail-perjalanan-dinas";

    createCategoryDutyTrip = "/hrm/create-category-perjalanan-dinas";

    getListCategoryCuti = "/hrm/list-category-cuti";

    detailCuti = "/hrm/detail-cuti";

    createCategoryCuti = "/hrm/create-category-cuti";

    setApprovalCuti = "/hrm/set-approval-cuti";

    setApprovalDutyTrip = "/hrm/set-approval-perjalanan-dinas";

    setApprovalLembur = "/hrm/set-approval-lembur";

    constructor() {
        super();
    }

    SetApprovalLembur<T extends {}>(data: SetApproval) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setApprovalLembur,
                data,
            });
            return req;
        });
    }

    SetApprovalDutyTrip<T extends {}>(data: SetApproval) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setApprovalDutyTrip,
                data,
            });
            return req;
        });
    }

    SetApprovalCuti<T extends {}>(data: SetApproval) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setApprovalCuti,
                data,
            });
            return req;
        });
    }

    DetailLembur<T extends DetailLembur>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailLembur}/${id}`,
            });
            return req;
        });
    }

    CreateCategoryCuti<T extends {}>(data: CreateCategoryCuti) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createCategoryCuti,
                data,
            });
            return req;
        });
    }

    DetailCuti<T extends DetailCuti>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailCuti}/${id}`,
            });
            return req;
        });
    }

    GetListCategoryCuti<T extends CategoryCuti[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getListCategoryCuti,
            });
            return req;
        });
    }

    CreateCategoryDutyTrip<T extends {}>(data: CreateCategoryDutyTrip) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createCategoryDutyTrip,
                data,
            });
            return req;
        });
    }

    DetailDutyTrip<T extends DetailDutyTrip>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailDutyTrip}/${id}`,
            });
            return req;
        });
    }

    GetListCategoryDutyTrip<T extends CategoryDutyTrip[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getListCategoryDutyTrip,
            });
            return req;
        });
    }

    EditHoliday<T extends {}>(data: HolidayList) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.editHoliday,
                data,
            });
            return req;
        });
    }

    DetailHoliday<T extends HolidayList>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailHoliday}/${params.id}`,
                config: { params },
            });
            return req;
        });
    }

    GetListHoliday<T extends HolidayList>(params: { page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getListHoliday,
                config: { params },
            });
            return req;
        });
    }

    CreateHoliday<T extends {}>(data: CreateHolidayData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createHoliday,
                data,
            });
            return req;
        });
    }

    GetListLembur<T extends LemburList>(params: { page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getListLembur,
                config: { params },
            });
            return req;
        });
    }

    GetListCuti<T extends CutiList>(params: { page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getListCuti,
                config: { params },
            });
            return req;
        });
    }

    GetListDutyTrip<T extends PerjalananDinasList>(params: { page?: any; status?: number }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getListDutyTrip,
                config: { params },
            });
            return req;
        });
    }
}

const hadirAppService = new HadirAppService();
export default hadirAppService;

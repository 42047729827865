import { useContext } from "react";
import { BOOLEAN_TYPE, IMPACT_CATEGORY, IMPACT_FREE_PRODUCT, IMPACT_NAME, IMPACT_PERCENT } from "utils/constant";
import { VoucherContext } from "../context/voucher";
import ProductFreeTable from "../lib/product-free-table";

const Action = () => {
    const { state } = useContext(VoucherContext);

    return (
        <div className="w-full grid grid-cols-2 gap-5">
            <p className="TEXT-1 mt-4">
                impact name <br />
                <span className="TEXT-2">{IMPACT_NAME.find((el) => el.value === state?.createData.impact_name)?.label}</span>
            </p>
            <p className="TEXT-1 mt-4">
                is multiple <br />
                <span className="TEXT-2">{BOOLEAN_TYPE.find((el) => el.value === state?.createData.is_multiple)?.label}</span>
            </p>

            {state?.createData.impact_name !== IMPACT_FREE_PRODUCT && (
                <>
                    <p className="TEXT-1 mt-4">
                        impact category <br />
                        <span className="TEXT-2">{IMPACT_CATEGORY.find((el) => el.value === state?.createData.impact_category)?.label}</span>
                    </p>
                    <p className="TEXT-1 mt-4">
                        amount <br />
                        <span className="TEXT-2">
                            {state?.createData.impact_category === IMPACT_PERCENT
                                ? `${state?.createData.amount}%`
                                : Number(state?.createData.amount)?.ToIndCurrency("Rp")}
                        </span>
                    </p>
                    <p className="TEXT-1 mt-4">
                        min trx <br />
                        <span className="TEXT-2">{Number(state?.createData.min_trx)?.ToIndCurrency("Rp")}</span>
                    </p>
                    <p className="TEXT-1 mt-4">
                        max discount <br />
                        <span className="TEXT-2">{Number(state?.createData.max_discount)?.ToIndCurrency("Rp")}</span>
                    </p>
                </>
            )}

            {state?.createData.impact_name === IMPACT_FREE_PRODUCT && (
                <>
                    <div className="col-span-3 mt-2">
                        <ProductFreeTable list={state?.createData.free_product || []} action={false} />
                    </div>
                </>
            )}
        </div>
    );
};

export default Action;

/* eslint-disable react/no-array-index-key */
import moment from "moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { MaterialMovementDetail, ProductTransferWithEdit } from "services/api-endpoints/dashboard/master-data/material-movement";
import { FORMAT_DATE_TIME } from "utils/constant";
import { tProductTransformWithQty } from "../utils";

type Props = {
    data: MaterialMovementDetail | null;
    title: "Material Movement" | "Transfer Stock";
};

const Print = React.forwardRef<HTMLDivElement, Props>(({ data, title }, ref) => {
    const [currentTime, setCurrentTime] = useState();

    const productPerPage = 25;

    const listDetailGroup = React.useMemo(() => {
        if (!data?.list_product) return [];
        const listProductCopy = [...(tProductTransformWithQty(data!.list_product) || [])];
        const temp: ProductTransferWithEdit[][] = [];
        const totalPage = Math.ceil(listProductCopy.length / productPerPage);
        [...new Array(totalPage)].forEach(() => {
            temp.push(listProductCopy.splice(0, productPerPage));
        });
        return temp;
    }, [data]);

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            {listDetailGroup?.map((products, page) => (
                <div className="w-full bg-white p-5 break-after-page">
                    {page === 0 ? (
                        <>
                            <div className="w-full flex items-end justify-between">
                                <div className="max-w-[55%]">
                                    <h1 className="text-2xl uppercase m-0">{data.name || "-"}</h1>
                                    <p className="text-xs m-0">{data.address || "-"}</p>
                                </div>
                                <h2 className="text-3xl font-medium m-0 capitalize">{title}</h2>
                            </div>
                            <div className="w-full h-[2px] bg-gray-400 mt-2" />
                            <div className="w-full h-[2px] bg-gray-400 mt-1" />
                            <div className="w-full grid grid-cols-3 mt-4">
                                <div className="">
                                    <table>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">No</td>
                                            <td>{` : ${data?.mm_no}`}</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Options</td>
                                            <td> : Warehouse Stock</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Tanggal</td>
                                            <td> : {moment().format("dddd DD MM yyyy")}</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td>.</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Locator From</td>
                                            <td>: {data?.area_from || "-"}</td>
                                        </tr>
                                        {title === "Transfer Stock" && (
                                            <>
                                                <tr className="text-xs">
                                                    <td className="min-w-[60px]">Warehouse From</td>
                                                    <td>: {data?.warehouse_from || "-"}</td>
                                                </tr>
                                                <tr className="text-xs">
                                                    <td className="min-w-[60px]">Warehouse To</td>
                                                    <td>: {data?.warehouse_to || "-"}</td>
                                                </tr>
                                            </>
                                        )}
                                    </table>
                                </div>
                                <div className="flex justify-center">
                                    <QRCode size={70} value={(data?.mm_no as any) || ""} />
                                </div>
                            </div>
                        </>
                    ) : null}
                    <div className="flex flex-col mt-5">
                        <table>
                            <thead className="border border-solid border-gray-500">
                                <th className="border border-solid border-gray-500 px-2">No</th>
                                <th className="border border-solid border-gray-500">Kode</th>
                                <th className="border border-solid border-gray-500">Nama Produk</th>
                                <th className="border border-solid border-gray-500">Locator From</th>
                                <th className="border border-solid border-gray-500">Locator To</th>
                                <th className="border border-solid border-gray-500">Qty</th>
                                <th className="border border-solid border-gray-500">UOM</th>
                            </thead>
                            <tbody className="border border-solid border-gray-500">
                                {products?.map((el, i) => (
                                    <tr key={i} className="border border-solid border-gray-500">
                                        <td className="border border-solid border-gray-500 text-xs text-center px-1">
                                            {i + 1 + productPerPage * page}
                                        </td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.product_code}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1">{el.product_name}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.area_name || "-"}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.locator_to || "-"}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_to}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.uom_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p className="self-end mt-2">{`Halaman ${page + 1} dari ${listDetailGroup.length}`}</p>
                    </div>
                    {page === listDetailGroup.length - 1 ? (
                        <div className="flex items-start justify-between">
                            {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                                <div className="flex flex-col items-center" key={i}>
                                    <p className="capitalize font-medium text-xs">{el}</p>
                                    <p className="text-3xl m-0 mt-1">(...........)</p>
                                </div>
                            ))}
                            <div className="">
                                <table className="text-xs">
                                    <tr>
                                        <td className="min-w-[100px]">Catatan</td>
                                        <td className="max-w-[200px]">: {data.note || "-"}</td>
                                    </tr>
                                    <tr>
                                        <td className="min-w-[100px]">Print</td>
                                        <td>: {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
});

export default Print;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import ImageJsl from "assets/images/jsl.svg";
import React from "react";
import { DetailWOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { TableBoxType } from "../add/table-box";
import { RESOURCE_OR_PLANNING } from "utils/constant";
import QRCode from "react-qr-code";

type Props = {
    data?: DetailWOM | null;
    box?: TableBoxType | null;
};

const PrintDetailWOM = React.forwardRef<HTMLDivElement, Props>(({ data, box }, ref) => {
    if (!data) return <div className="" />;
    const arrayName = [...new Set(box?.detail.map((val) => val.product_name))];
    return (
        <div ref={ref} className="hidden print:block m-0">
            <div className="bg-white p-0 flex flex-col gap-4 m-0">
                <table border={1} className="w-full table-fixed">
                    <tr>
                        <td rowSpan={2} className="p-3">
                            <img src={ImageJsl} className="h-[50px]" alt="" />
                        </td>
                        <td colSpan={6} className="text-xl text-center font-semibold py-1">
                            IDENTITAS PRODUKSI INJECTION
                        </td>
                    </tr>
                    <tr>
                        <td />
                        <td colSpan={3} className="text-center font-semibold py-1">
                            {RESOURCE_OR_PLANNING.find((val) => val.value === data.resource_id)?.label}
                        </td>
                        <td colSpan={2} className="italic text-end">
                            FM-PI- 05 / Rev 02
                        </td>
                    </tr>
                </table>
                <table border={1} className="w-full table-fixed">
                    <tr>
                        <td colSpan={2} className="font-bold text-center">
                            {data.wo_no || "-"}
                        </td>
                        <td>No Urut WO</td>
                        <td className="font-semibold capitalize">{box?.no || 1}</td>
                        <td className="font-semibold capitalize text-center">{(box?.detail || []).length} Item</td>
                        <td colSpan={2} rowSpan={4}>
                            <div className="flex justify-center">
                                <QRCode size={70} value={`${data.wo_no || ""}_${box?.box_no || ""}`} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Type Product</td>
                        <td className="font-semibold capitalize" />
                        <td>Warna</td>
                        <td className="font-semibold capitalize" />
                        <td className="text-center">No Box</td>
                    </tr>
                    <tr>
                        <td>Tgl Produksi</td>
                        <td className="font-semibold capitalize" />
                        <td>Shift</td>
                        <td className="font-semibold capitalize" />
                        <td className="font-semibold capitalize text-center bg-gray-300">{box?.box_no || "-"}</td>
                    </tr>
                    <tr>
                        <td>No Mesin</td>
                        <td className="font-semibold capitalize">{box?.machine_no || "-"}</td>
                        <td>Operator</td>
                        <td className="font-semibold capitalize">{box?.operator_name || "-"}</td>
                        <td className="font-semibold capitalize text-center">-</td>
                    </tr>
                </table>
                <table border={1} className="w-full table-fixed">
                    <tr>
                        <td className="text-center" colSpan={2}>
                            Kode Barang
                        </td>
                        <td className="text-center" colSpan={3}>
                            Nama Barang
                        </td>
                        <td className="text-center" colSpan={1}>
                            Qty Inj
                        </td>
                        <td className="text-center" colSpan={1}>
                            Qty OK Sisip
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2 m-0" colSpan={2}>
                            {box?.detail.map((val) => val.product_code1).join(", ")}
                        </td>
                        <td className="py-2 m-0" colSpan={3}>
                            {arrayName.map((val) => (
                                <p className="m-0">{val}</p>
                            ))}
                        </td>
                        <td className="py-2 text-xl text-center" colSpan={1}>
                            {box?.detail.reduce((total, item) => total + (item.qty || 0), 0)} SET
                        </td>
                        <td className="py-2 text-3xl" colSpan={1} />
                    </tr>
                </table>
                <table border={1} className="w-full table-fixed">
                    <tr>
                        <td className="text-center" colSpan={1}>
                            NG Sisip
                        </td>
                        <td className="text-center" colSpan={1}>
                            NG Gerinda
                        </td>
                        <td className="" rowSpan={2} colSpan={2}>
                            Catatan:
                        </td>
                        <td className="text-center" colSpan={1}>
                            Leader INJ
                        </td>
                        <td className="text-center" colSpan={1}>
                            Leader IPQC
                        </td>
                        <td className="text-center" colSpan={1}>
                            Leader IQC
                        </td>
                    </tr>
                    <tr className="h-10">
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                </table>
                <div className="flex gap-4">
                    <table border={1} className="w-full table-fixed">
                        <tr>
                            <td className="text-center" colSpan={1}>
                                OP Sisip
                            </td>
                            <td className="text-center" colSpan={1}>
                                OP Packing
                            </td>
                            <td className="text-center" colSpan={1}>
                                Input OQC
                            </td>
                        </tr>
                        <tr className="h-10">
                            <td />
                            <td />
                            <td />
                        </tr>
                    </table>
                    <table border={1} className="w-full table-fixed">
                        <tr>
                            <td className="text-center" colSpan={1}>
                                Tgl STB
                            </td>
                            <td className="text-center" colSpan={1}>
                                Tgl Supply Sisip
                            </td>
                            <td className="text-center" colSpan={1}>
                                Tgl Sisip
                            </td>
                            <td className="text-center" colSpan={1}>
                                Tgl Packing
                            </td>
                        </tr>
                        <tr className="h-10">
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
});

export default PrintDetailWOM;

/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import UserRoleMenuTable from "modules/master-data/user-role-menu/index/table";
import TableIndex from "modules/user-magement/user-role-menu/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import userRoleMenuService from "services/api-endpoints/dashboard/master-data/user-role-menu";

const UserRoleMenu = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([userRoleMenuService.list, page, searchValue], async () => {
        return (await userRoleMenuService.List({ query: searchValue.query, page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default UserRoleMenu;

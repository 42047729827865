/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterLocator from "modules/master-data/locator/filter";
import LocatorTable from "modules/master-data/locator/index/table";
import ModalLocatorAdd from "modules/master-data/locator/modal-add";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import locatorService from "services/api-endpoints/dashboard/master-data/locator";

const filter = ["warehouse_id"];

const Locator = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([locatorService.getList, page, searchValue, filterValue], async () => {
        return (
            await locatorService.GetList({
                ...(filterValue?.warehouse_id !== "null" ? { warehouse_id: filterValue?.warehouse_id } : {}),
                query: searchValue.query,
                page,
            })
        ).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterLocator filter={filter}>
                {(filterCtrl) => (
                    <ModalLocatorAdd refresh={getList.refetch}>
                        {(locator) => (
                            <Toolbar
                                placeholder="Search..."
                                defaultParams={["warehouse_id"]}
                                filterHandler={filterCtrl.openModal}
                                onSearchHandler={filterCtrl.onClickClearHandler}
                                addHandler={locator.openModal}
                            />
                        )}
                    </ModalLocatorAdd>
                )}
            </FilterLocator>
            <LocatorTable fetcher={getList} />
        </div>
    );
};

export default Locator;

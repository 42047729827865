import { yupResolver } from "@hookform/resolvers/yup";
import { Form, message, Select } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { SelectOption } from "models";
import { Dispatch, SetStateAction, useRef } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import purchasingRequestService, { ChildRequest, ProductInventory } from "services/api-endpoints/purchasing/purchasing-request";
import { DOC_TYPE_INVENTORY, TYPE_ITEM_INVENTORY } from "utils/constant";
import * as yup from "yup";

export interface AddItemInventory {
    type: any;
    type_name: any;
    product_id: any;
    qty: any;
    product_name: any;
    uom_id?: any;
    uom_name?: any;
    note?: any;
}

type Props = {
    children: (data: HandlerProps) => void;
    selected: ChildRequest[];
    setSelected?: Dispatch<SetStateAction<ChildRequest[]>>;
};

const schema: yup.SchemaOf<Omit<AddItemInventory, "product_name" | "type_name" | "uom_name">> = yup.object().shape({
    type: yup.number().required("Type Required"),
    product_id: yup.array().required("Product Required"),
    qty: yup.string().required("Qty Required"),
    uom_id: yup.number().required("UOM Required"),
    note: yup.string(),
});

const ModalAddInventory = ({ children, selected, setSelected }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, reset, watch } = useForm<AddItemInventory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const type = watch("type");
    const watchProductID = watch("product_id");

    const products = useQuery(
        [purchasingRequestService.getProduct, type],
        async () => {
            return (
                await purchasingRequestService.GetProduct({
                    type,
                    pr_type: DOC_TYPE_INVENTORY,
                })
            ).data.data.map(
                (el) => ({ ...el, label: `${el.product_name} - ${el.product_code1}`, value: el.product_id } as SelectOption & ProductInventory)
            );
        },
        {
            enabled: !!type,
        }
    );

    const getProduct = (key: string) => {
        const product = [...(products.data || [])].find((p) => p.product_id === (key as any));
        return product;
    };

    const onSelect = (values: any) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Product not found");
            return;
        }
        if (setSelected)
            setSelected((prev) => [
                ...prev,
                {
                    product_id: product.product_id,
                    product_name: product.product_name,
                    qty: 1,
                    type_id: type,
                    type_name: TYPE_ITEM_INVENTORY.find((p) => p.value === type)?.label,
                    uom_id: product.uom_id,
                    uom_name: product.uom_code,
                    note: "",
                } as ChildRequest,
            ]);
    };

    const onDeSelect = (values: any) => {
        if (setSelected) setSelected((prev) => [...prev].filter((p) => p.product_id !== (values as any)));
    };

    return (
        <ModalTemplate width={1000} title="Add Item Inventory" handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button onClick={dt.closeModal} type="button" ref={closeRef} className="hidden">
                        close
                    </button>
                    <Form form={form} layout="vertical">
                        <div className="grid grid-cols-2 gap-x-5">
                            <ControlledSelectInput
                                classNameForm="col-span-1"
                                control={control}
                                options={TYPE_ITEM_INVENTORY}
                                name="type"
                                placeholder="Type item"
                                label="Type item"
                                allowClear
                            />
                            <div />
                            <div className="w-full col-span-2">
                                <p>Item Name</p>
                                <Select
                                    className="w-full"
                                    value={selected.map((val) => val.product_id)}
                                    options={products.data}
                                    loading={products.isLoading}
                                    showSearch
                                    placeholder="Name Item"
                                    mode="multiple"
                                    disabled={!type || products.isLoading}
                                    onSelect={onSelect}
                                    onDeselect={onDeSelect}
                                    optionFilterProp="label"
                                    filterOption={(input, option) => {
                                        return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                    }}
                                />
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddInventory;

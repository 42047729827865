/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Checkbox, Table, TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ProductPerformaInv } from "services/api-endpoints/dashboard/master-data/c-sales-order";
import StockModal from "../modal/stock-modal";
import { TableRowSelection } from "antd/lib/table/interface";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    setProduct: React.Dispatch<React.SetStateAction<ProductPerformaInv[]>>;
};

export function tableEdit<T extends ProductPerformaInv>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setProduct, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        // const rowSelection: TableRowSelection<T> = {
        //     selectedRowKeys: (props.list?.filter((el) => el.is_last_stock).map((el) => el.product_id && el.order_detail_id) as any[]) || [],
        //     onSelectAll(selected, selectedRows, changeRows) {
        //         setProduct((prev) => {
        //             const curr = prev?.map((el) => {
        //                 const filterRows = selectedRows.filter((val) => val !== undefined);
        //                 const rowId = (selected ? filterRows : changeRows).find((el2) => el2.product_id === el.product_id)?.product_id;
        //                 return el.product_id === rowId ? { ...el, is_last_stock: selected } : el;
        //             });
        //             return curr;
        //         });
        //     },
        //     onSelect(record) {
        //         setProduct((prev) =>
        //             prev.map((el) => {
        //                 if (el.order_detail_id === record.order_detail_id && el.product_id === record.product_id) {
        //                     return {
        //                         ...el,
        //                         is_last_stock: !el.is_last_stock,
        //                     };
        //                 }
        //                 return el;
        //             })
        //         );
        //     },
        // };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => (
                    <div className="flex flex-row">
                        <p className="capitalize m-0 mr-2">{text || "-"}</p>
                        {record.is_last_stock === 1 && (
                            <Tag color="gold" className="!h-fit">
                                Last Stock
                            </Tag>
                        )}
                        {record.status === "delete" && (
                            <Tag color="red" className="!h-fit">
                                Deleted
                            </Tag>
                        )}
                        {record.status === "update" && (
                            <Tag color="green" className="!h-fit">
                                Edited
                            </Tag>
                        )}
                        {record.note && (
                            <Tag color="blue" className="!h-fit">
                                {record.note}
                            </Tag>
                        )}
                    </div>
                ),
            },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{(text || 0)?.ToIndCurrency("Rp")}</p>,
            },
            {
                title: "Qty",
                dataIndex: "qty",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Stock",
                dataIndex: "qty_stock",
                render: (text, record) => (
                    <>
                        {text ? (
                            <StockModal productId={record.product_id} productName={record.product_code}>
                                {(dt) => (
                                    <p onClick={dt.openModal} className="cursor-pointer !text-primary m-0 text-center underline">
                                        {text || 0}
                                    </p>
                                )}
                            </StockModal>
                        ) : (
                            <p className="m-0 text-center">0</p>
                        )}
                    </>
                ),
            },
            {
                title: "Discount",
                dataIndex: "discount_price",
                render: (text) => <p className="capitalize m-0">{(text || 0)?.ToIndCurrency("Rp")}</p>,
            },
            // Table.SELECTION_COLUMN,
            // {
            //     title: "Last Stock",
            //     dataIndex: "is_check",
            //     render: (text, record) => <p className="capitalize m-0">{record.is_last_stock ? "Last Stock" : "-"}</p>,
            // },
        ];

        const isEditing = (record: ProductPerformaInv, edited: ProductPerformaInv | null) =>
            record.order_detail_id === edited?.order_detail_id && record.product_id === edited?.product_id;
        const findIndexSave = (record: ProductPerformaInv, edited: ProductPerformaInv) =>
            record.order_detail_id === edited.order_detail_id && record.product_id === edited?.product_id;
        const rowKey = (record: ProductPerformaInv) => (record.product_id && record.order_detail_id) as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        const reqQty = editRow?.qty_max || 1;

        return (
            <Component
                {...props}
                // rowSelection={rowSelection}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: reqQty,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
                onChange={handleTableChange}
            />
        );
    };
}

const TableEdit = tableEdit(EditTable);
export default TableEdit;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Card, Form, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import TableView from "modules/master-data/consignment/packing-list/table-view";
import VersionHistory from "modules/master-data/consignment/version-history";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import cPackingListService, { ProductDetail } from "services/api-endpoints/dashboard/master-data/c-packing-list";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const CPackingListView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [productsSO, setProductsSO] = useState<ProductDetail[]>([]);

    const detailQuery = useQuery(
        [cPackingListService.detail],
        async () => {
            return (await cPackingListService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProductsSO(data.list_detail);
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail packing list" />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline italic m-0">{detailQuery.data?.doc_no}</span>
                                        </p>
                                        <div className="capitalize m-0 flex flex-col gap-1">
                                            Referensi No <br />
                                            {detailQuery.data?.references.map((val) => (
                                                <RefModal id={val.ref_id} docNo={val.ref_no} type={detailQuery.data.ref_type}>
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {`${val.ref_no}, ` || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ))}
                                        </div>
                                        <p className="capitalize m-0">
                                            Partner Internal <br />
                                            <span className="underline italic m-0">{detailQuery.data?.partner_name}</span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: (
                                    <TableView
                                        plId={detailQuery.data?.id}
                                        list={productsSO}
                                        setList={setProductsSO}
                                        action={false}
                                        loading={detailQuery.isLoading}
                                    />
                                ),
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default CPackingListView;

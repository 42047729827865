import { Alert, Button, DatePicker, Form, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import paymentService, { GetPiutangParams, InvoiceDocPayment, Piutang } from "services/api-endpoints/dashboard/payment";
import { FORMAT_DATE_1, FORMAT_DATE_TIME } from "utils/constant";

type Props = ModalProps & {
    accountID: number;
    type: number;
    partnerId: number;
    total: any;
    onAddInvoice: (data: InvoiceDocPayment[]) => void;
};

export const columnsFilterDocPayment: ColumnsType<InvoiceDocPayment> = [
    {
        title: "Inv Ref",
        dataIndex: "inv_ref",
        render: (text) => <span className="text-sm">{text}</span>,
    },
    {
        title: "Grand Total",
        dataIndex: "grand_total",
        render: (text) => <span className="text-sm">{(text as number)?.ToIndCurrency("Rp")}</span>,
    },
    {
        title: "Desc",
        dataIndex: "description",
        render: (text) => <span className="text-sm">{text}</span>,
    },
    {
        title: "Date Invoiced",
        dataIndex: "created_at",
        render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
    },
];

const docNoCol: ColumnsType<InvoiceDocPayment>[number] = {
    title: "Doc No",
    dataIndex: "inv_no",
    render: (text) => <span className="text-sm">{text}</span>,
};

export const columnsFilterPayment = [docNoCol, ...columnsFilterDocPayment];

const FilterPiutangReceipt = ({ children, accountID, type, partnerId, total, onAddInvoice, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [selected, setSelected] = useState<React.Key[]>([]);

    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState(10);

    const piutangMutate = useMutation([paymentService.getPiutang], async (data: GetPiutangParams) => {
        return (await paymentService.GetPiutang(data)).data.data;
    });

    const onFilter = (values: { inv_no: any; inv_ref: any; description: any; transaction_date: Moment[] }) => {
        setSelected([]);
        const tData: GetPiutangParams = {
            account_id: accountID,
            bp_id: partnerId,
            start_date: !values.transaction_date ? undefined : moment(values.transaction_date[0]).format(FORMAT_DATE_1),
            end_date: !values.transaction_date ? undefined : moment(values.transaction_date[1]).format(FORMAT_DATE_1),
        };
        piutangMutate.mutateAsync(tData);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
        setSize(pagination.pageSize || 10);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelected(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<Piutang> = {
        selectedRowKeys: selected,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const onChoose = () => {
        const data = (piutangMutate.data?.filter((el) => selected.includes(el.inv_id)) || []).map(
            (el) => ({ ...el } as unknown as InvoiceDocPayment)
        );
        onAddInvoice(data);
        closeRef.current?.click();
    };

    const columnsFilter: ColumnsType<Piutang> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * size + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "inv_no",
            render: (text) => <span className="text-sm">{text}</span>,
        },
        {
            title: "Inv Ref",
            dataIndex: "inv_ref",
            render: (text) => <span className="text-sm">{text}</span>,
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: (text) => <span className="text-sm">{(text as number)?.ToIndCurrency("Rp")}</span>,
        },
        {
            title: "Desc",
            dataIndex: "description",
            render: (text) => <span className="text-sm">{text}</span>,
        },
        {
            title: "Date Invoiced",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null} width={1000}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form onFinish={onFilter} autoComplete="off" layout="vertical" requiredMark={false}>
                        <div className="grid w-full grid-cols-4 gap-x-5">
                            <Form.Item label="Transaction Date" name="transaction_date">
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button loading={piutangMutate.isLoading} type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                    <State data={piutangMutate.data} isLoading={piutangMutate.isLoading} isError={piutangMutate.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Table
                                        rowKey={(rec) => rec.inv_id!}
                                        rowSelection={rowSelection}
                                        columns={columnsFilter}
                                        dataSource={piutangMutate.data}
                                        pagination={{
                                            current: page,
                                            pageSize: size,
                                            showSizeChanger: false,
                                        }}
                                        onChange={handleTableChange}
                                    />
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(piutangMutate.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                    <div className="flex justify-end mt-4">
                        <Button type="primary" onClick={onChoose}>
                            Add
                        </Button>
                    </div>
                </>
            )}
        </ModalTemplate>
    );
};

export default FilterPiutangReceipt;

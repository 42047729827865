import { Image } from "antd";
import React, { useContext } from "react";
import { IMAGE_FALLBACK } from "utils/constant";
import htmlParser from "html-react-parser";
import { VoucherContext } from "../context/voucher";

const RuleInformation = () => {
    const { state } = useContext(VoucherContext);

    return (
        <div className="grid grid-cols-2 gap-x-4">
            <p className="TEXT-1 mt-4">
                voucher code <br />
                <span className="TEXT-2">{state?.createData.voucher_code}</span>
            </p>
            <p className="TEXT-1 mt-4">
                voucher title <br />
                <span className="TEXT-2">{state?.createData.title}</span>
            </p>
            <p className="TEXT-1 mt-4">
                effective date <br />
                <span className="TEXT-2">
                    {state?.createData.start_date} - {state?.createData?.end_date}
                </span>
            </p>
            <p className="TEXT-1 mt-4">
                priority <br />
                <span className="TEXT-2">{state?.createData.priority}</span>
            </p>
            <div className="TEXT-1 mt-4">
                description <br />
                <div className=" p-2 bg-gray-50 rounded">{htmlParser(state?.createData.description || "")}</div>
            </div>
            <div className="TEXT-1 mt-4">
                image <br />
                <Image src={state?.createData.image_path} fallback={IMAGE_FALLBACK} className="object-cover w-full rounded" />
            </div>
        </div>
    );
};

export default RuleInformation;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-pascal-case */
import { UserContext } from "context/user";
import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// pages
import AuthPage from "pages/auth";
import IndexPage from "pages";
import AppsPage from "pages/apps";
import { APPS_PATH, CHANGE_PASSWORD_PATH, SETTING_PATH } from "utils/routes";
import SettingPage from "pages/setting";
import { StateContext } from "context/state";
import apps from "utils/apps";
import NotFoundPage from "pages/404";
import Layout from "components/common/layout";
import { message, notification } from "antd";
import { MenuType, PageObj, Pages } from "models";
import UpdatePage from "pages/update";
import { ErrorBoundary } from "react-error-boundary";
import ChangePasswordPage from "pages/change-password";

function App() {
    const { state } = useContext(UserContext);
    const { state: stateContext } = useContext(StateContext);

    message.config({ maxCount: 1 });
    notification.config({ maxCount: 1 });

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/update" element={<UpdatePage />} />
            </Routes>
            {!state.user?.token ? (
                <Routes>
                    <Route path="/" element={<AuthPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path={APPS_PATH} element={<AppsPage />} />
                    <Route path={SETTING_PATH} element={<SettingPage />} />
                    <Route path={CHANGE_PASSWORD_PATH} element={<ChangePasswordPage />} />
                    {stateContext.activeApp && !state.user.apps && <Route path="*" element={<Layout.Loading />} />}
                    {stateContext.activeApp &&
                        state.user?.apps?.map((app) => (
                            <React.Fragment key={app.apps_name}>
                                {app?.apps_menu?.map((menu) => {
                                    const menuCode = apps
                                        .find((appCode) => appCode.name === app.apps_name?.LowerAndDashLetter())
                                        ?.menus.find((mc) => mc.name === menu.key);
                                    if (!menuCode?.menu) return null;

                                    const renderRoute = (pageObj: (PageObj | undefined)[]) => {
                                        return pageObj.map((Page) => {
                                            if (Page?.hasOwnLayout) {
                                                return (
                                                    <Route
                                                        path={Page?.route || "*"}
                                                        element={
                                                            Page?.page ? (
                                                                <ErrorBoundary FallbackComponent={() => <div>no page</div>}>
                                                                    <Page.page />
                                                                </ErrorBoundary>
                                                            ) : (
                                                                <NotFoundPage />
                                                            )
                                                        }
                                                        key={Page?.route}
                                                    />
                                                );
                                            }
                                            return (
                                                <Route
                                                    path={Page?.route || "*"}
                                                    element={
                                                        <Layout.Default backHandlerOverride={Page?.backHandlerOverride}>
                                                            {Page?.page ? <Page.page /> : <NotFoundPage />}
                                                        </Layout.Default>
                                                    }
                                                    key={Page?.route}
                                                />
                                            );
                                        });
                                    };

                                    const renderPage = (key: string) => {
                                        const pageObj = menuCode.menu as MenuType;
                                        const r = (obj: MenuType | Pages) => {
                                            if (!obj) return obj;
                                            if ("index" in obj) return obj;
                                            return Object.keys(obj).map((k): any => {
                                                if ("index" in obj[k]) return obj[k];
                                                return r(obj[k]);
                                            });
                                        };

                                        const flattenPages = (r(pageObj) as Pages[])?.flat(Infinity);

                                        return flattenPages?.map((pages: Pages) => {
                                            return renderRoute([pages?.index, pages?.add, pages?.edit, pages?.editDraft, pages?.view]);
                                        });
                                    };

                                    if (!menu.collapse) return renderPage(menu.key!);

                                    const renderPages = (collapse: typeof menu.collapse): any => {
                                        return collapse.map((menuItem) => {
                                            if (menuItem?.collapse) return renderPages(menuItem.collapse);
                                            return renderPage(menuItem.key);
                                        });
                                    };

                                    return renderPages(menu.collapse);
                                })}
                            </React.Fragment>
                        ))}
                    {!stateContext.activeApp && <Route path="*" element={<Navigate to="/" />} />}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            )}
        </BrowserRouter>
    );
}

export default App;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface ProductGR {
    pl_id?: number;
    product_id?: number;
    qty?: number;
    price?: number;
    is_free?: number;
    discount_price?: number;
    voucher_id?: number;
    note?: string;
    no_koli?: string;
    is_last_stock?: string;
}

export interface CreateGRData {
    bp_id?: number;
    note?: string;
    child_document?: any[];
    so_child?: number[];
    detail_product?: ProductGR[];
}

export interface ListData {
    id?: number;
    do_no?: string;
    total_sku?: number;
    total_item?: number;
    date?: number;
    payment_term_code?: string;
    payment_term_id?: number;
    so_child: number[];
}

export interface DetailProduct {
    id?: number;
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    code2?: string;
    product_price?: number;
    area_id?: number;
    area_name?: string;
    qty?: number;
    qty_receipt?: number;
    product_owner?: string;
    is_free?: number;
    discount_price?: number;
    voucher_id?: number;
    note?: string;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

export interface DetailList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    code2?: string;
    product_price?: number;
    qty_send?: number;
    qty?: number;
    product_owner?: string;
    discount_price?: number;
    voucher_id?: number;
    note?: string;
    is_free?: number;
    no_koli?: string;
}

export interface ReferencesData {
    ref_no: string;
    ref_id: number;
}

export interface DetailGR {
    id?: number;
    doc_no?: string;
    name?: string;
    address?: string;
    account_id_to?: number;
    partner_name?: string;
    partner_address?: string;
    bp_name?: string;
    order_status?: number;
    created_at?: number;
    note?: string;
    voucher_id?: number;
    ref_type?: number;
    references?: ReferencesData[];
    list_detail?: DetailList[];
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface List {
    id?: number;
    no?: string;
}

export interface ListHistory {
    id?: number;
    notes?: string;
    created_by?: string;
    created_at?: number;
    list_so?: List[];
    list_pl?: List[];
    list_do?: List[];
    list_history?: List[];
}

export interface GRList {
    id?: number;
    doc_no?: string;
    partner_name?: string;
    bp_name?: string;
    order_status?: number;
    created_at?: number;
}

class ExtGoodsReceiptService extends BaseService {
    getAll = "/ext-order/list-gr";

    listData = "/ext-order/get-do";

    detailProduct = "/ext-order/get-product-do";

    createGR = "/ext-order/create-gr";

    detailGR = "/ext-order/detail-gr";

    history = "/bp-gr/history-goods-receipt";

    constructor() {
        super();
    }

    History<T extends ListHistory>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.history,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailGR<T extends DetailGR>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailGR}/${id}`,
            });
            return req;
        });
    }

    ListData<T extends ListData[]>(param: { partner_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listData,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailProduct<T extends DetailProduct[]>(params: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProduct,
                config: { params },
            });
            return req;
        });
    }

    CreateGR<T extends any>(data: CreateGRData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createGR,
                data,
            });
            return req;
        });
    }

    GetAll<T extends GRList>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const extGoodsReceiptService = new ExtGoodsReceiptService();
export default extGoodsReceiptService;

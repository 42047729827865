/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { Button, Card, Form, Image, Skeleton, Space, Tabs, Tag, Upload, UploadFile, UploadProps, message } from "antd";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import useProgressUpload from "hooks/useProgressUpload";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import Print from "modules/master-data/purchasing/print";
import PrintVendor from "modules/master-data/purchasing/print-vendor";
import PrintDO from "modules/master-data/purchasing/print-do";
import ListExpenses from "modules/purchasing/inventory/add/list-expenses";
import { transformToChild } from "modules/purchasing/inventory/lib/utils";
import { CustomVoucherList } from "modules/purchasing/inventory/models";
import ApprovalPurchasing from "modules/purchasing/inventory/view/approval";
import HistoryStatusPurchasing from "modules/purchasing/inventory/view/history-status";
import JourneyPO from "modules/purchasing/inventory/view/journey-po";
import TenderViewPurchasing from "modules/purchasing/inventory/view/tender-view";
import moment from "moment";
import { createContext, useContext, useMemo, useRef, useState } from "react";
import { AiOutlineCar, AiOutlineDelete, AiOutlineUpload } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import { IoIosCheckmarkCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import purchasingInventoryService, {
    ApprovedData,
    BodyCreatePurchasingInventory,
    DetailVendor,
    InventoryItem,
    UploadInvoice,
} from "services/api-endpoints/purchasing/inventory";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";
import Utils from "utils";
import {
    FORMAT_DATE_1,
    STATUS_PURCHASING,
    STATUS_PURCHASING_APPROVED,
    STATUS_PURCHASING_ARRIVED,
    STATUS_PURCHASING_COMPLETED,
    STATUS_PURCHASING_COMPLETED_TENDER,
    STATUS_PURCHASING_DRAFT,
    STATUS_PURCHASING_OPEN_TENDER,
    STATUS_PURCHASING_REJECTED,
    STATUS_PURCHASING_SHIPPING,
    STATUS_PURCHASING_VOID,
    TYPE_TAX,
} from "utils/constant";
import * as yup from "yup";
import ModalConfirm from "components/modal/confirm_modal";

export interface CreatePurchasingInventory
    extends Omit<
        BodyCreatePurchasingInventory,
        "id" | "voucher" | "child" | "total_tax" | "total_disc" | "total_price" | "grand_total" | "doc_type"
    > {}

export interface DocReqChild extends ChildRequest {
    vendors?: {
        [columnIndex: number]: {
            vendor_id: any;
            price: any;
        };
    };
}

const schema: yup.SchemaOf<Partial<Omit<CreatePurchasingInventory, "vendor_id">>> = yup.object().shape({
    payment_term_id: yup.number().required("Payment required"),
    delivery_date: yup.string().required("Delivery date required"),
    delivery_address: yup.string().required("Delivery address required"),
    shipping_id: yup.number().required("Shipping required"),
    tax_type: yup.number().required("Tax Type required"),
    transaction_date: yup.string().required("Transaction date required"),
    tax_id: yup.number(),
    doc_id: yup.number(),
    pr_id: yup.array(),
    expenses: yup.array(),
    note: yup.string(),
});

export const PurchasingContext = createContext<any>({});

const PurchasingInventoryViewContext = () => {
    const { paramId, asPreview, setPickedVendor, setListVendor, setDocReqChild, setListVoucher } = useContext(PurchasingContext);
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId || null : searchParams.get("id");
    const [progress, onUploadProgress] = useProgressUpload();

    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const { approveAccess } = useContext(StateContext);
    const letterRef = useRef<HTMLDivElement | null>(null);
    const letterVendorRef = useRef<HTMLDivElement | null>(null);
    const letterRefDO = useRef<HTMLDivElement | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [expanses, setExpanses] = useState<ExpansesData[]>([]);

    const [form] = Form.useForm();

    const shippingQuery = useQuery([purchasingInventoryService.getShipping], async () => {
        return (await purchasingInventoryService.GetShipping()).data.data;
    });

    const detailQuery = useQuery(
        [purchasingInventoryService.getDetail, id],
        async () => {
            return (await purchasingInventoryService.GetDetail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setPickedVendor(data.header?.selected_vendor_id);
                setListVendor(data.list_vendor || []);
                setDocReqChild(transformToChild(data?.list_product_price || []));
                setListVoucher(data.header?.list_voucher);
                setExpanses(data?.header?.expenses?.map((val) => ({ ...val, charge_id: val.id, charge_name: val.name } as ExpansesData)) || []);
            },
        }
    );

    const getIsAlreadyApprove = useQuery([purchasingInventoryService.historyApproval], async () => {
        return (await purchasingInventoryService.HistoryApproval(id)).data.data;
    });

    const setApprovedMutation = useMutation(
        [purchasingInventoryService.setStatusApproval],
        async (data: ApprovedData) => {
            return (await purchasingInventoryService.SetStatusApproved(data)).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                getIsAlreadyApprove.refetch();
                setIsRefresh(!isRefresh);
                message.success("Success Action Tender");
            },
        }
    );

    const setGenerateDOMutation = useMutation(
        [purchasingInventoryService.setGenerateDO],
        async () => {
            return (await purchasingInventoryService.SetGenerateDO(id)).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                setIsRefresh(!isRefresh);
                message.success("Success Generate DO");
            },
        }
    );

    const voidPOMutation = useMutation(
        [purchasingInventoryService.voidPO],
        async () => {
            return (await purchasingInventoryService.VoidPO({ id })).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                setIsRefresh(!isRefresh);
                message.success("Success Void PO");
            },
        }
    );

    const uploadInvoiceMutation = useMutation(
        [purchasingInventoryService.uploadInvoiceVendor],
        async (data: UploadInvoice) => {
            return (await purchasingInventoryService.UploadInvoiceVendor(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: (data) => {
                detailQuery.refetch();
                setIsRefresh(!isRefresh);
                message.success("Success Upload Invoice");
            },
        }
    );

    const status = detailQuery.data?.header?.doc_status;
    const statusText = STATUS_PURCHASING.find((t) => t.value === status)?.label;
    const statusColor = () => {
        if (status === STATUS_PURCHASING_VOID) return "black";
        if (status === STATUS_PURCHASING_DRAFT) return "purple";
        if (status === STATUS_PURCHASING_OPEN_TENDER) return "red";
        if (status === STATUS_PURCHASING_COMPLETED) return "green";
        return "blue";
    };

    const onApprove = (value: string) => {
        const data: ApprovedData = {
            id,
            note: value,
            type_approved: STATUS_PURCHASING_APPROVED,
        };
        setApprovedMutation.mutate(data);
    };

    const onReject = (value: string) => {
        const data: ApprovedData = {
            id,
            note: value,
            type_approved: STATUS_PURCHASING_REJECTED,
        };
        setApprovedMutation.mutate(data);
    };

    const onGenerateDO = () => {
        setGenerateDOMutation.mutate();
    };

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Purchasing Order - ${id}`,
        pageStyle: "@page {  }",
    });

    const handlePrintVendorPrice = useReactToPrint({
        content: () => letterVendorRef.current,
        documentTitle: `Purchasing Vendor Price - ${id}`,
        pageStyle: "@page {  }",
    });

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
    };

    const uploadInvoice = async () => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const data: UploadInvoice = {
            id,
            inv_vendor: filesBase64 || null,
        };
        uploadInvoiceMutation.mutate(data);
    };

    const onVoid = () => {
        voidPOMutation.mutate();
    };

    const componentToolbarAction = () => (
        <>
            {approveAccess &&
            detailQuery.data?.header?.doc_status === STATUS_PURCHASING_OPEN_TENDER &&
            !getIsAlreadyApprove.data?.IsAlreadyApproval ? (
                <Space>
                    <ModalNote title="Approve Tender" onSubmit={onApprove}>
                        {(dt) => (
                            <Button
                                type="primary"
                                className="!flex !items-center"
                                htmlType="button"
                                onClick={dt.openModal}
                                loading={setApprovedMutation.isLoading}
                            >
                                <IoIosCheckmarkCircle className="m-0 mr-2" />
                                Approve Tender
                            </Button>
                        )}
                    </ModalNote>
                    <ModalConfirm title="Void PO" value={id} subTitle="Anda yakin ingin void PO ini" onSubmit={onVoid}>
                        {(dt) => (
                            <Button onClick={dt.openModal} danger className="!flex !items-center" loading={voidPOMutation.isLoading}>
                                <IoMdCloseCircleOutline className="m-0 mr-2" />
                                Void PO
                            </Button>
                        )}
                    </ModalConfirm>
                </Space>
            ) : null}
            {status === STATUS_PURCHASING_COMPLETED_TENDER && (
                <Button onClick={onGenerateDO} type="primary" className="!flex !items-center" loading={setGenerateDOMutation.isLoading}>
                    <AiOutlineCar className="m-0 mr-2" />
                    Generate DO
                </Button>
            )}
            {Number(status) > STATUS_PURCHASING_COMPLETED_TENDER && (
                <Button onClick={() => handlePrintLetter()} type="default" className="!flex !items-center">
                    <AiOutlineCar className="m-0 mr-2" />
                    Print Document PO
                </Button>
            )}
            {(status === STATUS_PURCHASING_COMPLETED_TENDER || status === STATUS_PURCHASING_SHIPPING) && (
                <ModalConfirm title="Void PO" value={id} subTitle="Anda yakin ingin void PO ini" onSubmit={onVoid}>
                    {(dt) => (
                        <Button onClick={dt.openModal} danger className="!flex !items-center" loading={voidPOMutation.isLoading}>
                            <IoMdCloseCircleOutline className="m-0 mr-2" />
                            Void PO
                        </Button>
                    )}
                </ModalConfirm>
            )}
            {status === STATUS_PURCHASING_ARRIVED && detailQuery.data?.header?.inv_vendor === null ? (
                <Space>
                    <>{progress}</>
                    <Button onClick={uploadInvoice} type="default" className="!flex !items-center" disabled={fileList.length === 0}>
                        <AiOutlineUpload className="m-0 mr-2" />
                        Save Image
                    </Button>
                </Space>
            ) : null}
        </>
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintVendor ref={letterVendorRef} data={detailQuery.data || null} />
            <Print ref={letterRef} data={detailQuery.data || null} />
            <PrintDO ref={letterRefDO} data={detailQuery.data || null} />
            <ToolbarAction title="detail purchasing order" rightAddition={!asPreview ? componentToolbarAction : undefined} />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Purchasing Information</h1>
                                    <Tag color={statusColor()}>{statusText}</Tag>
                                </div>
                                <Form form={form} layout="vertical">
                                    <div className="grid grid-cols-10 gap-6">
                                        <div className="col-span-2 gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Document No <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.header?.po_no}
                                                </span>
                                            </p>
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Tax Type <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {TYPE_TAX.find((val) => val.value === detailQuery.data?.header?.tax_type)?.label}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="col-span-2 gap-6">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Payment Method <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.header?.payment_term}
                                                </span>
                                            </p>
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Tax <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.header?.tax}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="col-span-2 gap-6">
                                            <p className="capitalize m-0 text-gray-700 mb-6">
                                                Custom Discount <br />
                                                <ListCustomVoucher bordered showTextEmpty vouchers={detailQuery.data?.header?.voucher || []} />
                                            </p>
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Courier <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {
                                                        shippingQuery.data?.find((val) => val.shipping_id === detailQuery.data?.header?.shipping_id)
                                                            ?.shipping_name
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                        <div className="col-span-2 m-0">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Transaction Date <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {moment(detailQuery.data?.header?.transaction_date).format(FORMAT_DATE_1)}
                                                </span>
                                            </p>
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Note <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.header?.note || "-"}
                                                </span>
                                            </p>
                                            {expanses.length !== 0 && (
                                                <>
                                                    <p className="capitalize m-0 font-semibold text-gray-700">Expenses</p>
                                                    <ListExpenses data={expanses} />
                                                </>
                                            )}
                                        </div>
                                        <div className="col-span-2 gap-6">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Delivery Date <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {moment(detailQuery.data?.header?.delivery_date).format(FORMAT_DATE_1)}
                                                </span>
                                            </p>
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-6">
                                                Delivery Address <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">
                                                    {detailQuery.data?.header?.delivery_address}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 gap-6">
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total price <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.total_price || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total tax <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.total_tax || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total discount <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.total_disc || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total expenses <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.total_expenses || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1 capitalize m-0 text-gray-700">
                                            grand total <br />
                                            <span className="font-bold m-0 text-gray-700">
                                                {(detailQuery.data?.header?.grand_total || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                    </div>
                                </Form>
                            </Card>
                            {status === STATUS_PURCHASING_ARRIVED && !asPreview && detailQuery.data?.header?.inv_vendor === null ? (
                                <Upload {...uploadProps} className="w-full flex flex-row gap-5">
                                    <Button className="col-span-1 !flex !items-center" type="primary">
                                        <BiImageAdd className="text-lg mr-1" />
                                        Upload Invoice Vendor
                                    </Button>
                                </Upload>
                            ) : null}
                            {detailQuery.data?.header?.inv_vendor !== null ? (
                                <Card>
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-2">Invoice Vendor</p>
                                    <div className="grid grid-cols-12 gap-4">
                                        {(detailQuery.data?.header?.inv_vendor || []).map((img) => (
                                            <Image src={img} />
                                        ))}
                                    </div>
                                </Card>
                            ) : null}
                            <Card>
                                <div className="flex justify-between items-center">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                        PR No :
                                        <span className="m-0 font-normal text-gray-500">
                                            {" "}
                                            {detailQuery.data?.header?.pr_no ||
                                                (detailQuery.data?.header?.references || []).map((val) => val.pr_no).join(", ")}
                                        </span>
                                    </p>
                                    <Button onClick={() => handlePrintVendorPrice()} type="default" className="!flex !items-center">
                                        <AiOutlineCar className="m-0 mr-2" />
                                        Print
                                    </Button>
                                </div>
                                <Tabs
                                    items={[
                                        {
                                            label: `Tender`,
                                            key: "tender",
                                            children: <TenderViewPurchasing context={PurchasingContext} />,
                                        },
                                        {
                                            label: `Approval History`,
                                            key: "approval_history",
                                            children: <ApprovalPurchasing isRefresh={isRefresh} paramId={id} />,
                                        },
                                        {
                                            label: `History`,
                                            key: "history",
                                            children: <HistoryStatusPurchasing isRefresh={isRefresh} paramId={id} />,
                                        },
                                        {
                                            label: `Journey PO`,
                                            key: "journey_po",
                                            children: <JourneyPO paramId={id} />,
                                        },
                                    ]}
                                />
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const PurchasingInventoryView = ({ paramId, asPreview }: Props) => {
    const [problemChild, setProblemChild] = useState<InventoryItem[]>([]);
    const [docReqChild, setDocReqChild] = useState<DocReqChild[]>([]);
    const [pickedVendor, setPickedVendor] = useState<number | null>(null);
    const [listVendor, setListVendor] = useState<DetailVendor[]>([]);
    const [listVoucher, setListVoucher] = useState<CustomVoucherList[]>([]);

    const getVendor = useQuery([purchasingInventoryService.getBp], async () => {
        return (await purchasingInventoryService.GetBP()).data.data?.map((el, i) => ({
            label: el.bp_name,
            value: el.bp_id,
        }));
    });

    const value = useMemo(
        () => ({
            paramId,
            asPreview,
            docReqChild,
            setDocReqChild,
            pickedVendor,
            setPickedVendor,
            getVendor,
            setProblemChild,
            problemChild,
            listVendor,
            setListVendor,
            listVoucher,
            setListVoucher,
        }),
        [paramId, docReqChild, pickedVendor, getVendor]
    );

    return (
        <PurchasingContext.Provider value={value}>
            <PurchasingInventoryViewContext />
        </PurchasingContext.Provider>
    );
};

export default PurchasingInventoryView;

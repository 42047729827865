/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import masterCoaService, { CreateCoa, Currency } from "services/api-endpoints/accounting/master-coa";

type SelectOptionCustom = SelectOption & Currency;

export interface Props extends ControlledInputSelectProps<CreateCoa> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withCurrency<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([masterCoaService.getCurrency], async () => {
            const req = await masterCoaService.GetCurrency();
            return req.data.data?.map((el) => ({ ...el, label: el?.currency_name || "Any", value: el.currency_id } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.currency_id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const CurrencySelect = withCurrency(ControlledSelectInput);

export default CurrencySelect;

import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import React from "react";
import { Product } from "services/api-endpoints/dashboard-all/sales/order-sales";

type Props<T> = TableProps<T> & {
    list: T[] | undefined;
    loading: boolean;
};

const ProductTable = <T extends Product>({ list, loading, ...props }: Props<T>) => {
    const pagingConfig = props.pagination as TablePaginationConfig;
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => (
                <p className="capitalize m-0">{((pagingConfig?.current || 1) - 1) * (pagingConfig.pageSize || 10) + (i + 1)}</p>
            ),
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Price",
            dataIndex: "product_price",
            render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Estimated Price",
            dataIndex: "-",
            render: (text, record) => (
                <p className="capitalize m-0">{(Number(record.qty || 1) * Number(record?.product_price || record?.price))?.ToIndCurrency("Rp")}</p>
            ),
        },
        {
            title: "Stock",
            dataIndex: "stock",
            render: (text, record) => (
                <>
                    {(record.stock || 0) <= 5 && <p className="text-red-500 capitalize m-0 text-center font-bold">{text}</p>}
                    {(record.stock || 0) > 5 && (record.stock || 0) <= 10 && (
                        <p className="text-yellow-400 capitalize m-0 text-center font-bold">{text}</p>
                    )}
                    {(record.stock || 0) > 10 && <p className="text-green-500 capitalize m-0 text-center font-bold">{text}</p>}
                </>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Discount",
            dataIndex: "discount",
            render: (text, record) => (
                <p className="text-red-500 font-semibold capitalize m-0">{record.discount ? Number(text).ToIndCurrency("Rp") : "-"}</p>
            ),
        },
        {
            title: "Note",
            dataIndex: "note",
            render: (text) => <p className="m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.order_detail_id!}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            {...props}
        />
    );
};

export default ProductTable;

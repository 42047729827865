import Toolbar from "components/toolbar";
import TableIndex from "modules/master-data/external-order/ext-pd/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extPickingDetailService from "services/api-endpoints/dashboard/master-data/ext-picking-detail";
import extPurchaseOrderService from "services/api-endpoints/dashboard/master-data/ext-purchase-order";

const ExtPickingDetail = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const getList = useQuery([extPickingDetailService.listAll, page], async () => {
        return (await extPickingDetailService.ListAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default ExtPickingDetail;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import PrintCDO from "modules/master-data/consignment/delivery-order/c-do-print";
import TableView from "modules/master-data/consignment/delivery-order/table-view";
import { mapDuplicateProductDetail } from "modules/master-data/consignment/delivery-order/utils";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VersionHistory from "modules/master-data/consignment/version-history";
import Print from "modules/master-data/external-order/ext-do/print";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import extDeliveryOrderService, { ProductDeliveryOrder } from "services/api-endpoints/dashboard/master-data/ext-delivery-order";
import { C_STATUS_ORDER } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ExtDeliveryOrderView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<ProductDeliveryOrder[]>([]);

    const detailQuery = useQuery(
        [extDeliveryOrderService.detail, id],
        async () => {
            return (await extDeliveryOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(mapDuplicateProductDetail(data.list_detail));
            },
        }
    );

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Delivery Order - ${detailQuery.data?.doc_no}`,
        pageStyle: "@page { size: A5 landscape; }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail delivery order"
                rightAddition={() => (
                    <>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print DO
                        </Button>
                    </>
                )}
            />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>

                                        <p className="capitalize m-0">
                                            Partner Name <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            BP Name <br />
                                            <span className="underline m-0">{detailQuery.data?.bp_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 flex flex-col gap-1 mt-4">
                                            Referensi No <br />
                                            {detailQuery.data?.references.map((val) => (
                                                <RefModal id={val.ref_id} docNo={val.ref_no} type={detailQuery.data.ref_type}>
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {`${val.ref_no || "-"}, ` || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ))}
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Plate Number <br />
                                            <span className="underline m-0">{detailQuery.data?.plate_number || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Driver Name <br />
                                            <span className="underline m-0">{detailQuery.data?.driver_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Note <br />
                                            <span className="underline m-0">{detailQuery.data?.note || "-"}</span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: <TableView list={products} setList={setProducts} action={false} loading={detailQuery.isLoading} />,
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default ExtDeliveryOrderView;

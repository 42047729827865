/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
import { useDrag } from "react-dnd";
import ShapeMaterial from "../material/shape";
import clsx from "clsx";
import React from "react";
import { MenuTypes, cardSubProcessItemInfo } from "modules/manufacture/utils/constant";

type CardSubProcessItemProps = React.HTMLProps<HTMLDivElement> & {
    attach?: any;
    asPlaceholder?: boolean;
    childrenShape?: any;
};

const CardSubProcessItem = ({ className, attach, children, asPlaceholder, childrenShape, ...props }: CardSubProcessItemProps) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.subProcess.type,
            item: { ...MenuTypes.subProcess, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    const classnameContainer = clsx(
        className,
        cardSubProcessItemInfo.style.className,
        "hover:opacity-70 min-w-[150px] w-full items-center cursor-grab py-2 gap-4 px-3 flex flex-col shadow",
        isDragging && "filter grayscale",
    )

    return (
        <div ref={asPlaceholder ? null : drag} className={classnameContainer} {...props}>
            {children}
            <ShapeMaterial backgroundColor={cardSubProcessItemInfo.style.secondaryColor} className="text-black text-sm !h-[40px] w-full">
                {childrenShape}
            </ShapeMaterial>
        </div>
    );
};

export default CardSubProcessItem;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataCategory, DetailDataCategory, EditDataCategory, FilterDataCategory, InfoDataCategory } from "modules/master-data/category/models";
import { FilterDataModel } from "modules/master-data/model/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface AllCategory {
    id: number;
    category_name: string;
    category_code: string;
    category_type: string;
}

class CategoryService extends BaseService {
    getAll = "/master-category/all-category";

    delete = "/master-category/delete-category";

    search = "/master-category/search-category";

    suggestCategoryName = "/master-category/suggest-category-name";

    suggestCategoryCode = "/master-category/suggest-category-code";

    suggestCategoryType = "/master-category/suggest-category-type";

    filter = "/master-category/filter-category";

    create = "/master-category/create-category";

    detail = "/master-category/get-detail-category";

    edit = "/master-category/edit-category";

    info = "/master-category/category-information";

    getAllCategory = "/master-category/get-all-category";

    constructor() {
        super();
    }

    GetAllCategory<T extends AllCategory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllCategory,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataCategory>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataCategory>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestCategoryName<T extends string[]>(param: { category_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestCategoryName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestCategoryCode<T extends string[]>(param: { category_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestCategoryCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestCategoryType<T extends string[]>(param: { category_type: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestCategoryType,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataCategory>(param: FilterDataCategory) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataCategory, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataCategory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataCategory, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    Info<T extends InfoDataCategory[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.info,
            });
            return req;
        });
    }
}

const categoryService = new CategoryService();
export default categoryService;

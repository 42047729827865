import { Alert, Input, Pagination } from "antd";
import StateRender from "components/common/state-render";
import { BasePaginationResponse } from "models";
import CardMaterialItem from "modules/manufacture/card/material/item";
import CardMaterialLoading from "modules/manufacture/card/material/loading";
import CodeTag from "modules/manufacture/components/code-tag";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity } from "modules/manufacture/utils/models";
import React, { memo } from "react";
import { PiPuzzlePieceBold } from "react-icons/pi";
import { useQuery } from "react-query";
import manufactureRawMaterialService from "services/api-endpoints/dashboard/manufacture/raw-material";

const ListMaterial = () => {
    const [params, setParams] = React.useState({
        page: 1,
        paramSearch: "",
        queryValue: "",
    });
    const { menu } = React.useContext(ManufactureContext) as any;

    const materialQuery = useQuery(
        [manufactureRawMaterialService.getAll, params.page, params.paramSearch],
        async () => {
            return (await manufactureRawMaterialService.GetAll({ page: params.page, param_search: params.paramSearch })).data
                .data as BasePaginationResponse<MaterialEntity>;
        },
        {
            enabled: menu === MenuTypes.material.id,
        }
    );

    const onChangePage = (page: number) => {
        setParams((prev) => ({
            ...prev,
            page,
        }));
    };

    const onChangeSearch = (e: any) => {
        const query = e.target.value;
        setParams((prev) => ({
            ...prev,
            queryValue: query,
        }));
    };

    const onSearch = (query: string) => {
        setParams((prev) => ({
            page: 1,
            queryValue: query,
            paramSearch: query,
        }));
    };

    if (menu !== MenuTypes.material.id) return null;
    return (
        <>
            {menu === MenuTypes.material.id ? (
                <div className="flex flex-col pt-4 pb-2 gap-3 w-full relative">
                    <StateRender data={materialQuery.data} isLoading={materialQuery.isLoading} isError={materialQuery.isError}>
                        <StateRender.Data>
                            {!materialQuery.data?.list?.length ? (
                                <div className="w-full h-full flex items-center flex-col justify-center">
                                    <PiPuzzlePieceBold className="text-7xl text-gray-300" />
                                    <p className="m-0 text-gray-300 text-xl font-medium">No material listed</p>
                                </div>
                            ) : null}
                            <div className="w-full flex gap-2 py-2 overflow-x-auto">
                                {materialQuery.data?.list?.map((item) => (
                                    <CardMaterialItem title={item.text} key={item.id} attach={{ data: item } as Entity<MaterialEntity>}>
                                        <div className="flex flex-col w-full px-2 min-w-[150px]">
                                            <CodeTag code={item.code1} />
                                            <span className="line-clamp-2 text-xs mt-1 break-words">{item.text}</span>
                                        </div>
                                    </CardMaterialItem>
                                ))}
                            </div>
                        </StateRender.Data>
                        <StateRender.Loading>
                            <div className="w-full flex gap-2">
                                <CardMaterialLoading />
                                <CardMaterialLoading />
                                <CardMaterialLoading />
                            </div>
                        </StateRender.Loading>
                        <StateRender.Error>
                            <Alert message={(materialQuery.error as any)?.message} type="error" showIcon />
                        </StateRender.Error>
                    </StateRender>
                    <div className="flex justify-between items-center w-full">
                        <div className="w-[300px]">
                            <Input.Search
                                value={params.queryValue}
                                onChange={onChangeSearch}
                                onSearch={onSearch}
                                allowClear
                                placeholder="find material..."
                                className="flex-1"
                            />
                        </div>
                        <Pagination current={params.page} onChange={onChangePage} total={materialQuery.data?.total_data} size="small" pageSize={20} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default memo(ListMaterial);

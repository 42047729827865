import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import PaymentView from "pages/payment/view";
import { TYPE_JURNAL_PAYMENT_OTHER, TYPE_JURNAL_REF_TYPE_WO, TYPE_PAYMENT_VOUCHER } from "utils/constant";
import DetailWOAccounting from "../order/view/detail-wo-accounting";

export type Props = ModalProps & {
    id?: any;
    type?: any;
};

const ModalRefDetail = ({ id, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => <>{type === TYPE_JURNAL_REF_TYPE_WO && <DetailWOAccounting paramId={id} asPreview />}</>}
        </ModalTemplate>
    );
};

export default ModalRefDetail;

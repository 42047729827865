/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { AddItemT } from "modules/accounting/purchasing-supplies/modal-add-item";

export interface SuppliesList {
    id?: number;
    name?: string;
    type_id?: string;
    type_name?: string;
    type_code?: string;
    description?: number;
    images?: string[];
    is_active?: number;
}

export interface SuppliesTypeList {
    type_id?: number;
    type_name?: string;
    type_code?: string;
    description?: string;
    is_active?: number;
}

export interface CreateSuppliesType {
    name?: string;
    code?: string;
    description?: string;
    is_active?: number;
}

export interface EditSuppliesType extends CreateSuppliesType {
    type_id?: number;
}

export interface CustomVoucher {
    name?: string;
    price?: any | null;
    percentage?: any | null;
}

export interface CreateSupplies {
    type_id?: number;
    code?: string;
    name?: string;
    description?: string;
    is_active?: number;
    uom_id?: number;
    images?: any[];
}

export interface EditSupplies extends CreateSupplies {
    id?: number;
}

export interface BaseSupplies<T = any> {
    list: T[];
    total_data: number;
    total_page: number;
    current_page: number;
}

export interface BaseSupplies2 {
    model_list: CreateSuppliesType[];
}

export interface TaxData {
    tax_id: number;
    tax_name: string;
    tax_value: number;
}

export interface ShippingData {
    shipping_id: number;
    shipping_name: string;
}

export interface PartnerData {
    bp_id: number;
    bp_name: string;
}

export interface PaymentTermData {
    payment_term_id: number;
    description: string;
}

export interface NameSupplies {
    supplies_id: number;
    type: number;
    name: string;
    code: string;
    description: string;
    uom_code: string;
    uom_id: number;
}

export interface FilterPurchasing {
    docType: string;
    start_date: string;
    end_date: string;
    page: number | string;
}

export interface DetailPurchasing extends CreateSupplies {
    id: number;
    po_no: string;
    vendor: string;
    payment_term: string;
    kurir: string;
    tax: string;
    request_by: string;
    status: number;
}

export interface DetailSuppliesTypeData {
    type_id?: number;
    name?: string;
    code?: string;
    description?: string;
    is_active?: number;
}

export interface SummaryData {
    total_pajak: number;
    total_discount: number;
    total_price: number;
    grand_total: number;
}

export interface SummaryChild {
    product_id?: number;
    qty?: number;
    price?: number;
}

export interface SummaryFilter {
    child: SummaryChild[];
    voucher: any[];
    tax_value?: number;
}

export interface ListHistory {
    purchasing_order_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface HistoryOrder {
    icon?: string;
    name?: string;
    order_status?: number;
    list: ListHistory[];
}

export interface DetailSupplies {
    id?: number;
    name?: string;
    code?: string;
    type_id?: number;
    type_name?: string;
    type_code?: string;
    description?: string;
    images?: string[];
    uom_id?: number;
    is_active?: number;
}

export interface UOMData {
    uom_id: number;
    uom_name: string;
    uom_code: string;
}

class SuppliesService extends BaseService {
    getAll = "/supplies/get-all-supplies";

    createSupplies = "/supplies/add-supplies";

    detailSupplies = "/supplies/get-detail-supplies";

    getTypeSupplies = "/supplies/get-all-types-of-supplies";

    getTax = "/purchasing/get-all-tax";

    getShipping = "/purchasing/get-all-shipping";

    getPartner = "/purchasing/get-business_partner";

    getPaymentTerm = "/purchasing/get-payment-term";

    getNameSupplies = "/purchasing/get-supplies";

    createPurchasing = "/purchasing/create";

    draftPurchasing = "/purchasing/save-draft";

    deleteSupplies = "/supplies/delete-supplies";

    detailPurchasing = "/purchasing/detail-purchasing-order";

    summaryPurchasing = "/purchasing/get-summary-price";

    getHistoryPurchasing = "/purchasing/get-history-purchasing";

    createSuppliesType = "/supplies/add-type-of-supplies";

    getAllSuppliesType = "/supplies/get-list-type-of-supplies";

    getSuppliesType = "/supplies/get-types-of-supplies";

    detailSuppliesType = "/supplies/detail-type-of-supplies";

    deleteSuppliesType = "/supplies/delete-type-of-supplies";

    getUOM = "/supplies/get-uom";

    constructor() {
        super();
    }

    GetUOM<T extends UOMData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getUOM,
            });
            return req;
        });
    }

    DetailSupplies<T extends DetailSupplies>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailSupplies,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetSuppliesType<T extends SuppliesTypeList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSuppliesType,
            });
            return req;
        });
    }

    DeleteSuppliesType<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteSuppliesType}/${id}`,
            });
            return req;
        });
    }

    DetailSuppliesType<T extends DetailSuppliesTypeData>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailSuppliesType,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAllSuppliesType<T extends SuppliesTypeList>(param: FilterPurchasing) {
        return this.ProxyRequest<BaseSupplies<T>>(async () => {
            const req = await ApiMethod.get<BaseSupplies<T>>({
                url: this.getAllSuppliesType,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    CreateSuppliesType<T extends any>(data: CreateSuppliesType) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createSuppliesType,
                data,
            });
            return req;
        });
    }

    GetHistoryPurchasing<T extends HistoryOrder[]>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getHistoryPurchasing}/${data.id}`,
            });
            return req;
        });
    }

    SummaryPurchasing<T extends SummaryData>(data: SummaryFilter) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.summaryPurchasing,
                data,
            });
            return req;
        });
    }

    DetailPurchasing<T extends DetailPurchasing>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailPurchasing,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DeleteSupplies<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteSupplies}/${id}`,
            });
            return req;
        });
    }

    SaveDraftPurchasing<T extends any>(data: EditSupplies) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPurchasing,
                data,
            });
            return req;
        });
    }

    SaveCreatePurchasing<T extends any>(data: EditSupplies) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPurchasing,
                data,
            });
            return req;
        });
    }

    DraftPurchasing<T extends any>(data: CreateSupplies) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPurchasing,
                data,
            });
            return req;
        });
    }

    CreatePurchasing<T extends any>(data: CreateSupplies) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPurchasing,
                data,
            });
            return req;
        });
    }

    GetName<T extends NameSupplies[]>(param: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getNameSupplies,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetTax<T extends TaxData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getTax,
            });
            return req;
        });
    }

    GetShipping<T extends ShippingData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getShipping,
            });
            return req;
        });
    }

    GetPartner<T extends PartnerData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPartner,
            });
            return req;
        });
    }

    GetPaymentTerm<T extends PaymentTermData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPaymentTerm,
            });
            return req;
        });
    }

    GetTypeSupplies<T extends BaseSupplies2>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getTypeSupplies,
            });
            return req;
        });
    }

    CreateSupplies<T extends any>(data: CreateSupplies) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createSupplies,
                data,
            });
            return req;
        });
    }

    GetAll<T extends SuppliesList>(param: FilterPurchasing) {
        return this.ProxyRequest<BaseSupplies<T>>(async () => {
            const req = await ApiMethod.get<BaseSupplies<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const suppliesService = new SuppliesService();
export default suppliesService;

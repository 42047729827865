import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "components/form/inputs/input-select";
import React from "react";
import { useQuery } from "react-query";
import paymentService, { CreatePayment } from "services/api-endpoints/dashboard/payment";

export function withBankAccount<T extends ControlledInputSelectProps<CreatePayment>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const bankAccountQuery = useQuery([paymentService.getBA], async () => {
            return (await paymentService.GetBA()).data.data?.map((el) => ({ label: el.bank_account_no, value: el.bank_account_id } as SelectOption));
        });

        return (
            <Component
                {...props}
                showSearch
                allowClear
                optionFilterProp="children"
                options={bankAccountQuery.data}
                loading={bankAccountQuery.isLoading}
            />
        );
    };
}

const BankAccountSelect = withBankAccount(ControlledSelectInput);

export default BankAccountSelect;

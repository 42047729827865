import { Button, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import React from "react";
import { BiSave } from "react-icons/bi";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import hadirAppService, { CreateHolidayData } from "services/api-endpoints/hadir-app/hadir-app-service";

const HolidayAdd = () => {
    const navigate = useNavigate();
    const [dateRange, setDateRange] = React.useState<any[]>([]);
    const [nasionalHolidays, setNasionalHolidays] = React.useState([]);
    const [holidays, setHolidays] = React.useState([]);

    const createMutate = useMutation([hadirAppService.createHoliday], async (data: CreateHolidayData) => {
        return (await hadirAppService.CreateHoliday(data)).data.data;
    });

    const formatting = (date: any) => new DateObject(date).format("YYYY-MM-DD");

    const onSave = () => {
        if (dateRange.length < 2) {
            message.error("Rentang tanggal required!");
            return;
        }
        createMutate
            .mutateAsync({
                start_date: formatting(dateRange[0]),
                end_date: formatting(dateRange[1]),
                libur_nasional: nasionalHolidays.map((date) => formatting(date)),
                libur_wajib: holidays.map((date) => formatting(date)),
            })
            .then(() => {
                message.success("Hari libur created!");
                navigate(-1);
            });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new holiday"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onSave} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <div className="">
                <p>Rentang Tanggal</p>
                <Calendar value={dateRange} highlightToday={false} onChange={setDateRange as any} range numberOfMonths={2} showOtherDays rangeHover />
            </div>
            <div style={{ opacity: dateRange.length < 2 ? "0.4" : "1" }} className="w-full flex gap-10">
                <div className="">
                    <p className="">Hari libur wajib</p>
                    <Calendar
                        highlightToday={false}
                        minDate={dateRange[0]}
                        maxDate={dateRange[1]}
                        multiple
                        value={holidays}
                        onChange={setHolidays as any}
                        numberOfMonths={2}
                        disabled={dateRange.length < 2}
                    />
                </div>
                <div className="">
                    <p className="">Hari libur nasional</p>
                    <Calendar
                        minDate={dateRange[0]}
                        maxDate={dateRange[1]}
                        multiple
                        value={nasionalHolidays}
                        highlightToday={false}
                        onChange={setNasionalHolidays as any}
                        numberOfMonths={2}
                        disabled={dateRange.length < 2}
                    />
                </div>
            </div>
        </div>
    );
};

export default HolidayAdd;

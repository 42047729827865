import { Alert, message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpSalesService, { BpSales } from "services/api-endpoints/dashboard-all/bp-sales";
import BpSalesTable from "modules/dashboard-all/bp-sales/index/table";
import FilterBpSales from "modules/dashboard-all/bp-sales/index/filter";

const filter = ["sales_order_no", "invoice_no", "start_date", "end_date", "marketplace_id", "customer_name", "status"];

const BpSalesPage = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bpSalesService.getAll, page, size, searchValue, filterValue, isFilter, isSearch], async () => {
        // if (isSearch) return (await salesService.Search({ page, query: searchValue.query })).data.data;
        // if (isFilter) return (await salesService.Filter(filterValue as any)).data.data;
        return (await bpSalesService.GetAll({ page, size })).data.data;
    });

    const deleteMutation = useMutation(async (data: BpSales) => (await bpSalesService.DeleteDraft({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<BpSales>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBpSales filter={filter}>
                {(dt) => (
                    <Toolbar placeholder="SO No / Inv No / Customer Name" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />
                )}
            </FilterBpSales>
            <BpSalesTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default BpSalesPage;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { SelectOption } from "models";
import ModalLocator from "modules/master-data/external-order/ext-ro/modal-locator";
import React, { useState } from "react";
import { UseMutationResult } from "react-query";
import { DetailOrder, LocatorData } from "services/api-endpoints/dashboard/master-data/ext-return-order";
import { EXT_RETURN_PENGEMBALIAN_UANG, EXT_RETURN_POTONG_HUTANG, EXT_RETURN_TUKAR_BARANG, STOCK_ACTION, STOCK_ACTION2 } from "utils/constant";

type Props<T> = Omit<EditTableProps<T>, "editInputType" | "columns" | "editRow" | "setEditRow" | "findIndexSave" | "isEditing" | "rowKey"> & {
    type: number | null;
    locatorIN: UseMutationResult<(SelectOption & LocatorData)[], unknown, number, unknown>;
    warehouseId: number | null;
    onSubmitLocatorOrder: (locator: LocatorData) => void;
};

export function tableEdit<T extends DetailOrder>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ type, locatorIN, warehouseId, onSubmitLocatorOrder, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);

        const onChangeLocator = (record: T) => {
            return (val: any) => {
                const locator = locatorIN.data?.find((loc) => loc.area_id === val);
                const item = {
                    ...record,
                    ...locator,
                };
                onSubmitLocatorOrder(item);
            };
        };

        const columns: ColumnType<T>[] = [
            {
                dataIndex: "inv_no",
                title: "Invoice No",
                render: (text) => text,
            },
            {
                dataIndex: "product_name",
                title: "Product",
                render: (text) => text,
            },
            {
                dataIndex: "product_code1",
                title: "Code",
                render: (text) => text,
            },
            {
                dataIndex: "qty",
                title: "Qty Order",
                render: (text) => text,
            },
            {
                title: "Qty Return",
                width: "100px",
                dataIndex: "qty_return",
                ...{ editable: true },
                render: (text) => text || "",
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => (
                    <>
                        {type === EXT_RETURN_TUKAR_BARANG && (
                            <ModalLocator
                                title="Locator"
                                productId={record.product_id}
                                qty={record.qty}
                                onSubmit={onSubmitLocatorOrder}
                                warehouseId={warehouseId}
                            >
                                {(dt) => (
                                    <Button
                                        size="small"
                                        onClick={() => dt.openModalWithData(record)}
                                        className="!flex !items-center"
                                        type="default"
                                        {...(!text ? { danger: true } : {})}
                                    >
                                        {text || "Choose Locator"}
                                    </Button>
                                )}
                            </ModalLocator>
                        )}
                        {(type === EXT_RETURN_PENGEMBALIAN_UANG || type === EXT_RETURN_POTONG_HUTANG) && (
                            <Select
                                value={record?.area_id}
                                showSearch
                                placeholder="Locator"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                                className="!w-[150px]"
                                loading={locatorIN.isLoading}
                                options={locatorIN.data || []}
                                onChange={onChangeLocator(record)}
                            />
                        )}
                    </>
                ),
            },
            {
                title: "Stock Action",
                width: "100px",
                dataIndex: "stock_action",
                ...{ editable: true },
                render: (text) => text || "",
            },
        ];

        const editInputType: EditTableProps<T>["editInputType"] = { qty_return: "number", stock_action: "select" };
        const isEditing = (record: DetailOrder, edited: DetailOrder | null) => record.id === edited?.id && record.product_id === edited?.product_id;
        const findIndexSave = (record: DetailOrder, edited: DetailOrder) => record.id === edited.id && record.product_id === edited?.product_id;
        const rowKey = (record: DetailOrder) => `${record.id}${record.product_id}`;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: editRow?.qty,
                    selectProps: {
                        options: type === EXT_RETURN_TUKAR_BARANG ? STOCK_ACTION2 : STOCK_ACTION,
                        className: "!w-[150px]",
                        placeholder: "Action",
                    },
                    ...props?.cellProps,
                }}
                list={props.list?.map((order: any) => ({
                    ...order,
                    stock_action: STOCK_ACTION.find((act) => act.value === order.stock_action || act.label === order.stock_action)?.label,
                }))}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const OrderTable = tableEdit(EditTable);
export default OrderTable;

import { Alert, Button, Card, Skeleton, Space, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import useConfirmDelete from "hooks/useConfirmDelete";
import { tProductTransformWithQty2 } from "modules/master-data/material-movement/utils";
import Print from "modules/master-data/routine-stock/print";
import TableViewProduct from "modules/master-data/routine-stock/table-view";
import React, { useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiListCheck } from "react-icons/bi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import materialMovementService, {
    AdjustParams,
    ProductTransfer,
    ProductTransferWithEdit2,
} from "services/api-endpoints/dashboard/master-data/material-movement";
import { MATERIAL_MOVEMENT_DRAFT } from "utils/constant";

const RoutineStockView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const status = searchParams.get("status");

    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Routine Stock - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const [products, setProducts] = React.useState<ProductTransferWithEdit2[]>([]);

    const adjustStockMutate = useMutation(
        [materialMovementService.adjustStock],
        async (data: AdjustParams) => {
            return (await materialMovementService.AdjustStock(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Success Adjustment Routine Stock ");
                navigate(-1);
            },
        }
    );

    const detailQuery = useQuery(
        [materialMovementService.detailRoutineStock, id],
        async () => {
            return (await materialMovementService.DetailRoutineStock({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setProducts(tProductTransformWithQty2(data?.list_product) || []);
            },
        }
    );

    const [showConfirmApprove] = useConfirmDelete<any>({
        title: "Adjustment Stock",
        content: "You sure to approve adjustment stock?",
        onOk: (data, callback) => {
            const temp: AdjustParams = {
                id,
                warehouse_id: detailQuery.data?.warehouse_id || 0,
                status: 1,
                list_product: products?.map((item) => ({
                    id: item.product_id,
                    area_id: item.area_id,
                    qty: item.qty,
                })) as any,
            };
            adjustStockMutate.mutateAsync(temp).then(callback).catch(callback);
        },
    });

    const [showConfirmReject] = useConfirmDelete<any>({
        title: "Reject Adjustment Stock",
        content: "You sure to reject adjustment stock?",
        onOk: (data, callback) => {
            const temp: AdjustParams = {
                id,
                warehouse_id: detailQuery.data?.warehouse_id || 0,
                status: 0,
                list_product: products?.map((item) => ({
                    id: item.product_id,
                    area_id: item.area_id,
                    qty: item.qty,
                })) as any,
            };
            adjustStockMutate.mutateAsync(temp).then(callback).catch(callback);
        },
    });

    const isEditing = (record: ProductTransfer, edited: ProductTransfer | null) => record.product_id === edited?.product_id;
    const findIndexSave = (record: ProductTransfer, edited: ProductTransfer) => record.product_id === edited.product_id;
    const rowKey = (record: ProductTransfer) => record.product_id! as any;

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail routine stock"
                rightAddition={() => (
                    <>
                        {detailQuery.data?.status === MATERIAL_MOVEMENT_DRAFT && (
                            <Space>
                                <Button loading={adjustStockMutate.isLoading} onClick={showConfirmApprove} className="!flex !items-center">
                                    <BiListCheck className="m-0 mr-2" />
                                    Adjustment Stock
                                </Button>
                                <Button danger loading={adjustStockMutate.isLoading} onClick={showConfirmReject} className="!flex !items-center">
                                    <IoMdCloseCircleOutline className="m-0 mr-2" />
                                    Reject
                                </Button>
                            </Space>
                        )}
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </>
                )}
            />
            <Card>
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-3 gap-4">
                                    <p className="capitalize m-0">
                                        Material Movement No <br />
                                        <span className="italic m-0">{detailQuery.data?.mm_no || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse From <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse To <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Date <br />
                                        <span className="underline italic m-0">{detailQuery.data?.created_at || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Locator From <br />
                                        <span className="italic m-0">{detailQuery.data?.area_from || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Locator To <br />
                                        <span className="italic m-0">{detailQuery.data?.area_from || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 4 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <TableViewProduct
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
                loading={detailQuery.isLoading}
                list={products}
                onSetList={setProducts}
                canRemove={false}
                action={detailQuery.data?.status === MATERIAL_MOVEMENT_DRAFT}
            />
        </div>
    );
};

export default RoutineStockView;

import DevelopmentBlocker from "components/common/development-blocker";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import React from "react";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBP = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Filter Business Partner"
            forms={(dt) => {
                return (
                    <DevelopmentBlocker>
                        <div className="grid grid-cols-2 gap-4">
                            <h1>Filter</h1>
                        </div>
                    </DevelopmentBlocker>
                );
            }}
            {...props}
        />
    );
};

export default FilterBP;

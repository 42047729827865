/* eslint-disable no-shadow */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import ModalAddRoleMenu from "modules/master-data/role-menu/add/modal-menu";
import RoleMenuTable, { ItemRowRoleMenu } from "modules/master-data/role-menu/add/table-menu";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import roleMenuService, { ListMenu, RoleMenuRequest } from "services/api-endpoints/dashboard/master-data/role-menu";
import { ROLE_ACCESS } from "utils/constant";
import * as yup from "yup";

export type FormRoleMenu = Pick<RoleMenuRequest, "apps_id" | "role_id" | "role_access" | "description">;

const schema: yup.SchemaOf<FormRoleMenu> = yup.object().shape({
    apps_id: yup.number().required("Apps required"),
    role_id: yup.number().required("Role required"),
    description: yup.string(),
    role_access: yup.array().min(1).required("Role Access required"),
});

const RoleMenuAdd = () => {
    const navigate = useNavigate();
    const [menus, setMenus] = useState<ItemRowRoleMenu[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FormRoleMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation(
        async (data: RoleMenuRequest) => {
            return (await roleMenuService.CreateRoleMenu(data)).data.data;
        },
        {
            onSuccess() {
                navigate(-1);
                message.success("Role menu created!");
            },
        }
    );

    const listAppQuery = useQuery([roleMenuService.listApp], async () => {
        return (await roleMenuService.ListApp()).data.data?.filter((el) => el?.is_active === 1).map((el) => ({ value: el.id!, label: el.name! }));
    });

    const listRoleQuery = useQuery([roleMenuService.listRole], async () => {
        return (await roleMenuService.ListRole()).data.data?.filter((el) => el?.is_active === 1).map((el) => ({ value: el.id!, label: el.name! }));
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            apps_id: data.apps_id,
            description: data?.description,
            role_id: data?.role_id,
            role_access: data?.role_access,
            collapse: menus,
        } as RoleMenuRequest;

        if (!menus.length) {
            message.error("At least insert 1 menu");
            return;
        }

        createMutate.mutate(parseData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitMenu = (menu: ListMenu | undefined) => {
        if (menu) {
            setMenus((prev) => {
                if (prev?.find((m) => m.key === menu.key)) {
                    message.error(`Menu ${menu.name} is already inserted`);
                    return prev;
                }
                return [...(prev || []), menu];
            });
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new role menu"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4">
                        <ControlledSelectInput control={control} name="apps_id" options={listAppQuery.data || []} placeholder="Apps" label="Apps" />
                        <ControlledSelectInput control={control} name="role_id" options={listRoleQuery.data || []} placeholder="Role" label="Role" />
                        <ControlledSelectInput
                            control={control}
                            mode="multiple"
                            name="role_access"
                            options={ROLE_ACCESS as any}
                            placeholder="Role Access"
                            label="Role Access"
                        />
                        <ControlledInputText control={control} name="description" placeholder="Description" label="Description" />
                    </div>
                </Form>
                <div className="w-full flex justify-end">
                    <ModalAddRoleMenu onSubmit={onSubmitMenu}>
                        {({ openModal }) => (
                            <Button onClick={openModal} type="primary" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Menu
                            </Button>
                        )}
                    </ModalAddRoleMenu>
                </div>
            </Card>
            <RoleMenuTable menus={menus} setMenus={setMenus} />
        </div>
    );
};

export default RoleMenuAdd;

import { Input } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (value: string) => void;
};

const ModalNote = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [value, setValue] = useState("");

    const onSubmitHandler = () => {
        onSubmit(value);
        closeRef.current?.click();
        setValue("");
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} onOk={onSubmitHandler} okButtonProps={{ disabled: !value }}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Input.TextArea rows={10} placeholder="Note" value={value} onChange={(e) => setValue(e.target.value)} />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalNote;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListPackingList {
    id: number;
    pl_no: string;
    partner_name: string;
    order_status: number;
    created_at: string;
}

export interface RefList {
    ref_id: number;
    ref_no: string;
}

export interface ProductDetail {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    discount_price: number;
    voucher_id: number;
    note: string;
    is_free: number;
    no_koli: string;
    code2: string;
}

export interface DetailPackingList {
    id: number;
    doc_no: string;
    ref_type: number;
    references: RefList[];
    partner_name: string;
    order_status: number;
    created_at: string;
    list_detail: ProductDetail[];
}

export interface ProductCPD {
    id?: number;
    product_id?: number;
    product_code1?: string;
    product_code2?: string;
    product_name?: string;
    product_price?: number;
    price?: number;
    qty?: number;
    product_owner?: string;
    discount_price?: number;
    images?: string[];
    voucher_id?: number;
    note?: string;
    is_free?: number;
    no_koli?: any;
    area_id?: number;
    is_last_stock?: number;
}

export interface CreatePackingList {
    account_id_to: number;
    child_document: number[];
    detail_product: ProductCPD[];
}

export interface ParamsGetPD {
    pd_id: any;
}

export interface GetPickingDetail {
    id?: number;
    doc_no?: string;
    account_id_to?: number;
    partner_name?: string;
}

export interface DetailKoliProduct {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    qty?: number;
}
export interface DetailKoli {
    pl_no?: string;
    no_koli?: string;
    list_product?: DetailKoliProduct[];
}

class CPackingListService extends BaseService {
    listAll = "/consignment/list-c-packing-list";

    detail = "/consignment/detail-c-packing-list";

    create = "/consignment/create-c-packing-list";

    getProductCPD = "/consignment/get-product-pd";

    getPickingDetail = "/consignment/get-picking-detail";

    detailKoli = "/consignment/detail-koli";

    constructor() {
        super();
    }

    DetailKoli<T extends DetailKoli>(params: { pl_id?: any; no_koli?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailKoli,
                config: { params },
            });
            return req;
        });
    }

    GetPickingDetail<T extends GetPickingDetail>(params: { pd_no?: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPickingDetail,
                config: { params },
            });
            return req;
        });
    }

    GetProductCPD<T extends ProductCPD[]>(params: ParamsGetPD) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductCPD,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends { id: number }>(data: CreatePackingList, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailPackingList>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    ListAll<T extends ListPackingList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAll,
            });
            return req;
        });
    }
}

const cPackingListService = new CPackingListService();
export default cPackingListService;

import { Alert, Input } from "antd";
import StateRender from "components/common/state-render";
import CardModelItem from "modules/manufacture/card/model/item";
import CardModelLoading from "modules/manufacture/card/model/loading";
import { ManufactureContext, SaveModels } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, ModelEntity } from "modules/manufacture/utils/models";
import React from "react";
import { PiPuzzlePieceBold } from "react-icons/pi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import manufactureModelService from "services/api-endpoints/dashboard/manufacture/model";

const ListModel = () => {
    const { menu, setModels, onEditModel } = React.useContext(ManufactureContext) as any;
    const [deleteId, setDeleteId] = React.useState<any>(null);

    const [params, setParams] = React.useState({
        page: 1,
        paramSearch: "",
        queryValue: "",
    });

    const modelQuery = useQuery(
        [manufactureModelService.getAll, params.paramSearch],
        async () => {
            const res = (await manufactureModelService.GetAll({ param_search: params.paramSearch })).data.data;
            return {
                ...res,
                list: res.list?.map((model) => ({ parent: model.process?.parent, entities: model.process?.entities } as SaveModels)),
                listRaw: res.list,
            };
        },
        {
            onSuccess(data) {
                setModels(data.list);
            },
            enabled: menu === MenuTypes.model.id,
        }
    );

    const deleteMutation = useMutation([manufactureModelService.delete], async (id: any) => {
        return (await manufactureModelService.Delete({ id })).data.data;
    });

    const onClickDelete = (idModel?: string) => {
        return () => {
            setDeleteId(idModel);
            deleteMutation.mutateAsync(idModel).finally(() => {
                modelQuery.refetch().finally(() => {
                    setDeleteId(null);
                });
            });
        };
    };

    const onClickEdit = (attach?: Entity<ModelEntity>, idModel?: string) => {
        return () => {
            onEditModel(modelQuery.data?.list?.find((model) => model.parent.idEntity === attach?.idEntity)?.entities, idModel);
        };
    };

    const onChangeSearch = (e: any) => {
        const query = e.target.value;
        setParams((prev) => ({
            ...prev,
            queryValue: query,
        }));
    };

    const onSearch = (query: string) => {
        setParams((prev) => ({
            ...prev,
            queryValue: query,
            paramSearch: query,
        }));
    };

    if (menu !== MenuTypes.model.id) return null;
    return (
        <>
            {menu === MenuTypes.model.id ? (
                <div className="flex flex-col pb-2 gap-3 w-full relative">
                    <StateRender data={modelQuery.data} isLoading={modelQuery.isLoading} isError={modelQuery.isError}>
                        <StateRender.Data>
                            {!modelQuery.data?.listRaw?.length ? (
                                <div className="w-full h-full flex items-center flex-col justify-center">
                                    <PiPuzzlePieceBold className="text-7xl text-gray-300" />
                                    <p className="m-0 text-gray-300 text-xl font-medium">No model listed</p>
                                </div>
                            ) : null}
                            <div className="w-full flex gap-2 py-2 overflow-x-auto">
                                {modelQuery.data?.listRaw?.map((model) => {
                                    const parent = model.process?.parent;
                                    if (model.id === deleteId) return null;
                                    return (
                                        <CardModelItem
                                            onEdit={onClickEdit(parent, model.id)}
                                            onDelete={onClickDelete(model.id)}
                                            key={parent?.idEntity}
                                            attach={parent}
                                            title={parent?.data?.text}
                                        >
                                            <img className="h-[120px] object-contain" src={parent?.data?.preview} alt={parent?.data?.text} />
                                        </CardModelItem>
                                    );
                                })}
                            </div>
                        </StateRender.Data>
                        <StateRender.Loading>
                            <div className="w-full flex gap-2">
                                <CardModelLoading />
                                <CardModelLoading />
                                <CardModelLoading />
                            </div>
                        </StateRender.Loading>
                        <StateRender.Error>
                            <Alert message={(modelQuery.error as any)?.message} type="error" showIcon />
                        </StateRender.Error>
                    </StateRender>
                    <div className="flex justify-between items-center w-full">
                        <div className="w-[300px]">
                            <Input.Search
                                value={params.queryValue}
                                onChange={onChangeSearch}
                                onSearch={onSearch}
                                allowClear
                                placeholder="find model..."
                                className="flex-1"
                            />
                        </div>
                        {/* <Pagination current={params.page} onChange={onChangePage} total={variableQuery.data?.total_data} size="small" pageSize={20} /> */}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ListModel;

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ListProductWo } from "services/api-endpoints/dashboard/master-data/working-order";

type Props<T> = {
    list: ListProductWo[];
};

const WorkingOrderTableProduct = <T extends ListProductWo>({ list }: Props<T>) => {
    const columns: ColumnsType<ListProductWo> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
        },
        {
            title: "Product Code 1",
            dataIndex: "product_code1",
        },
        {
            title: "Product Code 2",
            dataIndex: "product_code2",
        },
        {
            title: "Price",
            dataIndex: "product_price",
            render: (val: number) => val.ToIndCurrency("Rp"),
        },
        {
            title: "UOM",
            dataIndex: "uom_name",
        },
        {
            title: "Quantity Use",
            dataIndex: "qty_use",
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_code1!}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default WorkingOrderTableProduct;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Checkbox, Form, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { UserContext } from "context/user";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { mapDiscountProduct } from "modules/master-data/consignment/delivery-order/utils";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VoucherModal from "modules/master-data/consignment/modal/voucher-modal";
import ModalEditSO from "modules/master-data/consignment/sales-order/edit-so-modal";
import ModalLaststock from "modules/master-data/consignment/sales-order/laststock-modal";
import TableEdit from "modules/master-data/consignment/sales-order/table-edit";
import VersionHistory from "modules/master-data/consignment/version-history";
import { useContext, useEffect, useState } from "react";
import { AiOutlineUndo } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/bp-sales";
import cSalesOrderService, {
    EditCSalesOrder,
    EditProduct,
    LastStockParam,
    ParamsSummaryPrice,
    ProductPerformaInv,
    ReadyToPickingParam,
} from "services/api-endpoints/dashboard/master-data/c-sales-order";
import { C_STATUS_ORDER } from "utils/constant";

const CSalesOrderEdit = () => {
    const { state } = useContext(UserContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [products, setProducts] = useState<ProductPerformaInv[]>([]);
    const [allProducts, setAllProducts] = useState<ProductPerformaInv[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [isLastStock, setIsLasStock] = useState<boolean>(false);

    const detailQuery = useQuery(
        [cSalesOrderService.detail, id],
        async () => {
            return (await cSalesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setProducts(
                    data.list_detail
                        .map((val) => ({
                            ...val,
                            qty_max: val.qty,
                            hideEditAction: val.is_last_stock === 1,
                            hideRemoveAction: val.is_last_stock === 1,
                        }))
                        .filter((val) => val.is_free === 0)
                );
                if (data.voucher_id !== 0) {
                    setVouchers([{ voucher_id: data.voucher_id, voucher_code: data.voucher_code } as Voucher]);
                }
            },
        }
    );

    const editMutation = useMutation(
        [cSalesOrderService.edit],
        async (data: EditCSalesOrder) => {
            return (await cSalesOrderService.Edit(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales Order Edited");
                navigate(-1);
            },
        }
    );

    const setLastStockMutation = useMutation(
        [cSalesOrderService.setLastStock],
        async (data: LastStockParam) => {
            return (await cSalesOrderService.SetLastStock(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales Order Success Set Last Stock");
                navigate(-1);
            },
        }
    );
    const readyToPickingMutation = useMutation(
        [cSalesOrderService.readyToPicking],
        async (data: ReadyToPickingParam) => {
            return (await cSalesOrderService.ReadyToPicking(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("SO Success Ready To Picking");
                navigate(-1);
            },
        }
    );

    const getSummaryPrice = useMutation(
        [cSalesOrderService.summaryPrice],
        async (data: ParamsSummaryPrice) => {
            return (await cSalesOrderService.SummaryPrice(data)).data.data;
        },
        {
            onSuccess: (data) => {
                const filterFree = data.detail_product?.filter((val) => val.is_free === 1);
                const prodFree = (filterFree || []).map((el) => {
                    return {
                        ...el,
                        product_price: el.price,
                        product_code: el.product_code1,
                        hideEditAction: true,
                        hideRemoveAction: true,
                    } as ProductPerformaInv;
                });
                const filterDisc = data.detail_product?.filter((val) => val.is_free !== 1 && val.voucher_id !== null);
                const disc = (filterDisc || []).map((el) => {
                    return {
                        ...el,
                        product_price: el.price,
                        product_code: el.product_code1,
                    } as ProductPerformaInv;
                });
                const prodDisc = mapDiscountProduct(products, disc || []);
                setAllProducts([...prodDisc, ...prodFree]);
                if (prodFree.length !== 0) {
                    message.success(`Get Free ${prodFree.length} Product`);
                }
            },
        }
    );

    const onSaveHandler = async (type: number) => {
        try {
            const productFilter = allProducts.filter((p) => p.status !== "delete");
            const parseData: EditCSalesOrder = {
                id,
                type_edit: type,
                voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
                discount_price: getSummaryPrice.data?.total_discount || 0,
                detail_product: productFilter.map((el) => {
                    return {
                        product_id: el.product_id,
                        price: el.product_price,
                        qty: el.qty,
                        discount_price: el.discount_price,
                        voucher_id: el.voucher_id,
                        note: el.note,
                        is_free: el.is_free,
                        is_last_stock: el.is_last_stock ? 1 : 0,
                    } as EditProduct;
                }),
            };
            editMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onSaveLastStockHandler = async () => {
        try {
            const parseData: LastStockParam = {
                id,
                is_last_stock: isLastStock ? 1 : 0,
            };
            setLastStockMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const onReadyToPickingHandler = async () => {
        try {
            const parseData: ReadyToPickingParam = {
                id,
            };
            readyToPickingMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    const isSameProduct = () => {
        const filteredQty = products.filter((p) => p.status === "update" || p.status === "delete");
        if (filteredQty.length !== 0) {
            return false;
        }
        return true;
    };

    // const isLastStock = () => {
    //     const filteredQty = products.filter((p) => p.is_last_stock);
    //     if (filteredQty.length !== 0) {
    //         return true;
    //     }
    //     return false;
    // };

    const onRemoveProduct = (product: ProductPerformaInv) => {
        const productIsAlreadyThere = products?.find((p) => p.product_id === product.product_id);
        if (productIsAlreadyThere) {
            message.warning("Product is given DELETED flag");
            setAllProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            setProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            return;
        }
        message.warning("Product removed");
        setAllProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
        setProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
    };

    const onUndoRemoveProduct = (product: ProductPerformaInv) => {
        return () => {
            const prevProduct = products?.find((p) => p.product_id === product.product_id);

            const isEdited = prevProduct?.qty !== product.qty;
            setAllProducts((prev) =>
                prev?.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
            setProducts((prev) =>
                prev?.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
        };
    };

    const onEditProduct = (productList: ProductPerformaInv[], prevEditProduct?: ProductPerformaInv | null) => {
        if (!prevEditProduct) return;

        const productNotFree = (detailQuery.data?.list_detail || [])
            .map((val) => {
                return {
                    ...val,
                    qty_max: val.qty,
                    is_check: productList.find((el) => el.product_id === val.product_id && el.order_detail_id && val.order_detail_id)?.is_check,
                };
            })
            .filter((val) => val.is_free === 0);
        const prevProduct = productNotFree?.find((p) => p.product_id === prevEditProduct?.product_id);
        const currEditProduct = productList?.find((p) => p.product_id === prevEditProduct.product_id);
        if (!currEditProduct) return;
        const isEdited = prevProduct?.qty !== currEditProduct.qty;
        if (prevProduct) {
            setAllProducts(() =>
                productList?.map((p) =>
                    p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined, qty: currEditProduct.qty } : p
                )
            );
            setProducts(() =>
                productList?.map((p) =>
                    p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined, qty: currEditProduct.qty } : p
                )
            );
            return;
        }
        setAllProducts(productList);
        setProducts(productNotFree);
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    const onParseData = () => {
        const productFilter = products.filter((p) => p.status !== "delete");
        const parseData: ParamsSummaryPrice = {
            id,
            account_id_to: detailQuery.data?.account_id_to,
            detail_product: [...(productFilter?.map((p) => ({ product_id: p.product_id, qty: p.qty, price: p.product_price } as EditProduct)) || [])],
            voucher_id: vouchers.length === 1 ? vouchers[0].voucher_id : null,
        };
        return parseData;
    };

    useEffect(() => {
        getSummaryPrice.mutate(onParseData());
    }, [products, vouchers]);

    const onChangeCheckBox = (e: any) => {
        const isChecked = e.target.checked;
        setIsLasStock(isChecked);
    };
    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit sales order"
                rightAddition={() => (
                    <Space>
                        {isLastStock && (
                            <ModalLaststock title="Konfirmasi" onSubmit={onSaveLastStockHandler}>
                                {(modal) => (
                                    <Button
                                        loading={setLastStockMutation.isLoading}
                                        onClick={modal.openModal}
                                        type="primary"
                                        className="!flex !items-center"
                                    >
                                        Set SO Last Stock
                                    </Button>
                                )}
                            </ModalLaststock>
                        )}
                        <Button
                            loading={readyToPickingMutation.isLoading}
                            onClick={onReadyToPickingHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            Ready To Picking
                        </Button>
                        {detailQuery.data?.account_id === state.user?.account_id && (
                            <ModalEditSO title="Konfirmasi" onSubmit={onSaveHandler} isSameProduct={isSameProduct()}>
                                {(modal) => (
                                    <Button loading={editMutation.isLoading} onClick={modal.openModal} type="default" className="!flex !items-center">
                                        <BiSave className="m-0 mr-2" />
                                        Save Edit
                                    </Button>
                                )}
                            </ModalEditSO>
                        )}
                    </Space>
                )}
            />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(stateDetail) => (
                            <>
                                <State.Data state={stateDetail}>
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Referensi No <br />
                                            {detailQuery.data?.ref_id ? (
                                                <RefModal
                                                    id={detailQuery.data?.ref_id}
                                                    docNo={detailQuery.data?.ref_no}
                                                    type={detailQuery.data.ref_type}
                                                >
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {detailQuery.data?.ref_no || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ) : (
                                                <span className="underline m-0">-</span>
                                            )}
                                        </p>
                                        <p className="capitalize m-0">
                                            Partner Internal <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                        <div className="m-0 mt-2">
                                            <p className="capitalize m-0 mb-1">Voucher</p>
                                            <VoucherModal vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                                {(dt) => (
                                                    <div className="w-full flex flex-col gap-1">
                                                        <Button
                                                            onClick={dt.openModal}
                                                            disabled={vouchers.length >= 1}
                                                            className="!w-full !flex !items-center !justify-between"
                                                        >
                                                            Voucher <MdAdd />
                                                        </Button>
                                                        <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                                    </div>
                                                )}
                                            </VoucherModal>
                                        </div>
                                        <p className="capitalize m-0 mt-4">
                                            total price <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            total discount <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            grand total <br />
                                            <span className="underline italic font-semibold m-0 text-gray-700 mt-1">
                                                {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        {detailQuery.data?.account_id === state.user?.account_id && (
                                            <div className="mt-8">
                                                <Checkbox checked={isLastStock} onChange={onChangeCheckBox} className="font-bold ">
                                                    SO Last Stock
                                                </Checkbox>
                                            </div>
                                        )}
                                    </div>
                                </State.Data>
                                <State.Loading state={stateDetail}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: (
                                    <TableEdit
                                        list={allProducts}
                                        setList={setAllProducts}
                                        setProduct={setProducts}
                                        loading={detailQuery.isLoading}
                                        onSetList={onEditProduct}
                                        removeItemList={onRemoveProduct}
                                        action={detailQuery.data?.account_id === state.user?.account_id}
                                        actionAddition={(record: ProductPerformaInv) =>
                                            record.status === "delete" ? (
                                                <Button
                                                    onClick={onUndoRemoveProduct(record)}
                                                    icon={<AiOutlineUndo className="mr-1" />}
                                                    size="small"
                                                    type="primary"
                                                >
                                                    Undo
                                                </Button>
                                            ) : (
                                                ""
                                            )
                                        }
                                    />
                                ),
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default CSalesOrderEdit;

import { Alert, Card, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React from "react";
import { UseQueryResult } from "react-query";
import { DetailHistory, DetailHistoryList } from "services/api-endpoints/dashboard-jsl/purchase-order";

export type Props = ModalProps & {
    idHistory?: number | null;
    option?: number;
    fetcher?: UseQueryResult<DetailHistory, unknown>;
};

const columns: ColumnsType<DetailHistoryList> = [
    {
        width: "50px",
        title: "No",
        dataIndex: "-",
        render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
    },
    {
        title: "Product Name",
        dataIndex: "product_name",
        render: (text) => <p className="capitalize m-0">{text}</p>,
    },
    {
        title: "Product Code",
        dataIndex: "product_code",
        render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
    },
    {
        title: "Product Price",
        dataIndex: "product_price",
        render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
    },
    {
        title: "Quantity",
        dataIndex: "qty",
        render: (text) => <p className="capitalize m-0">{text}</p>,
    },
];

const ModalHistory = ({ idHistory, fetcher, children, ...props }: Props) => {
    const type = (() => {
        if (!fetcher?.data) return "Load...";
        if (fetcher?.data?.do_no) return "Delivery Order";
        if (fetcher?.data?.so_no) return "Sales Order";
        if (fetcher?.data?.po_no) return "Purchase Order";
        return "-";
    })();

    return (
        <ModalTemplate width={800} title={type} {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    <State data={fetcher?.data} isLoading={fetcher?.isLoading} isError={fetcher?.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Card>
                                        <div className="grid grid-cols-3">
                                            <p className="capitalize m-0">
                                                {type} no <br />
                                                <span className="underline italic m-0">
                                                    {fetcher?.data?.do_no || fetcher?.data?.so_no || fetcher?.data?.po_no}
                                                </span>
                                            </p>
                                            <p className="capitalize m-0">
                                                request from <br />
                                                <span className="underline italic m-0">{fetcher?.data?.request_from}</span>
                                            </p>
                                            <p className="capitalize m-0">
                                                request to <br />
                                                <span className="underline italic m-0">{fetcher?.data?.request_to}</span>
                                            </p>
                                        </div>
                                    </Card>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active paragraph={{ rows: 2 }} />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(fetcher?.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                    <Table
                        size="small"
                        loading={fetcher?.isLoading}
                        columns={columns}
                        dataSource={fetcher?.data?.list_detail || []}
                        className="w-full"
                        pagination={undefined}
                    />
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalHistory;

import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import roleService, { Role } from "services/api-endpoints/dashboard/master-data/role";
import { Button, Form, message } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { STATUS_ACTIVE } from "utils/constant";
import { useMutation } from "react-query";

export type RoleModalEditProps = {
    children: (data: HandlerProps) => void;
    refresh?: () => void;
    role?: Role;
};

const schema: yup.SchemaOf<Omit<Role, "id">> = yup.object().shape({
    name: yup.string().required("Name required"),
    is_active: yup.number().required("Is active required"),
});

const RoleModalEdit = ({ children, refresh, role }: RoleModalEditProps) => {
    const closeModalRef = React.useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<Omit<Role, "id">>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            is_active: role?.is_active,
            name: role?.name,
        },
    });

    React.useEffect(() => {
        setValue("name", role?.name as any);
        setValue("is_active", role?.is_active as any);
    }, [role]);

    const createRoleMutate = useMutation(
        async (data: Omit<Role, "id">) => {
            return (await roleService.EditRole({ ...data, id: role?.id as any })).data.data;
        },
        {
            onSuccess() {
                closeModalRef?.current?.click();
                form.resetFields(["name", "is_active"]);
                setValue("name", "");
                setValue("is_active", 0);
                message.success("Role Edit!");
                if (refresh) refresh();
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        createRoleMutate.mutate(data);
    });

    return (
        <ModalTemplate title={`Edit Role ${role?.name}`} handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} disabled={createRoleMutate.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="grid grid-cols-2 gap-x-4">
                            <ControlledInputText control={control} name="name" placeholder="Role name" label="Role Name" />
                            <ControlledSelectInput
                                control={control}
                                name="is_active"
                                options={STATUS_ACTIVE}
                                placeholder="Is Active"
                                label="Is Active"
                            />
                            <Button loading={createRoleMutate.isLoading} htmlType="submit" type="primary">
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default RoleModalEdit;

import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { BasePaginationResponse } from "models";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { PurchasingInventory } from "services/api-endpoints/purchasing/inventory";
import ButtonAccessView from "components/button/button-access-view";
import {
    EDIT_DRAFT_PATH,
    EDIT_PATH,
    FORMAT_DATE_1,
    STATUS_PURCHASING_REQUEST,
    STATUS_PURCHASING_REQUEST_COMPLETED,
    STATUS_PURCHASING_REQUEST_DRAFT,
    STATUS_PURCHASING_REQUEST_NEW,
    STATUS_PURCHASING_REQUEST_STILL_PO,
    STATUS_PURCHASING_REQUEST_VOID,
    VIEW_PATH,
} from "utils/constant";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import { RequestList } from "services/api-endpoints/purchasing/purchasing-request";
import moment from "moment";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const PurchasingRequestTable = <T extends RequestList>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: RequestList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: RequestList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 15 + (i + 1)}</p>,
        },
        {
            width: "170px",
            title: "PR No",
            dataIndex: "pr_no",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            width: "100px",
            title: "Create Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_1) : "-"}</p>,
        },
        {
            title: "Note",
            dataIndex: "note",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "400px",
            title: "List Product",
            dataIndex: "product",
            render: (text, record) => {
                return (
                    <div className="w-full">
                        {record.product?.map((val) => (
                            <span className={val.product_status ? "text-black" : "text-red-500 font-semibold"}>{val.product_code}, </span>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Category",
            dataIndex: "doc_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Request By",
            dataIndex: "request_by",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Doc Status",
            dataIndex: "doc_status",
            render: (text) => {
                const type = STATUS_PURCHASING_REQUEST.find((t) => t.value === text);
                if (!type) return "-";
                if (type.value === STATUS_PURCHASING_REQUEST_VOID) return <Tag color="grey">{type?.label}</Tag>;
                if (type.value === STATUS_PURCHASING_REQUEST_DRAFT) return <Tag color="purple">{type?.label}</Tag>;
                if (type.value === STATUS_PURCHASING_REQUEST_NEW) return <Tag color="red">{type?.label}</Tag>;
                if (type.value === STATUS_PURCHASING_REQUEST_STILL_PO) return <Tag color="cyan">{type?.label}</Tag>;
                if (type.value === STATUS_PURCHASING_REQUEST_COMPLETED) return <Tag color="green">{type?.label}</Tag>;
                return <Tag color="blue">{type?.label}</Tag>;
            },
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.doc_status === STATUS_PURCHASING_REQUEST_VOID ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : null}
                    {record.doc_status === STATUS_PURCHASING_REQUEST_DRAFT ? (
                        <>
                            <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                            <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        </>
                    ) : null}
                    {(record.doc_status || 0) > STATUS_PURCHASING_REQUEST_DRAFT ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : null}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 15,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default PurchasingRequestTable;

import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import paymentService from "services/api-endpoints/dashboard/payment";
import { CHARGE_TYPE, FORMAT_DATE_1 } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterReceipt = ({ filter = [], ...props }: Props) => {
    const accountQuery = useQuery([paymentService.getAccount], async () => {
        const req = await paymentService.GetAccount();
        return req.data.data?.map((el) => ({ label: el.account_name, value: el.account_id } as SelectOption));
    });
    return (
        <FilterModal
            filter={filter}
            title="Filter Receipt"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Document Number"
                            value={dt.values.doc_no}
                            name="doc_no"
                            onChange={(e) => dt.setOneValue("doc_no", e.target.value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.account_id ? Number(dt.values.account_id) : null}
                            placeholder="Cabang Account"
                            options={accountQuery.data}
                            loading={accountQuery.isLoading}
                            onChange={(value) => dt.setOneValue("account_id", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterReceipt;

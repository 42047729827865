/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Skeleton, Space, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonDelete from "components/button/button-delete";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import { columnsFilterDocPayment } from "modules/master-data/payment/add/filter-document2";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalDetailInv from "modules/master-data/payment/modal/modal-detail-inv";
import Print from "modules/master-data/payment/view/print";
import moment from "moment";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { AiFillPrinter } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import { RiFileEditFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import paymentService, { EditParams, FilterPurchasingList } from "services/api-endpoints/dashboard/payment";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import Utils from "utils";
import {
    DOC_TYPE_PURCHASING,
    FORMAT_DATE_1,
    PAYMENT_INTERNAL_ORDER,
    PAYMENT_LOAN_INTERNAL,
    PAYMENT_OTHER,
    PAYMENT_PURCHASING,
    PAYMENT_TYPE,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<EditParams>> = yup.object().shape({
    id: yup.number(),
    transaction_date: yup.string().required("Transaction Date Required"),
    payment_images: yup.array(),
    payment_type: yup.number(),
});

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const PaymentView = ({ paramId, asPreview }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [progress, onUploadProgress] = useProgressUpload();

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");

    const [selectedDocument, setSelectedDocument] = React.useState<React.Key[]>([]);
    const [invoiceList, setInvoiceList] = React.useState<FilterPurchasingList[]>([]);
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);

    const letterRef = useRef<HTMLDivElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<EditParams>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchPaymentType = watch("payment_type");

    const onPType = (type: number | null | undefined) => {
        if (type === PAYMENT_PURCHASING) {
            return PAYMENT_PURCHASING;
        }
        if (type === PAYMENT_LOAN_INTERNAL) {
            return PAYMENT_LOAN_INTERNAL;
        }
        if (type === PAYMENT_INTERNAL_ORDER) {
            return PAYMENT_INTERNAL_ORDER;
        }
        return PAYMENT_OTHER;
    };

    const getVendor = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const detailQuery = useQuery(
        [paymentService.detailPaymentV, id],
        async () => {
            return (await paymentService.DetailPaymentV({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    transaction_date: moment(data.transaction_date),
                });
                setValue("transaction_date", moment(data.transaction_date));
                setValue("payment_type", onPType(data.purchasing_type));
                setSelectedDocument(data.child?.map((itm) => Number(itm.doc_id)) || []);
                setInvoiceList(
                    data.child?.map(
                        (item) =>
                            ({
                                doc_id: item.doc_id,
                                doc_no: item.doc_no,
                                total_tax: item.total_tax,
                                total_disc: item.total_disc,
                                total_price: item.total_price,
                                grand_total: item.grand_total,
                                created_at: item.created_at,
                            } as any)
                    ) || []
                );
                setExpanses(data.expenses || []);
                setUrlImgList((data.payment_images || [])?.filter((url) => url));
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editMutate = useMutation(
        [paymentService.editPaymentV],
        async (data: EditParams) => {
            return (await paymentService.EditPaymentV(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Payment Voucher Edited");
                navigate(-1);
            },
        }
    );

    const bankAccountQuery = useQuery([paymentService.getBA], async () => {
        return (await paymentService.GetBA()).data.data?.map((el) => ({ label: el.bank_account_no, value: el.bank_account_id } as SelectOption));
    });

    const dataSourceFilter = invoiceList?.filter((el) => selectedDocument.includes(el.doc_id));
    const total = dataSourceFilter?.reduce((t, curr) => Number(curr?.grand_total || 0) + t, 0);
    const grandTotal = (total === undefined ? 0 : total) + expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const columnsFilter: ColumnsType<FilterPurchasingList> = [
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <ModalDetailInv title="Invoice Detail" id={record.doc_id} type={watchPaymentType}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalDetailInv>
                );
            },
        },
        ...columnsFilterDocPayment,
    ];

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const handlePreviewExistImage = (url: string) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };

    const onDeleteImageUrl = (image: any) => {
        return (e: any) => {
            e.stopPropagation();
            setUrlImgList((prev) => prev?.filter((url) => url !== image));
        };
    };

    const onEditHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: EditParams = {
            ...data,
            id: id!,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            payment_images: [...(filesBase64 || []), ...urlImgList],
        };

        editMutate.mutate(transformData);
    });

    const onSaveEditHandler = async () => {
        await form.validateFields();
        onEditHandler();
    };

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `BP Sales Order - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} bankAccount={bankAccountQuery.data || []} />
            <ToolbarAction
                title="detail payment voucher"
                rightAddition={() => (
                    <>
                        {!asPreview && (
                            <Space>
                                <Button
                                    onClick={onClickPrintHandler}
                                    type="default"
                                    className="!flex !items-center"
                                    loading={bankAccountQuery.isLoading}
                                >
                                    <AiFillPrinter className="m-0 mr-2" />
                                    Print
                                </Button>
                                <Button loading={editMutate.isLoading} onClick={onSaveEditHandler} type="default" className="!flex !items-center">
                                    <RiFileEditFill className="m-0 mr-2" />
                                    Edit
                                </Button>
                            </Space>
                        )}
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                                <Card className="">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline italic m-0">{detailQuery.data?.doc_no}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Cabang Account <br />
                                            <span className="underline italic m-0">{detailQuery.data?.account_name|| "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Bank Account <br />
                                            <span className="underline italic m-0">
                                                {bankAccountQuery.data?.find((item) => item.value === detailQuery.data?.bank_account_id)?.label}
                                            </span>
                                        </p>
                                        <ControlledInputDate
                                            control={control}
                                            name="transaction_date"
                                            placeholder="Transaction Date"
                                            label="Transaction Date"
                                        />
                                        <p className="capitalize m-0">
                                            Note Voucher <br />
                                            <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                        </p>
                                    </div>
                                </Card>
                                <Card className="">
                                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Purchasing Type <br />
                                            <span className="underline italic m-0">
                                                {PAYMENT_TYPE.find((item) => item.value === watchPaymentType)?.label}
                                            </span>
                                        </p>
                                        <>
                                            {watchPaymentType === PAYMENT_PURCHASING && (
                                                <p className="capitalize m-0">
                                                    Category Purchasing <br />
                                                    <span className="underline italic m-0">
                                                        {DOC_TYPE_PURCHASING.find((item) => item.value === detailQuery.data?.purchasing_type)?.label}
                                                    </span>
                                                </p>
                                            )}
                                            {watchPaymentType === PAYMENT_INTERNAL_ORDER && <div />}
                                        </>
                                        <p className="capitalize m-0">
                                            Invoice Total
                                            <br />
                                            <span className="font-semibold m-0">{total?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                            <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                                        </p>
                                        <>
                                            {watchPaymentType === PAYMENT_PURCHASING ? (
                                                <>
                                                    <p className="capitalize mb-8">
                                                        Vendor Name <br />
                                                        <span className="underline italic m-0">
                                                            {getVendor?.data?.find((item) => item.value === detailQuery.data?.vendor_id)?.label}
                                                        </span>
                                                    </p>
                                                    <div />
                                                    <div />
                                                </>
                                            ) : null}
                                        </>
                                        <div className="col-span-2 flex flex-col gap-5 pr-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                                            <>
                                                {(watchPaymentType === PAYMENT_PURCHASING || watchPaymentType === PAYMENT_INTERNAL_ORDER) && (
                                                    <Table
                                                        scroll={{ x: 800 }}
                                                        rowKey={(rec) => rec.po_id}
                                                        columns={columnsFilter}
                                                        dataSource={dataSourceFilter}
                                                        pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                                        style={{ borderBottom: "1px solid #d9d9d9" }}
                                                        className="pb-2"
                                                    />
                                                )}
                                            </>
                                            {!asPreview && (
                                                <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                    <div>
                                                        <BiImageAdd className="text-2xl" />
                                                        <div style={{ marginTop: 8 }}>Upload</div>
                                                    </div>
                                                </Upload>
                                            )}
                                            <div className="grid grid-cols-3 gap-4">
                                                {urlImgList?.map((url) => (
                                                    <button
                                                        onClick={() => handlePreviewExistImage(url)}
                                                        type="button"
                                                        className="relative border-none bg-transparent"
                                                    >
                                                        {!asPreview && <ButtonDelete onClick={onDeleteImageUrl(url)} />}

                                                        <ImageCard image={url} title="document" />
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <>
                                                {expanses.length !== 0 ? (
                                                    <div>
                                                        <p className="capitalize m-0 p-0">Expenses</p>
                                                        <ListExpenses data={expanses} />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </>
                                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                                            <p className="capitalize m-0">
                                                Receipt Total
                                                <br />
                                                <span className="font-semibold m-0">{grandTotal?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default PaymentView;

import CardMultipleOperatorItem from "modules/manufacture/card/multiple-operator/item";
import CardOperatorItem from "modules/manufacture/card/operator/item";
import { ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes, divideOperator, minusOperator, plusOperator, timesOperator } from "modules/manufacture/utils/constant";
import { Entity, OperatorEntity } from "modules/manufacture/utils/models";
import React from "react";

const ListOperator = () => {
    const { menu } = React.useContext(ManufactureContext) as any;

    const operators: OperatorEntity[] = [
        {
            id: 1,
            text: "Addition",
            icon: plusOperator.id,
            resolver: plusOperator.resolver,
        },
        {
            id: 2,
            text: "Substraction",
            icon: minusOperator.id,
            resolver: minusOperator.resolver,
        },
        {
            id: 3,
            text: "Multiplication",
            icon: timesOperator.id,
            resolver: timesOperator.resolver,
        },
        {
            id: 4,
            text: "Division",
            icon: divideOperator.id,
            resolver: divideOperator.resolver,
        },
    ];

    if (menu !== MenuTypes.operator.id) return null;
    return (
        <>
            {menu === MenuTypes.operator.id ? (
                <div className="flex gap-5">
                    {/* {operators.map((item) => (
                        <CardOperatorItem key={item.id} attach={{ data: item } as Entity<OperatorEntity>}>
                            {item.text}
                        </CardOperatorItem>
                    ))} */}
                    {operators.map((item) => (
                        <CardMultipleOperatorItem key={item.id} attach={{ data: item } as Entity<OperatorEntity>}>
                            {item.text}
                        </CardMultipleOperatorItem>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default ListOperator;

import { CreateDataModelType } from "modules/master-data/model-type/models";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import useProgressUpload from "hooks/useProgressUpload";
import IsActiveSelect from "modules/master-data/model-type/lib/is-active-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import { useMutation } from "react-query";

const schema: yup.SchemaOf<Partial<CreateDataModelType>> = yup.object().shape({
    model_type_name: yup.string().required("Brand name required"),
    model_type_code: yup.string().required("Brand code required"),
    is_active: yup.number().required("Is Active required"),
});

const ModelTypeAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<CreateDataModelType>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [modelTypeService.create],
        async (data: CreateDataModelType) => {
            return (await modelTypeService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Model Type Created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new model type"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <div className="max-w-2xl">
                <Card>
                    <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">Type Model Information</h1>
                        <ControlledInputText
                            label="Model Type Name"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="model_type_name"
                            placeholder="Model Type Name"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <ControlledInputText
                            label="Model Type Code"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="model_type_code"
                            placeholder="Model Type Code"
                            style={{ transform: "translateY(-6px)" }}
                        />
                        <IsActiveSelect
                            label="Is Active"
                            labelCol={{ span: 24 }}
                            control={control}
                            name="is_active"
                            placeholder="Is Active"
                            style={{ transform: "translateY(-6px)" }}
                        />
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default ModelTypeAdd;

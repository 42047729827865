/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ModalJournalDetail from "modules/master-data/purchasing/modal-journal-detail";
import ModalRefDetail from "modules/master-data/purchasing/modal-ref-voucher";
import moment from "moment";
import { UseMutationResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { BukuBesarList, BukuBesarPagination } from "services/api-endpoints/accounting/buku-besar";
import { FORMAT_DATE_TIME, TYPE_JURNAL_UMUM_SALDO_AWAL } from "utils/constant";

type Props<T> = {
    fetcher: UseMutationResult<BukuBesarPagination<T>, unknown, any, unknown>;
    pageChange?: (page: any) => void;
};

const BukuBesarAkunTable = <T extends BukuBesarList>({ fetcher, pageChange }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const page = pagination.current?.toString() || "1";
        if (pageChange) {
            pageChange(page);
            return;
        }
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => (
                <>
                    {record.note === "TOTAL" || record.note === "SALDO AWAL" ? (
                        <p />
                    ) : (
                        <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 100 + (i + 1)}</p>
                    )}
                </>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            render: (text, record) => <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>,
        },
        {
            title: "Account",
            dataIndex: "account",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Coa Name",
            dataIndex: "coa_name",
            render: (text, record) => <p className="capitalize m-0">{`${text} (${record.coa_code})`}</p>,
        },
        {
            title: "Description",
            dataIndex: "note",
            render: (text, record) => {
                if (record.note === "TOTAL" || record.note === "SALDO AWAL" || record.doc_type === TYPE_JURNAL_UMUM_SALDO_AWAL)
                    return <p className="capitalize m-0">{text || "-"}</p>;
                return (
                    <ModalRefDetail title="Detail Referensi" id={record.doc_id} type={record.doc_type}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small" title={text}>
                                {text}
                            </Button>
                        )}
                    </ModalRefDetail>
                );
            },
        },
        {
            title: "Ref",
            dataIndex: "journal_no",
            render: (text, record) => {
                if (record.note === "TOTAL" || record.note === "SALDO AWAL") return <p> </p>;
                return (
                    <ModalJournalDetail title="Detail Jurnal" id={record.id}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small" title={text}>
                                {text}
                            </Button>
                        )}
                    </ModalJournalDetail>
                );
            },
        },
        {
            title: "Debit",
            dataIndex: "debit",
            render: (text, record) => (
                <>
                    {record.note === "TOTAL" || record.note === "SALDO AWAL" ? (
                        <p />
                    ) : (
                        <p className="font-semibold">{text !== 0 ? text?.ToIndCurrency("Rp") : "-"}</p>
                    )}
                </>
            ),
        },
        {
            title: "Kredit",
            dataIndex: "kredit",
            render: (text, record) => (
                <>
                    {record.note === "TOTAL" || record.note === "SALDO AWAL" ? (
                        <p />
                    ) : (
                        <p className="font-semibold">{text !== 0 ? text?.ToIndCurrency("Rp") : "-"}</p>
                    )}
                </>
            ),
        },
        {
            title: "Saldo",
            dataIndex: "saldo",
            render: (text: number, _) => (
                <>
                    {text < 0 ? (
                        <p className="font-semibold">{`${(text * -1)?.ToIndCurrency("Rp")}`} (K)</p>
                    ) : (
                        <p className="font-semibold">{text?.ToIndCurrency("Rp")} (D)</p>
                    )}
                </>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 100,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default BukuBesarAkunTable;

import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import AdvanceSearch from "modules/advance-search";
import { ProductWithQty } from "modules/advance-search/models";
import ModalCustomVoucher from "modules/dashboard-all/sales/add/modal-custom-voucher";
import ModalVoucher from "modules/dashboard-all/sales/add/modal-voucher";
import ProductTable from "modules/dashboard-all/sales/add/product-table";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { CustomVoucher } from "modules/dashboard-all/sales/models";
import BpSelect from "modules/master-data/bp-order/lib/bp-select";
import { FDataCreateBPOrder } from "modules/master-data/bp-order/models";

import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import bpOrderService, { BpOrderDetail, BusinessPartner, CreateBpOrder, DetailProduct } from "services/api-endpoints/dashboard/master-data/bp-order";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";
import salesOrderService from "services/api-endpoints/dashboard-jsl/sales-order";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";

const schema: yup.SchemaOf<Partial<FDataCreateBPOrder>> = yup.object().shape({
    bp_id: yup.number().required("Business partner required"),
    payment_term_id: yup.number().required("Term Of Payment required"),
});

const BpOrderAdd = () => {
    const navigate = useNavigate();

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [allProducts, setAllProducts] = useState<ProductWithQty[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<FDataCreateBPOrder>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchBpId = watch("bp_id");

    const getSummaryPrice = useMutation(
        [bpOrderService.getSummaryPrice],
        async (data: BpOrderDetail) => {
            return (await bpOrderService.GetSummaryPrice(data)).data.data;
        },
        {
            onSuccess: (data) => {
                const prodExist = products.filter((el) => !el.isFree);
                const prodFree = (data?.free_product || []).map(
                    (el) =>
                        ({
                            product_id: el.id,
                            product_name: el.item,
                            product_code: el.product_code,
                            product_price: el.price,
                            qty: el.qty,
                            note: "FREE",
                            hideEditAction: true,
                            hideRemoveAction: true,
                            isFree: true,
                        } as ProductWithQty)
                );
                setAllProducts([...prodExist, ...prodFree]);
            },
        }
    );

    const createMutation = useMutation(
        [bpOrderService.createBpOrder],
        async (data: CreateBpOrder) => {
            return (await bpOrderService.CreateBpOrder(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Order created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [bpOrderService.createBpOrderDraft],
        async (data: CreateBpOrder) => {
            return (await bpOrderService.CreateBpOrderDraft(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Order draft created");
                navigate(-1);
            },
        }
    );

    const topQuery = useQuery([salesOrderService.paymentTerm, watchBpId], async () => {
        return (await salesOrderService.PaymentTerm()).data.data?.map(
            (el) => ({ label: el.payment_term_code, value: el.payment_term_id } as SelectOption)
        );
    });

    const getBP = useQuery([bpOrderService.getBusinessPartner], async () => {
        const req = await bpOrderService.GetBusinessPartner({ status: 0 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption & BusinessPartner));
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("submit");
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("draft");
    };

    const parseData: BpOrderDetail = {
        detail_product: [
            ...(products?.map((p) => ({ price: p.product_price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)) ||
                []),
        ],
        voucher_id: [...(vouchers?.map((v) => v.voucher_id) || [])],
        voucher: [
            ...(customVouchers?.map((cv) => ({ name: cv.name, price: cv.price || null, percentage: cv.percentage || null } as CustomVoucher)) || []),
        ],
    };

    const onSubmitHandler = handleSubmit((data) => {
        setTimeout(() => {
            const createBpOrder: CreateBpOrder = {
                ...data,
                ...parseData,
            };
            if (typeSave === "submit") createMutation.mutate(createBpOrder);
            else createDraftMutation.mutate(createBpOrder);
        }, 300);
    });

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onSetProduct = (list: any[], prevRow: any) => {
        const prodList = list.filter((el) => !el.isFree);
        setProducts([...prodList]);
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    const onRemoveCustomVoucher = (index: number) => {
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    useEffect(() => {
        if (!products.length) return;
        getSummaryPrice.mutate(parseData);
    }, [products, vouchers, customVouchers]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new BP Order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDraftMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                        sales order no <br />
                                        <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                    </p>
                                    <p className="capitalize m-0 text-gray-700 mt-10">
                                        total price <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                        status <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">New</span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <ControlledSelectInput
                                        name="bp_id"
                                        label="Partner Name"
                                        placeholder="Partner Name"
                                        control={control}
                                        showSearch
                                        loading={getBP.isLoading}
                                        options={getBP.data || []}
                                        onChange={(val) => {
                                            const tempID = getBP.data?.find((val2) => val === val2.bp_id)?.payment_term_id;
                                            setValue("bp_id", val);
                                            setValue("payment_term_id", Number(tempID || 0));
                                        }}
                                    />
                                    <p className="capitalize m-0 text-gray-700">
                                        total discount <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <ControlledSelectInput
                                        name="payment_term_id"
                                        label="Term Of Payment"
                                        placeholder="Term Of Payment"
                                        control={control}
                                        loading={topQuery.isLoading}
                                        options={topQuery.data || []}
                                    />
                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                        grand total <br />
                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                            {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                        </span>
                                    </p>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <p className="capitalize m-0 text-gray-700 mb-2">Voucher</p>
                                    <ModalVoucher vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                        {(dt) => (
                                            <div className="w-full flex flex-col gap-1">
                                                <Button
                                                    onClick={dt.openModal}
                                                    disabled={vouchers.length === 2}
                                                    className="!w-full !flex !items-center !justify-between"
                                                >
                                                    Voucher <MdAdd />
                                                </Button>
                                                <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                            </div>
                                        )}
                                    </ModalVoucher>
                                    {/* <p className="capitalize m-0 text-gray-700 mt-4 mb-2">Voucher Custom</p>
                                    <ModalCustomVoucher onCreate={onCustomVoucherCreate}>
                                        {(dt) => (
                                            <div className="w-full flex flex-col gap-1">
                                                <Button
                                                    onClick={dt.openModal}
                                                    disabled={customVouchers.length === 2}
                                                    className="!w-full !flex !items-center !justify-between"
                                                >
                                                    Custom Voucher <MdAdd />
                                                </Button>
                                                <ListCustomVoucher vouchers={customVouchers} onRemoveVoucher={onRemoveCustomVoucher} />
                                            </div>
                                        )}
                                    </ModalCustomVoucher> */}
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                        </div>
                    </div>
                </Form>
            </Card>
            {getSummaryPrice.isError ? <Alert type="error" message={(getSummaryPrice.error as any)?.message} /> : null}
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={allProducts} setList={setAllProducts} removeItemList={onRemoveProduct} onSetList={onSetProduct} />
        </div>
    );
};

export default BpOrderAdd;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterTask from "modules/dashboard-all/team-force/filter";
import TaskTable from "modules/dashboard-all/team-force/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import taskService from "services/api-endpoints/dashboard-all/team-force/task";
import purchasingRequestService, { FilterRequest, RequestList } from "services/api-endpoints/purchasing/purchasing-request";
import { DOC_TYPE_INVENTORY } from "utils/constant";

const filter = ["start_date", "end_date", "docType"];

const TeamForceTask = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([taskService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await taskService.GetAll(filterValue as FilterRequest)).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterTask filter={filter}>
                {(dt) => <Toolbar add={false} placeholder="Task ..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterTask>
            <TaskTable fetcher={getList} />
        </div>
    );
};

export default TeamForceTask;

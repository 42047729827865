/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import docInventoryService from "services/api-endpoints/dashboard/master-data/doc-inventory";
import { LocatorData, ProductDocInventory } from "../models";

type SelectOptionCustom = SelectOption & LocatorData;

export interface Props extends ControlledInputSelectProps<ProductDocInventory> {
    onSelectedData?: (id: number, data: SelectOptionCustom[]) => void;
    warehouseId?: number;
}

export function withLocatorFrom<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const productID = props.control._getWatch("product_id");
        const locator = useQuery([docInventoryService.locator, productID], async () => {
            const req = await docInventoryService.Locator({ product_id: productID, warehouse_id: props.warehouseId });
            return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id || "" } as SelectOptionCustom));
        });
        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(id, locator.data || []);
            }
        };
        return (
            <Component
                {...props}
                onSelect={onSelect}
                onDeselect={onSelect}
                optionFilterProp="children"
                loading={locator.isLoading}
                options={locator.data || []}
            />
        );
    };
}

const LocatorFromSelect = withLocatorFrom(ControlledSelectInput);

export default LocatorFromSelect;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Checkbox, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BaseJurnalResponse, JurnalUmum, Type } from "services/api-endpoints/accounting/jurnal-umum";
import { EDIT_PATH, FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";
import React, { useEffect, useState } from "react";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";

type Props<T> = {
    fetcher: UseQueryResult<BaseJurnalResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const DraftedTable = <T extends JurnalUmum>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const editHandler = (data: JurnalUmum) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            className: "h-[1px]",
            render: (text, record, i) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{i + 1}</p>
                </div>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>
                </div>
            ),
        },
        {
            title: "Account",
            dataIndex: "account",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0 font-semibold">{text || "-"}</p>
                </div>
            ),
        },
        {
            title: "Ref ID",
            dataIndex: "doc_no",
            className: "h-[1px]",
            render: (text, record) => {
                return (
                    <div className="w-full h-full flex items-start">
                        <ModalRefVoucher title="Detail Referensi" id={record?.doc_id} type={record.doc_type}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalRefVoucher>
                    </div>
                );
            },
        },
        {
            title: "Account Name",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            // <p className="m-0 mb-2 font-semibold">{item?.name?.toUpperCase()}</p>
                            <p className="m-0 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        )}
                    </div>
                ));
            },
        },
        {
            title: "Debit",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
        {
            className: "h-[1px]",
            title: "Kredit",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Kredit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
        {
            width: "90px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <>
                        <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                        <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                    </>
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list_draft || []}
            className="w-full"
            pagination={{
                pageSize: 20,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default DraftedTable;

/* eslint-disable no-plusplus */
import { Button, Card, Tag } from "antd";
import Search from "antd/lib/transfer/search";
import Tree, { DataNode } from "antd/lib/tree";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import masterCoaService, { Coa } from "services/api-endpoints/accounting/master-coa";
import { useSearchParams } from "react-router-dom";
import ButtonAccessAdd from "components/button/button-access-add";
import { StateContext } from "context/state";
import MasterCoaEdit from "./edit";
import MasterCoaAdd from "./add";
import MasterCoaView from "./view";

const MasterCoa = () => {
    const { updateAccess, readAccess } = useContext(StateContext);
    // const updateAccess = "U";
    const [searchParams, setSearchParams] = useSearchParams();
    const detailId = searchParams.get("id");

    const getAll = useQuery([masterCoaService.getAll], async () => {
        return (await masterCoaService.GetAll()).data.data;
    });

    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const height80Percent = 0.7 * windowHeight;

    const [heightWindowTree] = useState(height80Percent);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [addMode, setAddMode] = useState(false);

    const onExpand = (newExpandedKeys: React.Key[]) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const flattenArray = useMemo(() => {
        const dataList: { key: React.Key; title: string }[] = [];
        const generateList = (data: Coa[]) => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { id } = node;
                dataList.push({ key: id, title: `${node.account} - ${node.name_in} - ${node.type_name}` });
                if (node.children) {
                    generateList(node.children);
                }
            }
        };
        generateList(getAll.data?.list || []);
        return dataList;
    }, [getAll.data?.list]);

    const getParentKey = (key: React.Key, tree: Coa[]): React.Key => {
        let parentKey: React.Key;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.id === key)) {
                    parentKey = node.id;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey!;
    };

    const treeData = useMemo(() => {
        if (getAll.isLoading) return [];
        const loop = (data: Coa[]): DataNode[] =>
            data.map((item) => {
                const strTitle = `${item.account} - ${item.name_in} ( ${item.type_name} )`;
                const index = strTitle?.toLowerCase().indexOf(searchValue?.toString()?.toLowerCase());
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchValue.length);

                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="site-tree-search-value">{searchValue}</span>
                            {afterStr}
                            {(detailId as any)?.toString() === item.id?.toString() ? (
                                <Tag color={updateAccess ? "red" : "blue"} className="!ml-4">
                                    {updateAccess ? "Edit" : "View"}
                                </Tag>
                            ) : (
                                ""
                            )}
                        </span>
                    ) : (
                        <span>
                            {strTitle}{" "}
                            {(detailId as any)?.toString() === item.id?.toString() ? (
                                <Tag color={updateAccess ? "red" : "blue"} className="!ml-4">
                                    {updateAccess ? "Edit" : "View"}
                                </Tag>
                            ) : (
                                ""
                            )}
                        </span>
                    );

                if (item?.children) {
                    return {
                        title: <span className="font-bold">{title}</span>,
                        key: item.id,
                        children: loop(item?.children || []),
                    };
                }

                return {
                    title,
                    key: item.id,
                };
            });

        return loop(getAll.data?.list || []);
    }, [searchValue, getAll.data?.list, detailId]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const newExpandedKeys = flattenArray
            .map((item) => {
                if (item.title?.toLowerCase()?.indexOf(value?.toString()?.toLowerCase()) > -1) {
                    return getParentKey(item.key, getAll.data?.list || []);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        setExpandedKeys(newExpandedKeys as React.Key[]);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    const onSelect = (val: any) => {
        if (!val?.length) return;
        setAddMode(false);
        searchParams.set("id", val ? val[0] : "");
        setSearchParams(searchParams);
    };

    const onSuccessEdit = () => {
        searchParams.delete("id");
        setSearchParams(searchParams);
        getAll.refetch();
    };

    const onSuccessAdd = () => {
        setAddMode(false);
        getAll.refetch();
    };

    const onClickAddHandler = () => {
        setAddMode(true);
        searchParams.delete("id");
        setSearchParams(searchParams);
    };

    const onCloseAdd = () => {
        setAddMode(false);
    };

    const onCloseEdit = () => {
        searchParams.delete("id");
        setSearchParams(searchParams);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Card>
                <div className="flex gap-5">
                    <div className="w-full flex flex-col gap-5">
                        <div className="w-full flex gap-5">
                            <Search placeholder="Search" onChange={onChange as any} />
                            <ButtonAccessAdd onClick={onClickAddHandler} type="primary" className="!flex !items-center !font-semibold" />
                        </div>
                        <Tree
                            onSelect={onSelect}
                            height={heightWindowTree}
                            showLine
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            treeData={treeData}
                            defaultExpandAll={!!detailId}
                        />
                    </div>
                    {detailId && updateAccess ? (
                        <MasterCoaEdit onClose={onCloseEdit} onSaveSuccess={onSuccessEdit} id={detailId} />
                    ) : (
                        <>{detailId && readAccess ? <MasterCoaView onClose={onCloseEdit} id={detailId} /> : null}</>
                    )}
                    {addMode && <MasterCoaAdd onClose={onCloseAdd} onSaveSuccess={onSuccessAdd} />}
                </div>
            </Card>
        </div>
    );
};

export default MasterCoa;

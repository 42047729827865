/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ChildPK, DetailChildPK, SuppliesPK } from "services/api-endpoints/dashboard/master-data/pemakaian-barang";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends DetailChildPK>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Supplies Name",
                dataIndex: "supplies_name",
                render: (text, record) => <p className="capitalize m-0">{record.supplies_name || "-"}</p>,
            },
            {
                title: "UOM",
                dataIndex: "uom_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Qty",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                render: (text) => <p className="m-0 text-center ">{text === 0 ? "0" : text || "-"}</p>,
            },
        ];

        const isEditing = (record: ChildPK, edited: ChildPK | null) => record?.supplies_id === edited?.supplies_id;
        const findIndexSave = (record: ChildPK, edited: ChildPK) => record?.supplies_id === edited?.supplies_id;
        const rowKey = (record: ChildPK) => record?.supplies_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const PemakaianTableView = tableEdit(EditTable);
export default PemakaianTableView;

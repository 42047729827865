/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Seasonal {
    id?: number;
    title?: string;
    subtitle?: string;
    start_date?: Date;
    end_date?: Date;
    created_by?: string;
    created_at?: number;
}

export interface SeasonalDetail {
    seasonal_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
}

export interface DetailSeasonal {
    id?: number;
    title?: string;
    subtitle?: string;
    start_date?: Date;
    end_date?: Date;
    seasonal_detail?: SeasonalDetail[];
}

export interface CreateSeasonal {
    title?: string;
    subtitle?: string;
    start_date?: string;
    end_date?: string;
    seasonal_detail?: { product_id: any }[];
}

export interface EditSeasonal extends CreateSeasonal {
    id?: number;
}

export interface Filter {
    title: string;
    subtitle: string;
    start_date: string;
    end_date: string;
    page: any;
}

class SeasonalProductService extends BaseService {
    getAll = "/seasonal/get-seasonal-product";

    detail = "/seasonal/detail-seasonal-product";

    create = "/seasonal/create-seasonal-product";

    edit = "/seasonal/edit-seasonal-product";

    search = "/seasonal/search-seasonal-product";

    filter = "/seasonal/filter-seasonal-product";

    constructor() {
        super();
    }

    Filter<T extends Seasonal>(params: Filter) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    Search<T extends Seasonal>(params: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditSeasonal) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateSeasonal) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetAll<T extends Seasonal>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends DetailSeasonal>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${params.id}`,
            });
            return req;
        });
    }
}

const seasonalProductService = new SeasonalProductService();
export default seasonalProductService;

/* eslint-disable react/no-array-index-key */
import { forwardRef } from "react";
import JSLPng from "assets/images/jsl-big-trans.png";
import QRCode from "react-qr-code";
import { DetailListKoli } from "services/api-endpoints/dashboard/doc-container";

type MultipleKoliPrintProps = {
    detailListKoli?: DetailListKoli;
};

const MultipleKoliPrint = forwardRef<HTMLDivElement, MultipleKoliPrintProps>(({ detailListKoli }, ref) => {
    return (
        <div ref={ref} className="break-before-all hidden print:flex flex-col">
            {detailListKoli?.list_product?.map((product, i) => {
                const qrString = `${detailListKoli?.do_no}_${product?.no_koli}`;
                return (
                    <div key={i} className="flex w-[8cm] h-[5cm] p-2 px-4 flex-col gap-2 bg-white items-center relative overflow-hidden">
                        <img src={JSLPng} alt="jsl" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[50%] z-0" />
                        <div className="h-[3.3cm] w-[1px] bg-black absolute bottom-1 left-1/2 transform -translate-x-1/2" />
                        <div className="flex items-center gap-3 relative z-20">
                            <QRCode size={40} value={qrString || ""} />
                            <div className="flex flex-col">
                                <span className="font-medium m-0">{product?.no_koli}</span>
                                <span className="font-medium m-0">1 Item</span>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 relative w-full z-20">
                            <div className="m-0 leading-[0.96] font-medium text-2xs whitespace-pre w-full flex">
                                <span>{product?.product_code}</span>
                                <span className="flex-1 text-end">#{product.qty}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
});

export default MultipleKoliPrint;

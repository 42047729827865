import { Icon } from "@mui/material";
import { Alert, Skeleton, StepProps, Steps } from "antd";
import State from "components/common/state";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import { FORMAT_DATE_TIME } from "utils/constant";

const Versioning = () => {
    const [params] = useSearchParams();
    const id = params.get("id");

    const getHistory = useQuery([orderSalesService.getVersioning, id], async () => (await orderSalesService.GetVersioning({ id })).data.data, {
        enabled: !!id,
    });

    const versioning = React.useCallback(
        () =>
            getHistory.data?.map(
                (version) =>
                    ({
                        status: "finish",
                        icon: <Icon fontSize="medium">{version.icon || "beenhereicon"}</Icon>,
                        title: <span className="font-semibold">{version.name || "-"}</span>,
                        description: (
                            <div className="">
                                {version.list?.map((step) => (
                                    <div className="flex items-center mb-2">
                                        {step.note && <p className="m-0 font-medium">{step.note}</p>}
                                        <p className="m-0 capitalize">
                                            {step.created_by ? `, ${step.created_by} - ` : ""}
                                            {moment(step.created_at).format(FORMAT_DATE_TIME)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ),
                    } as StepProps)
            ),
        [getHistory.data]
    );

    return (
        <State data={getHistory.data} isLoading={getHistory.isLoading} isError={getHistory.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        <Steps direction="vertical" size="default" items={versioning() || []} />
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(getHistory.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default Versioning;

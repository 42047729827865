/* eslint-disable no-param-reassign */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import TableAdd from "modules/user-magement/user-role-menu/table-add";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import userManagementService, { UserRoleMenu } from "services/api-endpoints/dashboard-all/user-management/user";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<UserRoleMenu, "id">> = yup.object().shape({
    user_id: yup.number().required("User Required"),
    rm_id: yup.array().required("User Menu Required"),
    // is_active: yup.number().required("Is Active Required"),
});

const UserRoleMenuAdd = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const { handleSubmit, control, watch } = useForm<UserRoleMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchRoleMenu = watch("rm_id");

    const createMutation = useMutation(
        [userManagementService.createUserRoleMenu],
        async (data: UserRoleMenu) => {
            return (await userManagementService.CreateUserRoleMenu(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("User Role Menu Created");
                navigate(-1);
            },
        }
    );

    const getUser = useQuery([userManagementService.getUser], async () => {
        return (await userManagementService.GetUser()).data.data?.map((el) => ({ label: el.email, value: el.id } as SelectOption));
    });

    const getRoleMenu = useQuery([userManagementService.listRoleMenu], async () => {
        return (await userManagementService.ListRoleMenu()).data.data?.map((el) => ({ label: el.description, value: el.id } as SelectOption));
    });

    const getRoleMenuDetail = useMutation([userManagementService.detailGetRoleMenu], async (dataId: any) => {
        return (await userManagementService.DetailGetRoleMenu({ id: dataId })).data.data;
    });

    const onSubmitHandler = handleSubmit(async (data) => {
        createMutation.mutate(data);
    });

    React.useEffect(() => {
        getRoleMenuDetail.mutate((watchRoleMenu || []).join(","));
    }, [watchRoleMenu]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new user role menu"
                rightAddition={() => (
                    <>
                        <Button loading={createMutation.isLoading} onClick={onSubmitHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-4 gap-4">
                        <ControlledSelectInput
                            classNameForm="col-span-1"
                            control={control}
                            options={getUser.data}
                            name="user_id"
                            placeholder="User"
                            label="User"
                            showSearch
                        />
                        <ControlledSelectInput
                            classNameForm="col-span-2"
                            control={control}
                            options={getRoleMenu.data}
                            name="rm_id"
                            placeholder="Role Menu"
                            label="Role Menu"
                            showSearch
                            mode="multiple"
                        />
                        {/* <ControlledSelectInput control={control} name="is_active" options={STATUS_ACTIVE} placeholder="Is Active" label="Is Active" /> */}
                    </div>
                </Form>
            </Card>
            <TableAdd fetcher={getRoleMenuDetail} />
        </div>
    );
};

export default UserRoleMenuAdd;

import React, { useContext } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation } from "react-query";
import masterCoaService, { CreateCoa } from "services/api-endpoints/accounting/master-coa";
import IsActiveSelect from "modules/accounting/master-coa/lib/is-active-select";
import ParentSelect from "modules/accounting/master-coa/lib/parent-select";
import CurrencySelect from "modules/accounting/master-coa/lib/currency-select";
import AccTypeSelect from "modules/accounting/master-coa/lib/acc-type-select";
import { StateContext } from "context/state";

const schema: yup.SchemaOf<Partial<CreateCoa>> = yup.object().shape({
    code: yup.string().required("Code required"),
    name_in: yup.string().required("Name IN required"),
    name_en: yup.string().required("Name EN required"),
    parent_id: yup.number(),
    type_id: yup.number().required("Type required"),
    currency_id: yup.number().required("Currency required"),
    is_active: yup.number().required("Status required"),
    current_balance: yup.string().notRequired(),
});

const MasterCoaAdd = ({ onSaveSuccess, onClose }: { onSaveSuccess: () => void; onClose: () => void }) => {
    const { updateAccess } = useContext(StateContext);
    // const updateAccess = "U";
    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreateCoa>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [masterCoaService.createCoa],
        async (data: CreateCoa) => {
            return (await masterCoaService.CreateCoa(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("COA Created");
                onSaveSuccess();
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate({ ...data, current_balance: (data.current_balance || "0").replaceAll(".", "") });
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new COA"
                rightAddition={() => (
                    <Space>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                        <Button danger onClick={onClose}>
                            Close
                        </Button>
                    </Space>
                )}
            />
            <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler} className="grid grid-cols-2 gap-x-5">
                <ControlledInputText allowClear control={control} name="code" placeholder="Code" label="Code" />
                <ControlledInputText allowClear control={control} name="name_in" placeholder="Name ID" label="Name ID" />
                <ControlledInputText allowClear control={control} name="name_en" placeholder="Name EN" label="Name EN" />
                <ParentSelect allowClear control={control} name="parent_id" placeholder="Parent" label="Parent" />
                <CurrencySelect allowClear control={control} name="currency_id" placeholder="Currency" label="Currency" />
                <IsActiveSelect allowClear control={control} name="is_active" placeholder="Is Active" label="Is Active" />
                <AccTypeSelect allowClear control={control} name="type_id" placeholder="Acc Type" label="Acc Type" />
                {updateAccess ? (
                    <ControlledInputText
                        allowClear
                        control={control}
                        name="current_balance"
                        placeholder="Beginning Balance"
                        label="Beginning Balance"
                        type="number"
                    />
                ) : null}
            </Form>
        </div>
    );
};

export default MasterCoaAdd;

import ModalNote from "components/modal/note-modal";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import TransferStockTable from "modules/master-data/transfer-stock/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { HandlerProps as ModalTemplateHandlerProps } from "components/modal/template-modal";
import transferStockService, { DeleteTS, TransferStockModel } from "services/api-endpoints/dashboard/master-data/transfer-stock";
import React from "react";
import useConfirmDelete from "hooks/useConfirmDelete";

const TransferStock = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const materialMovementDelete = React.useRef<TransferStockModel | null>(null);

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getAll = useQuery([transferStockService.list, page, searchValue, isSearch], async () => {
        return (await transferStockService.List({ page: Number(page), query: searchValue.query || "" })).data.data; // all
    });

    const deleteMutation = useMutation(async (data: DeleteTS) => (await transferStockService.Delete(data)).data.data, {
        onSuccess: () => getAll.refetch(),
    });

    const [showConfirm] = useConfirmDelete<DeleteTS>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    const onSubmitNote = (note: string) => {
        if (!materialMovementDelete.current?.id) return;
        showConfirm({ note, id: materialMovementDelete.current?.id });
    };

    const onClickDelete = (handler: ModalTemplateHandlerProps) => {
        return (data: TransferStockModel) => {
            handler.openModal();
            materialMovementDelete.current = data;
        };
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar filter={false} placeholder="Search..." />
            <ModalNote title="Delete Notes" onSubmit={onSubmitNote}>
                {(handler) => <TransferStockTable fetcher={getAll} onClickDelete={onClickDelete(handler)} />}
            </ModalNote>
        </div>
    );
};

export default TransferStock;

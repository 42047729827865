/* eslint-disable no-promise-executor-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import { Button, Select, message } from "antd";
import { RowDefault } from "components/table/edit-table";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import AssemblingModalAdd from "modules/assembling/modal-add";
import AddAssemblingTable from "modules/assembling/table";
import React from "react";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import assemblingService, { CreateBOM, ProductCodeAssembling } from "services/api-endpoints/dashboard/manufacture/assembling";

export type AssemblingExample = RowDefault<any> & {
    id?: any;
    product_code?: any;
    product_name?: any;
    qty?: any;
    logic?: { logic_name?: string };
    actionRow?: any;
    child?: AssemblingExample[];
};

const AssemblingAdd = () => {
    const navigate = useNavigate();
    const [selectedCode, setSelectedCode] = React.useState<number>();
    const [list, setList] = React.useState<AssemblingExample[]>([]);
    const [selectChildItem, setChildItem] = React.useState<ProductCodeAssembling | null>(null);

    const getProductCode = useQuery([assemblingService.getProductCode], async () => {
        return (await assemblingService.GetProductCode({ type: "" })).data.data;
    });

    const codeOptions = getProductCode.data?.map((el) => ({ label: el.product_code, value: el.id } as SelectOption));

    const createMutation = useMutation([assemblingService.create], async (data: CreateBOM) => {
        return (await assemblingService.Create(data)).data.data;
    });

    const onSubmitList = (ls: AssemblingExample[], asChild: boolean) => {
        setList((prev) => {
            if (asChild) {
                return [
                    ...prev,
                    {
                        id: new Date().getTime(),
                        logic: { logic_name: "OR Logic" },
                        hideEditAction: true,
                        child: ls,
                    },
                ];
            }
            const duplicateProducts = ls.filter((el) => prev.find((prevEl) => prevEl.id === el.id));
            const merge = [...prev, ...ls.filter((el) => !duplicateProducts.find((dupEl) => dupEl.id === el.id))];
            return merge.map((el) => {
                const dupProduct = duplicateProducts.find((dupEl) => dupEl.id === el.id);
                if (!dupProduct) return el;
                return {
                    ...el,
                    qty: el.qty + dupProduct.qty,
                };
            });
        });
    };

    const onChangeSelect = (id: number) => {
        setChildItem(getProductCode.data?.find((el) => el.id === id) || null);
    };

    const onAddChild = (row: AssemblingExample) => {
        if (!selectChildItem) return;
        const alreadyInChild = !!row.child?.find((el) => el.id === selectChildItem?.id);
        const alreadyInList = !!list.find((el) => el.id === selectChildItem?.id);
        if (alreadyInChild || alreadyInList) {
            message.error("Product already in list!");
            return;
        }
        setList((prev) => {
            return prev.map((el) => {
                if (row.id !== el.id) return el;
                return {
                    ...el,
                    child: [...el.child!, { ...selectChildItem, qty: 1 }],
                };
            });
        });
        setChildItem(null);
    };

    const onRemoveList = (row: AssemblingExample) => {
        setList((prev) => prev.filter((el) => el.id !== row.id));
    };

    const onRemoveListChild = (record: AssemblingExample) => {
        return (row: AssemblingExample) => {
            if (record.child?.length === 1 && row.id === record.child[0].id) {
                setList((prev) => prev.filter((el) => el.id !== record.id));
                return;
            }
            setList((prev) =>
                prev.map((el) => {
                    if (el.id !== record.id) return el;
                    return {
                        ...el,
                        child: el.child?.filter((cld) => cld.id !== row.id),
                    };
                })
            );
        };
    };

    const onSetListChild = (record: AssemblingExample) => {
        return (ls: AssemblingExample[]) => {
            setList((prev) =>
                prev.map((el) => {
                    if (el.id !== record.id) return el;
                    return {
                        ...el,
                        child: ls,
                    };
                })
            );
        };
    };

    const onCreate = () => {
        const parse: CreateBOM = {
            id: selectedCode,
            bom: list.map((el) => {
                if (el.logic) {
                    return {
                        child: el.child?.map((cl) => {
                            return {
                                id: cl.id,
                                qty: cl.qty,
                            };
                        }),
                    };
                }
                return {
                    id: el.id,
                    qty: el.qty,
                };
            }) as any,
        };
        if (!parse.id) {
            message.error("Code required!");
            return;
        }
        createMutation.mutateAsync(parse).then(() => {
            message.success("Create Assembling Success");
            navigate(-1);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new item supplies"
                rightAddition={() => (
                    <>
                        <Button onClick={onCreate} disabled={!list.length} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <ToolbarAction
                title="Code :"
                leftAddition={() => (
                    <Select
                        loading={getProductCode.isLoading}
                        className="w-[300px]"
                        size="middle"
                        placeholder="Code"
                        optionFilterProp="label"
                        allowClear
                        options={codeOptions || []}
                        showSearch
                        value={selectedCode}
                        onChange={(val) => setSelectedCode(val)}
                        status={!selectedCode ? "error" : undefined}
                    />
                )}
                rightAddition={() => (
                    <AssemblingModalAdd canAsChild onSubmitList={onSubmitList}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} type="primary" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Add
                            </Button>
                        )}
                    </AssemblingModalAdd>
                )}
            />
            <AddAssemblingTable
                removeItemList={onRemoveList}
                expandable={{
                    expandedRowRender: (rec: AssemblingExample) => {
                        return (
                            <div className="flex flex-row pl-12 gap-10 items-stretch opacity-80">
                                <div className="h-auto w-[5px] bg-blue-400 rounded" />
                                <div className="flex gap-3 flex-col flex-1">
                                    <div className="flex items-center gap-7 font-semibold">
                                        Code :
                                        <Select
                                            value={selectChildItem?.id}
                                            onChange={onChangeSelect}
                                            loading={getProductCode.isLoading}
                                            className="w-[300px]"
                                            size="middle"
                                            placeholder="Code"
                                            allowClear
                                            options={codeOptions || []}
                                            showSearch
                                        />
                                        <Button onClick={() => onAddChild(rec)} type="primary" className="!flex !items-center">
                                            Add Item
                                        </Button>
                                    </div>
                                    <AddAssemblingTable
                                        removeItemList={onRemoveListChild(rec)}
                                        footer={() => <p className="text-red-400">In {rec.logic?.logic_name} you can only choose one item *</p>}
                                        list={rec?.child || []}
                                        setList={onSetListChild(rec) as any}
                                        pagination={false}
                                        className="flex-1"
                                    />
                                </div>
                            </div>
                        );
                    },
                    rowExpandable: (rec: AssemblingExample) => !!rec.logic,
                }}
                list={list}
                setList={setList}
            />
        </div>
    );
};

export default AssemblingAdd;

/* eslint-disable no-shadow */
import { UseQueryResult, useQuery } from "react-query";
import orderService, { Order, OrderProduct } from "services/api-endpoints/dashboard/master-data/order";
import TableProduct from "./table/table-product";
import TableTechnician from "./table/table-technician";
import TableProductWaste from "./table/tabke-product-waste";
import State from "components/common/state";
import { Skeleton } from "antd";
import { STATUS_WO } from "utils/constant";

type Props<T> = {
    paramId?: any;
    asPreview?: boolean;
};

const DetailWO = <T extends OrderProduct>({ paramId, asPreview }: Props<T>) => {
    const detailWOQuery = useQuery([orderService.detailWO, paramId], async () => (await orderService.DetailWO({ id: paramId })).data.data, {
        enabled: !!paramId,
    });

    return (
        <>
            <State data={detailWOQuery.data} isLoading={detailWOQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <div className="w-full flex flex-col gap-2">
                                <p className="m-0 font-bold">Data Customer</p>
                                <div className="grid grid-cols-2 mb-5">
                                    <div className="m-0 capitalize flex w-fit text-xs">
                                        <p className="!w-[100px] mb-1">WO No</p>
                                        <span>: {detailWOQuery.data?.wo_no}</span>
                                    </div>
                                    <div className="m-0 capitalize flex w-fit text-xs">
                                        <p className="!w-[100px] mb-1">Nama Customer</p>
                                        <span>: {detailWOQuery.data?.cust_name}</span>
                                    </div>
                                    <div className="m-0 capitalize flex w-fit text-xs">
                                        <p className="!w-[100px] mb-1">Status WO</p>
                                        <span>: {detailWOQuery.data?.status}</span>
                                    </div>
                                    <div className="m-0 capitalize flex w-fit text-xs">
                                        <p className="!w-[100px] mb-1">Telp Customer</p>
                                        <span>: {detailWOQuery.data?.phone_number}</span>
                                    </div>
                                    <div />
                                    <div className="m-0 capitalize flex w-fit text-xs">
                                        <p className="!w-[100px] mb-1">Alamat</p>
                                        <span>: {detailWOQuery.data?.address}</span>
                                    </div>
                                </div>
                                <p className="m-0 font-bold">Product Cut Stock</p>
                                <TableProduct list={detailWOQuery.data?.list_product || []} action={false} />
                                <p className="m-0 font-bold">Technician</p>
                                <TableTechnician list={detailWOQuery.data?.teknisi || []} action={false} />
                                {detailWOQuery.data?.waste_product?.length !== 0 && (
                                    <>
                                        <p className="m-0 font-bold">Waste Product</p>
                                        <TableProductWaste list={detailWOQuery.data?.waste_product || []} action={false} />
                                    </>
                                )}
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </>
    );
};

export default DetailWO;

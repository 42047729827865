/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory, FilterSalesOrder } from "modules/dashboard-jsl/sales-order/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ProductSO {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
    qty_request?: number;
    po_id?: number;
}
export interface DetailSO {
    order_id?: number;
    order_status?: number;
    so_number?: string;
    request_from?: string;
    request_from_id?: number;
    request_to?: string;
    payment_term_code?: string;
    payment_term_id?: number;
    child_document?: number[];
    list_detail?: ProductSO[];
}

export interface List {
    id: number;
    no: string;
}
export interface HistorySO {
    id?: number;
    notes?: string;
    list_do?: List[];
    list_so?: List[];
    list_po?: List[];
    created_by?: string;
    created_at?: number;
}

export interface Account {
    account_id?: number;
    name?: string;
}

export interface ListPO {
    po_id?: number;
    po_no?: string;
    total_sku?: number;
    total_item?: number;
    date?: string;
}

export interface ProductPO {
    po_id?: number;
    po_no?: string;
    product_id?: number;
    product_name?: string;
    code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
    qty_request?: number;
}
export interface CreateDetailProduct {
    po_id?: number;
    product_id?: number;
    qty?: number;
    price?: number;
}

export interface EditDetailProduct {
    order_detail_id: number;
    po_id?: number;
    product_id?: number;
    qty?: number;
    price?: number;
    is_active?: number;
}
export interface CreateSOData {
    type?: number;
    bp_id?: number;
    payment_term_id?: number | null;
    child_document?: number[];
    detail_product?: CreateDetailProduct[];
}

export interface EditSOData {
    id: number;
    type?: number;
    bp_id?: number;
    payment_term_id?: number | null;
    child_document?: number[];
    detail_product?: EditDetailProduct[];
}

export interface DetailHistoryList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
}

export interface DetailHistory {
    order_id?: number;
    po_no?: string;
    do_no?: string;
    so_no?: string;
    request_from?: string;
    request_to?: string;
    list_detail?: DetailHistoryList[];
}

export interface SendNotifData {
    account_id?: number;
    message?: string;
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface PaymentTermData {
    payment_term_id?: number;
    payment_term_code?: string;
}

class SalesOrderService extends BaseService {
    getAll = "/so/all-sales-order";

    search = "/so/search-sales-order";

    filter = "/so/filter-sales-order";

    delete = "/so/delete-draft-so";

    detail = "/so/detail-sales-order";

    historySO = "/so/history-sales-order";

    getHeaderSO = "/so/get-header-so";

    requestFrom = "/so/list-account";

    listPO = "/so/list-po";

    detailProductPO = "/so/detail-product-po";

    draftSO = "/so/draft-sales-order";

    createSO = "/so/create-sales-order";

    editDraftSO = "/so/edit-draft-so";

    editSO = "/so/edit-sales-order";

    detailHistorySO = "/so/detail-history-so";

    sendNotif = "/so/send-notif";

    deleteSO = "/so/delete-so";

    paymentTerm = "/so/get-payment-term";

    constructor() {
        super();
    }

    PaymentTerm<T extends PaymentTermData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.paymentTerm}`,
            });
            return req;
        });
    }

    DeleteSO<T extends any>(data: DeleteData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.deleteSO,
                data,
            });
            return req;
        });
    }

    DetailHistorySO<T extends DetailHistory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailHistorySO}/${id}`,
            });
            return req;
        });
    }

    EditSO<T extends any>(data: EditSOData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editSO,
                data,
            });
            return req;
        });
    }

    EditDraftSO<T extends any>(data: EditSOData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraftSO,
                data,
            });
            return req;
        });
    }

    CreateSO<T extends any>(data: CreateSOData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createSO,
                data,
            });
            return req;
        });
    }

    DraftSO<T extends any>(data: CreateSOData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftSO,
                data,
            });
            return req;
        });
    }

    DetailProductPO<T extends ProductPO[]>(params: { po_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProductPO,
                config: { params },
            });
            return req;
        });
    }

    ListPO<T extends ListPO[]>(params: { bp_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listPO,
                config: { params },
            });
            return req;
        });
    }

    RequestFrom<T extends Account[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.requestFrom,
            });
            return req;
        });
    }

    GetHeaderSO<T extends { so_number: string; request_to: string }>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getHeaderSO,
            });
            return req;
        });
    }

    HistorySO<T extends HistorySO>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.historySO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Detail<T extends DetailSO>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslSalesOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.DashboardJslSalesOrder>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.DashboardJslSalesOrder>(param: FilterSalesOrder) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SendNotif<T extends any>(data: SendNotifData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.sendNotif,
                data,
            });
            return req;
        });
    }
}

const salesOrderService = new SalesOrderService();
export default salesOrderService;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Form, Select, Space, message } from "antd";
import Card from "antd/lib/card/Card";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "components/form/inputs/input-select";
import ReportTable from "modules/accounting/report/table-index";
import PrintReport from "modules/master-data/accounting/print-report";
import moment from "moment";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCar, AiOutlinePrinter } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import accountingReportService, { FilterReport, TrialBalance } from "services/api-endpoints/accounting/report";

import { FORMAT_DATE_3, TYPE_REPORT } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<FilterReport>> = yup.object().shape({
    periode: yup.string().required("Period Monthly Required"),
    filter: yup.number(),
});

const ReportNeracaSaldo = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const letterRef = useRef<HTMLDivElement | null>(null);
    const [list, setList] = React.useState<TrialBalance[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<FilterReport>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchMonthly = watch("periode");

    const getTrialBalance = useMutation(
        [accountingReportService.trialBalance],
        async (data: FilterReport) => {
            return (await accountingReportService.TrialBanlance(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Search Success");
                setList(data || []);
            },
        }
    );

    const onSearch = async () => {
        const data: FilterReport = {
            periode: moment(watchMonthly).format("YYYY-MM-") + moment(watchMonthly).daysInMonth(),
        };
        getTrialBalance.mutate(data);
    };

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Report - ${moment(watchMonthly).format("YYYY-MM-") + moment(watchMonthly).daysInMonth()}`,
        pageStyle: "@page {  }",
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintReport ref={letterRef} data={list} dateMonth={watchMonthly} />
            <Card>
                <Form className="flex flex-col gap-5" form={form} layout="vertical">
                    <div className="w-full flex items-center justify-between">
                        <Space direction="horizontal" className="mb-4">
                            <div>
                                <p className="mb-2 font-normal">Period Monthly</p>
                                <DatePicker
                                    className="w-48"
                                    picker="month"
                                    onChange={(val) => {
                                        setValue("periode", val?.format(FORMAT_DATE_3));
                                    }}
                                />
                            </div>
                            {/* <div>
                                <p className="mb-2">Filter</p>
                                <Select
                                    className="w-48"
                                    placeholder="Filter"
                                    options={TYPE_REPORT}
                                    onChange={(val) => {
                                        setValue("filter", val);
                                    }}
                                />
                            </div> */}
                            <Button
                                onClick={onSearch}
                                type="primary"
                                className="!flex !items-center m-0 mt-7"
                                disabled={!watchMonthly}
                                loading={getTrialBalance.isLoading}
                            >
                                <BiSearch className="m-0 mr-2" />
                                Search
                            </Button>
                        </Space>
                        <Space>
                            <Button
                                onClick={() => handlePrintLetter()}
                                type="default"
                                className="!flex !items-center m-0 mt-4"
                                disabled={list.length === 0}
                            >
                                <AiOutlinePrinter className="m-0 mr-2" />
                                Print Report
                            </Button>
                        </Space>
                    </div>
                </Form>
                <ReportTable listData={list || []} />
            </Card>
        </div>
    );
};

export default ReportNeracaSaldo;

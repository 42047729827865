/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface MasterVariable {
    id?: any;
    name: string;
    price: any | null;
    is_active: any;
}

export interface ListVariable {
    id?: any;
    text: string;
    price: number;
    is_active: number;
}

class ManufactureMasterVariableService extends BaseService {
    create = "/manufacture/create-variable";

    getAll = "/manufacture/list-variable";

    detail = "/manufacture/get-variable-detail";

    delete = "";

    constructor() {
        super();
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Detail<T extends ListVariable>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends ListVariable>(params: { page?: any; param_search?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: MasterVariable, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const manufactureMasterVariableService = new ManufactureMasterVariableService();
export default manufactureMasterVariableService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { BasePaginationResponseHistory, FilterPurchaseOrder } from "modules/dashboard-jsl/purchase-order/models";

export interface ProductPL {
    doc_id?: number;
    product_id?: number;
    qty?: number;
    price?: number;
    area_id?: number;
}

export interface CreatePLData {
    account_id?: number;
    doc_type?: number;
    payment_term_id: number | null;
    child_document?: number[];
    detail_product?: ProductPL[];
}

export interface EditPLData extends CreatePLData {
    id: number;
}

export interface ListData {
    doc_id?: number;
    doc_no?: string;
    total_sku?: number;
    total_item?: number;
    date?: number;
    payment_term_code?: string;
    payment_term_id?: number;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

export interface DetailProduct {
    doc_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    area_id?: number;
    area_name?: string;
    qty_locator?: number;
    qty_req?: number;
    qty?: number;
    qty_approve?: number;
    so_no?: number;
}

export interface DetailList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    qty?: number;
    po_id?: number;
    qty_req?: number;
}

export interface DetailPL {
    id?: number;
    pl_no?: string;
    request_from?: string;
    request_from_id?: number;
    request_to?: string;
    order_status?: number;
    child_document?: number[];
    doc_type?: number;
    list_detail?: DetailList[];
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface ListAccount {
    account_id?: number;
    name?: string;
}

class PickingListService extends BaseService {
    createPL = "/pl/create-picking-list";

    listData = "/pl/list-data";

    locator = "/pl/get-locator";

    getAll = "/pl/get-all-picking-list";

    detailProduct = "/pl/get-detail-product";

    detailPL = "/pl/get-detail-picking-list";

    deletePL = "/pl/delete-picking-list";

    draftPL = "/pl/draft-picking-list";

    editDraft = "/pl/edit-draft";

    editPL = "/pl/edit-picking-list";

    listAccount = "/pl/list-account";

    constructor() {
        super();
    }

    ListAccount<T extends ListAccount[]>(params: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAccount,
                config: { params },
            });
            return req;
        });
    }

    EditPL<T extends any>(data: EditPLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editPL,
                data,
            });
            return req;
        });
    }

    EditDraft<T extends any>(data: EditPLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraft,
                data,
            });
            return req;
        });
    }

    DraftPL<T extends any>(data: CreatePLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPL,
                data,
            });
            return req;
        });
    }

    DeletePL<T extends any>(data: DeleteData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.deletePL,
                data,
            });
            return req;
        });
    }

    DetailPL<T extends DetailPL>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailPL}/${id}`,
            });
            return req;
        });
    }

    DetailProduct<T extends DetailProduct[]>(params: { doc_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProduct,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslPickingList>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Locator<T extends LocatorData[]>(param: { product_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.locator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListData<T extends ListData[]>(param: { account_id: any; option: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listData,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    CreatePL<T extends any>(data: CreatePLData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPL,
                data,
            });
            return req;
        });
    }
}

const pickingListService = new PickingListService();
export default pickingListService;

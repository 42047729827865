import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import React from "react";
import { Product } from "services/api-endpoints/dashboard-all/bp-sales";

type Props<T> = TableProps<T> & {
    list: T[] | undefined;
    loading: boolean;
};

const ProductTable = <T extends Product>({ list, loading, ...props }: Props<T>) => {
    const pagingConfig = props.pagination as TablePaginationConfig;
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => (
                <p className="capitalize m-0">{((pagingConfig?.current || 1) - 1) * (pagingConfig.pageSize || 10) + (i + 1)}</p>
            ),
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Price",
            dataIndex: "product_price",
            render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Estimated Price",
            dataIndex: "-",
            render: (text, record) => (
                <p className="capitalize m-0">{(Number(record.qty || 1) * Number(record?.product_price))?.ToIndCurrency("Rp")}</p>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Note",
            dataIndex: "note",
            render: (text) => <p className="m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.order_detail_id!}
            loading={loading}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full"
            {...props}
        />
    );
};

export default ProductTable;

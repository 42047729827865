/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Tabs, TabsProps } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import TableProductsPOM from "modules/working-order-m/add/table-products-pom";
import AddPurchasingOrderMModal from "modules/working-order-m/modal/add-purchasing-order-m";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import workingOrderMService, { CreateWOM, ProductPOM, PurchasingOrderM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { AUTOGENERATED, RESOURCE_OR_PLANNING } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreateWOM, "note" | "resource_id">> = yup.object().shape({
    resource_id: yup.number().required("Resource Required"),
    note: yup.string().nullable(),
});

const WorkingOrderMAdd = () => {
    const navigate = useNavigate();
    const [pom, setPom] = React.useState<PurchasingOrderM | undefined>(undefined);
    const [products, setProducts] = React.useState<ProductPOM[]>([]);
    const [tab, setTab] = React.useState<string>("products");

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<Pick<CreateWOM, "note" | "resource_id">>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation(
        async (dt: CreateWOM) => {
            return (await workingOrderMService.Create(dt)).data.data;
        },
        {
            onSuccess: () => {
                navigate(-1);
                message.success("Working order M Created");
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: CreateWOM = {
            note: data.note,
            pom_id: pom?.id!,
            resource_id: data.resource_id,
            child: products.map((p) => ({
                product_id: p.product_id,
                qty: p.qty_sisa,
            })) as any,
        };

        createMutate.mutate(parseData);
    });

    const onChange = (key: string) => {
        setTab(key);
    };

    const onDeleteProduct = (p: ProductPOM) => {
        setProducts((products) => products?.filter((pd) => pd.product_id !== p.product_id));
    };

    const items: TabsProps["items"] = [
        {
            key: "products",
            label: "Products",
            children: <TableProductsPOM boxes={[]} removeItemList={onDeleteProduct} list={products} onSetList={(list) => setProducts(list)} />,
        },
    ];

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onProductAdd = (ps: ProductPOM[], p?: PurchasingOrderM) => {
        setProducts(ps);
        setPom(p);
    };

    const contentAction = () => {
        return (
            <AddPurchasingOrderMModal onProductAdd={onProductAdd}>
                {(ctrl) => (
                    <Button type="primary" className="!w-fit" onClick={ctrl.openModal}>
                        Add Purchasing Order M
                    </Button>
                )}
            </AddPurchasingOrderMModal>
        );
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new working order m"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card>
                <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="w-full grid grid-cols-4 gap-x-4">
                        <p className="capitalize m-0">
                            Doc No <br />
                            <span className="m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            classNameForm="col-span-1"
                            control={control}
                            name="resource_id"
                            allowClear
                            options={RESOURCE_OR_PLANNING}
                            placeholder="Resource / Planning"
                            label="Resource / Planning"
                        />
                        <p className="capitalize m-0">
                            Ref Doc No <br />
                            <span className="m-0">{pom?.po_no || "-"}</span>
                        </p>
                        <ControlledInputTextArea control={control} name="note" placeholder="Notes" label="Notes" rows={2} />
                    </div>
                </Form>
            </Card>
            <Card>
                <Tabs tabBarExtraContent={{ right: contentAction() }} activeKey={tab} items={items} onChange={onChange} />
            </Card>
        </div>
    );
};

export default WorkingOrderMAdd;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, DatePicker, Select } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import moment from "moment";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import reportService from "services/api-endpoints/dashboard-giias/report";
import { FORMAT_DATE_1, VIEW_PATH } from "utils/constant";

const SalesPerformance = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const salesId = searchParams.get("sales_id");
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");

    const changeSalesName = (values: number[]) => {
        searchParams.set("sales_id", values.join(","));
        setSearchParams(searchParams);
    };

    const changeStartDate = (value: any) => {
        searchParams.set("start_date", value);
        setSearchParams(searchParams);
    };

    const changeEndDate = (value: any) => {
        searchParams.set("end_date", value);
        setSearchParams(searchParams);
    };

    const salesQuery = useQuery([reportService.salesName], async () => {
        const req = await reportService.SalesName();
        return req.data.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption));
    });

    const searchHandler = () => {
        navigate(location.pathname + VIEW_PATH + location.search);
    };

    return (
        <div className="flex flex-col gap-6 items-center justify-center w-full">
            <div className="w-[700px] rounded-2xl bg-white border border-solid border-gray-300 p-7 flex flex-col gap-5">
                <label htmlFor="date-range" className="flex flex-col gap-2">
                    Date Range
                    <DatePicker.RangePicker
                        name="date-range"
                        value={[startDate ? moment(startDate, FORMAT_DATE_1) : null, endDate ? moment(endDate, FORMAT_DATE_1) : null]}
                        placeholder={["Start Date", "End Date"]}
                        onChange={(val) => {
                            changeStartDate(val?.[0]?.format(FORMAT_DATE_1));
                            changeEndDate(val?.[1]?.format(FORMAT_DATE_1));
                        }}
                    />
                </label>
                <label htmlFor="sales-name" className="flex flex-col gap-2">
                    Sales Names
                    <Select
                        value={salesId ? salesId.split(",").map(Number) : undefined}
                        showSearch
                        id="sales-name"
                        mode="multiple"
                        allowClear
                        placeholder="Sales names"
                        onChange={changeSalesName}
                        optionFilterProp="label"
                        options={salesQuery.data}
                    />
                </label>
                <div className="w-full flex justify-end">
                    <Button onClick={searchHandler} className="!flex !items-center w-fit" type="primary">
                        Search
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SalesPerformance;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import ModalAddSupplies from "modules/dashboard-jsl/pamakaian-barang/modal-add-supplies";
import PemakaianTableAdd from "modules/dashboard-jsl/pamakaian-barang/table-add";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import pemakaianBarangService, { ChildPK, CreatePK, SuppliesPK } from "services/api-endpoints/dashboard/master-data/pemakaian-barang";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePK>> = yup.object().shape({
    note: yup.string().required("Note Required"),
    child: yup.array(),
});

const PemakaianBarangAdd = () => {
    const navigate = useNavigate();
    const [child, setChild] = useState<SuppliesPK[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch } = useForm<CreatePK>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [pemakaianBarangService.create],
        async (data: CreatePK) => {
            return (await pemakaianBarangService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Pemakaian Supplies Created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parse: CreatePK = {
            note: data.note,
            child: child.map(
                (val) =>
                    ({
                        supplies_id: val.supplies_id,
                        uom_id: val.uom_id,
                        qty: val.qty,
                    } as ChildPK)
            ),
        };
        createMutation.mutate(parse);
    });

    const onAddItemSupplies = (item: SuppliesPK) => {
        setChild((prev) => [...prev, item]);
    };

    const onRemoveList = (item: SuppliesPK) => {
        setChild((prev) => prev?.filter((pPrev) => pPrev.supplies_id !== item.supplies_id));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new Pemakaian Supplies"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createMutation.isLoading}
                            onClick={onSubmitHandler}
                            type="primary"
                            className="!flex !items-center"
                            disabled={child.length === 0}
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-2 gap-x-5">
                        <p className="capitalize m-0">
                            Doc No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledInputTextArea
                            control={control}
                            name="note"
                            placeholder="Note Pemakaian Supplies"
                            label="Note Pemakaian Supplies"
                            rows={3}
                        />
                    </div>
                </Form>
            </Card>
            <div className="flex items-start">
                <ModalAddSupplies onSubmit={onAddItemSupplies} selected={child}>
                    {(dt) => (
                        <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                            <MdAdd className="mr-2" />
                            Add Supplies
                        </Button>
                    )}
                </ModalAddSupplies>
            </div>
            <PemakaianTableAdd list={child} setList={setChild} removeItemList={onRemoveList} />
        </div>
    );
};

export default PemakaianBarangAdd;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import MaterialMovementDocEdit from "pages/material-movement-doc/edit";
import OrderView from "pages/order/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import { KARTU_STOCK_MM_IN, KARTU_STOCK_MM_OUT, KARTU_STOCK_PURCHASING, KARTU_STOCK_SALES, KARTU_STOCK_SALES_CANCEL } from "utils/constant";

export type Props = ModalProps & {
    id?: any;
    type?: any;
};

const ModalReferensi = ({ id, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    {type === KARTU_STOCK_MM_IN || type === KARTU_STOCK_MM_OUT ? <MaterialMovementDocEdit paramId={id} asPreview /> : null}
                    {type === KARTU_STOCK_SALES || type === KARTU_STOCK_SALES_CANCEL ? <OrderView asPreview paramId={id} /> : null}
                    {type === KARTU_STOCK_PURCHASING ? <PurchasingInventoryView paramId={id} asPreview /> : null}
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalReferensi;

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "components/form/inputs/input-select";
import React from "react";
import { useQuery } from "react-query";
import paymentService, { CreatePayment } from "services/api-endpoints/dashboard/payment";

export function withBusinessPartner<T extends ControlledInputSelectProps<CreatePayment>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const getBpQuery = useQuery([paymentService.getBP], async () => {
            return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
        });

        return <Component {...props} showSearch allowClear optionFilterProp="children" options={getBpQuery.data} loading={getBpQuery.isLoading} />;
    };
}

const BusinessPartnerSelect = withBusinessPartner(ControlledSelectInput);

export default BusinessPartnerSelect;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataBrand, DetailDataBrand, EditDataBrand, FilterDataBrand } from "modules/master-data/brand/models";
import { FilterDataModel } from "modules/master-data/model/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

class BrandService extends BaseService {
    getAll = "/master-brand/all-brands";

    delete = "/master-brand/delete-brand";

    search = "/master-brand/search-brand";

    suggestBrandName = "/master-brand/suggest-brandname";

    suggestBrandCode = "/master-brand/suggest-brandcode";

    suggestBrandType = "/master-brand/suggest-brandtype";

    filter = "/master-brand/filter-brand";

    getAllBrand = "/master-brand/get-allbrand";

    create = "/master-brand/create-brand";

    detail = "/master-brand/get-detail-brand";

    edit = "/master-brand/edit-brand";

    constructor() {
        super();
    }

    Edit<T extends any>(data: EditDataBrand, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataBrand>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataBrand, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataBrand>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataBrand>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestBrandName<T extends string[]>(param: { brand_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestBrandName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestBrandCode<T extends string[]>(param: { brand_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestBrandCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestBrandType<T extends Models.BrandType[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestBrandType,
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataBrand>(param: FilterDataBrand) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAllBrand<T extends Models.AllBrand[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllBrand,
            });
            return req;
        });
    }
}

const brandService = new BrandService();
export default brandService;

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useEffect } from "react";
import { CreateDataProduct, FCreateDataProduct, TempData } from "modules/master-data/product/models";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import InputFile from "components/form/inputs/input-file";
import useControlFile from "hooks/useControlFile";
import ImageCard from "components/card/image";
import { CATEGORY_PRODUCT, IMAGE_TYPES, STATUS_ACTIVE, TYPE_ITEM_INVENTORY } from "utils/constant";
import BrandSelect from "modules/master-data/product/lib/brand-select";
import ModelSelect from "modules/master-data/product/lib/model-select";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import CategorySelect from "modules/master-data/product/lib/category-select";
import SubCategorySelect from "modules/master-data/product/lib/subcategory-select";
import StyleSelect from "modules/master-data/product/lib/style-select";
import AttributeSelect from "modules/master-data/product/lib/attribute-select";
import SubAttributeSelect from "modules/master-data/product/lib/subattribute-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import UomSelect from "modules/master-data/product/lib/uom-select";
import { AllBrand, AllModel, AllModelType } from "models";
import { AllSubCategory } from "services/api-endpoints/dashboard/master-data/sub-category";
import { AllStyle } from "services/api-endpoints/dashboard/master-data/style";
import { AllAttribute } from "services/api-endpoints/dashboard/master-data/attribute";
import { AllSubAttribute } from "services/api-endpoints/dashboard/master-data/sub-attribute";
import InputText from "components/form/inputs/input-text";
import { AllCategory } from "services/api-endpoints/dashboard/master-data/category";
import Utils from "utils";
import { useMutation } from "react-query";
import productService from "services/api-endpoints/dashboard/master-data/product";
import useProgressUpload from "hooks/useProgressUpload";
import { useNavigate } from "react-router-dom";
import { generateSKU } from "modules/master-data/product/utils";

const supporter = ["image1", "image2", "image3", "image4", "image5", "brand", "category", "category_type"] as const;
type Supporter = typeof supporter;

const schema: yup.SchemaOf<Partial<FCreateDataProduct>> = yup.object().shape({
    type: yup.number().required("Product Type Required"),
    model_id: yup.array().required("Model required"),
    // model_type_id: yup.number().required("Model Type required"),
    subcategory_id: yup.string().required("Sub Category required"),
    style_id: yup.number().required("Style required"),
    attribute_id: yup.number().required("Attribute required"),
    subattribute_id: yup.number().required("Sub Attribute required"),
    code1: yup.string().required("Product Code required"),
    molding_code: yup.string().required("Molding Code required"),
    name: yup.string().required("Product Name required"),
    description: yup.string().required("Description required"),
    uom_id: yup.number().required("UOM required"),
    brand: yup.string().required("Brand required"),
    category: yup.string().required("Category required"),
    category_type: yup.string().required("Category type required"),
    is_active: yup.number().required("Status required"),
    image1: yup.string().required("Image required"),
    sku: yup.string(),
    image2: yup.string(),
    image3: yup.string(),
    image4: yup.string(),
    image5: yup.string(),
    images: yup.array(),
});

const ProductAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [tempData, setTempData] = React.useState<TempData>({});

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors },
        watch,
    } = useForm<FCreateDataProduct>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchBrand = watch("brand");
    const watchCategory = watch("category");
    const watchCategoryType = watch("category_type");

    const [controlFile] = useControlFile<FCreateDataProduct>({
        setValue,
        errors: errors as any,
    });

    const createMutate = useMutation(
        [productService.create],
        async (data: CreateDataProduct) => {
            return (await productService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Product created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: FCreateDataProduct = {
            ...data,
            images: [data.image1, data.image2, data.image3, data.image4, data.image5].filter((el) => el),
        };
        Object.keys(parseData).forEach((key: any) => {
            if (supporter.includes(key)) {
                delete parseData[key as keyof FCreateDataProduct];
            }
        });
        createMutate.mutate(parseData);
    });

    useEffect(() => {
        const sku = generateSKU(tempData);
        form.setFieldValue("sku", sku);
        setValue("sku", sku);
    }, [tempData]);

    useEffect(() => {
        form.setFieldValue("category_type", "");
        setValue("category_type", "");
        setTempData((prev) => ({
            ...prev,
            subCategory: null,
        }));
    }, [watchCategory]);

    useEffect(() => {
        form.setFieldValue("subcategory_id", "");
        setValue("subcategory_id", "");
        setTempData((prev) => ({
            ...prev,
            subCategory: null,
        }));
    }, [watchCategoryType]);

    const brandSelected = (brand: AllBrand | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            brand,
        }));
    };

    const modelSelected = (id: number, model: AllModel[]) => {
        const selectedModels = getValues("model_id");
        const models = model.filter((el) => selectedModels?.includes(el.id as any));
        setTempData((prev) => ({
            ...prev,
            models,
        }));
    };

    // const modelTypeSelected = (modelType: AllModelType | null | undefined) => {
    //     setTempData((prev) => ({
    //         ...prev,
    //         modelType,
    //     }));
    // };

    const categorySelected = (category: AllCategory | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            category,
        }));
    };

    const subCategorySelected = (subCategory: AllSubCategory | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            subCategory,
        }));
    };

    const styleSelected = (style: AllStyle | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            style,
        }));
    };

    const attributeSelected = (attribute: AllAttribute | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            attribute,
        }));
    };

    const subAttributeSelected = (subAttribute: AllSubAttribute | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            subAttribute,
        }));
    };

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new product"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-3 gap-4">
                    <div className="grid gap-4 h-fit">
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="image1" className="col-span-2">
                            {(dt) => <ImageCard image={dt.file} title="header image" />}
                        </InputFile>
                        {["image2", "image3", "image4", "image5"].map((img) => (
                            <InputFile key={img} control={controlFile} types={IMAGE_TYPES} name={img}>
                                {(dt) => <ImageCard size="small" image={dt.file} title="Additional Image" />}
                            </InputFile>
                        ))}
                    </div>
                    <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">product literation</h1>
                        <div className="w-full grid grid-cols-3 gap-x-4">
                            <ControlledSelectInput
                                classNameForm="col-span-2"
                                control={control}
                                name="type"
                                options={TYPE_ITEM_INVENTORY}
                                placeholder="Product Type"
                                label="Product Type"
                            />
                            <BrandSelect
                                onSelectedData={brandSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="brand"
                                label="Brand Name"
                                placeholder="Brand Name"
                                showSearch
                            />
                            <InputText value={tempData?.brand?.brand_code} disabled placeholder="Brand Code" label="Brand Code" />
                            <ModelSelect
                                onSelectedData={modelSelected}
                                mode="multiple"
                                classNameForm="col-span-2"
                                control={control}
                                name="model_id"
                                label="Model Name"
                                placeholder="Model Name"
                                showSearch
                            />
                            <InputText
                                value={tempData?.models?.map((el) => el.model_code).join(", ")}
                                disabled
                                placeholder="Model Code"
                                label="Model Name"
                            />
                            {/* <ModelTypeSelect
                                onSelectedData={modelTypeSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="model_type_id"
                                placeholder="Model Type"
                                showSearch
                            /> */}
                            <ControlledSelectInput
                                classNameForm="col-span-2"
                                control={control}
                                name="category"
                                options={CATEGORY_PRODUCT}
                                placeholder="Category Name"
                                label="Category Name"
                            />
                            <CategorySelect
                                onSelectedData={categorySelected}
                                control={control}
                                name="category_type"
                                placeholder="Category Type"
                                label="Category Type"
                                showSearch
                            />
                            <SubCategorySelect
                                onSelectedData={subCategorySelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="subcategory_id"
                                placeholder="Subcategory Name"
                                label="Subcategory Name"
                                showSearch
                            />
                            <InputText
                                value={tempData?.subCategory?.subcategory_code}
                                disabled
                                placeholder="Subcategory Code"
                                label="Subcategory Code"
                            />
                            <StyleSelect
                                onSelectedData={styleSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="style_id"
                                placeholder="Product Style"
                                label="Product Style"
                                showSearch
                            />
                            <InputText value={tempData?.style?.style_code} disabled placeholder="Style Code" label="Style Code" />
                            <AttributeSelect
                                onSelectedData={attributeSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="attribute_id"
                                placeholder="Attribute"
                                label="Attribute"
                                showSearch
                            />
                            <InputText value={tempData?.attribute?.attribute_code} disabled placeholder="Attribute Code" label="Attribute Code" />
                            <SubAttributeSelect
                                onSelectedData={subAttributeSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="subattribute_id"
                                placeholder="Sub Attribute"
                                label="Sub Attribute"
                                showSearch
                            />
                            <InputText
                                value={tempData?.subAttribute?.subattribute_code}
                                disabled
                                placeholder="Subattribute Code"
                                label="Subattribute Code"
                            />
                        </div>
                        <h1 className="capitalize font-semibold text-gray-500 my-4">product information</h1>
                        <div className="w-full grid grid-cols-3 gap-x-4">
                            <ControlledInputText
                                classNameForm="col-span-2"
                                control={control}
                                name="name"
                                placeholder="Product Name"
                                label="Product Name"
                            />
                            <ControlledInputText control={control} name="code1" placeholder="Product Code" label="Product Code" />
                            <div className="col-span-3 grid grid-cols-3 gap-x-4">
                                <ControlledInputTextArea
                                    classNameForm="col-span-2"
                                    control={control}
                                    rows={4}
                                    name="description"
                                    placeholder="Product Description"
                                    label="Product Description"
                                />
                                <div className="">
                                    <ControlledInputText control={control} name="molding_code" placeholder="Molding Code" label="Molding Code" />
                                    <UomSelect control={control} name="uom_id" placeholder="UOM" label="UOM" />
                                </div>
                            </div>
                            <ControlledSelectInput
                                classNameForm="col-span-2"
                                control={control}
                                name="is_active"
                                options={STATUS_ACTIVE}
                                placeholder="Status"
                                label="Status"
                            />
                            <ControlledInputText control={control} name="sku" placeholder="SKU" label="SKU" />
                        </div>
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default ProductAdd;

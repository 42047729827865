import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useState } from "react";
import { UseQueryResult } from "react-query";
import { RecapOffline } from "services/api-endpoints/dashboard/master-data/rekap";

type Props<T> = {
    fetcher: UseQueryResult<T[], unknown>;
};

const TableIndexOffline = <T extends RecapOffline>({ fetcher }: Props<T>) => {
    const [page, setPage] = useState<number>(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Info",
            dataIndex: "info",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Note",
            dataIndex: "note",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Sales",
            dataIndex: "sales",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Payment",
            dataIndex: "payment_channel",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Price",
            dataIndex: "price",
            render: (_, record) => <p className="capitalize m-0">{(record.price || 0).ToIndCurrency("")}</p>,
        },
        {
            title: "Discount",
            dataIndex: "diskon",
            render: (_, record) => <p className="capitalize m-0">{(record.diskon || 0).ToIndCurrency("")}</p>,
        },
        {
            width: "100px",
            title: "Create Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={{
                current: page,
                pageSize: 20,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
                style: {
                    background: record.status === "Canceled" ? "rgb(254 202 202)" : "default",
                },
            })}
        />
    );
};

export default TableIndexOffline;

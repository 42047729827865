/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { CreateSales, CustomVoucher, EditSales, GetSummaryPrice } from "modules/dashboard-all/sales/models";

export interface Voucher {
    voucher_id: number;
    voucher_image: string | null;
    voucher_code: string;
}

export interface SummaryProduct {
    product_id?: number;
    discount_price?: number;
    discount_name?: string;
    freeProduct?: boolean;
}

export interface SummaryPrice {
    total_price: number;
    total_discount: number;
    grand_total: number;
    list_discount?: SummaryProduct[];
}

export interface Product {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
    note?: any;
    discount?: number;
    stock?: number;
}

export interface ProductEdit extends Pick<Product, "product_id" | "product_price" | "qty" | "note"> {}

export interface DetailSales {
    order_id?: number;
    order_no?: string;
    inv_no?: string;
    marketplace_id?: number;
    customer_name?: string;
    customer_info?: string;
    customer_address?: string;
    order_status: number;
    total?: string;
    total_price?: number;
    grand_total?: number;
    total_discount?: number;
    list_detail?: Product[];
    voucher?: Voucher[];
    voucher_custom?: CustomVoucher[] | null;
    list_voucher?: SummaryProduct[];
    note?: string;
    shipping_id?: number;
    shipping_name?: string;
    editable?: boolean;
    order_product_status?: number;
    confirmation_received?: boolean;
    payment_channel_id?: number;
    payment_channel_name?: string;
}

export interface EditSalesOrder {
    id: number;
    order_status: number;
    note: string;
    data_new: ProductEdit[];
    data_update: ProductEdit[];
    data_delete: ProductEdit[];
    voucher?: CustomVoucher[] | null;
}

export interface ListHistory {
    order_history_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface HistoryOrder {
    icon?: string;
    name?: string;
    order_status?: number;
    list: ListHistory[];
}

export interface ShippingData {
    shipping_id?: number;
    shipping_name?: string;
    shipping_code?: string;
    shipping_images?: string;
}

export interface MarketplaceData {
    marketplace_id?: number;
    marketplace_name?: string;
}

export interface VersioningHistory {
    order_history_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface Versioning {
    icon?: string;
    name?: string;
    order_status?: number;
    list?: VersioningHistory[];
}

export interface PaymentData {
    payment_channel_id?: number;
    payment_channel_name?: string;
}

export interface StockWH {
    product_id?: number;
    qty_stock?: number;
}

class OrderSalesService extends BaseService {
    getVoucher = "/pos/get-voucher";

    checkAuth = "/pos/check-auth";

    getSummaryPrice = "/pos/get-summary-price";

    createSales = "/pos/create-sales";

    createDraftSales = "/pos/create-draft-sales";

    detail = "/pos/detail-sales";

    editSalesOrder = "/pos/edit-sales-order";

    editDraftSales = "/pos/edit-draft-sales";

    editSales = "/pos/edit-sales";

    historyOrder = "/pos/get-history-order";

    getShipping = "/pos/get-shipping";

    getMarketplace = "/pos/get-marketplace";

    getVersioning = "/pos/get-versioning-history";

    getPayment = "/pos/get-payment-channel";

    setReceived = "/pos/set-confirmation-received";

    getStockWH = "/pos/get-stock-wh";

    constructor() {
        super();
    }

    GetStockWH<T extends StockWH>(param: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getStockWH,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SetReceived<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.setReceived}/${id}`,
            });
            return req;
        });
    }

    GetPayment<T extends PaymentData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getPayment}`,
            });
            return req;
        });
    }

    GetVersioning<T extends Versioning[]>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getVersioning}/${id}`,
            });
            return req;
        });
    }

    GetMarketplace<T extends MarketplaceData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getMarketplace,
            });
            return req;
        });
    }

    GetShipping<T extends ShippingData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getShipping,
            });
            return req;
        });
    }

    HistoryOrder<T extends HistoryOrder[]>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.historyOrder}/${data.id}`,
            });
            return req;
        });
    }

    EditSales<T extends any>(data: EditSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editSales,
                data,
            });
            return req;
        });
    }

    EditDraftSales<T extends any>(data: EditSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraftSales,
                data,
            });
            return req;
        });
    }

    EditSalesOrder<T extends any>(data: EditSalesOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editSalesOrder,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailSales>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    CreateSales<T extends any>(data: CreateSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createSales,
                data,
            });
            return req;
        });
    }

    CreateDraftSales<T extends any>(data: CreateSales) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDraftSales,
                data,
            });
            return req;
        });
    }

    GetSummaryPrice<T extends SummaryPrice>(data: GetSummaryPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.getSummaryPrice,
                data,
            });
            return req;
        });
    }

    GetVoucher<T extends Voucher[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getVoucher,
            });
            return req;
        });
    }

    CheckAuth<T extends any>(data: { idToken: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.checkAuth,
                data,
            });
            return req;
        });
    }
}

const orderSalesService = new OrderSalesService();
export default orderSalesService;

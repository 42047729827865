import { Button, DatePicker, Select, message } from "antd";
import { convertOfflineToExcel, convertOnlineToExcel } from "modules/accounting/report/utils";
import ChartSalesPerformance from "modules/dashboard-giias/report/chart-sales-performance";
import GetAllReport from "modules/dashboard-giias/report/get-all-report";
import MostProductValueChart from "modules/dashboard-giias/report/most-product-value-chart";
import ProductCategory from "modules/dashboard-giias/report/product-category";
import TopCustomer from "modules/dashboard-giias/report/top-customer";
import TopPaymentChannel from "modules/dashboard-giias/report/top-payment-channel";
import TopTransaction from "modules/dashboard-giias/report/top-transaction";
import moment from "moment";
import { AiOutlineDownload } from "react-icons/ai";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { ParamReport } from "services/api-endpoints/dashboard-giias/report";
import { FORMAT_DATE_1, TYPE_POS, TYPE_POS_OFFLINE } from "utils/constant";

export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const TYPE_SALE = "type";

const DashboardPOS = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get(START_DATE);
    const endDate = searchParams.get(END_DATE);
    const typeSales = (searchParams.get(TYPE_SALE) || TYPE_POS_OFFLINE) as number;

    const onDownloadToExcel = (data: any[]) => {
        const typeName = TYPE_POS.find((el) => el.value === parseInt(typeSales as any, 10))?.label;
        const title = `${startDate?.split("-").join("")}_${endDate?.split("-").join("")}_${typeName} `;
        if (typeSales === TYPE_POS_OFFLINE) {
            convertOfflineToExcel({
                title,
                list: data,
            });
        } else {
            convertOnlineToExcel({
                title,
                list: data,
            });
        }
        message.success("Success Request Download");
    };

    const download = useMutation(
        [],
        async (data: ParamReport) => {
            return (await reportService.Download(data)).data.data;
        },
        {
            onSuccess: (data) => {
                onDownloadToExcel(data);
            },
        }
    );

    const handlerDownload = () => {
        const params: ParamReport = {
            start_date: startDate,
            end_date: endDate,
            type: parseInt(typeSales as any, 10),
        };
        download.mutate(params);
    };

    const onChangeDate = (val: moment.Moment[]) => {
        searchParams.set(START_DATE, val?.[0]?.format(FORMAT_DATE_1) || "");
        searchParams.set(END_DATE, val?.[1]?.format(FORMAT_DATE_1) || "");
        setSearchParams(searchParams);
    };

    const onChangeType = (value: number) => {
        searchParams.set(TYPE_SALE, value?.toString());
        setSearchParams(searchParams);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-end gap-5">
                <DatePicker.RangePicker
                    format="DD MMM yyyy"
                    allowClear
                    allowEmpty={[true, true]}
                    value={[startDate ? moment(startDate, FORMAT_DATE_1) : null, endDate ? moment(endDate, FORMAT_DATE_1) : null]}
                    placeholder={["Start Date", "End Date"]}
                    onChange={onChangeDate as any}
                />
                <Select value={Number(typeSales)} options={TYPE_POS} onChange={onChangeType} className="!w-[130px]" />
                <Button loading={download.isLoading} onClick={handlerDownload} disabled={!startDate || !endDate} className="w-fit">
                    <AiOutlineDownload className="m-0 mr-2" />
                    Download
                </Button>
            </div>
            <GetAllReport />
            <div className="w-full grid grid-cols-12 gap-4 mb-20">
                {/* <div className="col-span-4">
                    <ChartSalesOverview />
                </div> */}
                {/* <div className="col-span-4">
                    <TotalSales />
                </div> */}
                <div className={`${String(typeSales) === String(TYPE_POS_OFFLINE) ? "col-span-8" : "col-span-12"}`}>
                    <ProductCategory />
                </div>
                {String(typeSales) === String(TYPE_POS_OFFLINE) && (
                    <div className="col-span-4">
                        <TopCustomer />
                    </div>
                )}
                <div className="col-span-8">
                    <MostProductValueChart />
                </div>
                <div className="col-span-4">
                    <TopPaymentChannel />
                </div>
                <div className="col-span-8">
                    <ChartSalesPerformance />
                </div>
                <div className="col-span-4">
                    <TopTransaction />
                </div>
            </div>
        </div>
    );
};

export default DashboardPOS;

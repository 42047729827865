import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, message } from "antd";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ModalAddInventory, { AddItemInventory } from "modules/purchasing/request/modal/modal-add-inventory";
import ModalAddSupplies, { AddItem } from "modules/purchasing/request/modal/modal-add-supplies";
import TableAddRequest from "modules/purchasing/request/table-add-item";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import purchasingRequestService, {
    ChildRequest,
    CreatePurchasingRequest,
    EditPurchasingRequest,
} from "services/api-endpoints/purchasing/purchasing-request";
import suppliesService from "services/api-endpoints/purchasing/supplies";
import { AUTOGENERATED, DOC_TYPE_INVENTORY, DOC_TYPE_PURCHASING, DOC_TYPE_SUPPLIES } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePurchasingRequest>> = yup.object().shape({
    doc_type: yup.number().required("Purchasing Category required"),
    note: yup.string(),
    child: yup.array(),
});

const PurchasingRequestEdit = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const navigate = useNavigate();

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [items, setItems] = useState<ChildRequest[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<CreatePurchasingRequest>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchType = watch("doc_type");

    const detailQuery = useQuery(
        [purchasingRequestService.detailRequest, id],
        async () => {
            return (await purchasingRequestService.DetailRequest({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("doc_type", data.doc_type || 0);
                setValue("note", data.note || "");
                setItems(data.child || []);
                form.setFieldsValue({
                    doc_type: data.doc_type,
                    note: data.note,
                });
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const saveCreateMutation = useMutation(
        [purchasingRequestService.createRequest],
        async (data: EditPurchasingRequest) => {
            return (await purchasingRequestService.CreatePurchasing(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing inventory created");
                navigate(-1);
            },
        }
    );

    const saveDraftMutation = useMutation(
        [purchasingRequestService.draftRequest],
        async (data: EditPurchasingRequest) => {
            return (await purchasingRequestService.DraftPurchasing(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing inventory draft created");
                navigate(-1);
            },
        }
    );

    const getUOM = useQuery([suppliesService.getUOM], async () => {
        return (await suppliesService.GetUOM()).data.data?.map((el) => ({ label: el.uom_name, value: el.uom_id } as SelectOption));
    });

    // const totalPrice = items?.reduce((t, curr) => Number(curr?.price) * Number(curr?.qty) + t, 0);

    const onSaveHandler = async () => {
        setTypeSave("submit");
        await form.validateFields();
        form.submit();
    };

    const onDraftHandler = async () => {
        setTypeSave("draft");
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        setTimeout(() => {
            const tData: EditPurchasingRequest = {
                ...data,
                id,
                child: items,
            };
            if (typeSave === "submit") saveCreateMutation.mutate(tData);
            else saveDraftMutation.mutate(tData);
        }, 300);
    });

    const onAddItemInventory = (item: AddItemInventory) => {
        setItems((prev) => [
            ...prev,
            {
                product_id: item.product_id,
                product_name: item.product_name,
                qty: item.qty,
                type_id: item.type,
                type_name: item.type_name,
                note: item.note,
            } as ChildRequest,
        ]);
    };

    const onRemoveItem = (item: ChildRequest) => {
        setItems((prev) => prev?.filter((p) => p.product_id !== item.product_id));
    };

    const onAddItemSupplies = (item: AddItem) => {
        setItems((prev) => [
            ...prev,
            {
                product_id: item.product_id,
                product_name: item.product_name,
                qty: item.qty,
                type_id: item.type_of_supplies_id,
                type_name: item.type_name,
                uom_id: item.uom_id,
                uom_name: item.uom_name,
                note: item.note,
            } as ChildRequest,
        ]);
    };

    const onEditChange = (record: ChildRequest) => {
        if (setItems) {
            setItems((prev) => prev?.map((el) => (el?.product_id === record?.product_id ? record : el)));
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit purchasing request"
                rightAddition={() => (
                    <>
                        <Button
                            loading={saveDraftMutation.isLoading}
                            disabled={items.length === 0}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={saveCreateMutation.isLoading}
                            disabled={items.length === 0}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Inventory Information</h1>
                                </div>
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-4 gap-3">
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700">
                                                document no <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700">
                                                Status <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">NEW</span>
                                            </p>
                                        </div>
                                        <div className="w-full flex flex-col gap-2">
                                            <ControlledSelectInput
                                                classNameForm="col-span-2"
                                                allowClear
                                                control={control}
                                                name="doc_type"
                                                label="Purchasing Category"
                                                placeholder="Purchasing Category"
                                                options={DOC_TYPE_PURCHASING}
                                                onChange={(val) => {
                                                    setValue("doc_type", val);
                                                    setItems([]);
                                                }}
                                            />
                                        </div>
                                        <ControlledInputTextArea
                                            control={control}
                                            name="note"
                                            placeholder="Note Voucher"
                                            label="Note Voucher"
                                            rows={2}
                                        />
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>

            {watchType === DOC_TYPE_INVENTORY ? (
                <ModalAddInventory selected={items} setSelected={setItems}>
                    {(ctrl) => (
                        <Button className="!flex !items-center w-fit" type="primary" onClick={ctrl.openModal}>
                            <MdAdd className="mr-2" />
                            Add Item Inventory
                        </Button>
                    )}
                </ModalAddInventory>
            ) : null}
            {watchType === DOC_TYPE_SUPPLIES ? (
                <ModalAddSupplies selected={items} setSelected={setItems}>
                    {(dt) => (
                        <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                            <MdAdd className="mr-2" />
                            Add Item Supplies
                        </Button>
                    )}
                </ModalAddSupplies>
            ) : null}
            <TableAddRequest list={items} onEditChange={onEditChange} uomFetcher={getUOM} removeItem={onRemoveItem} />
        </div>
    );
};

export default PurchasingRequestEdit;

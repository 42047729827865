import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBrand from "modules/master-data/brand/index/filter";
import { TDataBrand } from "modules/master-data/brand/models";
import BrandTable from "modules/master-data/brand/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import brandService from "services/api-endpoints/dashboard/master-data/brand";

const filter = ["brand_name", "brand_code", "brand_type", "is_popular"];

const Brand = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([brandService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await brandService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await brandService.Filter(filterValue as any)).data.data;
        return (await brandService.GetAll({ page })).data.data;
    });

    const deleteModelType = useMutation(async (data: TDataBrand) => (await brandService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataBrand>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBrand filter={filter}>
                {(dt) => <Toolbar placeholder="Brand Name / Brand Code" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBrand>
            <BrandTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Brand;

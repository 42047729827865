/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataModelType, DetailDataModelType, EditDataModelType, FilterDataModelType } from "modules/master-data/model-type/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

class ModelTypeService extends BaseService {
    getAll = "/master-type/all-modeltype";

    suggestModelTypeName = "/master-type/suggest-modeltype-name";

    suggestModelTypeCode = "/master-type/suggest-modeltype-code";

    filter = "/master-type/filter-model-type";

    search = "/master-type/search-model-type";

    delete = "/master-type/delete-modeltype";

    getAllModelType = "/master-type/get-all-modeltype";

    create = "/master-type/create-modeltype";

    detail = "/master-type/get-detail-modeltype";

    edit = "/master-type/edit-modeltype";

    constructor() {
        super();
    }

    GetAll<T extends Models.MasterDataModelType>(param: Models.ModelTypeParam) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataModelType>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestModelTypeName<T extends string[]>(param: { model_type_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestModelTypeName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestModelTypeCode<T extends string[]>(param: { model_type_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestModelTypeCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataModelType>(param: FilterDataModelType) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAllModelType<T extends Models.AllModelType[]>(param: { model_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllModelType,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataModelType, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataModelType>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataModelType, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }
}

const modelTypeService = new ModelTypeService();
export default modelTypeService;

import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Holiday } from "services/api-endpoints/dashboard/master-data/holiday";
import { EDIT_PATH, FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: Holiday) => void;
    onClickEdit: (data: Holiday) => void;
};

const HolidayTable = <T extends Holiday>({ fetcher, onClickDelete, onClickEdit }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Range date",
            dataIndex: "",
            render: (text, record) => (
                <p className="capitalize m-0">
                    {moment(record?.start_date).format(FORMAT_DATE_TIME)} - {moment(record?.end_date).format(FORMAT_DATE_TIME)}
                </p>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Create By",
            dataIndex: "full_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessEdit onClick={() => onClickEdit(record)} type="text" />
                    <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default HolidayTable;

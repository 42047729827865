/* eslint-disable react/no-array-index-key */
import { Alert, Result } from "antd";
import ProductCard from "components/card/product";
import State from "components/common/state";
import React from "react";
import { AiOutlineInbox } from "react-icons/ai";
import { UseQueryResult } from "react-query";
import { ProductBySubcategory, ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";

type Props = {
    fetcher: UseQueryResult<ProductBySubcategory, unknown>;
    onClickProduct: (data: ProductSub) => void;
};

const ShowProducts = ({ fetcher, onClickProduct }: Props) => {
    return (
        <State data={fetcher.data} isLoading={fetcher.isLoading} isError={fetcher.isError} isEmpty={fetcher.data?.product_list?.length === 0}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        {fetcher.data?.product_list?.map((product) => (
                            <div className="">
                                <p>{product?.name}</p>
                                <div className="w-full grid grid-cols-4 gap-4">
                                    {product?.list?.map((p) => (
                                        <ProductCard onClick={() => onClickProduct(p)} data={p} key={p.product_id} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </State.Data>
                    <State.Loading state={state}>
                        <div className="w-full grid grid-cols-4 gap-4">
                            {[...new Array(4)].map((_, i) => (
                                <ProductCard.Loading key={i} />
                            ))}
                        </div>
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert type="error" message={(fetcher.error as any)?.message} />
                    </State.Error>
                    <State.Empty state={state}>
                        <Result icon={<AiOutlineInbox className="text-7xl text-gray-500" />} title="No Product!" />
                    </State.Empty>
                </>
            )}
        </State>
    );
};

export default ShowProducts;

/* eslint-disable no-shadow */
import { Button, Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import Print from "modules/master-data/doc-container/view/print";
import DocContainerDetailTable from "modules/master-data/doc-container/view/table";
import moment from "moment";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import docContainerService, { DetailProduct } from "services/api-endpoints/dashboard/doc-container";
import { DO_CONTAINER_STATUS, FORMAT_DATE_TIME, STATUS_ORDER } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const DocContainerView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [list, setList] = useState<DetailProduct[]>([]);

    const detailQuery = useQuery(
        [docContainerService.detail, id],
        async () => {
            return (await docContainerService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setList(data?.detail_product || []);
            },
        }
    );

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `DO Container - ${detailQuery.data?.do_no}`,
        pageStyle: "@page { size: A4 portrait; }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="DO Container Detail"
                rightAddition={() => (
                    <>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print DO Container
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Do no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.do_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Order Status <br />
                                        <span className="underline italic m-0">
                                            {DO_CONTAINER_STATUS?.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Created At <br />
                                        <span className="underline italic m-0">{moment(detailQuery.data?.created_at).format(FORMAT_DATE_TIME)}</span>
                                    </p>
                                    <div />
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <DocContainerDetailTable docId={id} list={list} />
        </div>
    );
};

export default DocContainerView;

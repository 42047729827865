/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
import { DocReqChild, VendorColumn } from "pages/purchasing-inventory/add";
import { DetailProduct, InventoryItem } from "services/api-endpoints/purchasing/inventory";
import { DOC_TYPE_INVENTORY } from "utils/constant";

export const transformChild = (requestChild: DocReqChild[]) => {
    const tChild = requestChild
        ?.reduce((array: any, child: DocReqChild) => {
            const vendors = child?.vendors || {};
            const flatVendor = Object.keys(vendors).map((column) => ({
                ...vendors[Number(column)],
                product_id: child.product_id,
                qty: child.qty,
                doc_id: DOC_TYPE_INVENTORY,
                type_of_supplies_id: child.type_id,
                uom_id: child.uom_id,
                note: child.note,
                column,
            }));
            return [...array, ...flatVendor];
        }, [])
        ?.filter((child) => child.vendor_id);

    return {
        allChild: tChild as InventoryItem[],
        problemChild: tChild?.filter((child) => !child.price) as InventoryItem[],
    };
};

export const transformToChild = (products: DetailProduct[]) => {
    const groupingProduct = products?.reduce((obj: any, current) => {
        return {
            ...obj,
            [current.product_id!]: obj && obj[current.product_id!] ? [...obj[current.product_id!], current] : [current],
        };
    }, {});

    const getColumn = (Object.keys(groupingProduct || {}).map((key) => groupingProduct[key])[0] || []).map((p: DetailProduct, i: number) => ({
        column: i,
        vendor_id: p.vendor_id,
    }));

    const tChild = Object.keys(groupingProduct || {})?.reduce((array: any[], key) => {
        const tProduct = groupingProduct[key]?.reduce((product: DetailProduct & VendorColumn, current: DetailProduct, index: number) => {
            const { column } = getColumn.find((col: any) => col.vendor_id === current.vendor_id);
            return {
                ...current,
                ...product,
                vendors: {
                    ...product.vendors,
                    [column]: {
                        vendor_id: current.vendor_id,
                        price: current.price,
                    },
                },
            };
        }, {});

        return [...array, tProduct];
    }, []);

    return tChild;
};

export default {};

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, message } from "antd";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledSwitchInput from "components/form/controlled-inputs/controlled-input-switch";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import manufactureMasterVariableService, { MasterVariable } from "services/api-endpoints/dashboard/manufacture/master-variable";
import { STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<MasterVariable, "id">> = yup.object().shape({
    name: yup.string().required("Variable Name required"),
    price: yup.string().required("Variable Price required"),
    is_active: yup.number().required("Status required"),
});

const MasterVariableEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [progress, onUploadProgress] = useProgressUpload();

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<MasterVariable>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [manufactureMasterVariableService.detail, id],
        async () => {
            return (await manufactureMasterVariableService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("name", data.text);
                setValue("price", data.price);
                setValue("is_active", data.is_active);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const createMutate = useMutation(
        [manufactureMasterVariableService.create],
        async (data: MasterVariable) => {
            return (await manufactureMasterVariableService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Variable Manufacture Created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parse: MasterVariable = {
            ...data,
            id,
            price: (data.price || "0").split(".").join(""),
            is_active: data.is_active,
        };
        createMutate.mutate(parse);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit variable"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="w-full grid grid-cols-3 gap-x-4">
                                        <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                                        <ControlledInputText control={control} type="number" name="price" placeholder="Price" label="Price" />
                                        {/* <div className="">
                                            <label htmlFor="isactive">Is Active</label>
                                            <ControlledSwitchInput control={control} id="isactive" name="is_active" placeholder="Is Active" />
                                        </div> */}
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            name="is_active"
                                            options={STATUS_ACTIVE}
                                            placeholder="Status"
                                            label="Status"
                                        />
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

export default MasterVariableEdit;

import { Icon } from "@mui/material";
import { Button, message, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import { ListMenu } from "services/api-endpoints/dashboard/master-data/role-menu";
import ModalAddRoleSubMenu from "./modal-sub-menu";
import { BiPlus } from "react-icons/bi";
import RoleSubMenuTable from "./table-sub-menu";
import React from "react";

export type ItemRowRoleMenu = Pick<ListMenu, "name" | "key" | "icon"> & { id?: any } & {
    collapse?: ItemRowRoleMenu[];
};

export type RoleMenuTableProps = {
    menus?: ItemRowRoleMenu[];
    setMenus: React.Dispatch<React.SetStateAction<ItemRowRoleMenu[]>>;
};

const RoleMenuTable = ({ menus, setMenus }: RoleMenuTableProps) => {
    const onChangeMenu = (menu: ItemRowRoleMenu | undefined, parentKey: string) => {
        if (menu) {
            setMenus((prev) => {
                return prev.map((parent) => {
                    if (parent.key !== parentKey) return parent;

                    if (parent?.collapse?.find((c) => c.key === menu.key)) {
                        message.error(`Sub menu ${menu.name} is already inserted`);
                        return parent;
                    }

                    return {
                        ...parent,
                        collapse: [...(parent?.collapse || []), menu],
                    };
                });
            });
        }
    };

    const onDelete = (key: string) => {
        setMenus((prev) => prev?.filter((m) => m.key !== key));
    };

    const columns: ColumnsType<ItemRowRoleMenu> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Menu name",
            dataIndex: "name",
        },
        {
            title: "Menu key",
            dataIndex: "key",
        },
        {
            title: "Icon",
            dataIndex: "icon",
            render: (val) => (
                <div className="flex items-center gap-2">
                    {val && <Icon fontSize="medium">{val}</Icon>}
                    {val}
                </div>
            ),
        },
        {
            title: "Action",
            width: "100px",
            render: (_, record) => (
                <Space>
                    <ModalAddRoleSubMenu keyMenu={record.key} onSubmit={(val) => onChangeMenu(val, record.key)}>
                        {({ openModal }) => (
                            <Button onClick={openModal} type="text" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Sub Menu
                            </Button>
                        )}
                    </ModalAddRoleSubMenu>
                    <ButtonAccessDelete onClick={() => onDelete(record.key)} danger type="text" />
                </Space>
            ),
        },
    ];

    const childTable = {
        expandedRowRender: (rec: ItemRowRoleMenu) => {
            if (!rec?.collapse || !rec?.collapse?.length) return null;
            return (
                <div className="w-full my-5">
                    <RoleSubMenuTable setMenus={setMenus} list={rec.collapse} />
                </div>
            );
        },
        rowExpandable: (rec: ItemRowRoleMenu) => !!rec.key,
        defaultExpandAllRows: true,
    };

    return (
        <Table
            expandable={childTable}
            rowKey={(record) => record.key!}
            size="small"
            columns={columns}
            dataSource={menus || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default RoleMenuTable;

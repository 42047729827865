import { Button, Table, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import physicalInventoryService, { LocatorPI, UploadProduct } from "services/api-endpoints/dashboard/master-data/physical-inventory";

type Props = ModalProps & {
    product?: UploadProduct & LocatorPI;
    onSubmit: (locator: LocatorPI) => void;
    list?: (UploadProduct & LocatorPI)[];
};

const LocatorModalPI = ({ onSubmit, product, list, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [openId, setOpenId] = useState<any>(null);

    const locatorQuery = useQuery(
        [physicalInventoryService.getLocator, product],
        async () => {
            return (await physicalInventoryService.GetLocator({ product_id: product?.product_id as any })).data.data;
        },
        {
            enabled: !!product?.product_id && openId === product?.product_id,
        }
    );

    const locatorCalculateQtyUsed =
        locatorQuery.data?.map((locator) => {
            const findUsedLocator = list?.filter((el) => el.area_id && el.area_id === locator?.area_id && el.product_id === locator?.product_id);
            return {
                ...locator,
                qty_locator: findUsedLocator?.reduce((a, b) => a - (b.qty || 0), locator.qty_locator),
            };
        }) || [];

    const onAddLocator = (locator: LocatorPI) => {
        return () => {
            if (!locator?.qty_locator) {
                message.error("Stock not available");
                return;
            }
            if ((product?.qty || 0) > (locator?.qty_locator || 0)) {
                message.error("Not enough stock, choose other locator!");
                return;
            }
            onSubmit({ ...product, ...locator });
            closeRef.current?.click();
        };
    };

    const columns: ColumnsType<LocatorPI> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Stock Quantity",
            dataIndex: "qty_locator",
            render: (qty, record) => {
                const originalRecord = locatorQuery.data?.find((loc) => loc.area_id === record.area_id);
                return (
                    <p className="capitalize m-0 text-xs">
                        {originalRecord?.qty_locator}
                        {originalRecord?.qty_locator !== record.qty_locator ? (
                            <span className="text-red-400 ml-2">Used {(originalRecord?.qty_locator || 0) - qty}</span>
                        ) : null}
                    </p>
                );
            },
        },
        {
            title: "Area Name",
            dataIndex: "area_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return <Button onClick={onAddLocator(record)}>Add Locator</Button>;
            },
        },
    ];

    const isModalOpenFn = (opn: boolean) => {
        setOpenId(opn ? product?.product_id : null);
    };

    return (
        <ModalTemplate width={700} isModalOpenFn={isModalOpenFn} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        size="small"
                        loading={locatorQuery.isLoading}
                        columns={columns}
                        dataSource={(locatorCalculateQtyUsed || []) as LocatorPI[]}
                        className="w-full"
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default LocatorModalPI;

import { Alert, Button, Card, DatePicker, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import InputFile from "components/form/inputs/input-file";
import { BANNER_TYPE, FORMAT_DATE_1, IMAGE_TYPES, PRIORITY, STATUS_ACTIVE } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import bannerService, { Banner, DetailBanner } from "services/api-endpoints/dashboard/master-data/banner";
import useProgressUpload from "hooks/useProgressUpload";
import ControlledInputRichText from "components/form/controlled-inputs/controlled-input-richtext";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import moment from "moment";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<Banner>> = yup.object().shape({
    title: yup.string().required("Banner title required"),
    description: yup.string().required("Banner description required"),
    position: yup.number().required("Banner position required"),
    type: yup.number().required("Banner type required"),
    link: yup.string().required("Banner link required"),
    active: yup.number().required("Banner active required"),
    start_date: yup.string().required("Banner effective date required"),
    end_date: yup.string().required("Banner effective required"),
    image: yup.string().required("Banner image required"),
});

const BannerEdit = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const id = params.get("id");

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<Banner>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const startDateWatch = watch("start_date") ? moment(watch("start_date"), FORMAT_DATE_1) : null;
    const endDateWatch = watch("end_date") ? moment(watch("end_date"), FORMAT_DATE_1) : null;

    const [progress, onUploadProgress] = useProgressUpload();

    const [controlFile] = useControlFile<Banner>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const detailQuery = useQuery(
        [bannerService.detail, id],
        async () => {
            return (await bannerService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                form.setFieldsValue(data);
                setValue("title", data.title);
                setValue("description", data.description);
                setValue("active", Number(data.active));
                setValue("start_date", data.start_date);
                setValue("end_date", data.end_date);
                setValue("type", data.type);
                setValue("position", data.position);
                setValue("image", data.image);
                setValue("link", data.link);
            },
        }
    );

    const editMutation = useMutation(
        [bannerService.edit],
        async (data: DetailBanner) => {
            return (await bannerService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Banner edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        editMutation.mutate({
            ...data,
            id: id as any,
        });
    });

    const onChangeDatePricker = (key: keyof Banner, value: string) => {
        setValue(key, value);
        form.setFieldValue(key, value);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit banner"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-2 gap-4">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <InputFile control={controlFile} types={IMAGE_TYPES} name="image">
                                        {(dt) => <ImageCard image={dt.file || dt.urlFile} title="banner image" />}
                                    </InputFile>
                                    <Form form={form} disabled={editMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">banner information</h1>
                                        <ControlledInputText control={control} name="title" placeholder="Brand title" />
                                        <ControlledInputRichText control={control} name="description" placeholder="Brand description" />
                                        <ControlledSelectInput control={control} name="type" options={BANNER_TYPE} placeholder="Banner Type" />
                                        <ControlledSelectInput control={control} name="position" options={PRIORITY} placeholder="Banner Position" />
                                        <ControlledInputText control={control} name="link" placeholder="Brand link" />
                                        <ControlledSelectInput control={control} name="active" options={STATUS_ACTIVE} placeholder="Banner Active" />
                                        <div className="!w-full">
                                            <DatePicker.RangePicker
                                                allowClear
                                                status={errors.start_date ? "error" : undefined}
                                                className="w-full"
                                                value={[startDateWatch, endDateWatch]}
                                                placeholder={["Start Date", "End Date"]}
                                                onChange={(val) => {
                                                    onChangeDatePricker("start_date", val?.[0]?.format(FORMAT_DATE_1) || "");
                                                    onChangeDatePricker("end_date", val?.[1]?.format(FORMAT_DATE_1) || "");
                                                }}
                                            />
                                            {errors.start_date ? <p className="text-red-400">{errors.start_date?.message}</p> : null}
                                        </div>
                                    </Form>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton.Image active />
                                    <Skeleton paragraph={{ rows: 4 }} />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(detailQuery.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </div>
            </Card>
        </div>
    );
};

export default BannerEdit;

import React from "react";
import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useSearchParams } from "react-router-dom";
import { ListDetail } from "services/api-endpoints/dashboard-jsl/delivery-order";

type Props<T> = {
    list: T[];
    loading?: boolean;
    urlNamePage?: string;
};

const ProductTable = <T extends ListDetail>({ list, loading, urlNamePage = "page" }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set(urlNamePage, pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "SO NO",
            dataIndex: "so_no",
            render: (text) => <p className="capitalize m-0">{text || "FREE"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Price",
            dataIndex: "product_price",
            render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
    ];

    return (
        <Table
            size="small"
            loading={loading}
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={{
                pageSize: 10,
                total: list.length || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ProductTable;

import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import { useState } from "react";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { ListRawMaterial } from "services/api-endpoints/dashboard/manufacture/raw-material";
import { EDIT_PATH, STATUS_ACTIVE } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onDelete: (data: T) => void;
};

const TableIndex = <T extends ListRawMaterial>({ fetcher, onDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const editHandler = (data: ListRawMaterial) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Code 1",
            dataIndex: "code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Name",
            dataIndex: "text",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "is_active",
            render: (text, record) => <p className="capitalize m-0">{STATUS_ACTIVE.find((val) => record.is_active === val.value)?.label}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <>
                        <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                        {/* <ButtonAccessDelete onClick={() => onDelete(record)} type="text" danger /> */}
                    </>
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: page,
                pageSize: 20,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableIndex;

import { Button, Card, Select, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ModalDataOrder from "modules/master-data/return-order/add/modal-data-order";
import OrderTable from "modules/master-data/return-order/add/order-table";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import returnOrderService, {
    CreateReturnOrder,
    DetailOrder,
    Order,
    ReturnOrderDetail,
} from "services/api-endpoints/dashboard/master-data/return-order";

import { AUTOGENERATED, STOCK_ACTION, TYPE_ORDER } from "utils/constant";

const ReturnOrderAdd = () => {
    const navigate = useNavigate();
    const [reason, setReason] = useState<number | null>(null);
    const [selectedOrder, setSelectedOrder] = useState<Order[]>([]);
    const [orderItems, setOrderItems] = useState<DetailOrder[]>([]);
    const [orderType, setOrderType] = useState<number | null>(null);

    const getReason = useQuery([returnOrderService.getReason], async () => {
        return (await returnOrderService.GetReason()).data.data;
    });

    const getDetailOrder = useMutation([returnOrderService.getDetailOrder], async (orderId: string) => {
        return (await returnOrderService.GetDetailOrder({ id: orderId })).data.data;
    });

    const create = useMutation([returnOrderService.create], async (data: CreateReturnOrder) => {
        return (await returnOrderService.Create(data)).data.data;
    });

    const getDataOrder = useMutation([returnOrderService.getDataOrder], async (type: number) => {
        return (await returnOrderService.GetDataOrder({ query: "", type })).data.data?.map(
            (order) => ({ ...order, label: order.inv_no, value: order.order_id } as SelectOption & Order)
        );
    });

    const onChangeWarehouse = (value: number) => {
        setReason(value);
    };

    const onChangeOrderType = (value: number) => {
        setOrderItems([]);
        setSelectedOrder([]);
        setOrderType(value);
        getDataOrder.mutateAsync(value);
    };

    const onSelectOrder = async (order: Order) => {
        getDetailOrder.mutateAsync(order.order_id as any).then((res) => {
            setOrderItems((prev) => [...prev, ...res.map((itm) => ({ ...itm, qty_return: itm.qty_order }))]);
            setSelectedOrder((prev) => {
                if (!prev.find((ord) => ord.order_id === order.order_id)) {
                    return [...prev, order];
                }
                return prev;
            });
        });
    };

    const onDeSelectOrder = (order: Order) => {
        setSelectedOrder((prev) => prev?.filter((ord) => ord.order_id !== order.order_id));
        setOrderItems((prev) => prev?.filter((item) => item.order_id !== order.order_id));
    };

    const onRemoveOrderItem = (item: DetailOrder) => {
        setOrderItems((prev) => {
            return prev
                ?.map((itm) => {
                    if (itm.order_id === item.order_id && itm.product_id === item.product_id) return {};
                    return itm;
                })
                .filter((itm) => Object.keys(itm).length);
        });
    };

    const createHandler = () => {
        const noStockAction = orderItems.find((itm) => !itm.stock_action);
        if (noStockAction) {
            message.error(`Stock Action Required on ${noStockAction.order_no}`);
            return;
        }
        const data: CreateReturnOrder = {
            reason_id: reason as number,
            order_id: [...new Set(orderItems.map((itm) => itm.order_id))] as number[],
            return_order_detail: orderItems.map((itm) => {
                return {
                    order_id: itm.order_id,
                    order_reference_no: itm.order_reference,
                    product_id: itm.product_id,
                    qty_order: itm.qty_order,
                    qty_return: itm.qty_return,
                    stock_action: STOCK_ACTION.find((act) => act.label === itm.stock_action || act.value === itm.stock_action)?.value,
                } as ReturnOrderDetail;
            }),
        };
        create.mutateAsync(data).then(() => {
            message.success("Return Order Created");
            navigate(-1);
        });
    };

    React.useEffect(() => {
        setSelectedOrder((prev) => {
            const deletedOrder = prev.filter((ord) => !orderItems.find((itm) => itm.order_id === ord.order_id));
            if (!deletedOrder.length) return prev;
            return prev.filter((prevOrd) => deletedOrder.find((ord) => prevOrd.order_id !== ord.order_id));
        });
    }, [orderItems]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add return order"
                rightAddition={() => (
                    <Button
                        onClick={createHandler}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={!reason || orderItems.length === 0}
                        loading={create.isLoading}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <div className="flex items-start gap-x-10">
                    <p className="capitalize m-0">
                        Return order no <br />
                        <span className="underline italic m-0">{AUTOGENERATED}</span>
                    </p>
                    <div className="">
                        <div className="capitalize m-0">
                            Reason to return
                            <br />
                            <Select
                                loading={getReason.isLoading}
                                disabled={getReason.isLoading || getReason.isError}
                                value={reason}
                                className="!w-[300px]"
                                options={getReason.data?.map((r) => ({ label: r.reason_name, value: r.reason_id } as SelectOption))}
                                onChange={onChangeWarehouse}
                                showSearch
                                placeholder="Reason"
                            />
                        </div>
                    </div>
                    <div className="">
                        <div className="capitalize m-0">
                            Order Type
                            <br />
                            <Select
                                value={orderType}
                                className="!w-[150px]"
                                options={TYPE_ORDER}
                                onChange={onChangeOrderType}
                                showSearch
                                placeholder="Order Type"
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <ModalDataOrder
                getDetailOrderLoading={getDetailOrder.isLoading}
                onSelectOrder={onSelectOrder}
                onDeSelectOrder={onDeSelectOrder}
                selected={selectedOrder}
                orderData={getDataOrder.data}
                loading={getDataOrder.isLoading}
            >
                {(dt) => (
                    <Button
                        loading={getDetailOrder.isLoading}
                        className="!flex !items-center w-fit"
                        type="primary"
                        onClick={dt.openModal}
                        disabled={orderType === null}
                    >
                        <MdAdd className="mr-2" />
                        Data Order
                    </Button>
                )}
            </ModalDataOrder>
            <OrderTable list={orderItems} setList={setOrderItems} removeItemList={onRemoveOrderItem} />
        </div>
    );
};

export default ReturnOrderAdd;

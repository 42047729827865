import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterPurchaseOrder from "modules/dashboard-jsl/purchase-order/index/filter";
import { TDataPurchaseOrder } from "modules/dashboard-jsl/purchase-order/models";
import PurchaseOrderTable from "modules/dashboard-jsl/purchase-order/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchaseOrderExtService from "services/api-endpoints/dashboard-jsl/purchase-order-ext";
import { message } from "antd";

const filter = ["purchase_order_no", "invoice_no", "request_to", "start_date", "end_date", "status"];

const PurchaseOrderExt = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([purchaseOrderExtService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await purchaseOrderExtService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await purchaseOrderExtService.Filter(filterValue as any)).data.data;
        return (await purchaseOrderExtService.GetAll({ page })).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataPurchaseOrder) => (await purchaseOrderExtService.DeleteDraft({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataPurchaseOrder>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterPurchaseOrder filter={filter}>
                {(dt) => <Toolbar placeholder="PO No / Inv No / Request To" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterPurchaseOrder>
            <PurchaseOrderTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default PurchaseOrderExt;

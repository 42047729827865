/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Button, Form, Popconfirm, Space, Table, TableProps, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import EditableCell, { Props as EditableCellProps } from "components/table/editable-cell";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";

export type Props<T> = TableProps<T> & {
    loading?: boolean;
    allList?: T[];
    list: T[];
    setList?: React.Dispatch<React.SetStateAction<T[]>>;
    removeItemList?: (dt: T) => void;
    action?: boolean;
    columns: ColumnType<T>[];
    editRow: T | null;
    setEditRow: React.Dispatch<React.SetStateAction<T | null>>;
    rowKey: (record: T) => string;
    findIndexSave: (item: T, record: T) => boolean;
    isEditing: (record: T, edited: T | null) => boolean;
    editInputType: {
        [key: string]: EditableCellProps<T>["inputType"];
    };
    canEdit?: boolean;
    canRemove?: boolean;
    actionAddition?: (record: T) => React.ReactNode;
    actionAdditionLeft?: (record: T) => React.ReactNode;
    onSetList?: (list: any[], prevRow?: T | null) => void;
    cellProps?: Partial<EditableCellProps<T>>;
};

export interface RowDefault<T = {}> {
    hideEditAction?: boolean;
    hideRemoveAction?: boolean;
    actionRow?: (record: T) => React.ReactElement;
}

const EditTable = <T extends {}>({
    allList,
    list,
    loading,
    setList,
    removeItemList,
    action = true,
    findIndexSave,
    rowKey,
    columns,
    isEditing,
    editInputType,
    editRow,
    setEditRow,
    canEdit = true,
    canRemove = true,
    onSetList,
    cellProps,
    actionAddition,
    actionAdditionLeft,
    ...props
}: Props<T>) => {
    const [form] = Form.useForm();

    const onEdit = (record: T) => {
        form.setFieldsValue(record);
        setEditRow(record);
    };

    const cancel = () => {
        setEditRow(null);
    };

    React.useEffect(() => {
        window.document.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                cancel();
            }
        });
    }, []);

    const save = async (record: T, autoNextEdit?: boolean) => {
        try {
            const row = (await form.validateFields()) as T;

            const newData = [...(allList || list)];
            const index = newData.findIndex((item) => findIndexSave(item, record));

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                if (setList) {
                    setList(newData);
                }
                if (onSetList) {
                    onSetList(newData, editRow);
                }
                setEditRow(null);
            } else {
                newData.push(row);
                if (setList) {
                    setList(newData);
                }
                if (onSetList) {
                    onSetList(newData, editRow);
                }
                setEditRow(null);
            }

            if (newData[index + 1] && autoNextEdit) {
                if ((newData[index + 1] as any)?.hideEditAction) {
                    setEditRow(newData[index + 2]);
                    return;
                }
                setEditRow(newData[index + 1]);
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const actions: ColumnType<T> = {
        width: canEdit && canRemove ? "130px" : "60px",
        title: "Action",
        dataIndex: "action",
        fixed: "right",
        render: (_: any, record: RowDefault<T>) => {
            const editable = isEditing(record as T, editRow);
            return editable ? (
                <div className="flex flex-col gap-3">
                    <Space size={5}>
                        <button
                            title="Save"
                            type="button"
                            className="focus:outline-primary focus:outline-2 focus:outline-offset-2 bg-primary rounded border-none cursor-pointer text-white"
                            onClick={() => save(record as T)}
                        >
                            Save
                        </button>
                        <button
                            className="focus:outline-red-400 focus:outline-2 focus:outline-offset-2 bg-white rounded border-none cursor-pointer text-red-400"
                            type="button"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                    </Space>
                    <p className="text-2xs">
                        <span className="block">*Enter to next row</span>
                        <span className="text-red-400">*Esc to cancel</span>
                    </p>
                </div>
            ) : (
                <div className="w-full flex gap-3">
                    {actionAdditionLeft && actionAdditionLeft(record as T)}
                    {record?.actionRow && record?.actionRow(record as T)}
                    {canEdit && !record?.hideEditAction && (
                        <Button title="Edit row" disabled={!!editRow} type="text" onClick={() => onEdit(record as T)}>
                            <FiEdit className="text-lg" />
                        </Button>
                    )}
                    {canRemove && !record?.hideRemoveAction && (
                        <Button
                            title="Delete row"
                            disabled={!!editRow}
                            type="text"
                            danger
                            onClick={() => removeItemList && removeItemList(record as T)}
                        >
                            <MdOutlineDeleteOutline className="text-lg" />
                        </Button>
                    )}
                    {actionAddition && actionAddition(record as T)}
                </div>
            );
        },
    };

    if (action) {
        if (!columns.find((col: any) => col?.dataIndex === "action")) {
            columns.push(actions);
        }
    }

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) return col;
        return {
            ...col,
            onCell: (record: T) => ({
                record,
                inputType: editInputType[col.dataIndex] || "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: (record as any)?.hideEditAction ? false : isEditing(record, editRow),
                ...(col?.onCell ? { ...col?.onCell(record) } : {}),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                loading={loading}
                rowKey={rowKey}
                components={{
                    body: {
                        cell: (cProps: any) => {
                            return (
                                <EditableCell
                                    {...cProps}
                                    {...cellProps}
                                    inputNumberProps={{
                                        ...cellProps?.inputNumberProps,
                                        onPressEnter: () => save(editRow as any, true),
                                    }}
                                    inputTextProps={{
                                        ...cellProps?.inputTextProps,
                                        onPressEnter: () => save(editRow as any, true),
                                    }}
                                />
                            );
                        },
                    },
                }}
                size="small"
                columns={mergedColumns}
                dataSource={list || []}
                pagination={{
                    pageSize: 10,
                    total: list.length,
                    showSizeChanger: false,
                }}
                {...props}
            />
        </Form>
    );
};

export default EditTable;

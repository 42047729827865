/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, Skeleton, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { UserContext } from "context/user";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VersionHistory from "modules/master-data/consignment/version-history";
import TableView from "modules/master-data/external-order/ext-so/table-view";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Voucher } from "services/api-endpoints/dashboard-all/bp-sales";
import extSalesOrderService, { DetailProduct, ReadyParam } from "services/api-endpoints/dashboard/master-data/ext-sales-order";
import { ACCOUNT_ID_GNM, C_STATUS_NEW, C_STATUS_ORDER } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ExtSalesOrderView = ({ paramId, asPreview }: Props) => {
    const { state } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<DetailProduct[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);

    const detailQuery = useQuery(
        [extSalesOrderService.detail, id],
        async () => {
            return (await extSalesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
                if (data.voucher_id !== 0) {
                    setVouchers([{ voucher_id: data.voucher_id, voucher_code: data.voucher_code } as Voucher]);
                }
            },
        }
    );

    const readyToPickingMutation = useMutation(
        [extSalesOrderService.readyToPicking],
        async (data: ReadyParam) => {
            return (await extSalesOrderService.ReadyToPicking(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("SO Success Ready To Picking");
                navigate(-1);
            },
        }
    );

    const onReadyToPickingHandler = async () => {
        try {
            const parseData: ReadyParam = {
                id,
            };
            readyToPickingMutation.mutate(parseData);
        } catch (e: any) {
            message.error(e?.message);
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail sales order"
                rightAddition={() => (
                    <>
                        {detailQuery.data?.order_status === C_STATUS_NEW && state.user?.account_id === ACCOUNT_ID_GNM && !asPreview && (
                            <Button
                                loading={readyToPickingMutation.isLoading}
                                onClick={onReadyToPickingHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                Ready To Picking
                            </Button>
                        )}
                    </>
                )}
            />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(stateDetail) => (
                            <>
                                <State.Data state={stateDetail}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 mb-4">
                                                        sales order no <br />
                                                        <span className=" m-0 font-semibold text-gray-700">{detailQuery.data?.doc_no}</span>
                                                    </p>
                                                    <p className="capitalize m-0">
                                                        Referensi No <br />
                                                        {detailQuery.data?.ref_id ? (
                                                            <RefModal
                                                                id={detailQuery.data?.ref_id}
                                                                docNo={detailQuery.data?.ref_no}
                                                                type={detailQuery.data.ref_type}
                                                            >
                                                                {(dt) => (
                                                                    <span
                                                                        onClick={dt.openModal}
                                                                        className="cursor-pointer !text-primary underline m-0"
                                                                    >
                                                                        {detailQuery.data?.ref_no || "-"}
                                                                    </span>
                                                                )}
                                                            </RefModal>
                                                        ) : (
                                                            <span className="underline m-0">-</span>
                                                        )}
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        total price <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Partner Name <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.partner_name || "-"}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-5">Voucher</p>
                                                    <ListVoucher vouchers={vouchers} bordered showTextEmpty />
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        total discount <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.discount_price || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        BP Name <br />
                                                        <span className="font-semibold m-0 text-gray-700">{detailQuery.data?.bp_name || "-"}</span>
                                                    </p>
                                                    <p className="capitalize m-0 mt-4">
                                                        note <br />
                                                        <span className="font-semibold m-0 text-gray-700">{detailQuery.data?.note || "-"}</span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        grand total <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Term Of Payment <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.payment_term_code || "-"}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 mt-4">
                                                        status <br />
                                                        <span className="font-semibold m-0 text-gray-700">
                                                            {C_STATUS_ORDER.find((st) => st.value === detailQuery.data?.order_status)?.label}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </State.Data>
                                <State.Loading state={stateDetail}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>

                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: <TableView list={products} setList={setProducts} action={false} loading={detailQuery.isLoading} />,
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default ExtSalesOrderView;

/* eslint-disable no-shadow */
import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { CreateVoucher, DetailVoucher } from "services/api-endpoints/dashboard/master-data/voucher";
import { RULE_INFORMATION_STEP } from "utils/constant";

type Props = {
    children: any;
};

type StateType = {
    createData: Partial<CreateVoucher> | Partial<DetailVoucher>;
};

type ValueContextType = {
    state: StateType;
    setState: Dispatch<SetStateAction<StateType>>;
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    nextStep: () => void;
    prevStep: () => void;
};

const VoucherContext = createContext<Partial<ValueContextType>>({
    state: {
        createData: {},
    },
});

function VoucherProvider({ children }: Props) {
    const [state, setState] = useState<StateType | null>({
        createData: {},
    });
    const [currentStep, setCurrentStep] = useState(RULE_INFORMATION_STEP);

    const nextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const prevStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    const value = useMemo(
        () => ({
            state,
            setState,
            setCurrentStep,
            currentStep,
            nextStep,
            prevStep,
        }),
        [state, currentStep]
    );
    return <VoucherContext.Provider value={value as ValueContextType}>{children}</VoucherContext.Provider>;
}

export { VoucherContext, VoucherProvider };

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBuyer from "modules/user-magement/buyer/filter";
import BuyerTable from "modules/user-magement/buyer/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import buyerManagementService from "services/api-endpoints/dashboard-all/user-management/buyer";

const filter = ["name", "email", "status"];

const Buyer = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([buyerManagementService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await buyerManagementService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await buyerManagementService.Filter(filterValue as any)).data.data;
        return (await buyerManagementService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBuyer filter={filter}>
                {(dt) => <Toolbar add={false} placeholder="Name / Email" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBuyer>
            <BuyerTable fetcher={getList} />
        </div>
    );
};

export default Buyer;

/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface UploadInventory {
    type: number;
    file: File;
    note: string;
}

export interface PhysicalInventoryList {
    id?: any;
    created_at?: any;
}

export interface DocListData {
    id?: number;
    doc_no?: string;
    file_name?: string;
    type: number;
}

export interface DocProduct {
    detail_id: number;
    ref_doc?: string;
    notes?: string;
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    molding_code?: string;
    area_code?: string;
    qty?: number;
}

export interface DocDetailData {
    id?: number;
    doc_no?: string;
    file_name?: string;
    note?: string;
    type?: number;
    created_at?: number;
    list_product?: DocProduct[];
}

export interface LocatorPI {
    product_id: number;
    area_id: any;
    area_name: any;
    product_name: string;
    qty_locator: number;
}

export interface UploadProduct {
    id?: any;
    product_code1?: string;
    product_code2?: string;
    product_name?: string;
    product_id?: number;
    qty?: number;
    ref_doc?: string;
    notes?: string;
}

export interface UploadData {
    file_name?: string;
    type?: number;
    note?: string;
    area_id?: number;
    list_product?: UploadProduct[];
}

export interface CutStockInventoryUpload {
    id: any;
    area_id: any;
    product_id: any;
    qty: any;
    ref_doc: any;
    notes: any;
}

export interface CutStockParams {
    file_name?: string;
    type?: number;
    note?: string;
    child?: CutStockInventoryUpload[];
}

class PhysicalInventoryService extends BaseService {
    getList = "/PI/list-physical-inventory";

    upload = "/PI/upload-inventory";

    listDoc = "/PI/list-document";

    detail = "/PI/detail";

    getLocator = "/PI/get-locator";

    cutStock = "/PI/cut-stock";

    getLocatorIN = "PI/get-locator-in";

    constructor() {
        super();
    }

    GetLocatorIN<T extends LocatorPI[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocatorIN,
            });
            return req;
        });
    }

    CutStock<T extends any>(data: CutStockParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.cutStock,
                data,
            });
            return req;
        });
    }

    GetLocator<T extends LocatorPI[]>(params: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocator,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends DocDetailData>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListDoc<T extends DocListData>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listDoc,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Upload<T extends UploadData>(data: UploadInventory, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.upload,
                data,
                config: {
                    ...config,
                    headers: { "Content-Type": "multipart/form-data" },
                },
            });
            return req;
        });
    }

    GetList<T extends {}>(params: { file_name?: any; type?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getList,
                config: { params },
            });
            return req;
        });
    }
}

const physicalInventoryService = new PhysicalInventoryService();
export default physicalInventoryService;

import { useSearchParams } from "react-router-dom";
import Utils from "utils";

type TypeSearch = {
    search?: string[];
    filter?: string[];
};

const useTypeSearchUrl = ({ search = [], filter = [] }: TypeSearch) => {
    const [searchParams] = useSearchParams();

    const searchValue = search.map((s) => ({
        [s]: searchParams.get(s),
    }));

    const filterValue = filter.map((f) => ({
        [f]: searchParams.get(f),
    }));

    const isSearch = Utils.cleanEmptyObjFromArray([...searchValue]).length !== 0;
    const isFilter = Utils.cleanEmptyObjFromArray([...filterValue]).length !== 0;

    const parseSearchValue = {
        ...Utils.arrayOfObjectToObject(searchValue),
        page: searchParams.get("page") || 1,
    } as { [key: string]: any };

    const parseFilterValue = {
        ...Utils.arrayOfObjectToObject(filterValue),
        page: searchParams.get("page") || 1,
    } as { [key: string]: any };

    const parseFilter2Value = {
        ...Utils.arrayOfObjectToObject(filterValue),
    } as { [key: string]: any };

    return {
        isSearch,
        isFilter,
        searchValue: parseSearchValue,
        filterValue: parseFilterValue,
        filterValue2: parseFilter2Value,
    };
};

export default useTypeSearchUrl;

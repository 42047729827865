/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Checkbox, Select, message } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { AssemblingExample } from "pages/assembling/add";
import React from "react";
import { useQuery } from "react-query";
import AddBomTable from "./table";
import { BomExample } from "pages/bom/add";
import bomService, { ProductCodeBom } from "services/api-endpoints/dashboard/manufacture/bom";
import assemblingService from "services/api-endpoints/dashboard/manufacture/assembling";

export type Props = ModalProps & {
    onSubmitList: (list: BomExample[], asChild: boolean) => void;
    canAsChild?: boolean;
};

const BomModalAdd = ({ onSubmitList, canAsChild, children, ...props }: Props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const [selectValue, setSelectValue] = React.useState<ProductCodeBom | null>(null);
    const [list, setList] = React.useState<AssemblingExample[]>([]);
    const [asChild, setAsChild] = React.useState(false);

    const getProductCode = useQuery([assemblingService.getProductCode], async () => {
        return (await assemblingService.GetProductCode({ type: "1,2" })).data.data;
    });

    const codeOptions = getProductCode.data?.map((el) => ({ label: el.product_code, value: el.id } as SelectOption));

    const onClickAdd = () => {
        if (!selectValue) return;
        if (list.find((el) => el.id === selectValue.id)) {
            message.error("Product already in list!");
            return;
        }
        const product = getProductCode.data?.find((el) => el.id === selectValue?.id);
        setList((prev) => [...prev, { id: product?.id, product_code: product?.product_code, product_name: product?.product_name, qty: 1 }]);
        setSelectValue(null);
    };

    const onChangeSelect = (id: number) => {
        setSelectValue(getProductCode.data?.find((el) => el.id === id) || null);
    };

    const onRemove = (dt: AssemblingExample) => {
        setList((prev) => prev.filter((el) => el.id !== dt.id));
    };

    const onSubmit = () => {
        if (asChild && list.length <= 1) {
            message.error("OR Logic minimal 2 product");
            return;
        }
        onSubmitList(list, asChild);
        setList([]);
        setAsChild(false);
        setSelectValue(null);
        if (buttonRef.current) {
            buttonRef.current.click();
        }
    };

    return (
        <ModalTemplate width={800} {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    <button onClick={dt.closeModal} ref={buttonRef} type="button" className="h-0 w-0 opacity-0 pointer-events-none">
                        close
                    </button>
                    <div className="flex items-center gap-10 font-semibold">
                        Code
                        <Select
                            value={selectValue?.id}
                            onChange={onChangeSelect}
                            loading={getProductCode.isLoading}
                            className="w-[300px]"
                            size="middle"
                            optionFilterProp="label"
                            placeholder="Code"
                            allowClear
                            options={codeOptions || []}
                            showSearch
                        />
                        <Button onClick={onClickAdd} type="primary" className="!flex !items-center">
                            Add
                        </Button>
                        {canAsChild && (
                            <label htmlFor="asChild" className="flex gap-2 font-normal">
                                <Checkbox checked={asChild} id="asChild" onChange={(val) => setAsChild(val.target.checked)} />
                                Insert as OR Logic
                            </label>
                        )}
                    </div>
                    <AddBomTable
                        list={list}
                        removeItemList={onRemove}
                        setList={setList}
                        footer={() => (
                            <div className="flex justify-end">
                                <Button onClick={onSubmit} disabled={!list.length} type="primary" className="!flex !items-center">
                                    Submit
                                </Button>
                            </div>
                        )}
                    />
                </div>
            )}
        </ModalTemplate>
    );
};

export default BomModalAdd;

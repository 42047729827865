import { Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import { useQuery } from "react-query";
import priceService from "services/api-endpoints/dashboard/master-data/price";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterLocator = ({ filter = [], ...props }: Props) => {
    const getWarehouse = useQuery([priceService.warehouse], async () => {
        return (await priceService.Warehouse()).data.data;
    });

    const options = getWarehouse.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));

    return (
        <FilterModal
            filter={filter}
            title="Filter Price"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            allowClear
                            value={dt.values.warehouse_id}
                            loading={getWarehouse.isLoading}
                            placeholder="Warehouse"
                            options={options || []}
                            onChange={(value) => {
                                dt.setOneValue("warehouse_id", value);
                            }}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterLocator;

import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterModelType from "modules/master-data/model-type/index/filter";
import { TDataModelType } from "modules/master-data/model-type/models";
import ModelTypeTable from "modules/master-data/model-type/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import { message } from "antd";

const filter = ["model_type_name", "model_type_code", "is_active"];

const ModelType = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getAllModelType = useQuery([modelTypeService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await modelTypeService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await modelTypeService.Filter(filterValue as any)).data.data;
        return (await modelTypeService.GetAll({ page })).data.data;
    });

    const deleteModelType = useMutation(async (data: TDataModelType) => (await modelTypeService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getAllModelType.refetch();
            message.success("Data Not Active");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataModelType>({
        title: "Change Status",
        content: `You sure to not active this data?`,
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterModelType filter={filter}>
                {(dt) => <Toolbar placeholder="Model Type Name" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterModelType>
            <ModelTypeTable onClickDelete={showConfirm} fetcher={getAllModelType} />
        </div>
    );
};

export default ModelType;

/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface Role {
    id: number;
    name: string;
    is_active: number;
}

class RoleService extends BaseService {
    listRole = "/manage/list-role";

    createRole = "/manage/create-role";

    constructor() {
        super();
    }

    EditRole<T extends any>(data: Role) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createRole,
                data,
            });
            return req;
        });
    }

    CreateRole<T extends any>(data: Omit<Role, "id">) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createRole,
                data,
            });
            return req;
        });
    }

    ListRole<T extends Role[]>(params: { query?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listRole,
                config: { params },
            });
            return req;
        });
    }
}

const roleService = new RoleService();
export default roleService;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Skeleton, Space } from "antd";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import InputFile from "components/form/inputs/input-file";
import ToolbarAction from "components/toolbar/action";
import useControlFile from "hooks/useControlFile";
import useProgressUpload from "hooks/useProgressUpload";
import UomSelect from "modules/master-data/raw-material/lib/uom-select";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import manufactureModelService, { CreateRawMaterial } from "services/api-endpoints/dashboard/manufacture/raw-material";
import { IMAGE_TYPES, STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";

export type CreateRawMaterialSchema = Omit<CreateRawMaterial, "image" | "id"> & {
    image1?: string | null;
    image2?: string | null;
    image3?: string | null;
    image4?: string | null;
    image5?: string | null;
    is_active: number;
};

const schema: yup.SchemaOf<Partial<CreateRawMaterialSchema>> = yup.object().shape({
    code1: yup.string().required("Product Code required"),
    name: yup.string().required("Product Name required"),
    description: yup.string().default(""),
    uom_id: yup.number().required("UOM required"),
    is_active: yup.number().required("IsActive required"),
    image1: yup.string(),
    image2: yup.string(),
    image3: yup.string(),
    image4: yup.string(),
    image5: yup.string(),
    images: yup.array(),
});

const supporter = ["image1", "image2", "image3", "image4", "image5"];

const RawMaterialEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [progress, onUploadProgress] = useProgressUpload();

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm<CreateRawMaterialSchema>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [controlFile] = useControlFile<CreateRawMaterialSchema>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const detailQuery = useQuery(
        [manufactureModelService.detail, id],
        async () => {
            return (await manufactureModelService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                const images = data.images || [];
                form.setFieldsValue({
                    ...data,
                    image1: images ? images[0] : "",
                    image2: images ? images[1] : "",
                    image3: images ? images[2] : "",
                    image4: images ? images[3] : "",
                    image5: images ? images[4] : "",
                });
                setValue("name", data.text);
                setValue("code1", data.code1);
                setValue("uom_id", data.uom_id);
                setValue("description", data.description);
                setValue("is_active", data.is_active);
                setValue("image1", images ? images[0] : "");
                setValue("image2", images ? images[1] : "");
                setValue("image3", images ? images[2] : "");
                setValue("image4", images ? images[3] : "");
                setValue("image5", images ? images[4] : "");
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const createMutate = useMutation(
        [manufactureModelService.create],
        async (data: CreateRawMaterial) => {
            return (await manufactureModelService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Raw Material Created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: CreateRawMaterial = {
            ...data,
            id,
            is_active: data?.is_active ? 1 : 0,
            images: [data?.image1, data?.image2, data?.image3, data?.image4, data?.image5].filter((el) => el),
        };
        Object.keys(parseData).forEach((key: any) => {
            if (supporter.includes(key)) {
                delete parseData[key as keyof CreateRawMaterial];
            }
        });
        createMutate.mutate(parseData);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit raw material"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-3 gap-4">
                    <div className="grid gap-4 h-fit">
                        <InputFile control={controlFile} types={IMAGE_TYPES} name="image1" className="col-span-2">
                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="header image" />}
                        </InputFile>
                        {["image2", "image3", "image4", "image5"].map((img) => (
                            <InputFile key={img} control={controlFile} types={IMAGE_TYPES} name={img}>
                                {(dt) => <ImageCard size="small" image={dt.file || dt.urlFile} title="Additional Image" />}
                            </InputFile>
                        ))}
                    </div>
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">Material</h1>
                                        <div className="w-full grid grid-cols-2 gap-x-4">
                                            <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                                            <ControlledInputText control={control} name="code1" placeholder="Code 1" label="Code 1" />
                                            <UomSelect control={control} name="uom_id" placeholder="UOM" label="UOM" showSearch />
                                            {/* <DimensionSelect control={control} name="dimensi_id" placeholder="Dimension" /> */}
                                            {/* <WorkflowTypeSelect control={control} name="workflow_type_id" placeholder="Workflow Type" /> */}
                                            {/* <div className="">
                                                <label htmlFor="isactive">Is Active</label>
                                                <ControlledSwitchInput control={control} id="isactive" name="is_active" placeholder="Is Active" />
                                            </div> */}
                                            <ControlledSelectInput
                                                classNameForm="col-span-1"
                                                control={control}
                                                name="is_active"
                                                options={STATUS_ACTIVE}
                                                placeholder="Status"
                                                label="Status"
                                            />
                                            <ControlledInputTextArea
                                                control={control}
                                                rows={4}
                                                name="description"
                                                placeholder="Description"
                                                label="Description"
                                            />
                                        </div>
                                    </Form>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </div>
            </Card>
        </div>
    );
};

export default RawMaterialEdit;

import React from "react";
import { Button, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { BANNER_TYPE, EDIT_PATH } from "utils/constant";
import ModalTemplate from "components/modal/template-modal";
import htmlParser from "html-react-parser";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import { TDataBanner } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: TDataBanner) => void;
};

const BannerTable = <T extends TDataBanner>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: TDataBanner) => {
        return () => {
            navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
        };
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text) => {
                if (!text) return "-";
                return (
                    <ModalTemplate
                        footer={null}
                        handlerInComponent={(dt) => (
                            <Button onClick={dt.openModal} type="primary">
                                Show
                            </Button>
                        )}
                    >
                        {() => <div>{htmlParser(text || "")}</div>}
                    </ModalTemplate>
                );
            },
        },
        {
            title: "Position",
            dataIndex: "position",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Type",
            dataIndex: "type",
            render: (_, record) => <p className="capitalize m-0">{BANNER_TYPE.find((el) => el.value === record.type)?.label}</p>,
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "End Date",
            dataIndex: "end_date",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Link",
            dataIndex: "link",
            render: (text) => {
                if (!text) return "-";
                return (
                    <a href={text} target="_blank" className="capitalize m-0" rel="noreferrer">
                        {text}
                    </a>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "active",
            render: (text) => <p className="capitalize m-0">{text ? "Active" : "Not Active"}</p>,
        },
        {
            width: "150px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessEdit onClick={editHandler(record)} type="text" />
                    <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default BannerTable;

/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { Alert, Button, Card, Divider, Image, message, Modal, Popconfirm, Skeleton, Space, Tabs, Upload, UploadFile, UploadProps } from "antd";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import useProgressUpload from "hooks/useProgressUpload";
import DetailProduct from "modules/master-data/order/view/detail-product";
import DetailProductAccounting from "modules/master-data/order/view/detail-product-accounting";
import DetailWO from "modules/master-data/order/view/detail-wo";
import HistoryPaymentTable from "modules/master-data/order/view/history-payment-table";
import HistoryStatus from "modules/master-data/order/view/history-status";
import VersioningOrder from "modules/master-data/order/view/versioning";
import React, { useContext } from "react";
import { BiImageAdd } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import orderService, { SetStatusOrder, SetStatusPayment } from "services/api-endpoints/dashboard/master-data/order";
import Utils from "utils";
import {
    APPROVE_BY_ADMIN,
    CHECKING_PAYMENT_STATUS,
    ORDER_APPROVE,
    ORDER_PICKED,
    ORDER_SALES_CANCEL,
    REJECT_BY_ADMIN,
    STATUS_ORDER,
    STATUS_PAYMENT_ORDER,
    TYPE_SALES_OFFLINE
} from "utils/constant";

export const PRODUCT_PAGE = "product_page";
export const MAX_RECEIPT_UPLOAD = 1;

interface Props {
    paramId?: any;
    asPreview?: boolean;
    asAccounting?: boolean
}

const OrderView = ({ paramId, asPreview, asAccounting }: Props) => {
    const { updateAccess } = useContext(StateContext);

    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    // uploader image preview
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [progress, onUploadProgress] = useProgressUpload();

    const detailQuery = useQuery(
        [orderService.detail, id],
        async () => {
            return (await orderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const statusPaymentMutate = useMutation(
        [orderService.setStatusPayment],
        async (data: SetStatusPayment) => {
            return (await orderService.SetStatusPayment(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Status payment edited");
            },
        }
    );

    const statusOrderMutate = useMutation(
        [orderService.setStatusOrder],
        async (data: SetStatusOrder) => {
            return (await orderService.SetStatusOrder(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Status order edited");
            },
        }
    );

    const rejectTransactionMutate = useMutation(
        [orderService.rejectTransaction],
        async (data: { id: any }) => {
            return (await orderService.RejectTransaction(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Transaction Rejected");
            },
        }
    );

    const onRejectTransaction = () => {
        rejectTransactionMutate.mutateAsync({ id }).finally(detailQuery.refetch);
    };

    const onRejectSubmit = async (note: string) => {
        statusPaymentMutate.mutateAsync({ note, order_id: id as any, status: REJECT_BY_ADMIN }).finally(detailQuery.refetch);
    };

    const onApproveSubmit = async (note: string) => {
        statusPaymentMutate.mutateAsync({ note, order_id: id as any, status: APPROVE_BY_ADMIN }).finally(detailQuery.refetch);
    };

    const onHoldHandler = async () => {
        statusOrderMutate.mutateAsync({ order_id: id as any, status: ORDER_PICKED }).finally(detailQuery.refetch);
    };

    const alertStatus = (() => {
        if (detailQuery.data?.payment_status === APPROVE_BY_ADMIN) return "success";
        if (detailQuery.data?.payment_status === REJECT_BY_ADMIN) return "error";
        return "info";
    })();

    const uploadReceiptMutate = useMutation(
        [orderService.uploadReceipt],
        async () => {
            const base64 = await Utils.toBase64(fileList[0].originFileObj as any);
            return (await orderService.UploadReceipt({ id, receipt_image: base64 }, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                setFileList([]);
                message.success("Upload receipt success");
                detailQuery.refetch();
            },
        }
    );

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: false,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
        maxCount: MAX_RECEIPT_UPLOAD,
    };

    const itemsTabs = [
        {
            label: `Detail Product`,
            key: "1",
            children: <DetailProduct asPreview={asPreview} detailQuery={detailQuery} paramId={id} />,
        },
        {
            label: `Status History`,
            key: "2",
            children: <HistoryStatus paramId={id} />,
        },
        {
            label: `Payment History`,
            key: "3",
            children: <HistoryPaymentTable paramId={id} />,
        },
        {
            label: "Versioning History",
            key: "4",
            children: <VersioningOrder paramId={id} />,
        },
        
    ]
    const tabHpp = [
        {
            label: "HPP",
            key: "5",
            children: <DetailProductAccounting paramId={id} />,
        },
    ]
    const tabWO = [
        {
            label: "Working Order",
            key: "6",
            children: <DetailWO asPreview={asPreview} paramId={detailQuery.data?.wo_id} />,
        },
    ]

    const getTabs = () => {
        let tabs = itemsTabs
        if (detailQuery.data?.wo_id) {
            tabs = [...tabs, ...tabWO]
        }
        if (asAccounting) {
            tabs = [...tabs, ...tabHpp]
        }
        return tabs
    }

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail order"
                rightAddition={
                    !updateAccess || asPreview
                        ? () => <div />
                        : () =>
                            detailQuery.isLoading ? (
                                <Skeleton paragraph={{ rows: 0 }} style={{ width: 150 }} active />
                            ) : (
                                <Space>
                                    {!detailQuery.data?.payment_status || detailQuery.data?.payment_status === CHECKING_PAYMENT_STATUS ? (
                                        <ModalNote title="Cancel order notes" onSubmit={onRejectSubmit}>
                                            {(dt) => (
                                                <Button loading={statusPaymentMutate.isLoading} onClick={dt.openModal} type="ghost" danger>
                                                    Cancel Order
                                                </Button>
                                            )}
                                        </ModalNote>
                                    ) : null}
                                    {/* {detailQuery.data?.type === TYPE_SALES_OFFLINE && detailQuery.data.order_status !== ORDER_SALES_CANCEL ? (
                                        <Popconfirm
                                            placement="left"
                                            title="Reject Transaction?"
                                            onConfirm={onRejectTransaction}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger type="ghost" loading={rejectTransactionMutate.isLoading}>
                                                Reject Transaction
                                            </Button>
                                        </Popconfirm>
                                    ) : null} */}
                                    {detailQuery.data?.payment_status === APPROVE_BY_ADMIN && detailQuery.data?.order_status !== ORDER_APPROVE ? (
                                        <Popconfirm placement="left" title="Proceed?" onConfirm={onHoldHandler} okText="Yes" cancelText="No">
                                            <Button loading={statusOrderMutate.isLoading} type="ghost">
                                                On Hold
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                    {detailQuery.data?.invoice_no &&
                                        detailQuery.data?.type === TYPE_SALES_OFFLINE &&
                                        !detailQuery.data?.payment_receipt_url ? (
                                        <Button
                                            disabled={fileList.length === 0}
                                            onClick={() => uploadReceiptMutate.mutate()}
                                            type="ghost"
                                            loading={uploadReceiptMutate.isLoading}
                                        >
                                            Upload Receipt
                                        </Button>
                                    ) : null}
                                    <span>{progress}</span>
                                </Space>
                            )
                }
            />
            <div className="grid grid-cols-8 gap-4">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="col-span-5">
                                    <Card>
                                        <div className="grid grid-cols-2 mb-5">
                                            {/* <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">no trx</p>
                                                <span>: {detailQuery.data?.order_no}</span>
                                            </div> */}
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">invoice no</p>
                                                <span>: {detailQuery.data?.invoice_no}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">client name</p>
                                                <span>: {detailQuery.data?.client_name}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">order reference</p>
                                                <span>: {detailQuery.data?.invoice_reference}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">total price</p>
                                                <span>: {detailQuery.data?.total_price?.ToIndCurrency("Rp")}</span>
                                            </div>
                                            {detailQuery.data?.dp_no && <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">DP No</p>
                                                <span>: {detailQuery.data?.dp_no}</span>
                                            </div>}
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">total qty</p>
                                                <span>: {detailQuery.data?.total_quantity}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">status order</p>
                                                <span>: {STATUS_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}</span>
                                            </div>
                                            {detailQuery.data?.discount_name && <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">Discount Name</p>
                                                <span>: {detailQuery.data?.discount_name}</span>
                                            </div>}

                                        </div>
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={getTabs()}
                                        />
                                    </Card>
                                </div>
                                <div className="col-span-3 flex flex-col gap-4">
                                    <Card>
                                        <div className="flex w-full flex-col gap-4">
                                            <p className="m-0 capitalize font-semibold">
                                                payment method <br />
                                                <span className="font-normal text-xs">{detailQuery.data?.payment_channel || "-"}</span> <br />
                                                <ul>
                                                    {detailQuery.data?.payment_custom?.map((pCustom) => (<li className="text-xs">{pCustom?.payment_name}</li>))}
                                                </ul>
                                                {detailQuery.data?.payment_channel !== "COD" ? (
                                                    <>
                                                        <span className="font-normal text-xs">
                                                            {Utils.stripHtml(detailQuery.data?.transfer_dest || "-")}
                                                        </span>{" "}
                                                        <br />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <span className="font-normal text-xs">{detailQuery.data?.order_no || "-"}</span> <br />
                                            </p>
                                            <Alert
                                                type={alertStatus}
                                                message={
                                                    (
                                                        STATUS_PAYMENT_ORDER.find((el) => el.value === detailQuery.data?.payment_status) ||
                                                        STATUS_PAYMENT_ORDER[0]
                                                    )?.label
                                                }
                                            />
                                            {detailQuery.data?.payment_status === CHECKING_PAYMENT_STATUS ? (
                                                <p className="m-0 font-medium text-xs">Paid At {detailQuery.data?.paid_at}</p>
                                            ) : null}
                                            {detailQuery.data?.payment_status === CHECKING_PAYMENT_STATUS ? (
                                                <a
                                                    href={detailQuery.data?.payment_receipt_url || undefined}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="capitalize"
                                                >
                                                    lihat bukti transaksi
                                                </a>
                                            ) : null}
                                            {detailQuery.data?.payment_status === REJECT_BY_ADMIN && detailQuery.data?.is_approve ? (
                                                <Alert type="info" message="Finish" />
                                            ) : null}
                                            <form className="">
                                                {detailQuery.data?.payment_receipt_url ? (
                                                    <div>
                                                        <p className="font-semibold">Payment Receipt</p>
                                                        <Image src={detailQuery.data?.payment_receipt_url} />
                                                    </div>
                                                ) : null}
                                                {!detailQuery.data?.payment_receipt_url && !asPreview && detailQuery.data?.type === TYPE_SALES_OFFLINE ? (
                                                    <div>
                                                        <p className="font-semibold">Payment Receipt</p>
                                                        <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                            <div>
                                                                <BiImageAdd className="text-2xl" />
                                                                <div style={{ marginTop: 8 }}>Pick Receipt</div>
                                                            </div>
                                                        </Upload>
                                                    </div>
                                                ) : null}
                                            </form>
                                            <Divider />
                                            <div className="">
                                                <div className="capitalize flex items-center justify-between">
                                                    <p className="m-0">total price</p>
                                                    <p className="m-0">{detailQuery.data?.total_price?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                <div className="capitalize flex items-center justify-between">
                                                    <p className="m-0">shipping</p>
                                                    <p className="m-0">{detailQuery.data?.shipping_cost?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                {detailQuery.data?.payment_custom?.map((pCustom) => (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">{pCustom?.payment_name}</p>
                                                        <p className="m-0">{pCustom?.payment_amount?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                ))}
                                                {detailQuery.data?.discount !== 0 ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">discount</p>
                                                        <p className="m-0">{detailQuery.data?.discount?.ToIndCurrency("- Rp")}</p>
                                                    </div>
                                                ) : null}
                                                {detailQuery.data?.cc_amount && (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">{detailQuery.data.cc_name}</p>
                                                        <p className="m-0">{detailQuery.data?.cc_amount?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                )}
                                                <div className="capitalize flex items-center justify-between font-semibold mb-4">
                                                    <p className="m-0">grand total</p>
                                                    <p className="m-0 ">{detailQuery.data?.grand_total?.ToIndCurrency("Rp")}</p>
                                                </div>
                                                {detailQuery.data?.dp_amount ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">Down Payment (DP)</p>
                                                        <p className="m-0">{detailQuery.data?.dp_amount?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                ) : null}
                                                {detailQuery.data?.sisa_pembayaran ? (
                                                    <div className="capitalize flex items-center justify-between">
                                                        <p className="m-0">Sisa Pembayaran</p>
                                                        <p className="m-0">{detailQuery.data?.sisa_pembayaran?.ToIndCurrency("Rp")}</p>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {!asPreview && (
                                                <>
                                                    {updateAccess && detailQuery.data?.payment_status === CHECKING_PAYMENT_STATUS ? (
                                                        <Space>
                                                            <ModalNote title="Reject notes" onSubmit={onRejectSubmit}>
                                                                {(dt) => (
                                                                    <Button
                                                                        loading={statusPaymentMutate.isLoading}
                                                                        onClick={dt.openModal}
                                                                        type="ghost"
                                                                        danger
                                                                    >
                                                                        Reject
                                                                    </Button>
                                                                )}
                                                            </ModalNote>
                                                            <ModalNote title="Approve notes" onSubmit={onApproveSubmit}>
                                                                {(dt) => (
                                                                    <Button
                                                                        loading={statusPaymentMutate.isLoading}
                                                                        onClick={dt.openModal}
                                                                        type="primary"
                                                                    >
                                                                        Approve
                                                                    </Button>
                                                                )}
                                                            </ModalNote>
                                                        </Space>
                                                    ) : null}
                                                </>
                                            )}
                                        </div>
                                    </Card>
                                    <Card>
                                        <Space direction="vertical">
                                            <div className="m-0 capitalize w-fit text-xs">
                                                <p className="mb-1 font-medium">recipient address</p>
                                                <span className="">{detailQuery.data?.alias_address}</span> <br />
                                                <span className="">{detailQuery.data?.address}</span>
                                            </div>
                                            <div className="m-0 capitalize w-fit text-xs">
                                                <p className="mb-1 font-medium">courier</p>
                                                <span className="">{detailQuery.data?.courier_name}</span>
                                            </div>
                                        </Space>
                                    </Card>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="col-span-5">
                                    <Card>
                                        <Skeleton paragraph={{ rows: 4 }} />
                                    </Card>
                                </div>
                                <div className="col-span-3 flex flex-col gap-4">
                                    <Card>
                                        <Skeleton paragraph={{ rows: 3 }} />
                                    </Card>
                                    <Card>
                                        <Skeleton paragraph={{ rows: 2 }} />
                                    </Card>
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="receipt" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default OrderView;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { RowDefault } from "components/table/edit-table";
import { number } from "prop-types";

export interface ListSalesOrder {
    id: number;
    so_no: string;
    partner_name: string;
    order_status: number;
    created_at: string;
}

export interface ProductPerformaInv extends RowDefault {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    qty_max: number;
    product_owner: string;
    status?: "update" | "delete" | undefined;
    is_free: number;
    note?: string;
    discount_price: number;
    voucher_id: number;
    qty_stock?: number;
    is_last_stock?: number;
    is_check?: boolean;
}

export interface DetailPerformaInv {
    id: number;
    account_id: number;
    account_id_to: number;
    doc_no: string;
    ref_no: string;
    ref_id: number;
    ref_type: number;
    partner_name: string;
    order_status: number;
    created_at: string;
    list_detail: ProductPerformaInv[];
    voucher_code: string;
    voucher_id: number;
    total_price: number;
    discount_price: number;
    grand_total: number;
    is_last_stock: number;
}

export interface EditProduct {
    product_id: number;
    price: number;
    qty: number;
    discount_price: number;
    voucher_id: number;
    note: string;
    is_free: number;
    is_last_stock: number;
}

export interface EditCSalesOrder {
    id: any;
    type_edit: number;
    voucher_id?: number | null;
    discount_price?: number;
    detail_product: EditProduct[];
}

export interface ParamsSummaryPrice {
    id: any;
    detail_product?: EditProduct[];
    voucher_id?: number | null;
    account_id_to: any;
}

export interface FreeProduct {
    id: number;
    images: string[];
    item: string;
    price: number;
    product_code: string;
    product_owner: string;
    qty: number;
}

export interface DiscountProduct {
    product_id: number;
    discount_price: number;
    discount_name: string;
}

export interface SummaryProduct {
    discount_price?: number;
    is_free?: number;
    note?: string;
    price?: number;
    product_code1?: string;
    product_id: number;
    product_owner: string;
    product_name: string;
    qty: number;
    voucher_id: number;
}

export interface SummaryPriceData {
    total_price: number;
    total_discount: number;
    grand_total: number;
    // free_product?: FreeProduct[];
    // list_discount?: DiscountProduct[];
    detail_product?: SummaryProduct[];
}

export interface StockData {
    warehouse_name: string;
    area_code: string;
    qty: number;
}

export interface LastStockParam {
    id: any;
    is_last_stock: number;
}

export interface ReadyToPickingParam {
    id: any;
}

export interface SoOutstanding {
    id?: number;
    account_id: number;
    account_id_to: number;
    partner_name?: string;
    doc_id?: number;
    so_no?: string;
    created_at?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    code2?: string;
    product_price?: number;
    qty?: number;
    qty_req?: number;
    product_owner?: string;
    qty_stock?: number;
    is_check?: boolean;
}

export interface GenerateSOData {
    doc_id: number;
    account_id: number;
    product_id: number;
    price: number;
    qty: number;
}

export interface CheckSOReq {
    doc_id: number;
    product_id: number;
    qty: number;
}

export interface CheckItem {
    Item: string;
}

export interface CheckSOData {
    invoice: string;
    list_product: CheckItem[];
}

class CSalesOrderService extends BaseService {
    listAll = "/consignment/list-c-sales-order";

    detail = "/consignment/detail-c-sales-order";

    edit = "/consignment/edit-sales-order";

    summaryPrice = "/consignment/summary-price";

    listStock = "/consignment/list-stock-product";

    setLastStock = "/consignment/set-is-last-stock";

    readyToPicking = "/consignment/ready-to-picking";

    listOutstanding = "/consignment/list-so-outstanding";

    generateSO = "/consignment/edit-so-outstanding";

    checkSO = "/consignment/cek-so-outstanding";

    constructor() {
        super();
    }

    CheckSO<T extends CheckSOData[]>(data: CheckSOReq[]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.checkSO,
                data,
            });
            return req;
        });
    }

    GenerateSO<T extends any>(data: GenerateSOData[]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.generateSO,
                data,
            });
            return req;
        });
    }

    ListOutstanding<T extends SoOutstanding>(param: { page: any; bp?: any; doc?: any; date?: any; code1?: any; code2?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listOutstanding,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ReadyToPicking<T extends any>(data: ReadyToPickingParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.readyToPicking,
                data,
            });
            return req;
        });
    }

    SetLastStock<T extends any>(data: LastStockParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setLastStock,
                data,
            });
            return req;
        });
    }

    ListStock<T extends StockData[]>(param: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listStock,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SummaryPrice<T extends SummaryPriceData>(data: ParamsSummaryPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.summaryPrice,
                data,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditCSalesOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailPerformaInv>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    ListAll<T extends ListSalesOrder[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAll,
            });
            return req;
        });
    }
}

const cSalesOrderService = new CSalesOrderService();
export default cSalesOrderService;

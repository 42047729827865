import {
    ListProduct,
    ListProductRoutine,
    ProductTransfer,
    ProductTransferWithEdit,
    ProductTransferWithEdit2,
} from "services/api-endpoints/dashboard/master-data/material-movement";

export const tProductTransformWithQty = (products?: ListProduct[]) => {
    return products?.map((product) => {
        const locFrom = product.list_locator_from && product?.list_locator_from[product.list_locator_from.length - 1];
        const locTo = product.list_locator_to && product?.list_locator_to[product.list_locator_to.length - 1];
        return {
            ...product,
            area_id: locFrom?.area_id_from,
            area_name: locFrom?.area_name_from,
            locator_to: locTo?.area_name_to,
            qty_from: locFrom?.qty_from,
            qty_to: locTo?.qty_to,
        } as ProductTransferWithEdit;
    });
};

export const tProductTransformWithQty2 = (products?: ListProductRoutine[]) => {
    return products?.map((product) => {
        const loc = product.list_locator && product?.list_locator[product.list_locator.length - 1];
        return {
            ...product,
            area_id: loc?.area_id,
            area_name: loc?.area_name,
            qty_before: loc?.qty_before,
            qty_scan: loc?.qty_scan,
            qty: loc?.qty_before === loc?.qty_scan ? loc?.qty_scan : loc?.qty,
            hideEditAction: loc?.qty_before === loc?.qty_scan,
        } as ProductTransferWithEdit2;
    });
};

export const tProductTransformWithQty3 = (transfer: ProductTransfer[], list: ListProduct[]) => {
    return transfer?.map((product) => {
        const matchingItem = list.find((product2) => product2.product_id === product.product_id);
        const loc = matchingItem?.list_locator_to && matchingItem.list_locator_to[matchingItem.list_locator_to.length - 1];
        if (matchingItem) {
            return {
                ...product,
                locator_to: loc?.area_id_to,
                qty_to: loc?.qty_to,
            } as ProductTransfer;
        }
        return {
            ...product,
            locator_to: 1,
            qty_to: product?.qty_to || product?.qty_from || 1,
        } as ProductTransfer;
    });
};

export default {};

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { Control, useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FEditProductPrice } from "modules/master-data/price/models";
import priceService, { EditPrice } from "services/api-endpoints/dashboard/master-data/price";
import InputText from "components/form/inputs/input-text";
import ControlledInputNumber from "components/form/controlled-inputs/controlled-input-number";
import WarehouseSelect from "modules/master-data/price/lib/warehouse-select";
import { STATUS_ACTIVE } from "utils/constant";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<FEditProductPrice>> = yup.object().shape({
    warehouse_id: yup.number().required("Warehouse required"),
    price: yup.number().required("Price required"),
});

const PriceEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        setValue,
    } = useForm<FEditProductPrice>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const editMutate = useMutation(
        [priceService.editPrice],
        async (data: EditPrice) => {
            return (await priceService.EditPrice(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Product Price edited");
                navigate(-1);
            },
        }
    );

    const detailProduct = useQuery(
        [priceService.detailProductEdit, id],
        async () => {
            return (await priceService.DetailProductEdit({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    price: data.price,
                    warehouse_id: data.warehouse_id,
                });
                setValue("price", data.price);
                setValue("warehouse_id", data.warehouse_id);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: EditPrice = {
            ...data,
            id: id as any,
        };
        editMutate.mutate(parseData);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit product price"
                // rightAddition={() => (
                //     <Button loading={editMutate.isLoading} onClick={onSaveHandler} disabled={!isValid} type="primary" className="!flex !items-center">
                //         <BiSave className="m-0 mr-2" />
                //         Save
                //     </Button>
                // )}
            />
            <State data={detailProduct.data} isLoading={detailProduct.isLoading} isError={detailProduct.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="col-span-2" form={form} disabled={detailProduct.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                                <div className="grid w-full grid-cols-2 gap-4">
                                    <Card className="">
                                        <div className="grid grid-cols-3 gap-x-4 h-fit">
                                            <h1 className="capitalize font-semibold col-span-3 text-gray-500 mb-4">product information</h1>
                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.product_name}
                                                disabled
                                                placeholder="Product Name"
                                                label="Product Name"
                                            />
                                            <InputText
                                                value={detailProduct.data?.product_code}
                                                disabled
                                                placeholder="Product Code"
                                                label="Product Code"
                                            />
                                            <ControlledInputNumber
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="price"
                                                placeholder="Price"
                                                label="Price"
                                                disabled
                                            />
                                            <WarehouseSelect
                                                control={control as Control<any, unknown>}
                                                name="warehouse_id"
                                                placeholder="Warehouse"
                                                label="Warehouse"
                                                disabled
                                            />
                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.product_desc}
                                                disabled
                                                placeholder="Product Description"
                                                label="Product Description"
                                            />
                                            <InputText
                                                value={detailProduct.data?.molding_code}
                                                disabled
                                                placeholder="Molding Code"
                                                label="Molding Code"
                                            />
                                            <InputText
                                                value={STATUS_ACTIVE.find((el) => el.value === Number(detailProduct.data?.status))?.label}
                                                disabled
                                                classNameForm="col-span-2"
                                                placeholder="Status"
                                                label="Status"
                                            />
                                        </div>
                                    </Card>
                                    <Card>
                                        <div className="grid grid-cols-3 gap-x-4 h-fit">
                                            <h1 className="capitalize font-semibold col-span-3 text-gray-500 mb-4">product literation</h1>
                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.brand_name}
                                                disabled
                                                placeholder="Brand Name"
                                                label="Brand Name"
                                            />
                                            <InputText value={detailProduct.data?.brand_code} disabled placeholder="Brand Code" label="Brand Code" />

                                            <InputText value={detailProduct.data?.model_name} disabled placeholder="Model Name" label="Model Name" />
                                            <InputText value={detailProduct.data?.model_code} disabled placeholder="Model Code" label="Model Code" />
                                            <InputText
                                                value={(JSON.parse(detailProduct.data?.model_year || "[]") as Array<number>)?.join(", ")}
                                                disabled
                                                placeholder="Model Year"
                                                label="Model Year"
                                            />
                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.category_name}
                                                disabled
                                                placeholder="Category Name"
                                                label="Category Name"
                                            />
                                            <InputText
                                                value={detailProduct.data?.category_type}
                                                disabled
                                                placeholder="Category Type"
                                                label="Category Type"
                                            />

                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.subcategory_name}
                                                disabled
                                                placeholder="Subcategory Name"
                                                label="Subcategory Name"
                                            />
                                            <InputText
                                                value={detailProduct.data?.subcategory_code}
                                                disabled
                                                placeholder="Subcategory Code"
                                                label="Subcategory Code"
                                            />

                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.style_name}
                                                disabled
                                                placeholder="Style Name"
                                                label="Style Name"
                                            />
                                            <InputText value={detailProduct.data?.style_code} disabled placeholder="Style Code" label="Style Code" />

                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.attribute_name}
                                                disabled
                                                placeholder="Attribute Name"
                                                label="Attribute Name"
                                            />
                                            <InputText
                                                value={detailProduct.data?.attribute_code}
                                                disabled
                                                placeholder="Attribute Code"
                                                label="Attribute Code"
                                            />

                                            <InputText
                                                classNameForm="col-span-2"
                                                value={detailProduct.data?.subattribute_name}
                                                disabled
                                                placeholder="Subattribute Name"
                                                label="Subattribute Name"
                                            />
                                            <InputText
                                                value={detailProduct.data?.subattribute_code}
                                                disabled
                                                placeholder="Subattribute Code"
                                                label="Subattribute Code"
                                            />
                                        </div>
                                    </Card>
                                </div>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <div className="w-full grid grid-cols-2 gap-4">
                                <Card>
                                    <Skeleton paragraph={{ rows: 3 }} />
                                </Card>
                                <Card>
                                    <Skeleton paragraph={{ rows: 5 }} />
                                </Card>
                            </div>
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(detailProduct.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </div>
    );
};

export default PriceEdit;

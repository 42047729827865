import errorImg from "assets/images/error.svg";
import React from "react";

const ErrorPage = ({ error, resetErrorBoundary }: any) => {
    const [showRestError, setShowRestError] = React.useState(false);

    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <div className="w-full h-[90vh] p-10">
            <div className="w-full h-full items-center bg-white rounded-3xl p-10 flex gap-10 border border-solid border-red-400 overflow-y-auto">
                <div className="flex flex-col gap-4 flex-1">
                    <p className="text-6xl text-gray-600">
                        Whooops, <br /> this page <br /> is error.
                    </p>
                    <div className={`text-sm ${showRestError ? "" : "line-clamp-3"}`}>{error?.message || error?.toString()} </div>
                    <button
                        onClick={() => setShowRestError((prev) => !prev)}
                        className="cursor-pointer text-red-400 border-none w-fit bg-transparent"
                        type="button"
                    >
                        {showRestError ? "Hide rest" : "Show All"}
                    </button>
                    <div className="flex items-center gap-10">
                        <button
                            onClick={reloadPage}
                            type="button"
                            className="bg-red-400 rounded-lg cursor-pointer text-white px-5 py-2 border-none w-fit"
                        >
                            Refresh Page
                        </button>
                        <button
                            onClick={resetErrorBoundary}
                            type="button"
                            className="text-red-400 rounded-lg cursor-pointer bg-white px-5 py-2 border-none w-fit"
                        >
                            Retry
                        </button>
                    </div>
                </div>
                <img src={errorImg} alt="error" className="w-[300px] flex-1" />
            </div>
        </div>
    );
};

export default ErrorPage;

import { Select, Spin, message } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React, { useRef } from "react";
import { InternalReturnProduct } from "services/api-endpoints/dashboard/master-data/internal-return-order";
import { Order } from "services/api-endpoints/dashboard/master-data/return-order";

type Props = {
    children: (data: HandlerProps) => void;
    selected: InternalReturnProduct[];
    onSelectOrder: (data: Order) => void;
    onDeSelectOrder: (data: Order) => void;
    getProductLoading: boolean;
    products: InternalReturnProduct[] | undefined;
};

const InternalModalProduct = ({ children, selected, onSelectOrder, onDeSelectOrder, getProductLoading, products }: Props) => {
    const closeModalRef = useRef<HTMLButtonElement | null>(null);

    const createKey = (data: InternalReturnProduct) =>
        `${data.product_name}~${data?.product_code1 || (data as any)?.product_code}~${data.product_id}`;
    const getProductId = (key: string) => key.split("~")[key.split("~").length - 1];
    const getProduct = (key: string) => {
        const product = [...(products || [])].find((p) => p.product_id === Number(getProductId(key))) as SelectOption & InternalReturnProduct;
        return product;
    };
    const onSelect = (values: any) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Product Not Found");
            return;
        }
        onSelectOrder(product as Order);
    };

    const onDeSelect = (values: any) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Product Not Found");
            return;
        }
        onDeSelectOrder(product as Order);
    };

    const OptionsMemoize = React.useCallback(
        () =>
            products?.map((option) => {
                return (
                    <Select.Option value={createKey(option)} key={option.product_id}>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                            {option.product_name}
                            <br />
                            <span style={{ margin: 0, fontSize: "9px", fontWeight: "bold" }}>
                                {`${option.product_code1} | ${option.product_owner}`}
                            </span>
                        </p>
                    </Select.Option>
                );
            }),
        [products]
    );

    const SelectMemoize = React.useMemo(
        () => (
            <Select
                value={selected.map((p) => createKey(p))}
                bordered
                mode="multiple"
                className="!w-full"
                showSearch
                placeholder="Search Product"
                onSelect={onSelect}
                onDeselect={onDeSelect}
                loading={getProductLoading}
            >
                {OptionsMemoize()}
            </Select>
        ),
        [products, selected]
    );

    return (
        <ModalTemplate
            title={
                <div className="flex item-center">
                    <span className="mr-3">Add Product</span> {getProductLoading && <Spin />}
                </div>
            }
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <React.Suspense fallback={<p className="m-0">Loading...</p>}>{SelectMemoize}</React.Suspense>
                </div>
            )}
        </ModalTemplate>
    );
};

export default InternalModalProduct;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface BrandSearch {
    brand_id: number;
    brand_name: string;
}

export interface ModelByBrand {
    brand: string;
    model_list: {
        model_id: number;
        model_name: string;
        model_code: string;
        model_image: string;
    }[];
}

export interface ModelType {
    brand_name: string;
    model_id: number;
    model: string;
    model_year_id: string;
    model_year: string;
    type_id: number;
    type: string;
    name: string;
}

export interface Subcategory {
    subcategory_id?: number;
    subcategory_name?: string;
    subcategory_image?: null | string;
}

export interface SubcategoryProduct {
    brand_name?: string;
    model_id?: number;
    model?: string;
    model_image?: string;
    model_year_id?: number;
    model_year?: string;
    type_id?: number;
    type?: string;
    name?: string;
    subcategory_list?: Subcategory[];
}

export interface ProductSub {
    product_id?: number;
    product_category?: string;
    product_name?: string;
    product_price?: number;
    product_code?: string;
    product_images?: string[];
    product_wishlist?: boolean;
    product_owner?: string;
}

export interface ProductList {
    brand_name?: string;
    model_id?: number;
    model?: string;
    model_year_id?: number;
    model_year?: string;
    type_id?: number;
    type?: string;
    name?: string;
    subcategory_name?: string;
    list?: ProductSub[];
}

export interface ProductBySubcategory {
    product_list?: ProductList[];
}

class SpecificSearchService extends BaseService {
    getBrand = "/giias/cashier/get-brand";

    getModelByBrand = "/giias/cashier/get-model-by-brand";

    getModelType = "/giias/cashier/get-model-type";

    getSubcategory = "/giias/cashier/get-subcategory-product";

    getProductBySubcategory = "/giias/cashier/get-product-by-subcategory";

    constructor() {
        super();
    }

    GetProductBySubcategory<T extends ProductBySubcategory>(param: { model_id: any; is_all_year: any; type_id: any; subcategory_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductBySubcategory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetSubcategory<T extends SubcategoryProduct>(param: { model_id: any; is_all_year: any; type_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSubcategory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetBrand<T extends BrandSearch[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBrand,
            });
            return req;
        });
    }

    GetModelByBrand<T extends ModelByBrand>(param: { brand_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getModelByBrand,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetModelType<T extends ModelType[]>(param: { model_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getModelType,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const specificSearchService = new SpecificSearchService();
export default specificSearchService;

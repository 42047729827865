/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { DetailProduct, LocatorData } from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends DetailProduct>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "SO NO",
                dataIndex: "so_no",
                render: (text, record) => <p className="capitalize m-0">{text || "FREE"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Quantity Send",
                dataIndex: "qty_req",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Receipt",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="m-0 text-center ">{text === 0 ? "0" : text || "-"}</p>,
            },
        ];

        const isEditing = (record: DetailProduct, edited: DetailProduct | null) =>
            record.so_no === edited?.so_no && record.product_id === edited?.product_id;
        const findIndexSave = (record: DetailProduct, edited: DetailProduct) =>
            record.so_no === edited?.so_no && record.product_id === edited?.product_id;
        const rowKey = (record: DetailProduct) => (record?.so_no && record.product_id) as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        const reqSend = editRow?.qty_req || 1;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 0,
                    maxNumber: reqSend,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const ProductTableBPAdd = tableEdit(EditTable);
export default ProductTableBPAdd;

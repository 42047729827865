/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListCSO {
    id: number;
    doc_no: string;
    total_sku: number;
    total_item: number;
    created_at: string;
}

export interface ProductCSO {
    id: number;
    account_id: number;
    product_id: number;
    product_code1: string;
    product_name: string;
    product_price: number;
    product_owner: string;
    qty: number;
    qty_locator?: number;
    qty_approve?: number;
    area_id?: number;
    area_name?: string;
    is_free: number;
    discount_price: number;
    note: string;
    voucher_id: number;
    is_last_stock: number;
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

export interface ListPickingList {
    id: number;
    pd_no: string;
    partner_name: string;
    order_status: number;
    created_at: string;
}

export interface CreateProduct {
    product_id: number;
    price: number;
    discount_price: number;
    voucher_id: number;
    note: string;
    qty: number;
    so_id: number;
    area_id: number;
    is_free: number;
    is_last_stock?: number;
}

export interface CreatePickingList {
    account_id_to: number;
    account_id: number;
    child_document: number[];
    detail_product: CreateProduct[];
}

export interface ProductPickingList {
    id: number;
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    qty_locator: number;
    qty_approved: number;
    is_free: number;
    product_owner: string;
    so_id: number;
    so_no: string;
    area_id: number;
    area_code: string;
    note: string;
    code2: string;
    discount_price: number;
    voucher_id: number;
    status?: string;
}

export interface DetailPickingList {
    id: number;
    account_id: number;
    doc_no: string;
    ref_no: string;
    ref_id: number;
    ref_type: number;
    partner_name: string;
    order_status: number;
    created_at: string;
    name?: string;
    address?: string;
    list_detail: ProductPickingList[];
}

export interface EditPickingDetail {
    id: number;
    detail_product: CreateProduct[];
}

class CPickingDetailService extends BaseService {
    getCSalesOrder = "/consignment/get-c-sales-order";

    getProductCSO = "/consignment/get-product-so";

    getLocator = "/consignment/get-locator";

    getLocatorMM = "/mm/get-locator";

    create = "/consignment/create-c-picking-detail";

    listAll = "/consignment/list-c-picking-detail";

    detail = "/consignment/detail-c-picking-detail";

    edit = "/consignment/edit-picking-detail";

    constructor() {
        super();
    }

    Edit<T extends any>(data: EditPickingDetail, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailPickingList>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreatePickingList, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    ListAll<T extends ListPickingList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAll,
            });
            return req;
        });
    }

    GetLocatorMM<T extends LocatorData[]>(param: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocatorMM,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetLocator<T extends LocatorData[]>(param: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetProductCSO<T extends ProductCSO[]>(param: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductCSO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetCSalesOrder<T extends ListCSO[]>(param: { partner_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCSalesOrder,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const cPickingDetailService = new CPickingDetailService();
export default cPickingDetailService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataOrder } from "modules/master-data/order/models";
import { FilterDataPrice } from "modules/master-data/price/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface AddPrice {
    product_id: number;
    warehouse_id: number;
    price: number;
}
export interface EditPrice {
    id: number;
    warehouse_id: number;
    price: number;
}

export interface SuggestProduct {
    id: number;
    product_name: string;
    product_code: string;
}

export interface DetailProduct {
    id: number;
    product_desc: string;
    molding_code: string;
    status: string;
    brand_name: string;
    brand_code: string;
    model_name: string;
    model_code: string;
    model_year: string;
    category_name: string;
    category_type: string;
    subcategory_name: string;
    subcategory_code: string;
    style_name: string;
    style_code: string;
    attribute_name: string;
    attribute_code: string;
    subattribute_name: string;
    subattribute_code: string;
    images: string[];
}

export interface HistoryList {
    id?: number;
    doc_id?: number;
    doc_no?: string;
    doc_type?: number;
    created_at?: string;
    qtyM?: number;
    priceM?: number;
    totalM?: number;
    qtyK?: number;
    priceK?: number;
    totalK?: number;
    qtyS?: number;
    priceS?: number;
    totalS?: number;
    created_by?: string;
}

export interface StockDetail {
    product_id?: number;
    product_code?: string;
    product_name?: string;
    warehouse_id?: number;
    warehouse_name?: string;
    qty?: number;
    list_stock?: HistoryList[];
}

export interface DetailProductEdit {
    id: number;
    product_id: number;
    product_desc: string;
    molding_code: string;
    status: string;
    brand_name: string;
    brand_code: string;
    model_name: string;
    model_code: string;
    model_year: string;
    category_name: string;
    category_type: string;
    subcategory_name: string;
    subcategory_code: string;
    style_name: string;
    style_code: string;
    attribute_name: string;
    attribute_code: string;
    subattribute_name: string;
    subattribute_code: string;
    images: string;
    product_name: string;
    product_code: string;
    price: number;
    warehouse_id: number;
}

export interface StockList {
    product_id?: number;
    product_code?: string;
    product_name?: string;
    quantity?: number;
    list_area?: string[];
}

class KartuStokService extends BaseService {
    getAll = "/report-accounting/all-quantity";

    search = "/product-price/search-price";

    filter = "/product-price/filter-price";

    delete = "/product-price/delete-price";

    warehouse = "/report-accounting/get-all-warehouse";

    addPrice = "/product-price/add-price";

    suggestProduct = "/product-price/suggest-product";

    detailStock = "/report-accounting/kartu-stok";

    detailProductEdit = "/product-price/detail-product-edit";

    editPrice = "/product-price/edit-price";

    constructor() {
        super();
    }

    EditPrice<T extends any>(data: EditPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editPrice,
                data,
            });
            return req;
        });
    }

    DetailProductEdit<T extends DetailProductEdit>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProductEdit,
                config: { params },
            });
            return req;
        });
    }

    DetailStock<T extends StockDetail>(params: { product_id: any; warehouse_id: any; start_date?: any; end_date?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailStock,
                config: { params },
            });
            return req;
        });
    }

    SuggestProduct<T extends SuggestProduct[]>(params: { product_name_code?: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestProduct,
                config: { params },
            });
            return req;
        });
    }

    AddPrice<T extends any>(data: AddPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.addPrice,
                data,
            });
            return req;
        });
    }

    GetAll<T extends StockList>(param: { page: any; warehouse_id: any; param_search?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataPrice>(param: { page: any; param_search: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataPrice>(param: FilterDataPrice) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Warehouse<T extends Models.AllWarehouse[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.warehouse,
            });
            return req;
        });
    }
}

const kartuStokService = new KartuStokService();
export default kartuStokService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataUser } from "modules/user-magement/user/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface User {
    id: number;
    full_name: null | string;
    email: string;
    phone_number: null | string;
    address: null | string;
    profile_image: null | string;
    dob: null | string;
    gender: number;
    is_verified_phone: number;
    status: number;
}

export interface AppsData {
    id?: number;
    name?: string;
    description?: string;
    icon?: string;
    theme_color?: string;
    is_active?: number;
}

export interface UserRoleMenu {
    id?: number;
    user_id?: number;
    rm_id?: any[];
    // is_active?: number;
}

export interface EditRoleMenu {
    id?: number | null;
    role_menu_id: number;
    is_active: number;
}

export interface EditUserRoleMenuData {
    user_id: any;
    role_menu: EditRoleMenu[];
}

export interface UserRoleMenuList {
    user_id?: number;
    email?: string;
    apps_name?: string;
}

export interface UserData {
    id?: number;
    email?: string;
    account_id?: number;
    account?: string;
}

export interface RoleMenuData {
    id?: number;
    description?: string;
    role_name?: string;
    apps_name?: string;
}

export interface DetailGetRoleMenu {
    id?: number;
    role_menu_id?: number;
    description?: string;
    apps_name?: string;
    list_menu?: string;
    is_active?: number;
}

export interface DetailUserRoleMenu {
    user_id?: number;
    email?: string;
    list_role_menu?: DetailGetRoleMenu[];
}

class UserManagementService extends BaseService {
    getAll = "/manage/user/get-all-user";

    search = "/manage/user/search-user";

    filter = "/manage/user/filter-user";

    detail = "/manage/user/get-detail-user";

    listApps = "/manage/list-apps";

    createApps = "/manage/create-apps";

    createUserRoleMenu = "/manage/create-user-role-menu";

    editUserRoleMenu = "/manage/edit-user-role-menu";

    listUserRoleMenu = "/manage/list-user-role-menu";

    getUser = "/manage/get-user";

    listRoleMenu = "/manage/list-role-menu";

    detailUserRoleMenu = "/manage/detail-user-role-menu";

    detailGetRoleMenu = "/manage/detail-get-role-menu";

    constructor() {
        super();
    }

    EditUserRoleMenu<T extends any>(data: EditUserRoleMenuData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.editUserRoleMenu,
                data,
            });
            return req;
        });
    }

    DetailUserRoleMenu<T extends DetailUserRoleMenu>(param: { user_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailUserRoleMenu,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailGetRoleMenu<T extends DetailGetRoleMenu[]>(param: { id?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailGetRoleMenu,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListRoleMenu<T extends RoleMenuData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listRoleMenu,
            });
            return req;
        });
    }

    GetUser<T extends UserData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getUser,
            });
            return req;
        });
    }

    CreateUserRoleMenu<T extends any>(data: Omit<UserRoleMenu, "id">) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createUserRoleMenu,
                data,
            });
            return req;
        });
    }

    ListUserRoleMenu<T extends UserRoleMenuList>(param: { query?: any; page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listUserRoleMenu,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    EditApps<T extends any>(data: AppsData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createApps,
                data,
            });
            return req;
        });
    }

    CreateApps<T extends any>(data: Omit<AppsData, "id">) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createApps,
                data,
            });
            return req;
        });
    }

    ListApps<T extends AppsData[]>(params: { query?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listApps,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends User>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends Models.UserManagementUser>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.UserManagementUser>(param: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.UserManagementUser>(param: FilterDataUser) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const userManagementService = new UserManagementService();
export default userManagementService;

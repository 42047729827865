/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { message } from "antd";
import clsx from "clsx";
import { HTMLMotionProps, motion } from "framer-motion";
import LoaderEntity from "modules/manufacture/components/loader-entity";
import { Entities, ManufactureContext, checkIfTextIsEmpty, findEntity } from "modules/manufacture/context/context";
import RenderEntities from "modules/manufacture/draw/render-entities";
import { MenuTypes, animateEntity, cardOperatorItemInfo, operatorIcons, setIdElementEntity } from "modules/manufacture/utils/constant";
import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import Operator from "./operator";
import PriceTag from "modules/manufacture/components/price-tag";

type CardMultipleOperatorDrawProps = Omit<HTMLMotionProps<"div">, "children"> & {
    attach?: Entities;
    asChild?: boolean;
    children: (totalChild: number) => React.ReactNode;
};

const CardMultipleOperatorDraw = ({ className, asChild, attach, children, ...props }: CardMultipleOperatorDrawProps) => {
    const {
        entities,
        clickEntityToFocus,
        setCurrentDraggingEntity,
        expandEntities,
        entitiesWithTotal,
        activeEntity,
        clickEntityToZIndex,
        activeEntitiesMenu,
    } = React.useContext(ManufactureContext) as any;

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.multipleOperator.type,
            item: { ...MenuTypes.multipleOperator, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
        }),
        [attach?.left, attach?.top, attach?.idEntity, entities]
    );

    useEffect(() => {
        setCurrentDraggingEntity(isDragging ? attach : null);
    }, [isDragging]);

    const Icon = operatorIcons.find((icon) => icon.id === attach?.data?.icon)?.icon as React.ReactNode;

    const childrenEntity = attach?.data?.children?.map((id) => findEntity(entities, id)).filter(Boolean);

    const grandTotal = entitiesWithTotal?.find((ent: any) => ent?.idEntity === attach?.idEntity)?.total;

    const isPartOfActive = (() => {
        if (!activeEntitiesMenu.length) return true;
        return activeEntitiesMenu.includes(attach?.idEntity);
    })();

    const onClick = (e: any) => {
        e.stopPropagation();
        if (checkIfTextIsEmpty(entities, activeEntity?.idEntity)) {
            message.error("Title can't be empty!");
            return;
        }
        clickEntityToFocus(attach);
        clickEntityToZIndex(attach);
    };

    const classnameContainer = clsx(
        className,
        cardOperatorItemInfo.style.className,
        "border border-solid border-gray-300 hover:shadow-lg transition-shadow w-fit duration-100 cursor-pointer px-3 py-2 flex gap-3 flex-col items-center",
        !isPartOfActive && "!bg-gray-400 !border-gray-500",
        !attach?.idEntityParent && "shadow-md",
        !asChild && "absolute transform",
        activeEntity?.idEntity === attach?.idEntity ? "outline-card-entity" : ""
    );

    const isExpand = expandEntities.find((id: string) => id === attach?.idEntity);

    if (isDragging) return <div />;
    return (
        <motion.div
            animate={asChild ? {} : animateEntity.animate}
            initial={asChild ? {} : animateEntity.initial}
            transition={asChild ? {} : animateEntity.transition}
            exit={animateEntity.exit}
            role="button"
            tabIndex={0}
            id={setIdElementEntity(attach?.idEntity)}
            onClick={onClick}
            ref={drag as any}
            style={{ left: attach!.left!, top: attach!.top!, zIndex: attach!.zIndex! }}
            className={classnameContainer}
            {...props}
        >
            <LoaderEntity />
            {children(childrenEntity?.length || 0)}
            <div className="w-fit flex items-start gap-2 justify-between">
                {!isExpand ? (
                    <>
                        {childrenEntity?.map((ent) => (
                            <RenderEntities asChild entities={[ent!]} key={ent?.idEntity} />
                        ))}
                    </>
                ) : (
                    <div
                        className={clsx(
                            "min-w-[100px] min-h-[50px] rounded shadow-inner relative flex items-center justify-center",
                            cardOperatorItemInfo.style.secondaryColor,
                            !isPartOfActive && "!bg-gray-400 !border-gray-500"
                        )}
                    >
                        <p className="text-gray-600 text-center font-medium m-0">{childrenEntity?.length} Items</p>
                    </div>
                )}
                <span className="text-xl">{Icon}</span>
                <Operator attach={attach} />
            </div>
            {childrenEntity?.length ? (
                <div className="self-start font-light text-sm">
                    Total: <PriceTag price={grandTotal} />
                </div>
            ) : null}
        </motion.div>
    );
};

export default CardMultipleOperatorDraw;

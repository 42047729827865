/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ProductTransfer } from "services/api-endpoints/dashboard/master-data/material-movement";

type Props<T> = Omit<EditTableProps<T>, "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends ProductTransfer>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                dataIndex: "product_code",
                title: "Code",
                render: (text) => text,
            },
            {
                dataIndex: "product_name",
                title: "Name",
                render: (text) => text,
            },
            {
                title: "Qty Transfer",
                width: "100px",
                dataIndex: "qty_to",
                ...{ editable: true },
                render: (text) => text || "",
            },
            {
                dataIndex: "area_name",
                title: "Locator From",
                render: (text) => text || "-",
            },
            {
                title: "Locator To",
                width: "130px",
                dataIndex: "locator_to",
                ...{ editable: true },
                render: (text) => text || "",
            },
        ];

        const editInputType: EditTableProps<T>["editInputType"] = { qty_to: "number", locator_to: "select" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: editRow?.qty_from,
                    ...props?.cellProps,
                }}
                columns={columns}
            />
        );
    };
}

const TableEditProduct = tableEdit(EditTable);
export default TableEditProduct;

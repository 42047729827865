import { Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { AllModelType, SelectOption } from "models";
import React from "react";
import { useMutation, useQuery } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
    warehouseId?: string | null;
};

const FilterQuantity = ({ filter = [], warehouseId, ...props }: Props) => {
    const brandNameQuery = useQuery([brandService.getAllBrand], async () => {
        const req = await brandService.GetAllBrand();
        return req.data.data?.map((el) => ({ label: el.brand_name, value: el.id } as SelectOption));
    });

    const modelNameQuery = useMutation([modelService.getAllModel], async (brandId: number) => {
        const req = await modelService.GetAllModel({ brand_id: brandId });
        return req.data.data?.map((el) => ({ label: el.model_name, value: el.id } as SelectOption));
    });

    const modelTypeQuery = useMutation([modelTypeService.getAllModelType], async (modelId: number) => {
        const req = await modelTypeService.GetAllModelType({ model_id: modelId });
        return req.data.data?.map((el) => ({ ...el, label: el.name, value: el.name } as SelectOption & AllModelType));
    });

    const areaQuery = useQuery(
        [quantityService.allArea, warehouseId],
        async () => {
            const req = await quantityService.AllArea({ warehouse_id: warehouseId });
            return req.data.data?.map((el) => ({ label: el.area_code, value: el.area_id } as SelectOption));
        },
        {
            enabled: !!warehouseId,
        }
    );

    return (
        <FilterModal
            filter={filter}
            title="Filter Quantity"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input
                            placeholder="Product Name"
                            value={dt.values.product_name}
                            name="product_name"
                            onChange={(e) => dt.setOneValue("product_name", e.target.value)}
                        />
                        <Input
                            placeholder="Product Code"
                            value={dt.values.product_code}
                            name="product_code"
                            onChange={(e) => dt.setOneValue("product_code", e.target.value)}
                        />
                        <Input
                            placeholder="Molding Code"
                            value={dt.values.molding_code}
                            name="molding_code"
                            onChange={(e) => dt.setOneValue("molding_code", e.target.value)}
                        />
                        <Select
                            value={dt.values.brand_name}
                            loading={brandNameQuery.isLoading}
                            placeholder="Brand"
                            options={brandNameQuery.data || []}
                            onChange={(value) => {
                                const brand = brandNameQuery.data?.find((val) => val.value === value);
                                dt.setOneValue("brand_id", value);
                                dt.setOneValue("model_id", undefined);
                                dt.setOneValue("model_type_id", undefined);
                                modelNameQuery.mutate(brand?.value);
                            }}
                        />
                        <Select
                            value={dt.values.model_name}
                            loading={modelNameQuery.isLoading}
                            placeholder="Model"
                            options={modelNameQuery.data || []}
                            onChange={(value) => {
                                const model = modelNameQuery.data?.find((val) => val.value === value);
                                dt.setOneValue("model_id", value);
                                dt.setOneValue("model_type_id", undefined);
                                modelTypeQuery.mutate(model?.value);
                            }}
                        />
                        <Select
                            value={dt.values.model_type_name}
                            loading={modelTypeQuery.isLoading}
                            placeholder="Model Type"
                            options={modelTypeQuery.data || []}
                            onChange={(value) => {
                                const model = modelTypeQuery.data?.find((val) => val.name === value);
                                dt.setOneValue("model_type_id", model?.type_id);
                                dt.setOneValue("model_type_name", value);
                            }}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.area_id}
                            loading={areaQuery.isLoading}
                            placeholder="Area"
                            options={areaQuery.data || []}
                            optionFilterProp="label"
                            onChange={(value) => dt.setOneValue("area_id", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterQuantity;

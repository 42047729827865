import { Icon } from "@mui/material";
import { Button, message, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import { ListMenu } from "services/api-endpoints/dashboard/master-data/role-menu";
import RoleChildMenuTable from "./table-child-menu";
import { BiPlus } from "react-icons/bi";
import ModalAddRoleChildMenu from "./modal-child-menu";
import React from "react";
import { ItemRowRoleMenu } from "./table-menu";

export type ItemRowRoleSubMenu = Pick<ListMenu, "name" | "key" | "icon"> & { id?: any } & {
    collapse?: ItemRowRoleSubMenu[];
};

export type RoleSubMenuTableProps = {
    list?: ItemRowRoleSubMenu[];
    setMenus: React.Dispatch<React.SetStateAction<ItemRowRoleMenu[]>>;
};

const RoleSubMenuTable = ({ list, setMenus }: RoleSubMenuTableProps) => {
    const onChangeChildSubMenu = (child: ItemRowRoleMenu | undefined, parentKey: string) => {
        if (child) {
            setMenus((prev) => {
                return prev.map((menu) => {
                    return {
                        ...menu,
                        collapse: menu?.collapse?.map((submenu) => {
                            if (submenu.key !== parentKey) return submenu;

                            if (submenu?.collapse?.find((c) => c.key === child.key)) {
                                message.error(`Child menu ${child.name} is already inserted`);
                                return submenu;
                            }

                            return {
                                ...submenu,
                                collapse: [...(submenu?.collapse || []), child],
                            };
                        }),
                    };
                });
            });
        }
    };

    const onDelete = (key: string) => {
        setMenus((prev) => prev?.map((menu) => ({ ...menu, collapse: menu?.collapse?.filter((submenu) => submenu.key !== key) })));
    };

    const columns: ColumnsType<ItemRowRoleSubMenu> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Sub menu name",
            dataIndex: "name",
        },
        {
            title: "Sub menu key",
            dataIndex: "key",
        },
        {
            title: "Icon",
            dataIndex: "icon",
            render: (val) => (
                <div className="flex items-center gap-2">
                    {val && <Icon fontSize="medium">{val}</Icon>}
                    {val}
                </div>
            ),
        },
        {
            title: "Action",
            width: "100px",
            render: (_, record) => (
                <Space>
                    <ModalAddRoleChildMenu keySubmenu={record.key} onSubmit={(val) => onChangeChildSubMenu(val, record.key)}>
                        {({ openModal }) => (
                            <Button onClick={openModal} type="text" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Child Menu
                            </Button>
                        )}
                    </ModalAddRoleChildMenu>
                    <ButtonAccessDelete onClick={() => onDelete(record.key)} danger type="text" />
                </Space>
            ),
        },
    ];

    const childTable = {
        expandedRowRender: (rec: ItemRowRoleSubMenu) => {
            if (!rec?.collapse || !rec?.collapse?.length) return null;
            return (
                <div className="w-full my-5">
                    <RoleChildMenuTable setMenus={setMenus} list={rec.collapse} />
                </div>
            );
        },
        rowExpandable: (rec: ItemRowRoleSubMenu) => !!rec.key,
        defaultExpandAllRows: true,
    };

    return (
        <Table
            expandable={childTable}
            rowKey={(record) => record.key!}
            size="small"
            columns={columns}
            dataSource={list || []}
            className="w-full second-table-branch"
            pagination={false}
        />
    );
};

export default RoleSubMenuTable;

import React from "react";
import { List, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FORMAT_DATE_TIME, STATUS_BP_ORDER, VIEW_PATH } from "utils/constant";
import moment from "moment";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponse } from "models";
import { BPGRList } from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const BPGoodsReceiptTable = <T extends BPGRList>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: BPGRList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        // {
        //     title: "PL No",
        //     dataIndex: "pl_no",
        //     render: (_, record) =>
        //         record.pl_no?.includes(null) ? (
        //             <p>-</p>
        //         ) : (
        //             <List
        //                 size="small"
        //                 bordered
        //                 dataSource={record.pl_no || []}
        //                 renderItem={(item: string) => <List.Item className="text-xs">{item}</List.Item>}
        //             />
        //         ),
        // },
        {
            title: "Doc No",
            dataIndex: "gr_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Request From",
            dataIndex: "reques_from",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status Order",
            dataIndex: "status_order",
            render: (text) => <p className="capitalize m-0">{STATUS_BP_ORDER.find((val) => val.value === text)?.label}</p>,
        },
        // {
        //     title: "Driver Name",
        //     dataIndex: "driver_name",
        //     render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        // },
        {
            title: "Created Date",
            dataIndex: "date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1300 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default BPGoodsReceiptTable;

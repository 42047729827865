import { v4 as uuid } from "uuid";
import CardMultipleOperatorDraw from "../card/multiple-operator/draw";
import CardOperatorDraw from "../card/operator/draw";
import CardProcessDraw, { MultipleProcessEntity } from "../card/process/draw";
import CardSubProcessDraw from "../card/sub-process/draw";
import HeaderEntity from "../components/header-entity";
import { Entities } from "../context/context";
import { MenuTypes } from "../utils/constant";

type RenderEntitiesProps = { entities: Entities[]; asChild?: boolean };

const RenderEntities = ({ entities, asChild }: RenderEntitiesProps) => {
    return (
        <>
            {entities.map((entity) => {
                if (!asChild && entity?.idEntityParent) return <div className="" key={uuid()} />;
                if (entity?.type === MenuTypes.multipleOperator.type) {
                    return (
                        <CardMultipleOperatorDraw asChild={asChild} key={entity.idEntity + entity.id} attach={entity}>
                            {(totalChild) => (
                                <HeaderEntity canExpand={!!totalChild} idEntity={entity?.idEntity}>
                                    <span className="font-light">{entity?.data?.text?.trim() || "( empty title )"}</span>
                                </HeaderEntity>
                            )}
                        </CardMultipleOperatorDraw>
                    );
                }
                if (entity?.type === MenuTypes.operator.type) {
                    return (
                        <CardOperatorDraw asChild={asChild} key={entity.idEntity + entity.id} attach={entity}>
                            <span className="font-light">{entity?.data?.text?.trim() || "( empty title )"}</span>
                        </CardOperatorDraw>
                    );
                }
                if (entity?.type === MenuTypes.subProcess.type) {
                    return (
                        <CardSubProcessDraw asChild={asChild} key={entity.idEntity + entity.id} attach={entity}>
                            {(totalChild) => (
                                <HeaderEntity canExpand={!!totalChild} idEntity={entity?.idEntity}>
                                    <span className="font-light">{entity?.data?.text?.trim() || "( empty title )"}</span>
                                </HeaderEntity>
                            )}
                        </CardSubProcessDraw>
                    );
                }
                if (entity?.type === MenuTypes.process.type) {
                    return (
                        <CardProcessDraw asChild={asChild} key={entity.idEntity + entity.id} attach={entity as MultipleProcessEntity}>
                            <span className="font-light">{entity?.data?.text?.trim() || "( empty title )"}</span>
                        </CardProcessDraw>
                    );
                }
                return null;
            })}
        </>
    );
};

export default RenderEntities;

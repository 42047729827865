import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Select, Steps } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import workingOrderMService, { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import * as yup from "yup";
import TableProductsPOM from "../add/table-products-pom";
import { TableBoxType } from "../add/table-box";
import { v4 as uuid } from "uuid";

export type Props = ModalProps & {
    products: ProductPOM[];
    onAddBox: (box: TableBoxType) => void;
    boxes: TableBoxType[];
};

export type SchemaFormBox = {
    box_no: string;
    machine_id: number;
    operator_id: number;
    machine_no: any;
    operator_name: any;
};

const schema: yup.SchemaOf<Omit<SchemaFormBox, "machine_no" | "operator_name">> = yup.object().shape({
    box_no: yup.string().required("Box Number required"),
    machine_id: yup.number().required("Machine Number required"),
    operator_id: yup.number().required("Operator required"),
});

const AddBoxModal = ({ children, products, boxes, onAddBox, ...props }: Props) => {
    const closeRef = React.useRef<HTMLButtonElement | null>(null);
    const [step, setStep] = React.useState(0);
    const [selectProducts, setSelectProducts] = React.useState<ProductPOM[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setError } = useForm<SchemaFormBox>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const values = watch();

    const machineQuery = useQuery([workingOrderMService.getMachine], async () => {
        return (await workingOrderMService.GetMachine()).data.data;
    });

    const machines = machineQuery.data?.map((el) => ({ label: el.machine_no, value: el.id }));

    const operatorQuery = useQuery([workingOrderMService.getOperator], async () => {
        return (await workingOrderMService.GetOperator()).data.data;
    });

    const operators = operatorQuery.data?.map((el) => ({ label: el.operator_name, value: el.operator_id }));

    const onChangeStep = (value: number) => {
        setStep(value);
    };

    const onSubmitStep = handleSubmit((data) => {
        if (boxes.find((b) => b.box_no === data.box_no)) {
            message.error("Box No already register");
            setError("box_no", { message: "Already register" });
            return;
        }

        setStep(1);
    });

    const onNext = async () => {
        await form.validateFields();
        form.submit();
    };

    const onChangeSelectProduct = (ids: number[]) => {
        setSelectProducts(products?.filter((p) => ids.includes(p.product_id)));
    };

    const productAlreadyUse = boxes?.reduce((prdtcs: ProductPOM[], box) => {
        let tempPrdtcs = [...prdtcs];
        box.detail.forEach((p) => {
            if (tempPrdtcs.find((tempP) => tempP.product_id === p.product_id)) {
                tempPrdtcs = [...tempPrdtcs].map((tempP) => {
                    if (tempP.product_id !== p.product_id) return tempP;
                    return {
                        ...tempP,
                        qty_sisa: (tempP.qty_sisa || 0) + (p.qty_sisa || 0),
                    };
                });
            } else {
                tempPrdtcs = [...tempPrdtcs, p];
            }
        });

        return tempPrdtcs;
    }, []);

    const productsOption = products?.map((p) => {
        const product = productAlreadyUse.find((prd) => prd.product_id === p.product_id);
        const disabled = product && product?.qty === product?.qty_sisa;
        return { label: p.product_code1, value: p.product_id, disabled };
    });

    const onRemoveProduct = (pd: ProductPOM) => {
        setSelectProducts((pds) => pds?.filter((p) => p.product_id !== pd.product_id));
    };

    const onClickAdd = () => {
        const qtyOverflow = document.querySelectorAll(".qty-overflow");
        if (qtyOverflow.length) {
            message.error(`${qtyOverflow.length} product(s) quantity overflow, please check each product quantity`);
            return;
        }

        onAddBox({
            id: uuid(),
            ...values,
            machine_no: machines?.find((m) => m.value === values?.machine_id)?.label,
            operator_name: operators?.find((o) => o.value === values?.operator_id)?.label,
            detail: selectProducts,
        });
        setSelectProducts([]);
        setStep(0);
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} width={800} title="Add Box" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Steps current={step} onChange={onChangeStep} items={[{ title: "Box No" }, { title: "Product", disabled: true }]} />
                    {step === 0 && (
                        <Form form={form} layout="vertical" onFinish={onSubmitStep}>
                            <div className="grid grid-cols-2 gap-x-4">
                                <ControlledInputText control={control} name="box_no" placeholder="Box no" label="Box No" />
                                <ControlledSelectInput
                                    control={control}
                                    options={machines}
                                    loading={machineQuery.isLoading}
                                    name="machine_id"
                                    placeholder="Machine no"
                                    label="Machine No"
                                />
                                <ControlledSelectInput
                                    control={control}
                                    options={operators}
                                    loading={operatorQuery.isLoading}
                                    name="operator_id"
                                    placeholder="Operator"
                                    label="Operator"
                                />
                            </div>
                        </Form>
                    )}
                    {step === 1 && (
                        <div className="flex flex-col gap-2">
                            <div className="grid grid-cols-4">
                                <p className="m-0 text-sm">Box No</p>
                                <p className="m-0 text-sm font-semibold">{values?.box_no}</p>
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm">Machine No</p>
                                <p className="m-0 text-sm font-semibold">{machines?.find((el) => el.value === values?.machine_id)?.label}</p>
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm">Operator</p>
                                <p className="m-0 text-sm font-semibold">{operators?.find((el) => el.value === values?.operator_id)?.label}</p>
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm" />
                                <p className="m-0 text-sm mt-4">Select product</p>
                                <Select
                                    value={selectProducts.map((p) => p.product_id)}
                                    onChange={onChangeSelectProduct}
                                    options={productsOption}
                                    showSearch
                                    mode="multiple"
                                    placeholder="select products"
                                    className="col-span-3 !mt-4"
                                />
                            </div>
                            <TableProductsPOM
                                boxes={boxes}
                                list={selectProducts}
                                removeItemList={onRemoveProduct}
                                onSetList={(list) => setSelectProducts(list)}
                                pagination={false}
                            />
                        </div>
                    )}
                    <div className="w-full flex justify-between">
                        {step !== 0 ? (
                            <Button onClick={() => setStep(0)} type="text">
                                Back
                            </Button>
                        ) : (
                            <p />
                        )}
                        {step === 0 && (
                            <Button onClick={onNext} type="primary">
                                Next
                            </Button>
                        )}
                        {step === 1 && (
                            <Button disabled={selectProducts.length === 0} onClick={onClickAdd} type="primary">
                                Add
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </ModalTemplate>
    );
};

export default AddBoxModal;

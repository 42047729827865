/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataModel, DetailDataModel, EditDataModel, FilterDataModel } from "modules/master-data/model/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export type ModelType = {
    model_type_id: number;
    model_type_name: string;
};

class ModelService extends BaseService {
    getAll = "/master-model/all-models";

    delete = "/master-model/delete-model";

    search = "/master-model/search-model";

    suggestModelName = "/master-model/suggest-model-name";

    suggestModelCode = "/master-model/suggest-model-code";

    brandInformation = "/master-model/brand-information";

    filter = "/master-model/filter-model";

    getAllModel = "/master-model/get-all-model";

    parentName = "/master-model/parent-name";

    create = "/master-model/create-model";

    detail = "/master-model/get-detail-model";

    edit = "/master-model/edit-model";

    listModelType = "/master-model/list-model-type";

    constructor() {
        super();
    }

    ListModelType<T extends ModelType[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listModelType,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataModel, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataModel>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataModel, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataModel>(param: Models.ModelParam) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataModel>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestModelName<T extends string[]>(param: { model_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestModelName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestModelCode<T extends string[]>(param: { model_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestModelCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    BrandInformation<T extends Models.BrandInformation[]>(param: { brand_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.brandInformation,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataModel>(param: FilterDataModel) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAllModel<T extends Models.AllModel[]>(params: { brand_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllModel,
                config: { params },
            });
            return req;
        });
    }

    ParentName<T extends Models.ParentName[]>(param: { parent_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.parentName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const modelService = new ModelService();
export default modelService;

import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import { useQuery } from "react-query";
import rekapService from "services/api-endpoints/dashboard/master-data/rekap";
import { FORMAT_DATE_1, OFFLINE_STATUS_FILTER } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterOffline = ({ filter = [], ...props }: Props) => {
    const getSalesQuery = useQuery([rekapService.getOfflineSales], async () => {
        return (await rekapService.GetOfflineSales()).data.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Offline"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.sales_id ? Number(dt.values.sales_id) : null}
                            placeholder="Sales Name"
                            options={getSalesQuery.data}
                            loading={getSalesQuery.isLoading}
                            onChange={(value) => dt.setOneValue("sales_id", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.order_status ? Number(dt.values.order_status) : null}
                            placeholder="Status Order"
                            options={OFFLINE_STATUS_FILTER}
                            onChange={(value) => dt.setOneValue("order_status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterOffline;

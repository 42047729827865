/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, DatePicker, Select } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import moment from "moment";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import { FORMAT_DATE_1, VIEW_PATH } from "utils/constant";

export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const WAREHOUSE_ID = "warehouse_id";

const FastMovingProduct = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouse = searchParams.get(WAREHOUSE_ID);
    const startDate = searchParams.get(START_DATE);
    const endDate = searchParams.get(END_DATE);

    const changeWarehouse = ({ id }: { id: any }) => {
        searchParams.set(WAREHOUSE_ID, id);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.warehouse, warehouse],
        async () => {
            return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            enabled: warehouse !== undefined,
        }
    );

    const viewHandler = () => {
        navigate(location.pathname + VIEW_PATH + location.search);
    };

    const onChangeDate = (val: moment.Moment[]) => {
        searchParams.set(START_DATE, val?.[0]?.format(FORMAT_DATE_1) || "");
        searchParams.set(END_DATE, val?.[1]?.format(FORMAT_DATE_1) || "");
        setSearchParams(searchParams);
    };

    return (
        <div className="flex flex-col gap-6 items-center justify-center w-full">
            <div className="w-[700px] rounded-2xl bg-white border border-solid border-gray-300 p-7 flex flex-col gap-5">
                <label htmlFor="warehouse" className="flex flex-col gap-2">
                    Warehouse Name
                    <Select
                        id="warehouse"
                        showSearch
                        value={warehouse ? Number(warehouse) : undefined}
                        loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                        placeholder="Warehouse"
                        options={getWarehouse.data || []}
                        onChange={(val) => changeWarehouse({ id: val })}
                    />
                </label>
                <label htmlFor="exclude-locator" className="flex flex-col gap-2">
                    Date Range
                    <DatePicker.RangePicker
                        format="DD MMM yyyy"
                        allowClear
                        allowEmpty={[true, true]}
                        value={[startDate ? moment(startDate, FORMAT_DATE_1) : null, endDate ? moment(endDate, FORMAT_DATE_1) : null]}
                        placeholder={["Start Date", "End Date"]}
                        onChange={onChangeDate as any}
                    />
                </label>
                <div className="w-full flex justify-end">
                    <Button disabled={!warehouse} onClick={viewHandler} className="!flex !items-center w-fit" type="primary">
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FastMovingProduct;

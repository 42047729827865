/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import masterBpService, { Agent, CreateBP } from "services/api-endpoints/accounting/master-bp";

type SelectOptionCustom = SelectOption & Agent;

export interface Props extends ControlledInputSelectProps<CreateBP> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withBP<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([masterBpService.getAgent], async () => {
            const req = await masterBpService.GetAgent();
            return req.data.data?.map((el) => ({ ...el, label: el.business_partner_name, value: el.business_partner_id } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.business_partner_id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const BpSelect = withBP(ControlledSelectInput);

export default BpSelect;

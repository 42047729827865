/* eslint-disable no-shadow */
import { Alert, Card, Image, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import React from "react";
import { useQuery } from "react-query";
import State from "components/common/state";
import { useSearchParams } from "react-router-dom";
import { FORMAT_DATE_TIME, GENDER, IMAGE_FALLBACK, STATUS_ACTIVE, STATUS_VERIFIED } from "utils/constant";
import moment from "moment";
import userManagementService from "services/api-endpoints/dashboard-all/user-management/user";

const UserView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const detailQuery = useQuery(
        [userManagementService.detail, id],
        async () => {
            return (await userManagementService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="user detail" />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4 grid-rows-2">
                                    <div className="row-span-3">
                                        <Image
                                            width={200}
                                            height={200}
                                            src={detailQuery.data?.profile_image || undefined}
                                            fallback={IMAGE_FALLBACK}
                                            className="bg-gray-200"
                                        />
                                    </div>
                                    <p className="capitalize m-0">
                                        username <br />
                                        <span className="underline italic m-0">{detailQuery.data?.full_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        email <br />
                                        <span className="lowercase italic m-0">{detailQuery.data?.email}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        phone number <br />
                                        <span className="underline italic m-0">{detailQuery.data?.phone_number}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        date of birth <br />
                                        <span className="underline italic m-0">
                                            {detailQuery.data?.dob ? moment(detailQuery.data.dob).format(FORMAT_DATE_TIME) : "-"}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        address <br />
                                        <span className="underline italic m-0">{detailQuery.data?.address}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        gender <br />
                                        <span className="underline italic m-0">
                                            {GENDER.find((el) => el.value === detailQuery.data?.gender)?.label}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        is verified phone <br />
                                        <span className="underline italic m-0">
                                            {STATUS_VERIFIED.find((el) => el.value === detailQuery.data?.is_verified_phone)?.label}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span className="underline italic m-0">
                                            {STATUS_ACTIVE.find((el) => el.value === detailQuery.data?.status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton avatar paragraph={{ rows: 3 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailQuery.data as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default UserView;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory, FilterDataQuantity } from "modules/master-data/quantity/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface FilterRecap {
    start_date: string;
    end_date: string;
    page: any;
    order_status: any;
    sales_id: any;
}

export interface RecapList {
    id?: number;
    order_no?: string;
    sub_brand?: string;
    customer_name: string;
    market_place: string;
    customer_invoice: string;
    code1: string;
    product_name: string;
    subcategory_name: string;
    attribute_name: string;
    created_at: string;
    is_active: number;
    qty: number;
    price: number;
    diskon: number;
    status: string;
    product_account?: string;
    hpp?: number;
    price_hpp?: number;
}

export interface RecapOffline {
    id: any;
    inv_no: string;
    ref_no?: string;
    code1: string;
    name: string;
    subcategory_name: string;
    product_type: string;
    created_at: string;
    is_active: number;
    note: string;
    info: string;
    payment_channel: string;
    qty: number;
    price: number;
    diskon: number;
    status: string;
    sales: string;
    hpp?: number;
    total_hpp?: number;
    price_hpp?: number;
}

export interface RecapBpSales {
    id: any;
    doc_no: string;
    code1: string;
    name: string;
    subcategory_name: string;
    product_account: string;
    created_at: string;
    payment_term: number;
    qty: number;
    price: number;
    diskon: number;
    status: string;
    bp_name: string;
    payment_status?: string;
}

export interface SalesData {
    id: number;
    name: string;
}

class RekapService extends BaseService {
    getOffline = "/report/sales-offline";

    getOnline = "/report/sales-online";

    getOfflineSales = "/giias/sales/get-sales-name";

    getBpSales = "/report/business-partner-sales";

    constructor() {
        super();
    }

    GetBPSales<T extends RecapBpSales[]>(param: FilterRecap) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBpSales,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetOfflineSales<T extends SalesData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getOfflineSales,
            });
            return req;
        });
    }

    GetOffline<T extends RecapOffline[]>(param: FilterRecap) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getOffline,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetOnline<T extends RecapList[]>(param: FilterRecap) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getOnline,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const rekapService = new RekapService();
export default rekapService;

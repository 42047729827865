/* eslint-disable no-useless-constructor */
import { BasePaginationResponse, Product } from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";
import { RowDefault } from "components/table/edit-table";

export interface SalesOrder {
    id: number;
    doc_no: string;
    partner_name: string;
    bp_name: string;
    order_status: number;
    created_at: number;
}

export interface DetailProduct extends RowDefault {
    product_id?: number;
    price?: number;
    discount_price?: number;
    voucher_id?: number;
    qty?: number;
    qty_max?: number;
    note?: string;
    is_free?: number;
    product_owner?: string;
    product_code1?: string;
    product_code?: string;
    product_name?: string;
    product_price?: number;
    is_last_stock?: number;
    is_check?: boolean;
    status?: "update" | "delete" | undefined;
}

export interface SummaryPrice {
    total_item: number;
    total_price: number;
    total_discount: number;
    grand_total: number;
    detail_product: DetailProduct[];
}

export interface SummaryPriceProduct {
    product_id?: number;
    qty?: number;
    price?: number;
}

export interface SummaryPriceParam {
    account_id_to?: number;
    detail_product?: SummaryPriceProduct[];
    voucher_id?: number | null;
}

export interface PaymentTermData {
    payment_term_id?: number;
    payment_term_code?: string;
}

export interface BusinessPartner {
    bp_id: number;
    bp_name: string;
    payment_term_id: number;
}

export interface SalesOrderDetail {
    detail_product: DetailProduct[];
    voucher_id?: number | null;
}

export interface CreateSalesOrder extends SalesOrderDetail {
    bp_id: number;
    payment_term_id: number;
    note: string;
}

export interface DetailSalesOrder {
    id?: number;
    voucher_id?: number;
    voucher_code?: string;
    doc_no?: string;
    ref_id?: number;
    ref_no?: string;
    ref_type?: number;
    account_id_to?: number;
    partner_name?: string;
    bp_id?: number;
    bp_name?: string;
    order_status?: number;
    note?: string;
    created_at?: number;
    grand_total?: number;
    total_price?: number;
    discount_price?: number;
    payment_term_code?: string;
    payment_term_id?: number;
    list_detail?: DetailProduct[];
}

export interface ReadyParam {
    id: any;
}

export interface SoOutstanding {
    id?: number;
    account_id: number;
    account_id_to: number;
    partner_name?: string;
    bp_id?: number;
    bp_name?: string;
    doc_id?: number;
    so_no?: string;
    created_at?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    code2?: string;
    product_price?: number;
    qty?: number;
    qty_req?: number;
    product_owner?: string;
    qty_stock?: number;
    is_check?: boolean;
}

export interface GenerateSOData {
    doc_id: number;
    account_id: number;
    product_id: number;
    price: number;
    qty: number;
}

export interface CheckSOReq {
    doc_id: number;
    product_id: number;
    qty: number;
}

export interface CheckItem {
    Item: string;
}

export interface CheckSOData {
    invoice: string;
    list_product: CheckItem[];
}

export interface EditProduct {
    product_id: number;
    price: number;
    qty: number;
    discount_price: number;
    voucher_id: number;
    note: string;
    is_free: number;
    is_last_stock: number;
}

export interface EditCSalesOrder {
    id: any;
    type_edit: number;
    voucher_id?: number | null;
    discount_price?: number;
    detail_product: EditProduct[];
}

export interface CreateProduct {
    product_id: number;
    price: number;
    discount_price: number;
    voucher_id: number;
    note: string;
    qty: number;
    so_id: number;
    area_id: number;
    is_free: number;
    is_last_stock?: number;
}

export interface EditPickingDetail {
    id: number;
    detail_product: CreateProduct[];
}

class ExtSalesOrderService extends BaseService {
    getAll = "/ext-order/list-so";

    paymentTerm = "/ext-order/get-payment-term";

    summaryPrice = "/ext-order/summary-price";

    getBp = "/ext-order/get-business-partner";

    create = "/ext-order/create-so";

    productPrice = "/ext-order/get-product-price";

    detail = "/ext-order/detail-so";

    readyToPicking = "/ext-order/ready-to-picking";

    listOutstanding = "ext-order/list-so-outstanding";

    generateSO = "/ext-order/edit-so-outstanding";

    checkSO = "/ext-order/cek-so-outstanding";

    edit = "/ext-order/edit-so";

    constructor() {
        super();
    }

    Edit<T extends any>(data: EditCSalesOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    CheckSO<T extends CheckSOData[]>(data: CheckSOReq[]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.checkSO,
                data,
            });
            return req;
        });
    }

    GenerateSO<T extends any>(data: GenerateSOData[]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.generateSO,
                data,
            });
            return req;
        });
    }

    ListOutstanding<T extends SoOutstanding>(param: { page: any; bp?: any; doc?: any; date?: any; code1?: any; code2?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listOutstanding,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ReadyToPicking<T extends any>(data: ReadyParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.readyToPicking,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailSalesOrder>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${data.id}`,
            });
            return req;
        });
    }

    ProductPrice<T extends Product[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.productPrice,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateSalesOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetBp<T extends BusinessPartner[]>(param: { status: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBp,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    PaymentTerm<T extends PaymentTermData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.paymentTerm}`,
            });
            return req;
        });
    }

    SummaryPrice<T extends SummaryPrice>(data: SummaryPriceParam) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.summaryPrice,
                data,
            });
            return req;
        });
    }

    GetAll<T extends SalesOrder>(param: { page: any; size: any }) {
        return this.ProxyRequest<BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const extSalesOrderService = new ExtSalesOrderService();
export default extSalesOrderService;

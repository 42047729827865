/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Form, Image, Modal, Skeleton, Tag, UploadFile } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import transferBankCabangService, { CreateTransferBankCabang } from "services/api-endpoints/dashboard/transfer-bank-cabang";
import Utils from "utils";
import { FORMAT_DATE_2, TBC_TYPE, TBC_TYPE_KIRIM } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateTransferBankCabang>> = yup.object().shape({
    bank_coa_from: yup.number().required("Bank Account From Required"),
    bank_coa_to: yup.number().required("Bank Account To Required"),
    account_id_to: yup.number().required("Cabang To Required"),
    transaction_date: yup.string().required("Transaction Date Required"),
    note: yup.string().required("Note Voucher Required"),
    amount: yup.string().required("Amount (Rp) Required"),
    expenses: yup.array(),
    bank_images: yup.array(),
    total_amount: yup.number(),
    account_id_from: yup.number(),
});

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const TransferBankCabangView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreateTransferBankCabang>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchAmount = watch("amount");

    const detailQuery = useQuery(
        [transferBankCabangService.detailTransferCabang, id],
        async () => {
            return (await transferBankCabangService.DetailTransferCabang({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("bank_coa_from", data?.bank_coa_from);
                setValue("bank_coa_to", data?.bank_coa_to);
                setValue("transaction_date", data?.transaction_date);
                setValue("note", data.note);
                setExpanses(data?.expenses);
                setUrlImgList((data.bank_images || [])?.filter((url) => url));
                setValue("amount", data?.amount);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const grandTotal = Number(String(watchAmount).replaceAll(".", "") || 0) + expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    const type = TBC_TYPE.find((val) => val.value === detailQuery.data?.type);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail transfer bank cabang" />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                                <Card className="">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline italic mt-4">{detailQuery.data?.doc_no}</span>
                                        </p>
                                        <p className="capitalize m-0 col-span-2">
                                            Cabang From <br />
                                            <span className="mt-4">{detailQuery.data?.account_name_from || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Bank Account From <br />
                                            <span className="mt-4">{detailQuery.data?.bank_from || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-6">
                                            Transaction Date <br />
                                            <span className="mt-4">{moment(detailQuery.data?.transaction_date)?.format(FORMAT_DATE_2)}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-6 col-span-2">
                                            Cabang To <br />
                                            <span className="mt-4">{detailQuery.data?.account_name_to || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-6">
                                            Bank Account To <br />
                                            <span className="mt-4">{detailQuery.data?.bank_to || "-"}</span>
                                        </p>
                                        <p className="capitalize mt-6">
                                            Note <br />
                                            <span className="mt-4">{detailQuery.data?.note}</span>
                                        </p>
                                        <p className="capitalize mt-6">
                                            Transfer Type <br />
                                            <Tag color={type?.value === TBC_TYPE_KIRIM ? "blue" : "green"}>{type?.label || "-"}</Tag>
                                        </p>
                                    </div>
                                </Card>
                                <Card className="">
                                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Amount <br />
                                            <span className="underline italic mt-4">{detailQuery.data?.amount.ToIndCurrency("Rp")}</span>
                                        </p>
                                        <div />
                                        <div className="col-span-2 flex flex-col gap-5 pr-2 mt-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                                            <div className="grid grid-cols-5 gap-4 mt-4">
                                                {detailQuery.data?.bank_images?.map((img) => (
                                                    <Image src={img} />
                                                ))}
                                            </div>
                                        </div>

                                        <div className="flex flex-col">
                                            <>
                                                {expanses.length !== 0 ? (
                                                    <div>
                                                        <p className="capitalize m-0">Expenses</p>
                                                        <ListExpenses data={expanses} />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </>
                                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                                            <p className="capitalize m-0">
                                                Total Transfer
                                                <br />
                                                <span className="font-semibold m-0">{(grandTotal || 0).ToIndCurrency("Rp")}</span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default TransferBankCabangView;

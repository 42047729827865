import { Alert, Button, Checkbox, DatePicker, Form, Select, Skeleton, Space, Table, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import consignmentService, { FilterInvoiceParams, ListInvoice } from "services/api-endpoints/dashboard/master-data/consignment";
import { C_INVOICE_FILTER, FORMAT_DATE_1, FORMAT_DATE_TIME } from "utils/constant";

type Props = ModalProps & {
    partnerID: number;
    onAddInvoice: (data: ListInvoice[]) => void;
};

const FilterInvoice = ({ children, partnerID, onAddInvoice, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [selected, setSelected] = useState<React.Key[]>([]);

    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState(10);
    const [selectAll, setSelectAll] = useState(false);

    const invoiceMutate = useMutation([consignmentService.getInvoice], async (data: FilterInvoiceParams) => {
        return (await consignmentService.GetInvoice(data)).data.data;
    });

    const onFilter = (values: { type: any; transaction_date: Moment[] }) => {
        setSelected([]);
        if (!values.type) {
            message.error("Type Penjualan Required");
            return;
        }
        if (!values.transaction_date) {
            message.error("Transaction Date Required");
            return;
        }
        const tData: FilterInvoiceParams = {
            partner_id: partnerID,
            type: values.type,
            start_date: !values.transaction_date ? undefined : moment(values.transaction_date[0]).format(FORMAT_DATE_1),
            end_date: !values.transaction_date ? undefined : moment(values.transaction_date[1]).format(FORMAT_DATE_1),
        };
        invoiceMutate.mutateAsync(tData);
    };

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
        setSize(pagination.pageSize || 10);
    };

    const onChoose = () => {
        onAddInvoice(invoiceMutate.data?.filter((el) => selected.includes(el?.id)) || []);
        closeRef.current?.click();
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelected(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<ListInvoice> = {
        selectedRowKeys: selected,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columnsFilter: ColumnsType<ListInvoice> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * size + (i + 1)}</p>,
        },
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <span className="text-sm">{text || "-"}</span>,
        },
        {
            title: "Invoice Referensi",
            dataIndex: "inv_ref",
            render: (text) => <span className="text-sm">{text || "-"}</span>,
        },
        {
            title: "Create At",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    const onChangeSelectAll = (e: any) => {
        const isChecked = e.target.checked;

        setSelected(isChecked ? (invoiceMutate.data?.map((val) => val.id) as any[]) : []);
        setSelectAll(isChecked);
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null} width={900}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form onFinish={onFilter} autoComplete="off" layout="vertical" requiredMark={false}>
                        <div className="grid w-full grid-cols-2 gap-x-5">
                            <Form.Item label="Type Penjualan" name="type">
                                <Select options={C_INVOICE_FILTER} placeholder="Type Penjualan" />
                            </Form.Item>
                            <Form.Item label="Transaction Date" name="transaction_date">
                                <DatePicker.RangePicker className="w-full" />
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Space>
                                <Button loading={invoiceMutate.isLoading} type="primary" htmlType="submit">
                                    Search
                                </Button>
                                <Checkbox checked={selectAll} onChange={onChangeSelectAll} disabled={!invoiceMutate.data}>
                                    Select All
                                </Checkbox>
                            </Space>
                        </Form.Item>
                    </Form>
                    <State data={invoiceMutate.data} isLoading={invoiceMutate.isLoading} isError={invoiceMutate.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Table
                                        rowKey={(rec) => rec?.id}
                                        rowSelection={rowSelection}
                                        columns={columnsFilter}
                                        dataSource={invoiceMutate.data}
                                        pagination={{
                                            current: page,
                                            pageSize: size,
                                            showSizeChanger: false,
                                        }}
                                        onChange={handleTableChange}
                                    />
                                    <div className="flex justify-end mt-4">
                                        <Button type="primary" onClick={onChoose}>
                                            Add
                                        </Button>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(invoiceMutate.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                </>
            )}
        </ModalTemplate>
    );
};

export default FilterInvoice;

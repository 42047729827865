import { MainMenu } from "models";
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ACTIVE_APP, VALUES_BARCODE_TO_PRINT } from "utils/constant";

type Props = {
    children: any;
};

type HistoryTab = { url: string; appName: string; label?: string; pathname: string };

type StateType = {
    historyTab?: HistoryTab[];
    activeApp?: Partial<MainMenu> | null;
    valueBarcodeToPrint?: typeof VALUES_BARCODE_TO_PRINT;
    online?: boolean;
    menuCollapsed?: boolean;
};

type ValueContextType = {
    state: StateType;
    setState?: Dispatch<SetStateAction<StateType>>;
    setActiveApp?: (app: MainMenu | null) => void;
    setHistoryTab?: (data: HistoryTab) => void;
    removeHistoryTab?: (key: string) => HistoryTab[];
    navigateAppHandler?: (app: MainMenu, route?: string) => string;
    createAccess?: boolean;
    readAccess?: boolean;
    updateAccess?: boolean;
    deleteAccess?: boolean;
    approveAccess?: boolean;
};

const StateContext = createContext<ValueContextType>({
    state: {
        activeApp: localStorage.getItem(ACTIVE_APP) ? (JSON.parse(localStorage.getItem(ACTIVE_APP) as string) as MainMenu) : null,
        valueBarcodeToPrint: VALUES_BARCODE_TO_PRINT,
        online: true,
        menuCollapsed: false,
    },
});

function StateProvider({ children }: Props) {
    const [state, setState] = useState<StateType | null>({
        activeApp: localStorage.getItem(ACTIVE_APP) ? (JSON.parse(localStorage.getItem(ACTIVE_APP) as string) as MainMenu) : null,
        valueBarcodeToPrint: VALUES_BARCODE_TO_PRINT,
        online: true,
        menuCollapsed: false,
    });

    const createAccess = state?.activeApp?.role_access?.includes("C");
    const readAccess = state?.activeApp?.role_access?.includes("R");
    const updateAccess = state?.activeApp?.role_access?.includes("U");
    const deleteAccess = state?.activeApp?.role_access?.includes("D");
    const approveAccess = state?.activeApp?.role_access?.includes("A");

    const setActiveApp = (app: MainMenu | null) => {
        setState((prev) => ({
            ...prev,
            activeApp: app,
        }));
        if (!app) {
            localStorage.removeItem(ACTIVE_APP);
            return;
        }
        localStorage.setItem(ACTIVE_APP, JSON.stringify(app));
    };

    const navigateAppHandler = (app: MainMenu, route?: string) => {
        setActiveApp(app);
        const defaultFirstRoute = app.apps_menu![0].collapse ? app.apps_menu![0].collapse[0].route : `/${app.apps_menu![0].key}`;
        return route || defaultFirstRoute || "/";
    };

    /// offline detection
    useEffect(() => {
        const offlineHandler = () => {
            setState((prev) => ({
                ...prev,
                online: false,
            }));
        };
        const onlineHandler = () => {
            setState((prev) => ({
                ...prev,
                online: true,
            }));
        };

        window.addEventListener("offline", offlineHandler);
        window.addEventListener("online", onlineHandler);

        return () => {
            window.removeEventListener("offline", offlineHandler);
            window.removeEventListener("online", onlineHandler);
        };
    }, []);

    const setHistoryTab = (data: HistoryTab) => {
        setState((prev) => {
            return {
                ...prev,
                historyTab: prev?.historyTab?.find((h) => h.pathname === data.pathname)
                    ? prev?.historyTab?.map((history) => (history?.pathname === data.pathname ? data : history))
                    : [...(prev?.historyTab || []), data],
            };
        });
    };

    const removeHistoryTab = (key: string) => {
        const rest = state?.historyTab?.filter((h) => h.pathname !== key);
        setState((prev) => ({ ...prev, historyTab: rest }));
        return rest;
    };

    const value = useMemo(
        () => ({
            state,
            setState,
            setHistoryTab,
            removeHistoryTab,
            setActiveApp,
            navigateAppHandler,
            createAccess,
            readAccess,
            updateAccess,
            deleteAccess,
            approveAccess,
        }),
        [state]
    );
    return <StateContext.Provider value={value as any}>{children}</StateContext.Provider>;
}

export { StateContext, StateProvider };

import { Space, Table } from "antd";
import React from "react";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BP_ORDER_DRAFT, BP_ORDER_NEW, EDIT_PATH, FORMAT_DATE_TIME, STATUS_BP_ORDER, VIEW_PATH } from "utils/constant";
import { TDataBpOrder } from "../models";
import ButtonAccessView from "components/button/button-access-view";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: TDataBpOrder) => void;
};

const BpOrderTable = <T extends TDataBpOrder>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: TDataBpOrder) => {
        return () => {
            navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
        };
    };

    const viewHandler = (data: TDataBpOrder) => {
        return () => {
            navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
        };
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        // {
        //     title: "Order No",
        //     dataIndex: "order_no",
        //     render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        // },
        {
            title: "Doc No",
            dataIndex: "so_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Partner Name",
            dataIndex: "bp_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        // {
        //     title: "Status Payment",
        //     dataIndex: "payment_status",
        //     render: (text) => (
        //         <p className="capitalize m-0">
        //             {STATUS_PAYMENT_ORDER?.find((payment) => payment.value === text)?.label || STATUS_PAYMENT_ORDER[0]?.label}
        //         </p>
        //     ),
        // },
        {
            title: "Status Order",
            dataIndex: "status",
            render: (text) => <p className="capitalize m-0">{STATUS_BP_ORDER.find((status) => status.value === text)?.label}</p>,
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: (text) => <p className="capitalize m-0">{Number(text || "0")?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Date",
            dataIndex: "date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {(record.status || 0) >= BP_ORDER_NEW && <ButtonAccessView onClick={viewHandler(record)} type="text" />}
                    {record.status === BP_ORDER_DRAFT && <ButtonAccessEdit onClick={editHandler(record)} type="text" />}
                    {record.status === BP_ORDER_DRAFT && <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default BpOrderTable;

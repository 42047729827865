import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { EDIT_PATH, FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";
import moment from "moment";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponseIO, TDataInventoryOut } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponseIO<T>, unknown>;
};

const InventoryOutTable = <T extends TDataInventoryOut>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: TDataInventoryOut) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: TDataInventoryOut) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Requester",
            dataIndex: "requester",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Items",
            dataIndex: "total_items",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Created Date",
            dataIndex: "created_date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.status ? (
                        <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                    ) : (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list_inventory_out || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default InventoryOutTable;

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { UseQueryResult } from "react-query";
import ModalEdit from "./modal-edit";
import { AppsData } from "services/api-endpoints/dashboard-all/user-management/user";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const TableIndex = <T extends AppsData[]>({ fetcher }: Props<T>) => {
    const columns: ColumnsType<AppsData> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Apps Name",
            dataIndex: "name",
        },
        {
            title: "Apps Description",
            dataIndex: "description",
        },
        {
            title: "Icon",
            dataIndex: "icon",
        },
        {
            title: "Theme Color",
            dataIndex: "theme_color",
        },
        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (val) => (val ? "Active" : "Non active"),
        },
        {
            width: "60px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <ModalEdit refresh={fetcher.refetch} data={record}>
                    {(ctrl) => <ButtonAccessEdit onClick={ctrl.openModal} type="text" />}
                </ModalEdit>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default TableIndex;

import React from "react";
import { Button, List, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { FORMAT_DATE_2, FORMAT_DATE_TIME } from "utils/constant";
import moment from "moment";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import { ListHistory } from "services/api-endpoints/dashboard/master-data/bp-delivery-order";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponseHistory<T>, unknown>;
    onClickLink?: (id: any) => void;
    urlNamePage?: string;
};

const BPHistoryTable = <T extends ListHistory>({ fetcher, urlNamePage = "page", onClickLink }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set(urlNamePage, pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Notes",
            dataIndex: "notes",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        // {
        //     title: "List SO",
        //     dataIndex: "list_so",
        //     render: (text, record) => {
        //         if (!text) return "-";
        //         return (
        //             <List
        //                 size="small"
        //                 bordered
        //                 dataSource={record.list_so}
        //                 renderItem={(item) => (
        //                     <List.Item onClick={() => onClickLink(item.id!)} className="text-xs cursor-pointer !text-primary">
        //                         {item.no}
        //                     </List.Item>
        //                 )}
        //             />
        //         );
        //     },
        // },
        // {
        //     title: "List PL",
        //     dataIndex: "list_pl",
        //     render: (text, record) => {
        //         if (!text) return "-";
        //         return (
        //             <List
        //                 size="small"
        //                 bordered
        //                 dataSource={record.list_pl}
        //                 renderItem={(item) => (
        //                     <List.Item onClick={() => onClickLink(item.id!)} className="text-xs cursor-pointer !text-primary">
        //                         {item.no}
        //                     </List.Item>
        //                 )}
        //             />
        //         );
        //     },
        // },
        {
            title: "List Doc",
            dataIndex: "list_history",
            render: (text, record) => {
                if (!text) return "-";
                return (
                    <List
                        size="small"
                        bordered
                        dataSource={record.list_history}
                        renderItem={(item) => (
                            <List.Item onClick={() => onClickLink && onClickLink(item.id!)} className="text-xs cursor-pointer !text-primary">
                                {item.no}
                            </List.Item>
                        )}
                    />
                );
            },
        },
    ];

    return (
        <Table
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list_history || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default BPHistoryTable;

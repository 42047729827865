import { Form, Input, InputNumber, InputNumberProps, InputProps, Select, SelectProps } from "antd";
import React from "react";

export interface Props<T> extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: "number" | "text" | "checkbox" | "select";
    record: T;
    index: number;
    children: React.ReactNode;
    minNumber?: number;
    maxNumber?: number;
    selectProps?: SelectProps;
    inputNumberProps?: InputNumberProps;
    inputTextProps?: InputProps;
}

const EditableCell = <T extends {}>({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    minNumber = 0,
    maxNumber,
    selectProps,
    inputNumberProps,
    inputTextProps,
    ...restProps
}: Props<T>) => {
    const inputNode = (() => {
        if (inputType === "number") return <InputNumber {...inputNumberProps} min={minNumber} max={maxNumber} autoFocus />;
        if (inputType === "select") return <Select {...selectProps} autoFocus />;
        return <Input {...inputTextProps} autoFocus />;
    })();

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: dataIndex !== "note",
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;

import { Button, ButtonProps, Skeleton } from "antd";
import React from "react";
import { AiFillAppstore } from "react-icons/ai";

type Props = ButtonProps & {
    description: any;
};

const Loading = () => {
    return (
        <Skeleton.Node active>
            <AiFillAppstore fontSize={25} />
        </Skeleton.Node>
    );
};

const ButtonApp = ({ description, ...props }: Props) => {
    return (
        <div className="flex flex-col items-center">
            <Button {...props} className="!w-20 !h-20 !rounded-xl !flex !items-center !justify-center" />
            <div className="m-0 mt-2 text-gray-600">{description}</div>
        </div>
    );
};

ButtonApp.Loading = Loading;

export default ButtonApp;

import { List, Popover } from "antd";
import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";

type Props = {
    data: ExpansesData[];
    onRemoveData?: (index: number) => void;
    showTextEmpty?: boolean;
    bordered?: boolean;
};

const ListExpenses = ({ data, onRemoveData, showTextEmpty, bordered }: Props) => {
    return (
        <List
            size="small"
            dataSource={data}
            bordered={bordered}
            renderItem={(item, i) => {
                return (
                    <List.Item>
                        <div className="!w-full flex items-center justify-between gap-4">
                            <p className="m-0 font-semibold text-xs capitalize line-clamp-1 w-[30%]">{item.amount?.ToIndCurrency("Rp")}</p>
                            <p className="m-0 text-xs w-[70%]">
                                {item.bp_id ? <p className="text-xs text-gray-800 font-bold mb-2">{item.bp_name}</p> : null}
                                {item?.charge_name} <br />
                                {item.note ? <p className="text-xs text-gray-400">note: {item.note}</p> : null}
                            </p>
                            {onRemoveData ? (
                                <MdOutlineDeleteOutline className="text-lg !h-5 !w-5 cursor-pointer text-red-500" onClick={() => onRemoveData(i)} />
                            ) : null}
                        </div>
                    </List.Item>
                );
            }}
        />
    );
};

export default ListExpenses;

import { Button, Card, message, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import AdvanceSearchBp from "modules/advance-search-bp";
import { ProductWithQty } from "modules/advance-search/models";
import ProductTable from "modules/dashboard-jsl/purchase-order/add/product-table";
import { TDataProductSub } from "modules/dashboard-jsl/purchase-order/models";
import { parseProductsToCreate } from "modules/dashboard-jsl/purchase-order/utils";
import { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import purchaseOrderService, { CreatePO } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { PRODUCT_JSL } from "utils/constant";
import { getDocumentRoute } from "utils/routes";

const PurchaseOrderAdd = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [productOwner, setProductOwner] = useState<number>(PRODUCT_JSL);
    const [note, setNote] = useState<string>();

    const getHeaderPOQuery = useQuery([purchaseOrderService.getHeaderPO], async () => {
        return (await purchaseOrderService.GetHeaderPO()).data.data;
    });

    const draftPoMutation = useMutation(
        [purchaseOrderService.draftPO],
        async (data: CreatePO) => {
            return (await purchaseOrderService.DraftPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal Purchase order draft created");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const createPoMutation = useMutation(
        [purchaseOrderService.createPO],
        async (data: CreatePO) => {
            return (await purchaseOrderService.CreatePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal Purchase order created");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const onSaveHandler = () => {
        createPoMutation.mutate(parseProductsToCreate(products, note));
    };

    const onDraftHandler = () => {
        draftPoMutation.mutate(parseProductsToCreate(products, note));
    };

    const onRemoveProduct = (product: ProductSub | TDataProductSub) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new purchase order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={draftPoMutation.isLoading}
                            disabled={products.length === 0}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createPoMutation.isLoading}
                            disabled={products.length === 0}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={getHeaderPOQuery.data} isLoading={getHeaderPOQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="w-full grid grid-cols-4 gap-x-4">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{getHeaderPOQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <span className="underline italic m-0">{getHeaderPOQuery.data?.request_from}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            Note <br />
                                            <TextArea
                                                value={note}
                                                name="note"
                                                placeholder="Note"
                                                onChange={(val) => setNote(val.target.value)}
                                                rows={2}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <AdvanceSearchBp
                isSPM={getHeaderPOQuery.data?.is_spm || false}
                selected={products}
                setSelected={setProducts}
                productOwner={productOwner}
                setProductOwner={setProductOwner}
            >
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearchBp>
            <ProductTable list={products} setList={setProducts} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default PurchaseOrderAdd;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataUser } from "modules/user-magement/user/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Buyer {
    id: number;
    full_name: string;
    email: string;
    phone_number: string;
    address: null | string;
    profile_image: null | string;
    dob: number;
    gender: number;
    is_verified_phone: number;
    status: number;
}

class BuyerManagementService extends BaseService {
    getAll = "/manage/buyer/get-all-buyer";

    search = "/manage/buyer/search-buyer";

    filter = "/manage/buyer/filter-buyer";

    detail = "/manage/buyer/get-detail-buyer";

    constructor() {
        super();
    }

    Detail<T extends Buyer>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends Models.UserManagementBuyer>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.UserManagementBuyer>(param: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.UserManagementBuyer>(param: FilterDataUser) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const buyerManagementService = new BuyerManagementService();
export default buyerManagementService;

/* eslint-disable react/destructuring-assignment */
import React from "react";
import ModalHistory, { Props as ModalHistoryProps } from "components/modal/history-modal";
import { useQuery } from "react-query";
import purchaseOrderService from "services/api-endpoints/dashboard-jsl/purchase-order";

type Props = ModalHistoryProps;

export function withModalHistory<T extends Props>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const detailHistory = useQuery(
            [purchaseOrderService.detailHistoryPO, props.idHistory],
            async () => {
                return (await purchaseOrderService.DetailHistoryPO({ id: props.idHistory })).data.data;
            },
            {
                enabled: !!props.idHistory,
            }
        );
        return <Component {...props} fetcher={detailHistory} />;
    };
}

const ModalHistoryPO = withModalHistory(ModalHistory);
export default ModalHistoryPO;

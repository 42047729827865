/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import Utils from "utils";
import { convertToStringFormat } from "../utils/constant";
import { TotalItemInputProps } from "./total-item-input";
import { motion } from "framer-motion";

type PriceTagProps = TotalItemInputProps & {
    price?: number;
    inputMode?: boolean;
};

const PriceTag = ({ price, inputMode, ...props }: PriceTagProps) => {
    const [focus, setFocus] = useState(false);
    const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const num = Math.abs(Utils.convertToIntFormat(e.target.value as unknown as string));
        e.target.value = Utils.convertToStringFormat((Number.isNaN(num) ? "" : num) as number);
    };

    const onChange = (e: any) => {
        const value = Utils.convertToIntFormat(e.target.value);
        if (props?.onChange) props?.onChange(isNaN(value) ? 0 : value);
    };

    if (inputMode) {
        return (
            <div className="flex items-center">
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={focus ? { opacity: 1 } : { opacity: 0 }}
                    className="bg-slate-300 rounded-full h-[18px] relative z-10 text-2xs py-0 pl-1 pr-5 p text-gray-800"
                >
                    Rp .
                </motion.span>
                <motion.input
                    onClick={(e) => e.stopPropagation()}
                    animate={focus ? { marginLeft: -20 } : { marginLeft: -40 }}
                    initial={{ marginLeft: -40 }}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onChange={onChange}
                    size={8}
                    value={Utils.convertToStringFormat(props.entity?.data?.price)}
                    onInput={onInput}
                    className="bg-white border-none text-xs !rounded-full h-full text-center text-gray-700 focus:outline-none active:outline-none relative z-20"
                />
            </div>
        );
    }
    return <span className="bg-white rounded-full !font-semibold text-2xs px-2 m-0 text-gray-800 w-fit">Rp. {convertToStringFormat(price)}</span>;
};

export default PriceTag;

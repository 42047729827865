import { Alert, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useState } from "react";
import { useQuery } from "react-query";
import bukuBesarService, { JournalChild, JournalDetail, Type } from "services/api-endpoints/accounting/buku-besar";

export type Props = ModalProps & {
    id: any;
};

const ModalJournalDetail = ({ id, children, ...props }: Props) => {
    const [list, setList] = useState<JournalDetail[]>([]);

    const journalDetail = useQuery(
        [bukuBesarService.detailJournal, id],
        async () => {
            return (await bukuBesarService.DetailJournal({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                setList([data]);
            },
        }
    );

    const columns: ColumnsType<JournalDetail> = [
        {
            title: "Account Name",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full">
                        {item?.type === Type.Debit ? (
                            <p className="m-0 mb-2 font-normal">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2 font-normal">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        )}
                    </div>
                ));
            },
        },
        {
            title: "Debit",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => <p className="m-0 mb-2 font-semibold">{item?.debit?.ToIndCurrency("Rp") || "-"}</p>);
            },
        },
        {
            className: "h-[1px]",
            title: "Kredit",
            render: (_, { child }) => {
                return child?.map((item, i) => <p className="m-0 mb-2 font-semibold">{item?.kredit?.ToIndCurrency("Rp") || "-"}</p>);
            },
        },
    ];

    return (
        <ModalTemplate width={800} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <div className="w-full flex flex-col gap-6">
                    <State data={journalDetail?.data} isLoading={journalDetail?.isLoading} isError={journalDetail?.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div>
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                            {journalDetail.data?.journal_name}
                                            <span className="m-0 font-normal text-gray-500 "> ({journalDetail.data?.journal_no})</span>
                                        </p>
                                        <Table
                                            size="small"
                                            loading={journalDetail?.isLoading}
                                            columns={columns}
                                            dataSource={list}
                                            className="w-full"
                                            pagination={{
                                                current: 1,
                                                pageSize: 10,
                                                showSizeChanger: false,
                                            }}
                                        />
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active paragraph={{ rows: 4 }} />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(journalDetail?.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalJournalDetail;

import { Button, Card, message, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiSave, BiTrash } from "react-icons/bi";
import React, { useState } from "react";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import purchaseOrderExtService, { DeleteData, EditPO, ListDetail, ProductPoEdit } from "services/api-endpoints/dashboard-jsl/purchase-order-ext";
import State from "components/common/state";
import AdvanceSearch from "modules/advance-search";
import { MdAdd } from "react-icons/md";
import ProductTable from "modules/dashboard-jsl/purchase-order/edit/product-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDocumentRoute } from "utils/routes";
import { Product } from "models";
import { STATUS_PURCHASE_ORDER } from "utils/constant";
import ModalDelete from "components/modal/delete_modal";

const DEFAULT_QUANTITY = 1;

const PurchaseOrderEditExt = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const navigate = useNavigate();
    const [products, setProducts] = useState<ListDetail[]>([]);
    const [deletedProduct, setDeletedProduct] = useState<ListDetail[]>([]);

    const detailQuery = useQuery(
        [purchaseOrderExtService.detail, id],
        async () => {
            return (await purchaseOrderExtService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editDraftPoMutation = useMutation(
        [purchaseOrderExtService.editDraftPO],
        async (data: EditPO) => {
            return (await purchaseOrderExtService.EditDraftPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order draft edited");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const editPoMutation = useMutation(
        [purchaseOrderExtService.editPO],
        async (data: EditPO) => {
            return (await purchaseOrderExtService.EditPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order edited");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const editPOData: EditPO = {
        id: id as any,
        detail_product: [...products].map(
            (el) =>
                ({
                    order_detail_id: el.order_detail_id || null,
                    price: el.price !== undefined ? el.price : el.product_price,
                    product_id: el.product_id,
                    qty: el.qty,
                } as ProductPoEdit)
        ),
    };

    const mergePOData: EditPO = {
        ...editPOData,
        detail_product: [
            ...editPOData.detail_product,
            ...deletedProduct.map((el) => ({ order_detail_id: el.order_detail_id, is_active: 0 } as ProductPoEdit)),
        ],
    };

    const onSaveHandler = () => {
        editPoMutation.mutate(mergePOData);
    };

    const onDraftHandler = () => {
        editDraftPoMutation.mutate(mergePOData);
    };

    const onSearchSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            if (products.find((p) => p.product_id === data.product_id)) {
                callback("Product already exist!");
                return;
            }
            setProducts((prev) => [...prev, { ...data, qty: 1, product_code: data.product_code1 }]);
            callback(null);
        },
        [products]
    );

    const onSearchDeSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            setProducts((prev) => [...prev].filter((p) => p.product_id !== data.product_id));
            callback(null);
        },
        [products]
    );

    const onAddProduct = (data: ListDetail, callback: (str: string | null) => void) => {
        if (products.find((p) => p.product_id === data.product_id)) {
            callback("Product already exist!");
            return;
        }
        setProducts((prev) => [...prev, { ...data, qty: DEFAULT_QUANTITY, price: data.product_price, order_detail_id: null }]);
        callback(null);
    };

    const onRemoveProduct = (product: ListDetail) => {
        setDeletedProduct((prev) => {
            if (product.order_detail_id) return [...prev, product];
            return prev;
        });
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const deletePOMutation = useMutation(
        [purchaseOrderExtService.deletePO],
        async (data: DeleteData) => {
            return (await purchaseOrderExtService.DeletePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order deleted");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const onDeletePOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePOMutation.mutate(dataDelete);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Edit purchase order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={editDraftPoMutation.isLoading}
                            disabled={products.length === 0}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <ModalDelete title="Delete PO" onSubmit={onDeletePOHandler}>
                            {(dt) => (
                                <Button
                                    loading={deletePOMutation.isLoading}
                                    disabled={detailQuery.data?.order_status === 2}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                        <Button
                            loading={editPoMutation.isLoading}
                            disabled={products.length === 0}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        purchase order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.po_number}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span className="underline italic m-0">
                                            {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable
                loading={detailQuery.isLoading || detailQuery.isRefetching}
                list={products}
                setList={setProducts}
                removeItemList={onRemoveProduct}
            />
        </div>
    );
};

export default PurchaseOrderEditExt;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import ModalAdd from "modules/user-magement/apps/modal-add";
import TableIndex from "modules/user-magement/apps/table-index";
import { useQuery } from "react-query";
import userManagementService from "services/api-endpoints/dashboard-all/user-management/user";

const UserApps = () => {
    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([userManagementService.listApps, searchValue, isSearch], async () => {
        return (await userManagementService.ListApps({ query: searchValue.query })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ModalAdd refresh={getList.refetch}>
                {(ctrl) => <Toolbar addHandler={ctrl.openModal} placeholder="Searching..." filter={false} />}
            </ModalAdd>
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default UserApps;

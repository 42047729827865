/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import { useNavigate } from "react-router-dom";
import ProductSelect from "modules/master-data/price/lib/product-select";
import { FCreateProductPrice, TempData } from "modules/master-data/price/models";
import priceService, { AddPrice, SuggestProduct } from "services/api-endpoints/dashboard/master-data/price";
import InputText from "components/form/inputs/input-text";
import ControlledInputNumber from "components/form/controlled-inputs/controlled-input-number";
import WarehouseSelect from "modules/master-data/price/lib/warehouse-select";
import { AllWarehouse } from "models";
import { STATUS_ACTIVE } from "utils/constant";

const schema: yup.SchemaOf<Partial<FCreateProductPrice>> = yup.object().shape({
    product_id: yup.number().required("Product required"),
    warehouse_id: yup.number().required("Warehouse required"),
    price: yup.number().required("Price required"),
});

const PriceAdd = () => {
    const navigate = useNavigate();

    const [tempData, setTempData] = useState<TempData>({});

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm<FCreateProductPrice>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation(
        [priceService.addPrice],
        async (data: AddPrice) => {
            return (await priceService.AddPrice(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Product Price created");
                navigate(-1);
            },
        }
    );

    const detailProduct = useQuery(
        [priceService.detailProduct, tempData.product?.id],
        async () => {
            return (await priceService.DetailProduct({ product_id: tempData.product?.id })).data.data;
        },
        {
            enabled: !!tempData.product?.id,
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        createMutate.mutate(data);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onProductSelect = (product: SuggestProduct | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            product,
        }));
    };

    const onWarehouseSelect = (warehouse: AllWarehouse | undefined | null) => {
        setTempData((prev) => ({
            ...prev,
            warehouse,
        }));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new product price"
                rightAddition={() => (
                    <Button
                        loading={createMutate.isLoading}
                        onClick={onSaveHandler}
                        disabled={!isValid}
                        type="primary"
                        className="!flex !items-center"
                    >
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Form className="col-span-2" form={form} disabled={detailProduct.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                <div className="grid w-full grid-cols-2 gap-4">
                    <Card className="">
                        <div className="grid grid-cols-3 gap-x-4 h-fit">
                            <h1 className="capitalize font-semibold col-span-3 text-gray-500 mb-4">product information</h1>
                            <ProductSelect
                                showSearch
                                classNameForm="col-span-2"
                                onSelectedData={onProductSelect}
                                control={control}
                                name="product_id"
                                placeholder="Product Name"
                            />
                            <InputText value={tempData?.product?.product_code} disabled placeholder="Product Code" />
                            <ControlledInputNumber classNameForm="col-span-2" control={control} name="price" placeholder="Price" />
                            <WarehouseSelect onSelectedData={onWarehouseSelect} control={control} name="warehouse_id" placeholder="Warehouse" />
                            <InputText
                                classNameForm="col-span-2"
                                value={detailProduct.data?.product_desc}
                                disabled
                                placeholder="Product Description"
                            />
                            <InputText value={detailProduct.data?.molding_code} disabled placeholder="Molding Code" />
                            <InputText
                                value={STATUS_ACTIVE.find((el) => el.value === Number(detailProduct.data?.status))?.label}
                                disabled
                                classNameForm="col-span-2"
                                placeholder="Status"
                            />
                        </div>
                    </Card>
                    <Card>
                        <div className="grid grid-cols-3 gap-x-4 h-fit">
                            <h1 className="capitalize font-semibold col-span-3 text-gray-500 mb-4">product literation</h1>
                            <InputText classNameForm="col-span-2" value={detailProduct.data?.brand_name} disabled placeholder="Brand Name" />
                            <InputText value={detailProduct.data?.brand_code} disabled placeholder="Brand Code" />

                            <InputText value={detailProduct.data?.model_name} disabled placeholder="Model Name" />
                            <InputText value={detailProduct.data?.model_code} disabled placeholder="Model Code" />
                            <InputText
                                value={(JSON.parse(detailProduct.data?.model_year || "[]") as Array<number>)?.join(", ")}
                                disabled
                                placeholder="Model Year"
                            />
                            <InputText classNameForm="col-span-2" value={detailProduct.data?.category_name} disabled placeholder="Category Name" />
                            <InputText value={detailProduct.data?.category_type} disabled placeholder="Category Type" />

                            <InputText
                                classNameForm="col-span-2"
                                value={detailProduct.data?.subcategory_name}
                                disabled
                                placeholder="Subcategory Name"
                            />
                            <InputText value={detailProduct.data?.subcategory_code} disabled placeholder="Subcategory Code" />

                            <InputText classNameForm="col-span-2" value={detailProduct.data?.style_name} disabled placeholder="Style Name" />
                            <InputText value={detailProduct.data?.style_code} disabled placeholder="Style Code" />

                            <InputText classNameForm="col-span-2" value={detailProduct.data?.attribute_name} disabled placeholder="Attribute Name" />
                            <InputText value={detailProduct.data?.attribute_code} disabled placeholder="Attribute Code" />

                            <InputText
                                classNameForm="col-span-2"
                                value={detailProduct.data?.subattribute_name}
                                disabled
                                placeholder="Subattribute Name"
                            />
                            <InputText value={detailProduct.data?.subattribute_code} disabled placeholder="Subattribute Code" />
                        </div>
                    </Card>
                </div>
            </Form>
        </div>
    );
};

export default PriceAdd;

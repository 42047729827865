import { Card, message, Steps } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import { STEP_CREATE_VOUCHER } from "utils/constant";
import RuleInformation from "modules/master-data/voucher/add/rule-information";
import DataCustomer from "modules/master-data/voucher/add/data-customer";
import Condition from "modules/master-data/voucher/add/condition";
import DetailCondition from "modules/master-data/voucher/add/detail-condition";
import Action from "modules/master-data/voucher/add/action";
import { VoucherContext, VoucherProvider } from "modules/master-data/voucher/context/voucher";
import voucherService, { CreateVoucher } from "services/api-endpoints/dashboard/master-data/voucher";
import LoadingBlocker from "components/common/loading-blocker";

const Main = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();

    const { currentStep, state } = useContext(VoucherContext);

    const createMutation = useMutation(
        [voucherService.create],
        async (data: CreateVoucher) => {
            return (await voucherService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Voucher created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async (data: CreateVoucher) => {
        createMutation.mutate(data);
    };

    const component = useMemo(() => {
        const components = [<RuleInformation />, <DataCustomer />, <Condition />, <DetailCondition />, <Action submit={onSaveHandler} />];
        // const components = [<Action submit={onSaveHandler} />, <RuleInformation />, <DataCustomer />, <Condition />, <DetailCondition />];
        return components[currentStep!];
    }, [currentStep, state?.createData]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="add new voucher" rightAddition={() => <>{progress}</>} />
            <LoadingBlocker loading={createMutation.isLoading} title="Creating new voucher">
                <Card>
                    <div className="w-full flex flex-col gap-10">
                        <Steps current={currentStep} items={STEP_CREATE_VOUCHER} />
                        {component}
                    </div>
                </Card>
            </LoadingBlocker>
        </div>
    );
};

const VoucherAdd = () => (
    <VoucherProvider>
        <Main />
    </VoucherProvider>
);

export default VoucherAdd;

import { Icon } from "@mui/material";
import { Alert, Button, Skeleton, StepProps, Steps } from "antd";
import State from "components/common/state";
import ModalDetailInv from "modules/master-data/purchasing/modal-detail-inv";
import React from "react";
import { useQuery } from "react-query";
import purchasingInventoryService from "services/api-endpoints/purchasing/inventory";
import { MODAL_PURCHASING } from "utils/constant";

interface Props {
    paramId: any;
}

const JourneyPO = ({ paramId }: Props) => {
    const journeyPO = useQuery(
        [purchasingInventoryService.journeyPO, paramId],
        async () => (await purchasingInventoryService.JourneyPO(paramId)).data.data,
        {
            enabled: !!paramId,
        }
    );

    const journeys = React.useCallback(
        () =>
            journeyPO.data?.map(
                (journey) =>
                    ({
                        status: "finish",
                        icon: <Icon fontSize="small">beenhereicon</Icon>,
                        title: (
                            <>
                                <p className="m-0 text-sm">{MODAL_PURCHASING.find((val) => val.value === journey.type)?.label || "-"}</p>
                                <ModalDetailInv title="Invoice Detail" id={journey.doc_id} type={journey.type}>
                                    {(ctrl) => (
                                        <Button onClick={ctrl.openModal} size="small">
                                            {journey.doc_no}
                                        </Button>
                                    )}
                                </ModalDetailInv>
                            </>
                        ),
                    } as StepProps)
            ),
        [journeyPO.data]
    );

    return (
        <State data={journeyPO.data} isLoading={journeyPO.isLoading} isError={journeyPO.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        {journeyPO.data?.length === 0 ? (
                            <Alert message="Waiting Create Purchasing Order" type="warning" showIcon />
                        ) : (
                            <Steps direction="vertical" size="default" items={journeys() || []} />
                        )}
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(journeyPO.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default JourneyPO;

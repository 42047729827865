/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface DetailList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    discount?: number;
    qty?: number;
    po_id?: number;
    qty_gr?: number;
    qty_send?: number;
    area_id?: number;
    area_name?: string;
}

export interface DetailInvoice {
    id?: number;
    inv_no?: string;
    request_from?: string;
    request_from_address?: string;
    request_from_id?: number;
    request_to?: string;
    order_status?: number;
    payment_term_id?: number;
    payment_term_code?: string;
    payment_status?: number;
    total_product_price?: number;
    total_discount?: number;
    grand_total?: number;
    ppn?: number;
    dpp?: number;
    date?: string;
    child_document?: number[];
    list_detail?: DetailList[];
}

export interface BPInvoiceList {
    id?: number;
    inv_no?: string;
    reques_from?: string;
    driver_name?: string;
    status_order?: number;
    payment_status?: number;
    date?: number;
    pl_no?: any[];
}

class BpInvoiceService extends BaseService {
    getAll = "/bp-inv/get-all-inv";

    detailInvoice = "/bp-inv/detail-invoice";

    constructor() {
        super();
    }

    DetailInvoice<T extends DetailInvoice>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailInvoice}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends BPInvoiceList>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const bpInvoiceService = new BpInvoiceService();
export default bpInvoiceService;

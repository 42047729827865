/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { AllModel, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import Utils from "utils";
import { CreateDataModel } from "../models";

type SelectOptionCustom = SelectOption & AllModel;

export function withModelYear<T extends ControlledInputSelectProps<CreateDataModel>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const brand = props.control._getWatch("brand");
        const year = Utils.generateYear(20).map((el) => ({ label: el || "", value: el || "" } as SelectOption));

        return <Component {...props} optionFilterProp="children" options={year || []} />;
    };
}

const ModelYearSelect = withModelYear(ControlledSelectInput);

export default ModelYearSelect;

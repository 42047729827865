import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import OrganizationSelect from "modules/accounting/bank-account/lib/organization-select";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation } from "react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import bankAccountService, { CreateBankAccount } from "services/api-endpoints/accounting/bank-account";
import { STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateBankAccount>> = yup.object().shape({
    name: yup.string().required("Name required"),
    description: yup.string(),
    account_no: yup.string().required("Account required"),
    organization_id: yup.number().required("Organization required"),
    is_active: yup.number().required("Status required"),
});

const BankAccountAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreateBankAccount>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [bankAccountService.create],
        async (data: CreateBankAccount) => {
            return (await bankAccountService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Bank Account Created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new bank account"
                rightAddition={() => (
                    <Space>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <div className="w-[50%]">
                <Card>
                    <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">COA information</h1>
                        <ControlledInputText allowClear control={control} name="name" placeholder="Name" />
                        <ControlledInputText allowClear control={control} name="description" placeholder="Description" />
                        <ControlledInputText allowClear control={control} name="account_no" placeholder="Account No" />
                        <OrganizationSelect allowClear control={control} name="organization_id" placeholder="Organization" />
                        <ControlledSelectInput
                            allowClear
                            control={control}
                            name="is_active"
                            placeholder="Is Active"
                            optionFilterProp="children"
                            options={STATUS_ACTIVE}
                        />
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default BankAccountAdd;

import React from "react";
import { Image, List, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { EDIT_PATH, IMAGE_FALLBACK, TYPE_ITEM_INVENTORY } from "utils/constant";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import { TDataProduct } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: TDataProduct) => void;
};

const ProductTable = <T extends TDataProduct>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: TDataProduct) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            width: "100px",
            title: "Image",
            dataIndex: "images",
            render: (_, record) => (
                <List
                    size="small"
                    dataSource={record?.images || []}
                    renderItem={(item) => (
                        <Image width={80} height={50} src={item || "error"} alt={item || ""} className="object-contain " fallback={IMAGE_FALLBACK} />
                    )}
                />
            ),
        },
        {
            width: "250px",
            title: "Product Name",
            dataIndex: "name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "150px",
            title: "Product Code",
            dataIndex: "code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "150px",
            title: "Product Type",
            dataIndex: "type",
            render: (text, record) => <p className="capitalize m-0">{TYPE_ITEM_INVENTORY.find((val) => val.value === (record.type || 3))?.label}</p>,
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Model Year",
            dataIndex: "model_year",
            render: (_, record) => <p className="capitalize m-0">{record.model_year ? record.model_year?.join(", ") : "-"}</p>,
        },
        {
            title: "Model Name",
            dataIndex: "model_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Model Type",
            dataIndex: "model_type_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Sub Category",
            dataIndex: "subcategory_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Sub Attribute",
            dataIndex: "subattribute_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "is_active",
            render: (text) => <p className="capitalize m-0">{text ? "Active" : "Not Active"}</p>,
        },
        {
            width: "160px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                    <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ProductTable;

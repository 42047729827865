/* eslint-disable no-shadow */
import { Skeleton } from "antd";
import State from "components/common/state";
import { useQuery } from "react-query";
import orderService from "services/api-endpoints/dashboard/master-data/order";
import TableProductAccounting from "./table/table-product-accounting";
import TableProductAccounting2 from "./table/table-product-accounting2";

type Props = {
    paramId?: any;
    asPreview?: boolean;
};

const DetailProductAccounting = ({ paramId, asPreview }: Props) => {
    const detailWOQuery = useQuery(
        [orderService.detailProductAccounting, paramId],
        async () => (await orderService.DetailProductAccounting({ id: paramId })).data.data,
        {
            enabled: !!paramId,
        }
    );

    return (
        <>
            <State data={detailWOQuery.data} isLoading={detailWOQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <div className="w-full flex flex-col gap-2">
                                <p className="m-0 font-bold">HPP Product</p>
                                <TableProductAccounting2 list={detailWOQuery.data || []} action={false} />
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </>
    );
};

export default DetailProductAccounting;

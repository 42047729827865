/* eslint-disable react/destructuring-assignment */
import React from "react";
import ModalHistory, { Props as ModalHistoryProps } from "components/modal/history-modal";
import { useQuery } from "react-query";
import deliveryOrderService from "services/api-endpoints/dashboard-jsl/delivery-order";

type Props = ModalHistoryProps;

export function withModalHistory<T extends Props>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const detailHistory = useQuery(
            [deliveryOrderService.detailHistoryDO, props.idHistory],
            async () => {
                return (await deliveryOrderService.DetailHistoryDO({ id: props.idHistory })).data.data;
            },
            {
                enabled: !!props.idHistory,
            }
        );
        return <Component {...props} fetcher={detailHistory} />;
    };
}

const ModalHistoryDO = withModalHistory(ModalHistory);
export default ModalHistoryDO;

import moment from "moment";
import { BukuBesarList } from "services/api-endpoints/accounting/buku-besar";
import { FORMAT_DATE_TIME } from "utils/constant";
import XLSX from "xlsx-js-style";

export default {};
export const bukuBesarTableToExcel = ({ title, list }: { title: string; list: BukuBesarList[] }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Date", ...headerStyle },
        { v: "Account", ...headerStyle },
        { v: "Coa Name", ...headerStyle },
        { v: "Description", ...headerStyle },
        { v: "Ref", ...headerStyle },
        { v: "Debit", ...headerStyle },
        { v: "Kredit", ...headerStyle },
        { v: "Saldo", ...headerStyle },
    ];

    const rows: any[] = [];
    list?.forEach((el, listIndex) => {
        const row = [
            { v: listIndex + 1, t: "s" },
            { v: moment(el.created_at)?.format(FORMAT_DATE_TIME), t: "s" },
            { v: el.account || "-", t: "s" },
            { v: `${el.coa_name} (${el.coa_code})`, t: "s" },
            { v: el.note, t: "s" },
            { v: el.journal_no, t: "s" },
            { v: el.debit, t: "n" },
            { v: el.kredit, t: "n" },
            { v: (el.saldo || 0) < 0 ? el.saldo! * -1 : el.saldo, t: "n" },
        ];
        rows.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
};

import { Checkbox, Image, List, Space } from "antd";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React, { useRef } from "react";
import { useQuery } from "react-query";
import orderSalesService, { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import { IMAGE_FALLBACK } from "utils/constant";

type Props = {
    children: (data: HandlerProps) => void;
    vouchers: Voucher[];
    onVoucherChange: (voucher: Voucher) => void;
};

const VoucherModal = ({ children, vouchers, onVoucherChange }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const getVoucher = useQuery([orderSalesService.getVoucher], async () => (await orderSalesService.GetVoucher()).data.data);

    if (vouchers.length === 1) {
        closeRef.current?.click();
    }

    const onChangeVoucher = (voucher: Voucher) => {
        onVoucherChange(voucher);
    };

    return (
        <ModalTemplate title="Voucher" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="">
                    <button className="hidden" ref={closeRef} type="button" onClick={dt.closeModal}>
                        close
                    </button>
                    <List
                        rowKey={(record) => record.voucher_id}
                        size="small"
                        bordered
                        dataSource={getVoucher.data}
                        renderItem={(item) => (
                            <List.Item>
                                <Checkbox onChange={() => onChangeVoucher(item)} checked={!!vouchers.find((v) => v.voucher_id === item.voucher_id)}>
                                    <Space>
                                        <Image width={150} height={70} src={item.voucher_image || undefined} fallback={IMAGE_FALLBACK} />
                                        <p className="m-0 font-medium text-gray-600 capitalize">{item.voucher_code}</p>
                                    </Space>
                                </Checkbox>
                            </List.Item>
                        )}
                    />
                    <p className="m-0 text-xs mt-2">Max 1 Voucher</p>
                </div>
            )}
        </ModalTemplate>
    );
};

export default VoucherModal;

/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Space, Tabs, Tag } from "antd";
import State from "components/common/state";
import ModalDelete from "components/modal/delete_modal";
import ToolbarAction from "components/toolbar/action";
import HistoryTable from "modules/dashboard-jsl/purchase-order/view/history-table";
import ModalHistoryPO from "modules/dashboard-jsl/purchase-order/view/modal-history";
import Print from "modules/dashboard-jsl/purchase-order/view/print";
import ProductTable from "modules/dashboard-jsl/purchase-order/view/product-table";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import purchaseOrderService, { DeleteData, ListDetail } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { PO_INT_COLOR_TAG, PO_INT_COMPLETED, PO_INT_ON_MM, PO_INT_ON_PROGRESS, PO_INT_REJECTED, STATUS_PURCHASE_ORDER_INT } from "utils/constant";
import { getDocumentRoute } from "utils/routes";

const PurchaseOrderView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const letterRef = useRef<HTMLDivElement | null>(null);

    const [products, setProducts] = useState<ListDetail[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [purchaseOrderService.detail, id],
        async () => {
            return (await purchaseOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [purchaseOrderService.historyPO, page],
        async () => {
            return (await purchaseOrderService.HistoryPO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const deletePOMutation = useMutation(
        [purchaseOrderService.deletePO],
        async (data: DeleteData) => {
            return (await purchaseOrderService.DeletePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal Purchase order deleted");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const onClickLinkHandler = (id: number) => {
        setDetailHistoryID(id);
    };

    const onDeletePOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePOMutation.mutate(dataDelete);
    };

    const type = STATUS_PURCHASE_ORDER_INT.find((el) => el.value === detailQuery.data?.order_status);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Internal PO - ${id}`,
        pageStyle: "@page {  }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Purchase order detail"
                rightAddition={() => (
                    <>
                        <ModalDelete title="Delete PO" onSubmit={onDeletePOHandler}>
                            {(dt) => (
                                <Button
                                    loading={deletePOMutation.isLoading}
                                    disabled={
                                        detailQuery.data?.order_status === PO_INT_REJECTED ||
                                        detailQuery.data?.order_status === PO_INT_ON_MM ||
                                        detailQuery.data?.order_status === PO_INT_ON_PROGRESS ||
                                        detailQuery.data?.order_status === PO_INT_COMPLETED
                                    }
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="w-full grid grid-cols-4 gap-x-4">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{detailQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            note <br />
                                            <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            status <br />
                                            <Tag color={PO_INT_COLOR_TAG[type?.value || 0]}>{type?.label}</Tag>
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <ModalHistoryPO idHistory={detailHistoryID}>
                                    {(dt) => (
                                        <HistoryTable
                                            fetcher={history}
                                            onClickLink={(id) => {
                                                onClickLinkHandler(id);
                                                dt.openModal();
                                            }}
                                        />
                                    )}
                                </ModalHistoryPO>
                            ),
                        },
                    ]}
                />
            </Card>
            <Print ref={letterRef} data={detailQuery.data || null} />
        </div>
    );
};

export default PurchaseOrderView;

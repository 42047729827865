import Toolbar from "components/toolbar";
import TableIndex from "modules/master-data/external-order/ext-gr/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extGoodsReceiptService from "services/api-endpoints/dashboard/master-data/ext-goods-receipt";

const ExtGoodsReceipt = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const getList = useQuery([extGoodsReceiptService.getAll, page, size], async () => {
        return (await extGoodsReceiptService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default ExtGoodsReceipt;

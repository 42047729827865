import { Button, Card, message, Select, Skeleton } from "antd";
import State from "components/common/state";
import ModalNewPo from "components/modal/new_po_modal";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ModalGoodsReceiptAdd from "modules/master-data/external-order/ext-gr/modal-add";
import TableAdd from "modules/master-data/external-order/ext-gr/table-add";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import salesOrderService from "services/api-endpoints/dashboard-jsl/sales-order";
import extGoodsReceiptService, { CreateGRData, DetailProduct, ProductGR } from "services/api-endpoints/dashboard/master-data/ext-goods-receipt";
import extSalesOrderService, { BusinessPartner } from "services/api-endpoints/dashboard/master-data/ext-sales-order";

const ExtGoodsReceiptAdd = () => {
    const navigate = useNavigate();
    const [productsPO, setProductsPO] = useState<DetailProduct[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);
    const [partner, setPartner] = useState<number | null>(null);
    const [soChild, setSoChild] = useState<number[]>([]);

    const getHeaderPOQuery = useQuery([salesOrderService.getHeaderSO], async () => {
        return (await salesOrderService.GetHeaderSO()).data.data;
    });

    const getBP = useQuery([extSalesOrderService.getBp], async () => {
        const req = await extSalesOrderService.GetBp({ status: 0 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption & BusinessPartner));
    });

    const createGRMutation = useMutation(
        [extGoodsReceiptService.createGR],
        async (data: CreateGRData) => {
            return (await extGoodsReceiptService.CreateGR(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Goods Receipt Created");
                navigate(-1);
            },
        }
    );

    const onFilterApprove: DetailProduct[] = productsPO.filter((el) => el.qty !== undefined);
    const onFilter = () => {
        if (productsPO.length === 0) {
            return true;
        }
        return !(onFilterApprove.length === productsPO.length);
    };

    const prepareData = () => {
        const createPLData: CreateGRData = {
            bp_id: partner!,
            child_document: [...new Set(selectedPO?.map((p) => p!) || [])],
            so_child: soChild,
            detail_product: [
                ...(productsPO?.map(
                    (p) =>
                        ({
                            pl_id: p.id,
                            product_id: p.product_id,
                            qty: p.qty_receipt || 0,
                            price: p.product_price,
                            is_free: p.is_free,
                            discount_price: p.discount_price,
                            voucher_id: p.voucher_id,
                            note: p.note,
                        } as ProductGR)
                ) || []),
            ],
        };
        return createPLData;
    };

    const onSaveHandler = () => {
        try {
            const createPLData = prepareData();
            createGRMutation.mutate(createPLData);
            console.log(createPLData);
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onChangeWarehouse = (value: number) => {
        setSelectedPO([]);
        setProductsPO([]);
        setPartner(value);
    };

    const onProductPO = (data: DetailProduct[]) => {
        setProductsPO(data);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add external goods receipt"
                rightAddition={() => (
                    <>
                        <ModalNewPo title="Konfirmasi" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button onClick={onSaveHandler} type="primary" className="!flex !items-center w-fit" disabled={onFilter()}>
                                    <BiSave className="mr-2" />
                                    Save and Completed
                                </Button>
                            )}
                        </ModalNewPo>
                    </>
                )}
            />
            <Card className="">
                <State data={getHeaderPOQuery.data} isLoading={getHeaderPOQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Document No <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.so_number}</span>
                                    </p>
                                    <div className="">
                                        <div className="capitalize m-0">
                                            BP Name <br />
                                            <Select
                                                value={partner}
                                                className="!w-[300px]"
                                                loading={getBP.isLoading}
                                                options={getBP.data || []}
                                                onChange={onChangeWarehouse}
                                                showSearch
                                                placeholder="Partner Name"
                                                optionFilterProp="label"
                                            />
                                        </div>
                                    </div>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                                    </p>
                                    <div />
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <div className="flex items-start">
                <ModalGoodsReceiptAdd
                    onProductPO={onProductPO}
                    partner={partner}
                    selectedPO={selectedPO}
                    setSelectedPO={setSelectedPO}
                    setSoChild={setSoChild}
                >
                    {(dt) => (
                        <Button className="!flex !items-center w-fit mr-3" type="primary" onClick={dt.openModal} disabled={!partner}>
                            <MdAdd className="mr-2" />
                            Data Ext Delivery Order
                        </Button>
                    )}
                </ModalGoodsReceiptAdd>
            </div>
            <TableAdd list={productsPO} setList={setProductsPO} canRemove={false} />
        </div>
    );
};

export default ExtGoodsReceiptAdd;

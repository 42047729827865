/* eslint-disable no-shadow */
import { Select } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { SelectOption } from "models";
import KartuStokTable from "modules/master-data/kartu-stok/table-index";
import FilterQuantity from "modules/master-data/quantity/index/filter";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import kartuStokService from "services/api-endpoints/dashboard/master-data/kartu-stock";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";

const filter = ["product_name", "product_code", "molding_code", "brand_name", "model_name", "model_type_name", "area_code"];

const KartuStok = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const warehouse = searchParams.get("warehouse_id");

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const changeWarehouse = ({ id, page }: { id: any; page: string }) => {
        filter.forEach((ftr) => searchParams.delete(ftr));
        searchParams.set("warehouse_id", id);
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [kartuStokService.warehouse],
        async () => {
            return (await kartuStokService.Warehouse()).data.data?.map(
                (el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption)
            );
        },
        {
            onSuccess: (data) => {
                if (warehouse) return;
                changeWarehouse({ id: data[0].value, page: "1" });
            },
        }
    );

    const getList = useQuery(
        [kartuStokService.getAll, page, searchValue, filterValue, isFilter, isSearch, warehouse],
        async () => {
            // if (isSearch) return (await quantityService.Search({ page, param_search: searchValue.query, warehouse_id: warehouse })).data.data;
            // if (isFilter) return (await quantityService.Filter({ ...(filterValue as any), warehouse_id: warehouse })).data.data;
            return (await kartuStokService.GetAll({ page, warehouse_id: warehouse, param_search: searchValue.query })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    // useEffect(() => {
    //     (() => {
    //         if (warehouse) return;
    //         getWarehouse.refetch();
    //     })();
    // }, [warehouse]);

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar
                add={false}
                filter={false}
                prefixAddition={() => (
                    <Select
                        className="!w-[400px]"
                        showSearch
                        value={warehouse ? Number(warehouse) : ""}
                        loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                        placeholder="Warehouse"
                        options={getWarehouse.data || []}
                        onChange={(val) => changeWarehouse({ id: val, page: "1" })}
                    />
                )}
                placeholder="Product Name / Product Code"
                defaultParams={["warehouse_id"]}
            />
            <KartuStokTable fetcher={getList} />
        </div>
    );
};

export default KartuStok;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import BPDeliveryOrderView from "pages/bp-delivery-order/view";
import BPInvoiceView from "pages/bp-invoice/view";
import CDeliveryOrderView from "pages/c-delivery-order/view";
import CPackingListView from "pages/c-packing-list/view";
import CReturnOrderView from "pages/c-return-order/view";
import DocContainerView from "pages/doc-container/view";
import ExtGoodsReceiptView from "pages/ext-goods-receipt/view";
import ExtReturnOrderView from "pages/ext-return-order/view";
import MaterialMovementView from "pages/material-movement/view";
import OrderView from "pages/order/view";
import PhysicalInventoryView from "pages/physical-inventory/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import { useSearchParams } from "react-router-dom";
import { History } from "services/api-endpoints/dashboard/master-data/quantity";
import {
    TYPE_DOC_HISTORY_C_DO,
    TYPE_DOC_HISTORY_C_GR,
    TYPE_DOC_HISTORY_C_PL,
    TYPE_DOC_HISTORY_C_RETURN,
    TYPE_DOC_HISTORY_DO_CONTAINER,
    TYPE_DOC_HISTORY_EXT_GR,
    TYPE_DOC_HISTORY_EXT_RO,
    TYPE_DOC_HISTORY_MM,
    TYPE_DOC_HISTORY_PH_INV,
    TYPE_DOC_HISTORY_PURCHASING,
    TYPE_DOC_HISTORY_SALES_ORDER,
    TYPE_SALES_HISTORY_AUTOLOKA,
    TYPE_SALES_HISTORY_BP_DO,
    TYPE_SALES_HISTORY_BP_INV,
    TYPE_SALES_HISTORY_OFFLINE,
    TYPE_SALES_HISTORY_ONLINE,
} from "utils/constant";

export type Props = ModalProps & {
    data?: History;
};

const DetailModal = ({ data, children, ...props }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const removeDetailParams = () => {
        searchParams.delete("preview_page");
        setSearchParams(searchParams);
    };

    const isModalOpen = (isOpen: boolean) => {
        if (isOpen) {
            searchParams.set("preview_page", "1");
            setSearchParams(searchParams);
        }
    };

    return (
        <ModalTemplate
            isModalOpenFn={isModalOpen}
            afterClose={removeDetailParams}
            width={1300}
            title={<p>{data?.doc_no || "-"}</p>}
            handlerInComponent={children}
            footer={null}
            {...props}
        >
            {(dt) => {
                if (data?.doc_type_id === TYPE_DOC_HISTORY_SALES_ORDER) {
                    if (
                        data?.sales_type === TYPE_SALES_HISTORY_ONLINE ||
                        data?.sales_type === TYPE_SALES_HISTORY_OFFLINE ||
                        data?.sales_type === TYPE_SALES_HISTORY_AUTOLOKA
                    ) {
                        return <OrderView asPreview paramId={data.doc_id} />;
                    }
                    if (data?.sales_type === TYPE_SALES_HISTORY_BP_DO) {
                        return <BPDeliveryOrderView urlNamePage="preview_page" asPreview paramId={data.doc_id} />;
                    }
                    if (data?.sales_type === TYPE_SALES_HISTORY_BP_INV) {
                        return <BPInvoiceView paramId={data.doc_id} />;
                    }
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_MM) {
                    return <MaterialMovementView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_PURCHASING) {
                    return <PurchasingInventoryView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_C_PL) {
                    return <CPackingListView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_C_DO || data?.doc_type_id === TYPE_DOC_HISTORY_C_GR) {
                    return <CDeliveryOrderView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_PH_INV) {
                    return <PhysicalInventoryView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_EXT_GR) {
                    return <ExtGoodsReceiptView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_C_RETURN) {
                    return <CReturnOrderView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_DO_CONTAINER) {
                    return <DocContainerView asPreview paramId={data.doc_id} />;
                }
                if (data?.doc_type_id === TYPE_DOC_HISTORY_EXT_RO) {
                    return <ExtReturnOrderView asPreview paramId={data.doc_id} />;
                }
                return <p className="text-red-400 text-xl">Doc type not handled yet</p>;
            }}
        </ModalTemplate>
    );
};

export default DetailModal;

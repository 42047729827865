/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface DetailProduct {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    qty?: number;
    note?: null;
}

export interface Voucher {
    voucher_id: number;
    voucher_image: string;
    voucher_code: string;
}

export interface VoucherCustome {
    name?: any;
    price?: any;
    percentage?: any;
}

export interface DetailBpOrder {
    order_id: number;
    order_no: string;
    so_no: string;
    bp_id: number;
    bp_name: string;
    payment_term_id: number;
    payment_term_name: string;
    total_discount: number;
    total_price: number;
    grand_total: number;
    order_status: number;
    list_detail: DetailProduct[];
    voucher: Voucher[];
    voucher_custom: VoucherCustome[];
}

export interface BpOrder {
    id: number;
    order_no: string;
    so_no: string;
    bp_name: string;
    payment_status: number;
    status: number;
    grand_total: number;
    date: Date;
}

export interface FilterParams {
    order_no: any;
    so_no: any;
    status: any;
    bp_name: any;
    start_date: any;
    end_date: any;
    size: any;
    page: any;
}

export interface BpOrderDetail {
    detail_product: DetailProduct[];
    voucher_id?: number[];
    voucher?: VoucherCustome[];
}

export interface CreateBpOrder extends BpOrderDetail {
    bp_id: number;
    payment_term_id: number;
}

export interface EditBpOrder extends CreateBpOrder {
    id: number;
}

export interface FreeProduct {
    product_code: string;
    id: number;
    item: string;
    qty: number;
    price: number;
}

export interface SummaryPriceBpOrder {
    total_discount: number;
    total_price: number;
    grand_total: number;
    free_product?: FreeProduct[];
}

export interface BusinessPartner {
    bp_id: number;
    bp_name: string;
    payment_term_id: number;
}

export interface List {
    id?: number;
    no?: string;
}

export interface ListHistory {
    id?: number;
    notes?: string;
    created_by?: string;
    created_at?: number;
    list_so?: List[];
    list_pl?: List[];
    list_do?: List[];
    list_history?: List[];
}

class BpOrderService extends BaseService {
    getAll = "/bp-order/get-all";

    getBusinessPartner = "/bp-order/get-business-partner";

    search = "/bp-order/search-bp-sales";

    filter = "/bp-order/filter-bp-sales";

    getSummaryPrice = "/bp-order/get-summary-price";

    createBpOrder = "/bp-order/create-bp-sales";

    createBpOrderDraft = "/bp-order/create-draft";

    delete = "/bp-order/delete-draft-bp-sales";

    detail = "/bp-order/detail-bp-sales";

    edit = "/bp-order/edit-bp-sales";

    editDraft = "/bp-order/edit-draft";

    history = "/bp-order/history-sales-order";

    constructor() {
        super();
    }

    History<T extends ListHistory>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.history,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    EditDraft<T extends any>(data: EditBpOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraft,
                data,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditBpOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailBpOrder>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${data.id}`,
            });
            return req;
        });
    }

    Delete<T extends any>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${data.id}`,
            });
            return req;
        });
    }

    CreateBpOrderDraft<T extends any>(data: CreateBpOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createBpOrderDraft,
                data,
            });
            return req;
        });
    }

    CreateBpOrder<T extends any>(data: CreateBpOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createBpOrder,
                data,
            });
            return req;
        });
    }

    GetSummaryPrice<T extends SummaryPriceBpOrder>(data: BpOrderDetail) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.getSummaryPrice,
                data,
            });
            return req;
        });
    }

    Filter<T extends BpOrder>(param: FilterParams) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends BpOrder>(param: { page: any; size: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends BpOrder>(param: { page: any; size: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetBusinessPartner<T extends BusinessPartner[]>(param: { status: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBusinessPartner,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const bpOrderService = new BpOrderService();
export default bpOrderService;

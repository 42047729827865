/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Model from "models/index";

export interface WarehouseTo {
    warehouse_id_to: number;
    warehouse_name: string;
}

export interface WarehouseFrom {
    warehouse_id_from: number;
    warehouse_name: string;
}

export interface LocatorFrom {
    area_id_from?: number;
    qty_from?: number;
}

export interface LocatorTo {
    area_id_to?: number;
    qty_to?: number;
}

export interface ProductTransform {
    product_id?: number;
    locator_from?: LocatorFrom[];
    locator_to?: LocatorTo[];
}

export interface CreateTransferStock {
    id?: number;
    warehouse_id_from: number;
    warehouse_id_to: number;
    area_from_id: number;
    product?: ProductTransform[];
}

export interface TransferStockModel {
    created_at: number;
    created_by: string;
    doc_no: string;
    id: number;
    status: number;
    total_move: number | string;
    warehouse_from: string;
    warehouse_to: string;
}

export interface TransferStockList {
    id: number;
    doc_no: string;
    warehouse_from: string;
    warehouse_to: string;
    total_move: string;
    status: number;
    created_by: string;
    created_at: number;
}

export interface DeleteTS {
    id: number;
    note: string;
}

class TransferStockService extends BaseService {
    getWHFrom = "/tfs/get-wh-from";

    getWHTo = "/tfs/get-wh-to";

    list = "/tfs/get-list";

    create = "/tfs/create";

    editCreate = "/tfs/edit-create";

    draft = "/tfs/save-draft";

    delete = "/tfs/delete";

    constructor() {
        super();
    }

    Delete<T extends any>(data: DeleteTS) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.delete,
                data,
            });
            return req;
        });
    }

    EditCreate<T extends any>(data: CreateTransferStock) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editCreate,
                data,
            });
            return req;
        });
    }

    List<T extends TransferStockList>(params: { query?: any; page?: any }) {
        return this.ProxyRequest<Model.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Model.BasePaginationResponse<T>>({
                url: this.list,
                config: { params },
            });
            return req;
        });
    }

    Draft<T extends any>(data: CreateTransferStock) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draft,
                data,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateTransferStock) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetWHTo<T extends WarehouseTo[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getWHTo,
            });
            return req;
        });
    }

    GetWHFrom<T extends WarehouseFrom[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getWHFrom,
            });
            return req;
        });
    }
}

const transferStockService = new TransferStockService();
export default transferStockService;

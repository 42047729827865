/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { message } from "antd";
import clsx from "clsx";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import update from "immutability-helper";
import LoaderEntity from "modules/manufacture/components/loader-entity";
import PriceTag from "modules/manufacture/components/price-tag";
import { Entities, ManufactureContext, calculateProcessPrice, checkIfTextIsEmpty } from "modules/manufacture/context/context";
import { MenuTypes, animateEntity, backgroundColorManufacture, cardSubProcessItemInfo, setIdElementEntity } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity, ProcessEntity, VariableEntity } from "modules/manufacture/utils/models";
import React, { useCallback, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import ShapeMaterial from "../material/shape";
import ChildrenSubProcess from "./children-proces";

type CardSubProcessDrawProps = Omit<HTMLMotionProps<"div">, "children"> & {
    attach?: Entity<ProcessEntity<Entity<MaterialEntity | VariableEntity>>>;
    asChild?: boolean;
    parentExpand?: boolean;
    children: (totalChild: number) => React.ReactNode;
};

const CardSubProcessDraw = ({ className, parentExpand, attach, asChild, children, ...props }: CardSubProcessDrawProps) => {
    const {
        entities,
        setEntities,
        clickEntityToFocus,
        expandEntities,
        setCurrentDraggingEntity,
        clickEntityToZIndex,
        activeEntitiesMenu,
        activeEntity,
    } = React.useContext(ManufactureContext) as any;

    const [items, setItems] = useState<Entity<MaterialEntity & VariableEntity>[]>([]);

    useEffect(() => {
        setItems(attach?.data?.children || []);
    }, [attach?.data?.children]);

    const dropNewChildren = (data: Entity<MaterialEntity & VariableEntity>) => {
        setEntities((ents: Entities[]) =>
            ents?.map((entity) => {
                if (entity?.idEntity !== attach?.idEntity) return entity;
                if (entity?.data?.children?.find((item) => item?.idEntity === data?.idEntity)) return entity;
                if (entity?.data?.children?.find((item) => item?.data?.id === data?.data?.id)) {
                    return {
                        ...entity,
                        data: {
                            ...entity?.data,
                            children: entity?.data.children.map((item) => {
                                if (item?.data?.id !== data?.data?.id) return item;
                                return {
                                    ...item,
                                    data: {
                                        ...item?.data,
                                        temp_total: data?.data?.total || 1,
                                    },
                                };
                            }),
                        },
                    };
                }
                return {
                    ...entity,
                    data: {
                        ...entity?.data,
                        children: [...(entity?.data?.children || []), { ...data, idEntity: uuidv4() }],
                    },
                };
            })
        );
    };

    const dropTransferChildren = (data: Entity<MaterialEntity & VariableEntity>) => {
        if (attach?.idEntity === data?.idEntityParent) return;
        setEntities((ents: Entities[]) =>
            ents?.map((entity) => {
                if (data?.idEntityParent === entity?.idEntity) {
                    return {
                        ...entity,
                        data: {
                            ...entity?.data,
                            children: entity?.data?.children?.filter((child) => child?.idEntity !== data?.idEntity),
                        },
                    };
                }
                if (attach?.idEntity === entity?.idEntity) {
                    if (entity.data?.children?.find((item) => item?.data?.id === data?.data?.id)) {
                        return {
                            ...entity,
                            data: {
                                ...entity?.data,
                                children: entity?.data.children.map((item) => {
                                    if (item?.data?.id !== data?.data?.id) return item;
                                    return {
                                        ...item,
                                        data: {
                                            ...item.data,
                                            temp_total: data?.data?.total || 1,
                                        },
                                    };
                                }),
                            },
                        };
                    }
                    return {
                        ...entity,
                        data: {
                            ...entity?.data,
                            children: [...(entity?.data?.children || []), data],
                        },
                    };
                }
                return entity;
            })
        );
    };

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.subProcess.type,
            item: { ...MenuTypes.subProcess, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [attach?.left, attach?.top, attach?.idEntity, entities]
    );

    const [{ itemDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [MenuTypes.material.type, MenuTypes.variable.type],
            drop(item: Entity<MaterialEntity & VariableEntity>) {
                if (!item?.idEntity) {
                    dropNewChildren(item);
                    return undefined;
                }
                dropTransferChildren(item);
                return undefined;
            },
            collect(monitor) {
                return {
                    isOver: monitor.isOver(),
                    itemDrop: monitor.getItem(),
                };
            },
        }),
        [attach, entities]
    );

    useEffect(() => {
        setCurrentDraggingEntity(isDragging ? attach : null);
    }, [isDragging]);

    const onClick = (e: any) => {
        e.stopPropagation();
        if (checkIfTextIsEmpty(entities, activeEntity?.idEntity)) {
            message.error("Title can't be empty!");
            return;
        }
        clickEntityToFocus(attach);
        clickEntityToZIndex(attach);
    };

    const sumItemPrice = calculateProcessPrice(items);

    const isActive = activeEntitiesMenu?.length && !activeEntitiesMenu.includes(attach?.idEntity);

    const isPartOfActive = (() => {
        if (!activeEntitiesMenu.length) return true;
        return activeEntitiesMenu.includes(attach?.idEntity);
    })();

    const indexEffectedItem = (() => {
        const index = items.findIndex((child) => child.idEntity === itemDrop?.idEntity);
        if (index < 0) return [];
        return [index - 1, index, index + 1];
    })();

    const isOverNewItem = isOver && !items.find((child) => child?.idEntity === itemDrop?.idEntity) && !itemDrop?.idEntity;
    const isOverTransferItem = isOver && !items.find((child) => child?.idEntity === itemDrop?.idEntity);
    const isOverOldItem = isOver && !!itemDrop?.idEntity;

    const classnameContainer = clsx(
        className,
        cardSubProcessItemInfo.style.className,
        "border border-solid border-gray-300 min-w-[200px] hover:shadow-lg transition-shadow duration-100 w-fit items-center cursor-pointer py-2 gap-4 px-3 flex flex-col",
        !isPartOfActive && "!bg-gray-400 !border-gray-500",
        isActive && "z-[50]",
        !attach?.idEntityParent && "shadow-md",
        isOverNewItem || isOverTransferItem ? "outline outline-gray-500" : "outline outline-transparent",
        !asChild && "absolute transform",
        activeEntity?.idEntity === attach?.idEntity ? "outline-card-entity" : ""
    );

    const onMoveChild = useCallback(
        (hoverIndex: number, item: Entity<MaterialEntity | VariableEntity>) => {
            if (!itemDrop?.idEntity) return; // if new item
            if (!items.find((i) => i.idEntity === itemDrop.idEntity)) return;
            setItems((prev) => {
                const lastIndex = prev.findIndex((i) => i.idEntity === item.idEntity);
                const updateIndex = update(prev, {
                    $splice: [
                        [lastIndex, 1],
                        [hoverIndex, 0, prev[lastIndex]],
                    ],
                });
                setEntities((ents: Entities[]) => {
                    return ents.map((ent) => {
                        if (ent.idEntity !== attach?.idEntity) return ent;
                        return {
                            ...ent,
                            data: {
                                ...ent?.data,
                                children: updateIndex,
                            },
                        };
                    });
                });
                return updateIndex;
            });
        },
        [itemDrop, attach, entities]
    );

    const classnameShape = clsx(
        "transition flex flex-col gap-[1px] duration-100 text-black !min-h-[50px] text-sm w-full",
        isOverNewItem || isOverTransferItem ? "!p-2" : "!p-0"
    );

    const isExpand = expandEntities.find((id: string) => id === attach?.idEntity);

    if (isDragging) return <div />;
    return (
        <motion.div
            title={attach?.data?.text}
            animate={asChild ? {} : animateEntity.animate}
            initial={asChild ? {} : animateEntity.initial}
            transition={asChild ? {} : animateEntity.transition}
            exit={animateEntity.exit}
            role="button"
            tabIndex={0}
            id={setIdElementEntity(attach?.idEntity)}
            onClick={onClick}
            ref={drag}
            style={{ left: attach!.left!, top: attach!.top!, zIndex: attach!.zIndex! }}
            className={classnameContainer}
            {...props}
        >
            <LoaderEntity />
            {children(items?.length || 0)}
            <div id="container-items" ref={drop} className={clsx("w-full", !isPartOfActive ? "grayscale" : "")}>
                <ShapeMaterial
                    backgroundColor={items.length ? cardSubProcessItemInfo.style.color : backgroundColorManufacture.primary}
                    className={classnameShape}
                >
                    <AnimatePresence mode="sync">
                        {!isExpand &&
                            items?.map((item, i) => (
                                <motion.div
                                    animate={isOverNewItem || isOverTransferItem ? { opacity: 0.4 } : { opacity: 1 }}
                                    key={item?.idEntity}
                                    className="w-full"
                                    transition={{ ...animateEntity.transition, damping: 20 }}
                                    exit={{ scale: 0.3, opacity: 0, height: 0 }}
                                >
                                    <ChildrenSubProcess
                                        indexEffectedItem={indexEffectedItem}
                                        isOver={isOver}
                                        onMoveChild={onMoveChild}
                                        isOverNewItem={isOverNewItem}
                                        isOverOldItem={isOverOldItem}
                                        parentEntity={attach!}
                                        entity={item}
                                        index={i}
                                        totalChild={items.length}
                                    />
                                </motion.div>
                            ))}
                        {isExpand && (
                            <ShapeMaterial backgroundColor={cardSubProcessItemInfo.style.secondaryColor} className="w-full min-h-[50px] p-1">
                                <div className="text-gray-600 text-center font-medium w-full mt-2">{items?.length} Items</div>
                            </ShapeMaterial>
                        )}
                    </AnimatePresence>
                </ShapeMaterial>
            </div>
            {items?.length ? (
                <div className="self-start font-light text-sm">
                    Total: <PriceTag price={sumItemPrice} />
                </div>
            ) : null}
        </motion.div>
    );
};

export default CardSubProcessDraw;

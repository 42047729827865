/* eslint-disable jsx-a11y/control-has-associated-label */
import EllipsisMiddle from "components/ellipsis-text/ellipsis-middle";
import { ReturnType } from "hooks/useControlFile";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { MdDeleteOutline } from "react-icons/md";
import { FILE_SIZE_ERROR, FILE_TYPE_ERROR } from "utils/constant";

type Props = {
    name?: string;
    types?: string[];
    label?: string;
    disabled?: boolean;
    hoverTitle?: string;
    imageUrl?: string;
    className?: string;
    children?: (dt: { file: File | null; urlFile: string | null; deleteHandler: () => void }) => React.ReactNode;
    maxSize?: number;
    minSize?: number;
    onTypeError?: (err: any) => void;
    onDelete?: (imageUrl?: string | null) => void;
    onSizeError?: (err: any) => void;
    onDrop?: (file: File) => void;
    onSelect?: (file: File) => void;
    handleChange?: (file: File | null) => void;
    onDraggingStateChange?: (dragging: any) => void;
    deleteHandler?: (dlt: () => void) => React.ReactNode;
    onError?: { [key: string]: { message: string; ref: any; type: string } };
    control?: ReturnType;
};

const InputFile = ({ ...props }: Props) => {
    const [error, setError] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [urlFile, setUrlFile] = useState<string | null>(null);

    const urlFileWatch = props.control?.watchValue ? props.control?.watchValue(props?.name || "") : null;

    useEffect(() => {
        setUrlFile(urlFileWatch || props.imageUrl);
    }, [urlFileWatch, props.imageUrl]);

    const handleChange = (fl: File | null) => {
        setError("");
        setFile(fl);
        if (props.handleChange) {
            props.handleChange(fl);
        }
        if (props.control) {
            props.control.handleChange(props?.name || "", fl);
        }
    };

    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(urlFile);
        }
        handleChange(null);
        setUrlFile(null);
    };

    const onSizeError = (err: any) => {
        setError(err || FILE_SIZE_ERROR);
        if (props.onSizeError) {
            props.onSizeError(err || FILE_SIZE_ERROR);
        }
    };

    const onTypeError = (err: any) => {
        setError(err || FILE_TYPE_ERROR);
        if (props.onTypeError) {
            props.onTypeError(err || FILE_TYPE_ERROR);
        }
    };

    // const isError = !!error || !!props.onError || !!props.control?.errors;

    return (
        <>
            <div className={`h-fit ${props.className}`}>
                {props.label && <p className="m-0 capitalize mb-2">{props.label}</p>}
                <div className="relative h-fit">
                    <FileUploader
                        {...props}
                        multiple={false}
                        classes={`INPUT-FILE-UPLOADER ${props?.disabled ? "pointer-events-none" : ""}`}
                        onTypeError={onTypeError}
                        onSizeError={onSizeError}
                        handleChange={handleChange}
                    >
                        {props.children ? (
                            props.children({ file, urlFile, deleteHandler: onDelete })
                        ) : (
                            <button
                                type="button"
                                className="flex items-center justify-between border rounded-md border-solid cursor-pointer border-gray-300 w-full py-2 px-2"
                            >
                                {!file ? (
                                    <p className="m-0 text-xs text-gray-400">Drop Or Click</p>
                                ) : (
                                    <EllipsisMiddle className="text-xs !max-w-[90%]" suffixCount={20}>
                                        {file?.name}
                                    </EllipsisMiddle>
                                )}
                            </button>
                        )}
                    </FileUploader>
                    {!props.deleteHandler && (file || urlFile) && (
                        <button
                            title="Delete"
                            type="button"
                            onClick={onDelete}
                            className="cursor-pointer w-10 h-10 rounded-md border-none bg-transparent absolute top-2 right-2 z-2 !bg-red-400 opacity-70 hover:opacity-100 flex items-center justify-center"
                        >
                            <MdDeleteOutline className="text-white text-2xl" />
                        </button>
                    )}
                </div>
                {error && <p className="text-red-500 m-0 my-1 text-sm capitalize">{error}</p>}
                {props.onError && <p className="text-red-500 m-0 my-1 text-sm capitalize">{props.onError[props?.name || ""]?.message}</p>}
                {props.control?.errors && (
                    <p className="text-red-500 m-0 my-1 text-sm capitalize">{props.control.errors[props?.name || ""]?.message}</p>
                )}
            </div>
            {props.deleteHandler && props.deleteHandler(onDelete)}
        </>
    );
};

export default InputFile;

import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useRef } from "react";
import { useQuery } from "react-query";
import manufacturingOrderService, { LocatorManufacturingOrder } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";

type Props = ModalProps & {
    productId: number | undefined;
    onSubmit: (locator: LocatorManufacturingOrder) => void;
};

const TableLocatorManufacturing = ({ onSubmit, productId, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const locatorQuery = useQuery(
        [manufacturingOrderService.locator, productId],
        async () => {
            return (await manufacturingOrderService.Locator({ product_id: productId as any })).data.data;
        },
        {
            enabled: !!productId,
        }
    );

    const onAddLocator = (locator: LocatorManufacturingOrder) => {
        return () => {
            if (!productId) {
                message.error("Product not found");
                return;
            }
            if (!locator.qty_locator) {
                message.error("Stock not available");
                return;
            }
            // if (product.quantity > Number(locator.qty_locator)) {
            //     message.error("Not enough stock");
            //     return;
            // }
            onSubmit(locator);
            closeRef.current?.click();
        };
    };

    const columns: ColumnsType<LocatorManufacturingOrder> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Area Name",
            dataIndex: "area_name",
        },
        {
            title: "Quantity Locator",
            dataIndex: "qty_locator",
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return <Button onClick={onAddLocator(record)}>Add Locator</Button>;
            },
        },
    ];

    return (
        <ModalTemplate width={700} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        size="small"
                        loading={locatorQuery.isLoading}
                        columns={columns}
                        dataSource={locatorQuery.data || []}
                        className="w-full"
                        pagination={false}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default TableLocatorManufacturing;

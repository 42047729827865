import { Button, Card, Select } from "antd";
import React from "react";
import { useQuery } from "react-query";
import voucherService, { User } from "services/api-endpoints/dashboard/master-data/voucher";
import UserTable from "./user-table";

const createKey = (data: User) => [data.email, data.name, data.id].filter((el) => el).join("~");
const getUserId = (key: string) => key.split("~")[key.split("~").length - 1];

type Props = {
    selectedUser: User[];
    setSelectedUser: React.Dispatch<React.SetStateAction<User[]>>;
};

const UserPicker = ({ selectedUser, setSelectedUser }: Props) => {
    const getUser = useQuery([voucherService.getDataCustomer, 1], async () => {
        return (await voucherService.GetUser()).data.data;
    });

    const onChange = (keys: string[]) => {
        const ids = keys.map((key) => getUserId(key));
        const users = getUser.data?.list.filter((user) => ids.find((id) => user.id === Number(id)));
        setSelectedUser(users || []);
    };

    const onSelectAll = () => {
        setSelectedUser(getUser.data?.list || []);
    };

    const onDeselectAll = () => {
        setSelectedUser([]);
    };

    const onRemove = (user: User) => {
        return () => {
            setSelectedUser((prev) => [...prev].filter((usr) => usr.id !== user.id));
        };
    };

    return (
        <Card className="!bg-gray-50">
            <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center gap-4 w-full">
                    <Select
                        value={selectedUser.map((user) => createKey(user))}
                        onChange={onChange}
                        loading={getUser.isLoading}
                        showSearch
                        mode="multiple"
                        placeholder="Pick user"
                        maxTagCount="responsive"
                        className="w-full"
                    >
                        {getUser.data?.list?.map((user) => (
                            <Select.Option value={createKey(user)} key={user.id}>
                                <p style={{ fontSize: "14px", margin: 0 }}>
                                    {user.email}
                                    <br />
                                    <span style={{ margin: 0, fontSize: "12px", fontWeight: "bold" }}>{user.name}</span>
                                </p>
                            </Select.Option>
                        ))}
                    </Select>
                    <Button onClick={onSelectAll}>Select All</Button>
                    {selectedUser.length !== 0 ? (
                        <Button onClick={onDeselectAll} danger>
                            Deselect All
                        </Button>
                    ) : null}
                </div>
                <UserTable loading={getUser.isLoading} list={selectedUser} onRemove={onRemove} />
            </div>
        </Card>
    );
};

export default UserPicker;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { LocatorData } from "services/api-endpoints/dashboard-jsl/picking-list";
import { ProductPD } from "services/api-endpoints/dashboard/master-data/ext-picking-detail";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function tableEdit<T extends ProductPD>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => (
                    <div className="flex flex-row">
                        <p className="capitalize m-0 mr-2">{text || "-"}</p>
                        {record.note && (
                            <Tag color="blue" className="!h-fit">
                                {record.note}
                            </Tag>
                        )}
                        {record.status === "delete" && (
                            <Tag color="red" className="!h-fit">
                                Deleted
                            </Tag>
                        )}
                        {record.status === "update" && (
                            <Tag color="green" className="!h-fit">
                                Edited
                            </Tag>
                        )}
                    </div>
                ),
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code 2",
                dataIndex: "code2",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Locator",
                dataIndex: "area_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Quantity Request",
                dataIndex: "qty",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Approved",
                dataIndex: "qty_approved",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className={text === null || text === 0 ? "m-0 text-center text-red-500" : "m-0 text-center "}>{text || "-"}</p>,
            },
        ];

        const isEditing = (record: ProductPD, edited: ProductPD | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: ProductPD, edited: ProductPD) => record.product_id === edited?.product_id;
        const rowKey = (record: ProductPD) => record.product_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty_approved: "number" };

        const reqQty = editRow?.qty || 1;
        // const locQty = editRow?.qty_locator || undefined;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: reqQty,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
                onChange={handleTableChange}
            />
        );
    };
}

const TableEdit = tableEdit(EditTable);
export default TableEdit;

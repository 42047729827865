import clsx from "clsx";
import React from "react";

export type ShapeMaterialProps = React.HTMLProps<HTMLDivElement> & {
    backgroundColor?: string;
    children?: any;
    asDraw?: boolean;
};

const ShapeMaterial = React.forwardRef(
    ({ backgroundColor, asDraw, className, children, ...props }: ShapeMaterialProps, ref?: React.ForwardedRef<HTMLDivElement> | null) => {
        const cls = clsx(
            "relative py-1 pb-3 flex flex-col items-center w-full rounded-b rounded-tr h-full",
            asDraw ? "shadow-[0px_-10px_13px_-4px_rgba(0,0,0,0.2)]" : "",
            backgroundColor,
            className
        );
        return (
            <div ref={ref} className={cls} {...props}>
                {asDraw ? (
                    <div
                        className="h-[6px] w-[50%] absolute -top-[6px] left-0 transform"
                        style={{ filter: "drop-shadow(rgba(50, 50, 0, 0.2) -3px -4px 3px)" }}
                    >
                        <div className={`${backgroundColor} w-full h-full`} style={{ clipPath: "polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%)" }} />
                    </div>
                ) : (
                    <div
                        className={`h-[6px] w-[50%] absolute -top-[6px] left-0 transform ${backgroundColor}`}
                        style={{ clipPath: "polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%)" }}
                    />
                )}
                <div className="w-full z-10 h-full">{children}</div>
            </div>
        );
    }
);

export default ShapeMaterial;

import { ProductWithQty } from "modules/advance-search/models";
import { CreatePO } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { CreatePOExt } from "services/api-endpoints/dashboard-jsl/purchase-order-ext";

export default {};

export const parseProductsToCreate = (products: ProductWithQty[], note?: string): CreatePO => {
    return {
        note,
        detail_product: [...products].map((el) => ({ price: el.product_price, qty: el.qty, product_id: el.product_id })),
    };
};

export const parseProductsToCreateExt = (bpId: number | null, products: ProductWithQty[]): CreatePOExt => {
    return {
        bp_id: bpId,
        detail_product: [...products].map((el) => ({ price: el.product_price, qty: el.qty, product_id: el.product_id })),
    };
};

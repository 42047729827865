import moment from "moment";
import { RecapOffline } from "services/api-endpoints/dashboard/master-data/rekap";
import { FORMAT_DATE_2 } from "utils/constant";
import XLSX from "xlsx-js-style";

export default {};
export const rekapOfflineAccountingToExcel = ({ title, list }: { title: string; list: RecapOffline[] }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Invoice No", ...headerStyle },
        { v: "Referensi No", ...headerStyle },
        { v: "Info", ...headerStyle },
        { v: "Note", ...headerStyle },
        { v: "Sales", ...headerStyle },
        { v: "Payment Channel", ...headerStyle },
        { v: "Product Code", ...headerStyle },
        { v: "Product Name", ...headerStyle },
        { v: "Qty", ...headerStyle },
        { v: "Price", ...headerStyle },
        { v: "Discount", ...headerStyle },
        { v: "HPP", ...headerStyle },
        { v: "Price : HPP", ...headerStyle },
        { v: "Create Date", ...headerStyle },
        { v: "Status", ...headerStyle },
    ];

    const errorStyle = { font: { bold: true, color: { rgb: "de122d" } } };
    const getRasio = (price: number, hpp: number) => {
        let tempPrice = price;
        let tempHpp = hpp;
        while (tempHpp !== 0) {
            const temp = tempHpp;
            tempHpp = tempPrice % tempHpp;
            tempPrice = temp;
        }
        return tempPrice;
    };

    const rows: any[] = [];
    list?.forEach((el, listIndex) => {
        const row = [
            { v: listIndex + 1, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.inv_no, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.ref_no || "-", t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.info, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.note, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.sales, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.payment_channel, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.code1, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.name, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.qty, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.price, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.diskon, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.hpp || "-", t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            {
                v: `${el.price_hpp}%`,
                t: "s",
                s: el.status === "Canceled" ? errorStyle : {},
            },
            { v: moment(el.created_at)?.format(FORMAT_DATE_2), t: "s", s: el.status === "Canceled" ? errorStyle : {} },
            { v: el.status, t: "s", s: el.status === "Canceled" ? errorStyle : {} },
        ];
        rows.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
};

import ModalNote from "components/modal/note-modal";
import { HandlerProps as ModalTemplateHandlerProps } from "components/modal/template-modal";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import MaterialMovementDocTable from "modules/master-data/material-movement-doc/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import materialMovementService, {
    DeleteMM,
    MaterialMovement as MaterialMovementModel,
} from "services/api-endpoints/dashboard/master-data/material-movement";

const MaterialMovementDoc = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const materialMovementDelete = React.useRef<MaterialMovementModel | null>(null);

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getAll = useQuery([materialMovementService.getMaterialMovement, page, searchValue, isSearch, "material-movement-doc"], async () => {
        if (isSearch) return (await materialMovementService.Search({ page, query: searchValue.query, type: 2 })).data.data; // search
        return (await materialMovementService.GetMaterialMovement({ page, type: 2 })).data.data; // all
    });

    const deleteMutation = useMutation(async (data: DeleteMM) => (await materialMovementService.Delete(data)).data.data, {
        onSuccess: () => getAll.refetch(),
    });

    const [showConfirm] = useConfirmDelete<DeleteMM>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    const onSubmitNote = (note: string) => {
        if (!materialMovementDelete.current?.id) return;
        showConfirm({ note, id: materialMovementDelete.current?.id });
    };

    const onClickDelete = (handler: ModalTemplateHandlerProps) => {
        return (data: MaterialMovementModel) => {
            handler.openModal();
            materialMovementDelete.current = data;
        };
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar filter={false} placeholder="Search..." />
            <ModalNote title="Delete Notes" onSubmit={onSubmitNote}>
                {(handler) => <MaterialMovementDocTable fetcher={getAll} onClickDelete={onClickDelete(handler)} />}
            </ModalNote>
        </div>
    );
};

export default MaterialMovementDoc;

import React, { useContext } from "react";
import { CUSTOMER_TYPE } from "utils/constant";
import { User } from "services/api-endpoints/dashboard/master-data/voucher";
import { VoucherContext } from "../context/voucher";
import UserTable from "../lib/user-table";
import RegionTable from "../lib/region-table";

const DataCustomer = () => {
    const { state } = useContext(VoucherContext);

    return (
        <div className="flex flex-col gap-6">
            <p className="TEXT-1 mt-4">
                customer type <br />
                <span className="TEXT-2">{CUSTOMER_TYPE.find((el) => el.value === state?.createData.customer_type)?.label}</span>
            </p>
            {state?.createData?.array_user?.length ? (
                <UserTable list={state?.createData?.array_user?.map((user) => ({ ...user, full_name: user.name } as User))} />
            ) : null}

            {state?.createData?.array_region?.length ? <RegionTable list={state?.createData?.array_region} /> : null}
        </div>
    );
};

export default DataCustomer;

import { Button, message, Radio, RadioChangeEvent, Skeleton } from "antd";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { Product } from "models";
import { SelectOptionCustom } from "modules/master-data/price/lib/warehouse-select";
import React, { useEffect, useRef } from "react";
import { IoRefresh } from "react-icons/io5";
import { useQuery } from "react-query";
import purchaseOrderService from "services/api-endpoints/dashboard-jsl/purchase-order";
import Utils from "utils";
import { PO_PRODUCT_TYPE, PO_PRODUCT_TYPE2 } from "utils/constant";

const GetAllProduct = React.lazy(() => import("./get-all-product"));

type Props = {
    children: (data: HandlerProps) => void;
    onSelectProduct?: (data: Product) => void;
    onSearchSelectProduct?: (data: Product) => void;
    onSearchDeSelectProduct?: (data: Product) => void;
    selected: Product[];
    setSelected?: React.Dispatch<React.SetStateAction<Product[]>>;
    productOwner: number;
    setProductOwner: React.Dispatch<React.SetStateAction<number>>;
    isSPM: boolean;
};

const AdvanceSearchBp = ({
    children,
    selected,
    setSelected,
    onSelectProduct,
    onSearchDeSelectProduct,
    onSearchSelectProduct,
    productOwner,
    setProductOwner,
    isSPM,
}: Props) => {
    const closeModalRef = useRef<HTMLButtonElement | null>(null);

    const getAllProduct = useQuery(
        [purchaseOrderService.productPrice, productOwner],
        async () => {
            return (await purchaseOrderService.ProductPrice({ type_id: productOwner })).data.data
                ?.filter((v, i, a) => a.findIndex((v2) => v2.product_id === v.product_id) === i)
                ?.map((el) => ({ ...el, label: el.product_name, value: el.product_id } as SelectOptionCustom));
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
        }
    );

    const onSearchSelectProductHandler = (product: Product) => {
        if (selected.find((p) => p.product_id === product.product_id)) {
            message.error("Product already exist!");
            return;
        }
        if (onSearchSelectProduct) {
            onSearchSelectProduct(product);
            return;
        }
        if (setSelected) setSelected((prev) => [...prev, { ...product, qty: 1, product_code: product.product_code1 }]);
    };

    const onSearchDeSelectProductHandler = (product: Product) => {
        if (onSearchDeSelectProduct) {
            onSearchDeSelectProduct(product);
            return;
        }
        if (setSelected) setSelected((prev) => [...prev].filter((p) => p.product_id !== product.product_id));
    };

    const GetAllProductMemoize = React.useMemo(
        () => (
            <GetAllProduct
                selected={selected}
                onSelectProduct={onSearchSelectProductHandler}
                onDeSelectProduct={onSearchDeSelectProductHandler}
                getAllProduct={getAllProduct}
                isLoading={getAllProduct.isLoading}
            />
        ),
        [selected, onSearchSelectProduct, onSearchDeSelectProduct, productOwner, getAllProduct.isLoading]
    );

    const onChangeLogic = (e: RadioChangeEvent) => {
        if (setSelected) setSelected([]);
        setProductOwner(e.target.value);
    };

    const onRefresh = () => {
        getAllProduct.refetch();
    };

    return (
        <ModalTemplate width={900} title="Advance Search" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-3">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <div className="w-full flex items-center justify-between">
                        <p className="capitalize underline m-0">Product Owner</p>
                        <Button className="w-fit" onClick={onRefresh}>
                            <IoRefresh className="mr-3" />
                            Refresh Product
                        </Button>
                    </div>
                    <Radio.Group
                        className="m-0 mb-5"
                        onChange={onChangeLogic}
                        value={productOwner}
                        options={isSPM ? PO_PRODUCT_TYPE2 : PO_PRODUCT_TYPE}
                    />
                    <div className="w-full relative flex flex-col gap-3 items-start">
                        <p className="capitalize underline m-0 mt-4">Search Product</p>
                        <React.Suspense fallback={<Skeleton paragraph={{ rows: 1 }} active />}>{GetAllProductMemoize}</React.Suspense>
                    </div>
                </div>
            )}
        </ModalTemplate>
    );
};

export default React.memo(AdvanceSearchBp);

import { Select, Spin, message } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React, { useRef } from "react";
import { Order } from "services/api-endpoints/dashboard/master-data/return-order";

type Props = {
    children: (data: HandlerProps) => void;
    selected: Order[];
    onSelectOrder: (data: Order) => void;
    onDeSelectOrder: (data: Order) => void;
    getDetailOrderLoading: boolean;
    orderData: (SelectOption & Order)[] | undefined;
    loading: boolean;
};

const ModalDataOrder = ({ children, selected, onSelectOrder, onDeSelectOrder, getDetailOrderLoading, orderData, loading }: Props) => {
    const closeModalRef = useRef<HTMLButtonElement | null>(null);

    const onSelect = (invNo: any) => {
        const order = orderData?.find((ordr) => ordr.inv_no === invNo);
        if (!order) {
            message.error("Order Not Found");
            return;
        }
        onSelectOrder(order as Order);
    };

    const onDeSelect = (invNo: any) => {
        const order = orderData?.find((ordr) => ordr.inv_no === invNo);
        if (!order) {
            message.error("Order Not Found");
            return;
        }
        onDeSelectOrder(order as Order);
    };

    const OptionsMemoize = React.useCallback(
        () =>
            orderData?.map((option) => {
                return (
                    <Select.Option value={option.label} key={option.order_reference}>
                        <p style={{ fontSize: "12px", margin: 0 }}>
                            {option.inv_no === " null " ? "-" : option.inv_no}
                            <br />
                            <span style={{ margin: 0, fontSize: "9px", fontWeight: "bold" }}>
                                {`${option.order_reference} | ${option.total_items} items`}
                            </span>
                        </p>
                    </Select.Option>
                );
            }),
        [orderData]
    );

    const SelectMemoize = React.useMemo(
        () => (
            <Select
                value={selected.map((p) => p.inv_no)}
                bordered
                disabled={loading}
                mode="multiple"
                className="!w-full"
                showSearch
                loading={loading}
                placeholder="Invoice No"
                onSelect={onSelect}
                onDeselect={onDeSelect}
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return (
                        (option?.key ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase()) ||
                        (option?.value ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())
                    );
                }}
            >
                {OptionsMemoize()}
            </Select>
        ),
        [orderData, selected]
    );

    return (
        <ModalTemplate
            title={
                <div className="flex item-center">
                    <span className="mr-3">Add Data Order</span> {getDetailOrderLoading && <Spin />}
                </div>
            }
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <React.Suspense fallback={<p className="m-0">Loading...</p>}>{SelectMemoize}</React.Suspense>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalDataOrder;

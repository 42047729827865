import { Button, Card, Collapse, Form, Select, Space, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiPlus, BiSave } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { AUTOGENERATED, FORMAT_DATE_1, ORDER_PAYMENT_ONLINE, TYPE_ORDER_PAYMENT } from "utils/constant";
import React, { useState } from "react";
import ModalAddItemJurnalPenyesuaian, { AddItem, AddItemT } from "modules/accounting/jurnal-penyesuaian/add/modal-add-item";
import TableAddJurnalPenyesuaian from "modules/accounting/jurnal-penyesuaian/add/table-add";
import jurnalPenyesuaianService, {
    BodyChildJurnalPenyesuaian,
    BodyCreateJurnalPenyesuaian,
    InvoiceItem,
} from "services/api-endpoints/accounting/jurnal-penyesuaian";
import { useMutation, useQuery } from "react-query";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectOption } from "models";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService from "services/api-endpoints/dashboard/payment";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import { RiDraftLine } from "react-icons/ri";
import moment from "moment";

const schema: yup.SchemaOf<Partial<BodyCreateJurnalPenyesuaian>> = yup.object().shape({
    transaction_date: yup.string().required("Date Required"),
    doc_id: yup.number(),
    doc_type: yup.number(),
    inv_no: yup.string(),
    inv_ref: yup.string(),
    type: yup.number(),
    market_place: yup.number(),
    child: yup.array(),
});

const JurnalPenyesuaianAdd = () => {
    const navigate = useNavigate();
    const [list, setList] = useState<AddItemT[]>([]);
    const [editedItem, setEditedItem] = useState<AddItemT | undefined>(undefined);
    const [optionsInv, setOptionsInv] = useState<(SelectOption & InvoiceItem)[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<BodyCreateJurnalPenyesuaian>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchSalesType = watch("type");
    const watchMarketplace = watch("market_place");
    const watchDocId = watch("doc_id");
    const watchInvNo = watch("inv_no");
    const watchInvRef = watch("inv_ref");
    const watchDate = watch("transaction_date");

    const balanceAmount = list?.reduce((a, b) => (b.type ? a + b.amount : a), 0) === list?.reduce((a, b) => (!b.type ? a + b.amount : a), 0);

    const queryInvoice = useQuery(
        [jurnalPenyesuaianService.invoice, watchMarketplace, watchSalesType],
        async () => {
            const req = await jurnalPenyesuaianService.GetInvoice({
                type: watchSalesType,
                inv_no: "",
                partner_id: watchSalesType === ORDER_PAYMENT_ONLINE ? watchMarketplace : "",
            });
            return req.data.data;
        },
        {
            enabled: !!watchSalesType,
            onSuccess(data) {
                setOptionsInv(
                    data.map((el) => ({ ...el, label: el.inv_no, value: el.inv_ref === null ? el.inv_id : el.inv_ref } as SelectOption & InvoiceItem))
                );
            },
        }
    );

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const bpQuery = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const onAddItem = (item: AddItem & { id?: any }) => {
        setList((prev) => {
            const item2: AddItemT = {
                ...item,
                invoice_detail: { inv_id: watchDocId, inv_no: watchInvNo } as InvoiceItem,
            };
            if (prev?.find((el) => el.id === item.id)) {
                return prev?.map((el) => (el.id !== item.id ? el : item2));
            }
            return [...prev, item2];
        });
        setEditedItem(undefined);
    };

    const onDeleteItem = (item: AddItemT) => {
        setList((prev) => prev?.filter((el) => el.id !== item.id));
    };

    const onEditItem = (item: AddItemT) => {
        setEditedItem(item);
    };

    const saveMutate = useMutation(async (data: BodyCreateJurnalPenyesuaian) => {
        return (await jurnalPenyesuaianService.Create(data)).data;
    });

    const saveDraftMutate = useMutation(async (data: BodyCreateJurnalPenyesuaian) => {
        return (await jurnalPenyesuaianService.CreateDraft(data)).data;
    });

    const prepareData = () => {
        return [...list]?.map(
            (el) =>
                ({
                    coa_id: el.account_detail?.coa_id,
                    debit: el.type ? null : el.amount,
                    kredit: el.type ? el.amount : null,
                    name: el.account_detail?.coa_name,
                    note: el.invoice_detail?.inv_no,
                } as BodyChildJurnalPenyesuaian)
        );
    };

    const onCreateSubmitHandler = () => {
        saveMutate
            .mutateAsync({
                doc_id: watchDocId,
                doc_type: 6,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                type: watchSalesType,
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Journal");
                navigate(-1);
            });
    };

    const save = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftSubmitHandler = () => {
        saveDraftMutate
            .mutateAsync({
                doc_id: watchDocId,
                doc_type: 6,
                transaction_date: moment(watchDate).format(FORMAT_DATE_1),
                type: watchSalesType,
                child: prepareData(),
            })
            .then(() => {
                message.success("Success Create Draft Journal");
                navigate(-1);
            });
    };

    const saveDraft = async () => {
        if (!watchDate) {
            message.error("Date Required");
            return;
        }
        // await form.validateFields();
        onDraftSubmitHandler();
    };

    const onClickInvoice = (key: any) => {
        const invoice = optionsInv.find((val) => val.inv_no === key);
        setValue("doc_id", invoice?.inv_id);
        setValue("inv_no", invoice?.inv_no);
        setValue("inv_ref", invoice?.inv_ref);
        setList(
            list.map(
                (el) =>
                    ({
                        ...el,
                        invoice_detail: { inv_id: invoice?.value, inv_no: invoice?.label } as InvoiceItem,
                    } as AddItemT)
            )
        );
    };

    const isInvoice = () => {
        if (watchSalesType === ORDER_PAYMENT_ONLINE) {
            return watchMarketplace;
        }
        return watchSalesType;
    };

    const OptionsMemoize = React.useCallback(
        () =>
            optionsInv?.map((option) => {
                return (
                    <Select.Option value={option.inv_no} key={option.inv_ref === null ? option.inv_id : option.inv_ref}>
                        <p style={{ fontSize: "14px", margin: 0 }}>
                            {option.inv_no}
                            <br />
                            <span style={{ margin: 0, fontSize: "10px", fontWeight: "bold" }}>
                                {`${option.inv_ref === null ? "-" : option.inv_ref}`}
                            </span>
                        </p>
                    </Select.Option>
                );
            }),
        [optionsInv]
    );

    const SelectMemoize = React.useMemo(
        () => (
            <Select
                allowClear
                showSearch
                className="w-full"
                loading={queryInvoice.isLoading}
                placeholder="Invoice"
                disabled={!isInvoice()}
                filterOption={(input, option) => {
                    return (
                        (option?.key ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase()) ||
                        (option?.value ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())
                    );
                }}
                onChange={(val) => onClickInvoice(val)}
            >
                {OptionsMemoize()}
            </Select>
        ),
        [optionsInv]
    );

    const onChangeType = (val: any) => {
        setValue("type", val);
        setValue("market_place", undefined);
        setValue("doc_id", undefined);
        setValue("inv_no", undefined);
        setValue("inv_ref", undefined);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add jurnal penyesuaian"
                rightAddition={() => (
                    <Space>
                        <Button
                            onClick={saveDraft}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading}
                            loading={saveDraftMutate.isLoading}
                            type="ghost"
                            className="!flex !items-center !bg-white"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            onClick={save}
                            loading={saveMutate.isLoading}
                            disabled={!list.length || !balanceAmount || saveDraftMutate.isLoading || saveMutate.isLoading}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical">
                    <div className="grid grid-cols-4 gap-x-5">
                        <p className="capitalize m-0 col-span-1">
                            Journal No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledInputDate control={control} name="transaction_date" placeholder="Transaction Date" label="Transaction Date" />
                        <div className="col-span-2 mt-5">
                            <Collapse defaultActiveKey={["0"]}>
                                <CollapsePanel header="Reference Document Sales" key="1">
                                    <div className="grid grid-cols-2 gap-x-5">
                                        <ControlledSelectInput
                                            control={control}
                                            name="type"
                                            options={TYPE_ORDER_PAYMENT}
                                            placeholder="Sales Type"
                                            label="Sales Type"
                                            onChange={(val) => onChangeType(val)}
                                        />
                                        <>
                                            {watchSalesType === ORDER_PAYMENT_ONLINE ? (
                                                <ControlledSelectInput
                                                    showSearch
                                                    control={control}
                                                    name="market_place"
                                                    options={marketplaceQuery.data}
                                                    loading={marketplaceQuery.isLoading}
                                                    placeholder="Market Place"
                                                    label="Market Place"
                                                />
                                            ) : null}
                                        </>
                                        <div className="w-full">
                                            <p className="capitalize m-0 col-span-1 mb-2">Invoice</p>
                                            <React.Suspense fallback={<p className="m-0">Loading...</p>}>{SelectMemoize}</React.Suspense>
                                        </div>
                                        {watchInvRef ? (
                                            <div className="w-full">
                                                <p className="capitalize m-0 col-span-1 mt-1">Invoice Ref</p>
                                                <p className="capitalize m-0 col-span-1 mt-2">{watchInvRef}</p>
                                            </div>
                                        ) : null}
                                    </div>
                                </CollapsePanel>
                            </Collapse>
                        </div>
                    </div>
                </Form>
            </Card>
            <ModalAddItemJurnalPenyesuaian afterClose={() => setEditedItem(undefined)} defaultValues={editedItem} onSubmit={onAddItem}>
                {(ctrl) => (
                    <div className="w-full flex items-end justify-between">
                        <Button
                            disabled={saveMutate.isLoading || saveDraftMutate.isLoading}
                            type="default"
                            className="!flex !items-center w-fit"
                            onClick={ctrl.openModal}
                        >
                            <BiPlus />
                            Add Item
                        </Button>
                        {!balanceAmount ? <p className="m-0 text-red-400">The number of credit and debit amount must be balance</p> : null}
                    </div>
                )}
            </ModalAddItemJurnalPenyesuaian>
            <TableAddJurnalPenyesuaian onClickEdit={onEditItem} onClickDelete={onDeleteItem} list={list} />
        </div>
    );
};

export default JurnalPenyesuaianAdd;

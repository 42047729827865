/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ChildRequest {
    tax_name?: any;
    tax_id?: any;
    product_id: number;
    product_name: string;
    product_code?: string;
    qty: number;
    qty_receipt?: number;
    qty_max?: number;
    type_id: number;
    type_name: string;
    uom_id?: number;
    uom_name?: string;
    uom_code?: string;
    discount_price?: any;
    discount_name?: any;
    discount_qty?: any;
    note?: string;
}

export interface CreatePurchasingRequest {
    doc_type: number;
    note: string;
    child: ChildRequest[];
}

export interface EditPurchasingRequest extends CreatePurchasingRequest {
    id: any;
}

export interface FilterRequest {
    docType?: number;
    start_date?: string;
    end_date?: string;
    page?: number;
}

export interface ProductPR {
    product_code?: string;
    product_status?: boolean;
}

export interface RequestList {
    id?: number;
    pr_no?: string;
    qty?: number;
    request_by?: string;
    doc_status?: number;
    doc_type?: number;
    doc_name?: number;
    note?: string;
    product?: ProductPR[];
    created_at?: string;
}

export interface DetailRequest {
    account_address?: string;
    account_name?: string;
    id?: number;
    pr_no?: string;
    note?: string;
    created_by?: number;
    request_by?: string;
    doc_status?: number;
    doc_type?: number;
    created_at?: string;
    child?: ChildRequest[];
}

export interface ListHistory {
    pr_id?: string;
    note?: string;
    created_by?: string;
    created_at?: string;
}

export interface HistoryOrder {
    icon?: string;
    name?: string;
    request_status?: number;
    list: ListHistory[];
}

export interface ApprovedData {
    id?: any;
    note?: any;
    type_approved?: number;
}

export interface JourneyPOData {
    doc_id?: number;
    doc_no?: string;
    vendor_id?: number;
    vendor_name?: string;
    type?: number;
    child?: JourneyPOData[];
}

export interface ProductInventory {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    type?: number;
    uom_code?: string;
    uom_id?: number;
}

export interface CreatedByData {
    created_by: number;
    full_name: string;
    email: string;
}

class PurchasingRequestService extends BaseService {
    getAll = "/purchasing-request/get-all-purchasing-request";

    draftRequest = "/purchasing-request/save-draft";

    createRequest = "/purchasing-request/create";

    detailRequest = "/purchasing-request/detail-purchasing-request";

    deleteRequest = "/purchasing-request/delete-purchasing-request";

    historyRequest = "/purchasing-request/get-history";

    approvedRequest = "/purchasing-request/set-approved";

    journeyPO = "/purchasing-request/get-journey-po";

    getProduct = "/purchasing-request/get-product";

    void = "/purchasing-request/void";

    createdBy = "/purchasing-request/get-request-pr";

    constructor() {
        super();
    }

    CreatedBy<T extends CreatedByData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.createdBy,
            });
            return req;
        });
    }

    Void<T extends any>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.void,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetProduct<T extends ProductInventory[]>(params: { type: any; pr_type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
                config: { params },
            });
            return req;
        });
    }

    JourneyPO<T extends JourneyPOData[]>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.journeyPO}/${id}`,
            });
            return req;
        });
    }

    ApprovedRequest<T extends any>(data: ApprovedData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.approvedRequest,
                data,
            });
            return req;
        });
    }

    HistoryRequest<T extends HistoryOrder[]>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.historyRequest}/${data.id}`,
            });
            return req;
        });
    }

    DeleteRequest<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteRequest}/${id}`,
            });
            return req;
        });
    }

    DetailRequest<T extends DetailRequest>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailRequest,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SaveDraftPurchasing<T extends any>(data: CreatePurchasingRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftRequest,
                data,
            });
            return req;
        });
    }

    SaveCreatePurchasing<T extends any>(data: CreatePurchasingRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createRequest,
                data,
            });
            return req;
        });
    }

    DraftPurchasing<T extends any>(data: CreatePurchasingRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftRequest,
                data,
            });
            return req;
        });
    }

    CreatePurchasing<T extends any>(data: CreatePurchasingRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createRequest,
                data,
            });
            return req;
        });
    }

    GetAll<T extends RequestList>(param: FilterRequest) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const purchasingRequestService = new PurchasingRequestService();
export default purchasingRequestService;

/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export enum Type {
    Debit = "DEBIT",
    Kredit = "KREDIT",
}

export interface Child {
    name?: string;
    type?: Type;
    amount?: number;
}

export interface JurnalPenyesuaianItem {
    id?: number;
    doc_id?: string;
    journal_no?: string;
    is_fn_posted?: number;
    is_acc_posted?: number;
    created_at?: string;
    coa_id?: number;
    child?: Child[];
}

export interface JurnalPenyesuaian {
    list_unposted?: JurnalPenyesuaianItem[];
    list_posted?: JurnalPenyesuaianItem[];
    list_draft?: JurnalPenyesuaianItem[];
}

export interface FilterJurnalPenyesuaian {
    start_date?: any;
    end_date?: any;
    type?: any;
}

export interface AccountItem {
    coa_id: number;
    coa_name: string;
    coa_code: string;
}

export interface InvoiceItem {
    inv_id: number;
    inv_no: string;
    inv_ref?: string;
    marketplace_id?: number;
    grand_total?: number;
    description?: string;
    created_at?: number;
}

export interface ParamsGetInvoice {
    type: any;
    partner_id?: any;
    inv_no?: any;
}

export interface BodyChildJurnalPenyesuaian {
    coa_id?: number;
    name?: string;
    debit?: number;
    kredit?: number;
    note?: string;
    id?: any;
    journal_id?: number;
    hpp?: any;
    is_active?: number;
}

export interface BodyCreateJurnalPenyesuaian {
    doc_id?: any;
    doc_type?: number;
    inv_no?: string;
    inv_ref?: string;
    type?: number;
    market_place?: number;
    transaction_date?: string;
    child?: BodyChildJurnalPenyesuaian[];
}

export interface DetailJurnalPenyesuaian {
    id?: number;
    doc_id?: null;
    doc_no?: string;
    doc_type?: number;
    is_fn_posted?: number;
    is_acc_posted?: number;
    is_active?: number;
    created_by?: null;
    created_at?: null;
    modified_by?: null;
    modified_at?: null;
    journal_no?: string;
    transaction_date?: string;
    type?: number;
    marketplace_id?: number;
    child?: BodyChildJurnalPenyesuaian[];
}

class JurnalPenyesuaianService extends BaseService {
    filter = "/report-accounting/filter-journal";

    account = "/report-accounting/get-all-coa";

    invoice = "/report-accounting/get-invoice";

    create = "/report-accounting/create-penyesuaian";

    createDraft = "/report-accounting/save-draft-penyesuaian";

    detail = "/report-accounting/detail-journal";

    Detail<T extends DetailJurnalPenyesuaian>(params: { id?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Filter<T extends JurnalPenyesuaian>(params: FilterJurnalPenyesuaian) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filter,
                config: { params },
            });
            return req;
        });
    }

    GetAccount<T extends AccountItem[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.account,
            });
            return req;
        });
    }

    GetInvoice<T extends InvoiceItem[]>(params: ParamsGetInvoice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.invoice,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: BodyCreateJurnalPenyesuaian) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    CreateDraft<T extends any>(data: BodyCreateJurnalPenyesuaian) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDraft,
                data,
            });
            return req;
        });
    }

    // Filter<T extends JurnalUmum>(param: FilterJurnalUmum) {
    //     return this.ProxyRequest<BaseJurnalResponse<T>>(async () => {
    //         const req = await ApiMethod.get<BaseJurnalResponse<T>>({
    //             url: this.filter,
    //             config: {
    //                 params: {
    //                     ...param,
    //                 },
    //             },
    //         });
    //         return req;
    //     });
    // }

    // UpdateStatusJournal<T extends any>(data: UpdateJournalData) {
    //     return this.ProxyRequest<T>(async () => {
    //         const req = await ApiMethod.put<T>({
    //             url: this.updateStatusJournal,
    //             data,
    //         });
    //         return req;
    //     });
    // }
}

const jurnalPenyesuaianService = new JurnalPenyesuaianService();
export default jurnalPenyesuaianService;

/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { Button, Card, Form, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import TableView from "modules/master-data/consignment/invoice/table-view";
import ExtInvoicePrint from "modules/master-data/external-order/ext-inv/print";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import extInvoiceService, { ProductInvoice } from "services/api-endpoints/dashboard/master-data/ext-invoice";
import { C_STATUS_ORDER } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ExtInvoiceView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<ProductInvoice[]>([]);
    const invoiceRef = useRef<HTMLDivElement | null>(null);

    const detailQuery = useQuery(
        [extInvoiceService.detail, id],
        async () => {
            return (await extInvoiceService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail);
            },
        }
    );

    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: detailQuery.data?.doc_no || "",
        pageStyle: "@page { auto; }",
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ExtInvoicePrint ref={invoiceRef} data={detailQuery.data} />
            <ToolbarAction
                title="detail generate invoice"
                rightAddition={() => (
                    <>
                        <Button onClick={handlePrintInvoice} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print Invoice
                        </Button>
                    </>
                )}
            />
            <Form className="flex flex-col gap-5" layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Partner Name <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            BP Name <br />
                                            <span className="underline m-0">{detailQuery.data?.bp_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Total Price <br />
                                            <span className="underline m-0">{(detailQuery.data?.total_price || 0).ToIndCurrency("Rp")}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Discount Price <br />
                                            <span className="underline m-0">{(detailQuery.data?.discount_price || 0).ToIndCurrency("Rp")}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Grand Total <br />
                                            <span className="underline m-0 font-bold">
                                                {(detailQuery.data?.grand_total || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Payment Status <br />
                                            <span className="underline m-0 font-bold">{detailQuery.data?.payment_status || "-"}</span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <TableView list={products} setList={setProducts} action={false} loading={detailQuery.isLoading} />
            </Form>
        </div>
    );
};

export default ExtInvoiceView;

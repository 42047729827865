import { Alert, Card, Skeleton } from "antd";
import { Chart, ChartOptions, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import State from "components/common/state";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService from "services/api-endpoints/dashboard-giias/report";
import Utils from "utils";
import { TYPE_POS_OFFLINE } from "utils/constant";

export const dataDefault = {
    labels: [],
    datasets: [],
};

export const options: ChartOptions<"bar"> = {
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: true,
            align: "end",
            labels: {
                title: {
                    font: {
                        weight: "lighter",
                    },
                },
                value: {
                    color: "black",
                    // backgroundColor: "black",
                },
            },
            formatter(value) {
                return `Rp.${Utils.convertToStringFormat(value)}`;
            },
        },
    },
};

const ChartSalesPerformance = () => {
    if (typeof window !== "undefined") {
        Chart.register(...registerables);
    }
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const [chartData, setChartData] = useState<any>(dataDefault);

    const watchParams = [startDate, endDate, typeSales];

    const chartSalesQuery = useQuery(
        [reportService.getSalesPerformance, ...watchParams],
        async () => {
            return (await reportService.GetSalesPerformance({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
        },
        {
            onSuccess(data) {
                setChartData((prev: any) => ({
                    ...prev,
                    labels: data.map((el) => el.sales_name),
                    datasets: [
                        {
                            label: "Sales Performance",
                            data: data.map((el) => el.total_price),
                            backgroundColor: [
                                "#04bfbf",
                                "#acf0f2",
                                "#0eeaff",
                                "#59d8e6",
                                "#59d8e6",
                                "#00abd8",
                                "#04668c",
                                "#0288d1",
                                "#7ecefd",
                                "#0067a6",
                            ],
                            borderWidth: 1,
                        },
                    ],
                }));
            },
        }
    );

    return (
        <Card className="h-full">
            <State data={chartSalesQuery.data} isLoading={chartSalesQuery.isLoading} isError={chartSalesQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <p className="m-4 text-center font-light text-xl text-gray-500 capitalize">sales performance</p>
                            <Bar plugins={[ChartDataLabels]} data={chartData} options={options} />
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 3 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(chartSalesQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </Card>
    );
};

export default ChartSalesPerformance;

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import AdvanceSearch from "modules/advance-search";
import { ProductWithQty } from "modules/advance-search/models";
import ModalCustomVoucher from "modules/dashboard-all/sales/add/modal-custom-voucher";
import ModalVoucher from "modules/dashboard-all/sales/add/modal-voucher";
import ProductTable from "modules/dashboard-all/sales/add/product-table";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { CreateSales, CustomVoucher, DetailProduct, FDataCreateSales, GetSummaryPrice, VocuherProduct } from "modules/dashboard-all/sales/models";
import { Key, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import orderSalesService, { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<FDataCreateSales>> = yup.object().shape({
    marketplace_id: yup.number().required("Marketplace required"),
    customer_name: yup.string().required("Customer name required"),
    customer_info: yup.string().required("Customer info required"),
    customer_address: yup.string().required("Customer address required"),
    shipping_id: yup.number().required("Courier required"),
    payment_channel_id: yup.number().required("Payment type required"),
});

const SalesAdd = () => {
    const navigate = useNavigate();

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [productsAll, setProductsAll] = useState<ProductWithQty[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);
    const [cvProdKeys, setcvProdKeys] = useState<Key[]>([]);

    const getMarketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const getShippingQuery = useQuery([orderSalesService.getShipping], async () => {
        return (await orderSalesService.GetShipping()).data.data?.map((el) => ({ label: el.shipping_name, value: el.shipping_id } as SelectOption));
    });

    const getPaymentQuery = useQuery([orderSalesService.getPayment], async () => {
        return (await orderSalesService.GetPayment()).data.data?.map(
            (el) => ({ label: el.payment_channel_name, value: el.payment_channel_id } as SelectOption)
        );
    });

    const getStockWH = useMutation(
        [orderSalesService.getStockWH],
        async (productID: any) => {
            return (await orderSalesService.GetStockWH({ product_id: productID })).data.data;
        },
        {
            onSuccess: (data) => {
                setProducts((prev) =>
                    prev.map((prd) => {
                        if (prd.product_id === data.product_id) {
                            return {
                                ...prd,
                                stock: data.qty_stock || 0,
                            };
                        }
                        return prd;
                    })
                );
            },
        }
    );

    const getSummaryPrice = useMutation(
        [orderSalesService.getSummaryPrice],
        async (data: GetSummaryPrice) => {
            return (await orderSalesService.GetSummaryPrice(data)).data.data;
        },
        {
            onSuccess: (data) => {
                setProductsAll(
                    products.map((prd) => {
                        const matchingPrd = data.list_discount?.find((el) => el.product_id === prd.product_id);
                        if (matchingPrd) {
                            return {
                                ...prd,
                                discount: matchingPrd.discount_price,
                            };
                        }
                        return prd;
                    })
                );
            },
        }
    );

    const createMutation = useMutation(
        [orderSalesService.createSales],
        async (data: CreateSales) => {
            return (await orderSalesService.CreateSales(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Order sales created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [orderSalesService.createDraftSales],
        async (data: CreateSales) => {
            return (await orderSalesService.CreateDraftSales(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Order sales draft created");
                navigate(-1);
            },
        }
    );

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FDataCreateSales>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("submit");
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        form.submit();
        setTypeSave("draft");
    };

    const productVoucher: any[] = productsAll
        .filter((prod) => cvProdKeys.find((key) => prod.product_id === key))
        .map((val) => ({ id: val.product_id, price: val.product_price, qty: val.qty } as VocuherProduct));

    const parseData: GetSummaryPrice = {
        detail_product: [
            ...(products?.map((p) => ({ price: p.product_price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)) ||
                []),
        ],
        voucher_id: [...(vouchers?.map((v) => v.voucher_id) || [])],
        voucher: [
            ...(customVouchers?.map(
                (cv) => ({ name: cv.name, price: cv.price || null, percentage: cv.percentage || null, product: productVoucher } as CustomVoucher)
            ) || []),
        ],
    };

    const onSubmitHandler = handleSubmit((data) => {
        setTimeout(() => {
            const createSales: CreateSales = {
                ...data,
                ...parseData,
            };
            if (typeSave === "submit") createMutation.mutate(createSales);
            else createDraftMutation.mutate(createSales);
        }, 300);
    });

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onVoucherChange = (voucher: Voucher) => {
        setVouchers((prev) => {
            if (prev.length === 2) return prev;
            if (prev.find((v) => v.voucher_id === voucher.voucher_id)) {
                return prev.filter((v) => v.voucher_id !== voucher.voucher_id);
            }
            return [...prev, voucher];
        });
    };

    const onRemoveVoucher = (voucher: Voucher) => {
        setVouchers((prev) => prev.filter((v) => v.voucher_id !== voucher.voucher_id));
    };

    const onRemoveCustomVoucher = (index: number) => {
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    const onGetWHProduct = (data: Product) => {
        getStockWH.mutate(data.product_id);
    };

    useEffect(() => {
        // if (!products.length) return;
        getSummaryPrice.mutate(parseData);
    }, [products, vouchers, customVouchers]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new sales"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDraftMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={products.length === 0 || getSummaryPrice.isError}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-4 gap-4">
                        <div className="">
                            <div className="flex">
                                <div className="w-full">
                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-3">
                                        sales order no <br />
                                        <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                    </p>
                                    <div className="">
                                        <p className="capitalize m-0 font-semibold text-gray-700">Store</p>
                                        <ControlledSelectInput
                                            showSearch
                                            name="marketplace_id"
                                            placeholder="Marketplace"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={getMarketplaceQuery.isLoading}
                                            options={getMarketplaceQuery.data || []}
                                        />
                                        <p className="capitalize m-0 font-semibold text-gray-700">Courier</p>
                                        <ControlledSelectInput
                                            showSearch
                                            name="shipping_id"
                                            placeholder="Courier"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={getShippingQuery.isLoading}
                                            options={getShippingQuery.data || []}
                                        />
                                        <p className="capitalize m-0 font-semibold text-gray-700">Payment Type</p>
                                        <ControlledSelectInput
                                            showSearch
                                            name="payment_channel_id"
                                            placeholder="Payment Type"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={getPaymentQuery.isLoading}
                                            options={getPaymentQuery.data || []}
                                        />
                                    </div>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                            {/* <p className="capitalize m-0 text-gray-700">
                                status <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">New</span>
                            </p> */}
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex">
                                <div className="flex flex-col gap-6 w-full">
                                    <div className="">
                                        <p className="capitalize m-0 font-semibold text-gray-700">Discount</p>
                                        <ModalVoucher vouchers={vouchers} onVoucherChange={onVoucherChange}>
                                            {(dt) => (
                                                <div className="w-full flex flex-col gap-1">
                                                    <Button
                                                        onClick={dt.openModal}
                                                        disabled={vouchers.length === 2 || !products.length}
                                                        className="!w-full !flex !items-center !justify-between"
                                                    >
                                                        Discount <MdAdd />
                                                    </Button>
                                                    <ListVoucher vouchers={vouchers} onRemoveVoucher={onRemoveVoucher} />
                                                </div>
                                            )}
                                        </ModalVoucher>
                                    </div>
                                    <ModalCustomVoucher
                                        onCreate={onCustomVoucherCreate}
                                        products={products}
                                        selectedRowKeys={cvProdKeys}
                                        setSelectedRowKeys={setcvProdKeys}
                                    >
                                        {(dt) => (
                                            <div className="w-full flex flex-col gap-1">
                                                <Button
                                                    onClick={dt.openModal}
                                                    disabled={customVouchers.length === 2 || !products.length}
                                                    className="!w-full !flex !items-center !justify-between"
                                                >
                                                    Custom Discount <MdAdd />
                                                </Button>
                                                <ListCustomVoucher vouchers={customVouchers} onRemoveVoucher={onRemoveCustomVoucher} />
                                            </div>
                                        )}
                                    </ModalCustomVoucher>
                                </div>
                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                            </div>
                            <p className="capitalize mt-1 text-gray-700">
                                total discount <br />
                                <span className="underline italic font-semibold m-0 text-red-500">
                                    {(getSummaryPrice.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                            <p className="capitalize mt-2 text-gray-700">
                                status <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">New</span>
                            </p>
                        </div>
                        <div className="flex flex-col w-full">
                            <p className="capitalize m-0 font-semibold text-gray-700">Customer</p>
                            <ControlledInputText control={control} labelCol={{ xs: 12 }} name="customer_name" placeholder="Customer Name" />
                            <ControlledInputText control={control} labelCol={{ xs: 12 }} name="customer_info" placeholder="Customer Info" />
                            <p className="capitalize m-0 text-gray-700">
                                total price <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">
                                    {(getSummaryPrice.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 w-full">
                            <div className="mt-5">
                                <ControlledInputTextArea
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="customer_address"
                                    placeholder="Customer Address"
                                    className="h-full"
                                    rows={3}
                                />
                            </div>
                            <p className="capitalize m-0 text-gray-700">
                                grand total <br />
                                <span className="underline italic font-semibold m-0 text-gray-700">
                                    {(getSummaryPrice.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                </span>
                            </p>
                        </div>
                    </div>
                </Form>
            </Card>
            {getSummaryPrice.isError ? <Alert type="error" message={(getSummaryPrice.error as any)?.message} /> : null}
            <AdvanceSearch selected={products} setSelected={setProducts} onGetWHProduct={onGetWHProduct}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={productsAll} setList={setProducts} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default SalesAdd;

import { Alert, Button, Skeleton, Spin, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import React from "react";
import { BiSave } from "react-icons/bi";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import hadirAppService, { CreateHolidayData, HolidayList } from "services/api-endpoints/hadir-app/hadir-app-service";

const HolidayEdit = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const id = searchParams.get("id");

    const [dateRange, setDateRange] = React.useState<any[]>([]);
    const [nasionalHolidays, setNasionalHolidays] = React.useState<any[]>([]);
    const [holidays, setHolidays] = React.useState<any[]>([]);

    const detailQuery = useQuery(
        [hadirAppService.detailHoliday, id],
        async () => {
            return (await hadirAppService.DetailHoliday({ id })).data.data;
        },
        {
            onSuccess(data) {
                setDateRange([new DateObject(data.start_date), new DateObject(data.end_date)]);
                setNasionalHolidays(data.libur_nasional.map((date) => new DateObject(date)));
                setHolidays(data.libur_wajib.map((date) => new DateObject(date)));
            },
        }
    );

    const editMutate = useMutation([hadirAppService.editHoliday], async (data: HolidayList) => {
        return (await hadirAppService.EditHoliday(data)).data.data;
    });

    const formatting = (date: any) => new DateObject(date).format("YYYY-MM-DD");

    const onSave = () => {
        if (dateRange.length < 2) {
            message.error("Rentang tanggal required!");
            return;
        }
        editMutate
            .mutateAsync({
                id: id as any,
                start_date: formatting(dateRange[0]),
                end_date: formatting(dateRange[1]),
                libur_nasional: nasionalHolidays.map((date) => formatting(date)),
                libur_wajib: holidays.map((date) => formatting(date)),
            })
            .then(() => {
                message.success("Hari libur editwd!");
                navigate(-1);
            });
    };

    return (
        <div className="w-full flex flex-col gap-6 relative">
            <ToolbarAction
                title="edit holiday"
                rightAddition={() => (
                    <Button loading={editMutate.isLoading || detailQuery.isLoading} onClick={onSave} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <div className="">
                                <p>Rentang Tanggal</p>
                                <Calendar
                                    value={dateRange}
                                    highlightToday={false}
                                    onChange={setDateRange as any}
                                    range
                                    numberOfMonths={2}
                                    showOtherDays
                                    rangeHover
                                />
                            </div>
                            <div style={{ opacity: dateRange.length < 2 ? "0.4" : "1" }} className="w-full flex gap-10">
                                <div className="">
                                    <p className="">Hari libur wajib</p>
                                    <Calendar
                                        highlightToday={false}
                                        minDate={dateRange[0]}
                                        maxDate={dateRange[1]}
                                        multiple
                                        value={holidays}
                                        onChange={setHolidays as any}
                                        numberOfMonths={2}
                                        disabled={dateRange.length < 2}
                                    />
                                </div>
                                <div className="">
                                    <p className="">Hari libur nasional</p>
                                    <Calendar
                                        minDate={dateRange[0]}
                                        maxDate={dateRange[1]}
                                        multiple
                                        value={nasionalHolidays}
                                        highlightToday={false}
                                        onChange={setNasionalHolidays as any}
                                        numberOfMonths={2}
                                        disabled={dateRange.length < 2}
                                    />
                                </div>
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(detailQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </div>
    );
};

export default HolidayEdit;

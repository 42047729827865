/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Skeleton, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ButtonDelete from "components/button/button-delete";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import { columnsCCAndPaymentMethod, columnsFilterDocPayment } from "modules/master-data/payment/add/filter-document";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalJournalDetail from "modules/master-data/purchasing/modal-journal-detail";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";
import ModalRefDoc from "modules/master-data/receipt/modal-ref-doc";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd } from "react-icons/bi";
import { RiFileEditFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService, { InvoiceDocPayment } from "services/api-endpoints/dashboard/payment";
import receiptService, { EditParams, ExpansesData } from "services/api-endpoints/dashboard/receipt";
import Utils from "utils";
import {
    FORMAT_DATE_1,
    ORDER_PAYMENT_BP_SALES,
    ORDER_PAYMENT_EMPLOYESS,
    ORDER_PAYMENT_OFFLINE,
    ORDER_PAYMENT_ONLINE,
    TYPE_ORDER_PAYMENT,
    TYPE_PAYMENT_BP_SALES,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<EditParams>> = yup.object().shape({
    id: yup.number(),
    transaction_date: yup.string().required("Transaction Date Required"),
    receipt_images: yup.array(),
});

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ReceiptView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const id = asPreview ? paramId : searchParams.get("id");

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");

    const [selectedDocument, setSelectedDocument] = React.useState<React.Key[]>([]);
    const [invoiceList, setInvoiceList] = React.useState<InvoiceDocPayment[]>([]);
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [urlImgList, setUrlImgList] = React.useState<string[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch } = useForm<EditParams>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [receiptService.detailReceipt, id],
        async () => {
            return (await receiptService.DetailReceipt({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    transaction_date: moment(data.transaction_date),
                    note: data.note,
                });
                setValue("transaction_date", moment(data.transaction_date));
                setSelectedDocument(data?.child?.map((itm) => Number(itm.doc_id)) || []);
                setInvoiceList(
                    data?.child?.map((itm) => ({
                        inv_id: itm.doc_id,
                        inv_no: itm.doc_no,
                        inv_ref: itm.inv_ref,
                        inv_ref_id: itm.inv_ref_id,
                        grand_total: itm.grand_total,
                        description: itm.description,
                        created_at: itm.date_invoice,
                        cc_charge: itm.cc_charge,
                        payment_channel_name: itm.payment_channel_name,
                    })) || []
                );
                setExpanses(data?.expenses);
                setUrlImgList((data.receipt_images || [])?.filter((url) => url));
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editMutate = useMutation(
        [receiptService.editReceipt],
        async (data: EditParams) => {
            return (await receiptService.EditReceipt(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Receipt Voucher Edited");
                navigate(-1);
            },
        }
    );

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const bankQuery = useQuery([receiptService.bankAccount], async () => {
        return (await receiptService.GetBankAccount()).data.data?.map(
            (el) => ({ label: el.bank_account_no, value: el.bank_account_id } as SelectOption)
        );
    });

    const bpQuery = useQuery([paymentService.getBP], async () => {
        return (await paymentService.GetBP()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const employeeQuery = useQuery([paymentService.getEmployee], async () => {
        return (await paymentService.GetEmployee()).data.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption));
    });

    const dataSourceFilter = invoiceList.filter((el) => selectedDocument.includes(el.inv_id));

    const columnsFilter: ColumnsType<InvoiceDocPayment> = [
        {
            title: "Doc No",
            dataIndex: "inv_no",
            render: (text, record) => {
                if (detailQuery.data?.order_type_id === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalJournalDetail title="Detail Jurnal" id={record.inv_id}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalJournalDetail>
                    );
                return (
                    <ModalRefVoucher title="Invoice" id={record.inv_id} type={detailQuery.data?.order_type_id}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalRefVoucher>
                );
            },
        },
        {
            title: "Inv Ref",
            dataIndex: "inv_ref",
            render: (text, record) => {
                if (detailQuery.data?.order_type_id === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalRefDoc title="Invoice" id={record.inv_ref_id} type={detailQuery.data?.order_type_id}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalRefDoc>
                    );
                return <span className="text-sm">{text}</span>;
            },
        },
        ...columnsFilterDocPayment,
        ...(detailQuery.data?.order_type_id === ORDER_PAYMENT_OFFLINE ? columnsCCAndPaymentMethod : []),
    ];

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const handlePreviewExistImage = (url: string) => {
        setPreviewImage(url);
        setPreviewOpen(true);
    };

    const onDeleteImageUrl = (image: any) => {
        return (e: any) => {
            e.stopPropagation();
            setUrlImgList((prev) => prev?.filter((url) => url !== image));
        };
    };

    const onEditHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: EditParams = {
            ...data,
            id: id!,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            receipt_images: [...(filesBase64 || []), ...urlImgList],
        };

        editMutate.mutate(transformData);
    });

    const onSaveEditHandler = async () => {
        await form.validateFields();
        onEditHandler();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail receipt voucher"
                rightAddition={() => (
                    <>
                        {!asPreview && (
                            <Button loading={editMutate.isLoading} onClick={onSaveEditHandler} type="default" className="!flex !items-center">
                                <RiFileEditFill className="m-0 mr-2" />
                                Edit
                            </Button>
                        )}
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                                <Card className="">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <p className="capitalize m-0 mb-8">
                                            Document No <br />
                                            <span className="underline italic m-0">{detailQuery.data?.doc_no}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Cabang Account <br />
                                            <span className="underline italic m-0">
                                                {detailQuery.data?.account_name || "-"}
                                            </span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Bank Account <br />
                                            <span className="underline italic m-0">
                                                {bankQuery.data?.find((item) => item.value === detailQuery.data?.bank_account_id)?.label}
                                            </span>
                                        </p>
                                        <ControlledInputDate
                                            control={control}
                                            name="transaction_date"
                                            placeholder="Transaction Date"
                                            label="Transaction Date"
                                        />
                                        <>
                                            {detailQuery.data?.order_type_id === ORDER_PAYMENT_BP_SALES ? (
                                                <p className="capitalize m-0">
                                                    Payment BP Sales <br />
                                                    <span className="underline italic m-0">
                                                        {TYPE_PAYMENT_BP_SALES.find((val) => val.value === detailQuery.data?.payment_id)?.label}
                                                    </span>
                                                </p>
                                            ) : null}
                                        </>
                                        <p className="capitalize m-0">
                                            Note Voucher <br />
                                            <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                        </p>
                                    </div>
                                </Card>
                                <Card className="">
                                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Sales Type <br />
                                            <span className="underline italic m-0">
                                                {TYPE_ORDER_PAYMENT.find((item) => item.value === detailQuery.data?.order_type_id)?.label}
                                            </span>
                                        </p>
                                        <>
                                            {detailQuery.data?.order_type_id === ORDER_PAYMENT_ONLINE ? (
                                                <p className="capitalize m-0">
                                                    Market Place <br />
                                                    <span className="underline italic m-0">
                                                        {marketplaceQuery.data?.find((item) => item.value === detailQuery.data?.partner_id)?.label}
                                                    </span>
                                                </p>
                                            ) : (
                                                <>
                                                    {detailQuery.data?.order_type_id === ORDER_PAYMENT_BP_SALES ? (
                                                        <p className="capitalize m-0">
                                                            Partner Name <br />
                                                            <span className="underline italic m-0">
                                                                {bpQuery.data?.find((item) => item.value === detailQuery.data?.partner_id)?.label}
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <>
                                                            {detailQuery.data?.order_type_id === ORDER_PAYMENT_EMPLOYESS ? (
                                                                <p className="capitalize m-0">
                                                                    Employees Name <br />
                                                                    <span className="underline italic m-0">
                                                                        {
                                                                            employeeQuery.data?.find(
                                                                                (item) => item.value === detailQuery.data?.partner_id
                                                                            )?.label
                                                                        }
                                                                    </span>
                                                                </p>
                                                            ) : (
                                                                <div />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                        <p className="capitalize m-0">
                                            Invoice Total
                                            <br />
                                            <span className="font-semibold m-0">
                                                {detailQuery.data?.receipt_amount === null
                                                    ? "Rp. -"
                                                    : detailQuery.data?.receipt_amount.ToIndCurrency("Rp")}
                                            </span>
                                            <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                                        </p>
                                        <div className="col-span-2 flex flex-col gap-5 pr-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                                            <Table
                                                scroll={{ x: 800 }}
                                                rowKey={(rec) => rec.inv_id}
                                                columns={columnsFilter}
                                                dataSource={dataSourceFilter}
                                                pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                                style={{ borderBottom: "1px solid #d9d9d9" }}
                                                className="pb-2"
                                            />
                                            {!asPreview && (
                                                <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                                    <div>
                                                        <BiImageAdd className="text-2xl" />
                                                        <div style={{ marginTop: 8 }}>Upload</div>
                                                    </div>
                                                </Upload>
                                            )}

                                            <div className="grid grid-cols-3 gap-4">
                                                {urlImgList?.map((url) => (
                                                    <button
                                                        onClick={() => handlePreviewExistImage(url)}
                                                        type="button"
                                                        className="relative border-none bg-transparent"
                                                    >
                                                        {!asPreview && <ButtonDelete onClick={onDeleteImageUrl(url)} />}
                                                        <ImageCard image={url} title="document" />
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <>
                                                {expanses?.length !== 0 ? (
                                                    <div>
                                                        <p className="capitalize m-0">Expenses</p>
                                                        <ListExpenses data={expanses} />
                                                        <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </>
                                            <p className="capitalize m-0">
                                                Receipt Total
                                                <br />
                                                <span className="font-semibold m-0">
                                                    {detailQuery.data?.total_receipt_amount?.ToIndCurrency("Rp") || "Rp. -"}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Form>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default ReceiptView;

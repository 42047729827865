/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataPrice } from "modules/master-data/price/models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface AddPrice {
    product_id: number;
    warehouse_id: number;
    price: number;
}
export interface EditPrice {
    id: number;
    warehouse_id: number;
    price: number;
}

export interface SuggestProduct {
    id: number;
    product_name: string;
    product_code: string;
}

export interface DetailProduct {
    id: number;
    product_desc: string;
    molding_code: string;
    status: string;
    brand_name: string;
    brand_code: string;
    model_name: string;
    model_code: string;
    model_year: string;
    category_name: string;
    category_type: string;
    subcategory_name: string;
    subcategory_code: string;
    style_name: string;
    style_code: string;
    attribute_name: string;
    attribute_code: string;
    subattribute_name: string;
    subattribute_code: string;
    images: string[];
}

export interface DetailProductEdit {
    id: number;
    product_id: number;
    product_desc: string;
    molding_code: string;
    status: string;
    brand_name: string;
    brand_code: string;
    model_name: string;
    model_code: string;
    model_year: string;
    category_name: string;
    category_type: string;
    subcategory_name: string;
    subcategory_code: string;
    style_name: string;
    style_code: string;
    attribute_name: string;
    attribute_code: string;
    subattribute_name: string;
    subattribute_code: string;
    images: string;
    product_name: string;
    product_code: string;
    price: number;
    warehouse_id: number;
}

class PriceService extends BaseService {
    getAll = "/product-price/all-price";

    search = "/product-price/search-price";

    filter = "/product-price/filter-price";

    delete = "/product-price/delete-price";

    warehouse = "/get-warehouse";

    listAccount = "/product-price/list-account";

    addPrice = "/product-price/add-price";

    suggestProduct = "/product-price/suggest-product";

    detailProduct = "/product-price/detail-product-info";

    detailProductEdit = "/product-price/detail-product-edit";

    editPrice = "/product-price/edit-price";

    constructor() {
        super();
    }

    EditPrice<T extends any>(data: EditPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editPrice,
                data,
            });
            return req;
        });
    }

    DetailProductEdit<T extends DetailProductEdit>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProductEdit,
                config: { params },
            });
            return req;
        });
    }

    DetailProduct<T extends DetailProduct>(params: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProduct,
                config: { params },
            });
            return req;
        });
    }

    SuggestProduct<T extends SuggestProduct[]>(params: { product_name_code?: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestProduct,
                config: { params },
            });
            return req;
        });
    }

    AddPrice<T extends any>(data: AddPrice) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.addPrice,
                data,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataPrice>(param: { page: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataPrice>(param: { page: any; param_search: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataPrice>(param: FilterDataPrice) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListAccount<T extends Models.AllWarehouse[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listAccount,
            });
            return req;
        });
    }

    Warehouse<T extends Models.AllWarehouse[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.warehouse,
            });
            return req;
        });
    }
}

const priceService = new PriceService();
export default priceService;

import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { EDIT_PATH, FORMAT_DATE_2, FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessView from "components/button/button-access-view";
import moment from "moment";
import { BaseReceiptResponse, TransferBankList } from "services/api-endpoints/dashboard/transfer-bank";

type Props<T> = {
    fetcher: UseQueryResult<BaseReceiptResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const TransferBankTable = <T extends TransferBankList>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: TransferBankList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: TransferBankList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Bank Account From",
            dataIndex: "bank_from",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Bank Account To",
            dataIndex: "bank_to",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Transaction Date",
            dataIndex: "transaction_date",
            render: (text) => <p className="capitalize m-0">{moment(text)?.format(FORMAT_DATE_2)}</p>,
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            render: (text) => <p className="capitalize m-0">{(text || 0).ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{moment(text)?.format(FORMAT_DATE_TIME)}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.is_active ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : (
                        <>
                            <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                            <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 20,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TransferBankTable;

/* eslint-disable no-plusplus */
import React, { useRef } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import salesOrderService, { ListPO, ProductPO } from "services/api-endpoints/dashboard-jsl/sales-order";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";

type Props<T> = {
    warehouse: number | null;
    selectedPO: React.Key[];
    setSelectedPO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductPO: (data: ProductPO[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
};

const ModalPOTable = <T extends ListPO>({ children, selectedPO, setSelectedPO, warehouse, onProductPO }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const listPOQuery = useQuery(
        [salesOrderService.listPO, warehouse],
        async () => {
            return (await salesOrderService.ListPO<T[]>({ bp_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    const detailProductPOMutation = useMutation([salesOrderService.detailProductPO], async (ids: string) => {
        return (await salesOrderService.DetailProductPO({ po_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedPO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedPO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "PO No",
            dataIndex: "po_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Valid Until",
            dataIndex: "date",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    const onOkHandler = () => {
        detailProductPOMutation
            .mutateAsync(selectedPO.join(","))
            .then((res) => {
                onProductPO(res);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title="List Purchase Order"
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: selectedPO.length === 0, loading: detailProductPOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <Table
                        rowKey={(i) => i.po_id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalPOTable;

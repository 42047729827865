/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface PaymentTerm {
    payment_term_id: number;
    payment_term_code: string;
}

export interface Agent {
    business_partner_id: number;
    business_partner_name: string;
}

export interface BusinessPartner {
    id: number;
    name: string;
    partner_code: string;
    partner_type: number;
    business_partner_id: number | null;
    business_partner_name: null | string;
    credit_status: string;
    credit_limit: number;
    open_balance: number;
    tax_id: string;
    payment_term_id: number;
    payment_term_name: string;
}

export interface CreateBP {
    name: string;
    partner_code: string;
    partner_type: number;
    business_partner_id: number | null;
    credit_limit: number;
    tax_id: string;
    payment_term_id: number;
}

export interface EditBP extends CreateBP {
    id: any;
}

export interface DetailBP {
    id: number;
    name: string;
    partner_code: string;
    partner_type: number;
    business_partner_id: null;
    business_partner_name: null;
    credit_status: null;
    credit_limit: number;
    open_balance: null;
    tax_id: string;
    payment_term_id: number;
    payment_term_name: string;
}

class MasterBpService extends BaseService {
    getPaymentTerm = "/accounting/get-payment-term";

    getAgent = "/accounting/get-agent";

    getAll = "/accounting/business-partner/get-all";

    create = "/accounting/business-partner/create";

    detail = "/accounting/business-partner/detail";

    edit = "/accounting/business-partner/edit";

    search = "/accounting/business-partner/search";

    Search<T extends BusinessPartner>(params: { query: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditBP) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailBP>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${data.id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateBP) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetAll<T extends BusinessPartner>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    GetPaymentTerm<T extends PaymentTerm[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPaymentTerm,
            });
            return req;
        });
    }

    GetAgent<T extends Agent[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAgent,
            });
            return req;
        });
    }
}

const masterBpService = new MasterBpService();
export default masterBpService;

/* eslint-disable no-plusplus */
import React, { useRef, useState } from "react";
import { Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import extGoodsReceiptService, { DetailProduct, ListData } from "services/api-endpoints/dashboard/master-data/ext-goods-receipt";

type Props<T> = {
    partner: number | null;
    selectedPO: React.Key[];
    setSelectedPO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductPO: (data: DetailProduct[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
    setSoChild: React.Dispatch<React.SetStateAction<number[]>>;
};

const ModalGoodsReceiptAdd = <T extends ListData>({ children, selectedPO, setSelectedPO, partner, onProductPO, setSoChild }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const listQuery = useQuery(
        [extGoodsReceiptService.listData, partner],
        async () => {
            return (await extGoodsReceiptService.ListData<T[]>({ partner_id: partner })).data.data;
        },
        {
            enabled: !!partner,
        }
    );

    const detailProductMutation = useMutation([extGoodsReceiptService.detailProduct], async (ids: string) => {
        return (await extGoodsReceiptService.DetailProduct({ order_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // const termsId = listQuery.data?.filter((po) => newSelectedRowKeys.includes(po.do_id as any))?.map((po) => po.payment_term_id);
        // if ([...new Set(termsId)].length !== 1) {
        //     setCanSubmit(false);
        // } else {
        //     setCanSubmit(true);
        //     setTopId(termsId !== undefined ? termsId[0]! : null);
        // }
        const soChild = listQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.so_child);
        setSoChild([...new Set(soChild?.flat())]);
        setSelectedPO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedPO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        // {
        //     title: "Term Of Payment",
        //     dataIndex: "payment_term_code",
        //     render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        // },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    const onOkHandler = () => {
        detailProductMutation
            .mutateAsync(selectedPO.join(","))
            .then((res) => {
                onProductPO(res.map((el) => ({ ...el, qty_receipt: el.qty })));
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title="List Ext Delivery Order"
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !selectedPO.length, loading: detailProductMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    {/* <p className="capitalize m-0 mb-2 text-xs text-red-400">Selected delivery order with same TOP (Term Of Payment)</p> */}
                    <Table
                        rowKey={(i) => i.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listQuery.data || []}
                        loading={listQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalGoodsReceiptAdd;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button, TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import BPLocatorModal from "modules/dashboard-jsl/bp-picking-list/modal-bp-locator";
import React, { useState } from "react";
import { DetailProduct, LocatorData } from "services/api-endpoints/dashboard-jsl/picking-list";
import { ProductCSO } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import LocatorModal from "../modal/locator-modal";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    onSubmitLocator: (docId: number, locator: LocatorData) => void;
};

export function tableEdit<T extends ProductCSO>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ onSubmitLocator, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            // {
            //     title: "C SO No",
            //     dataIndex: "so_no",
            //     render: (text, record) => <p className="capitalize m-0">{text || "FREE"}</p>,
            // },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => (
                    <div className="flex flex-row">
                        <p className="capitalize m-0 mr-2">{text || "-"}</p>
                        {record.note && (
                            <Tag color="blue" className="!h-fit">
                                {record.note}
                            </Tag>
                        )}
                    </div>
                ),
            },
            {
                title: "Product Code 1",
                dataIndex: "product_code1",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            // {
            //     title: "Price",
            //     dataIndex: "product_price",
            //     render: (text) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
            // },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => (
                    <LocatorModal title="Locator" docId={record.id!} productId={record.product_id} onSubmit={onSubmitLocator}>
                        {(dt) => (
                            <Button
                                size="small"
                                onClick={() => dt.openModalWithData(record)}
                                className="!flex !items-center"
                                type="default"
                                {...(!text ? { danger: true } : {})}
                            >
                                {text || "Choose Locator"}
                            </Button>
                        )}
                    </LocatorModal>
                ),
            },
            {
                title: "Quantity Request",
                dataIndex: "qty",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Locator",
                dataIndex: "qty_locator",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Approved",
                dataIndex: "qty_approve",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className={text === null || text === 0 ? "m-0 text-center text-red-500" : "m-0 text-center "}>{text || "-"}</p>,
            },
        ];

        const isEditing = (record: DetailProduct, edited: DetailProduct | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: DetailProduct, edited: DetailProduct) => record.product_id === edited?.product_id;
        const rowKey = (record: DetailProduct) => record.product_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty_approve: "number" };

        const reqQty = editRow?.qty || 1;
        const locQty = editRow?.qty_locator || undefined;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: [reqQty, locQty].filter((qty) => qty).sort((a, b) => a! - b!)[0] || 1,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
                onChange={handleTableChange}
            />
        );
    };
}

const TableAdd = tableEdit(EditTable);
export default TableAdd;

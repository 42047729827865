/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface TaskData {
    id?: number;
    employee_name?: string;
    dealer_brand_name?: string;
    off_package_name?: string;
    pic_name?: string;
    pic_title?: string;
    created_at?: string;
}

export interface TaskDetailData {
    id?: number;
    employee_name?: string;
    dealer_brand_id?: number;
    dealer_brand_name?: string;
    off_package_id?: number;
    off_package_name?: string;
    pic_name?: string;
    pic_title?: string;
    description?: string;
    images?: string[];
    lat?: string;
    lon?: string;
    address?: string;
    created_at?: string;
}

class TaskService extends BaseService {
    getAll = "dashboard-tf/get-all-task";

    taskDetail = "dashboard-tf/get-detail-task";

    constructor() {
        super();
    }

    TaskDetail<T extends TaskDetailData>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.taskDetail,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends TaskData>(param: any) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const taskService = new TaskService();
export default taskService;

import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Form, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { PRODUCT_TYPE } from "utils/constant";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { FCondition } from "../models";
import { VoucherContext } from "../context/voucher";

const schema: yup.SchemaOf<Partial<FCondition>> = yup.object().shape({
    product_type: yup.number().required("Product type required"),
    max_coupon: yup.string().required("Max coupon required"),
});

const Condition = () => {
    const { nextStep, prevStep, setState, state } = useContext(VoucherContext);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FCondition>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            product_type: state?.createData?.product_type,
            max_coupon: state?.createData?.max_coupon,
        },
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: FCondition = {
            ...data,
            max_coupon: data.max_coupon?.ToIntegerFromIndCurrency()?.toString(),
        };
        if (setState) {
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    ...parseData,
                },
            }));
        }
        if (nextStep) nextStep();
    });

    const onNextHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onPrevHandler = () => {
        if (prevStep) prevStep();
    };

    return (
        <>
            <Form form={form} layout="horizontal" onFinish={onSubmitHandler}>
                <div className="grid grid-cols-2 gap-x-4">
                    <ControlledSelectInput allowClear options={PRODUCT_TYPE} control={control} name="product_type" placeholder="Product type" />
                    <ControlledInputText allowClear type="number" control={control} name="max_coupon" placeholder="Max Coupon" />
                </div>
            </Form>
            <Space>
                <Button onClick={onPrevHandler}>Previous</Button>
                <Button type="primary" onClick={onNextHandler} className="!w-fit">
                    Next
                </Button>
            </Space>
        </>
    );
};

export default Condition;

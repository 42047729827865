import { List, Popover } from "antd";
import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CustomVoucher } from "../models";

type Props = {
    vouchers: CustomVoucher[];
    onRemoveVoucher?: (index: number) => void;
    showTextEmpty?: boolean;
    bordered?: boolean;
};

const ListCustomVoucher = ({ vouchers, onRemoveVoucher, showTextEmpty, bordered }: Props) => {
    if (!vouchers.length) return showTextEmpty ? <p className="m-0 text-gray-400 capitalize text-xs italic">no custom discount</p> : null;
    return (
        <List
            size="small"
            dataSource={vouchers}
            bordered={bordered}
            renderItem={(item, i) => {
                const content = (
                    <p className="m-0 capitalize">
                        {item.name} <br />
                        <span className="font-semibold">{item.percentage ? `${item.percentage}%` : item.price?.ToIndCurrency("Rp")}</span>
                    </p>
                );
                return (
                    <List.Item>
                        <Popover content={content} title="Discount">
                            <div className="!w-full flex items-center justify-between gap-4">
                                <p className="m-0 text-xs text-gray-600 capitalize line-clamp-1 w-[90%]">{item?.name}</p>
                                {onRemoveVoucher ? (
                                    <MdOutlineDeleteOutline
                                        className="text-lg !h-5 !w-5 cursor-pointer text-red-500"
                                        onClick={() => onRemoveVoucher(i)}
                                    />
                                ) : null}
                            </div>
                        </Popover>
                    </List.Item>
                );
            }}
        />
    );
};

export default ListCustomVoucher;

import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FORMAT_DATE_1, STATUS_ORDER } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterAutoloka = ({ filter = [], ...props }: Props) => {
    const [orderType, setOrderType] = useState<any>();
    const [searchParams] = useSearchParams();
    const orderTypeParam = searchParams.get("order_type");

    useEffect(() => {
        if (orderTypeParam === null || orderTypeParam === undefined) return;
        setOrderType(Number(orderTypeParam));
    }, [orderTypeParam]);

    return (
        <FilterModal
            filter={filter}
            title="Filter Autoloka"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.status ? Number(dt.values.status) : null}
                            placeholder="Status Order"
                            options={STATUS_ORDER}
                            onChange={(value) => dt.setOneValue("status", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterAutoloka;

import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import React from "react";
import { SearchProduct } from "../models";

export function withParentName<T extends ControlledInputSelectProps<SearchProduct>>(Component: React.ComponentType<T>) {
    return (props: T) => {
        return <Component {...props} optionFilterProp="children" />;
    };
}

const WarehouseSelect = withParentName(ControlledSelectInput);

export default WarehouseSelect;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterUser from "modules/user-magement/user/filter";
import UserTable from "modules/user-magement/user/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import userManagementService from "services/api-endpoints/dashboard-all/user-management/user";

const filter = ["name", "email", "status"];

const User = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([userManagementService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await userManagementService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await userManagementService.Filter(filterValue as any)).data.data;
        return (await userManagementService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterUser filter={filter}>
                {(dt) => <Toolbar add={false} placeholder="Name / Email" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterUser>
            <UserTable fetcher={getList} />
        </div>
    );
};

export default User;

/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import RoleMenuTable from "modules/master-data/role-menu/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import roleMenuService from "services/api-endpoints/dashboard/master-data/role-menu";

const RoleMenu = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([roleMenuService.listRoleMenu, page, searchValue], async () => {
        return (await roleMenuService.ListRoleMenu({ query: searchValue.query })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} />
            <RoleMenuTable fetcher={getList} />
        </div>
    );
};

export default RoleMenu;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, message } from "antd";
import State from "components/common/state";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import { CustomVoucher } from "modules/dashboard-all/sales/models";
import PrintVendor from "modules/master-data/purchasing/print-vendor";
import ListExpenses from "modules/purchasing/inventory/add/list-expenses";
import ModalAddExpenses from "modules/purchasing/inventory/add/modal-add-expenses";
import ModalCustomVoucher from "modules/purchasing/inventory/add/modal-custom-voucher";
import PaymentMethodSelect from "modules/purchasing/inventory/lib/payment-method-select";
import ShippingSelect from "modules/purchasing/inventory/lib/shipping-select";
import { transformChild, transformToChild } from "modules/purchasing/inventory/lib/utils";
import TenderPurchasing from "modules/purchasing/inventory/view/tender";
import moment from "moment";
import { createContext, Key, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCar } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { MdAdd, MdRefresh } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import purchasingInventoryService, {
    BodyCreatePurchasingInventory,
    GetSummary,
    InventoryItem,
    POPrice,
} from "services/api-endpoints/purchasing/inventory";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";
import Utils from "utils";
import { AUTOGENERATED, DOC_TYPE_INVENTORY, FORMAT_DATE_1, TYPE_TAX, TYPE_TAX_INCLUDED, TYPE_TAX_TAXABLE } from "utils/constant";
import * as yup from "yup";

export type CreatePurchasingInventory = Omit<
    BodyCreatePurchasingInventory,
    "id" | "voucher" | "child" | "total_tax" | "total_disc" | "total_price" | "grand_total" | "doc_type"
>;

export interface DocReqChild extends ChildRequest {
    vendors?: {
        [columnIndex: number]: {
            vendor_id: any;
            price: any;
        };
    };
}

const schema: yup.SchemaOf<Partial<Omit<CreatePurchasingInventory, "vendor_id">>> = yup.object().shape({
    payment_term_id: yup.number().required("Payment required"),
    shipping_id: yup.number().required("Shipping required"),
    tax_type: yup.number().required("Tax Type required"),
    delivery_date: yup.string().required("Delivery date required"),
    delivery_address: yup.string().required("Delivery address required"),
    transaction_date: yup.string().required("Transaction date required"),
    tax_id: yup.number(),
    doc_id: yup.number(),
    pr_id: yup.array(),
    expenses: yup.array(),
    note: yup.string(),
});

export const PurchasingContext = createContext<any>({});

const PurchasingInventoryEditContext = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const navigate = useNavigate();
    const { setPickedVendor, setGetVendor, setDocReqChild, pickedVendor, docReqChild, setProblemChild, isRefresh } = useContext(PurchasingContext);

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);
    const [expanses, setExpanses] = useState<ExpansesData[]>([]);
    const letterVendorRef = useRef<HTMLDivElement | null>(null);
    const [selectedPR, setSelectedPR] = useState<Key[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<CreatePurchasingInventory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchTaxType = watch("tax_type");
    const watchTaxId = watch("tax_id");

    const createMutation = useMutation(
        [purchasingInventoryService.save],
        async (data: Partial<BodyCreatePurchasingInventory>) => {
            return (await purchasingInventoryService.Save(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing Order created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [purchasingInventoryService.draft],
        async (data: Partial<BodyCreatePurchasingInventory>) => {
            return (await purchasingInventoryService.Draft(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing Order Draft Created");
                navigate(-1);
            },
        }
    );

    const getTax = useQuery([purchasingInventoryService.getTax], async () => {
        return (await purchasingInventoryService.GetTax()).data.data;
    });

    const getTaxOption = getTax.data?.map((el) => ({ label: el.tax_name, value: el.tax_id } as SelectOption));

    const getSummaryPrice = useMutation([purchasingInventoryService.getSummary], async (data: GetSummary) => {
        return (await purchasingInventoryService.GetSummary(data)).data.data;
    });

    const onSaveHandler = async () => {
        setTypeSave("submit");
        await form.validateFields();
        form.submit();
    };

    const onDraftHandler = async () => {
        setTypeSave("draft");
        await form.validateFields();
        form.submit();
    };

    const handlePrintVendorPrice = useReactToPrint({
        content: () => letterVendorRef.current,
        documentTitle: `Purchasing Vendor Price - ${id}`,
        pageStyle: "@page {  }",
    });

    const parseData: Partial<BodyCreatePurchasingInventory> = {
        vendor_id: pickedVendor,
        child: transformChild(docReqChild).allChild,
        doc_type: DOC_TYPE_INVENTORY,
        total_tax: getSummaryPrice.data?.total_pajak,
        total_disc: getSummaryPrice.data?.total_discount,
        total_price: getSummaryPrice.data?.total_price,
        grand_total: getSummaryPrice.data?.grand_total,
    };

    const onSubmitHandler = handleSubmit((data) => {
        const { problemChild } = transformChild(docReqChild);

        if (!parseData.child?.length) {
            message.error("No record child found!");
            return;
        }

        if (!parseData.vendor_id) {
            message.error("Pick vendor first!");
            return;
        }

        if ((watchTaxType === TYPE_TAX_TAXABLE || watchTaxType === TYPE_TAX_INCLUDED) && !watchTaxId) {
            message.error("Please Choose Tax!");
            return;
        }

        setProblemChild(problemChild);
        if (problemChild.length) return;

        const parseData2: Partial<BodyCreatePurchasingInventory> = {
            ...data,
            ...parseData,
            id,
            pr_id: selectedPR,
            delivery_date: moment(data.delivery_date).format(FORMAT_DATE_1),
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            voucher: customVouchers,
            expenses: expanses.map((val) => ({ ...val, id: val.charge_id, name: val.charge_name })),
        };
        if (typeSave === "submit") createMutation.mutate(parseData2);
        else createDraftMutation.mutate(parseData2);
    });

    const onRemoveCustomVoucher = (index: number) => {
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    const childFilter = parseData.child?.filter((val) => val.vendor_id === parseData.vendor_id && val.price !== null);

    const onSummaryPrice = () => {
        const summaryData: GetSummary = {
            child: childFilter || [],
            voucher: customVouchers,
            tax_type: watchTaxType || 2,
            tax_value: getTax.data?.find((val) => val.tax_id === watchTaxId)?.tax_value || 0,
            total_expenses: expanses.map((ex) => ex.amount || 0),
        };
        getSummaryPrice.mutate(summaryData);
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (!childFilter?.length) return;
        onSummaryPrice();
    }, [customVouchers, watchTaxId, watchTaxType, pickedVendor, isRefresh, expanses]);

    const detailQuery = useQuery(
        [purchasingInventoryService.getDetail, id],
        async () => {
            return (await purchasingInventoryService.GetDetail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                form.setFieldsValue({
                    delivery_date: moment(data.header?.delivery_date),
                    delivery_address: data.header?.delivery_address,
                    transaction_date: moment(data.header?.transaction_date),
                    note: data.header?.note,
                    doc_id: data.header?.doc_id,
                });
                const prID = data.header?.references?.map((val) => val.pr_id!) || [];
                setSelectedPR(prID);
                setValue("doc_id", data.header?.doc_id);
                setValue("tax_type", data.header?.tax_type);
                setValue("tax_id", data.header?.tax_id);
                setValue("payment_term_id", data.header?.payment_term_id);
                setValue("note", data.header?.note);
                setCustomVouchers(data.header?.voucher || []);
                setValue("shipping_id", data.header?.shipping_id);
                setValue("delivery_date", moment(data.header?.delivery_date));
                setValue("delivery_address", data.header?.delivery_address);
                setValue("transaction_date", moment(data.header?.transaction_date));
                setPickedVendor(data.header?.selected_vendor_id);

                const temp = (data?.list_product_price || []).map((val) => ({ ...val, qty_max: val.qty }));
                setDocReqChild(transformToChild(temp));
                setExpanses(data?.header?.expenses?.map((val) => ({ ...val, charge_id: val.id, charge_name: val.name } as ExpansesData)) || []);
                onSummaryPrice();
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintVendor ref={letterVendorRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="edit purchasing order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDraftMutation.isLoading}
                            disabled={!childFilter?.length}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={!childFilter?.length}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-center justify-between mb-5">
                                    <h1 className="m-0">Inventory Information</h1>
                                    <ModalAddExpenses onCreate={onAddExpenses}>
                                        {(dt) => (
                                            <Button onClick={dt.openModal} type="primary" className="!self-start">
                                                Add Expenses
                                            </Button>
                                        )}
                                    </ModalAddExpenses>
                                </div>
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-10 gap-6">
                                        <div className="col-span-2 gap-2">
                                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                                document no <br />
                                                <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                                            </p>
                                            <ControlledSelectInput
                                                control={control}
                                                name="tax_type"
                                                placeholder="Tax Type"
                                                label="Tax Type"
                                                options={TYPE_TAX}
                                            />
                                        </div>

                                        <div className="col-span-2 gap-6">
                                            <PaymentMethodSelect
                                                classNameForm="col-span-2"
                                                showSearch
                                                allowClear
                                                control={control}
                                                name="payment_term_id"
                                                label="Payment Method"
                                                placeholder="Payment Method"
                                            />
                                            <ControlledSelectInput
                                                control={control}
                                                name="tax_id"
                                                placeholder="Tax"
                                                label="Tax"
                                                options={getTaxOption}
                                                disabled={watchTaxType !== TYPE_TAX_TAXABLE && watchTaxType !== TYPE_TAX_INCLUDED}
                                            />
                                        </div>

                                        <div className="col-span-2 gap-6">
                                            <p className="capitalize m-0 text-gray-700 mb-2">Custom Discount</p>
                                            <ModalCustomVoucher onCreate={onCustomVoucherCreate}>
                                                {(dt) => (
                                                    <div className="w-full flex flex-col gap-1">
                                                        <Button
                                                            onClick={dt.openModal}
                                                            disabled={customVouchers.length === 2}
                                                            className="!w-full !flex !items-center !justify-between"
                                                        >
                                                            Custom Discount <MdAdd />
                                                        </Button>
                                                        <ListCustomVoucher vouchers={customVouchers} onRemoveVoucher={onRemoveCustomVoucher} />
                                                    </div>
                                                )}
                                            </ModalCustomVoucher>
                                            <br />
                                            <ShippingSelect
                                                showSearch
                                                allowClear
                                                classNameForm="col-span-2"
                                                control={control}
                                                name="shipping_id"
                                                label="Courier"
                                                placeholder="Courier"
                                            />
                                        </div>
                                        <div className="col-span-2 m-0">
                                            <ControlledInputDate
                                                control={control}
                                                placeholder="Transaction Date"
                                                label="Transaction Date"
                                                name="transaction_date"
                                            />
                                            <ControlledInputTextArea control={control} placeholder="Note" label="Note" name="note" rows={1} />
                                            {expanses.length !== 0 && (
                                                <>
                                                    <p className="capitalize m-0 text-gray-700">Expenses</p>
                                                    <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                                </>
                                            )}
                                        </div>
                                        <div className="col-span-2 gap-6">
                                            <ControlledInputDate
                                                control={control}
                                                placeholder="Delivery Date"
                                                label="Delivery Date"
                                                name="delivery_date"
                                            />
                                            <ControlledInputTextArea
                                                control={control}
                                                placeholder="Delivery Address"
                                                label="Delivery Address"
                                                name="delivery_address"
                                                rows={3}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-5 gap-6">
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total price <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(getSummaryPrice.data?.total_price || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total tax <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(getSummaryPrice.data?.total_pajak || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total discount <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(getSummaryPrice.data?.total_discount || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1  capitalize m-0 text-gray-700">
                                            total expenses <br />
                                            <span className="font-semibold m-0 text-gray-700">
                                                {(getSummaryPrice.data?.total_expenses || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                        <p className="col-span-1 capitalize m-0 text-gray-700">
                                            grand total <br />
                                            <span className="font-bold m-0 text-gray-700">
                                                {(getSummaryPrice.data?.grand_total || 0).ToIndCurrency("Rp")}
                                            </span>
                                        </p>
                                    </div>
                                </Form>
                            </Card>
                            <Button
                                className="!flex !items-center w-fit"
                                type="primary"
                                disabled={!childFilter?.length && !parseData.vendor_id}
                                onClick={onSummaryPrice}
                                loading={getSummaryPrice.isLoading}
                            >
                                <MdRefresh className="mr-2" />
                                Refresh Price
                            </Button>
                            <Card>
                                <div>
                                    <div className="flex justify-between items-center">
                                        <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                            PR No :
                                            <span className="m-0 font-normal text-gray-500">
                                                {detailQuery.data?.header?.pr_no ||
                                                    (detailQuery.data?.header?.references || []).map((val) => val.pr_no).join(", ")}
                                            </span>
                                        </p>
                                        <Button onClick={() => handlePrintVendorPrice()} type="default" className="!flex !items-center">
                                            <AiOutlineCar className="m-0 mr-2" />
                                            Print
                                        </Button>
                                    </div>
                                    <TenderPurchasing context={PurchasingContext} />
                                </div>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

const PurchasingInventoryEdit = () => {
    const [problemChild, setProblemChild] = useState<InventoryItem[]>([]);
    const [docReqChild, setDocReqChild] = useState<DocReqChild[]>([]);
    const [pickedVendor, setPickedVendor] = useState<number | null>(null);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const getVendor = useQuery([purchasingInventoryService.getBp], async () => {
        return (await purchasingInventoryService.GetBP()).data.data?.map((el, i) => ({
            label: el.bp_name,
            value: el.bp_id,
        }));
    });

    const getPOPrice = useMutation([purchasingInventoryService.poPrice], async (param: POPrice & { columnIndex: number }) => {
        await Utils.pause(0.5);
        return (await purchasingInventoryService.POPrice({ bp_id: param.bp_id, child: param.child })).data.data;
    });

    const value = useMemo(
        () => ({
            docReqChild,
            setDocReqChild,
            pickedVendor,
            setPickedVendor,
            getVendor,
            setProblemChild,
            problemChild,
            isRefresh,
            setIsRefresh,
            getPOPrice,
        }),
        [docReqChild, pickedVendor, getVendor, isRefresh]
    );

    return (
        <PurchasingContext.Provider value={value}>
            <PurchasingInventoryEditContext />
        </PurchasingContext.Provider>
    );
};

export default PurchasingInventoryEdit;

/* eslint-disable no-nested-ternary */
import React from "react";
import { BiImageAdd } from "react-icons/bi";

type Props = {
    image: File | string | null;
    title?: string;
    className?: string;
    size?: "small" | "medium" | "large";
};

const ImageCard = ({ image, title = "image upload", className, size = "medium" }: Props) => {
    const classes = (() => {
        if (size === "small") {
            return {
                box: "h-[140px]",
                imageAdd: "text-3xl",
                title: "text-xs",
            };
        }
        if (size === "medium") {
            return {
                box: "h-[200px]",
                imageAdd: "text-5xl",
                title: "text-lg",
            };
        }
        return {
            box: "h-[250px]",
            imageAdd: "text-7xl",
            title: "text-xl",
        };
    })();

    return (
        <div
            className={`${classes.box} cursor-pointer w-full border border-solid border-gray-300 text-gray-400 rounded-md flex flex-col items-center justify-center overflow-hidden relative ${className}`}
        >
            {image ? (
                typeof image === "string" ? (
                    <img src={image} alt={image} className="h-full w-full object-cover" />
                ) : (
                    <img src={URL.createObjectURL(image)} alt={URL.createObjectURL(image)} className="h-full w-full object-cover" />
                )
            ) : (
                <div className="my-5 flex flex-col items-center">
                    <BiImageAdd className={classes.imageAdd} />
                    <p className="capitalize text-xs text-center mt-4">
                        drop or click here <br />
                        <span className={`${classes.title} font-semibold`}>{title}</span>
                    </p>
                </div>
            )}
        </div>
    );
};

export default ImageCard;

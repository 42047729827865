/* eslint-disable react/destructuring-assignment */
import React from "react";
import ModalHistory, { Props as ModalHistoryProps } from "components/modal/history-modal";
import { useQuery } from "react-query";
import purchaseOrderExtService from "services/api-endpoints/dashboard-jsl/purchase-order-ext";

type Props = ModalHistoryProps;

export function withModalHistoryExt<T extends Props>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const detailHistory = useQuery(
            [purchaseOrderExtService.detailHistoryPO, props.idHistory],
            async () => {
                return (await purchaseOrderExtService.DetailHistoryPO({ id: props.idHistory })).data.data;
            },
            {
                enabled: !!props.idHistory,
            }
        );
        return <Component {...props} fetcher={detailHistory} />;
    };
}

const ModalHistoryPOExt = withModalHistoryExt(ModalHistory);
export default ModalHistoryPOExt;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DatePicker, Form, message } from "antd";
import Card from "antd/lib/card/Card";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "components/form/inputs/input-select";
import BukuBesarAkunTable from "modules/accounting/buku-besar/index/table";
import { bukuBesarTableToExcel } from "modules/accounting/buku-besar/utils";
import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDownload } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import bukuBesarService, { BukuBesarList, CoaPiutangList, FilterBukuBesar } from "services/api-endpoints/accounting/buku-besar";
import jurnalUmumService from "services/api-endpoints/accounting/jurnal-umum";

import { FORMAT_DATE_1, ORDER_PAYMENT_BP_SALES, ORDER_PAYMENT_EMPLOYESS, TYPE_BUKU_BESAR_PIUTANG } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Omit<FilterBukuBesar, "page">> = yup.object().shape({
    account_id: yup.number().required("Account Cabang Required"),
    type_id: yup.number().required("Sales Type Required"),
    id: yup.number(),
    coa_id: yup.number(),
    bp_id: yup.number(),
    start_date: yup.string(),
    end_date: yup.string(),
});

const BukuPiutang = () => {
    const [coaList, setCoaList] = React.useState<(SelectOption & CoaPiutangList)[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<FilterBukuBesar>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchId = watch("id");
    const watchAccountId = watch("account_id");
    const watchTypeId = watch("type_id");
    const watchCoaId = watch("coa_id");
    const watchBpId = watch("bp_id");
    const watchStartDate = watch("start_date");
    const watchEndDate = watch("end_date");

    const getBukuBesarList = useMutation(
        [bukuBesarService.bukuBesarList],
        async (data: FilterBukuBesar) => {
            return (await bukuBesarService.BukuBesarList(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Search Success");
            },
        }
    );

    const getAccountQuery = useQuery([jurnalUmumService.getAccount], async () => {
        return (await jurnalUmumService.GetAccount()).data.data.map(
            (el) => ({ ...el, label: `${el.account_code} - ${el.account_name}`, value: el.account_id } as SelectOption)
        );
    });

    const saldoAwal = () => {
        if ((getBukuBesarList.data?.saldo_awal?.saldo || 0) === 0)
            return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${(getBukuBesarList.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")}`;
        if ((getBukuBesarList.data?.saldo_awal?.saldo || 0) < 0)
            return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${((getBukuBesarList.data?.saldo_awal?.saldo || 0) * -1).ToIndCurrency(
                "Rp"
            )} (K)`;
        return `${getBukuBesarList.data?.saldo_awal?.note || ""} : ${(getBukuBesarList.data?.saldo_awal?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const total = () => {
        if ((getBukuBesarList.data?.total?.saldo || 0) < 0)
            return `${getBukuBesarList.data?.total?.note || ""} : ${((getBukuBesarList.data?.total?.saldo || 0) * -1).ToIndCurrency("Rp")} (K)`;
        return `${getBukuBesarList.data?.total?.note || ""} : ${(getBukuBesarList.data?.total?.saldo || 0).ToIndCurrency("Rp")} (D)`;
    };

    const marketplaceQuery = useQuery(
        [bukuBesarService.coaPiutangList, watchTypeId],
        async () => {
            return (await bukuBesarService.CoaPiutangList({ order_type: watchTypeId })).data.data?.map(
                (el) => ({ ...el, label: el.name, value: el.id } as SelectOption & CoaPiutangList)
            );
        },
        {
            onSuccess(data) {
                if (watchTypeId === ORDER_PAYMENT_EMPLOYESS || watchTypeId === ORDER_PAYMENT_BP_SALES) {
                    setCoaList(data?.map((el) => ({ ...el, label: el.name, value: el.bp_id } as SelectOption & CoaPiutangList)));
                } else {
                    setCoaList(data?.map((el) => ({ ...el, label: el.name, value: el.id } as SelectOption & CoaPiutangList)));
                }
            },
        }
    );

    const onSearchHandler = handleSubmit(async (data) => {
        getBukuBesarList.mutate({ ...data, page: 1 });
    });

    const onSearch = async () => {
        await form.validateFields();
        onSearchHandler();
    };

    const pageChange = (pg: any) => {
        if (!watchStartDate || !watchEndDate || !watchTypeId) return;
        getBukuBesarList.mutate({
            account_id: watchAccountId,
            bp_id: watchBpId,
            coa_id: watchCoaId,
            start_date: watchStartDate,
            end_date: watchEndDate,
            page: pg,
            type_id: watchTypeId,
        });
    };

    const onDownload = () => {
        const salesType = TYPE_BUKU_BESAR_PIUTANG.find((el) => el.value === watchTypeId)?.label;
        const COAName =
            watchTypeId === ORDER_PAYMENT_EMPLOYESS
                ? coaList.find((el) => el.bp_id === watchBpId)?.label
                : coaList.find((el) => el.value === watchCoaId)?.label;
        const date = `${watchStartDate?.split("-").join("")}_${watchEndDate?.split("-").join("")}`;

        bukuBesarTableToExcel({
            title: `Buku Piutang.${salesType}.${COAName}.${date}`,
            list: getBukuBesarList.data?.list as BukuBesarList[],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Card>
                <Form className="flex flex-col gap-5" form={form} layout="vertical">
                    <div className="grid grid-cols-5 gap-x-4 align-bottom">
                        <ControlledSelectInput
                            control={control}
                            name="account_id"
                            placeholder="Account Cabang"
                            label="Account Cabang"
                            options={getAccountQuery.data}
                            loading={getAccountQuery.isLoading}
                        />
                        <ControlledSelectInput
                            control={control}
                            name="type_id"
                            placeholder="Sales Type"
                            label="Sales Type"
                            options={TYPE_BUKU_BESAR_PIUTANG}
                            onChange={(val) => {
                                setValue("type_id", val);
                                setValue("id", undefined);
                                setValue("bp_id", undefined);
                                setValue("coa_id", undefined);
                            }}
                        />
                        <ControlledSelectInput
                            showSearch
                            name="id"
                            label="Coa Piutang"
                            placeholder="Coa Piutang"
                            optionFilterProp="children"
                            control={control}
                            disabled={!watchTypeId}
                            loading={marketplaceQuery.isLoading}
                            options={coaList}
                            onChange={(val) => {
                                if (watchTypeId === ORDER_PAYMENT_EMPLOYESS || watchTypeId === ORDER_PAYMENT_BP_SALES) {
                                    const bp = marketplaceQuery.data?.find((el) => el.bp_id === val);
                                    setValue("coa_id", bp?.id);
                                    setValue("bp_id", bp?.bp_id);
                                } else {
                                    setValue("coa_id", val);
                                }
                                setValue("id", val);
                            }}
                        />
                        <div className="col-span-1">
                            <p className="mb-2">Date Range</p>
                            <DatePicker.RangePicker
                                className="w-full"
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    setValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    setValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div>

                        <div className="m-0 mt-2 flex items-center gap-4">
                            <Button
                                onClick={onSearch}
                                type="primary"
                                className="!flex !items-center"
                                disabled={!watchTypeId || !watchStartDate || !watchEndDate || !watchId}
                                loading={getBukuBesarList.isLoading}
                            >
                                <BiSearch className="m-0 mr-2" />
                                Search
                            </Button>
                            <Button onClick={onDownload} disabled={!getBukuBesarList.data?.list.length} className="w-fit">
                                <AiOutlineDownload className="m-0 mr-2" />
                                Download
                            </Button>
                        </div>
                    </div>
                </Form>
                {getBukuBesarList.data?.saldo_awal ? (
                    <div className="grid grid-cols-5">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{saldoAwal()}</p>
                    </div>
                ) : null}
                <BukuBesarAkunTable pageChange={pageChange} fetcher={getBukuBesarList} />
                {getBukuBesarList.data?.total ? (
                    <div className="grid grid-cols-5 mt-4">
                        <div className="col-span-4" />
                        <p className="col-span-1 font-bold text-sm">{total()}</p>
                    </div>
                ) : null}
            </Card>
        </div>
    );
};

export default BukuPiutang;

/* eslint-disable import/prefer-default-export */
import { List, Tag } from "antd";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { ProductInvoice } from "services/api-endpoints/dashboard/master-data/c-invoice";
import { ProductWOData } from "services/api-endpoints/dashboard/master-data/order";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function viewTable<T extends ProductWOData>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code1",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code2",
                dataIndex: "product_code2",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Qty Use",
                dataIndex: "qty_use",
                width: "75px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const TableProductWaste = viewTable(EditTable);
export default TableProductWaste;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { message } from "antd";
import clsx from "clsx";
import { HTMLMotionProps, motion } from "framer-motion";
import LoaderEntity from "modules/manufacture/components/loader-entity";
import PriceTag from "modules/manufacture/components/price-tag";
import { ManufactureContext, checkIfTextIsEmpty } from "modules/manufacture/context/context";
import { MenuTypes, animateEntity, cardOperatorItemInfo, operatorIcons, setIdElementEntity } from "modules/manufacture/utils/constant";
import { Entity, OperatorEntity } from "modules/manufacture/utils/models";
import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import FirstOperator from "./first-operator";
import SecondOperator from "./second-operator";

type CardOperatorDrawProps = HTMLMotionProps<"div"> & {
    attach?: Entity<OperatorEntity>;
    asChild?: boolean;
};

const CardOperatorDraw = ({ className, asChild, attach, children, ...props }: CardOperatorDrawProps) => {
    const { entities, clickEntityToFocus, setCurrentDraggingEntity, entitiesWithTotal, activeEntity, clickEntityToZIndex, activeEntitiesMenu } =
        React.useContext(ManufactureContext) as any;

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.operator.type,
            item: { ...MenuTypes.operator, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                };
            },
        }),
        [attach?.left, attach?.top, attach?.idEntity, entities]
    );

    useEffect(() => {
        setCurrentDraggingEntity(isDragging ? attach : null);
    }, [isDragging]);

    const Icon = operatorIcons.find((icon) => icon.id === attach?.data?.icon)?.icon as React.ReactNode;

    const grandTotal = entitiesWithTotal?.find((ent: any) => ent?.idEntity === attach?.idEntity)?.total;

    const isPartOfActive = (() => {
        if (!activeEntitiesMenu.length) return true;
        return activeEntitiesMenu.includes(attach?.idEntity);
    })();

    const onClick = (e: any) => {
        e.stopPropagation();
        if (checkIfTextIsEmpty(entities, activeEntity?.idEntity)) {
            message.error("Title can't be empty!");
            return;
        }
        clickEntityToFocus(attach);
        clickEntityToZIndex(attach);
    };

    const classnameContainer = clsx(
        className,
        cardOperatorItemInfo.style.className,
        "border border-solid border-gray-300 min-w-[250px] hover:shadow-lg transition-shadow duration-100 cursor-pointer px-3 py-2 flex gap-3 flex-col items-center",
        !isPartOfActive && "!bg-gray-400 !border-gray-500",
        !attach?.idEntityParent && "shadow-md",
        !asChild && "absolute transform",
        activeEntity?.idEntity === attach?.idEntity ? "outline-card-entity" : ""
    );

    if (isDragging) return <div />;
    return (
        <motion.div
            animate={asChild ? {} : animateEntity.animate}
            initial={asChild ? {} : animateEntity.initial}
            transition={asChild ? {} : animateEntity.transition}
            exit={animateEntity.exit}
            role="button"
            tabIndex={0}
            id={setIdElementEntity(attach?.idEntity)}
            onClick={onClick}
            ref={drag as any}
            style={{ left: attach!.left!, top: attach!.top!, zIndex: attach!.zIndex! }}
            className={classnameContainer}
            {...props}
        >
            <LoaderEntity />
            {children as any}
            <div className="w-full flex items-center gap-2 justify-between">
                <FirstOperator entity={attach} />
                <span className="text-xl">{Icon}</span>
                <SecondOperator entity={attach} />
            </div>
            {attach?.data?.child1 || attach?.data?.child2 ? (
                <div className="self-start font-light text-sm">
                    Total: <PriceTag price={grandTotal} />
                </div>
            ) : null}
        </motion.div>
    );
};

export default CardOperatorDraw;

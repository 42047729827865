import { clsx } from "clsx";
import WrapperCardEffect, { WrapperCardEffectProps } from "modules/manufacture/components/wrapper-card-effect";
import { backgroundColorManufacture, cardMaterialItemInfo } from "modules/manufacture/utils/constant";
import ShapeMaterial, { ShapeMaterialProps } from "./shape";

export type CardMaterialDrawProps = Omit<WrapperCardEffectProps, "children"> &
    ShapeMaterialProps & {
        children?: any;
    };

const CardMaterialDraw = ({
    children,
    className,
    removeChildren,
    onMoveChild,
    isOverOldItem,
    totalChild,
    indexEffectedItem,
    ...props
}: CardMaterialDrawProps) => {
    const wrapperProps = {
        ...props,
        indexEffectedItem,
        totalChild,
        isOverOldItem,
        onMoveChild,
        removeChildren,
    };
    return (
        <WrapperCardEffect {...wrapperProps}>
            {({ isDragging, isDragOutsideToRemove, ref }: any) => (
                <ShapeMaterial
                    ref={ref}
                    asDraw
                    backgroundColor={clsx(
                        isDragging ? backgroundColorManufacture.primary : cardMaterialItemInfo.style.color,
                        isDragOutsideToRemove ? backgroundColorManufacture.danger : ""
                    )}
                    className={clsx("cursor-grab text-white", isDragging ? "!min-h-[50px]" : "", className)}
                    {...props}
                >
                    {children}
                </ShapeMaterial>
            )}
        </WrapperCardEffect>
    );
};

export default CardMaterialDraw;

import React from "react";
import { Image, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Remark } from "services/api-endpoints/dashboard-jsl/inventory-out";
import orderService from "services/api-endpoints/dashboard/master-data/order";
import moment from "moment";
import { AWS_PATH, FORMAT_DATE_TIME, IMAGE_FALLBACK, STATUS_PAYMENT_ORDER } from "utils/constant";

export const REMARK_PAGE = "remark_page";

interface Props {
    paramId?: any;
}

const HistoryPaymentTable = ({ paramId }: Props) => {
    const [searchParams] = useSearchParams();
    const id = paramId;

    const paymentHistoryQuery = useQuery(
        [orderService.paymentHistory, id],
        async () => {
            return (await orderService.PaymentHistory({ order_id: id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const columns: ColumnsType<Remark> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Note from user",
            dataIndex: "note1",
            render: (text: string) => {
                if (text?.includes(AWS_PATH)) {
                    return <Image src={text} width={100} height={50} fallback={IMAGE_FALLBACK} className="object-cover" />;
                }
                return <p className="capitalize m-0">{text || "-"}</p>;
            },
        },
        {
            title: "Note from admin",
            dataIndex: "note2",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => (
                <p className="capitalize m-0">{(STATUS_PAYMENT_ORDER?.find((el) => el.value === text) || STATUS_PAYMENT_ORDER[0])?.label}</p>
            ),
        },
        {
            title: "Created by",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created at",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
    ];

    return (
        <Table
            scroll={{ x: 1000 }}
            rowKey={(record) => record.id!}
            size="small"
            loading={paymentHistoryQuery.isLoading}
            columns={columns}
            dataSource={(paymentHistoryQuery.data as any) || []}
            className="w-full"
        />
    );
};

export default HistoryPaymentTable;

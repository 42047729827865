/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Payment {
    id?: number;
    doc_no?: string;
    bank_account_no?: string;
    transaction_date?: string;
    bp_name?: string;
    charge_type?: number;
    created_at?: number;
}

export interface FilterPayment {
    doc_no?: string;
    bg_id?: string;
    charge_type?: number;
    start_date?: string;
    end_date?: string;
}

export interface FilterDocPayment {
    account_id?: number;
    inv_no?: string;
    inv_ref?: string;
    description?: string;
    start_date?: string;
    end_date?: string;
    type?: number;
    partner_id?: number;
}

export interface BusinessPartner {
    bp_id: number;
    bp_name: string;
}

export interface SalesOrderPayment {
    sales_order_id: number;
    sales_order_no: string;
}

export interface BankAccount {
    bank_account_id: number;
    bank_account_no: string;
    coa_id: number;
}

export interface InvoicePayment {
    inv_id: number;
    inv_no: string;
}

export interface COAChargePayment {
    coa_id: number;
    coa_name: string;
}

export interface ChargeData {
    charge_id: number;
    charge_code: string;
    charge_name: string;
}

export interface InvoiceDocPayment {
    inv_id: number;
    inv_no: string;
    inv_ref: string;
    inv_ref_id: number;
    bp_name?: string;
    grand_total?: number;
    description?: string;
    ref_id?: number;
    created_at: number;
    cc_charge?: number | null;
    payment_channel_name?: string;
}

export interface SalesOrderDocPayment {
    bp_name?: string;
    created_at: number;
    description?: string;
    grand_total?: number;
    sales_order_id: number;
    sales_order_no: string;
}

export interface DetailPayment {
    id?: number;
    doc_no?: string;
    transaction_date?: string;
    bank_account_id?: number;
    account_no?: string;
    bp_id?: number;
    bp_name?: string;
    charge_type?: number;
    coa_id?: number;
    coa_name?: string;
    sales_order_id?: InvoiceDocPayment[] | null;
    inv_id?: InvoiceDocPayment[] | null;
    payment_images?: string[] | null;
}

export interface CreatePayment {
    account_id?: any;
    bank_account_id?: any;
    transaction_date?: any;
    partner_id?: any;
    coa_id?: any;
    charge_type?: any;
    sales_order_id?: any;
    inv_id?: any;
    payment_images?: any;
    total_price?: any;
    payment_amount?: any;
    order_type_id?: any;
    note?: any;
    payment_id?: number;
}

export interface ExpansesData {
    coa_id?: number;
    coa_name?: string;
    price?: any;
}

export interface DetailSoInv {
    order_detail_id?: number;
    product_name?: string;
    product_code?: string;
    locator?: string;
    qty?: number;
    created_by?: string;
    created_at?: string;
}

export interface FilterPurchasingList {
    doc_id: any;
    po_id: number;
    po_no?: string;
    doc_no?: string;
    total_tax?: number;
    total_disc?: number;
    total_price?: number;
    grand_total?: number;
    delivery_date?: number;
    status?: number;
    doc_type?: number;
    created_at?: number;
}

export interface FilterPurchasing {
    account_id?: number;
    po_no?: string;
    type?: number;
    start_date?: string;
    end_date?: string;
    bp_id?: number;
}

export interface CreateExpenses {
    charge_id?: number;
    charge_name?: string;
    amount?: number;
    note?: string;
}

export interface ChildData {
    doc_id?: number;
    doc_no?: string;
    grand_total?: number;
    total_tax?: number;
    total_disc?: number;
    total_price?: number;
    created_at?: string;
}

export interface ChildDetail extends ChildData {
    po_no?: string;
    date_invoice?: string;
}

export interface CreatePaymentV {
    account_id?: any;
    bank_account_id?: any;
    transaction_date?: any;
    payment_type?: any;
    purchasing_type?: any;
    child?: ChildData[];
    expenses: CreateExpenses[];
    payment_images: any[];
    payment_amount?: any;
    total_payment_amount?: any;
    note?: any;
    vendor_id?: any;
}

export interface DraftPaymentV extends CreatePaymentV {
    id?: number;
}

export interface FilterPaymentV {
    doc_no: string;
    start_date: string;
    end_date: string;
    page: number | string;
}

export interface PaymentVList {
    id?: number;
    doc_no?: string;
    name?: string;
    transaction_date?: string;
    expenses?: any[];
    payment_images?: any[];
    payment_amount?: number | null;
    total_payment_amount?: number;
    created_at?: string;
    purchasing_type?: number;
    is_active?: number;
    account_name?: string;
}

export interface PaymentVDetail {
    account_id?: number;
    account_name?: string;
    id?: number;
    doc_no?: string;
    bank_account_id?: number;
    transaction_date?: number;
    expenses?: CreateExpenses[];
    child?: ChildDetail[];
    payment_images?: string[];
    payment_amount?: number;
    total_payment_amount?: number;
    purchasing_type?: number | null;
    is_active?: number;
    note?: any;
    vendor_id?: number;
}

export interface EmployeeList {
    id?: number;
    name?: string;
}

export interface Piutang {
    inv_id: number;
    inv_no: string;
    inv_ref: string;
    bp_id: number;
    grand_total: number;
    description: string;
    created_at: string;
}

export interface GetPiutangParams {
    account_id: number;
    bp_id: number;
    start_date?: string;
    end_date?: string;
}

export interface EditParams {
    id: any;
    transaction_date: any;
    payment_images: any[];
    payment_type: any;
}

export interface FilterInternalList {
    id: number;
    inv_no: string;
    grand_total: number;
    created_at: string;
}

export interface FilterInternal {
    account_id?: number;
    inv_no?: string;
    start_date?: string;
    end_date?: string;
}

export interface AccountData {
    account_id?: number;
    account_name?: string;
    account_code?: string;
}

class PaymentService extends BaseService {
    getAll = "/payment/get-all-payment";

    search = "/payment/search-payment";

    filter = "/payment/filter-payment";

    getBP = "/payment/get-business-partner";

    getSO = "/payment/get-sales-order";

    getBA = "/payment/get-bank-account";

    getInvoice = "/payment/get-invoice";

    getCOACharge = "/payment/get-coa-charge";

    getDetailPayment = "/payment/detail-payment";

    create = "/payment/create-payment";

    createReceipt = "/receipt-voucher/create";

    detailSO = "/payment/detail-sales-order";

    detailInvoice = "/payment/detail-invoice";

    filterDoc = "/payment/filter-get-invoice";

    getCharge = "/receipt-voucher/get-charge";

    filterPurchasing = "/payment-voucher/filter-get-purchasing";

    createPaymentV = "/payment-voucher/create";

    draftPaymentV = "/payment-voucher/save-draft";

    allPaymentV = "/payment-voucher/get-payment-voucher";

    deletePaymentV = "/payment-voucher/delete";

    detailPaymentV = "/payment-voucher/detail-payment-voucher";

    getEmployee = "/payment-voucher/get-employee";

    getPiutang = "/receipt-voucher/get-piutang";

    editPaymentV = "/payment-voucher/edit";

    filterInternalOrder = "/payment-voucher/get-internal-order";

    getAccount = "payment/get-account";

    constructor() {
        super();
    }

    GetAccount<T extends AccountData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAccount,
            });
            return req;
        });
    }

    FilterInternalOrder<T extends FilterInternalList[]>(params: FilterInternal) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filterInternalOrder,
                config: { params },
            });
            return req;
        });
    }

    EditPaymentV<T extends any>(data: EditParams, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.editPaymentV,
                data,
                config,
            });
            return req;
        });
    }

    GetPiutang<T extends Piutang[]>(params: GetPiutangParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getPiutang,
                config: { params },
            });
            return req;
        });
    }

    GetEmployee<T extends EmployeeList[]>(param?: { account_id?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getEmployee,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DetailPaymentV<T extends PaymentVDetail>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailPaymentV,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DeletePaymentV<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deletePaymentV}/${id}`,
            });
            return req;
        });
    }

    AllPaymentV<T extends PaymentVList>(param: FilterPaymentV) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.allPaymentV,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SaveDraftPaymentV<T extends any>(data: DraftPaymentV, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPaymentV,
                data,
                config,
            });
            return req;
        });
    }

    SaveCreatePaymentV<T extends any>(data: DraftPaymentV, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPaymentV,
                data,
                config,
            });
            return req;
        });
    }

    DraftPaymentV<T extends any>(data: CreatePaymentV, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPaymentV,
                data,
                config,
            });
            return req;
        });
    }

    CreatePaymentV<T extends any>(data: CreatePaymentV, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPaymentV,
                data,
                config,
            });
            return req;
        });
    }

    FilterPurchasing<T extends FilterPurchasingList[]>(params: FilterPurchasing) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filterPurchasing,
                config: { params },
            });
            return req;
        });
    }

    FilterDoc<T extends InvoiceDocPayment[]>(params: FilterDocPayment) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filterDoc,
                config: { params },
            });
            return req;
        });
    }

    DetailInvoice<T extends DetailSoInv>({ id, page }: { id: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: `${this.detailInvoice}/${id}`,
                config: {
                    params: {
                        page,
                    },
                },
            });
            return req;
        });
    }

    DetailSO<T extends DetailSoInv>({ id, page }: { id: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: `${this.detailSO}/${id}`,
                config: {
                    params: {
                        page,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreatePayment, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetDetailPayment<T extends DetailPayment>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getDetailPayment}/${id}`,
            });
            return req;
        });
    }

    GetCOACharge<T extends COAChargePayment[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCOACharge,
            });
            return req;
        });
    }

    GetCharge<T extends ChargeData[]>(param: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCharge,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetInvoice<T extends InvoicePayment[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getInvoice,
            });
            return req;
        });
    }

    GetBA<T extends BankAccount[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBA,
            });
            return req;
        });
    }

    GetSO<T extends SalesOrderPayment[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSO,
            });
            return req;
        });
    }

    GetBP<T extends BusinessPartner[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBP,
            });
            return req;
        });
    }

    Filter<T extends Payment>(params: FilterPayment) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: { params },
            });
            return req;
        });
    }

    Search<T extends Payment>(params: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends Payment>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }
}

const paymentService = new PaymentService();
export default paymentService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface ListData {
    id: number;
    po_no: string;
    total_sku: number;
    total_item: number;
    date: string;
    payment_term_id: number;
    payment_term_code: string;
}

export interface BpData {
    bp_id: number;
    bp_name: string;
}

export interface ChildData {
    id: number;
    po_id: number;
    po_no: string;
    qty: number;
    qty_receive: number;
    product_id: number;
    type_id: number;
    product_name: string;
    type_name: string;
    uom_code?: string;
    uom_id?: number;
}

export interface DetailPOData {
    id: number;
    po_no: string;
    vendor_id: number;
    vendor_name: string;
    doc_type: number;
    child: ChildData[];
}

export interface CreateChild {
    supplies_id: number;
    qty_order: number;
    qty_receipt: number;
}

export interface CreatePenerimaan {
    doc_id: number;
    vendor_id: number;
    child: CreateChild[];
    do_image: any[];
}

export interface PenerimaanList {
    id: number;
    pb_no: string;
    vendor: string;
    qty: number;
    created_at: string;
}

export interface DetailPBChild {
    id: number;
    qty_order: number;
    qty_receipt: number;
    supplies_id: number;
    supplies_name: string;
    type_id: number;
    type_name: string;
    uom_code?: string;
    uom_id?: number;
}

export interface DetailPB {
    id: number;
    pb_no: string;
    vendor_id: number;
    vendor_name: string;
    doc_id: number;
    doc_no: string;
    account_address: string;
    account_name: string;
    child: DetailPBChild[];
    pb_images: string[];
}

export interface JourneyPOData {
    doc_id?: number;
    doc_no?: string;
    vendor_id?: number;
    vendor_name?: string;
    type?: number;
    child?: JourneyPOData[];
}

class PenerimaanBarangService extends BaseService {
    listData = "/penerimaan-barang/list-data-po";

    getBp = "/penerimaan-barang/get-business_partner";

    detailPO = "/penerimaan-barang/detail-purchasing-order";

    create = "/penerimaan-barang/create";

    getAll = "penerimaan-barang/list-penerimaan-barang";

    detailPB = "/penerimaan-barang/detail";

    journeyPO = "penerimaan-barang/get-journey-po";

    constructor() {
        super();
    }

    JourneyPO<T extends JourneyPOData[]>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.journeyPO}/${id}`,
            });
            return req;
        });
    }

    DetailPB<T extends DetailPB>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailPB,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends PenerimaanList>(param: { start_date: any; end_date: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreatePenerimaan) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    DetailPO<T extends DetailPOData>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailPO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetBp<T extends BpData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getBp,
            });
            return req;
        });
    }

    ListData<T extends ListData[]>(param: { bp_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listData,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const penerimaanBarangService = new PenerimaanBarangService();
export default penerimaanBarangService;

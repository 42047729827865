/* eslint-disable no-shadow */

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton } from "antd";
import StateRender from "components/common/state-render";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import ModalAddRoleMenu from "modules/master-data/role-menu/add/modal-menu";
import RoleMenuTable, { ItemRowRoleMenu } from "modules/master-data/role-menu/add/table-menu";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import roleMenuService, { ListMenu, RoleMenuRequest } from "services/api-endpoints/dashboard/master-data/role-menu";
import { ROLE_ACCESS } from "utils/constant";
import * as yup from "yup";
import { v4 as uuid } from "uuid";

export type FormRoleMenu = Pick<RoleMenuRequest, "apps_id" | "role_id" | "role_access" | "description">;

const schema: yup.SchemaOf<FormRoleMenu> = yup.object().shape({
    apps_id: yup.number().required("Apps required"),
    role_id: yup.number().required("Role required"),
    description: yup.string(),
    role_access: yup.array().min(1).required("Role Access required"),
});

const RoleMenuEdit = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const navigate = useNavigate();
    const [menus, setMenus] = useState<ItemRowRoleMenu[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<FormRoleMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [roleMenuService.detailRoleMenu, id],
        async () => {
            return (await roleMenuService.DetailRoleMenu({ id })).data.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            onSuccess(data) {
                form.setFieldValue("apps_id", data.apps_id);
                form.setFieldValue("role_id", data.role_id);
                form.setFieldValue("description", data?.description);
                form.setFieldValue("role_access", data.role_access);
                setValue("apps_id", data.apps_id);
                setValue("role_id", data.role_id);
                setValue("description", data?.description);
                setValue("role_access", data.role_access);
                setMenus(
                    data.collapse?.map((menu) => ({
                        id: uuid(),
                        name: menu.name,
                        icon: menu?.icon,
                        key: menu.key,
                        collapse: menu?.collapse?.map((sub) => ({
                            id: uuid(),
                            name: sub.name,
                            key: sub.key,
                            icon: sub?.icon,
                            collapse: menu?.collapse?.map((child) => ({
                                id: uuid(),
                                name: child.name,
                                key: child.key,
                                icon: child?.icon,
                            })),
                        })),
                    })) as any[]
                );
            },
        }
    );

    const createMutate = useMutation(
        async (data: RoleMenuRequest) => {
            return (await roleMenuService.CreateRoleMenu(data)).data.data;
        },
        {
            onSuccess() {
                navigate(-1);
                message.success("Role menu edited!");
            },
        }
    );

    const listAppQuery = useQuery([roleMenuService.listApp], async () => {
        return (await roleMenuService.ListApp()).data.data?.filter((el) => el?.is_active === 1).map((el) => ({ value: el.id!, label: el.name! }));
    });

    const listRoleQuery = useQuery([roleMenuService.listRole], async () => {
        return (await roleMenuService.ListRole()).data.data?.filter((el) => el?.is_active === 1).map((el) => ({ value: el.id!, label: el.name! }));
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            id,
            apps_id: data.apps_id,
            description: data?.description,
            role_id: data?.role_id,
            role_access: data?.role_access,
            collapse: menus,
        } as RoleMenuRequest;

        if (!menus.length) {
            message.error("At least insert 1 menu");
            return;
        }

        createMutate.mutate(parseData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitMenu = (menu: ListMenu | undefined) => {
        if (menu) {
            setMenus((prev) => {
                if (prev?.find((m) => m.key === menu.key)) {
                    message.error(`Menu ${menu.name} is already inserted`);
                    return prev;
                }
                return [...(prev || []), menu];
            });
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit role menu"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card className="">
                        <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4">
                                <ControlledSelectInput
                                    control={control}
                                    name="apps_id"
                                    options={listAppQuery.data || []}
                                    placeholder="Apps"
                                    label="Apps"
                                />
                                <ControlledSelectInput
                                    control={control}
                                    name="role_id"
                                    options={listRoleQuery.data || []}
                                    placeholder="Role"
                                    label="Role"
                                />
                                <ControlledSelectInput
                                    control={control}
                                    mode="multiple"
                                    name="role_access"
                                    options={ROLE_ACCESS as any}
                                    placeholder="Role Access"
                                    label="Role Access"
                                />
                                <ControlledInputText control={control} name="description" placeholder="Description" label="Description" />
                            </div>
                        </Form>
                        <div className="w-full flex justify-end">
                            <ModalAddRoleMenu onSubmit={onSubmitMenu}>
                                {({ openModal }) => (
                                    <Button onClick={openModal} type="primary" className="!flex !items-center">
                                        <BiPlus className="m-0 mr-2" />
                                        Menu
                                    </Button>
                                )}
                            </ModalAddRoleMenu>
                        </div>
                    </Card>
                    <RoleMenuTable menus={menus} setMenus={setMenus} />
                </StateRender.Data>
                <StateRender.Loading>
                    <div className="w-full flex flex-col">
                        <Skeleton className="w-[90%] h-11" />
                        <Skeleton className="w-[80%] h-11 my-2" />
                    </div>
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert type="error" message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default RoleMenuEdit;

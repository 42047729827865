/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import MenuTable from "modules/master-data/menu/index/table";
import { useQuery } from "react-query";
import menuService from "services/api-endpoints/dashboard/master-data/menu";

const Menu = () => {
    const getList = useQuery([menuService.listMenu], async () => {
        return (await menuService.ListMenu()).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} />
            <MenuTable fetcher={getList} />
        </div>
    );
};

export default Menu;

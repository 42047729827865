/* eslint-disable no-shadow */
import { Button } from "antd";
import Toolbar from "components/toolbar";
import ModalUploadDocContainer from "modules/master-data/doc-container/modal-upload";
import DocContainerTable from "modules/master-data/doc-container/table";
import { BsFillFileEarmarkArrowUpFill } from "react-icons/bs";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import docContainerService from "services/api-endpoints/dashboard/doc-container";

const DocContainer = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const listDoc = useQuery([docContainerService.list, page], async () => {
        return (await docContainerService.List({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar
                placeholder="Search..."
                filter={false}
                add={false}
                postfixAddition={() => (
                    <ModalUploadDocContainer onSuccess={() => listDoc.refetch()}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} className="!flex !items-center !gap-3" type="primary">
                                <BsFillFileEarmarkArrowUpFill />
                                Choose file
                            </Button>
                        )}
                    </ModalUploadDocContainer>
                )}
            />
            <DocContainerTable fetcher={listDoc} />
        </div>
    );
};

export default DocContainer;

import { Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import React from "react";
import { STATUS_ACTIVE } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterUser = ({ filter = [], ...props }: Props) => {
    return (
        <FilterModal
            filter={filter}
            title="Filter User"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Input placeholder="Name" value={dt.values.name} name="name" onChange={(e) => dt.setOneValue("name", e.target.value)} />
                        <Input placeholder="Email" value={dt.values.email} name="email" onChange={(e) => dt.setOneValue("email", e.target.value)} />
                        <Select
                            allowClear
                            value={dt.values.status !== null && dt.values.status !== undefined ? Number(dt.values.status) : dt.values.status}
                            placeholder="Status"
                            options={STATUS_ACTIVE}
                            onChange={(value) => dt.setOneValue("status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterUser;

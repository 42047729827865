/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ChargeData {
    charge_id?: number;
    // charge_code?: string;
    charge_name?: string;
}

export interface BaseReceiptResponse<T = any> {
    list: T[];
    total_data: number;
    total_page: number;
    current_page: number;
}

export interface BankAccount {
    bank_id: number;
    bank_no: string;
}

export interface ExpansesData extends ChargeData {
    note?: any;
    amount?: any;
    bp_id?: any;
    bp_name?: any;
}

export interface ChildData {
    doc_id?: any;
    doc_no?: any;
    inv_ref?: any;
    grand_total?: any;
    description?: any;
    date_invoice?: any;
}

export interface CreateTransferBankCabang {
    bank_coa_from?: any;
    bank_coa_to?: any;
    account_id_to?: any;
    account_id_from?: any;
    transaction_date?: any;
    expenses: ExpansesData[];
    bank_images: any[];
    amount?: any;
    total_amount?: any;
    note?: any;
}

export interface DraftTransferBank extends CreateTransferBankCabang {
    id?: any;
}

export interface DetailTransferCabang extends CreateTransferBankCabang {
    id?: any;
    doc_no?: string;
    is_active?: number;
    type?: number;
    account_id_to?: number;
    account_name_to?: string;
    account_id_from?: number;
    account_name_from?: string;
    bank_to?: string;
    bank_from?: string;
}

export interface TransferBankList {
    id?: number;
    doc_no?: string;
    bank_coa_from?: number;
    bank_from?: string;
    bank_coa_to?: number;
    bank_to?: string;
    transaction_date?: number;
    created_at?: number;
    total_amount?: number;
    is_active?: number;
    account_name_to?: string;
    account_name_from?: string;
    type?: number;
}

export interface FilterTransferBank {
    doc_no: string;
    start_date: string;
    end_date: string;
    page: number | string;
}

export interface AccountToData {
    account_id_to: number;
    account_name_to: string;
    account_code: string;
}

export interface HeaderData {
    account_id: number;
    account_name: string;
    account_code: string;
}

class TransferBankCabangService extends BaseService {
    getCharge = "/receipt-voucher/get-charge";

    bankAccount = "/bank-transfer/get-bank-account";

    bankAccountTo = "bank-transfer/get-bank-account-to";

    getListTransferCabang = "/bank-transfer/list-cabang";

    createTransferCabang = "/bank-transfer/create-cabang";

    draftTransferCabang = "/bank-transfer/save-draft-cabang";

    detailTransferCabang = "/bank-transfer/detail-cabang";

    deleteReceipt = "/receipt-voucher/delete-receipt-voucher";

    accountTo = "/bank-transfer/get-account-to";

    getHeader = "bank-transfer/get-header";

    constructor() {
        super();
    }

    GetBankAccountTo<T extends BankAccount[]>(param: { account_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.bankAccountTo,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetHeader<T extends HeaderData>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getHeader,
            });
            return req;
        });
    }

    AccountTo<T extends AccountToData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.accountTo,
            });
            return req;
        });
    }

    DeleteReceipt<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteReceipt}/${id}`,
            });
            return req;
        });
    }

    CreateTransferBank<T extends any>(data: CreateTransferBankCabang, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createTransferCabang,
                data,
                config,
            });
            return req;
        });
    }

    DraftTransferBank<T extends any>(data: CreateTransferBankCabang, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftTransferCabang,
                data,
                config,
            });
            return req;
        });
    }

    SaveCreateTransferBank<T extends any>(data: DraftTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createTransferCabang,
                data,
                config,
            });
            return req;
        });
    }

    SaveDraftTransferBank<T extends any>(data: DraftTransferBank, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftTransferCabang,
                data,
                config,
            });
            return req;
        });
    }

    DetailTransferCabang<T extends DetailTransferCabang>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailTransferCabang,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetListTransferCabang<T extends TransferBankList>(param: FilterTransferBank) {
        return this.ProxyRequest<BaseReceiptResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseReceiptResponse<T>>({
                url: this.getListTransferCabang,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetCharge<T extends ChargeData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getCharge,
            });
            return req;
        });
    }

    GetBankAccount<T extends BankAccount[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.bankAccount,
            });
            return req;
        });
    }
}

const transferBankCabangService = new TransferBankCabangService();
export default transferBankCabangService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataAttribute, DetailDataAttribute, EditDataAttribute, FilterDataAttribute } from "modules/master-data/attribute/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface AllAttribute {
    id: number;
    attribute_name: string;
    attribute_code: string;
}
class AttributeService extends BaseService {
    getAll = "/master-attribute/all-attribute";

    delete = "/master-attribute/delete-attribute";

    search = "/master-attribute/search-attribute";

    suggestAttrName = "/master-attribute/suggest-attribute-name";

    suggestAttrCode = "/master-attribute/suggest-attribute-code";

    filter = "/master-attribute/filter-attribute";

    getAllAttribute = "/master-attribute/get-all-attribute";

    create = "/master-attribute/create-attribute";

    detail = "/master-attribute/get-detail-attribute";

    edit = "/master-attribute/edit-attribute";

    constructor() {
        super();
    }

    GetAllAttribute<T extends AllAttribute[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllAttribute,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataAttribute>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataAttribute>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestAttrName<T extends string[]>(param: { attribute_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestAttrName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestAttrCode<T extends string[]>(param: { attribute_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestAttrCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataAttribute>(param: FilterDataAttribute) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataAttribute, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataAttribute>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataAttribute, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const attributeService = new AttributeService();
export default attributeService;

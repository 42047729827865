/* eslint-disable no-shadow */
import { Menu, MenuProps, Skeleton } from "antd";
import React, { useContext, useMemo } from "react";

import { StateContext } from "context/state";
import { useLocation, useNavigate } from "react-router-dom";

// images
import { Icon } from "@mui/material";
import ImageJsl from "assets/images/jsl.svg";
import { ACTION_PATHS } from "utils/constant";
import { AppsMenu } from "models";

type MenuItem = Required<MenuProps>["items"][number];

type Props = {
    collapse: boolean;
};

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

const Loading = () => {
    return (
        <div style={{ borderRight: "1px solid #e7e7e7" }} className="h-screen overflow-y-auto overflow-x-hidden pb-20 px-5">
            <div className="w-full relative p-5 flex justify-center flex-col items-center mb-10">
                <Skeleton.Node active style={{ width: "200px" }}>
                    <p />
                </Skeleton.Node>
            </div>
            <Skeleton paragraph={{ rows: 10 }} active />
        </div>
    );
};

const removeActionPath = (str: string) => {
    return str.replace(/\/(add|edit|view)$/, "");
};

function SidebarApp({ collapse }: Props) {
    const { state: stateContext, setActiveApp, setHistoryTab } = useContext(StateContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isActionPath = ACTION_PATHS.find((path) => location.pathname.includes(path));

    const clickLogoHandler = () => {
        if (setActiveApp) setActiveApp(null);
    };

    const makeHistory = ({ url, pathname }: { url: any; pathname: any }) => {
        const appName = stateContext.activeApp?.apps_name;

        const label = () => {
            const isActionPath = ACTION_PATHS.find((path) => url.includes(path));
            const endpoint = url.split("/")[url.split("/").length - (isActionPath ? 2 : 1)];
            return endpoint.split("-").join(" ").split("?")[0].CapitalizeEachFirstLetter();
        };

        if (setHistoryTab) setHistoryTab({ url, appName: appName!, label: label(), pathname: pathname || url });
    };
    const onNavigate: MenuProps["onClick"] = (e: any) => {
        const url = e.key;
        navigate(url, { replace: true });
    };

    const items: never[] | MenuProps["items"] = useMemo(() => {
        if (!stateContext.activeApp) return [];

        const menus = (menu: AppsMenu) => {
            return [
                ...menu.collapse.map((child1) =>
                    getItem(
                        child1.name,
                        child1.route,
                        null,
                        child1?.collapse ? [...child1.collapse.map((child2) => getItem(child2.name, child2.route))] : undefined
                    )
                ),
            ];
        };

        const menuItem = stateContext.activeApp?.apps_menu?.map((menu) =>
            getItem(menu.name, `/${menu.key}` || "/", <Icon>{menu.icon}</Icon>, menu.collapse ? menus(menu) : undefined)
        );

        return menuItem;
    }, [stateContext.activeApp]);

    const selectedKeys = (() => {
        if (!isActionPath) return location.pathname;
        const path = location.pathname.split("/");
        path.pop();
        return path.join("/");
    })();

    React.useEffect(() => {
        makeHistory({ url: location.pathname + location.search, pathname: removeActionPath(location.pathname) });
    }, [location.pathname, location.search]);

    return (
        <div style={{ borderRight: "1px solid #e7e7e7" }} className="w-full h-screen overflow-y-auto pb-20">
            <div className="w-full relative p-5 flex justify-center flex-col items-center">
                <button onClick={clickLogoHandler} type="button" className="bg-transparent w-full border-none flex items-center justify-center">
                    <img src={ImageJsl} alt="jsl" className="h-[60px] cursor-pointer w-full" />
                </button>
                {!collapse ? <p className="m-0 font-semibold capitalize text-lg mt-2 text-gray-500">{stateContext.activeApp?.apps_name}</p> : null}
            </div>
            <Menu onClick={onNavigate} mode="inline" items={items} selectedKeys={[selectedKeys]} className="sidebar" />
        </div>
    );
}

SidebarApp.Loading = Loading;

export default SidebarApp;

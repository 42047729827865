/* eslint-disable no-plusplus */
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import cDeliveryOrderService, { ListPickingList, ProductCPL } from "services/api-endpoints/dashboard/master-data/c-delivery-order";
import { FORMAT_DATE_TIME } from "utils/constant";
import { mapDuplicateProduct2 } from "../delivery-order/utils";

type Props<T> = {
    partnerID: number | null;
    partnerName: string;
    selectedSO: React.Key[];
    setSelectedSO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductSO: (data: ProductCPL[], real: ProductCPL[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
};

const DeliveryOrderAddModal = <T extends ListPickingList>({ children, selectedSO, setSelectedSO, partnerID, onProductSO, partnerName }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const listPOQuery = useQuery(
        [cDeliveryOrderService.pickingList, partnerID],
        async () => {
            return (await cDeliveryOrderService.PickingList<T[]>({ partner_id: partnerID })).data.data;
        },
        {
            enabled: !!partnerID,
        }
    );

    const getProductPLMutation = useMutation([cDeliveryOrderService.getProductPL], async (ids: string) => {
        return (await cDeliveryOrderService.GetProductPL({ order_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // const termsId = listPOQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.payment_term_id);
        // if ([...new Set(termsId)].length !== 1) {
        //     setCanSubmit(false);
        // } else {
        //     setCanSubmit(true);
        //     setTopId(termsId !== undefined ? termsId[0]! : null);
        // }
        setCanSubmit(newSelectedRowKeys.length === 1);
        setSelectedSO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedSO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "PL No",
            dataIndex: "doc_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    const onOkHandler = () => {
        getProductPLMutation
            .mutateAsync(selectedSO.join(","))
            .then((res) => {
                onProductSO(mapDuplicateProduct2(res), res);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title={`Packing List - ${partnerName}`}
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: getProductPLMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Selected 1 Picking List</p>
                    <Table
                        rowKey={(i) => i?.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default DeliveryOrderAddModal;

/* eslint-disable react/no-array-index-key */
import { Alert, Card, Skeleton } from "antd";
import State from "components/common/state";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService from "services/api-endpoints/dashboard-giias/report";
import { TYPE_POS_OFFLINE } from "utils/constant";

const GetAllReport = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const watchParams = [startDate, endDate, typeSales];

    const getAllReportQuery = useQuery([reportService.getAllReport, ...watchParams], async () => {
        return (await reportService.GetAllReport({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
    });
    return (
        <div className="w-full grid grid-cols-4 gap-6">
            <State data={getAllReportQuery.data} isLoading={getAllReportQuery.isLoading} isError={getAllReportQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            {Object.keys(getAllReportQuery.data || {}).map((key) => {
                                if (!getAllReportQuery.data) return null;
                                const value = getAllReportQuery.data[key as keyof typeof getAllReportQuery.data];
                                const title = key.split("_").join(" ");
                                return (
                                    <Card className="!flex justify-center">
                                        <div className="flex flex-col items-center gap-2">
                                            <p className="m-0 capitalize text-sm text-gray-600">{title}</p>
                                            <span className="text-xl text-gray-400">{value}</span>
                                        </div>
                                    </Card>
                                );
                            })}
                        </State.Data>
                        <State.Loading state={state}>
                            {[...new Array(4)].map((_, i) => (
                                <Skeleton paragraph={{ rows: 2 }} active className="w-full bg-white rounded-md p-3" key={i} />
                            ))}
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(getAllReportQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </div>
    );
};

export default GetAllReport;

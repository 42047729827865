import { Button, ButtonProps, Image, Skeleton } from "antd";
import React from "react";
import { AiFillAppstore } from "react-icons/ai";
import { IMAGE_FALLBACK } from "utils/constant";

type Props = ButtonProps & {
    description: any;
    image: string | undefined;
};

const Loading = () => {
    return (
        <Skeleton.Node active>
            <AiFillAppstore fontSize={25} />
        </Skeleton.Node>
    );
};

const ButtonAccesory = ({ description, image, ...props }: Props) => {
    return (
        <div className="flex flex-col items-center">
            <Button {...props} className="!w-[100px] !h-[100px] !rounded-md !flex !items-center !justify-center">
                <Image preview={false} src={image} alt={description} className="object-contain w-full h-full" fallback={IMAGE_FALLBACK} />
            </Button>
            <div className="m-0 mt-2 text-gray-600 text-center text-xs !w-[100px]">{description}</div>
        </div>
    );
};

ButtonAccesory.Loading = Loading;

export default ButtonAccesory;

/* eslint-disable no-lonely-if */
import { Button, Input, message, Steps } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { ProductWithBOM } from "pages/purchasing-order-m/add";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingOrderMService from "services/api-endpoints/dashboard/master-data/purchasing-order-m";
import TableByDoc from "./table-by-doc";
import TableChooseProduct from "./table-choose-product";
import TablePOMError from "./table-pom-error";
import { BOMWithLocatorAndNote } from "./table-pom-view";
import { MultipleChildBOM } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";

export type Props = ModalProps & {
    setSelected: React.Dispatch<React.SetStateAction<ProductWithBOM[]>>;
};

const ModalProductByDoc = ({ children, setSelected, ...props }: Props) => {
    const closeRef = React.useRef<HTMLButtonElement | null>(null);
    const [step, setStep] = React.useState(0);
    const [isError, setIsError] = React.useState(false);
    const [docID, setDocID] = React.useState(undefined);
    const [selectedProduct, setSelectedProduct] = React.useState<React.Key[]>([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const query = searchParams.get("query");
    const [queryValue, setQueryValue] = React.useState("");

    const listDocQuery = useQuery([purchasingOrderMService.listDoc, page, query], async () => {
        return (await purchasingOrderMService.ListDoc({ page, query })).data.data;
    });

    const getProductDocMutation = useMutation([purchasingOrderMService.getProductDoc], async (id: any) => {
        return (await purchasingOrderMService.GetProductDoc({ id })).data.data;
    });

    const getDetailProductMutation = useMutation(
        [purchasingOrderMService.getDetailProduct],
        async (id: any) => {
            return (await purchasingOrderMService.GetDetailProduct({ id })).data.data;
        },
        {
            async onSuccess(data) {
                const parse = data.list_product.map((el, i) => {
                    const p = (getProductDocMutation.data || []).find((val) => val.product_id === el.product_id);
                    return {
                        product_id: el.product_id,
                        product_name: el.name,
                        product_code1: el.code1,
                        qty: p?.qty,
                        no: i,
                        child: el.bom?.map((b) => {
                            const temp = { ...b, qty_bom_temp: b.qty_bom, child: [] };
                            if ("child" in b && (b as MultipleChildBOM).child.length) {
                                return {
                                    ...temp,
                                    child: (b as MultipleChildBOM).child.map((c) => ({ ...c, qty_bom_temp: c.qty_bom })),
                                };
                            }
                            return temp;
                        }) as BOMWithLocatorAndNote[],
                    };
                }) as ProductWithBOM[];

                if (!data.error) {
                    setSelected(parse);
                    closeRef.current?.click();
                    setStep(0);
                } else {
                    setIsError(true);
                    setStep(2);
                    message.error("Terdapat product yang tidak ada Raw Material/BOM ");
                }
            },
        }
    );

    const steps = [
        {
            title: "Document",
        },
        {
            title: "Product",
        },
    ];

    const stepsError = [
        {
            title: "Document",
        },
        {
            title: "Product",
        },
        {
            title: "Error",
        },
    ];

    const onChangeStep = (stp: number) => {
        if (step === 0) return;
        setStep(stp);
    };

    const onChooseDoc = (id: any) => {
        setDocID(id);
        setStep(1);
        setIsError(false);
        getProductDocMutation.mutate(id);
    };

    const onSubmit = () => {
        getDetailProductMutation.mutate(selectedProduct.join(","));
    };

    const onSearch = (value: string) => {
        searchParams.set("query", value);
        setSearchParams(searchParams);
    };

    return (
        <>
            <ModalTemplate
                {...props}
                title="Search Product By Document"
                handlerInComponent={children}
                footer={null}
                width={1000}
                closable={!getProductDocMutation.isLoading}
            >
                {(dt) => (
                    <div className="w-full flex flex-col gap-2">
                        <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                            close
                        </button>
                        <Steps current={step} onChange={onChangeStep} items={isError ? stepsError : steps} />
                        {step === 0 && (
                            <>
                                <div className="w-full flex items-center bg-tertiary p-2 rounded-md gap-4 justify-between">
                                    <Input.Search
                                        value={queryValue || ""}
                                        onChange={(e) => setQueryValue(e.target.value)}
                                        allowClear
                                        placeholder="Search By Document No"
                                        onSearch={onSearch}
                                    />
                                </div>
                                <TableByDoc fetcher={listDocQuery} onChooseDoc={onChooseDoc} />
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <TableChooseProduct
                                    fetcher={getProductDocMutation}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={setSelectedProduct}
                                />
                                <div className="w-full relative flex flex-col gap-1 items-end">
                                    <Button type="primary" onClick={onSubmit}>
                                        Submit
                                    </Button>
                                </div>
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <p className="text-red-500 font-bold m-0">Error</p>
                                <p className="text-red-500 m-0">Product di bawah ini, Tidak ada Raw Material / BOM</p>
                                <TablePOMError product={getDetailProductMutation.data?.list_product || []} />
                            </>
                        )}
                    </div>
                )}
            </ModalTemplate>
        </>
    );
};

export default ModalProductByDoc;

import { Button } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { rekapOfflineAccountingToExcel } from "modules/master-data/rekap/download/offline-accounting-utils";
import FilterOffline from "modules/master-data/rekap/filter/filter-offline";
import TableIndexOfflineAccounting from "modules/master-data/rekap/table-index-offline-accounting";
import { FaDownload } from "react-icons/fa";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import rekapService from "services/api-endpoints/dashboard/master-data/rekap";

const filter = ["sales_id", "start_date", "end_date", "order_status"];

const RekapOfflineAccounting = () => {
    const [searchParams] = useSearchParams();

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [rekapService.getOffline, searchValue, filterValue, isFilter, isSearch],
        async () => {
            return (await rekapService.GetOffline(filterValue as any)).data.data;
        },
        {
            enabled: !!filterValue.start_date || !!filterValue.end_date,
        }
    );

    const onDownloadRekap = () => {
        const dateFilter = `${filterValue.start_date?.split("-").join("")}_${filterValue.end_date?.split("-").join("")}`;
        rekapOfflineAccountingToExcel({
            title: `Rekap Penjualan - Offline (Accounting) (${dateFilter})`,
            list: getList.data || [],
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterOffline filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Invoice No"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        postfixAddition={() => (
                            <Button
                                loading={getList.isLoading}
                                disabled={!getList.data}
                                type="primary"
                                className="!flex !items-center w-fit"
                                onClick={onDownloadRekap}
                            >
                                <FaDownload className="mr-2" />
                                Download
                            </Button>
                        )}
                    />
                )}
            </FilterOffline>
            <TableIndexOfflineAccounting fetcher={getList} />
        </div>
    );
};

export default RekapOfflineAccounting;

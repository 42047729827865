/* eslint-disable import/no-extraneous-dependencies */
import clsx from "clsx";
import { MenuTypes, cardOperatorItemInfo, operatorIcons } from "modules/manufacture/utils/constant";
import { MultipleOperatorEntity } from "modules/manufacture/utils/models";
import React from "react";
import { useDrag } from "react-dnd";

type CardMultipleOperatorItemProps = React.HTMLAttributes<HTMLDivElement> & {
    attach: MultipleOperatorEntity;
    asPlaceholder?: boolean;
};

const CardMultipleOperatorItem = ({ className, children, attach, asPlaceholder, ...props }: CardMultipleOperatorItemProps) => {
    const [_, drag] = useDrag(
        () => ({
            type: MenuTypes.multipleOperator.type,
            item: { ...MenuTypes.multipleOperator, ...attach, left: 0, top: 0, zIndex: 1 },
        }),
        []
    );

    const Icon = operatorIcons.find((icon) => icon.id === attach.data?.icon)?.icon as React.ReactNode;

    const classnameContainer = clsx(cardOperatorItemInfo.style.className, "cursor-grab px-3 py-2 flex gap-3 flex-col items-center", className);

    return (
        <div ref={asPlaceholder ? null : drag} className={classnameContainer} {...props}>
            {children}
            <div className="w-full flex items-center gap-2 justify-between">
                <span className="text-xl">{Icon}</span>
                <div className={clsx("w-[100px] h-[50px] rounded", cardOperatorItemInfo.style.secondaryColor)} />
            </div>
        </div>
    );
};

export default CardMultipleOperatorItem;

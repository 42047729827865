import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { CreateDataModel, EditDataModel } from "modules/master-data/model/models";
import BrandNameSelect from "modules/master-data/model/lib/brand-name-select";
import InputFile from "components/form/inputs/input-file";
import { AWS_PATH, IMAGE_TYPES } from "utils/constant";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import useProgressUpload from "hooks/useProgressUpload";
import State from "components/common/state";
import ParentNameSelect from "modules/master-data/model/lib/parent-name-select";
import ModelYearSelect from "modules/master-data/model/lib/model-year-select";
import IsActiveSelect from "modules/master-data/model/lib/is-active-select";
import ModelTypeSelect from "modules/master-data/model/lib/model-type-select";

const schema: yup.SchemaOf<Partial<CreateDataModel>> = yup.object().shape({
    brand_id: yup.number().required("Brand name required"),
    model_type_id: yup.string().nullable().required("Model type required"),
    parent_id: yup.string().nullable(),
    model_name: yup.string().required("Brand type required"),
    model_code: yup.string().required("Popular required"),
    model_year: yup.array().nullable(),
    is_active: yup.number().required("Is Active required"),
    model_image: yup.string().required("Header Image required"),
});

const ModelEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [progress, onUploadProgress] = useProgressUpload();
    const [form] = Form.useForm();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<CreateDataModel>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const parentID = watch("parent_id");
    const isActive = watch("is_active");

    const [controlFile] = useControlFile<CreateDataModel>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const detailQuery = useQuery(
        [modelService.detail, id],
        async () => {
            return (await modelService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("brand_id", data.brand_id);
                setValue("parent_id", data.parent_id);
                setValue("model_name", data.model_name);
                setValue("model_code", data.model_code);
                setValue("model_year", data.model_year);
                setValue("is_active", data.is_active);
                setValue("model_image", data.model_image);

                if (data.model_type_id === "0" || data.model_type_id === 0) setValue("model_type_id", "1");
                else setValue("model_type_id", data.model_type_id);
            },
        }
    );

    const editMutation = useMutation(
        [modelService.edit],
        async (data: EditDataModel) => {
            return (await modelService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Model edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
            model_image: data.model_image?.includes(AWS_PATH) ? "default" : data.model_image || "",
        } as EditDataModel;
        editMutation.mutate(parseData);
    });

    const onChangeParent = (data: string | null) => {
        setValue("parent_id", data);
        if (data === "") {
            setValue("model_year", []);
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new model"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="model_image" className="col-span-2">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="model image" />}
                                        </InputFile>
                                    </div>
                                    <Form form={form} disabled={editMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">brand information</h1>
                                        <BrandNameSelect control={control} label="Brand Type" name="brand_id" placeholder="Brand type" />
                                        <h1 className="capitalize font-semibold text-gray-500 mb-4">model information</h1>
                                        <div className="grid grid-cols-2 gap-x-5">
                                            <ParentNameSelect
                                                control={control}
                                                label="Parent Name"
                                                name="parent_id"
                                                placeholder="Parent Name"
                                                onChange={onChangeParent}
                                            />
                                            <ControlledInputText control={control} label="Model Name" name="model_name" placeholder="Model Name" />
                                            <ModelYearSelect
                                                label="Model Year"
                                                mode="multiple"
                                                control={control}
                                                name="model_year"
                                                placeholder="Model Year"
                                                showSearch
                                                disabled={!parentID}
                                            />
                                            <ModelTypeSelect control={control} label="Model Type" name="model_type_id" placeholder="Model Type" />
                                            <ControlledInputText control={control} label="Model Code" name="model_code" placeholder="Model Code" />
                                            <IsActiveSelect control={control} label="Is Active" name="is_active" placeholder="Is Active" />
                                        </div>
                                    </Form>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default ModelEdit;

import { Button, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { DocData } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    fetcher?: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onChooseDoc: (id: any) => void;
};

const TableByDoc = <T extends DocData>({ fetcher, onChooseDoc }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher?.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Document No",
            dataIndex: "po_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_product",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Qty",
            dataIndex: "total_qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Create Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "150px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Button type="primary" onClick={() => onChooseDoc(record.id)}>
                    Choose
                </Button>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher?.isLoading}
            columns={columns}
            dataSource={fetcher?.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher?.data?.current_page || 1,
                pageSize: 10,
                total: fetcher?.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableByDoc;

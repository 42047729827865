import { useDrag } from "react-dnd";
import ShapeMaterial from "./shape";
import React from "react";
import { MenuTypes, cardMaterialItemInfo } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity } from "modules/manufacture/utils/models";

type CardMaterialItemProps = React.HTMLProps<HTMLDivElement> & {
    attach?: Entity<MaterialEntity>;
};

const CardMaterialItem = ({ className, attach, ...props }: CardMaterialItemProps) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.material.type,
            item: { ...MenuTypes.material, ...attach },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    return (
        <ShapeMaterial
            ref={drag as any}
            backgroundColor={cardMaterialItemInfo.style.color}
            className={`cursor-grab !w-[150px] text-white ${isDragging ? "opacity-40" : ""} ${className}`}
            {...props}
        />
    );
};

export default CardMaterialItem;

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import { Button, Input, InputRef, Steps, Table, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { ADD_KOLI_STR, ProductCPDWithScan, SAVE_KOLI_STR } from "pages/c-packing-list/add";
import React from "react";
import { BiSave } from "react-icons/bi";
import okayAudio from "assets/audio/okay2.mp3";
import nopeAudio from "assets/audio/nope.mp3";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";

export type Props = ModalProps & {
    productPD: ProductCPDWithScan[];
    onSaveKoli: (products: ProductCPDWithScan[]) => void;
    defaultKoliName?: string;
    defaultProductScan?: ProductCPDWithScan[];
};

const steps = [
    {
        title: "Koli name",
    },
    {
        title: "Product scan",
    },
];

const ModalAddKoli = ({ productPD, onSaveKoli, children, defaultKoliName, defaultProductScan, ...props }: Props) => {
    const closeRef = React.useRef<HTMLButtonElement>(null);
    const inputSearchRef = React.useRef<InputRef>(null);
    const inputKoliRef = React.useRef<InputRef>(null);

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [productScan, setProductScan] = React.useState<ProductCPDWithScan[]>([]);
    const [lastScanProduct, setLastScanProduct] = React.useState<ProductCPDWithScan | null>(null);
    const [noKoli, setNoKoli] = React.useState("");
    const [query, setQuery] = React.useState<string>("");
    const [currentStep, setCurrentStep] = React.useState(0);

    const nopeTune = new Audio(nopeAudio);
    const okayTune = new Audio(okayAudio);

    const prevStep = () => {
        setCurrentStep((prev) => prev - 1);
    };

    React.useEffect(() => {
        if (defaultKoliName) setNoKoli(defaultKoliName);
        if (defaultProductScan) setProductScan(defaultProductScan);
        setCurrentStep(0);
    }, [defaultKoliName, defaultProductScan, isModalOpen]);

    React.useEffect(() => {
        nopeTune.load();
        okayTune.load();
    }, []);

    const removeRecord = (rec: ProductCPDWithScan) => {
        return () => {
            if (lastScanProduct && lastScanProduct?.product_code1 === rec?.product_code1) {
                setLastScanProduct(null);
            }
            setProductScan((prev) => prev.filter((el) => el.product_code1 !== rec.product_code1));
        };
    };

    const columns: ColumnsType<ProductCPDWithScan> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "product_code1",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Scan Total",
            render: (text, record) => {
                const total = record?.group_koli?.reduce((sum, curr) => sum + curr.total_scan, 0) || 0;
                return <p className="capitalize m-0">{total}</p>;
            },
        },
        {
            title: "Action",
            render: (_, record) => (
                <Button onClick={removeRecord(record)} danger type="ghost" size="small">
                    Remove
                </Button>
            ),
        },
    ];

    const onSave = (koliStr: any = "") => {
        const nameKoli = koliStr || noKoli;

        if (typeof nameKoli !== "string") return;

        if (!nameKoli?.trim()) {
            message.error("Koli name required!");
            nopeTune.play();
            if (inputKoliRef.current) {
                inputKoliRef.current.focus();
            }
            return;
        }
        const findNoKoli = productPD.find((el) => el.group_koli.find((koli) => koli.no_koli?.toLowerCase() === nameKoli?.trim()?.toLowerCase()));
        if (findNoKoli && !defaultKoliName) {
            message.error("Koli Name already exist!");
            nopeTune.play();
            return;
        }
        okayTune.play();

        let restProduct = productScan.map((el) => ({
            ...el,
            group_koli: el.group_koli?.map((koli) => {
                if (koli.no_koli !== "temp_koli") return koli;
                return {
                    ...koli,
                    no_koli: nameKoli?.trim(),
                };
            }),
        }));

        if (restProduct.length !== defaultProductScan?.length) {
            const deletedProduct = defaultProductScan?.filter((p) => !restProduct.find((el) => el.product_code1 === p.product_code1));
            const removeKoliFromProduct =
                deletedProduct?.map((p) => ({
                    ...p,
                    group_koli: p.group_koli.filter((koli) => koli.no_koli !== defaultKoliName),
                })) || [];
            restProduct = [...restProduct, ...removeKoliFromProduct];
        }

        onSaveKoli(restProduct);
        setProductScan([]);
        setNoKoli("");
        setLastScanProduct(null);
        setCurrentStep(0);
        if (closeRef.current) {
            closeRef.current.click();
        }
    };

    const onSearch = (q: string) => {
        if (!q.trim()) return;
        setQuery("");
        if (q.includes(SAVE_KOLI_STR) || q.includes(ADD_KOLI_STR)) {
            onSave();
            return;
        }
        const productFind = productPD.find((el) => el.product_code1 === q?.toUpperCase());
        if (!productFind) {
            message.error("Can't find product!");
            nopeTune.play();
            return;
        }

        const totalProductScanPrev = productFind?.group_koli.reduce((sum, curr) => sum + curr.total_scan, 0) || 0;
        const productScanFind = productScan.find((el) => el.product_code1 === q?.toUpperCase());
        const productScanCurrFind = productScanFind?.group_koli.reduce((sum, curr) => sum + curr.total_scan, 0) || 0;

        if (productScan.length && productScanCurrFind >= (productScanFind?.qty || 1)) {
            message.error("All Product already scanned!");
            nopeTune.play();
            return;
        }

        if (totalProductScanPrev && totalProductScanPrev >= (productFind.qty || 1)) {
            message.error("All Product already scanned!");
            nopeTune.play();
            return;
        }

        setLastScanProduct(productFind);
        setProductScan((prev) => {
            if (prev.find((el) => el?.product_code1 === q?.toUpperCase())) {
                return prev.map((el) => {
                    if (el.product_code1 !== q?.toUpperCase()) return el;
                    const tempGroupKoli = el.group_koli.find((koli) => koli.no_koli === "temp_koli");
                    let groupKoli;
                    if (!tempGroupKoli) {
                        const existKoli = el.group_koli.find((koli) => koli.no_koli === defaultKoliName);
                        groupKoli = [{ total_scan: (existKoli?.total_scan || 0) + 1, no_koli: "temp_koli" }];
                    } else {
                        groupKoli = el.group_koli.map((koli) => {
                            if (koli.no_koli !== "temp_koli") {
                                return koli;
                            }
                            return {
                                ...koli,
                                total_scan: koli.total_scan + 1,
                            };
                        });
                    }
                    return {
                        ...el,
                        group_koli: groupKoli,
                    };
                });
            }

            return [
                ...prev,
                {
                    ...productFind,
                    group_koli: [...productFind.group_koli, { total_scan: 1, no_koli: "temp_koli" }],
                },
            ];
        });
        okayTune.play();
    };

    const onReset = () => {
        setProductScan([]);
        setLastScanProduct(null);
    };

    const focusInputCode = (isOpen: boolean) => {
        setIsModalOpen(isOpen);
        if (isOpen) {
            setTimeout(() => {
                if (inputKoliRef.current) {
                    inputKoliRef.current.focus();
                }
            }, 200);
        }
        if (!isOpen) {
            setProductScan([]);
            setNoKoli("");
            setCurrentStep(0);
            setLastScanProduct(null);
        }
    };

    // do not delete
    // const onSubmitInputKoli = (e: any) => {
    //     e.preventDefault();
    //     if (noKoli.includes(ADD_KOLI_STR)) {
    //         setNoKoli((prev) => prev.replace(ADD_KOLI_STR, ""));
    //         return;
    //     }
    //     if (noKoli.includes(SAVE_KOLI_STR)) {
    //         const nKoli = noKoli.replace(SAVE_KOLI_STR, "");
    //         if (!nKoli.trim()) {
    //             setNoKoli((prev) => prev.replace(SAVE_KOLI_STR, ""));
    //             return;
    //         }
    //         onSave(nKoli);
    //     }
    // };

    const onNextStep1 = () => {
        if (currentStep === 0 && !noKoli.trim()) return;
        const findNoKoli = productPD.find((el) => el.group_koli.find((koli) => koli.no_koli?.toLowerCase() === noKoli?.trim()?.toLowerCase()));
        if (findNoKoli && !defaultKoliName) {
            message.error("Koli Name already exist!");
            nopeTune.play();
            return;
        }
        setTimeout(() => {
            if (inputSearchRef.current) {
                inputSearchRef.current.focus();
            }
        }, 200);
        setCurrentStep((prev) => prev + 1);
    };

    const onChangeStep = (stp: number) => {
        if (currentStep === 0 && !noKoli.trim()) return;
        setCurrentStep(stp);
        if (stp === 1) {
            setTimeout(() => {
                if (inputSearchRef.current) {
                    inputSearchRef.current.focus();
                }
            }, 200);
        }
    };

    return (
        <ModalTemplate
            isModalOpenFn={focusInputCode}
            {...props}
            width={lastScanProduct && currentStep === 1 ? 1200 : 800}
            title="Add Koli"
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full flex flex-col gap-6 items-start relative">
                    <button ref={closeRef} className="opacity-0 w-0 h-0 absolute" type="button" onClick={dt.closeModal}>
                        close
                    </button>
                    <div className="flex gap-7 w-full">
                        <div className={`flex-col flex gap-6 ${lastScanProduct ? "flex-2" : "flex-1"}`}>
                            <Steps current={currentStep} onChange={onChangeStep} items={steps} />
                            <div className="border-solid border rounded-lg border-gray-300 p-6 w-full">
                                {currentStep === 0 && (
                                    <div className="flex w-full flex-col gap-10 items-start">
                                        <div className="flex gap-10 items-center">
                                            <label htmlFor="koliname">Koli: </label>
                                            <Input
                                                id="koliname"
                                                ref={inputKoliRef}
                                                value={noKoli}
                                                onChange={(e) => setNoKoli(e.target.value)}
                                                placeholder="Koli Name"
                                                className="text-xl !text-gray-800 placeholder:text-gray-800 font-medium mt-10 !w-[500px]"
                                                autoFocus
                                            />
                                            <Button
                                                id="save-koli-btn"
                                                disabled={!noKoli.trim()}
                                                onClick={onNextStep1}
                                                type="primary"
                                                className="!flex !items-center"
                                            >
                                                Next
                                                <MdOutlineNavigateNext className="m-0 mr-2 w-fit" />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {currentStep === 1 && (
                                    <div className="flex w-full flex-col gap-7 items-start">
                                        <p className="text-xl m-0">{noKoli}</p>
                                        <div className="flex items-center justify-between w-full">
                                            <Input.Search
                                                ref={inputSearchRef}
                                                onSearch={onSearch}
                                                placeholder="Product Code"
                                                className="!w-[300px]"
                                                value={query}
                                                onChange={(val) => setQuery(val.target.value)}
                                            />
                                        </div>
                                        <Table size="small" columns={columns} dataSource={productScan} className="w-full" pagination={false} />
                                        <div className="flex items-center justify-between w-full">
                                            <Button id="save-koli-btn" onClick={prevStep} type="primary" className="!flex !items-center">
                                                <MdOutlineNavigateBefore className="m-0 mr-2 w-fit" />
                                                Prev
                                            </Button>
                                            <div className="flex items-center gap-2">
                                                <Button disabled={!productScan.length} onClick={onReset} type="link">
                                                    Reset
                                                </Button>
                                                <Button
                                                    disabled={!productScan.length}
                                                    id="save-koli-btn"
                                                    onClick={() => onSave("")}
                                                    type="primary"
                                                    className="!flex !items-center"
                                                >
                                                    <BiSave className="m-0 mr-2 w-fit" />
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {currentStep === 1 && lastScanProduct && (
                            <div className="flex-1 h-full px-10 bg-white items-center overflow-hidden flex flex-col justify-around">
                                {lastScanProduct?.images?.length ? (
                                    <img
                                        src={lastScanProduct.images[0]}
                                        alt={lastScanProduct.product_name}
                                        className="h-full w-full object-contain"
                                    />
                                ) : (
                                    <div className="h-full w-full object-contain flex items-center justify-center bg-slate-300">
                                        <p>Image not found</p>
                                    </div>
                                )}
                                <p className="font-semibold bg-white px-2 py-1">
                                    CODE 1: <span className="font-normal">{lastScanProduct?.product_code1}</span> | CODE 2:{" "}
                                    <span className="font-normal">{lastScanProduct?.product_code2}</span>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalAddKoli;

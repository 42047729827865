/* eslint-disable no-redeclare */
/* eslint-disable no-plusplus */
/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable no-useless-concat */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { message } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import Cookies from "js-cookie";
import { BasePaginationResponse, MenuApps } from "models";
import { ACTIVE_APP, EMAIL_USER, ID_USER, locale, TOKEN_USER } from "./constant";

export type GetRoutes = { menu: MenuApps["menus"][0], menuName: string, page: "index" | "edit" | "add" | "view" };
export default class Utils {
    static charCodeA = 65;

    static convertToStringFormat(num?: number | null): string {
        if (num === null || num === undefined) return "-";
        const mergeNum = num?.toString().split(".").join("");
        return mergeNum.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    static convertToIntFormat(str?: string): number {
        if (str === null || str === undefined) return 0;
        return parseInt(str.toString().split(".").join(""), 10);
    }

    static cutText(length: number, string?: string): string {
        if (!string) return "-";
        return string?.length > length ? `${string.slice(0, length)}...` : string;
    }

    static imageSafety(image: string | undefined | null): string {
        return image ?? "/images/placeholder.png";
    }

    static currencyFormatter = (selectedCurrOpt: any) => (value: any) => {
        return new Intl.NumberFormat(locale, {
            style: "currency",
            currency: selectedCurrOpt.split("::")[1],
        }).format(value);
    };

    static currencyFormatterId = (vl: any) => {
        return new Intl.NumberFormat("id", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
        }).format(vl);
    };

    static currencyParser = (val: any) => {
        try {
            // for when the input gets clears
            if (typeof val === "string" && !val.length) {
                val = "0.0";
            }

            // detecting and parsing between comma and dot
            const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
            const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
            let reversedVal = val.replace(new RegExp(`\\${group}`, "g"), "");
            reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
            //  => 1232.21 €

            // removing everything except the digits and dot
            reversedVal = reversedVal.replace(/[^0-9.]/g, "");
            //  => 1232.21

            // appending digits properly
            const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
            const needsDigitsAppended = digitsAfterDecimalCount > 2;

            if (needsDigitsAppended) {
                reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
            }

            return Number.isNaN(reversedVal) ? 0 : reversedVal;
        } catch (error) {
            console.error(error);
        }
    };

    static currencyParserId = (val: any) => {
        try {
            if (typeof val === "string" && !val.length) {
                val = "0";
            }

            const group = new Intl.NumberFormat("id").format(1111).replace(/1/g, "");
            const decimal = new Intl.NumberFormat("id").format(1.1).replace(/1/g, "");
            let reversedVal = val.replace(new RegExp(`\\${group}`, "g"), "");
            reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
            //  => 1232.21 €

            // removing everything except the digits and dot
            reversedVal = reversedVal.replace(/[^0-9.]/g, "");
            //  => 1232.21

            // appending digits properly
            const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
            const needsDigitsAppended = digitsAfterDecimalCount > 2;

            if (needsDigitsAppended) {
                reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
            }

            return Number.isNaN(reversedVal) ? 0 : reversedVal;
        } catch (error) {
            console.error(error);
        }
    };

    static getBase64 = (file: File, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result as string));
        reader.readAsDataURL(file);
    };

    static toBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    static beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Format gambar hanya boleh jpg/jpeg/png");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Ukuran gambar tidak boleh lebih dari 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    static SignOut = () => {
        Cookies.remove(TOKEN_USER);
        Cookies.remove(EMAIL_USER);
        Cookies.remove(ID_USER);
        localStorage.removeItem(ACTIVE_APP);
    };

    static pause(time: number) {
        return new Promise((res, rej) => {
            setTimeout(() => {
                res(true);
            }, time * 1000);
        });
    }

    static async imageInfo(file: File) {
        try {
            const toBase64 = await this.toBase64(file);
            return {
                uri: URL.createObjectURL(new Blob([file], { type: "application/zip" })),
                fileBase64: toBase64 as string | ArrayBuffer | null,
                file,
            };
        } catch (e: any) {
            throw new Error("oops something went wrong converting image to base64");
        }
    }

    static toBaseTable<T extends { id: string | number }, R>(data: BasePaginationResponse<T>): BasePaginationResponse<R> {
        return {
            ...data,
            list: data.list?.map(
                (el) =>
                ({
                    ...el,
                    key: el.id,
                } as R)
            ),
        };
    }

    static createFileNameDownload({ url, text }: { url: string; text: string }): string {
        const extension = url.split('.')[url.split('.').length - 1];
        return `${text}.${extension}`;
    }

    static remainPercent(remaining: number, total: number) {
        const percent = 100
        return percent - ((total - remaining) / total * percent);
    }

    static getRandomIntRange(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static cleanObject = (obj: any) => {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
        return obj
    }

    static cleanEmptyObjFromArray = (arr: any[]) => {
        return arr.filter((obj) => obj[Object.keys(obj)[0]]);
    }

    static arrayOfObjectToObject = (arr: any[]) => {
        return arr.reduceRight((result, item) => {
            const key = Object.keys(item)[0]; // first property: a, b, c
            result[key] = item[key];
            return result;
        }, {});
    }

    static getRouteApp = ({ menu, menuName, page }: GetRoutes): string => {
        const mn = menu.menu[menuName] as any;
        return mn[page].route || "/" as string;
    }

    static jsonToCSV({ json, title, showLabel }: { json: JSON, title: string, showLabel: boolean }) {
        const arrData = typeof json !== 'object' ? JSON.parse(json) : json;

        let CSV = 'sep=,' + '\r\n\n';
        if (showLabel) {
            var row = "";
            for (var index in arrData[0]) {
                row += `${index},`;
            }

            row = row.slice(0, -1);
            CSV += `${row}\r\n`;
        }

        for (let i = 0; i < arrData.length; i++) {
            var row = "";
            for (var index in arrData[i]) {
                row += `"${arrData[i][index]}",`;
            }
            row.slice(0, row.length - 1);
            CSV += `${row}\r\n`;
        }

        if (CSV === '') {
            alert("Invalid data");
            return;
        }

        let fileName = "My Report ";
        fileName += title;

        const uri = `data:text/csv;charset=utf-8,${escape(CSV)}`;

        const link = document.createElement("a");
        link.href = uri;

        link.style.visibility = "hidden";
        link.download = `${fileName}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static jsonJurnalToCSV({ json, title, showLabel }: { json: JSON, title: string, showLabel: boolean }) {
        const arrData = typeof json !== 'object' ? JSON.parse(json) : json;

        let CSV = 'sep=,' + '\r\n\n';
        if (showLabel) {
            var row = "";
            for (var index in arrData[0]) {
                row += `${index},`;
            }

            row = row.slice(0, -1);
            CSV += `${row}\r\n`;
        }

        for (let i = 0; i < arrData.length; i++) {
            var row = "";
            for (var i2 in arrData[i]) {
                row += `"${arrData[i][i2]}",`;
                // for (var i3 in arrData[i][index]) {
                //     row += `"${arrData[i][index][i3]}",`;
                // }
            }
            row.slice(0, row.length - 1);
            CSV += `${row}\r\n`;
        }

        if (CSV === '') {
            alert("Invalid data");
            return;
        }

        let fileName = "";
        fileName += title;

        const uri = `data:text/csv;charset=utf-8,${escape(CSV)}`;

        const link = document.createElement("a");
        link.href = uri;

        link.style.visibility = "hidden";
        link.download = `${fileName}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static generateYear = (total: number): number[] => {
        const currentYear = new Date().getFullYear();
        return [...new Array(total)].map((_, i) => currentYear - i).sort((a, b) => b - a);
    }

    static stripHtml = (html: string) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    static objectsEqual = (o1: any, o2: any) =>
        Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => o1[p] === o2[p]);

    static arraysEqual = (a1: any[], a2: any[]) =>
        a1.length === a2.length && a1.every((o, idx) => this.objectsEqual(o, a2[idx]));

    static middleware = (...fns: any) => (x: any) => fns.reduce((v: any, f: any) => f(v), x);

    static findDuplicateObjectsByKey(arr: any[], key: string) {
        const seenIds = new Set();
        const duplicateObjects = [];

        for (const item of arr) {
            if (seenIds.has(item[key])) {
                duplicateObjects.push(item);
            } else {
                seenIds.add(item[key]);
            }
        }

        return duplicateObjects;
    }

}

export function toLowerSnakeCase(str: string) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1_$2')  // Add underscore between camel case transitions
        .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2') // Handle acronyms like HTMLIcon -> html_icon
        .toLowerCase(); // Convert everything to lowercase
}
/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Model from "models";

export interface UserRoleMenuRequest {
    id?: number;
    user_id: number;
    role_menu: number[];
    is_active: number;
}

export interface ListUserRoleMenu {
    user_id: number;
    email: string;
    apps_name: string;
}

export interface UserInRoleMenu {
    id: number;
    email: string;
    account_id: number;
    account: string;
}

export interface List {
    id: number;
    role_menu_id: number;
    description: string;
    apps_name: string;
    is_active: number;
    list_menu: string;
}

export interface DetailUserRoleMenu {
    user_id: number;
    email: string;
    list_role_menu: List[];
}

class UserRoleMenuService extends BaseService {
    createUserRoleMenu = "/manage/create-user-role-menu";

    getUser = "/manage/get-user";

    list = "/manage/list-user-role-menu";

    detail = "/manage/detail-user-role-menu";

    constructor() {
        super();
    }

    Detail<T extends DetailUserRoleMenu>(params: { user_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    List<T extends ListUserRoleMenu>(params: { page: any; query: any }) {
        return this.ProxyRequest<Model.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Model.BasePaginationResponse<T>>({
                url: this.list,
                config: { params },
            });
            return req;
        });
    }

    GetUser<T extends UserInRoleMenu[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getUser,
            });
            return req;
        });
    }

    CreateUserRoleMenu<T extends any>(data: UserRoleMenuRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createUserRoleMenu,
                data,
            });
            return req;
        });
    }
}

const userRoleMenuService = new UserRoleMenuService();
export default userRoleMenuService;

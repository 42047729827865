/* eslint-disable no-shadow */
import { Alert, Card, Descriptions, message, Select, Skeleton } from "antd";
import StateRender from "components/common/state-render";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import hadirAppService, { SetApproval } from "services/api-endpoints/hadir-app/hadir-app-service";
import { APPROVAL_STATUS_CUTI_DUTYTRIP_LEMBUR } from "utils/constant";

const LemburView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") as any;

    const detailQuery = useQuery(
        [hadirAppService.detailLembur, id],
        async () => {
            return (await hadirAppService.DetailLembur({ id })).data.data;
        },
        { enabled: !!id }
    );

    const setApproval = useMutation([hadirAppService.setApprovalLembur], async (data: SetApproval) => {
        return (await hadirAppService.SetApprovalLembur(data)).data.data;
    });

    const onChangeStatus = (opt: number) => {
        setApproval.mutateAsync({ id, status: opt }).then(() => {
            message.success("Success set status");
            detailQuery.refetch();
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card>
                        <Descriptions title="Detail Lembur">
                            <Descriptions.Item label="Overtime Date">{detailQuery.data?.overtime_date}</Descriptions.Item>
                            <Descriptions.Item label="Description">{detailQuery.data?.description}</Descriptions.Item>
                            <Descriptions.Item label="Start Time">{detailQuery.data?.start_time}</Descriptions.Item>
                            <Descriptions.Item label="End Time">{detailQuery.data?.end_time}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <Select
                                    className="!w-[200px]"
                                    status={(detailQuery.data?.status as any) === -1 ? "error" : ""}
                                    loading={setApproval.isLoading}
                                    onChange={onChangeStatus}
                                    defaultValue={Number(detailQuery.data?.status)}
                                    options={APPROVAL_STATUS_CUTI_DUTYTRIP_LEMBUR}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Description">{detailQuery.data?.description}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </StateRender.Data>
                <StateRender.Loading>
                    <Card>
                        <Skeleton />
                    </Card>
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default LemburView;

/* eslint-disable no-unneeded-ternary */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Collapse, CollapseProps, Divider, Form, Switch } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AccountItem, InvoiceItem } from "services/api-endpoints/accounting/jurnal-penyesuaian";
import Utils from "utils";
import { MARKET_PLACE_VALUES, TYPE_ORDER_PAYMENT } from "utils/constant";
import * as yup from "yup";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import AccountSelect from "../lib/account-select";
import InvoiceSelect from "../lib/invoice-select";

export interface AddItem {
    account: any;
    amount: any;
    sales_type?: any;
    market_place?: any;
    invoice?: any;
}

export interface AddItemT extends AddItem {
    id?: any;
    account_detail?: AccountItem;
    invoice_detail?: InvoiceItem;
    type?: any;
    canEdit?: boolean;
}

export type Props = ModalProps & {
    onSubmit: (item: AddItemT) => void;
    defaultValues?: AddItemT;
};

const schema: yup.SchemaOf<Partial<AddItem>> = yup.object().shape({
    account: yup.string().required("Account Name Required"),
    amount: yup.string().required("Amount Required"),
    sales_type: yup.string(),
    market_place: yup.string(),
    invoice: yup.string(),
});

const ModalAddItemJurnalPenyesuaian = ({ onSubmit, defaultValues, children, ...props }: Props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const buttonOpenRef = React.useRef<HTMLButtonElement>(null);
    const [account, setAccount] = React.useState<AccountItem | undefined>(undefined);
    const [invoice, setInvoice] = React.useState<InvoiceItem | undefined>(undefined);
    const [type, setType] = React.useState(!!defaultValues?.type);

    const defaultVal = {
        account: Number(defaultValues?.account),
        amount: defaultValues?.amount,
        invoice: Number(defaultValues?.invoice),
        market_place: Number(defaultValues?.market_place),
        sales_type: Number(defaultValues?.sales_type),
    };

    const [form] = Form.useForm();
    const { setValue, handleSubmit, control, watch } = useForm<AddItem>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: defaultVal,
    });

    useEffect(() => {
        if (defaultValues) {
            setType(!!defaultValues?.type);

            setValue("account", defaultVal?.account);
            setValue("amount", defaultVal?.amount);
            setValue("invoice", defaultVal?.invoice);
            setValue("market_place", defaultVal?.market_place);
            setValue("sales_type", defaultVal?.sales_type);
            form.setFieldsValue(defaultVal);

            if (buttonOpenRef.current) {
                buttonOpenRef.current.click();
            }
        }
    }, [defaultValues]);

    const salesTypeWatch = watch("sales_type");

    const onSubmitHandler = handleSubmit((data) => {
        onSubmit({
            ...data,
            id: defaultValues?.id || new Date().getTime(),
            amount: Utils.convertToIntFormat(data.amount),
            type,
            account_detail: account || defaultValues?.account_detail,
            invoice_detail: invoice || defaultValues?.invoice_detail,
        });
        if (buttonRef.current) {
            buttonRef.current?.click();
        }
    });

    const resetForm = () => {
        setAccount(undefined);
        setInvoice(undefined);

        setValue("account", "");
        setValue("amount", "");
        setValue("invoice", "");
        setValue("market_place", "");
        setValue("sales_type", "");
        form.setFieldsValue({});
        setType(false);
    };

    return (
        <ModalTemplate
            width={800}
            title="Add Item"
            {...props}
            afterClose={() => {
                resetForm();
                if (props?.afterClose) props?.afterClose();
            }}
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full">
                    <button ref={buttonRef} onClick={dt.closeModal} type="button" className="hidden">
                        close
                    </button>
                    <button ref={buttonOpenRef} onClick={dt.openModal} type="button" className="hidden">
                        open
                    </button>
                    <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="flex flex-col gap-5">
                            <div className="flex items-center gap-x-7">
                                <AccountSelect
                                    classNameForm="w-full"
                                    label="Account Name"
                                    control={control}
                                    name="account"
                                    placeholder="Account Name"
                                    onSelectedData={(val) => {
                                        setAccount(val);
                                        form.setFieldValue("invoice", "");
                                        setValue("invoice", "");
                                    }}
                                />
                                <ControlledInputText
                                    classNameForm="w-full"
                                    allowClear
                                    control={control}
                                    type="number"
                                    name="amount"
                                    placeholder="Amount"
                                    label="Amount"
                                />
                                <div className="W-[100px]">
                                    <Switch
                                        title="Type"
                                        checkedChildren="Kredit"
                                        unCheckedChildren="Debit"
                                        checked={type}
                                        onChange={(c) => setType(c)}
                                    />
                                </div>
                            </div>

                            {/* <Collapse defaultActiveKey={["0"]}>
                                <CollapsePanel header="Reference Document Sales" key="1">
                                    <div className="grid grid-cols-2 gap-x-7">
                                        <ControlledSelectInput
                                            allowClear
                                            control={control}
                                            name="sales_type"
                                            options={TYPE_ORDER_PAYMENT}
                                            placeholder="Sales Type"
                                            label="Sales Type"
                                        />
                                        <ControlledSelectInput
                                            allowClear
                                            control={control}
                                            name="market_place"
                                            options={MARKET_PLACE_VALUES}
                                            placeholder="Market Place"
                                            label="Market Place"
                                        />
                                        <InvoiceSelect
                                            type={salesTypeWatch}
                                            classNameForm="w-full"
                                            label="Invoice"
                                            control={control}
                                            onSelectedData={(val) => setInvoice(val)}
                                            name="invoice"
                                            placeholder="Invoice"
                                        />
                                    </div>
                                </CollapsePanel>
                            </Collapse> */}
                        </div>
                        <div className="flex justify-between mt-8">
                            <p />
                            <Button htmlType="submit" className="self-end" type="primary">
                                Add Item
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalAddItemJurnalPenyesuaian;

/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-new-func */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-shadow */
import React from "react";
import { MIN_TOTAL_ITEM, MenuTypes, getIdElementEntity } from "../utils/constant";
import html2canvas from "html2canvas";
import { v4 as uuidv4 } from "uuid";
import {
    Entity,
    IdEntityWithPrice,
    MaterialEntity,
    ModelEntity,
    MultipleOperatorEntity,
    OperatorEntity,
    ProcessEntity,
    VariableEntity,
} from "../utils/models";
import { message } from "antd";
import manufactureModelService, { CreateModelManufacture } from "services/api-endpoints/dashboard/manufacture/model";
import { useMutation, useQueryClient } from "react-query";

export type Entities = Entity<OperatorEntity & ProcessEntity<Entity<MaterialEntity & VariableEntity>>>;

const ManufactureContext = React.createContext({});

export const findEntity = (entities: Entities[], id: any) => entities?.find((entity) => entity?.idEntity === id);

const findEntityAndItsChildren = (entities: Entities[], entity: Entities | undefined, idEntities: string[]): any => {
    if (!entity) return;
    if (entity.type === MenuTypes.multipleOperator.type) {
        const multipleOperatorEntity = entity as MultipleOperatorEntity;
        multipleOperatorEntity.data?.children?.forEach((id) => {
            const child = findEntity(entities, id);
            findEntityAndItsChildren(entities, child, idEntities);
            if (!idEntities.includes(id)) {
                idEntities.push(id);
            }
        });
    }
    if (entity.type === MenuTypes.operator.type) {
        if (entity?.data?.child1) {
            const child1 = findEntity(entities, entity?.data?.child1) as Entity<OperatorEntity>;
            findEntityAndItsChildren(entities, child1, idEntities);
            if (!idEntities.includes(child1!.idEntity!)) {
                idEntities.push(child1!.idEntity!);
            }
        }
        if (entity?.data?.child2) {
            const child2 = findEntity(entities, entity?.data?.child2) as Entity<OperatorEntity>;
            findEntityAndItsChildren(entities, child2, idEntities);
            if (!idEntities.includes(child2!.idEntity!)) {
                idEntities.push(child2!.idEntity!);
            }
        }
    }
    if (entity.type === MenuTypes.process.type) {
        entity?.data?.children?.forEach((id) => {
            const idEntity = id as unknown as string;
            const child = findEntity(entities, idEntity) as Entities;
            findEntityAndItsChildren(entities, child, idEntities);
            if (!idEntities.includes(idEntity)) {
                idEntities.push(idEntity);
            }
        });
    }
    if (!idEntities.includes(entity!.idEntity!)) {
        idEntities.push(entity!.idEntity!);
    }
};

const findEntityParent = (idEntities: string[], entities: Entities[], entity?: Entities) => {
    if (!entity?.idEntityParent) return;
    if (entity?.idEntityParent) {
        findEntityParent(idEntities, entities, findEntity(entities, entity?.idEntityParent));
        idEntities.push(entity?.idEntityParent);
    }
};

export const changeIds = (entities: Entities[]) => {
    let tempId: string;
    let tempEntities = [...entities];
    entities?.forEach((ent) => {
        tempId = ent.idEntity!;
        const newId = uuidv4();
        tempEntities = tempEntities.map((tempEnt) => {
            const children = (() => {
                if (tempEnt.type !== MenuTypes.multipleOperator.type && tempEnt.type !== MenuTypes.process.type) return tempEnt?.data?.children;
                return tempEnt.data?.children?.map((id: unknown) => {
                    if (id === tempId) return newId;
                    return id;
                });
            })();

            return {
                ...tempEnt,
                idEntity: tempEnt?.idEntity === tempId ? newId : tempEnt?.idEntity,
                idEntityParent: tempEnt?.idEntityParent === tempId ? newId : tempEnt?.idEntityParent,
                data: {
                    ...tempEnt.data,
                    child1: tempEnt?.data?.child1 === tempId ? newId : tempEnt?.data?.child1,
                    child2: tempEnt?.data?.child2 === tempId ? newId : tempEnt?.data?.child2,
                    children,
                },
            };
        }) as Entities[];
    });
    return tempEntities;
};

const captureEntityToImage = async (idElement: string) => {
    const element = document.querySelector(idElement);
    if (!element) return "";
    const base64 = await html2canvas(element as any, { allowTaint: true, useCORS: true }).then((canvas: any) => canvas.toDataURL("image/png", 1));
    return base64;
};

export const calculateProcessPrice = (entities?: Entity<MaterialEntity & VariableEntity>[]) => {
    const sumItemPrice = entities?.reduce((price: number, curr) => {
        if (!curr?.data) return price;
        return (curr?.data?.price || 0) * (curr?.data?.total || MIN_TOTAL_ITEM) + price;
    }, 0);
    return sumItemPrice;
};

export const calculateBigProcessPrice = (processEntities: Entities[], entityPrices: { total: number; idEntity: any }[]) => {
    return processEntities.map((ent) => {
        const total =
            ent.data?.children?.reduce((sum, id) => {
                const childId = id as unknown as string;
                const childTotal = entityPrices.find((item) => item.idEntity === childId);
                if (!childTotal) return sum;
                return sum + childTotal!.total;
            }, 0) || 0;
        return {
            total,
            idEntity: ent.idEntity!,
        };
    });
};

export const calculateEntityPrice = (entity: Entities, entities: Entities[], sequenceEntityWithPrice: IdEntityWithPrice[]): any => {
    if (!entity)
        return {
            total: 0,
            idEntity: null,
        };

    if (entity.type === MenuTypes.multipleOperator.type) {
        const multipleOperatorEntity = entity as MultipleOperatorEntity;
        const resolverF = new Function(multipleOperatorEntity!.data!.resolver.arguments, multipleOperatorEntity!.data!.resolver.body);

        const sequenceTotal =
            multipleOperatorEntity.data?.children?.map((id) => {
                return calculateEntityPrice(findEntity(entities, id)!, entities, sequenceEntityWithPrice)?.total;
            }) || [];

        const result = !sequenceTotal.length ? 0 : sequenceTotal?.reduce((a, b) => resolverF(a, b));

        const total = {
            total: result,
            idEntity: entity.idEntity!,
        };
        if (!sequenceEntityWithPrice.find((item) => item.idEntity === entity?.idEntity)) {
            sequenceEntityWithPrice.push(total);
        }
        return total;
    }

    // operator type
    if (entity?.data?.child1 || entity?.data?.child2) {
        const resolverF = new Function(entity.data.resolver.arguments, entity.data.resolver.body);

        const resultResolver = resolverF(
            calculateEntityPrice(findEntity(entities, entity!.data!.child1)!, entities, sequenceEntityWithPrice).total,
            calculateEntityPrice(findEntity(entities, entity!.data!.child2)!, entities, sequenceEntityWithPrice).total
        );
        const total = {
            total: resultResolver,
            idEntity: entity.idEntity!,
        };

        if (!sequenceEntityWithPrice.find((item) => item.idEntity === entity?.idEntity)) {
            sequenceEntityWithPrice.push(total);
        }
        return total;
    }

    const total = {
        total: calculateProcessPrice(entity?.data?.children) || 0,
        idEntity: entity.idEntity!,
    };

    if (!sequenceEntityWithPrice.find((item) => item.idEntity === entity?.idEntity)) {
        sequenceEntityWithPrice.push(total);
    }
    return total;
};

export const checkIfTextIsEmpty = (entities: Entities[], id: any) => {
    const entity = findEntity(entities, id);
    return id && entity && !entity.data?.text.trim();
};

export type SaveModels = {
    parent: Entity<ModelEntity>;
    entities: Entities[];
};

const ManufactureProvider = ({ children }: { children: any }) => {
    const queryClient = useQueryClient();

    const [isMenuOpen, setIsMenuOpen] = React.useState(true);
    const [menu, setMenu] = React.useState(MenuTypes.process.id);
    const [entities, setEntities] = React.useState<Entities[]>([]);
    const [entitiesWithTotal, setEntitiesWithTotal] = React.useState<any[]>([]);
    const [processEntitiesWithTotal, setProcessEntitiesWithTotal] = React.useState<{ total: number; idEntity: string }[]>([]);
    const [activeEntitiesMenu, setActiveEntitiesMenu] = React.useState<string[]>([]);
    const [activeEntity, setActiveEntity] = React.useState<Entities | null>(null);
    const [leftSidebar, setLeftSidebar] = React.useState(false);
    const [models, setModels] = React.useState<SaveModels[]>([]);
    const [expandEntities, setExpandEntities] = React.useState<string[]>([]);
    const [currentDraggingEntity, setCurrentDraggingEntity] = React.useState<Entities>();
    const [modelEdit, setModelEdit] = React.useState("");
    const [tempEntitiesEdit, setTempEntitiesEdit] = React.useState<Entities[]>([]);

    const onEditModel = (editEntities: Entities[], idModel: string) => {
        setModelEdit(idModel);
        setTempEntitiesEdit(entities);
        setActiveEntity(null);
        setExpandEntities([]);
        setLeftSidebar(false);

        const centeringPosParent = editEntities.map((ent) => {
            if (ent?.idEntityParent) return ent;
            return {
                ...ent,
                left: window.innerWidth / 2,
                top: 200,
            };
        });

        setEntities(centeringPosParent);
    };

    const onDropEditModel = () => {
        setModelEdit("");
        setTempEntitiesEdit([]);
        setEntities(tempEntitiesEdit);
    };

    const onChangeNameEntity = (entity: Entity, name: string) => {
        setEntities((ents: any) => {
            return ents?.map((ent: any) => {
                if (ent?.idEntity !== entity?.idEntity) return ent;
                return {
                    ...ent,
                    data: {
                        ...ent?.data,
                        text: name,
                    },
                };
            });
        });
    };

    const toggleLeftSidebar = () => {
        setLeftSidebar((prev) => !prev);
    };

    const createModelManufactureMutate = useMutation([manufactureModelService.create], async (data: CreateModelManufacture) => {
        return (await manufactureModelService.Create(data)).data.data;
    });

    const saveToModel = async (entities: Entities[], entity: Entities) => {
        const tempEntities: string[] = [];

        const loaderElement = document.querySelector(`${getIdElementEntity(entity?.idEntity)} .loader-entity`);

        // check model with same name
        if (!modelEdit && models.find((model) => model.parent.data?.text.toLowerCase() === entity.data?.text.toLowerCase())) {
            message.error("There is another model with identic name!");
            return;
        }

        loaderElement?.classList.add("active");
        const base64Capture = await captureEntityToImage(getIdElementEntity(entity.idEntity!));

        findEntityAndItsChildren(entities, entity, tempEntities);
        const tempModels = entities
            ?.filter((ent) => tempEntities.includes(ent.idEntity!))
            ?.map((ent) => {
                // delete idEntityParent in parent model
                if (ent?.idEntity !== entity?.idEntity) return ent;
                return {
                    ...ent,
                    idEntityParent: null,
                    left: null,
                    top: null,
                };
            }) as Entities[];

        let tempIdEntity = entity.idEntity!;
        const newEntityIds = changeIds(tempModels);
        newEntityIds.forEach((ent) => {
            if (!ent?.idEntityParent) {
                tempIdEntity = ent.idEntity!;
            }
        });

        const model = {
            parent: {
                ...entity,
                idEntity: tempIdEntity,
                idEntityParent: null,
                top: null,
                left: null,
                data: {
                    ...entity.data,
                    preview: base64Capture,
                },
            } as SaveModels["parent"],
            entities: newEntityIds,
        };

        createModelManufactureMutate
            .mutateAsync({
                id: modelEdit || null,
                name: model.parent.data?.text,
                is_active: 1,
                process: model,
            })
            .then(async () => {
                setMenu(MenuTypes.model.id);
                setIsMenuOpen(true);

                const msg = modelEdit
                    ? `${entity.data?.text?.CapitalizeFirstLetter()} edited successfully`
                    : `${entity.data?.text?.CapitalizeFirstLetter()} save to listed model`;

                message.success(msg);

                await queryClient.refetchQueries({ queryKey: [manufactureModelService.getAll] });

                setTimeout(() => {
                    loaderElement?.classList.remove("active");
                }, 300);
            })
            .catch((e: any) => {
                message.error(e?.message);
            })
            .finally(() => {
                // if model edit
                setModelEdit("");
                setEntities(tempEntitiesEdit);
                setTempEntitiesEdit([]);
                setActiveEntity(null);
            });
    };

    const clickEntityToFocus = (entity: Entities) => {
        const findEntityFromEntities = entities.find((ent: any) => ent?.idEntity === entity?.idEntity);
        const currentEntity = { ...entity, ...findEntityFromEntities };
        const tempIdEntities: any[] = [];

        findEntityAndItsChildren(entities, currentEntity, tempIdEntities);
        setActiveEntity(currentEntity);
        setActiveEntitiesMenu(tempIdEntities);
    };

    const clickEntityToZIndex = (entity: Entities) => {
        let tempIdEntities: string[] = [];
        if (entity?.idEntityParent) {
            findEntityParent(tempIdEntities, entities, entity);
        } else {
            tempIdEntities = [entity.idEntity!];
        }

        setEntities((entities) =>
            entities?.map((ent) => {
                if (tempIdEntities.includes(ent.idEntity!)) return { ...ent, zIndex: 10 };
                return { ...ent, zIndex: 1 };
            })
        );
    };

    const deleteEntity = (entity: Entities) => {
        const tempEntityToDelete: any[] = [];
        findEntityAndItsChildren(entities, entity, tempEntityToDelete);
        setActiveEntity(null);
        setActiveEntitiesMenu([]);
        setEntities(
            (entities) =>
                entities
                    ?.filter((ent) => !tempEntityToDelete.includes(ent?.idEntity))
                    ?.map((ent) => {
                        if (ent?.idEntity !== entity?.idEntityParent) return ent;
                        return {
                            ...ent,
                            data: {
                                ...ent.data,
                                child1: ent?.data?.child1 === entity?.idEntity ? null : ent?.data?.child1,
                                child2: ent?.data?.child2 === entity?.idEntity ? null : ent?.data?.child2,
                            },
                        };
                    })
                    ?.map((ent) => ({ ...ent, zIndex: 1 })) as Entities[]
        );
    };

    const dropOperatorChildren = (data: any, placement: "child1" | "child2", attach: any, models: any[]) => {
        setEntities((entities: any[]) => {
            if (attach?.data?.children?.find((child: any) => child?.idEntity === data?.idEntity)) return entities;
            let tempEntities = [...entities];
            let tempIdEntity = data?.idEntity || uuidv4();

            if (data?.asType === MenuTypes.model.type) {
                // drop entity as model
                const modelEntities = models?.find((m: any) => m.parent?.idEntity === data?.idEntity)?.entities;
                const newEntityIds = changeIds(modelEntities);
                newEntityIds.forEach((ent: any) => {
                    if (!ent?.idEntityParent) {
                        tempIdEntity = ent?.idEntity;
                    }
                });
                tempEntities = [...tempEntities, ...newEntityIds];
            }

            if (data?.idEntity) {
                tempEntities = tempEntities
                    ?.map((ent: any) => {
                        if (ent?.idEntity !== attach?.idEntity) return ent;
                        return { ...ent, data: { ...ent.data, [placement]: tempIdEntity } };
                    })
                    ?.map((ent: any) => {
                        if (ent?.idEntity !== tempIdEntity) return ent;
                        return { ...ent, idEntityParent: attach?.idEntity };
                    });
            } else {
                tempEntities = [
                    ...tempEntities.map((ent: any) => {
                        if (ent?.idEntity !== attach?.idEntity) return ent;
                        return { ...ent, data: { ...ent.data, [placement]: tempIdEntity } };
                    }),
                    { idEntity: tempIdEntity, ...data, idEntityParent: attach?.idEntity },
                ];
            }

            // move child in same parent
            if (data?.idEntityParent && data?.idEntityParent === attach?.idEntity) {
                const movedChild = tempEntities?.map((ent: any) => {
                    if (ent?.idEntity !== attach?.idEntity) return ent;
                    return {
                        ...ent,
                        data: {
                            ...ent.data,
                            [placement === "child1" ? "child2" : "child1"]: null,
                        },
                    };
                });
                return movedChild;
            }

            // move child different parent
            if (data?.idEntityParent && data?.idEntityParent !== attach?.idEntity) {
                const movedChild = tempEntities?.map((ent: any) => {
                    if (ent?.idEntity !== data?.idEntityParent) return ent;
                    return {
                        ...ent,
                        data: {
                            ...ent.data,
                            children: ent?.data?.children?.filter((id: string) => id !== data?.idEntity),
                            child1: ent?.data?.child1 === tempIdEntity ? null : ent?.data?.child1,
                            child2: ent?.data?.child2 === tempIdEntity ? null : ent?.data?.child2,
                        },
                    };
                });
                return movedChild;
            }

            return tempEntities;
        });
    };

    React.useEffect(() => {
        if (activeEntity) {
            clickEntityToFocus(activeEntity);
        }
    }, [entities]);

    React.useEffect(() => {
        const onClickAnywhere = (e: any) => {
            if (checkIfTextIsEmpty(entities, activeEntity?.idEntity)) {
                message.error("Title can't be empty!");
                return;
            }
            setActiveEntitiesMenu([]);
            setActiveEntity(null);
        };

        document.addEventListener("click", onClickAnywhere);

        return () => {
            document.removeEventListener("click", onClickAnywhere);
        };
    }, [entities, activeEntity]);

    React.useEffect(() => {
        const sequenceEntityWithPrice: any[] = [];
        const sequenceGrandTotals: any[] = [];

        entities?.forEach((ent: any) => {
            const res = calculateEntityPrice(ent, entities, sequenceEntityWithPrice);
            sequenceGrandTotals.push(res);
        });

        setEntitiesWithTotal(sequenceEntityWithPrice);
    }, [entities]);

    React.useEffect(() => {
        const processEntities = entities.filter((ent) => ent.type === MenuTypes.process.type);
        setProcessEntitiesWithTotal(calculateBigProcessPrice(processEntities, entitiesWithTotal));
    }, [entitiesWithTotal, entities]);

    return (
        <ManufactureContext.Provider
            value={{
                leftSidebar,
                models,
                clickEntityToZIndex,
                saveToModel,
                toggleLeftSidebar,
                activeEntity,
                clickEntityToFocus,
                dropOperatorChildren,
                setActiveEntitiesMenu,
                activeEntitiesMenu,
                deleteEntity,
                menu,
                setMenu,
                entities,
                setEntities,
                entitiesWithTotal,
                setEntitiesWithTotal,
                setIsMenuOpen,
                isMenuOpen,
                currentDraggingEntity,
                setCurrentDraggingEntity,
                setModels,
                processEntitiesWithTotal,
                setExpandEntities,
                expandEntities,
                onEditModel,
                modelEdit,
                onDropEditModel,
                onChangeNameEntity,
            }}
        >
            {children}
        </ManufactureContext.Provider>
    );
};

export { ManufactureContext, ManufactureProvider };

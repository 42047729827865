import { Alert, Card, Skeleton, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import React from "react";
import { useQuery } from "react-query";
import inventoryOutService from "services/api-endpoints/dashboard-jsl/inventory-out";
import ProductTable from "modules/dashboard-jsl/inventory-out/view/product-table";
import { useSearchParams } from "react-router-dom";
import State from "components/common/state";
import RemarksTable from "modules/dashboard-jsl/inventory-out/view/remarks-table";

const InventoryOutView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const pageRemark = searchParams.get("page-remark") || 1;

    const detailIO = useQuery([inventoryOutService.detailIO, id, page], async () => {
        return (await inventoryOutService.DetailIO({ id })).data.data;
    });

    const detailProductIO = useQuery(
        [inventoryOutService.detailProductIO, id],
        async () => {
            return (await inventoryOutService.DetailProductIO({ id, page })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const remarksQuery = useQuery([inventoryOutService.getRemarks, id, pageRemark], async () => {
        return (await inventoryOutService.GetRemarks({ id, page: pageRemark })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="Detail inventory out" />
            <Card className="">
                <State data={detailIO.data} isLoading={detailIO.isLoading} isError={detailIO.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-6">
                                    <p className="capitalize m-0">
                                        document no <br /> <span className="underline italic m-0">{detailIO.data?.doc_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        requester <br /> <span className="underline italic m-0">{detailIO.data?.requester}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br /> <span className="underline italic m-0">{detailIO.data?.status ? "Open" : "Close"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        notes <br /> <span className="underline italic m-0">{detailIO.data?.note}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailIO.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable fetcher={detailProductIO} />,
                        },
                        {
                            label: `Remarks`,
                            key: "2",
                            children: <RemarksTable fetcher={remarksQuery} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default InventoryOutView;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation } from "react-query";
import masterBpService, { CreateBP } from "services/api-endpoints/accounting/master-bp";
import ControlledInputNumber from "components/form/controlled-inputs/controlled-input-number";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { PARTNER_AGENT, PARTNER_TYPE } from "utils/constant";
import BpSelect from "modules/accounting/master-bp/lib/bp-select";
import PaymentTermSelect from "modules/accounting/master-bp/lib/payment-term-select";

const schema: yup.SchemaOf<Partial<CreateBP>> = yup.object().shape({
    name: yup.string().required("Name required"),
    partner_code: yup.string().required("Partner code required"),
    partner_type: yup.number().required("Partner type required"),
    credit_limit: yup.number().required("Creadit limit required"),
    tax_id: yup.string().required("Tax ID required"),
    payment_term_id: yup.number().required("Payment Term required"),
    business_partner_id: yup.number().nullable(),
});

const MasterBPAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue, setError } = useForm<CreateBP>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const partnerTypeWatch = watch("partner_type");

    const createMutation = useMutation(
        [masterBpService.create],
        async (data: CreateBP) => {
            return (await masterBpService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Business Partner Created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onPartnerTypeSelect = () => {
        form.setFieldValue("business_partner_id", null);
        setValue("business_partner_id", null);
    };

    const onSubmitHandler = handleSubmit((data) => {
        if (data.partner_type === PARTNER_AGENT && !data.business_partner_id) {
            setError("business_partner_id", {
                message: "Business Partner Required",
                type: "required",
            });
            return;
        }
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new Business Partner"
                rightAddition={() => (
                    <Space>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <div className="w-[50%]">
                <Card>
                    <Form form={form} disabled={createMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                        <h1 className="capitalize font-semibold text-gray-500 mb-4">Business Partner information</h1>
                        <ControlledInputText allowClear control={control} name="name" placeholder="Name" />
                        <ControlledInputText allowClear control={control} name="partner_code" placeholder="Partner Code" />
                        <ControlledInputNumber control={control} name="credit_limit" placeholder="Credit Limit" />
                        <ControlledInputText allowClear control={control} name="tax_id" placeholder="Tax ID" />

                        <ControlledSelectInput
                            onSelect={onPartnerTypeSelect}
                            control={control}
                            name="partner_type"
                            options={PARTNER_TYPE}
                            placeholder="Partner Type"
                        />
                        <BpSelect
                            disabled={partnerTypeWatch !== PARTNER_AGENT}
                            control={control}
                            name="business_partner_id"
                            placeholder="Business Partner"
                        />
                        <PaymentTermSelect control={control} name="payment_term_id" placeholder="Payment Term" />
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default MasterBPAdd;

/* eslint-disable react/no-array-index-key */
import moment from "moment";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { ListDetail, POMDetailData } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = {
    data?: POMDetailData | null;
};

const PrintPOM = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const [currentTime, setCurrentTime] = useState();

    const itemPerPage = 25;

    const listDetailGroup = (): ListDetail[][] => {
        if (!data?.detail) return [];

        const flatten = [...data.detail]?.reduce((arr: any[], curr: any) => {
            return [...arr, curr];
        }, []);

        const temp: any[] = [];
        const totalPage = Math.ceil(flatten.length / itemPerPage);
        [...new Array(totalPage)].forEach(() => {
            temp.push(flatten.splice(0, itemPerPage));
        });

        return temp;
    };

    const list = listDetailGroup();

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            {list?.map((items, page) => (
                <div className="w-full bg-white p-5 break-after-page">
                    {page === 0 ? (
                        <>
                            <div className="w-full flex items-end justify-between">
                                <div className="max-w-[55%]">
                                    <h1 className="text-2xl uppercase m-0">{data?.account_name || "-"}</h1>
                                    <p className="text-xs m-0">{data?.account_address || "-"}</p>
                                </div>
                                <h2 className="text-3xl font-medium m-0 capitalize">Purchasing Order</h2>
                            </div>
                            <div className="w-full h-[2px] bg-gray-400 mt-2" />
                            <div className="w-full h-[2px] bg-gray-400 mt-1" />
                            <div className="w-full grid grid-cols-3 mt-4">
                                <div className="">
                                    <table>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Doc No</td>
                                            <td>{` : ${data?.po_no}`}</td>
                                        </tr>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px]">Tanggal</td>
                                            <td> : {moment().format("dddd DD MM yyyy")}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="flex justify-center">
                                    <QRCode size={70} value={data?.po_no || ""} />
                                </div>
                                <div className="">
                                    <table>
                                        <tr className="text-xs">
                                            <td className="min-w-[60px] float-start">Notes</td>
                                            <td>{` : ${data?.note || "-"} `}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : null}
                    <div className="flex flex-col mt-5">
                        <table border={1} cellPadding={1} cellSpacing={1}>
                            <thead>
                                <th>No</th>
                                <th>Code1</th>
                                <th>Code WIP</th>
                                <th>Qty WIP</th>
                            </thead>
                            <tbody>
                                {items.map((parent, parentIndex) => {
                                    if (!parent?.child || !parent.child?.length) {
                                        return (
                                            <tr key={parentIndex}>
                                                <td className="text-center align-middle" rowSpan={parent.child?.length || 0}>
                                                    {parentIndex + 1}
                                                </td>
                                                <td className="text-center align-middle" rowSpan={parent.child?.length || 0}>
                                                    {parent.note}
                                                </td>
                                                <td className="text-center align-middle">{"  "}</td>
                                                <td className="text-center align-middle">{"  "}</td>
                                            </tr>
                                        );
                                    }
                                    return parent.child?.map((child, childIndex) => {
                                        const isParent = childIndex === 0;
                                        return (
                                            <tr key={child.product_id}>
                                                {isParent && (
                                                    <>
                                                        <td className="text-center align-middle" rowSpan={parent.child?.length || 0}>
                                                            {parentIndex + 1}
                                                        </td>
                                                        <td className="text-center align-middle" rowSpan={parent.child?.length || 0}>
                                                            {parent.note}
                                                        </td>
                                                    </>
                                                )}
                                                <td className="text-center align-middle">{child.code1}</td>
                                                <td className="text-center align-middle">{child.qty}</td>
                                            </tr>
                                        );
                                    });
                                })}
                            </tbody>
                        </table>
                        <p className="self-end mt-2">{`Halaman ${page + 1} dari ${listDetailGroup.length}`}</p>
                    </div>
                    {page === list.length - 1 ? (
                        <div className="flex items-start justify-between">
                            {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                                <div className="flex flex-col items-center" key={i}>
                                    <p className="capitalize font-medium text-xs">{el}</p>
                                    <p className="text-3xl m-0 mt-1">(...........)</p>
                                </div>
                            ))}
                            <div className="">
                                <table className="text-xs">
                                    <tr>
                                        <td className="min-w-[100px]">Catatan</td>
                                        <td className="max-w-[200px]">: </td>
                                    </tr>
                                    <tr>
                                        <td className="min-w-[100px]">Print</td>
                                        <td>
                                            : - <br />
                                            {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
});

export default PrintPOM;

/* eslint-disable no-unneeded-ternary */
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, message, Select } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { SelectOption } from "models";
import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";
import suppliesService, { NameSupplies } from "services/api-endpoints/purchasing/supplies";
import * as yup from "yup";

export interface AddItem {
    type_of_supplies_id: any;
    type_name: any;
    product_id: any;
    product_name: any;
    description?: any;
    qty?: any;
    uom_id?: any;
    uom_name?: any;
    note?: any;
}

export interface AddItemT extends AddItem {
    id?: any;
    canEdit?: boolean;
}

export type Props = ModalProps & {
    selected: ChildRequest[];
    setSelected?: Dispatch<SetStateAction<ChildRequest[]>>;
};

const schema: yup.SchemaOf<Partial<AddItem>> = yup.object().shape({
    type_of_supplies_id: yup.number().required("Type Item Required"),
    type_name: yup.string(),
    product_id: yup.array().required("Name Item Required"),
    product_name: yup.string(),
    description: yup.string(),
    qty: yup.string().required("Quantity Name Required"),
    uom_id: yup.number().required("UOM Item Required"),
    uom_name: yup.string(),
    note: yup.string(),
});

const ModalAddSupplies = ({ children, selected, setSelected, ...props }: Props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const buttonOpenRef = React.useRef<HTMLButtonElement>(null);

    const [form] = Form.useForm();
    const { setValue, handleSubmit, control, watch } = useForm<AddItem>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchTypeId = watch("type_of_supplies_id");

    const getTypeSupplies = useQuery([suppliesService.getSuppliesType], async () => {
        return (await suppliesService.GetSuppliesType()).data.data?.map((el) => ({ label: el.type_name, value: el.type_id } as SelectOption));
    });

    const getNameSupplies = useQuery(
        [suppliesService.getNameSupplies, watchTypeId],
        async () => {
            return (await suppliesService.GetName({ type: watchTypeId })).data.data.map(
                (el) => ({ ...el, label: `${el.name} - ${el.code}`, value: el.supplies_id } as SelectOption & NameSupplies)
            );
        },
        {
            enabled: !!watchTypeId,
        }
    );

    // const pickedProductId = selected.map((el) => Number(el.product_id));
    // const availProducts = getNameSupplies.data?.map((el) => ({
    //     label: `${el.name} - ${el.code || ""} (${el.uom_code})`,
    //     value: el.supplies_id!,
    //     disabled: pickedProductId.includes(Number(el.supplies_id!)),
    // }));

    const getProduct = (key: string) => {
        const product = [...(getNameSupplies.data || [])].find((p) => p.supplies_id === (key as any));
        return product;
    };

    const onSelect = (values: any) => {
        const product = getProduct(values);
        if (!product) {
            message.error("Product not found");
            return;
        }
        if (setSelected)
            setSelected((prev) => [
                ...prev,
                {
                    product_id: product.supplies_id,
                    product_name: product.name,
                    qty: 1,
                    type_id: watchTypeId,
                    type_name: getTypeSupplies.data?.find((v) => v.value === watchTypeId)?.label,
                    uom_id: product.uom_id,
                    uom_name: product.uom_code,
                    note: "",
                } as ChildRequest,
            ]);
    };

    const onDeSelect = (values: any) => {
        // const product = getProduct(values);
        // if (!product) {
        //     message.error("Product not found");
        //     return;
        // }
        if (setSelected) setSelected((prev) => [...prev].filter((p) => p.product_id !== (values as any)));
    };

    return (
        <ModalTemplate width={800} title="Add Item Supplies" {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full">
                    <button ref={buttonRef} onClick={dt.closeModal} type="button" className="hidden">
                        close
                    </button>
                    <button ref={buttonOpenRef} onClick={dt.openModal} type="button" className="hidden">
                        open
                    </button>

                    <Form form={form} layout="vertical">
                        <div className="grid grid-cols-2 gap-x-5">
                            <ControlledSelectInput
                                classNameForm="col-span-1"
                                allowClear
                                control={control}
                                name="type_name"
                                placeholder="Type Supplies"
                                label="Type Supplies"
                                loading={getTypeSupplies.isLoading}
                                options={getTypeSupplies.data}
                                onChange={(val) => {
                                    setValue("type_of_supplies_id", val);
                                    setValue("type_name", getTypeSupplies.data?.find((v) => v.value === val)?.label);
                                }}
                            />
                            <div className="w-full col-span-2">
                                <p>Item Name</p>
                                <Select
                                    value={selected.map((val) => val.product_id)}
                                    className="w-full"
                                    placeholder="Item Name"
                                    showSearch
                                    mode="multiple"
                                    loading={getNameSupplies.isLoading}
                                    options={getNameSupplies.data}
                                    disabled={!watchTypeId}
                                    onSelect={onSelect}
                                    onDeselect={onDeSelect}
                                    optionFilterProp="label"
                                    filterOption={(input, option) => {
                                        return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                    }}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalAddSupplies;

import { CreateDataModelType, EditDataModelType } from "modules/master-data/model-type/models";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { useForm } from "react-hook-form";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import useProgressUpload from "hooks/useProgressUpload";
import IsActiveSelect from "modules/master-data/model-type/lib/is-active-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import modelTypeService from "services/api-endpoints/dashboard/master-data/model-type";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";

const schema: yup.SchemaOf<Partial<CreateDataModelType>> = yup.object().shape({
    model_type_name: yup.string().required("Brand name required"),
    model_type_code: yup.string().required("Brand code required"),
    is_active: yup.number().required("Is Active required"),
});

const ModelTypeEdit = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<CreateDataModelType>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const editMutation = useMutation(
        [modelTypeService.edit],
        async (data: EditDataModelType) => {
            return (await modelTypeService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Model Type Edited");
                navigate(-1);
            },
        }
    );

    const detailQuery = useQuery(
        [modelTypeService.detail, id],
        async () => {
            return (await modelTypeService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("model_type_name", data.model_type_name);
                setValue("model_type_code", data.model_type_code);
                setValue("is_active", data.is_active);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
        } as EditDataModelType;
        editMutation.mutate(parseData);
    });
    return (
        <div>
            <div className="w-full flex flex-col gap-6">
                <ToolbarAction
                    title="edit model type"
                    rightAddition={() => (
                        <Space>
                            <>{progress}</>
                            <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        </Space>
                    )}
                />
                <div className="max-w-2xl">
                    <Card>
                        <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                            {(state) => (
                                <>
                                    <State.Data state={state}>
                                        <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                            <h1 className="capitalize font-semibold text-gray-500 mb-4">Type Model Information</h1>
                                            <ControlledInputText
                                                control={control}
                                                label="Model Type Name"
                                                labelCol={{ span: 24 }}
                                                name="model_type_name"
                                                placeholder="Model Type Name"
                                                style={{ transform: "translateY(-6px)" }}
                                            />
                                            <ControlledInputText
                                                control={control}
                                                label="Model Type Code"
                                                labelCol={{ span: 24 }}
                                                name="model_type_code"
                                                placeholder="Model Type Code"
                                                style={{ transform: "translateY(-6px)" }}
                                            />
                                            <IsActiveSelect
                                                control={control}
                                                label="Is Active"
                                                labelCol={{ span: 24 }}
                                                name="is_active"
                                                placeholder="Is Active"
                                                style={{ transform: "translateY(-6px)" }}
                                            />
                                        </Form>
                                    </State.Data>

                                    <State.Loading state={state}>
                                        <div className="w-full grid grid-cols-2 gap-5">
                                            <Skeleton paragraph={{ rows: 3 }} active />
                                        </div>
                                    </State.Loading>
                                    <State.Error state={state}>
                                        <Alert type="error" message={(detailQuery.error as any)?.message} />
                                    </State.Error>
                                </>
                            )}
                        </State>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default ModelTypeEdit;

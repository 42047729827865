import { Input, Form, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (type: number) => void;
};

const ModalNewPo = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();

    const onWithPoHandler = () => {
        onSubmit(2);
        closeRef.current?.click();
    };
    const onWithOutPoHandler = () => {
        onSubmit(1);
        closeRef.current?.click();
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col gap-1 items-end">
                            <h4 className="!w-full font-semibold text-gray-500">
                                Dikarenakan ada item yang tidak bisa diproses, Apakah anda yakin untuk melanjutkan SO ini.?
                            </h4>
                            <div className="grid grid-cols-2 gap-4">
                                <Button type="default" className="items-center" onClick={onWithOutPoHandler}>
                                    Tanpa PO Baru
                                </Button>
                                <Button type="primary" className="items-center" onClick={onWithPoHandler}>
                                    Dengan PO Baru
                                </Button>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalNewPo;

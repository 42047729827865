import { Alert, Button, Card, Skeleton, Space, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import OrderTableView from "modules/master-data/return-order/view/order-table";
import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import returnOrderService from "services/api-endpoints/dashboard/master-data/return-order";
import { RETURN_ORDER_STATUS } from "utils/constant";

const APPROVE_STATUS = 2;
const REJECTED_STATUS = -1;

const ReturnOrderView = () => {
    const { updateAccess } = useContext(StateContext);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const getReason = useQuery([returnOrderService.getReason], async () => {
        return (await returnOrderService.GetReason()).data.data;
    });

    const detailQuery = useQuery(
        [returnOrderService.getDetailReturnOrder, id],
        async () => {
            return (await returnOrderService.GetDetailReturnOrder({ id })).data.data;
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const setStatusApprove = useMutation(
        [returnOrderService.setStatus, id],
        async () => {
            return (await returnOrderService.SetStatus({ id, status: APPROVE_STATUS })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Status has been set approve");
                navigate(-1);
            },
        }
    );

    const setStatusReject = useMutation(
        [returnOrderService.setStatus, id],
        async () => {
            return (await returnOrderService.SetStatus({ id, status: REJECTED_STATUS })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Status has been set reject");
                navigate(-1);
            },
        }
    );

    const approveHandler = () => {
        setStatusApprove.mutate();
    };

    const rejectHandler = () => {
        setStatusReject.mutate();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail return order"
                rightAddition={
                    updateAccess && detailQuery.data?.status === 1
                        ? () => (
                              <Space>
                                  <Button loading={setStatusApprove.isLoading} onClick={approveHandler} className="!flex !items-center">
                                      Approve
                                  </Button>
                                  <Button loading={setStatusReject.isLoading} onClick={rejectHandler} className="!flex !items-center" danger>
                                      Reject
                                  </Button>
                              </Space>
                          )
                        : () => <div />
                }
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-start gap-x-10">
                                    <p className="capitalize m-0">
                                        Return order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.return_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Reason to return <br />
                                        <span className="underline italic m-0">
                                            {getReason.data?.find((reason) => reason.reason_id === detailQuery.data?.reason_id)?.reason_name}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Status <br />
                                        <span className="underline italic m-0">
                                            {RETURN_ORDER_STATUS.find((status) => status.value === detailQuery.data?.status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert type="error" message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
            <OrderTableView fetcher={detailQuery} onClickLink={() => {}} />
        </div>
    );
};

export default ReturnOrderView;

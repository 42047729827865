/* eslint-disable no-shadow */
import { message, Select } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { SelectOption } from "models";
import FilterPrice from "modules/master-data/price/index/fitler";
import { TDataPrice } from "modules/master-data/price/models";
import PriceTable from "modules/master-data/price/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";

const filter = ["product_name", "product_code", "molding_code", "brand_id", "model_id", "model_type_id", "model_type_name"];

const Price = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const warehouse = searchParams.get("warehouse_id");

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const changeWarehouse = ({ id, page }: { id: any; page: string }) => {
        filter.forEach((ftr) => searchParams.delete(ftr));
        searchParams.set("warehouse_id", id);
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.listAccount, warehouse],
        async () => {
            return (await priceService.ListAccount()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            onSuccess: (data) => {
                if (warehouse) return;
                changeWarehouse({ id: data[0]?.value, page: "1" });
            },
            enabled: warehouse !== undefined,
        }
    );

    const getList = useQuery(
        [priceService.getAll, page, searchValue, filterValue, isFilter, isSearch, warehouse],
        async () => {
            if (isSearch) return (await priceService.Search({ page, param_search: searchValue.query, warehouse_id: warehouse })).data.data;
            if (isFilter) return (await priceService.Filter({ ...(filterValue as any), warehouse_id: warehouse })).data.data;
            return (await priceService.GetAll({ page, warehouse_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    const deleteMutation = useMutation(async (data: TDataPrice) => (await priceService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataPrice>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterPrice filter={filter}>
                {(dt) => (
                    <Toolbar
                        prefixAddition={() => (
                            <Select
                                className="!w-[300px]"
                                value={warehouse ? Number(warehouse) : undefined}
                                loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                                placeholder="Warehouse"
                                onChange={(val) => changeWarehouse({ id: val, page: "1" })}
                            >
                                {getWarehouse.data?.map((option) => (
                                    <Select.Option value={option.value}>
                                        <span className="flex whitespace-pre-wrap !w-[300px]">{option.label}</span>
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                        placeholder="Product Name / Product Code / Molding Code / Brand / Model"
                        defaultParams={["warehouse_id"]}
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterPrice>
            <PriceTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Price;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface SuppliesWH {
    warehouse_id: number;
    warehouse_name: string;
}

export interface SuppliesList {
    supplies_id: number;
    name: string;
    type_name: string;
    type_code: string;
    quantity: number;
}

export interface SuppliesInfo {
    name: string;
    type_name: string;
    type_code: string;
}

export interface SuppliesListHistory {
    id: number;
    doc_id: number;
    doc_no: string;
    doc_type_id: number;
    doc_type: string;
    qty: number;
    qty_after: number;
    qty_before: number;
    created_by: string;
    created_at: string;
}

export interface SuppliesHistory {
    total_data: number;
    total_page: number;
    current_page: number;
    total_qty: number;
    list_history: SuppliesListHistory[];
}

export interface DocHistory {
    created_at: string;
    created_by: string;
    doc_detail_id: number;
    supplies_name: string;
    type_name: string;
    type_code: string;
    doc_no: string;
    qty: number;
}

export interface DocDetail {
    list_history: DocHistory[];
    current_page: number;
    total_data: number;
    total_page: number;
}

class SuppliesQuantityService extends BaseService {
    getSuppliesWH = "/get-warehouse-supplies";

    getAll = "/quantity-supplies/all-quantity";

    getSuppliesInfo = "/quantity-supplies/get-supplies-info";

    getHistory = "/quantity-supplies/get-supplies-history";

    docDetail = "/quantity-supplies/get-doc-detail";

    constructor() {
        super();
    }

    DocDetail<T extends DocDetail>(param: { doc_id: any; page: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.docDetail,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetHistory<T extends SuppliesHistory>(param: { suppliesID: any; page: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getHistory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetSuppliesInfo<T extends SuppliesInfo>(param: { suppliesID: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSuppliesInfo,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetSuppliesWH<T extends SuppliesWH[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSuppliesWH,
            });
            return req;
        });
    }

    GetAll<T extends SuppliesList>(param: { param_search: any; page: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const suppliesQuantityService = new SuppliesQuantityService();
export default suppliesQuantityService;

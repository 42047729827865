import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import { CreateDataModel } from "../models";

export function withParentName<T extends ControlledInputSelectProps<CreateDataModel>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const parentNameQuery = useQuery([modelService.parentName], async () => {
            const req = await modelService.ParentName({ parent_name: "" });
            return req.data.data?.map(
                (el) => ({ label: el?.parent_name === "EMPTY" ? "-" : el?.parent_name || "", value: el.parent_id || "" } as SelectOption)
            );
        });
        return (
            <Component
                showSearch
                filterOption={(input, option) => {
                    return (option?.children ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                }}
                {...props}
                optionFilterProp="children"
                loading={parentNameQuery.isLoading}
                options={parentNameQuery.data || []}
            />
        );
    };
}

const ParentNameSelect = withParentName(ControlledSelectInput);

export default ParentNameSelect;

import { Button, Card, message, Select, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiSave, BiMessageAdd } from "react-icons/bi";
import React, { useState } from "react";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import { MdAdd } from "react-icons/md";
import ProductTable from "modules/dashboard-jsl/sales-order/add/product-table";
import { useNavigate } from "react-router-dom";
import salesOrderService, { CreateDetailProduct, CreateSOData, ProductPO, SendNotifData } from "services/api-endpoints/dashboard-jsl/sales-order";
import { SelectOption } from "models";
import ModalPOTable from "modules/dashboard-jsl/sales-order/add/modal-po-table";
import { getDocumentRoute } from "utils/routes";
import ModalNotify from "components/modal/notify_modal";
import ModalNewPo from "components/modal/new_po_modal";
import { HandlerProps } from "components/modal/template-modal";
import purchaseOrderExtService from "services/api-endpoints/dashboard-jsl/purchase-order-ext";

const SalesOrderAdd = () => {
    const navigate = useNavigate();
    const [productsPO, setProductsPO] = useState<ProductPO[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);
    const [warehouse, setWarehouse] = useState<number | null>(null);
    const [paymentId, setPaymentId] = useState<number | null>(null);

    const getHeaderPOQuery = useQuery([salesOrderService.getHeaderSO], async () => {
        return (await salesOrderService.GetHeaderSO()).data.data;
    });

    const requestFromQuery = useQuery([purchaseOrderExtService.listBp], async () => {
        return (await purchaseOrderExtService.ListBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    const requestPaymentTerm = useQuery([salesOrderService.paymentTerm], async () => {
        return (await salesOrderService.PaymentTerm()).data.data?.map(
            (el) => ({ label: el.payment_term_code, value: el.payment_term_id } as SelectOption)
        );
    });

    const draftSoMutation = useMutation(
        [salesOrderService.draftSO],
        async (data: CreateSOData) => {
            return (await salesOrderService.DraftSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order draft created");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const createSoMutation = useMutation(
        [salesOrderService.createSO],
        async (data: CreateSOData) => {
            return (await salesOrderService.CreateSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order created");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const sendNotifMutation = useMutation(
        [salesOrderService.sendNotif],
        async (data: SendNotifData) => {
            return (await salesOrderService.SendNotif(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Send Notify To User Success");
            },
        }
    );

    const onFilter: ProductPO[] = productsPO.filter((el) => el.qty !== el.qty_request);

    const createSOData: CreateSOData = {
        type: 1,
        bp_id: warehouse!,
        payment_term_id: paymentId,
        child_document: [...new Set(productsPO?.map((p) => p.po_id!) || [])],
        detail_product: [
            ...(productsPO?.map((p) => ({ po_id: p.po_id, product_id: p.product_id, qty: p.qty, price: p.price } as CreateDetailProduct)) || []),
        ],
    };

    const onSaveHandler = (typeData: number) => {
        const saveData: CreateSOData = {
            type: typeData,
            bp_id: warehouse!,
            payment_term_id: paymentId,
            child_document: [...new Set(productsPO?.map((p) => p.po_id!) || [])],
            detail_product: [
                ...(productsPO?.map((p) => ({ po_id: p.po_id, product_id: p.product_id, qty: p.qty, price: p.price } as CreateDetailProduct)) || []),
            ],
        };
        createSoMutation.mutate(saveData);
    };

    const onSave1Handler = () => {
        createSoMutation.mutate(createSOData);
    };
    const onDraftHandler = () => {
        draftSoMutation.mutate(createSOData);
    };

    const onSendNotifHandler = (value: string) => {
        const notifData: SendNotifData = {
            account_id: warehouse!,
            message: value,
        };
        sendNotifMutation.mutate(notifData);
    };

    const onChangePayment = (value: number) => {
        setPaymentId(value);
    };

    const onRemoveProduct = (product: ProductPO) => {
        setProductsPO((prev) => {
            return prev
                .map((el) => {
                    if (el.product_id === product.product_id && el.po_no === product.po_no)
                        return {
                            po_id: product.po_id,
                            po_no: product.po_no,
                            product_id: product.product_id,
                            product_name: product.product_name,
                            code: product.code,
                            price: product.price,
                            product_price: product.product_price,
                            qty: 0,
                            qty_request: product.qty_request,
                        } as ProductPO;
                    return el;
                })
                .filter((el) => el.product_id);
        });
    };

    const onChangeWarehouse = (value: number) => {
        setSelectedPO([]);
        setProductsPO([]);
        setWarehouse(value);
    };

    const onProductPO = (data: ProductPO[]) => {
        setProductsPO([]);
        setProductsPO((prev) => {
            const newData = data.map((el) => ({ ...el, qty_request: el.qty! }));
            if (prev.length === 0) return newData;
            const setNewData = newData.filter(
                (product) => !prev.find((prevProduct) => prevProduct.product_id === product.product_id && prevProduct.po_no === product.po_no)
            );
            return [...prev, ...setNewData];
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new sales order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={draftSoMutation.isLoading}
                            disabled={productsPO.length === 0 || paymentId === null}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <ModalNewPo title="Konfirmasi" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button
                                    onClick={onFilter.length === 0 ? onSave1Handler : dt.openModal}
                                    type="primary"
                                    className="!flex !items-center w-fit"
                                    disabled={productsPO.length === 0 || paymentId === null}
                                >
                                    <BiSave className="mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNewPo>
                    </>
                )}
            />
            <Card className="">
                <State data={getHeaderPOQuery.data} isLoading={getHeaderPOQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Sales order no <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.so_number}</span>
                                    </p>
                                    <div className="">
                                        <div className="capitalize m-0">
                                            request from <br />
                                            <Select
                                                value={warehouse}
                                                className="!w-[300px]"
                                                loading={requestFromQuery.isLoading}
                                                options={requestFromQuery.data || []}
                                                onChange={onChangeWarehouse}
                                                showSearch
                                                placeholder="Request From"
                                            />
                                        </div>
                                    </div>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Term Of Payment <br />
                                        <Select
                                            value={paymentId}
                                            className="!w-[200px]"
                                            loading={requestPaymentTerm.isLoading}
                                            options={requestPaymentTerm.data || []}
                                            onChange={onChangePayment}
                                            showSearch
                                            placeholder="Request From"
                                        />
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <ModalPOTable onProductPO={onProductPO} warehouse={warehouse} selectedPO={selectedPO} setSelectedPO={setSelectedPO}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal} disabled={warehouse === null}>
                        <MdAdd className="mr-2" />
                        Data PO
                    </Button>
                )}
            </ModalPOTable>
            <ModalNotify title="Send Notify To User" onSubmit={onSendNotifHandler}>
                {(dt) => (
                    <Button onClick={dt.openModal} type="primary" className="!flex !items-center w-fit" disabled={productsPO.length === 0}>
                        <BiMessageAdd className="mr-2" />
                        Notify To User
                    </Button>
                )}
            </ModalNotify>
            <ProductTable list={productsPO} setList={setProductsPO} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default SalesOrderAdd;

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { Locator } from "services/api-endpoints/dashboard/master-data/material-movement";
import { SearchProduct } from "../models";

export type SelectOptionCustom = SelectOption & Locator;

export interface Props extends ControlledInputSelectProps<SearchProduct> {
    onSelectedData?: (id: number, data: SelectOptionCustom[]) => void;
}

export function withLocatorFrom<T extends Props>(Component: React.ComponentType<T>) {
    return (props: T) => {
        return <Component {...props} optionFilterProp="children" />;
    };
}

const LocatorSelect = withLocatorFrom(ControlledSelectInput);

export default LocatorSelect;

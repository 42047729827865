import React from "react";
import { List, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { EDIT_PATH, VIEW_PATH } from "utils/constant";
import { BasePaginationResponse } from "models";
import ButtonAccessView from "components/button/button-access-view";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import { TDataSalesOrder } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: TDataSalesOrder) => void;
};

const SalesOrderTable = <T extends TDataSalesOrder>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: TDataSalesOrder) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const viewHandler = (data: TDataSalesOrder) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "PO No",
            dataIndex: "po_no",
            render: (_, record) => (
                <List size="small" bordered dataSource={record.po_no} renderItem={(item) => <List.Item className="text-xs">{item}</List.Item>} />
            ),
        },
        {
            title: "SO No",
            dataIndex: "so_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Request To",
            dataIndex: "request_to",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status Order",
            dataIndex: "status_order",
            render: (text) => <p className="capitalize m-0">{!text ? "Draft" : "Submitted"}</p>,
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: (_, record) => <p className="capitalize m-0">{record.grand_total?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Date",
            dataIndex: "date",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "150px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.status_order ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : (
                        <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                        // <>
                        //     <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        // </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            scroll={{ x: 1500 }}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default SalesOrderTable;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBPDeliveryOrder from "modules/dashboard-jsl/bp-delovery-order/filter";
import BPGoodsReceiptTable from "modules/dashboard-jsl/bp-goods-receipt/index-table";
import PenerimaanIndexTable from "modules/dashboard-jsl/penerimaan-barang/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bpGoodsReceiptService from "services/api-endpoints/dashboard/master-data/bp-goods-receipt";
import penerimaanBarangService from "services/api-endpoints/dashboard/master-data/penerimaan-barang";

const filter = ["do_no", "inv", "bp_id"];

const PenerimaanBarang = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([penerimaanBarangService.getAll, page, searchValue, isSearch, isFilter, filterValue], async () => {
        return (await penerimaanBarangService.GetAll({ start_date: "", end_date: "", page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} />
            <PenerimaanIndexTable fetcher={getList} />
        </div>
    );
};

export default PenerimaanBarang;

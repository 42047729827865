import moment from "moment";
import { ListStockResponse } from "services/api-endpoints/dashboard/master-data/quantity";
import { FORMAT_DATE_1_TIME } from "utils/constant";
import XLSX from "xlsx-js-style";

const downloadStock = ({ list, title }: { list: ListStockResponse[]; title: string }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const areaColumn = () => {
        if (!list) return [];
        return list
            .map((el) => el.list_stock!.map((stock) => ({ v: stock.area_code, ...headerStyle })))
            .flat()
            .reduce((arr: any, curr) => {
                if (arr.find((ar: any) => ar.v === curr.v)) return arr;
                return [...arr, curr];
            }, []);
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Product Name", ...headerStyle },
        { v: "Product Code", ...headerStyle },
        { v: "Molding Code", ...headerStyle },
        { v: "Product ID", ...headerStyle },
        { v: "UOM", ...headerStyle },
        ...areaColumn(),
    ];

    const rows: any[] = [];
    list?.forEach((el, listIndex) => {
        const row = [
            { v: listIndex + 1, t: "s" },
            { v: el.name, t: "s" },
            { v: el.code1, t: "s" },
            { v: el.molding_code, t: "s" },
            { v: el.product_id, t: "s" },
            { v: el.uom_name, t: "s" },
            ...areaColumn().map((col: any) => ({
                v: el.list_stock?.find((st) => st.area_code === col.v)?.qty,
                t: "s",
            })),
        ];
        rows.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...rows]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}-${moment().format(FORMAT_DATE_1_TIME)}.xlsx`);
};

export default downloadStock;

import { Input, Form, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: () => void;
    isSameProduct: boolean;
};

const ModalEditPD = ({ onSubmit, isSameProduct, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();

    const onSubmitHandler = () => {
        onSubmit();
        closeRef.current?.click();
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col items-end">
                            {isSameProduct ? (
                                <>
                                    <h4 className="m-0 !w-full font-semibold">Anda Yakin Simpan Data Picking Detail.?</h4>
                                </>
                            ) : (
                                <>
                                    <h4 className="m-0 !w-full font-semibold">
                                        Dikarenakan ada selisih jumlah item di Picking Detail, Akan Di buat SO baru.
                                    </h4>
                                </>
                            )}
                            <Button type="primary" className="items-center mt-4" onClick={onSubmitHandler}>
                                Save Edit
                            </Button>
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalEditPD;

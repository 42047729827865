/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface Collapse {
    name?: string;
    key?: string;
    icon?: string;
    collapse?: Collapse[];
}

export interface ListMenu {
    id?: number;
    menu: string;
    key: string;
    is_active: number;
}

export interface MenuRequest {
    id?: any;
    name?: string;
    key?: string;
    icon?: string;
    is_active?: number;
    collapse?: Collapse[];
}

export interface DetailMenuDetails {
    key: string;
    icon: null;
    name: string;
    type: null | string;
    route: null | string;
    dropdown: boolean | null;
    collapse: DetailMenuDetails[] | null;
}

export interface DetailMenu {
    id: number;
    menu: DetailMenuDetails;
    is_active: number;
}

class MenuService extends BaseService {
    createMenu = "/manage/create-menu";

    listMenu = "/manage/list-menu";

    detailMenu = "/manage/detail-menu";

    constructor() {
        super();
    }

    DetailMenu<T extends DetailMenu>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailMenu,
                config: { params },
            });
            return req;
        });
    }

    ListMenu<T extends ListMenu[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listMenu,
            });
            return req;
        });
    }

    CreateMenu<T extends any>(data: MenuRequest) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createMenu,
                data,
            });
            return req;
        });
    }
}

const menuService = new MenuService();
export default menuService;

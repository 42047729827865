import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Skeleton, message } from "antd";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import suppliesService, { CreateSupplies, CreateSuppliesType, EditSuppliesType } from "services/api-endpoints/purchasing/supplies";
import { STATUS_ACTIVE_2, STATUS_ACTIVE_3 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<EditSuppliesType>> = yup.object().shape({
    type_id: yup.number(),
    name: yup.string().required("Name Required"),
    code: yup.string().required("Code Required"),
    description: yup.string().required("Description Required"),
    is_active: yup.number().required("Is Active Required"),
});

const SuppliesTypeEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<EditSuppliesType>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [suppliesService.detailSuppliesType, id],
        async () => {
            return (await suppliesService.DetailSuppliesType({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setValue("type_id", data.type_id);
                setValue("name", data.name);
                setValue("code", data.code);
                setValue("description", data.description);
                setValue("is_active", data.is_active);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const createMutation = useMutation(
        [suppliesService.createSuppliesType],
        async (data: CreateSuppliesType) => {
            return (await suppliesService.CreateSuppliesType(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Type Of Supplies Created");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit type of supplies"
                rightAddition={() => (
                    <>
                        <Button loading={createMutation.isLoading} onClick={onSubmitHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <ControlledInputText control={control} name="name" placeholder="Name" label="Name" />
                                        <ControlledInputText control={control} name="code" placeholder="Code" label="Code" />
                                        <ControlledInputText control={control} name="description" placeholder="Description" label="Description" />
                                        <ControlledSelectInput
                                            classNameForm="col-span-1"
                                            control={control}
                                            name="is_active"
                                            options={STATUS_ACTIVE_3}
                                            placeholder="Status"
                                            label="Status"
                                        />
                                    </div>
                                </Form>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 2 }} active />
                        </State.Loading>
                    </>
                )}
            </State>
        </div>
    );
};

export default SuppliesTypeEdit;

import { clsx } from "clsx";
import React from "react";
import { HTMLMotionProps, motion } from "framer-motion";

type ShapeProcessProps = HTMLMotionProps<"div"> & {
    backgroundColor?: string;
};

const ShapeProcess = React.forwardRef(({ className, backgroundColor, ...props }: ShapeProcessProps, ref?: any) => {
    return (
        <motion.div className={clsx("grid grid-cols-3 grid-rows-2 gap-2 h-full w-full", className)} {...props}>
            <motion.div className={clsx("col-span-2 row-span-2 rounded w-full h-full", backgroundColor)} />
            <motion.div className={clsx("rounded w-full h-full", backgroundColor)} />
            <motion.div className={clsx("rounded w-full h-full", backgroundColor)} />
        </motion.div>
    );
});

export default ShapeProcess;

import { Button } from "antd";
import AccountHeader from "components/header/account";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

type Props = {
    children: React.ReactNode;
    back?: boolean;
};

const LayoutNoSide = ({ children, back = false }: Props) => {
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    };

    return (
        <div className="w-full min-h-screen container mx-auto px-4 xl:px-8 py-6">
            <div className="w-full items-center flex justify-between">
                {back ? (
                    <Button onClick={onBack} size="large" shape="circle" className="!flex !items-center !justify-center">
                        <IoArrowBack />
                    </Button>
                ) : (
                    <p />
                )}
                <AccountHeader />
            </div>
            <div className="w-full min-h-[70vh] flex items-center justify-center">{children}</div>
        </div>
    );
};

export default LayoutNoSide;

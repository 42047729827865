/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption, SubCategoryData } from "models";
import React from "react";
import { useQuery } from "react-query";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";
import { CreateDataSubCategory } from "../models";

type SelectOptionCustom = SelectOption & SubCategoryData;

export interface Props extends ControlledInputSelectProps<CreateDataSubCategory> {
    onSelectedData?: (data: SubCategoryData[]) => void;
}

export function withCategoryType<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const categoryName = props.control._getWatch("category_name");
        const query = useQuery(
            [subCategoryService.suggestByCategoryName, categoryName],
            async () => {
                const req = await subCategoryService.SuggestByCategoryName({ category_name: categoryName });
                return req.data.data?.map((el) => ({ ...el, label: el.category_type || "", value: el.id || "" } as SelectOptionCustom));
            },
            {
                enabled: !!categoryName,
            }
        );

        const onSelectChange = () => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data || []);
            }
        };

        return (
            <Component
                {...props}
                disabled={!categoryName}
                onSelect={onSelectChange}
                onDeselect={onSelectChange}
                optionFilterProp="children"
                loading={query.isLoading}
                options={query.data || []}
            />
        );
    };
}

const CategoryTypeSelect = withCategoryType(ControlledSelectInput);

export default CategoryTypeSelect;

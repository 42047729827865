/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { message } from "antd";
import clsx from "clsx";
import { HTMLMotionProps, motion } from "framer-motion";
import LoaderEntity from "modules/manufacture/components/loader-entity";
import { Entities, ManufactureContext, checkIfTextIsEmpty, findEntity } from "modules/manufacture/context/context";
import { MenuTypes, animateEntity, backgroundColorManufacture, cardProcessItemInfo, setIdElementEntity } from "modules/manufacture/utils/constant";
import { Entity, ProcessEntity } from "modules/manufacture/utils/models";
import React, { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { useDrag, useDrop } from "react-dnd";
import ShapeProcess from "./shape";
import RenderEntities from "modules/manufacture/draw/render-entities";
import PriceTag from "modules/manufacture/components/price-tag";

export type MultipleProcessEntity = Entity<ProcessEntity<string>>;

type CardProcessDrawProps = HTMLMotionProps<"div"> & {
    attach?: MultipleProcessEntity;
    asChild?: boolean;
    parentExpand?: boolean;
};

const CardProcessDraw = ({ className, attach, parentExpand, asChild, children, ...props }: CardProcessDrawProps) => {
    const ref = React.useRef(null);
    const manufactureCtx = React.useContext(ManufactureContext) as any;

    const childrenEntities = attach?.data?.children?.map((id) => findEntity(manufactureCtx.entities, id))?.filter(Boolean) || [];

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.process.type,
            item: { ...MenuTypes.process, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [attach?.left, attach?.top, attach?.idEntity, manufactureCtx.entities]
    );

    const dropChildren = (data: any) => {
        manufactureCtx.setEntities((ents: Entities[]) => {
            const tempIdEntity = data?.idEntity || uuid();
            let tempEntities = [...ents];

            if (data?.idEntity) {
                tempEntities = tempEntities
                    .map((ent) => {
                        if (ent.idEntity !== tempIdEntity) return ent;
                        return { ...ent, idEntityParent: attach?.idEntity };
                    })
                    .map((ent) => {
                        // add idEntity on parent;
                        if (attach?.idEntity === ent?.idEntity) {
                            return {
                                ...ent,
                                data: {
                                    ...ent?.data,
                                    children: Array.from(new Set(ent.data?.children || []).add(tempIdEntity)),
                                },
                            };
                        }
                        // remove idEntity on parent;
                        if (data?.idEntityParent === ent?.idEntity) {
                            return {
                                ...ent,
                                data: {
                                    ...ent?.data,
                                    children: ent?.data?.children?.filter((id) => id !== tempIdEntity),
                                    child1: ent?.data?.child1 === tempIdEntity ? null : ent?.data?.child1,
                                    child2: ent?.data?.child2 === tempIdEntity ? null : ent?.data?.child2,
                                },
                            };
                        }

                        return ent;
                    }) as Entities[];
            } else {
                tempEntities = [...tempEntities, { ...data, idEntity: tempIdEntity, idEntityParent: attach?.idEntity }].map((ent) => {
                    // add idEntity on parent;
                    if (attach?.idEntity === ent?.idEntity) {
                        return {
                            ...ent,
                            data: {
                                ...ent?.data,
                                children: Array.from(new Set(ent.data?.children || []).add(tempIdEntity)),
                            },
                        };
                    }
                    return ent;
                });
            }

            return tempEntities;
        });
    };

    const [{ itemDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [MenuTypes.subProcess.type, MenuTypes.multipleOperator.type],
            drop(item: MultipleProcessEntity) {
                dropChildren(item);
            },
            canDrop(item, monitor) {
                return monitor.isOver({ shallow: true });
            },
            collect(monitor) {
                return {
                    isOver: monitor.isOver({ shallow: true }),
                    itemDrop: monitor.getItem(),
                };
            },
        }),
        [attach, manufactureCtx.currentDraggingEntity]
    );

    useEffect(() => {
        manufactureCtx.setCurrentDraggingEntity(isDragging ? attach : null);
    }, [isDragging]);

    const onClick = (e: any) => {
        e.stopPropagation();
        if (checkIfTextIsEmpty(manufactureCtx.entities, manufactureCtx.activeEntity?.idEntity)) {
            message.error("Title can't be empty!");
            return;
        }
        manufactureCtx.clickEntityToFocus(attach);
        manufactureCtx.clickEntityToZIndex(attach);
    };

    const sumGrandTotal = manufactureCtx.processEntitiesWithTotal.find(
        (item: { total: number; idEntity: string }) => item.idEntity === attach?.idEntity
    );

    const isActive = manufactureCtx.activeEntitiesMenu?.length && !manufactureCtx.activeEntitiesMenu.includes(attach?.idEntity);

    const isPartOfActive = (() => {
        if (!manufactureCtx.activeEntitiesMenu.length) return true;
        return manufactureCtx.activeEntitiesMenu.includes(attach?.idEntity);
    })();

    const isOverNewItem = isOver; // TODO
    const isOverTransferItem = isOver; // TODO

    const classnameContainer = clsx(
        className,
        cardProcessItemInfo.style.className,
        "border border-solid border-gray-300 min-w-[200px] hover:shadow-lg transition-shadow duration-100 w-fit items-center cursor-pointer py-2 gap-4 px-3 flex flex-col",
        !isPartOfActive && "!bg-gray-400 !border-gray-500",
        isActive && "z-[50]",
        !attach?.idEntityParent && "shadow-md",
        isOverNewItem || isOverTransferItem ? "outline outline-gray-500" : "outline outline-transparent",
        !asChild && "absolute transform",
        manufactureCtx.activeEntity?.idEntity === attach?.idEntity ? "outline-card-entity" : ""
    );

    drag(drop(ref));

    const keyShape = useMemo(() => uuid(), [isOverNewItem || isOverTransferItem]);

    if (isDragging) return <div />;
    return (
        <motion.div
            animate={asChild ? {} : animateEntity.animate}
            initial={asChild ? {} : animateEntity.initial}
            transition={asChild ? {} : animateEntity.transition}
            exit={animateEntity.exit}
            role="button"
            tabIndex={0}
            id={setIdElementEntity(attach?.idEntity)}
            onClick={onClick}
            ref={ref as any}
            style={{ left: attach!.left!, top: attach!.top!, zIndex: attach!.zIndex! }}
            className={classnameContainer}
            {...props}
        >
            <LoaderEntity />
            {children as any}
            <div className={clsx("w-full min-h-24 flex flex-col gap-4")}>
                {!childrenEntities?.length ? (
                    <ShapeProcess key={keyShape} backgroundColor={backgroundColorManufacture.primary} className="!h-24 !w-full" />
                ) : null}
                <RenderEntities asChild entities={childrenEntities as Entities[]} />
            </div>
            {childrenEntities?.length ? (
                <div className="self-start font-light text-sm">
                    Total: <PriceTag price={sumGrandTotal?.total} />
                </div>
            ) : null}
        </motion.div>
    );
};

export default CardProcessDraw;

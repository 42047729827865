/* eslint-disable no-plusplus */
import React, { useRef, useState } from "react";
import { Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import pickingListService, { DetailProduct, ListData } from "services/api-endpoints/dashboard-jsl/picking-list";

type Props<T> = {
    warehouse: number | null;
    selectedPO: React.Key[];
    setSelectedPO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductPO: (data: DetailProduct[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
    option: number;
    setTopId: React.Dispatch<React.SetStateAction<number | null>>;
};

const ModalSOTable = <T extends ListData>({ option, children, selectedPO, setSelectedPO, warehouse, onProductPO, setTopId }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const listPOQuery = useQuery(
        [pickingListService.listData, warehouse, option],
        async () => {
            return (await pickingListService.ListData<T[]>({ account_id: warehouse, option })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    const detailProductPOMutation = useMutation([pickingListService.detailProduct], async (ids: string) => {
        return (await pickingListService.DetailProduct({ doc_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const termsId = listPOQuery.data?.filter((po) => newSelectedRowKeys.includes(po.doc_id as any))?.map((po) => po.payment_term_id);
        if ([...new Set(termsId)].length !== 1) {
            setCanSubmit(false);
        } else {
            setCanSubmit(true);
            setTopId(termsId !== undefined ? termsId[0]! : null);
        }
        setSelectedPO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedPO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Term Of Payment",
            dataIndex: "payment_term_code",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    const onOkHandler = () => {
        detailProductPOMutation
            .mutateAsync(selectedPO.join(","))
            .then((res) => {
                onProductPO(res);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title="List Sales Order"
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: detailProductPOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <Table
                        rowKey={(i) => i.doc_id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalSOTable;

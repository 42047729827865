import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { SelectOption } from "models";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export type Props = ModalProps & {
    dataArea?: SelectOption[];
};

const LocatorModal = ({ dataArea, children, ...props }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const locators = searchParams.get("locators") || "";

    const onChange = (data: SelectOption) => {
        return (val: CheckboxChangeEvent) => {
            const { checked } = val.target;
            if (locators) {
                searchParams.set("locators", `${locators}, ${data.value}`);
                setSearchParams(searchParams);
            } else {
                searchParams.set("locators", data.value);
                setSearchParams(searchParams);
            }
        };
    };

    return (
        <ModalTemplate width={700} title="Locator" handlerInComponent={children} footer={null} {...props}>
            {(dt) => {
                return (
                    <div>
                        <Row>
                            {dataArea &&
                                dataArea.map((val) => {
                                    const isCheck = locators.split(",").map(Number).includes(val.value);
                                    return (
                                        <Col span={6}>
                                            <Checkbox checked={isCheck} onChange={onChange(val)}>
                                                {val.label}
                                            </Checkbox>
                                        </Col>
                                    );
                                })}
                        </Row>
                    </div>
                );
            }}
        </ModalTemplate>
    );
};

export default LocatorModal;

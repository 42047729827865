/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface CreateLoyalty {
    name: string;
    type: number;
    starting_price: string;
    end_price: string;
    start_date: string;
    end_date: string;
    max_user_per_day: string;
    is_multiple: number;
    point: string;
    max_point: string;
}

export interface Loyalty {
    id: number;
    name: string;
    type: number;
    starting_price: number;
    end_price: number;
    is_multiple: number;
    max_user_per_day: number;
    point: number;
    max_point: number;
    start_date: string;
    end_date: string;
}

class LoyaltyService extends BaseService {
    getAll = "/loyalty/all-loyalty";

    search = "/loyalty/search-loyalty";

    create = "/loyalty/add-loyalty";

    detail = "/loyalty/detail-loyalty";

    constructor() {
        super();
    }

    Detail<T extends Loyalty>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateLoyalty) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataLoyalty>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataLoyalty>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const loyaltyService = new LoyaltyService();
export default loyaltyService;

/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { Button, InputNumber, Popconfirm, Select, Space, Typography, message } from "antd";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from "react-query";
import jurnalPenyesuaianService from "services/api-endpoints/accounting/jurnal-penyesuaian";
import jurnalUmumService, { BaseJurnalResponse, EditJournal, JurnalUmum, JurnalUmumChild, Type } from "services/api-endpoints/accounting/jurnal-umum";
import Utils from "utils";

export type JurnalContextType<T> = {
    list: T[];
    setList: React.Dispatch<React.SetStateAction<T[]>>;
    editRow: T;
    setEditRow: React.Dispatch<React.SetStateAction<T | null>>;
    isEdited: boolean;
    setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
    fetcher: UseQueryResult<BaseJurnalResponse<T>, unknown>;
    editMutate: UseMutationResult<unknown, unknown, EditJournal, unknown>;
};

type Props<T> = {
    children: any;
};

export const JurnalContext = React.createContext<JurnalContextType<JurnalUmum> | null>(null);

const JurnalProvider = <T extends JurnalUmum>({ children }: Props<T>) => {
    const [list, setList] = React.useState<T[]>([]);
    const [editRow, setEditRow] = React.useState<T | null>(null);
    const [isEdited, setIsEdited] = React.useState(false);

    const editMutate = useMutation(
        [jurnalUmumService.editJournal],
        async (data: EditJournal) => {
            return (await jurnalUmumService.EditJournal(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Row journal edit success");
                setIsEdited(false);
                setEditRow(null);
            },
        }
    );

    const value = React.useMemo(
        () => ({
            list,
            setList,
            editRow,
            setEditRow,
            isEdited,
            setIsEdited,
            editMutate,
        }),
        [list, editRow, setList, setEditRow, isEdited, setIsEdited, editMutate]
    );
    return <JurnalContext.Provider value={value as any}>{children}</JurnalContext.Provider>;
};

const AmmountEdit = ({ item, record, type, itemIndex }: { item: JurnalUmumChild; record: JurnalUmum; type: Type; itemIndex: number }) => {
    const { editRow, setEditRow } = React.useContext(JurnalContext) as JurnalContextType<JurnalUmum>;

    const onChangeAmmount = (amount: number) => {
        setEditRow((prev) => ({
            ...prev,
            child: prev?.child?.map((child, i) => {
                if (i !== itemIndex) return child;
                return {
                    ...child,
                    amount: amount || 0,
                    is_edited: true,
                };
            }),
        }));
    };

    if (record?.id !== editRow?.id) {
        if (type === Type.Debit) {
            if (item.type === Type.Debit) return <p className="m-0 mb-2 font-semibold">{(item?.amount || 0)?.ToIndCurrency("Rp")}</p>;
            return <p className="m-0 mb-2 ml-10 font-semibold">-</p>;
        }
        if (item.type === Type.Kredit) return <p className="m-0 mb-2 ml-10 font-semibold">{(item?.amount || 0)?.ToIndCurrency("Rp")}</p>;
        return <p className="m-0 mb-2 font-semibold">-</p>;
    }

    if (item.type !== type) {
        return <p className="m-0 mb-2 font-semibold">-</p>;
    }

    return (
        <InputNumber
            defaultValue={item.amount}
            formatter={Utils.currencyFormatterId}
            parser={Utils.currencyParserId}
            placeholder="Input Amount"
            className={`${item.type !== Type.Debit ? "!ml-10" : ""} !w-[150px]`}
            onChange={onChangeAmmount}
        />
    );
};

const AccountNameEdit = ({ item, record }: { item: JurnalUmumChild; record: JurnalUmum }) => {
    const { editRow, setEditRow } = React.useContext(JurnalContext) as JurnalContextType<JurnalUmum>;

    const accountNameQuery = useQuery([jurnalPenyesuaianService.account], async () => {
        const req = await jurnalPenyesuaianService.GetAccount();
        return req.data.data;
    });

    const options = accountNameQuery.data?.map((acc) => ({ value: acc.coa_id, label: acc.coa_name }));

    const onChangeAccount = (id: number) => {
        const nameAccount = options?.find((coa) => coa.value === id)?.label;
        setEditRow((prev) => ({
            ...prev,
            child: prev?.child?.map((child, i) => {
                if (child.id_detail !== item.id_detail) return child;
                return {
                    ...child,
                    name: nameAccount,
                    coa_id: id,
                    is_edited: true,
                };
            }),
        }));
    };

    if (record?.id !== editRow?.id) {
        return <p className={`m-0 mb-2 font-semibold ${item?.type !== Type.Debit ? "ml-10" : ""}`}>{item?.name?.CapitalizeEachFirstLetter()}</p>;
    }

    return (
        <Select
            size="small"
            optionFilterProp="label"
            showSearch
            defaultValue={item.coa_id}
            className={`${item.type !== Type.Debit ? "!ml-10" : ""} w-[200px]`}
            options={options}
            loading={accountNameQuery.isLoading}
            placeholder="Account Name"
            onChange={onChangeAccount}
        />
    );
};

const ActionEdit = ({ record, refetchJournal }: { record: JurnalUmum; refetchJournal: () => void }) => {
    const { setEditRow, editRow, setList, setIsEdited, editMutate } = React.useContext(JurnalContext) as JurnalContextType<JurnalUmum>;

    const onSaveEdit = () => {
        setList((prev) =>
            prev?.map((row) => {
                if (row.id !== editRow?.id) return row;
                return {
                    ...row,
                    ...editRow,
                    is_edited: true,
                };
            })
        );
        setEditRow(null);
        setIsEdited(true);
    };

    const saveEdit = () => {
        const data: EditJournal = {
            id: record.id as any,
            child: record?.child?.map((child) => ({
                coa_id: child.coa_id,
                name: child.name,
                note: null,
                debit: child.type === Type.Debit ? child.amount : null,
                kredit: child.type === Type.Kredit ? child.amount : null,
            })) as any,
        };
        editMutate.mutateAsync(data).finally(refetchJournal);
    };

    if (!record?.child?.length) return null;
    if (editRow && editRow.id === record.id) {
        return (
            <Space>
                <Typography.Link onClick={onSaveEdit}>Save</Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={() => setEditRow(null)}>
                    <a>Cancel</a>
                </Popconfirm>
            </Space>
        );
    }

    return (
        <Space>
            <Button disabled={editRow && editRow?.id !== record.id} type="text" title="edit row" onClick={() => setEditRow(record)}>
                <FiEdit className="text-lg" />
            </Button>
            {record?.is_edited && (
                <Button loading={editMutate.isLoading} className="w-fit" onClick={saveEdit}>
                    <FiEdit className="m-0 mr-2" />
                    Save Edit
                </Button>
            )}
        </Space>
    );
};

JurnalProvider.AccountNameEdit = AccountNameEdit;
JurnalProvider.ActionEdit = ActionEdit;
JurnalProvider.AmmountEdit = AmmountEdit;

export default JurnalProvider;

import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { LOYALTY_TYPE, VIEW_PATH } from "utils/constant";
import ButtonAccessView from "components/button/button-access-view";
import { TDataLoyalty } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const LoyaltyTable = <T extends TDataLoyalty>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: TDataLoyalty) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Loyalty Name",
            dataIndex: "loyalty_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Loyalty Type",
            dataIndex: "loyalty_type",
            render: (text) => <p className="capitalize m-0">{LOYALTY_TYPE.find((el) => el.value === Number(text))?.label}</p>,
        },
        {
            title: "Effective Date",
            dataIndex: "effective_date",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default LoyaltyTable;

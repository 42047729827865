/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface BusinessPartnerList {
    id?: number;
    name?: string;
    type?: number;
    code?: string;
    member_status?: number;
    payment_term_name?: string;
    is_active?: number;
}

export interface CreateBusinessPartner {
    name?: string;
    type?: number;
    address?: string;
    code?: string;
    credit_status?: number;
    credit_limit?: any | null;
    open_balance?: any | null;
    tax_id?: number | null;
    tax_type?: number | null;
    payment_term_id?: number | null;
    phone_number?: string;
    // membership_type?: string;
    // discount_schema?: string;
    // member_status?: number;
    // sales_area?: string;
    // sales_area_rule?: string;
    // agent_id?: number;
}

export interface DetailBusinessPartner {
    id?: number;
    name?: string;
    type?: number;
    address?: string;
    code?: string;
    credit_status?: number;
    credit_limit?: any;
    open_balance?: any;
    tax_id?: number;
    tax_type?: number;
    payment_term_id?: number;
    phone_number?: string;
    // membership_type?: string;
    // discount_schema?: string;
    // member_status?: number;
    // sales_area?: string;
    // sales_area_rule?: string;
    // agent_id?: number;
}

export interface EditBusinessPartner extends CreateBusinessPartner {
    id?: number;
}

class BusinessPartnerService extends BaseService {
    getAll = "/business-partner/list-bp";

    create = "/business-partner/add-bp";

    detail = "/business-partner/detail-bp";

    constructor() {
        super();
    }

    Detail<T extends DetailBusinessPartner>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateBusinessPartner) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetAll<T extends BusinessPartnerList>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }
}

const businessPartnerService = new BusinessPartnerService();
export default businessPartnerService;

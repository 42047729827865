import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { RoleMenuList } from "services/api-endpoints/dashboard/master-data/role-menu";
import { EDIT_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T, unknown>;
};

const RoleMenuTable = <T extends RoleMenuList[]>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();

    const editHandler = (data: RoleMenuList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<RoleMenuList> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Description",
            dataIndex: "description",
        },
        {
            title: "Role",
            dataIndex: "role_name",
        },
        {
            title: "Apps",
            dataIndex: "apps_name",
        },
        {
            width: "60px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessEdit type="text" onClick={() => editHandler(record)} />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={false}
        />
    );
};

export default RoleMenuTable;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { InternalReturnProduct } from "services/api-endpoints/dashboard/master-data/internal-return-order";
import { STOCK_ACTION } from "utils/constant";
import { Button } from "antd";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import LocatorModal from "./modal-locator";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    onSubmitLocator: (docId: number, locator: LocatorData) => void;
};

export function addProductTable<T extends InternalReturnProduct>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ onSubmitLocator, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code1",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => (
                    <LocatorModal title="Locator" docId={0} productId={record.product_id} onSubmit={onSubmitLocator}>
                        {(dt) => (
                            <Button
                                size="small"
                                onClick={() => dt.openModalWithData(record)}
                                className="!flex !items-center"
                                type="default"
                                {...(!text ? { danger: true } : {})}
                            >
                                {text || "Choose Locator"}
                            </Button>
                        )}
                    </LocatorModal>
                ),
            },
            {
                title: "Qty Locator",
                dataIndex: "qty_locator",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Qty Request",
                dataIndex: "qty",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            // {
            //     title: "Stock Action",
            //     width: "100px",
            //     dataIndex: "stock_action",
            //     ...{ editable: true },
            //     render: (text) => text || "",
            // },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id! as any;
        const editInputType: EditTableProps<T>["editInputType"] = { stock_action: "select", qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                cellProps={{
                    selectProps: {
                        options: STOCK_ACTION,
                        className: "!w-[150px]",
                        placeholder: "Action",
                    },
                    ...props?.cellProps,
                }}
                list={props.list?.map((product: any) => ({
                    ...product,
                    stock_action: STOCK_ACTION.find((act) => act.value === product.stock_action || act.label === product.stock_action)?.label,
                }))}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const InternalReturnProductTable = addProductTable(EditTable);
export default InternalReturnProductTable;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import categoryService, { AllCategory } from "services/api-endpoints/dashboard/master-data/category";
import { CATEGORY_PRODUCT } from "utils/constant";
import { FCreateDataProduct } from "../models";

type SelectOptionCustom = SelectOption & AllCategory;

export interface Props extends ControlledInputSelectProps<FCreateDataProduct> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withCategory<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const categoryID = props?.control?._getWatch("category");
        const category = CATEGORY_PRODUCT.find((el) => el.value === Number(categoryID))?.label;

        const query = useQuery([categoryService.getAllCategory], async () => {
            const req = await categoryService.GetAllCategory();
            return req.data.data?.map((el) => ({ ...el, label: el.category_type || "Any", value: el.id } as SelectOptionCustom));
        });

        const options = query.data?.filter((el) => el.category_name === category) || [];

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(options?.find((el) => el.id === id));
            }
        };

        return (
            <Component {...props} onSelect={onSelect} disabled={!category} optionFilterProp="children" loading={query.isLoading} options={options} />
        );
    };
}

const CategorySelect = withCategory(ControlledSelectInput);

export default CategorySelect;

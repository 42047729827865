/* eslint-disable react/no-array-index-key */
import { Icon } from "@mui/material";
import { Space } from "antd";
import ButtonApp from "components/button/button-app";
import LayoutNoSide from "components/common/layout-no-side";
import SearchMenu from "components/common/search-menu";
import { StateContext } from "context/state";
import { UserContext } from "context/user";
import { MainMenu } from "models";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const MyAppsPage = () => {
    const navigate = useNavigate();
    const { state } = useContext(UserContext);
    const { navigateAppHandler } = useContext(StateContext);

    const appClickHandler = (app: MainMenu) => {
        return () => {
            if (!navigateAppHandler) return;
            navigate(navigateAppHandler(app));
        };
    };

    return (
        <LayoutNoSide>
            <div className="flex items-center flex-col gap-10">
                <SearchMenu />
                <Space size={30}>
                    {!state.user?.apps && [...new Array(4)].map((_, i) => <ButtonApp.Loading key={i} />)}
                    {state.user?.apps?.map((app) => (
                        <ButtonApp onClick={appClickHandler(app)} description={app.apps_name || ""} type="primary" key={app.apps_name}>
                            <Icon fontSize="medium">{app.apps_icon || "dashboard"}</Icon>
                        </ButtonApp>
                    ))}
                </Space>
            </div>
        </LayoutNoSide>
    );
};

export default MyAppsPage;

/* eslint-disable no-plusplus */
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import extPickingDetailService, { BPData, ListSO, ProductSO } from "services/api-endpoints/dashboard/master-data/ext-picking-detail";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    partnerID: number | null;
    partnerName: string;
    selectedSO: React.Key[];
    setSelectedSO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductSO: (data: ProductSO[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
    setSoChild: React.Dispatch<React.SetStateAction<number[]>>;
    setBpData: React.Dispatch<React.SetStateAction<BPData | null>>;
    onNote: (text: string) => void;
};

const ModalPickingDetailAdd = <T extends ListSO>({
    children,
    selectedSO,
    setSelectedSO,
    onProductSO,
    partnerID,
    partnerName,
    setSoChild,
    setBpData,
    onNote,
}: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [bp2Data, setBp2Data] = useState<BPData | null>(null);

    const listQuery = useQuery(
        [extPickingDetailService.getSO, partnerID],
        async () => {
            return (await extPickingDetailService.GetSO<T[]>({ partner_id: partnerID })).data.data;
        },
        {
            enabled: !!partnerID,
        }
    );

    const detailProductSOMutation = useMutation([extPickingDetailService.getProductSO], async (ids: string) => {
        return (await extPickingDetailService.GetProductSO({ order_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const bpData = listQuery.data
            ?.filter((po) => newSelectedRowKeys.includes(po.id as any))
            ?.map((po) => ({ bp_id: po.bp_id, bp_name: po.bp_name, note: po.note } as BPData));
        // if ([...new Set(bpData)].length !== 1) {
        //     setCanSubmit(false);
        // } else {
        //     setCanSubmit(true);
        //     setBp2Data(bpData !== undefined ? bpData[0]! : null);
        // }
        const soChild = listQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.so_child);
        setSoChild([...new Set(soChild?.flat())]);
        setCanSubmit((newSelectedRowKeys?.length || 0) === 1);
        setBp2Data(bpData !== undefined ? bpData[0]! : null);
        setSelectedSO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedSO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Doc No",
            dataIndex: "doc_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "BP Name",
            dataIndex: "bp_name",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <span className="text-sm">{text ? moment(text).format(FORMAT_DATE_TIME) : ""}</span>,
        },
    ];

    const onOkHandler = () => {
        detailProductSOMutation
            .mutateAsync(selectedSO.join(""))
            .then((res) => {
                const qtyApprove = res.map((val) => {
                    return {
                        ...val,
                        qty_approve: val.qty,
                    };
                });
                onProductSO(qtyApprove);
                setBpData(bp2Data);
                onNote(bp2Data?.note || "");
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title={`List Sales Order - ${partnerName}`}
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: detailProductSOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Selected 1 Sales Order</p>
                    <Table
                        rowKey={(i) => i.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listQuery.data || []}
                        loading={listQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalPickingDetailAdd;

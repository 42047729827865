/* eslint-disable no-shadow */
import Toolbar from "components/toolbar";
import MasterCategoryCutiTable from "modules/hadir-app/master-category-cuti/table";
import { useQuery } from "react-query";
import hadirAppService from "services/api-endpoints/hadir-app/hadir-app-service";

const MasterCategoryCuti = () => {
    const getAll = useQuery([hadirAppService.getListCategoryCuti], async () => {
        return (await hadirAppService.GetListCategoryCuti()).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search..." filter={false} />
            <MasterCategoryCutiTable fetcher={getAll} />
        </div>
    );
};

export default MasterCategoryCuti;

/* eslint-disable react/no-array-index-key */
import { Icon } from "@mui/material";
import { Alert, Input, message, Modal, Space } from "antd";
import ButtonApp from "components/button/button-app";
import LayoutNoSide from "components/common/layout-no-side";
import State from "components/common/state";
import { UserContext } from "context/user";
import * as Models from "models";
import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import appsMenuService from "services/api-endpoints/dashboard/apps-menu";

const AppsPage = () => {
    const { allApps } = useContext(UserContext);

    const getAllAppsQuery = useQuery([appsMenuService.getAllApps], async () => {
        const req = await appsMenuService.GetAllApps();
        return req.data.data;
    });

    const installAppMutation = useMutation(
        async (data: Models.InstallAppData) => {
            const req = await appsMenuService.InstallApps(data);
            return req.data.data;
        },
        {
            onSuccess: () => {
                getAllAppsQuery.refetch();
                allApps?.refetch();
            },
            onError: (error: any) => {
                message.error(error?.message);
            },
        }
    );

    const installHandler = (app: Models.App) => {
        Modal.confirm({
            title: app.is_active ? "Uninstall" : "Install",
            content: `You sure to ${app.is_active ? "Uninstall" : "Install"} this app?`,
            onOk() {
                return new Promise((res, rej) => {
                    installAppMutation
                        .mutateAsync({ is_active: app.is_active ? 0 : 1, role_menu_id: app.role_menu_id, user_role_menu_id: app.user_role_menu_id })
                        .then(() => {
                            res(true);
                        })
                        .catch(() => {
                            rej();
                        });
                });
            },
        });
    };

    return (
        <LayoutNoSide back>
            <State data={getAllAppsQuery.data} isLoading={getAllAppsQuery.isLoading} isError={getAllAppsQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Space size={50}>
                                {getAllAppsQuery.data?.map((app) => (
                                    <ButtonApp
                                        onClick={() => installHandler(app)}
                                        type={app.is_active ? "primary" : "default"}
                                        description={
                                            <p className="text-center m-0">
                                                {app.name} <br />
                                                <span className="text-xs m-0 text-gray-400">{app.is_active ? "Installed" : "Not Installed"}</span>
                                            </p>
                                        }
                                        key={app.name}
                                    >
                                        <Icon fontSize="medium">{app.icon || "dashboard"}</Icon>
                                    </ButtonApp>
                                ))}
                            </Space>
                        </State.Data>
                        <State.Loading state={state}>
                            <Space size={50}>
                                {[...new Array(4)].map((_, i) => (
                                    <ButtonApp.Loading key={i} />
                                ))}
                            </Space>
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(getAllAppsQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </LayoutNoSide>
    );
};

export default AppsPage;

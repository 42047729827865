import { Button, Card, Select, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import AdvanceSearch from "modules/advance-search";
import { ProductWithQty } from "modules/advance-search/models";
import ModalOrder from "modules/dashboard-jsl/bp-return/modal-order";
import OrderTableAdd from "modules/dashboard-jsl/bp-return/order-table-add";
import ProductTableAdd from "modules/dashboard-jsl/bp-return/product-table-add";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import bpOrderService from "services/api-endpoints/dashboard/master-data/bp-order";
import bpReturnService, { CreateReturnOrder, DetailOrder, Order, ReturnOrderDetail } from "services/api-endpoints/dashboard/master-data/bp-return";

import { AUTOGENERATED, BP_RETURN_DO, BP_RETURN_INV, BP_RETURN_NON_INV, STOCK_ACTION, TYPE_BP_RETURN, TYPE_ORDER } from "utils/constant";

const BPReturnAdd = () => {
    const navigate = useNavigate();
    const [reason, setReason] = useState<number | null>(null);
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const [selectedOrder, setSelectedOrder] = useState<Order[]>([]);
    const [orderItems, setOrderItems] = useState<DetailOrder[]>([]);
    const [partnerID, setPartnerID] = useState<number | null>(null);
    const [type, setType] = useState<number | null>(BP_RETURN_INV);

    const getReason = useQuery([bpReturnService.getReason], async () => {
        return (await bpReturnService.GetReason()).data.data;
    });

    const getDetailOrder = useMutation([bpReturnService.getDetailOrder], async (orderId: string) => {
        return (await bpReturnService.GetDetailOrder({ id: orderId })).data.data;
    });

    const create = useMutation([bpReturnService.create], async (data: CreateReturnOrder) => {
        return (await bpReturnService.Create(data)).data.data;
    });

    const partnerQuery = useQuery([bpOrderService.getBusinessPartner], async () => {
        const req = await bpOrderService.GetBusinessPartner({ status: 6 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption));
    });

    const getDataOrder = useMutation([bpReturnService.getDataOrder], async (partner: number) => {
        return (await bpReturnService.GetDataOrder({ query: "", type, bp_id: partner })).data.data?.map(
            (order) => ({ ...order, label: order.order_no, value: order.order_id } as SelectOption & Order)
        );
    });

    const onChangeReason = (value: number) => {
        setReason(value);
    };

    const onChangePartner = (value: number) => {
        setOrderItems([]);
        setSelectedOrder([]);
        setPartnerID(value);
        getDataOrder.mutateAsync(value);
    };

    const onChangeType = (value: number) => {
        setOrderItems([]);
        setSelectedOrder([]);
        setType(value);
    };

    const onSelectOrder = async (order: Order) => {
        // const isExsis = orderItems.filter((val) => val.order_id === order.order_id);
        if (type === BP_RETURN_INV) {
            setOrderItems([]);
            setSelectedOrder([]);
        }
        getDetailOrder.mutateAsync(order.order_id as any).then((res) => {
            setOrderItems((prev) => [...prev, ...res.map((itm) => ({ ...itm, qty_return: 1 }))]);
            setSelectedOrder((prev) => {
                if (!prev.find((ord) => ord.order_id === order.order_id)) {
                    return [...prev, order];
                }
                return prev;
            });
        });
    };

    const onDeSelectOrder = (order: Order) => {
        setSelectedOrder((prev) => prev?.filter((ord) => ord.order_id !== order.order_id));
        setOrderItems((prev) => prev?.filter((item) => item.order_id !== order.order_id));
    };

    const onRemoveOrderItem = (item: DetailOrder) => {
        setOrderItems((prev) => {
            return prev
                ?.map((itm) => {
                    if (itm.order_id === item.order_id && itm.product_id === item.product_id) return {};
                    return itm;
                })
                .filter((itm) => Object.keys(itm).length);
        });
    };

    const createHandler = () => {
        const noStockAction = orderItems.find((itm) => !itm.stock_action);
        if (noStockAction) {
            message.error(`Stock Action Required on ${noStockAction.order_no}`);
            return;
        }
        const data: CreateReturnOrder = {
            reason_id: reason as number,
            bp_id: partnerID,
            type,
            order_id: [...new Set(orderItems.map((itm) => itm.order_id))] as number[],
            return_order_detail: orderItems.map(
                (itm) =>
                    ({
                        order_id: itm.order_id,
                        order_reference_no: itm.order_reference,
                        product_id: itm.product_id,
                        qty_order: itm.qty_order,
                        qty_return: itm.qty_return,
                        stock_action: STOCK_ACTION.find((act) => act.label === itm.stock_action || act.value === itm.stock_action)?.value,
                    } as ReturnOrderDetail)
            ),
        };
        create.mutateAsync(data).then(() => {
            message.success("Return Order Created");
            navigate(-1);
        });
    };

    React.useEffect(() => {
        setSelectedOrder((prev) => {
            const deletedOrder = prev.filter((ord) => !orderItems.find((itm) => itm.order_id === ord.order_id));
            if (!deletedOrder.length) return prev;
            return prev.filter((prevOrd) => deletedOrder.find((ord) => prevOrd.order_id !== ord.order_id));
        });
    }, [orderItems]);

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add return order"
                rightAddition={() => (
                    <Button
                        onClick={createHandler}
                        type="primary"
                        className="!flex !items-center w-fit"
                        disabled={!reason || orderItems.length === 0}
                        loading={create.isLoading}
                    >
                        <BiSave className="mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <div className="flex items-start justify-between">
                    <p className="capitalize m-0">
                        Return order no <br />
                        <span className="underline italic m-0">{AUTOGENERATED}</span>
                    </p>
                    <div className="capitalize m-0">
                        Reason to return
                        <br />
                        <Select
                            loading={getReason.isLoading}
                            disabled={getReason.isLoading || getReason.isError}
                            value={reason}
                            className="!w-[300px]"
                            options={getReason.data?.map((r) => ({ label: r.reason_name, value: r.reason_id } as SelectOption))}
                            onChange={onChangeReason}
                            showSearch
                            placeholder="Reason"
                        />
                    </div>
                    <div className="capitalize m-0">
                        Partner Name
                        <br />
                        <Select
                            value={partnerID}
                            className="!w-[200px]"
                            loading={partnerQuery.isLoading}
                            options={partnerQuery.data}
                            onChange={onChangePartner}
                            showSearch
                            placeholder="Partner Name"
                        />
                    </div>
                    {/* <div className="capitalize m-0">
                        Type Return
                        <br />
                        <Select
                            value={type}
                            className="!w-[200px]"
                            options={TYPE_BP_RETURN}
                            onChange={onChangeType}
                            showSearch
                            placeholder="Type Return"
                        />
                    </div> */}
                </div>
            </Card>
            {/* {type === BP_RETURN_DO || type === BP_RETURN_INV ? (
                <> */}
            <ModalOrder
                getDetailOrderLoading={getDetailOrder.isLoading}
                onSelectOrder={onSelectOrder}
                onDeSelectOrder={onDeSelectOrder}
                selected={selectedOrder}
                orderData={getDataOrder.data}
                type={type}
                loading={getDataOrder.isLoading}
            >
                {(dt) => (
                    <Button
                        loading={getDetailOrder.isLoading}
                        className="!flex !items-center w-fit"
                        type="primary"
                        onClick={dt.openModal}
                        disabled={partnerID === null || type === null}
                    >
                        <MdAdd className="mr-2" />
                        Data Order
                    </Button>
                )}
            </ModalOrder>
            <OrderTableAdd list={orderItems} setList={setOrderItems} removeItemList={onRemoveOrderItem} />
            {/* </>
            ) : null} */}

            {type === BP_RETURN_NON_INV ? (
                <>
                    <AdvanceSearch selected={products} setSelected={setProducts}>
                        {(dt) => (
                            <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                                <MdAdd className="mr-2" />
                                Add Product
                            </Button>
                        )}
                    </AdvanceSearch>
                    <ProductTableAdd list={products} setList={setProducts} removeItemList={onRemoveProduct} />
                </>
            ) : null}
        </div>
    );
};

export default BPReturnAdd;

import { Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import categoryService from "services/api-endpoints/dashboard/master-data/category";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";
import { STATUS_ACTIVE, STATUS_POPULAR } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterSubCategory = ({ filter = [], ...props }: Props) => {
    const categoryNameQuery = useQuery([subCategoryService.suggestSubCategoryName], async () => {
        const req = await subCategoryService.SuggestSubCategoryName({ subcategory_name: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const categoryCodeQuery = useQuery([subCategoryService.suggestSubCategoryCode], async () => {
        const req = await subCategoryService.SuggestSubCategoryCode({ subcategory_code: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const byCategoryNameQuery = useQuery([subCategoryService.suggestByCategoryName], async () => {
        const req = await subCategoryService.SuggestByCategoryName({ category_name: "" });
        return req.data.data?.map((el) => ({ label: el.category_name, value: el.category_name } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Sub Category"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.subcategory_name}
                            loading={categoryNameQuery.isLoading}
                            placeholder="Sub Category Name"
                            options={categoryNameQuery.data || []}
                            onChange={(value) => dt.setOneValue("subcategory_name", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.subcategory_code}
                            loading={categoryCodeQuery.isLoading}
                            placeholder="Sub Category Code"
                            options={categoryCodeQuery.data || []}
                            onChange={(value) => dt.setOneValue("subcategory_code", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.category_name}
                            loading={byCategoryNameQuery.isLoading}
                            placeholder="Category Name"
                            options={byCategoryNameQuery.data || []}
                            onChange={(value) => dt.setOneValue("category_name", value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_popular !== null && dt.values.is_popular !== undefined
                                    ? Number(dt.values.is_popular)
                                    : dt.values.is_popular
                            }
                            placeholder="Is Popular"
                            options={STATUS_POPULAR}
                            onChange={(value) => dt.setOneValue("is_popular", value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_active !== null && dt.values.is_active !== undefined ? Number(dt.values.is_active) : dt.values.is_active
                            }
                            placeholder="Status"
                            options={STATUS_ACTIVE}
                            onChange={(value) => dt.setOneValue("is_active", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterSubCategory;

/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { AllBrand, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import brandService from "services/api-endpoints/dashboard/master-data/brand";
import { FCreateDataProduct } from "../models";

type SelectOptionCustom = SelectOption & AllBrand;

export interface Props extends ControlledInputSelectProps<FCreateDataProduct> {
    onSelectedData?: (data: SelectOptionCustom | null | undefined) => void;
}

export function withBrand<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([brandService.getAllBrand], async () => {
            const req = await brandService.GetAllBrand();
            return req.data.data?.map((el) => ({ ...el, label: el?.brand_name || "Any", value: el.id || "" } as SelectOptionCustom));
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data?.find((el) => el.id === id));
            }
        };

        return <Component {...props} onSelect={onSelect} optionFilterProp="children" loading={query.isLoading} options={query.data || []} />;
    };
}

const BrandSelect = withBrand(ControlledSelectInput);

export default BrandSelect;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Collapse, Form, Row, Space, message } from "antd";
import LayoutNoSide from "components/common/layout-no-side";
import { useForm } from "react-hook-form";
import authService, { ChangePassData } from "services/api-endpoints/dashboard/auth";
import * as yup from "yup";
import ImgJsl from "assets/images/jsl.png";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { useMutation } from "react-query";
import { useContext } from "react";
import { UserContext } from "context/user";
import { StateContext } from "context/state";
import Utils from "utils";

const schema: yup.SchemaOf<Partial<ChangePassData>> = yup.object().shape({
    email: yup.string(),
    idToken: yup.string(),
    password: yup.string().required("Current Pasword Required"),
    new_password: yup.string().required("New Pasword Required"),
    confirm_password: yup.string().required("Confirm Pasword Required"),
});

const ChangePasswordPage = () => {
    const { state, setState: setStateUser } = useContext(UserContext);
    const { setState: setStateState, navigateAppHandler } = useContext(StateContext);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<ChangePassData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const signOutHandler = () => {
        Utils.SignOut();
        if (setStateUser) setStateUser({});
        if (setStateState) setStateState({});
        window.location.reload();
    };

    const changePassMutation = useMutation(
        [authService.changePass],
        async (data: ChangePassData) => {
            return (await authService.ChangePass(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Success Change Password");
                signOutHandler();
            },
        }
    );

    const onSubmitHandler = handleSubmit(async (data) => {
        const parseData = { ...data, email: state.user?.email || "" };
        changePassMutation.mutate(parseData);
    });

    return (
        <LayoutNoSide back>
            <div className=" w-full !h-screen overflow-y-hidden flex items-center justify-center flex-col">
                <div className="flex flex-col items-center z-10">
                    <div className="bg-white rounded-2xl w-40vw 2xl:w-[400px] p-10 shadow-xl z-10 relative">
                        <div className="bg-primary rounded-md px-20 py-5 absolute flex items-center justify-center left-1/2 -top-[70px] transform -translate-x-1/2 ">
                            <img src={ImgJsl} className="" alt="" />
                        </div>
                        <Form
                            form={form}
                            labelCol={{ span: 3 }}
                            labelAlign="left"
                            colon={false}
                            style={{ width: "100%" }}
                            layout="vertical"
                            onFinish={onSubmitHandler}
                            disabled={changePassMutation.isLoading}
                        >
                            <Space direction="vertical" className="w-full mt-4">
                                <ControlledInputText
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="password"
                                    label="Current Password"
                                    placeholder="Current Password"
                                    type="password"
                                />
                                <div />
                                <ControlledInputText
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="new_password"
                                    label="New Password"
                                    placeholder="New Password"
                                    type="password"
                                />
                                <ControlledInputText
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="confirm_password"
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    type="password"
                                />

                                <Row justify="end">
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={changePassMutation.isLoading}
                                            loading={changePassMutation.isLoading}
                                        >
                                            Change Password
                                        </Button>
                                    </Space>
                                </Row>
                            </Space>
                        </Form>
                    </div>
                </div>
            </div>
        </LayoutNoSide>
    );
};

export default ChangePasswordPage;

import { Image, List, Popover, Space } from "antd";
import EllipsisMiddle from "components/ellipsis-text/ellipsis-middle";
import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import { IMAGE_FALLBACK } from "utils/constant";

type Props = {
    vouchers: Voucher[];
    onRemoveVoucher?: (data: Voucher) => void;
    bordered?: boolean;
    showTextEmpty?: boolean;
};

const ListVoucher = ({ vouchers, onRemoveVoucher, bordered = false, showTextEmpty = false }: Props) => {
    if (!vouchers.length) return showTextEmpty ? <p className="m-0 text-gray-400 capitalize text-xs italic">no discount</p> : null;
    return (
        <List
            rowKey={(record) => record.voucher_id}
            size="small"
            dataSource={vouchers}
            bordered={bordered}
            renderItem={(item) => {
                const content = (
                    <Space direction="vertical">
                        <Image width={150} height={70} src={item.voucher_image || undefined} fallback={IMAGE_FALLBACK} />
                        <p className="m-0 font-medium text-gray-600 capitalize">{item.voucher_code}</p>
                    </Space>
                );
                return (
                    <List.Item>
                        <Popover content={content} title="Discount">
                            <div className="!w-full flex items-center justify-between gap-4">
                                <p className="m-0 text-xs text-gray-600 capitalize line-clamp-1 w-[90%]">{item.voucher_code}</p>
                                {onRemoveVoucher ? (
                                    <MdOutlineDeleteOutline
                                        className="text-lg cursor-pointer text-red-500 !h-5 !w-5"
                                        onClick={() => onRemoveVoucher(item)}
                                    />
                                ) : null}
                            </div>
                        </Popover>
                    </List.Item>
                );
            }}
        />
    );
};

export default ListVoucher;

import React from "react";
import { SelectProps, ColProps } from "antd";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import SelectInput, { SelectOption } from "../inputs/input-select";

export type ControlledInputSelectProps<T extends FieldValues> = SelectProps & {
    placeholder: string;
    label?: string;
    control: Control<T, any>;
    name: Path<T>;
    labelCol?: ColProps;
    options?: SelectOption[];
    classNameForm?: string;
};

const ControlledSelectInput = <T extends FieldValues>({
    label,
    control,
    placeholder,
    name,
    loading,
    options,
    labelCol,
    ...rest
}: ControlledInputSelectProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <SelectInput
                    {...field}
                    {...rest}
                    label={label || ""}
                    labelCol={labelCol}
                    options={options || []}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return (option?.children ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                    }}
                    placeholder={placeholder}
                    error={error?.message}
                    value={field.value || undefined}
                    loading={loading}
                />
            )}
        />
    );
};

export default ControlledSelectInput;

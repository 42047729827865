/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Skeleton, Space } from "antd";
import ImageCard from "components/card/image";
import State from "components/common/state";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import InputFile from "components/form/inputs/input-file";
import ToolbarAction from "components/toolbar/action";
import useControlFile from "hooks/useControlFile";
import useProgressUpload from "hooks/useProgressUpload";
import { Product } from "models";
import AdvanceSearch from "modules/advance-search";
import TableAdd from "modules/master-data/package-products/add/table-add";
import { PackageProductForm } from "modules/master-data/package-products/models";
import SearchProduct from "modules/master-data/package-products/search-product";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import packageProductsService, { ChildData, EditPackage } from "services/api-endpoints/dashboard-jsl/package-products";
import { IMAGE_TYPES, PACKAGE_BUNDLING, STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<PackageProductForm>> = yup.object().shape({
    subattribute_id: yup.number().required("Subattribute package Required"),
    package_name: yup.string().required("Package Name Required"),
    package_code: yup.string().required("Package Code Required"),
    package_description: yup.string().required("Package Description Required"),
    uom: yup.string(),
    sku: yup.string().nullable(),
    status: yup.number().required("Status Required"),
    image1: yup.string().required("Image required"),
    image2: yup.string(),
    image3: yup.string(),
    image4: yup.string(),
    image5: yup.string(),
    images: yup.array(),
});

const PackageProductsEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [progress, onUploadProgress] = useProgressUpload();
    const id = searchParams.get("id");

    const [products, setProducts] = React.useState<Product[]>([]);

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = useForm<PackageProductForm>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchSubattributeId = watch("subattribute_id");

    const [controlFile] = useControlFile<PackageProductForm>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const subAttributePackageQuery = useQuery([packageProductsService.subattributePackage], async () => {
        return (await packageProductsService.SubattributePackage()).data.data;
    });

    const subAttributePackage = subAttributePackageQuery.data?.map((i) => ({ label: i.subattribute_name, value: i.id }));

    const detailQuery = useQuery(
        [packageProductsService.detail, id],
        async () => {
            return (await packageProductsService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                const { images } = data;
                form.setFieldsValue({
                    package_name: data.name,
                    package_code: data.code1,
                    package_description: data.description,
                    subattribute_id: data?.subattribute_id,
                    sku: data.sku,
                    status: data.is_active,
                    image1: images ? images[0] : "",
                    image2: images ? images[1] : "",
                    image3: images ? images[2] : "",
                    image4: images ? images[3] : "",
                    image5: images ? images[4] : "",
                });
                setValue("package_name", data.name);
                setValue("package_code", data.code1);
                setValue("package_description", data.description);
                setValue("sku", data.sku);
                setValue("status", data.is_active);
                setValue("subattribute_id", data?.subattribute_id);
                setValue("image1", images ? images[0] : "");
                setValue("image2", images ? images[1] : "");
                setValue("image3", images ? images[2] : "");
                setValue("image4", images ? images[3] : "");
                setValue("image5", images ? images[4] : "");
                setProducts(
                    data.child.map(
                        (c) =>
                            ({
                                product_id: c.id,
                                product_code: c.product_code,
                                product_name: c.product_name,
                                qty: c.qty,
                                product_price: c.price,
                            } as Product)
                    )
                );
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editMutate = useMutation(
        [packageProductsService.editPackage],
        async (data: EditPackage) => {
            return (await packageProductsService.EditPackage(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Package Products edited");
                navigate(-1);
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        if (!products.length) {
            message.error("Pick Items Products");
            return;
        }
        const parseData = {
            ...data,
            images: [data.image1, data.image2, data.image3, data.image4, data.image5].filter((el) => el),
        };

        let priceComplete = true;
        products.forEach((prd) => {
            if (prd?.product_price === null || prd?.product_price === undefined) {
                priceComplete = false;
            }
        });

        if (!priceComplete && watchSubattributeId === PACKAGE_BUNDLING) {
            message.error("Please fill all product price");
            return;
        }

        const editPackage: EditPackage = {
            id,
            name: parseData.package_name,
            code1: parseData.package_code,
            description: parseData.package_description,
            images: parseData.images as string[],
            is_active: parseData.status,
            sku: parseData.sku || "",
            subattribute_id: parseData?.subattribute_id,
            child: products.map((prd) => ({ id: prd.product_id, qty: prd.qty, price: prd.product_price } as ChildData)),
        };
        editMutate.mutate(editPackage);
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onClickDeleteProduct = (p: Product) => {
        setProducts((prev) => prev?.filter((prd) => prd.product_id !== p.product_id));
    };

    const onChangeSubAttribute = (data: any) => {
        setProducts([]);
        setValue("subattribute_id", data);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit package product"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-3 gap-4">
                    <div className="grid gap-4 h-fit">
                        <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                            {(state) => (
                                <>
                                    <State.Data state={state}>
                                        <InputFile control={controlFile} types={IMAGE_TYPES} name="image1" className="col-span-2">
                                            {(dt) => <ImageCard image={dt.file || dt.urlFile} title="main image" />}
                                        </InputFile>
                                        {["image2", "image3", "image4", "image5"].map((img) => (
                                            <InputFile key={img} control={controlFile} types={IMAGE_TYPES} name={img}>
                                                {(dt) => <ImageCard size="small" image={dt.file || dt.urlFile} title="Additional Image" />}
                                            </InputFile>
                                        ))}
                                    </State.Data>
                                    <State.Loading state={state}>
                                        <Skeleton.Image active className="!w-full !h-[300px]" />
                                    </State.Loading>
                                </>
                            )}
                        </State>
                    </div>
                    <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <h2 className="capitalize font-semibold text-gray-500 mb-4">package product information</h2>
                        <div className="grid grid-cols-2 gap-x-5">
                            <ControlledInputText control={control} name="package_name" placeholder="Package Name" />
                            <ControlledInputText control={control} name="package_code" placeholder="Package Code" />
                            <ControlledInputTextArea rows={10} control={control} name="package_description" placeholder="Package Description" />
                            <div className="flex flex-col">
                                <ControlledInputText control={control} name="uom" placeholder="UOM Package" disabled label="UOM Package" />
                                <ControlledSelectInput
                                    loading={subAttributePackageQuery.isLoading}
                                    options={subAttributePackage}
                                    control={control}
                                    name="subattribute_id"
                                    placeholder="Sub Attribute Package"
                                    label="Sub Attribute Package"
                                    onChange={onChangeSubAttribute}
                                />
                            </div>
                            <ControlledSelectInput control={control} name="status" options={STATUS_ACTIVE} placeholder="Status" />
                            <ControlledInputText control={control} name="sku" placeholder="SKU" disabled />
                        </div>
                        <div className="flex items-center gap-5 mt-10 mb-5">
                            <h2 className="capitalize font-semibold text-gray-500 m-0">items product</h2>
                            <AdvanceSearch
                                selected={products}
                                setSelected={setProducts}
                                searchProductOverrideComponent={({ selected, selectHandler, deSelectHandler }) => (
                                    <SearchProduct
                                        id={watchSubattributeId}
                                        selected={selected}
                                        onSelectProduct={selectHandler}
                                        onDeSelectProduct={deSelectHandler}
                                    />
                                )}
                            >
                                {(dt) => (
                                    <Button
                                        disabled={!watchSubattributeId}
                                        className="!flex !items-center w-fit"
                                        type="primary"
                                        onClick={dt.openModal}
                                    >
                                        <MdAdd className="mr-2" />
                                        Add Product
                                    </Button>
                                )}
                            </AdvanceSearch>
                        </div>
                        <TableAdd list={products} setList={setProducts} removeItemList={onClickDeleteProduct} subattribute={watchSubattributeId} />
                    </Form>
                </div>
            </Card>
        </div>
    );
};

export default PackageProductsEdit;

import { List, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { UseQueryResult } from "react-query";
import { DetailReturnOrder, ListDetail } from "services/api-endpoints/dashboard/master-data/return-order";
import { STOCK_ACTION } from "utils/constant";

type Props = {
    fetcher: UseQueryResult<DetailReturnOrder, unknown>;
    onClickLink: (value: string) => void;
};

const OrderTableView = ({ fetcher, onClickLink }: Props) => {
    const columns: ColumnsType<ListDetail> = [
        {
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Order Reference",
            dataIndex: "order_reference",
            render: (text) => {
                return (
                    <List.Item onClick={() => onClickLink("")} className="text-xs cursor-pointer !text-primary">
                        {text}
                    </List.Item>
                );
            },
        },
        // {
        //     title: "Order No",
        //     dataIndex: "order_no",
        //     render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        // },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty Order",
            dataIndex: "qty_order",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty Return",
            dataIndex: "qty_return",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Stock Action",
            dataIndex: "stock_action",
            render: (text) => <p className="capitalize m-0">{STOCK_ACTION.find((act) => act.value === text)?.label || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.return_order_detail_id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list_detail || []}
            className="w-full"
        />
    );
};

export default OrderTableView;

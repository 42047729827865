/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Card, message, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import PrintBPDO from "modules/dashboard-jsl/bp-delovery-order/bp-do-print";
import BPHistoryTable from "modules/dashboard-jsl/bp-delovery-order/history-table";
import ProductTable from "modules/dashboard-jsl/delivery-order/view/product-table";
import { mapDuplicateProducts2 } from "modules/master-data/bp-delivery-order/utils";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import bpDeliveryOrderService, { DeleteData, ListDetail } from "services/api-endpoints/dashboard/master-data/bp-delivery-order";
import { STATUS_BP_ORDER } from "utils/constant";
import { getDocumentRoute } from "utils/routes";

interface Props {
    paramId?: any;
    asPreview?: boolean;
    urlNamePage?: string;
}

const BPDeliveryOrderView = ({ paramId, asPreview, urlNamePage = "page" }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const page = searchParams.get(urlNamePage) || 1;
    const [products, setProducts] = useState<ListDetail[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [bpDeliveryOrderService.detail, id],
        async () => {
            return (await bpDeliveryOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(mapDuplicateProducts2(data.list_detail || []));
                // setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [bpDeliveryOrderService.historyDO, page],
        async () => {
            return (await bpDeliveryOrderService.HistoryDO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `DP Delivery Order ${detailQuery.data?.do_no}`,
        pageStyle: "@page { size: A5 landscape; }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const deleteDOMutation = useMutation(
        [bpDeliveryOrderService.deleteDO],
        async (data: DeleteData) => {
            return (await bpDeliveryOrderService.DeleteDO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Delivery order deleted");
                navigate(getDocumentRoute({ menuName: "delivery-order", page: "index" }), { replace: true });
            },
        }
    );

    const onDeleteDOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deleteDOMutation.mutate(dataDelete);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="BP Delivery order detail"
                rightAddition={() => (
                    <>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print DO
                        </Button>
                        {/* <ModalDelete title="Delete SO" onSubmit={onDeleteDOHandler}>
                            {(dt) => (
                                <Button
                                    loading={deleteDOMutation.isLoading}
                                    disabled={detailQuery.data?.order_status === 2 || detailQuery.data?.order_status === -1}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete> */}
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        delivery order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.do_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        driver name <br />
                                        <span className="underline italic m-0">{detailQuery.data?.driver_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        plate number <br />
                                        <span className="underline italic m-0">{detailQuery.data?.plate_number}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-500" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_BP_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable urlNamePage={urlNamePage} loading={detailQuery.isLoading} list={products} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <BPHistoryTable
                                    urlNamePage={urlNamePage}
                                    fetcher={history}
                                    onClickLink={(id) => {
                                        setDetailHistoryID(id);
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </Card>
            <PrintBPDO ref={letterRef} data={detailQuery.data || null} />
        </div>
    );
};

export default BPDeliveryOrderView;

/* eslint-disable react/no-children-prop */
import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { EDIT_PATH, PARTNER_TYPE } from "utils/constant";
import ButtonAccessEdit from "components/button/button-access-edit";
import { TDataBP } from "../models";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const MasterBPTable = <T extends TDataBP>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: TDataBP) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Partner Code",
            dataIndex: "partner_code",
            render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Partner Type",
            dataIndex: "partner_type",
            render: (text, record) => <p className="capitalize m-0">{PARTNER_TYPE.find((el) => el.value === text)?.label}</p>,
        },
        {
            title: "Representative/Agent",
            dataIndex: "business_partner_name",
            render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Credit Status",
            dataIndex: "credit_status",
            render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Credit Limit",
            dataIndex: "credit_limit",
            render: (text, record) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
        },
        {
            title: "Open Balance",
            dataIndex: "open_balance",
            render: (text, record) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
        },
        {
            title: "Tax ID",
            dataIndex: "tax_id",
            render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Payment Term",
            dataIndex: "payment_term_name",
            render: (text, record) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "80px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            scroll={{ x: 1400 }}
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default MasterBPTable;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import PriceTag from "modules/manufacture/components/price-tag";
import TotalItemInput from "modules/manufacture/components/total-item-input";
import { Entities, ManufactureContext } from "modules/manufacture/context/context";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity, ProcessEntity, VariableEntity } from "modules/manufacture/utils/models";
import React, { useMemo } from "react";
import CardMaterialDraw, { CardMaterialDrawProps } from "../material/draw";
import CardVariableDraw, { CardVariableDrawProps } from "../variable/draw";
import CodeTag from "modules/manufacture/components/code-tag";

type ChildrenSubProcessProps = CardMaterialDrawProps &
    CardVariableDrawProps & {
        entity: Entity<MaterialEntity & VariableEntity>;
        parentEntity: Entity<ProcessEntity<Entity<MaterialEntity & VariableEntity>>>;
        isOverNewItem?: boolean;
        isOverOldItem?: boolean;
        isOver?: boolean;
        index?: number;
        totalChild?: number;
        onMoveChild?: (hoverIndex: number, item: Entity<MaterialEntity>) => void;
        indexEffectedItem?: number[];
    };

const ChildrenSubProcess = ({
    entity,
    parentEntity,
    onMoveChild,
    index = 0,
    totalChild = 0,
    isOverNewItem,
    isOverOldItem,
    isOver,
    indexEffectedItem,
    ...props
}: ChildrenSubProcessProps) => {
    const { setEntities } = React.useContext(ManufactureContext) as any;

    const removeChildren = (item: Entity) => {
        setEntities((entities: Entity<ProcessEntity<Entity<MaterialEntity & VariableEntity>>>[]) =>
            entities?.map((ent) => {
                if (parentEntity?.idEntity !== ent?.idEntity) return ent;
                return {
                    ...ent,
                    data: {
                        ...ent?.data,
                        children: ent?.data?.children?.filter((child) => child?.idEntity !== item?.idEntity),
                    },
                };
            })
        );
    };

    const onChangeTotal = (total: number) => {
        setEntities((prev: Entities[]) =>
            prev.map((ent) => {
                if (ent?.idEntity !== parentEntity?.idEntity) return ent;
                return {
                    ...ent,
                    data: {
                        ...ent.data,
                        children: ent.data?.children?.map((child) => {
                            const itemChild = child as unknown as Entity<MaterialEntity & VariableEntity>;
                            if (itemChild?.idEntity !== entity?.idEntity) return child;
                            return {
                                ...itemChild,
                                data: {
                                    ...itemChild.data,
                                    total,
                                    temp_total: 0,
                                },
                            };
                        }),
                    },
                };
            })
        );
    };

    const cardProps = {
        index,
        indexEffectedItem,
        totalChild,
        isOverOldItem,
        onMoveChild,
        className: "h-full w-full",
        title: entity?.data?.text,
        removeChildren,
        attach: { ...entity, idEntityParent: parentEntity?.idEntity },
    };

    const onChange = (total: number) => {
        setEntities((ents: Entities[]) => {
            return ents.map((ent) => {
                if (ent.idEntity !== parentEntity.idEntity) return ent;
                return {
                    ...ent,
                    data: {
                        ...ent?.data,
                        children: ent?.data?.children?.map((child) => {
                            if (child.idEntity !== entity?.idEntity) return child;
                            return {
                                ...child,
                                data: {
                                    ...child?.data,
                                    price: total,
                                },
                            };
                        }),
                    },
                };
            });
        });
    };

    if (entity?.type === MenuTypes.material.type) {
        return (
            <CardMaterialDraw {...cardProps} {...props}>
                <div className="flex flex-col w-full px-2 h-full max-w-[200px]">
                    <div className="flex items-center gap-2">{!!totalChild && <TotalItemInput entity={entity} onChange={onChangeTotal} />}</div>
                    <span className="line-clamp-2 text-xs mt-1">{entity?.data?.text || "-"}</span>
                </div>
            </CardMaterialDraw>
        );
    }

    if (entity?.type === MenuTypes.variable.type) {
        return (
            <CardVariableDraw {...cardProps} {...props}>
                <div className="flex flex-col w-full px-2 h-full max-w-[200px]">
                    <div className="flex items-center gap-2">
                        <PriceTag onChange={onChange} entity={entity} inputMode price={entity?.data?.price} />
                        {!!totalChild && (
                            <>
                                <span className="text-semibold text-xs text-gray-800">x</span>
                                <TotalItemInput entity={entity} onChange={onChangeTotal} />
                            </>
                        )}
                    </div>
                    <span className="line-clamp-2 text-xs mt-1">{entity?.data?.text || "-"}</span>
                </div>
            </CardVariableDraw>
        );
    }

    return <div />;
};

export default ChildrenSubProcess;

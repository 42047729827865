import { InputNumber, List, Select, Table, TableProps } from "antd";
import { ColumnType } from "antd/lib/table";
import { SelectOptionCustom } from "modules/master-data/price/lib/warehouse-select";
import { UseQueryResult } from "react-query";
import { ProductTransfer } from "services/api-endpoints/dashboard/master-data/material-movement";

type Props<T> = TableProps<T> & {
    onEditChange?: (record: T) => void;
    locatorFetcher: UseQueryResult<SelectOptionCustom[], unknown>;
    customColumns?: ColumnType<any>[];
    removeColumns?: string[];
};

const TableTransferEdit = <T extends ProductTransfer>({ onEditChange, customColumns, removeColumns, locatorFetcher, ...props }: Props<T>) => {
    const onChangeQty = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                qty_to: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const onChangeLocator = (record: T) => {
        return (val: any) => {
            const item = {
                ...record,
                locator_to: val,
            };
            if (onEditChange) onEditChange(item);
        };
    };

    const columns: ColumnType<T>[] = [
        {
            dataIndex: "product_code",
            title: "Code",
            render: (text) => text,
        },
        {
            dataIndex: "product_name",
            title: "Name",
            render: (text) => text,
        },
        {
            title: "Qty",
            width: "20px",
            dataIndex: "qty_to",
            render: (qtyTo, record) => (
                <InputNumber value={qtyTo} onChange={onChangeQty(record)} min={1} max={record?.qty_from || 1} className="!w-[80px]" />
            ),
        },
        {
            title: "Sugestion Locator",
            width: "140px",
            dataIndex: "suggestion_locator",
            render: (_, record) => (
                <List
                    size="small"
                    bordered
                    dataSource={record?.suggestion_locator?.split(",")}
                    renderItem={(item) => <List.Item className="text whitespace-nowrap text-xs">{item}</List.Item>}
                />
            ),
        },
        {
            title: "Locator To",
            width: "120px",
            dataIndex: "locator_to",
            render: (_, record) => (
                <Select
                    value={record?.locator_to}
                    showSearch
                    placeholder="Locator To"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                    className="!w-[150px]"
                    loading={locatorFetcher.isLoading}
                    options={locatorFetcher.data || []}
                    onChange={onChangeLocator(record)}
                />
            ),
        },
    ];

    const getColumns = (): ColumnType<T>[] => {
        let deletedColumns = columns;
        if (removeColumns) {
            deletedColumns = columns.filter((col) => !removeColumns.includes(col.dataIndex as string));
        }
        return [...deletedColumns, ...(customColumns || [])];
    };

    return <Table size="small" columns={getColumns()} {...props} />;
};

export default TableTransferEdit;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterGrInventory from "modules/purchasing/inventory-gr/filter";
import TableIndex from "modules/purchasing/inventory-gr/table-index";
import FilterPurchasingInventory from "modules/purchasing/inventory/index/filter";
import PurchasingInventoryTable from "modules/purchasing/inventory/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingInventoryService, { PurchasingInventory as PIType } from "services/api-endpoints/purchasing/inventory";
import { DOC_TYPE_INVENTORY } from "utils/constant";

const filter = ["page", "start_date", "end_date", "docType", "status", "vendor_id"];

const GrInventory = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([purchasingInventoryService.grList, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await purchasingInventoryService.GrList({ ...filterValue, ...searchValue })).data.data;
    });

    const deleteModelType = useMutation(async (data: PIType) => (await purchasingInventoryService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterGrInventory filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Search Doc No/PO No/Request By ..."
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        add={false}
                    />
                )}
            </FilterGrInventory>
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default GrInventory;

import Layout from "components/common/layout";
import Toolbar from "components/toolbar";
import React from "react";
import { useLocation } from "react-router-dom";

const TestPage = () => {
    const location = useLocation();

    return <p>test</p>;
};

export default TestPage;

import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserRoleMenuList } from "services/api-endpoints/dashboard-all/user-management/user";
import { EDIT_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const TableIndex = <T extends UserRoleMenuList>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: UserRoleMenuList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.user_id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Email",
            dataIndex: "email",
            render: (text) => <p className="m-0">{text || "-"}</p>,
        },
        {
            title: "List Apps",
            dataIndex: "apps_name",
            render: (text) => {
                const menu = text?.split(",");
                return (
                    <div>
                        {menu?.map((val: any) => (
                            <Tag color="blue">{val}</Tag>
                        ))}
                    </div>
                );
            },
        },
        // {
        //     width: "100px",
        //     title: "Is Active",
        //     dataIndex: "is_active",
        //     render: (text) => <p className="capitalize m-0">{text ? "Active" : "Non active"}</p>,
        // },
        {
            width: "70px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.user_id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 20,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableIndex;

/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export enum Type {
    Debit = "DEBIT",
    Kredit = "KREDIT",
}

export interface Child {
    name?: string;
    type?: Type;
    amount?: number;
    coa_id?: number;
    is_edited?: boolean;
    id_detail?: number;
}

// eslint-disable-next-line prettier/prettier
export interface JurnalUmumChild extends Child {}

export interface JurnalUmum {
    id?: number;
    doc_id?: number;
    doc_no?: any;
    doc_type?: number;
    type?: number;
    journal_no?: string;
    is_posting?: boolean;
    is_acc_posted?: number;
    is_fn_posted?: number;
    note?: string;
    status?: number;
    created_at?: string;
    child?: Child[];
    is_edited?: boolean;
    ref_id?: number;
    ref_no?: string;
    ref_type?: number;
    account?: string;
}

export interface FilterJurnalUmum {
    start_date?: any;
    end_date?: any;
    type?: any;
    query?: any;
}

export interface BaseJurnalResponse<T> {
    data: any;
    list_unposted: T[];
    list_posted: T[];
    list_draft: T[];
}

export interface BaseGitResponse<T> {
    data: any;
    list: T[];
}

export interface UpdateJournalData {
    type?: any;
    doc_id?: any[];
}

export interface EditJournalChild {
    coa_id: number;
    debit: number | null;
    kredit: number | null;
    name: string;
    note: any;
}

export interface EditJournal {
    id: any;
    child: EditJournalChild[];
}

export interface BodyChildJurnalUmum {
    coa_id?: number;
    name?: string;
    debit?: number;
    kredit?: number;
    note?: string;
}

export interface BodyCreateJurnalUmum {
    id?: any;
    transaction_date?: string;
    child?: BodyChildJurnalUmum[];
}

export interface DetailJurnalUmum {
    id?: number;
    is_fn_posted?: number;
    is_acc_posted?: number;
    is_active?: number;
    journal_no?: string;
    transaction_date?: string;
    child?: BodyChildJurnalUmum[];
}

export interface AccountData {
    account_id?: number;
    account_name?: string;
    account_code?: string;
}

class JurnalUmumService extends BaseService {
    getAll = "/report-accounting/list-journal";

    filter = "/report-accounting/filter-journal";

    filterGoodsInTransit = "/report-accounting/filter-goods-transit";

    updateStatusJournal = "report-accounting/update-status-journal";

    editJournal = "/report-accounting/edit-journal";

    deletePenyesuaian = "report-accounting/delete-penyesuaian";

    create = "/report-accounting/create-jurnal-umum";

    createDraft = "/report-accounting/save-draft-jurnal-umum";

    detail = "/report-accounting/detail-jurnal-umum";

    getAccount = "/report-accounting/get-account";

    GetAccount<T extends AccountData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAccount,
            });
            return req;
        });
    }

    Detail<T extends DetailJurnalUmum>(params: { id?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: BodyCreateJurnalUmum) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    CreateDraft<T extends any>(data: BodyCreateJurnalUmum) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDraft,
                data,
            });
            return req;
        });
    }

    DeletePenyesuaian<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deletePenyesuaian}/${id}`,
            });
            return req;
        });
    }

    EditJournal<T extends any>(data: EditJournal) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.editJournal,
                data,
            });
            return req;
        });
    }

    GetAll<T extends JurnalUmum>(params: { page: any }) {
        return this.ProxyRequest<BaseJurnalResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseJurnalResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Filter<T extends JurnalUmum>(param: FilterJurnalUmum) {
        return this.ProxyRequest<BaseJurnalResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseJurnalResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    FilterGoodsInTransit<T extends JurnalUmum>(param: FilterJurnalUmum) {
        return this.ProxyRequest<BaseGitResponse<T>>(async () => {
            const req = await ApiMethod.get<BaseGitResponse<T>>({
                url: this.filterGoodsInTransit,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    UpdateStatusJournal<T extends any>(data: UpdateJournalData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.updateStatusJournal,
                data,
            });
            return req;
        });
    }
}

const jurnalUmumService = new JurnalUmumService();
export default jurnalUmumService;

/* eslint-disable no-unneeded-ternary */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import pemakaianBarangService, { SuppliesPK } from "services/api-endpoints/dashboard/master-data/pemakaian-barang";
import * as yup from "yup";

export type Props = ModalProps & {
    onSubmit: (item: SuppliesPK) => void;
    selected: SuppliesPK[];
};

const schema: yup.SchemaOf<Partial<SuppliesPK>> = yup.object().shape({
    supplies_id: yup.number().required("Supplies Required"),
    name: yup.string(),
    uom_id: yup.number().required("UOM Of Supplies Required"),
    uom_code: yup.string(),
    qty: yup.number().required("Quantity Required"),
    qty_max: yup.string(),
});

const ModalAddSupplies = ({ onSubmit, children, selected, ...props }: Props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const buttonOpenRef = React.useRef<HTMLButtonElement>(null);

    const [form] = Form.useForm();
    const { setValue, handleSubmit, control, watch } = useForm<SuppliesPK>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchQty = watch("qty");

    const getSupplies = useQuery([pemakaianBarangService.getSupplies], async () => {
        return (await pemakaianBarangService.GetSupplies()).data.data;
    });

    const pickedProductId = selected.map((el) => Number(el.supplies_id));
    const availProducts = getSupplies.data?.map((el) => ({
        label: el.name!,
        value: el.supplies_id!,
        disabled: pickedProductId.includes(Number(el.supplies_id!)),
    }));

    const onSubmitHandler = handleSubmit((data) => {
        onSubmit(data);
        if (buttonRef.current) {
            buttonRef.current?.click();
        }
    });
    const resetForm = () => {
        setValue("supplies_id", 0);
        setValue("qty", 0);
        setValue("uom_id", 0);
        form.setFieldsValue({});
    };

    return (
        <ModalTemplate
            width={900}
            title="Add Item Supplies"
            {...props}
            afterClose={() => {
                resetForm();
                if (props?.afterClose) props?.afterClose();
            }}
            handlerInComponent={children}
            footer={null}
        >
            {(dt) => (
                <div className="w-full">
                    <button ref={buttonRef} onClick={dt.closeModal} type="button" className="hidden">
                        close
                    </button>
                    <button ref={buttonOpenRef} onClick={dt.openModal} type="button" className="hidden">
                        open
                    </button>
                    <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="flex flex-col gap-1">
                            <div className="grid grid-cols-4 items-center gap-x-7">
                                <ControlledSelectInput
                                    showSearch
                                    classNameForm="w-full col-span-2"
                                    allowClear
                                    control={control}
                                    name="supplies_id"
                                    placeholder="Supplies Name"
                                    label="Supplies Name"
                                    loading={getSupplies.isLoading}
                                    options={availProducts}
                                    onChange={(val) => {
                                        const temp = getSupplies.data?.find((val2) => val === val2.supplies_id);
                                        setValue("supplies_id", temp?.supplies_id || 0);
                                        setValue("name", temp?.name || "");
                                        setValue("uom_id", temp?.uom_id || 0);
                                        setValue("uom_code", temp?.uom_code || "");
                                        setValue("qty", 1);
                                        setValue("qty_max", temp?.qty || 0);
                                    }}
                                />
                                <ControlledInputText
                                    classNameForm="w-full col-span-1"
                                    control={control}
                                    name="uom_code"
                                    placeholder="UOM Of Supplies"
                                    label="UOM Of Supplies"
                                    disabled
                                />
                                <ControlledInputText
                                    classNameForm="w-full col-span-1"
                                    control={control}
                                    type="number"
                                    name="qty_max"
                                    placeholder="Quantity Warehouse"
                                    label="Quantity Warehouse"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="flex justify-between mt-8">
                            <p />
                            <Button htmlType="submit" className="self-end" type="primary">
                                Add Supplies
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalAddSupplies;

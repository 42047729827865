import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterDeliveryOrder from "modules/dashboard-jsl/delivery-order/index/filter";
import DeliveryOrderTable from "modules/dashboard-jsl/delivery-order/index/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import deliveryOrderService from "services/api-endpoints/dashboard-jsl/delivery-order";

const filter = ["po_no", "so_no", "do_no", "invoice_no", "request_to", "status"];

const DeliveryOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([deliveryOrderService.getAll, page, searchValue, isSearch, isFilter, filterValue], async () => {
        if (isSearch) return (await deliveryOrderService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await deliveryOrderService.Filter(filterValue as any)).data.data;
        return (await deliveryOrderService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterDeliveryOrder filter={filter}>
                {(dt) => <Toolbar placeholder="DO No / SO No" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterDeliveryOrder>
            <DeliveryOrderTable fetcher={getList} />
        </div>
    );
};

export default DeliveryOrder;

import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessView from "components/button/button-access-view";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PurchasingInventory } from "services/api-endpoints/purchasing/inventory";
import {
    EDIT_PATH,
    FORMAT_DATE_1,
    STATUS_PURCHASING,
    STATUS_PURCHASING_COMPLETED,
    STATUS_PURCHASING_DRAFT,
    STATUS_PURCHASING_VOID,
    VIEW_PATH,
} from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const PurchasingInventoryTable = <T extends PurchasingInventory>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: PurchasingInventory) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: PurchasingInventory) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const COLOR_TAG = ["magenta", "red", "gold", "cyan", "blue", "purple"];

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 15 + (i + 1)}</p>,
        },
        {
            title: "PO No",
            dataIndex: "po_no",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Trx Date",
            dataIndex: "transaction_date",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_1) : "-"}</p>,
        },
        {
            title: "Category",
            dataIndex: "doc_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Vendor",
            dataIndex: "vendor",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Payment Term",
            dataIndex: "payment_term",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Grand price",
            dataIndex: "grand_price",
            render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Request By",
            dataIndex: "request_by",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Doc Status",
            dataIndex: "doc_status",
            render: (text) => {
                const type = STATUS_PURCHASING.find((t) => t.value === text);
                if (!type) return "-";
                if (type.value === STATUS_PURCHASING_VOID) return <Tag color="black">{type?.label}</Tag>;
                if (type.value === STATUS_PURCHASING_COMPLETED) return <Tag color="green">{type?.label}</Tag>;
                return <Tag color={COLOR_TAG[type?.value || 0]}>{type?.label}</Tag>;
            },
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.doc_status === STATUS_PURCHASING_DRAFT ? (
                        <>
                            <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                            <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        </>
                    ) : (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 15,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default PurchasingInventoryTable;

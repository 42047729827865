/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseHistory, FilterDataQuantity } from "modules/master-data/quantity/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListArea {
    area_id: number;
    area_code: string;
    qty: number;
}
export interface ProductInfo {
    name: string;
    product_code: string;
    product_desc: string;
    molding_code: string;
    uom_id: string;
    uom_name: string;
    list_area: ListArea[];
}

export interface ProductHistoryParams {
    product_id: any;
    area_id: any;
    page: any;
    warehouse_id: any;
}

export interface History {
    id: number;
    doc_id: number;
    doc_no: string;
    doc_type: string;
    doc_type_id: number;
    qty: number;
    qty_after: number;
    qty_before: number;
    created_by: string;
    created_at: string;
    sales_type: number;
}

export interface DocHistory {
    created_at: string;
    created_by: string;
    doc_detail_id: number;
    product_code: string;
    product_name: string;
    qty: number;
}

export interface DocDetail {
    list_history: DocHistory[];
    current_page: number;
    total_data: number;
    total_page: number;
}

export interface AreaData {
    area_code: string;
    area_id: number;
}

export interface ListStockProductParams {
    product_id?: any;
    warehouse_id?: any;
    in_area_id?: any;
    ex_area_id?: any;
}

export interface ListStockLocatorParams {
    warehouse_id?: any;
    in_area_id?: any;
}

export interface ListFastMoving {
    warehouse_id?: any;
    start_date?: any;
    end_date?: any;
}

export interface ListStock {
    area_id?: number;
    area_code?: string;
    qty?: number;
}
export interface ListStockResponse {
    product_id?: number;
    code1?: string;
    molding_code?: string;
    name?: string;
    id?: string;
    uom_name?: string;
    list_stock?: ListStock[];
}

export interface FastMovingProduct {
    id?: any;
    code1?: string;
    product_name?: string;
    subcategory_name?: string;
    attribute_name?: string;
    total_qty?: number;
    unit_price?: string;
    locator?: string;
    current_stock?: number;
}

class QuantityService extends BaseService {
    getAll = "/quantity-product/all-quantity";

    search = "/quantity-product/search-quantity";

    filter = "/quantity-product/filter-quantity";

    allArea = "/quantity-product/get-all-area";

    getProductInfo = "/quantity-product/get-product-info";

    getProductHistory = "/quantity-product/get-product-history";

    getDocDetail = "quantity-product/get-doc-detail";

    setActiveProduct = "/quantity-product/set-active-product";

    listStockProduct = "/quantity-product/list-stock-product";

    listStockLocator = "/quantity-product/list-stock-locator";

    listFastMoving = "/quantity-product/list-fast-moving";

    constructor() {
        super();
    }

    ListFastMoving<T extends FastMovingProduct[]>(params: ListFastMoving) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listFastMoving,
                config: { params },
            });
            return req;
        });
    }

    ListStockLocator<T extends ListStockResponse[]>(params: ListStockLocatorParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listStockLocator,
                config: { params },
            });
            return req;
        });
    }

    ListStockProduct<T extends ListStockResponse[]>(params: ListStockProductParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listStockProduct,
                config: { params },
            });
            return req;
        });
    }

    SetActiveProduct<T>(params: { product_id?: any; area_id?: any; warehouse_id?: any; is_active?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setActiveProduct,
                config: { params },
            });
            return req;
        });
    }

    GetDocDetail<T extends DocDetail>(param: { doc_id: any; doc_type_id: any; page: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDocDetail,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetProductHistory<T extends History>(param: ProductHistoryParams) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.getProductHistory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetProductInfo<T extends ProductInfo>(param: { product_id: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductInfo,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataQuantity>(param: { page: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataQuantity>(param: { page: any; param_search: any; warehouse_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataQuantity>(param: FilterDataQuantity) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    AllArea<T extends AreaData[]>(param: { warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.allArea,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const quantityService = new QuantityService();
export default quantityService;

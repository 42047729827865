/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface UploadData {
    file: any;
    warehouse_id: number;
}

export interface DetailProduct {
    id?: any;
    detail_id?: number;
    note?: string;
    no_koli?: string;
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: null;
    molding_code?: string;
    area_id?: number;
    area_code?: string;
    qty?: number;
    is_mm?: number;
}

export interface CreateDocContainer {
    note?: string;
    warehouse_id?: number;
    detail_product?: DetailProduct[];
}

export interface DetailDocContainer {
    id?: number;
    do_no?: string;
    note?: string;
    warehouse_id?: number;
    warehouse_name?: string;
    order_status?: number;
    created_at?: number;
    account_from?: string;
    address_from?: string;
    account_to?: string;
    address_to?: string;
    detail_product?: DetailProduct[];
}

export interface UploadDataResponse {
    note?: string;
    warehouse_id?: number;
    warehouse_name?: string;
    detail_product?: DetailProduct[];
}

export interface ListDocContainer {
    id?: number;
    do_no?: string;
    note?: string;
    wh_to?: string;
    order_status?: number;
    created_by?: string;
    created_at?: number;
}

export interface DetailKoliProduct {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    qty?: number;
    no_koli?: string;
}
export interface DetailKoli {
    do_no?: string;
    no_koli?: string;
    list_product?: DetailKoliProduct[];
}

export interface DetailListKoli {
    do_no?: string;
    list_product?: DetailKoliProduct[];
}

class DocContainerService extends BaseService {
    upload = "/container/upload-file";

    create = "/container/create-do";

    list = "/container/get-list";

    detail = "/container/detail";

    detailKoli = "/container/detail-koli";

    detailListKoli = "/container/detail-list-koli";

    constructor() {
        super();
    }

    DetailListKoli<T extends DetailListKoli>(params: { doc_id?: any[] }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailListKoli,
                config: { params },
            });
            return req;
        });
    }

    DetailKoli<T extends DetailKoli>(params: { doc_id?: any; no_koli?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailKoli,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends DetailDocContainer>(params: { id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    List<T extends ListDocContainer>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.list,
                config: { params },
            });
            return req;
        });
    }

    Upload<T extends UploadDataResponse>(data: UploadData, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.upload,
                data,
                config: {
                    ...config,
                    headers: { "Content-Type": "multipart/form-data" },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDocContainer) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }
}

const docContainerService = new DocContainerService();
export default docContainerService;

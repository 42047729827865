/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { CreateDataStyle, DetailDataStyle, EditDataStyle, FilterDataStyle } from "modules/master-data/style/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface AllStyle {
    id: number;
    style_name: string;
    style_code: string;
}

class StyleService extends BaseService {
    getAll = "/master-style/all-style";

    delete = "/master-style/delete-style";

    search = "/master-style/search-style";

    suggestStyleName = "/master-style/suggest-style-name";

    suggestStyleCode = "/master-style/suggest-style-code";

    filter = "/master-style/filter-style";

    create = "/master-style/create-style";

    detail = "/master-style/get-detail-style";

    edit = "/master-style/edit-style";

    getAllStyle = "/master-style/get-all-style";

    constructor() {
        super();
    }

    GetAllStyle<T extends AllStyle[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllStyle,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataStyle>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataStyle>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestStyleName<T extends string[]>(param: { style_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestStyleName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestStyleCode<T extends string[]>(param: { style_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestStyleCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataStyle>(param: FilterDataStyle) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataStyle, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataStyle>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataStyle, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const styleService = new StyleService();
export default styleService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListPurchaseOrder {
    id: number;
    doc_no: string;
    partner_name: string;
    order_status: number;
    created_at: number;
}

export interface ProductPO {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    qty_stock: number;
}

export interface DetailPO {
    id: number;
    doc_no: string;
    ref_no: string;
    ref_id?: number;
    ref_type: number;
    partner_name: string;
    order_status: number;
    created_at: string;
    note: string;
    grand_total: number;
    total_price: number;
    discount_price: number;
    list_detail: ProductPO[];
}

class ExtPurchaseOrderService extends BaseService {
    listAll = "/ext-order/list-po";

    detail = "/ext-order/detail-po";

    constructor() {
        super();
    }

    Detail<T extends DetailPO>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    ListAll<T extends ListPurchaseOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const extPurchaseOrderService = new ExtPurchaseOrderService();
export default extPurchaseOrderService;

import { Alert, message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import salesService, { Sales } from "services/api-endpoints/dashboard-all/sales";
import SalesTable from "modules/dashboard-all/sales/index/table";
import FilterSales from "modules/dashboard-all/sales/index/filter";

const filter = ["sales_order_no", "invoice_no", "start_date", "end_date", "marketplace_id", "customer_name", "status"];

const SalesPage = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([salesService.getAll, page, size, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await salesService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await salesService.Filter(filterValue as any)).data.data;
        return (await salesService.GetAll({ page, size })).data.data;
    });

    const deleteMutation = useMutation(async (data: Sales) => (await salesService.DeleteDraft({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<Sales>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterSales filter={filter}>
                {(dt) => (
                    <Toolbar placeholder="Doc No / Inv No / Customer Name" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />
                )}
            </FilterSales>
            <SalesTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default SalesPage;

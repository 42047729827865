/* eslint-disable no-plusplus */
import React, { useRef, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import bpDeliveryOrderService, { ListPL, ProductSO } from "services/api-endpoints/dashboard/master-data/bp-delivery-order";
import { mapDuplicateProducts } from "modules/master-data/bp-delivery-order/utils";

type Props<T> = {
    warehouse: number | null;
    selectedSO: React.Key[];
    setSelectedSO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductSO: (data: ProductSO[], dataReal: ProductSO[]) => void;
    children: (data: HandlerProps) => React.ReactNode;
    setTopId: React.Dispatch<React.SetStateAction<number | null>>;
};

const ModalBPDOTable = <T extends ListPL>({ children, selectedSO, setSelectedSO, warehouse, onProductSO, setTopId }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const listPOQuery = useQuery(
        [bpDeliveryOrderService.listPL, warehouse],
        async () => {
            return (await bpDeliveryOrderService.ListPL<T[]>({ bp_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    const detailProductPOMutation = useMutation([bpDeliveryOrderService.detailPL], async (ids: string) => {
        return (await bpDeliveryOrderService.DetailPL({ pl_id: ids })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const termsId = listPOQuery.data?.filter((po) => newSelectedRowKeys.includes(po.pl_id as any))?.map((po) => po.payment_term_id);
        if ([...new Set(termsId)].length !== 1) {
            setCanSubmit(false);
        } else {
            setCanSubmit(true);
            setTopId(termsId !== undefined ? termsId[0]! : null);
        }
        setSelectedSO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedSO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "PL No",
            dataIndex: "pl_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Term Of Payment",
            dataIndex: "payment_term_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     render: (text) => <p className="capitalize m-0">{text ? "Open" : "Close"}</p>,
        // },
    ];

    const onOkHandler = () => {
        detailProductPOMutation
            .mutateAsync(selectedSO.join(","))
            .then((res) => {
                onProductSO(mapDuplicateProducts(res), res);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title="BP Packing List"
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: detailProductPOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Selected picking list with same TOP (Term Of Payment)</p>
                    <Table
                        rowKey={(i) => i?.pl_id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listPOQuery.data || []}
                        loading={listPOQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalBPDOTable;

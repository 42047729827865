import { Input, Form, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: () => void;
};

const ModalLaststock = ({ onSubmit, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();

    const onSubmitHandler = () => {
        onSubmit();
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col items-end">
                            <h4 className="m-0 !w-full font-semibold">Apakah anda yakin untuk merubah SO ini menjadi Last Stock</h4>
                            <Button type="primary" className="items-center mt-8" onClick={onSubmitHandler}>
                                Set Last Stock
                            </Button>
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalLaststock;

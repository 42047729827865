import Toolbar from "components/toolbar";
import TableIndex from "modules/master-data/external-order/ext-po/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import extPurchaseOrderService from "services/api-endpoints/dashboard/master-data/ext-purchase-order";

const ExtPurchaseOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const size = searchParams.get("pageSize") || 10;

    const getList = useQuery([extPurchaseOrderService.listAll, page], async () => {
        return (await extPurchaseOrderService.ListAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Doc No" filter={false} add={false} />
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default ExtPurchaseOrder;

/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Button, Form, InputNumber, Radio, RadioChangeEvent, Space } from "antd";
import Table, { ColumnType } from "antd/lib/table";
import { BOMWithLocatorAndNote } from "modules/manufacturing-order/table";
import { ProductWithBOM } from "pages/purchasing-order-m/add";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BOM, MultipleChildBOM } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";

type Props = {
    list: ProductWithBOM[];
    setProducts: React.Dispatch<React.SetStateAction<ProductWithBOM[]>>;
};

function TablePOMAdd({ list, setProducts }: Props) {
    type TransformListToBOMCentris = BOM &
        MultipleChildBOM & {
            product: ProductWithBOM;
            numberChild: number;
        };

    const [productEdit, setProductEdit] = React.useState<TransformListToBOMCentris | null>(null);

    const transformList = list.reduce((arr: TransformListToBOMCentris[], product: ProductWithBOM) => {
        const mapping = product.child?.map((child, i) => ({
            ...child,
            product,
            numberChild: i,
        })) as TransformListToBOMCentris[];
        return [...arr, ...mapping];
    }, []);

    const onCHangeRadioSelectChild = (record: TransformListToBOMCentris) => {
        return (e: RadioChangeEvent) => {
            const selectedChild = record.child.find((c) => c.product_id === e.target.value);
            setProducts((prev) => {
                return prev.map((product) => {
                    return {
                        ...product,
                        child: product.child?.map((child: BOM | MultipleChildBOM) => {
                            if ("child" in child) {
                                return {
                                    ...child,
                                    child: child.child.map((p) => {
                                        return {
                                            ...p,
                                            selected: p.product_id === selectedChild?.product_id,
                                        };
                                    }),
                                };
                            }
                            return child;
                        }),
                    };
                }) as ProductWithBOM[];
            });
        };
    };

    const onEdit = (record: TransformListToBOMCentris | null) => {
        return () => {
            setProductEdit(record);
        };
    };

    const onDeleteProduct = (record: TransformListToBOMCentris) => {
        return () => {
            setProducts((prev) => {
                return prev?.filter((product) => product.product_id !== record.product.product_id);
            });
        };
    };

    const onDeleteChild = (record: TransformListToBOMCentris) => {
        return () => {
            setProducts((prev) => {
                return prev
                    ?.map((product) => {
                        if (product.product_id !== record.product.product_id) return product;
                        const childRemoved = {
                            ...product,
                            child: product.child?.filter((child) => {
                                if (!("product_id" in record)) {
                                    if (!("product_id" in child)) {
                                        return false;
                                    }
                                }

                                if ("product_id" in record) {
                                    if ("product_id" in child) {
                                        if (record.product_id !== child.product_id) return true;
                                        return false;
                                    }
                                }

                                return true;
                            }),
                        };
                        return childRemoved;
                    })
                    .filter((product) => product.child?.length);
            });
        };
    };

    const disabledRowEdit = (record: TransformListToBOMCentris) => {
        if (!productEdit) return false;
        return record.product?.product_id !== productEdit?.product?.product_id;
    };

    const onFinish = (values: { qty: number }) => {
        setProducts((prev) => {
            return prev.map((product) => {
                if (product.product_id !== productEdit?.product.product_id) return product;
                return {
                    ...product,
                    qty: values.qty,
                    child: product.child?.map((child: BOM | MultipleChildBOM) => {
                        if ("child" in child && child.child.length) {
                            return {
                                child: child.child.map((c) => {
                                    return {
                                        ...c,
                                        qty_bom: (c.qty_bom_temp || 1) * values.qty,
                                    };
                                }),
                            };
                        }

                        return {
                            ...child,
                            qty_bom: ((child as BOM).qty_bom_temp || 1) * values.qty,
                        };
                    }),
                };
            }) as ProductWithBOM[];
        });
        setProductEdit(null);
    };

    const childColumns: ColumnType<TransformListToBOMCentris>[] = [
        {
            title: "Code WIP",
            dataIndex: "code1",
            render: (val, record) => {
                const disabled = disabledRowEdit(record);
                if ("child" in record) {
                    record as TransformListToBOMCentris;
                    if (record.child.length) {
                        const selectedChild = record.child.find((c) => c.selected);
                        return (
                            <Radio.Group
                                className={!selectedChild ? "warning-not-selected" : ""}
                                disabled={disabled}
                                onChange={onCHangeRadioSelectChild(record)}
                                value={selectedChild?.product_id}
                            >
                                {record.child?.map((child) => (
                                    <Radio key={child.product_id} value={child.product_id}>
                                        {child.code1}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        );
                    }
                }
                return val;
            },
        },
        {
            title: "Quantity",
            dataIndex: "qty_bom",
            render(val, record) {
                if ("child" in record) {
                    record as BOMWithLocatorAndNote;
                    if (record?.child.length) {
                        const selectedChild = record.child.find((c) => c.selected);
                        return selectedChild?.qty_bom;
                    }
                }
                return val;
            },
        },
    ];

    const columns: ColumnType<TransformListToBOMCentris>[] = [
        {
            title: "No",
            dataIndex: "no",
            render(value, record, index) {
                return index + 1;
            },
            onCell: (record) => {
                const length = record.product?.child?.length;
                return {
                    rowSpan: record.numberChild === 0 ? length : 0,
                };
            },
        },
        {
            title: "Code 1",
            render: (_, recod) => recod.product.product_code1,
            onCell: (record) => {
                const length = record.product?.child?.length;
                return {
                    rowSpan: record.numberChild === 0 ? length : 0,
                };
            },
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            className: "relative",
            render: (_, record) => {
                const disabled = disabledRowEdit(record);
                return (
                    <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                        {record.product.product_id === productEdit?.product?.product_id ? (
                            <Form layout="horizontal" initialValues={{ qty: record.product.qty }} onFinish={onFinish} className="p-3">
                                <Form.Item name="qty" rules={[{ required: true, min: 1, type: "number" }]}>
                                    <InputNumber />
                                </Form.Item>
                                <Space>
                                    <button
                                        title="Save"
                                        type="submit"
                                        className="focus:outline-primary focus:outline-2 focus:outline-offset-2 bg-primary rounded border-none cursor-pointer text-white"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={onEdit(null)}
                                        className="focus:outline-red-400 focus:outline-2 focus:outline-offset-2 bg-white rounded border-none cursor-pointer text-red-400"
                                        type="button"
                                    >
                                        Cancel
                                    </button>
                                </Space>
                            </Form>
                        ) : (
                            <>
                                <span className="px-2">{record.product.qty}</span>
                                <Button disabled={disabled} onClick={onEdit(record)} title="Edit row" type="text">
                                    <FiEdit className="text-lg" />
                                </Button>
                                <Button disabled={disabled} onClick={onDeleteProduct(record)} title="Delete row" type="text" danger>
                                    <MdOutlineDeleteOutline className="text-lg" />
                                </Button>
                            </>
                        )}
                    </div>
                );
            },
            onCell: (record) => {
                const length = record.product?.child?.length;
                return {
                    rowSpan: record.numberChild === 0 ? length : 0,
                };
            },
        },
        ...childColumns,
        {
            title: "Action",
            width: "45px",
            render: (value, record, index) => {
                const disabled = disabledRowEdit(record);
                return (
                    <Button disabled={disabled} onClick={onDeleteChild(record)} title="Delete row" type="text" danger>
                        <MdOutlineDeleteOutline className="text-lg" />
                    </Button>
                );
            },
        },
    ];

    return <Table size="small" pagination={false} bordered dataSource={transformList} columns={columns} />;
}

export default TablePOMAdd;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import TableIndex from "modules/master-data/master-variable/table-index";
import FilterOnline from "modules/master-data/rekap/filter/filter-online";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import manufactureMasterVariableService, { ListVariable } from "services/api-endpoints/dashboard/manufacture/master-variable";

const filter = ["mp_id", "order_status", "start_date", "end_date"];

const MasterVariable = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([manufactureMasterVariableService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await manufactureMasterVariableService.GetAll({ page, param_search: searchValue.query || "" })).data.data;
    });

    const deleteMutation = useMutation(async (data: ListVariable) => (await manufactureMasterVariableService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Variable Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<ListVariable>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterOnline filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Search Variable Manufacture"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                        filter={false}
                    />
                )}
            </FilterOnline>
            <TableIndex fetcher={getList} onDelete={showConfirm} />
        </div>
    );
};

export default MasterVariable;

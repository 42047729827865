import React from "react";
import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BasePaginationResponse } from "models";
import { VIEW_PATH } from "utils/constant";
import ButtonAccessView from "components/button/button-access-view";
import { StockList } from "services/api-endpoints/dashboard/master-data/kartu-stock";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const KartuStokTable = <T extends StockList>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouseId = searchParams.get("warehouse_id");

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const viewHandler = (data: StockList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.product_id}&warehouse_id=${warehouseId}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Product Code",
            width: "150px",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            width: "100px",
            title: "Total Qty",
            dataIndex: "quantity",
            render: (text) => <p className="capitalize m-0">{text || "0"}</p>,
        },
        // {
        //     title: "Area",
        //     width: "150px",
        //     dataIndex: "list_area",
        //     render: (text, record) => <p className="capitalize m-0">{record?.list_area?.join(", ")}</p>,
        // },
        {
            width: "70px",
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default KartuStokTable;

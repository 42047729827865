import { Alert, Button, Card, Skeleton, Space, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import { SelectOption } from "models";
import OrderTableView from "modules/master-data/return-order/view/order-table";
import React, { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import bpOrderService from "services/api-endpoints/dashboard/master-data/bp-order";
import bpReturnService from "services/api-endpoints/dashboard/master-data/bp-return";
import { RETURN_ORDER_STATUS, TYPE_BP_RETURN } from "utils/constant";

const APPROVE_STATUS = 2;
const REJECTED_STATUS = -1;

const BPReturnView = () => {
    const { updateAccess } = useContext(StateContext);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const getReason = useQuery([bpReturnService.getReason], async () => {
        return (await bpReturnService.GetReason()).data.data;
    });

    const partnerQuery = useQuery([bpOrderService.getBusinessPartner], async () => {
        const req = await bpOrderService.GetBusinessPartner({ status: 6 });
        return req.data.data?.map((el) => ({ ...el, label: el?.bp_name || "", value: el.bp_id || "" } as SelectOption));
    });

    const detailQuery = useQuery(
        [bpReturnService.getDetailReturnOrder, id],
        async () => {
            return (await bpReturnService.GetDetailReturnOrder({ id })).data.data;
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const setStatusApprove = useMutation(
        [bpReturnService.setStatus, id],
        async () => {
            return (await bpReturnService.SetStatus({ id, status: APPROVE_STATUS })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Status has been set approve");
                navigate(-1);
            },
        }
    );

    const setStatusReject = useMutation(
        [bpReturnService.setStatus, id],
        async () => {
            return (await bpReturnService.SetStatus({ id, status: REJECTED_STATUS })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Status has been set reject");
                navigate(-1);
            },
        }
    );

    const approveHandler = () => {
        setStatusApprove.mutate();
    };

    const rejectHandler = () => {
        setStatusReject.mutate();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail BP return"
                rightAddition={
                    updateAccess && detailQuery.data?.return_status === 1
                        ? () => (
                              <Space>
                                  <Button loading={setStatusApprove.isLoading} onClick={approveHandler} className="!flex !items-center">
                                      Approve
                                  </Button>
                                  <Button loading={setStatusReject.isLoading} onClick={rejectHandler} className="!flex !items-center" danger>
                                      Reject
                                  </Button>
                              </Space>
                          )
                        : () => <div />
                }
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Return order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.return_no}</span>
                                    </p>
                                    {detailQuery.data?.reason_id !== 0 ? (
                                        <p className="capitalize m-0">
                                            Reason to return <br />
                                            <span className="underline italic m-0">
                                                {getReason.data?.find((reason) => reason.reason_id === detailQuery.data?.reason_id)?.reason_name}
                                            </span>
                                        </p>
                                    ) : null}

                                    <p className="capitalize m-0">
                                        Partner Name <br />
                                        <span className="underline italic m-0">
                                            {partnerQuery.data?.find((status) => status.value === detailQuery.data?.bp_id)?.label}
                                        </span>
                                    </p>
                                    {/* <p className="capitalize m-0">
                                        Type Return <br />
                                        <span className="underline italic m-0">
                                            {TYPE_BP_RETURN.find((status) => status.value === detailQuery.data?.type)?.label}
                                        </span>
                                    </p> */}
                                    <p className="capitalize m-0">
                                        Status <br />
                                        <span className="underline italic m-0">
                                            {RETURN_ORDER_STATUS.find((status) => status.value === detailQuery.data?.return_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert type="error" message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
            <OrderTableView fetcher={detailQuery} onClickLink={() => {}} />
        </div>
    );
};

export default BPReturnView;

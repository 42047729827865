/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, DatePicker, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import FilterInvoice from "modules/master-data/consignment/filter-invoice";
import TableAdd from "modules/master-data/consignment/generate-invoice/table-add";
import ModalType from "modules/master-data/consignment/generate-invoice/type-modal";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import consignmentService, {
    CreateGenerateInvoice,
    CreateProduct,
    ListInvoice,
    ProductInvoice,
} from "services/api-endpoints/dashboard/master-data/consignment";
import { AUTOGENERATED, FORMAT_DATE_1 } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateGenerateInvoice>> = yup.object().shape({
    account_id_from: yup.number().required("Partner Required"),
    note: yup.string().required("Note Required"),
    doc_id: yup.array(),
    detail_product: yup.array(),
});

const CGenerateInvoiceAdd = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductInvoice[]>([]);
    const [dataSourceFilter, setDataSourceFilter] = useState<ListInvoice[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreateGenerateInvoice>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const watchPartner = watch("account_id_from");
    // const watchStartDate = watch("start_date");
    // const watchEndDate = watch("end_date");

    const getPartnerInt = useQuery([consignmentService.getPartnerInt], async () => {
        return (await consignmentService.GetPartnerInt()).data.data?.map((el) => ({ label: el.partner_name, value: el.partner_id } as SelectOption));
    });

    const productInvoiceMutate = useMutation(
        [consignmentService.getProductInvoice],
        async (order_id: string) => {
            return (await consignmentService.GetProductInvoice({ order_id })).data.data;
        },
        {
            onSuccess(data) {
                setProducts(data);
            },
        }
    );

    const createMutate = useMutation(
        [consignmentService.create],
        async (data: CreateGenerateInvoice) => {
            return (await consignmentService.Create(data)).data.data;
        },
        {
            onSuccess(data) {
                message.success("Generate Invoice Created");
                navigate(-1);
            },
        }
    );

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onAddInvoice = (data: ListInvoice[]) => {
        setDataSourceFilter(data);
        const orderID = data.map((val) => val.id).join(",");
        productInvoiceMutate.mutate(orderID);
    };

    const title = `Add Invoice - ${getPartnerInt.data?.find((val) => val.value === watchPartner)?.label || ""}`;

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const parse: CreateGenerateInvoice = {
            ...data,
            doc_id: dataSourceFilter.map((val) => val.id),
            detail_product: products.map(
                (val) =>
                    ({
                        product_id: val.product_id,
                        qty: val.qty,
                        ref_id: val.references.map((val2) => val2.id),
                    } as CreateProduct)
            ),
        };
        createMutate.mutate(parse);
    });

    const onCreateHandler = async (type: number) => {
        // if (!watchStartDate || !watchEndDate) {
        //     message.error("Transaction Date Required");
        //     return;
        // }
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const isOwnerDifferent = () => {
        if (products?.length <= 1) {
            return false;
        }
        const firstOwner = products[0].product_owner_id;
        for (let i = 1; i < products.length; i++) {
            if (products[i].product_owner_id !== firstOwner) {
                return true;
            }
        }
        return false;
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new generate invoice"
                rightAddition={() => (
                    <ModalType title="Konfirmasi" onSubmit={onCreateHandler} isOwnerDifferent={isOwnerDifferent()}>
                        {(modal) => (
                            <Button
                                loading={createMutate.isLoading}
                                onClick={modal.openModal}
                                type="primary"
                                className="!flex !items-center"
                                disabled={!dataSourceFilter.length}
                            >
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        )}
                    </ModalType>
                )}
            />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <div className="grid grid-cols-3 gap-x-5">
                        <p className="capitalize m-0">
                            Document No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            control={control}
                            options={getPartnerInt.data}
                            loading={getPartnerInt.isLoading}
                            name="account_id_from"
                            placeholder="Partner Internal"
                            label="Partner Internal"
                        />
                        {/* <div>
                            <p className="capitalize mb-2">Transaction Date</p>
                            <DatePicker.RangePicker
                                value={[
                                    watchStartDate ? moment(watchStartDate, FORMAT_DATE_1) : null,
                                    watchEndDate ? moment(watchEndDate, FORMAT_DATE_1) : null,
                                ]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    setValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    setValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div> */}
                        <ControlledInputTextArea control={control} name="note" placeholder="Invoice Note" label="Invoice Note" rows={2} />
                    </div>
                </Card>
                <FilterInvoice title={title} partnerID={watchPartner} onAddInvoice={onAddInvoice}>
                    {(ctrl) => (
                        <Button
                            onClick={ctrl.openModal}
                            type="primary"
                            className="!self-start"
                            disabled={!watchPartner}
                            loading={createMutate.isLoading}
                        >
                            Add Invoice
                        </Button>
                    )}
                </FilterInvoice>
                <TableAdd
                    list={products}
                    setList={setProducts}
                    removeItemList={onRemoveProduct}
                    action={false}
                    loading={productInvoiceMutate.isLoading}
                />
            </Form>
        </div>
    );
};

export default CGenerateInvoiceAdd;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ManufactureContext } from "../context/context";
import { MenuTypes } from "../utils/constant";

const Sidebar = () => {
    const { setMenu, menu, setIsMenuOpen } = React.useContext(ManufactureContext) as any;

    const onClickMenu = (str: any) => {
        return () => {
            setIsMenuOpen(true);
            setMenu(str);
        };
    };

    return (
        <div
            style={{ borderTop: "1px solid #e5e5e5" }}
            onClick={(e) => e.stopPropagation()}
            className="flex gap-5 w-full items-center py-2 px-2 bg-white z-20 relative pointer-events-auto"
        >
            {Object.keys(MenuTypes).map((key: any) => {
                const item = MenuTypes[key as keyof typeof MenuTypes];
                if (item.type === MenuTypes.process.type) return null;
                if (item.type === MenuTypes.multipleOperator.type) return null;
                return (
                    <button
                        type="button"
                        key={key}
                        className={`bg-gray-200 border-none cursor-pointer rounded ${menu === item.id ? item.style : ""}  px-4 py-2`}
                        onClick={onClickMenu(item.id)}
                        title={item?.text}
                    >
                        {item.text}
                    </button>
                );
            })}
        </div>
    );
};

export default Sidebar;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Popover, Table, TablePaginationConfig } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";

type Props<T> = {
    list?: T[];
    loading?: boolean;
};

const TableViewRequest = <T extends ChildRequest>({ list, loading }: Props<T>) => {
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 100 + (i + 1)}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => record?.product_name,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text, record) => record?.product_code,
        },
        {
            title: "Type",
            render: (_, record) => record?.type_name,
        },
        {
            title: "UOM",
            dataIndex: "uom_code",
            render: (text, record) => record?.uom_code || "-",
        },
        {
            title: "Qty",
            render: (_, record) => record?.qty,
        },
        {
            title: "Qty Receipt",
            render: (_, record) => (
                <p className={record.qty === record.qty_receipt ? "font-bold text-green-500 m-0" : "font-bold text-red-500 m-0"}>
                    {record?.qty_receipt}
                </p>
            ),
        },
        {
            title: "Referensi",
            render: (_, record) => {
                if (!record.note) return "-";
                const note = record.note || "";
                const title = note.length > 50 ? `${note.substring(0, 50)}...` : note;
                return (
                    <Popover
                        title="Link Referensi"
                        trigger="click"
                        content={
                            <div className="w-full">
                                <a href={note} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </div>
                        }
                    >
                        <Button>Link Ref</Button>
                    </Popover>
                );
            },
        },
    ];

    return (
        <Table
            rowKey={(record) => record.product_id!}
            size="small"
            columns={columns}
            dataSource={list}
            className="w-full"
            loading={loading}
            pagination={{ pageSize: 100, showSizeChanger: false }}
            onChange={handleTableChange}
        />
    );
};

export default TableViewRequest;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import {
    BasePaginationResponseIO,
    BasePaginationResponseProductIO,
    BasePaginationResponseRemarksIO,
    FDataInventoryOut,
    FilterInventoryOut,
} from "modules/dashboard-jsl/inventory-out/models";

export interface DetailIO {
    id: number;
    doc_no: string;
    note: string;
    requester: string;
    status: number;
}

export interface ProductIO {
    id: number;
    order_detail_id: number;
    product_code: string;
    product_name: string;
    qty: number;
}

export interface Remark {
    id?: number;
    remark?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface SetStatusData {
    id: number;
    order_status: number;
    note: string;
}

export interface AddRemarksData {
    order_id: number;
    note: string;
}

class InventoryOutService extends BaseService {
    getAll = "/inv-out/all-inventory-out";

    search = "/inv-out/search-inventory-out";

    filter = "/inv-out/filter-inventory-out";

    requester = "/inv-out/get-requester";

    create = "/inv-out/create-inventory-out";

    detailIO = "/inv-out/detail-inventory-out";

    detailProductIO = "/inv-out/detail-product-invout";

    getRemarks = "inv-out/get-remarks";

    setStatus = "/inv-out/set-status";

    addRemarks = "/inv-out/add-remarks";

    getAllProduct = "/giias/cashier/get-product-price";

    constructor() {
        super();
    }

    GetAllProduct<T extends Models.Product[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllProduct,
            });
            return req;
        });
    }

    AddRemarks<T extends any>(data: AddRemarksData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.addRemarks,
                data,
            });
            return req;
        });
    }

    SetStatus<T extends any>(data: SetStatusData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setStatus,
                data,
            });
            return req;
        });
    }

    GetRemarks<T extends Remark>(params: { id: any; page: any }) {
        return this.ProxyRequest<BasePaginationResponseRemarksIO<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseRemarksIO<T>>({
                url: this.getRemarks,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    DetailProductIO<T extends ProductIO>(params: { id: any; page: any }) {
        return this.ProxyRequest<BasePaginationResponseProductIO<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseProductIO<T>>({
                url: this.detailProductIO,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    DetailIO<T extends DetailIO>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailIO}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslInventoryOut>(param: { page: any }) {
        return this.ProxyRequest<BasePaginationResponseIO<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseIO<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Requester<T extends { id: number; name: string }[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.requester,
            });
            return req;
        });
    }

    Search<T extends Models.DashboardJslInventoryOut>(param: { page: any; query: any }) {
        return this.ProxyRequest<BasePaginationResponseIO<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseIO<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.DashboardJslInventoryOut>(param: FilterInventoryOut) {
        return this.ProxyRequest<BasePaginationResponseIO<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseIO<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: FDataInventoryOut) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }
}

const inventoryOutService = new InventoryOutService();
export default inventoryOutService;

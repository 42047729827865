import { Button, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useRef } from "react";
import { useQuery } from "react-query";
import extReturnOrderService, { LocatorData } from "services/api-endpoints/dashboard/master-data/ext-return-order";

type Props = ModalProps & {
    productId: number | undefined;
    qty: number | undefined;
    warehouseId: number | null;
    onSubmit: (locator: LocatorData) => void;
};

const ModalLocator = ({ onSubmit, productId, qty, warehouseId, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const locatorQuery = useQuery(
        [extReturnOrderService.locator, productId],
        async () => {
            return (await extReturnOrderService.Locator({ product_id: productId, type: 2, warehouse_id: warehouseId })).data.data;
        },
        {
            enabled: !!productId,
        }
    );

    const onAddLocator = (locator: LocatorData) => {
        return () => {
            if (!productId) {
                message.error("Product not found");
                return;
            }
            if (!locator.qty_locator) {
                message.error("Stock not available");
                return;
            }
            if ((qty || 0) > locator.qty_locator) {
                message.error("Not enough stock");
                return;
            }
            onSubmit(locator);
            closeRef.current?.click();
        };
    };

    const columns: ColumnsType<LocatorData> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Stock Quantity",
            dataIndex: "qty_locator",
            render: (text) => <p className="capitalize m-0 text-xs">{text || "-"}</p>,
        },
        {
            title: "Area Name",
            dataIndex: "area_name",
            render: (text) => <p className="capitalize m-0 text-xs">{text}</p>,
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => {
                return <Button onClick={onAddLocator(record)}>Add Locator</Button>;
            },
        },
    ];

    return (
        <ModalTemplate width={700} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        size="small"
                        loading={locatorQuery.isLoading}
                        columns={columns}
                        dataSource={locatorQuery.data || []}
                        className="w-full"
                        pagination={{
                            pageSize: 10,
                            total: locatorQuery.data?.length || 0,
                            showSizeChanger: false,
                        }}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalLocator;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { BasePaginationResponseHistory, FilterPurchaseOrder } from "modules/dashboard-jsl/purchase-order/models";

export interface CreatePOExt {
    bp_id: number | null;
    detail_product: {
        product_id: any;
        qty: any;
        price: any;
    }[];
}

export interface ProductPoEdit {
    order_detail_id?: any;
    product_id?: any;
    qty?: any;
    price?: any;
    is_active?: number;
}

export interface EditPO {
    id: any;
    detail_product: ProductPoEdit[];
}

export interface ListDetail {
    order_detail_id?: number | null;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    price?: number;
    qty?: number;
}
export interface DetailPO {
    order_id?: number;
    order_status?: number;
    po_number?: string;
    request_from?: string;
    request_to?: string;
    payment_term_code?: string;
    payment_term_id?: number;
    list_detail?: ListDetail[];
}

export interface List {
    id: number;
    no: string;
}

export interface HistoryPO {
    id?: number;
    notes?: string;
    list_do?: List[];
    list_so?: List[];
    list_po?: List[];
    created_by?: string;
    created_at?: number;
}

export interface DetailHistoryList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
}

export interface DetailHistory {
    order_id?: number;
    po_no?: string;
    do_no?: string;
    so_no?: string;
    request_from?: string;
    request_to?: string;
    list_detail?: DetailHistoryList[];
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface BpData {
    bp_id?: number;
    bp_name?: string;
}

class PurchaseOrderExtService extends BaseService {
    getAll = "/ext-po/all-purchase-order";

    search = "/ext-po/search-purchase-order";

    filter = "/ext-po/filter-purchase-order";

    deleteDraft = "/ext-po/delete-draft-po";

    getHeaderPO = "/ext-po/get-header-po";

    draftPO = "/ext-po/draft-purchase-order";

    createPO = "/ext-po/create-purchase-order";

    detail = "/ext-po/detail-purchase-order";

    editDraftPO = "/ext-po/edit-draft-po";

    editPO = "/ext-po/edit-purchase-order";

    historyPO = "/ext-po/history-purchase-order";

    detailHistoryPO = "/ext-po/detail-history-po";

    deletePO = "/ext-po/delete-po";

    listBp = "/ext-po/get-list-bp";

    constructor() {
        super();
    }

    ListBp<T extends BpData[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.listBp}`,
            });
            return req;
        });
    }

    DeletePO<T extends any>(data: DeleteData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.deletePO,
                data,
            });
            return req;
        });
    }

    DetailHistoryPO<T extends DetailHistory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailHistoryPO}/${id}`,
            });
            return req;
        });
    }

    HistoryPO<T extends HistoryPO>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.historyPO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    EditPO<T extends any>(data: EditPO) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editPO,
                data,
            });
            return req;
        });
    }

    EditDraftPO<T extends any>(data: EditPO) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraftPO,
                data,
            });
            return req;
        });
    }

    Detail<T extends DetailPO>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    CreatePO<T extends any>(data: CreatePOExt) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPO,
                data,
            });
            return req;
        });
    }

    DraftPO<T extends any>(data: CreatePOExt) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.draftPO,
                data,
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslPurchaseOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    DeleteDraft<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteDraft}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.DashboardJslPurchaseOrder>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.DashboardJslPurchaseOrder>(param: FilterPurchaseOrder) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetHeaderPO<T extends { po_number: string; request_from: string; request_to: string }>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getHeaderPO,
            });
            return req;
        });
    }
}

const purchaseOrderExtService = new PurchaseOrderExtService();
export default purchaseOrderExtService;

/* eslint-disable no-lonely-if */
import { Button, Form, Select, Steps, Upload, message } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import useProgressUpload from "hooks/useProgressUpload";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import physicalInventoryService, {
    CutStockInventoryUpload,
    CutStockParams,
    LocatorPI,
    UploadData,
    UploadProduct,
} from "services/api-endpoints/dashboard/master-data/physical-inventory";
import ValidasiLocatorTable from "./validasi-locator-table";
import { v4 as uuid } from "uuid";
import TextArea from "antd/lib/input/TextArea";
import { SelectOption } from "models";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";

export type Props = ModalProps & {
    onSuccess: () => void;
};

const schema: yup.SchemaOf<UploadData> = yup.object().shape({
    file_name: yup.string(),
    type: yup.number().required("Type Required"),
    note: yup.string(),
    area_id: yup.number(),
    list_product: yup.array(),
});

const ModalPhysicalInventoryUpload = ({ children, onSuccess, ...props }: Props) => {
    const [progress, onUploadProgress] = useProgressUpload();
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [step, setStep] = useState(0);
    const [list, setList] = useState<(UploadProduct & LocatorPI)[]>([]);

    const [form] = Form.useForm();
    const { watch, control, handleSubmit } = useForm<UploadData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchType = watch("type");
    const watchAreaId = watch("area_id");

    const uploadMutate = useMutation(
        [physicalInventoryService.upload],
        async (data: FormData) => {
            return (await physicalInventoryService.Upload(data as any, { onUploadProgress })).data.data;
        },
        {
            onSuccess(data) {
                const temp = data.list_product as (UploadProduct & LocatorPI)[];
                setList(temp.map((val) => ({ ...val, id: uuid() })));
            },
        }
    );

    const cutStockMutate = useMutation([physicalInventoryService.cutStock], async (data: CutStockParams) => {
        return (await physicalInventoryService.CutStock(data)).data.data;
    });

    const locatorInQuery = useQuery([physicalInventoryService.getLocatorIN], async () => {
        return (await physicalInventoryService.GetLocatorIN()).data.data.map(
            (val) => ({ ...val, label: val.area_name, value: val.area_id } as SelectOption)
        );
    });

    const onFinish = (values: any) => {
        // if (watchType === 1 && !watchAreaId) {
        //     message.error("File Type IN, Locator Required");
        //     return;
        // }
        const formData = new FormData();
        formData.append("type", String(watchType));
        formData.append("file", values.file[0]?.originFileObj);
        formData.append("note", values.note);
        formData.append("area_id", String(watchAreaId || 0));
        uploadMutate.mutateAsync(formData).then((data) => {
            setStep(1);
            // if ((data.list_product || []).length === 0) {
            //     message.success("Upload file success");
            //     form.resetFields();
            //     onSuccess();
            //     closeRef.current?.click();
            // } else {
            //     setStep(1);
            // }
        });
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const steps = [
        {
            title: "Upload File",
        },
        {
            title: "Validasi Locator",
        },
    ];

    const onChangeStep = (stp: number) => {
        if (!list?.length && stp === 1) return;
        setStep(stp);
    };

    const onChooseLocator = (product: UploadProduct & LocatorPI) => {
        setList((prev) => {
            return prev.map((item) => {
                if (item.id !== product.id) return item;
                return {
                    ...item,
                    area_id: product.area_id,
                    area_name: product.area_name,
                };
            });
        });
    };

    const onRemoveLocator = (product: UploadProduct & LocatorPI) => {
        setList((prev) => {
            return prev.map((item) => {
                if (item.id !== product.id) return item;
                return {
                    ...item,
                    area_id: null,
                    area_name: null,
                };
            });
        });
    };

    const canSubmitLocator = list.every((el) => !!el.area_id);

    const cutStockSubmit = () => {
        const productList: CutStockInventoryUpload[] = list.map((el) => ({
            id: el.id,
            area_id: el.area_id,
            product_id: el.product_id,
            qty: el.qty,
            ref_doc: el.ref_doc,
            notes: el.notes,
        }));
        const cutStockParams: CutStockParams = {
            file_name: uploadMutate.data?.file_name,
            type: uploadMutate.data?.type,
            note: uploadMutate.data?.note,
            child: productList,
        };
        cutStockMutate.mutateAsync(cutStockParams).then(() => {
            closeRef.current?.click();
            message.success("Validasi locator success");
            setList([]);
            setStep(0);
            uploadMutate.reset();
        });
    };

    const OPTION_TYPE = [
        {
            value: 1,
            label: "IN",
        },
        {
            value: 2,
            label: "OUT",
        },
    ];

    return (
        <>
            <ModalTemplate
                {...props}
                title={steps[step].title}
                handlerInComponent={children}
                footer={null}
                width={step === 1 ? 1000 : 600}
                closable={!uploadMutate.isLoading}
            >
                {(dt) => (
                    <div className="w-full flex flex-col gap-6">
                        <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                            close
                        </button>
                        <Steps current={step} onChange={onChangeStep} items={steps} />
                        <div className="rounded-lg border-solid border border-gray-300 p-4">
                            {step === 0 ? (
                                <Form
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    form={form}
                                    name="control-hooks"
                                    onFinish={onFinish}
                                    style={{ maxWidth: 600 }}
                                >
                                    <ControlledSelectInput
                                        control={control}
                                        name="type"
                                        placeholder="Select Type"
                                        label="Type"
                                        options={OPTION_TYPE}
                                    />
                                    <Form.Item name="file" label="File" rules={[{ required: true }]} getValueFromEvent={normFile} className="!w-full">
                                        <Upload
                                            beforeUpload={() => false}
                                            action={undefined}
                                            listType="text"
                                            accept=".xlsx,.xls,.csv"
                                            className="!w-full"
                                        >
                                            <Button htmlType="button">Pick File</Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item name="note" label="Note" rules={[{ required: true }]}>
                                        <TextArea rows={2} />
                                    </Form.Item>
                                    {/* {watchType === 1 && (
                                        <ControlledSelectInput
                                            control={control}
                                            name="area_id"
                                            placeholder="Select Locator"
                                            label="Locator"
                                            options={locatorInQuery.data}
                                            optionFilterProp="label"
                                            showSearch
                                        />
                                    )} */}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="!flex !items-center !gap-3"
                                            loading={cutStockMutate.isLoading || uploadMutate.isLoading}
                                            disabled={cutStockMutate.isLoading || uploadMutate.isLoading}
                                        >
                                            {progress}
                                            Upload
                                        </Button>
                                    </Form.Item>
                                </Form>
                            ) : null}
                            {step === 1 ? (
                                <>
                                    <p className="m-0 mb-2 text-xs text-red-400">Silakan pilih locator berdasarkan product</p>
                                    <ValidasiLocatorTable
                                        type={watchType}
                                        list={list}
                                        locatorList={locatorInQuery.data}
                                        onSubmit={onChooseLocator}
                                        onRemove={onRemoveLocator}
                                    />
                                    <Button
                                        loading={cutStockMutate.isLoading || uploadMutate.isLoading}
                                        onClick={cutStockSubmit}
                                        disabled={!canSubmitLocator || cutStockMutate.isLoading || uploadMutate.isLoading}
                                        type="primary"
                                    >
                                        Submit
                                    </Button>
                                </>
                            ) : null}
                        </div>
                    </div>
                )}
            </ModalTemplate>
        </>
    );
};

export default ModalPhysicalInventoryUpload;

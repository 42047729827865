import { Alert, Button, DatePicker, Form, Input, message } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { UseQueryResult, useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import holidayService, { Holiday, HolidayEdit } from "services/api-endpoints/dashboard/master-data/holiday";
import { FORMAT_DATE_1 } from "utils/constant";

type Props = ModalProps & {
    fetcher?: UseQueryResult<BasePaginationResponse<Holiday>, unknown>;
};

const ModalEditHoliday = ({ fetcher, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("edit");
    const [initialValues, setInitialValues] = useState<any>();

    const createHolidayMutate = useMutation(
        [holidayService.create],
        async (data: HolidayEdit) => {
            return (await holidayService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Data edited!");
            },
        }
    );

    const onFinish = (values: any) => {
        createHolidayMutate
            .mutateAsync({
                ...values,
                start_date: moment(values.range_date[0], FORMAT_DATE_1),
                end_date: moment(values.range_date[1], FORMAT_DATE_1),
                id,
            })
            .finally(() => {
                closeRef.current?.click();
                fetcher?.refetch();
            });
    };

    const [form] = Form.useForm();

    const row = fetcher?.data?.list?.find((el) => el.id === Number(id));

    useEffect(() => {
        if (!row) {
            setInitialValues(null);
            return;
        }

        const init = {
            range_date: [moment(row?.start_date), moment(row?.end_date)],
            description: row?.description,
        };
        setInitialValues(init);
        form.setFieldsValue(init);
    }, [id, fetcher?.data?.list]);

    return (
        <ModalTemplate stateWithUrlParams="edit" title="Edit Master Holiday" {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    {row ? (
                        <Form
                            form={form}
                            initialValues={initialValues}
                            disabled={createHolidayMutate.isLoading || fetcher?.isLoading}
                            onFinish={onFinish}
                            autoComplete="off"
                            requiredMark={false}
                            layout="vertical"
                        >
                            <Form.Item label="Range Date" name="range_date" rules={[{ required: true, message: "Please input range date!" }]}>
                                <DatePicker.RangePicker />
                            </Form.Item>

                            <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input description!" }]}>
                                <Input.TextArea placeholder="Write here" />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={createHolidayMutate.isLoading || fetcher?.isLoading} type="primary" htmlType="submit">
                                    Edit
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Alert type="error" message="Data not found!" />
                    )}
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalEditHoliday;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { CreatePurchasingInventory } from "pages/purchasing-inventory/add";
import React from "react";
import { useQuery } from "react-query";
import purchasingInventoryService, { PaymentTermInventory } from "services/api-endpoints/purchasing/inventory";

export interface Props extends ControlledInputSelectProps<CreatePurchasingInventory> {
    onSelectedData?: (id: number, data: PaymentTermInventory[]) => void;
}

export function withPaymentMethod<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const paymentsQuery = useQuery([purchasingInventoryService.getPayment], async () => {
            return (await purchasingInventoryService.GetPayment()).data.data;
        });

        const onSelect = (id: number) => {
            if (props.onSelectedData) {
                props.onSelectedData(id, paymentsQuery.data || []);
            }
        };

        const options = paymentsQuery?.data?.map((el) => ({ value: el.payment_term_id, label: el.description }));

        return <Component {...props} onSelect={onSelect} loading={paymentsQuery.isLoading} onDeselect={onSelect} options={options} />;
    };
}

const PaymentMethodSelect = withPaymentMethod(ControlledSelectInput);

export default PaymentMethodSelect;

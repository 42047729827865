/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Modal, Space, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import { SelectOption } from "models";
import FilterDocumentPayment, { columnsCCAndPaymentMethod, columnsFilterDocPayment } from "modules/master-data/payment/add/filter-document";
import FilterPiutangReceipt from "modules/master-data/payment/add/filter-putang";
import ListExpenses from "modules/master-data/payment/add/list-expanses";
import ModalAddExpenses from "modules/master-data/payment/add/modal-add-expenses";
import BankAccountSelect from "modules/master-data/payment/lib/bank-account-select";
import BusinessPartnerSelect from "modules/master-data/payment/lib/business-partner-select";
import EmployeeSelect from "modules/master-data/payment/lib/employee-select";
import ModalJournalDetail from "modules/master-data/purchasing/modal-journal-detail";
import ModalRefVoucher from "modules/master-data/purchasing/modal-ref-voucher";
import ModalRefDoc from "modules/master-data/receipt/modal-ref-doc";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { BiImageAdd, BiSave } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";
import paymentService, { CreatePayment, InvoiceDocPayment } from "services/api-endpoints/dashboard/payment";
import receiptService, { CreateReceipt, ExpansesData } from "services/api-endpoints/dashboard/receipt";
import Utils from "utils";
import {
    AUTOGENERATED,
    FORMAT_DATE_1,
    ORDER_PAYMENT_AUTOLOKA,
    ORDER_PAYMENT_BP_SALES,
    ORDER_PAYMENT_EMPLOYESS,
    ORDER_PAYMENT_OFFLINE,
    ORDER_PAYMENT_ONLINE,
    RECEIPT_OFFLINE_TRANSFER,
    TYPE_ORDER_PAYMENT,
    TYPE_PAYMENT_BP_SALES,
} from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateReceipt>> = yup.object().shape({
    account_id: yup.number().required("Cabang Account Required"),
    bank_account_id: yup.number().required("Bank Account Required"),
    transaction_date: yup.string().required("Transaction Date Required"),
    order_type_id: yup.number().required("Sales Type Required"),
    note: yup.string().required("Note Voucher Required"),
    partner_id: yup.number(),
    child: yup.array(),
    expenses: yup.array(),
    receipt_images: yup.array(),
    receipt_amount: yup.number(),
    total_receipt_amount: yup.number(),
    payment_id: yup.number().required("Payment Required"),
});

const ReceiptAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();

    // uploader image preview
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState("");
    const [previewTitle, setPreviewTitle] = React.useState("");
    //

    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [expanses, setExpanses] = React.useState<ExpansesData[]>([]);
    const [titlePartner, setTitlePartner] = React.useState<string>();
    const [dataSourceFilter, setDataSourceFilter] = React.useState<InvoiceDocPayment[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreatePayment>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchOrderType = watch("order_type_id");
    const watchPartnerId = watch("partner_id");
    const watchAccountId = watch("account_id");

    // React.useEffect(() => {
    //     /// default values
    //     form.setFieldsValue({
    //         transaction_date: moment(moment.now()),
    //     });
    //     setValue("transaction_date", moment(moment.now()));
    // }, []);

    const createMutate = useMutation(
        [receiptService.createReceipt],
        async (data: CreateReceipt) => {
            return (await receiptService.CreateReceipt(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Receipt Voucher Success Post Jurnal");
                navigate(-1);
            },
        }
    );

    const draftMutate = useMutation(
        [receiptService.draftReceipt],
        async (data: CreateReceipt) => {
            return (await receiptService.DraftReceipt(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Receipt Voucher Drafted");
                navigate(-1);
            },
        }
    );

    // const filterDocMutate = useMutation([paymentService.filterDoc], async (data: FilterDocPayment) => {
    //     return (await paymentService.FilterDoc(data)).data.data;
    // });

    const getAccountQuery = useQuery([paymentService.getAccount], async () => {
        return (await paymentService.GetAccount()).data.data?.map((el) => ({ label: el.account_name, value: el.account_id } as SelectOption));
    });

    const marketplaceQuery = useQuery([orderSalesService.getMarketplace], async () => {
        return (await orderSalesService.GetMarketplace()).data.data?.map(
            (el) => ({ label: el.marketplace_name, value: el.marketplace_id } as SelectOption)
        );
    });

    const employeeQuery = useQuery([paymentService.getEmployee, watchAccountId], async () => {
        return (await paymentService.GetEmployee({ account_id: watchAccountId })).data.data?.map(
            (el) => ({ label: el.name, value: el.id } as SelectOption)
        );
    });

    // const dataSourceFilter = filterDocMutate.data?.filter((el) => selectedDocument.includes(el.inv_id));
    const total = dataSourceFilter?.reduce((inc, curr) => Number(curr?.grand_total || 0) + inc, 0);
    const ccTotal = dataSourceFilter?.reduce((inc, curr) => Number(curr?.cc_charge || 0) + inc, 0);

    const grandTotal = (total === undefined ? 0 : total) - expanses.reduce((t, curr) => Number(curr?.amount || 0) + t, 0);

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: CreateReceipt = {
            ...data,
            account_id: data.account_id,
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            child: dataSourceFilter?.map((itm) => ({
                doc_id: itm.inv_id,
                grand_total: itm.grand_total,
                cc_charge: itm.cc_charge,
                ref_id: itm.ref_id,
            })),
            expenses: expanses,
            receipt_images: filesBase64 || null,
            receipt_amount: total,
            total_receipt_amount: grandTotal,
        };

        createMutate.mutate(transformData);
    });

    const onDraftSubmitHandler = handleSubmit(async (data) => {
        const filesBase64 = await Promise.all(fileList.map(async (el) => Utils.toBase64(el.originFileObj as any)));

        const transformData: CreateReceipt = {
            ...data,
            account_id: data.account_id,
            bank_account_id: data.bank_account_id,
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            child: dataSourceFilter?.map((itm) => ({
                doc_id: itm.inv_id,
                grand_total: itm.grand_total,
                cc_charge: itm.cc_charge,
                ref_id: itm.ref_id,
            })),
            expenses: expanses,
            receipt_images: filesBase64 || null,
            receipt_amount: total,
            total_receipt_amount: grandTotal,
        };

        draftMutate.mutate(transformData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const onDraftHandler = async () => {
        await form.validateFields();
        onDraftSubmitHandler();
    };

    const removeItemFilter = (item: InvoiceDocPayment) => {
        return () => {
            setDataSourceFilter((prev) =>
                prev?.filter((el) => (watchOrderType === ORDER_PAYMENT_OFFLINE ? el.ref_id !== item.ref_id : el.inv_id !== item.inv_id))
            );
        };
    };

    const columnsFilter: ColumnsType<InvoiceDocPayment> = [
        {
            title: "Action",
            render: (_, record) => <IoCloseSharp onClick={removeItemFilter(record)} className="text-red-400 text-lg cursor-pointer" />,
        },
        {
            title: "Doc No",
            dataIndex: "inv_no",
            render: (text, record) => {
                if (watchOrderType === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalJournalDetail title="Detail Jurnal" id={record.inv_id}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalJournalDetail>
                    );
                return (
                    <ModalRefVoucher title="Invoice" id={record.inv_id} type={watchOrderType}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalRefVoucher>
                );
            },
        },
        {
            title: "Inv Ref",
            dataIndex: "inv_ref",
            render: (text, record) => {
                if (watchOrderType === ORDER_PAYMENT_EMPLOYESS)
                    return (
                        <ModalRefDoc title="Invoice" id={record.inv_ref_id} type={watchOrderType}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalRefDoc>
                    );
                return <span className="text-sm">{text}</span>;
            },
        },
        ...columnsFilterDocPayment,
        ...columnsCCAndPaymentMethod,
    ];

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await Utils.toBase64(file.originFileObj as any);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
    };

    const uploadProps: UploadProps = {
        fileList,
        onChange: (info) => {
            const files = info.fileList.map((file) => {
                if (file.response) {
                    file.url = file.response.url;
                    file.status = "done";
                }
                return file;
            });
            setFileList(files);
        },
        multiple: true,
        accept: ".png, .jpg, .jpeg",
        onPreview: handlePreview,
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onAddInvoice = (data: InvoiceDocPayment[]) => {
        const removeDuplicateInv = [...dataSourceFilter, ...data]?.reduce((prev: InvoiceDocPayment[], curr) => {
            if (prev.find((inv) => (watchOrderType === ORDER_PAYMENT_OFFLINE ? inv?.ref_id === curr?.ref_id : inv?.inv_id === curr?.inv_id)))
                return prev;
            return [...prev, curr];
        }, []);
        setDataSourceFilter(removeDuplicateInv);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    const isAddInvoice = () => {
        if (watchOrderType === ORDER_PAYMENT_ONLINE) {
            return !watchPartnerId;
        }
        return !watchOrderType;
    };

    const onChangeSalesType = (id: any) => {
        setValue("order_type_id", id);
        setValue("partner_id", undefined);
        if (id === ORDER_PAYMENT_BP_SALES) {
            setValue("payment_id", undefined);
        } else {
            setValue("payment_id", 0);
        }
        setDataSourceFilter([]);
        setExpanses([]);

        const title = TYPE_ORDER_PAYMENT.find((val) => val.value === id)?.label;
        setTitlePartner(title);
    };

    const onChangePartnerType = (id: any) => {
        setValue("partner_id", id);
        setTitlePartner(marketplaceQuery?.data?.find((item) => item.value === id)?.label);
        setDataSourceFilter([]);
        setExpanses([]);
    };

    const onChangeCabang = (id: any) => {
        setValue("account_id", id);
        setDataSourceFilter([]);
        setExpanses([]);
    };

    // const onChangeOfflinePartner = (id: any) => {
    //     setValue("partner_id", id);
    //     if (watchOrderType === ORDER_PAYMENT_OFFLINE) {
    //         setTitlePartner(`Offline ${RECEIPT_OFFLINE_TYPE.find((item) => item.value === id)?.label}`);
    //     }
    //     if (watchOrderType === ORDER_PAYMENT_OFFLINE_DP) {
    //         setTitlePartner(`Offline DP ${RECEIPT_OFFLINE_TYPE.find((item) => item.value === id)?.label}`);
    //     }
    //     setDataSourceFilter([]);
    //     setExpanses([]);
    // };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new receipt voucher"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <>
                            <Button
                                disabled={!dataSourceFilter.length}
                                loading={createMutate.isLoading}
                                onClick={onDraftHandler}
                                type="default"
                                className="!flex !items-center"
                            >
                                <RiDraftLine className="m-0 mr-2" />
                                Draft
                            </Button>
                            <Button
                                disabled={!dataSourceFilter.length}
                                loading={createMutate.isLoading}
                                onClick={onCreateHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                <BiSave className="m-0 mr-2" />
                                Post Jurnal
                            </Button>
                        </>
                    </Space>
                )}
            />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <div className="grid grid-cols-4 gap-x-5">
                        <p className="capitalize m-0">
                            Document No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            control={control}
                            name="account_id"
                            placeholder="Cabang Account"
                            label="Cabang Account"
                            showSearch
                            options={getAccountQuery.data}
                            loading={getAccountQuery.isLoading}
                            onChange={onChangeCabang}
                        />
                        <BankAccountSelect control={control} name="bank_account_id" placeholder="Bank Account" label="Bank Account" />
                        <ControlledInputDate control={control} name="transaction_date" placeholder="Transaction Date" label="Transaction Date" />
                        <>
                            {watchOrderType === ORDER_PAYMENT_BP_SALES ? (
                                <ControlledSelectInput
                                    control={control}
                                    name="payment_id"
                                    placeholder="Payment BP Sales"
                                    label="Payment BP Sales"
                                    options={TYPE_PAYMENT_BP_SALES}
                                />
                            ) : null}
                        </>
                        <ControlledInputTextArea control={control} name="note" placeholder="Note Voucher" label="Note Voucher" rows={2} />
                    </div>
                </Card>
                <Card className="">
                    <p className="capitalize m-0 mb-5 italic">Document Reference</p>
                    <div className="grid grid-cols-3 gap-x-5">
                        <ControlledSelectInput
                            control={control}
                            name="order_type_id"
                            placeholder="Sales Type"
                            label="Sales Type"
                            options={TYPE_ORDER_PAYMENT}
                            onChange={onChangeSalesType}
                            disabled={!watchAccountId}
                        />
                        <>
                            {watchOrderType === ORDER_PAYMENT_ONLINE ? (
                                <ControlledSelectInput
                                    showSearch
                                    name="partner_id"
                                    label="Market Place"
                                    placeholder="Market Place"
                                    optionFilterProp="children"
                                    control={control}
                                    loading={marketplaceQuery.isLoading}
                                    options={marketplaceQuery.data || []}
                                    onChange={onChangePartnerType}
                                />
                            ) : (
                                <>
                                    {watchOrderType === ORDER_PAYMENT_EMPLOYESS ? (
                                        <ControlledSelectInput
                                            showSearch
                                            name="partner_id"
                                            label="Employee Name"
                                            placeholder="Employee Name"
                                            optionFilterProp="children"
                                            control={control}
                                            loading={employeeQuery.isLoading}
                                            options={employeeQuery.data || []}
                                        />
                                    ) : (
                                        <>
                                            {watchOrderType === ORDER_PAYMENT_BP_SALES ? (
                                                <BusinessPartnerSelect
                                                    control={control}
                                                    name="partner_id"
                                                    placeholder="Partner Name"
                                                    label="Partner Name"
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>

                        <div className="div flex items-start justify-between">
                            <div className="capitalize m-0">
                                Invoice Total
                                <br />
                                <span className="font-semibold m-0">{total?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                            </div>
                            <div className="capitalize m-0">
                                CC Charge Total
                                <br />
                                <span className="font-semibold m-0">{ccTotal?.ToIndCurrency("Rp") || "Rp. -"}</span>
                                <div className="w-full my-5" style={{ borderBottom: "1px dashed #d9d9d9" }} />
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-col gap-5 pr-2" style={{ borderRight: "1px solid #d9d9d9" }}>
                            <>
                                {watchOrderType === ORDER_PAYMENT_EMPLOYESS ? (
                                    <FilterPiutangReceipt
                                        accountID={watchAccountId}
                                        title={titlePartner}
                                        type={watchOrderType}
                                        partnerId={watchPartnerId}
                                        total={total}
                                        onAddInvoice={onAddInvoice}
                                    >
                                        {(ctrl) => (
                                            <Button onClick={ctrl.openModal} type="primary" className="!self-start" disabled={!watchPartnerId}>
                                                Add Piutang
                                            </Button>
                                        )}
                                    </FilterPiutangReceipt>
                                ) : (
                                    <FilterDocumentPayment
                                        accountID={watchAccountId}
                                        title={titlePartner}
                                        type={watchOrderType}
                                        partnerId={watchPartnerId}
                                        total={total}
                                        ccTotal={ccTotal}
                                        onAddInvoice={onAddInvoice}
                                    >
                                        {(ctrl) => (
                                            <Button onClick={ctrl.openModal} type="primary" className="!self-start" disabled={isAddInvoice()}>
                                                Add Invoice
                                            </Button>
                                        )}
                                    </FilterDocumentPayment>
                                )}
                            </>
                            <Table
                                scroll={{ x: 1100 }}
                                rowKey={(rec) => (watchOrderType === ORDER_PAYMENT_OFFLINE ? rec.ref_id : rec.inv_id)!}
                                columns={columnsFilter}
                                dataSource={dataSourceFilter}
                                pagination={{ showSizeChanger: false, pageSize: 5, size: "small" }}
                                style={{ borderBottom: "1px solid #d9d9d9" }}
                                className="pb-2"
                            />
                            <Upload {...uploadProps} listType="picture-card" className="w-full flex flex-row gap-5">
                                <div>
                                    <BiImageAdd className="text-2xl" />
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                            </Upload>
                        </div>
                        <div className="flex flex-col">
                            <>
                                {expanses.length !== 0 ? (
                                    <div>
                                        <p className="capitalize m-0">Expenses</p>
                                        <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                    </div>
                                ) : (
                                    <div />
                                )}
                            </>
                            <ModalAddExpenses onCreate={onAddExpenses} paymentType={0}>
                                {(dt) => (
                                    <div className="w-full flex flex-col gap-1">
                                        <Button
                                            onClick={dt.openModal}
                                            type="primary"
                                            className="!self-start"
                                            disabled={
                                                (dataSourceFilter === undefined ? 0 : dataSourceFilter.length) === 0 ||
                                                (watchOrderType === ORDER_PAYMENT_OFFLINE && watchPartnerId === RECEIPT_OFFLINE_TRANSFER)
                                            }
                                        >
                                            Add Expenses
                                        </Button>
                                    </div>
                                )}
                            </ModalAddExpenses>
                            <div className="w-full my-5" style={{ borderBottom: "1px solid #d9d9d9" }} />
                            <p className="capitalize m-0">
                                Receipt Total
                                <br />
                                <span className="font-semibold m-0">{grandTotal?.ToIndCurrency("Rp") || "Rp. -"}</span>
                            </p>
                        </div>
                    </div>
                </Card>
            </Form>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default ReceiptAdd;

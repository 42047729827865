import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterGoodsInTransit from "modules/accounting/goods-in-transit/filter";
import GoodsInTransitTable from "modules/accounting/goods-in-transit/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import jurnalUmumService from "services/api-endpoints/accounting/jurnal-umum";

const filter = ["start_date", "end_date"];

const GoodsInTransit = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, filterValue2 } = useTypeSearchUrl({
        filter,
    });

    const getList = useQuery([jurnalUmumService.filterGoodsInTransit, filterValue2], async () => {
        return (await jurnalUmumService.FilterGoodsInTransit({ ...(filterValue2 as any) })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterGoodsInTransit filter={filter}>
                {(dt) => <Toolbar add={false} placeholder="Goods In Transit" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterGoodsInTransit>
            <GoodsInTransitTable fetcher={getList} />
        </div>
    );
};

export default GoodsInTransit;

import { Alert, Button, DatePicker, Form, Input, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";
import { UseMutationResult } from "react-query";
import { FilterInternal, FilterInternalList } from "services/api-endpoints/dashboard/payment";
import { FORMAT_DATE_1, FORMAT_DATE_TIME } from "utils/constant";

type Props = ModalProps & {
    accountID: number;
    type: number;
    fetcher: UseMutationResult<FilterInternalList[], unknown, FilterInternal, unknown>;
    onAddInvoice: (data: FilterInternalList[]) => void;
};

export const columnsFilterInternalPayment: ColumnsType<FilterInternalList> = [
    {
        title: "Grand Total",
        dataIndex: "grand_total",
        render: (text) => <span className="text-sm">{(text as number)?.ToIndCurrency("Rp")}</span>,
    },
    {
        title: "Date Invoiced",
        dataIndex: "created_at",
        render: (text, record) => <span className="text-sm">{text ? moment(record.created_at).format(FORMAT_DATE_TIME) : ""}</span>,
    },
];

const docNoCol: ColumnsType<FilterInternalList>[number] = {
    title: "Invoice No",
    dataIndex: "doc_no",
    render: (text) => <span className="text-sm">{text}</span>,
};

export const columnsFilterPayment = [docNoCol, ...columnsFilterInternalPayment];

const FilterDocInternalOrder = ({ children, accountID, type, fetcher, onAddInvoice, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [selected, setSelected] = useState<React.Key[]>([]);

    const onFilter = (values: { inv_no: any; transaction_date: Moment[] }) => {
        const tData: FilterInternal = {
            account_id: accountID,
            inv_no: values.inv_no,
            start_date: values.transaction_date ? moment(values.transaction_date[0]).format(FORMAT_DATE_1) : undefined,
            end_date: values.transaction_date ? moment(values.transaction_date[1]).format(FORMAT_DATE_1) : undefined,
        };
        fetcher.mutateAsync(tData);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelected(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<FilterInternalList> = {
        selectedRowKeys: selected,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const onChoose = () => {
        onAddInvoice(fetcher.data?.filter((el) => selected.includes(el?.id || el.inv_no)) || []);
        closeRef.current?.click();
    };

    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null} width={800}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form onFinish={onFilter} autoComplete="off" layout="vertical" requiredMark={false}>
                        <div className="grid w-full grid-cols-3 gap-x-5">
                            <Form.Item label="Invoice No" name="inv_no">
                                <Input placeholder="Invoice No" />
                            </Form.Item>
                            <Form.Item label="Transaction Date" name="transaction_date">
                                <DatePicker.RangePicker />
                            </Form.Item>
                            {/* <Form.Item label="Invoice Total" name="invoice_total">
                                <span className="font-semibold m-0">{total?.ToIndCurrency("Rp") || "Rp. -"}</span>
                            </Form.Item> */}
                        </div>
                        <Form.Item>
                            <Button loading={fetcher.isLoading} type="primary" htmlType="submit">
                                Search
                            </Button>
                        </Form.Item>
                    </Form>
                    <State data={fetcher.data} isLoading={fetcher.isLoading} isError={fetcher.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Table
                                        rowKey={(rec) => rec.id}
                                        rowSelection={rowSelection}
                                        columns={columnsFilterPayment}
                                        dataSource={fetcher.data}
                                        pagination={{ showSizeChanger: false }}
                                    />
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(fetcher.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                    <div className="flex justify-end mt-4">
                        <Button type="primary" onClick={onChoose}>
                            Add
                        </Button>
                    </div>
                </>
            )}
        </ModalTemplate>
    );
};

export default FilterDocInternalOrder;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { SelectOption } from "components/form/inputs/input-select";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import locatorService, { LocatorRequest } from "services/api-endpoints/dashboard/master-data/locator";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import { STATUS_ACTIVE } from "utils/constant";
import * as yup from "yup";

export type ModalLocatorAddProps = {
    children: (data: HandlerProps) => void;
    refresh?: () => void;
};

const schema: yup.SchemaOf<Omit<LocatorRequest, "id">> = yup.object().shape({
    warehouse_id: yup.number().required("Warehouse required"),
    is_active: yup.number().required("Is active required"),
    code: yup.string().required("Area code required"),
});

const ModalLocatorAdd = ({ children, refresh }: ModalLocatorAddProps) => {
    const closeModalRef = React.useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<Omit<LocatorRequest, "id">>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const getWarehouse = useQuery([priceService.warehouse], async () => {
        return (await priceService.Warehouse()).data.data;
    });

    const warehouses = getWarehouse.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));

    const editLocatorMutate = useMutation(
        async (data: LocatorRequest) => {
            return (await locatorService.Create(data)).data.data;
        },
        {
            onSuccess() {
                closeModalRef?.current?.click();
                form.resetFields(["warehouse_id", "is_active", "code"]);
                setValue("warehouse_id", "" as any);
                setValue("is_active", 0);
                setValue("code", "");
                message.success("Locator Created!");
                if (refresh) refresh();
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        editLocatorMutate.mutate(data);
    });

    return (
        <ModalTemplate title="Add new locator" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                        <div className="grid grid-cols-2 gap-x-4">
                            <ControlledSelectInput
                                control={control}
                                showSearch
                                allowClear
                                name="warehouse_id"
                                options={warehouses}
                                placeholder="Warehouse"
                                label="Warehouse"
                            />
                            <ControlledSelectInput
                                control={control}
                                name="is_active"
                                options={STATUS_ACTIVE}
                                placeholder="Is Active"
                                label="Is Active"
                            />
                            <ControlledInputText control={control} name="code" placeholder="code" label="Code" />
                            <Button htmlType="submit" type="primary">
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default ModalLocatorAdd;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Checkbox, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BaseGitResponse, JurnalUmum, Type } from "services/api-endpoints/accounting/jurnal-umum";
import { FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";
import React, { useEffect, useState } from "react";

type Props<T> = {
    fetcher: UseQueryResult<BaseGitResponse<T>, unknown>;
};

const { confirm } = Modal;

const GoodsInTransitTable = <T extends JurnalUmum>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [list, setList] = useState<T[]>([]);
    const [canPos, setCanPos] = useState<Boolean>(false);

    useEffect(() => {
        setList(fetcher.data?.list || []);
        const canpos =
            !!fetcher.data?.list &&
            fetcher.data?.list.length !== 0 &&
            fetcher.data?.list?.filter((el) => el.is_posting).length === fetcher.data?.list?.length;
        setCanPos(canpos);
    }, [fetcher.data]);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const onChangeCheckbox = (record: JurnalUmum) => {
        return () => {
            setList((prev) => {
                const curr = prev?.map((el) => (el.id === record.id ? { ...el, is_posting: !el.is_posting } : el));
                setCanPos(curr.filter((el) => el.is_posting).length === list?.length);
                return curr;
            });
        };
    };

    const onClickPos = () => {
        confirm({
            content: "Lanjutkan untuk Pos Jurnal?",
            onOk() {},
            onCancel() {},
        });
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            className: "h-[1px]",
            render: (text, record, i) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{i + 1}</p>
                </div>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            className: "h-[1px]",
            render: (text, record) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>
                </div>
            ),
        },
        {
            title: "Ref ID",
            dataIndex: "doc_id",
            className: "h-[1px]",
            render: (text, _) => (
                <div className="w-full h-full flex items-start">
                    <p className="capitalize m-0">{text}</p>
                </div>
            ),
        },
        // {
        //     title: "Note",
        //     dataIndex: "note",
        //     className: "h-[1px]",
        //     render: (text, _) => (
        //         <div className="w-full h-full flex items-start">
        //             <p className="capitalize m-0">{text}</p>
        //         </div>
        //     ),
        // },
        {
            title: "Account Name",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            // <p className="m-0 mb-2 font-semibold">{item?.name?.toUpperCase()}</p>
                            <p className="m-0 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2 font-semibold">{item?.name?.CapitalizeEachFirstLetter()}</p>
                        )}
                    </div>
                ));
            },
        },
        {
            title: "Debit",
            className: "h-[1px]",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Debit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
        {
            className: "h-[1px]",
            title: "Kredit",
            render: (_, { child }) => {
                return child?.map((item, i) => (
                    <div className="flex w-full" key={`${item?.name}-${i}`}>
                        {item?.type === Type.Kredit ? (
                            <p className="m-0 mb-2 font-semibold">{item?.amount?.ToIndCurrency("Rp")}</p>
                        ) : (
                            <p className="m-0 ml-10 mb-2">-</p>
                        )}
                    </div>
                ));
            },
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={list || []}
            className="w-full"
            pagination={{
                pageSize: 1000,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default GoodsInTransitTable;

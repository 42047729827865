/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
import clsx from "clsx";
import { MenuTypes, backgroundColorManufacture, cardProcessItemInfo } from "modules/manufacture/utils/constant";
import React from "react";
import { useDrag } from "react-dnd";
import ShapeProcess from "./shape";

type CardProcessItemProps = React.HTMLProps<HTMLDivElement> & {
    attach?: any;
    asPlaceholder?: boolean;
    childrenShape?: any;
};

const CardProcessItem = ({ className, attach, children, asPlaceholder, childrenShape, ...props }: CardProcessItemProps) => {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: MenuTypes.process.type,
            item: { ...MenuTypes.process, ...attach, left: 0, top: 0, zIndex: 1 },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    );

    const classnameContainer = clsx(
        className,
        cardProcessItemInfo.style.className,
        "hover:opacity-70 min-w-[150px] w-full items-center cursor-grab py-2 gap-4 px-3 flex flex-col shadow",
        isDragging && "filter grayscale",
    )

    return (
        <div ref={asPlaceholder ? null : drag} className={classnameContainer} {...props}>
            {children}
            <ShapeProcess backgroundColor={cardProcessItemInfo.style.secondaryColor} />
        </div>
    );
};

export default CardProcessItem;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterAttribute from "modules/master-data/attribute/index/filter";
import { TDataSubAttribute } from "modules/master-data/sub-attribute/models";
import SubAttributeTable from "modules/master-data/sub-attribute/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import subAttributeService from "services/api-endpoints/dashboard/master-data/sub-attribute";

const filter = ["subattribute_name", "subattribute_code", "is_active"];

const SubAttribute = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([subAttributeService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await subAttributeService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await subAttributeService.Filter(filterValue as any)).data.data;
        return (await subAttributeService.GetAll({ page })).data.data;
    });

    const deleteModelType = useMutation(async (data: TDataSubAttribute) => (await subAttributeService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataSubAttribute>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterAttribute filter={filter}>
                {(dt) => (
                    <Toolbar
                        placeholder="Subattribute Name / Subattribute Code"
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterAttribute>
            <SubAttributeTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default SubAttribute;

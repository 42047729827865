import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import InputText from "components/form/inputs/input-text";
import { Button, Form, InputNumber, message } from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { useMutation } from "react-query";
import { LocatorData, LocatorFrom, LocatorTo, ProductDocInv, ProductDocInventory, TempData } from "../models";
import ProductSelect from "./product-select";
import LocatorFromSelect from "./locator-from-select";

type Props = {
    warehouseID?: number;
    children: (data: HandlerProps) => void;
    selected: ProductDocInventory[];
    setSelected: React.Dispatch<React.SetStateAction<ProductDocInventory[]>>;
};
const schema: yup.SchemaOf<Partial<ProductDocInventory>> = yup.object().shape({
    product_id: yup.number().required("Product required"),
    product_name: yup.string(),
    qty_transfer: yup.number(),
    from_id: yup.number().required("Locator From required"),
    locator_from: yup.array(),
    to_id: yup.number().required("Locator To required"),
    locator_to: yup.array(),
});

const Transfer = ({ warehouseID, children, selected, setSelected }: Props) => {
    const closeModalRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();
    const [tempData, setTempData] = React.useState<TempData>({});

    const {
        handleSubmit,
        control,
        formState: { errors },
        getValues,
        watch,
        setValue,
    } = useForm<ProductDocInventory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const productSelected = (id: number, data: ProductDocInv[]) => {
        const isExist = selected.find((el) => el.product_id === id);
        if (isExist === undefined) {
            setValue("from_id", undefined);
            const temp = data.find((el) => el.product_id === id);
            setValue("product_name", temp?.product_name || "");
        } else {
            setTimeout(() => {
                setValue("product_id", undefined);
                message.error("Product Is Exist");
            }, 500);
        }
    };
    const locatorFromSelected = (id: number, data: LocatorData[]) => {
        if (watch("to_id") !== id) {
            const locatorFrom = data.find((el) => el.area_id === id);
            setValue("to_id", undefined);
            setTempData((prev) => ({
                ...prev,
                locatorFrom,
                qty: 1,
            }));
        } else {
            setTimeout(() => {
                setValue("from_id", undefined);
                setValue("to_id", undefined);
                message.error("Locator From and To Can't be Same");
            }, 500);
        }
    };

    const locatorToSelected = (id: number, data: LocatorData[]) => {
        if (watch("from_id") !== id) {
            const locatorTo = data.find((el) => el.area_id === id);
            setTempData((prev) => ({
                ...prev,
                locatorTo,
            }));
        } else {
            setTimeout(() => {
                setValue("to_id", undefined);
                message.error("Locator From and To Can't be Same");
            }, 500);
        }
    };

    const onChangeQty = (value: valueType | null) => {
        const qty = Number(value);
        setTempData((prev) => ({
            ...prev,
            qty,
        }));
    };

    const locatorFrom: LocatorFrom = {
        area_from: tempData.locatorFrom?.area_id || 0,
        locator_name: tempData.locatorFrom?.area_name || "",
        qty_from_before: tempData.locatorFrom?.qty_before || 0,
        qty_from_after: Number(tempData.locatorFrom?.qty_before) - Number(tempData.qty),
    };

    const locatorTo: LocatorTo = {
        area_to: tempData.locatorTo?.area_id || 0,
        locator_name: tempData.locatorTo?.area_name || "",
        qty_to_before: tempData.locatorTo?.qty_before || 0,
        qty_to_after: Number(tempData.locatorTo?.qty_before) + Number(tempData.qty),
    };

    const parseData: ProductDocInventory = {
        product_id: getValues("product_id"),
        product_name: getValues("product_name"),
        qty_transfer: tempData.qty || 0,
        locator_from: [locatorFrom],
        locator_to: [locatorTo],
    };

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const resetData = () => {
        setValue("product_id", undefined);
        setValue("from_id", undefined);
        setValue("to_id", undefined);
        setValue("locator_from", []);
        setValue("locator_to", []);
        setTempData({});
    };

    const onSubmitHandler = handleSubmit((data) => {
        setSelected((prev) => [...prev, { ...parseData }]);
        closeModalRef.current?.click();
        resetData();
    });

    return (
        <ModalTemplate width={900} title="Transfer" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal" onFinish={onSubmitHandler}>
                        <div className="w-full relative flex flex-col gap-1 items-end">
                            <ProductSelect
                                onSelectedData={productSelected}
                                classNameForm="col-span-2"
                                control={control}
                                name="product_id"
                                placeholder="Product"
                                showSearch
                            />
                            <div className="!w-full bg-gray-300 mb-4" style={{ height: "1px" }} />
                            <h4 className="!w-full font-semibold text-gray-400">Locator From</h4>
                            <LocatorFromSelect
                                onSelectedData={locatorFromSelected}
                                warehouseId={warehouseID}
                                classNameForm="col-span-2"
                                control={control}
                                name="from_id"
                                placeholder="Locator From"
                                disabled={watch("product_id") === undefined}
                            />
                            <div className="grid grid-cols-5 gap-4">
                                <InputText value={`Before : ${tempData?.locatorFrom?.qty_before || 0}`} disabled placeholder="Quantity Before" />
                                <InputNumber
                                    value={tempData?.qty}
                                    onChange={onChangeQty}
                                    className="!w-full h-8"
                                    size="middle"
                                    min={1}
                                    max={tempData.locatorFrom?.qty_before}
                                    disabled={watch("from_id") === undefined}
                                />
                                <InputText
                                    value={`After : ${Number(tempData.locatorFrom?.qty_before) - Number(tempData.qty) || 0} `}
                                    disabled
                                    placeholder="Quantity After"
                                    type="number"
                                />
                            </div>
                            <div className="!w-full bg-gray-300 mb-4" style={{ height: "1px" }} />
                            <h4 className="!w-full font-semibold text-gray-400">Locator To</h4>
                            <LocatorFromSelect
                                onSelectedData={locatorToSelected}
                                warehouseId={warehouseID}
                                classNameForm="col-span-2"
                                control={control}
                                name="to_id"
                                placeholder="Locator To"
                                disabled={watch("from_id") === undefined}
                            />
                            <div className="grid grid-cols-5 gap-4">
                                <InputText value={`Before : ${tempData?.locatorTo?.qty_before || 0}`} disabled placeholder="Quantity Before" />
                                <InputText
                                    value={`After : ${Number(tempData.locatorTo?.qty_before) + Number(tempData.qty) || 0} `}
                                    disabled
                                    placeholder="Quantity After"
                                    type="number"
                                />
                            </div>
                            <Button type="primary" className="!flex items-end" onClick={onSaveHandler}>
                                Add
                            </Button>
                        </div>
                    </Form>
                </div>
            )}
        </ModalTemplate>
    );
};

export default React.memo(Transfer);

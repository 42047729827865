/* eslint-disable no-shadow */
import { Select } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { SelectOption } from "models";
import FilterQuantity from "modules/master-data/quantity/index/filter";
import QuantityTable from "modules/master-data/quantity/index/table";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";

const filter = ["product_name", "product_code", "molding_code", "brand_id", "model_id", "model_type_id", "model_type_name", "area_id"];

const Quantity = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const warehouse = searchParams.get("warehouse_id");

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const changeWarehouse = ({ id, page }: { id: any; page: string }) => {
        filter.forEach((ftr) => searchParams.delete(ftr));
        searchParams.set("warehouse_id", id);
        searchParams.set("page", page);
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.warehouse, warehouse],
        async () => {
            return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            onSuccess: (data) => {
                if (warehouse) return;
                changeWarehouse({ id: data[0]?.value, page: "1" });
            },
            enabled: warehouse !== undefined,
        }
    );

    const getList = useQuery(
        [quantityService.getAll, page, searchValue, filterValue, isFilter, isSearch, warehouse],
        async () => {
            if (isSearch) return (await quantityService.Search({ page, param_search: searchValue.query, warehouse_id: warehouse })).data.data;
            if (isFilter) return (await quantityService.Filter({ ...(filterValue as any), warehouse_id: warehouse })).data.data;
            return (await quantityService.GetAll({ page, warehouse_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterQuantity filter={filter} warehouseId={warehouse}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        prefixAddition={() => (
                            <Select
                                className="!w-[400px]"
                                showSearch
                                value={warehouse ? Number(warehouse) : undefined}
                                loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                                placeholder="Warehouse"
                                options={getWarehouse.data || []}
                                onChange={(val) => changeWarehouse({ id: val, page: "1" })}
                            />
                        )}
                        placeholder="Product Name / Product Code / Molding Code / Brand / Model"
                        defaultParams={["warehouse_id"]}
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterQuantity>
            <QuantityTable fetcher={getList} />
        </div>
    );
};

export default Quantity;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import { Button, Card, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VersionHistory from "modules/master-data/consignment/version-history";
import Print from "modules/master-data/external-order/ext-gr/print";
import TableView from "modules/master-data/external-order/ext-gr/table-view";
import { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { DetailList } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import extGoodsReceiptService from "services/api-endpoints/dashboard/master-data/ext-goods-receipt";
import { C_STATUS_ORDER, STATUS_BP_ORDER } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ExtGoodsReceiptView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    const [products, setProducts] = useState<DetailList[]>([]);
    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `BP Goods Receipt - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const detailQuery = useQuery(
        [extGoodsReceiptService.detailGR, id],
        async () => {
            return (await extGoodsReceiptService.DetailGR({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="goods receipt detail"
                rightAddition={() => (
                    <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                        <AiFillPrinter className="m-0 mr-2" />
                        Print
                    </Button>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Document No <br />
                                        <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                    </p>
                                    <div className="capitalize m-0 flex flex-col gap-1">
                                        Referensi No <br />
                                        {(detailQuery.data?.references || []).map((val) => (
                                            <RefModal id={val.ref_id} docNo={val.ref_no} type={detailQuery.data?.ref_type}>
                                                {(dt) => (
                                                    <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                        {`${val.ref_no}, ` || "-"}
                                                    </span>
                                                )}
                                            </RefModal>
                                        ))}
                                    </div>
                                    <p className="capitalize m-0">
                                        BP Name <br />
                                        <span className="underline m-0">{detailQuery.data?.bp_name || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Status <br />
                                        <span className="underline m-0">
                                            {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <TableView loading={detailQuery.isLoading} list={products} setList={setProducts} action={false} />,
                        },
                        {
                            label: `Version History`,
                            key: "2",
                            children: <VersionHistory id={id} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default ExtGoodsReceiptView;

import moment from "moment";
import { DownloadData, DownloadDataOnline } from "services/api-endpoints/dashboard-giias/report";
import { FORMAT_DATE_TIME } from "utils/constant";
import XLSX from "xlsx-js-style";

export default {};
export const convertOfflineToExcel = ({ title, list }: { title: string; list: DownloadData[] }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Inv No", ...headerStyle },
        { v: "Code", ...headerStyle },
        { v: "Name", ...headerStyle },
        { v: "Subcategory Name", ...headerStyle },
        { v: "Created At", ...headerStyle },
        { v: "Is Active", ...headerStyle },
        { v: "Note", ...headerStyle },
        { v: "Payment Channel", ...headerStyle },
        { v: "Quantity", ...headerStyle },
        { v: "Price", ...headerStyle },
        { v: "Discount", ...headerStyle },
    ];

    // const errorStyle = { font: { bold: true, color: { rgb: "de122d" } } };

    const transformList = list?.map((el, i) => {
        return [
            { v: i + 1, t: "s" },
            { v: el.inv_no, t: "s" },
            { v: el.code1, t: "s" },
            { v: el.name, t: "s" },
            { v: el.subcategory_name, t: "s" },
            { v: moment(el.created_at)?.format(FORMAT_DATE_TIME), t: "s" },
            { v: el.is_active ? "Active" : "Inactive", t: "s" },
            { v: el.note, t: "s" },
            { v: el.payment_channel, t: "s" },
            { v: el.qty, t: "n" },
            { v: el.price, t: "n" },
            { v: el.diskon, t: "s" },
        ];
    });

    const ws = XLSX.utils.aoa_to_sheet([header, ...transformList]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
};

export const convertOnlineToExcel = ({ title, list }: { title: string; list: DownloadDataOnline[] }) => {
    const wb = XLSX.utils.book_new();

    const headerStyle = {
        t: "s",
        s: {
            fill: {
                fgColor: {
                    rgb: "4EAEFF",
                },
                bgColor: {
                    rgb: "ffffff",
                },
            },
            font: {
                bold: true,
                color: {
                    rgb: "ffffff",
                },
            },
        },
    };

    const header = [
        { v: "No", ...headerStyle },
        { v: "Order No", ...headerStyle },
        { v: "Sub Brand", ...headerStyle },
        { v: "Customer Name", ...headerStyle },
        { v: "Market Place", ...headerStyle },
        { v: "Customer Invoice", ...headerStyle },
        { v: "Code", ...headerStyle },
        { v: "Product Name", ...headerStyle },
        { v: "Subcategory Name", ...headerStyle },
        { v: "Attribute Name", ...headerStyle },
        { v: "Created At", ...headerStyle },
        { v: "Is Active", ...headerStyle },
        { v: "Quantity", ...headerStyle },
        { v: "Price", ...headerStyle },
        { v: "Discount", ...headerStyle },
    ];

    const transformList = list?.map((el, i) => {
        return [
            { v: i + 1, t: "s" },
            { v: el.order_no, t: "s" },
            { v: el.sub_brand, t: "s" },
            { v: el.customer_name, t: "s" },
            { v: el.market_place, t: "s" },
            { v: el.customer_invoice, t: "s" },
            { v: el.code1, t: "s" },
            { v: el.product_name, t: "s" },
            { v: el.subcategory_name, t: "s" },
            { v: el.attribute_name, t: "s" },
            { v: moment(el.created_at)?.format(FORMAT_DATE_TIME), t: "s" },
            { v: el.is_active ? "Active" : "Inactive", t: "s" },
            { v: el.qty, t: "n" },
            { v: el.price, t: "n" },
            { v: el.diskon, t: "n" },
        ];
    });
    const ws = XLSX.utils.aoa_to_sheet([header, ...transformList]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, `${title}.xlsx`);
};

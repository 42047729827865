/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { Product, SelectOption } from "models";
import TableAdd from "modules/master-data/consignment/delivery-order/table-add";
import DeliveryOrderAddModal from "modules/master-data/consignment/modal/delivery-order-add-modal";
import { Key, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import cDeliveryOrderService, { CreateDeliveryOrder, CreateProduct, ProductCPL } from "services/api-endpoints/dashboard/master-data/c-delivery-order";
import consignmentService from "services/api-endpoints/dashboard/master-data/consignment";
import { AUTOGENERATED } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateDeliveryOrder>> = yup.object().shape({
    account_id_to: yup.number().required("Partner Required"),
    driver_name: yup.string().required("Driver Name required"),
    plate_number: yup.string().required("Plate Number required"),
    note: yup.string(),
    child_document: yup.array(),
    detail_product: yup.array(),
});

const CDeliveryOrderAdd = () => {
    const navigate = useNavigate();
    const [productsSO, setProductsSO] = useState<ProductCPL[]>([]);
    const [realProductsSO, setRealProductsSO] = useState<ProductCPL[]>([]);
    const [selectedSO, setSelectedSO] = useState<Key[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreateDeliveryOrder>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const watchPartner = watch("account_id_to");

    const getPartnerInt = useQuery([consignmentService.getPartnerInt], async () => {
        return (await consignmentService.GetPartnerInt()).data.data?.map((el) => ({ label: el.partner_name, value: el.partner_id } as SelectOption));
    });

    const createMutate = useMutation(
        [cDeliveryOrderService.create],
        async (data: CreateDeliveryOrder) => {
            return (await cDeliveryOrderService.Create(data)).data.data;
        },
        {
            onSuccess(data) {
                message.success("Delivery Order Created");
                navigate(-1);
            },
        }
    );

    const onRemoveProduct = (product: Product) => {
        setProductsSO((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const onCreateSubmitHandler = handleSubmit(async (data) => {
        const parse: CreateDeliveryOrder = {
            ...data,
            child_document: [...new Set(realProductsSO?.map((p) => p.id!) || [])],
            detail_product: [
                ...(realProductsSO?.map(
                    (p) =>
                        ({
                            product_id: p.product_id,
                            qty: p.qty,
                            pl_id: p.id,
                            is_free: p.is_free,
                            price: p.product_price,
                            discount_price: p.discount_price,
                            voucher_id: p.voucher_id,
                            note: p.note,
                            no_koli: p.no_koli,
                            is_last_stock: p.is_last_stock,
                        } as CreateProduct)
                ) || []),
            ],
        };
        createMutate.mutate(parse);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        onCreateSubmitHandler();
    };

    const onProductSO = (data: ProductCPL[], real: ProductCPL[]) => {
        setProductsSO(data);
        setRealProductsSO(real);
    };

    const partnerName = getPartnerInt.data?.find((val) => val.value === watchPartner)?.label;

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new delivery order"
                rightAddition={() => (
                    <Button disabled={!productsSO.length} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <div className="grid grid-cols-3 gap-x-5">
                        <p className="capitalize m-0">
                            Document No <br />
                            <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            control={control}
                            options={getPartnerInt.data}
                            loading={getPartnerInt.isLoading}
                            name="account_id_to"
                            placeholder="Partner Internal"
                            label="Partner Internal"
                        />
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="note"
                            label="Note"
                            placeholder="Note"
                        />
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="plate_number"
                            label="Plate Number"
                            placeholder="Plate Number"
                        />
                        <ControlledInputText
                            className="!w-[300px]"
                            control={control}
                            labelCol={{ xs: 12 }}
                            name="driver_name"
                            label="Driver Name"
                            placeholder="Driver Name"
                        />
                    </div>
                </Card>
                <DeliveryOrderAddModal
                    partnerID={watchPartner}
                    partnerName={partnerName || ""}
                    onProductSO={onProductSO}
                    selectedSO={selectedSO}
                    setSelectedSO={setSelectedSO}
                >
                    {(dt) => (
                        <Button
                            className="!flex !items-center w-fit mr-3"
                            type="primary"
                            onClick={dt.openModal}
                            disabled={!watchPartner}
                            loading={createMutate.isLoading}
                        >
                            <MdAdd className="mr-2" />
                            Add Picking List
                        </Button>
                    )}
                </DeliveryOrderAddModal>
                <TableAdd list={productsSO} setList={setProductsSO} removeItemList={onRemoveProduct} action={false} />
            </Form>
        </div>
    );
};

export default CDeliveryOrderAdd;

import { Table, Transfer } from "antd";
import type { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import type { TransferItem, TransferProps } from "antd/es/transfer";
import difference from "lodash/difference";
import React from "react";

interface TableTransferProps<T> extends TransferProps<T> {
    dataSource: T[];
    loading?: boolean;
    editList?: any[];
    setEditList?: React.Dispatch<React.SetStateAction<any[]>>;
    children: (data: any) => React.ReactNode;
}

const TableDocEditTransfer = <T extends TransferItem>({ loading, editList, setEditList, children, ...restProps }: TableTransferProps<T>) => {
    return (
        <Transfer {...restProps}>
            {(action) => {
                const leftTableColumns: ColumnsType<T> = [
                    {
                        dataIndex: "area_name",
                        title: "Locator",
                        render: (text) => text || "-",
                    },
                    {
                        dataIndex: "product_code",
                        title: "Code",
                        render: (text) => text,
                    },
                    {
                        dataIndex: "product_name",
                        title: "Name",
                        render: (text) => text,
                    },
                    {
                        dataIndex: "qty_from",
                        title: "Qty",
                        render: (text) => text,
                    },
                ];

                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: (item) => ({ disabled: action.disabled || item.disabled }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, action.selectedKeys)
                            : difference(action.selectedKeys, treeSelectedKeys);
                        action.onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        action.onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: action.selectedKeys,
                };

                if (action.direction === "left") {
                    return (
                        <Table
                            rowSelection={rowSelection}
                            columns={leftTableColumns as any}
                            dataSource={action.filteredItems}
                            size="small"
                            loading={loading}
                            style={{ pointerEvents: action.disabled ? "none" : undefined }}
                            onRow={({ key, disabled: itemDisabled }) => ({
                                onClick: () => {
                                    if (itemDisabled || action.disabled) return;
                                    action.onItemSelect(key as string, !action.selectedKeys.includes(key as string));
                                },
                            })}
                        />
                    );
                }

                return children({ ...action, rowSelection });
            }}
        </Transfer>
    );
};
export default TableDocEditTransfer;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import OrderView from "pages/order/view";
import { useRef } from "react";
import { AddItemT } from "./modal-add-item";

type Props = ModalProps & {
    item: AddItemT;
};

const OrderDetailModal = ({ item, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const onCancelModal = () => {
        closeRef.current?.click();
    };

    return (
        <ModalTemplate
            {...props}
            title={item.invoice_detail?.inv_no}
            onCancel={onCancelModal}
            destroyOnClose
            width={1200}
            handlerInComponent={children}
            footer={false}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <OrderView asPreview paramId={item.invoice_detail?.inv_id} />
                </>
            )}
        </ModalTemplate>
    );
};

export default OrderDetailModal;

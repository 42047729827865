/* eslint-disable no-plusplus */
import { Tabs } from "antd";
import { StateContext } from "context/state";
import { UserContext } from "context/user";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HistoryTab = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { state: userState } = React.useContext(UserContext);
    const { state, removeHistoryTab, navigateAppHandler } = React.useContext(StateContext);
    const [activeKey, setActiveKey] = React.useState("");
    const items = React.useMemo(
        () =>
            state?.historyTab?.map((history) => ({
                ...history,
                key: history.pathname,
                label: (
                    <p className="text-[10px] font-semibold m-0 leading-none">
                        {history.appName} <br />
                        <span className="text-sm font-light leading-none">{history.label}</span>
                    </p>
                ),
            })),
        [state?.historyTab]
    );

    const currentLocation = location.pathname.replace(/\/(add|edit|view)$/, "");

    React.useEffect(() => {
        setActiveKey(currentLocation);
    }, [location.pathname]);

    const onChange = (key: string) => {
        setActiveKey(key);
    };

    const remove = (key: string) => {
        if (removeHistoryTab) {
            const restItems = removeHistoryTab(key);
            if (key !== activeKey) return;

            const aKey = restItems[restItems.length - 1]?.pathname;

            setActiveKey(aKey);
            if (aKey) {
                navigate(aKey);
                const apps = userState?.user?.apps;
                const appsname = items?.find((h) => h.pathname === aKey)?.appName;

                const app = apps?.find((ap) => ap.apps_name === appsname);
                if (!app) return;
                if (navigateAppHandler) navigateAppHandler(app);
            }
        }
    };

    const onEdit = (targetKey: any, action: "remove" | "add") => {
        if (action === "remove") {
            remove(targetKey);
        }
    };

    const onClick = (key: any) => {
        const apps = userState?.user?.apps;
        const history = items?.find((h) => h.pathname === key);

        navigate(history!.url, { replace: true });

        const app = apps?.find((ap) => ap.apps_name === history?.appName);
        if (!app) return;
        if (navigateAppHandler) navigateAppHandler(app);
    };

    if (!items?.length) return null;
    if (items?.length === 1 && items[0].pathname === currentLocation) return null;
    return (
        <Tabs
            className="history-tabs"
            hideAdd
            onTabClick={onClick}
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            type="editable-card"
            onEdit={onEdit}
            items={items as any}
        />
    );
};

export default HistoryTab;

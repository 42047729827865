import { Button, Card, message, Skeleton, Tabs, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import State from "components/common/state";
import ModalDelete from "components/modal/delete_modal";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import AdvanceSearchBp from "modules/advance-search-bp";
import ProductTable from "modules/dashboard-jsl/purchase-order/edit/product-table";
import HistoryTable from "modules/dashboard-jsl/purchase-order/view/history-table";
import ModalHistoryPO from "modules/dashboard-jsl/purchase-order/view/modal-history";
import Print from "modules/dashboard-jsl/purchase-order/view/print";
import React, { useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiSave, BiTrash } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import purchaseOrderService, { DeleteData, EditPO, ListDetail, ProductPoEdit } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { PO_INT_COLOR_TAG, PO_INT_DRAFT, PO_INT_NEED_APPROVE, PO_INT_NEW, PRODUCT_JSL, STATUS_PURCHASE_ORDER_INT } from "utils/constant";
import { getDocumentRoute } from "utils/routes";

const DEFAULT_QUANTITY = 1;

const PurchaseOrderEdit = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const navigate = useNavigate();
    const letterRef = useRef<HTMLDivElement | null>(null);

    const [products, setProducts] = useState<ListDetail[]>([]);
    const [deletedProduct, setDeletedProduct] = useState<ListDetail[]>([]);
    const [productOwner, setProductOwner] = useState<number>(PRODUCT_JSL);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);
    const [note, setNote] = useState<string>("");

    const detailQuery = useQuery(
        [purchaseOrderService.detail, id],
        async () => {
            return (await purchaseOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
                const tempProduct = data.list_detail && data.list_detail[data.list_detail.length - 1];
                setProductOwner(tempProduct?.product_account || PRODUCT_JSL);
                setNote(data.note || "");
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const getHeaderPOQuery = useQuery([purchaseOrderService.getHeaderPO], async () => {
        return (await purchaseOrderService.GetHeaderPO()).data.data;
    });

    const editDraftPoMutation = useMutation(
        [purchaseOrderService.editDraftPO],
        async (data: EditPO) => {
            return (await purchaseOrderService.EditDraftPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal PO Draft Edited");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const editPoMutation = useMutation(
        [purchaseOrderService.editPO],
        async (data: EditPO) => {
            return (await purchaseOrderService.EditPO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal PO Edited");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const editCreateMutation = useMutation(
        [purchaseOrderService.editCreate],
        async (data: EditPO) => {
            return (await purchaseOrderService.EditCreate(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal PO Save Edit");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const history = useQuery(
        [purchaseOrderService.historyPO, page],
        async () => {
            return (await purchaseOrderService.HistoryPO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const editPOData: EditPO = {
        id: id as any,
        note,
        detail_product: [...products].map(
            (el) =>
                ({
                    order_detail_id: el.order_detail_id || null,
                    price: el.price !== undefined ? el.price : el.product_price,
                    product_id: el.product_id,
                    qty: el.qty,
                } as ProductPoEdit)
        ),
    };

    const mergePOData: EditPO = {
        ...editPOData,
        detail_product: [
            ...editPOData.detail_product,
            ...deletedProduct.map((el) => ({ order_detail_id: el.order_detail_id, is_active: 0 } as ProductPoEdit)),
        ],
    };

    const mergeEditPOData: EditPO = {
        ...editPOData,
        detail_product: [...editPOData.detail_product],
    };

    const onSaveHandler = () => {
        editPoMutation.mutate(mergePOData);
    };

    const onDraftHandler = () => {
        editDraftPoMutation.mutate(mergePOData);
    };

    const onEditCreateHandler = () => {
        editCreateMutation.mutate(mergeEditPOData);
    };

    const onSearchSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            if (products.find((p) => p.product_id === data.product_id)) {
                callback("Product already exist!");
                return;
            }
            setProducts((prev) => [...prev, { ...data, qty: 1, product_code: data.product_code1 }]);
            callback(null);
        },
        [products]
    );

    const onSearchDeSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            setProducts((prev) => [...prev].filter((p) => p.product_id !== data.product_id));
            callback(null);
        },
        [products]
    );

    const onAddProduct = (data: ListDetail, callback: (str: string | null) => void) => {
        if (products.find((p) => p.product_id === data.product_id)) {
            callback("Product already exist!");
            return;
        }
        setProducts((prev) => [...prev, { ...data, qty: DEFAULT_QUANTITY, price: data.product_price, order_detail_id: null }]);
        callback(null);
    };

    const onRemoveProduct = (product: ListDetail) => {
        setDeletedProduct((prev) => {
            if (product.order_detail_id) return [...prev, product];
            return prev;
        });
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    const deletePOMutation = useMutation(
        [purchaseOrderService.deletePO],
        async (data: DeleteData) => {
            return (await purchaseOrderService.DeletePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Internal Purchase order deleted");
                navigate(getDocumentRoute({ menuName: "int-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const onDeletePOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePOMutation.mutate(dataDelete);
    };

    const type = STATUS_PURCHASE_ORDER_INT.find((el) => el.value === detailQuery.data?.order_status);

    const onClickLinkHandler = (ID: number) => {
        setDetailHistoryID(ID);
    };

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Internal PO - ${id}`,
        pageStyle: "@page {  }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Edit purchase order"
                rightAddition={() => (
                    <>
                        <ModalDelete title="Delete PO" onSubmit={onDeletePOHandler}>
                            {(dt) => (
                                <Button
                                    loading={deletePOMutation.isLoading}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                        {(detailQuery.data?.order_status === PO_INT_NEW || detailQuery.data?.order_status === PO_INT_NEED_APPROVE) && (
                            <Button
                                loading={editPoMutation.isLoading}
                                disabled={products.length === 0}
                                onClick={onEditCreateHandler}
                                type="primary"
                                className="!flex !items-center"
                            >
                                <BiSave className="m-0 mr-2" />
                                Save Edit
                            </Button>
                        )}
                        {detailQuery.data?.order_status === PO_INT_DRAFT && (
                            <>
                                <Button
                                    loading={editDraftPoMutation.isLoading}
                                    disabled={products.length === 0}
                                    onClick={onDraftHandler}
                                    type="default"
                                    className="!flex !items-center"
                                >
                                    <RiDraftLine className="m-0 mr-2" />
                                    Draft
                                </Button>
                                <Button
                                    loading={editPoMutation.isLoading}
                                    disabled={products.length === 0}
                                    onClick={onSaveHandler}
                                    type="primary"
                                    className="!flex !items-center"
                                >
                                    <BiSave className="m-0 mr-2" />
                                    Save
                                </Button>
                            </>
                        )}
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="w-full grid grid-cols-4 gap-x-4">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{detailQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            Note <br />
                                            <TextArea
                                                value={note}
                                                name="note"
                                                placeholder="Note"
                                                onChange={(val) => setNote(val.target.value)}
                                                rows={2}
                                            />
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            status <br />
                                            <Tag color={PO_INT_COLOR_TAG[type?.value || 0]}>{type?.label}</Tag>
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <AdvanceSearchBp
                isSPM={getHeaderPOQuery.data?.is_spm || false}
                selected={products}
                setSelected={setProducts}
                productOwner={productOwner}
                setProductOwner={setProductOwner}
            >
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearchBp>
            {detailQuery.data?.order_status === PO_INT_DRAFT ? (
                <ProductTable
                    loading={detailQuery.isLoading || detailQuery.isRefetching}
                    list={products}
                    setList={setProducts}
                    removeItemList={onRemoveProduct}
                />
            ) : (
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: (
                                    <ProductTable
                                        loading={detailQuery.isLoading || detailQuery.isRefetching}
                                        list={products}
                                        setList={setProducts}
                                        removeItemList={onRemoveProduct}
                                    />
                                ),
                            },
                            {
                                label: `History`,
                                key: "2",
                                children: (
                                    <ModalHistoryPO idHistory={detailHistoryID}>
                                        {(dt) => (
                                            <HistoryTable
                                                fetcher={history}
                                                onClickLink={(ID) => {
                                                    onClickLinkHandler(ID);
                                                    dt.openModal();
                                                }}
                                            />
                                        )}
                                    </ModalHistoryPO>
                                ),
                            },
                        ]}
                    />
                </Card>
            )}
            <Print ref={letterRef} data={detailQuery.data || null} />
        </div>
    );
};

export default PurchaseOrderEdit;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable camelcase */
import { yupResolver } from "@hookform/resolvers/yup";
import { Card, Form, List, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import TableView from "modules/master-data/consignment/generate-invoice/table-view";
import RefModal from "modules/master-data/consignment/modal/ref-modal";
import VersionHistory from "modules/master-data/consignment/version-history";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import consignmentService, { CreateGenerateInvoice, ProductPerformaInv } from "services/api-endpoints/dashboard/master-data/consignment";
import { C_STATUS_ORDER, TYPE_MODAL_C_SO } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateGenerateInvoice>> = yup.object().shape({
    account_id_from: yup.number().required("Partner Required"),
    note: yup.string().required("Invoice Note Required"),
    doc_id: yup.array(),
    detail_product: yup.array(),
});

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const CGenerateInvoiceView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<ProductPerformaInv[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, setError, watch } = useForm<CreateGenerateInvoice>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [consignmentService.detail, id],
        async () => {
            return (await consignmentService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail);
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail generate invoice" />
            <Form className="flex flex-col gap-5" form={form} layout="vertical">
                <Card className="">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-3 gap-x-5">
                                        <p className="capitalize m-0">
                                            Document No <br />
                                            <span className="underline m-0">{detailQuery.data?.doc_no || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0">
                                            Referensi No <br />
                                            {detailQuery.data?.ref_id ? (
                                                <RefModal
                                                    id={detailQuery.data?.ref_id}
                                                    docNo={detailQuery.data?.ref_no}
                                                    type={detailQuery.data.ref_type}
                                                >
                                                    {(dt) => (
                                                        <span onClick={dt.openModal} className="cursor-pointer !text-primary underline m-0">
                                                            {detailQuery.data?.ref_no || "-"}
                                                        </span>
                                                    )}
                                                </RefModal>
                                            ) : (
                                                <span className="underline m-0">-</span>
                                            )}
                                        </p>
                                        <p className="capitalize m-0">
                                            Partner Internal <br />
                                            <span className="underline m-0">{detailQuery.data?.partner_name || "-"}</span>
                                        </p>
                                        <p className="capitalize m-0 mt-4">
                                            Status <br />
                                            <span className="underline m-0">
                                                {C_STATUS_ORDER.find((val) => val.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                </State.Loading>
                            </>
                        )}
                    </State>
                </Card>
                <Card>
                    <Tabs
                        defaultActiveKey="1"
                        items={[
                            {
                                label: `Detail Product`,
                                key: "1",
                                children: <TableView list={products} setList={setProducts} action={false} loading={detailQuery.isLoading} />,
                            },
                            {
                                label: `Version History`,
                                key: "2",
                                children: <VersionHistory id={id} />,
                            },
                        ]}
                    />
                </Card>
            </Form>
        </div>
    );
};

export default CGenerateInvoiceView;

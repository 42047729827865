/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { AllModel, SelectOption } from "models";
import React from "react";
import { CreateDataDocInventory } from "../models";

export function withDocType<T extends ControlledInputSelectProps<CreateDataDocInventory>>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const year = ["INTERNAL MOVE"].map((el) => ({ label: el || "", value: el || "" } as SelectOption));

        return <Component {...props} optionFilterProp="children" options={year || []} />;
    };
}

const DocTypeSelect = withDocType(ControlledSelectInput);

export default DocTypeSelect;

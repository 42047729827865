import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import PenerimaanBarangView from "pages/penerimaan-barang/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import PurchasingRequestView from "pages/purchasing-request/view";
import { TYPE_MODAL_PB, TYPE_MODAL_PO, TYPE_MODAL_PR } from "utils/constant";

export type Props = ModalProps & {
    id?: any;
    type?: number;
};

const ModalDetailInv = ({ id, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1200} handlerInComponent={children} footer={null} {...props}>
            {(dt) => {
                if (type === TYPE_MODAL_PR) {
                    return <PurchasingRequestView paramId={id} asPreview />;
                }
                if (type === TYPE_MODAL_PO) {
                    return <PurchasingInventoryView paramId={id} asPreview />;
                }
                if (type === TYPE_MODAL_PB) {
                    return <PenerimaanBarangView paramId={id} asPreview />;
                }
                return <p className="text-red-400 text-xl">Doc type not handled yet</p>;
            }}
        </ModalTemplate>
    );
};

export default ModalDetailInv;

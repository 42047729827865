/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button, TablePaginationConfig, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useMutation } from "react-query";
import { useReactToPrint } from "react-to-print";
import cPackingListService, { ProductDetail } from "services/api-endpoints/dashboard/master-data/c-packing-list";
import JSLPng from "assets/images/jsl-big-trans.png";
import KoliPrint from "./koli-print";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    plId?: any;
};

export function tableEdit<T extends ProductDetail>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ plId, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState(1);

        const qrRef = useRef<HTMLDivElement | null>(null);
        const handlePrintLetter = useReactToPrint({
            content: () => qrRef.current,
            documentTitle: `BARCODE`,
            pageStyle: "@page { size: auto }",
        });

        const detailKoliMutate = useMutation([], async (param: { pl_id: any; no_koli: any }) => {
            return (await cPackingListService.DetailKoli(param)).data.data;
        });

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const clickPrint = (row: ProductDetail) => {
            return () => {
                detailKoliMutate.mutateAsync({ pl_id: plId, no_koli: row.no_koli }).then(() => {
                    setTimeout(() => {
                        handlePrintLetter();
                    }, 500);
                });
            };
        };

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => (
                    <div className="flex flex-row">
                        <p className="capitalize m-0 mr-2">{text || "-"}</p>
                        {record.note && (
                            <Tag color="blue" className="!h-fit">
                                {record.note}
                            </Tag>
                        )}
                    </div>
                ),
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code 2",
                dataIndex: "code2",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Owner",
                dataIndex: "product_owner",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Koli",
                dataIndex: "no_koli",
                render: (text, record) => {
                    if (detailKoliMutate.isLoading && record.no_koli === detailKoliMutate.variables?.no_koli) {
                        return "Load...";
                    }
                    return (
                        <Button title="Print barcode" type="link" onClick={clickPrint(record)}>
                            {text}
                        </Button>
                    );
                },
            },
        ];

        const isEditing = (record: ProductDetail, edited: ProductDetail | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: ProductDetail, edited: ProductDetail) => record.product_id === edited?.product_id;
        const rowKey = (record: ProductDetail) => (record.product_id + record.no_koli) as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty_approve: "number" };

        return (
            <>
                <KoliPrint
                    ref={qrRef}
                    noKoli={detailKoliMutate.data?.no_koli}
                    products={detailKoliMutate.data?.list_product as any}
                    qrString={`${detailKoliMutate.data?.pl_no}_${detailKoliMutate.data?.no_koli}` || ""}
                />
                <Component
                    {...props}
                    setEditRow={setEditRow}
                    editRow={editRow}
                    editInputType={editInputType}
                    columns={columns}
                    isEditing={isEditing}
                    findIndexSave={findIndexSave}
                    rowKey={rowKey}
                    onChange={handleTableChange}
                />
            </>
        );
    };
}

const TableView = tableEdit(EditTable);
export default TableView;

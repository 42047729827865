import { HiMinus } from "react-icons/hi2";
import { TbDivide, TbPlus } from "react-icons/tb";
import { TiTimes } from "react-icons/ti";
import { MenuTypesType } from "./models";

export const getIdElementEntity = (idEntity?: string) => {
    return `#entity-${idEntity}`;
};

export const setIdElementEntity = (idEntity?: string) => {
    return `entity-${idEntity}`;
};

export const backgroundColorManufacture = {
    primary: "bg-gray-300",
    secondary: "bg-white",
    danger: "!bg-red-400",
};

export const cardSubProcessItemInfo = {
    style: {
        className: "capitalize rounded-xl bg-orange-300 text-white shadow-lg",
        color: "bg-orange-300",
        secondaryColor: "bg-orange-200",
    },
};

export const cardProcessItemInfo = {
    style: {
        className: "capitalize rounded-xl bg-orange-400 text-white shadow-lg",
        color: "bg-orange-400",
        secondaryColor: "bg-orange-300",
    },
};

export const cardOperatorItemInfo = {
    style: {
        className: "capitalize rounded-xl bg-red-300 text-white shadow-lg",
        color: "bg-red-300",
        secondaryColor: "bg-red-200",
    },
};

export const cardMaterialItemInfo = {
    style: {
        className: "capitalize rounded !bg-blue-300 text-white shadow-lg",
        color: "!bg-blue-300",
        secondaryColor: "bg-blue-200",
    },
};

export const cardVariableItemInfo = {
    style: {
        className: "capitalize rounded !bg-purple-300 text-white shadow-lg",
        color: "!bg-purple-300",
        secondaryColor: "bg-purple-400",
    },
};

export const cardModelItemInfo = {
    style: {
        className: "capitalize rounded !bg-green-300 text-white shadow-lg",
        color: "!bg-green-300",
        secondaryColor: "bg-green-200",
    },
};

export const MenuTypes: MenuTypesType = {
    subProcess: {
        id: 2,
        text: "Process",
        style: cardSubProcessItemInfo.style.className,
        color: cardSubProcessItemInfo.style.color,
        initial: "SP",
        type: "SUB_PROCESS",
    },
    process: {
        id: 3,
        text: "Process",
        style: cardProcessItemInfo.style.className,
        color: cardProcessItemInfo.style.color,
        initial: "P",
        type: "PROCESS",
    },
    operator: {
        id: 1,
        text: "Operator",
        style: cardOperatorItemInfo.style.className,
        color: cardOperatorItemInfo.style.color,
        initial: "O",
        type: "OPERATOR",
    },
    multipleOperator: {
        id: 10,
        text: "Operator",
        style: cardOperatorItemInfo.style.className,
        color: cardOperatorItemInfo.style.color,
        initial: "O",
        type: "MULTIPLE_OPERATOR",
    },
    material: {
        id: 4,
        text: "Material",
        style: cardMaterialItemInfo.style.className,
        color: cardMaterialItemInfo.style.color,
        initial: "M",
        type: "MATERIAL",
    },
    variable: {
        id: 5,
        text: "Variable",
        style: cardVariableItemInfo.style.className,
        color: cardVariableItemInfo.style.color,
        initial: "V",
        type: "VARIABLE",
    },
    model: {
        id: 6,
        text: "Model",
        style: cardModelItemInfo.style.className,
        color: cardModelItemInfo.style.color,
        initial: "M",
        type: "MODEL",
    },
};

export const plusOperator = {
    id: 1,
    icon: <TbPlus />,
    // resolver: (a: any, b: any) => a + b,
    resolver: {
        arguments: "a, b",
        body: "return a+b;",
    },
    name: "Addition",
};

export const minusOperator = {
    id: 2,
    icon: <HiMinus />,
    // resolver: (a: any, b: any) => a - b,
    resolver: {
        arguments: "a, b",
        body: "return a-b;",
    },
    name: "Substraction",
};

export const timesOperator = {
    id: 3,
    icon: <TiTimes />,
    // resolver: (a: any, b: any) => a * b,
    resolver: {
        arguments: "a, b",
        body: "return a*b;",
    },
    name: "Multiplication",
};

export const divideOperator = {
    id: 4,
    icon: <TbDivide />,
    // resolver: (a: any, b: any) => a / b,
    resolver: {
        arguments: "a, b",
        body: "return a/b;",
    },
    name: "Division",
};

export const operatorIcons = [plusOperator, minusOperator, timesOperator, divideOperator];

export const convertToStringFormat = (num?: number | null) => {
    if (num === null || num === undefined) return "-";
    const mergeNum = num?.toString().split(".").join("");
    return mergeNum.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertToIntFormat = (str?: string) => {
    if (str === null || str === undefined) return 0;
    return parseInt(str.toString().split(".").join(""), 10);
};

export const animateEntity = {
    transition: {
        type: "spring",
        damping: 10,
        mass: 0.75,
        stiffness: 300,
        duration: 0.1,
    },
    animate: { scale: 1 },
    initial: { scale: 0.95 },
    exit: { scale: 0.3, opacity: 0 },
};

export const MIN_TOTAL_ITEM = 1;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Organization {
    organization_id: number;
    organization_name: string;
}

export interface BankAccount {
    id: number;
    name: string;
    description: string;
    account_no: string;
    organization_id: number;
    organization_name: string;
    is_active: number;
}

export interface CreateBankAccount {
    name: string;
    description: string;
    account_no: string;
    organization_id: number;
    is_active: number;
}

export interface EditBankAccount extends CreateBankAccount {
    id?: any;
}

export interface FilterBankAccount {
    name?: any;
    description?: any;
    account_no?: any;
    organization_id?: any;
    page?: any;
}

class BankAccountService extends BaseService {
    getOrg = "/bank-account/get-organization";

    create = "/bank-account/create";

    edit = "/bank-account/edit";

    getAll = "/bank-account/get-all";

    detail = "/bank-account/get-detail";

    search = "/bank-account/search";

    filter = "/bank-account/get-filter";

    Filter<T extends BankAccount>(param: FilterBankAccount) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends BankAccount>(params: { query: any; page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends BankAccount>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${data.id}`,
            });
            return req;
        });
    }

    GetAll<T extends BankAccount>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditBankAccount) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateBankAccount) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetOrg<T extends Organization[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getOrg,
            });
            return req;
        });
    }
}

const bankAccountService = new BankAccountService();
export default bankAccountService;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import BPInvoiceView from "pages/bp-invoice/view";
import CDeliveryOrderView from "pages/c-delivery-order/view";
import CInvoiceView from "pages/c-invoice/view";
import ExtDeliveryOrderView from "pages/ext-delivery-order/view";
import ExtInvoiceView from "pages/ext-invoice/view";
import ExtReturnOrderView from "pages/ext-return-order/view";
import MaterialMovementEdit from "pages/material-movement/edit";
import OrderView from "pages/order/view";
import PaymentView from "pages/payment/view";
import PemakaianBarangView from "pages/pemakaian-barang/view";
import PenerimaanBarangView from "pages/penerimaan-barang/view";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import ReceiptView from "pages/receipt/view";
import TransferBankCabangView from "pages/transfer-bank-cabang/view";
import TransferBankView from "pages/transfer-bank/view";
import TransferStockView from "pages/transfer-stock/view";
import {
    TYPE_JURNAL_BP_SALES_NO,
    TYPE_JURNAL_C_DO,
    TYPE_JURNAL_C_INV,
    TYPE_JURNAL_EXT_DO,
    TYPE_JURNAL_EXT_INV,
    TYPE_JURNAL_EXT_RO,
    TYPE_JURNAL_PAYMENT_OTHER,
    TYPE_JURNAL_PEMAKAIAN_BARANG,
    TYPE_JURNAL_PEMBELIAN_NO,
    TYPE_JURNAL_PENERIMAAN_BARANG,
    TYPE_JURNAL_PENYESUAIAN_NO,
    TYPE_JURNAL_RECEIPT_OTHER,
    TYPE_JURNAL_STO_PO_INVENTORY,
    TYPE_JURNAL_TRANSFER_STOCK,
    TYPE_JURNAL_UMUM_BANK_TRANSFER,
    TYPE_JURNAL_UMUM_BANK_TRANSFER_CABANG,
    TYPE_PAYMENT_VOUCHER,
    TYPE_RECEIPT_VOUCHER,
    TYPE_SALES_AUTOLOKA,
    TYPE_SALES_OFFLINE,
    TYPE_SALES_ONLINE,
} from "utils/constant";

export type Props = ModalProps & {
    id?: any;
    type?: any;
    type_?: any;
};

const ModalRefVoucher = ({ id, type, type_, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1300} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    {type === TYPE_PAYMENT_VOUCHER || type === TYPE_JURNAL_PAYMENT_OTHER ? <PaymentView paramId={id} asPreview /> : null}
                    {type === TYPE_RECEIPT_VOUCHER || type === TYPE_JURNAL_RECEIPT_OTHER ? <ReceiptView paramId={id} asPreview /> : null}
                    {type === TYPE_SALES_OFFLINE || type === TYPE_SALES_ONLINE || type === TYPE_SALES_AUTOLOKA ? (
                        <OrderView paramId={id} asPreview asAccounting />
                    ) : null}
                    {type === TYPE_JURNAL_PEMBELIAN_NO ? <PurchasingInventoryView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_BP_SALES_NO ? <BPInvoiceView paramId={id} /> : null}
                    {type === TYPE_JURNAL_UMUM_BANK_TRANSFER ? <TransferBankView paramId={id} /> : null}
                    {type === TYPE_JURNAL_PENERIMAAN_BARANG ? <PenerimaanBarangView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_PEMAKAIAN_BARANG ? <PemakaianBarangView paramId={id} /> : null}
                    {type === TYPE_JURNAL_STO_PO_INVENTORY ? <MaterialMovementEdit paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_C_DO ? <CDeliveryOrderView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_C_INV ? <CInvoiceView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_EXT_INV ? <ExtInvoiceView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_EXT_DO ? <ExtDeliveryOrderView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_PENYESUAIAN_NO ? (
                        <>{type_ === TYPE_JURNAL_EXT_RO ? <ExtReturnOrderView paramId={id} asPreview /> : <OrderView asPreview paramId={id} />}</>
                    ) : null}
                    {type === TYPE_JURNAL_UMUM_BANK_TRANSFER_CABANG ? <TransferBankCabangView paramId={id} asPreview /> : null}
                    {type === TYPE_JURNAL_TRANSFER_STOCK ? <TransferStockView paramId={id} asPreview /> : null}
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalRefVoucher;

import { Button, Form, Input, InputNumber, Space, Switch } from "antd";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import paymentService from "services/api-endpoints/dashboard/payment";
import { SelectOption } from "components/form/inputs/input-select";
import { useQuery } from "react-query";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import { PAYMENT_LOAN_INTERNAL, PAYMENT_OTHER } from "utils/constant";
import ControlledInputNumberDecimal from "components/form/controlled-inputs/controlled-input-number.decimal";

type Props = {
    accountID?: number;
    children: (data: HandlerProps) => void;
    onCreate: (data: ExpansesData) => void;
    paymentType: number;
};

const schema: yup.SchemaOf<ExpansesData> = yup.object().shape({
    charge_id: yup.number().required("Charge Required"),
    amount: yup.number().required("Price Required"),
    note: yup.string().notRequired(),
    charge_name: yup.string().notRequired(),
    bp_id: yup.number().notRequired(),
    bp_name: yup.string().notRequired(),
});

const ModalAddExpenses = ({ children, accountID, onCreate, paymentType }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue, watch, reset } = useForm<ExpansesData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchChargeId = watch("charge_id");
    const watchBpId = watch("bp_id");

    const typeCharge = () => {
        if (paymentType === PAYMENT_LOAN_INTERNAL) {
            return 2;
        }
        if (paymentType === PAYMENT_OTHER) {
            return 3;
        }
        return 1;
    };

    const chargeQuery = useQuery([paymentService.getCharge, paymentType], async () => {
        return (await paymentService.GetCharge({ type: typeCharge() })).data.data?.map(
            (el) => ({ label: el.charge_name, value: el.charge_id } as SelectOption)
        );
    });

    const employeeQuery = useQuery([paymentService.getEmployee, accountID], async () => {
        return (await paymentService.GetEmployee({ account_id: accountID })).data.data?.map(
            (el) => ({ label: el.name, value: el.id } as SelectOption)
        );
    });

    const resetForm = () => {
        reset();
        form.setFieldsValue({
            charge_id: undefined,
            charge_name: undefined,
            amount: undefined,
        });
        setValue("charge_id", undefined);
        setValue("charge_name", undefined);
        setValue("amount", undefined);
    };

    const onSubmitHandler = handleSubmit(async (data) => {
        const parseData = {
            ...data,
            // amount: Number(data?.amount?.replaceAll(".", "")),
        };
        onCreate(parseData);
        closeRef.current?.click();
        resetForm();
    });

    const onChangeCoa = (data: any) => {
        setValue("charge_id", chargeQuery.data?.find((item) => item.value === data)?.value);
        setValue("charge_name", chargeQuery.data?.find((item) => item.value === data)?.label);
    };

    const onChangeBp = (data: any) => {
        setValue("bp_id", employeeQuery.data?.find((item) => item.value === data)?.value);
        setValue("bp_name", employeeQuery.data?.find((item) => item.value === data)?.label);
    };

    const isAdd = () => {
        if (paymentType === PAYMENT_LOAN_INTERNAL) {
            if (watchBpId) {
                return false;
            }
            return true;
        }
        return false;
    };

    return (
        <ModalTemplate title={<div className="flex items-center gap-2">Add Expenses</div>} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button onClick={dt.closeModal} type="button" ref={closeRef} className="hidden">
                        close
                    </button>
                    <Form form={form} onFinish={onSubmitHandler} layout="vertical">
                        <Space direction="vertical" className="w-full">
                            <ControlledSelectInput
                                showSearch
                                control={control}
                                name="charge_id"
                                placeholder="Charge"
                                label="Charge"
                                onChange={onChangeCoa}
                                options={chargeQuery.data}
                                loading={chargeQuery.isLoading}
                            />
                            <>
                                {paymentType === PAYMENT_LOAN_INTERNAL && watchChargeId ? (
                                    <ControlledSelectInput
                                        control={control}
                                        name="bp_id"
                                        placeholder="Employee Name"
                                        label="Employee Name"
                                        options={employeeQuery.data}
                                        loading={employeeQuery.isLoading}
                                        onChange={onChangeBp}
                                    />
                                ) : null}
                            </>
                            <div className="w-full grid grid-cols-2 gap-4">
                                <ControlledInputNumberDecimal control={control} name="amount" placeholder="Amount (Rp)" label="Amount (Rp)" />
                                <ControlledInputText control={control} name="note" placeholder="Note" label="Note (Optional)" />
                            </div>

                            <Button type="primary" htmlType="submit" disabled={isAdd()}>
                                Add
                            </Button>
                        </Space>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddExpenses;

import { Button, Card, Form, message } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { AUTOGENERATED } from "utils/constant";
import { useMutation, useQuery } from "react-query";
import inventoryOutService from "services/api-endpoints/dashboard-jsl/inventory-out";
import { Product, SelectOption } from "models";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FDataInventoryOut } from "modules/dashboard-jsl/inventory-out/models";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { MdAdd } from "react-icons/md";
import AdvanceSearch from "modules/advance-search";
import { ProductSub } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { TDataProductSub } from "modules/dashboard-jsl/purchase-order/models";
import ProductTable from "modules/dashboard-jsl/inventory-out/add/product-table";
import { useNavigate } from "react-router-dom";
import { ProductWithQty } from "modules/advance-search/models";

const schema: yup.SchemaOf<Partial<FDataInventoryOut>> = yup.object().shape({
    requester_id: yup.string().required("Requester required"),
    note: yup.string(),
    detail_product: yup.array(),
});

const InventoryOutAdd = () => {
    const [products, setProducts] = useState<ProductWithQty[]>([]);
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<FDataInventoryOut>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createInventoryMutation = useMutation(
        [inventoryOutService.create],
        async (data: FDataInventoryOut) => {
            return (await inventoryOutService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Inventory Out created!");
                navigate(-1);
            },
        }
    );

    const requesterQuery = useQuery([inventoryOutService.requester], async () => {
        return (await inventoryOutService.Requester()).data.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption));
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: FDataInventoryOut = {
            ...data,
            detail_product: products.map(
                (el) => ({ price: el.product_price, product_id: el.product_id, qty: el.qty } as FDataInventoryOut["detail_product"][0])
            ),
        };
        createInventoryMutation.mutate(parseData);
    });

    const onSaveHandler = () => form.submit();

    const onSearchSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            if (products.find((p) => p.product_id === data.product_id)) {
                callback("Product already exist!");
                return;
            }
            setProducts((prev) => [...prev, { ...data, qty: 1, product_code: data.product_code1 }]);
            callback(null);
        },
        [products]
    );

    const onSearchDeSelectProduct = React.useCallback(
        (data: Product, callback: (str: string | null) => void) => {
            setProducts((prev) => [...prev].filter((p) => p.product_id !== data.product_id));
            callback(null);
        },
        [products]
    );

    const onAddProduct = (data: ProductSub, callback: (str: string | null) => void) => {
        if (products.find((p) => p.product_id === data.product_id)) {
            callback("Product already exist!");
            return;
        }
        setProducts((prev) => [...prev, { ...data, qty: 1 }]);
        callback(null);
    };

    const onRemoveProduct = (product: ProductSub | TDataProductSub) => {
        setProducts((prev) => {
            return prev.filter((p) => p.product_id !== product.product_id);
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new inventory out"
                rightAddition={() => (
                    <Button loading={createInventoryMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-3 gap-6">
                        <p className="capitalize m-0">
                            document no <br /> <span className="underline italic m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            showSearch
                            name="requester_id"
                            label="Requester"
                            placeholder="Requester"
                            optionFilterProp="children"
                            control={control}
                            loading={requesterQuery.isLoading}
                            options={requesterQuery.data || []}
                        />
                        <ControlledInputText control={control} labelCol={{ xs: 12 }} name="note" label="Note" placeholder="Note" />
                    </div>
                </Form>
            </Card>
            <AdvanceSearch selected={products} setSelected={setProducts}>
                {(dt) => (
                    <Button className="!flex !items-center w-fit" type="primary" onClick={dt.openModal}>
                        <MdAdd className="mr-2" />
                        Add Product
                    </Button>
                )}
            </AdvanceSearch>
            <ProductTable list={products} setList={setProducts} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default InventoryOutAdd;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ListDeliveryOrder {
    id: number;
    doc_no: string;
    partner_name: string;
    bp_name: string;
    order_status: number;
    created_at: string;
}

export interface CreateProduct {
    product_id: number;
    qty: number;
    pl_id: number;
    is_free: number;
    price: number;
    discount_price: number;
    voucher_id: number;
    note: string;
    no_koli: string;
    is_last_stock: number;
}

export interface CreateDeliveryOrder {
    account_id_to: number;
    bp_id?: number;
    child_document: number[];
    driver_name: string;
    plate_number: string;
    note: string;
    so_child: number[];
    detail_product: CreateProduct[];
}

export interface ProductDeliveryOrder {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    product_code: string;
    product_price: number;
    qty: number;
    product_owner: string;
    pl_id: number;
    is_free: number;
    note: string;
    voucher_id: number;
    discount_price: number;
    no_koli: string;
    code2: string;
}

export interface ReferencesData {
    ref_no: string;
    ref_id: number;
}

export interface DetailDeliveryOrder {
    id: number;
    doc_no: string;
    references: ReferencesData[];
    ref_type: number;
    name: string;
    address?: string;
    partner_name: string;
    partner_address: string;
    bp_name: string;
    bp_address: string;
    order_status: number;
    created_at: string;
    driver_name: string;
    plate_number: string;
    note: string;
    list_detail: ProductDeliveryOrder[];
}

export interface ListPickingList {
    id: number;
    doc_no: string;
    total_sku: number;
    total_item: number;
    created_at: string;
    bp_id: number;
    bp_name: string;
    so_child: number[];
}

export interface ProductPL {
    id: number;
    product_id: number;
    product_code1: string;
    product_name: string;
    product_price: number;
    qty: number;
    product_owner: string;
    is_free: number;
    discount_price: number;
    note: string;
    voucher_id: number;
    no_koli: string;
    is_last_stock: number;
}

class ExtDeveliryOrderService extends BaseService {
    listAll = "/ext-order/list-do";

    detail = "/ext-order/detail-do";

    pickingList = "/ext-order/get-pl";

    getProductPL = "/ext-order/get-product-pl";

    create = "/ext-order/create-do";

    constructor() {
        super();
    }

    Create<T extends any>(data: CreateDeliveryOrder, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetProductPL<T extends ProductPL[]>(param: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductPL,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    PickingList<T extends ListPickingList[]>(param: { partner_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.pickingList,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Detail<T extends DetailDeliveryOrder>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    ListAll<T extends ListDeliveryOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const extDeliveryOrderService = new ExtDeveliryOrderService();
export default extDeliveryOrderService;

/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductTable from "modules/dashboard-jsl/sales-order/view/product-table";
import HistoryTable from "modules/dashboard-jsl/sales-order/view/history-table";
import salesOrderService, { DeleteData, HistorySO, ProductSO } from "services/api-endpoints/dashboard-jsl/sales-order";
import ModalHistorySO from "modules/dashboard-jsl/sales-order/view/modal-history";
import { STATUS_PURCHASE_ORDER } from "utils/constant";
import ModalDelete from "components/modal/delete_modal";
import { BiTrash } from "react-icons/bi";
import { getDocumentRoute } from "utils/routes";

const SalesOrderView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;
    const [products, setProducts] = useState<ProductSO[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [salesOrderService.detail, id],
        async () => {
            return (await salesOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [salesOrderService.historySO, page],
        async () => {
            return (await salesOrderService.HistorySO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const deleteSOMutation = useMutation(
        [salesOrderService.deleteSO],
        async (data: DeleteData) => {
            return (await salesOrderService.DeleteSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Sales order deleted");
                navigate(getDocumentRoute({ menuName: "sales-order", page: "index" }), { replace: true });
            },
        }
    );

    const onDeleteSOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deleteSOMutation.mutate(dataDelete);
    };

    const onClickLinkHandler = (id: number) => {
        setDetailHistoryID(id);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Sales order detail"
                rightAddition={() => (
                    <ModalDelete title="Delete SO" onSubmit={onDeleteSOHandler}>
                        {(dt) => (
                            <Button
                                loading={deleteSOMutation.isLoading}
                                disabled={detailQuery.data?.order_status === 2 || detailQuery.data?.order_status === -1}
                                onClick={dt.openModal}
                                type="default"
                                className="!flex !items-center text-red-600"
                            >
                                <BiTrash className="m-0 mr-2 text-red-600" />
                                Delete
                            </Button>
                        )}
                    </ModalDelete>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        sales order no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.so_number}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-600" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <ModalHistorySO idHistory={detailHistoryID}>
                                    {(dt) => (
                                        <HistoryTable
                                            fetcher={history}
                                            onClickLink={(id) => {
                                                onClickLinkHandler(id);
                                                dt.openModal();
                                            }}
                                        />
                                    )}
                                </ModalHistorySO>
                            ),
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default SalesOrderView;

import { Image } from "antd";
import { useState } from "react";
import { IMAGE_FALLBACK } from "utils/constant";

export interface Props {
    after: string[];
    before: string[];
}

const ImagesPreview = ({ images }: { images: string[] }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Image preview={{ visible: false }} width={150} src={images[0]} onClick={() => setVisible(true)} fallback={IMAGE_FALLBACK} />
            <div style={{ display: "none" }}>
                <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                    {images?.map((src: string) => (
                        <Image src={src} key={src} fallback={IMAGE_FALLBACK} />
                    ))}
                </Image.PreviewGroup>
            </div>
        </>
    );
};

const PhotoView = ({ after, before }: Props) => {
    return (
        <div>
            <p className="font-bold">Photo Before</p>
            <div className="grid grid-cols-8 gap-4">
                {before?.map((url) => (
                    <ImagesPreview images={before} />
                ))}
            </div>
            {after.length !== 0 && (
                <>
                    <p className="font-bold mt-8">Photo After</p>
                    <div className="grid grid-cols-8 gap-4">
                        {after?.map((url) => (
                            <ImagesPreview images={after} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default PhotoView;

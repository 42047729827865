import { Button, Card, message, Select, Skeleton } from "antd";
import State from "components/common/state";
import ModalNewPo from "components/modal/new_po_modal";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ModalPOTable from "modules/dashboard-jsl/picking-list/add/modal-po-table";
import ModalSOTable from "modules/dashboard-jsl/picking-list/add/modal-so-table";
import ProductTableEdit from "modules/dashboard-jsl/picking-list/add/product-table-edit";
import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import pickingListService, { CreatePLData, DetailProduct, LocatorData, ProductPL } from "services/api-endpoints/dashboard-jsl/picking-list";
import salesOrderService, { CreateSOData } from "services/api-endpoints/dashboard-jsl/sales-order";
import { ORDER_TYPE, PICKING_LIST_STATUS } from "utils/constant";
import { getDocumentRoute } from "utils/routes";

const PickingListAdd = () => {
    const navigate = useNavigate();
    const [productsPO, setProductsPO] = useState<DetailProduct[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);
    const [warehouse, setWarehouse] = useState<number | null>(null);
    const [status, setStatus] = useState<number | null>(6);
    const [type, setType] = useState<number | null>(2);
    const [topId, setTopId] = useState<number | null>(null);

    const getHeaderPOQuery = useQuery([salesOrderService.getHeaderSO], async () => {
        return (await salesOrderService.GetHeaderSO()).data.data;
    });

    const listAccount = useQuery([pickingListService.listAccount, type], async () => {
        return (await pickingListService.ListAccount({ type })).data.data?.map((el) => ({ label: el.name, value: el.account_id } as SelectOption));
    });

    const draftPLMutation = useMutation(
        [pickingListService.draftPL],
        async (data: CreatePLData) => {
            return (await pickingListService.DraftPL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Picking list drafted");
                navigate(getDocumentRoute({ menuName: "picking-list", page: "index" }), { replace: true });
            },
        }
    );

    const createPLMutation = useMutation(
        [pickingListService.createPL],
        async (data: CreatePLData) => {
            return (await pickingListService.CreatePL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Picking List Created");
                navigate(getDocumentRoute({ menuName: "picking-list", page: "index" }), { replace: true });
            },
        }
    );

    const onFilterApprove: DetailProduct[] = productsPO.filter((el) => el.qty_approve !== undefined);
    const onFilterLocator: DetailProduct[] = productsPO.filter((el) => el.area_id !== undefined);
    const onFilter = () => {
        if (productsPO.length === 0) {
            return true;
        }
        return !(onFilterApprove.length === productsPO.length && onFilterLocator.length === productsPO.length);
    };

    const prepareData = () => {
        productsPO?.forEach((pPO) => {
            if (!pPO.area_id) throw new Error(`Please select locator on ${pPO.product_name}`);
            if (Number(pPO.qty_approve || 0) > Number(pPO.qty_req || 0) || Number(pPO.qty_approve || 0) > Number(pPO.qty_locator || 0))
                throw new Error(`Quantity approve can't bigger than request or locator on ${pPO.product_name}`);
        });

        const createPLData: CreatePLData = {
            account_id: warehouse!,
            doc_type: status!,
            payment_term_id: topId,
            child_document: [...new Set(productsPO?.map((p) => p.doc_id!) || [])],
            detail_product: [
                ...(productsPO?.map(
                    (p) =>
                        ({
                            doc_id: p.doc_id,
                            product_id: p.product_id,
                            qty: p.qty_approve || 0,
                            price: p.product_price,
                            area_id: p?.area_id,
                        } as ProductPL)
                ) || []),
            ],
        };
        return createPLData;
    };

    const onSaveHandler = () => {
        try {
            const createPLData = prepareData();
            createPLMutation.mutate(createPLData);
            console.log(createPLData);
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onDraftHandler = () => {
        try {
            const createPLData = prepareData();
            draftPLMutation.mutate(createPLData);
            console.log(createPLData);
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onRemoveProduct = (product: DetailProduct) => {
        setProductsPO((prev) => prev?.filter((pPrev) => pPrev.doc_id !== product?.doc_id));
    };

    const onChangeWarehouse = (value: number) => {
        setSelectedPO([]);
        setProductsPO([]);
        setWarehouse(value);
    };

    const onChangeStatus = (value: number) => {
        setSelectedPO([]);
        setProductsPO([]);
        setStatus(value);
        setWarehouse(null);
        if (value === 5) setType(1);
        else setType(2);
    };

    const onProductPO = (data: DetailProduct[]) => {
        setProductsPO([]);
        setProductsPO((prev) => {
            const newData = data.map((el) => ({ ...el, qty_request: el.qty_req! }));
            if (prev.length === 0) return newData;
            const setNewData = newData.filter(
                (product) => !prev.find((prevProduct) => prevProduct.product_id === product.product_id && prevProduct.doc_id === product.doc_id)
            );
            return [...prev, ...setNewData];
        });
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        setProductsPO((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id !== locator.product_id || prd.doc_id !== docId) return prd;
                return {
                    ...prd,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: Number(locator.qty_locator),
                };
            })
        );
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new picking list"
                rightAddition={() => (
                    <>
                        <Button
                            loading={draftPLMutation.isLoading}
                            disabled={onFilter()}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <ModalNewPo title="Konfirmasi" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button onClick={onSaveHandler} type="primary" className="!flex !items-center w-fit" disabled={onFilter()}>
                                    <BiSave className="mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNewPo>
                    </>
                )}
            />
            <Card className="">
                <State data={getHeaderPOQuery.data} isLoading={getHeaderPOQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Sales order no <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.so_number}</span>
                                    </p>
                                    {/* <div className="capitalize m-0">
                                        Data From <br />
                                        <Select
                                            value={status}
                                            className="!w-[300px]"
                                            options={PICKING_LIST_STATUS}
                                            onChange={onChangeStatus}
                                            showSearch
                                            placeholder="Data From"
                                        />
                                    </div> */}
                                    <div className="">
                                        <div className="capitalize m-0">
                                            request from <br />
                                            <Select
                                                value={warehouse}
                                                className="!w-[300px]"
                                                loading={listAccount.isLoading}
                                                options={listAccount.data || []}
                                                onChange={onChangeWarehouse}
                                                showSearch
                                                placeholder="Request From"
                                                disabled={status === null}
                                            />
                                        </div>
                                    </div>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{getHeaderPOQuery.data?.request_to}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <div className="flex items-start">
                <ModalSOTable
                    onProductPO={onProductPO}
                    warehouse={warehouse}
                    selectedPO={selectedPO}
                    setSelectedPO={setSelectedPO}
                    option={type!}
                    setTopId={setTopId}
                >
                    {(dt) => (
                        <Button
                            className="!flex !items-center w-fit mr-3"
                            type="primary"
                            onClick={dt.openModal}
                            disabled={!(status === 6 && warehouse !== null)}
                        >
                            <MdAdd className="mr-2" />
                            Data SO External
                        </Button>
                    )}
                </ModalSOTable>
                {/* <ModalPOTable onProductPO={onProductPO} warehouse={warehouse} selectedPO={selectedPO} setSelectedPO={setSelectedPO} option={type!}>
                    {(dt) => (
                        <Button
                            className="!flex !items-center w-fit"
                            type="primary"
                            onClick={dt.openModal}
                            disabled={!(status === 5 && warehouse !== null)}
                        >
                            <MdAdd className="mr-2" />
                            Data PO Internal
                        </Button>
                    )}
                </ModalPOTable> */}
            </div>
            <ProductTableEdit list={productsPO} setList={setProductsPO} onSubmitLocator={onSubmitLocator} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default PickingListAdd;

/* eslint-disable no-shadow */
import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import TableIndex from "modules/master-data/raw-material/index/table-index";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import manufactureRawMaterialService, { ListRawMaterial } from "services/api-endpoints/dashboard/manufacture/raw-material";

const filter = ["mp_id", "order_status", "start_date", "end_date"];

const RawMaterial = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([manufactureRawMaterialService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await manufactureRawMaterialService.GetAll({ page, param_search: searchValue.query || "" })).data.data;
    });

    const deleteMutation = useMutation(async (data: ListRawMaterial) => (await manufactureRawMaterialService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Variable Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<ListRawMaterial>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Search Raw Material Manufacture" filter={false} />
            <TableIndex fetcher={getList} onDelete={showConfirm} />
        </div>
    );
};

export default RawMaterial;

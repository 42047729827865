import { DatePicker, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import { useQuery } from "react-query";
import bpSalesService from "services/api-endpoints/dashboard-all/bp-sales";
import { FORMAT_DATE_1, STATUS_BP_ORDER } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterBpSales = ({ filter = [], ...props }: Props) => {
    const getBpQuery = useQuery([bpSalesService.getBp], async () => {
        return (await bpSalesService.GetBp()).data.data?.map((el) => ({ label: el.bp_name, value: el.bp_id } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Bp Sales"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.bp_id ? Number(dt.values.bp_id) : null}
                            placeholder="Business Partner"
                            options={getBpQuery.data}
                            loading={getBpQuery.isLoading}
                            onChange={(value) => dt.setOneValue("bp_id", value)}
                        />
                        <DatePicker.RangePicker
                            value={[
                                dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                            ]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                            }}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.order_status ? Number(dt.values.order_status) : null}
                            placeholder="Status Order"
                            options={STATUS_BP_ORDER}
                            onChange={(value) => dt.setOneValue("order_status", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterBpSales;

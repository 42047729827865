/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { FilterSales } from "modules/dashboard-all/sales/models";

export interface Sales {
    id?: number;
    so_no?: string;
    inv_no?: string;
    info?: string;
    marketplace_name?: number;
    customer_name?: string;
    grand_total?: number;
    status?: number;
    date?: string;
}

class SalesService extends BaseService {
    getAll = "/pos/get-all-sales";

    search = "/pos/search-sales";

    filter = "/pos/filter-sales";

    deleteDraft = "/pos/delete-draft-sales";

    constructor() {
        super();
    }

    DeleteDraft<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.deleteDraft}/${id}`,
            });
            return req;
        });
    }

    GetAll<T extends Sales>(param: { page: any; size: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Sales>(param: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Sales>(param: FilterSales) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const salesService = new SalesService();
export default salesService;

import { Input, Form, Button } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";

type Props = ModalProps & {
    onSubmit: (type: number) => void;
    isSameProduct: boolean;
};

const ModalEditSO = ({ onSubmit, isSameProduct, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [form] = Form.useForm();

    const onWithNewPoHandler = () => {
        onSubmit(1);
        closeRef.current?.click();
    };
    const onWithOutPoHandler = () => {
        onSubmit(2);
        closeRef.current?.click();
    };
    return (
        <ModalTemplate {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form form={form} layout="horizontal">
                        <div className="w-full relative flex flex-col items-end">
                            {isSameProduct ? (
                                <>
                                    <h4 className="m-0 !w-full font-semibold">Anda Yakin Simpan Data Sales Order.?</h4>
                                    <Button type="primary" className="items-center mt-4" onClick={onWithOutPoHandler}>
                                        Save
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h4 className="m-0 !w-full font-semibold">
                                        Dikarenakan ada selisih jumlah item di SO, Apakah anda ingin buat SO baru.?
                                    </h4>
                                    <div className="grid grid-cols-2 gap-4 mt-8">
                                        <Button type="default" className="items-center" onClick={onWithOutPoHandler}>
                                            Tanpa SO Baru
                                        </Button>
                                        <Button type="primary" className="items-center" onClick={onWithNewPoHandler}>
                                            Dengan SO Baru
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalEditSO;

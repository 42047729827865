import { Result } from "antd";
import React from "react";

type Props = {
    children: React.ReactNode;
};

const DevelopmentBlocker = ({ children }: Props) => {
    if (process.env.NODE_ENV === "development") return <>{children}</>;
    return (
        <div className="relative">
            <div className="w-full h-full absolute top-0 left-0 z-40 flex items-center justify-center">
                <Result status="warning" title="Feature in development" />
            </div>
            <div className="w-full h-full blur-sm">{children}</div>
        </div>
    );
};

export default DevelopmentBlocker;

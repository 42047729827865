/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ProductWithStock } from "pages/ext-return-order/add";
import React, { useState } from "react";
import { EXT_RETURN_PENGEMBALIAN_UANG, EXT_RETURN_POTONG_HUTANG, EXT_RETURN_TUKAR_BARANG, STOCK_ACTION, STOCK_ACTION2 } from "utils/constant";
import { Button, Select } from "antd";
import ModalLocator from "./modal-locator";
import { UseMutationResult, UseQueryResult } from "react-query";
import { SelectOption } from "models";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/ext-return-order";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    type: number | null;
    locatorIN: UseMutationResult<(SelectOption & LocatorData)[], unknown, number, unknown>;
    warehouseId: number | null;
    onSubmitLocator: (locator: LocatorData) => void;
};

export function addProductTable<T extends ProductWithStock>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ type, locatorIN, warehouseId, onSubmitLocator, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const onChangeLocator = (record: T) => {
            return (val: any) => {
                const locator = locatorIN.data?.find((loc) => loc.area_id === val);
                const item = {
                    ...record,
                    ...locator,
                };
                onSubmitLocator(item);
            };
        };

        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
            },
            {
                title: "Code",
                dataIndex: "product_code",
            },
            {
                title: "Molding Code",
                dataIndex: "molding_code",
            },
            {
                title: "Qty Return",
                width: "100px",
                dataIndex: "qty_return",
                ...{ editable: true },
                render: (text) => text || "",
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => (
                    <>
                        {type === EXT_RETURN_TUKAR_BARANG && (
                            <ModalLocator
                                title="Locator"
                                productId={record.product_id}
                                qty={record.qty}
                                onSubmit={onSubmitLocator}
                                warehouseId={warehouseId}
                            >
                                {(dt) => (
                                    <Button
                                        size="small"
                                        onClick={() => dt.openModalWithData(record)}
                                        className="!flex !items-center"
                                        type="default"
                                        {...(!text ? { danger: true } : {})}
                                    >
                                        {text || "Choose Locator"}
                                    </Button>
                                )}
                            </ModalLocator>
                        )}
                        {(type === EXT_RETURN_PENGEMBALIAN_UANG || type === EXT_RETURN_POTONG_HUTANG) && (
                            <Select
                                value={record?.area_id}
                                showSearch
                                placeholder="Locator"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())}
                                className="!w-[150px]"
                                loading={locatorIN.isLoading}
                                options={locatorIN.data || []}
                                onChange={onChangeLocator(record)}
                            />
                        )}
                    </>
                ),
            },
            {
                title: "Stock Action",
                width: "100px",
                dataIndex: "stock_action",
                ...{ editable: true },
                render: (text) => text || "",
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id! as any;
        const editInputType: EditTableProps<T>["editInputType"] = { stock_action: "select", qty_return: "number" };

        return (
            <Component
                {...props}
                cellProps={{
                    selectProps: {
                        options: type === EXT_RETURN_TUKAR_BARANG ? STOCK_ACTION2 : STOCK_ACTION,
                        className: "!w-[150px]",
                        placeholder: "Action",
                    },
                    ...props?.cellProps,
                }}
                list={
                    props.list?.map((product: ProductWithStock) => ({
                        ...product,
                        stock_action: STOCK_ACTION.find((act) => act.value === product.stock_action || act.label === product.stock_action)?.label,
                    })) as any
                }
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const TableProductExtRO = addProductTable(EditTable);
export default TableProductExtRO;

import { Button, Card, message, Select, Skeleton } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiSave, BiMessageAdd, BiTrash } from "react-icons/bi";
import React, { useState } from "react";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import State from "components/common/state";
import { MdAdd } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDocumentRoute } from "utils/routes";
import ModalNewPo from "components/modal/new_po_modal";
import { BP_ORDER_DRAFT, PICKING_LIST_STATUS, STATUS_PURCHASE_ORDER } from "utils/constant";
import ProductTableEdit from "modules/dashboard-jsl/picking-list/edit/product-table-edit";
import ModalDelete from "components/modal/delete_modal";
import ModalBPSOTable from "modules/dashboard-jsl/bp-picking-list/modal-bp-so-table";
import bpPickingListService, {
    DeleteData,
    DetailProduct,
    EditPLData,
    LocatorData,
    ProductPL,
} from "services/api-endpoints/dashboard/master-data/bp-picking-list";
import ProductTableBPEdit from "modules/dashboard-jsl/bp-picking-list/product-table-bp-edit";

const BPPickingListEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [productsPO, setProductsPO] = useState<DetailProduct[]>([]);
    const [selectedPO, setSelectedPO] = useState<React.Key[]>([]);
    const [warehouse, setWarehouse] = useState<number | null>(null);
    const [status, setStatus] = useState<number | null>(null);
    const [topId, setTopId] = useState<number | null>(null);
    const id = searchParams.get("id");

    const detailQuery = useQuery(
        [bpPickingListService.detailPL, id],
        async () => {
            return (await bpPickingListService.DetailPL({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setTopId(data.payment_term_id);
                setProductsPO(data.list_detail || []);
                setWarehouse(data.request_from_id || 0);
                setStatus(data.order_status || 0);
                // setTopId(data.request_from_id || 0);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );

    const editDraftMutation = useMutation(
        [bpPickingListService.draftPL],
        async (data: EditPLData) => {
            return (await bpPickingListService.DraftEditPL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Picking list draft edited");
                navigate(-1);
            },
        }
    );

    const editPLMutation = useMutation(
        [bpPickingListService.createPL],
        async (data: EditPLData) => {
            return (await bpPickingListService.CreateEditPL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Picking List Edited");
                navigate(-1);
            },
        }
    );

    const onFilterApprove: DetailProduct[] = productsPO.filter((el) => el.qty !== undefined);
    const onFilterLocator: DetailProduct[] = productsPO.filter((el) => el.area_id !== undefined);
    const onFilter = () => {
        if (productsPO.length === 0) {
            return true;
        }
        return !(onFilterApprove.length === productsPO.length && onFilterLocator.length === productsPO.length);
    };

    const prepareData = () => {
        productsPO?.forEach((pPO) => {
            if (!pPO.area_id) throw new Error(`Please select locator on ${pPO.product_name}`);
            if (Number(pPO.qty || 0) > Number(pPO.qty_req || 0) || Number(pPO.qty || 0) > Number(pPO.qty_locator || 0))
                throw new Error(`Quantity approve can't bigger than request or locator on ${pPO.product_name}`);
        });

        const editPLData: EditPLData = {
            id: id as any,
            bp_id: warehouse!,
            doc_type: status!,
            payment_term_id: topId,
            child_document: [...new Set(productsPO?.map((p) => p.doc_id!) || [])],
            detail_product: [
                ...(productsPO?.map(
                    (p) =>
                        ({
                            doc_id: p.doc_id,
                            product_id: p.product_id,
                            qty: p.qty || 0,
                            price: p.product_price,
                            area_id: p?.area_id,
                        } as ProductPL)
                ) || []),
            ],
        };
        return editPLData;
    };

    const onSaveHandler = () => {
        try {
            const editPLData = prepareData();
            editPLMutation.mutate(editPLData);
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onDraftHandler = () => {
        try {
            const editPLData = prepareData();
            editDraftMutation.mutate(editPLData);
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onRemoveProduct = (product: DetailProduct) => {
        setProductsPO((prev) => prev?.filter((pPrev) => pPrev.product_id !== product?.product_id || pPrev.doc_id !== product.doc_id));
    };

    const onChangeStatus = (value: number) => {
        setSelectedPO([]);
        setProductsPO([]);
        setStatus(value);
    };

    const onProductPO = (data: DetailProduct[]) => {
        setProductsPO([]);
        setProductsPO((prev) => {
            const newData = data.map((el) => ({ ...el, qty_request: el.qty_req! }));
            if (prev.length === 0) return newData;
            const setNewData = newData.filter(
                (product) => !prev.find((prevProduct) => prevProduct.product_id === product.product_id && prevProduct.doc_id === product.doc_id)
            );
            return [...prev, ...setNewData];
        });
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        setProductsPO((prev) =>
            [...prev].map((prd) => {
                if (prd.product_id !== locator.product_id || prd.doc_id !== docId) return prd;
                return {
                    ...prd,
                    area_id: locator.area_id,
                    area_name: locator.area_name,
                    qty_locator: Number(locator.qty_locator),
                };
            })
        );
    };

    const deleteMutation = useMutation(
        [bpPickingListService.deletePL],
        async (data: DeleteData) => {
            return (await bpPickingListService.DeletePL(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("BP Picking list deleted");
                navigate(-1);
            },
        }
    );

    const onDeleteHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deleteMutation.mutate(dataDelete);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit picking list"
                rightAddition={() => (
                    <>
                        <ModalDelete title="Delete PL" onSubmit={onDeleteHandler}>
                            {(dt) => (
                                <Button
                                    loading={deleteMutation.isLoading}
                                    disabled={detailQuery.data?.order_status === 2}
                                    onClick={dt.openModal}
                                    type="default"
                                    className="!flex !items-center text-red-600"
                                >
                                    <BiTrash className="m-0 mr-2 text-red-600" />
                                    Delete
                                </Button>
                            )}
                        </ModalDelete>
                        <Button
                            loading={editDraftMutation.isLoading}
                            disabled={onFilter()}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <ModalNewPo title="Konfirmasi" onSubmit={onSaveHandler}>
                            {(dt) => (
                                <Button
                                    loading={editPLMutation.isLoading}
                                    onClick={onSaveHandler}
                                    type="primary"
                                    className="!flex !items-center w-fit"
                                    disabled={onFilter()}
                                >
                                    <BiSave className="mr-2" />
                                    Save
                                </Button>
                            )}
                        </ModalNewPo>
                    </>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        picking list no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.pl_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request from <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        request to <br />
                                        <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        status <br />
                                        <span
                                            className={
                                                detailQuery.data?.order_status === -1 ? "underline italic m-0 text-red-600" : "underline italic m-0"
                                            }
                                        >
                                            {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <div className="flex items-start">
                <ModalBPSOTable
                    onProductPO={onProductPO}
                    warehouse={warehouse}
                    selectedPO={selectedPO}
                    setSelectedPO={setSelectedPO}
                    option={2}
                    setTopId={setTopId}
                >
                    {(dt) => (
                        <Button
                            className="!flex !items-center w-fit mr-3"
                            type="primary"
                            onClick={dt.openModal}
                            disabled={!(status === BP_ORDER_DRAFT && warehouse !== null)}
                        >
                            <MdAdd className="mr-2" />
                            Data BP Sales Order
                        </Button>
                    )}
                </ModalBPSOTable>
            </div>
            <ProductTableBPEdit list={productsPO} setList={setProductsPO} onSubmitLocator={onSubmitLocator} removeItemList={onRemoveProduct} />
        </div>
    );
};

export default BPPickingListEdit;

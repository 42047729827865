/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataModel } from "modules/master-data/model/models";
import { FilterDataSubCategory, CreateDataSubCategory, EditDataSubCategory, DetailDataSubCategory } from "modules/master-data/sub-category/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface AllSubCategory {
    id: number;
    category_id: number;
    category_name: string;
    subcategory_name: string;
    subcategory_code: string;
}

export interface SubCategory {
    subcategory_id: number;
    subcategory_name: string;
    subcategory_image: string;
}

export interface SubCategoryList {
    subcategory_list: SubCategory[];
}

class SubCategoryService extends BaseService {
    getAll = "/master-subcategory/all-subcategory";

    delete = "/master-subcategory/delete-subcategory";

    search = "/master-subcategory/search-subcategory";

    suggestSubCategoryName = "/master-subcategory/suggest-subcategory-name";

    suggestSubCategoryCode = "/master-subcategory/suggest-subcategory-code";

    suggestByCategoryName = "/master-subcategory/suggest-by-categoryname";

    filter = "/master-subcategory/filter-subcategory";

    create = "/master-subcategory/create-subcategory";

    edit = "/master-subcategory/edit-subcategory";

    getAllSubCategory = "/master-subcategory/get-all-subcategory";

    detail = "/master-subcategory/get-detail-subcategory";

    subCategory = "master-subcategory/get-all-subcategory-product";

    constructor() {
        super();
    }

    SubCategory<T extends SubCategoryList>(param: { model_id: any; is_all_year: any; type_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.subCategory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAllSubCategory<T extends AllSubCategory[]>(param: { category_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllSubCategory,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataSubCategory>(param: { page: any; category_id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataSubCategory>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestSubCategoryName<T extends string[]>(param: { subcategory_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestSubCategoryName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestSubCategoryCode<T extends string[]>(param: { subcategory_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestSubCategoryCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestByCategoryName<T extends Models.SubCategoryData[]>(param: { category_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestByCategoryName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataSubCategory>(param: FilterDataSubCategory) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataSubCategory, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataSubCategory, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataSubCategory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }
}

const subCategoryService = new SubCategoryService();
export default subCategoryService;

/* eslint-disable no-shadow */
import { Alert, Card, Select, Skeleton, Switch } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import HistoryTable from "modules/master-data/quantity/view/history-table";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";

const QuantityView = ({ defaultWHID, defaultProductID, defaultAreaID }: { defaultWHID?: any; defaultProductID?: any; defaultAreaID?: any }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const id = defaultProductID || searchParams.get("id");
    const warehouseId = defaultWHID || searchParams.get("warehouse_id");
    const [totalQty, setTotalQty] = useState<number>(0);
    const [locatorIsActive, setLocatorIsActive] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [areaId, setAreaId] = useState<any>();

    const detailQuery = useQuery(
        [quantityService.getProductInfo, id],
        async () => {
            return (await quantityService.GetProductInfo({ product_id: id, warehouse_id: warehouseId })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                if (!data.list_area || data.list_area.length === 0) return;
                setSearchParams(searchParams);
                if (defaultAreaID === undefined || defaultAreaID === null) {
                    setAreaId(data.list_area[0]?.area_id?.toString());
                    return;
                }
                setAreaId(defaultAreaID);
            },
        }
    );

    const historyQuery = useQuery(
        [quantityService.getProductHistory, id, page, areaId],
        async () => {
            const req = (await quantityService.GetProductHistory({ area_id: areaId, page, product_id: id, warehouse_id: warehouseId })).data.data;
            setTotalQty(req.total_qty);
            return req;
        },
        {
            enabled: !!id && !!areaId,
            onSuccess(data) {
                setLocatorIsActive(data?.is_active === 1);
            },
        }
    );

    const setActiveProductMutate = useMutation([quantityService.setActiveProduct, id, areaId, warehouseId], async (active: any) => {
        return (await quantityService.SetActiveProduct({ area_id: areaId, warehouse_id: warehouseId, is_active: active, product_id: id })).data.data;
    });

    const selectOptions = detailQuery.data?.list_area?.map((el) => ({ label: el.area_code, value: el.area_id } as SelectOption)) || [];

    const onChangeLocator = (id: string) => {
        setAreaId(id);
        setPage(1);
    };

    const onChangeLocatorActive = (active: boolean) => {
        setLocatorIsActive(active);
        setActiveProductMutate.mutateAsync(active ? 1 : -1).finally(historyQuery.refetch);
    };

    const handleTableChange = (page: any) => {
        setPage(page);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail quantity product" />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        product name <br />
                                        <span className="underline italic m-0">{detailQuery.data?.name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        product code <br />
                                        <span className="underline italic m-0">{detailQuery.data?.product_code}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        molding code <br />
                                        <span className="underline italic m-0">{detailQuery.data?.molding_code}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        UOM <br />
                                        <span className="underline italic m-0">{detailQuery.data?.uom_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        product description <br />
                                        <span className="underline italic m-0">{detailQuery.data?.product_desc}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        quantity <br />
                                        <span className="italic mr-1">
                                            Total : {detailQuery.data?.list_area?.reduce((total, obj) => total + obj.qty, 0)},
                                        </span>
                                        <span className="italic m-0">Current : {totalQty}</span>
                                    </p>
                                    <div className="capitalize m-0">
                                        <p className="m-0">locator</p>
                                        <div className="flex gap-3 items-center">
                                            <Select
                                                value={(Number(areaId) as any) || null}
                                                onChange={onChangeLocator}
                                                className="w-full"
                                                showSearch
                                                placeholder="Locator"
                                                options={selectOptions}
                                                disabled={defaultProductID || defaultProductID}
                                            />
                                            <Switch
                                                disabled={historyQuery.isLoading || defaultProductID || defaultProductID}
                                                onChange={onChangeLocatorActive}
                                                className="w-[100px]"
                                                checkedChildren="Active"
                                                unCheckedChildren="Inactive"
                                                checked={locatorIsActive}
                                                loading={setActiveProductMutate.isLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(detailQuery.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <HistoryTable handleTableChangeOverride={handleTableChange} fetcher={historyQuery} loading={detailQuery.isLoading} />
        </div>
    );
};

export default QuantityView;

import { clsx } from "clsx";
import React from "react";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import { ManufactureContext } from "../context/context";

export type HeaderEntityProps = {
    children?: any;
    idEntity?: string;
    canExpand?: boolean;
};

const HeaderEntity = ({ children, idEntity, canExpand }: HeaderEntityProps) => {
    const { setExpandEntities, expandEntities } = React.useContext(ManufactureContext) as any;

    const isExpand = expandEntities.find((id: string) => id === idEntity);

    const onClickExpand = (e: any) => {
        e.stopPropagation();
        setExpandEntities((prev: string[]) => {
            if (prev.includes(idEntity!)) return prev.filter((id) => id !== idEntity);
            return [...prev, idEntity];
        });
    };

    return (
        <div className="w-full flex">
            {canExpand && (
                <button
                    title={!isExpand ? "Minimize card" : "Maximize card"}
                    onClick={onClickExpand}
                    className={clsx("bg-transparent border-none mr-2 cursor-pointer hover:text-gray-600 focus:text-gray-600")}
                    type="button"
                >
                    {!isExpand ? <FiMinimize2 /> : <FiMaximize2 />}
                </button>
            )}
            {children}
        </div>
    );
};

export default HeaderEntity;

import { Button, Skeleton, Space } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AccountHeader from "./account";
import { ACTION_PATHS } from "utils/constant";

const MAX_SCROLL_BG = 40;

const Loading = () => {
    return (
        <div className="w-full sticky top-0 left-0 z-100">
            <div className="w-full flex items-center justify-between px-4 py-2 transform duration-200 bg-white">
                <Skeleton.Button active shape="square" style={{ width: "300px" }} />
                <Skeleton.Avatar active shape="circle" size="large" />
            </div>
        </div>
    );
};

type Props = {
    isActionPath: boolean;
    backHandlerOverride?: () => void;
};

const Header = ({ isActionPath, backHandlerOverride }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const mainPath = ACTION_PATHS.reduce((str, path) => str.replace(path, ""), location.pathname);

    const bgRef = useRef<HTMLDivElement | null>(null);

    const title = useMemo(() => {
        let endpoint;
        if (isActionPath) {
            endpoint = location.pathname.split("/")[location.pathname.split("/").length - 2];
        } else {
            endpoint = location.pathname.split("/")[location.pathname.split("/").length - 1];
        }

        return endpoint.split("-").join(" ").CapitalizeEachFirstLetter();
    }, [location.pathname]);

    const backHandler = () => {
        if (backHandlerOverride) {
            backHandlerOverride();
            return;
        }

        navigate(mainPath, { replace: true });
    };

    useEffect(() => {
        const scrollHandler = () => {
            const { scrollY } = window;
            if (!bgRef.current) return;
            if (scrollY >= MAX_SCROLL_BG) {
                bgRef.current.classList.add("bg-white", "shadow-md");
                return;
            }
            bgRef.current.classList.remove("bg-white", "shadow-md");
        };
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    return (
        <div className="w-full sticky top-0 left-0 z-100">
            <div ref={bgRef} className="w-full flex items-center justify-between px-4 py-2 transform duration-200">
                <Space>
                    {isActionPath && (
                        <Button onClick={backHandler} size="large" shape="circle" className="!flex !items-center !justify-center !border-none">
                            <IoArrowBack />
                        </Button>
                    )}
                    <h2 className="font-bold text-gray-700 text-xl capitalize m-0">{title}</h2>
                </Space>
                <AccountHeader />
            </div>
        </div>
    );
};

Header.Loading = Loading;

export default Header;

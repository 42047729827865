/* eslint-disable react/no-array-index-key */
import { Button, message, Radio, RadioChangeEvent, Select, Space } from "antd";
import InputText from "components/form/inputs/input-text";
import { SelectOption } from "models";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import voucherService, { ArrayProduct, Product, ProductWithMinQty } from "services/api-endpoints/dashboard/master-data/voucher";
import { LOGIC_AND, PRODUCT_TYPE, PRODUCT_TYPE_ALL_SKU, VOUCHER_ITEM_LOGIC } from "utils/constant";
import { VoucherContext } from "../context/voucher";
import ProductTable from "../lib/product-table";

const createKey = (data: Product) => [data.item, data.product_code].filter((el) => el).join("~");
const getUserId = (key: string) => key.split("~")[key.split("~").length - 1];

const DetailCondition = () => {
    const { nextStep, prevStep, setState, state } = useContext(VoucherContext);

    const [minOrderQty, setMinOrderQty] = useState(state?.createData?.array_product ? state?.createData?.array_product[0]?.min_qty_order! : 1);
    const [logic, setLogic] = useState(state?.createData?.logic || LOGIC_AND);
    const [couponPerUser, setCouponPerUser] = useState(state?.createData?.usage_coupon_per_user || 1);
    const [productType, setProductType] = useState<number | null>(null);
    const [products, setProducts] = useState<ProductWithMinQty[]>(state?.createData?.array_product || []);
    const [search, setSearch] = useState<string>("");

    const productTypeQuery = useQuery(
        [voucherService.getProductType, state?.createData.product_type],
        async () => {
            return (await voucherService.GetProductType({ product_type: state?.createData.product_type as any })).data.data;
        },
        {
            enabled: state?.createData.product_type !== PRODUCT_TYPE_ALL_SKU,
        }
    );

    const productQuery = useQuery([voucherService.getProduct, state?.createData.product_type, productType, search], async () => {
        return (await voucherService.GetProduct({ product_type: state?.createData.product_type as any, id: productType || 0, search })).data.data;
    });

    const onChangeMinOrderQty = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as unknown as number;
        setMinOrderQty(value);
        setProducts((prev) => prev.map((prd) => ({ ...prd, min_qty_order: value })));
    };

    const onChangeCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponPerUser(e.target.value as unknown as number);
    };

    const onChangeProductType = (id: number) => {
        setProductType(id);
        setProducts([]);
    };

    const onChangeLogic = (e: RadioChangeEvent) => {
        setLogic(e.target.value);
    };

    const onChangeProduct = (keys: string[]) => {
        const ids = keys.map((key) => getUserId(key));
        const prdcts = productQuery.data?.list_product.filter((prd) => ids.find((id) => prd.product_code === id));
        setProducts(prdcts?.map((prd) => ({ ...prd, min_qty_order: minOrderQty })) || []);
    };

    const onSearchProduct = (query: string) => {
        setSearch(query);
    };

    const addAllProduct = () => {
        setProducts(productQuery.data?.list_product?.map((prd) => ({ ...prd, min_qty_order: minOrderQty })) || []);
    };

    const removeAllProduct = () => {
        setProducts([]);
    };

    const removeProduct = (product: ProductWithMinQty) => {
        return () => {
            setProducts((prev) => [...prev].filter((prd) => prd.id !== product.id));
        };
    };

    const onNextHandler = async () => {
        if (minOrderQty <= 0) {
            message.error("Minimal Order Quantity not valid");
            return;
        }
        if (Number(couponPerUser) > Number(state?.createData.max_coupon as any) || couponPerUser <= 0) {
            message.error("Invalid usage coupon / Insufficient Max Coupon");
            return;
        }
        if (!products.length) {
            message.error("No product selected");
            return;
        }
        if (setState) {
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    logic,
                    usage_coupon_per_user: couponPerUser,
                    array_product: products,
                },
            }));
        }
        if (nextStep) nextStep();
    };

    const onPrevHandler = () => {
        if (prevStep) prevStep();
    };

    const productTypeText = PRODUCT_TYPE.find((el) => el.value === state?.createData.product_type)?.label;
    const productTypeOptions = productTypeQuery.data?.map((el) => ({ label: el.name, value: el.id } as SelectOption)) || [];

    const productsMemoize = useMemo(
        () =>
            productQuery.data?.list_product?.map((product, i) => (
                <Select.Option value={createKey(product)} key={i}>
                    <p style={{ fontSize: "14px", margin: 0 }}>
                        {product.item}
                        <br />
                        <span style={{ margin: 0, fontSize: "12px", fontWeight: "bold" }}>{product.product_code}</span>
                    </p>
                </Select.Option>
            )),
        [productQuery.data]
    );

    return (
        <>
            <div className="grid grid-cols-5 gap-x-4">
                <p className="capitalize underline col-span-5">set item</p>
                <InputText classNameForm="col-span-2" value={productTypeText} disabled addonBefore="Product type" />
                <InputText classNameForm="col-span-2" value={state?.createData.max_coupon} disabled addonBefore="Max coupon" />
                <InputText type="number" value={minOrderQty} onChange={onChangeMinOrderQty} placeholder="Min Order Qty" addonBefore="Min Qty" />
                <Select
                    disabled={state?.createData.product_type === PRODUCT_TYPE_ALL_SKU}
                    value={productType}
                    options={productTypeOptions}
                    placeholder={productTypeText}
                    loading={productTypeQuery.isLoading}
                    onChange={onChangeProductType}
                />
                <Select
                    value={products?.map((el) => createKey(el))}
                    maxTagCount="responsive"
                    className="col-span-3"
                    showSearch
                    mode="multiple"
                    placeholder="Product Name"
                    loading={productQuery.isLoading}
                    onChange={onChangeProduct}
                    // onSearch={onSearchProduct}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return (
                            (option?.key ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase()) ||
                            (option?.value ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase())
                        );
                    }}
                >
                    {productsMemoize}
                </Select>
                <Space>
                    <Button type="primary" onClick={addAllProduct}>
                        Add All
                    </Button>
                    {products.length ? (
                        <Button danger onClick={removeAllProduct}>
                            Remove All
                        </Button>
                    ) : null}
                </Space>
                <div className="flex flex-col mt-7 col-span-2">
                    <p className="capitalize underline">logic</p>
                    <Radio.Group className="" onChange={onChangeLogic} value={logic} options={VOUCHER_ITEM_LOGIC} />
                    <p className="capitalize underline mt-7">set periode</p>
                    <InputText
                        type="number"
                        value={couponPerUser}
                        onChange={onChangeCoupon}
                        placeholder="Usage coupon per user"
                        addonBefore="Coupon per user"
                    />
                </div>
                <div className="flex flex-col mt-7 col-span-3">
                    <p className="capitalize underline">logic {VOUCHER_ITEM_LOGIC.find((el) => el.value === logic)?.label}</p>
                    <div className="col-span-3 mt-2">
                        <ProductTable list={products} onRemove={removeProduct} />
                    </div>
                </div>
            </div>
            <Space>
                <Button onClick={onPrevHandler}>Previous</Button>
                <Button type="primary" onClick={onNextHandler} className="!w-fit">
                    Next
                </Button>
            </Space>
        </>
    );
};

export default DetailCondition;

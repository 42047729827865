/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { FilterDataBanner } from "modules/master-data/banner/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface Banner {
    title: string;
    description: string;
    position: number;
    type: number;
    link: string;
    start_date: string;
    end_date: string;
    active: number;
    image: string;
}

export interface DetailBanner extends Banner {
    id: number;
}

class BannerService extends BaseService {
    getAll = "/banner/get-banner";

    delete = "/banner/delete-banner";

    search = "/banner/search-banner";

    filter = "/banner/filter-banner";

    create = "/banner/add-banner";

    detail = "/banner/detail-banner";

    edit = "/banner/edit-banner";

    constructor() {
        super();
    }

    Edit<T extends any>(data: DetailBanner, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailBanner>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: Banner, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataBanner>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataBanner>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataBanner>(param: FilterDataBanner) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const bannerService = new BannerService();
export default bannerService;

import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { FilterPurchaseOrder, TDataPurchaseOrder } from "modules/dashboard-jsl/purchase-order/models";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchaseOrderService, { RequestIntPOData } from "services/api-endpoints/dashboard-jsl/purchase-order";
import { message } from "antd";
import RequestIntPOTable from "modules/dashboard-jsl/request-int-po/table";
import FilterRequestIntPO from "modules/dashboard-jsl/request-int-po/filter";

const filter = ["purchase_order_no", "start_date", "end_date", "status"];

const RequestIntPO = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([purchaseOrderService.requestIntPO, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch)
            return (
                await purchaseOrderService.RequestIntPO({
                    ...({ param_search: searchValue.query, page } as any),
                })
            ).data.data;
        return (
            await purchaseOrderService.RequestIntPO({
                ...({ ...filterValue, query: "" } as any),
            })
        ).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataPurchaseOrder) => (await purchaseOrderService.DeleteDraft({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<RequestIntPOData>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterRequestIntPO filter={filter}>
                {(dt) => <Toolbar placeholder="PO No" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} add={false} />}
            </FilterRequestIntPO>
            <RequestIntPOTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default RequestIntPO;

/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { Alert, Card, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import HistoryStatusWo from "modules/master-data/working-order/history-status";
import ModalOrder from "modules/master-data/working-order/modal-order";
import PhotoView from "modules/master-data/working-order/photo-view";
import WorkingOrderTableProduct from "modules/master-data/working-order/table-product";
import WorkingOrderTableTeknisi from "modules/master-data/working-order/table-teknisi";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import workingOrderService from "services/api-endpoints/dashboard/master-data/working-order";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const WorkingOrderView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    const detailQuery = useQuery(
        [workingOrderService.detail, id],
        async () => {
            return (await workingOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail working order"
            />
            <div className="grid grid-cols-8 gap-4">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="col-span-8">
                                    <Card>
                                        <div className="grid grid-cols-2 mb-5">
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">WO no</p>
                                                <span>: {detailQuery.data?.wo_no}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">customer name</p>
                                                <span>: {detailQuery.data?.cust_name}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs font-bold">
                                                <p className="!w-[100px] mb-1">Inv no {"        : "}</p>
                                                <div className="flex flex-col gap-1">
                                                    {detailQuery.data?.list_inv?.map((inv) => (
                                                        <ModalOrder id={inv.ref_id}>
                                                            {(ctrl) => <button onClick={ctrl.openModal} type="button" className="font-bold underline cursor-pointer border-none bg-transparent">{inv.ref_no}</button>}
                                                        </ModalOrder>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">address</p>
                                                <span>: {detailQuery.data?.address}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">phone number</p>
                                                <span>: {detailQuery.data?.phone_number}</span>
                                            </div>
                                            <div className="m-0 capitalize flex w-fit text-xs">
                                                <p className="!w-[100px] mb-1">status</p>
                                                <span>: {detailQuery.data?.status}</span>
                                            </div>
                                        </div>
                                        <Tabs
                                            defaultActiveKey="1"
                                            items={[
                                                {
                                                    label: `Product`,
                                                    key: "1",
                                                    children: <WorkingOrderTableProduct key="product" list={detailQuery.data?.list_product || []} />
                                                },
                                                {
                                                    label: `Waste Product`,
                                                    key: "4",
                                                    children: <WorkingOrderTableProduct key="waste" list={detailQuery.data?.waste_product || []} />
                                                },
                                                {
                                                    label: `Teknisi`,
                                                    key: "2",
                                                    children: <WorkingOrderTableTeknisi list={detailQuery.data?.teknisi || []} />,
                                                },
                                                {
                                                    label: `Payment History`,
                                                    key: "3",
                                                    children: <HistoryStatusWo paramId={id} />,
                                                },
                                                {
                                                    label: `Photo`,
                                                    key: "5",
                                                    children: <PhotoView after={detailQuery.data?.after || []} before={detailQuery.data?.before || []} />,
                                                },
                                            ]}
                                        />
                                    </Card>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="col-span-8">
                                    <Card>
                                        <Skeleton paragraph={{ rows: 4 }} />
                                    </Card>
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
        </div>
    );
};

export default WorkingOrderView;

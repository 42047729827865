/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import ModalProductManufacturingOrder from "modules/manufacturing-order/modal-product";
import AddManufacturingOrder, { BOMWithLocatorAndNote } from "modules/manufacturing-order/table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import manufacturingOrderService, {
    CreateManufacturingOrder,
    ManufacturingOrderChild,
} from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import { AUTOGENERATED, RESOURCE_OR_PLANNING } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreateManufacturingOrder, "resource_id" | "notes">> = yup.object().shape({
    resource_id: yup.number().required("Resource Required"),
    notes: yup.string(),
});

export type ProductWithBOM = Product & {
    child?: BOMWithLocatorAndNote[];
    no?: number;
};

const ManufacturingOrderAdd = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductWithBOM[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreateManufacturingOrder>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutate = useMutation([manufacturingOrderService.create], async (data: CreateManufacturingOrder) => {
        return (await manufacturingOrderService.Create(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parse = {
            resource_id: data.resource_id,
            notes: data.notes,
            detail: products.map((p) => {
                return {
                    product_id: p.product_id,
                    qty_set: p.qty,
                    child: p.child?.map((c) => {
                        return {
                            product_id: c.product_id,
                            area_id: c.area_id,
                            code: c.code1,
                            note: c.note,
                            qty: c.qty_bom,
                        } as ManufacturingOrderChild;
                    }),
                };
            }),
        } as CreateManufacturingOrder;

        let error = false;
        parse.detail?.forEach((product) => {
            product.child?.forEach((child) => {
                if (!child?.area_id) {
                    message.error("Please fill area locator for all BOM");
                    error = true;
                }
            });
        });

        if (error) return;
        createMutate.mutateAsync(parse).then(() => {
            navigate(-1);
            message.success("Success Create Data!");
        });
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new manufacturing order"
                rightAddition={() => (
                    <Button onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Create
                    </Button>
                )}
            />
            <Card>
                <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="w-full grid grid-cols-4 gap-x-4">
                        <p className="capitalize m-0">
                            Manufacture Order No <br />
                            <span className="m-0">{AUTOGENERATED}</span>
                        </p>
                        <ControlledSelectInput
                            classNameForm="col-span-1"
                            control={control}
                            name="resource_id"
                            allowClear
                            options={RESOURCE_OR_PLANNING}
                            placeholder="Resource / Planning"
                            label="Resource / Planning"
                        />
                        <ControlledInputText classNameForm="col-span-1" control={control} name="notes" placeholder="Notes" label="Notes" />
                        <div className="w-full">
                            Products <br />
                            <ModalProductManufacturingOrder selected={products} setSelected={setProducts}>
                                {(dt) => (
                                    <Button className="!flex !items-center w-full mt-2" type="primary" onClick={dt.openModal}>
                                        <MdAdd className="mr-2" />
                                        Add Product
                                    </Button>
                                )}
                            </ModalProductManufacturingOrder>
                        </div>
                    </div>
                </Form>
            </Card>
            <AddManufacturingOrder list={products} setList={setProducts} />
        </div>
    );
};

export default ManufacturingOrderAdd;

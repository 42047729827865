/* eslint-disable react/no-array-index-key */
import { Alert, Skeleton } from "antd";
import State from "components/common/state";
import { useQuery } from "react-query";
import { Bar } from "react-chartjs-2";
import { useSearchParams } from "react-router-dom";
import { Chart, ChartOptions, registerables } from "chart.js";
import reportService, { SummarySalesPerformance } from "services/api-endpoints/dashboard-giias/report";

export const dataDefault = {
    labels: [],
    datasets: [],
};

export const options: ChartOptions<"bar"> = {
    plugins: {
        legend: {
            display: false,
        },
    },
};

const SalesPerformanceView = () => {
    if (typeof window !== "undefined") {
        Chart.register(...registerables);
    }

    const [searchParams] = useSearchParams();
    const salesId = searchParams.get("sales_id");
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");

    const detailQuery = useQuery([reportService.summarySalesPerformance, salesId, startDate, endDate], async () => {
        const result = (
            await reportService.SummarySalesPerformance({ start_date: startDate || "", end_date: endDate || "", sales_id: salesId || "" })
        ).data.data;
        return result;
    });

    const dataBarMapping = (obj: SummarySalesPerformance) => {
        const keys = Object.keys(obj);
        return keys.map((key) => ({
            key,
            labels: obj[key].map((el) => el.sales_name),
            datasets: [
                {
                    label: "Sales Performance",
                    data: obj[key].map((el) => el.total_price),
                    backgroundColor: ["#04bfbf", "#acf0f2", "#0eeaff", "#59d8e6", "#59d8e6", "#00abd8", "#04668c", "#0288d1", "#7ecefd", "#0067a6"],
                    borderWidth: 1,
                },
            ],
        }));
    };

    return (
        <div className="">
            <p className="text-center text-gray-400 text-2xl my-4">
                {startDate} - {endDate}
            </p>
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                                {detailQuery.data?.map((result) => {
                                    return dataBarMapping(result).map((chart, i) => {
                                        return (
                                            <div key={chart.key} className="bg-white rounded-xl p-4">
                                                <Bar data={chart} options={options} />
                                                <p className="font-semibold text-center mt-4">{chart.key}</p>
                                            </div>
                                        );
                                    });
                                })}
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <div className="grid grid-cols-2 gap-10">
                                <Skeleton paragraph={{ rows: 2 }} active />
                                <Skeleton paragraph={{ rows: 2 }} active />
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </div>
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
        </div>
    );
};

export default SalesPerformanceView;

/* eslint-disable jsx-a11y/control-has-associated-label */
import { Alert, Button, Image, message, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import purchasingInventoryService from "services/api-endpoints/purchasing/inventory";

type Props = ModalProps & {
    poId: any;
};

const ImagesModal = ({ poId, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const imageDeliveryQuery = useQuery([purchasingInventoryService.getImageDelivery, poId], async () => {
        return (await purchasingInventoryService.GetImageDelivery({ po_history_id: poId })).data.data;
    });

    return (
        <ModalTemplate width={700} handlerInComponent={children} footer={null} {...props}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <State data={imageDeliveryQuery.data} isLoading={imageDeliveryQuery.isLoading} isError={imageDeliveryQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="grid grid-cols-3 gap-5">
                                        {imageDeliveryQuery.data?.do_images.map((img) => (
                                            <a href={img} target="_blank" rel="noreferrer" key={img}>
                                                <Image src={img} preview={false} />
                                            </a>
                                        ))}
                                    </div>

                                    <p className="col-span-1  capitalize m-0 text-gray-700 mt-10">
                                        Catatan Penerima :{" "}
                                        <span className="font-semibold m-0 text-gray-700">{imageDeliveryQuery.data?.user_note || "-"}</span>
                                    </p>
                                </State.Data>
                                <State.Loading state={state}>
                                    <div className="grid grid-cols-3 gap-5">
                                        <Skeleton.Image active />
                                        <Skeleton.Image active />
                                    </div>
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(imageDeliveryQuery.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </>
            )}
        </ModalTemplate>
    );
};

export default ImagesModal;

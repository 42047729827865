import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Row, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import { AnimatePresence, motion } from "framer-motion";
import { SignInEmailData } from "models";
import { useForm } from "react-hook-form";
import { MdOutlineFileDownload } from "react-icons/md";
import { useMutation } from "react-query";
import authService from "services/api-endpoints/dashboard/auth";
import * as yup from "yup";

const schema: yup.SchemaOf<SignInEmailData> = yup.object().shape({
    email: yup.string().required("Email wajib diisi"),
    password: yup.string().required("Password wajib diisi"),
});

const UpdatePage = () => {
    const [form] = Form.useForm();

    const { handleSubmit, control } = useForm<SignInEmailData>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const getListAppsMutation = useMutation([authService.getListAppsDistribution], async (data: SignInEmailData) => {
        const req = await authService.GetListAppDistribution(data);
        return req.data?.data || [];
    });

    const onSubmitHandler = handleSubmit(async (data) => {
        getListAppsMutation.mutate(data);
    });

    return (
        <div className="flex items-center justify-center h-screen bg-white">
            <AnimatePresence mode="wait">
                {getListAppsMutation.data?.length ? (
                    <motion.div
                        animate={{ scale: 1, opacity: 1 }}
                        initial={{ scale: 0.7, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="flex flex-col gap-3"
                    >
                        <h1>APPS</h1>
                        {getListAppsMutation.data.map((item) => (
                            <button
                                title={`Download ${item.name} app`}
                                key={item.id}
                                className="flex justify-start p-0 bg-transparent border-none cursor-pointer hover:text-gray-700 focus:text-gray-700 active:text-gray-700 text-gray-400"
                                type="button"
                            >
                                <span className="text-black">{item.name?.CapitalizeEachFirstLetter()}</span>, {item.version}{" "}
                                <MdOutlineFileDownload className="text-2xl m-0 ml-4 p-0" />
                            </button>
                        ))}
                    </motion.div>
                ) : (
                    <motion.div key="form-layout" exit={{ scale: 0.7, opacity: 0 }} transition={{ duration: 0.4 }}>
                        <Form
                            className="w-[300px] "
                            form={form}
                            labelAlign="left"
                            disabled={getListAppsMutation.isLoading}
                            colon={false}
                            onFinish={onSubmitHandler}
                            layout="vertical"
                        >
                            <Space direction="vertical" className="w-full">
                                <h1>APPS</h1>
                                <ControlledInputText control={control} labelCol={{ xs: 12 }} name="email" label="Email" placeholder="Email" />
                                <ControlledInputText
                                    control={control}
                                    labelCol={{ xs: 12 }}
                                    name="password"
                                    label="Password"
                                    placeholder="Password"
                                    type="password"
                                />

                                <Row justify="start">
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={getListAppsMutation.isLoading}
                                            loading={getListAppsMutation.isLoading}
                                        >
                                            Sign in
                                        </Button>
                                    </Space>
                                </Row>
                            </Space>
                        </Form>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default UpdatePage;

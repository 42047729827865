/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React, { useState } from "react";
import { DetailProduct, LocatorData } from "services/api-endpoints/dashboard-jsl/picking-list";
import LocatorModal from "../add/modal-locator";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    onSubmitLocator: (docId: number, locator: LocatorData) => void;
};

export function tableEdit<T extends DetailProduct>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ onSubmitLocator, ...props }: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);

        const columns: ColumnsType<T> = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{text ? Number(text)?.ToIndCurrency("Rp") : "-"}</p>,
            },
            {
                title: "Locator",
                dataIndex: "area_name",
                render: (text, record) => (
                    <LocatorModal title="Locator" docId={record.doc_id!} productId={record.product_id} onSubmit={onSubmitLocator}>
                        {(dt) => (
                            <Button
                                size="small"
                                onClick={() => dt.openModalWithData(record)}
                                className="!flex !items-center"
                                type="default"
                                {...(!text ? { danger: true } : {})}
                            >
                                {text || "Choose Locator"}
                            </Button>
                        )}
                    </LocatorModal>
                ),
            },
            {
                title: "Quantity Request",
                dataIndex: "qty_req",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Locator",
                dataIndex: "qty_locator",
                width: "100px",
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Quantity Approved",
                dataIndex: "qty",
                fixed: "right",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className={text === null || text === 0 ? "m-0 text-center text-red-500" : "m-0 text-center "}>{text || "-"}</p>,
            },
        ];

        const isEditing = (record: DetailProduct, edited: DetailProduct | null) =>
            record.doc_id === edited?.doc_id && record.product_id === edited?.product_id;
        const findIndexSave = (record: DetailProduct, edited: DetailProduct) =>
            record.doc_id === edited.doc_id && record.product_id === edited?.product_id;
        const rowKey = (record: DetailProduct) => record.doc_id as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        const reqQty = editRow?.qty_req || 1;
        const locQty = editRow?.qty_locator || undefined;

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                cellProps={{
                    minNumber: 1,
                    maxNumber: [reqQty, locQty].filter((qty) => qty).sort((a, b) => a! - b!)[0] || 1,
                    ...props?.cellProps,
                }}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                rowKey={rowKey}
            />
        );
    };
}

const ProductTableEdit = tableEdit(EditTable);
export default ProductTableEdit;

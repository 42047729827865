import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import { UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Locator } from "services/api-endpoints/dashboard/master-data/locator";
import { STATUS_ACTIVE } from "utils/constant";
import ModalLocatorEdit from "../modal-edit";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
};

const LocatorTable = <T extends Locator>({ fetcher }: Props<T>) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Warehouse",
            dataIndex: "warehouse_name",
        },
        {
            title: "Area Code",
            dataIndex: "area_code",
        },
        {
            title: "Is Active",
            dataIndex: "is_active",
            render: (val) => STATUS_ACTIVE?.find((el) => el.value === val)?.label,
        },
        {
            width: "160px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <ModalLocatorEdit refresh={fetcher.refetch} locator={record}>
                    {(ctrl) => <ButtonAccessEdit onClick={ctrl.openModal} type="text" />}
                </ModalLocatorEdit>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 20,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default LocatorTable;

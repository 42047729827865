import React, { useContext } from "react";

import moment from "moment";
import { ACCOUNT_ID_GNM, FORMAT_DATE_TIME } from "utils/constant";
import { DetailInvoice } from "services/api-endpoints/dashboard/master-data/ext-invoice";
import { UserContext } from "context/user";

type Props = {
    data?: DetailInvoice;
};

const ExtInvoicePrint = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const { state } = useContext(UserContext);
    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="w-full h-full bg-white relative hidden print:block p-4">
            <div className="z-20 relative">
                <div className="w-full flex gap-4 ">
                    <div className="w-full flex flex-col">
                        <div className="max-w-[100%]">
                            <h1 className="text-4xl uppercase m-0">{data?.name}</h1>
                            <p className="m-0">{data?.address || "-"}</p>
                        </div>
                        <p className="m-0 text-blue-500 font-bold text-5xl self-end">INVOICE</p>
                    </div>
                </div>
                <div className="w-full h-1 bg-blue-500 my-3" />
                <div className="w-full flex gap-16">
                    <div className="flex flex-col gap-1 flex-2">
                        <p className="text-blue-500 text-base m-0">BILL TO:</p>
                        <p className="text-blue-500 text-base font-bold uppercase m-0">
                            {state.user?.account_id === ACCOUNT_ID_GNM ? data?.partner_name : data.bp_name || "-"}
                        </p>
                        <p className="m-0">{state.user?.account_id === ACCOUNT_ID_GNM ? data?.partner_address : data.bp_address || "-"}</p>
                    </div>
                    <table className="flex-1.5 mt-5 h-fit bg-white">
                        <thead className="border border-solid border-gray-400 ">
                            <th className="border border-solid border-gray-400 text-left px-2">No</th>
                            <th className="border border-solid border-gray-400 text-left px-2">{data?.doc_no || ""}</th>
                        </thead>
                        <thead className="border border-solid border-gray-400 ">
                            <th className="border border-solid border-gray-400 text-left px-2">Date</th>
                            <th className="border border-solid border-gray-400 text-left px-2">
                                {moment(data?.created_at).format(FORMAT_DATE_TIME)}
                            </th>
                        </thead>
                    </table>
                </div>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 bg-blue-200">
                        <th className="border border-solid border-blue-500">No</th>
                        <th className="border border-solid border-blue-500">Description</th>
                        <th className="border border-solid border-blue-500">Qty</th>
                        <th className="border border-solid border-blue-500">Unit Price</th>
                        <th className="border border-solid border-blue-500">Disc</th>
                        <th className="border border-solid border-blue-500">Total</th>
                    </thead>
                    {data.list_detail.map((product, index) => (
                        <tbody key={product.order_detail_id} className="border border-solid border-blue-500">
                            <td className="border border-solid border-blue-500 w-[50px] text-center">{index + 1}</td>
                            <td className="border border-solid border-blue-500 px-2">
                                {product.product_name} {product.note ? `(${product.note})` : ""}
                            </td>
                            <td className="border border-solid border-blue-500 w-[50px] text-right px-2">{product?.qty}</td>
                            <td className="border border-solid border-blue-500 w-[90px] text-right px-2">
                                {(product.product_price || 0).ToIndCurrency("")}
                            </td>
                            <td className="border border-solid border-blue-500 w-[90px] text-right px-2">
                                {(product?.discount_price || 0).ToIndCurrency("")}
                            </td>
                            <td className="border border-solid border-blue-500 w-[90px] text-right px-2">
                                {((product.qty || 0) * (product.product_price || 0) - (product.discount_price || 0)).ToIndCurrency("")}
                            </td>
                        </tbody>
                    ))}
                </table>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 ">
                        <th className="border border-solid border-blue-500 text-right px-2" colSpan={2}>
                            PPN = {(data?.ppn || 0).ToIndCurrency("Rp")}
                        </th>
                    </thead>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2">Subtotal</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2">
                            {(data?.total_price || 0).ToIndCurrency("Rp")}
                        </td>
                    </tbody>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2">Discount</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2">
                            {(data?.discount_price || 0).ToIndCurrency("Rp")}
                        </td>
                    </tbody>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 text-right px-2 font-semibold">Total Due</td>
                        <td className="border border-solid border-blue-500 w-[150px] text-right px-2 font-semibold text-black bg-blue-500">
                            {(data?.grand_total || 0).ToIndCurrency("Rp")}
                        </td>
                    </tbody>
                </table>
                <table className="w-full mt-5 bg-white">
                    <thead className="border border-solid border-blue-500 ">
                        <th className="border border-solid border-blue-500 text-left px-2" colSpan={2}>
                            NOTES
                        </th>
                    </thead>
                    <tbody className="border border-solid border-blue-500">
                        <td className="border border-solid border-blue-500 px-2" colSpan={2}>
                            This is invoice generated by system, no signature requried
                        </td>
                    </tbody>
                </table>
            </div>
        </div>
    );
});

export default ExtInvoicePrint;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import useProgressUpload from "hooks/useProgressUpload";
import IsActiveSelect from "modules/master-data/attribute/lib/is-active-select";
import { CreateDataAttribute } from "modules/master-data/attribute/models";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import attributeService from "services/api-endpoints/dashboard/master-data/attribute";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreateDataAttribute>> = yup.object().shape({
    attribute_name: yup.string().required("Attribute name required"),
    attribute_code: yup.string().required("Attribute code required"),
    is_active: yup.number().required("Is Active required"),
});

const AttributeAdd = () => {
    const navigate = useNavigate();
    const [progress, onUploadProgress] = useProgressUpload();
    const [form] = Form.useForm();

    const { handleSubmit, control } = useForm<CreateDataAttribute>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const createMutation = useMutation(
        [attributeService.create],
        async (data: CreateDataAttribute) => {
            return (await attributeService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Attribute created");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new attribute"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="max-w-2xl">
                <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                    <h1 className="capitalize font-semibold text-gray-500 mb-4">Attribute information</h1>
                    <ControlledInputText
                        control={control}
                        label="Style Name"
                        labelCol={{ span: 24 }}
                        name="attribute_name"
                        placeholder="Style Name"
                        style={{ transform: "translateY(-6px)" }}
                    />
                    <ControlledInputText
                        control={control}
                        label="Style Code"
                        labelCol={{ span: 24 }}
                        name="attribute_code"
                        placeholder="Style Code"
                        style={{ transform: "translateY(-6px)" }}
                    />
                    <IsActiveSelect
                        control={control}
                        label="Is Active"
                        labelCol={{ span: 24 }}
                        name="is_active"
                        placeholder="Is Active"
                        style={{ transform: "translateY(-6px)" }}
                    />
                </Form>
            </Card>
        </div>
    );
};

export default AttributeAdd;

import { Button, Select, Space, message } from "antd";
import { StateContext } from "context/state";
import React, { useContext, useMemo, useState } from "react";
import { useMutation, UseQueryResult } from "react-query";
import orderSalesService, { DetailSales } from "services/api-endpoints/dashboard-all/sales/order-sales";
import AdvanceSearch from "modules/advance-search";
import { MdAdd } from "react-icons/md";
import { FILTER_PRODUCT_EDIT_SALES } from "utils/constant";
import { AiOutlineUndo } from "react-icons/ai";
import { TablePaginationConfig } from "antd/es/table";
import { Product } from "models";
import ProductTableEdit from "./product-table-edit";
import { ProductEdit } from "../models";
import ProductTable from "./product-table";

export interface Props {
    detailQuery: UseQueryResult<DetailSales, unknown>;
    products: ProductEdit[];
    setProducts: React.Dispatch<React.SetStateAction<ProductEdit[]>>;
    refreshProduct: () => void;
}

const DetailEditProduct = ({ detailQuery, products, setProducts, refreshProduct }: Props) => {
    const { updateAccess } = useContext(StateContext);

    const [page, setPage] = useState(1);
    const [filterProduct, setFilterProduct] = useState<"new" | "update" | "delete" | "all">("all");

    const getStockWH = useMutation(
        [orderSalesService.getStockWH],
        async (productID: any) => {
            return (await orderSalesService.GetStockWH({ product_id: productID })).data.data;
        },
        {
            onSuccess: (data) => {
                setProducts((prev) =>
                    prev.map((prd) => {
                        if (prd.product_id === data.product_id) {
                            return {
                                ...prd,
                                stock: data.qty_stock || 0,
                            };
                        }
                        return prd;
                    })
                );
            },
        }
    );

    const onEditProduct = (productList: ProductEdit[], prevEditProduct?: ProductEdit | null) => {
        if (!prevEditProduct) return;

        const prevProduct = detailQuery.data?.list_detail?.find((p) => p.product_id === prevEditProduct?.product_id);
        const currEditProduct = productList?.find((p) => p.product_id === prevEditProduct.product_id);
        if (!currEditProduct) return;

        const isEdited = prevProduct?.qty !== currEditProduct.qty || (prevProduct?.note || null) !== (currEditProduct?.note || null);
        if (prevProduct) {
            setProducts(() =>
                productList?.map((p) => (p.product_id === prevEditProduct?.product_id ? { ...p, status: isEdited ? "update" : undefined } : p))
            );
            return;
        }
        setProducts(productList);
    };

    const onRemoveProduct = (product: ProductEdit) => {
        const productIsAlreadyThere = detailQuery.data?.list_detail?.find((p) => p.product_id === product.product_id);
        if (productIsAlreadyThere) {
            message.warning("Product is given DELETED flag");
            setProducts(
                (prev) =>
                    prev?.map((p) =>
                        p.product_id === product.product_id ? { ...p, status: "delete", hideEditAction: true, hideRemoveAction: true } : p
                    ) || []
            );
            return;
        }
        message.warning("Product removed");
        setProducts((prev) => prev?.filter((p) => p.product_id !== product.product_id));
        refreshProduct();
    };

    const onUndoRemoveProduct = (product: ProductEdit) => {
        return () => {
            const prevProduct = detailQuery.data?.list_detail?.find((p) => p.product_id === product.product_id);
            const isEdited = prevProduct?.qty !== product.qty || (prevProduct?.note || null) !== (product?.note || null);
            setProducts((prev) =>
                prev?.map((p) => {
                    if (p.product_id !== product.product_id) return p;
                    return { ...p, status: isEdited ? "update" : undefined, hideEditAction: false, hideRemoveAction: false };
                })
            );
            refreshProduct();
        };
    };

    const onChangePaging = (pagination: TablePaginationConfig) => {
        setPage(pagination.current as any);
    };

    const filteredProducts = useMemo(() => {
        if (filterProduct === "all") return products;
        return products.filter((p) => p.status === filterProduct);
    }, [products, filterProduct]);

    const onChangeFilter = (val: typeof filterProduct) => {
        setPage(1);
        setFilterProduct(val);
    };

    const onAdvanceAddProduct = (product: Product) => {
        setProducts((prev) => [{ ...product, qty: 1, status: "new", product_code: product.product_code1 }, ...(prev || [])]);
    };

    const onAdvanceRemoveProduct = (product: Product) => {
        onRemoveProduct(product);
    };

    const onGetWHProduct = (data: Product) => {
        getStockWH.mutate(data.product_id);
    };

    return (
        <Space direction="vertical" className="w-full">
            {updateAccess && detailQuery.data?.editable && (
                <div className="w-full flex items-center justify-between">
                    <AdvanceSearch
                        selected={products}
                        onSearchSelectProduct={onAdvanceAddProduct}
                        onSearchDeSelectProduct={onAdvanceRemoveProduct}
                        onGetWHProduct={onGetWHProduct}
                    >
                        {(dt) => (
                            <Button className="!flex !items-center w-fit mb-2" type="primary" onClick={dt.openModal}>
                                <MdAdd className="mr-2" />
                                Add Product
                            </Button>
                        )}
                    </AdvanceSearch>
                    <Select className="w-[150px]" options={FILTER_PRODUCT_EDIT_SALES} value={filterProduct} onSelect={onChangeFilter} />
                </div>
            )}
            {updateAccess && detailQuery.data?.editable && (detailQuery.data?.order_product_status || 0) <= 2 ? (
                <ProductTableEdit
                    onChange={onChangePaging}
                    allList={products}
                    list={filteredProducts}
                    onSetList={onEditProduct}
                    removeItemList={onRemoveProduct}
                    pagination={{
                        pageSize: 10,
                        current: page,
                    }}
                    actionAddition={(record: ProductEdit) =>
                        record.status === "delete" ? (
                            <Button onClick={onUndoRemoveProduct(record)} icon={<AiOutlineUndo className="mr-1" />} size="small" type="primary">
                                Undo
                            </Button>
                        ) : (
                            ""
                        )
                    }
                />
            ) : (
                <ProductTable
                    onChange={onChangePaging}
                    pagination={{
                        pageSize: 10,
                        current: page,
                    }}
                    list={detailQuery.data?.list_detail || []}
                    loading={detailQuery.isLoading}
                />
            )}
        </Space>
    );
};

export default DetailEditProduct;

import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/sub-attribute/lib/is-active-select";
import { useMutation, useQuery } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import State from "components/common/state";
import { CreateDataSubAttribute, EditDataSubAttribute } from "modules/master-data/sub-attribute/models";
import subAttributeService from "services/api-endpoints/dashboard/master-data/sub-attribute";

const schema: yup.SchemaOf<Partial<CreateDataSubAttribute>> = yup.object().shape({
    subattribute_name: yup.string().required("SubAttribute Name required"),
    subattribute_code: yup.string().required("SubAttribute Code required"),
    is_active: yup.number().required("Is Active required"),
});

const SubAttributEdit = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [form] = Form.useForm();
    const [progress, onUploadProgress] = useProgressUpload();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm<CreateDataSubAttribute>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [subAttributeService.Detail, id],
        async () => {
            return (await subAttributeService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                form.setFieldsValue({
                    ...data,
                });
                setValue("subattribute_name", data.subattribute_name);
                setValue("subattribute_code", data.subattribute_code);
                setValue("is_active", data.status);
            },
        }
    );

    const editMutation = useMutation(
        [subAttributeService.edit],
        async (data: EditDataSubAttribute) => {
            return (await subAttributeService.Edit(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("SubAttribute edited");
                navigate(-1);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            ...data,
            id: id as any,
        } as EditDataSubAttribute;
        editMutation.mutate(parseData);
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit subattribute"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={editMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card className="max-w-2xl">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form form={form} disabled={editMutation.isLoading} layout="horizontal" onFinish={onSubmitHandler}>
                                    <h1 className="capitalize font-semibold text-gray-500 mb-4">subattribute information</h1>
                                    <ControlledInputText
                                        control={control}
                                        label="Subattribute Name"
                                        labelCol={{ span: 24 }}
                                        name="subattribute_name"
                                        placeholder="Subattribute Name"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <ControlledInputText
                                        control={control}
                                        label="Subattribute Code"
                                        labelCol={{ span: 24 }}
                                        name="subattribute_code"
                                        placeholder="Subattribute Code"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <IsActiveSelect
                                        control={control}
                                        label="Is Active"
                                        labelCol={{ span: 24 }}
                                        name="is_active"
                                        placeholder="Is Active"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="w-full grid grid-cols-2 gap-5">
                                    <Skeleton.Image active style={{ width: "100%" }} />
                                    <Skeleton paragraph={{ rows: 3 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default SubAttributEdit;

import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import CDeliveryOrderView from "pages/c-delivery-order/view";
import CGenerateInvoiceView from "pages/c-generate-invoice/view";
import CInvoiceView from "pages/c-invoice/view";
import CPackingListView from "pages/c-packing-list/view";
import CPickingDetailView from "pages/c-picking-detail/view";
import CPurchaseOrderView from "pages/c-purchase-order/view";
import CSalesOrderView from "pages/c-sales-order/view";
import ExtDeliveryOrderView from "pages/ext-delivery-order/view";
import ExtPackingListView from "pages/ext-packing-list/view";
import ExtPickingDetailView from "pages/ext-picking-detail/view";
import ExtPurchaseOrderView from "pages/ext-purchase-order/view";
import ExtSalesOrderView from "pages/ext-sales-order/view";
import {
    TYPE_MODAL_C_DO,
    TYPE_MODAL_C_INV,
    TYPE_MODAL_C_PD,
    TYPE_MODAL_C_PINV,
    TYPE_MODAL_C_PL,
    TYPE_MODAL_C_PO,
    TYPE_MODAL_C_SO,
    TYPE_MODAL_EXT_DO,
    TYPE_MODAL_EXT_PD,
    TYPE_MODAL_EXT_PL,
    TYPE_MODAL_EXT_PO,
    TYPE_MODAL_EXT_SO,
} from "utils/constant";

export type Props = ModalProps & {
    id?: number;
    docNo?: string;
    type?: number;
};

const RefModal = ({ id, docNo, type, children, ...props }: Props) => {
    return (
        <ModalTemplate width={1300} title={<p>{docNo}</p>} handlerInComponent={children} footer={null} {...props}>
            {(dt) => {
                if (type === TYPE_MODAL_C_SO) {
                    return <CSalesOrderView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_PO) {
                    return <CPurchaseOrderView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_PL) {
                    return <CPackingListView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_DO) {
                    return <CDeliveryOrderView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_INV) {
                    return <CInvoiceView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_PINV) {
                    return <CGenerateInvoiceView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_C_PD) {
                    return <CPickingDetailView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_EXT_PO) {
                    return <ExtPurchaseOrderView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_EXT_SO) {
                    return <ExtSalesOrderView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_EXT_PD) {
                    return <ExtPickingDetailView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_EXT_PL) {
                    return <ExtPackingListView asPreview paramId={id} />;
                }
                if (type === TYPE_MODAL_EXT_DO) {
                    return <ExtDeliveryOrderView asPreview paramId={id} />;
                }
                return <p className="text-red-400 text-xl">Doc type not handled yet</p>;
            }}
        </ModalTemplate>
    );
};

export default RefModal;

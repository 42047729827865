/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ColumnType } from "antd/lib/table";
import { Tag } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { ProductEdit } from "../models";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {};

export function editProductTable<T extends ProductEdit>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ ...props }: Props<T>) => {
        const pagingConfig = props.pagination as TablePaginationConfig;

        const [editRow, setEditRow] = useState<T | null>(null);
        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (text, record, i) => (
                    <p className="capitalize m-0">{((pagingConfig?.current || 1) - 1) * (pagingConfig.pageSize || 10) + (i + 1)}</p>
                ),
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text, record) => (
                    <div className="flex flex-row">
                        <p className="capitalize m-0 mr-2">{text || "-"}</p>
                        {record.status === "delete" && (
                            <Tag color="red" className="!h-fit">
                                Deleted
                            </Tag>
                        )}
                        {record.status === "update" && (
                            <Tag color="green" className="!h-fit">
                                Edited
                            </Tag>
                        )}
                        {record.status === "new" && (
                            <Tag color="blue" className="!h-fit">
                                New
                            </Tag>
                        )}
                    </div>
                ),
            },
            {
                title: "Product Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Product Price",
                dataIndex: "product_price",
                render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
            },
            {
                title: "Estimated Price",
                dataIndex: "-",
                render: (text, record) => (
                    <p className="capitalize m-0">{(Number(record.qty || 1) * Number(record?.product_price))?.ToIndCurrency("Rp")}</p>
                ),
            },
            {
                title: "Stock",
                dataIndex: "stock",
                render: (text, record) => (
                    <>
                        {(record.stock || 0) <= 5 && <p className="text-red-500 capitalize m-0 text-center font-bold">{text}</p>}
                        {(record.stock || 0) > 5 && (record.stock || 0) <= 10 && (
                            <p className="text-yellow-400 capitalize m-0 text-center font-bold">{text}</p>
                        )}
                        {(record.stock || 0) > 10 && <p className="text-green-500 capitalize m-0 text-center font-bold">{text}</p>}
                    </>
                ),
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                fixed: "right",
                width: "75px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Discount",
                dataIndex: "discount",
                render: (text, record) => (
                    <p className="text-red-500 font-semibold capitalize m-0">{record.discount ? Number(text).ToIndCurrency("Rp") : "-"}</p>
                ),
            },
            {
                title: "Note",
                dataIndex: "note",
                align: "center",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id;
        const rowKey = (record: T) => record.product_id! as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
            />
        );
    };
}

const ProductTableEdit = editProductTable(EditTable);
export default ProductTableEdit;

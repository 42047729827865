import { Button, message, Result, Skeleton } from "antd";
import { AxiosResponse } from "axios";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import { BaseResponse, Product } from "models";
import React, { useRef, useState } from "react";
import { FcSearch } from "react-icons/fc";
import { IoMdArrowBack } from "react-icons/io";
import { useQuery } from "react-query";
import specificSearchService, { Subcategory } from "services/api-endpoints/giias/cashier-giias/specific-search";
import Filter, { Option } from "./filter";
import ShowProducts from "./show-products";
import SubcategoryProductDetail from "./subcategory-product";

const GetAllProduct = React.lazy(() => import("./get-all-product"));

type Props = {
    children: (data: HandlerProps) => void;
    onSelectProduct?: (data: Product) => void;
    onSearchSelectProduct?: (data: Product) => void;
    onSearchDeSelectProduct?: (data: Product) => void;
    selected: Product[];
    setSelected?: React.Dispatch<React.SetStateAction<Product[]>>;
    onGetWHProduct?: (data: Product) => void;
    searchProductOverrideComponent?: (dt: {
        selected: Product[];
        selectHandler: (product: Product) => void;
        deSelectHandler: (product: Product) => void;
    }) => any;
};

const AdvanceSearch = ({
    children,
    selected,
    setSelected,
    onSelectProduct,
    onSearchDeSelectProduct,
    onSearchSelectProduct,
    onGetWHProduct,
    searchProductOverrideComponent,
}: Props) => {
    const closeModalRef = useRef<HTMLButtonElement | null>(null);
    const [filterValue, setFilterValue] = useState<Option | null>(null);
    const [subCategory, setSubCategory] = useState<Subcategory | null>(null);
    const [type, setType] = useState<"filter" | "search">("filter");

    const getSubcategoryProduct = useQuery(
        [specificSearchService.getSubcategory, filterValue?.type_id, filterValue?.model_id],
        async () => {
            return (await specificSearchService.GetSubcategory({ is_all_year: 1, model_id: filterValue?.model_id, type_id: filterValue?.type_id }))
                .data.data;
        },
        {
            enabled: !!filterValue,
        }
    );

    const getProduct = useQuery(
        [specificSearchService.getProductBySubcategory, filterValue?.type_id, filterValue?.model_id, subCategory?.subcategory_id],
        async () => {
            return (
                await specificSearchService.GetProductBySubcategory({
                    is_all_year: 1,
                    model_id: filterValue?.model_id,
                    subcategory_id: subCategory?.subcategory_id,
                    type_id: filterValue?.type_id,
                })
            ).data.data;
        },
        {
            enabled: !!filterValue && !!subCategory,
        }
    );

    const onFilterSelect = (data: Option | null) => {
        setFilterValue(data);
        setSubCategory(null);
    };

    const onSelectSubCategory = (data: Subcategory | null) => {
        setSubCategory(data);
    };

    const onClickBack = () => {
        onSelectSubCategory(null);
    };

    const onClickProduct = (product: Product) => {
        if (selected.find((p) => p.product_id === product.product_id)) {
            message.error("Product already exist!");
            return;
        }
        closeModalRef.current?.click();
        if (onSelectProduct) {
            onSelectProduct(product);
            return;
        }
        if (setSelected) {
            setSelected((prev) => [...prev, { ...product, qty: 1 }]);
        }
    };

    const onSearchSelectProductHandler = (product: Product) => {
        if (onGetWHProduct) onGetWHProduct(product);
        if (selected.find((p) => p.product_id === product.product_id)) {
            message.error("Product already exist!");
            return;
        }
        if (onSearchSelectProduct) {
            onSearchSelectProduct(product);
            return;
        }
        if (setSelected) setSelected((prev) => [...prev, { ...product, qty: 1, product_code: product.product_code1 }]);
    };

    const onSearchDeSelectProductHandler = (product: Product) => {
        if (onSearchDeSelectProduct) {
            onSearchDeSelectProduct(product);
            return;
        }
        if (setSelected) setSelected((prev) => [...prev].filter((p) => p.product_id !== product.product_id));
    };

    const GetAllProductMemoize = React.useMemo(() => {
        if (searchProductOverrideComponent) {
            return searchProductOverrideComponent({
                selected,
                selectHandler: onSearchSelectProductHandler,
                deSelectHandler: onSearchDeSelectProductHandler,
            });
        }
        return (
            <GetAllProduct selected={selected} onSelectProduct={onSearchSelectProductHandler} onDeSelectProduct={onSearchDeSelectProductHandler} />
        );
    }, [selected, onSearchSelectProduct, onSearchDeSelectProduct, searchProductOverrideComponent]);

    return (
        <ModalTemplate width={900} title="Advance Search" handlerInComponent={children} footer={null}>
            {(dt) => (
                <div className="w-full flex flex-col gap-7">
                    <button ref={closeModalRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    {type === "filter" ? (
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between mb-10">
                                <div className="flex items-center gap-5">
                                    <Filter onFinalSelect={onFilterSelect} />
                                    <p className="m-0">Or</p>
                                    <Button onClick={() => setType("search")} type="default">
                                        Search Product
                                    </Button>
                                </div>
                                {filterValue && subCategory ? (
                                    <Button
                                        onClick={onClickBack}
                                        type="text"
                                        icon={<IoMdArrowBack className="mr-2" />}
                                        className="!flex !items-center"
                                    >
                                        Back
                                    </Button>
                                ) : null}
                            </div>
                            {filterValue && !subCategory ? (
                                <SubcategoryProductDetail onSelect={onSelectSubCategory} fetcher={getSubcategoryProduct} />
                            ) : null}
                            {filterValue && subCategory ? <ShowProducts onClickProduct={onClickProduct} fetcher={getProduct} /> : null}
                            {!filterValue && !subCategory ? (
                                <Result icon={<FcSearch className="text-7xl text-primary" />} title="Find Products" />
                            ) : null}
                        </div>
                    ) : (
                        <div className="w-full relative flex flex-col gap-5 items-end">
                            <div className="w-full flex items-center justify-between">
                                <p className="m-0 capitalize text-gray-500 font-semibold">search product</p>
                                <Button
                                    onClick={() => setType("filter")}
                                    type="text"
                                    icon={<IoMdArrowBack className="mr-2" />}
                                    className="!flex !items-center"
                                >
                                    Back
                                </Button>
                            </div>
                            <React.Suspense fallback={<Skeleton paragraph={{ rows: 1 }} active />}>{GetAllProductMemoize}</React.Suspense>
                        </div>
                    )}
                </div>
            )}
        </ModalTemplate>
    );
};

export default React.memo(AdvanceSearch);

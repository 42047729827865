/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import StateRender from "components/common/state-render";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import ModalProductManufacturingOrder from "modules/manufacturing-order/modal-product";
import Print from "modules/manufacturing-order/print";
import AddManufacturingOrder, { BOMWithLocatorAndNote } from "modules/manufacturing-order/table";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillPrinter } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import manufacturingOrderService, {
    CreateManufacturingOrder,
    ManufacturingOrderChild,
} from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import { AUTOGENERATED, RESOURCE_OR_PLANNING } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreateManufacturingOrder, "resource_id" | "notes">> = yup.object().shape({
    resource_id: yup.number().required("Resource Required"),
    notes: yup.string(),
});

export type ProductWithBOM = Product & {
    child?: BOMWithLocatorAndNote[];
};

const ManufacturingOrderEdit = () => {
    const [params] = useSearchParams();
    const id = params.get("id");

    const navigate = useNavigate();
    const [products, setProducts] = useState<ProductWithBOM[]>([]);

    const [form] = Form.useForm();

    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Manufacturing order`,
        pageStyle: "@page {  }",
    });

    const { handleSubmit, control, setValue } = useForm<CreateManufacturingOrder>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailProduct = useMutation([manufacturingOrderService.getProductDetail], async (id: any) => {
        const result = (await manufacturingOrderService.GetProductDetail({ product_id: id })).data.data;
        return result?.bom?.map((b) => ({ ...b, qty_bom_temp: b.qty_bom })) as BOMWithLocatorAndNote[];
    });

    const detailQuery = useQuery(
        [manufacturingOrderService.detail, id],
        async () => {
            return (await manufacturingOrderService.Detail({ id })).data.data;
        },
        {
            async onSuccess(data) {
                form.setFieldsValue({
                    resource_id: data.resource_id,
                    notes: data.notes,
                });
                setValue("resource_id", data.resource_id);
                setValue("notes", data.notes);

                try {
                    const getAllBOM = await Promise.all(data.detail?.map((product) => detailProduct.mutateAsync(product.product_id)) || []);

                    setProducts(
                        data.detail?.map((el, i) => ({
                            ...el,
                            qty: el.qty_set,
                            product_code1: el.code1,
                            product_name: el.name,
                            child: el.child_detail?.map((c) => {
                                const child = getAllBOM[i].find((ch) => ch.product_id === c.product_id);
                                return {
                                    ...c,
                                    qty_bom_temp: child?.qty_bom,
                                    qty_bom: c.qty,
                                    code1: c.code,
                                };
                            }) as BOMWithLocatorAndNote[],
                        })) as ProductWithBOM[]
                    );
                } catch (e: any) {
                    message.error(e?.message);
                }
            },
        }
    );

    const createMutate = useMutation([manufacturingOrderService.create], async (data: CreateManufacturingOrder) => {
        return (await manufacturingOrderService.Create(data)).data.data;
    });

    const onSubmitHandler = handleSubmit((data) => {
        const parse = {
            id: id as any,
            resource_id: data.resource_id,
            notes: data.notes,
            detail: products.map((p) => {
                return {
                    product_id: p.product_id,
                    qty_set: p.qty,
                    child: p.child?.map((c) => {
                        return {
                            ...c,
                            code: c.code1,
                            qty: c.qty_bom,
                        } as ManufacturingOrderChild;
                    }),
                };
            }),
        } as CreateManufacturingOrder;

        let error = false;
        parse.detail?.forEach((product) => {
            product.child?.forEach((child) => {
                if (!child?.area_id) {
                    message.error("Please fill area locator for all BOM");
                    error = true;
                }
            });
        });

        if (error) return;

        createMutate.mutateAsync(parse).then(() => {
            navigate(-1);
            message.success("Success Edit Data!");
        });
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="edit manufacturing order"
                rightAddition={() => (
                    <Space>
                        <Button onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </Space>
                )}
            />
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading}>
                <StateRender.Loading>
                    <p>Getting data...</p>
                </StateRender.Loading>
                <StateRender.Data>
                    <Card>
                        <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                            <div className="w-full grid grid-cols-4 gap-x-4">
                                <p className="capitalize m-0">
                                    Manufacture Order No <br />
                                    <span className="m-0">{AUTOGENERATED}</span>
                                </p>
                                <ControlledSelectInput
                                    classNameForm="col-span-1"
                                    control={control}
                                    name="resource_id"
                                    allowClear
                                    options={RESOURCE_OR_PLANNING}
                                    placeholder="Resource / Planning"
                                    label="Resource / Planning"
                                />
                                <ControlledInputText classNameForm="col-span-1" control={control} name="notes" placeholder="Notes" label="Notes" />
                                <div className="w-full">
                                    Products <br />
                                    <ModalProductManufacturingOrder selected={products} setSelected={setProducts}>
                                        {(dt) => (
                                            <Button className="!flex !items-center w-full mt-2" type="primary" onClick={dt.openModal}>
                                                <MdAdd className="mr-2" />
                                                Add Product
                                            </Button>
                                        )}
                                    </ModalProductManufacturingOrder>
                                </div>
                            </div>
                        </Form>
                    </Card>
                    <AddManufacturingOrder list={products} setList={setProducts} />
                    {/* <AddManufacturingOrder
                        removeItemList={onRemoveList}
                        expandable={{
                            expandedRowRender: (rec: ProductWithBOM) => {
                                return (
                                    <div className="flex flex-row pl-8 items-stretch opacity-80 gap-3 my-2">
                                        <div className="h-auto w-[5px] bg-blue-400 rounded" />
                                        <div className="flex-1">
                                            <AddManufacturingOrderChild
                                                asEdit
                                                qtySet={rec.qty}
                                                defaultBOM={rec?.child}
                                                onChangeBOM={onChangeChild(rec)}
                                                productId={rec.product_id}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                            rowExpandable: (rec: Product) => !!rec.product_id,
                            defaultExpandAllRows: true,
                            expandedRowKeys: products.map((p) => p.product_id!),
                        }}
                        list={products}
                        setList={setProducts}
                    /> */}
                </StateRender.Data>
            </StateRender>
            <Print ref={letterRef} data={{ ...detailQuery.data }} />
        </div>
    );
};

export default ManufacturingOrderEdit;

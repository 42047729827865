import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
    EDIT_PATH,
    MARKET_PLACE_VALUES,
    ORDER_SALES_CANCEL,
    ORDER_SALES_DRAFT,
    ORDER_SALES_NEW,
    STATUS_ORDER_SALES_FILTER,
    VIEW_PATH,
} from "utils/constant";
import { Sales } from "services/api-endpoints/dashboard-all/sales";
import { BasePaginationResponse } from "models";
import ButtonAccessView from "components/button/button-access-view";
import ButtonAccessEdit from "components/button/button-access-edit";
import ButtonAccessDelete from "components/button/button-access-delete";
import Order from "pages/order";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const SalesTable = <T extends Sales>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [size, setSize] = useState(10);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        searchParams.set("pageSize", pagination.pageSize?.toString() || "10");
        setSize(pagination.pageSize || 10);
        setSearchParams(searchParams);
    };

    const viewHandler = (data: Sales) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const editHandler = (data: Sales) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * size + (i + 1)}</p>,
        },
        {
            width: "240px",
            title: "Invoice No",
            dataIndex: "inv_no",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Invoice Info",
            dataIndex: "info",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Marketplace",
            dataIndex: "marketplace_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Customer",
            dataIndex: "customer_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Grand Total",
            dataIndex: "grand_total",
            render: (text) => <p className="capitalize m-0">{Number(text)?.ToIndCurrency("Rp")}</p>,
        },
        {
            title: "Date",
            dataIndex: "date",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text) => {
                const type = STATUS_ORDER_SALES_FILTER.find((t) => t.value === text);
                if (!type) return "-";
                if (type.value === ORDER_SALES_CANCEL) return <Tag color="red">{type?.label}</Tag>;
                if (type.value === ORDER_SALES_DRAFT) return <Tag color="purple">{type?.label}</Tag>;
                return <Tag color={type.value === ORDER_SALES_NEW ? "green" : "blue"}>{type?.label}</Tag>;
            },
        },
        {
            width: "140px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    {record.status ? (
                        <ButtonAccessView onClick={() => viewHandler(record)} type="text" />
                    ) : (
                        <>
                            <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                            <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            scroll={{ x: 1300 }}
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 20, 30, 50],
                locale: { items_per_page: "/ page" },
            }}
            onChange={handleTableChange}
        />
    );
};

export default SalesTable;

import React from "react";
import { Alert, Button, Card, Form, message, Skeleton, Space } from "antd";
import ToolbarAction from "components/toolbar/action";
import { useMutation, useQuery } from "react-query";
import masterCoaService, { CreateCoa, EditCoa } from "services/api-endpoints/accounting/master-coa";
import State from "components/common/state";
import { STATUS_ACTIVE } from "utils/constant";

const MasterCoaView = ({ id, onClose }: { id: any; onClose: () => void }) => {
    const detailQuery = useQuery(
        [masterCoaService.detailCoa, id],
        async () => {
            return (await masterCoaService.DetailCoa({ id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const getParent = useQuery([masterCoaService.getParent], async () => {
        return (await masterCoaService.GetParent()).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6 ">
            <ToolbarAction
                title="View COA"
                rightAddition={() => (
                    <Space>
                        <Button danger onClick={onClose}>
                            Close
                        </Button>
                    </Space>
                )}
            />
            <div className="w-full">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form layout="vertical" className="grid grid-cols-2 gap-4">
                                    <p className="capitalize m-0">
                                        Code <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.account}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Name IN <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.name_in}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Name EN <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.name_en}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Parent <br />
                                        <span className="font-semibold underline m-0">
                                            {getParent.data?.find((item) => item.parent_id === detailQuery.data?.parent_id)?.parent_name || "-"}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Currency <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.currency_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Acc Type <br />
                                        <span className="font-semibold underline m-0">{detailQuery.data?.type_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Is Active <br />
                                        <span className="font-semibold underline m-0">
                                            {STATUS_ACTIVE.find((item) => item.value === detailQuery.data?.is_active)?.label}
                                        </span>
                                    </p>
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 4 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </div>
        </div>
    );
};

export default MasterCoaView;

import { Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import PemakaianTableAdd from "modules/dashboard-jsl/pamakaian-barang/table-add";
import PemakaianTableView from "modules/dashboard-jsl/pamakaian-barang/table-view";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import pemakaianBarangService, { CreatePK, DetailChildPK, SuppliesPK } from "services/api-endpoints/dashboard/master-data/pemakaian-barang";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<CreatePK>> = yup.object().shape({
    note: yup.string().required("Note Required"),
    child: yup.array(),
});

interface Props {
    paramId?: any;
}

const PemakaianBarangView = ({ paramId }: Props) => {
    const [searchParams] = useSearchParams();
    const id = paramId || searchParams.get("id");

    const [child, setChild] = useState<DetailChildPK[]>([]);

    const detailQuery = useQuery(
        [pemakaianBarangService.detail, id],
        async () => {
            return (await pemakaianBarangService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setChild(data.child);
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="detail Pemakaian Supplies" />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-2 gap-4">
                                    <p className="capitalize m-0">
                                        Doc No <br />
                                        <span className="underline italic m-0">{detailQuery.data?.pk_no || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <PemakaianTableView list={child} setList={setChild} action={false} />
        </div>
    );
};

export default PemakaianBarangView;

import React, { useState } from "react";
import { Button, Form, Popconfirm, Table, Typography } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import EditableCell from "components/table/editable-cell";
import { TDataProductPrint } from "./models";

type Props = {
    productsToPrint: TDataProductPrint[];
    setProductsToPrint: React.Dispatch<React.SetStateAction<TDataProductPrint[]>>;
    removeProductToPrint: (dt: TDataProductPrint) => void;
    className?: string;
};

const ToPrintProductTable = React.memo(({ productsToPrint, setProductsToPrint, removeProductToPrint, className }: Props) => {
    const [form] = Form.useForm();
    const [editingId, setEditingId] = useState<number | null>(null);

    const onEdit = (record: TDataProductPrint) => {
        form.setFieldsValue(record);
        setEditingId(record.id!);
    };

    const isEditing = (record: TDataProductPrint) => record.id === editingId;

    const cancel = () => {
        setEditingId(null);
    };

    const save = async (id: number) => {
        try {
            const row = (await form.validateFields()) as TDataProductPrint;

            const newData = [...productsToPrint];
            const index = newData.findIndex((item) => id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setProductsToPrint(newData);
                setEditingId(null);
            } else {
                newData.push(row);
                setProductsToPrint(newData);
                setEditingId(null);
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns: any[] = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "name",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Product Code",
            dataIndex: "code1",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Molding Code",
            dataIndex: "molding_code",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Quantity",
            dataIndex: "qty",
            fixed: "right",
            width: "100px",
            editable: true,
            render: (text: string) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
        },
        {
            width: "130px",
            title: "Action",
            fixed: "right",
            render: (_: any, record: TDataProductPrint) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id!)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <div className="w-full flex gap-3">
                        <Button disabled={!!editingId} type="text" onClick={() => onEdit(record)}>
                            <FiEdit className="text-lg" />
                        </Button>
                        <Button disabled={!!editingId} type="text" danger onClick={() => removeProductToPrint(record)}>
                            <MdOutlineDeleteOutline className="text-lg" />
                        </Button>
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) return col;
        return {
            ...col,
            onCell: (record: TDataProductPrint) => ({
                record,
                inputType: "number",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                rowKey={(record) => record.id!}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                size="small"
                scroll={{ x: 1000 }}
                columns={mergedColumns}
                rowClassName="editable-row"
                dataSource={productsToPrint || []}
                className={className}
                pagination={{
                    pageSize: 10,
                    total: productsToPrint.length,
                    showSizeChanger: false,
                }}
            />
        </Form>
    );
});

export default ToPrintProductTable;

/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Card, Form, message, Skeleton, Tabs, TabsProps } from "antd";
import StateRender from "components/common/state-render";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import TableBox, { TableBoxType } from "modules/working-order-m/add/table-box";
import AddBoxModal from "modules/working-order-m/modal/add-box";
import TableProductsView from "modules/working-order-m/view/table-product";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import workingOrderMService, {
    CreateBox,
    CreateWOM,
    ProductPOM,
    PurchasingOrderM,
} from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { RESOURCE_OR_PLANNING } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreateWOM, "note" | "resource_id">> = yup.object().shape({
    resource_id: yup.number(),
    note: yup.string().nullable(),
});

const WorkingOrderMEdit = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const id = params.get("id") as any;

    const [pom, setPom] = React.useState<PurchasingOrderM | undefined>(undefined);
    const [products, setProducts] = React.useState<ProductPOM[]>([]);
    const [box, setBox] = React.useState<TableBoxType[]>([]);
    const [tab, setTab] = React.useState<string>("products");

    const [form] = Form.useForm();
    const { handleSubmit, control, setValue } = useForm<Pick<CreateWOM, "note" | "resource_id">>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const detailQuery = useQuery(
        [workingOrderMService.detail, id],
        async () => {
            return (await workingOrderMService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                form.setFieldsValue({
                    note: data.note,
                });
                setValue("note", data.note);
                setProducts(
                    data?.list_product?.map((p) => ({ product_id: p.product_id, product_code1: p.product_code1, qty: p.qty, qty_sisa: p.qty })) || []
                );
            },
        }
    );

    const createMutate = useMutation(
        async (dt: CreateBox) => {
            return (await workingOrderMService.AddBox(dt)).data.data;
        },
        {
            onSuccess: () => {
                navigate(-1);
                message.success("Success Add Box");
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData: CreateBox = {
            note: data.note,
            id,
            child: box.map((b) => ({
                box_no: b.box_no,
                machine_id: b.machine_id,
                operator_id: b.operator_id,
                detail: b.detail.map((p) => ({
                    product_id: p.product_id,
                    qty: p.qty_sisa,
                })),
            })) as any,
        };

        if (!box.length) {
            message.error("Please add box");
            return;
        }

        createMutate.mutate(parseData);
    });

    const onChange = (key: string) => {
        setTab(key);
    };

    const onDeleteProduct = (p: ProductPOM) => {
        setProducts((products) => products?.filter((pd) => pd.product_id !== p.product_id));
    };

    const onDeleteBox = (b: TableBoxType) => {
        setBox((boxes) => boxes?.filter((box) => box.id !== b.id));
    };

    const items: TabsProps["items"] = [
        {
            key: "products",
            label: "Products",
            children: <TableProductsView removeItemList={onDeleteProduct} list={products} action={false} pagination={false} />,
        },
        {
            key: "box",
            label: "Box",
            children: <TableBox removeItemList={onDeleteBox} list={box} canEdit={false} />,
        },
    ];

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onBoxAdd = (box: TableBoxType) => {
        setBox((prev) => [...prev, box]);
    };

    const contentAction = () => (
        <AddBoxModal boxes={box} onAddBox={onBoxAdd} products={products}>
            {(ctrl) => {
                const onClick = () => {
                    if (products.length) {
                        ctrl.openModal();
                        return;
                    }
                    message.error("Please add some product first");
                    setTab("products");
                };

                return (
                    <Button onClick={onClick} type="primary" className="!w-fit">
                        Add Box
                    </Button>
                );
            }}
        </AddBoxModal>
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Edit working order m"
                rightAddition={() => (
                    <Button loading={createMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card>
                        <Form className="col-span-2" form={form} layout="vertical" onFinish={onSubmitHandler}>
                            <div className="w-full grid grid-cols-4 gap-x-4">
                                <p className="capitalize m-0">
                                    Doc No <br />
                                    <span className="m-0">{detailQuery.data?.wo_no}</span>
                                </p>
                                <p className="capitalize m-0">
                                    Resource/Planning <br />
                                    <span className="m-0 underline">
                                        {RESOURCE_OR_PLANNING.find((el) => el.value === detailQuery.data?.resource_id)?.label}
                                    </span>
                                </p>
                                <p className="capitalize m-0">
                                    Ref Doc No <br />
                                    <span className="m-0">{detailQuery.data?.po_no || "-"}</span>
                                </p>
                                <ControlledInputTextArea control={control} name="note" placeholder="Notes" label="Notes" rows={2} />
                            </div>
                        </Form>
                    </Card>
                    <Card>
                        <Tabs tabBarExtraContent={{ right: contentAction() }} activeKey={tab} items={items} onChange={onChange} />
                    </Card>
                </StateRender.Data>
                <StateRender.Loading>
                    <Skeleton className="w-[80%] h-[30px] mb-5" />
                    <Skeleton className="w-[70%] h-[30px] mb-5" />
                    <Skeleton className="w-[30%] h-[30px] mb-5" />
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default WorkingOrderMEdit;

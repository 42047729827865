/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Checkbox, Modal, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";
import ModalRefDetail from "modules/master-data/purchasing/modal-ref-voucher";
import { TrialBalance } from "services/api-endpoints/accounting/report";

type Props<T> = {
    listData: T[];
};

const ReportTable = <T extends TrialBalance>({ listData }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            width: "100px",
            title: "Code",
            dataIndex: "coa_code",
            render: (text, record) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Indonesian Name",
            dataIndex: "coa_name_in",
            render: (text, record) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "English Name",
            dataIndex: "coa_name_en",
            render: (text, record) => <p className="capitalize m-0">{text}</p>,
        },
        {
            width: "150px",
            title: "Debit",
            dataIndex: "debit",
            render: (text, record) => <p className="font-semibold">{text === 0 ? "-" : text?.ToIndCurrency("Rp") || "-"}</p>,
        },
        {
            width: "150px",
            title: "Kredit",
            dataIndex: "kredit",
            render: (text, record) => <p className="font-semibold">{text === 0 ? "-" : text?.ToIndCurrency("Rp") || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.coa_code!}
            size="small"
            columns={columns}
            dataSource={listData || []}
            className="w-full"
            pagination={{
                pageSize: 1000,
                showSizeChanger: false,
                hideOnSinglePage: true,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ReportTable;

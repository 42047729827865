import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessEdit from "components/button/button-access-edit";
import { BasePaginationResponse } from "models";
import moment from "moment";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ListManufacturingOrder } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import { EDIT_PATH, FORMAT_DATE_TIME, RESOURCE_OR_PLANNING } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BasePaginationResponse<any>, unknown>;
};

const ManufacturingOrderTable = <T extends ListManufacturingOrder>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: ListManufacturingOrder) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{((fetcher.data?.current_page || 1) - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Order No",
            dataIndex: "order_no",
        },
        {
            title: "Resource",
            dataIndex: "resource_id",
            render: (val) => RESOURCE_OR_PLANNING.find((el) => el.value === val)?.label,
        },
        {
            title: "Total Quantity",
            dataIndex: "total_qty",
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            title: "Created By",
            dataIndex: "created_by",
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                current: fetcher.data?.current_page || 1,
                pageSize: 10,
                total: fetcher.data?.total_data || 0,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default ManufacturingOrderTable;

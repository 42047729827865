/* eslint-disable react/no-array-index-key */
import moment from "moment";
import React from "react";
import QRCode from "react-qr-code";
import { DetailGR } from "services/api-endpoints/dashboard/master-data/ext-goods-receipt";
import { ProductTransferWithEdit2 } from "services/api-endpoints/dashboard/master-data/material-movement";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = {
    data: DetailGR | null;
};

const Print = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const limitPattern = [10, 20]; // [first, middle]

    const arr: ProductTransferWithEdit2[][] = [];
    const listProductCopy = [...(data?.list_detail || [])];
    while (listProductCopy.length) {
        arr.push(listProductCopy.splice(0, arr.length === 0 ? limitPattern[0] : limitPattern[1]));
    }

    const listProduct = data?.list_detail || [];

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            <div className="w-full bg-white p-5">
                <div className="w-full flex items-end justify-between">
                    <div className="max-w-[55%]">
                        <h1 className="text-2xl uppercase m-0">PT. SEHATI PRIMA MAKMUR</h1>
                        <p className="text-xs m-0">
                            Rukan Paris Golf Lake Residence Blok B No 23-25 Cengkareng, RT.10/RW.14, East Cengkareng, Cengkareng, West Jakarta City,
                            Jakarta 11730
                        </p>
                    </div>
                    <h2 className="text-3xl font-medium m-0 capitalize">External Goods Receipt</h2>
                </div>
                <div className="w-full h-[2px] bg-gray-400 mt-2" />
                <div className="w-full h-[2px] bg-gray-400 mt-1" />
                <div className="w-full grid grid-cols-3 mt-4">
                    <div className="">
                        <table>
                            <tr className="text-xs">
                                <td className="min-w-[60px]">No</td>
                                <td>{` : ${data?.doc_no}`}</td>
                            </tr>
                            <tr className="text-xs">
                                <td className="min-w-[60px]">Tanggal</td>
                                <td> : {moment().format("dddd DD MM yyyy")}</td>
                            </tr>
                            <tr className="text-xs">
                                <td>.</td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex justify-center">
                        <QRCode size={70} value={(data?.doc_no as any) || ""} />
                    </div>
                </div>
                <div className="flex flex-col mt-5 break-inside-auto">
                    <table>
                        <thead className="border border-solid border-gray-500">
                            <th className="border border-solid border-gray-500 px-2 text-xs">No</th>
                            <th className="border border-solid border-gray-500 text-xs">Nama Produk</th>
                            <th className="border border-solid border-gray-500 text-xs">Kode</th>
                            <th className="border border-solid border-gray-500 text-xs">Kode 2</th>
                            <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Send</th>
                            <th className="border border-solid border-gray-500 w-[30px] px-1 text-xs">Qty Receipt</th>
                        </thead>
                        <tbody className="border border-solid border-gray-500">
                            {listProduct?.map((el, i) => {
                                return (
                                    <tr key={i} className="border border-solid border-gray-500">
                                        <td className="border border-solid border-gray-500 text-xs text-center px-1">{i + 1}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1">{el.product_name}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.product_code}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.code2 || "-"}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty_send}</td>
                                        <td className="border border-solid border-gray-500 text-xs px-1 whitespace-nowrap">{el.qty}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="flex items-start justify-between mt-10">
                    {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                        <div className="flex flex-col items-center" key={i}>
                            <p className="capitalize font-medium text-xs">{el}</p>
                            <p className="text-3xl m-0 mt-1">(...........)</p>
                        </div>
                    ))}
                    <div className="">
                        <table className="text-xs">
                            <tr>
                                <td className="min-w-[100px]">Catatan</td>
                                <td className="max-w-[200px]">: </td>
                            </tr>
                            <tr>
                                <td className="min-w-[100px]">Print</td>
                                <td>
                                    : - <br />
                                    {moment(moment.now()).format(FORMAT_DATE_TIME)}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Print;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface ReturnOrder {
    id?: number;
    return_no?: string;
    total_items?: number;
    status?: number;
    created_by?: string;
    created_at?: number;
    order_reference?: string;
}

export interface FilterParams {
    return_no?: string;
    order_reference_no?: string;
    status?: string;
    start_date?: string;
    end_date?: string;
    page?: string;
}

export interface Reason {
    reason_id: number;
    reason_name: string;
}

export interface Order {
    order_id?: number;
    order_no?: string;
    total_items?: number;
    order_reference?: string;
}

export interface DetailOrder {
    order_id?: number;
    order_no?: string;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    qty_order?: number;
    stock_action?: any;
    order_reference?: string;
    qty_return?: any;
}

export interface ReturnOrderDetail {
    order_reference_no?: number | string;
    order_id?: number | string | null;
    product_id?: number | string;
    qty_order?: number | string | null;
    qty_return?: number | string;
    stock_action?: number | string;
}

export interface CreateReturnOrder {
    reason_id?: number;
    bp_id?: number | null;
    type?: number | null;
    order_id?: number[];
    return_order_detail?: ReturnOrderDetail[];
}

export interface ListDetail {
    return_order_detail_id?: number;
    order_reference?: string;
    order_no?: string;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    qty_order?: number;
    qty_return?: number;
    stock_action?: number;
    area_id?: number;
    area_code?: string;
}

export interface DetailReturnOrder {
    id?: number;
    bp_id?: number;
    type?: number;
    return_no?: string;
    reason_id?: number;
    return_status?: number;
    list_detail?: ListDetail[];
}

class BPReturnService extends BaseService {
    getAll = "/bp-return-order/get-all-return-order";

    getReason = "/bp-return-order/get-reason";

    getDataOrder = "/bp-return-order/get-data-order";

    getDetailOrder = "/bp-return-order/get-detail-order";

    create = "/bp-return-order/create-return-order";

    getDetailReturnOrder = "/bp-return-order/detail-return-order";

    setStatus = "/bp-return-order/set-status";

    constructor() {
        super();
    }

    SetStatus<T extends any>({ id, status }: { id: any; status: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.setStatus}/${id}/${status}`,
            });
            return req;
        });
    }

    GetDetailReturnOrder<T extends DetailReturnOrder>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getDetailReturnOrder}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateReturnOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetDetailOrder<T extends DetailOrder[]>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getDetailOrder}/${id}`,
            });
            return req;
        });
    }

    GetDataOrder<T extends Order[]>(params?: { query?: any; type: any; bp_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDataOrder,
                config: { params },
            });
            return req;
        });
    }

    GetReason<T extends Reason[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getReason,
            });
            return req;
        });
    }

    GetAll<T extends ReturnOrder>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }
}

const bpReturnService = new BPReturnService();
export default bpReturnService;

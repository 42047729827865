import { Alert, Card, Skeleton, message } from "antd";
import { ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import State from "components/common/state";
import { useRef, useState } from "react";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { MostProductValue as MostProductValueType } from "services/api-endpoints/dashboard-giias/report";
import { TYPE_POS_OFFLINE } from "utils/constant";
import MostProductValue from "./most-product-value";

export const dataDefault = {
    labels: [],
    datasets: [],
};

export const options: ChartOptions<"pie"> = {
    parsing: {
        key: "product_price",
    },
    plugins: {
        legend: {
            display: false,
            position: "right" as const,
        },
        datalabels: {
            display: true,
            align: "end",
            padding: {
                right: 2,
            },
            labels: {
                title: {
                    font: {
                        weight: "lighter",
                    },
                },
                value: {
                    color: "white",
                },
            },
            formatter(value: MostProductValueType, ctx) {
                let sum = 0;
                const dataArr = ctx.chart.data.datasets[0].data;
                dataArr.forEach((data: any) => {
                    sum += data.product_price as number;
                });
                const percentage = `${((value.product_price * 100) / sum).toFixed(2)}%`;
                return percentage;
            },
        },
    },
};

const MostProductValueChart = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const chartRef = useRef<any>();

    const watchParams = [startDate, endDate, typeSales];
    const [chartData, setChartData] = useState<any>(dataDefault);

    const topTenQuery = useQuery(
        [reportService.getMostProductValue, ...watchParams],
        async () => {
            return (await reportService.GetMostProductValue({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
        },
        {
            onSuccess(data) {
                setChartData((prev: any) => ({
                    ...prev,
                    labels: data.map((el) => el.product_name),
                    datasets: [
                        {
                            label: "Most Product",
                            data,
                            backgroundColor: [
                                "#04bfbf",
                                "#acf0f2",
                                "#0eeaff",
                                "#59d8e6",
                                "#59d8e6",
                                "#00abd8",
                                "#04668c",
                                "#0288d1",
                                "#7ecefd",
                                "#0067a6",
                            ],
                            borderWidth: 2,
                            hoverOffset: 10,
                            parsing: {
                                key: "product_price",
                            },
                        },
                    ],
                }));
            },
        }
    );

    const onClickPie = (event: any) => {
        const element = getElementAtEvent(chartRef.current, event) as any;
        const data = element[0].element.$context.raw as MostProductValueType;
        const buttonClickItem = document.querySelector(`button#most-product-value-${data.product_id}`) as HTMLButtonElement;
        if (buttonClickItem) {
            buttonClickItem.click();
            return;
        }
        message.error("Detail can't found");
    };

    return (
        <Card className="h-full">
            <p className="m-4 text-center font-light text-xl text-gray-500 capitalize">most product value</p>
            <div className="w-full h-full items-center flex gap-4">
                <State data={topTenQuery.data} isLoading={topTenQuery.isLoading} isError={topTenQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex-1">
                                    <Pie ref={chartRef} onClick={onClickPie} data={chartData} plugins={[ChartDataLabels as any]} options={options} />
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 3 }} active />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert message={(topTenQuery.error as any)?.message} type="error" />
                            </State.Error>
                        </>
                    )}
                </State>
                <div className="flex-1">
                    <MostProductValue />
                </div>
            </div>
        </Card>
    );
};

export default MostProductValueChart;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { AddItemT } from "./modal-add-item";
import OrderDetailModal from "./modal-order-detail";

type Props<T> = {
    list?: T[];
    onClickDelete: (item: T) => void;
    onClickEdit?: (item: T) => void;
    loading?: boolean;
};

const TableAddJurnalPenyesuaian = <T extends AddItemT>({ list, onClickDelete, onClickEdit, loading }: Props<T>) => {
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (_, r, i) => i + 1,
        },
        {
            title: "Account Name",
            render: (_, record) => record?.account_detail?.coa_name,
        },
        {
            title: "Ref ID",
            render: (text, record) => (
                <OrderDetailModal item={record}>
                    {(ctrl) => (
                        <Button onClick={ctrl.openModal} size="small">
                            {record?.invoice_detail?.inv_no}
                        </Button>
                    )}
                </OrderDetailModal>
            ),
        },
        {
            title: "Debit",
            render: (_, record) => (record?.type ? "-" : (record?.amount as number)?.ToIndCurrency("Rp")),
        },
        {
            title: "Kredit",
            render: (_, record) => (!record?.type ? "-" : (record?.amount as number)?.ToIndCurrency("Rp")),
        },
        {
            width: "150px",
            title: "Action",
            render: (_, record) => {
                return (
                    <Space size={40}>
                        <MdOutlineDeleteOutline
                            title="Delete Item"
                            onClick={() => onClickDelete(record)}
                            className="text-lg text-red-400 cursor-pointer"
                        />
                        {record.canEdit ? (
                            <FiEdit
                                title="Edit Item"
                                onClick={() => onClickEdit && onClickEdit(record)}
                                className="text-lg text-gray-600 cursor-pointer"
                            />
                        ) : null}
                    </Space>
                );
            },
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            columns={columns}
            dataSource={list?.sort((a, b) => Number(a.type) - Number(b.type)) || []}
            className="w-full"
            loading={loading}
            pagination={{ pageSize: 100 }}
            summary={
                !list?.length
                    ? undefined
                    : () => (
                          <Table.Summary fixed>
                              <Table.Summary.Row>
                                  <Table.Summary.Cell index={0} />
                                  <Table.Summary.Cell index={0} />
                                  <Table.Summary.Cell index={2} className="font-semibold">
                                      Total
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={3} className="font-semibold">
                                      {list?.reduce((a, b) => (!b?.type ? b.amount + a : a), 0)?.ToIndCurrency("Rp")}
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={4} className="font-semibold">
                                      {list?.reduce((a, b) => (b?.type ? b.amount + a : a), 0)?.ToIndCurrency("Rp")}
                                  </Table.Summary.Cell>
                              </Table.Summary.Row>
                          </Table.Summary>
                      )
            }
        />
    );
};

export default TableAddJurnalPenyesuaian;

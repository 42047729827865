import { Alert, Button, Card, Skeleton, Space, Tabs, Tag, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import TableTransferProduct from "modules/master-data/material-movement/add/table-transfer-product";
import Print from "modules/master-data/material-movement/edit/print";
import { SelectOptionCustom } from "modules/master-data/material-movement/lib/locator-select";
import { EditMM } from "modules/master-data/material-movement/models";
import { tProductTransformWithQty, tProductTransformWithQty3 } from "modules/master-data/material-movement/utils";
import React, { useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { BiSave } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import materialMovementService, {
    GetProduct2Params,
    ListProduct,
    ProductTransfer,
    ProductTransferWithEdit,
} from "services/api-endpoints/dashboard/master-data/material-movement";
import { AUTOGENERATED, MATERIAL_MOVEMENT_DRAFT, MATERIAL_MOVEMENT_ON_PREPARE, MATERIAL_MOVEMENT_STATUS, MM_COLOR_TAG } from "utils/constant";
import Versioning from "modules/master-data/material-movement/edit/versioning";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const MaterialMovementEdit = ({ paramId, asPreview }: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const status = searchParams.get("status");

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Material Movement - ${id}`,
        pageStyle: "@page {  }",
    });

    const [products, setProducts] = React.useState<ProductTransferWithEdit[]>([]);
    const [targetKeyTransfer, setTargetKeyTransfer] = React.useState<string[]>([]);

    const saveMutate = useMutation(
        [materialMovementService.edit],
        async (data: EditMM) => {
            return (await materialMovementService.Edit(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Material Movement Save");
                navigate(-1);
            },
        }
    );

    const saveDraftMutate = useMutation(
        [materialMovementService.editDraft],
        async (data: EditMM) => {
            return (await materialMovementService.EditDraft(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Material Movement Save Draft");
                navigate(-1);
            },
        }
    );

    const editCreateMutate = useMutation(
        [materialMovementService.editCreate],
        async (data: EditMM) => {
            return (await materialMovementService.EditCreate(data)).data.data;
        },
        {
            onSuccess() {
                message.success("Material Movement Save Edit");
                navigate(-1);
            },
        }
    );

    const mergeArrays = (arrayFrom: ProductTransfer[], arrayTo: ProductTransfer[]) => {
        const mergedArray: ProductTransfer[] = [];
        mergedArray.push(...arrayFrom);
        arrayTo.forEach((itemTo) => {
            const existingItemIndex = mergedArray.findIndex(
                (itemFrom) => itemTo.product_id === itemFrom.product_id && itemTo.area_id === itemFrom.area_id
            );
            if (existingItemIndex !== -1) {
                mergedArray[existingItemIndex].qty_from += itemTo.qty_to;
            } else {
                mergedArray.push(itemTo);
            }
        });
        return mergedArray;
    };

    const productMutation = useMutation(
        [materialMovementService.getProduct],
        async (data: { data1: GetProduct2Params; data2: ListProduct[] }) => {
            const res = (await materialMovementService.GetProduct2(data.data1)).data.data;
            return {
                res,
                listProduct: data.data2,
            };
        },
        {
            onSuccess(data) {
                const transformDetailToProductTransfer = data.listProduct.map((item) => {
                    const locatorFrom = item.list_locator_from ? item.list_locator_from[item!.list_locator_from.length - 1] : null;
                    const locatorTo = item.list_locator_to ? item.list_locator_to[item!.list_locator_to.length - 1] : null;
                    return {
                        id: uuid(),
                        product_id: item.product_id,
                        product_code: item.product_code,
                        product_name: item.product_name,
                        order_id: item.order_id,
                        area_id: locatorFrom?.area_id_from,
                        area_name: locatorFrom?.area_name_from,
                        locator_to: locatorTo?.area_id_to,
                        qty_to: locatorTo?.qty_to,
                        qty_from: locatorTo?.qty_to,
                    } as ProductTransfer;
                });

                const mergeAll = mergeArrays(data.res, transformDetailToProductTransfer);
                const existProduct = mergeAll.filter((el1) =>
                    data.listProduct.some((el2) => {
                        const areaFrom = el2?.list_locator_from && el2.list_locator_from[el2.list_locator_from.length - 1];
                        return el1.product_id === el2.product_id && el1.area_id === areaFrom?.area_id_from;
                    })
                );
                const keyTransfer = existProduct.map((el) => el.id as any);
                setTargetKeyTransfer(keyTransfer);
                setProducts(tProductTransformWithQty3(mergeAll, data.listProduct));
            },
        }
    );

    const detailQuery = useQuery(
        [materialMovementService.detail, id],
        async () => {
            return (await materialMovementService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                const parseData: GetProduct2Params = {
                    area_id_from: data?.area_from_id,
                    warehouse_id_from: data?.warehouse_id_from,
                    warehouse_id_to: data?.warehouse_id_to,
                    option: 2,
                };
                productMutation.mutate({ data1: parseData, data2: data.list_product || [] });
            },
        }
    );

    const locatorToQuery = useQuery(
        [materialMovementService.getLocatorTo, detailQuery.data?.warehouse_id_to],
        async () => {
            const req = await materialMovementService.GetLocatorTo({ warehouse_id_to: detailQuery.data?.warehouse_id_to });
            return req.data.data?.map((el) => ({ ...el, label: el?.area_name || "", value: el.area_id_to || "" } as SelectOptionCustom));
        },
        {
            enabled: !!detailQuery.data?.warehouse_id_to,
        }
    );

    const prepareSave = () => {
        const filteredProduct = products?.filter((p) => targetKeyTransfer.includes(p.id as any));

        if (!filteredProduct.length) {
            message.error("Select products first");
            throw new Error("Select products first");
        }

        const parseData: EditMM = {
            id: id as any,
            area_from_id: detailQuery.data?.area_from_id,
            option: detailQuery.data?.option,
            order_id: null,
            product: filteredProduct?.map((product) => ({
                order_id: 0,
                product_id: product?.product_id,
                locator_from: [{ area_id_from: product?.area_id, qty_from: product?.qty_from }],
                locator_to: [
                    {
                        area_id_to:
                            locatorToQuery.data?.find((loc) => loc.label === product?.locator_to || loc.value === product?.locator_to)?.value || "",
                        qty_to: product?.qty_to,
                    },
                ],
            })),
        };

        const emptyLoc = parseData.product?.reverse()?.filter((product) => {
            const filter = product.locator_to?.filter((loc) => {
                if (!loc.area_id_to) {
                    message.error(`Locator to can't be empty on product ${products?.find((p) => p.product_id === product.product_id)?.product_name}`);
                    return loc;
                }
                return null;
            });
            if (filter?.length) return filter;
            return null;
        });

        if (emptyLoc?.length) throw new Error(`Locator to can't be empty on product`);

        return parseData;
    };

    const onSaveHandler = async () => {
        try {
            const editData = prepareSave();
            saveMutate.mutate(editData);
        } catch (e: any) {
            console.log(e?.message);
        }
    };

    const onDraftHandler = async () => {
        try {
            const editData = prepareSave();
            saveDraftMutate.mutate(editData);
        } catch (e: any) {
            console.log(e?.message);
        }
    };

    const onEditCreateHandler = async () => {
        try {
            const editData = prepareSave();
            editCreateMutate.mutate(editData);
        } catch (e: any) {
            console.log(e?.message);
        }
    };

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const titles = [detailQuery.data?.warehouse_from || "", detailQuery.data?.warehouse_to || ""];
    const type = MATERIAL_MOVEMENT_STATUS.find((t) => t.value === Number(status));

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} title="Material Movement" />
            <ToolbarAction
                title="edit material movement"
                rightAddition={() => (
                    <Space>
                        {status && Number(status) === MATERIAL_MOVEMENT_DRAFT && (
                            <Button loading={saveDraftMutate.isLoading} onClick={onDraftHandler} className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Draft
                            </Button>
                        )}
                        {status && Number(status) === MATERIAL_MOVEMENT_DRAFT && (
                            <Button loading={saveMutate.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Save
                            </Button>
                        )}
                        {status && Number(status) === MATERIAL_MOVEMENT_ON_PREPARE && (
                            <Button loading={saveMutate.isLoading} onClick={onEditCreateHandler} type="primary" className="!flex !items-center">
                                <BiSave className="m-0 mr-2" />
                                Save Edit
                            </Button>
                        )}
                        {status && Number(status) !== MATERIAL_MOVEMENT_DRAFT && (
                            <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                                <AiFillPrinter className="m-0 mr-2" />
                                Print
                            </Button>
                        )}
                    </Space>
                )}
            />
            <Card>
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <p className="capitalize m-0">
                                        Material Movement No <br />
                                        <span className="italic m-0">{detailQuery.data?.mm_no || AUTOGENERATED}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse From <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_from}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Warehouse To <br />
                                        <span className="underline italic m-0">{detailQuery.data?.warehouse_to}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Status <br />
                                        <Tag color={MM_COLOR_TAG[type?.value || 0]}>{type?.label}</Tag>
                                    </p>
                                    <p className="capitalize m-0">
                                        Options <br />
                                        <span className="underline italic m-0">Warehouse Stock</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Locator From <br />
                                        <span className="italic m-0">{detailQuery.data?.area_from || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note}</span>
                                    </p>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 4 }} />
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: (
                                <TableTransferProduct
                                    titles={titles}
                                    products={products}
                                    setProducts={setProducts}
                                    locatorFetcher={locatorToQuery}
                                    setTargetKeys={setTargetKeyTransfer}
                                    targetKeys={targetKeyTransfer}
                                    loading={productMutation.isLoading || locatorToQuery.isLoading}
                                />
                            ),
                        },
                        {
                            label: `Versioning History`,
                            key: "2",
                            children: <Versioning id={id} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default MaterialMovementEdit;

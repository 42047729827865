import { Alert, message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterSalesOrder from "modules/dashboard-jsl/sales-order/index/filter";
import { TDataSalesOrder } from "modules/dashboard-jsl/sales-order/models";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import salesOrderService from "services/api-endpoints/dashboard-jsl/sales-order";
import pickingListService from "services/api-endpoints/dashboard-jsl/picking-list";
import PickingListTable from "modules/dashboard-jsl/picking-list/index/table";

const filter = ["purchase_order_no", "sales_order_no", "invoice_no", "request_to", "start_date", "end_date", "status"];

const PickingList = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([pickingListService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        // if (isSearch) return (await salesOrderService.Search({ page, param_search: searchValue.query })).data.data;
        // if (isFilter) return (await salesOrderService.Filter(filterValue as any)).data.data;
        return (await pickingListService.GetAll({ page })).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataSalesOrder) => (await salesOrderService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataSalesOrder>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterSalesOrder filter={filter}>
                {(dt) => <Toolbar placeholder="PL No / Inv No / Request No" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterSalesOrder>
            <PickingListTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default PickingList;

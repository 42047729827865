import { Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessDelete from "components/button/button-access-delete";
import ButtonAccessEdit from "components/button/button-access-edit";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BaseSupplies, SuppliesList } from "services/api-endpoints/purchasing/supplies";
import { EDIT_PATH, STATUS_ACTIVE_3 } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<BaseSupplies<T>, unknown>;
    onClickDelete: (data: T) => void;
};

const SuppliesTable = <T extends SuppliesList>({ fetcher, onClickDelete }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const editHandler = (data: SuppliesList) => {
        navigate(`${location.pathname + EDIT_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Code",
            dataIndex: "code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Description",
            dataIndex: "description",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Type Name",
            dataIndex: "type_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Status",
            dataIndex: "is_active",
            render: (text) => <p className="capitalize m-0">{STATUS_ACTIVE_3.find((el) => el.value === text)?.label}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => (
                <Space size="middle" direction="horizontal">
                    <>
                        <ButtonAccessEdit onClick={() => editHandler(record)} type="text" />
                        <ButtonAccessDelete onClick={() => onClickDelete(record)} type="text" danger />
                    </>
                </Space>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data?.list || []}
            className="w-full"
            pagination={{
                pageSize: 1000,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default SuppliesTable;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Select } from "antd";
import { SelectOption } from "components/form/inputs/input-select";
import LocatorModal from "modules/master-data/stock/modal-locator";
import { AiOutlineSearch } from "react-icons/ai";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import priceService from "services/api-endpoints/dashboard/master-data/price";
import quantityService from "services/api-endpoints/dashboard/master-data/quantity";
import { VIEW_PATH } from "utils/constant";

const StockLocator = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouse = searchParams.get("warehouse_id");
    const locators = searchParams.get("locators");

    const changeWarehouse = ({ id }: { id: any }) => {
        searchParams.set("warehouse_id", id);
        searchParams.set("locators", "");
        setSearchParams(searchParams);
    };

    const changeLocators = (values: number[]) => {
        searchParams.set("locators", values.join(","));
        setSearchParams(searchParams);
    };

    const getWarehouse = useQuery(
        [priceService.warehouse, warehouse],
        async () => {
            return (await priceService.Warehouse()).data.data?.map((el) => ({ label: el.warehouse_name, value: el.warehouse_id } as SelectOption));
        },
        {
            enabled: warehouse !== undefined,
        }
    );

    const areaQuery = useQuery(
        [quantityService.allArea, warehouse],
        async () => {
            const req = await quantityService.AllArea({ warehouse_id: warehouse });
            return req.data.data?.map((el) => ({ label: el.area_code, value: el.area_id } as SelectOption));
        },
        {
            enabled: !!warehouse,
        }
    );

    const viewHandler = () => {
        navigate(location.pathname + VIEW_PATH + location.search);
    };

    return (
        <div className="flex flex-col gap-6 items-center justify-center w-full">
            <div className="w-[700px] rounded-2xl bg-white border border-solid border-gray-300 p-7 flex flex-col gap-5">
                <label htmlFor="warehouse" className="flex flex-col gap-2">
                    Warehouse Name
                    <Select
                        id="warehouse"
                        showSearch
                        value={warehouse ? Number(warehouse) : undefined}
                        loading={getWarehouse.isLoading || getWarehouse.isRefetching}
                        placeholder="Warehouse"
                        options={getWarehouse.data || []}
                        onChange={(val) => changeWarehouse({ id: val })}
                    />
                </label>
                <div className="flex flex-col gap-0">
                    <div className="w-full flex !items-center">
                        <p className="mt-3 mr-2">Locator</p>
                        <LocatorModal dataArea={areaQuery.data}>
                            {(dt) => (
                                <Button shape="circle" type="primary" className="!flex !items-center" onClick={dt.openModal} disabled={!warehouse}>
                                    <AiOutlineSearch className="m-2" />
                                </Button>
                            )}
                        </LocatorModal>
                    </div>
                    <Select
                        value={locators ? locators.split(",").map(Number) : undefined}
                        showSearch
                        id="exclude-locator"
                        mode="multiple"
                        allowClear
                        placeholder="Locator"
                        onChange={changeLocators}
                        optionFilterProp="label"
                        options={areaQuery.data}
                    />
                </div>
                <div className="w-full flex justify-end">
                    <Button disabled={!warehouse || !locators} onClick={viewHandler} className="!flex !items-center w-fit" type="primary">
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StockLocator;

import { Alert, Card, Segmented, Skeleton } from "antd";
import { ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import State from "components/common/state";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { IoCartOutline } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { ProductCategoryData } from "services/api-endpoints/dashboard-giias/report";
import Utils from "utils";
import { TYPE_POS_OFFLINE } from "utils/constant";

export const dataDefault = {
    labels: [],
    datasets: [],
};

const options: ChartOptions<"bar"> = {
    indexAxis: "y" as const,
    responsive: true,
};

const colors = ["#04bfbf", "#acf0f2", "#0eeaff", "#59d8e6", "#59d8e6", "#00abd8", "#0eeaff", "#04bfbf", "#7ecefd", "#acf0f2"];

const ProductCategory = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;
    const [chartData, setChartData] = useState<{ product: any; invoice: any }>({
        product: dataDefault,
        invoice: dataDefault,
    });
    const [optionsBar, setOptionsBar] = useState({
        product: options,
        invoice: options,
    });
    const [segmentChart, setSegmentChart] = useState<"invoice" | "product">("product");

    const watchParams = [startDate, endDate, typeSales];

    const splitOptions = (data: ProductCategoryData[]) => {
        const templateOpt: ChartOptions<"bar"> = {
            ...options,
            scales: {
                ...options.scales,
                actual: {
                    beginAtZero: true,
                    position: "left",
                    ticks: {
                        callback(val, index, _) {
                            return data[index].target_sales_name;
                        },
                        color: "#acadac",
                    },
                    stacked: true,
                },
                target: {
                    beginAtZero: true,
                    position: "right",
                    stacked: true,
                },
            },
        };

        setOptionsBar((prev) => ({
            invoice: {
                ...templateOpt,
                scales: {
                    ...templateOpt.scales,
                    target: {
                        ...templateOpt.scales!.target,
                        ticks: {
                            callback(val, index, _) {
                                return `${data[index].target_invoice} invoice`;
                            },
                            color: "#acadac",
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        filter: (item) => {
                            return item.datasetIndex === 0;
                        },
                        callbacks: {
                            label: (item) => {
                                if (!item?.raw) return "";
                                return `Tercapai ${(item.raw as ProductCategoryData)?.percentage_invoice}%`;
                            },
                            title: (item) => {
                                if (!item[0]?.raw) return "";
                                return (item[0].raw as ProductCategoryData)?.target_sales_name;
                            },
                            footer(item) {
                                if (!item[0]?.raw) return "";
                                return `${(item[0].raw as ProductCategoryData)?.actual_invoice} invoice`;
                            },
                        },
                    },
                },
            },
            product: {
                ...templateOpt,
                scales: {
                    ...templateOpt.scales,
                    target: {
                        ...templateOpt.scales!.target,
                        ticks: {
                            callback(val, index, _) {
                                return `Rp.${Utils.convertToStringFormat(data[index].target_price)}`;
                            },
                            color: "#acadac",
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        filter: (item) => {
                            return item.datasetIndex === 0;
                        },
                        callbacks: {
                            label: (item) => {
                                if (!item?.raw) return "";
                                return `Tercapai ${(item.raw as ProductCategoryData)?.percentage_price}%`;
                            },
                            title: (item) => {
                                if (!item[0]?.raw) return "";
                                return (item[0].raw as ProductCategoryData)?.target_sales_name;
                            },
                            footer(item) {
                                if (!item[0]?.raw) return "";
                                return `Rp. ${Utils.convertToStringFormat((item[0].raw as ProductCategoryData)?.actual_price)}`;
                            },
                        },
                    },
                },
            },
        }));
    };

    const splitData = (data: ProductCategoryData[]) => {
        const templateData = {
            labels: data.map((el) => el.target_sales_name),
            datasets: [
                {
                    data,
                    yAxisID: "actual",
                    backgroundColor: colors,
                    datalabels: {
                        display: true,
                        anchor: "start",
                        align: "end",
                        labels: {
                            title: {
                                font: {
                                    weight: "lighter",
                                },
                            },
                            value: {
                                color: "black",
                            },
                        },
                    },
                },
                {
                    data: data.map((el) => ({ ...el, max_percent: 100 })),
                    backgroundColor: "#dee0df",
                    yAxisID: "target",
                    tooltip: false,
                    datalabels: {
                        display: false,
                    },
                },
            ],
        };
        setChartData(() => ({
            product: {
                ...templateData,
                datasets: templateData.datasets.map((dtsets) => {
                    if (dtsets.yAxisID === "actual") {
                        return {
                            ...dtsets,
                            parsing: {
                                xAxisKey: "percentage_price",
                                yAxisKey: "percentage_price",
                            },
                            datalabels: {
                                ...dtsets.datalabels,
                                formatter(value: any) {
                                    return `Rp.${Utils.convertToStringFormat(value.actual_price)}`;
                                },
                            },
                        };
                    }
                    return {
                        ...dtsets,
                        parsing: {
                            xAxisKey: "max_percent",
                            yAxisKey: "target_invoice",
                        },
                    };
                }),
            },
            invoice: {
                ...templateData,
                datasets: templateData.datasets.map((dtsets) => {
                    if (dtsets.yAxisID === "actual") {
                        return {
                            ...dtsets,
                            parsing: {
                                xAxisKey: "percentage_invoice",
                                yAxisKey: "percentage_invoice",
                            },
                            datalabels: {
                                ...dtsets.datalabels,
                                formatter(value: any) {
                                    return `${value.actual_invoice} invoice`;
                                },
                            },
                        };
                    }
                    return {
                        ...dtsets,
                        parsing: {
                            xAxisKey: "max_percent",
                            yAxisKey: "target_price",
                        },
                    };
                }),
            },
        }));
    };

    const productCategoryQuery = useQuery(
        [reportService.productCategory, ...watchParams],
        async () => {
            return (await reportService.ProductCategory({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
        },
        {
            onSuccess(data) {
                if (!data) return;
                splitOptions(data!);
                splitData(data!);
            },
        }
    );

    return (
        <Card className="h-full">
            <State data={productCategoryQuery.data} isLoading={productCategoryQuery.isLoading} isError={productCategoryQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <div className="flex justify-between items-center">
                                <Segmented
                                    value={segmentChart}
                                    onChange={(val) => setSegmentChart(val as typeof segmentChart)}
                                    options={[
                                        { label: "Product", value: "product", icon: <IoCartOutline /> },
                                        { label: "Invoice", value: "invoice", icon: <TbFileInvoice /> },
                                    ]}
                                />
                                <p className="flex-1 m-4 text-center font-light text-xl text-gray-500 capitalize">
                                    Actual <span className="font-semibold">vs</span> Target
                                </p>
                                <span className="flex-1" />
                            </div>
                            <Bar
                                key={segmentChart}
                                plugins={[ChartDataLabels]}
                                data={segmentChart === "product" ? chartData.product : chartData.invoice}
                                options={segmentChart === "product" ? optionsBar.product : optionsBar.invoice}
                            />
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 3 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(productCategoryQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </Card>
    );
};

export default ProductCategory;

import { DatePicker, Input, Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingRequestService from "services/api-endpoints/purchasing/purchasing-request";
import suppliesService from "services/api-endpoints/purchasing/supplies";
import {
    DOC_TYPE_INVENTORY,
    DOC_TYPE_PURCHASING,
    DOC_TYPE_SUPPLIES,
    FORMAT_DATE_1,
    STATUS_PURCHASING_REQUEST,
    TYPE_ITEM_INVENTORY,
} from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterPurchasingRequest = ({ filter = [], ...props }: Props) => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get("itemType");

    const products = useMutation([purchasingRequestService.getProduct], async (itemType: any) => {
        return (
            await purchasingRequestService.GetProduct({
                type: itemType,
                pr_type: DOC_TYPE_INVENTORY,
            })
        ).data.data.map((el) => ({ label: `${el.product_name} - ${el.product_code1}`, value: el.product_id } as SelectOption));
    });

    const getCreatedBy = useQuery([purchasingRequestService.createdBy], async () => {
        return (await purchasingRequestService.CreatedBy()).data.data?.map(
            (el) => ({ label: `${el.full_name} - ${el.email}`, value: el.created_by } as SelectOption)
        );
    });

    const getTypeSupplies = useQuery([suppliesService.getSuppliesType], async () => {
        return (await suppliesService.GetSuppliesType()).data.data?.map((el) => ({ label: el.type_name, value: el.type_id } as SelectOption));
    });

    const getSupplies = useMutation([suppliesService.getNameSupplies], async (itemType: any) => {
        return (await suppliesService.GetName({ type: itemType })).data.data.map(
            (el) => ({ label: `${el.name} - ${el.code}`, value: el.supplies_id } as SelectOption)
        );
    });

    useEffect(() => {
        products.mutate(type);
    }, [type]);

    return (
        <FilterModal
            filter={filter}
            title="Filter Purchasing Request"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-3 gap-4">
                        <div className="w-full">
                            <p>Date Range</p>
                            <DatePicker.RangePicker
                                className="w-full"
                                value={[
                                    dt.values.start_date ? moment(dt.values.start_date, FORMAT_DATE_1) : null,
                                    dt.values.end_date ? moment(dt.values.end_date, FORMAT_DATE_1) : null,
                                ]}
                                placeholder={["Start Date", "End Date"]}
                                onChange={(val) => {
                                    dt.setOneValue("start_date", val?.[0]?.format(FORMAT_DATE_1));
                                    dt.setOneValue("end_date", val?.[1]?.format(FORMAT_DATE_1));
                                }}
                            />
                        </div>
                        <div className="w-full col-span-2">
                            <p>Notes</p>
                            <Input
                                className="w-full"
                                value={dt.values.note ? dt.values.note : null}
                                allowClear
                                placeholder="Note"
                                onChange={(val) => {
                                    dt.setOneValue("note", val.target.value);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <p>Status</p>
                            <Select
                                className="w-full"
                                allowClear
                                value={dt.values.status ? dt.values.status : null}
                                placeholder="Status"
                                options={STATUS_PURCHASING_REQUEST}
                                onChange={(val) => {
                                    dt.setOneValue("status", val);
                                }}
                            />
                        </div>
                        <div className="w-full col-span-2">
                            <p>Request By</p>
                            <Select
                                className="w-full"
                                showSearch
                                allowClear
                                value={dt.values.created_by ? dt.values.created_by : null}
                                placeholder="Request By"
                                options={getCreatedBy.data}
                                loading={getCreatedBy.isLoading}
                                optionFilterProp="label"
                                filterOption={(input, option) => {
                                    return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                }}
                                onChange={(val) => {
                                    dt.setOneValue("created_by", val);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <p>Purchasing Category</p>
                            <Select
                                className="w-full"
                                allowClear
                                value={dt.values.docType ? dt.values.docType : null}
                                placeholder="Purchasing Category"
                                options={DOC_TYPE_PURCHASING}
                                onChange={(val) => {
                                    dt.setOneValue("docType", val);
                                    dt.setOneValue("itemType", undefined);
                                    dt.setOneValue("product_id", undefined);
                                }}
                            />
                        </div>
                        {dt.values.docType === DOC_TYPE_INVENTORY && (
                            <>
                                <div className="w-full">
                                    <p>Type Item Inventory</p>
                                    <Select
                                        className="w-full"
                                        allowClear
                                        value={dt.values.itemType ? dt.values.itemType : null}
                                        placeholder="Type Item Inventory"
                                        options={TYPE_ITEM_INVENTORY}
                                        onChange={(val) => {
                                            dt.setOneValue("itemType", val);
                                            products.mutate(val);
                                        }}
                                    />
                                </div>
                                <div className="w-full col-span-3">
                                    <p>Item Name</p>
                                    <Select
                                        className="w-full"
                                        showSearch
                                        allowClear
                                        value={dt.values.product_id ? dt.values.product_id : null}
                                        placeholder="Item Name"
                                        options={products.data}
                                        loading={products.isLoading}
                                        disabled={!dt.values.itemType}
                                        optionFilterProp="label"
                                        filterOption={(input, option) => {
                                            return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                        }}
                                        onChange={(val) => {
                                            dt.setOneValue("product_id", val);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {dt.values.docType === DOC_TYPE_SUPPLIES && (
                            <>
                                <div className="w-full">
                                    <p>Type Item Supplies</p>
                                    <Select
                                        className="w-full"
                                        allowClear
                                        value={dt.values.itemType ? dt.values.itemType : null}
                                        placeholder="Type Item Supplies"
                                        options={getTypeSupplies.data}
                                        onChange={(val) => {
                                            dt.setOneValue("itemType", val);
                                            getSupplies.mutate(val);
                                        }}
                                    />
                                </div>
                                <div className="w-full col-span-3">
                                    <p>Item Name</p>
                                    <Select
                                        className="w-full"
                                        showSearch
                                        allowClear
                                        value={dt.values.product_id ? dt.values.product_id : null}
                                        placeholder="Item Name"
                                        options={getSupplies.data}
                                        loading={getSupplies.isLoading}
                                        disabled={!dt.values.itemType}
                                        optionFilterProp="label"
                                        filterOption={(input, option) => {
                                            return (option?.label ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                                        }}
                                        onChange={(val) => {
                                            dt.setOneValue("product_id", val);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterPurchasingRequest;

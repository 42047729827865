import { Button, Checkbox, InputNumber, Popover, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { DocReqChild } from "pages/purchasing-inventory/view";
import React, { useContext, useEffect, useState } from "react";
import { DetailVendor, InventoryItem } from "services/api-endpoints/purchasing/inventory";
import { CustomVoucherList } from "../models";

type SelectVendorProps = {
    columnIndex: number;
    vendor?: number;
    context: React.Context<any>;
    disable?: boolean;
};

const SelectVendor = ({ columnIndex, vendor, context, disable, ...props }: SelectVendorProps) => {
    const { pickedVendor, setPickedVendor, setDocReqChild, getVendor, docReqChild } = useContext(context);
    const [value, setValue] = useState<any>(vendor);
    const vendors = (getVendor?.data || []) as any[];

    useEffect(() => {
        setValue(vendor);
    }, [vendor]);

    const onChange = (v: number) => {
        if (!v && pickedVendor === vendor) {
            setPickedVendor(null);
        }
        setValue(v);
        setDocReqChild((prev: DocReqChild[]) =>
            prev?.map((child) => ({
                ...child,
                vendors: {
                    ...child?.vendors,
                    [columnIndex]: {
                        ...(child?.vendors ? child.vendors[columnIndex] || {} : {}),
                        vendor_id: v,
                    },
                },
            }))
        );
    };

    const onChangeCheckbox = (e: any) => {
        setPickedVendor(e.target.checked ? vendor : null);
    };

    const alreadyPickedVendor = Object.keys(docReqChild?.length && docReqChild[0] ? docReqChild[0]?.vendors || {} : {})
        .filter((key) => docReqChild[0]?.vendors[key]?.vendor_id)
        .map((key) => docReqChild[0]?.vendors[key]?.vendor_id);

    const availableVendor = vendors?.map((vdr) => ({ ...vdr, disabled: alreadyPickedVendor.includes(vdr.value) && vdr.value !== vendor }));

    return (
        <div className="flex items-center gap-x-4">
            <Select
                value={value}
                options={availableVendor}
                showSearch
                allowClear
                optionFilterProp="label"
                className="w-full"
                placeholder="Pick Vendor"
                onChange={onChange}
                disabled
                {...props}
            />
            {value ? <Checkbox checked={pickedVendor === vendor} onChange={onChangeCheckbox} disabled /> : null}
        </div>
    );
};

type InputPriceProps = {
    record: DocReqChild;
    columnIndex: number;
    context: React.Context<any>;
    disable?: boolean;
};

const InputPrice = ({ record, columnIndex, disable, context }: InputPriceProps) => {
    const { setDocReqChild, problemChild } = useContext(context);
    const [value, setValue] = useState<any>();

    useEffect(() => {
        const price = record?.vendors ? record.vendors[columnIndex]?.price : null;
        setValue(price);
    }, []);

    const onChange = (price: any) => {
        setDocReqChild((prev: DocReqChild[]) =>
            prev?.map((child) => {
                if (child?.product_id !== record?.product_id) return child;
                return {
                    ...child,
                    vendors: {
                        ...child?.vendors,
                        [columnIndex]: {
                            ...(child?.vendors ? child.vendors[columnIndex] || {} : {}),
                            price,
                        },
                    },
                };
            })
        );
        setValue(price);
    };

    const isProblem = problemChild.find(
        (child: InventoryItem & { column: number }) => child.product_id === record.product_id && columnIndex === Number(child.column) && !child.price
    );

    return (
        <InputNumber
            type="number"
            value={value}
            status={isProblem ? "error" : undefined}
            placeholder="Input Price"
            onChange={onChange}
            className="!w-full"
            disabled
        />
    );
};

const TenderPurchasing = ({ context }: { context: React.Context<any> }) => {
    const maxVendor = 4;
    const { docReqChild, pickedVendor, listVendor, listVoucher } = useContext(context);

    const columns: ColumnsType<DocReqChild> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t, r, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Referensi",
            render: (_, record) => {
                if (!record.note) return "-";
                const note = record.note || "";
                const title = note.length > 50 ? `${note.substring(0, 50)}...` : note;
                return (
                    <Popover
                        title="Link Referensi"
                        trigger="click"
                        content={
                            <div className="w-full">
                                <a href={note} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </div>
                        }
                    >
                        <Button>Link Ref</Button>
                    </Popover>
                );
            },
        },
        {
            title: "Type Item",
            dataIndex: "type_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        ...Array.from({ length: listVendor.length }).map((e, index) => {
            const vendor = (() => {
                if (!docReqChild?.length || !docReqChild[0]?.vendors || !docReqChild[0]?.vendors[index]) return null;
                return docReqChild[0]?.vendors[index]?.vendor_id;
            })();

            const priceTotal = (child: DocReqChild) => {
                const voucher: CustomVoucherList = listVoucher.find((val: CustomVoucherList) => val.product_id === child.product_id);
                const discountPrice = voucher ? voucher.discount_price : 0;
                const discount1an = (discountPrice || 0) / child.qty;
                return (child?.vendors ? child.vendors[index]?.price || 0 : 0) - discount1an;
            };

            const isVoucher = (child: DocReqChild) => {
                return listVoucher.find((val: CustomVoucherList) => val.product_id === child.product_id);
            };

            return {
                title: () => (
                    <p className={pickedVendor === vendor ? "text-gray-100 m-0 w-full" : "text-gray-600 m-0 w-full"}>
                        {pickedVendor === vendor ? <Checkbox className="items-end" checked disabled /> : null}
                        {"  "}
                        {listVendor.find((val: DetailVendor) => val.vendor_id === vendor)?.vendor_name || ""}
                    </p>
                ),
                className: pickedVendor && pickedVendor !== vendor ? "!bg-[#f5f5f5]" : "",
                render: (_: any, record: DocReqChild) => (
                    <Space>
                        <p className={pickedVendor === vendor ? "capitalize m-0 font-semibold" : "capitalize m-0"}>
                            {priceTotal(record).ToIndCurrency("Rp")}
                        </p>
                        {pickedVendor === vendor && isVoucher(record) && (
                            <p className="!w-full text-red-500 text-xs m-0 mb-1">
                                <del>{(record?.vendors ? record.vendors[index]?.price || 0 : 0).ToIndCurrency("Rp")}</del>
                            </p>
                        )}
                    </Space>
                ),
            };
        }),
    ];

    return <Table rowKey={(i) => i.product_id} size="small" columns={columns} dataSource={docReqChild} className="w-full" pagination={undefined} />;
};

export default TenderPurchasing;

import { Button, Card, Tabs, message } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import JurnalProvider from "modules/accounting/jurnal/context/jurnal";
import FilterJurnalPenjualan from "modules/accounting/jurnal/index/filter-penjualan";
import PostedTable from "modules/accounting/jurnal/index/table-posted";
import UnpostedTable from "modules/accounting/jurnal/index/table-unposted";
import { useState } from "react";
import { IoRefresh } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import jurnalUmumService, { FilterJurnalUmum, UpdateJournalData } from "services/api-endpoints/accounting/jurnal-umum";
import { TYPE_JOURNAL_ACCOUNTING, TYPE_JURNAL_PENJUALAN } from "utils/constant";

const filter = ["start_date", "end_date", "type", "account_id"];

const JurnalPenjualan = () => {
    const [keyTab, setKeyTab] = useState("1");

    const { isSearch, searchValue, filterValue2 } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [jurnalUmumService.filter, filterValue2, isSearch, searchValue],
        async () => {
            if (isSearch)
                return (
                    await jurnalUmumService.Filter({
                        ...({ query: searchValue.query, start_date: "", end_date: "", type: TYPE_JURNAL_PENJUALAN } as FilterJurnalUmum),
                    })
                ).data.data;
            return (
                await jurnalUmumService.Filter({
                    ...({ ...filterValue2, query: "" } as FilterJurnalUmum),
                })
            ).data.data;
        },
        {
            onSuccess: (data) => {
                if (data.list_unposted.length !== 0) {
                    setKeyTab("1");
                } else if (data.list_posted.length !== 0) {
                    setKeyTab("2");
                }
            },
        }
    );

    const updateStatusJournal = useMutation(
        [jurnalUmumService.updateStatusJournal],
        async (data: UpdateJournalData) => {
            return (await jurnalUmumService.UpdateStatusJournal(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Success Post Journal");
                getList.refetch();
            },
        }
    );

    const onPosJournal = (data: any[]) => {
        const dataFilter = data.filter((el) => el.is_posting);
        const parseData: UpdateJournalData = {
            type: TYPE_JOURNAL_ACCOUNTING,
            doc_id: dataFilter?.map((itm) => itm.id as any),
        };

        updateStatusJournal.mutateAsync(parseData);
    };

    const onRefresh = () => {
        getList.refetch();
        message.success("Refresh Journal");
    };

    const onChangeTab = (key: string) => {
        setKeyTab(key);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterJurnalPenjualan title="Jurnal Penjualan" filter={filter}>
                {(dt) => (
                    <Toolbar
                        add={false}
                        placeholder="Search Invoice Referensi..."
                        filterHandler={dt.openModal}
                        onSearchHandler={dt.onClickClearHandler}
                    />
                )}
            </FilterJurnalPenjualan>
            <Card>
                <Tabs
                    tabBarExtraContent={
                        <Button className="w-fit" onClick={onRefresh}>
                            <IoRefresh className="mr-3" />
                            Refresh
                        </Button>
                    }
                    defaultActiveKey="1"
                    activeKey={keyTab}
                    onChange={onChangeTab}
                    items={[
                        {
                            label: `Unposted`,
                            key: "1",
                            children: (
                                <JurnalProvider>
                                    <UnpostedTable fetcher={getList} onPosJournal={onPosJournal} typeJurnal={TYPE_JURNAL_PENJUALAN} />
                                </JurnalProvider>
                            ),
                        },
                        {
                            label: `Posted`,
                            key: "2",
                            children: <PostedTable fetcher={getList} typeJurnal={TYPE_JURNAL_PENJUALAN} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default JurnalPenjualan;

import { Button, Card, Form, message, Space } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ToolbarAction from "components/toolbar/action";
import { BiSave } from "react-icons/bi";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import IsActiveSelect from "modules/master-data/sub-category/lib/is-active-select";
import ImageCard from "components/card/image";
import useControlFile from "hooks/useControlFile";
import { useMutation, useQuery } from "react-query";
import useProgressUpload from "hooks/useProgressUpload";
import subCategoryService from "services/api-endpoints/dashboard/master-data/sub-category";
import { CreateDataSubCategory } from "modules/master-data/sub-category/models";
import IsPopularSelect from "modules/master-data/sub-category/lib/is-popular-select";
import InputFile from "components/form/inputs/input-file";
import { IMAGE_TYPES } from "utils/constant";
import CategorySelect from "modules/master-data/category/lib/categoryselect";
import { DetailDataCategory, InfoDataCategory, CreateDataCategory } from "modules/master-data/category/models";
import categoryService from "services/api-endpoints/dashboard/master-data/category";
import State from "components/common/state";
import { SubCategoryData } from "models";
import CategorySuggestSelect from "modules/master-data/category/lib/categorysuggestselect";

const schema: yup.SchemaOf<Partial<CreateDataSubCategory>> = yup.object().shape({
    category_id: yup.number().required("Category required"),
    category_name: yup.string(),
    category_code: yup.string().required("Category code required"),
    category_status: yup.number().required("Category status required"),
    subcategory_name: yup.string().required("Sub Category name required"),
    subcategory_code: yup.string().required("Sub Category code required"),
    subcategory_image: yup.string().required("Sub Category Image required"),
    is_popular: yup.number().required("Is Popular required"),
    is_active: yup.number().required("Is Active required"),
});

const SubcategoryAdd = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [progress, onUploadProgress] = useProgressUpload();
    const [searchParams] = useSearchParams();
    const [info, setInfo] = useState([] as InfoDataCategory[]);
    const [subCategory, setSubCategory] = useState([] as SubCategoryData[]);
    const [sub, setSub] = useState({} as SubCategoryData);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<CreateDataSubCategory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const [controlFile] = useControlFile<CreateDataSubCategory>({
        setValue,
        errors: errors as any,
    });

    const createMutation = useMutation(
        [subCategoryService.create],
        async (data: CreateDataSubCategory) => {
            return (await subCategoryService.Create(data, { onUploadProgress })).data.data;
        },
        {
            onSuccess: () => {
                message.success("Category created");
                navigate(-1);
            },
        }
    );

    const infoCategory = useQuery(
        [categoryService.info],
        async () => {
            return (await categoryService.Info()).data.data;
        },
        {
            onSuccess: (data) => {
                setInfo(data as []);
            },
        }
    );

    const suggestCategory = useMutation(
        [subCategoryService.suggestByCategoryName],
        async (name: string) => {
            return (await subCategoryService.SuggestByCategoryName({ category_name: name })).data.data;
        },
        {
            onSuccess: (data) => {
                setSubCategory(data as []);
            },
        }
    );

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onSubmitHandler = handleSubmit((data) => {
        createMutation.mutate(data);
    });

    const handleChange = (value: string) => {
        const cat = info.filter((item) => item.category_name === value);
        setSub({});
        setValue("category_code", cat[0].category_code);
        suggestCategory.mutate(cat[0].category_name);
    };

    const handleSuggest = (value: string) => {
        const tempSub = subCategory.filter((item) => item.category_type === value);
        setSub(tempSub[0]);
        setValue("category_id", tempSub[0].id);
        setValue("category_status", Number(tempSub[0].category_status));
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new sub category"
                rightAddition={() => (
                    <Space>
                        <>{progress}</>
                        <Button loading={createMutation.isLoading} onClick={onSaveHandler} type="primary" className="!flex !items-center">
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </Space>
                )}
            />
            <Card>
                <State data={infoCategory.data} isLoading={infoCategory.isLoading} isError={infoCategory.isError}>
                    {(state) => (
                        <>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="grid grid-cols-2 gap-4">
                                    <InputFile control={controlFile} types={IMAGE_TYPES} name="subcategory_image" className="col-span-2">
                                        {(dt) => <ImageCard image={dt.file} title="header image" />}
                                    </InputFile>
                                </div>
                                <Form form={form} disabled={createMutation.isLoading} layout="vertical" onFinish={onSubmitHandler}>
                                    <h1 className="capitalize font-semibold text-gray-500 mb-4">sub category information</h1>
                                    <ControlledInputText
                                        control={control}
                                        label="Sub Category Name"
                                        labelCol={{ span: 24 }}
                                        name="subcategory_name"
                                        placeholder="Sub Category Name"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <ControlledInputText
                                        control={control}
                                        label="Sub Category Code"
                                        labelCol={{ span: 24 }}
                                        name="subcategory_code"
                                        placeholder="Sub Category Code"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <IsPopularSelect
                                        control={control}
                                        label="Is Popular"
                                        labelCol={{ span: 24 }}
                                        name="is_popular"
                                        placeholder="Is Popular"
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <IsActiveSelect
                                        control={control}
                                        label="Is Active"
                                        labelCol={{ span: 24 }}
                                        name="is_active"
                                        placeholder="Is Active"
                                        style={{ transform: "translateY(-6px)" }}
                                    />

                                    <h1 className="capitalize font-semibold text-gray-500 mb-4">category information</h1>
                                    <p className="capitalize text-black mb-0">category name</p>
                                    <CategorySelect handleChange={handleChange} info={info} />
                                    <ControlledInputText
                                        control={control}
                                        label="Category Code"
                                        labelCol={{ span: 24 }}
                                        name="category_code"
                                        placeholder="Category Code"
                                        disabled
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                    <p className="capitalize text-black mb-0">category type</p>
                                    <CategorySuggestSelect handleChange={handleSuggest} data={subCategory} value={sub.category_type} />
                                    <IsActiveSelect
                                        control={control}
                                        label="Category Is Active"
                                        labelCol={{ span: 24 }}
                                        name="category_status"
                                        placeholder="Category Is Active"
                                        disabled
                                        style={{ transform: "translateY(-6px)" }}
                                    />
                                </Form>
                            </div>
                        </>
                    )}
                </State>
            </Card>
        </div>
    );
};

export default SubcategoryAdd;

import React from "react";
import { Button, List, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useSearchParams } from "react-router-dom";
import { HistoryList } from "services/api-endpoints/dashboard/master-data/kartu-stock";
import { FORMAT_DATE_2, FORMAT_DATE_TIME } from "utils/constant";
import moment from "moment";
import ModalReferensi from "./modal-referensi";

type Props = {
    data?: HistoryList[];
    loading?: boolean;
};

const KartuStokTable = ({ data, loading }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleTableChange = (pagination: TablePaginationConfig) => {
        searchParams.set("page", pagination.current?.toString() || "1");
        setSearchParams(searchParams);
    };

    const columns: ColumnsType<HistoryList> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{moment(text)?.format(FORMAT_DATE_TIME) || "-"}</p>,
        },
        {
            title: "Ref ID",
            dataIndex: "doc_no",
            render: (text, record) => {
                return (
                    <div className="w-full h-full flex items-start">
                        <ModalReferensi title="Detail Referensi" id={record?.doc_id} type={record.doc_type}>
                            {(ctrl) => (
                                <Button onClick={ctrl.openModal} size="small">
                                    {text}
                                </Button>
                            )}
                        </ModalReferensi>
                    </div>
                );
            },
        },
        {
            title: "Product Masuk",
            children: [
                {
                    title: "Qty",
                    dataIndex: "qtyM",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text}</p>,
                },
                {
                    title: "Price (Rp)",
                    dataIndex: "priceM",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
                {
                    title: "Total (Rp)",
                    dataIndex: "totalM",
                    render: (text) => <p className="capitalize m-0 font-semibold">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
            ],
        },
        {
            title: "Product Keluar",
            children: [
                {
                    title: "Qty",
                    dataIndex: "qtyK",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text}</p>,
                },
                {
                    title: "Price (Rp)",
                    dataIndex: "priceK",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
                {
                    title: "Total (Rp)",
                    dataIndex: "totalK",
                    render: (text) => <p className="capitalize m-0 font-semibold">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
            ],
        },
        {
            title: "Saldo",
            children: [
                {
                    title: "Qty",
                    dataIndex: "qtyS",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text}</p>,
                },
                {
                    title: "Price (Rp)",
                    dataIndex: "priceS",
                    render: (text) => <p className="capitalize m-0">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
                {
                    title: "Total (Rp)",
                    dataIndex: "totalS",
                    render: (text) => <p className="capitalize m-0 font-semibold">{text === 0 ? "-" : text?.ToIndCurrency() || "-"}</p>,
                },
            ],
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.created_at!}
            size="small"
            bordered
            loading={loading}
            columns={columns}
            dataSource={data}
            className="w-full"
            pagination={{
                current: 1,
                pageSize: 1000,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default KartuStokTable;

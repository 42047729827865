import { Result, Spin } from "antd";
import React from "react";

type Props = {
    children: React.ReactNode;
    loading?: boolean;
    title?: React.ReactNode | string;
};

const LoadingBlocker = ({ children, loading = false, title = "Loading" }: Props) => {
    if (!loading) return <>{children}</>;
    return (
        <div className="relative">
            <div className="w-full h-full absolute top-0 left-0 z-40 flex items-center justify-center">
                <Result status="warning" icon={<Spin size="large" />} title={title} />
            </div>
            <div className="w-full h-full blur-sm">{children}</div>
        </div>
    );
};

export default LoadingBlocker;

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Space, Table } from "antd";
import { TablePaginationConfig } from "antd/es/table";
import { ColumnsType } from "antd/lib/table";
import ToolbarAction from "components/toolbar/action";
import downloadFastMoving from "modules/fastmoving/download";
import React from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import quantityService, { FastMovingProduct } from "services/api-endpoints/dashboard/master-data/quantity";
import { VIEW_PATH } from "utils/constant";
import { v4 as uuid } from "uuid";
import { END_DATE, START_DATE, WAREHOUSE_ID } from ".";

const FastMovingProductView = () => {
    const pageSize = 50;
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const warehouse = searchParams.get(WAREHOUSE_ID);
    const startDate = searchParams.get(START_DATE);
    const endDate = searchParams.get(END_DATE);

    const [page, setPage] = React.useState(1);

    const listQuery = useQuery([quantityService.listFastMoving, warehouse, startDate, endDate], async () => {
        return (
            await quantityService.ListFastMoving({
                warehouse_id: warehouse,
                start_date: startDate,
                end_date: endDate,
            })
        ).data.data.map((el) => ({ ...el, id: uuid() }));
    });

    const handleTableChange = (pagination: TablePaginationConfig) => {
        const pg = pagination.current || 1;
        setPage(pg);
    };

    const columns: ColumnsType<FastMovingProduct> = [
        {
            width: "50px",
            title: "No",
            fixed: "left",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * pageSize + (i + 1)}</p>,
        },
        {
            title: "Product Code",
            fixed: "left",
            dataIndex: "code1",
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
        },
        {
            title: "Attribute Name",
            dataIndex: "attribute_name",
        },
        {
            title: "Total Qty",
            dataIndex: "total_qty",
        },
        {
            title: "Unit Price",
            dataIndex: "unit_price",
        },
        {
            title: "Locator",
            dataIndex: "locator",
        },
        {
            title: "Current Stock",
            dataIndex: "current_stock",
        },
    ];

    const resetViewClick = () => {
        navigate(location.pathname.replace(VIEW_PATH, ""), { replace: true });
    };

    const downloadClick = () => {
        if (listQuery.isLoading) return;
        downloadFastMoving({
            list: listQuery.data! as FastMovingProduct[],
            title: `Fast Moving Product ${startDate} ${startDate && endDate ? "-" : ""} ${endDate} `,
        });
    };

    return (
        <div className="flex flex-col gap-6 w-full">
            <ToolbarAction
                title="View stock product"
                rightAddition={() => (
                    <Space>
                        <Button onClick={resetViewClick} type="text">
                            Reset View
                        </Button>
                        <Button onClick={downloadClick} type="primary">
                            <AiOutlineDownload className="m-0 mr-2" />
                            Download
                        </Button>
                    </Space>
                )}
            />
            <Table
                dataSource={listQuery.data}
                loading={listQuery.isLoading}
                rowKey={(p) => p.id!}
                size="small"
                columns={columns}
                className="w-full"
                pagination={{
                    current: page,
                    pageSize,
                    total: listQuery.data?.length,
                }}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default FastMovingProductView;

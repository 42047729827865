import Toolbar from "components/toolbar";
import InternalReturnOrderTable from "modules/master-data/internal-return-order/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import internalReturnOrderService from "services/api-endpoints/dashboard/master-data/internal-return-order";

const CReturnOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const getList = useQuery([internalReturnOrderService.getList, page], async () => {
        return (await internalReturnOrderService.GetList({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar search={false} filter={false} />
            <InternalReturnOrderTable fetcher={getList} />
        </div>
    );
};

export default CReturnOrder;

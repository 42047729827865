import { Icon } from "@mui/material";
import { Alert, Skeleton, StepProps, Steps } from "antd";
import State from "components/common/state";
import moment from "moment";
import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useQuery } from "react-query";
import workingOrderService from "services/api-endpoints/dashboard/master-data/working-order";
import { FORMAT_DATE_TIME } from "utils/constant";

interface Props {
    paramId?: any;
}

const HistoryStatusWo = ({ paramId }: Props) => {
    const id = paramId;

    const getHistory = useQuery([workingOrderService.history, id], async () => (await workingOrderService.History({ id })).data.data, {
        enabled: !!id,
    });

    const histories = React.useCallback(
        () =>
            getHistory.data?.map((history) => {
                return {
                    status: "finish",
                    icon: <Icon fontSize="medium">{history.icon || "beenhereicon"}</Icon>,
                    title: <span className="font-semibold">{history.name || "-"}</span>,
                    description: (
                        <div className="">
                            {history.list?.map((step) => (
                                <div className="flex items-center mb-2">
                                    <IoIosCheckmarkCircle className="text-green-300 text-xl" />
                                    <div className="ml-2">
                                        {step.note && <p className="m-0 font-medium">{step.note}</p>}
                                        <p className="m-0 capitalize">
                                            {step.created_by ? `${step.created_by} - ` : ""}
                                            {moment(step.created_at).format(FORMAT_DATE_TIME)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ),
                } as StepProps;
            }),
        [getHistory.data]
    );

    return (
        <State data={getHistory.data} isLoading={getHistory.isLoading} isError={getHistory.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        <Steps direction="vertical" size="default" items={histories() || []} />
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton active paragraph={{ rows: 3 }} />
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert message={(getHistory.error as any)?.message} type="error" />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default HistoryStatusWo;

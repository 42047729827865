import { Button, message } from "antd";
import ModalGenerateSO from "modules/master-data/consignment/sales-order/generate-so-modal";
import TableIndexOut from "modules/master-data/consignment/sales-order/table-view-out";
import { Key, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import cSalesOrderService, { GenerateSOData, SoOutstanding } from "services/api-endpoints/dashboard/master-data/c-sales-order";

const CSalesOrderOutstanding = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const bp = searchParams.get("bp");
    const doc = searchParams.get("doc");
    const date = searchParams.get("date");
    const code1 = searchParams.get("code1");
    const code2 = searchParams.get("code2");

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [products, setProducts] = useState<SoOutstanding[]>([]);

    const getList = useQuery(
        [cSalesOrderService.listOutstanding, page, bp, date, doc, code1, code2],
        async () => {
            return (await cSalesOrderService.ListOutstanding({ page, bp, doc, date, code1, code2 })).data.data;
        },
        {
            onSuccess(data) {
                setProducts(data.list.map((el) => ({ ...el, qty_req: el.qty })));
            },
            refetchOnWindowFocus: false,
        }
    );

    const generateSOMutation = useMutation(
        [cSalesOrderService.generateSO],
        async (data: GenerateSOData[]) => {
            return (await cSalesOrderService.GenerateSO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Generate SO Success");
                getList.refetch();
            },
        }
    );

    const onSubmit = () => {
        const parseData =
            products
                ?.filter((p) => selectedRowKeys.includes(p.id as any))
                .map(
                    (el) =>
                        ({
                            doc_id: el.doc_id,
                            account_id: el.account_id,
                            product_id: el.product_id,
                            price: el.product_price,
                            qty: el.qty,
                        } as GenerateSOData)
                ) || [];

        generateSOMutation.mutate(parseData);
    };

    const onEditChange = (record: SoOutstanding) => {
        if (setProducts) {
            setProducts((prev) => prev?.map((el) => (el?.id === record?.id ? record : el)));
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-full flex items-center justify-between">
                <div />
                <ModalGenerateSO
                    title="Konfirmasi"
                    onSubmit={onSubmit}
                    total={selectedRowKeys.length}
                    products={products}
                    selectedRowKeys={selectedRowKeys}
                >
                    {(modal) => (
                        <Button
                            loading={generateSOMutation.isLoading}
                            type="primary"
                            className="!flex !items-center"
                            disabled={!selectedRowKeys.length}
                            onClick={modal.openModal}
                        >
                            Generate SO
                        </Button>
                    )}
                </ModalGenerateSO>
            </div>

            <TableIndexOut
                fetcher={getList}
                product={products}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                onEditChange={onEditChange}
            />
        </div>
    );
};

export default CSalesOrderOutstanding;

/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { BrandInformation, SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import modelService from "services/api-endpoints/dashboard/master-data/model";
import { CreateDataModel } from "../models";

type SelectOptionCustom = SelectOption & BrandInformation;

export interface Props extends ControlledInputSelectProps<CreateDataModel> {
    onSelectedData?: (data: BrandInformation[]) => void;
}

export function withBrandName<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([modelService.brandInformation], async () => {
            const req = await modelService.BrandInformation({ brand_name: "" });
            return req.data.data?.map((el) => ({ ...el, label: el?.brand_name, value: el.brand_id || "" } as SelectOptionCustom));
        });

        const onSelectChange = () => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data || []);
            }
        };

        return (
            <Component
                {...props}
                showSearch
                filterOption={(input, option) => {
                    return (option?.children ?? "")?.toString()?.toLowerCase().includes(input?.toLowerCase());
                }}
                onSelect={onSelectChange}
                onDeselect={onSelectChange}
                loading={query.isLoading}
                options={query.data || []}
            />
        );
    };
}

const BrandNameSelect = withBrandName(ControlledSelectInput);

export default BrandNameSelect;

/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { Button, Checkbox, InputNumber, Popover, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DocReqChild } from "pages/purchasing-inventory/add";
import React, { useContext, useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { UseMutationResult } from "react-query";
import { InventoryItem, POPrice } from "services/api-endpoints/purchasing/inventory";

type SelectVendorProps = {
    columnIndex: number;
    vendor?: number;
    context: React.Context<any>;
};

const BP_ID_SPM = 114;
const MAX_VENDOR = 4;

const SelectVendor = ({ columnIndex, vendor, context, ...props }: SelectVendorProps) => {
    const { pickedVendor, setPickedVendor, setDocReqChild, getVendor, docReqChild, getPOPrice } = useContext(context) as {
        getPOPrice: UseMutationResult<POPrice, unknown, POPrice, unknown>;
        [a: string]: any;
    };

    const [value, setValue] = useState<any>(vendor);
    const vendors = (getVendor?.data || []) as any[];

    useEffect(() => {
        setValue(vendor);
    }, [vendor]);

    const onChange = async (v: number) => {
        if (!v && pickedVendor === vendor) {
            setPickedVendor(null);
        }

        // [ATTENTION] currently only for PT.SEHATI PRIMA MAKMUR (GOLF) id = 114 can getPOPrice
        if (v === BP_ID_SPM) {
            const dataPrice = {
                columnIndex,
                bp_id: BP_ID_SPM,
                child: (docReqChild as DocReqChild[]).map((p) => ({ product_id: p.product_id, price: 0 })),
            };
            getPOPrice.mutateAsync(dataPrice);
        }

        setValue(v);
        setDocReqChild((prev: DocReqChild[]) =>
            prev?.map((child) => ({
                ...child,
                vendors: {
                    ...child?.vendors,
                    [columnIndex]: {
                        ...(child?.vendors ? child.vendors[columnIndex] || {} : {}),
                        vendor_id: v,
                    },
                },
            }))
        );
    };

    const onChangeCheckbox = (e: any) => {
        setPickedVendor(e.target.checked ? vendor : null);
    };

    const alreadyPickedVendor = Object.keys(docReqChild?.length && docReqChild[0] ? docReqChild[0]?.vendors || {} : {})
        .filter((key) => docReqChild[0]?.vendors[key]?.vendor_id)
        .map((key) => docReqChild[0]?.vendors[key]?.vendor_id);

    const availableVendor = vendors?.map((vdr) => ({ ...vdr, disabled: alreadyPickedVendor.includes(vdr.value) && vdr.value !== vendor }));

    return (
        <div className="flex items-center gap-x-4">
            <Select
                loading={getPOPrice.isLoading && value === BP_ID_SPM}
                value={value}
                options={availableVendor}
                showSearch
                allowClear
                optionFilterProp="label"
                className="w-full"
                placeholder="Pick Vendor"
                onChange={onChange}
                {...props}
            />
            {value ? <Checkbox checked={pickedVendor === vendor} onChange={onChangeCheckbox} /> : null}
        </div>
    );
};

type InputPriceProps = {
    record: DocReqChild;
    columnIndex: number;
    context: React.Context<any>;
};

const InputPrice = ({ record, columnIndex, context }: InputPriceProps) => {
    const { setDocReqChild, problemChild, getPOPrice } = useContext(context) as {
        getPOPrice: UseMutationResult<POPrice, unknown, POPrice & { columnIndex: number }, unknown>;
        [a: string]: any;
    };

    const [value, setValue] = useState<any>();

    useEffect(() => {
        const price = record?.vendors ? record.vendors[columnIndex]?.price : null;
        setValue(price);
    }, []);

    const currencyFormatter = (vl: any) => {
        return new Intl.NumberFormat("id", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
        }).format(vl);
    };

    const currencyParser = (val: any) => {
        try {
            if (typeof val === "string" && !val.length) {
                val = "0";
            }

            const group = new Intl.NumberFormat("id").format(1111).replace(/1/g, "");
            const decimal = new Intl.NumberFormat("id").format(1.1).replace(/1/g, "");
            let reversedVal = val.replace(new RegExp(`\\${group}`, "g"), "");
            reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, "g"), ".");
            //  => 1232.21 €

            // removing everything except the digits and dot
            reversedVal = reversedVal.replace(/[^0-9.]/g, "");
            //  => 1232.21

            // appending digits properly
            const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
            const needsDigitsAppended = digitsAfterDecimalCount > 2;

            if (needsDigitsAppended) {
                reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
            }

            return Number.isNaN(reversedVal) ? 0 : reversedVal;
        } catch (error) {
            console.error(error);
        }
    };

    const onChange = (price: any) => {
        setDocReqChild((prev: DocReqChild[]) =>
            prev?.map((child) => {
                if (child?.product_id !== record?.product_id) return child;
                return {
                    ...child,
                    vendors: {
                        ...child?.vendors,
                        [columnIndex]: {
                            ...(child?.vendors ? child.vendors[columnIndex] || {} : {}),
                            price,
                        },
                    },
                };
            })
        );
        setValue(price);
    };

    const isProblem = problemChild.find(
        (child: InventoryItem & { column: number }) => child.product_id === record.product_id && columnIndex === Number(child.column) && !child.price
    );

    useEffect(() => {
        if (getPOPrice.variables?.columnIndex === columnIndex) {
            const product = getPOPrice.data?.child.find((p) => p.product_id === record.product_id);
            onChange(product?.price);
        }
    }, [getPOPrice.data, getPOPrice.variables]);

    return (
        <InputNumber
            formatter={currencyFormatter}
            parser={currencyParser}
            value={value}
            status={isProblem ? "error" : undefined}
            placeholder="Input Price"
            onChange={onChange}
            className="!w-full"
        />
    );
};

const TenderPurchasing = ({ context }: { context: React.Context<any> }) => {
    const { docReqChild, pickedVendor, setDocReqChild, isRefresh, setIsRefresh, getPOPrice } = useContext(context) as {
        getPOPrice: UseMutationResult<POPrice, unknown, POPrice, unknown>;
        [a: string]: any;
    };

    const removeItemFilter = (item: DocReqChild) => {
        return () => {
            setDocReqChild((prev: DocReqChild[]) => prev?.filter((el) => el.product_id !== item.product_id));
            setIsRefresh(!isRefresh);
        };
    };

    const onChangeQty = (record: DocReqChild) => {
        return (val: any) => {
            const item = { ...record, qty: val };
            setDocReqChild((prev: DocReqChild[]) =>
                prev?.map((child) => {
                    if (child?.product_id === record?.product_id) return item;
                    return child;
                })
            );
        };
    };

    const columns: ColumnsType<DocReqChild> = [
        {
            title: "Action",
            render: (_, record) => <IoCloseSharp onClick={removeItemFilter(record)} className="text-red-400 text-lg cursor-pointer" />,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "Ref",
            render: (_, record) => {
                if (!record.note) return "-";
                const note = record.note || "";
                const title = note.length > 50 ? `${note.substring(0, 50)}...` : note;
                return (
                    <Popover
                        title="Link Referensi"
                        trigger="click"
                        content={
                            <div className="w-full">
                                <a href={note} target="_blank" rel="noreferrer">
                                    {title}
                                </a>
                            </div>
                        }
                    >
                        <Button>Link Ref</Button>
                    </Popover>
                );
            },
        },
        {
            title: "Type Item",
            dataIndex: "type_name",
            render: (text) => <p className="capitalize m-0">{text}</p>,
        },
        {
            title: "UOM",
            dataIndex: "uom_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Qty",
            dataIndex: "qty",
            render: (_, record) => (
                <InputNumber value={record.qty} onChange={onChangeQty(record)} min={1} max={record.qty_max} className="!w-[100px]" />
            ),
        },
        ...Array.from({ length: MAX_VENDOR }).map((e, index) => {
            const vendor = (() => {
                if (!docReqChild?.length || !docReqChild[0]?.vendors || !docReqChild[0]?.vendors[index]) return null;
                return docReqChild[0]?.vendors[index]?.vendor_id;
            })();

            return {
                title: () => <SelectVendor context={context} columnIndex={index} vendor={vendor} />,
                className: pickedVendor && pickedVendor !== vendor ? "!bg-[#f5f5f5]" : "",
                render: (_: any, record: DocReqChild) => {
                    if (getPOPrice.isLoading && vendor === BP_ID_SPM) {
                        return <p className="text-blue-500">Get pricing...</p>;
                    }

                    return (
                        <div className="m-0">
                            <InputPrice context={context} columnIndex={index} record={record} />
                            {pickedVendor && pickedVendor === vendor && record.discount_price && (
                                <p className="!w-full text-red-500 text-xs m-0 mt-1">
                                    {record.discount_name} ({(record.discount_price / record.discount_qty).ToIndCurrency("Rp")})
                                </p>
                            )}
                        </div>
                    );
                },
            };
        }),
    ];

    return (
        <Table
            scroll={{ x: 1500 }}
            rowKey={(i) => i.product_id}
            size="small"
            columns={columns}
            dataSource={docReqChild}
            className="w-full"
            pagination={undefined}
        />
    );
};

export default TenderPurchasing;

/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-constructor */
import { RowDefault } from "components/table/edit-table";
import * as Models from "models";
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export type ProductCodeBom = {
    id: number;
    product_code: string;
    product_name: string;
};

export type CreateBOM = {
    id?: any;
    bom: (
        | {
            id: number;
            qty: number;
        }
        | {
            child: {
                id: number;
                qty: number;
            }
        }
    )[];
};

export type ListBom = {
    id: number;
    product_code: string;
    product_name: string;
};

export type BomExample = RowDefault<any> & {
    id?: any;
    product_code?: any;
    product_name?: any;
    qty?: any;
    logic?: { logic_name?: string };
    actionRow?: any;
    child?: BomExample[];
};

export type BomDetail = {
    id: number;
    product_code: string;
    bom: BomExample[];
}

class BomService extends BaseService {
    create = "/master-product/create-bom";

    getProductCode = "/master-product/get-product-code";

    getAll = "/master-product/list-bom";

    delete = "/master-product/delete-bom";

    detail = "/master-product/detail-bom";

    constructor() {
        super();
    }

    Detail<T extends BomDetail>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Delete<T extends any>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.delete,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends ListBom[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAll,
            });
            return req;
        });
    }

    GetProductCode<T extends ProductCodeBom[]>(params: { type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductCode,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateBOM, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const bomService = new BomService();
export default bomService;

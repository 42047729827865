/* eslint-disable no-shadow */
import React from "react";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import paymentService, { PaymentVList } from "services/api-endpoints/dashboard/payment";
import FilterPayment from "modules/master-data/payment/index/filter";
import PaymentTable from "modules/master-data/payment/index/table";
import { message } from "antd";
import useConfirmDelete from "hooks/useConfirmDelete";

const filter = ["doc_no", "start_date", "end_date", "account_id"];

const Payment = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([paymentService.allPaymentV, page, filterValue], async () => {
        return (await paymentService.AllPaymentV(filterValue as any)).data.data;
    });

    const deleteMutation = useMutation(async (data: PaymentVList) => (await paymentService.DeletePaymentV({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<PaymentVList>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterPayment filter={filter}>
                {(dt) => <Toolbar placeholder="Search Payment Voucher" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterPayment>
            <PaymentTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default Payment;

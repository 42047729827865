import React, { useContext, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import { BOOLEAN_TYPE, IMPACT_CASHBACK_POINT, IMPACT_CATEGORY, IMPACT_FREE_PRODUCT, IMPACT_NAME, IMPACT_PERCENT, MAX_PERCENT } from "utils/constant";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import Utils from "utils";
import voucherService, { CreateVoucher, Product, ProductWithQty } from "services/api-endpoints/dashboard/master-data/voucher";
import { FAction } from "../models";
import { VoucherContext } from "../context/voucher";
import { useQuery } from "react-query";
import { SelectOption } from "models";
import ProductFreeTable from "../lib/product-free-table";

const schema: yup.SchemaOf<Partial<FAction>> = yup.object().shape({
    amount: yup.string(),
    impact_category: yup.number(),
    impact_name: yup.number().required("Impact Name required"),
    is_multiple: yup.number().required("Multiple Type required"),
    max_discount: yup.string(),
    min_trx: yup.string(),
    free_product: yup.array(),
});

type Props = {
    submit?: (data: CreateVoucher) => void;
};

const Action = ({ submit }: Props) => {
    const { prevStep, setState, state } = useContext(VoucherContext);
    const [products, setProducts] = useState<ProductWithQty[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, formState } = useForm<FAction>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            amount: state?.createData?.amount,
            impact_category: state?.createData?.impact_category,
            impact_name: state?.createData?.impact_name,
            is_multiple: state?.createData?.is_multiple,
            max_discount: state?.createData?.max_discount,
            min_trx: state?.createData?.min_trx,
        },
    });

    const watchImpactName = watch("impact_name");
    const watchImpactCategory = watch("impact_category");

    const productQuery = useQuery([voucherService.getProduct], async () => {
        const req = await voucherService.GetProduct({
            product_type: 0,
            id: 0,
            search: "",
        });
        return req.data.data?.list_product.map((el) => ({ ...el, label: el?.item || "", value: el.id || "" } as SelectOption & Product));
    });

    const onSubmitHandler = handleSubmit((data) => {
        const amount = Utils.convertToIntFormat(data.amount as any);
        const parseData: FAction =
            watchImpactName === IMPACT_FREE_PRODUCT
                ? {
                      ...data,
                      free_product: products,
                  }
                : {
                      ...data,
                      max_discount: Utils.convertToIntFormat(data.max_discount as any),
                      min_trx: Utils.convertToIntFormat(data.min_trx as any),
                      amount,
                  };
        if (data.impact_category === IMPACT_PERCENT && amount > MAX_PERCENT) {
            message.error("Percentage not valid");
            return;
        }
        if (setState) {
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    ...parseData,
                },
            }));
            if (submit)
                submit({
                    ...state?.createData,
                    ...parseData,
                } as CreateVoucher);
        }
    });

    const onSaveHandler = async () => {
        await form.validateFields();
        form.submit();
        console.log(formState.errors);
    };

    const onPrevHandler = () => {
        if (prevStep) prevStep();
    };

    const onChangeProduct = (keys: string[]) => {
        const prdcts = productQuery.data?.filter((prd) => keys.find((id) => prd.id === Number(id)));
        setProducts(prdcts?.map((prd) => ({ ...prd })) || []);
    };

    const onRemoveProduct = (product: Product) => {
        setProducts((prev) => {
            return prev.filter((p) => p.item !== product.item);
        });
    };

    return (
        <>
            <Form form={form} layout="horizontal" onFinish={onSubmitHandler}>
                <div className="w-full grid grid-cols-3 gap-x-4">
                    <div className="col-span-3">
                        <ControlledSelectInput
                            classNameForm="!w-1/2"
                            options={IMPACT_NAME}
                            placeholder="Impact Name"
                            control={control}
                            name="impact_name"
                        />
                    </div>
                    {watchImpactName !== IMPACT_FREE_PRODUCT && (
                        <>
                            <ControlledSelectInput
                                classNameForm="col-span-2"
                                options={IMPACT_CATEGORY}
                                placeholder="Impact Category"
                                control={control}
                                name="impact_category"
                            />
                            <ControlledInputText
                                addonBefore={watchImpactCategory === IMPACT_PERCENT ? "%" : "Rp"}
                                control={control}
                                name="amount"
                                type="number"
                                placeholder={watchImpactCategory === IMPACT_PERCENT ? "Percent" : "Amount"}
                            />
                            <ControlledInputText control={control} name="min_trx" type="number" placeholder="Min Trx (Rp)" />
                            <ControlledInputText control={control} name="max_discount" type="number" placeholder="Max Discount (Rp)" />
                        </>
                    )}
                    {watchImpactName === IMPACT_FREE_PRODUCT && (
                        <>
                            <ControlledSelectInput
                                classNameForm="col-span-3"
                                options={productQuery.data}
                                placeholder="Product Free"
                                control={control}
                                mode="multiple"
                                showSearch
                                name="free_product"
                                loading={productQuery.isLoading}
                                onChange={onChangeProduct}
                            />
                            <div className="col-span-3 mt-2">
                                <ProductFreeTable list={products} setList={setProducts} removeItemList={onRemoveProduct} />
                            </div>
                        </>
                    )}
                    <ControlledSelectInput options={BOOLEAN_TYPE} placeholder="Multiple" control={control} name="is_multiple" />
                </div>
            </Form>
            <div className="w-full flex items-center justify-between">
                <Button onClick={onPrevHandler}>Previous</Button>
                <Button onClick={onSaveHandler} type="primary">
                    save
                </Button>
            </div>
        </>
    );
};

export default Action;

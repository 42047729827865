/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ColumnType, TablePaginationConfig } from "antd/lib/table";
import { ProductWithQty } from "modules/advance-search/models";
import { useMutation, useQuery } from "react-query";
import orderSalesService from "services/api-endpoints/dashboard-all/sales/order-sales";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export function addProductTable<T extends ProductWithQty>(Component: React.ComponentType<EditTableProps<T>>) {
    return (props: Props<T>) => {
        const [editRow, setEditRow] = useState<T | null>(null);
        const [page, setPage] = useState<number>(1);

        const handleTableChange = (pagination: TablePaginationConfig) => {
            setPage(pagination.current || 1);
        };

        const columns: ColumnType<T>[] = [
            {
                width: "50px",
                title: "No",
                dataIndex: "-",
                render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
            },
            {
                title: "Product Name",
                dataIndex: "product_name",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Code",
                dataIndex: "product_code",
                render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                render: (text, record) => (
                    <p className={record.isFree ? "text-green-500 capitalize m-0" : "capitalize m-0"}>{Number(text)?.ToIndCurrency("Rp")}</p>
                ),
            },
            {
                title: "Estimated Price",
                dataIndex: "-",
                render: (text, record) => (
                    <p className={record.isFree ? "text-green-500 capitalize m-0" : "capitalize m-0"}>
                        {(Number(record.qty || 1) * Number(record.product_price))?.ToIndCurrency("Rp")}
                    </p>
                ),
            },
            {
                title: "Stock",
                dataIndex: "stock",
                render: (text, record) => (
                    <>
                        {(record.stock || 0) <= 5 && <p className="text-red-500 capitalize m-0 text-center font-bold">{text}</p>}
                        {(record.stock || 0) > 5 && (record.stock || 0) <= 10 && (
                            <p className="text-yellow-400 capitalize m-0 text-center font-bold">{text}</p>
                        )}
                        {(record.stock || 0) > 10 && <p className="text-green-500 capitalize m-0 text-center font-bold">{text}</p>}
                    </>
                ),
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                fixed: "right",
                width: "75px",
                ...{ editable: true },
                render: (text) => <p className="capitalize m-0 text-center">{text || "-"}</p>,
            },
            {
                title: "Discount",
                dataIndex: "discount",
                render: (text, record) => (
                    <p className="text-red-500 font-semibold capitalize m-0">{record.discount ? Number(text).ToIndCurrency("Rp") : "-"}</p>
                ),
            },
            {
                title: "Note",
                dataIndex: "note",
                align: "center",
                width: "100px",
                ...{ editable: true },
                render: (text) => <p className="m-0 text-center">{text || "-"}</p>,
            },
        ];

        const isEditing = (record: T, edited: T | null) => record.product_id === edited?.product_id && record.isFree === edited?.isFree;
        const findIndexSave = (record: T, edited: T) => record.product_id === edited.product_id && record.isFree === edited?.isFree;
        const rowKey = (record: T) => (record.product_id && record.isFree)! as any;
        const editInputType: EditTableProps<T>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                setEditRow={setEditRow}
                editRow={editRow}
                editInputType={editInputType}
                rowKey={rowKey}
                columns={columns}
                isEditing={isEditing}
                findIndexSave={findIndexSave}
                onChange={handleTableChange}
            />
        );
    };
}

const ProductTable = addProductTable(EditTable);
export default ProductTable;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import LoyaltyTable from "modules/master-data/loyalty/index/table";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import loyaltyService from "services/api-endpoints/dashboard/master-data/loyalty";

const Loyalty = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isSearch, searchValue } = useTypeSearchUrl({
        search: ["query"],
    });

    const getList = useQuery([loyaltyService.getAll, page, searchValue, isSearch], async () => {
        if (isSearch) return (await loyaltyService.Search({ page, param_search: searchValue.query })).data.data;
        return (await loyaltyService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Loyalty Name" filter={false} />
            <LoyaltyTable fetcher={getList} />
        </div>
    );
};

export default Loyalty;

import { List, Popover } from "antd";
import React from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";

type Props = {
    data: ExpansesData[];
    onRemoveData?: (index: number) => void;
};

const ListExpenses = ({ data, onRemoveData }: Props) => {
    return (
        <List
            dataSource={data}
            renderItem={(item, i) => {
                return (
                    <List.Item style={{ padding: "0px", paddingBottom: "8px" }}>
                        <div className="!w-full m-0">
                            <div className="!w-full flex items-center justify-between gap-4 m-0 p-0">
                                <p className="m-0 text-xs w-[80%]">
                                    <p className="text-xs text-gray-800 font-bold mb-2 m-0">{item.bp_name}</p>
                                    {item?.charge_name} <br />
                                    {item.note ? <p className="text-xs text-gray-400">note: {item.note}</p> : null}
                                </p>
                                {onRemoveData ? (
                                    <MdOutlineDeleteOutline
                                        className="text-lg !h-7 !w-7 cursor-pointer text-red-500"
                                        onClick={() => onRemoveData(i)}
                                    />
                                ) : null}
                            </div>
                            <p className="m-0 font-semibold text-xs capitalize !w-full">{item.amount?.ToIndCurrency("Rp")}</p>
                        </div>
                    </List.Item>
                );
            }}
        />
    );
};

export default ListExpenses;

import React, { useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Button, DatePicker, Form } from "antd";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ControlledInputRichText from "components/form/controlled-inputs/controlled-input-richtext";
import moment from "moment";
import { FORMAT_DATE_1, IMAGE_TYPES, PRIORITY } from "utils/constant";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import InputFile from "components/form/inputs/input-file";
import useControlFile from "hooks/useControlFile";
import ImageCard from "components/card/image";
import Utils from "utils";
import { FRuleInformation } from "../models";
import { VoucherContext } from "../context/voucher";

const schema: yup.SchemaOf<Partial<FRuleInformation>> = yup.object().shape({
    voucher_code: yup.string().required("Voucher code required"),
    title: yup.string().required("Title required"),
    description: yup.string().required("Description required"),
    start_date: yup.string().required("Start date required"),
    end_date: yup.string().required("End date required"),
    priority: yup.number().required("Priority required"),
    image_path: yup.string().required("Image required"),
});

const RuleInformation = () => {
    const { nextStep, setState, state } = useContext(VoucherContext);

    const [form] = Form.useForm();
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
        setError,
    } = useForm<FRuleInformation>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            voucher_code: state?.createData.voucher_code,
            title: state?.createData.title,
            description: state?.createData.description,
            start_date: state?.createData.start_date,
            end_date: state?.createData.end_date,
            priority: state?.createData.priority,
            image_path: state?.createData.image_path,
        },
    });

    const [controlFile] = useControlFile<FRuleInformation>({
        setValue,
        watchValue: watch,
        errors: errors as any,
    });

    const startDateWatch = watch("start_date") ? moment(watch("start_date"), FORMAT_DATE_1) : null;
    const endDateWatch = watch("end_date") ? moment(watch("end_date"), FORMAT_DATE_1) : null;

    const onSubmitHandler = handleSubmit((data) => {
        if (!data.start_date || !data.end_date) {
            setError("start_date", {
                message: "Effective date required",
                type: "required",
            });
            return;
        }
        if (!Utils.stripHtml(data.description)) {
            setError("description", { message: "Description required", type: "required" });
            return;
        }
        if (setState) {
            setState((prev) => ({
                ...prev,
                createData: {
                    ...prev.createData,
                    ...data,
                },
            }));
        }
        if (nextStep) nextStep();
    });

    const onNextHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onChangeDatePricker = (key: keyof FRuleInformation, value: string) => {
        setValue(key, value);
        form.setFieldValue(key, value);
    };

    return (
        <>
            <Form form={form} layout="horizontal" onFinish={onSubmitHandler}>
                <div className="grid grid-cols-2 gap-x-4">
                    <ControlledInputText allowClear control={control} name="voucher_code" placeholder="Voucher Code" />
                    <ControlledInputText allowClear control={control} name="title" placeholder="Voucher Title" />
                    <div className="!w-full">
                        <DatePicker.RangePicker
                            allowClear
                            status={errors.start_date ? "error" : undefined}
                            className="w-full"
                            value={[startDateWatch, endDateWatch]}
                            placeholder={["Start Date", "End Date"]}
                            onChange={(val) => {
                                onChangeDatePricker("start_date", val?.[0]?.format(FORMAT_DATE_1) || "");
                                onChangeDatePricker("end_date", val?.[1]?.format(FORMAT_DATE_1) || "");
                            }}
                        />
                        {errors.start_date ? <p className="text-red-400">{errors.start_date?.message}</p> : null}
                    </div>
                    <ControlledSelectInput allowClear options={PRIORITY} control={control} name="priority" placeholder="Priority" />
                    <ControlledInputRichText control={control} name="description" placeholder="Voucher Description" />
                    <InputFile control={controlFile} types={IMAGE_TYPES} name="image_path">
                        {(dt) => <ImageCard image={dt.file || dt.urlFile} title="Image" />}
                    </InputFile>
                </div>
            </Form>
            <Button type="primary" onClick={onNextHandler} className="!w-fit">
                Next
            </Button>
        </>
    );
};

export default RuleInformation;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { FilterDeliveryOrder } from "modules/dashboard-jsl/delivery-order/models";
import { BasePaginationResponseHistory } from "modules/dashboard-jsl/sales-order/models";

export interface ListBp {
    bp_id?: number;
    bp_name?: string;
}

export interface ListSO {
    so_id?: number;
    so_no?: string;
    total_sku?: number;
    total_item?: number;
    status?: number;
}

export interface ProductSO {
    so_id?: number;
    so_no?: string;
    product_id?: number;
    product_name?: string;
    code?: string;
    price?: number;
    qty?: number;
}

export interface DetailProduct {
    pl_id?: number;
    product_id?: number;
    price?: number;
    qty?: number;
}
export interface CreateDO {
    bp_id?: number;
    driver_name?: string;
    plate_number?: string;
    note?: string;
    payment_term_id?: number | null;
    child_document?: number[];
    detail_product?: DetailProduct[];
}

export interface ListDetail {
    order_detail_id?: number;
    so_no?: string;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    qty?: number;
}
export interface DetailDO {
    order_id?: number;
    order_status?: number;
    do_no?: string;
    request_from?: string;
    request_to?: string;
    driver_name?: string;
    plate_number?: string;
    note?: string;
    address_from?: null;
    address_to?: string;
    list_so?: string[];
    list_detail?: ListDetail[];
}

export interface List {
    id?: number;
    no?: string;
}

export interface ListHistory {
    id?: number;
    notes?: string;
    created_by?: string;
    created_at?: number;
    list_do?: List[];
    list_so?: List[];
    list_po?: List[];
}

export interface DetailHistoryList {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    price?: number;
    product_price?: number;
    qty?: number;
}

export interface DetailHistory {
    order_id?: number;
    po_no?: string;
    do_no?: string;
    so_no?: string;
    request_from?: string;
    request_to?: string;
    list_detail?: DetailHistoryList[];
}

export interface DeleteData {
    id?: any;
    note?: string;
}

export interface ListPL {
    pl_id?: any;
    pl_no?: string;
    total_sku?: number;
    total_item?: number;
    status?: number;
    payment_term_code?: string;
    payment_term_id?: number;
}

export interface DetailPL {
    pl_id?: number;
    pl_no?: string;
    so_no?: string;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    product_price?: number;
    qty?: number;
}

class DeliveryOrderService extends BaseService {
    getAll = "/do/all-delivery-order";

    search = "/do/search-delivery-order";

    filter = "/do/filter-delivery-order";

    getRequester = "/do/get-requester";

    listSO = "/do/list-so";

    detailProductSO = "/do/detail-product-so";

    createDO = "/do/create-delivery-order";

    detail = "/do/detail-delivery-order";

    historyDO = "/do/history-delivery-order";

    detailHistoryDO = "/do/detail-history-do";

    deleteDO = "/do/delete-do";

    listPL = "/do/list-picking-list";

    detailPL = "/do/detail-product-pl";

    constructor() {
        super();
    }

    DetailPL<T extends DetailPL[]>(params: { pl_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailPL,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    ListPL<T extends ListPL[]>(params: { bp_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listPL,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    DeleteDO<T extends any>(data: DeleteData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.deleteDO,
                data,
            });
            return req;
        });
    }

    DetailHistoryDO<T extends DetailHistory>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailHistoryDO}/${id}`,
            });
            return req;
        });
    }

    HistoryDO<T extends ListHistory>(param: { page: any; id: any }) {
        return this.ProxyRequest<BasePaginationResponseHistory<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseHistory<T>>({
                url: this.historyDO,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Detail<T extends DetailDO>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    CreateDO<T extends any>(data: CreateDO) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDO,
                data,
            });
            return req;
        });
    }

    DetailProductSO<T extends ProductSO[]>(params: { so_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProductSO,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    ListSO<T extends ListSO[]>(params: { account_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listSO,
                config: {
                    params,
                },
            });
            return req;
        });
    }

    GetRequester<T extends ListBp[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getRequester,
            });
            return req;
        });
    }

    Filter<T extends Models.DashboardJslDeliveryOrder>(param: FilterDeliveryOrder) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends Models.DashboardJslDeliveryOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.DashboardJslDeliveryOrder>(param: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const deliveryOrderService = new DeliveryOrderService();
export default deliveryOrderService;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import React from "react";
import ReturnOrderTable from "modules/master-data/return-order/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import returnOrderService from "services/api-endpoints/dashboard/master-data/return-order";
import FilterReturnOrder from "modules/master-data/return-order/index/filter";

const filter = ["order_reference_no", "status", "start_date", "end_date", "return_no"];

const ReturnOrder = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([returnOrderService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await returnOrderService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await returnOrderService.Filter(filterValue as any)).data.data;
        return (await returnOrderService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterReturnOrder filter={filter}>
                {(dt) => <Toolbar placeholder="Doc No or Order Reference" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterReturnOrder>
            <ReturnOrderTable fetcher={getList} />
        </div>
    );
};

export default ReturnOrder;

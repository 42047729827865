/* eslint-disable no-shadow */
import { Button, Card, message, Skeleton, Tabs } from "antd";
import ToolbarAction from "components/toolbar/action";
import { BiTrash } from "react-icons/bi";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import purchaseOrderExtService, { DeleteData, ListDetail } from "services/api-endpoints/dashboard-jsl/purchase-order-ext";
import State from "components/common/state";
import ProductTable from "modules/dashboard-jsl/purchase-order/view/product-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { STATUS_PURCHASE_ORDER } from "utils/constant";
import ModalDelete from "components/modal/delete_modal";
import { getDocumentRoute } from "utils/routes";
import HistoryTableExt from "modules/dashboard-jsl/purchase-order-ext/view/history-table";
import ModalHistoryPOExt from "modules/dashboard-jsl/purchase-order-ext/view/model-history";

const PurchaseOrderViewExt = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const page = searchParams.get("page") || 1;

    const [products, setProducts] = useState<ListDetail[]>([]);
    const [detailHistoryID, setDetailHistoryID] = useState<number | null>(null);

    const detailQuery = useQuery(
        [purchaseOrderExtService.detail, id],
        async () => {
            return (await purchaseOrderExtService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [purchaseOrderExtService.historyPO, page],
        async () => {
            return (await purchaseOrderExtService.HistoryPO({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const deletePOMutation = useMutation(
        [purchaseOrderExtService.deletePO],
        async (data: DeleteData) => {
            return (await purchaseOrderExtService.DeletePO(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("External Purchase order deleted");
                navigate(getDocumentRoute({ menuName: "ext-purchase-order", page: "index" }), { replace: true });
            },
        }
    );

    const onClickLinkHandler = (id: number) => {
        setDetailHistoryID(id);
    };

    const onDeletePOHandler = (value: string) => {
        const dataDelete: DeleteData = {
            id: id!,
            note: value,
        };
        deletePOMutation.mutate(dataDelete);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="Purchase order detail"
                rightAddition={() => (
                    <ModalDelete title="Delete PO" onSubmit={onDeletePOHandler}>
                        {(dt) => (
                            <Button
                                loading={deletePOMutation.isLoading}
                                disabled={detailQuery.data?.order_status === 2 || detailQuery.data?.order_status === -1}
                                onClick={dt.openModal}
                                type="default"
                                className="!flex !items-center text-red-600"
                            >
                                <BiTrash className="m-0 mr-2 text-red-600" />
                                Delete
                            </Button>
                        )}
                    </ModalDelete>
                )}
            />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <div className="">
                                        <p className="capitalize m-0">
                                            purchase order no <br />
                                            <span className="underline italic m-0">{detailQuery.data?.po_number}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request from <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_from}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            request to <br />
                                            <span className="underline italic m-0">{detailQuery.data?.request_to}</span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="capitalize m-0">
                                            status <br />
                                            <span
                                                className={
                                                    detailQuery.data?.order_status === -1
                                                        ? "underline italic m-0 text-red-600"
                                                        : "underline italic m-0"
                                                }
                                            >
                                                {STATUS_PURCHASE_ORDER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: (
                                <ModalHistoryPOExt idHistory={detailHistoryID}>
                                    {(dt) => (
                                        <HistoryTableExt
                                            fetcher={history}
                                            onClickLink={(id) => {
                                                onClickLinkHandler(id);
                                                dt.openModal();
                                            }}
                                        />
                                    )}
                                </ModalHistoryPOExt>
                            ),
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default PurchaseOrderViewExt;

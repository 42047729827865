/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { DetailDO, ListDetail } from "services/api-endpoints/dashboard-jsl/delivery-order";
import QRCode from "react-qr-code";
import moment from "moment";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = {
    data: DetailDO | null;
};

const Print = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
    const [currentTime, setCurrentTime] = useState();

    const productPerPage = 10;

    const listDetailGroup = React.useMemo(() => {
        if (!data?.list_detail) return [];
        const listProductCopy = [...data!.list_detail];
        const temp: ListDetail[][] = [];
        const totalPage = Math.ceil(listProductCopy.length / productPerPage);
        [...new Array(totalPage)].forEach(() => {
            temp.push(listProductCopy.splice(0, productPerPage));
        });
        return temp;
    }, [data]);

    if (!data) return <div className="" />;
    return (
        <div ref={ref} className="hidden print:block">
            {listDetailGroup?.map((products, page) => (
                <div className="w-full bg-white p-5 break-after-page">
                    <div className="w-full flex items-end justify-between">
                        <div className="max-w-[60%]">
                            <h1 className="text-2xl uppercase m-0">{data?.request_to}</h1>
                            <p className="text-xs m-0">{data?.address_to}</p>
                        </div>
                        <h2 className="text-4xl font-medium m-0 capitalize">surat jalan</h2>
                    </div>
                    <div className="w-full h-[2px] bg-gray-400 mt-2" />
                    <div className="w-full h-[2px] bg-gray-400 mt-1" />
                    <div className="w-full grid grid-cols-3 mt-4">
                        <div className="">
                            <h2 className="m-0 capitalize text-2xl font-medium">kepada yth.</h2>
                            <p className="m-0 uppercase text-xs">{data?.request_from}</p>
                            <p className="m-0 capitalize text-xs">{data?.address_from || "-"}</p>
                        </div>
                        <div className="flex justify-center">
                            <QRCode size={70} value={data?.do_no || ""} />
                        </div>
                        <div className="">
                            <table>
                                <tr className="text-xs">
                                    <td className="min-w-[100px]">No</td>
                                    <td>{`: ${data?.do_no}`}</td>
                                </tr>
                                <tr className="text-xs">
                                    <td className="min-w-[100px]">Tanggal</td>
                                    <td>: {moment().format("dddd DD MM yyyy")}</td>
                                </tr>
                                <tr className="text-xs">
                                    <td className="min-w-[100px] max-w[300px]">No So</td>
                                    <td>{`: ${data?.list_so?.join(",")}`}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="flex flex-col mt-5">
                        <table>
                            <thead className="border border-solid border-gray-500">
                                <th className="border border-solid border-gray-500">No</th>
                                <th className="border border-solid border-gray-500">Kode</th>
                                <th className="border border-solid border-gray-500">Nama Produk</th>
                                <th className="border border-solid border-gray-500">Qty</th>
                                <th className="border border-solid border-gray-500">No Koli</th>
                            </thead>
                            <tbody className="border border-solid border-gray-500">
                                {products?.map((el, i) => (
                                    <tr key={i} className="border border-solid border-gray-500">
                                        <td className="border border-solid border-gray-500">{i + 1 + productPerPage * page}</td>
                                        <td className="border border-solid border-gray-500">{el.product_code}</td>
                                        <td className="border border-solid border-gray-500">{el.product_name}</td>
                                        <td className="border border-solid border-gray-500">{el.qty}</td>
                                        <td className="border border-solid border-gray-500">-</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <p className="self-end mt-2">{`Halaman ${page + 1} dari ${listDetailGroup.length}`}</p>
                    </div>
                    <div className="flex items-start justify-between">
                        {["Penerima", "Pengirim", "Petugas Gudang"].map((el, i) => (
                            <div className="flex flex-col items-center" key={i}>
                                <p className="capitalize font-medium text-xs">{el}</p>
                                <p className="text-3xl m-0 mt-1">(...........)</p>
                            </div>
                        ))}
                        <div className="">
                            <table className="text-xs">
                                <tr>
                                    <td className="min-w-[100px]">Catatan</td>
                                    <td className="max-w-[200px]">: {data?.note}</td>
                                </tr>
                                <tr>
                                    <td className="min-w-[100px]">Print</td>
                                    <td>
                                        : - <br />
                                        {moment(currentTime || moment.now()).format(FORMAT_DATE_TIME)}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default Print;

/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface PackageProducts {
    id: number;
    code1: string;
    name: string;
    description: string;
    is_active: number;
    subattribute_name: string;
    images: string[];
}

export interface ChildData {
    id: number;
    qty: number;
    price: number;
}

export interface CreatePackage {
    code1: string;
    name: string;
    description: string;
    subattribute_id: number;
    sku: string;
    is_active: number;
    child: ChildData[];
    images: string[];
}

export interface FilterPackageProduct {
    product_code: any;
    product_name: any;
    is_active: any;
}

export interface ChildPackage {
    id: number;
    product_name: string;
    product_code: string;
    product_desc: null | string;
    price: number;
    qty: number;
}

export interface DetailPackageProduct {
    id: number;
    code1: string;
    name: string;
    description: string;
    subattribute_id: number;
    is_active: number;
    images: string[];
    sku: string;
    child: ChildPackage[];
}

export interface EditPackage extends CreatePackage {
    id: any;
}

export interface SubattributePackage {
    id: number;
    subattribute_code: string;
    subattribute_name: string;
    is_active: number;
}

export interface ChildProduct {
    product_id: number;
    product_name: string;
    product_code1: string;
    product_code2: string;
    molding_code: string;
    type: number;
}

class PackageProductsService extends BaseService {
    example = "";

    createPackage = "/master-product/create-package";

    detail = "/master-product/get-package-by-id";

    getAll = "/master-product/all-package";

    search = "/master-product/search-master-package";

    filter = "/master-product/filter-package";

    editPackage = "/master-product/edit-package";

    subattributePackage = "/master-product/get-subattribute-package";

    getChildProduct = "/master-product/get-child-product";

    constructor() {
        super();
    }

    GetChildProduct<T extends ChildProduct[]>(params: { id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getChildProduct,
                config: { params },
            });
            return req;
        });
    }

    EditPackage<T extends {}>(data: EditPackage, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editPackage,
                data,
                config,
            });
            return req;
        });
    }

    Filter<T extends PackageProducts>(params: FilterPackageProduct) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: { params },
            });
            return req;
        });
    }

    Search<T extends PackageProducts>(params: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    SubattributePackage<T extends SubattributePackage[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.subattributePackage,
            });
            return req;
        });
    }

    GetAll<T extends PackageProducts>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends DetailPackageProduct>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    CreatePackage<T extends {}>(data: CreatePackage, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createPackage,
                data,
                config,
            });
            return req;
        });
    }

    Example<T extends PackageProducts>() {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.example,
            });
            return req;
        });
    }
}

const packageProductsService = new PackageProductsService();
export default packageProductsService;

/* eslint-disable no-plusplus */
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import ModalTemplate, { HandlerProps } from "components/modal/template-modal";
import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import penerimaanBarangService, { DetailPOData, ListData } from "services/api-endpoints/dashboard/master-data/penerimaan-barang";

type Props<T> = {
    warehouse: number | null;
    selectedPO: React.Key[];
    setSelectedPO: React.Dispatch<React.SetStateAction<React.Key[]>>;
    onProductPO: (data: DetailPOData) => void;
    children: (data: HandlerProps) => React.ReactNode;
};

const ModalPurchasingTable = <T extends ListData>({ children, selectedPO, setSelectedPO, warehouse, onProductPO }: Props<T>) => {
    const btnCloseRef = useRef<HTMLButtonElement | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const listQuery = useQuery(
        [penerimaanBarangService.listData, warehouse],
        async () => {
            return (await penerimaanBarangService.ListData<T[]>({ bp_id: warehouse })).data.data;
        },
        {
            enabled: !!warehouse,
        }
    );

    const detailPOMutation = useMutation([penerimaanBarangService.detailPO], async (id: string) => {
        // return (await bpGoodsReceiptService.DetailProduct({ do_id: ids })).data.data;
        return (await penerimaanBarangService.DetailPO({ id })).data.data;
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // const termsId = listQuery.data?.filter((po) => newSelectedRowKeys.includes(po.id as any))?.map((po) => po.payment_term_id);
        // if ([...new Set(termsId)].length !== 1) {
        //     setCanSubmit(false);
        // } else {
        //     setCanSubmit(true);
        //     setTopId(termsId !== undefined ? termsId[0]! : null);
        // }

        setCanSubmit(newSelectedRowKeys.length === 1);
        setSelectedPO(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedPO,
        onChange: onSelectChange,
        selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t: any, r: any, i: number) => <p className="capitalize m-0">{i + 1}</p>,
        },
        {
            title: "Po No",
            dataIndex: "po_no",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Term Of Payment",
            dataIndex: "payment_term_code",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total SKU",
            dataIndex: "total_sku",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Total Item",
            dataIndex: "total_item",
            render: (text: string) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    const onOkHandler = () => {
        detailPOMutation
            .mutateAsync(selectedPO.join(","))
            .then((res) => {
                onProductPO(res);
            })
            .finally(() => btnCloseRef.current && btnCloseRef.current.click());
    };

    return (
        <ModalTemplate
            width={700}
            title="List Purchasing Order"
            handlerInComponent={children}
            onOk={onOkHandler}
            okButtonProps={{ disabled: !canSubmit, loading: detailPOMutation.isLoading }}
        >
            {(dt) => (
                <>
                    <button ref={btnCloseRef} className="hidden" type="button" onClick={dt.closeModal}>
                        cls
                    </button>
                    <p className="capitalize m-0 mb-2 text-xs text-red-400">Select 1 Purchasing Order</p>
                    <Table
                        rowKey={(i) => i.id as any}
                        size="small"
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={listQuery.data || []}
                        loading={listQuery.isLoading}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalPurchasingTable;

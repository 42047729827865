import React from "react";
import { Button, Space, Table } from "antd";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { LocatorFrom, LocatorTo, ProductDocInventory } from "../models";

type Props<T> = {
    list: T[];
    onRemove: (dt: T) => void;
};

const LocatorTable = <T extends ProductDocInventory>({ list, onRemove }: Props<T>) => {
    const columns = [
        {
            width: "50px",
            title: "No",
            render: (id: any, record: any, index: number) => <p className="capitalize m-0">{index + 1}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text: any) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Locator From",
            children: [
                {
                    title: "Locator Name",
                    dataIndex: "locator_from",
                    render: (record: LocatorFrom[]) => <p className="capitalize m-0">{record[0].locator_name || "-"}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "locator_from",
                    render: (record: LocatorFrom[]) => <p className="capitalize m-0">{record[0].qty_from_before || ""}</p>,
                },
                {
                    title: "Qty Transfer",
                    dataIndex: "qty_transfer",
                    render: (text: any) => <p className="capitalize m-0">{text || "0"}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "locator_from",
                    render: (record: LocatorFrom[]) => <p className="capitalize m-0">{record[0].qty_from_after || "0"}</p>,
                },
            ],
        },
        {
            title: "Locator To",
            dataIndex: "locator_to",
            children: [
                {
                    title: "Locator Name",
                    dataIndex: "locator_to",
                    render: (record: LocatorTo[]) => <p className="capitalize m-0">{record[0].locator_name || "-"}</p>,
                },
                {
                    title: "Qty Before",
                    dataIndex: "locator_to",
                    render: (record: LocatorTo[]) => <p className="capitalize m-0">{record[0].qty_to_before || "0"}</p>,
                },
                {
                    title: "Qty Transfer",
                    dataIndex: "qty_transfer",
                    render: (text: any) => <p className="capitalize m-0">{text || "0"}</p>,
                },
                {
                    title: "Qty After",
                    dataIndex: "locator_to",
                    render: (record: LocatorTo[]) => <p className="capitalize m-0">{record[0].qty_to_after || "0"}</p>,
                },
            ],
        },
        {
            width: "50px",
            title: "Action",
            key: "action",
            render: (record: T) => (
                <Space size="middle" direction="horizontal">
                    <Button onClick={() => onRemove(record)} type="text">
                        <MdOutlineDeleteOutline className="text-lg" />
                    </Button>
                </Space>
            ),
        },
    ];

    return <Table size="small" columns={columns} dataSource={list || []} className="w-full" bordered />;
};

export default LocatorTable;

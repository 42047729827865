import { Button, DatePicker, Form, Input, message } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment from "moment";
import { useRef } from "react";
import { useMutation } from "react-query";
import holidayService, { HolidayCreate } from "services/api-endpoints/dashboard/master-data/holiday";
import { FORMAT_DATE_1 } from "utils/constant";

type Props = ModalProps & {
    refetchAll: () => void;
};

const ModalAddHoliday = ({ refetchAll, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const createHolidayMutate = useMutation(
        [holidayService.create],
        async (data: HolidayCreate) => {
            return (await holidayService.Create(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Data created!");
            },
        }
    );

    const onFinish = (values: any) => {
        createHolidayMutate
            .mutateAsync({
                ...values,
                start_date: moment(values.range_date[0], FORMAT_DATE_1),
                end_date: moment(values.range_date[1], FORMAT_DATE_1),
            })
            .finally(() => {
                closeRef.current?.click();
                refetchAll();
            });
    };

    return (
        <ModalTemplate stateWithUrlParams="add" title="Add Master Holiday" {...props} handlerInComponent={children} footer={null}>
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Form disabled={createHolidayMutate.isLoading} onFinish={onFinish} autoComplete="off" requiredMark={false} layout="vertical">
                        <Form.Item label="Range Date" name="range_date" rules={[{ required: true, message: "Please input range date!" }]}>
                            <DatePicker.RangePicker />
                        </Form.Item>

                        <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input description!" }]}>
                            <Input.TextArea placeholder="Write here" />
                        </Form.Item>

                        <Form.Item>
                            <Button loading={createHolidayMutate.isLoading} type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalAddHoliday;

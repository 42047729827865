import { motion } from "framer-motion";
import { MenuTypes } from "modules/manufacture/utils/constant";
import { Entity, MaterialEntity, ModelEntity, OperatorEntity, ProcessEntity, VariableEntity } from "modules/manufacture/utils/models";
import CardMultipleOperatorItem from "../multiple-operator/item";
import CardSubProcessItem from "../sub-process/item";

type PlaceholderOperatorProps = {
    entity: Entity;
};

const PlaceholderOperator = ({ entity }: PlaceholderOperatorProps) => {
    if (entity?.asType === MenuTypes.model.type) {
        return (
            <motion.img
                initial={{ scale: 0.5 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.5 }}
                className="max-w-[300px] object-contain p-1"
                src={(entity as Entity<ModelEntity>)?.data?.preview}
                alt={(entity as Entity<ModelEntity>)?.data?.text}
            />
        );
    }
    if (entity?.type === MenuTypes.subProcess.type) {
        const entityOperator = entity as Entity<ProcessEntity<MaterialEntity & VariableEntity>>;
        return (
            <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} exit={{ scale: 0.5 }} className="p-1">
                <CardSubProcessItem
                    asPlaceholder
                    attach={entityOperator}
                    childrenShape={
                        <div className="text-sm font-medium text-gray-500 text-center">{entityOperator?.data?.children?.length || 0} Items</div>
                    }
                >
                    <span className="text-sm font-light capitalize">{entityOperator?.data?.text}</span>
                </CardSubProcessItem>
            </motion.div>
        );
    }
    // if (entity?.type === MenuTypes.operator.type) {
    //     const entityOperator = entity as Entity<OperatorEntity>;
    //     return (
    //         <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} exit={{ scale: 0.5 }} className="p-1">
    //             <CardOperatorItem asPlaceholder attach={entityOperator}>
    //                 <span className="text-sm font-light capitalize">{entityOperator?.data?.text}</span>
    //             </CardOperatorItem>
    //         </motion.div>
    //     );
    // }
    if (entity?.type === MenuTypes.multipleOperator.type) {
        const entityOperator = entity as Entity<OperatorEntity>;
        return (
            <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} exit={{ scale: 0.5 }} className="p-1">
                <CardMultipleOperatorItem asPlaceholder attach={entityOperator}>
                    <span className="text-sm font-light capitalize">{entityOperator?.data?.text}</span>
                </CardMultipleOperatorItem>
            </motion.div>
        );
    }
    return <div className="text-center text-sm">Not Catch Type</div>;
};

export default PlaceholderOperator;

/* eslint-disable no-restricted-globals */
import { Button, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";

export type ChildrenFilterModalProps = {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    values: { [key: string]: any };
    setOneValue: (name: string, value: any) => void;
    onClickClearHandler: () => void;
};

type Props = {
    title?: string;
    children: (data: ChildrenFilterModalProps) => void;
    forms: (data: ChildrenFilterModalProps) => void;
    filter: string[];
    autoClose?: boolean;
};

const FilterModal = ({ children, title = "Filter", filter = [], forms, autoClose = true }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { filterValue } = useTypeSearchUrl({ filter });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [values, setValues] = useState<{ [key: string]: any }>(filterValue);

    useEffect(() => {
        const paramsObject = Object.fromEntries(
            Array.from(searchParams.entries()).map(([key, value]) => [key, isNaN(value as any) ? value : Number(value)])
        );
        setValues(paramsObject);
    }, [searchParams]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const onClickFilterHandler = () => {
        if (autoClose) {
            closeModal();
        }
        Object.keys(values).forEach((key) => {
            if (values[key] === null || values[key] === undefined) {
                searchParams.delete(key);
                return;
            }
            searchParams.set(key, values[key]);
        });
        searchParams.delete("query");
        searchParams.set("page", "1");
        setSearchParams(searchParams);
    };

    const onClickClearHandler = () => {
        if (autoClose) {
            closeModal();
        }
        setValues({});
        filter.forEach((ftr) => {
            searchParams.delete(ftr);
        });
        setSearchParams(searchParams);
    };

    const setOneValue = (name: string, value: any) => {
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const childrenData: ChildrenFilterModalProps = {
        isModalOpen,
        openModal,
        closeModal,
        values,
        setOneValue,
        onClickClearHandler,
    };

    return (
        <>
            <Modal width={800} title={title} open={isModalOpen} onCancel={closeModal} footer={null}>
                <Space direction="vertical" className="w-full">
                    <>
                        {forms(childrenData)}
                        <Row justify="start" className="mt-10">
                            <Space>
                                <Button type="text" onClick={onClickClearHandler}>
                                    Clear
                                </Button>
                                <Button type="primary" onClick={onClickFilterHandler}>
                                    Filter
                                </Button>
                            </Space>
                        </Row>
                    </>
                </Space>
            </Modal>
            {children(childrenData)}
        </>
    );
};

export default FilterModal;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface Reference {
    ref_id?: number;
    ref_no?: string;
}

export interface ExtReturnOrder {
    id?: number;
    return_no?: string;
    status?: number;
    created_by?: string;
    created_at?: number;
    references?: Reference[];
}

export interface ExtReturnOrderListDetail {
    order_detail_id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    code2?: null;
    qty_order?: number;
    qty_return?: number;
    stock_action?: number;
}

export interface DetailExtReturnOrder {
    id?: number;
    name?: string;
    address?: string;
    inv_type?: number;
    status_approve?: boolean;
    bp_id?: number;
    bp_name?: string;
    doc_no?: string;
    references?: Reference[];
    reason_id?: number;
    reason?: string;
    impact_type?: string;
    order_status?: number;
    list_detail?: ExtReturnOrderListDetail[];
    area_code?: string;
    area_id?: number;
    warehouse_id?: number;
    warehouse_name?: string;
}

export interface Order {
    bp_id?: number;
    bp_name?: string;
    created_at?: number;
    inv_no?: string;
    order_id?: number;
    total_item?: number;
    total_sku?: number;
}

export interface DetailOrder {
    inv_no?: string;
    discount_price?: number;
    id?: number;
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_owner?: string;
    product_price?: number;
    qty?: number;
    stock_action?: any;
    qty_return?: any;
    area_id?: number;
    area_name?: string;
    qty_locator?: number;
}

export interface ReturnOrderDetail {
    order_id?: number | string;
    product_id?: number | string;
    qty_order?: number | string;
    qty_return?: number | string;
    stock_action?: number | string;
    area_id?: number | string;
}

export interface CreateReturnOrder {
    inv_type?: number;
    bp_id?: number;
    reason_id?: number;
    order_id?: number[];
    impact_type?: number;
    warehouse_id?: number;
    return_order_detail?: ReturnOrderDetail[];
}

export interface LocatorData {
    product_id?: number;
    product_name?: string;
    area_id?: number;
    area_name?: string;
    qty_locator?: number;
}

class ExtReturnOrderService extends BaseService {
    getList = "/ext-order/list-return-order";

    detail = "/ext-order/detail-return-order";

    getDataOrder = "/ext-order/get-data-order";

    getDetailOrder = "/ext-order/get-product-return";

    create = "/ext-order/create-return-order";

    approve = "/ext-order/approve-return-order";

    locator = "/ext-order/get-locator-retur";

    constructor() {
        super();
    }

    Locator<T extends LocatorData[]>(params?: { product_id?: any; type: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.locator,
                config: { params },
            });
            return req;
        });
    }

    Approve<T extends any>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.approve}?id=${params.id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateReturnOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetDetailOrder<T extends DetailOrder[]>(params?: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDetailOrder,
                config: { params },
            });
            return req;
        });
    }

    GetDataOrder<T extends Order[]>(params?: { query?: any; partner_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDataOrder,
                config: { params },
            });
            return req;
        });
    }

    Detail<T extends DetailExtReturnOrder>(id: any) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    GetList<T extends ExtReturnOrder>(params: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getList,
                config: { params },
            });
            return req;
        });
    }
}

const extReturnOrderService = new ExtReturnOrderService();
export default extReturnOrderService;

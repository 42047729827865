import Utils, { GetRoutes } from "utils";
import { documentMenu, paymentMenu } from "./apps";

export default {};
export const APPS_PATH = "/apps";
export const SETTING_PATH = "/setting";
export const CHANGE_PASSWORD_PATH = "/change-password";

export const getDocumentRoute = (data: Omit<GetRoutes, "menu">) => {
    return Utils.getRouteApp({ menu: documentMenu, ...data });
};

export const getPaymentRoute = (data: Omit<GetRoutes, "menu">) => {
    return Utils.getRouteApp({ menu: paymentMenu, ...data });
};

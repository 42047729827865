import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { TableRowSelection } from "antd/lib/table/interface";
import { ProductWithQty } from "modules/advance-search/models";
import { Dispatch, Key, SetStateAction, useState } from "react";

type Props<T> = {
    product?: T[];
    setSelectedRowKeys?: Dispatch<SetStateAction<Key[]>>;
    selectedRowKeys?: Key[];
};

const TableCustomVoucher = <T extends ProductWithQty>({ product, setSelectedRowKeys, selectedRowKeys }: Props<T>) => {
    const [page, setPage] = useState<number>(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        if (setSelectedRowKeys) setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (t, r, i) => <p className="capitalize m-0">{(page - 1) * 10 + (i + 1)}</p>,
        },
        {
            title: "Product Name",
            dataIndex: "product_name",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
        {
            title: "Code",
            dataIndex: "product_code",
            render: (text) => <p className="capitalize m-0">{text || "-"}</p>,
        },
    ];

    return (
        <Table
            rowSelection={rowSelection}
            rowKey={(record) => record.product_id as any}
            size="small"
            columns={columns}
            dataSource={product}
            onChange={handleTableChange}
        />
    );
};

export default TableCustomVoucher;

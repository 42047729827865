import { message, Skeleton } from "antd";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { ProductWithBOM } from "pages/manufacturing-order/add";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import manufacturingOrderService, {
    MultipleChildBOM,
    ProductManufacturingOrder,
} from "services/api-endpoints/dashboard/manufacture/manufacturing-order";
import GetProductManufacturingOrder from "./product";
import { BOMWithLocatorAndNote } from "./table";

type Props = ModalProps & {
    selected: ProductWithBOM[];
    setSelected: React.Dispatch<React.SetStateAction<ProductWithBOM[]>>;
};

const ModalProductManufacturingOrder = ({ selected, setSelected, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const detailProduct = useMutation([manufacturingOrderService.getProductDetail], async (id: any) => {
        const result = (await manufacturingOrderService.GetProductDetail({ product_id: id })).data.data;
        return result?.bom?.map((b) => {
            const temp = { ...b, qty_bom_temp: b.qty_bom, child: [] };

            if ("child" in b && (b as MultipleChildBOM).child.length) {
                return {
                    ...temp,
                    child: (b as MultipleChildBOM).child.map((c) => ({ ...c, qty_bom_temp: c.qty_bom })),
                };
            }

            return temp;
        }) as BOMWithLocatorAndNote[];
    });

    const onSearchSelectProductHandler = async (product: ProductManufacturingOrder) => {
        if (selected.find((p) => p.product_id === product.product_id)) {
            message.error("Product already exist!");
            return;
        }
        if (setSelected) {
            detailProduct.mutateAsync(product.product_id).then((bom) => {
                setSelected((prev) =>
                    [...prev, { ...product, qty: 1, product_code: product.product_code1, child: bom }].map((el, i) => ({ ...el, no: i }))
                );
            });
        }
    };

    const onSearchDeSelectProductHandler = (product: ProductManufacturingOrder) => {
        if (setSelected) setSelected((prev) => [...prev].filter((p) => p.product_id !== product.product_id));
    };

    const GetAllProductMemoize = React.useMemo(
        () => (
            <GetProductManufacturingOrder
                selected={selected}
                onSelectProduct={onSearchSelectProductHandler}
                onDeSelectProduct={onSearchDeSelectProductHandler}
            />
        ),
        [selected]
    );

    return (
        <ModalTemplate
            title="Select Products"
            width={700}
            handlerInComponent={detailProduct.isLoading ? () => <p>Getting BOM data...</p> : children}
            footer={null}
            {...props}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <React.Suspense fallback={<Skeleton paragraph={{ rows: 1 }} active />}>{GetAllProductMemoize}</React.Suspense>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalProductManufacturingOrder;

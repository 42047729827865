import { ColProps, SwitchProps } from "antd";
import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import InputSwitch from "../inputs/input-switch";

export type ControlledInputSwitchProps<T extends FieldValues> = SwitchProps & {
    placeholder: string;
    label?: string;
    control: Control<T, any>;
    name: Path<T>;
    labelCol?: ColProps;
    classNameForm?: string;
};

const ControlledSwitchInput = <T extends FieldValues>({
    label,
    control,
    placeholder,
    name,
    loading,
    labelCol,
    ...rest
}: ControlledInputSwitchProps<T>) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <InputSwitch {...field} {...rest} label={label || ""} labelCol={labelCol} error={error?.message} loading={loading} />
            )}
        />
    );
};

export default ControlledSwitchInput;

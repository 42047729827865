import { Select } from "antd";
import FilterModal, { ChildrenFilterModalProps } from "components/modal/filter-modal";
import { SelectOption } from "models";
import React from "react";
import { useQuery } from "react-query";
import styleService from "services/api-endpoints/dashboard/master-data/style";
import { STATUS_ACTIVE } from "utils/constant";

type Props = {
    children: (data: ChildrenFilterModalProps) => void;
    filter?: string[];
};

const FilterStyle = ({ filter = [], ...props }: Props) => {
    const styleNameQuery = useQuery([styleService.suggestStyleName], async () => {
        const req = await styleService.SuggestStyleName({ style_name: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    const styleCodeQuery = useQuery([styleService.suggestStyleCode], async () => {
        const req = await styleService.SuggestStyleCode({ style_code: "" });
        return req.data.data?.map((el) => ({ label: el, value: el } as SelectOption));
    });

    return (
        <FilterModal
            filter={filter}
            title="Filter Style"
            forms={(dt) => {
                return (
                    <div className="grid grid-cols-2 gap-4">
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.style_name}
                            loading={styleNameQuery.isLoading}
                            placeholder="Style Name"
                            options={styleNameQuery.data || []}
                            onChange={(value) => dt.setOneValue("style_name", value)}
                        />
                        <Select
                            showSearch
                            allowClear
                            value={dt.values.style_code}
                            loading={styleCodeQuery.isLoading}
                            placeholder="Style Code"
                            options={styleCodeQuery.data || []}
                            onChange={(value) => dt.setOneValue("style_code", value)}
                        />
                        <Select
                            allowClear
                            value={
                                dt.values.is_active !== null && dt.values.is_active !== undefined ? Number(dt.values.is_active) : dt.values.is_active
                            }
                            placeholder="Status"
                            options={STATUS_ACTIVE}
                            onChange={(value) => dt.setOneValue("is_active", value)}
                        />
                    </div>
                );
            }}
            {...props}
        />
    );
};

export default FilterStyle;

/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface RoleMenuList {
    id?: string;
    description?: string;
    role_name?: string;
    apps_name?: number;
}

export interface Collapse {
    name?: string;
    key?: string;
    icon?: string;
    collapse?: Collapse[];
}

export interface RoleMenuRequest {
    id?: any;
    apps_id?: number;
    role_id?: number;
    description?: string;
    role_access?: string[];
    collapse?: Collapse[];
}

export const MENU_STATUS_ACTIVE = [
    { value: 1, label: "Active" },
    { value: -1, label: "Non Active" },
];

export interface ListApps {
    id?: number;
    name?: string;
    description?: string;
    icon?: string;
    theme_color?: string;
    is_active?: number;
}

export interface ListRole {
    id: number;
    name: string;
    is_active: number;
}

export interface ListMenu {
    id: any;
    key: string;
    name: string;
    icon: string;
}

export interface DetailRoleMenu {
    id: number;
    collapse: Collapse[];
    description: string;
    role_id: number;
    role_name: string;
    apps_id: number;
    apps_name: string;
    role_access: string[];
}

class RoleMenuService extends BaseService {
    createRoleMenu = "/manage/create-role-menu";

    listRoleMenu = "/manage/list-role-menu?query";

    listApp = "/manage/get-apps";

    listRole = "/manage/get-role";

    getMenu = "/manage/get-menu";

    getSubMenu = "manage/get-sub-menu";

    getChildMenu = "/manage/get-child-menu";

    detailRoleMenu = "/manage/detail-role-menu";

    constructor() {
        super();
    }

    DetailRoleMenu<T extends DetailRoleMenu>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailRoleMenu,
                config: { params },
            });
            return req;
        });
    }

    GetChildMenu<T extends ListMenu[]>(params: { key: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getChildMenu,
                config: { params },
            });
            return req;
        });
    }

    GetSubMenu<T extends ListMenu[]>(params: { key: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getSubMenu,
                config: { params },
            });
            return req;
        });
    }

    GetMenu<T extends ListMenu[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getMenu,
            });
            return req;
        });
    }

    ListRole<T extends ListRole[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listRole,
            });
            return req;
        });
    }

    ListApp<T extends ListApps[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listApp,
            });
            return req;
        });
    }

    CreateRoleMenu<T extends any>(data: Omit<RoleMenuRequest, "id">) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createRoleMenu,
                data,
            });
            return req;
        });
    }

    ListRoleMenu<T extends RoleMenuList[]>(params: { query?: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.listRoleMenu,
                config: { params },
            });
            return req;
        });
    }
}

const roleMenuService = new RoleMenuService();
export default roleMenuService;

import { Alert, Card, Skeleton } from "antd";
import State from "components/common/state";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import reportService, { TopTransaction as TopTransactionType } from "services/api-endpoints/dashboard-giias/report";
import { TYPE_POS_OFFLINE } from "utils/constant";
import ModalInvoiceTransaction from "./modal-invoice-transaction";

const ProductItem = ({ item, ...props }: React.HTMLAttributes<HTMLButtonElement> & { item: TopTransactionType }) => {
    return (
        <button
            type="button"
            className="m-0 capitalize text-start pt-4 text-gray-700 text-xs font-bold cursor-pointer w-full bg-transparent border-none hover:bg-gray-100"
            {...props}
        >
            <div className="flex w-full items-start justify-between">
                <span className="w-[60%]">{item.sales_name}</span>
                <span>{item.total_transaction} Transaction</span>
            </div>
            <span className="capitalize font-medium text-gray-400">{item.total_price?.ToIndCurrency("Rp")}</span>
            <div className="w-full bg-gray-200 mt-4" style={{ height: 1 }} />
        </button>
    );
};

const TopTransaction = () => {
    const [searchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const typeSales = searchParams.get("type") || TYPE_POS_OFFLINE;

    const watchParams = [startDate, endDate, typeSales];

    const topTenQuery = useQuery([reportService.topTransaction, ...watchParams], async () => {
        return (await reportService.TopTransaction({ start_date: startDate, end_date: endDate, type: typeSales as any })).data.data;
    });

    return (
        <Card className="h-full">
            <State data={topTenQuery.data} isLoading={topTenQuery.isLoading} isError={topTenQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <p className="m-4 text-center font-light text-xl text-gray-500 capitalize">top transaction</p>
                            <div className="w-full h-[350px] 2xl:h-[430px] overflow-auto pr-3">
                                {topTenQuery.data?.map((item) => (
                                    <ModalInvoiceTransaction key={item.sales_id} item={item}>
                                        {(ctrl) => <ProductItem item={item} onClick={ctrl.openModal} />}
                                    </ModalInvoiceTransaction>
                                ))}
                            </div>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 3 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert message={(topTenQuery.error as any)?.message} type="error" />
                        </State.Error>
                    </>
                )}
            </State>
        </Card>
    );
};

export default TopTransaction;

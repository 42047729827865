import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterCategory from "modules/master-data/category/index/filter";
import { TDataCategory } from "modules/master-data/category/models";
import CategoryTable from "modules/master-data/category/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import categoryService from "services/api-endpoints/dashboard/master-data/category";

const filter = ["category_name", "category_code", "category_type", "is_active"];

const Category = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([categoryService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await categoryService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await categoryService.Filter(filterValue as any)).data.data;
        return (await categoryService.GetAll({ page })).data.data;
    });

    const deleteMutation = useMutation(async (data: TDataCategory) => (await categoryService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataCategory>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterCategory filter={filter}>
                {(dt) => (
                    <Toolbar placeholder="Category Name / Category Type" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />
                )}
            </FilterCategory>
            <CategoryTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Category;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, message, Space } from "antd";
import ControlledInputDate from "components/form/controlled-inputs/controlled-input-date";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputTextArea from "components/form/controlled-inputs/controlled-input-textarea";
import ToolbarAction from "components/toolbar/action";
import { SelectOption } from "models";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import { CustomVoucher } from "modules/dashboard-all/sales/models";
import ListExpenses from "modules/purchasing/inventory/add/list-expenses";
import ModalAddExpenses from "modules/purchasing/inventory/add/modal-add-expenses";
import ModalAddRequest from "modules/purchasing/inventory/add/modal-add-request";
import ModalCustomVoucher from "modules/purchasing/inventory/add/modal-custom-voucher";
import PaymentMethodSelect from "modules/purchasing/inventory/lib/payment-method-select";
import ShippingSelect from "modules/purchasing/inventory/lib/shipping-select";
import { transformChild } from "modules/purchasing/inventory/lib/utils";
import TenderPurchasing from "modules/purchasing/inventory/view/tender";
import moment from "moment";
import { createContext, Key, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSave } from "react-icons/bi";
import { MdAdd, MdRefresh } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ExpansesData } from "services/api-endpoints/dashboard/receipt";
import purchasingInventoryService, {
    BodyCreatePurchasingInventory,
    GetSummary,
    InventoryItem,
    POPrice,
} from "services/api-endpoints/purchasing/inventory";
import purchasingRequestService, { ChildRequest } from "services/api-endpoints/purchasing/purchasing-request";
import Utils from "utils";
import { AUTOGENERATED, FORMAT_DATE_1, TYPE_TAX, TYPE_TAX_INCLUDED, TYPE_TAX_TAXABLE } from "utils/constant";
import * as yup from "yup";

export interface CreatePurchasingInventory
    extends Omit<BodyCreatePurchasingInventory, "id" | "voucher" | "child" | "total_tax" | "total_disc" | "total_price" | "grand_total"> {
    _?: any;
}

export interface VendorColumn {
    vendors?: {
        [columnIndex: number]: {
            vendor_id: any;
            price: any;
        };
    };
}

export interface DocReqChild extends ChildRequest, VendorColumn {
    _?: any;
}

const schema: yup.SchemaOf<Partial<Omit<CreatePurchasingInventory, "vendor_id" | "_">>> = yup.object().shape({
    payment_term_id: yup.number().required("Payment required"),
    shipping_id: yup.number().required("Shipping required"),
    tax_type: yup.number().required("Tax Type required"),
    delivery_date: yup.string().required("Delivery date required"),
    delivery_address: yup.string().required("Delivery address required"),
    transaction_date: yup.string().required("Transaction date required"),
    tax_id: yup.number(),
    doc_id: yup.number(),
    pr_id: yup.array(),
    doc_type: yup.number(),
    expenses: yup.array(),
    note: yup.string(),
});

export const PurchasingContext = createContext<any>({});

const PurchasingInventoryAddContext = () => {
    const navigate = useNavigate();
    const { setDocReqChild, getVendor, pickedVendor, docReqChild, setProblemChild, isRefresh } = useContext(PurchasingContext);

    const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);
    const [prNo, setPrNo] = useState<string>("");
    const [selectedKey, setSelectedKey] = useState<Key[]>([]);
    const [expanses, setExpanses] = useState<ExpansesData[]>([]);
    const [selectedPR, setSelectedPR] = useState<Key[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch, setValue } = useForm<CreatePurchasingInventory>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchTaxType = watch("tax_type");
    const watchTaxId = watch("tax_id");

    const createMutation = useMutation(
        [purchasingInventoryService.save],
        async (data: Partial<BodyCreatePurchasingInventory>) => {
            return (await purchasingInventoryService.Save(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing Order Created");
                navigate(-1);
            },
        }
    );

    const createDraftMutation = useMutation(
        [purchasingInventoryService.draft],
        async (data: Partial<BodyCreatePurchasingInventory>) => {
            return (await purchasingInventoryService.Draft(data)).data.data;
        },
        {
            onSuccess: () => {
                message.success("Purchasing Order Draft Created");
                navigate(-1);
            },
        }
    );

    const getTax = useQuery([purchasingInventoryService.getTax], async () => {
        return (await purchasingInventoryService.GetTax()).data.data;
    });

    const getTaxOption = getTax.data?.map((el) => ({ label: el.tax_name, value: el.tax_id } as SelectOption));

    const getSummaryPrice = useMutation(
        [purchasingInventoryService.getSummary],
        async (data: GetSummary) => {
            return (await purchasingInventoryService.GetSummary(data)).data.data;
        },
        {
            onSuccess(data) {
                setDocReqChild((prev: DocReqChild[]) => {
                    return prev.map((doc: DocReqChild) => {
                        const matchingProd = data.list_discount.find((discount) => discount.product_id === doc.product_id);
                        if (matchingProd) {
                            return {
                                ...doc,
                                discount_price: matchingProd.discount_price,
                                discount_name: matchingProd.discount_name,
                                discount_qty: matchingProd.qty,
                            };
                        }
                        return {
                            ...doc,
                            discount_price: undefined,
                            discount_name: undefined,
                            discount_qty: undefined,
                        };
                    });
                });
            },
        }
    );

    // const detailRequest = useMutation(
    //     [purchasingInventoryService.detailRequest],
    //     async (type: any) => {
    //         return (await purchasingInventoryService.DetailRequest({ pr_id: selectedPR.join(","), type })).data.data;
    //     },
    //     {
    //         onSuccess(data) {
    //             const temp = data.map((val) => ({ ...val, qty_max: val.qty }));
    //             setDocReqChild(temp);
    //         },
    //     }
    // );

    const onSaveHandler = async () => {
        setTypeSave("submit");
        await form.validateFields();
        form.submit();
    };

    const onDraftHandler = async () => {
        setTypeSave("draft");
        await form.validateFields();
        form.submit();
    };

    const parseData: Partial<BodyCreatePurchasingInventory> = {
        vendor_id: pickedVendor,
        child: transformChild(docReqChild).allChild,
        total_tax: getSummaryPrice.data?.total_pajak,
        total_disc: getSummaryPrice.data?.total_discount,
        total_price: getSummaryPrice.data?.total_price,
        grand_total: getSummaryPrice.data?.grand_total,
    };

    const onSubmitHandler = handleSubmit((data) => {
        const { problemChild } = transformChild(docReqChild);

        if (!parseData.child?.length) {
            message.error("No Record Child Found!");
            return;
        }

        if (!parseData.vendor_id) {
            message.error("Pick Vendor First!");
            return;
        }

        if ((watchTaxType === TYPE_TAX_TAXABLE || watchTaxType === TYPE_TAX_INCLUDED) && !watchTaxId) {
            message.error("Please Choose Tax!");
            return;
        }

        setProblemChild(problemChild);
        if (problemChild.length) return;

        const product = parseData.child
            .filter((child) => selectedKey.find((key) => child.product_id === key && child.vendor_id === parseData.vendor_id))
            .map((child) => ({ id: child.product_id, price: child.price, qty: child.qty, note: child.note }));

        const parseData2: Partial<BodyCreatePurchasingInventory> = {
            ...data,
            ...parseData,
            pr_id: selectedPR.map((val) => Number(val)).sort((a, b) => a - b),
            delivery_date: moment(data.delivery_date).format(FORMAT_DATE_1),
            transaction_date: moment(data.transaction_date).format(FORMAT_DATE_1),
            voucher: customVouchers.map((voucher) => ({ ...voucher, product })),
            expenses: expanses.map((val) => ({ ...val, id: val.charge_id, name: val.charge_name })),
        };

        if (typeSave === "submit") createMutation.mutate(parseData2);
        else createDraftMutation.mutate(parseData2);
    });

    const onRemoveCustomVoucher = (index: number) => {
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    const onChooseRequest = (type: any, doc: any) => {
        setPrNo(doc);
        setValue("doc_type", type);
    };

    const childFilter = parseData.child?.filter((val) => val.vendor_id === parseData.vendor_id && val.price !== null);

    const onSummaryPrice = () => {
        if (!parseData.child?.length) {
            message.error("No record child found!");
            return;
        }

        if (!parseData.vendor_id) {
            message.error("Pick vendor first!");
            return;
        }

        const product = parseData.child
            .filter((child) => selectedKey.find((key) => child.product_id === key && child.vendor_id === parseData.vendor_id))
            .map((child) => ({ id: child.product_id, price: child.price, qty: child.qty }));

        const summaryData: GetSummary = {
            child: childFilter || [],
            tax_type: watchTaxType || 2,
            tax_value: getTax.data?.find((val) => val.tax_id === watchTaxId)?.tax_value || 0,
            voucher: customVouchers.map((vouc) => ({ ...vouc, product })),
            total_expenses: expanses.map((ex) => ex.amount || 0),
        };
        getSummaryPrice.mutate(summaryData);
    };

    const onAddExpenses = (data: ExpansesData) => {
        setExpanses((prev) => [...prev, data]);
    };

    const onRemoveExpenses = (index: number) => {
        setExpanses((prev) => prev.filter((_, i) => i !== index));
    };

    useEffect(() => {
        if (!childFilter?.length) return;
        onSummaryPrice();
    }, [customVouchers, watchTaxId, watchTaxType, pickedVendor, isRefresh, expanses]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new purchasing order"
                rightAddition={() => (
                    <>
                        <Button
                            loading={createDraftMutation.isLoading}
                            disabled={!childFilter?.length}
                            onClick={onDraftHandler}
                            type="default"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <RiDraftLine className="m-0 mr-2" />
                            Draft
                        </Button>
                        <Button
                            loading={createMutation.isLoading}
                            disabled={!childFilter?.length}
                            onClick={onSaveHandler}
                            type="primary"
                            className="!flex !items-center"
                            htmlType="button"
                        >
                            <BiSave className="m-0 mr-2" />
                            Save
                        </Button>
                    </>
                )}
            />
            <Card className="">
                <div className="flex items-center justify-between mb-5">
                    <h1 className="m-0">Inventory Information</h1>
                    <ModalAddExpenses onCreate={onAddExpenses}>
                        {(dt) => (
                            <Button onClick={dt.openModal} type="primary" className="!self-start">
                                Add Expenses
                            </Button>
                        )}
                    </ModalAddExpenses>
                </div>
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-10 gap-6">
                        <div className="col-span-2 gap-2">
                            <p className="capitalize m-0 font-semibold text-gray-700 mb-10">
                                document no <br />
                                <span className="underline italic m-0 font-normal text-gray-500">{AUTOGENERATED}</span>
                            </p>
                            <ControlledSelectInput control={control} name="tax_type" placeholder="Tax Type" label="Tax Type" options={TYPE_TAX} />
                        </div>

                        <div className="col-span-2 gap-6">
                            <PaymentMethodSelect
                                classNameForm="col-span-2"
                                showSearch
                                allowClear
                                control={control}
                                name="payment_term_id"
                                label="Payment Method"
                                placeholder="Payment Method"
                            />
                            <ControlledSelectInput
                                control={control}
                                name="tax_id"
                                placeholder="Tax"
                                label="Tax"
                                options={getTaxOption}
                                disabled={watchTaxType !== TYPE_TAX_TAXABLE && watchTaxType !== TYPE_TAX_INCLUDED}
                            />
                        </div>

                        <div className="col-span-2 gap-6">
                            <p className="capitalize m-0 text-gray-700 mb-2">Custom Discount</p>
                            <ModalCustomVoucher
                                onCreate={onCustomVoucherCreate}
                                doc={docReqChild}
                                selectedRowKeys={selectedKey}
                                setSelectedRowKeys={setSelectedKey}
                            >
                                {(dt) => (
                                    <div className="w-full flex flex-col gap-1">
                                        <Button
                                            onClick={dt.openModal}
                                            disabled={customVouchers.length === 2 || !parseData.vendor_id || !parseData.child?.length}
                                            className="!w-full !flex !items-center !justify-between"
                                        >
                                            Custom Discount <MdAdd />
                                        </Button>
                                        <ListCustomVoucher vouchers={customVouchers} onRemoveVoucher={onRemoveCustomVoucher} />
                                    </div>
                                )}
                            </ModalCustomVoucher>
                            <br />
                            <ShippingSelect
                                showSearch
                                allowClear
                                classNameForm="col-span-2"
                                control={control}
                                name="shipping_id"
                                label="Courier"
                                placeholder="Courier"
                            />
                        </div>
                        <div className="col-span-2 m-0">
                            <ControlledInputDate control={control} placeholder="Transaction Date" label="Transaction Date" name="transaction_date" />
                            <ControlledInputTextArea control={control} placeholder="Note" label="Note" name="note" rows={1} />
                            {expanses.length !== 0 && (
                                <>
                                    <p className="capitalize m-0 text-gray-700">Expenses</p>
                                    <ListExpenses data={expanses} onRemoveData={onRemoveExpenses} />
                                </>
                            )}
                        </div>
                        <div className="col-span-2 gap-6">
                            <ControlledInputDate control={control} placeholder="Delivery Date" label="Delivery Date" name="delivery_date" />
                            <ControlledInputTextArea
                                control={control}
                                placeholder="Delivery Address"
                                label="Delivery Address"
                                name="delivery_address"
                                rows={3}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-5 gap-6">
                        <p className="col-span-1  capitalize m-0 text-gray-700">
                            total price <br />
                            <span className="font-semibold m-0 text-gray-700">{(getSummaryPrice.data?.total_price || 0).ToIndCurrency("Rp")}</span>
                        </p>
                        <p className="col-span-1  capitalize m-0 text-gray-700">
                            total tax <br />
                            <span className="font-semibold m-0 text-gray-700">{(getSummaryPrice.data?.total_pajak || 0).ToIndCurrency("Rp")}</span>
                        </p>
                        <p className="col-span-1  capitalize m-0 text-gray-700">
                            total discount <br />
                            <span className="font-semibold m-0 text-gray-700">{(getSummaryPrice.data?.total_discount || 0).ToIndCurrency("Rp")}</span>
                        </p>
                        <p className="col-span-1  capitalize m-0 text-gray-700">
                            total expenses <br />
                            <span className="font-semibold m-0 text-gray-700">{(getSummaryPrice.data?.total_expenses || 0).ToIndCurrency("Rp")}</span>
                        </p>
                        <p className="col-span-1 capitalize m-0 text-gray-700">
                            grand total <br />
                            <span className="font-bold m-0 text-gray-700">{(getSummaryPrice.data?.grand_total || 0).ToIndCurrency("Rp")}</span>
                        </p>
                    </div>
                </Form>
            </Card>
            <Space>
                <ModalAddRequest selected={selectedPR} setSelected={setSelectedPR} setDocReqChild={setDocReqChild} onChoose={onChooseRequest}>
                    {(ctrl) => (
                        <Button className="!flex !items-center w-fit" type="primary" onClick={ctrl.openModal} disabled={getVendor.isLoading}>
                            <MdAdd className="mr-2" />
                            Add Document Request
                        </Button>
                    )}
                </ModalAddRequest>
                <Button
                    className="!flex !items-center w-fit"
                    type="primary"
                    disabled={!childFilter?.length && !parseData.vendor_id}
                    onClick={onSummaryPrice}
                    loading={getSummaryPrice.isLoading}
                >
                    <MdRefresh className="mr-2" />
                    Refresh Price
                </Button>
            </Space>
            {docReqChild.length !== 0 ? (
                <Card>
                    <div>
                        <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                            PR No :<span className="m-0 font-normal text-gray-500"> {prNo}</span>
                        </p>
                        <TenderPurchasing context={PurchasingContext} />
                    </div>
                </Card>
            ) : null}
        </div>
    );
};

const PurchasingInventoryAdd = () => {
    const [problemChild, setProblemChild] = useState<InventoryItem[]>([]);
    const [docReqChild, setDocReqChild] = useState<DocReqChild[]>([]);
    const [pickedVendor, setPickedVendor] = useState<number | null>(null);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const getVendor = useQuery([purchasingInventoryService.getBp], async () => {
        return (await purchasingInventoryService.GetBP()).data.data?.map((el, i) => ({
            label: el.bp_name,
            value: el.bp_id,
        }));
    });

    const getPOPrice = useMutation([purchasingInventoryService.poPrice], async (param: POPrice & { columnIndex: number }) => {
        await Utils.pause(0.5);
        return (await purchasingInventoryService.POPrice({ bp_id: param.bp_id, child: param.child })).data.data;
    });

    const requestDetail = useMutation(
        [purchasingRequestService.detailRequest],
        async (id: any) => {
            return (await purchasingRequestService.DetailRequest({ id })).data.data;
        },
        {
            onSuccess(data) {
                setDocReqChild(data?.child || []);
            },
        }
    );

    const value = useMemo(
        () => ({
            docReqChild,
            setDocReqChild,
            pickedVendor,
            setPickedVendor,
            requestDetail,
            getVendor,
            setProblemChild,
            problemChild,
            isRefresh,
            setIsRefresh,
            getPOPrice,
        }),
        [docReqChild, pickedVendor, requestDetail, isRefresh]
    );

    return (
        <PurchasingContext.Provider value={value}>
            <PurchasingInventoryAddContext />
        </PurchasingContext.Provider>
    );
};

export default PurchasingInventoryAdd;

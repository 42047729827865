/* eslint-disable no-shadow */
import { Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import ProductTable from "modules/master-data/physical-inventory/view/table";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import physicalInventoryService, { DocProduct } from "services/api-endpoints/dashboard/master-data/physical-inventory";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const PhysicalInventoryView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");
    const [products, setProducts] = useState<DocProduct[]>([]);

    const detailQuery = useQuery(
        [physicalInventoryService.detail, id],
        async () => {
            return (await physicalInventoryService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess: (data) => {
                setProducts(data.list_product || []);
            },
        }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction title="Physical Inventory detail" />
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <div className="flex items-start justify-between">
                                    <p className="capitalize m-0">
                                        Doc no <br />
                                        <span className="underline italic m-0">{detailQuery.data?.doc_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        File Name <br />
                                        <span className="underline italic m-0">{detailQuery.data?.file_name}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Type <br />
                                        <span className="underline italic m-0">{detailQuery.data?.type === 1 ? "In" : "Out"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Note <br />
                                        <span className="underline italic m-0">{detailQuery.data?.note || "-"}</span>
                                    </p>
                                    <div />
                                </div>
                            </State.Data>
                            <State.Loading state={state}>
                                <Skeleton paragraph={{ rows: 2 }} active />
                            </State.Loading>
                        </>
                    )}
                </State>
            </Card>
            <ProductTable loading={detailQuery.isLoading} list={products} setList={setProducts} />
        </div>
    );
};

export default PhysicalInventoryView;

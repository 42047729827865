import { ColProps, Form, Switch, SwitchProps } from "antd";
import React, { forwardRef } from "react";

type InputSwitchProps = SwitchProps & {
    error: string | undefined;
    label: string;
    labelCol?: ColProps;
    classNameForm?: string;
};

const InputSwitch: React.FC<InputSwitchProps> = forwardRef(({ error, classNameForm, label, labelCol, loading, ...rest }: InputSwitchProps) => (
    <Form.Item label={label} validateStatus={error ? "error" : ""} help={error && error} labelCol={labelCol} className={`!w-full ${classNameForm}`}>
        <Switch {...rest} />
    </Form.Item>
));

InputSwitch.displayName = "InputSwitch";

export default InputSwitch;

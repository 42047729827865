import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterPurchasingRequest from "modules/purchasing/request/filter";
import PurchasingRequestTable from "modules/purchasing/request/table-index";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import purchasingRequestService, { FilterRequest, RequestList } from "services/api-endpoints/purchasing/purchasing-request";

const filter = ["start_date", "end_date", "docType", "status", "product_id", "itemType", "note", "created_by"];

const PurchasingRequest = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [purchasingRequestService.getAll, page, searchValue, filterValue, isFilter, isSearch],
        async () => {
            return (await purchasingRequestService.GetAll({ ...filterValue, ...searchValue })).data.data;
        },
        {
            enabled: !!page,
        }
    );

    const deleteModelType = useMutation(async (data: RequestList) => (await purchasingRequestService.DeleteRequest({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<RequestList>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterPurchasingRequest filter={filter}>
                {(dt) => <Toolbar placeholder="Search PR No/Request By ..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterPurchasingRequest>
            <PurchasingRequestTable fetcher={getList} onClickDelete={showConfirm} />
        </div>
    );
};

export default PurchasingRequest;

import { Button, ButtonProps } from "antd";
import { StateContext } from "context/state";
import React, { useContext } from "react";
import { AiFillEye } from "react-icons/ai";

type Props = ButtonProps;

export default function ButtonAccessView({ children, ...props }: Props) {
    const { readAccess } = useContext(StateContext);

    if (!readAccess) {
        return null;
    }

    return (
        <Button {...props}>
            <AiFillEye className="text-lg" />
        </Button>
    );
}

/* eslint-disable react/destructuring-assignment */
import ModalHistoryTableMM, { Props as ModalHistoryProps } from "components/modal/history-modal-table-mm";
import React from "react";
import { useQuery } from "react-query";
import materialMovementService from "services/api-endpoints/dashboard/master-data/material-movement";

type Props = ModalHistoryProps;

export function withModalHistory<T extends Props>(Component: React.ComponentType<T>) {
    return (props: T) => {
        const detailHistory = useQuery(
            [materialMovementService.byDoc, props.idHistory],
            async () => {
                return (await materialMovementService.HistoryDetailMM({ order_id: props.idHistory, option: props.option })).data.data;
            },
            {
                enabled: !!props.idHistory,
            }
        );
        return <Component {...props} fetcher={detailHistory} />;
    };
}

const ModalHistoryMM = withModalHistory(ModalHistoryTableMM);
export default ModalHistoryMM;

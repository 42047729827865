/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import ControlledSelectInput, { ControlledInputSelectProps } from "components/form/controlled-inputs/controlled-input-select";
import { SelectOption } from "models";
import { InfoDataCategory } from "modules/master-data/category/models";
import React from "react";
import { useQuery } from "react-query";
import categoryService from "services/api-endpoints/dashboard/master-data/category";
import { CreateDataSubCategory } from "../models";

type SelectOptionCustom = SelectOption & InfoDataCategory;

export interface Props extends ControlledInputSelectProps<CreateDataSubCategory> {
    onSelectedData?: (data: InfoDataCategory[]) => void;
}

export function withCategory<T extends Props>(Component: React.ComponentType<Omit<T, "options">>) {
    return (props: Omit<T, "options">) => {
        const query = useQuery([categoryService.info], async () => {
            const req = await categoryService.Info();
            return req.data.data?.map((el) => ({ ...el, label: el.category_name || "", value: el.category_code || "" } as SelectOptionCustom));
        });

        const onSelectChange = () => {
            if (props.onSelectedData) {
                props.onSelectedData(query.data || []);
            }
        };

        return (
            <Component
                {...props}
                onSelect={onSelectChange}
                onDeselect={onSelectChange}
                optionFilterProp="children"
                loading={query.isLoading}
                options={query.data || []}
            />
        );
    };
}

const CategorySelect = withCategory(ControlledSelectInput);

export default CategorySelect;

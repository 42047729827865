/* eslint-disable no-shadow */

import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@mui/material";
import { Button, Card, Form, message } from "antd";
import ControlledSelectInput from "components/form/controlled-inputs/controlled-input-select";
import ControlledInputText from "components/form/controlled-inputs/controlled-input-text";
import ToolbarAction from "components/toolbar/action";
import ModalAddMenu from "modules/master-data/menu/add/modal";
import SubMenuAddTable, { ItemRowSubMenu } from "modules/master-data/menu/add/table";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiPlus, BiSave } from "react-icons/bi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import menuService, { MenuRequest } from "services/api-endpoints/dashboard/master-data/menu";
import { toLowerSnakeCase } from "utils";
import { MENU_ACTIVE } from "utils/constant";
import * as yup from "yup";
import { v4 as uuid } from "uuid";

export type FormMenu = Pick<MenuRequest, "name" | "key" | "icon" | "is_active">;

export type SubMenuStruct = ItemRowSubMenu & {
    collapse?: SubMenuStruct[];
};

const schema: yup.SchemaOf<FormMenu> = yup.object().shape({
    name: yup.string().required("Name required"),
    key: yup.string().required("Key required"),
    icon: yup.string(),
    is_active: yup.number().required("Is Active required"),
});

const MenuAdd = () => {
    const navigate = useNavigate();
    const [subMenu, setSubMenu] = useState<SubMenuStruct[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control, watch } = useForm<FormMenu>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const watchIcon = watch("icon");

    const createMutation = useMutation(
        async (data: MenuRequest) => {
            return (await menuService.CreateMenu(data)).data.data;
        },
        {
            onSuccess() {
                navigate(-1);
                message.success("New menu created");
            },
        }
    );

    const onSubmitHandler = handleSubmit((data) => {
        const parseData = {
            name: data.name,
            key: data.key,
            is_active: data.is_active,
            icon: data?.icon ? toLowerSnakeCase(data.icon) : null,
            collapse: subMenu,
        } as MenuRequest;

        createMutation.mutate(parseData);
    });

    const onCreateHandler = async () => {
        await form.validateFields();
        form.submit();
    };

    const onDelete = (key: string) => {
        setSubMenu((prev) => {
            return prev
                .map((parent) => {
                    const collapse = parent?.collapse?.filter((child) => child?.key !== key);
                    return { ...parent, collapse };
                })
                .filter((parent) => parent.key !== key);
        });
    };

    const checkSameKey = (key: string) => {
        let isSame = false;
        subMenu.forEach((parent) => {
            if (isSame) return;
            if (parent.key === key) {
                isSame = true;
            }
            parent?.collapse?.forEach((child) => {
                if (child.key === key) {
                    isSame = true;
                }
            });
        });
        return isSame;
    };

    const onSubmitParent = (data: ItemRowSubMenu) => {
        if (checkSameKey(data.key!)) {
            message.error(`Key "${data.key}" already exist`);
            return;
        }
        setSubMenu((prev) => [...prev, { ...data, id: uuid(), collapse: [] }]);
    };

    const onSubmitChild = (data: ItemRowSubMenu, parentKey: string) => {
        if (parentKey) {
            if (checkSameKey(data.key!)) {
                message.error(`Key "${data.key}" already exist`);
                return;
            }
            setSubMenu((prev) => {
                return prev?.map((el) => {
                    if (el.key !== parentKey) return el;
                    return {
                        ...el,
                        id: uuid(),
                        collapse: [...(el?.collapse || []), data],
                    };
                });
            });
            return;
        }
        setSubMenu((prev) => {
            return prev?.map((parent) => {
                if (parent.id === data.id) {
                    return {
                        ...parent,
                        ...data,
                        collapse: parent?.collapse,
                    };
                }
                return {
                    ...parent,
                    collapse: (parent?.collapse || [])?.map((child) => {
                        if (child.id === data.id) {
                            return {
                                ...child,
                                ...data,
                            };
                        }
                        return child;
                    }),
                };
            });
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="add new menu"
                rightAddition={() => (
                    <Button loading={createMutation.isLoading} onClick={onCreateHandler} type="primary" className="!flex !items-center">
                        <BiSave className="m-0 mr-2" />
                        Save
                    </Button>
                )}
            />
            <Card className="">
                <Form form={form} layout="vertical" onFinish={onSubmitHandler}>
                    <div className="grid grid-cols-3 gap-x-4">
                        <ControlledInputText control={control} name="name" placeholder="Menu Name" label="Menu Name" />
                        <ControlledInputText control={control} name="key" placeholder="Menu Key" label="Menu Key" />
                        <div className="flex items-start gap-3">
                            {watchIcon && <Icon fontSize="medium">{toLowerSnakeCase(watchIcon)}</Icon>}
                            <ControlledInputText control={control} name="icon" placeholder="Icon" label="Icon" />
                            <a href="https://mui.com/material-ui/material-icons/" target="_blank" className="" rel="noreferrer">
                                icons
                            </a>
                        </div>
                        <ControlledSelectInput options={MENU_ACTIVE} control={control} name="is_active" placeholder="Is Active" label="Is Active" />
                    </div>
                </Form>
                <div className="w-full flex justify-end">
                    <ModalAddMenu onSubmit={onSubmitParent}>
                        {({ openModal }) => (
                            <Button onClick={openModal} type="primary" className="!flex !items-center">
                                <BiPlus className="m-0 mr-2" />
                                Sub Menu
                            </Button>
                        )}
                    </ModalAddMenu>
                </div>
            </Card>
            <SubMenuAddTable onDelete={onDelete} onSubmit={onSubmitChild} list={subMenu} asParent />
        </div>
    );
};

export default MenuAdd;

import { Alert, Button, Card, Form, Skeleton, Space, Tabs, message } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import useConfirmDelete from "hooks/useConfirmDelete";
import ProductTable from "modules/dashboard-all/bp-order/product-table";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import BPHistoryTable from "modules/dashboard-jsl/bp-delovery-order/history-table";
import { FDataCreateBPOrder } from "modules/master-data/bp-order/models";
import Print from "modules/master-data/bp-order/view/print";
import { useCallback, useRef, useState } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import bpOrderService, { DetailProduct } from "services/api-endpoints/dashboard/master-data/bp-order";
import { BP_ORDER_PROCESS, STATUS_BP_ORDER } from "utils/constant";
import * as yup from "yup";

const schema: yup.SchemaOf<Partial<FDataCreateBPOrder>> = yup.object().shape({
    bp_id: yup.number().required("Business partner required"),
    payment_term_id: yup.number().required("Term Of Payment required"),
});

export type BpOrderViewProps = {
    asPreview?: boolean;
    idUrl?: string;
    urlNamePage?: string;
};

const BpOrderView = ({ asPreview, idUrl = "id", urlNamePage = "page" }: BpOrderViewProps) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const id = params.get(idUrl);
    const page = params.get(urlNamePage) || 1;

    // const [typeSave, setTypeSave] = useState<"draft" | "submit">("draft");
    const [products, setProducts] = useState<DetailProduct[]>([]);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    // const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);

    const letterRef = useRef<HTMLDivElement | null>(null);

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `BP Sales Order - ${id}`,
        pageStyle: `@page { 
            size: A5 landscape;
         }`,
    });

    const [form] = Form.useForm();

    const detailQuery = useQuery(
        [bpOrderService.detail, id],
        async () => {
            return (await bpOrderService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                form.setFieldsValue({
                    bp_id: data.bp_id,
                });
                setVouchers(data?.voucher || []);
                // setCustomVouchers(data?.voucher_custom || []);
                setProducts(data?.list_detail || []);
            },
        }
    );

    const history = useQuery(
        [bpOrderService.history, page],
        async () => {
            return (await bpOrderService.History({ page: page as any, id })).data.data;
        },
        {
            enabled: !!id,
        }
    );

    const deleteModelType = useMutation(async () => (await bpOrderService.Delete({ id })).data.data, {
        onSuccess: () => {
            message.success("Picking List deleted");
            navigate(-1);
        },
    });

    const [showConfirm] = useConfirmDelete<any>({
        content: "You sure to delete BP Order?",
        onOk: (data, callback) => {
            deleteModelType.mutateAsync().then(callback).catch(callback);
        },
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const toolbarActions = useCallback(
        () => (
            <Space>
                <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                    <AiFillPrinter className="m-0 mr-2" />
                    Print
                </Button>
                <Button
                    loading={deleteModelType.isLoading}
                    disabled={!((detailQuery.data?.order_status || 0) <= BP_ORDER_PROCESS)}
                    onClick={showConfirm}
                    type="default"
                    className="!flex !items-center text-red-600"
                >
                    <BiTrash className="m-0 mr-2 text-red-600" />
                    Delete
                </Button>
            </Space>
        ),
        [detailQuery.data, deleteModelType.isLoading, onClickPrintHandler]
    );

    return (
        <div className="w-full flex flex-col gap-6">
            {asPreview ? null : (
                <>
                    <Print ref={letterRef} data={detailQuery.data || null} />
                    <ToolbarAction title="detail BP Order" rightAddition={toolbarActions} />
                </>
            )}
            <Card className="">
                <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                    {(state) => (
                        <>
                            <State.Data state={state}>
                                <Form form={form} layout="vertical">
                                    <div className="grid grid-cols-4 gap-4">
                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 font-semibold text-gray-700 mb-4">
                                                        sales order no <br />
                                                        <span className="underline italic m-0 font-normal text-gray-500">
                                                            {detailQuery.data?.so_no}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        total price <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.total_price || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Partner Name <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.bp_name}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        total discount <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.total_discount || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700">
                                                        Term Of Payment <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {detailQuery.data?.payment_term_name}
                                                        </span>
                                                    </p>
                                                    <p className="capitalize m-0 text-gray-700 mt-6">
                                                        grand total <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {(detailQuery.data?.grand_total || 0)?.ToIndCurrency("Rp")}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="h-[130px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="flex">
                                                <div className="w-full">
                                                    <p className="capitalize m-0 text-gray-700 mb-2">Voucher</p>
                                                    <ListVoucher vouchers={vouchers} bordered showTextEmpty />
                                                    {/* <p className="capitalize m-0 text-gray-700 mt-4 mb-2">Voucher Custom</p>
                                                    <ListCustomVoucher vouchers={customVouchers} bordered showTextEmpty /> */}
                                                    <p className="capitalize m-0 text-gray-700 mt-4">
                                                        status <br />
                                                        <span className="underline italic font-semibold m-0 text-gray-700">
                                                            {STATUS_BP_ORDER.find((st) => st.value === detailQuery.data?.order_status)?.label}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </State.Data>
                            <State.Loading state={state}>
                                <div className="grid grid-cols-4 gap-4">
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                </div>
                            </State.Loading>
                            <State.Error state={state}>
                                <Alert type="error" message={(detailQuery.error as any)?.message} />
                            </State.Error>
                        </>
                    )}
                </State>
            </Card>
            <Card>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: `Detail Product`,
                            key: "1",
                            children: <ProductTable list={products} setList={setProducts} action={false} />,
                        },
                        {
                            label: `History`,
                            key: "2",
                            children: <BPHistoryTable urlNamePage={urlNamePage} fetcher={history} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default BpOrderView;

import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import TableIndexBom from "modules/bom/index/table";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bomService, { ListBom } from "services/api-endpoints/dashboard/manufacture/bom";
import suppliesService, { FilterPurchasing } from "services/api-endpoints/purchasing/supplies";

const filter = ["start_date", "end_date"];

const Bom = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bomService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        return (await bomService.GetAll()).data.data;
    });

    const deleteMutation = useMutation(async (data: ListBom) => (await bomService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Bom Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<ListBom>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <Toolbar placeholder="Bom Package ..." filter={false} />
            <TableIndexBom fetcher={getList} onDelete={showConfirm} />
        </div>
    );
};

export default Bom;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import {
    CreateDataSubAttribute,
    DetailDataSubAttribute,
    EditDataSubAttribute,
    FilterDataSubAttribute,
} from "modules/master-data/sub-attribute/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { PostMethodParams } from "models";

export interface AllSubAttribute {
    id: number;
    subattribute_name: string;
    subattribute_code: string;
}

class SubAttributeService extends BaseService {
    getAll = "/master-subattribute/all-subattribute";

    delete = "/master-subattribute/delete-subattribute";

    search = "/master-subattribute/search-subattribute";

    suggestSubAttrName = "/master-subattribute/suggest-subattribute-name";

    suggestSubAttrCode = "/master-subattribute/suggest-subattribute-code";

    filter = "/master-subattribute/filter-subattribute";

    getAllSubAttribute = "/master-subattribute/get-all-subattribute";

    create = "/master-subattribute/create-subattribute";

    detail = "/master-subattribute/get-detail-subattribute";

    edit = "/master-subattribute/edit-subattribute";

    constructor() {
        super();
    }

    GetAllSubAttribute<T extends AllSubAttribute[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllSubAttribute,
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataSubAttribute>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Delete<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.delete<T>({
                url: `${this.delete}/${id}`,
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataSubAttribute>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestSubAttrName<T extends string[]>(param: { subattribute_name: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestSubAttrName,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    SuggestSubAttrCode<T extends string[]>(param: { subattribute_code: string }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.suggestSubAttrCode,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataSubAttribute>(param: FilterDataSubAttribute) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditDataSubAttribute, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
                config,
            });
            return req;
        });
    }

    Detail<T extends DetailDataSubAttribute>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${id}`,
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateDataSubAttribute, config?: PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
                config,
            });
            return req;
        });
    }
}

const subAttributeService = new SubAttributeService();
export default subAttributeService;

/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

class AppsMenuService extends BaseService {
    getMainMenu = "/apps-menu/get-main-menu";

    getAllApps = "/apps-menu/get-apps";

    installApps = "/apps-menu/install-apps";

    constructor() {
        super();
    }

    GetMainMenu<T extends Models.MainMenu[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getMainMenu,
            });
            return req;
        });
    }

    GetAllApps<T extends Models.App[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAllApps,
            });
            return req;
        });
    }

    InstallApps<T extends any>(data: Models.InstallAppData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.installApps,
                data,
            });
            return req;
        });
    }
}

const appsMenuService = new AppsMenuService();
export default appsMenuService;

import { clsx } from "clsx";
import WrapperCardEffect, { WrapperCardEffectProps } from "modules/manufacture/components/wrapper-card-effect";
import { backgroundColorManufacture, cardVariableItemInfo } from "modules/manufacture/utils/constant";
import ShapeVariable, { ShapeVariableProps } from "./shape";

export type CardVariableDrawProps = Omit<WrapperCardEffectProps, "children"> &
    ShapeVariableProps & {
        children?: any;
    };

const CardVariableDraw = ({
    children,
    className,
    indexEffectedItem,
    totalChild,
    isOverOldItem,
    onMoveChild,
    removeChildren,
    ...props
}: CardVariableDrawProps) => {
    const wrapperProps = {
        ...props,
        indexEffectedItem,
        totalChild,
        isOverOldItem,
        onMoveChild,
        removeChildren,
    };
    return (
        <WrapperCardEffect {...wrapperProps}>
            {({ isDragging, isDragOutsideToRemove, ref }: any) => (
                <ShapeVariable
                    ref={ref}
                    asDraw
                    backgroundColor={clsx(
                        isDragging ? cardVariableItemInfo.style.secondaryColor : cardVariableItemInfo.style.color,
                        isDragOutsideToRemove ? backgroundColorManufacture.danger : ""
                    )}
                    className={clsx("cursor-grab text-white", isDragging ? "!min-h-[50px]" : "", className)}
                    {...props}
                >
                    {children}
                </ShapeVariable>
            )}
        </WrapperCardEffect>
    );
};

export default CardVariableDraw;

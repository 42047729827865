/* eslint-disable no-shadow */
/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface HistoryBankItem {
    id: number;
    journal_no: string;
    is_acc_posted: number;
    created_at: string;
    coa_id: number;
    name: string;
    note: string;
    doc_id: number;
    doc_type: number;
    saldo: number;
    debit: number;
    kredit: number;
}

export interface SaldoData {
    note?: string;
    saldo?: number;
}

export interface HistoryBank {
    list?: HistoryBankItem[];
    saldo_awal?: SaldoData;
    total?: SaldoData;
}

export interface FilterHistoryBank {
    coa_id?: any;
    start_date?: any;
    end_date?: any;
}

class HistoryBankService extends BaseService {
    historyBank = "/bank-account/history-bank";

    HistoryBank<T extends HistoryBank>(params: FilterHistoryBank) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.historyBank,
                config: { params },
            });
            return req;
        });
    }
}

const historyBankService = new HistoryBankService();
export default historyBankService;

/* eslint-disable react/no-array-index-key */
import { Alert, Image, Skeleton, Space } from "antd";
import ButtonAccesory from "components/button/button-accesory";
import State from "components/common/state";
import React from "react";
import { useQuery, UseQueryResult } from "react-query";
import specificSearchService, { Subcategory, SubcategoryProduct } from "services/api-endpoints/giias/cashier-giias/specific-search";
import { IMAGE_FALLBACK } from "utils/constant";
import { Option } from "./filter";

type Props = {
    fetcher: UseQueryResult<SubcategoryProduct, unknown>;
    onSelect: (data: Subcategory) => void;
};

const SubcategoryProductDetail = ({ fetcher, onSelect }: Props) => {
    return (
        <State data={fetcher.data} isLoading={fetcher.isLoading} isError={fetcher.isError}>
            {(state) => (
                <>
                    <State.Data state={state}>
                        <div className="">
                            <div className="w-full flex items-start justify-between gap-6">
                                <div className="flex-1">
                                    <p className="capitalize text-gray-500 m-0">accesories for</p>
                                    <h2 className="text-primary font-semibold uppercase text-xl m-0">{fetcher.data?.name}</h2>
                                </div>
                                <div className="flex-1 grid grid-cols-2 gap-4">
                                    <p className="m-0 capitalize leading-4">
                                        merk <br /> <span className="font-semibold uppercase text-gray-600">{fetcher.data?.brand_name}</span>
                                    </p>
                                    <p className="m-0 capitalize leading-4">
                                        year <br /> <span className="font-semibold uppercase text-gray-600">{fetcher.data?.model_year}</span>
                                    </p>
                                    <p className="m-0 capitalize leading-4">
                                        model <br /> <span className="font-semibold uppercase text-gray-600">{fetcher.data?.model}</span>
                                    </p>
                                    <p className="m-0 capitalize leading-4">
                                        type <br /> <span className="font-semibold uppercase text-gray-600">{fetcher.data?.type}</span>
                                    </p>
                                </div>
                                <div className="flex-1">
                                    <Image
                                        preview={false}
                                        width={250}
                                        src={fetcher.data?.model_image}
                                        alt={fetcher.data?.name}
                                        className="object-contain"
                                        fallback={IMAGE_FALLBACK}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-7 mt-2">
                                {fetcher.data?.subcategory_list?.map((accesory) => (
                                    <ButtonAccesory
                                        onClick={() => onSelect(accesory)}
                                        description={accesory.subcategory_name}
                                        image={accesory.subcategory_image || undefined}
                                        key={accesory.subcategory_id}
                                    />
                                ))}
                            </div>
                        </div>
                    </State.Data>
                    <State.Loading state={state}>
                        <Skeleton paragraph={{ rows: 3 }} />
                        <Space>
                            {[...new Array(5)].map((_, i) => (
                                <ButtonAccesory.Loading key={i} />
                            ))}
                        </Space>
                    </State.Loading>
                    <State.Error state={state}>
                        <Alert type="error" message={(fetcher.error as any)?.message} />
                    </State.Error>
                </>
            )}
        </State>
    );
};

export default SubcategoryProductDetail;

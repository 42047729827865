import { Button, Card, Tabs, message } from "antd";
import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterJurnalPenyesuaian from "modules/accounting/jurnal-penyesuaian/index/filter";
import { useMutation, useQuery } from "react-query";
import React, { useState } from "react";
import { TYPE_JOURNAL_ACCOUNTING, TYPE_JURNAL_PENYESUAIAN } from "utils/constant";
import jurnalUmumService, { FilterJurnalUmum, JurnalUmum, UpdateJournalData } from "services/api-endpoints/accounting/jurnal-umum";
import UnpostedTable from "modules/accounting/jurnal/index/table-unposted";
import PostedTable from "modules/accounting/jurnal/index/table-posted";
import DraftedTable from "modules/accounting/jurnal/index/table-drafted";
import useConfirmDelete from "hooks/useConfirmDelete";
import JurnalProvider from "modules/accounting/jurnal/context/jurnal";
import { IoRefresh } from "react-icons/io5";

const filter = ["start_date", "end_date", "type", "account_id"];

const JurnalPenyesuaian = () => {
    const [keyTab, setKeyTab] = useState("1");
    const { isSearch, searchValue, filterValue2 } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery(
        [jurnalUmumService.filter, filterValue2, isSearch, searchValue],
        async () => {
            if (isSearch)
                return (
                    await jurnalUmumService.Filter({
                        ...({ query: searchValue.query, start_date: "", end_date: "", type: TYPE_JURNAL_PENYESUAIAN } as FilterJurnalUmum),
                    })
                ).data.data;
            return (
                await jurnalUmumService.Filter({
                    ...({ ...filterValue2, query: "", type: TYPE_JURNAL_PENYESUAIAN } as FilterJurnalUmum),
                })
            ).data.data;
        },
        {
            onSuccess: (data) => {
                if (data.list_unposted.length !== 0) {
                    setKeyTab("1");
                } else if (data.list_posted.length !== 0) {
                    setKeyTab("2");
                } else if ((data.list_draft || []).length !== 0) {
                    setKeyTab("3");
                }
            },
        }
    );

    const deleteMutation = useMutation(async (data: JurnalUmum) => (await jurnalUmumService.DeletePenyesuaian({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Draft Deleted");
        },
    });

    const updateStatusJournal = useMutation(
        [jurnalUmumService.updateStatusJournal],
        async (data: UpdateJournalData) => {
            return (await jurnalUmumService.UpdateStatusJournal(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Success Post Journal");
                getList.refetch();
            },
        }
    );

    const onPosJournal = (data: any[]) => {
        const dataFilter = data.filter((el) => el.is_posting);
        const parseData: UpdateJournalData = {
            type: TYPE_JOURNAL_ACCOUNTING,
            doc_id: dataFilter?.map((itm) => itm.id as any),
        };

        updateStatusJournal.mutateAsync(parseData);
    };

    const [showConfirm] = useConfirmDelete<JurnalUmum>({
        onOk: (data, callback) => {
            deleteMutation.mutateAsync(data).then(callback).catch(callback);
        },
    });

    const onRefresh = () => {
        getList.refetch();
        message.success("Refresh Journal");
    };

    const onChangeTab = (key: string) => {
        setKeyTab(key);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterJurnalPenyesuaian title="Jurnal Penyesuaian" filter={filter}>
                {(dt) => <Toolbar placeholder="Search Invoice Referensi..." filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterJurnalPenyesuaian>
            <Card>
                <Tabs
                    tabBarExtraContent={
                        <Button className="w-fit" onClick={onRefresh}>
                            <IoRefresh className="mr-3" />
                            Refresh
                        </Button>
                    }
                    defaultActiveKey="1"
                    activeKey={keyTab}
                    onChange={onChangeTab}
                    items={[
                        {
                            label: `Unposted`,
                            key: "1",
                            children: (
                                <JurnalProvider>
                                    <UnpostedTable fetcher={getList} onPosJournal={onPosJournal} typeJurnal={TYPE_JURNAL_PENYESUAIAN} />
                                </JurnalProvider>
                            ),
                        },
                        {
                            label: `Posted`,
                            key: "2",
                            children: <PostedTable fetcher={getList} typeJurnal={TYPE_JURNAL_PENYESUAIAN} />,
                        },
                        {
                            label: `Drafted`,
                            key: "3",
                            children: <DraftedTable fetcher={getList} onClickDelete={showConfirm} />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default JurnalPenyesuaian;

import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBankAccount from "modules/accounting/bank-account/index/filter";
import BankAccountTable from "modules/accounting/bank-account/index/table";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bankAccountService from "services/api-endpoints/accounting/bank-account";
import React from "react";

const filter = ["name", "description", "account_no", "organization_id"];

const BankAccount = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bankAccountService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await bankAccountService.Search({ page, query: searchValue.query })).data.data;
        if (isFilter) return (await bankAccountService.Filter(filterValue as any)).data.data;
        return (await bankAccountService.GetAll({ page })).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBankAccount filter={filter}>
                {(dt) => <Toolbar placeholder="Name / Description" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBankAccount>
            <BankAccountTable fetcher={getList} />
        </div>
    );
};

export default BankAccount;

import { Alert, Button, Card, message, Select, Skeleton, Tabs } from "antd";
import State from "components/common/state";
import ModalNote from "components/modal/note-modal";
import ToolbarAction from "components/toolbar/action";
import { StateContext } from "context/state";
import ModalCustomVoucher from "modules/dashboard-all/sales/add/modal-custom-voucher";
import ListCustomVoucher from "modules/dashboard-all/sales/components/list-custom-voucher";
import ListVoucher from "modules/dashboard-all/sales/components/list-voucher";
import { CustomVoucher, DetailProduct, GetSummaryPrice, ProductEdit } from "modules/dashboard-all/sales/models";
import DetailEditProduct from "modules/dashboard-all/sales/view/detail-product";
import HistoryStatus from "modules/dashboard-all/sales/view/history-status";
import Versioning from "modules/dashboard-all/sales/view/versioning";
import React, { Key, useContext, useEffect, useState } from "react";
import { BiCheck, BiSave } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import orderSalesService, { EditSalesOrder, Product, Voucher } from "services/api-endpoints/dashboard-all/sales/order-sales";
import { MARKET_PLACE_VALUES, ORDER_SALES_CANCEL, STATUS_ORDER_SALES, STATUS_ORDER_SALES_FILTER } from "utils/constant";

const SalesView = () => {
    const { updateAccess } = useContext(StateContext);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    const [products, setProducts] = useState<ProductEdit[]>([]);
    const [orderStatus, setOrderStatus] = useState<number | null>(null);
    const [tab, setTab] = useState<"detail_product" | "history" | "versioning">("detail_product");
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [customVouchers, setCustomVouchers] = useState<CustomVoucher[]>([]);
    const [edit, setEdit] = useState<number>(0);
    const [cvProdKeys, setcvProdKeys] = useState<Key[]>([]);

    const detailQuery = useQuery(
        [orderSalesService.detail, id],
        async () => {
            return (await orderSalesService.Detail({ id })).data.data;
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: !!id,
            onSuccess: (data) => {
                setOrderStatus(data?.order_status);
                setCustomVouchers(data?.voucher_custom || []);
                setProducts(
                    (data?.list_detail || []).map((prd) => {
                        const matchingPrd = data.list_voucher?.find((el) => el.product_id === prd.product_id);
                        if (matchingPrd) {
                            return {
                                ...prd,
                                discount: matchingPrd.discount_price,
                            };
                        }
                        return prd;
                    })
                );
            },
        }
    );

    const editSalesOrder = useMutation(
        [orderSalesService.editSalesOrder],
        async (data: EditSalesOrder) => {
            return (await orderSalesService.EditSalesOrder(data)).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Sales updated!");
                navigate(-1);
            },
        }
    );

    const setReceived = useMutation(
        [orderSalesService.setReceived],
        async () => {
            return (await orderSalesService.SetReceived({ id })).data.data;
        },
        {
            onSuccess: (data) => {
                message.success("Success Confirmation Order");
                navigate(-1);
            },
        }
    );

    const parseData: GetSummaryPrice = {
        detail_product: [
            ...(products?.map((p) => ({ price: p.product_price, product_id: p.product_id, qty: p.qty, note: p.note || null } as DetailProduct)) ||
                []),
        ],
        voucher_id: [...(vouchers?.map((v) => v.voucher_id) || [])],
        voucher: [
            ...(customVouchers?.map((cv) => ({ name: cv.name, price: cv.price || null, percentage: cv.percentage || null } as CustomVoucher)) || []),
        ],
    };

    const getSummaryPrice = useMutation([orderSalesService.getSummaryPrice], async (data: GetSummaryPrice) => {
        return (await orderSalesService.GetSummaryPrice(data)).data.data;
    });

    const onSubmitNote = (value: string) => {
        if (!orderStatus) return;
        editSalesOrder
            .mutateAsync({
                id: id as any,
                note: value,
                order_status: orderStatus,
                data_new: products.filter((p) => p.status === "new"),
                data_delete: products.filter((p) => p.status === "delete"),
                data_update: products.filter((p) => p.status === "update"),
                voucher: customVouchers?.map(
                    (cv) => ({ name: cv.name, price: cv.price || null, percentage: cv.percentage || null } as CustomVoucher)
                ),
            })
            .finally(detailQuery.refetch);
    };

    const onChangeTab = (key: any) => {
        setTab(key);
    };

    const onCustomVoucherCreate = (voucher: CustomVoucher) => {
        setEdit(1);
        setCustomVouchers((prev) => [...prev, voucher]);
    };

    const onRemoveCustomVoucher = (index: number) => {
        setEdit(1);
        setCustomVouchers((prev) => prev.filter((_, i) => i !== index));
    };

    const onRefreshProduct = () => {
        setEdit(1);
        getSummaryPrice.mutate(parseData);
    };

    const onConfirmationReceived = () => {
        setReceived.mutate();
    };

    useEffect(() => {
        if (!products.length) return;
        getSummaryPrice.mutate(parseData);
    }, [products, vouchers, customVouchers]);

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail sales"
                rightAddition={() => (
                    <>
                        {updateAccess && detailQuery.data?.editable && (detailQuery.data?.order_product_status || 0) <= 2 ? (
                            <ModalNote title="Status Notes" onSubmit={onSubmitNote}>
                                {(dt) => (
                                    <Button loading={editSalesOrder.isLoading} onClick={dt.openModal} className="!flex !items-center">
                                        <BiSave className="m-0 mr-2" />
                                        Save
                                    </Button>
                                )}
                            </ModalNote>
                        ) : (
                            <div />
                        )}
                        {updateAccess && detailQuery.data?.confirmation_received ? (
                            <Button loading={setReceived.isLoading} onClick={onConfirmationReceived} className="!flex !items-center">
                                <BiCheck className="m-0 mr-2" />
                                Konfirmasi Barang Sampai
                            </Button>
                        ) : (
                            <div />
                        )}
                    </>
                )}
            />
            <Card className="">
                <div className="grid grid-cols-4 gap-4">
                    <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <div className="">
                                        <div className="flex">
                                            <div className="w-full">
                                                <p className="TEXT-1">
                                                    invoice no <br />
                                                    <span className="TEXT-2">{detailQuery.data?.inv_no || "-"}</span>
                                                </p>
                                                <p className="TEXT-1 mt-4">
                                                    marketplace <br />
                                                    <span className="TEXT-2">
                                                        {MARKET_PLACE_VALUES.find((el) => el.value === detailQuery.data?.marketplace_id)?.label}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="h-[100px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                        </div>
                                        <p className="TEXT-1 mt-4">
                                            courier <br />
                                            <span className="TEXT-2">{detailQuery.data?.shipping_name || "_"}</span>
                                        </p>
                                        <p className="TEXT-1 mt-5">
                                            Payment Type <br />
                                            <span className="TEXT-2">{detailQuery.data?.payment_channel_name || "_"}</span>
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex">
                                            <div className="flex flex-col gap-4 w-full">
                                                <div className="">
                                                    <p className="capitalize m-0 font-semibold text-gray-700">Discount</p>
                                                    <ListVoucher showTextEmpty bordered vouchers={detailQuery.data?.voucher || []} />
                                                </div>
                                                <div className="">
                                                    <p className="capitalize m-0 font-semibold text-gray-700">Custom Discount</p>
                                                    {updateAccess && detailQuery.data?.editable ? (
                                                        <ModalCustomVoucher
                                                            onCreate={onCustomVoucherCreate}
                                                            products={products}
                                                            selectedRowKeys={cvProdKeys}
                                                            setSelectedRowKeys={setcvProdKeys}
                                                        >
                                                            {(dt: any) => (
                                                                <div className="w-full flex flex-col gap-1">
                                                                    <Button
                                                                        onClick={dt.openModal}
                                                                        disabled={customVouchers.length === 2}
                                                                        className="!w-full !flex !items-center !justify-between"
                                                                    >
                                                                        Custom Discount <MdAdd />
                                                                    </Button>
                                                                    <ListCustomVoucher
                                                                        vouchers={customVouchers}
                                                                        onRemoveVoucher={onRemoveCustomVoucher}
                                                                    />
                                                                </div>
                                                            )}
                                                        </ModalCustomVoucher>
                                                    ) : (
                                                        <ListCustomVoucher bordered showTextEmpty vouchers={detailQuery.data?.voucher_custom || []} />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="h-[100px] bg-gray-300 ml-4" style={{ width: "1px" }} />
                                        </div>
                                        <p className="TEXT-1">
                                            total discount <br />
                                            <span className="TEXT-2">
                                                {edit === 0
                                                    ? detailQuery.data?.total_discount?.ToIndCurrency("Rp") || "Rp.-"
                                                    : getSummaryPrice.data?.total_discount?.ToIndCurrency("Rp") || "Rp.-"}
                                            </span>
                                        </p>
                                        <p className="TEXT-1 m-0">
                                            status <br />
                                            {updateAccess && detailQuery.data?.editable ? (
                                                <Select
                                                    value={orderStatus}
                                                    options={STATUS_ORDER_SALES}
                                                    status={orderStatus === ORDER_SALES_CANCEL ? "error" : undefined}
                                                    onChange={(value) => setOrderStatus(value)}
                                                    className="w-[90%]"
                                                />
                                            ) : (
                                                <span className="TEXT-2">
                                                    {STATUS_ORDER_SALES_FILTER.find((el) => el.value === detailQuery.data?.order_status)?.label}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div className="col-span-2 grid grid-cols-2 ">
                                        <div className="grid grid-cols-2 gap-x-4 col-span-2">
                                            <p className="TEXT-1">
                                                name <br />
                                                <span className="TEXT-2">{detailQuery.data?.customer_name || "_"}</span>
                                            </p>
                                            <div className="row-span-2">
                                                <p className="TEXT-1">
                                                    address <br />
                                                    <span className="TEXT-2">{detailQuery.data?.customer_address || "_"}</span>
                                                </p>
                                            </div>
                                            <p className="TEXT-1">
                                                invoice info <br />
                                                <span className="TEXT-2">{detailQuery.data?.customer_info || "_"}</span>
                                            </p>
                                        </div>
                                        <p className="TEXT-1">
                                            total price <br />
                                            <span className="TEXT-2">
                                                {edit === 0
                                                    ? detailQuery.data?.total_price?.ToIndCurrency("Rp") || "Rp.-"
                                                    : getSummaryPrice.data?.total_price?.ToIndCurrency("Rp") || "Rp.-"}
                                            </span>
                                        </p>
                                        <p className="TEXT-1">
                                            grand total <br />
                                            <span className="TEXT-2">
                                                {edit === 0
                                                    ? detailQuery.data?.grand_total?.ToIndCurrency("Rp") || "Rp.-"
                                                    : getSummaryPrice.data?.grand_total?.ToIndCurrency("Rp") || "Rp.-"}
                                            </span>
                                        </p>
                                    </div>
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                    <Skeleton paragraph={{ rows: 1 }} active />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert type="error" message={(detailQuery.error as any)?.message} />
                                </State.Error>
                            </>
                        )}
                    </State>
                </div>
            </Card>
            <Card>
                <Tabs
                    activeKey={tab}
                    onChange={onChangeTab}
                    items={[
                        {
                            label: `Detail Product`,
                            key: "detail_product",
                            children: (
                                <DetailEditProduct
                                    detailQuery={detailQuery}
                                    products={products}
                                    setProducts={setProducts}
                                    refreshProduct={onRefreshProduct}
                                />
                            ),
                        },
                        {
                            label: `Order History`,
                            key: "history",
                            children: <HistoryStatus />,
                        },
                        {
                            label: `Versioning History`,
                            key: "versioning",
                            children: <Versioning />,
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export default SalesView;

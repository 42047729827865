import { message } from "antd";
import Toolbar from "components/toolbar";
import useConfirmDelete from "hooks/useConfirmDelete";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterBanner from "modules/master-data/banner/index/filter";
import { TDataBanner } from "modules/master-data/banner/models";
import BannerTable from "modules/master-data/banner/index/table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import bannerService from "services/api-endpoints/dashboard/master-data/banner";

const filter = ["title", "start_date", "end_date", "status"];

const Banner = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([bannerService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isSearch) return (await bannerService.Search({ page, param_search: searchValue.query })).data.data;
        if (isFilter) return (await bannerService.Filter(filterValue as any)).data.data;
        return (await bannerService.GetAll({ page })).data.data;
    });

    const deleteModelType = useMutation(async (data: TDataBanner) => (await bannerService.Delete({ id: data.id })).data.data, {
        onSuccess: () => {
            getList.refetch();
            message.success("Data Deleted");
        },
    });

    const [showConfirm] = useConfirmDelete<TDataBanner>({
        onOk: (data, callback) => {
            deleteModelType.mutateAsync(data).then(callback).catch(callback);
        },
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterBanner filter={filter}>
                {(dt) => <Toolbar placeholder="Title" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} />}
            </FilterBanner>
            <BannerTable onClickDelete={showConfirm} fetcher={getList} />
        </div>
    );
};

export default Banner;
